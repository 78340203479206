import PREPAREDNESS_PHASE_CONST from "./actionTypes";

const initialState = {
  isContentLoading: false,
  editContentError: "",
};
const EAPreparednessPhaseReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    // EDIT
    case PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_EDIT_REQUEST:
      state = {
        ...state,
        isContentLoading: true,
        editedPreparednessPhaseData: false,
        editContentError: false,
        tableUpdate: null,
        editContentNetworkError: false,
      };
      break;
    case PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_EDIT_SUCCESS:
      state = {
        ...state,
        isContentLoading: false,
        editedPreparednessPhaseData:
          action.payload.statusCode === 200 ? action.payload : false,
        editContentError: false,
        tableUpdate: action.payload.isTable,
        editContentNetworkError: false,
      };
      break;
    case PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_EDIT_ERROR:
      state = {
        ...state,
        isContentLoading: false,
        editedPreparednessPhaseData: false,
        editContentError: action.payload.data,
        tableUpdate: null,
        editContentNetworkError: action.payload.data,
      };
      break;

    // DELETE
    case PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_DELETE_REQUEST:
      state = {
        ...state,
        isContentLoading: true,
        deletePreparednessPhaseData: false,
        deleteContetDataError: false,
        deleteContentDatanetwork: false,
      };
      break;
    case PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_DELETE_SUCCESS:
      state = {
        ...state,
        isContentLoading: false,
        deletePreparednessPhaseData:
          action.payload.statusCode === 200 ? action.payload : false,
        deleteContetDataError: false,
        deleteContentDatanetwork: false,
      };
      break;
    case PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_DELETE_ERROR:
      state = {
        ...state,
        isContentLoading: false,
        deletePreparednessPhaseData: false,
        deleteContetDataError: action.payload.data,
        deleteContentDatanetwork: action.payload.data,
      };
      break;

    // CREATE
    case PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_CREATE_REQUEST:
      state = {
        ...state,
        isContentLoading: true,
        createPreparednessPhaseData: false,
        createContentError: false,
        createContentNetworkError: false,
      };
      break;
    case PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_CREATE_SUCCESS:
      state = {
        ...state,
        isContentLoading: false,
        createPreparednessPhaseData:
          action.payload.statusCode === 200 ? action.payload : false,
        createContentError: false,
        createContentNetworkError: false,
      };
      break;
    case PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_CREATE_ERROR:
      state = {
        ...state,
        isContentLoading: false,
        createPreparednessPhaseData: false,
        createContentError: action.payload.data,
        createContentNetworkError: action.payload.data,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};
export default EAPreparednessPhaseReducer;
