import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import successCheckmark from "../../assest/img/successCheckmark.svg";

function SuccessModal({ toggle, modal }) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={modal} size="md">
      <div className="success-modal">
        <ModalHeader className="p-0 delete-header user-flex-end mr-2 mt-5" />
        <ModalBody className="delete-modal-padding">
          <div className="reg-success">
            <img
              src={successCheckmark}
              alt="not-available"
              className="reg-check-image"
            />
            <h2 className="back-office-succes-head-text">
              {" "}
              {t("UPLOAD_PAST_DATA.FILE_UPLOADED")}{" "}
            </h2>
            <p className="back-office-succes-desc">
              {t("UPLOAD_PAST_DATA.SUCCESS_MSG")}
            </p>
            <Button
              type="button"
              className="back-office-ok-btn"
              onClick={() => {
                toggle();
              }}
            >
              {t("UPLOAD_PAST_DATA.OKAY")}
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default SuccessModal;
