import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Label } from "reactstrap";

import Select from "react-select";
import BadIcon from "../../assest/img/Bad-Selected.svg";
import ExcellentIcon from "../../assest/img/ExcellentFillIcon.svg";
import GoodIcon from "../../assest/img/Good-Selected.svg";
import GreatIcon from "../../assest/img/Great-Selected.svg";
import OkayIcon from "../../assest/img/Okay-Selected.svg";
import BackButton from "../../assest/img/back-button.svg";
import { FEEDBACK_MODULES } from "../../constants";
import { getCropYieldFeedbackRequest } from "../../store/actions";
import ModuleComponent from "./ModuleComponent";

function ModalPerformanceFeedback() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [selectedModule, setSelectedModule] = useState({
    label: "Crop Yield Assessment",
    value: "crop_yield_prediction",
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [feedbackList, setFeedbackList] = useState([]);
  const [totalRating, setTotalRating] = useState(0);
  const [maxRatingCount, setMaxRatingCount] = useState(0);
  const [progressBar, setProgressBar] = useState([
    {
      icon: ExcellentIcon,
      label: t("CROP_YIELD.EXCELLENT"),
      value: "",
      color: "success",
      rating: 5,
    },
    {
      icon: GreatIcon,
      label: t("CROP_YIELD.GOOD"),
      value: "",
      color: "success",
      rating: 4,
    },
    {
      icon: GoodIcon,
      label: t("CROP_YIELD.SATISFACTORY"),
      value: "",
      color: "success",
      rating: 3,
    },
    {
      icon: OkayIcon,
      label: t("CROP_YIELD.UNSATISFACTORY"),
      value: "",
      color: "warning",
      rating: 2,
    },
    {
      icon: BadIcon,
      label: t("CROP_YIELD.POOR"),
      value: "",
      color: "danger",
      rating: 1,
    },
  ]);

  const feedbackRatingSystem = [
    {
      icon: ExcellentIcon,
      label: t("CROP_YIELD.EXCELLENT"),
      rating: 5,
    },
    {
      icon: GreatIcon,
      label: t("CROP_YIELD.GOOD"),
      rating: 4,
    },
    {
      icon: GoodIcon,
      label: t("CROP_YIELD.SATISFACTORY"),
      rating: 3,
    },
    {
      icon: OkayIcon,
      label: t("CROP_YIELD.UNSATISFACTORY"),
      rating: 2,
    },
    {
      icon: BadIcon,
      label: t("CROP_YIELD.POOR"),
      rating: 1,
    },
  ];

  const nextProps = useSelector((state) => ({
    getCrpFeedbackData: state.CropYield?.getCrpFeedbackData,
    isGetFeedbackLoading: state.CropYield?.isGetFeedbackLoading,
  }));

  useEffect(() => {
    dispatch(
      getCropYieldFeedbackRequest({ module_name: "crop_yield_prediction" }),
    );
  }, []);

  const isFeedbackListRef = useRef(true);
  useEffect(() => {
    if (isFeedbackListRef.current) {
      isFeedbackListRef.current = false;
      return;
    }
    if (nextProps.getCrpFeedbackData) {
      let new_list = [];

      if (nextProps.getCrpFeedbackData?.results) {
        const [ratingData, ...restElements] =
          nextProps?.getCrpFeedbackData?.results; // destructring

        if (feedbackList) {
          if (pageNumber > 1) {
            new_list = feedbackList.concat(restElements);
          } else {
            new_list = restElements;
          }
        } else {
          new_list = restElements;
        }
        setFeedbackList(new_list);
        setPageCount(nextProps?.getCrpFeedbackData?.count); // no of count
        setTotalRating(ratingData?.module_rating?.overall_rating); // overall rating
        const updatedProgressBar = progressBar.map((item) => {
          const ratingInfo = ratingData?.module_rating?.ratings_count?.find(
            (rating) => rating.rating === item.rating,
          );
          return ratingInfo ? { ...item, value: ratingInfo.count } : item;
        });

        setProgressBar(updatedProgressBar); // progress bar data
        const counts = ratingData?.module_rating?.ratings_count?.map(
          (item) => item.count,
        );
        const maxCount = Math.max(...counts);
        setMaxRatingCount(maxCount); // maxvalur for progress bar
      } else {
        setFeedbackList([]);
        setPageCount();
      }
    }
  }, [nextProps.getCrpFeedbackData]);

  const renderRatingIcon = (rating) => {
    const ratingIcon = feedbackRatingSystem.filter(
      (review) => review.rating === rating,
    );
    return (
      <>
        <img
          src={ratingIcon[0].icon}
          alt={rating.label}
          height={36}
          width={36}
        />{" "}
        <p className="feedback-comment ml-2">{ratingIcon[0].label}</p>
      </>
    );
  };

  const fetchNextData = () => {
    setPageNumber(pageNumber + 1);
    dispatch(
      getCropYieldFeedbackRequest({
        page: pageNumber + 1,
        module_name: selectedModule?.value,
      }),
    );
  };

  const handleSelectedModule = (e) => {
    setSelectedModule(e);
    setPageNumber(1);
    dispatch(
      getCropYieldFeedbackRequest({
        page: 1,
        module_name: e.value,
      }),
    );
    window.scrollTo(0, 0);
    setFeedbackList([]);
  };

  return (
    <div className="feedback-main-page">
      <div className="d-flex align-items-center justify-content-between">
        <div className="back-office-main-header back-office-center w-50 m-0">
          <img
            src={BackButton}
            alt="back-button"
            onClick={() => history("/settings")}
            onKeyUp={() => {}}
            className="pointer"
            width={64}
            height={64}
          />
          <h2 className="discription-text heading-text-user-guide">
            {t("SETTINGS.MODEL_PERFORMANCE_FEEDBACK")}
          </h2>
        </div>
        <div className="d-flex align-items-center justify-content-end w-50">
          <Label className="m-0 pr-3 select-module-label">
            Select a Module
          </Label>
          <Select
            options={FEEDBACK_MODULES}
            classNamePrefix="react-select"
            className="dash-select common-select-r"
            styles={{
              width: "40%",
              borderRadius: "10px !important",
              border: "1px solid #22AD7A",
            }}
            onChange={(e) => handleSelectedModule(e)}
            value={selectedModule}
          />
        </div>
      </div>

      <Card className="modal-performance-card">
        {feedbackList?.length ? (
          <ModuleComponent
            totalRating={totalRating}
            pageCount={pageCount}
            progressBar={progressBar}
            maxRatingCount={maxRatingCount}
            pageNumber={pageNumber}
            isLoading={nextProps.isGetFeedbackLoading}
            fetchNextData={fetchNextData}
            feedbackList={feedbackList}
            renderRatingIcon={renderRatingIcon}
            selectedModule={selectedModule}
          />
        ) : (
          <center>
            <h2>{t("SETTINGS.NO_FEEDBACK_AVAILABLE")}</h2>
          </center>
        )}
      </Card>
    </div>
  );
}

export default ModalPerformanceFeedback;
