import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import CROP_YIELD_CONST from "./actionTypes";
import {
  cropYieldOptionsSuccess,
  cropYieldOptionsError,
  cropYieldPastDataSuccess,
  cropYieldPastDataError,
  cropYieldSummaryError,
  cropYieldSummarySuccess,
  cropYieldStartEndDateError,
  cropYieldStartEndDateSuccess,
  cropYieldPredectionError,
  cropYieldPredectionSuccess,
  cropYieldCropListDateError,
  cropYieldCropListSuccess,
  cropYieldFeedbackError,
  cropYieldFeedbackSuccess,
  getCropYieldFeedbackSuccess,
  getCropYieldFeedbackError,
  getCropCalenderSuccess,
  getCropCalenderError,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import {
  CROP_YIELD,
  AXIOS_INSTANCE,
  AXIOS_INSTANCE_LOADER,
} from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* cropyieldOptions(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${CROP_YIELD}/options/${action.payload?.country_id}`),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(cropYieldOptionsSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };

      yield put(cropYieldOptionsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };

    yield put(cropYieldOptionsError(errorData));
  }
}

function* cropyieldPastData(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${CROP_YIELD}/past_data?crop_id=${action?.payload?.crop_id}&season_id=${action?.payload?.season_id}&district_id=${action?.payload?.district_id}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(cropYieldPastDataSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(cropYieldPastDataError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(cropYieldPastDataError(errorData));
  }
}

// summary saga
function* cropyieldSummary(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${CROP_YIELD}/get_ind_var_summary/?district_id=${action?.payload?.district_id}&ind_vars=${action?.payload?.ind_vars}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(cropYieldSummarySuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(cropYieldSummaryError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(cropYieldSummaryError(errorData));
  }
}

// for getting start and end dates
function* cropyieldStartEndDate(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  const url = `ind_parameter_id=${action?.payload?.ind_parameter_id}&season_end_month=${action?.payload?.season_end_month}&season_start_month=${action?.payload?.season_start_month}`;
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${CROP_YIELD}/get_parameter_available_dates/?${url}`),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(cropYieldStartEndDateSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(cropYieldStartEndDateError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(cropYieldStartEndDateError(errorData));
  }
}

function* cropyieldPredections(action) {
  AXIOS_INSTANCE_LOADER.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.post(
        `${CROP_YIELD}/crop_yield_prediction/`,
        action.payload,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };

      yield put(cropYieldPredectionSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };

      yield put(cropYieldPredectionError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };

    yield put(cropYieldPredectionError(errorData));
  }
}

// crop lsit in Crop predection
function* cropyieldCropList(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${CROP_YIELD}/crop_list/${action.payload?.country_id}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(cropYieldCropListSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(cropYieldCropListDateError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(cropYieldCropListDateError(errorData));
  }
}

// POST FFEDBACK
function* cropYieldFeedback(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${CROP_YIELD}/feedback`, action.payload),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data,
      };
      yield put(cropYieldFeedbackSuccess(responseData));
    } else {
      const responseData = {
        data: response.data,
      };
      yield put(cropYieldFeedbackError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(cropYieldFeedbackError(errorData));
  }
}

// GET FEEDBACK
function* getCropYieldFeedback(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${CROP_YIELD}/get_feedback${
          action.payload.module_name
            ? `?module_name=${action.payload.module_name}`
            : ""
        }${action.payload.limit ? `&limit=${action.payload.limit}` : ""}${
          action.payload.page ? `&page=${action.payload.page}` : ""
        }`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getCropYieldFeedbackSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getCropYieldFeedbackError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getCropYieldFeedbackError(errorData));
  }
}

// GET FEEDBACK
function* getCropCalender(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${CROP_YIELD}/get_crop_calendar/${action.payload.country_id}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getCropCalenderSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getCropCalenderError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getCropCalenderError(errorData));
  }
}

export function* watchCropyieldOptions() {
  yield takeEvery(
    CROP_YIELD_CONST.CROP_YIELD_OPTIONS_REQUEST,
    cropyieldOptions,
  );
}

export function* watchCropyieldPastData() {
  yield takeEvery(
    CROP_YIELD_CONST.CROP_YIELD_PAST_DATA_REQUEST,
    cropyieldPastData,
  );
}

export function* watchCropyieldSummary() {
  yield takeEvery(
    CROP_YIELD_CONST.CROP_YIELD_SUMMARY_REQUEST,
    cropyieldSummary,
  );
}

export function* watchCropyieldStartEndDate() {
  yield takeEvery(
    CROP_YIELD_CONST.CROP_YIELD_START_END_DATE_REQUEST,
    cropyieldStartEndDate,
  );
}

export function* watchCropyieldPredections() {
  yield takeEvery(
    CROP_YIELD_CONST.CROP_YIELD_PREDECTION_REQUEST,
    cropyieldPredections,
  );
}

export function* watchCropyieldCropList() {
  yield takeEvery(
    CROP_YIELD_CONST.CROP_YIELD_CROP_LIST_REQUEST,
    cropyieldCropList,
  );
}

export function* watchCropYieldFeedback() {
  yield takeEvery(
    CROP_YIELD_CONST.CROP_YEILD_FEEDBACK_REQUEST,
    cropYieldFeedback,
  );
}
export function* watchGetCropYieldFeedback() {
  yield takeEvery(
    CROP_YIELD_CONST.GET_CROP_YEILD_FEEDBACK_REQUEST,
    getCropYieldFeedback,
  );
}

export function* watchGetCropCalender() {
  yield takeEvery(CROP_YIELD_CONST.GET_CROP_CALENDER_REQUEST, getCropCalender);
}

function* CropYieldSaga() {
  yield all([
    fork(watchCropyieldOptions),
    fork(watchCropyieldPastData),
    fork(watchCropyieldSummary),
    fork(watchCropyieldStartEndDate),
    fork(watchCropyieldPredections),
    fork(watchCropyieldCropList),
    fork(watchCropYieldFeedback),
    fork(watchGetCropYieldFeedback),
    fork(watchGetCropCalender),
  ]);
}

export default CropYieldSaga;
