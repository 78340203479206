import React from "react";
import { ErrorMessage } from "formik";
import { Input, Label } from "reactstrap";
import Select from "react-select";
import uploadImage from "../../assest/img/upload-image.svg";

export function formickTextField({
  placeholder,
  max,
  field,
  type,
  disabled,
  classNameActive,
  classNameInactive,
  lable,
  onChangeField,
  form,
  maxLength,
}) {
  return (
    <div className="common-select-input">
      {lable && field?.value && <Label className="label">{lable}</Label>}
      <Input
        placeholder={placeholder}
        max={max}
        type={type}
        name={field?.name}
        value={field?.value}
        maxLength={maxLength}
        {...field}
        disabled={disabled}
        onChange={(e) => {
          form.setFieldValue(field?.name, e?.target?.value);
          onChangeField(e);
        }}
        className={field?.value ? classNameActive : classNameInactive}
      />
    </div>
  );
}

export function formickTextAreaField({
  placeholder,
  max,
  field,
  type,
  disabled,
  classNameActive,
  classNameInactive,
  lable,
  onChangeField,
  form,
}) {
  return (
    <div className="common-select-textarea-input">
      {lable && field?.value && <Label className="label">{lable}</Label>}
      <Input
        placeholder={placeholder}
        max={max}
        type={type}
        name={field?.name}
        value={field?.value}
        {...field}
        disabled={disabled}
        onChange={(e) => {
          form.setFieldValue(field?.name, e?.target?.value);
          onChangeField(e);
        }}
        className={field?.value ? classNameActive : classNameInactive}
      />
    </div>
  );
}

export function formickCheckboxField({
  placeholder,
  max,
  field,
  type,
  disabled,
  className,
  lable,
  checked,
  form,
}) {
  return (
    <div className="common-select-input data-source-checkbox">
      <Input
        placeholder={placeholder}
        max={max}
        type={type}
        name={field.name}
        value={field.value}
        {...field}
        disabled={disabled}
        checked={checked}
        onChange={(e) => {
          form.setFieldValue(field.name, e.target.checked);
        }}
        className={className}
      />
      {lable && <Label className="">{lable}</Label>}
    </div>
  );
}

export function formickPasswordField({
  placeholder,
  max,
  field,
  icon,
  type,
  disabled,
  classNameActive,
  classNameInactive,
  lable,
}) {
  return (
    <div className="common-select-input">
      {lable && field.value && <Label className="label">{lable}</Label>}
      <div className="password-field">
        <Input
          placeholder={placeholder}
          max={max}
          type={type}
          name={field.name}
          {...field}
          disabled={disabled}
          className={field.value ? classNameActive : classNameInactive}
        />
        <div className="password-icon-field">{icon}</div>
      </div>
    </div>
  );
}

export function SelectFiled({
  value,

  placeholder,
  options,
  className,
  label,
}) {
  return (
    <div className="common-select-input w-100">
      <Label className="label">{label}</Label>
      <Input
        type="select"
        value={value}
        placeholder={placeholder}
        className={className}
      >
        {placeholder && (
          <option value="" hidden className="option-select" key={placeholder}>
            {placeholder}
          </option>
        )}
        {options?.map((eachOption) => (
          <option
            value={eachOption.value}
            className="option-select"
            key={eachOption.value}
          >
            {eachOption.label}
          </option>
        ))}
      </Input>
    </div>
  );
}

export function formickSelectField({
  field,
  options,
  placeholder,
  type,
  disabled,
  label,
  classNameActive,
  classNameInactive,
}) {
  return (
    <div className="common-select-input">
      {label && field.value && <Label className="label">{label}</Label>}
      <Input
        name={field.name}
        type={type}
        disabled={disabled}
        {...field}
        className={field.value ? classNameActive : classNameInactive}
      >
        <option value="0" selected hidden>
          {placeholder}
        </option>
        {options}
      </Input>
      <ErrorMessage name={field.name} />
    </div>
  );
}

export function ReactSelectFiled({
  value,
  placeholder,
  options,
  className,
  onSelectOption,
  label,
  currentValue,
  disabled,
}) {
  return (
    <div>
      {label && <Label className="label">{label}</Label>}
      <Select
        classNamePrefix="react-select"
        defaultValue={value}
        value={currentValue}
        options={options}
        className={className}
        placeholder={placeholder}
        isSearchable={false}
        onChange={(e) => onSelectOption(e)}
        isDisabled={disabled}
      />
    </div>
  );
}

export const renderMultiSelectField = ({
  placeholder,
  id,
  field,
  className,
  options,
}) => {
  return (
    <div className=" common-select-sm ml-3">
      <Label className="label">lable </Label>
      <Select
        classNamePrefix="react-select"
        id={id}
        name={field.name}
        options={options}
        className={className}
        placeholder={placeholder}
      />
    </div>
  );
};

export function formickReactSelectFiled({
  options,
  className,
  label,
  placeholder,
  field,
  form,
  selectProvience,
  disabled,
}) {
  return (
    <div
      className={
        field.value || field.value?.value !== ""
          ? "common-select-register"
          : "common-select-dropdown"
      }
    >
      {field.value && field.value.value !== "" && (
        <Label className="label">{label}</Label>
      )}
      <Select
        classNamePrefix="react-select"
        name={field.name}
        {...field}
        options={options}
        className={className}
        placeholder={placeholder}
        isDisabled={disabled}
        onBlur={() => form.setFieldTouched(field.name, true)}
        onChange={(e) => {
          selectProvience(e);
          form.setFieldValue(field.name, e);
        }}
        isSearchable={false}
      />
    </div>
  );
}

export function formickReactSelectionFiled({
  options,
  className,
  placeholder,
  field,
  form,
  disabled,
  onChange,
  value,
}) {
  return (
    <div className="common-select-register">
      <Select
        classNamePrefix="react-select"
        name={field.name}
        {...field}
        options={options}
        className={className}
        value={value === null ? null : field.value}
        isDisabled={disabled}
        placeholder={placeholder}
        onBlur={() => form.setFieldTouched(field.name, true)}
        onChange={(e) => {
          onChange(e);
          form.setFieldValue(field.name, e);
        }}
        isSearchable={false}
      />
    </div>
  );
}

export function formickTextingField({
  placeholder,
  max,
  field,
  type,
  disabled,
  className,
  lable,
}) {
  return (
    <div className="common-select-input">
      {lable && field.value && <Label className="label">{lable}</Label>}
      <Input
        placeholder={placeholder}
        max={max}
        type={type}
        name={field.name}
        value={field.value}
        {...field}
        disabled={disabled}
        className={className}
      />
    </div>
  );
}

export const renderImageField = ({
  field,
  placeholder,
  className,
  accept,
  controlledValue,
  readOnly,
  autoFocus,
  type,
  id,
  form,
  onChangeField,
}) => {
  return (
    <>
      <span className="upload-image">
        <Label
          htmlFor={id}
          className="upload-image-label-1 form-control file-upload"
        >
          {placeholder}
          <img src={uploadImage} alt="not-available" className="upload-icon" />
        </Label>
      </span>
      <Input
        {...field}
        placeholder={placeholder}
        className={className}
        value={controlledValue}
        type={type}
        accept={accept}
        autoFocus={autoFocus}
        readOnly={readOnly}
        id={id}
        onChange={(e) => {
          form.setFieldValue(field.name, e);
          onChangeField(e);
        }}
        style={{ display: "none" }}
      />
    </>
  );
};

export function renderTextField({
  placeholder,
  max,
  field,
  type,
  disabled,
  classNameActive,
  classNameInactive,
  lable,
}) {
  return (
    <div className="common-select-input">
      {lable && field?.value && <Label className="label">{lable}</Label>}
      <Input
        placeholder={placeholder}
        max={max}
        type={type}
        name={field?.name}
        value={field?.value}
        {...field}
        disabled={disabled}
        className={field?.value ? classNameActive : classNameInactive}
      />
    </div>
  );
}
