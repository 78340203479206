import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import i18n from "../../i18n";
// Login Redux States
import EARLY_ACTIONS_CONST from "./actionTypes";
import {
  getAllRiskTypeSuccess,
  getAllRiskTypeError,
  createProjectSuccess,
  createProjectError,
  getAllProjectListSuccess,
  getAllProjectListError,
  deleteprojectError,
  deleteprojectSuccess,
  getPreparednessPhaseSuccess,
  getPreparednessPhaseError,
  editprojectError,
  editprojectSuccess,
  getPreparednessPhaseSuggestionsSuccess,
  getPreparednessPhaseSuggestionsError,
  projectStatusError,
  projectStatusSuccess,
  currencyListingSuccess,
  currencyListingError,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import {
  EARLY_ACTION_API,
  AXIOS_INSTANCE,
  AXIOS_INSTANCE_LOADER,
} from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* earlyActionRiskTypes() {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.get(`${EARLY_ACTION_API}/risk_type`),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getAllRiskTypeSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getAllRiskTypeError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllRiskTypeError(errorData));
  }
}

function* createNewProject(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${EARLY_ACTION_API}/project/create`, action.payload),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(createProjectSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(createProjectError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(createProjectError(errorData));
  }
}

// project lsut saga
function* getAllProjectsListing(action) {
  const data = action?.payload?.payload?.payLoad;
  const isUpdate = action?.payload?.payload?.isUpdate;
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${EARLY_ACTION_API}/project/list?project_name=${
          data?.searchProject
        }&location_type_id=${
          data?.location_type_id ? data?.location_type_id : ""
        }&location_type=${
          data?.location_type ? data?.location_type : ""
        }&risk_type_id=${data?.risk_type_id ? data?.risk_type_id : ""}&year=${
          data?.year ? data?.year : ""
        }&month=${data?.month ? data?.month : ""}&page=${data?.page}&limit=${
          data.limit
        }&is_early_finance=${
          data?.is_early_finance ? data?.is_early_finance : ""
        }`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
        isUpdate,
      };
      yield put(getAllProjectListSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getAllProjectListError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllProjectListError(errorData));
  }
}

function* deleteProject(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.delete(
        `${EARLY_ACTION_API}/project/delete/${action?.payload?.project_id}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(deleteprojectSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(deleteprojectError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteprojectError(errorData));
  }
}

function* earlyActionPreparednessPhase(data) {
  const payload = {
    is_default: data?.payload?.is_default === "false" ? 0 : 1,
    project_id: data?.payload?.project_id,
    instance_slug: data?.payload?.slug,
  };
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${EARLY_ACTION_API}/pp/get_instance/`, {
        params: payload,
      }),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
        isUpdateAll: data?.payload?.isUpdateAll,
      };
      yield put(getPreparednessPhaseSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getPreparednessPhaseError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getPreparednessPhaseError(errorData));
  }
}

function* earlyActionPreparednessPhaseSuggestions(data) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${EARLY_ACTION_API}/pp/get_instance_suggestions/${data?.payload?.slug}`,
      ),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getPreparednessPhaseSuggestionsSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getPreparednessPhaseSuggestionsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getPreparednessPhaseError(errorData));
  }
}

function* editProject(action) {
  const { t } = i18n;
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.put(
        `${EARLY_ACTION_API}/project/edit`,
        action.payload?.payload,
      ),
      action.payload?.show ? `${t("EARLY_ACTIONS.UPDATE_SUCESSFUL")}` : "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(editprojectSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(editprojectError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(editprojectError(errorData));
  }
}

function* projectStatus(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.put(
        `${EARLY_ACTION_API}/project/update/status`,
        action.payload,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(projectStatusSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(projectStatusError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(projectStatusError(errorData));
  }
}

// currency listing
function* currencyListing() {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${EARLY_ACTION_API}/get_currency_list`),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(currencyListingSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(currencyListingError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(currencyListingError(errorData));
  }
}

export function* watchEarlyActionRiskTypes() {
  yield takeEvery(
    EARLY_ACTIONS_CONST.EARLY_ACTION_RISK_TYPE_REQUEST,
    earlyActionRiskTypes,
  );
  yield takeEvery(
    EARLY_ACTIONS_CONST.EARLY_ACTION_CREATE_PROJECT_REQUEST,
    createNewProject,
  );

  yield takeEvery(
    EARLY_ACTIONS_CONST.EARLY_ACTION_PROJECT_LIST_REQUEST,
    getAllProjectsListing,
  );

  yield takeEvery(
    EARLY_ACTIONS_CONST.EARLY_ACTION_DELETE_PROJECT_REQUEST,
    deleteProject,
  );

  yield takeEvery(
    EARLY_ACTIONS_CONST.EARLY_ACTION_PREPAREDNESS_PHASE_REQUEST,
    earlyActionPreparednessPhase,
  );

  yield takeEvery(
    EARLY_ACTIONS_CONST.EARLY_ACTION_PREPAREDNESS_PHASE_SUGGESTIONS_REQUEST,
    earlyActionPreparednessPhaseSuggestions,
  );

  yield takeEvery(
    EARLY_ACTIONS_CONST.EARLY_ACTION_EDIT_PROJECT_REQUEST,
    editProject,
  );
  yield takeEvery(
    EARLY_ACTIONS_CONST.EARLY_ACTION_PROJECT_STATUS_REQUEST,
    projectStatus,
  );
  yield takeEvery(
    EARLY_ACTIONS_CONST.CURRENCY_LISTING_REQUEST,
    currencyListing,
  );
}

function* EarlyActionProjects() {
  yield all([fork(watchEarlyActionRiskTypes)]);
}

export default EarlyActionProjects;
