import WEATHER_CONST from "./actionTypes";

const Weather = (state, action) => {
  if (typeof state === "undefined") {
    state = {
      isWeatherRequesting: true,
      weatherData: false,
      weatherDataError: false,
      weatherDataNetworkError: false,
      noaaTimeSeriesData: null,
      erpasTimeSeriesData: null,
      error: "",
      loading: false,
      isStaticResourceRequesting: true,
      staticResourceData: false,
      staticResourceDataError: false,
      staticResourceDataNetworkError: false,
    };
  }
  switch (action.type) {
    case WEATHER_CONST.GET_FORECAST_DATA_SOURCES_REQUEST:
      return {
        ...state,
        isWeatherForecastSourcesRequesting: true,
        weatherForecastSourcesData: false,
        weatherForecastSourcesDataError: false,
        weatherForecastSourcesDataNetworkError: false,
      };
    case WEATHER_CONST.GET_FORECAST_DATA_SOURCES_SUCCESS:
      return {
        ...state,
        isWeatherForecastSourcesRequesting: false,
        weatherForecastSourcesData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        // weatherForecastSourcesData: action.payload.response,
        weatherForecastSourcesDataError: false,
        weatherForecastSourcesDataNetworkError: false,
      };
    case WEATHER_CONST.GET_FORECAST_DATA_SOURCES_FAILED:
      return {
        ...state,
        isWeatherForecastSourcesRequesting: false,
        weatherForecastSourcesData: false,
        weatherForecastSourcesDataError: action.payload.error
          ? false
          : action.payload.response,
        weatherForecastSourcesDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
    case WEATHER_CONST.IRI_AVAILABLE_DATES_REQUEST:
      return {
        ...state,
        iriAvailableDatesDataRequesting: true,
        iriAvailableDatesData: false,
        iriAvailableDatesError: false,
      };
    case WEATHER_CONST.IRI_AVAILABLE_DATES_SUCCESS:
      return {
        ...state,
        iriAvailableDatesDataRequesting: false,
        iriAvailableDatesData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        // iriAvailableDatesData: action.payload.response,
        iriAvailableDatesError: false,
      };
    case WEATHER_CONST.IRI_AVAILABLE_DATES_FAILED:
      return {
        ...state,
        iriAvailableDatesDataRequesting: false,
        iriAvailableDatesData: false,
        iriAvailableDatesError: action.payload.error
          ? false
          : action.payload.response,
      };
    case WEATHER_CONST.GET_FORECAST_DATA_REQUEST:
      return {
        ...state,
        isWeatherForecastRequesting: true,
        weatherForecastData: false,
        weatherForecastDataError: false,
        weatherForecastDataNetworkError: false,
      };
    case WEATHER_CONST.GET_FORECAST_DATA_SUCCESS:
      return {
        ...state,
        isWeatherForecastRequesting: false,
        weatherForecastData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        // weatherForecastData: action.payload.response,
        weatherForecastDataError: false,
        weatherForecastDataNetworkError: false,
      };
    case WEATHER_CONST.GET_FORECAST_DATA_FAILED:
      return {
        ...state,
        isWeatherForecastRequesting: false,
        weatherForecastData: false,
        weatherForecastDataError: action.payload.error
          ? false
          : action.payload.response,
        weatherForecastDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
    case WEATHER_CONST.ADD_TIMESERIES_REQUEST:
      return {
        ...state,
        isWeatherForecastGraphRequesting: true,
        weatherForecastGraphData: false,
        weatherForecastGraphDataError: false,
        weatherForecastGraphDataNetworkError: false,
      };
    case WEATHER_CONST.ADD_TIMESERIES_SUCCESS:
      return {
        ...state,
        isWeatherForecastGraphRequesting: false,
        weatherForecastGraphData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        // weatherForecastSourcesData: action.payload.response,
        weatherForecastGraphDataError: false,
        weatherForecastGraphDataNetworkError: false,
      };
    case WEATHER_CONST.ADD_TIMESERIES_ERROR:
      return {
        ...state,
        isWeatherForecastGraphRequesting: false,
        weatherForecastGraphData: false,
        weatherForecastGraphDataError: action.payload.error
          ? false
          : action.payload.response,
        weatherForecastGraphDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

    case WEATHER_CONST.GET_STATIC_RESOURCE_DATA_REQUEST:
      return {
        ...state,
        isStaticResourceRequesting: true,
        staticResourceData: false,
        staticResourceDataError: false,
        staticResourceDataNetworkError: false,
      };
    case WEATHER_CONST.GET_STATIC_RESOURCE_DATA_SUCCESS:
      return {
        ...state,
        isStaticResourceRequesting: false,
        staticResourceData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        // StaticResourceData: action.payload.response,
        staticResourceDataError: false,
        staticResourceDataNetworkError: false,
      };
    case WEATHER_CONST.GET_STATIC_RESOURCE_DATA_FAILED:
      return {
        ...state,
        isStaticResourceRequesting: false,
        staticResourceData: false,
        staticResourceDataError: action.payload.error
          ? false
          : action.payload.response,
        staticResourceDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

    case WEATHER_CONST.GET_IRI_IMAGE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        ecmwfImaheLoading: true,
        staticResourceDataControls: false,
        staticResourceDataControlsError: false,
      };
    case WEATHER_CONST.GET_IRI_IMAGE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        ecmwfImaheLoading: false,
        staticResourceDataControls:
          action.payload.statusCode === 200 ? action.payload.data : false,
        // StaticResourceData: action.payload.response,
        staticResourceDataControlsError: false,
        // staticResourceDataNetworkError: false,
      };
    case WEATHER_CONST.GET_IRI_IMAGE_DATA_FAILED:
      return {
        ...state,
        loading: false,
        staticResourceDataControls: false,
        ecmwfImaheLoading: false,
        staticResourceDataControlsError: action.payload.error
          ? false
          : action.payload.response,
      };

    default:
      return state;
  }
};

export default Weather;
