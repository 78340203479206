import { takeEvery, fork, all, call, put } from "redux-saga/effects";

// Login Redux States
import CLIMBER_CONST from "./actionTypes";
import {
  otherToolsSuccess,
  otherToolsError,
  focusAreasSuccess,
  focusAreasError,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import { CLIMBER_BANNER_API, AXIOS_INSTANCE } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* otherTools(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${CLIMBER_BANNER_API}/tools/${action.payload?.order}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };

      yield put(otherToolsSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(otherToolsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(otherToolsError(errorData));
  }
}

function* focusAreas() {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${CLIMBER_BANNER_API}/focus-areas/ASC`),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };

      yield put(focusAreasSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(focusAreasError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(focusAreasError(errorData));
  }
}

export function* watchOtherTools() {
  yield takeEvery(CLIMBER_CONST.OTHER_TOOLS_REQUEST, otherTools);
  yield takeEvery(CLIMBER_CONST.FOCUS_AREAS_REQUEST, focusAreas);
}

function* OtherToolsSaga() {
  yield all([fork(watchOtherTools)]);
}

export default OtherToolsSaga;
