import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { BiPlus } from "react-icons/bi";
import { TiTick } from "react-icons/ti";
import { MdKeyboardArrowLeft } from "react-icons/md";
import DOMPurify from "dompurify";
import { GrEdit } from "react-icons/gr";
import { FaTrashAlt, FaTimes } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import Loader from "../../components/Common/Loader";
import { DISPLAY_DATE_FORMAT } from "../../constants";
import {
  htmlToText,
  isPermissionsMatch,
  superAdmin,
} from "../../components/Common/Utils";
import BackButton from "../../assest/img/back-button.svg";
import { searchByCriteriaManageUserGuideRequest } from "../../store/actions";

function ManageUserGuideContent({
  userList,
  isHide,
  setIsHide,
  onDeleteUserGuide,
  loading,
  pageCount,
  pageNumber,
  onPublishUserGuide,
  setPageNumber,
  selectedCatrgory,
  subList,
  permissionsList,
}) {
  const history = useNavigate();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onPublishUserGuideContent = (data) => {
    onPublishUserGuide(data);
  };

  const fetchNextUserGuideData = () => {
    setPageNumber(pageNumber + 1);
    dispatch(
      searchByCriteriaManageUserGuideRequest({
        page: pageNumber + 1,
        fromBackOffice: true,
        data: {
          topic_id: selectedCatrgory?.value,
          subtopic_id: subList?.value,
          is_delete: "False",
          is_backoffice: "True",
        },
      }),
    );
  };

  return (
    <>
      {loading && <Loader isSuspense indicator />}
      {isHide && (
        <Row className="">
          <Col sm={12} className="p-0">
            <div
              onClick={() => setIsHide(!isHide)}
              onKeyUp={() => {}}
              role="button"
              tabIndex="0"
              className="hide-bar-expand mt-3 user-guide-icon expand-width"
            >
              <p className="mr-2"> {t("MANAGE_USER.EXPAND_BAR")} </p>
              <MdKeyboardArrowLeft
                className="hide-icon expand-margin"
                size={25}
              />
            </div>
          </Col>
        </Row>
      )}
      <Row className="">
        <Col sm={4} className="userguide-verticalcenter">
          <div>
            <img
              src={BackButton}
              alt="back-button"
              onClick={() => history("/settings")}
              onKeyUp={() => {}}
              className="pointer"
            />
          </div>
          <h5> {t("MANAGE_USER.MANAGE_USER_GUIDE")} </h5>
        </Col>
        {isPermissionsMatch(permissionsList, t("PERMISSIONS.ADD_USER_GUIDE")) ||
        superAdmin() ? (
          <Col
            sm={8}
            className="user-flex-end pointer"
            onClick={() => history("/manageUserGuide-form")}
          >
            <BiPlus size={30} color="#66C6A3" className="news-feed-add-style" />
            <h5 className="mb-0"> {t("MANAGE_USER.ADD_USER_GUIDE")}</h5>
          </Col>
        ) : (
          ""
        )}
      </Row>

      <div className="filter-container-2">
        <InfiniteScroll
          dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ""}
          next={fetchNextUserGuideData}
          hasMore={pageNumber * 10 < pageCount}
        >
          {userList?.length
            ? userList.map((i) => {
                const link = `/user-guide-details?topicId=${i.topic_id}&sub-topic-name=${i.subtopic_name}`;

                const safeHtml =
                  htmlToText(i.content)?.length > 120
                    ? DOMPurify.sanitize(i.content.substring(0, 200))
                    : DOMPurify.sanitize(i.content);
                return (
                  <div className="user-card" key={i?.title}>
                    <div>
                      <h5 className="user-card-heading">{i?.title}</h5>

                      {htmlToText(i.content)?.length > 120 ? (
                        <div id="card-description">
                          <div className="mt-2">
                            <div
                              className="user-guide-search-content  user-guide-card-content"
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{ __html: safeHtml }}
                            />
                          </div>
                          <div className="mt-2">
                            <span> ... </span>
                            <a
                              href={link}
                              className="read-more"
                              // onClick={() => handleReadMore(i)}
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="user-guide-search-content  user-guide-card-content"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: safeHtml }}
                        />
                      )}

                      <p className="mt-2">
                        {t("MANAGE_USER.CATEGORY")}: {i?.topic_name}
                      </p>

                      {i?.topic_category_name ? (
                        <p>
                          {t("MANAGE_USER.SUB_CATEGORY")} :{" "}
                          {i?.topic_category_name ? i?.topic_category_name : ""}
                        </p>
                      ) : (
                        ""
                      )}
                      <p>
                        {t("MANAGE_USER.TITLE")}:{" "}
                        {i?.subtopic_name
                          ? i?.subtopic_name
                          : t("MANAGE_USER.NOT_AVAILABLE")}
                      </p>
                      <p>
                        {t("MANAGE_USER.UPDATED_DATE")}:{" "}
                        {moment(i?.updated_date).format(DISPLAY_DATE_FORMAT)}
                      </p>
                      <div className="user-guide-icon">
                        {isPermissionsMatch(
                          permissionsList,
                          t("PERMISSIONS.CHANGE_GUIDE_STATUS"),
                          // "change_status_user_guide",
                        ) || superAdmin() ? (
                          <div>
                            {i?.status ? (
                              <TiTick
                                size={30}
                                id={i.id}
                                className="icon-guide cursor-pointer"
                                data-tip
                                data-for={i.id}
                                data-event="mouseenter"
                                data-event-off="mouseleave"
                                onClick={() => onPublishUserGuideContent(i)}
                              />
                            ) : (
                              <FaTimes
                                size={25}
                                id={i.id}
                                className="icon-guide cursor-pointer"
                                data-tip
                                data-for="unpublish-icon"
                                data-event="mouseenter"
                                data-event-off="mouseleave"
                                onClick={() => onPublishUserGuideContent(i)}
                              />
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        {isPermissionsMatch(
                          permissionsList,
                          t("PERMISSIONS.CHANGE_USER_GUIDE"),
                        ) || superAdmin() ? (
                          <GrEdit
                            className="edit-btn icon-guide"
                            data-tip
                            data-for="edit-btn"
                            onClick={() =>
                              history(
                                `/manageUserGuide-form?unique=${i.id}&&category=${i.topic_id}&&topic-name=${i?.topic_name}&&sub-category=${i.subtopic_id}&&subtopic_name=${i?.subtopic_name}`,
                              )
                            }
                          />
                        ) : (
                          ""
                        )}

                        {isPermissionsMatch(
                          permissionsList,
                          t("PERMISSIONS.DELETE_USER_GUIDE"),
                        ) || superAdmin() ? (
                          <FaTrashAlt
                            className="delete-btn icon-guide"
                            data-tip
                            data-for="delete"
                            data-event="mouseenter"
                            data-event-off="mouseleave"
                            onClick={() => onDeleteUserGuide(i)}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <ReactTooltip id={i.id} effect="solid" place="top">
                        {t("MANAGE_USER.PUBLISH")}
                      </ReactTooltip>
                      <ReactTooltip
                        id="unpublish-icon"
                        effect="solid"
                        place="top"
                      >
                        {t("MANAGE_USER.UN_PUBLISH_TOOLTIP")}
                      </ReactTooltip>
                      <ReactTooltip id="edit-btn" effect="solid" place="top">
                        {t("MANAGE_USER.EDIT")}
                      </ReactTooltip>

                      <ReactTooltip id="delete" place="top" effect="solid">
                        {t("MANAGE_USER.DELETE")}
                      </ReactTooltip>
                    </div>
                  </div>
                );
              })
            : loading === false && (
                <div className="userguide-aligncenter w-100">
                  <h1 className="text-center">
                    {" "}
                    {t("MANAGE_USER.NO_RECORED_FOUND")}{" "}
                  </h1>
                </div>
              )}
        </InfiniteScroll>
      </div>
    </>
  );
}

export default ManageUserGuideContent;
