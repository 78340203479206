import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { showError } from "./Notification";
import { ReactComponent as UploadIcon } from "../../assest/img/ea-upload.svg";

function Basic({
  onHandleUploadImage,
  isMedia,
  isBasic,
  acceptList,
  lable,
  isUpload,
  isFeedback,
  isUploadDocs,
}) {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState("");
  const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB (you can adjust this value)
  const max1MbSizeInBytes = 1 * 1024 * 1024; // 5 MB (you can adjust this value)
  const onDrop = (acceptedFiles) => {
    if (isMedia) {
      // For each accepted file, convert it to Base64 and get the file name

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result;
          onHandleUploadImage(file, base64String);
        };
        reader.readAsDataURL(file);
      });
    } else if (isUpload) {
      acceptedFiles.forEach((file) => {
        if (file.size <= maxSizeInBytes) {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result;
            onHandleUploadImage(file, base64String);
          };
          reader.readAsDataURL(file);
        } else {
          showError(`${file.name} size is more than 5 mb`);
        }
      });
    } else if (isFeedback) {
      acceptedFiles.forEach((file) => {
        if (file.size <= max1MbSizeInBytes) {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result;
            onHandleUploadImage(file, base64String);
          };
          reader.readAsDataURL(file);
        } else {
          showError(`${file.name} size is more than 1 mb`);
        }
      });
    } else {
      const file = acceptedFiles[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          // reader.result contains the Base64-encoded representation of the file
          // base64String = reader.result;
          onHandleUploadImage(file, reader.result);
          // setBase64Image(base64String);
          setFileName(file.name);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptList,

    multiple: isFeedback || !!isMedia || isUpload,
    onDrop,
  });

  return (
    <>
      {isUploadDocs ? (
        <section className="up-docx-dropzone   mb-3">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} className="cursor-pointer" />
            <div className="text-center">
              <p className="feedback-upload-label">
                <UploadIcon />
              </p>

              <p className="up-upload-text">
                Drag your files here or
                <span className="ml-1 up-browser">browse files</span>
              </p>
            </div>
          </div>
        </section>
      ) : !isFeedback ? (
        <section className="container-dropzone mb-3">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} className="cursor-pointer" />
            <p className="text-center">
              {lable ||
                (isMedia
                  ? t("EARLY_FINANCE_REPORT.DRAG_AND_DROP_TEXT")
                  : isBasic
                  ? t("EARLY_FINANCE_REPORT.ADD_IMAGE_TEXT")
                  : t("EARLY_ACTIONS.NUMBER_OF_PEOPLE"))}
            </p>
          </div>
        </section>
      ) : (
        <section className="feedback-dropzone-container  mb-3">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} className="cursor-pointer" />
            <div className="text-center">
              <p className="feedback-upload-label">
                <UploadIcon /> Upload file{" "}
              </p>
              <p className="feedback-upload-text">
                Max file size: <span>1 MB</span>
              </p>
              <p className="feedback-upload-text">
                Supported file types:{" "}
                <span>DOCX, PDF, XLSX, CSV, JPG, PNG</span>
              </p>
            </div>
          </div>
        </section>
      )}

      {fileName && <p> {fileName}</p>}
    </>
  );
}

export default Basic;
