const ODK_CONST = {
  FIELD_DATA_LIST_REQUEST: "FIELD_DATA_LIST_REQUEST",
  FIELD_DATA_LIST_SUCCESS: "FIELD_DATA_LIST_SUCCESS",
  FIELD_DATA_LIST_FAILED: "FIELD_DATA_LIST_FAILED",

  FIELD_DATA_BY_ID_REQUEST: "FIELD_DATA_BY_ID_REQUEST",
  FIELD_DATA_BY_ID_SUCCESS: "FIELD_DATA_BY_ID_SUCCESS",
  FIELD_DATA_BY_ID_FAILED: "FIELD_DATA_BY_ID_FAILED",

  SUBMIT_CONFIGURATION_REQUEST: "SUBMIT_CONFIGURATION_REQUEST",
  SUBMIT_CONFIGURATION_SUCCESS: "SUBMIT_CONFIGURATION_SUCCESS",
  SUBMIT_CONFIGURATION_FAILED: "SUBMIT_CONFIGURATION_FAILED",

  INVITE_CONFIGURATION_REQUEST: "INVITE_CONFIGURATION_REQUEST",
  INVITE_CONFIGURATION_SUCCESS: "INVITE_CONFIGURATION_SUCCESS",
  INVITE_CONFIGURATION_FAILED: "INVITE_CONFIGURATION_FAILED",

  COMPONENTS_LIST_REQUEST: "COMPONENTS_LIST_REQUEST",
  COMPONENTS_LIST_SUCCESS: "COMPONENTS_LIST_SUCCESS",
  COMPONENTS_LIST_FAILED: "COMPONENTS_LIST_FAILED",

  COMPONENT_BY_ID_REQUEST: "COMPONENT_BY_ID_REQUEST",
  COMPONENT_BY_ID_SUCCESS: "COMPONENT_BY_ID_SUCCESS",
  COMPONENT_BY_ID_FAILED: "COMPONENT_BY_ID_FAILED",

  MANAGE_SURVEYORS_LIST_REQUEST: "MANAGE_SURVEYORS_LIST_REQUEST",
  MANAGE_SURVEYORS_LIST_SUCCESS: "MANAGE_SURVEYORS_LIST_SUCCESS",
  MANAGE_SURVEYORS_LIST_FAILED: "MANAGE_SURVEYORS_LIST_FAILED",

  INSURANCE_COMPANY_LIST_REQUEST: "INSURANCE_COMPANY_LIST_REQUEST",
  INSURANCE_COMPANY_LIST_SUCCESS: "INSURANCE_COMPANY_LIST_SUCCESS",
  INSURANCE_COMPANY_LIST_FAILED: "INSURANCE_COMPANY_LIST_FAILED",

  DELETE_SURVEYORS_REQUEST: "DELETE_SURVEYORS_REQUEST",
  DELETE_SURVEYORS_SUCCESS: "DELETE_SURVEYORS_SUCCESS",
  DELETE_SURVEYORS_FAILED: "DELETE_SURVEYORS_FAILED",

  SURVEY_CONFIGURATION_REQUEST: "SURVEY_CONFIGURATION_REQUEST",
  SURVEY_CONFIGURATION_SUCCESS: "SURVEY_CONFIGURATION_SUCCESS",
  SURVEY_CONFIGURATION_FAILED: "SURVEY_CONFIGURATION_FAILED",
};

export default ODK_CONST;
