import EARLY_WARNING_POPULATION_DISPLACEMENT_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};
const PopulationDisplacement = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_TIMESERIES_REQUEST:
      state = {
        ...state,
        populationDisplacementTimeSeriesloading: true,
        populationDisplacementTimeseriesData: null,
        populationDisplacementTimeSeriesError: null,
      };
      break;
    case EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_TIMESERIES_SUCCESS:
      state = {
        ...state,
        populationDisplacementTimeSeriesloading: false,
        populationDisplacementTimeseriesData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        populationDisplacementTimeSeriesError: null,
      };
      break;
    case EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_TIMESERIES_ERROR:
      state = {
        ...state,
        populationDisplacementTimeSeriesError: action.payload.data,
        populationDisplacementTimeSeriesloading: false,
        populationDisplacementTimeseriesData: null,
      };
      break;

    // map data request
    case EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_MAP_DATA_REQUEST:
      state = {
        ...state,
        ispopulationDisplacementMapDataRequesting: true,
        populationDisplacementMapData: false,
        populationDisplacementMapDataError: false,
      };
      break;
    case EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_MAP_DATA_SUCCESS:
      state = {
        ...state,
        ispopulationDisplacementMapDataRequesting: false,
        populationDisplacementMapData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        populationDisplacementMapDataError: false,
      };
      break;
    case EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_MAP_DATA_FAILED:
      state = {
        ...state,
        ispopulationDisplacementMapDataRequesting: false,
        populationDisplacementMapData: false,
        populationDisplacementMapDataError: action.payload.data,
      };
      break;

    // Hazard data request
    case EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_HAZARD_REQUEST:
      state = {
        ...state,
        populationDisplacementHazardData: false,
        populationDisplacementHazardDataError: false,
      };
      break;
    case EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_HAZARD_SUCCESS:
      state = {
        ...state,
        populationDisplacementHazardData:
          action.payload.statusCode === 200 ? action.payload.data : [],
        populationDisplacementHazardDataError: false,
      };
      break;
    case EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_HAZARD_ERROR:
      state = {
        ...state,
        populationDisplacementHazardData: false,
        populationDisplacementHazardDataError: action.payload.data,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};
export default PopulationDisplacement;
