import { toast } from "react-toastify";
import _ from "lodash";
import "react-toastify/dist/ReactToastify.css";
import { htmlToText as text1 } from "html-to-text";
import moment from "moment";
import { Label, Input } from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import chroma from "chroma-js";
import Cookies from "js-cookie";
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import i18n from "../i18n";
import {
  DATA_SOURCES,
  DATE_FORMAT,
  DISPLAY_DATE_FORMAT,
  MonthsArray,
  ExtremeRainfall,
  DrysPell,
} from "../constants";
import Drought from "../assest/img/DroughtUserGuide.png";
import Flood from "../assest/img/FloodUserguide.png";
import Health from "../assest/img/HealthUserGuide.png";
import Market from "../assest/img/MarketUserGuide.png";
import Nutritions from "../assest/img/NutritionsUserGuide.png";
import Weather from "../assest/img/WeatherUserGuide.png";
import DroughtHover from "../assest/img/DroughtHover.png";
import FloodHover from "../assest/img/FloodHover.png";
import WeatherHover from "../assest/img/WeatherHover.png";
import NutritionsHover from "../assest/img/NutritionsHover.png";
import HealthHover from "../assest/img/HealthHover.png";
import { htmlToText } from "../components/Common/Utils";

export const userGuideImages = () => {
  const { t } = i18n;
  const dataArray = [
    {
      img: Drought,
      hoverImg: DroughtHover,
      slug: `${t("USER_GUIDE.DROUGHT")}`,
    },
    {
      img: Flood,
      hoverImg: FloodHover,
      slug: `${t("USER_GUIDE.FLOOD")}`,
    },
    {
      img: Health,
      hoverImg: HealthHover,
      slug: `${t("USER_GUIDE.HEALTH")}`,
    },
    {
      img: Market,
      hoverImg: DroughtHover,
      slug: `${t("USER_GUIDE.MARKET")}`,
    },
    {
      img: Nutritions,
      hoverImg: NutritionsHover,
      slug: `${t("USER_GUIDE.NUTRITION")}`,
    },
    {
      img: Weather,
      hoverImg: WeatherHover,
      slug: `${t("USER_GUIDE.WEATHER")}`,
    },
  ];
  return dataArray;
};

export function renderOptions(optionsList, label, value) {
  if (label && value && optionsList) {
    return optionsList.map((option) => {
      if (option) {
        return (
          <option value={option[value]} key={option[value]}>
            {option[label]}
          </option>
        );
      }
      return null;
    });
  }
  return null;
}

export function renderOptionsArray(optionsList, label, value) {
  const array = [];

  optionsList.map((eachValue) =>
    array.push({
      label: eachValue[label],
      value: eachValue[value],
    }),
  );
  return array;
}

export function renderReactOptionsArray(optionsList, label, value, order) {
  const array = [];

  if (optionsList) {
    optionsList.map((eachValue) =>
      array.push({
        label: eachValue[label],
        value: eachValue[value],
        order: eachValue[order],
      }),
    );
  }

  return array;
}

export function renderBackOfficeOptionsArray(
  optionsList,
  label,
  value,
  slug,
  list,
) {
  const array = [];

  if (optionsList) {
    optionsList.map((eachValue) =>
      array.push({
        label: eachValue[label],
        value: eachValue[value],
        slug: eachValue[slug],
        list: eachValue[list],
      }),
    );
  }

  return array;
}

export function renderBasinsArrayOptions(
  optionsList,
  label,
  value,
  latitude,
  longitude,
  zoom_level,
  list,
) {
  const array = [];

  if (optionsList) {
    optionsList.map((eachValue) =>
      array.push({
        label: eachValue[label],
        value: eachValue[value],
        latitude: eachValue[latitude],
        longitude: eachValue[longitude],
        zoom_level: eachValue[zoom_level],
        list: eachValue[list],
      }),
    );
  }

  return array;
}

export function renderReactOptionsArraystate(
  optionsList,
  label,
  value,
  latitude,
  longitude,
  zoom_level,
  code,
  isCommodity,
  min_year,
  max_year,
  is_monthly,
  is_daily,
  available_years,
  avialble_months,
  basin_feature_collection,
) {
  const array = [];
  if (isCommodity) {
    optionsList.map((eachValue) =>
      array.push({
        label: eachValue[label],
        value: eachValue[value],
        min_year: eachValue[min_year],
        max_year: eachValue[max_year],
        is_monthly: eachValue[is_monthly],
        is_daily: eachValue[is_daily],
        available_years: eachValue[available_years],
        avialble_months: eachValue[avialble_months],
      }),
    );
  } else {
    optionsList.map((eachValue) =>
      array.push({
        label: eachValue[label],
        value: eachValue[value],
        latitude: eachValue[latitude],
        longitude: eachValue[longitude],
        zoom_level: eachValue[zoom_level],
        code: eachValue[code],
        basin_feature_collection: eachValue[basin_feature_collection],
      }),
    );
  }
  return array;
}
export const renderGenericDateFormat = (date) => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate).local().format(DISPLAY_DATE_FORMAT);
  return formatedDate;
};
export const dateFormatter = (date) => {
  const dateformats = moment(date).format("YYYY-MM-DD").toString();
  return dateformats;
};
export const dateFormatters = (date) => {
  const dateformats = moment(date).format("DD/MM/YYYY").toString();
  return dateformats;
};

export const dateTimeFormat = (date) => {
  const dateformats = moment(date).format("DD/MM/YYYY h:mm:ss a").toString();
  return dateformats;
};

export const customToast = (message) => {
  toast(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const getYearsListFormat = (min, max) => {
  const minStartYear = min;
  const maxEndYear = max;
  const yearCount = maxEndYear - minStartYear;
  const ERPAS_YEAR_CONST = [];
  /* eslint-disable-next-line no-plusplus */
  for (let i = yearCount; i >= 0; i--) {
    ERPAS_YEAR_CONST.push({
      value: `${minStartYear + i}-01-01`,
      label: minStartYear + i,
    });
  }
  return ERPAS_YEAR_CONST;
};

export const getSelectedDate = (year) => {
  const selectedDate = {
    value: `${year}-01-01`,
    label: year,
  };

  return selectedDate;
};
export const getSelectedEndDate = (year) => {
  const selectedDate = {
    value: `${year}-12-31`,
    label: year,
  };

  return selectedDate;
};

// filter disable functionality
export const disableApplyLayer = (
  compare,
  seconddate,
  mapPreferencesData,
  date,
  mins,
  maxs,
  secondmins,
  secondmaxs,
  firstRadio,
  selectedStartYear,
  selectedEndYear,
  secondselectedStartYear,
  secondselectedEndYear,
) => {
  return compare
    ? !seconddate ||
        !date ||
        secondselectedStartYear.label > secondselectedEndYear.label ||
        mapPreferencesData.length === 2 ||
        (secondmins !== "" &&
          secondmaxs !== "" &&
          Number(secondmins) > Number(secondmaxs))
    : !date ||
        selectedStartYear.label > selectedEndYear.label ||
        mapPreferencesData.length === 2 ||
        (mins !== "" && maxs !== "" && mins > maxs);
};
export const disableFloodApplyLayer = (
  compare,
  seconddate,
  mapPreferencesData,
  date,
  mins,
  maxs,
  secondmins,
  secondmaxs,
) => {
  return compare
    ? !seconddate ||
        !date ||
        mapPreferencesData.length === 2 ||
        (secondmins !== null && secondmaxs !== null && secondmins > secondmaxs)
    : !date ||
        mapPreferencesData.length === 2 ||
        (mins !== null && maxs !== null && mins > maxs);
};

export const disableFloodApplyLayerIndicators = (
  compare,
  seconddate,
  date,
  comparePrecipitationThreshold,
  secondcomparePrecipitationThreshold,
) => {
  return compare
    ? [DrysPell, ExtremeRainfall].includes(seconddate?.indices_slug) &&
        secondcomparePrecipitationThreshold === ""
    : [DrysPell, ExtremeRainfall].includes(date?.indices_slug) &&
        comparePrecipitationThreshold === "";
};
// weather forecast filter disable functionality
export const disableWeatherForecastApplyLayer = (
  compare,
  compareIRIForecastTime,
  mapPreferencesData,
  IRIForecastTime,
  invalidDate,
  selectedDataSource,
) => {
  const { t } = i18n;
  return compare
    ? selectedDataSource?.secondDataSource?.[0]?.id ===
      DATA_SOURCES?.GFS_NOAA?.id
      ? invalidDate || mapPreferencesData.length === 2
      : selectedDataSource?.secondDataSource?.[0]?.id ===
        DATA_SOURCES?.OPEN_WEATHER?.id
      ? invalidDate || mapPreferencesData.length === 2
      : _.isEmpty(compareIRIForecastTime?.year) ||
        _.isEmpty(compareIRIForecastTime?.month) ||
        _.isEmpty(compareIRIForecastTime?.lead_time) ||
        mapPreferencesData.length === 2
    : selectedDataSource?.firstDataSource?.[0]?.id ===
      DATA_SOURCES?.GFS_NOAA?.id
    ? invalidDate || mapPreferencesData.length === 2
    : selectedDataSource?.firstDataSource?.[0]?.id ===
      DATA_SOURCES?.OPEN_WEATHER?.id
    ? invalidDate || mapPreferencesData.length === 2
    : selectedDataSource?.firstDataSource?.[0]?.name ===
      t("WEATHER_FORCAST.ECMWF")
    ? false
    : _.isEmpty(IRIForecastTime?.year) ||
      _.isEmpty(IRIForecastTime?.month) ||
      _.isEmpty(IRIForecastTime?.lead_time) ||
      mapPreferencesData.length === 2;
};

export const disableWeatherForecastGraphLayer = (
  compare,
  mapPreferencesData,
  IRIForecastTime,
  invalidDate,
  selectedDataSource,
) => {
  return compare
    ? selectedDataSource?.secondDataSource?.[0]?.name === "ECMWF (static)"
      ? true
      : selectedDataSource?.secondDataSource?.[0]?.id ===
        DATA_SOURCES?.GFS_NOAA?.id
      ? invalidDate || mapPreferencesData.length === 2
      : selectedDataSource?.secondDataSource?.[0]?.name === "IRI"
      ? selectedDataSource?.firstDataSource?.[0]?.name !== "IRI"
      : mapPreferencesData.length === 2 ||
        selectedDataSource?.secondDataSource?.length === 0
    : selectedDataSource?.firstDataSource?.[0]?.id ===
      DATA_SOURCES?.GFS_NOAA?.id
    ? invalidDate || mapPreferencesData.length === 2
    : selectedDataSource?.firstDataSource?.[0]?.id ===
      DATA_SOURCES?.OPEN_WEATHER?.id
    ? invalidDate || mapPreferencesData.length === 2
    : _.isEmpty(IRIForecastTime?.year) ||
      _.isEmpty(IRIForecastTime?.month) ||
      _.isEmpty(IRIForecastTime?.lead_time) ||
      mapPreferencesData.length === 2;
};

export const updateDate = (dataSource) => {
  if (dataSource?.id === DATA_SOURCES?.OPEN_WEATHER?.id) {
    return DATA_SOURCES?.OPEN_WEATHER?.dateRange;
  } else if (dataSource?.id === DATA_SOURCES?.GFS_NOAA?.id) {
    return DATA_SOURCES.GFS_NOAA.dateRange;
  } else {
    return {
      startDate: moment().format(DATE_FORMAT),
      endDate: moment().add(6, "days").format(DATE_FORMAT),
      minStartDate: "",
      maxStartDate: "",
      maxEndDate: "",
    };
  }
};

export const debounce = (func) => {
  let timer;
  return (...args) => {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};

export const getColor = (d) => {
  return d > 1000000
    ? "#eb2b1e"
    : d > 500000
    ? "#ff964a"
    : d > 200000
    ? "#ffe710"
    : "#ffe710";
};

// global utility to create querystring
export const createJSON = (search) => {
  return JSON.parse(
    `{"${decodeURI(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')}"}`,
  );
};
// //  global function to rename name as label in dropdown options

export const renderOptionsList = (optionsList) => {
  if (optionsList?.length) {
    const newOptions = [];
    optionsList.forEach((element) => {
      newOptions.push({
        ...element,
        value: element.id,
        label: element.indices_name,
      });
    });
    return newOptions;
  }
  return [];
};

// get selected element from nested object of array's data
export const getNestedObjectData = (dataArray, id) => {
  return dataArray
    .filter((x) => x.parameter.some((child) => id === child.name))
    .map((y) => ({
      ...y.parameter.find((child) => id === child.name),
      parentIndiceName: y.name,
    }))?.[0];
};

export const renderTextField = ({
  input,
  placeholder,
  className,
  readOnly,
  autoFocus,
  type,
  isDisabled,
  required,
  min,
  max,
  label,
  meta: { touched, error, warning },
}) => (
  <>
    {label && <Label className="mx-2">{label}</Label>}
    <Input
      {...input}
      placeholder={placeholder}
      className={className}
      value={input.value || ""}
      type={type}
      autoFocus={autoFocus}
      readOnly={readOnly}
      invalid={touched && error}
      required={required}
      disabled={isDisabled}
      min={min || ""}
      max={max || ""}
    />
    <div className="invalid-feedback">
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </>
);

export const getDateSuffix = (d) => {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const getBulletinDateFormat = (dateString) => {
  const givenDate = new Date(dateString);
  const date = givenDate.getDate();
  const month = MonthsArray[givenDate.getMonth()];
  const year = givenDate.getFullYear();

  return ` ${date}${getDateSuffix(date)} ${month} ${year}`;
};

export const getBulletinDateFormatwithOutSubScript = (dateString) => {
  const givenDate = new Date(dateString);
  const formattedDate = moment(givenDate).format("DD/MMM/YYYY");
  return formattedDate;
};

export const getBulletinMonthFormat = (dateString) => {
  const givenDate = new Date(dateString);
  const month = MonthsArray[givenDate.getMonth()];
  const year = givenDate.getFullYear();

  return `${month}-${year}`;
};
export const formatNumber = (number) => {
  if (number) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: "2",
      maximumFractionDigits: "2",
    }).format(number);
  }
};

export const monthsData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const rangeAndColorPaletteGenerator = (
  minColor,
  maxColor,
  steps,
  data,
  module,
  priceType,
) => {
  const colorPalette = chroma.scale([minColor, maxColor]).colors(14);
  const valueColors = {};
  if (data?.length) {
    const arr = [];
    data?.forEach((item) => {
      if (module === "health") {
        item.no_of_case ? arr.push(item.no_of_case) : "";
      } else if (module === "nutrition") {
        // item.total_children ? arr.push(item.total_children) : ""; // wasting
        item.wasting ? arr.push(item.wasting) : ""; // wasting
      } else if (module === "market") {
        item?.usd_price
          ? ["Local", "Both"].includes(priceType)
            ? arr.push(item?.price)
            : arr.push(item?.usd_price)
          : "";
      } else {
        item?.internal_displacement
          ? arr.push(item?.internal_displacement)
          : "";
      }
    });
    // Assigning Values to colors
    if (arr?.length) {
      arr.sort((a, b) => a - b);
      arr?.length !== 1
        ? arr?.forEach((item, index) => {
            valueColors[item] = colorPalette[index];
          })
        : arr?.forEach((item) => {
            valueColors[item] = colorPalette[0];
          });
    }
  }
  return valueColors;
};

export const colorPaletteGenerator = (minColor, maxColor) => {
  // Generate a color palette between the minimum and maximum colors
  const colorPalette = chroma.scale([minColor, maxColor]).colors(14);

  return colorPalette;
};

export const thousandSepartor = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// function for get token from user details
export const getToken = () => {
  let userLoggedInData = [];
  userLoggedInData = Cookies.get("user");
  if (userLoggedInData) {
    return userLoggedInData;
  } else {
    return false;
  }
};

export const setODKAccessToken = (token) => {
  Cookies.set("odk-access-token", token);
};

export const getODKAccessToken = () => {
  return Cookies.get("odk-access-token");
};

export const sortECMWFDays = (data) => {
  const result = _.groupBy(
    _.orderBy(
      data.map((item) => {
        return {
          ...item,
          firstValue: item.value.split("-")?.[0],
          secondValue: item.value.split("-")?.[1]
            ? Number(item.value.split("-")?.[1])
            : 0,
        };
      }),
      "secondValue",
      "asc",
    ),
    "firstValue",
  );
  return [].concat(...Object.values(result));
};

export const handleExportToCSVExcel = async (tableData, ColumnsData) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("data");

  // Define column headers
  worksheet.columns = ColumnsData;

  // Apply bold style to header row
  const headerRow = worksheet.getRow(1);
  headerRow.font = { bold: true };

  // Populate data
  tableData.forEach((item) => {
    worksheet.addRow({
      id: item.id + 1,
      categoryOfImpacts: item.categoryOfImpacts,
      description: htmlToText(item.description[0]),
    });
  });

  //  Enable text wrapping for the Respective column
  ColumnsData.forEach((item) => {
    worksheet.getColumn(item?.key).alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: item?.key === "id" ? "center" : "left",
    };
  });

  ColumnsData.forEach((column, columnIndex) => {
    headerRow.getCell(columnIndex + 1).alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
  });

  // Generate Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  const sheetData = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  FileSaver.saveAs(sheetData, `EA_PP_CATEGORY.xlsx`);
};

export const handleExportToCSVExcelImpacts = async (tableData, ColumnsData) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("data");

  // Define column headers
  worksheet.columns = ColumnsData;

  // Apply bold style to header row
  const headerRow = worksheet.getRow(1);
  headerRow.font = { bold: true };

  tableData.forEach((item) => {
    worksheet.addRow({
      id: item.id + 1,
      directimpacts: text1(item.directImpacts[0], {
        unorderedListItemPrefix: "- ", // Set the prefix for unordered list items
      }),
      indirectimpacts: text1(item.inDirectImpacts?.[0]),
    });
  });

  //  Enable text wrapping for the Respective column
  ColumnsData.forEach((item) => {
    worksheet.getColumn(item?.key).alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: item?.key === "id" ? "center" : "left",
    };
  });

  ColumnsData.forEach((column, columnIndex) => {
    headerRow.getCell(columnIndex + 1).alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
  });

  // Generate Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  const sheetData = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  FileSaver.saveAs(sheetData, `EA_PP_CATEGORY.xlsx`);
};

export const graphPredectionDateSet = (date, count) => {
  const originalDate = moment(date);
  const newDate = originalDate.add(count, "months");
  return newDate;
};

// export const separatedData = (data) =>
//   _.reduce(
//     data,
//     (result, item) => {
//       if (item.url) {
//         result.urls.push(item.url);
//       }
//       if (item.file_name) {
//         result.fileNames.push(item.file_name);
//       }
//       return result;
//     },
//     { urls: [], fileNames: [] },
//   );

export const separatedData = (data) =>
  _.reduce(
    data,
    (result, item) => {
      if (item.url) {
        result.urls.push(item);
      }
      if (item.file_name) {
        result.fileNames.push(item);
      }
      return result;
    },
    { urls: [], fileNames: [] },
  );

export const onHandleCropPredectionGraph = (
  graphData,
  // selectedLocation,
  // isPredict,
) => {
  const xAxis = [];
  const YAxisPredection = [];
  const YAxisActual = [];

  graphData?.map((each) => {
    // xAxis.push(
    //   isPredict && selectedLocation?.district?.value
    //     ? `${each.Year}`
    //     : isPredict
    //     ? `${each.district ? each?.district : each?.state}`
    //     : selectedLocation?.district?.value
    //     ? `${each.Year}`
    //     : `${each.Year}`,
    // );

    xAxis.push(`${each.Year}`);

    YAxisPredection.push(each?.predicted ? each?.predicted : 0);
    YAxisActual.push(each?.actual ? each?.actual : 0);

    return null;
  });
  return { xAxis, YAxisPredection, YAxisActual };
};

export const onHandleCropPredectionAnalytics = (data) => {
  const predictedYieldSum = _.sumBy(
    data,
    function predictedYieldSumFunction(o) {
      return o.predicted;
    },
  );

  const predictedRmseSum = _.sumBy(data, function predictedYieldSumFunction(o) {
    return o.rmse;
  });

  const predictedRsquareSum = _.sumBy(
    data,
    function predictedYieldSumFunction(o) {
      return o.rsq;
    },
  );
  const maxPredicted = _.maxBy(data, function maxActualFunction(o) {
    return o.predicted && o.predicted > 0 ? o.predicted : 0;
  });

  const minPredicted = _.minBy(data, function minPredictedFunction(o) {
    return o.predicted && o.predicted > 0 ? o.predicted : 0;
  });

  const sumPredicted = _.sumBy(data, function sumPredecitedFunction(o) {
    return o.predicted;
  });

  const sumActual = _.sumBy(data, function sumPredecitedFunction(o) {
    return o.actual;
  });

  const avgPredicted = sumPredicted / data.length;

  const avgActual = sumActual / data.length;

  const minActual = _.minBy(data, function getMinimum(o) {
    // return o.actual[0]; // specify the index of the element you want to use for comparison
    return o.actual && o.actual > 0 ? o.actual : 0;
  });

  const maxActual = _.maxBy(data, function getMaximum(o) {
    return o.actual && o.actual > 0 ? o.actual : 0;
  });

  const PredictedYield = predictedYieldSum / data.length;
  const rsq = predictedRsquareSum / data.length;
  const rmse = predictedRmseSum / data.length;

  return {
    PredictedYield,
    rsq,
    rmse,
    maxPredicted,
    minPredicted,
    avgPredicted,
    maxActual,
    minActual,
    avgActual,
  };
};

export const handleGenerateWeekDate = (startDate) => {
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 7);

  const startDay = startDate.getDate();
  const startMonth = startDate.toLocaleString("default", { month: "short" });
  const endDay = endDate.getDate();
  const endMonth = endDate.toLocaleString("default", { month: "short" });

  if (startMonth === endMonth) {
    return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
  } else {
    return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
  }
};

export const getMapSortedData = (historicData, predictedData) => {
  return {
    predictedMap: predictedData?.map_data,
    historicGraphData: historicData?.graph_data,
    currentGraphData: predictedData?.graph_data,
    combinedGraph: [...historicData?.graph_data, ...predictedData?.graph_data],
  };
};

export const convertToTitleCase = (str) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const onhandleEndDate = (data, modalData) => {
  const currentyear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const rainFallEndyear = moment(data?.[0]?.indices_end_date).year();
  let endyear = moment(data?.[0]?.indices_end_date).year();
  const filtermodal = modalData?.filter((each) => each?.months < currentMonth);

  if (currentyear === rainFallEndyear) {
    if (filtermodal?.length === 0) {
      endyear = moment(data?.[0]?.indices_end_date).year() - 1;
    } else {
      endyear = moment(data?.[0]?.indices_end_date).year();
    }
  } else {
    endyear = moment(data?.[0]?.indices_end_date).year();
  }

  return {
    endyear,
  };
};

export const renderFilesRemoveOfUpload = (files, setFiles) => {
  const handleRemoveFile = (index) => {
    const tempFiles = [...files];
    tempFiles.splice(index, 1);
    setFiles(tempFiles);
  };

  return files?.length
    ? files.map((item, index) => {
        return (
          <div
            className="img-upload-container d-flex justify-content-between align-items-center my-3"
            key={item?.file_name}
          >
            {item?.file_name || item?.name}
            <span
              role="button"
              onClick={() => handleRemoveFile(index)}
              onKeyUp={() => {}}
              tabIndex="0"
              aria-label="MediaModal"
            >
              <GrFormClose
                size={16}
                className="pointer media-modal-header-close-icon"
              />
            </span>
          </div>
        );
      })
    : null;
};

export const downloadExcelFile = async (template, moduleName) => {
  // Create an anchor tag element with download attribute
  const link = document.createElement("a");
  link.setAttribute("download", `${moduleName}.xlsx`);

  // Set the href attribute to the URL of the Excel file
  link.setAttribute("href", template);

  // Append the anchor tag to the document body
  document.body.appendChild(link);

  // Trigger a click event on the anchor tag to start the download
  link.click();

  // Remove the anchor tag from the document body
  document.body.removeChild(link);
};
