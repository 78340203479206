import CROP_YIELD_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};
const CropYield = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case CROP_YIELD_CONST.CROP_YIELD_OPTIONS_REQUEST:
      state = {
        ...state,
        loading: true,
        cropYieldList: null,
        error: "",
      };
      break;
    case CROP_YIELD_CONST.CROP_YIELD_OPTIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        cropYieldList:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case CROP_YIELD_CONST.CROP_YIELD_OPTIONS_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        cropYieldList: null,
      };
      break;

    // past data graph api
    case CROP_YIELD_CONST.CROP_YIELD_PAST_DATA_REQUEST:
      state = {
        ...state,
        loading: true,
        pastDataList: null,
        error: "",
      };
      break;
    case CROP_YIELD_CONST.CROP_YIELD_PAST_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        pastDataList:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case CROP_YIELD_CONST.CROP_YIELD_PAST_DATA_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        pastDataList: null,
      };
      break;

    // summary api
    case CROP_YIELD_CONST.CROP_YIELD_SUMMARY_REQUEST:
      state = {
        ...state,
        loading: true,
        summaryData: null,
        error: "",
      };
      break;
    case CROP_YIELD_CONST.CROP_YIELD_SUMMARY_SUCCESS:
      state = {
        ...state,
        loading: false,
        summaryData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case CROP_YIELD_CONST.CROP_YIELD_SUMMARY_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        summaryData: null,
      };
      break;

    // start and end date
    case CROP_YIELD_CONST.CROP_YIELD_START_END_DATE_REQUEST:
      state = {
        ...state,
        loading: true,
        startEndDateData: null,
        error: "",
      };
      break;
    case CROP_YIELD_CONST.CROP_YIELD_START_END_DATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        startEndDateData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case CROP_YIELD_CONST.CROP_YIELD_START_END_DATE_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        startEndDateData: null,
      };
      break;

    // CROP_YIELD_PREDECTION_REQUEST
    case CROP_YIELD_CONST.CROP_YIELD_PREDECTION_REQUEST:
      state = {
        ...state,
        loading: true,
        cropPredectionData: null,
        error: "",
        predectionLoading: true,
      };
      break;
    case CROP_YIELD_CONST.CROP_YIELD_PREDECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        cropPredectionData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
        predectionLoading: false,
      };
      break;
    case CROP_YIELD_CONST.CROP_YIELD_PREDECTION_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        cropPredectionData: null,
        predectionLoading: false,
      };
      break;

    // CYP CROP LISTING
    case CROP_YIELD_CONST.CROP_YIELD_CROP_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
        cropListLoading: true,
        cpCropList: null,
        error: "",
      };
      break;
    case CROP_YIELD_CONST.CROP_YIELD_CROP_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        cropListLoading: false,
        cpCropList:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case CROP_YIELD_CONST.CROP_YIELD_CROP_LIST_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        cpCropList: null,
        cropListLoading: false,
      };
      break;

    // POST FEEDBACK
    case CROP_YIELD_CONST.CROP_YEILD_FEEDBACK_REQUEST:
      state = {
        ...state,
        loading: true,
        crpPostFeedback: null,
        crpPostFeedbackError: "",
      };
      break;
    case CROP_YIELD_CONST.CROP_YEILD_FEEDBACK_SUCCESS:
      state = {
        ...state,
        loading: false,
        crpPostFeedback:
          action.payload.statusCode === 200 ? action.payload.data : false,
        crpPostFeedbackError: "",
      };
      break;
    case CROP_YIELD_CONST.CROP_YEILD_FEEDBACK_ERROR:
      state = {
        ...state,
        crpPostFeedbackError: action.payload.data ? action.payload.data : "",
        loading: false,
        crpPostFeedback: null,
      };
      break;

    // GET FEEDBACK
    case CROP_YIELD_CONST.GET_CROP_YEILD_FEEDBACK_REQUEST:
      state = {
        ...state,
        isGetFeedbackLoading: true,
        getCrpFeedbackData: null,
        error: "",
      };
      break;
    case CROP_YIELD_CONST.GET_CROP_YEILD_FEEDBACK_SUCCESS:
      state = {
        ...state,
        isGetFeedbackLoading: false,
        getCrpFeedbackData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case CROP_YIELD_CONST.GET_CROP_YEILD_FEEDBACK_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        isGetFeedbackLoading: false,
        getCrpFeedbackData: null,
      };
      break;

    // GET CROP CALENDER
    case CROP_YIELD_CONST.GET_CROP_CALENDER_REQUEST:
      state = {
        ...state,
        loading: true,
        getCropCalenderData: null,
        error: "",
      };
      break;
    case CROP_YIELD_CONST.GET_CROP_CALENDER_SUCCESS:
      state = {
        ...state,
        loading: false,
        getCropCalenderData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case CROP_YIELD_CONST.GET_CROP_CALENDER_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.data ? action.payload.data.errorDescription : "",

        getCropCalenderData: null,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};
export default CropYield;
