import OTHER_TOOLS_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};

const OtherTools = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }

  switch (action.type) {
    case OTHER_TOOLS_CONST.OTHER_TOOLS_REQUEST:
      state = {
        ...state,
        loading: true,
        otherToolsListData: null,
        error: "",
      };
      break;
    case OTHER_TOOLS_CONST.OTHER_TOOLS_SUCCESS:
      state = {
        ...state,
        loading: false,
        otherToolsListData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case OTHER_TOOLS_CONST.OTHER_TOOLS_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        otherToolsListData: null,
      };
      break;
    case OTHER_TOOLS_CONST.FOCUS_AREAS_REQUEST:
      state = {
        ...state,
        loading: true,
        focusAreasData: null,
        error: "",
      };
      break;
    case OTHER_TOOLS_CONST.FOCUS_AREAS_SUCCESS:
      state = {
        ...state,
        loading: false,
        focusAreasData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case OTHER_TOOLS_CONST.FOCUS_AREAS_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        focusAreasData: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default OtherTools;
