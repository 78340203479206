import React from "react";
import { useTranslation } from "react-i18next";
import { BsExclamationTriangleFill } from "react-icons/bs";

function IndicatorCard({
  severity,
  name,
  value,
  // threshold,
  action,
  onHandelIndicator,
  indicator,
  selectedParameter,
  ActiveThreshold,
  readinessThreshold,
  preparednessThreshold,
}) {
  const { t } = useTranslation();

  return (
    <div
      className={
        selectedParameter?.slug === indicator?.slug
          ? "indicator-card-container selected-patameter-alert "
          : "indicator-card-container  "
      }
      role="button"
      tabIndex="0"
      onKeyUp={() => {}}
      onClick={() => {
        onHandelIndicator(indicator);
      }}
      // style={selectedParameter?.slug === indicator?.slug ?  { border: "2px solid red" }: {}}
    >
      <div className="indicator-card-inner-container">
        <div className="indicator-card-header">
          <p className="d-inline header-indicator">{name}</p>
          {action !== "-" ? (
            <div className="d-inline">
              {/* <p className={`card-severity-indicator ${severity}`}>
              <BsExclamationTriangleFill /> &nbsp;&nbsp; Extreme
            </p> */}
              <p className={`card-severity-indicator ${severity}`}>
                {severity !== "Normal" ? (
                  <>
                    <BsExclamationTriangleFill />
                    &nbsp;&nbsp; {action}
                  </>
                ) : (
                  <span>{action}</span>
                )}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={`card-header-divider ${severity}-card-divider`} />
        <div className="indicator-card-text-container">
          <p>
            {`${t("DASHBOARD.CURRENT_VALUE")}: ${value}`}
            <i className="bi bi-exclamation-triangle-fill" />
          </p>
          <p> {`${t("DASHBOARD.ACTIVE_THRESHOLD")}: ${ActiveThreshold}`}</p>
          <p>
            {`${t("DASHBOARD.READINESS_THRESHOLD")}: ${readinessThreshold}`}
          </p>
          <p>
            {`${t(
              "DASHBOARD.PREPAREDNESS_THRESHOLD",
            )}: ${preparednessThreshold}`}
          </p>
          <p>
            {t("DASHBOARD.SEVERITY")}: <strong>&nbsp;{severity} </strong>
          </p>
        </div>
      </div>
    </div>
  );
}

export default IndicatorCard;
