import React, { useState, useEffect, useCallback, createRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Input,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import Select from "react-select";
import _ from "lodash";
import moment from "moment";
import {
  renderReactOptionsArray,
  renderReactOptionsArraystate,
  renderOptionsList,
} from "../../../helpers";
import {
  State,
  District,
  Montly_bulletin,
  Bi_Weekly_Bulletin,
  BI_WEEKLY_BULLETIN,
  MONTHLY_BULLETIN,
  DATE_FORMAT,
} from "../../../constants/index";
import { selectRequired } from "../../../helpers/validate";
import { corestateRequest, coredistrictRequest } from "../../../store/actions";

function CreateBulletin({
  isOpenBulletin,
  toggleModal,
  bulletinCategories,
  selectedLocation,
  countryList,
  statesList,
  districtsList,
  indicatorsList,
  isBulletinPage,
  setMapRefs,
  indicatorRows,
  setIndicatorRows,
  setEachRowAPIPayload,
  renderedValues,
}) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const history = useNavigate();
  const dispatch = useDispatch();
  const [indicators, setIndicators] = useState([]);
  const [bulletinType, setBulletinType] = useState("");
  const [provience, setProvience] = useState();
  const [regionListOptions, setRegionListOptions] = useState([]);
  const [districtsListOptions, setDistrictsListOptions] = useState([]);
  const [country, setCountry] = useState({});
  const [district, setDistrict] = useState();
  const local = JSON.parse(localStorage.getItem("selectedCountry"));
  const [error, setError] = useState("");
  const [error1, setError1] = useState("");
  const [buttonEnable, setButtonEnable] = useState(false);

  const selectedRegions = [
    {
      label: t("DASHBOARD.ALL_REGIONS"),
      value: "",
      latitude: JSON.parse(localStorage.getItem("selectedCountry"))?.latitude,
      longitude: JSON.parse(localStorage.getItem("selectedCountry"))?.longitude,
      zoom_level: JSON.parse(localStorage.getItem("selectedCountry"))
        ?.zoom_level,
    },
  ];
  const selectedDistricts = [
    {
      label: t("DASHBOARD.ALL_DISTRICTS"),
      value: "",
      latitude: selectedLocation?.state?.latitude,
      longitude: selectedLocation?.state?.longitude,
      zoom_level: selectedLocation?.state?.zoom_level,
    },
  ];

  const onSubmit = () => {
    toggleModal();
    const indicatorsNew = indicators?.length
      ? indicators.map((item) => {
          return item.label;
        })
      : [];
    const countryNew = country?.label;
    let latitude = country?.latitude;
    let longitude = country?.longitude;
    let zoomLevel = country?.zoom - 1;

    let stateNew = "";
    if (provience?.label) {
      if (provience?.label === t("DASHBOARD.ALL_REGIONS")) {
        stateNew = "";
      } else {
        stateNew = provience?.label;
        latitude = provience?.latitude;
        longitude = provience?.longitude;
        zoomLevel = provience?.zoom_level - 2;
      }
    } else {
      stateNew = "";
    }

    let districtNew = "";
    if (district?.label) {
      if (district?.label === t("DASHBOARD.ALL_DISTRICTS")) {
        districtNew = "";
      } else {
        districtNew = district?.label;
        latitude = district?.latitude;
        longitude = district?.longitude;
        zoomLevel = district?.zoom_level - 2;
      }
    } else {
      districtNew = "";
    }

    let startDateNew;
    let endDateNew;
    if (
      bulletinType?.value === Montly_bulletin ||
      renderedValues?.bulletinType?.value === Montly_bulletin
    ) {
      startDateNew = `${startDate}-01`;
      endDateNew = `${startDate}-${moment(startDate, "YYYY-MM").daysInMonth()}`;
    } else {
      startDateNew = startDate;
      endDateNew = endDate;
    }
    toggleModal();
    /* eslint max-len: ["error", { "code": 1000 }] */
    const newQueryParams = `?country=${countryNew.toString()}&state=${stateNew.toString()}&district=${districtNew.toString()}&indicators=${indicatorsNew.toString()}&startDate=${startDateNew}&endDate=${endDateNew}&bulletinType=${
      bulletinType?.label
    }&bulletinId=${
      bulletinType?.value
    }&latitude=${latitude}&longitude=${longitude}&zoomLevel=0${zoomLevel}`;

    if (!isBulletinPage) {
      history({
        pathname: "/create-online-bulletin",
        search: newQueryParams,
      });
    } else {
      setMapRefs((prev) => ({
        ...prev,
        [`row${Object.keys(indicatorRows)?.length}`]: createRef({}),
      }));
      const formData = {
        country: countryNew,
        state: stateNew,
        district: districtNew,
        indicatorsList: indicators,
        startDate: startDateNew,
        endDate: endDateNew,
      };

      // data for api call
      setEachRowAPIPayload(formData);
      // pushing row into existing row data
      setIndicatorRows((prev) => ({
        ...prev,
        [`row${Object.keys(prev)?.length}`]: formData,
      }));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const selectedCountry = JSON.parse(localStorage.getItem("selectedCountry"));
    setCountry({
      label: selectedCountry?.label,
      value: selectedCountry?.value,
      latitude: selectedCountry?.latitude,
      longitude: selectedCountry?.longitude,
      zoom: selectedCountry?.zoom_level,
    });
  }, []);

  useEffect(() => {
    if (renderedValues) {
      setProvience(renderedValues?.provience);
      setDistrict(renderedValues?.district);
      setBulletinType(renderedValues?.bulletinType);
    }
  }, []);

  useEffect(() => {
    if (bulletinType?.label === BI_WEEKLY_BULLETIN) {
      if (!startDate || !endDate || _.isEmpty(indicators) || error1)
        setButtonEnable(true);
      else setButtonEnable(false);
    } else if (bulletinType?.label === MONTHLY_BULLETIN) {
      if (!startDate || _.isEmpty(indicators) || error1) setButtonEnable(true);
      else setButtonEnable(false);
    } else setButtonEnable(true);
  }, [startDate, endDate, indicators, error1]);

  useEffect(() => {
    if (statesList) {
      renderReactOptionsArraystate(
        statesList?.data,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
      )?.map((eachValue) => selectedRegions.push(eachValue));
      setRegionListOptions(selectedRegions);
    }
  }, [statesList]);

  useEffect(() => {
    if (provience !== "" || undefined) {
      if (districtsList) {
        renderReactOptionsArraystate(
          districtsList?.data,
          "name",
          "id",
          "latitude",
          "longitude",
          "zoom_level",
        )?.map((eachValue) => selectedDistricts.push(eachValue));
      }

      setDistrictsListOptions(selectedDistricts);
    }
  }, [districtsList]);

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")) {
      dispatch(
        corestateRequest({
          data: {
            parent_id: local?.value,
            location_type: State,
          },
          isCompareLocation: false,
        }),
      );
    }
  }, [localStorage.getItem("i18nextLng")]);

  const handleIndicators = useCallback((e) => {
    if (e.length < 4) {
      setIndicators(e);
    }
  }, []);

  const handleBulletinType = (e) => {
    setBulletinType(e);
    setStartDate(null);
    setEndDate(null);
  };

  const selectCountry = (e) => {
    if (e?.value !== country?.value) {
      setCountry(e);
    }
  };

  const selectProvience = (e) => {
    setProvience(e);
  };

  useEffect(() => {
    if (!_.isEmpty(provience)) {
      dispatch(
        coredistrictRequest({
          requestObj: {
            parent_id: provience?.value,
            location_type: District,
          },
          isCompareLocation: false,
        }),
      );
    }
  }, [provience]);

  const selectDistrict = (e) => {
    setDistrict(e);
  };

  const getCurrentYearMonth = () => {
    const dateObj = new Date();
    const year = dateObj.getUTCFullYear();
    const month = `${dateObj.getMonth()}`.padStart(2, "0");
    const maxDate = `${year}-${month}`;
    return maxDate;
  };

  const handleMonthChange = (e) => {
    const selectedMonth = e.target.value;
    const year = selectedMonth.split("-")[0];
    const month = selectedMonth.split("-")[1];
    const firstDayOfMonth = moment(`${year}-${month}-01`);

    const today = moment();
    const isFutureDate = firstDayOfMonth.isAfter(today, "month");
    const isValidYear = Number(year) <= 1999;

    if (isFutureDate) {
      setError(t("CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.DATE_ERROR1"));
    } else if (isValidYear) {
      setError(t("CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.DATE_ERROR"));
    } else {
      setError("");
      setStartDate(e.target.value);
    }
  };

  return (
    <Modal
      isOpen={isOpenBulletin}
      toggle={toggleModal}
      className="create-bulletin-popup"
    >
      <ModalHeader
        className="custom-header"
        toggle={toggleModal}
        close={
          <button className="close-button" type="button">
            ×
          </button>
        }
      >
        <div>
          <p className="create-bulletin-header mb-0">
            {t("CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.HEADER")}
          </p>
          <Button className="close-btn-map" onClick={() => toggleModal()}>
            <IoMdCloseCircleOutline className="close-svg" />
          </Button>
        </div>
      </ModalHeader>
      <ModalBody>
        <Card className="card-style">
          <Formik onSubmit={onSubmit}>
            {(formick) => {
              return (
                <Form className="filter-control-dp">
                  <div className="search-container">
                    <Row>
                      <Col sm={12} className="pop-bulletin pt-4 pb-4">
                        <div
                          className={
                            country?.value
                              ? "common-select-register"
                              : "common-select-dropdown"
                          }
                        >
                          <Label className="label">
                            {t(
                              "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.COUNTRY",
                            )}
                          </Label>
                          <Select
                            classNamePrefix="react-select"
                            placeholder={t("CREATE_ONLINE_BULLETIN.COUNTRY")}
                            name="country"
                            options={renderReactOptionsArray(
                              countryList,
                              "name",
                              "id",
                            )}
                            value={country}
                            validate={selectRequired}
                            onChange={(e) => selectCountry(e)}
                            className="common-select-r"
                            isDisabled
                          />
                        </div>
                      </Col>
                      <Col sm={12} className="pop-bulletin pb-4">
                        <div
                          className={
                            country?.value
                              ? "common-select-register"
                              : "common-select-dropdown"
                          }
                        >
                          <Label className="label">
                            {t(
                              "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.PROVINCE",
                            )}
                          </Label>
                          <Select
                            classNamePrefix="react-select"
                            name="state_id"
                            placeholder={t(
                              "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.PROVINCE_PLACEHOLDER",
                            )}
                            className={
                              formick?.errors?.state_id &&
                              formick?.touched?.state_id
                                ? "common-select-r input-error-class"
                                : "common-select-r"
                            }
                            value={provience}
                            validate={selectRequired}
                            options={regionListOptions}
                            onChange={(e) => selectProvience(e)}
                            isDisabled={
                              renderedValues?.provience?.label ||
                              renderedValues?.bulletinType?.value ===
                                Montly_bulletin ||
                              renderedValues?.bulletinType?.value ===
                                Bi_Weekly_Bulletin
                            }
                          />
                        </div>
                      </Col>
                      <Col sm={12} className="pop-bulletin ">
                        <div
                          className={
                            country?.value
                              ? "common-select-register"
                              : "common-select-dropdown"
                          }
                        >
                          <Label className="label">
                            {t(
                              "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.DISTRICT",
                            )}
                          </Label>
                          <Select
                            classNamePrefix="react-select"
                            name="district_id"
                            placeholder={t(
                              "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.DISTRICT_PLACEHOLDER",
                            )}
                            className={
                              formick?.errors?.district_id &&
                              formick?.touched?.district_id
                                ? "common-select-r input-error-class"
                                : "common-select-r"
                            }
                            value={district}
                            validate={selectRequired}
                            options={districtsListOptions}
                            onChange={(e) => selectDistrict(e)}
                            isDisabled={
                              renderedValues?.district?.label ||
                              renderedValues?.bulletinType?.value ===
                                Montly_bulletin ||
                              provience?.label === "" ||
                              renderedValues?.bulletinType?.value ===
                                Bi_Weekly_Bulletin
                            }
                          />
                        </div>
                      </Col>
                      <Col
                        sm={12}
                        className="pop-bulletin pb-4 select-bulliten-container mb-4"
                      >
                        <div
                          className={
                            country?.value
                              ? "common-select-register"
                              : "common-select-dropdown"
                          }
                        >
                          <Label className="label select-bulliten-lable">
                            {t(
                              "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.BULLETIN_TYPE",
                            )}
                          </Label>
                          <Select
                            classNamePrefix="react-select"
                            className={
                              formick?.errors?.bulletinType &&
                              formick?.touched?.bulletinType
                                ? "common-select-r input-error-class"
                                : "common-select-r"
                            }
                            type="select"
                            name="bulletinType"
                            placeholder={t(
                              "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.SELECT_BULLETIN_TYPE",
                            )}
                            value={bulletinType}
                            isDisabled={
                              renderedValues?.bulletinType?.value ===
                                Montly_bulletin ||
                              renderedValues?.bulletinType?.value ===
                                Bi_Weekly_Bulletin
                            }
                            options={renderReactOptionsArray(
                              bulletinCategories,
                              "name",
                              "id",
                            )}
                            validate={selectRequired}
                            onChange={(e) => {
                              handleBulletinType(e);
                              setError1("");
                              setError("");
                            }}
                          />
                        </div>
                      </Col>
                      <Col sm={12} className="pop-bulletin pb-4 ">
                        <div
                          className={
                            country?.value
                              ? "common-select-register"
                              : "common-select-dropdown"
                          }
                        >
                          <Label className="label select-bulliten-lable">
                            {t(
                              "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.INDICATORS",
                            )}
                          </Label>
                          <Select
                            classNamePrefix="react-select"
                            className={
                              formick?.errors?.indicators &&
                              formick?.touched?.indicators
                                ? "common-select-r input-error-class"
                                : "common-select-r"
                            }
                            type="select"
                            name="indicators"
                            placeholder={t(
                              "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.INDICATOR_PLACEHOLDER",
                            )}
                            onChange={(e) => handleIndicators(e)}
                            options={renderOptionsList(indicatorsList)}
                            isMulti
                            isOptionDisabled={() => indicators.length === 3}
                            validate={selectRequired}
                          />
                        </div>
                      </Col>
                      <Col md={12} className="pop-bulletin pb-4">
                        <div
                          className={
                            country?.value
                              ? "common-select-register"
                              : "common-select-dropdown"
                          }
                        >
                          <Label className="label">
                            {bulletinType
                              ? bulletinType.value === Montly_bulletin
                                ? t(
                                    "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.MONTH_YEAR",
                                  )
                                : t(
                                    "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.START_DATE",
                                  )
                              : renderedValues?.bulletinType?.value ===
                                Montly_bulletin
                              ? t(
                                  "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.MONTH_YEAR",
                                )
                              : t(
                                  "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.START_DATE",
                                )}
                          </Label>
                          <Input
                            type={
                              bulletinType
                                ? bulletinType.value === Montly_bulletin
                                  ? "month"
                                  : "date"
                                : renderedValues?.bulletinType?.value ===
                                  Montly_bulletin
                                ? "Month"
                                : "date"
                            }
                            name="startDate"
                            max={
                              bulletinType.value === Montly_bulletin
                                ? getCurrentYearMonth()
                                : moment(new Date())
                                    .subtract(15, "days")
                                    .format(DATE_FORMAT)
                            }
                            value={startDate}
                            data-validate={selectRequired}
                            className={
                              bulletinType
                                ? bulletinType.value === Montly_bulletin &&
                                  formick?.errors?.startDate &&
                                  formick?.touched?.startDate
                                  ? "common-select-r input-error-class"
                                  : "common-select-r"
                                : renderedValues?.bulletinType?.value ===
                                  Montly_bulletin
                                ? "common-select-r input-error-class"
                                : "common-select-r"
                            }
                            onChange={(e) => {
                              handleMonthChange(e);
                            }}
                            disabled={!bulletinType}
                          />
                          {error && (
                            <p className="error-msg">
                              <AiOutlineExclamationCircle /> {error}
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col
                        md={12}
                        className={
                          bulletinType
                            ? bulletinType.value === Montly_bulletin
                              ? "montly-display"
                              : "pop-bulletin pb-4"
                            : renderedValues?.bulletinType?.value ===
                              Montly_bulletin
                            ? "montly-display"
                            : "pop-bulletin pb-4"
                        }
                      >
                        <div
                          className={
                            country?.value
                              ? "common-select-register"
                              : "common-select-dropdown"
                          }
                        >
                          <Label className="label">
                            {bulletinType
                              ? bulletinType.value === Montly_bulletin
                                ? t(
                                    "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.MONTH_YEAR",
                                  )
                                : t(
                                    "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.END_DATE",
                                  )
                              : renderedValues?.bulletinType?.value ===
                                Montly_bulletin
                              ? t(
                                  "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.MONTH_YEAR",
                                )
                              : t(
                                  "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.END_DATE",
                                )}
                          </Label>
                          <Input
                            type={
                              bulletinType
                                ? bulletinType.value === Montly_bulletin
                                  ? "month"
                                  : "date"
                                : renderedValues?.bulletinType?.value ===
                                  Montly_bulletin
                                ? "Month"
                                : "date"
                            }
                            name="endDate"
                            disabled={
                              bulletinType
                                ? bulletinType.value === Montly_bulletin
                                  ? true
                                  : !startDate
                                : renderedValues?.bulletinType?.value ===
                                  Montly_bulletin
                                ? true
                                : !startDate
                            }
                            min={startDate}
                            max={
                              bulletinType.value === Montly_bulletin
                                ? getCurrentYearMonth()
                                : moment(new Date())
                                    .subtract(15, "days")
                                    .format(DATE_FORMAT)
                            }
                            data-validate={selectRequired}
                            className={
                              bulletinType
                                ? bulletinType.value === Montly_bulletin &&
                                  formick?.errors?.endDate &&
                                  formick?.touched?.endDate
                                  ? "common-select-r input-error-class"
                                  : "common-select-r"
                                : renderedValues?.bulletinType?.value ===
                                  Montly_bulletin
                                ? "common-select-r input-error-class"
                                : "common-select-r"
                            }
                            onChange={(e) => {
                              const { value } = e.target;
                              if (value <= startDate) {
                                setError1(
                                  t(
                                    "CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.END_DATE_ERROR",
                                  ),
                                );
                              } else {
                                setError1("");
                                setEndDate(value);
                              }
                            }}
                          />
                          {error1 && (
                            <p className="error-msg">
                              <AiOutlineExclamationCircle /> {error1}
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md={12}>
                        <Button
                          disabled={buttonEnable}
                          type="submit"
                          className="bulletin-submit"
                          onClick={onSubmit}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </ModalBody>
    </Modal>
  );
}

export default CreateBulletin;
