import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardCommon from "../../components/Common/DasboardCommon";
import ClimberFooter from "../../components/Common/ClimberFooter";
import AwareHeader from "../../components/Common/AwareHeader";

import DisclaimerModal from "../../components/Common/Disclaimer";

function DashboardLayoutUnprotected() {
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <div className="layout-div">
      <AwareHeader />
      <div className="second-container">
        <div className="dashboard-main-page common-dashboard-ftr">
          {showModal ? (
            <DisclaimerModal modal={showModal} toggleModal={toggleModal} />
          ) : (
            ""
          )}
          <div className="d-flex dashboard-md">
            <DashboardCommon
              setOpen={setOpen}
              open={open}
              toggleModal={toggleModal}
            />
            <div
              className={
                open
                  ? "dashboard-drought dashboard-drought-full"
                  : "dashboard-drought dashboard-drought-sm"
              }
            >
              <Outlet context={[open, setOpen]} />
            </div>
          </div>
          <ClimberFooter />
        </div>
      </div>
    </div>
  );
}

export default DashboardLayoutUnprotected;
