import React from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import L from "leaflet";

import {
  RAPID_RESPONSE_NEWSFEED_DISASTER_TYPES,
  myConst,
} from "../../constants";

function MapComponent({ position, zoom, selectedDisasterEvent }) {
  const disasterEvent = RAPID_RESPONSE_NEWSFEED_DISASTER_TYPES.find(
    (event) => event.value === selectedDisasterEvent.type,
  );
  const LoaderIcon = new L.Icon({
    iconUrl: disasterEvent?.icon,
    iconSize: [24, 24],
  });

  return (
    <MapContainer
      center={position}
      zoom={zoom}
      className="disaster-event-map h-100"
    >
      <TileLayer
        options={{ tileSize: 256 }}
        attribution={myConst.TITLE_LAYER_ATTRIBUTE}
        url={myConst.TITLE_LAYER_URL}
      />
      <Marker position={position} icon={LoaderIcon} />
    </MapContainer>
  );
}

export default MapComponent;
