import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { BsArrowLeft, BsPlusLg } from "react-icons/bs";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../../../assest/img/ea-download.svg";
import ICON from "../../../../assest/img/empy-img.png";
import EarlyActionTable from "../../../../components/Common/EarlyActionTable";
import { ReactComponent as EditIcon } from "../../../../assest/img/ea-edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assest/img/ea-delete.svg";
import RightSideModal from "./editImageSidebar";
import ReadynessTable from "../readnessAddRow";
import DeleteModal from "../../DeleteModal";
import {
  editReadynessContentRequest,
  getReadynessPhaseRequest,
} from "../../../../store/actions";
import {
  hanldeExportToCSV,
  isLogedIn,
} from "../../../../components/Common/Utils";
import Comments from "../../../../components/Common/Comments";
import { handleExportToCSVIntervantion } from "../../../../helpers/excelDownload";
import { ColumnsData } from "../../../../constants";

function NewIntervention() {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();

  const [, setOpen] = useOutletContext(); // for closing side bar when it is opening

  const [intervantationData, setInterventationdataList] = useState({
    name: "",
    indicator: "",
    people: "",
    houseHolds: "",
    file: "",
    base64: "",
  });

  const [deleteModal, setDeleteModal] = useState(false);

  const [addNewRow, setAddNewRow] = useState({
    id: "",
    activities: "",
    timeframe: "",
    target: "",
  });

  const [initialValues, setInitialValues] = useState({
    activities: "",
    timeframe: "",
    target: "",
  });

  const [readynessPPState, setReadynessPPState] = useState([]);
  const [readynessRRState, setReadynessRRState] = useState([]);
  const [readynessPrState, setReadynessPrState] = useState([]);
  const [selectTable, setSelectTable] = useState();
  const [imageData, setImageData] = useState("");
  const [base64, setBase64] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [isOpenEditEditor, setIsOpenEditEditor] = useState(false);
  const [callApi, setApiCall] = useState(false);

  const [sendComment, setSendComment] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [onChangeImage, setOnChangeImage] = useState(false);
  const scrollref = useRef();

  const nextProps = useSelector((state) => ({
    addSucess: state?.EAReadynessReducer?.createReadynessPhaseData,
    editSuccess: state?.EAReadynessReducer?.editedReadynessPhaseData,
    interventionList: state?.EAReadynessReducer?.interventionList,
    userDetails: state.User?.userDetails,
    deletemessage: state?.EAReadynessReducer?.editContentError,
  }));

  const userDetails = JSON.parse(isLogedIn());

  const handleClickBack = () => {
    localStorage.setItem("stepperActiveStep", "1");
    history(-1);
  };

  const COLUMNS = [
    {
      Header: t("EARLY_ACTIONS.ACTIVITIES"),
      accessorKey: "Activities",
      size: 350,
    },
    {
      Header: t("EARLY_ACTIONS.TIME_FRAME"),
      accessorKey: "Timeframe",
      size: 100,
    },
    {
      Header: t("EARLY_ACTIONS.TARGET"),
      accessorKey: "Target",
      size: 60,
    },
    {
      Header: t("EARLY_ACTIONS.MANAGE_DATA"),
      accessorKey: "managedata",
      size: 60,
    },
  ];

  const params = new URLSearchParams(window.location.search);
  const iId = params.get("interventionId");
  const interventionName = params.get("name");

  const isdeletemessage = useRef(true);
  useEffect(() => {
    if (isdeletemessage.current) {
      isdeletemessage.current = false;
      return;
    }
    if (nextProps?.deletemessage) {
      dispatch(
        getReadynessPhaseRequest({
          module: "early_action",
          project_id: JSON.parse(localStorage.getItem("projectDetails"))?.id,
          data: 0,
        }),
      );
    }
  }, [nextProps.deletemessage]);
  useEffect(() => {
    setOpen(true);
    dispatch(
      getReadynessPhaseRequest({
        module: "early_action",
        project_id: JSON.parse(localStorage.getItem("projectDetails"))?.id,
        data: 0,
      }),
    );
  }, []);

  const isAddandEditRun = useRef(true);
  useEffect(() => {
    if (isAddandEditRun.current) {
      isAddandEditRun.current = false;
      return;
    }
    if (nextProps?.editSuccess) {
      dispatch(
        getReadynessPhaseRequest({
          module: "early_action",
          project_id: JSON.parse(localStorage.getItem("projectDetails"))?.id,
          data: 0,
        }),
      );
    }
  }, [nextProps.editSuccess]);

  const handleAddRow = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setIsOpenEditor(true);
  };

  const modifyData = (dataArray, tableNum) => {
    const modifiedData = [];
    setApiCall(false);
    dataArray?.forEach((contentData) => {
      const uniqueId = uuidv4();
      const modifedColumn = {
        id: uniqueId,
        Activities: contentData.Activities,
        Target: contentData.Target,
        Budget: contentData.Budget,
        Timeframe: contentData.Timeframe,
        managedata: (
          <div className="d-flex align-items-center justify-content-around">
            <span>
              <EditIcon
                onClick={() => {
                  setInitialValues({
                    id: uniqueId,
                    activities: contentData.Activities,
                    timeframe: contentData.Timeframe,
                    target: contentData.Target,
                    budget: contentData.Budget,
                    table: tableNum,
                  });
                  setSelectTable(tableNum);
                  setIsOpenEditor(true);
                  setIsOpenEditEditor(true);
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
                className="cursor-pointer"
              />
            </span>

            <span>
              <DeleteIcon
                className="cursor-pointer"
                onClick={() => {
                  setInitialValues({
                    id: uniqueId,
                    activities: contentData.Activities,
                    timeframe: contentData.Timeframe,
                    target: contentData.Target,
                    table: tableNum,
                  });
                  setSelectTable(tableNum);
                  setDeleteModal(true);
                }}
              />
            </span>
          </div>
        ),
      };

      modifiedData.push(modifedColumn);
    });

    return modifiedData;
  };

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (nextProps?.interventionList?.length) {
      const selectedIntervention = nextProps.interventionList.find(
        (each) => each?.id === iId,
      );

      setInterventationdataList((prev) => ({
        ...prev,
        name: selectedIntervention?.name,
        indicator: selectedIntervention?.indicator,
        people: selectedIntervention?.people_targeted,
        houseHolds: selectedIntervention?.households_targeted,
        base64: selectedIntervention?.icon_url,
      }));
      const scrollableElement = scrollref.current;
      const timeoutId = setTimeout(() => {
        sendComment !== ""
          ? (scrollableElement.scrollTop = scrollableElement.scrollHeight)
          : "";
      }, 100);

      // Clear the timeout if the component unmounts or sendComment changes

      setSendComment("");
      setCommentList(selectedIntervention?.comment);
      const ppPhase = modifyData(selectedIntervention?.preparedness_content, 1);
      const RRPhase = modifyData(selectedIntervention?.readiness_content, 2);
      const PRPhase = modifyData(
        selectedIntervention?.prioritized_ea_content,
        3,
      );
      setReadynessPPState(ppPhase);
      setReadynessRRState(RRPhase);
      setReadynessPrState(PRPhase);
      return () => clearTimeout(timeoutId);
    }
  }, [nextProps.interventionList]);

  function removeKeyFromObject(obj, keyToRemove) {
    const newObj = { ...obj };
    delete newObj[keyToRemove];
    return newObj;
  }

  const onDataChange = () => {
    if (intervantationData?.name) {
      const readynessPPStatenewArray = readynessPPState.map((obj) =>
        removeKeyFromObject(obj, "managedata"),
      );
      const readynessRRStatenewArray = readynessRRState.map((obj) =>
        removeKeyFromObject(obj, "managedata"),
      );
      const readynessPrStatenewArray = readynessPrState.map((obj) =>
        removeKeyFromObject(obj, "managedata"),
      );

      const payLoad = {
        intervention_id: iId,
        project_id: JSON.parse(localStorage.getItem("projectDetails"))?.id,
        name: intervantationData?.name,
        indicator: intervantationData?.indicator,
        preparedness_content: readynessPPStatenewArray,
        readiness_content: readynessRRStatenewArray,
        prioritized_ea_content: readynessPrStatenewArray,
        households_targeted: !intervantationData?.houseHolds
          ? 0
          : intervantationData?.houseHolds,
        people_targeted: !intervantationData?.people
          ? 0
          : intervantationData?.people,
      };
      if (base64) {
        payLoad.icon = {
          base64: base64.split(",")?.[1],
          file: imageData?.name,
        };
      }

      dispatch(editReadynessContentRequest(payLoad));
    }
  };

  const onHanldeAddEdit = (data) => {
    setApiCall(true);
    const uniqueId = uuidv4();
    const modifedColumn = {};
    modifedColumn.id = isOpenEditEditor ? data.id : uniqueId;
    modifedColumn.Activities = data.activities;
    modifedColumn.Timeframe = data.timeframe;
    modifedColumn.Budget = isOpenEditEditor ? initialValues.budget : "";
    modifedColumn.Target = data.target;

    modifedColumn.managedata = (
      <div className="d-flex align-items-center justify-content-center">
        <span>
          <EditIcon
            onClick={() => {
              setInitialValues({
                ...data,
                budget: initialValues.budget,
                table: selectTable,
                id: isOpenEditEditor ? data.id : uniqueId,
              });
              setSelectTable(selectTable);
              setIsOpenEditor(true);
              setIsOpenEditEditor(true);
            }}
            className="cursor-pointer"
          />
        </span>
        <span className="ml-2">
          <DeleteIcon
            className="cursor-pointer"
            onClick={() => {
              setInitialValues({
                ...data,
                table: selectTable,
                id: isOpenEditEditor ? initialValues?.id : uniqueId,
              });
              setSelectTable(selectTable);
              setDeleteModal(true);
            }}
          />
        </span>
      </div>
    );
    if (isOpenEditEditor) {
      if (selectTable === 1) {
        const updatedArray = readynessPPState.map((obj) =>
          obj.id === initialValues?.id ? { ...modifedColumn, id: obj.id } : obj,
        );
        setReadynessPPState([...updatedArray]);
      }
      if (selectTable === 2) {
        const updatedArray = readynessRRState.map((obj) =>
          obj.id === initialValues?.id ? { ...modifedColumn } : obj,
        );
        setReadynessRRState([...updatedArray]);
      }
      if (selectTable === 3) {
        const updatedArray = readynessPrState.map((obj) =>
          obj.id === initialValues?.id ? { ...modifedColumn, id: obj.id } : obj,
        );
        setReadynessPrState([...updatedArray]);
      }
    } else {
      if (selectTable === 1) {
        setReadynessPPState([modifedColumn, ...readynessPPState]);
      }
      if (selectTable === 2) {
        setReadynessRRState([modifedColumn, ...readynessRRState]);
      }
      if (selectTable === 3) {
        setReadynessPrState([modifedColumn, ...readynessPrState]);
      }
    }
    setIsOpenEditor(false);
    setIsOpenEditEditor(false);

    setAddNewRow((prev) => ({
      ...prev,
      id: "",
      activities: "",
      timeframe: "",
      target: "",
      table: "",
    }));
    setInitialValues((prev) => ({
      ...prev,
      id: "",
      activities: "",
      timeframe: "",
      target: "",
    }));
    setSelectTable();
  };

  useEffect(() => {
    if (!isOpenEditor) {
      setAddNewRow((prev) => ({
        ...prev,
        id: "",
        activities: "",
        timeframe: "",
        target: "",
        table: "",
      }));
      setInitialValues((prev) => ({
        ...prev,
        id: "",
        activities: "",
        timeframe: "",
        target: "",
      }));
      setAddNewRow((prev) => ({
        ...prev,
        id: "",
        activities: "",
        timeframe: "",
        target: "",
        table: "",
      }));
      setSelectTable();
      if (callApi) {
        onDataChange();
      }
    }
  }, [isOpenEditor]);
  useEffect(() => {
    if (!filterModal) {
      if (callApi) {
        onDataChange();
      }
    }
  }, [filterModal]);

  useEffect(() => {
    if (!deleteModal) {
      setInitialValues((prev) => ({
        ...prev,
        id: "",
        activities: "",
        timeframe: "",
        target: "",
      }));
      setAddNewRow((prev) => ({
        ...prev,
        id: "",
        activities: "",
        timeframe: "",
        target: "",
        table: "",
      }));
      setSelectTable();
      if (callApi) {
        onDataChange();
      }
    }
  }, [deleteModal]);

  // deleting value from json file
  const handleDeleteApiCall = () => {
    setApiCall(true);
    if (selectTable === 1) {
      const updatedArray = readynessPPState.filter(
        (obj) => obj.id !== initialValues?.id,
      );
      setReadynessPPState([...updatedArray]);
    }
    if (selectTable === 2) {
      const updatedArray = readynessRRState.filter(
        (obj) => obj.id !== initialValues?.id,
      );
      setReadynessRRState([...updatedArray]);
    }
    if (selectTable === 3) {
      const updatedArray = readynessPrState.filter(
        (obj) => obj.id !== initialValues?.id,
      );
      setReadynessPrState([...updatedArray]);
    }
  };

  const onHandleInterventionData = (data) => {
    setInterventationdataList((prev) => ({
      ...prev,
      name: data?.name,
      indicator: data?.indicator,
      people: data?.people,
      houseHolds: data?.houseHolds,
      file: imageData,
      base64,
    }));
  };

  const onHandleComments = () => {
    dispatch(
      editReadynessContentRequest({
        intervention_id: iId,
        project_id: JSON.parse(localStorage.getItem("projectDetails"))?.id,
        comment: [
          {
            name: userDetails?.user_details?.[1]?.first_name,
            comment: sendComment,
            date: new Date(),
          },
        ],
      }),
    );
  };

  const onHandleDownloadexcel = (data, fileNmae) => {
    handleExportToCSVIntervantion(data, ColumnsData, fileNmae);
  };

  return (
    <>
      <div className="ea-project-container">
        <div className="EA-Header ea-space-between">
          <div className="ea-header-image-position">
            <div>
              <BsArrowLeft
                className="back-icon-ea"
                onClick={handleClickBack}
                role="button"
              />
              <p className="ea-header-text">{interventionName}</p>
            </div>
          </div>
        </div>
        <div className="ea-space">
          <Row className="new-inv-div">
            <Col lg={2}>
              {intervantationData?.base64 ? (
                <img
                  src={intervantationData?.base64}
                  alt=""
                  className="new-inv-img"
                />
              ) : (
                <img src={ICON} alt="" className="new-inv-img" />
              )}
            </Col>
            <Col lg={10} className="new-inv-heading">
              <EditIcon
                className="float-right pointer"
                onKeyDown={() => {}}
                onClick={() => setFilterModal(true)}
              />

              <h3>
                {intervantationData?.name === ""
                  ? t("EARLY_ACTIONS.NEW_INTERVENTION")
                  : intervantationData?.name}
              </h3>
              <p>
                {t("EARLY_ACTIONS.INDICATOR")}: {intervantationData?.indicator}
              </p>
              <p>
                {t("EARLY_ACTIONS.TARGETED_HOUSEHOLDS")}:{" "}
                {intervantationData?.houseHolds}
              </p>
              <p>
                {t("EARLY_ACTIONS.PEOPLE")}: {intervantationData?.people}
              </p>
            </Col>
          </Row>
          <div className="new-inv-section">
            <div className="new-inv-section-2" ref={scrollref}>
              <div className="intervention-div">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="intervention-h2">
                    {t("EARLY_ACTIONS.PREPAREDNESS")}
                  </h2>
                  <div className="actions-container d-flex align-items-center justify-content-end">
                    <span
                      role="button"
                      className={
                        readynessPPState?.length > 0
                          ? "ea-upload-button d-none"
                          : "download-opacity ea-upload-button"
                      }
                      onClick={() =>
                        hanldeExportToCSV(
                          readynessPPState,
                          "preparedness_table",
                        )
                      }
                      onKeyUp={() => {}}
                      tabIndex="0"
                      aria-label="CSVreadynessPPState"
                    >
                      <DownloadIcon />
                    </span>

                    <span
                      role="button"
                      className={
                        readynessPPState?.length > 0
                          ? "ea-upload-button"
                          : "download-opacity ea-upload-button"
                      }
                      onClick={() =>
                        onHandleDownloadexcel(
                          readynessPPState,
                          "ea_pr_preparedness",
                        )
                      }
                      onKeyUp={() => {}}
                      tabIndex="0"
                      aria-label="DownloadreadynessPPState"
                    >
                      <DownloadIcon />
                    </span>

                    <Button
                      className="readiness-btn"
                      onClick={() => {
                        setSelectTable(1);
                        handleAddRow("", true);
                      }}
                    >
                      {t("EARLY_ACTIONS.ADD_NEW_ROW")}
                      <BsPlusLg className="ml-2" />
                    </Button>
                  </div>
                </div>
                <div className="new-inv-table">
                  <EarlyActionTable
                    COLUMNS={COLUMNS}
                    tableData={readynessPPState}
                    step={1}
                  />
                </div>
              </div>
              <div className="intervention-div">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="intervention-h2">
                    {t("EARLY_ACTIONS.READINESS")}
                  </h2>
                  <div className="actions-container d-flex align-items-center justify-content-end">
                    <input type="file" style={{ display: "none" }} />

                    <span
                      role="button"
                      className={
                        readynessRRState?.length > 0
                          ? "ea-upload-button d-none"
                          : "download-opacity ea-upload-button d-none"
                      }
                      onClick={() =>
                        hanldeExportToCSV(readynessRRState, "readyness_table")
                      }
                      onKeyUp={() => {}}
                      tabIndex="0"
                      aria-label="CvsReadynessPhase"
                    >
                      <DownloadIcon />
                    </span>

                    <span
                      role="button"
                      className={
                        readynessPPState?.length > 0
                          ? "ea-upload-button "
                          : "download-opacity ea-upload-button"
                      }
                      onClick={() =>
                        onHandleDownloadexcel(
                          readynessRRState,
                          "readyness_table",
                        )
                      }
                      onKeyUp={() => {}}
                      tabIndex="0"
                      aria-label="CvsreadynessPPState1"
                    >
                      <DownloadIcon />
                    </span>

                    <Button
                      className="readiness-btn"
                      onClick={() => {
                        handleAddRow("", true);
                        setSelectTable(2);
                      }}
                    >
                      {t("EARLY_ACTIONS.ADD_NEW_ROW")}
                      <BsPlusLg className="ml-2" />
                    </Button>
                  </div>
                </div>
                <div className="new-inv-table">
                  <EarlyActionTable
                    COLUMNS={COLUMNS}
                    tableData={readynessRRState}
                  />
                </div>
              </div>
              <div className="intervention-div">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="intervention-h2">
                    {t("EARLY_ACTIONS.PRIORITY_EA")}
                  </h2>
                  <div className="actions-container d-flex align-items-center justify-content-end">
                    <span
                      role="button"
                      className={
                        readynessPrState?.length > 0
                          ? "ea-upload-button d-none"
                          : "download-opacity ea-upload-button d-none"
                      }
                      onClick={() =>
                        hanldeExportToCSV(readynessPrState, "priority_table")
                      }
                      onKeyUp={() => {}}
                      tabIndex="0"
                      aria-label="CsvReadynessPrState"
                    >
                      <DownloadIcon />
                    </span>

                    <span
                      role="button"
                      className={
                        readynessPrState?.length > 0
                          ? "ea-upload-button"
                          : "download-opacity ea-upload-button"
                      }
                      onClick={() =>
                        onHandleDownloadexcel(
                          readynessPrState,
                          "priority_table",
                        )
                      }
                      onKeyUp={() => {}}
                      tabIndex="0"
                      aria-label="CsvPrioritytable"
                    >
                      <DownloadIcon />
                    </span>

                    <Button
                      className="readiness-btn"
                      onClick={() => {
                        handleAddRow("", true);
                        setSelectTable(3);
                      }}
                    >
                      {t("EARLY_ACTIONS.ADD_NEW_ROW")}
                      <BsPlusLg className="ml-2" />
                    </Button>
                  </div>
                </div>
                <div className="new-inv-table">
                  <EarlyActionTable
                    COLUMNS={COLUMNS}
                    tableData={readynessPrState}
                  />
                </div>
              </div>
              <div className="intervention-div">
                <Comments
                  onHandleComments={onHandleComments}
                  setComment={setSendComment}
                  sendComment={sendComment}
                  commentList={commentList}
                  canAddComment
                />
              </div>
            </div>
          </div>
        </div>
        {filterModal ? (
          <RightSideModal
            onHandleInterventionData={onHandleInterventionData}
            intervantationData={intervantationData}
            toggle={() => setFilterModal(!filterModal)}
            isOpenEditEditor={isOpenEditEditor}
            setImageData={setImageData}
            imageData={imageData}
            setBase64={setBase64}
            base64={base64}
            setApiCall={setApiCall}
            filterModal={filterModal}
            setFilterModal={setFilterModal}
            setOnChangeImage={setOnChangeImage}
            onChangeImage={onChangeImage}
          />
        ) : (
          ""
        )}
        {isOpenEditor ? (
          <ReadynessTable
            setAddNewRow={setAddNewRow}
            addNewRow={addNewRow}
            onHanldeAddEdit={onHanldeAddEdit}
            initialValues={initialValues}
            isOpenEditEditor={isOpenEditEditor}
            isOpenEditor={isOpenEditor}
            setIsOpenEditor={setIsOpenEditor}
            toggle={() => {
              setIsOpenEditor(false);
              setIsOpenEditEditor(false);
            }}
            setApiCall={setApiCall}
          />
        ) : (
          ""
        )}
      </div>

      {deleteModal ? (
        <DeleteModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          handleDeleteApiCall={handleDeleteApiCall}
          setApiCall={setApiCall}
        />
      ) : (
        ""
      )}
      {(filterModal || isOpenEditor || deleteModal) && (
        <div className="ea-project-card-disabled " />
      )}
    </>
  );
}
export default NewIntervention;
