import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import AWARE_CONST from "./actionTypes";
import {
  awareAboutSuccess,
  awareAboutError,
  awareToolsSuccess,
  awareToolsError,
  awareTeamListError,
  awareTeamListSuccess,
  uploadDocumentsSuccess,
  uploadDocumentsError,
  uploadDocumentsListingSuccess,
  uploadDocumentsListingError,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import { CLIMBER_BANNER_API, AXIOS_INSTANCE } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* awareAbout(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${CLIMBER_BANNER_API}/about-us/${action.payload?.system}`,
      ),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(awareAboutSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(awareAboutError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(awareAboutError(errorData));
  }
}

function* awareTools() {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${CLIMBER_BANNER_API}/aware-tools`),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(awareToolsSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(awareToolsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(awareToolsError(errorData));
  }
}

function* awareTeam() {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${CLIMBER_BANNER_API}/teams`),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(awareTeamListSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(awareTeamListError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(awareTeamListError(errorData));
  }
}

function* uploadDocuments(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(
        `${CLIMBER_BANNER_API}/general_docs/upload`,
        action.payload,
      ),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(uploadDocumentsSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(uploadDocumentsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(uploadDocumentsError(errorData));
  }
}

function* documentsListing(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${CLIMBER_BANNER_API}/general_docs/list?page=${action?.payload?.page}&limit=${action?.payload?.limit}&type=${action?.payload?.type}`,
      ),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(uploadDocumentsListingSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(uploadDocumentsListingError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(uploadDocumentsListingError(errorData));
  }
}

export function* watchAwareAbout() {
  yield takeEvery(AWARE_CONST.AWARE_ABOUT_REQUEST, awareAbout);
  yield takeEvery(AWARE_CONST.AWARE_TOOLS_REQUEST, awareTools);
  yield takeEvery(AWARE_CONST.AWARE_TEAM_LIST_REQUEST, awareTeam);
  yield takeEvery(AWARE_CONST.UPLOAD_DOCUMENTS_REQUEST, uploadDocuments);
  yield takeEvery(
    AWARE_CONST.UPLOAD_DOCUMENTS_LISTING_REQUEST,
    documentsListing,
  );
}
function* AwareSaga() {
  yield all([fork(watchAwareAbout)]);
}
export default AwareSaga;
