import React, { useState } from "react";
import { Carousel, CarouselItem, CarouselControl } from "reactstrap";
import classnames from "classnames";

function CarouselComponent({ carouselData }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === carouselData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? carouselData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    carouselData.length && (
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <ol className="carousel-indicators">
          {carouselData.length !== 0
            ? carouselData?.map((item, index) => {
                return (
                  <li
                    key={item.title}
                    onClick={() => goToIndex(index)}
                    onKeyDown={() => {}}
                    className={classnames({
                      active: activeIndex === index,
                    })}
                  />
                );
              })
            : ""}
        </ol>
        {carouselData && carouselData.length !== 0
          ? carouselData.map((item) => {
              return (
                <CarouselItem
                  onExiting={() => setAnimating(true)}
                  onExited={() => setAnimating(false)}
                  key={item.id}
                >
                  <div className="banner-carousel">
                    <img
                      src={item.image}
                      alt="text"
                      className="carousal-images"
                      width="100%"
                      height="100%"
                    />
                    <div className="heading">
                      <p className="carousel-heading"> {item.title} </p>
                    </div>
                  </div>
                </CarouselItem>
              );
            })
          : ""}

        {carouselData?.length > 1 ? (
          <CarouselControl
            direction="prev"
            directionText=""
            onClickHandler={previous}
          />
        ) : (
          ""
        )}
        {carouselData?.length > 1 ? (
          <CarouselControl
            direction="next"
            directionText=""
            onClickHandler={next}
          />
        ) : (
          ""
        )}
      </Carousel>
    )
  );
}

export default CarouselComponent;
