import React from "react";
import { Row, Col } from "reactstrap";
import DocumentNavigation from "./documentnavigator";
import DocumenttableListing from "./documentListingTable";

function EAproject({
  setSelectedCategory,
  selectedCategory,
  documentsList,
  pageCount,
  onHandleFiles,
  setIsMediaPreviewModal,
  setSelectedVideo,
  selectedVedio,
  setSelectedImage,
  selectedImage,
  setSelectedImagesIndex,
  // pageCount,
  pageLimit,
  setPageCount,
  setPageLimit,
  pageNumber,
  setPageNumber,
  setPageSize,
  pageSize,
  handleGoToPage,
  handlePageSize,
  disabled,
}) {
  return (
    <Row className="du-card-container">
      <Col sm={2} className="h-100 w-98">
        <DocumentNavigation
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
        />
      </Col>

      <Col sm={10} className="h-100">
        <DocumenttableListing
          documentsList={documentsList}
          selectedCategory={selectedCategory}
          pageCount={pageCount}
          onHandleFiles={onHandleFiles}
          setIsMediaPreviewModal={setIsMediaPreviewModal}
          setSelectedVideo={setSelectedVideo}
          selectedVedio={selectedVedio}
          setSelectedImage={setSelectedImage}
          selectedImage={selectedImage}
          setSelectedImagesIndex={setSelectedImagesIndex}
          pageLimit={pageLimit}
          setPageCount={setPageCount}
          setPageLimit={setPageLimit}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageSize={pageSize}
          handleGoToPage={handleGoToPage}
          handlePageSize={handlePageSize}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
}

export default EAproject;
