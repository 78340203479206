const PREPAREDNESS_PHASE_CONST = {
  // Edit
  PREPAREDNESS_PHASE_EDIT_REQUEST: "PREPAREDNESS_PHASE_EDIT_REQUEST",
  PREPAREDNESS_PHASE_EDIT_SUCCESS: "PREPAREDNESS_PHASE_EDIT_SUCCESS",
  PREPAREDNESS_PHASE_EDIT_ERROR: "PREPAREDNESS_PHASE_EDIT_ERROR",

  // DELETE
  PREPAREDNESS_PHASE_DELETE_REQUEST: "PREPAREDNESS_PHASE_DELETE_REQUEST",
  PREPAREDNESS_PHASE_DELETE_SUCCESS: "PREPAREDNESS_PHASE_DELETE_SUCCESS",
  PREPAREDNESS_PHASE_DELETE_ERROR: "PREPAREDNESS_PHASE_DELETE_ERROR",

  // Create
  PREPAREDNESS_PHASE_CREATE_REQUEST: "PREPAREDNESS_PHASE_CREATE_REQUEST",
  PREPAREDNESS_PHASE_CREATE_SUCCESS: "PREPAREDNESS_PHASE_CREATE_SUCCESS",
  PREPAREDNESS_PHASE_CREATE_ERROR: "PREPAREDNESS_PHASE_CREATE_ERROR",
};
export default PREPAREDNESS_PHASE_CONST;
