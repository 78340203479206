import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import ClimberFooter from "../../components/Common/ClimberFooter";
import { orderBy, otherTools } from "../../constants";

import {
  otherToolsRequest,
  focusAreasRequest,
  coreRequest,
  climberPartnerRequest,
} from "../../store/actions";
import otherToolsDisp from "../../assest/img/otherToolsDisp.png";

function OtherTools() {
  window.scrollTo(0, 0);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoged, setIsLoged] = useState(false);

  /* ------handling api data---------*/
  const nextProps = useSelector((state) => ({
    focusAreasData: state.OtherTools.focusAreasData,
    countriesList: state.Core?.coreList,
    otherToolsListData: state.OtherTools?.otherToolsListData,
    partnerImages: state.Climber?.climberPartnerData,
  }));

  const history = useNavigate();
  const handleClickBack = () => {
    history("/climber");
  };

  useEffect(() => {
    setIsLoged(JSON.parse(localStorage.getItem("isLoged")));
    dispatch(
      otherToolsRequest({
        order: otherTools,
      }),
    );
  }, []);

  useEffect(() => {
    setIsLoged(JSON.parse(localStorage.getItem("isLoged")));
  }, [localStorage.getItem("isLoged")]);

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")) {
      // setFocusAreaLabel(t("OTHER_TOOLS.DROUGHT"));
      dispatch(focusAreasRequest());
      dispatch(
        coreRequest({
          location_type: "country",
        }),
      );
      if (location?.state?.country?.label) {
        dispatch(
          climberPartnerRequest({
            country_id: location?.state?.country?.value,
            order: orderBy,
          }),
        );
      } else {
        dispatch(
          climberPartnerRequest({
            order: orderBy,
          }),
        );
      }
    }
  }, [localStorage.getItem("i18nextLng")]);

  const openNewTab = (url) => {
    if (url) {
      const urlToOpen = url; // Replace with your desired URL
      window.open(urlToOpen, "_blank");
    }
  };
  return (
    <div className="climber-main-div">
      <div className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              className="d-block w-100 position-relative"
              src={otherToolsDisp}
              alt=""
            />
          </div>
        </div>
        <div className="position-absolute other-tools-text">
          <h3>{t("OTHER_TOOLS.OTHER_TOOLS")}</h3>
        </div>
      </div>
      <div className="tools-main-dev">
        <h3 className="bread-crum">
          <span
            onClick={handleClickBack}
            role="button"
            tabIndex="0"
            onKeyUp={() => {}}
            style={{ color: "#22ad7a", fontSize: "16px" }}
          >
            {t("HOME.GO_TO_ClimBeR")}
          </span>{" "}
          <span
            style={{ color: "#22ad7a", fontWeight: "650", fontSize: "16px" }}
          >
            {t("OTHER_TOOLS.OTHER_TOOLS")}
          </span>{" "}
        </h3>
        <div className="our-patner">
          <Row className="cards-row">
            <div className=" climber-cards-row">
              {nextProps?.otherToolsListData?.map((eachCard) => {
                return (
                  <div
                    key={eachCard?.title}
                    role="button"
                    tabIndex="0"
                    onKeyUp={() => {}}
                    className="climber-card other-tools-card"
                    onClick={() => {
                      openNewTab(eachCard?.url);
                    }}
                  >
                    <div>
                      <img src={eachCard?.icon} alt="OtherTools" />
                      <h3>
                        {eachCard?.url
                          ? eachCard?.title
                          : eachCard?.title + t("LOADING.COMING_SOON")}
                      </h3>
                    </div>
                    <p>{eachCard?.desc}</p>
                  </div>
                );
              })}
            </div>
            {nextProps?.otherToolsListData?.length < 1 ? (
              <div className="cards-col">
                <h3>{t("OTHER_TOOLS.CURRENTLY_NO_OTHER_TOOLS")}</h3>
              </div>
            ) : (
              ""
            )}
          </Row>
        </div>
      </div>
      <div className="climber-section-2">
        <Row className="">
          <Col sm={12} className="mt-5 p-0">
            {!isLoged ? (
              <div className="pre-footer">
                <div className="pre-footer-div">
                  <p>{t("CLIMBER_HEADER.KEEP_IN")}</p>
                  <div>
                    <span>{t("CLIMBER_HEADER.REGISTER_TEXT")}</span>
                    <button
                      type="button"
                      className="btn"
                      onClick={() => history("/registerPage")}
                    >
                      {t("CLIMBER_HEADER.REGISTER")}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <ClimberFooter page="climber" />
    </div>
  );
}

export default OtherTools;
