import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MaterialReactTable from "material-react-table";
import BackButton from "../../assest/img/back-button.svg";
import { showSuccess } from "../../components/Common/Notification";
import {
  NEWSFEED_APPROVAL_TYPES,
  RAPID_RESPONSE_NEWSFEED_DISASTER_TYPES,
} from "../../constants";
import { dateFormatters } from "../../helpers";
import { getDisasterAlertListRequest } from "../../store/actions";
import PaginationSection from "../ManageUser/PaginationSection";
import ViewDisasterEvents from "./ViewDisasterEvents";

function ApproveDisasterEvents() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();

  const [selectedCountry, setSelectedCountry] = useState({});
  const [disasterEvents, setDisasterEvents] = useState([]);
  const [selectedDisasterType, setSelectedDisasterType] = useState({
    label: "All",
    value: "",
  });
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [isViewDeatilsOpen, setIsViewDetailsOpen] = useState(false);
  const [selectedDisasterEvent, setSelectedDisasterEvent] = useState({});
  const [latLng, setLatLng] = useState([]);

  const COLUMNS = useMemo(
    () => [
      {
        header: t("RAPID_RESPONSE.TITLE"),
        accessorKey: "title",
        size: 120,
      },
      {
        header: t("RAPID_RESPONSE.DISASTER_TYPE"),
        accessorKey: "disaster_type",
        size: 50,
      },
      {
        header: t("RAPID_RESPONSE.LOCATION"),
        accessorKey: "disaster_location",
        size: 50,
      },
      {
        header: t("RAPID_RESPONSE.UPLOADED_ON"),
        accessorKey: "disaster_created_date",
        size: 50,
      },
      {
        header: t("RAPID_RESPONSE.UPLOADED_BY"),
        accessorKey: "disaster_created_by",
        size: 50,
      },
      {
        header: t("RAPID_RESPONSE.STATUS"),
        accessorKey: "approval_type",
        size: 50,
      },
      {
        header: "Actions",
        accessorKey: "disaster_view_details",
        size: 50,
      },
    ],
    [],
  );

  const nextProps = useSelector((state) => ({
    disasterAlertList: state.RapidResponse.disasterAlertListData,
    disasterApproveData: state.RapidResponse.disasterEventApprovalData,
    disasterApproveError: state.RapidResponse.disasterEventApprovalDataError,
  }));

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("selectedCountry"));
    if (!_.isEmpty(data)) {
      dispatch(
        getDisasterAlertListRequest({
          is_backoffice: true,
          country_id: data.value,
        }),
      ); // FOR CREATED DISASTERS ALERTS
      setSelectedCountry(data);
    }
  }, [localStorage.getItem("selectedCountry")]);

  const isApproveRun = useRef(true);
  useEffect(() => {
    if (isApproveRun.current) {
      isApproveRun.current = false;
      return;
    }

    if (nextProps.disasterApproveData) {
      showSuccess(nextProps.disasterApproveData.message);
      dispatch(
        getDisasterAlertListRequest({
          is_backoffice: true,
          country_id: selectedCountry.value,
        }),
      );
    }
  }, [nextProps.disasterApproveData]);

  const handleShow = (userdata) => {
    setSelectedDisasterEvent(userdata);
    setLatLng([userdata.geometry?.lat, userdata.geometry?.lon]);
    setIsViewDetailsOpen(!isViewDeatilsOpen);
  };

  const isDisasterAlertListRun = useRef(true);
  useEffect(() => {
    if (isDisasterAlertListRun.current) {
      isDisasterAlertListRun.current = false;
      return;
    }

    if (nextProps?.disasterAlertList) {
      if (nextProps?.disasterAlertList?.results?.length) {
        const modifiedData = nextProps?.disasterAlertList?.results?.map(
          (alert) => {
            const disasterEvent = RAPID_RESPONSE_NEWSFEED_DISASTER_TYPES.find(
              (event) => event.value === alert.type,
            );
            return {
              ...alert,
              approval_type: (
                <p
                  className={`event-status-pending ${
                    alert.status === "APPROVED"
                      ? "event-status-approved"
                      : alert.status === "REJECTED"
                      ? "event-status-rejected"
                      : ""
                  }`}
                >
                  {alert.status}
                </p>
              ),
              title: (
                <p className="font-weight-bold desc-trunate">{alert.name}</p>
              ),
              disaster_type: (
                <div className="event-info-details my-2">
                  <img
                    src={disasterEvent?.icon}
                    alt={disasterEvent?.label}
                    width={16}
                    height={16}
                    className="mr-1"
                  />
                  <span>{disasterEvent?.label}</span>
                </div>
              ),
              disaster_location: (
                <p>
                  {alert.country_name}, {alert.state_name},{" "}
                  {alert.district_name}
                </p>
              ),
              disaster_created_date: (
                <p>{dateFormatters(alert.created_date)}</p>
              ),
              disaster_created_by: <p>{alert.source}</p>,
              disaster_view_details: (
                <span
                  role="button"
                  tabIndex="0"
                  onKeyUp={() => {}}
                  onClick={() => handleShow(alert)}
                  className="views-events-details"
                >
                  {t("RAPID_RESPONSE.VIEW_DETAILS")}
                </span>
              ),
            };
          },
        );
        setPageCount(nextProps.disasterAlertList?.count);
        setDisasterEvents([...modifiedData]);
      }
    }
  }, [nextProps.disasterAlertList]);

  const handleFilterSelection = (selectedOption) => {
    setSelectedDisasterType(selectedOption);
    setDisasterEvents([]);
    // settings pagination to normal
    setPageSize(10);
    setPageLimit(10);
    setPageNumber(1);

    // payload
    const payload = {
      is_backoffice: true,
      country_id: selectedCountry.value,
    };
    if (selectedOption.label !== "All") {
      payload.status = selectedOption.value;
    }
    if (!_.isEmpty(selectedCountry)) {
      dispatch(getDisasterAlertListRequest(payload)); // FOR CREATED DISASTERS ALERTS
    }
  };

  const handleGoToPage = (value) => {
    setPageNumber(Number(value));
    const payload = {
      is_backoffice: true,
      country_id: selectedCountry.value,
      page: value,
      limit: pageSize,
    };
    if (selectedDisasterType.label !== "All") {
      payload.status = selectedDisasterType.value;
    }
    if (!_.isEmpty(selectedCountry)) {
      dispatch(getDisasterAlertListRequest(payload)); // FOR CREATED DISASTERS ALERTS
    }
  };
  const handlePageSize = (value) => {
    setPageSize(value);
    setPageLimit(value);
    setPageNumber(1);

    const payload = {
      is_backoffice: true,
      country_id: selectedCountry.value,
      page: 1,
      limit: value,
    };
    if (selectedDisasterType.label !== "All") {
      payload.status = selectedDisasterType.value;
    }
    if (!_.isEmpty(selectedCountry)) {
      dispatch(getDisasterAlertListRequest(payload)); // FOR CREATED DISASTERS ALERTS
    }
  };

  return (
    <div className="feedback-main-page">
      {isViewDeatilsOpen &&
      !_.isEmpty(selectedDisasterEvent) &&
      latLng?.length ? (
        <ViewDisasterEvents
          isOpen={isViewDeatilsOpen}
          latLng={latLng}
          selectedDisasterEvent={selectedDisasterEvent}
          toggle={setIsViewDetailsOpen}
        />
      ) : null}
      <div className="d-flex align-items-center justify-content-between">
        <div className="back-office-main-header back-office-center w-50 m-0">
          <img
            src={BackButton}
            alt="back-button"
            onClick={() => history("/settings")}
            onKeyUp={() => {}}
            className="pointer"
            width={64}
            height={64}
          />
          <h2 className="discription-text heading-text-user-guide">
            {t("SETTINGS.AAPROVE_DIASATER_EVENTS")}
          </h2>
        </div>
        <div className="event-filter-container justify-content-end">
          {NEWSFEED_APPROVAL_TYPES?.map((option) => (
            <div
              key={option.label}
              onKeyUp={() => {}}
              role="button"
              tabIndex={0}
              className={`event-filter-options mr-2 ${
                selectedDisasterType.label === option.label
                  ? "event-active-filter"
                  : ""
              }`}
              onClick={() => handleFilterSelection(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>

      <div className="manageUser-table m-0">
        <MaterialReactTable
          columns={COLUMNS}
          data={disasterEvents}
          rowCount={pageSize}
          manualPagination
          initialState={{ showColumnFilters: false }}
          muiTableHeadCellProps={{
            align: "left",
            sx: {
              fontFamily: "National",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 650,
              lineHeight: "20.75px",
            },
          }}
          muiTableBodyCellProps={{
            align: "left",
            sx: {
              fontWeight: 500,
              color: "#363636",
              fontFamily: "National",
              fontSize: "14px",
              fontStyle: "normal",
              lineHeight: "16.37px",
            },
          }}
        />
        {disasterEvents?.length ? (
          <div className="mt-3">
            <PaginationSection
              pageCount={pageCount}
              pageLimit={pageLimit}
              setPageCount={setPageCount}
              setPageLimit={setPageLimit}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setPageSize={setPageSize}
              pageSize={pageSize}
              handleGoToPage={handleGoToPage}
              handlePageSize={handlePageSize}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ApproveDisasterEvents;
