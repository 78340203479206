import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import RegisterCheckMarck from "../../assest/img/RegisterCheckmark.png";

function SuccessModal(props) {
  const { t } = useTranslation();
  const {
    okayButtonClickHandler,
    modalSuccessMessage,
    modalHeaderMessage,
    isWeb,
  } = props;
  const handleButtonClick = () => {
    okayButtonClickHandler(true);
  };

  return (
    <div className="reg-success">
      <img
        src={RegisterCheckMarck}
        alt="not-available"
        className="reg-check-image"
      />
      <h2 className="reset-succes-head-text"> {modalHeaderMessage}</h2>
      <p className="reset-succes-desc">{modalSuccessMessage}</p>
      {isWeb ? (
        ""
      ) : (
        <Button
          type="button"
          className="ok-button"
          onClick={() => handleButtonClick()}
        >
          {" "}
          {t("LOGIN.OK")}{" "}
        </Button>
      )}
    </div>
  );
}
export default SuccessModal;
