import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import "react-multi-date-picker/styles/colors/green.css";
import { Button, FormGroup, Label } from "reactstrap";
import Close from "../../assest/img/close.png";
import { Months } from "../../constants";
import { formickReactSelectFiled } from "../../components/Common/FormickField";

function RightSideModal({
  countryList,
  setSelectedLocation,
  selectedLocation,
  stateList,
  districtsList,
  riskList,
  setSelectedRisk,
  selectedRisk,
  setOpenfilter,
  handleFilteredData,
  filterProjectName,
  clearFilters,
  yearList,
  availableDate,
  setSelectedYear,
  setMonthList,
  monthList,
  selectedyear,
  selectedMonths,
  setSelectedMonths,
  setDistrictsList,
  setStateList,
  openFilter,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    // Add a CSS class to the body element when the sideEditor is open
    if (openFilter) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [openFilter]);

  const initialValues = {
    country: selectedLocation?.country,
    state: selectedLocation?.state,
    district: selectedLocation?.district,
    riskType: selectedRisk,
    projectName: filterProjectName,
    year: "",
    month: selectedMonths,
  };

  const onCountryChange = (e) => {
    setSelectedLocation((prev) => {
      return {
        ...prev,
        country: e,
        state: "",
        district: "",
      };
    });
    setDistrictsList([]);
    if (e?.value === "") {
      setStateList([]);
    }
  };

  const onStateChange = (e) => {
    setSelectedLocation((prev) => {
      return {
        ...prev,

        state: e,
        district: "",
      };
    });

    if (e?.value === "") {
      setDistrictsList([]);
    }
  };

  const onDistrictChange = (e) => {
    setSelectedLocation((prev) => {
      return {
        ...prev,

        district: e,
      };
    });
  };

  const onRiskTypeChange = (e) => {
    setSelectedRisk(e);
  };

  function checkAndPushObjects(arr1, arr2, prop) {
    const newArray = [];

    arr2.forEach((item1) => {
      const valueToCheck = item1;
      const data1 = arr1.find((item2) => item2[prop] === valueToCheck);

      if (data1) {
        newArray.push(data1);
      }
    });

    return newArray;
  }

  const onSelectYear = (e) => {
    if (e.value === "") {
      setSelectedMonths("");
      setMonthList([]);

      setSelectedYear(e);
    } else {
      const data1 = checkAndPushObjects(Months, availableDate[e.value], "id");
      setMonthList([
        {
          value: "",
          label: "Select",
        },
        ...data1,
      ]);

      setSelectedYear(e);
    }
  };

  return (
    <div className="st-right-sidebar-wrapper-filter">
      <div className="st-filter-header-manageuser">
        <h3 className="st-text-green">{t("FILTER.FILTER")}</h3>
        <div
          className="st-close ml-auto cursor"
          role="button"
          tabIndex="0"
          onKeyUp={() => {}}
          onClick={() => setOpenfilter(false)}
        >
          <img className="st-close-img" alt="not-available" src={Close} />
        </div>
      </div>
      <hr />
      <Formik initialValues={initialValues} enableReinitialize>
        {(formick) => (
          <Form id="filter-form">
            <div className="st-collapse-padding">
              <FormGroup className="mt-2 mb-3">
                <Field
                  type="select"
                  name="country"
                  component={formickReactSelectFiled}
                  placeholder={t("MANAGE_USERS.COUNTRY")}
                  options={countryList}
                  label={t("MANAGE_USERS.COUNTRY")}
                  className="common-select-r"
                  value={selectedLocation.country}
                  disabled
                  selectProvience={onCountryChange}
                />
              </FormGroup>

              <FormGroup className="mt-4">
                <Field
                  type="select"
                  name="state"
                  component={formickReactSelectFiled}
                  placeholder={t("EARLY_ACTIONS.STATE")}
                  options={stateList}
                  label={t("EARLY_ACTIONS.STATE")}
                  className="common-select-r"
                  value={selectedLocation.state}
                  selectProvience={onStateChange}
                />
              </FormGroup>

              <FormGroup className="mt-4">
                <Field
                  type="select"
                  name="district"
                  component={formickReactSelectFiled}
                  placeholder={t("EARLY_ACTIONS.SELECT_DISTRICT")}
                  options={districtsList}
                  label={t("EARLY_ACTIONS.DISTRICT")}
                  className="common-select-r"
                  value={selectedLocation.district}
                  selectProvience={onDistrictChange}
                />
              </FormGroup>

              <FormGroup className="mt-4">
                <Field
                  type="select"
                  name="riskType"
                  component={formickReactSelectFiled}
                  placeholder={t("EARLY_ACTIONS.SELECT_RISK_TYPE")}
                  options={riskList}
                  label={t("EARLY_ACTIONS.RISK_TYPE")}
                  className="common-select-r"
                  value={selectedRisk}
                  selectProvience={onRiskTypeChange}
                />
              </FormGroup>

              {yearList?.length ? (
                <div className="common-select-register">
                  {selectedyear && selectedyear?.value !== "" && (
                    <Label className="label">{t("GEOGLOWS.YEAR")}</Label>
                  )}
                  <Select
                    classNamePrefix="react-select"
                    placeholder={t("GEOGLOWS.YEAR")}
                    name="year"
                    options={yearList}
                    value={selectedyear}
                    onChange={onSelectYear}
                    className="common-select-r"
                  />
                </div>
              ) : (
                ""
              )}
              {Object.keys(selectedyear)?.length !== 0 &&
                selectedyear.value !== "" && (
                  <FormGroup className="mt-4">
                    <Field
                      type="select"
                      name="month"
                      component={formickReactSelectFiled}
                      placeholder={t("GEOGLOWS.MONTH")}
                      options={monthList}
                      label={t("GEOGLOWS.MONTH")}
                      className="common-select-r"
                      value={selectedMonths}
                      selectProvience={(e) => setSelectedMonths(e)}
                    />
                  </FormGroup>
                )}
            </div>

            <hr />
            <div className="filter-buttons">
              <Button
                className="btn clear-filter-btn-manageuser st-btn-custom-manageuser "
                onClick={() => {
                  formick.resetForm();
                  formick.setFieldValue("projectName", "");
                  clearFilters();
                }}
              >
                {t("FILTER.CLEAR_FILTERS")}
              </Button>
              <Button
                className="btn manage-user-btn-custom manage-user-submit-btn"
                onClick={handleFilteredData}
              >
                {t("FILTER.SHOW_RESULT")}
              </Button>
            </div>
            <hr />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RightSideModal;
