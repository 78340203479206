import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import MapImg from "../../assest/img/map-bg.png";
import EarlyActionTable from "../../components/Common/EarlyActionTable";
import EarlyActionProcess from "../../components/Common/EarlyActionProcess";

function ActivePhase() {
  const { t } = useTranslation();
  const history = useNavigate();
  const [locationState, setLocationState] = useState();
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("projectDetails"));
    setLocationState(data);
  }, []);
  const handleClickBack = () => {
    history("/readiness-phase");
  };
  const tableData = [
    {
      table: "Category",
      status: <p className="EA-status-text">Completed</p>,
    },
    {
      table: "Impact",
      status: <p className="EA-status-text">Completed</p>,
    },
  ];
  const COLUMNS = [
    {
      Header: t("EARLY_ACTIONS.TABLE"),
      accessorKey: "table",
      size: 300,
    },
    {
      Header: t("APPROVE_DATA.STATUS"),
      accessorKey: "status",
      size: 100,
    },
  ];
  return (
    <div className="ea-project-container">
      <div className="EA-Header ea-space-between">
        <div>
          <BsArrowLeft
            className="back-icon-ea"
            onClick={handleClickBack}
            role="button"
          />
          <p className="ea-header-text">{locationState?.name} </p>
        </div>
      </div>
      <div className="activeness-header">
        <EarlyActionProcess />
      </div>
      <div className="ea-space">
        <div className="new-inv-section-3">
          <div className="EA-active">
            <h3 className="EA-active-heading">Project 1 is activated</h3>
            <Button
              className="submit-next-btn"
              onClick={() => history("/early-finance-page")}
            >
              Go to Early Finance
            </Button>
          </div>
          <Row className="new-inv-section-2">
            <Col lg={4}>
              <div className="EA-status-box">
                <p>AA Community Plan name: Project 1</p>
                <p>Country: Senegal</p>
                <p>Region: Dakar</p>
                <p>District: Dakar</p>
                <p>Indicator: Flood Extend</p>
              </div>
              <img src={MapImg} alt="" className="EA-map-img" />
            </Col>
            <Col lg={8} className="active-phase-table">
              <div className="intervention-div">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="intervention-h2">
                    {t("EARLY_ACTIONS.PREPAREDNESS")}
                  </h2>
                </div>
                <div className="new-inv-table">
                  <EarlyActionTable COLUMNS={COLUMNS} tableData={tableData} />
                </div>
              </div>
              <div className="intervention-div">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="intervention-h2">
                    {t("EARLY_ACTIONS.READINESS")}
                  </h2>
                </div>
                <div className="new-inv-table">
                  <EarlyActionTable COLUMNS={COLUMNS} tableData={tableData} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
export default ActivePhase;
