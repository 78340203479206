import Cookies from "js-cookie";

function checkHttpStatus(response) {
  if (response.status >= 500 && response.status < 600) {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  } else if (response.status === 401 || response.status === 403) {
    localStorage.removeItem("loggedInUserDetails");
    localStorage.removeItem("isLoged");
    Cookies.remove("user");
    window.location.href = "/login";
  } else {
    return response;
  }
}

export default checkHttpStatus;
