import React, { useRef } from "react";
import { Row, Col, Label, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FaUserCircle } from "react-icons/fa";
import SendBtn from "../../assest/img/commentSend.svg";
import { renderDateTimeFormat } from "./Utils";

function Comments({
  setComment,
  onHandleComments,
  commentList,
  sendComment,
  canAddComment,
}) {
  const scrollRef = useRef(null);

  const { t } = useTranslation();
  // submitting the comment when pressed "ENTER"
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && sendComment !== "") {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
      onHandleComments();
    }
  };

  const onSubmitComments = () => {
    if (sendComment !== "") {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
      onHandleComments();
    }
  };
  return (
    <div className="comment-section">
      <Row className="comment-row">
        <Col
          sm={12}
          className="mx-auto d-flex flex-column justify-conten-start w-100 mb-2 p-0"
        >
          {canAddComment || (!canAddComment && commentList) ? (
            <Label className="region-label-comment mt-0 ml-2">
              {" "}
              {t("EARLY_ACTIONS.COMMENTS")}{" "}
            </Label>
          ) : (
            ""
          )}
          {canAddComment ? (
            <div className="send-container">
              <Input
                classNamePrefix="react-select"
                className="ea-text-field-comment"
                placeholder="Write your text here"
                maxLength={300}
                value={sendComment}
                onChange={(e) => setComment(e.target.value)}
                onKeyDown={handleKeyDown}
              />

              <img
                src={SendBtn}
                alt="send"
                className="send-btn"
                onClick={onSubmitComments}
                onKeyDown={() => {}}
              />

              {sendComment?.length > 250 ? (
                <p className="error">{t("VALIDATION.COMMENT_VALIDAATION")}</p>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>

      {commentList?.length ? (
        <div className="comments-container" ref={scrollRef}>
          <Row>
            {commentList?.map((eachComment) => (
              <Col sm={12} className="py-2 comments-box">
                <div>
                  <div className="d-flex mb-1 mt-1">
                    <FaUserCircle className="comment-user" />
                    <p className="comment-name"> {eachComment?.name}</p>
                    <p className="ml-4 comment-p">
                      {" "}
                      {renderDateTimeFormat(eachComment?.date)}
                    </p>
                  </div>
                  <p className="comment-p1"> {eachComment?.comment} </p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Comments;
