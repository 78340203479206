/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import { FormGroup, Row, Col, Container, Button, Label } from "reactstrap";
import { BsEyeSlashFill } from "react-icons/bs";
import { IoEyeSharp } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import { formickTextField } from "../../components/Common/FormickField";
import LoginBackgroundImage from "../../assest/img/LoginBackgroundImage.png";
import ClimberFooter from "../../components/Common/ClimberFooter";
import {
  isLogedIn,
  hasSuperUserPermissions,
  hasStaffPermission,
} from "../../components/Common/Utils";
import { validatePassword, validateEmail } from "../../helpers/validate";
import { loginUser } from "../../store/auth/actions";
import SocialLogin from "./SocialLogins";

function Login() {
  const [initialValues] = useState({});
  const history = useNavigate();
  const [passwordType, setPasswordType] = useState(true);
  const [myParams, setMyParams] = useState("");
  const [slug, setSlug] = useState("");
  const [locationState, setLocationState] = useState();
  const locationData = useLocation();
  window.scrollTo(0, 0);

  useEffect(() => {
    if (!isLogedIn()) {
      setLocationState(locationData);
    }
  }, [isLogedIn()]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nextProps = useSelector((state) => ({
    loginData: state.Login.loginData,
  }));
  const showPassword = () => {
    setPasswordType(!passwordType);
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("prevPath");
    const slug1 = urlParams.get("slug");
    if (myParam) {
      setMyParams(myParam);
    }
    if (slug1) {
      setSlug(slug1);
    }
    if (isLogedIn()) {
      history("/home");
    }
  }, []);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (nextProps.loginData?.result?.token) {
      if (
        isLogedIn() &&
        myParams &&
        JSON.parse(localStorage.getItem("selectedCountry"))
      ) {
        if (myParams === t("HOME.ONLINE_BULLETIN")) {
          history(`/online-bulletin?slug=${slug}`);
        } else if (myParams === t("DASHBOARD.EARLY_ACTION")) {
          history(`/earlyaction?slug=${slug}`);
        } else if (myParams === t("DASHBOARD.EARLY_FINANCE")) {
          history(`/early-finance-page?isFinance=true&slug=${slug}`);
        } else if (myParams === "rapid_response") {
          history(`/rapid-response-newsfeed?slug=rapid_response`);
        } else if (myParams === t("DASHBOARD.CROP_YIELD_SLUG")) {
          history(`/crop_yield_prediction?slug=${slug}`);
        } else {
          if (locationState?.state?.queryData?.length) {
            const url = locationState?.state?.queryData?.map((menuData) => {
              return menuData?.title === t("DASHBOARD.SECTORIAL_CLIMATE_RISKS")
                ? menuData?.menu_list[0]?.sub_menu_list?.length
                  ? `/early-warnings/${menuData?.menu_list[0]?.slug}/${menuData?.menu_list[0]?.sub_menu_list[0]?.slug}?module=${menuData?.menu_list[0]?.sub_menu_list[0]?.slug}&moduleId=${menuData?.menu_list[0]?.id}&mainModule=${menuData?.slug}&subModule=${menuData?.menu_list[0]?.slug}&id=${menuData?.id}`
                  : `/early-warnings/${menuData?.menu_list[0]?.slug}?module=${menuData?.menu_list[0]?.slug}&moduleId=${menuData?.menu_list[0]?.id}&mainModule=${menuData?.slug}&subModule=${menuData?.menu_list[0]?.slug}&id=${menuData?.id}`
                : `/early-warnings/${menuData?.menu_list[0]?.slug}?module=${menuData?.menu_list[0]?.slug}&moduleId=${menuData?.menu_list[0]?.id}&mainModule=${menuData?.slug}&subModule=${menuData?.menu_list[0]?.slug}&id=${menuData?.id}`;
            });
            history(url[0]);
          } else {
            locationState?.state?.queryData?.menu_list?.map((item) => {
              if (myParams === item?.slug) {
                if (item.sub_menu_list.length) {
                  history(
                    // eslint-disable-next-line max-len
                    `/early-warnings/${item.slug}/${item.sub_menu_list[0]?.slug}?module=${item.sub_menu_list[0]?.slug}&moduleId=${item.id}&mainModule=${locationState?.state?.queryData?.slug}&subModule=${item?.slug}&id=${item.sub_menu_list[0]?.id}`,
                  );
                } else {
                  history(
                    `/early-warnings/${item.slug}?module=${item?.slug}&moduleId=${item?.id}&mainModule=${locationState?.state?.queryData?.slug}&subModule=${item?.slug}`,
                  );
                }
              }
              return null;
            });
          }
        }
      } else {
        history("/home");
      }
      hasSuperUserPermissions(nextProps.loginData.user_details[0].is_superuser);
      hasStaffPermission(nextProps.loginData.user_details[0].is_staff);
    } else {
      history("/login");
    }
  }, [nextProps.loginData]);

  const handleSubmit = (values) => {
    const payload = {
      email: values.email,
      password: values.password,
      social_media: false,
      social_media_type: "",
      social_media_token: "",
    };

    dispatch(loginUser(payload));
  };

  const handleLogin = (provider, data) => {
    const payload = {
      email: "",
      password: "",
      social_media: true,
      social_media_type: provider,
      social_media_token:
        provider === "google" ? data : data?.access_token || data?.accessToken,
    };

    dispatch(loginUser(payload));
  };

  const handleForgotPassword = () => {
    history("/forgot-password");
  };
  return (
    <div>
      <div>
        <div className="sign-in-background flex-container">
          <Container fluid className="flex-container index-z">
            <div className="sign-in-card-responsive">
              <Row className="mb-4">
                <Col sm={12}>
                  <h2 className="sign-in-text">{t("LOGIN.SIGN_IN")}</h2>
                </Col>
              </Row>

              <Formik
                validateOnMount
                initialValues={initialValues}
                onSubmit={handleSubmit}
              >
                {(formick) => (
                  <Form>
                    <Row>
                      <Col sm={12} className="mb-3">
                        <FormGroup className="">
                          <Field
                            classNameActive="input-class-login"
                            classNameInactive={
                              formick?.errors?.email && formick?.touched?.email
                                ? "input-class-empty-login input-error-class"
                                : "input-class-empty-login"
                            }
                            type="email"
                            name="email"
                            component={formickTextField}
                            onChangeField={() => {}}
                            validate={validateEmail}
                            placeholder={t("LOGIN.EMAIL")}
                            lable={t("LOGIN.EMAIL")}
                          />
                          {formick?.errors?.email &&
                            formick?.touched?.email && (
                              <p className=" error">
                                {t(formick?.errors?.email)}
                              </p>
                            )}
                        </FormGroup>
                      </Col>
                      <Col sm={12} className="mb-3">
                        <FormGroup>
                          <Field
                            classNameActive="input-class-login"
                            classNameInactive={
                              formick?.errors?.password &&
                              formick?.touched?.password
                                ? "input-class-empty-login input-error-class"
                                : "input-class-empty-login"
                            }
                            type={passwordType ? "password" : "text"}
                            name="password"
                            component={formickTextField}
                            onChangeField={() => {}}
                            validate={validatePassword}
                            placeholder={t("LOGIN.PASSWORD")}
                            lable={t("LOGIN.PASSWORD")}
                          />
                          <span
                            className="show-eye-password"
                            role="button"
                            tabIndex="0"
                            onClick={showPassword}
                            onKeyUp={() => {}}
                          >
                            {passwordType ? (
                              <BsEyeSlashFill className="close-eye" />
                            ) : (
                              <IoEyeSharp className="show-eye" />
                            )}
                          </span>
                          {formick?.errors?.password &&
                            formick?.touched?.password && (
                              <p className=" error">
                                {t(formick?.errors?.password)}
                              </p>
                            )}
                        </FormGroup>
                        <FormGroup>
                          <Label className="forgot-password-label">
                            <span
                              className="forgot-password-link"
                              role="button"
                              tabIndex="0"
                              onClick={handleForgotPassword}
                              onKeyUp={() => {}}
                            >
                              {t("LOGIN.FORGOT_PASSWORD")}
                            </span>
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col sm={12} className="mb-3">
                        <Button
                          type="submit"
                          className="sign-in-button w-100"
                          disabled={!formick.isValid}
                        >
                          {" "}
                          {t("LOGIN.SIGN_IN")}{" "}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
              <Row>
                <hr
                  style={{ background: "#D9D9D9", height: "1px" }}
                  className="w-100 sign-hr-line mt-2 mb-0"
                />
                <div className="w-100">
                  <p className="sign-in-social-text">
                    {t("REGISTER.SOCIAL_SIGN_IN")}
                  </p>
                  <SocialLogin
                    iconContainerClassName="social-sign-in-icon-container"
                    size={40}
                    socialConatinerClassName="sign-in-social-container w-100 mb-3"
                    handleLogin={handleLogin}
                  />
                </div>
                <div className="text-center w-100 mb-3">
                  <p className="m-0 p-0 login-term-text mt-3 cursor-ponter">
                    {" "}
                    {t("LOGIN.DONT_ACCOUNT")}
                    <span
                      onClick={() => history("/registerPage")}
                      role="button"
                      tabIndex="0"
                      onKeyUp={() => {}}
                      className="register-link"
                    >
                      {t("LOGIN.REGISTER")}
                    </span>
                  </p>
                </div>
              </Row>
            </div>
          </Container>
        </div>
        <div className="login-footer">
          <img
            src={LoginBackgroundImage}
            alt="pic"
            className="sign-in-bg-image"
          />
        </div>
      </div>
      <ClimberFooter />
    </div>
  );
}

export default Login;
