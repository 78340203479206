import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";

function PreviewTable(props) {
  const { COLUMNS, tableData, isPreview, isBudget } = props;

  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const data = useMemo(() => tableData, [tableData]);

  return (
    <div className={isPreview ? "preview-table" : "ef-table"}>
      <MaterialReactTable
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 15, 20],
        }}
        columns={columns}
        data={data}
        muiTableBodyCellSkeletonProps={{
          sx: {
            height: "100%",
            maxHeight: isPreview ? "100%" : "600px",
            overflow: isPreview ? "unset" : "auto",
          },
        }}
        muiTableContainerProps={{
          sx: {
            height: "100%",
          },
        }}
        enableBottomToolbar={!isPreview}
        enableTopToolbar={false}
        enablePagination={!isPreview}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableHiding={false}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableGlobalFilter={false}
        enableSorting={false}
        enableColumnDragging={false}
        enableStickyHeader
        muiTableHeadCellProps={{
          align: "center",
          sx: {
            border: "1px solid white",
            fontFamily: "National",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 650,
            lineHeight: "normal",
            "& .css-1y3afkq": {
              whiteSpace: "normal",
            },
            "&. .css-1gijpgg-MuiTableHead-root": {
              top: 0,
            },
            zIndex: "0 !important",
          },
        }}
        muiTableBodyCellProps={{
          align: isBudget ? "center" : "left",
          sx: {
            border: "1px solid white",
            fontWeight: 500,
            color: "#363636",
            fontFamily: "National",
            fontSize: "16px",
            fontStyle: "normal",
            lineHeight: "normal",
          },
        }}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
      />
    </div>
  );
}

export default PreviewTable;
