import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import { useEffect, useRef } from "react";
// Load Highcharts modules
require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/indicators/macd")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
// require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/map")(Highcharts);

function HighChartGraph({ options, chartRef }) {
  return (
    <div className={chartRef === undefined ? "" : "cropyeld-padding"}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
}

export default HighChartGraph;
