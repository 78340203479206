import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Container, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RegisterCheckMarck from "../../assest/img/RegisterCheckmark.png";
import registerBackgroundImage from "../../assest/img/RegisterBackgroundImage.png";
import ClimberFooter from "../../components/Common/ClimberFooter";
import { userVerificationRequest } from "../../store/actions";

function RegisterVerification() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const [disabled, setisDisabled] = useState(true);
  const timeoutRef = useRef();

  const key = params.get("key");

  useEffect(() => {
    dispatch(
      userVerificationRequest({
        verification_code: key,
      }),
    );
  }, []);

  /* ------handling api data---------*/
  const nextProps = useSelector((state) => ({
    register: state.User,
    isLoading: state.Loader.isLoading,
  }));

  const loginNavigate = () => navigate("/login");

  const isRegisterSucess = useRef(true);
  useEffect(() => {
    if (isRegisterSucess.current) {
      isRegisterSucess.current = false;
      return;
    }
    if (
      nextProps.register?.VerifyRegisteration ||
      nextProps.register?.VerifyRegisterationError
    ) {
      timeoutRef.current = setTimeout(loginNavigate, 6000);
      setisDisabled(false);
    }
  }, [nextProps.register]);

  return (
    <div className="register-container">
      <div className="register-background flex-container   sucess-card-padding">
        <Container fluid className="flex-container index-z">
          <div
            className={
              !nextProps.register?.VerifyRegisterationError &&
              nextProps.isLoading === false
                ? "sign-in-card reset-success-card"
                : "sign-in-card-transparent reset-success-card"
            }
          >
            {!nextProps.register?.VerifyRegisterationError &&
              nextProps.isLoading === false && (
                <Row className="mb-4">
                  <Col sm={12}>
                    <div className="reg-success">
                      <img
                        src={RegisterCheckMarck}
                        alt="not-available"
                        className="reg-check-image"
                      />
                      <h2 className="reset-succes-head-text">
                        {" "}
                        {t("REGISTER.VERIFICATION_SUCESSFUL_REGISTER")}
                      </h2>
                      <p className="reset-succes-desc">
                        {t("REGISTER.USER_VERIFICATION_CONTENT")}
                      </p>
                      <Button
                        type="button"
                        className="sucess-ok-btn"
                        disabled={disabled}
                        onClick={() => {
                          loginNavigate();
                        }}
                      >
                        {t("REGISTER.OK")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
          </div>
        </Container>
      </div>

      <div>
        <img
          src={registerBackgroundImage}
          alt="pic"
          className="register-bg-image"
        />
      </div>
      <ClimberFooter />
    </div>
  );
}

export default RegisterVerification;
