import React from "react";
import { GeoJSON } from "react-leaflet";
import {
  MAP_PREFERENCES_LABELS,
  GEOJSON_COUNTRY_DATA,
} from "../../../constants";

function GeojsonComponent({
  mapPreferences,
  // districtBoundry,
  setDistrictJsonData,
  districtJsonData,
  geoJsonKeys,
  clickEventData,
  setScrollableyear,
  predectionDataLoadingRef,
}) {
  const handleBasinFeature = (feat, layer, countryData, locationType) => {
    const selectedCountryData = GEOJSON_COUNTRY_DATA?.find(
      (item) => item.selectedCountry === countryData?.label,
    );

    const labelprop = selectedCountryData
      ? selectedCountryData[locationType]
      : "";

    if (labelprop) {
      layer.bindTooltip(feat.properties[labelprop], {
        sticky: true,
        direction: "auto",
      });
    }
  };

  const handleEachFeatureChange = (countryData, locationType, feat, layer) => {
    const selectedCountryData = GEOJSON_COUNTRY_DATA?.find(
      (item) => item.selectedCountry === countryData?.label,
    );

    if (["major_rivers", "minor_rivers"].includes(locationType)) {
      const labelprop = feat?.properties?.Name;

      if (labelprop)
        layer.bindTooltip(labelprop, {
          sticky: true,
          direction: "auto",
        });
    } else {
      const labelprop = selectedCountryData
        ? selectedCountryData[locationType]
        : "";

      if (labelprop && feat.properties[labelprop])
        layer.bindTooltip(feat.properties[labelprop], {
          sticky: true,
          direction: "auto",
        });
    }
  };

  const handleEachFeatureChange1 = (countryData, locationType, feat, layer) => {
    layer.on({
      click: (e) => {
        if (
          clickEventData?.current === true &&
          predectionDataLoadingRef?.current === false
        ) {
          setDistrictJsonData(e.target?.feature);
          setScrollableyear({
            yearList: [],
            selectedYear: "",
          });
        }
      },
    });

    const selectedCountryData = GEOJSON_COUNTRY_DATA?.find(
      (item) => item.selectedCountry === countryData?.label,
    );

    if (["major_rivers", "minor_rivers"].includes(locationType)) {
      const labelprop = feat?.properties?.Name;

      if (labelprop)
        layer.bindTooltip(labelprop, {
          sticky: true,
          direction: "auto",
        });
    } else {
      const labelprop = selectedCountryData
        ? selectedCountryData[locationType]
        : "";

      if (labelprop && feat.properties[labelprop])
        layer.bindTooltip(feat.properties[labelprop], {
          sticky: true,
          direction: "auto",
        });
    }
  };
  return (
    <>
      {/* <GeoJSON
        key={
          `${
            districtBoundry?.features?.[0]?.properties?.[
              geoJsonKeys.districts_or_cities
            ]
          }${MAP_PREFERENCES_LABELS.DISTRICTS}` ||
          MAP_PREFERENCES_LABELS.DISTRICTS
        }
        data={districtBoundry || null}
        style={{
          color: mapPreferences.districts_or_cities.color,
          fillColor: "#0000",
          weight: 1,
        }}
        onEachFeature={(feat, layer) =>
          handleEachFeatureChange1(
            mapPreferences.districts_or_cities.country,
            "districts_or_cities",
            feat,
            layer,
          )
        }
      /> */}

      <GeoJSON
        key={
          districtJsonData?.properties?.[
            `${geoJsonKeys?.districts_or_cities}`
          ] || MAP_PREFERENCES_LABELS.DISTRICTS?.NPSC_LULC
        }
        data={districtJsonData || null}
        style={{
          color: mapPreferences.districts_or_cities.color,
          fillColor: "#0000",
          weight: 4,
          // pane: "overlayPane",
        }}
        onEachFeature={(feat, layer) =>
          handleEachFeatureChange(
            mapPreferences.districts_or_cities.country,
            "districts_or_cities",
            feat,
            layer,
          )
        }
      />

      <GeoJSON
        key={MAP_PREFERENCES_LABELS.COUNTRY}
        ref={mapPreferences.country.layer}
        style={{
          color: mapPreferences.country.color,
          fillColor: "#0000",
          weight: 1,
        }}
        onEachFeature={(feat, layer) =>
          handleEachFeatureChange(
            mapPreferences.country.country,
            "selectedCountry",
            feat,
            layer,
          )
        }
      />

      <GeoJSON
        key={MAP_PREFERENCES_LABELS.STATES}
        ref={mapPreferences.regions.layer}
        style={{
          color: mapPreferences.regions.color,
          fillColor: "#0000",
          weight: 1.6,
        }}
        onEachFeature={(feat, layer) =>
          handleEachFeatureChange(
            mapPreferences.regions.country,
            "states_or_provinces",
            feat,
            layer,
          )
        }
      />

      <GeoJSON
        key={MAP_PREFERENCES_LABELS.DISTRICTS}
        ref={mapPreferences.districts_or_cities.layer}
        style={{
          color: mapPreferences.districts_or_cities.color,
          fillColor: "#0000",
          weight: 1,
          pane: "overlayPane",
        }}
        onEachFeature={(feat, layer) =>
          handleEachFeatureChange1(
            mapPreferences.districts_or_cities.country,
            "districts_or_cities",
            feat,
            layer,
          )
        }
      />
      <GeoJSON
        key={MAP_PREFERENCES_LABELS.TALUKAS}
        ref={mapPreferences.talukas.layer}
        style={{
          color: mapPreferences.talukas.color,
          fillColor: "#0000",
          weight: 1,
        }}
        onEachFeature={(feat, layer) =>
          handleEachFeatureChange(
            mapPreferences.talukas.country,
            "talukas",
            feat,
            layer,
          )
        }
      />
      <GeoJSON
        key={MAP_PREFERENCES_LABELS.DISCHARGE_STATION}
        ref={mapPreferences.discharge_station.layer}
        style={{
          color: mapPreferences.discharge_station.color,
          fillColor: "#0000",
          weight: 1,
        }}
        onEachFeature={(feat, layer) => {
          handleEachFeatureChange(
            mapPreferences.discharge_station.country,
            "discharge_station",
            feat,
            layer,
          );

          // layer.on({
          //   click: (e) => onhandleDischargeStation(e),
          // });
        }}
      />
      <GeoJSON
        key={MAP_PREFERENCES_LABELS.RIVERS}
        ref={mapPreferences.rivers_or_reservoirs.layer}
        style={{
          color: mapPreferences.rivers_or_reservoirs.color,
          fillColor: "#0000",
          weight: 1,
        }}
      />

      <GeoJSON
        key={MAP_PREFERENCES_LABELS.BASINS}
        ref={mapPreferences.basins.layer}
        style={{
          color: mapPreferences.basins.color,
          fillColor: "#0000",
          weight: 1,
        }}
        onEachFeature={(feat, layer) =>
          handleBasinFeature(
            feat,
            layer,
            mapPreferences.basins.country,
            "basins",
          )
        }
      />

      <GeoJSON
        key={MAP_PREFERENCES_LABELS.SUB_BASIN}
        ref={mapPreferences.sub_basins.layer}
        style={{
          color: mapPreferences.sub_basins.color,
          fillColor: "#0000",
          weight: 1,
        }}
        onEachFeature={(feat, layer) =>
          handleBasinFeature(
            feat,
            layer,
            mapPreferences.sub_basins?.country,
            "sub_basins",
          )
        }
      />

      <GeoJSON
        key={MAP_PREFERENCES_LABELS.AWS}
        ref={mapPreferences.automatic_weather_station.layer}
        style={{
          color: mapPreferences.automatic_weather_station.color,
          fillColor: "#0000",
          weight: 1,
        }}
      />

      <GeoJSON
        key={MAP_PREFERENCES_LABELS.MAJOR_RIVERS}
        ref={mapPreferences.major_rivers.layer}
        style={{
          color: mapPreferences.major_rivers.color,
          fillColor: "#0000",
          weight: 1,
        }}
        onEachFeature={(feat, layer) =>
          handleEachFeatureChange(
            mapPreferences.major_rivers.country,
            "major_rivers",
            feat,
            layer,
          )
        }
      />

      <GeoJSON
        key={MAP_PREFERENCES_LABELS.MINOR_RIVERS}
        ref={mapPreferences.minor_rivers.layer}
        style={{
          color: mapPreferences.minor_rivers.color,
          fillColor: "#0000",
          weight: 1,
        }}
        onEachFeature={(feat, layer) =>
          handleEachFeatureChange(
            mapPreferences.minor_rivers.country,
            "minor_rivers",
            feat,
            layer,
          )
        }
      />
      <GeoJSON
        key={MAP_PREFERENCES_LABELS.HYDRO_STATIONS}
        ref={mapPreferences.hydro_stations.layer}
        style={{
          color: mapPreferences.hydro_stations.color,
          fillColor: "#0000",
          weight: 1,
        }}
        onEachFeature={(feat, layer) => {
          handleEachFeatureChange(
            mapPreferences.discharge_station.country,
            "hydro_stations",
            feat,
            layer,
          );

          // layer.on({
          //   click: (e) => onhandleHydrostatic(e),
          //   // click: (e) => onDichargeClick(e),
          // });
        }}
        // pointToLayer={pointToLayerHydroStations}
      />

      <GeoJSON
        key={MAP_PREFERENCES_LABELS.METEOROLOGICAL_LOCATIONS}
        ref={mapPreferences.meteorological_locations.layer}
        style={{
          color: mapPreferences.meteorological_locations.color,
          fillColor: "#0000",
          weight: 1,
        }}
        // onEachFeature={(feat, layer) => {
        //   handleHydroFeatureChange(feat, layer);
        // }}
        // pointToLayer={pointToLayer}
      />
    </>
  );
}

export default GeojsonComponent;
