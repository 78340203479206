import React, { useState, useEffect } from "react";
import { Row, Col, Modal, ModalBody, Label } from "reactstrap";
import { HiOutlineXMark } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import UploadIcon from "../../../../assest/img/upload-image.svg";

function PublishModel({ publishBulletin, toggle, modal, handlePreviewImage }) {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [validSize, setValidSize] = useState(false);

  const handlePublish = () => {
    publishBulletin();
    toggle();
  };

  const handleImage = (e) => {
    const pic = e.target.files[0];
    setSelectedImage(pic);
    handlePreviewImage(pic);
    const reader = new FileReader();
    reader.readAsDataURL(pic);
    reader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;
      image.onload = () => {
        if (image.height <= image.width && image.width > 450) {
          setValidSize(true);
        } else {
          setValidSize(false);
        }
      };
    };
  };

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  return (
    <div className="news-feed-alert-modal">
      <Row>
        <Col>
          <Modal
            className="modal-dialog modal-lg modal-dialog-centered publish-modal"
            isOpen={modal}
            toggle={toggle}
            centered
          >
            <div className="alert-heading">
              <div className="header-text">
                &nbsp;&nbsp;
                <h6 className="online-heading">
                  {t("BULLET_IN.UPLOAD_PREVIEW_IMAGE")}
                </h6>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  color: "white !important",
                }}
                className="cursor-ponter"
              >
                <HiOutlineXMark size={25} onClick={toggle} />
              </div>
            </div>
            <ModalBody className="text-center delete-modal-body">
              <div className="file-input-wrapper image-file">
                <Label>{t("BULLET_IN.UPLOAD_IMAGE")}:</Label>&nbsp;&nbsp;
                <Label
                  htmlFor="file"
                  className="upload-image-label form-control"
                >
                  {t("BULLET_IN.UPLOAD_IMAGE")}&nbsp;&nbsp;
                  <img
                    src={UploadIcon}
                    alt="not-available"
                    className="upload-icon"
                  />
                </Label>
                <input
                  id="upload_image"
                  name="file"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  className="upload-image"
                  onChange={(event) => {
                    handleImage(event);
                  }}
                />
              </div>
              {!validSize ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {" "}
                  {t("BULLET_IN.ADDING_UPDATING_IMAGE_SIZE_ERROR")}
                </span>
              ) : (
                <br />
              )}
              <div className="image-file1">
                <Label>Image Preview:</Label>&nbsp;&nbsp;
                <div className="image-box">
                  {imageUrl && selectedImage && (
                    <img
                      src={imageUrl}
                      alt={selectedImage.name}
                      height="150px"
                      width="350px"
                    />
                  )}
                </div>
              </div>
              <div className="mt-3">
                <button
                  className="btn bulletin-cb mx-2"
                  type="button"
                  onClick={toggle}
                >
                  {t("BULLET_IN.CANCEL")}
                </button>
                <button
                  className="btn btn-bulletin-submit mx-2"
                  onClick={handlePublish}
                  type="button"
                  disabled={!validSize}
                >
                  {t("BULLET_IN.PUBLISH")}
                </button>
              </div>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </div>
  );
}

export default PublishModel;
