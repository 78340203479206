import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";
// import {
//   LoginSocialFacebook,
//   // LoginSocialLinkedin,
//   // LoginSocialMicrosoft,
// } from "reactjs-social-login";

// import { ReactComponent as LinkedinIcon } from "../../assest/img/linkedin.svg";
// import { ReactComponent as FacebookIcon } from "../../assest/img/facebook.svg";
// import { ReactComponent as MicrosoftIcon } from "../../assest/img/microsoft.svg";
// import { showError } from "../../components/Common/Notification";
import {
  REACT_APP_GG_APP_ID,
  // REACT_APP_FB_APP_ID,
  // REACT_APP_LINKEDIN_APP_ID,
  // REACT_APP_LINKEDIN_APP_SECRET,
  // REACT_APP_MICROSOFT_APP_ID,
} from "../../constants";

// REDIRECT URL must be same with URL where the (reactjs-social-login) components is locate
// MAKE SURE the (reactjs-social-login) components aren't unmounted or destroyed before the ask permission dialog closes
// const REDIRECT_URI = window.location.href;
function SocialLogin({
  // iconContainerClassName,
  handleLogin,
  // size,
  socialConatinerClassName,
}) {
  // const { t } = useTranslation();

  const handleCallbackResponse = (response) => {
    handleLogin("google", response?.credential);
  };

  useEffect(() => {
    window?.google?.accounts?.id.initialize({
      client_id: REACT_APP_GG_APP_ID || "",
      callback: handleCallbackResponse,
    });

    // window?.google?.accounts?.id.prompt();
    window?.google?.accounts?.id.renderButton(
      document.getElementById("signInDiv"),
      { type: "icon", shape: "pill", size: "large" },
    );
  }, []);

  return (
    <div className={socialConatinerClassName}>
      <div id="signInDiv" />

      {/* <LoginSocialLinkedin
        client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ""}
        client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ""}
        redirect_uri={REDIRECT_URI}
        onResolve={({ provider, data }) => {
          handleLogin(provider, data);
        }}
        onReject={() => {
          showError(t("LOGIN.SOMETHING_WENT_WRONG"));
        }}
      >
        <div className={iconContainerClassName}>
          <LinkedinIcon width={size} height={size} />
        </div>
      </LoginSocialLinkedin> */}
      {/* <LoginSocialFacebook
        appId={REACT_APP_FB_APP_ID || ""}
        redirect_uri={REDIRECT_URI}
        onResolve={({ provider, data }) => {
          handleLogin(provider, data);
        }}
        onReject={() => {
          showError(t("LOGIN.SOMETHING_WENT_WRONG"));
        }}
      >
        <div className={iconContainerClassName}>
          <FacebookIcon width={size} height={size} />
        </div>
      </LoginSocialFacebook> */}

      {/* Hiding for now
      <LoginSocialMicrosoft
        client_id={REACT_APP_MICROSOFT_APP_ID || ""}
        redirect_uri={REDIRECT_URI}
        onResolve={({ provider, data }) => {
          handleLogin(provider, data);
        }}
        onReject={() => {
          showError(t("LOGIN.SOMETHING_WENT_WRONG"));
        }}
      >
        <div className={iconContainerClassName}>
          <MicrosoftIcon width={size} height={size} />
        </div>
      </LoginSocialMicrosoft> */}
    </div>
  );
}

export default SocialLogin;
