const READYNESSPHASE_PHASE_CONST = {
  // READYNESS Edit
  READYNESS_PHASE_EDIT_REQUEST: "READYNESS_PHASE_EDIT_REQUEST",
  READYNESS_PHASE_EDIT_SUCCESS: "READYNESS_PHASE_EDIT_SUCCESS",
  READYNESS_PHASE_EDIT_ERROR: "READYNESS_PHASE_EDIT_ERROR",

  // READYNESS CREATE
  READYNESS_PHASE_CREATE_REQUEST: "READYNESS_PHASE_CREATE_REQUEST",
  READYNESS_PHASE_CREATE_SUCCESS: "READYNESS_PHASE_CREATE_SUCCESS",
  READYNESS_PHASE_CREATE_ERROR: "READYNESS_PHASE_CREATE_ERROR",

  // DELETE TABLE
  READYNESS_PHASE_DELETE_REQUEST: "READYNESS_PHASE_DELETE_REQUEST",
  READYNESS_PHASE_DELETE_SUCCESS: "READYNESS_PHASE_DELETE_SUCCESS",
  READYNESS_PHASE_DELETE_ERROR: "READYNESS_PHASE_DELETE_ERROR",

  // intervention listing
  GET_READYNESS_INTERVENTIONS_LIST_REQUEST:
    "GET_READYNESS_INTERVENTIONS_LIST_REQUEST",
  GET_READYNESS_INTERVENTIONS_LIST_SUCCESS:
    "GET_READYNESS_INTERVENTIONS_LIST_SUCCESS",
  GET_READYNESS_INTERVENTIONS_LIST_ERROR:
    "GET_READYNESS_INTERVENTIONS_LIST_ERROR",

  // DELETE INTERVENTION

  INTERVENTION_DELETE_REQUEST: "INTERVENTION_DELETE_REQUEST",
  INTERVENTION_DELETE_SUCCESS: "INTERVENTION_DELETE_SUCCESS",
  INTERVENTION_DELETE_ERROR: "INTERVENTION_DELETE_ERROR",
};

export default READYNESSPHASE_PHASE_CONST;
