import { takeEvery, fork, all, call, put } from "redux-saga/effects";

// Login Redux States
import USER_MANAGE_GUIDE_CONSTS from "./actionTypes";
import {
  searchByCriteriaManageUserGuideSuccess,
  searchByCriteriaManageUserGuideFailed,
  manageUserGuideSubTopicsSuccess,
  manageUserGuideSubTopicsFailed,
  addUserGuideSuccess,
  addUserGuideFailure,
  deleteManageUserGuideSuccess,
  deleteManageUserGuideFailure,
  updateUserGuideSuccess,
  updateUserGuideFailure,
  publishManageUserGuideSuccess,
  publishManageUserGuideFailure,
  getUserSubCategoryGuideSuccess,
  getUserSubCategoryGuideFailure,
} from "./action";
import checkHttpStatus from "../apiUtils";
import { USER_GUIDE_URL, AXIOS_INSTANCE } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* ManageUserList(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(
        `${USER_GUIDE_URL}/getAllByCriteria${
          action?.payload.page ? `?page=${action?.payload.page}` : ""
        }`,
        action.payload?.data,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(searchByCriteriaManageUserGuideSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(searchByCriteriaManageUserGuideFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(searchByCriteriaManageUserGuideFailed(errorData));
  }
}

// to get sub topics
function* ManageUserSubTopicList(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(
        `${USER_GUIDE_URL}/getAllTopicSubtopic`,
        action.payload,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(manageUserGuideSubTopicsSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(manageUserGuideSubTopicsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(manageUserGuideSubTopicsFailed(errorData));
  }
}
// add user guide
function* addUserGuide(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${USER_GUIDE_URL}/add`, action.payload),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(addUserGuideSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(addUserGuideFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(addUserGuideFailure(errorData));
  }
}

// update user guide
function* updateUserGuide(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.put(`${USER_GUIDE_URL}/update`, action.payload),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(updateUserGuideSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(updateUserGuideFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(updateUserGuideFailure(errorData));
  }
}

// delete manage user guide
function* deleteManageUserGuide(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.delete(
        `${USER_GUIDE_URL}/delete/${action?.payload?.id}`,
        action.payload,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(deleteManageUserGuideSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(deleteManageUserGuideFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteManageUserGuideFailure(errorData));
  }
}

// update user guide
function* publishUserGuide(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.put(`${USER_GUIDE_URL}/changeStatus`, action.payload),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(publishManageUserGuideSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(publishManageUserGuideFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(publishManageUserGuideFailure(errorData));
  }
}

// sub categories
function* getSubCategoriesList(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${USER_GUIDE_URL}/topic-category/${action.payload.topic_id}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getUserSubCategoryGuideSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(getUserSubCategoryGuideFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getUserSubCategoryGuideFailure(errorData));
  }
}

export function* watchManageUserList() {
  yield takeEvery(
    USER_MANAGE_GUIDE_CONSTS.GET_All_MANAGE_USER_GUIDE_LIST_REQUEST,
    ManageUserList,
  );
}

export function* watchManageUserSubTopicList() {
  yield takeEvery(
    USER_MANAGE_GUIDE_CONSTS.GET_All_MANAGE_USER_SUB_TOPICS_REQUEST,
    ManageUserSubTopicList,
  );
}

export function* watchAddUserGuide() {
  yield takeEvery(
    USER_MANAGE_GUIDE_CONSTS.ADD_USER_GUIDE_REQUEST,
    addUserGuide,
  );
}

export function* watchUpdateUserGuide() {
  yield takeEvery(
    USER_MANAGE_GUIDE_CONSTS.UPDATE_USER_GUIDE_REQUEST,
    updateUserGuide,
  );
}

export function* watchDeleteManageUserGuide() {
  yield takeEvery(
    USER_MANAGE_GUIDE_CONSTS.DELETE_USER_GUIDE_REQUEST,
    deleteManageUserGuide,
  );
}

export function* watchPublishManageUserGuide() {
  yield takeEvery(
    USER_MANAGE_GUIDE_CONSTS.PUBLISH_USER_GUIDE_REQUEST,
    publishUserGuide,
  );
}

export function* watchGetSubCategoriesList() {
  yield takeEvery(
    USER_MANAGE_GUIDE_CONSTS.GET_SUB_CATEGORY_GUIDE_REQUEST,
    getSubCategoriesList,
  );
}

function* ManageUserGuidesagaSaga() {
  yield all([
    fork(watchManageUserList),
    fork(watchManageUserSubTopicList),
    fork(watchAddUserGuide),
    fork(watchDeleteManageUserGuide),
    fork(watchUpdateUserGuide),
    fork(watchPublishManageUserGuide),
    fork(watchGetSubCategoriesList),
  ]);
}

export default ManageUserGuidesagaSaga;
