import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Button, FormGroup, Input, Label } from "reactstrap";
import "../../assest/css/DroughtGraph.css";
import { ReactComponent as DisasterInprogressIcon } from "../../assest/img/disaster_exposure_inprogress.svg";
import { ReactComponent as FloodInprogressIcon } from "../../assest/img/flood_inprogress.svg";
import { ReactComponent as RainfallInprogressIcon } from "../../assest/img/rainfall_inprogress.svg";
import CommonToolTip from "../../components/Common/CommonToolTip";
import CustomDateInput from "../../components/Common/CustomDateInput";
import RadioSubBasin from "../../components/Common/RadioSubBasin";
import { RAPID_RESPONSE_DATA_SOURCES } from "../../constants";
import {
  renderBasinsArrayOptions,
  renderReactOptionsArraystate,
} from "../../helpers";
import { dashBoardMenuRequest } from "../../store/actions";

function Filter({
  statesList,
  updatePage,
  handleSelectedLocation,
  selectedRegions,
  countryList,
  basinsList,
  selectedBasin,
  adminRadio,
  setAdminRadio,
  endDate,
  setEndDate,
  startDate,
  setStartDate,
  setRainfallThreshold,
  rainfallThreshold,
  setSelectedRainfallDataSource,
  selectedRainfallDataSource,
  activeStep,
  handleGetMap,
  mapPreferencesData,
  selectedFloodDataSource,
  setSelectedFloodDataSource,
  handleResetFunc,
  selectedLocation,
  setSelectedLocation,
  setSelectedExposureTypes,
  selectedExposureTypes,
  loadingMap,
  setIsDataSoruceChanged,
  setShowChart,
  setIndicatorList,
}) {
  const { t } = useTranslation();
  const scrollRef = useRef();

  const [regionListOptions, setRegionListOptions] = useState([]);
  const [basinListOptions, setBasinListOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedLocation) handleSelectedLocation(selectedLocation);
  }, [selectedLocation]);

  useEffect(() => {
    if (selectedLocation?.country) {
      const country = selectedLocation?.country;
      updatePage([country.latitude, country.longitude], country.zoom_level);
    }
  }, [
    localStorage.getItem("i18nextLng"),
    selectedLocation?.country,
    adminRadio,
  ]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("selectedCountry"));
    setSelectedLocation((prev) => {
      return {
        ...prev,
        country: data,
        state: "",
        basin: "",
        region: [],
      };
    });
  }, [localStorage.getItem("selectedCountry")]);

  const onSelectStates = (e, stateList, type) => {
    setIndicatorList([]);
    if (type === "basin") {
      const selectedOption = stateList.find((item) => item.label === e?.label);
      updatePage([e.latitude, e.longitude], e.zoom_level);
      setSelectedLocation((prev) => {
        return {
          ...prev,
          state: "",
          basin: selectedOption,
          region: [],
        };
      });
    } else {
      const selectedOption = stateList.find((item) => item.label === e?.label);
      updatePage([e.latitude, e.longitude], e.zoom_level);
      setSelectedLocation((prev) => {
        return {
          ...prev,
          state: selectedOption,
          basin: "",
          region: [],
        };
      });
    }
    scrollRef.current.scrollTop = 0;
  };

  const onCountrySelection = (e) => {
    setSelectedLocation((prev) => {
      return {
        ...prev,
        country: e,
        state: "",
        basin: "",
        region: [],
      };
    });
    setAdminRadio("admin");
    setIndicatorList([]);
    // for update left side menu based on selected country
    dispatch(dashBoardMenuRequest(e));
    localStorage.setItem("selectedCountry", JSON.stringify(e));
    // scrollRef.current.scrollTop = 0;
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (statesList?.data) {
      renderReactOptionsArraystate(
        statesList?.data,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
      )?.map((eachValue) => selectedRegions.push(eachValue));
      if (!statesList?.isCompare) {
        setRegionListOptions(selectedRegions);
      }
    }
  }, [statesList]);

  useEffect(() => {
    if (basinsList?.data) {
      renderBasinsArrayOptions(
        basinsList?.data,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
        "sub_basins",
      )?.map((eachValue) => selectedBasin.push(eachValue));
      // if (!basinsList?.isCompare) {
      setBasinListOptions(selectedBasin);
    }
  }, [basinsList]);

  // Handle start Date change
  const handleStartDateChange = (date) => {
    setStartDate(date);
    setEndDate(moment(date).add(30, "days").format("YYYY-MM-DD"));
  };

  // Handle select exposure types
  const handleSelectExposuretypes = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedExposureTypes((prev) => [...prev, item]);
    } else {
      setSelectedExposureTypes((prev) =>
        prev.filter((exposure) => exposure.value !== item.value),
      );
    }
  };

  const handleRainfallDataSourceChange = (value) => {
    setSelectedRainfallDataSource(value);
    setIsDataSoruceChanged(true);
    setShowChart(false);
  };

  return (
    <>
      {/* Radio buttons */}
      <RadioSubBasin
        adminRadio={adminRadio}
        setAdminRadio={setAdminRadio}
        selectedCountry={selectedLocation?.country}
      />
      <div
        className="dash-drought-col-div"
        ref={scrollRef}
        id="rapid-response-filter"
      >
        {/* Country field */}
        <Label className="region-label mt-0">{t("OTHER_TOOLS.COUNTRY")}</Label>
        <Select
          options={countryList}
          value={selectedLocation?.country}
          classNamePrefix="react-select"
          onChange={(e) => onCountrySelection(e)}
          className="dash-select common-select-r"
          placeholder="Country"
          isDisabled={activeStep >= 1 || loadingMap}
        />
        {/* States & Basins field */}
        <Label className="region-label">
          {adminRadio === "basin"
            ? t("DASHBOARD.BASIN")
            : t("DASHBOARD.REGION")}
        </Label>
        <Select
          options={
            adminRadio === "basin" ? basinListOptions : regionListOptions
          }
          value={
            adminRadio === "basin"
              ? selectedLocation.basin
              : selectedLocation.state
          }
          classNamePrefix="react-select"
          onChange={(e) => {
            adminRadio === "basin"
              ? onSelectStates(e, basinListOptions, "basin")
              : onSelectStates(e, regionListOptions, "");
          }}
          className="dash-select common-select-r"
          placeholder={t(
            adminRadio === "basin"
              ? "DASHBOARD.SELECT_BASIN"
              : "DASHBOARD.ALL_REGIONS",
          )}
          isDisabled={activeStep >= 1 || loadingMap}
        />

        {/* Dates Field */}
        <Label className="region-label"> {t("DASHBOARD.DATE_RANGE")}</Label>
        <div className="crp-datepicker">
          <DatePicker
            selected={startDate ? new Date(startDate) : ""}
            onChange={handleStartDateChange}
            dateFormat="dd/MM/yyyy"
            minDate={new Date(2010, 0, 1)}
            maxDate={new Date()}
            className="rp-datepicker"
            customInput={
              <CustomDateInput
                customPlaceholder={t("RAPID_RESPONSE.ENTER_START_DATE")}
                data={startDate}
                displaytext={t("RAPID_RESPONSE.START_DATE")}
                disabled={activeStep >= 1 || loadingMap}
              />
            }
            disabled={activeStep >= 1 || loadingMap}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>

        <div className="mt-3 crp-datepicker rp-datepicker">
          <DatePicker
            selected={endDate ? new Date(endDate) : ""}
            onChange={(e) => setEndDate(e)}
            dateFormat="dd/MM/yyyy"
            minDate={startDate || new Date(2010, 0, 1)}
            maxDate={new Date()}
            className="rp-datepicker"
            customInput={
              <CustomDateInput
                customPlaceholder={t("RAPID_RESPONSE.ENTER_END_DATE")}
                data={endDate}
                displaytext={t("RAPID_RESPONSE.END_DATE")}
                disabled={activeStep >= 1 || loadingMap}
              />
            }
            disabled={activeStep >= 1 || loadingMap}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>
        {moment(startDate).isSame(moment(endDate)) ? (
          <div className="minerror">
            {t("CLIMATE_WEATHER_FILTER.START_END_DATE_INVALID")}
          </div>
        ) : moment(startDate).isAfter(moment(endDate)) ? (
          <div className="minerror">
            startDate should not be less than endDate
          </div>
        ) : null}
        <div className="d-flex align-items-center mt-3" id="rainfall-select">
          <hr className="rp-filter-hr-line w-100  pl-1" />
          <div
            className={`d-flex align-items-center px-2 ${
              activeStep > 0 ? "rp-filter-active-step" : ""
            }`}
          >
            <RainfallInprogressIcon width={16} height={16} />
            <span className="pl-1 rp-filter-step-idicator">Rainfall</span>
          </div>
          <hr className="rp-filter-hr-line w-100 pr-1" />
        </div>
        {/* Rainfall Data source Field  */}
        <Label className="region-label">
          {t("RAPID_RESPONSE.RAINFALL_DATA_SOURCE_LABEL")}{" "}
        </Label>
        <Select
          classNamePrefix="react-select"
          options={RAPID_RESPONSE_DATA_SOURCES.rainfall}
          value={selectedRainfallDataSource}
          onChange={(e) => handleRainfallDataSourceChange(e)}
          className="dash-select common-select-r"
          placeholder={t("RAPID_RESPONSE.SELECT_DATA_SOURCE")}
          isDisabled={activeStep >= 1 || loadingMap}
          menuPlacement="top"
          menuPosition="fixed"
        />
        {/* Threshold Field */}
        {activeStep >= 0 && activeStep !== null ? (
          <div className="indicator mt-3">
            <Label className="region-label mt-0">
              {t("RAPID_RESPONSE.RAINFALL_THRESHOLD_LABEL")}
            </Label>
            <Input
              type="number"
              id="quantity"
              name="quantity"
              className="threshold-input"
              min="0"
              value={rainfallThreshold}
              onChange={(e) => setRainfallThreshold(e.target.value)}
              disabled={activeStep >= 1 || loadingMap}
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  // eslint-disable-next-line func-names
                  function (eve) {
                    eve.preventDefault();
                  },
                  { passive: false },
                )
              }
              style={
                rainfallThreshold === "" || rainfallThreshold < 10
                  ? { border: "1px solid red" }
                  : {}
              }
            />
          </div>
        ) : null}
        {rainfallThreshold === "" || rainfallThreshold < 10 ? (
          <div className="minerror">{t("RAPID_RESPONSE.THRESHOLD_ERROR")}</div>
        ) : (
          ""
        )}
        {/* flood Data source Field  */}
        {activeStep >= 1 ? (
          <>
            <div className="d-flex align-items-center mt-3">
              <hr className="rp-filter-hr-line w-100  pl-1" />
              <div
                className={`d-flex align-items-center px-2 ${
                  activeStep > 1 ? "rp-filter-active-step" : ""
                }`}
              >
                <FloodInprogressIcon width={16} height={16} />
                <span className="pl-1 rp-filter-step-idicator">Flood</span>
              </div>
              <hr className="rp-filter-hr-line w-100 pr-1" />
            </div>
            <Label className="region-label">
              {t("RAPID_RESPONSE.FLOOD_DATA_SOURCE_LABEL")}{" "}
            </Label>
            <Select
              classNamePrefix="react-select"
              options={RAPID_RESPONSE_DATA_SOURCES.flood}
              value={selectedFloodDataSource}
              onChange={(e) => {
                setSelectedFloodDataSource(e);
              }}
              className="dash-select common-select-r"
              placeholder={t("RAPID_RESPONSE.SELECT_DATA_SOURCE")}
              isDisabled={activeStep >= 2 || loadingMap}
              menuPlacement="top"
              menuPosition="fixed"
            />
          </>
        ) : null}
        {/* Exposure types source Field  */}
        {activeStep >= 2 ? (
          <>
            <div className="d-flex align-items-center mt-3">
              <hr className="rp-filter-hr-line w-100  pl-1" />
              <div
                className={`d-flex align-items-center px-2 ${
                  activeStep > 2 ? "rp-filter-active-step" : ""
                }`}
              >
                <DisasterInprogressIcon width={16} height={16} />
                <span className="pl-1 rp-filter-step-idicator">
                  Disaster Exposure
                </span>
              </div>
              <hr className="rp-filter-hr-line w-100 pr-1" />
            </div>
            <Label className="region-label">
              {t("RAPID_RESPONSE.DISATER_DATA_SOURCE_LABEL")}{" "}
            </Label>
            <div
              className={`rp-checkbox-container ${
                activeStep >= 3 ? "disabled" : ""
              }`}
            >
              {RAPID_RESPONSE_DATA_SOURCES?.exposures?.map((item, index) => (
                <>
                  <FormGroup
                    check
                    inline
                    className="d-flex justify-content-between my-2 pl-1"
                    key={item.label}
                  >
                    <Label check>{item.label}</Label>
                    <Input
                      type="checkbox"
                      className="m-0 cursor-pointer"
                      onChange={(e) => handleSelectExposuretypes(e, item)}
                      checked={selectedExposureTypes.some(
                        (selectedItem) => selectedItem.value === item.value,
                      )}
                      disabled={activeStep >= 3 || loadingMap}
                    />
                  </FormGroup>
                  {RAPID_RESPONSE_DATA_SOURCES?.exposures?.length - 1 !==
                  index ? (
                    <hr className="rp-hrline" />
                  ) : null}
                </>
              ))}
            </div>
          </>
        ) : null}
      </div>

      {/* Fixed Button */}
      <div className="btns-fixed-sidebar ">
        <div data-tip data-for="disablebtn">
          {activeStep <= 2 ? (
            mapPreferencesData?.length === 2 ? (
              <CommonToolTip
                message={t("MAP_PREFERENCES.INDICATOR_MESSAGES")}
                disabled
              >
                <Button className="green-btn-lg-disable">
                  {t("BULLET_IN.SUBMIT")}
                </Button>
              </CommonToolTip>
            ) : (
              <Button
                className="green-btn-lg"
                onClick={handleGetMap}
                disabled={
                  !(activeStep === null
                    ? selectedRainfallDataSource?.value !== "" &&
                      !moment(startDate).isSame(moment(endDate)) &&
                      !loadingMap
                    : activeStep === 0
                    ? rainfallThreshold !== "" &&
                      rainfallThreshold >= 10 &&
                      selectedRainfallDataSource?.value !== "" &&
                      !loadingMap
                    : activeStep === 1
                    ? selectedFloodDataSource?.value !== "" && !loadingMap
                    : activeStep === 2
                    ? selectedExposureTypes?.length && !loadingMap
                    : false)
                }
              >
                {t("BULLET_IN.SUBMIT")}
              </Button>
            )
          ) : null}

          {!loadingMap ? (
            <p className="reset" onKeyUp={() => {}} onClick={handleResetFunc}>
              {" "}
              {t("CROP_YIELD.RESET")}{" "}
            </p>
          ) : null}
        </div>
      </div>
    </>
  );
}
export default React.memo(Filter);
