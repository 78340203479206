import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import EARLY_ACTIONS_CONST from "./actionTypes";
import {
  getAllGuideLinesError,
  getAllGuideLinesSuccess,
  editGuideLinesError,
  editGuideLinesSuccess,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import { EARLY_ACTION_API, AXIOS_INSTANCE } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* earlyActionGuideLines(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${EARLY_ACTION_API}/guidelines/list?location_type=${action.payload?.location_type}&location_type_id=${action.payload?.location_type_id}&risk_type_id=${action?.payload?.risk_type_id}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getAllGuideLinesSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getAllGuideLinesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllGuideLinesError(errorData));
  }
}

function* earlyActionEditGuideLines(action) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.put(
        `${EARLY_ACTION_API}/guidelines/edit`,
        action.payload,
        config,
      ),
      "File uploaded sucessfully",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(editGuideLinesSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(editGuideLinesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(editGuideLinesError(errorData));
  }
}

export function* watchEarlyActionGuideLines() {
  yield takeEvery(
    EARLY_ACTIONS_CONST.GET_ALL_GUIDE_LINES_REQUEST,
    earlyActionGuideLines,
  );

  yield takeEvery(
    EARLY_ACTIONS_CONST.GET_EDIT_GUIDE_LINES_REQUEST,
    earlyActionEditGuideLines,
  );
}

function* EarlyActionGuideLinesSaga() {
  yield all([fork(watchEarlyActionGuideLines)]);
}

export default EarlyActionGuideLinesSaga;
