import GUIDE_LINES_CONST from "./actionTypes";

export const getAllGuideLinesRequest = (data) => {
  return {
    type: GUIDE_LINES_CONST.GET_ALL_GUIDE_LINES_REQUEST,
    payload: data,
  };
};
export const getAllGuideLinesSuccess = (user) => {
  return {
    type: GUIDE_LINES_CONST.GET_ALL_GUIDE_LINES_SUCCESS,
    payload: user,
  };
};
export const getAllGuideLinesError = (error) => {
  return {
    type: GUIDE_LINES_CONST.GET_ALL_GUIDE_LINES_ERROR,
    payload: error,
  };
};

export const editGuideLinesRequest = (data) => {
  return {
    type: GUIDE_LINES_CONST.GET_EDIT_GUIDE_LINES_REQUEST,
    payload: data,
  };
};
export const editGuideLinesSuccess = (user) => {
  return {
    type: GUIDE_LINES_CONST.GET_EDIT_GUIDE_LINES_SUCCESS,
    payload: user,
  };
};
export const editGuideLinesError = (error) => {
  return {
    type: GUIDE_LINES_CONST.GET_EDIT_GUIDE_LINES_ERROR,
    payload: error,
  };
};
