import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import { HiOutlineXMark } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { dashBoardMenuRequest } from "../../store/actions";
import { isLogedIn } from "../../components/Common/Utils";

function HomeModal({ isOpen, toggle, data, language, countrys }) {
  const { t } = useTranslation();
  const [dashBoardData, setdashboardData] = useState([]);
  const dispatch = useDispatch();
  const restdata = dashBoardData.filter((eachCard) => eachCard.id === data);
  useEffect(() => {
    dispatch(dashBoardMenuRequest());
  }, [localStorage.getItem("i18nextLng")]);

  const nextProps = useSelector((state) => ({
    dashBoardMenuList: state.Dashboard.dashBoardMenuData,
    otherToolsListData: state.OtherTools?.otherToolsListData,
  }));

  const isPartnerImages = useRef(true);
  useEffect(() => {
    if (isPartnerImages.current) {
      isPartnerImages.current = false;
      return;
    }
    if (nextProps.dashBoardMenuList) {
      setdashboardData([...nextProps.dashBoardMenuList]);
    }
  }, [nextProps.dashBoardMenuList]);

  return (
    <>
      {restdata.map((menudata) => {
        return menudata.menu_list.length !== 0 ? (
          <Modal
            isOpen={isOpen}
            onHide={toggle}
            animation={false}
            className="modal-dialog modal-lg Home-page-md"
          >
            <ModalHeader closeButton className="border-content">
              {t("HOME_POPUP.HEADER")}
              <Button onClick={toggle}>
                <HiOutlineXMark />
              </Button>
            </ModalHeader>
            <ModalBody>
              <h3 className="md-heading">
                {menudata.title} {t("HOME_POPUP.INDICATOR")}
              </h3>
              <Row md={12} lg={12} sm={12} xl={12} className="modalsize">
                {menudata.menu_list.map((each) => {
                  let url;
                  if (isLogedIn()) {
                    if (each.sub_menu_list.length) {
                      // eslint-disable-next-line max-len
                      url = `/early-warnings/${each.slug}/${each.sub_menu_list[0]?.slug}?module=${each.sub_menu_list[0]?.slug}&moduleId=${each.id}&mainModule=${menudata?.slug}&subModule=${each?.slug}&id=${each.sub_menu_list[0]?.id}`;
                    } else {
                      url = `/early-warnings/${each.slug}?module=${each?.slug}&moduleId=${each?.id}&mainModule=${menudata?.slug}&subModule=${each?.slug}`;
                    }
                  } else {
                    url = `/login?prevPath=${each?.slug}`;
                  }
                  return (
                    <Col md={4} lg={4} sm={4} xl={4}>
                      <Link
                        state={{
                          selectedLanguage: language,
                          queryData: menudata,
                        }}
                        country={{ country: countrys }}
                        to={url}
                        className="modal-cards"
                      >
                        <div className="home-md-text">
                          <img
                            src={each.icon}
                            alt="Agriculture"
                            className="icon4"
                          />
                          <p>{each.title}</p>
                        </div>
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            </ModalBody>
          </Modal>
        ) : null;
      })}
    </>
  );
}

export default HomeModal;
