import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import REGISTER_CONST from "./actionTypes";
import { provienceSuccess, provienceError } from "./action";
import checkHttpStatus from "../apiUtils";
import { CORE_API, AXIOS_INSTANCE } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* provsionlist(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${CORE_API}/location/list/${action.payload?.location_type}?parent_id=${action.payload.parent_id}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(provienceSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(provienceError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(provienceError(errorData));
  }
}

export function* watchCore() {
  yield takeEvery(REGISTER_CONST.PROVIENCE_REQUEST, provsionlist);
}

function* RegisterSaga() {
  yield all([fork(watchCore)]);
}

export default RegisterSaga;
