import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Row, Col, Collapse } from "reactstrap";
import _ from "lodash";
import { BsSearch } from "react-icons/bs"; // BiSolidChevronDown
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
// import { AiOutlineCaretUp } from "react-icons/ai";
// import { BsChevronDown } from 'react-icons/bs';
import RightArrow from "../../assest/img/back-button.svg";
import {
  getAllTopicsRequest,
  getAllTopicSuccess,
  getAllUserGuideRequest,
  getAllUserGuideSuccess,
  searchByCriteriaUserGuideRequest,
} from "../../store/actions";
import Loader from "../../components/Common/Loader";

function UserGuideDetailPage() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();
  const [, setOpen] = useOutletContext();
  const [getAllTopicDataList, setGetAllTopicDataList] = useState([]);
  // const [TopicHeading, setTopicHeading] = useState("");
  const [subTopicHeading, setSubTopicHeading] = useState([]);
  const [initialSubtopicHeading, setInitialSubtopicHeading] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [userguideData, setUserGuideData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [activeSubTopic, setActiveSubTopic] = useState(0);
  // const [wordEntered, setWordEntered] = useState("");
  const [searchEnteredSubTopics, setSearchEnteredSubTopics] = useState([]);
  const [, setNoResults] = useState(false); // noResults
  const [searchContentWordEntered, setSearchContentWordEntered] = useState("");
  const [pageNumber, setPageNumber] = useState();
  const [pageCount, setPageCount] = useState();
  const [topicId, setTopicId] = useState();
  const [groupedData, setGroupedData] = useState();
  const [selectedCollapse, setSelectedCollapse] = useState("");

  const search = window.location.search;
  const URLParams = new URLSearchParams(search);

  const params = new URLSearchParams(window.location.search);

  const selectedSubModule = params.get("slug");
  const searchSubTopicName = URLParams.get("sub-topic-name");
  const selectedModule = params.get("indicator");

  const categoryName = URLParams.get("categoryName");
  const selectedSubCategroyId = URLParams.get("id");
  const {
    getAllTopicData,
    getAllUserGuideData,
    searchByCriteriaUserGuideData,
    guideLoading,
    topicLoader,
  } = useSelector((state) => ({
    getAllTopicData: state.UserGuide.getAllTopicData,
    getAllUserGuideData: state.UserGuide.getAllUserGuideData,
    searchByCriteriaUserGuideData:
      state.UserGuide.searchByCriteriaUserGuideData,
    guideLoading: state.UserGuide.isGetAllUserGuideRequesting,
    topicLoader: state.UserGuide.isGetAllTopicsRequesting,
  }));
  const scrollToElement = (id) => {
    const element = document.getElementById(
      id.toLowerCase().trim().split(" ").join("_"),
    );

    if (!_.isEmpty(element)) {
      element?.scrollIntoView({ block: "start" });
      if (selectedSubCategroyId) {
        document
          .getElementById(selectedSubCategroyId)
          ?.scrollIntoView({ block: "start" });
      }

      window.scrollTo(0, 0);
    } else {
      window.scroll(0, 0);
    }
  };

  // function to scroll for selected topic/ sub-topic
  const toggle = (tab, id) => {
    setActiveSubTopic(id);
    scrollToElement(id);
  };
  useEffect(() => {
    setSubTopicHeading([]);

    const topicID = URLParams.get("topicId");
    setTopicId(topicID);
    setPageNumber(1);
    if (topicID) {
      dispatch(getAllTopicsRequest({ topic_id: topicID }));
      dispatch(
        getAllUserGuideRequest({
          page: 1,
          fromBackOffice: false,
          data: {
            topic_id: topicID,
            status: "True",
            is_delete: "False",
            is_backoffice: "False",
          },
        }),
      );
    }
    selectedModule && setSelectedCollapse(selectedModule);
    window.scrollTo({ top: 0, behavior: "instant" });
    setOpen(true);
    return () => {
      setUserGuideData([]);
      setAllData([]);
      setNoResults(false);
      setSubTopicHeading([]);
      setGetAllTopicDataList([]);
      dispatch(searchByCriteriaUserGuideRequest({}));
      dispatch(getAllTopicSuccess({}));
      dispatch(getAllUserGuideSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (getAllTopicData?.data) {
      const { data } = getAllTopicData.data;
      if (!_.isEmpty(data) && !_.isEmpty(data.result)) {
        setGetAllTopicDataList(data.result);
        setNoResults(false);
      }
    }
  }, [getAllTopicData]);

  useEffect(() => {
    if (getAllTopicDataList?.length) {
      const { sub_topic } = getAllTopicDataList[0];

      if (sub_topic?.length) {
        const groupedData1 = _(sub_topic)
          .groupBy("topic_category_name")
          .value();
        setGroupedData(groupedData1);
        setInitialSubtopicHeading(sub_topic);
        categoryName
          ? setSelectedCollapse(categoryName)
          : selectedModule
          ? setSelectedCollapse(selectedModule)
          : setSelectedCollapse(Object?.keys(groupedData1)?.[0]);
      } else {
        setGroupedData(getAllTopicDataList[0]);
      }

      if (sub_topic?.length === 0) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    }
  }, [getAllTopicDataList]);

  useEffect(() => {
    if (getAllUserGuideData?.data && !getAllUserGuideData?.fromBackOffice) {
      const { result } = getAllUserGuideData?.data;
      const getPageNumber = getAllUserGuideData?.pageNumber;
      if (result) {
        const updatedUserGuideData = [...userguideData];
        if (userguideData?.length) {
          const isPageNumberExists = userguideData?.findIndex(
            (i) => i.pageNumber === getPageNumber,
          );
          if (isPageNumberExists >= 0) {
            updatedUserGuideData[isPageNumberExists] = {
              ...updatedUserGuideData[isPageNumberExists],
              data: result.results,
            };
          } else {
            updatedUserGuideData.push({
              pageNumber: getPageNumber,
              data: result.results,
            });
          }
        } else {
          updatedUserGuideData.push({
            pageNumber: getPageNumber,
            data: result.results,
          });
        }
        const orderedData = _.orderBy(
          updatedUserGuideData,
          ["pageNumber"],
          ["asc"],
        ); // using lodash to sort array by pagenumber
        const allDataList = [];
        orderedData?.map((item) => item.data?.map((i) => allDataList.push(i)));
        setUserGuideData(orderedData);
        setPageCount(result?.count);
        if (allDataList?.length) {
          setAllData(allDataList);
        }
      } else {
        setUserGuideData([]);
        setAllData([]);
      }
    }
  }, [getAllUserGuideData]);

  const recursion = (pageNumbers, pageCounts) => {
    const pagenum = pageNumbers;
    const hasMore = pagenum * 10 < pageCounts;
    if (hasMore && topicId) {
      dispatch(
        getAllUserGuideRequest({
          page: pagenum + 1,
          fromBackOffice: false,
          data: {
            topic_id: topicId,
            status: "True",
            is_delete: "False",
            is_backoffice: "False",
          },
        }),
      );
      recursion(pagenum + 1, pageCounts);
    }
  };

  useEffect(() => {
    const hasMore = pageNumber * 10 < pageCount;
    if (hasMore) {
      recursion(pageNumber, pageCount);
    }
  }, [pageCount]);
  useEffect(() => {
    const a = [];
    if (allData && allData.length && initialSubtopicHeading.length) {
      allData?.map((element) => {
        const allDataElement = element?.subtopic_name;
        /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
        for (let j = 0; j < initialSubtopicHeading.length; j++) {
          if (
            initialSubtopicHeading[j]?.name?.trim() === allDataElement?.trim()
          ) {
            a.push(initialSubtopicHeading[j]);
            break;
          }
        }
        return null;
      });
      setSubTopicHeading(a);
    }
  }, [allData, initialSubtopicHeading]);

  useEffect(() => {
    let index;
    if (
      subTopicHeading.length >= pageCount - 2 &&
      subTopicHeading.length <= pageCount &&
      searchSubTopicName
    ) {
      subTopicHeading.map((item, idx) => {
        if (item.name === searchSubTopicName) {
          index = idx;
        }
        return null;
      });

      if (index && searchSubTopicName) {
        toggle(index, searchSubTopicName);
      }
    }
  }, [subTopicHeading?.length, allData?.length]);

  useEffect(() => {
    // const searchSubTopicName = URLParams.get("sub-topic-name");
    let index;
    if (subTopicHeading.length && searchSubTopicName) {
      subTopicHeading.map((item, idx) => {
        if (item.name === searchSubTopicName) {
          index = idx;
        }
        return null;
      });

      if (index !== undefined && searchSubTopicName) {
        toggle(index, searchSubTopicName);
      }
    }
  }, [subTopicHeading?.length, allData?.length]);

  const handleSearchContent = (e) => {
    const searchContent = e.target.value;
    setSearchContentWordEntered(searchContent);
  };

  useEffect(() => {
    const serachedSubTopicList = [];
    if (searchByCriteriaUserGuideData?.data) {
      const { data } = searchByCriteriaUserGuideData.data;
      if (!_.isEmpty(data) && !_.isEmpty(data.result)) {
        data.result.map((item) => {
          serachedSubTopicList.push(item);
          return null;
        });
        setNoResults(false);
        setSearchEnteredSubTopics(serachedSubTopicList);
      } else {
        setNoResults(true);
        setSearchEnteredSubTopics([]);
      }
    }
  }, [searchByCriteriaUserGuideData]);

  const handleManageCropsBackButton = () => {
    if (window.history.length === 1) {
      window.close();
    } else {
      history(-1);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.value?.length !== 0) {
      history({
        pathname: "/user-guide-search",
        search: `?q=${searchContentWordEntered}&slug=${selectedSubModule}`,
      });
    }
  };

  const handleClick = () => {
    if (searchContentWordEntered?.length !== 0) {
      history({
        pathname: "/user-guide-search",
        search: `?q=${searchContentWordEntered}`,
      });
    }
  };

  return (
    <>
      {" "}
      {(guideLoading || topicLoader) && <Loader isSuspense indicator />}
      <div className="bulletin-header detail-page" key="str">
        <div className="sidebar-user-guide">
          <div className="sidebarContainer">
            <span
              className="drought-category-style"
              onKeyDown={() => {
                setIsOpen(!isOpen);
              }}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              role="button"
              tabIndex={0}
            >
              <div className="pl-2">
                {getAllTopicDataList[0]?.sub_topic?.length && groupedData ? (
                  Object?.entries(groupedData).map(([k, v]) => {
                    return (
                      <div>
                        <span
                          className="user-guide-collaspe"
                          onClick={() =>
                            selectedCollapse === k
                              ? setSelectedCollapse("")
                              : setSelectedCollapse(k)
                          }
                          onKeyDown={() => {}}
                          role="button"
                          tabIndex="0"
                        >
                          <span className="topic-heading-text ">
                            {k === "null" ? getAllTopicDataList[0]?.name : k}
                          </span>

                          {selectedCollapse !== k ? (
                            <FaAngleDown
                              size={18}
                              className="collapse-icon-down"
                            />
                          ) : (
                            <FaAngleUp size={18} className="collapse-icon-up" />
                          )}
                        </span>

                        <Collapse isOpen={selectedCollapse === k}>
                          {v?.length && searchEnteredSubTopics?.length === 0
                            ? v.map((i, index) => {
                                return (
                                  <section className="mt-3 mb-3 mr-3" id={i.id}>
                                    <nav key={i}>
                                      <ul
                                        className={
                                          activeSubTopic === i.name
                                            ? "selected-item un-order-item links"
                                            : "un-selected-item un-order-item links"
                                        }
                                      >
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a
                                          onClick={() => {
                                            toggle(index, i.name);
                                          }}
                                          onKeyDown={() => {
                                            toggle(index, i.name);
                                          }}
                                          className={
                                            activeSubTopic === i.name
                                              ? "selected-item"
                                              : "un-selected-item"
                                          }
                                          style={{ textDecoration: "none" }} // border:"2px solid red"
                                          role="button"
                                          tabIndex={0}
                                        >
                                          {i.name}
                                        </a>
                                      </ul>
                                    </nav>
                                  </section>
                                );
                              })
                            : ""}
                        </Collapse>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <span
                      className="user-guide-collaspe"
                      onClick={() => setIsOpen(!isOpen)}
                      onKeyDown={() => {}}
                      role="button"
                      tabIndex="0"
                    >
                      <span className="topic-heading-text ">
                        {groupedData?.name}{" "}
                      </span>

                      {isOpen ? (
                        <FaAngleDown size={18} className="collapse-icon-down" />
                      ) : (
                        <FaAngleUp size={18} className="collapse-icon-up" />
                      )}
                    </span>
                  </div>
                )}
              </div>
            </span>
          </div>
        </div>

        <div className="detail-main-page md-pge1">
          <Row className="iconSearchContainer">
            <Col lg={1} sm={2} xs={3}>
              <img
                src={RightArrow}
                className="manage-static-header-back-button backarrow-position"
                onClick={() => handleManageCropsBackButton()}
                onKeyDown={() => handleManageCropsBackButton()}
                alt="right-arrow"
              />
            </Col>
            <Col lg={11} sm={10} xs={9}>
              <center className="sidebar-collapse">
                <span className="span-sidebar">
                  <div className="sidebarContainer">
                    <div className="search-box common-search-bar float-right">
                      <input
                        className="inputField input-box "
                        type="text"
                        placeholder={t("USER_GUIDE.INPUT_TEXT")}
                        value={searchContentWordEntered}
                        onChange={handleSearchContent}
                        onKeyPress={handleKeyPress}
                        onKeyDown={handleKeyPress}
                      />
                      <BsSearch
                        onClick={handleClick}
                        className="searchIcons user-guide-search-icon"
                      />
                    </div>
                  </div>
                </span>
              </center>
            </Col>
          </Row>
          <div className="user-guide-headers mx-3">
            {getAllTopicDataList[0]?.name}
          </div>
          <p className="mx-3 mb-2 mt-2">
            {getAllTopicDataList[0]?.description}
          </p>
          <hr className="m-3 mt-4" />
          {allData
            ? allData.map((i) => {
                return (
                  <div className="list" key={i}>
                    <section
                      className="m-3"
                      id={`${i?.subtopic_name?.toLowerCase()}`
                        .trim()
                        .split(" ")
                        .join("_")}
                    >
                      <p className="user-guide-detailed-heading-text">
                        {i.title}
                      </p>
                      <div
                        className="search-content-img discription-text-user-guide-detail"
                        dangerouslySetInnerHTML={{ __html: i?.content }}
                      />
                    </section>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
}

export default React.memo(UserGuideDetailPage);
