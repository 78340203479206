import RAPID_RESPONSE_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};
const RapidResponse = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    // map data request
    case RAPID_RESPONSE_CONST.GET_RAINFALL_MAP_DATA_REQUEST:
      state = {
        ...state,
        isRainfallMapDataRequesting: true,
        rainfallMapData: false,
        rainfallMapDataError: false,
        rainfallMapDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;
    case RAPID_RESPONSE_CONST.GET_RAINFALL_MAP_DATA_SUCCESS:
      state = {
        ...state,
        isRainfallMapDataRequesting: false,
        rainfallMapData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        rainfallMapDataError: false,
        rainfallMapDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;
    case RAPID_RESPONSE_CONST.GET_RAINFALL_MAP_DATA_FAILED:
      state = {
        ...state,
        isRainfallMapDataRequesting: false,
        rainfallMapData: false,
        rainfallMapDataError: action.payload.data,
        rainfallMapDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;

    // RAPID RESPONSE NEWSFEED
    case RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_NEWSFEED_DATA_REQUEST:
      state = {
        ...state,
        isNewsfeedDataRequesting: true,
        rapidResponseNewsfeedData: false,
        rapidResponseNewsfeedDataError: false,
        rapidResponseNewsfeedDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;
    case RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_NEWSFEED_DATA_SUCCESS:
      state = {
        ...state,
        isNewsfeedDataRequesting: false,
        rapidResponseNewsfeedData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        rapidResponseNewsfeedDataError: false,
        rapidResponseNewsfeedDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;
    case RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_NEWSFEED_DATA_FAILED:
      state = {
        ...state,
        isNewsfeedDataRequesting: false,
        rapidResponseNewsfeedData: false,
        rapidResponseNewsfeedDataError: action.payload.data,
        rapidResponseNewsfeedDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;

    case RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_DISASTER_EVENTS_DATA_REQUEST:
      state = {
        ...state,
        isDisasterEventsDataRequesting: true,
        disasterEventsData: false,
        disasterEventsDataError: false,
        disasterEventsDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;
    case RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_DISASTER_EVENTS_DATA_SUCCESS:
      state = {
        ...state,
        isDisasterEventsDataRequesting: false,
        disasterEventsData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        disasterEventsDataError: false,
        disasterEventsDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;
    case RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_DISASTER_EVENTS_DATA_FAILED:
      state = {
        ...state,
        isDisasterEventsDataRequesting: false,
        disasterEventsData: false,
        disasterEventsDataError: action.payload.data,
        disasterEventsDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;

    case RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_DISASTER_TYPES_REQUEST:
      state = {
        ...state,
        isDisasterListDataRequesting: true,
        disasterListData: false,
        disasterListDataError: false,
        disasterListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;
    case RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_DISASTER_TYPES_SUCCESS:
      state = {
        ...state,
        isDisasterListDataRequesting: false,
        disasterListData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        disasterListDataError: false,
        disasterListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;
    case RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_DISASTER_TYPES_FAILED:
      state = {
        ...state,
        isDisasterListDataRequesting: false,
        disasterListData: false,
        disasterListDataError: action.payload.data,
        disasterListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;

    case RAPID_RESPONSE_CONST.CREATE_DISASTER_ALERT_REQUEST:
      state = {
        ...state,
        isCreateAlertRequest: true,
        createDisasterAlertData: false,
        createDisasterAlertDataError: false,
        createDisasterAlertDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;
    case RAPID_RESPONSE_CONST.CREATE_DISASTER_ALERT_SUCCESS:
      state = {
        ...state,
        isCreateAlertRequest: false,
        createDisasterAlertData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        createDisasterAlertDataError: false,
        createDisasterAlertDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;
    case RAPID_RESPONSE_CONST.CREATE_DISASTER_ALERT_FAILED:
      state = {
        ...state,
        isCreateAlertRequest: false,
        createDisasterAlertData: false,
        createDisasterAlertDataError: action.payload.data,
        createDisasterAlertDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;

    case RAPID_RESPONSE_CONST.GET_DISASTER_ALERT_LIST_REQUEST:
      state = {
        ...state,
        isDisasterAlertListLoading: true,
        disasterAlertListData: false,
        disasterAlertListDataError: false,
        disasterAlertListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;
    case RAPID_RESPONSE_CONST.GET_DISASTER_ALERT_LIST_SUCCESS:
      state = {
        ...state,
        isDisasterAlertListLoading: false,
        disasterAlertListData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        disasterAlertListDataError: false,
        disasterAlertListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;
    case RAPID_RESPONSE_CONST.GET_DISASTER_ALERT_LIST_FAILED:
      state = {
        ...state,
        isDisasterAlertListLoading: false,
        disasterAlertListData: false,
        disasterAlertListDataError: action.payload.data,
        disasterAlertListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;

    case RAPID_RESPONSE_CONST.GET_APPROVE_DISASTER_EVENT_REQUEST:
      state = {
        ...state,
        isDisasterEventApprovalLoading: true,
        disasterEventApprovalData: false,
        disasterEventApprovalDataError: false,
        disasterEventApprovalDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;
    case RAPID_RESPONSE_CONST.GET_APPROVE_DISASTER_EVENT_SUCCESS:
      state = {
        ...state,
        isDisasterEventApprovalLoading: false,
        disasterEventApprovalData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        disasterEventApprovalDataError: false,
        disasterEventApprovalDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;
    case RAPID_RESPONSE_CONST.GET_APPROVE_DISASTER_EVENT_FAILED:
      state = {
        ...state,
        isDisasterEventApprovalLoading: false,
        disasterEventApprovalData: false,
        disasterEventApprovalDataError: action.payload.data,
        disasterEventApprovalDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};
export default RapidResponse;
