import React from "react";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FiDownload } from "react-icons/fi";
import { useSelector } from "react-redux";
import { MdContentCopy } from "react-icons/md";
import { IoMdEye, IoIosEye } from "react-icons/io";
import copy from "copy-to-clipboard";
import PaginationSection from "../ManageUser/PaginationSection";

function DocumenttableListing({
  documentsList,
  selectedCategory,
  pageCount,
  onHandleFiles,
  setIsMediaPreviewModal,
  setSelectedVideo,
  setSelectedImage,
  setSelectedImagesIndex,
  pageLimit,
  setPageCount,
  setPageLimit,
  pageNumber,
  setPageNumber,
  setPageSize,
  pageSize,
  handleGoToPage,
  handlePageSize,
  disabled,
}) {
  const { t } = useTranslation();
  const [tooltipText, setTooltipText] = React.useState("");

  const handleCopyToClipboard = (url, index) => {
    copy(url);
    setTooltipText(`${url}${index}`);

    setTimeout(() => {
      setTooltipText("");
    }, 400);
  };

  /* ------handling api data---------*/
  const nextProps = useSelector((state) => ({
    uploadSuccess: state.Aware?.documentUpload,
    documentUploadList: state.Aware?.documentUploadList,
    documentUploadListloading: state.Aware?.documentUploadListloading,
  }));

  const onHandleImage = (each, index) => {
    if (index > 3) {
      setSelectedImagesIndex({
        start: index - 4,
        end: index + 1,
      });
    } else {
      setSelectedImagesIndex({
        start: 0,
        end: 4,
      });
    }
    setSelectedImage(each);
  };

  return (
    <>
      {documentsList?.length ? (
        <Row>
          {" "}
          <Col sm={12} className="mt-2">
            <p className="upload-text-count">
              {" "}
              {selectedCategory?.param} ({pageCount}){" "}
            </p>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row className="du-table-header mt-1">
        <Col sm={10} className="du-header-text ">
          <p className="up-table-headings ml-3">
            {" "}
            {t("DOCUMENT_UPLOAD.FILE_NAME")}{" "}
          </p>
        </Col>

        <Col sm={2} className="du-header-text ">
          <p className="up-table-headings"> {t("DOCUMENT_UPLOAD.ACTION")} </p>
        </Col>
      </Row>
      <div className="du-table-body-height">
        {documentsList?.length ? (
          documentsList?.map((each, index) => {
            const extenstion1 = each?.url?.split("/")?.pop();
            return (
              <Row className="du-body-items">
                <Col sm={10} className="du-file-name">
                  <img
                    src={each?.icon}
                    alt={each?.url}
                    className="up-doc-icon"
                  />{" "}
                  <span>
                    {" "}
                    {each?.title
                      ? each?.title
                      : extenstion1?.length > 120
                      ? `${extenstion1?.slice(0, 100)}.... ${extenstion1?.slice(
                          -10,
                        )}`
                      : extenstion1}{" "}
                  </span>
                </Col>

                {!each?.title ? (
                  <Col sm={2}>
                    <IoIosEye
                      onClick={() =>
                        selectedCategory?.param !== "Images" &&
                        selectedCategory?.param !== "Video"
                          ? onHandleFiles(each)
                          : (setIsMediaPreviewModal(true),
                            selectedCategory?.param === "Video"
                              ? setSelectedVideo(each)
                              : onHandleImage(each, index))
                      }
                      className="Du-action-color mr-4 cursor-ponter "
                    />

                    <a href={each?.url} download aria-label="documetDownload">
                      <span className="">
                        <FiDownload className="Du-action-color cursor-ponter " />
                      </span>
                    </a>
                  </Col>
                ) : (
                  <Col sm={2}>
                    <a
                      href={each?.url}
                      rel="noreferrer"
                      target="_blank"
                      aria-label="documetDownload"
                    >
                      <span className="">
                        <IoMdEye className="mr-2 Du-action-color cursor-ponter " />
                      </span>
                    </a>

                    <MdContentCopy
                      className="ml-4 mr-2 Du-action-color cursor-ponter "
                      onClick={() => handleCopyToClipboard(each?.url, index)}
                    />
                    {`${each?.url}${index}` === tooltipText && (
                      <span className="tooltiptext">
                        {" "}
                        {t("DOCUMENT_UPLOAD.COPIED")}{" "}
                      </span>
                    )}
                  </Col>
                )}
              </Row>
            );
          })
        ) : (
          <div className="uo-doc-no-data-avaiable mt-5">
            <p> Data not available </p>
          </div>
        )}
      </div>
      {nextProps.documentUploadList?.results?.length ? (
        <div className="mt-3 mb-2">
          <PaginationSection
            pageCount={pageCount}
            pageLimit={pageLimit}
            setPageCount={setPageCount}
            setPageLimit={setPageLimit}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageSize={pageSize}
            handleGoToPage={handleGoToPage}
            handlePageSize={handlePageSize}
            disabled={disabled}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default DocumenttableListing;
