import CORE_CONST from "./actionTypes";

export const coreRequest = (data) => {
  return {
    type: CORE_CONST.CORE_REQUEST,
    payload: data,
  };
};
export const coreSuccess = (user) => {
  return {
    type: CORE_CONST.CORE_SUCCESS,
    payload: user,
  };
};
export const coreError = (error) => {
  return {
    type: CORE_CONST.CORE_ERROR,
    payload: error,
  };
};
export const corestateRequest = ({ data, isCompareLocation, isCreate }) => {
  return {
    type: CORE_CONST.CORE_STATE_REQUEST,
    payload: { data, isCompareLocation, isCreate },
  };
};
export const corestateSuccess = (user) => {
  return {
    type: CORE_CONST.CORE_STATE_SUCCESS,
    payload: user,
  };
};
export const corestateError = (error) => {
  return {
    type: CORE_CONST.CORE_STATE_ERROR,
    payload: error,
  };
};

export const coredistrictRequest = ({
  requestObj,
  isCompareLocation,
  isCreate,
}) => {
  return {
    type: CORE_CONST.CORE_DISTRICT_REQUEST,
    payload: {
      requestObj,
      isCompareLocation,
      isCreate,
    },
  };
};
export const coredistrictSuccess = (user) => {
  return {
    type: CORE_CONST.CORE_DISTRICT_SUCCESS,
    payload: user,
  };
};
export const coredistrictError = (error) => {
  return {
    type: CORE_CONST.CORE_DISTRICT_ERROR,
    payload: error,
  };
};

// for basins
export const coreBasinRequest = ({ data, isCompareLocation }) => {
  return {
    type: CORE_CONST.CORE_BASIN_REQUEST,
    payload: {
      data,
      isCompareLocation,
    },
  };
};
export const coreBasinSuccess = (user) => {
  return {
    type: CORE_CONST.CORE_BASIN_SUCCESS,
    payload: user,
  };
};
export const coreBasinError = (error) => {
  return {
    type: CORE_CONST.CORE_BASIN_ERROR,
    payload: error,
  };
};
