import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { GrFormClose } from "react-icons/gr";
import { Alert } from "reactstrap";
import _ from "lodash";
import { renderChartTime } from "../../components/Common/Utils";
import HighChartGraph from "../../components/Common/HighCharts";
import {
  chartColorLabel,
  CHART_LABEL_COLORS,
  HighChartConstants,
} from "../../constants";
import { dateFormatters } from "../../helpers";
import GraphLoader from "../../components/GraphLoader";

function DroughtGraph({
  indicatorsData,
  setShowChart,
  showChart,
  chartData,
  aggregation,
  startDate,
  endDate,
  compareGraph,
  compareIndicatorData,
  compareAggregation,
  errorData,
  setErrorData,
  compare,
  setCompareGraph,
  adminRadio,
}) {
  const myDiv = document.getElementById("graph1");
  const containerWidth = myDiv?.offsetWidth;
  const containerHeight = myDiv?.offsetHeight;

  const { t } = useTranslation();
  const [highChartData, setHighChartData] = useState({
    xaxis: [],
    yaxis: [],
    serise: [],
  });
  const [graphTitle, setGraphTitle] = useState("");
  const [showGraph, setGraphShow] = useState(false);

  const [chartWidth, setChartWidth] = useState();

  const nextProps = useSelector((state) => ({
    droughtTimeseriesData: state.Drought.droughtTimeseriesData,
    droughtTimeSeriesloading: state.Drought.droughtTimeSeriesloading,
    graphLoading: state.Loader.graphLoading,
    droughtTimeseriesError: state.Drought.error,
  }));

  const options = {
    title: {
      text: `${graphTitle}`,
      style: {
        fontSize: "17px",
        color: "#435A52",
      },

      align: "center",
    },
    chart: {
      type: "line",
      height: containerHeight - 10,
      width: chartWidth,

      resetZoomButton: {
        position: {
          align: "left",
          verticalAlign: "top",
          x: 10,
          y: 10,
        },
      },

      zoomType: "x",
    },
    legend: {
      layout: "horizontal",
      align: "left",
      verticalAlign: "bottom",
    },

    tooltip: {
      shared: true,
      useHTML: true,
      headerFormat: '<table><tr><th colspan="2">{point.x}</th></tr>',
      pointFormat:
        "<tr><td >{series.name} : </td>" +
        '<td style="text-align: right"><b>{point.y} </b></td></tr>',
      footerFormat: "</table>",
      valueDecimals: 2,
    },

    exporting: {
      enabled: true,
      filename: graphTitle,
      buttons: {
        contextButton: {
          align: "left",
          x: 35,
          y: -5,
        },
      },
    },

    xAxis: [
      {
        categories: highChartData?.xaxis,
        crosshair: false,
        gridLineWidth: 2,
        type: "datetime",
      },
    ],
    yAxis: highChartData.yaxis,
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          borderRadius: 2,

          allowOverlap: true,
          crop: true,
        },
      },
    },

    series: highChartData?.serise,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  };
  useEffect(() => {
    setGraphShow(!nextProps.droughtTimeSeriesloading);
    setErrorData({
      showError: false,
      message: "",
    });
  }, [nextProps.droughtTimeSeriesloading]);

  useEffect(() => {
    setGraphShow(!nextProps.graphLoading);
  }, [nextProps.graphLoading]);

  const onSetDataValue = (formattedDates, data) => {
    const b = [];
    data.data?.map((eachData, index) =>
      b.push([formattedDates[index], eachData]),
    );
    return b;
  };

  useEffect(() => {
    if (!_.isEmpty(nextProps.droughtTimeseriesError)) {
      setErrorData({
        showError: true,
        message: nextProps.droughtTimeseriesError,
      });
    }
  }, [nextProps.droughtTimeseriesError]);

  useEffect(() => {
    if (nextProps?.droughtTimeseriesData?.graph_data && !compare) {
      const mills = nextProps?.droughtTimeseriesData?.graph_data?.millis;
      const data = nextProps?.droughtTimeseriesData?.graph_data?.data;

      const yaxisdata = [];
      const yaxisSerise = [];
      let formattedDates = [];
      setGraphShow(true);

      const width =
        mills.length > HighChartConstants?.twenty
          ? mills?.length * HighChartConstants?.thirtyFive
          : null;
      containerWidth > width
        ? setChartWidth(containerWidth)
        : setChartWidth(width);

      const b = [];

      if (!_.isEmpty(mills)) {
        formattedDates = mills?.length
          ? mills.map((item) => {
              const dateFormat = new Date(item);
              return renderChartTime(dateFormat);
            })
          : "";
        data.map((eachData, index) =>
          b.push([formattedDates[index], eachData]),
        );

        const a = {
          name: indicatorsData?.indices_name,
          data: b,
          dataLabels: {
            format: "{y:.2f}",
            backgroundColor: HighChartConstants?.datalabelcolor,
            borderRadius: HighChartConstants?.three,
            padding: HighChartConstants?.three,
            y: HighChartConstants?.three,
            style: {
              color: HighChartConstants?.white,
              textOutline: "none",
            },
          },
        };
        yaxisSerise.push(a);
        yaxisdata.push({
          lineColor: CHART_LABEL_COLORS[1],
          lineWidth: 1,
          tickLength: 10,
          tickWidth: 1,
          tickAmount: 6,
          showEmpty: false,
          labels: {
            format: "{value:.2f}",
            style: {
              size: 12,
              color: CHART_LABEL_COLORS[1],
            },
          },
          title: {
            text: indicatorsData?.indices_name,
            style: {
              color: CHART_LABEL_COLORS[1],
              fontWeight: "bold",
            },
          },
        });
        setHighChartData((prev) => {
          return {
            ...prev,
            xaxis: formattedDates,
            yaxis: yaxisdata,
            serise: yaxisSerise,
          };
        });
        setGraphTitle(
          `${indicatorsData?.indices_name} (${aggregation?.label})`,
        );
      } else {
        setHighChartData((prev) => {
          return {
            ...prev,
            xaxis: [],
            yaxis: [],
            serise: [],
          };
        });
        setGraphTitle("");
      }
    }
  }, [nextProps.droughtTimeseriesData, nextProps?.droughtTimeSeriesloading]);

  useEffect(() => {
    if (chartData) {
      let dates;
      const yaxisdata = [];
      const yaxisSerise = [];
      let formattedDates = [];
      if (!_.isEmpty(chartData) && chartData?.graph_data?.length) {
        dates = chartData?.graph_data[0]?.millis;
        if (chartData?.graph_data?.length === 2) {
          // eslint-disable-next-line no-unused-vars
          dates =
            chartData?.graph_data[0]?.millis.length >
            chartData?.graph_data[1]?.millis.length
              ? chartData?.graph_data[0]?.millis
              : chartData?.graph_data[1]?.millis;
        }
      }

      // eslint-disable-next-line no-unused-vars
      formattedDates = dates.map((item) => {
        const dateFormat = new Date(item);
        return renderChartTime(dateFormat);
      });

      const width =
        formattedDates.length > HighChartConstants?.twenty
          ? formattedDates?.length * HighChartConstants?.thirtyFive
          : null;
      containerWidth > width
        ? setChartWidth(containerWidth)
        : setChartWidth(width);

      chartData?.graph_data?.map((eachData, index) => {
        const data1 = onSetDataValue(formattedDates, eachData);
        const seriseData = {
          name:
            eachData.layerType === "marker"
              ? `Point (${eachData.latlng[1].toFixed(
                  2,
                )},${eachData.latlng[0].toFixed(2)})`
              : `${eachData?.layerType}-${eachData?.polygonCount}`,
          type: "line",
          data: data1,
          dataLabels: {
            format: "{y:.2f}",
            backgroundColor: chartColorLabel[index],
            borderRadius: HighChartConstants?.three,
            padding: HighChartConstants?.three,
            y:
              index === 0
                ? HighChartConstants?.minusTen
                : HighChartConstants?.thirty,
            style: {
              color: HighChartConstants?.white,
              textOutline: "none",
            },
          },
          yAxis: index,
          color: chartColorLabel[index],
        };
        const yaxis = {
          lineColor: chartColorLabel[index],
          lineWidth: 1,
          tickLength: 10,
          tickWidth: 1,
          tickAmount: 6,
          tickColor: chartColorLabel[index],
          labels: {
            format: "{value:.2f}",
            style: {
              size: 12,
              color: chartColorLabel[index],
            },
          },
          title: {
            text:
              eachData.layerType === "marker"
                ? `point (${eachData.latlng[1].toFixed(
                    2,
                  )},${eachData.latlng[0].toFixed(2)})`
                : `${eachData?.layerType}-${eachData?.polygonCount}`,
            style: {
              color: chartColorLabel[index],
              fontWeight: "bold",
            },
          },
        };
        if (index > 0) {
          yaxis.opposite = true;
        }
        yaxis.showEmpty = false;
        yaxisdata.push(yaxis);
        yaxisSerise.push(seriseData);
        return null;
      });
      setHighChartData((prev) => {
        return {
          ...prev,
          xaxis: formattedDates,
          yaxis: yaxisdata,
          serise: yaxisSerise,
        };
      });
      setGraphTitle(`${indicatorsData?.indices_name}(${aggregation?.label}) `);
      setGraphShow(true);
    }
  }, [chartData]);

  useEffect(() => {
    if (compareGraph) {
      let dates;
      const yaxisdata = [];
      const yaxisSerise = [];
      let formattedDates = [];
      if (!_.isEmpty(compareGraph) && compareGraph?.graph_data?.length) {
        dates = compareGraph?.graph_data[0]?.millis;
        if (compareGraph?.graph_data?.length === 2) {
          // eslint-disable-next-line no-unused-vars
          dates =
            compareGraph?.graph_data[0]?.millis.length >
            compareGraph?.graph_data[1]?.millis.length
              ? compareGraph?.graph_data[0]?.millis
              : compareGraph?.graph_data[1]?.millis;
        }
      }
      // eslint-disable-next-line no-unused-vars
      formattedDates = dates.map((item) => {
        const dateFormat = new Date(item);
        return renderChartTime(dateFormat);
      });

      const width =
        formattedDates.length > HighChartConstants?.twenty
          ? formattedDates?.length * HighChartConstants?.fourtyFive
          : null;

      containerWidth > width
        ? setChartWidth(containerWidth)
        : setChartWidth(width);

      compareGraph?.graph_data?.map((eachData, index) => {
        const data1 = onSetDataValue(formattedDates, eachData);

        const seriseData = {
          name:
            index === 0
              ? `${indicatorsData?.indices_name} (${
                  adminRadio === "admin"
                    ? eachData?.location?.district === ""
                      ? eachData?.location?.state === ""
                        ? eachData?.location?.country
                        : eachData?.location?.state
                      : eachData?.location?.district
                    : eachData?.location?.sub_basin === ""
                    ? eachData?.location?.basin === ""
                      ? eachData?.location?.country
                      : eachData?.location?.basin
                    : eachData?.location?.sub_basin
                })`
              : `${compareIndicatorData?.indices_name} (${
                  adminRadio === "admin"
                    ? eachData?.location?.district === ""
                      ? eachData?.location?.state === ""
                        ? eachData?.location?.country
                        : eachData?.location?.state
                      : eachData?.location?.district
                    : eachData?.location?.sub_basin === ""
                    ? eachData?.location?.basin === ""
                      ? eachData?.location?.country
                      : eachData?.location?.basin
                    : eachData?.location?.sub_basin
                })`,
          type: "line",
          data: data1,

          yAxis: index,
          color: chartColorLabel[index],
          dataLabels: {
            format: "{y:.2f}",
            backgroundColor: chartColorLabel[index],
            borderRadius: HighChartConstants?.three,
            padding: HighChartConstants?.three,

            y:
              index === 0
                ? HighChartConstants?.minusTen
                : HighChartConstants?.thirty,
            style: {
              color: HighChartConstants?.white,
              textOutline: "none",
            },
          },
        };
        const yaxis = {
          lineColor: chartColorLabel[index],
          lineWidth: 1,
          tickLength: 10,
          tickWidth: 1,

          tickAmount: 6,

          tickColor: chartColorLabel[index],
          labels: {
            format: "{value:.2f}",
            style: {
              size: 12,
              color: chartColorLabel[index],
            },
          },
          title: {
            text:
              index === 0
                ? `${indicatorsData?.indices_name} - ${
                    adminRadio === "admin"
                      ? eachData?.location?.district === ""
                        ? eachData?.location?.state === ""
                          ? eachData?.location?.country
                          : eachData?.location?.state
                        : eachData?.location?.district
                      : eachData?.location?.sub_basin === ""
                      ? eachData?.location?.basin === ""
                        ? eachData?.location?.country
                        : eachData?.location?.basin
                      : eachData?.location?.sub_basin
                  }`
                : `${compareIndicatorData?.indices_name} - ${
                    adminRadio === "admin"
                      ? eachData?.location?.district === ""
                        ? eachData?.location?.state === ""
                          ? eachData?.location?.country
                          : eachData?.location?.state
                        : eachData?.location?.district
                      : eachData?.location?.sub_basin === ""
                      ? eachData?.location?.basin === ""
                        ? eachData?.location?.country
                        : eachData?.location?.basin
                      : eachData?.location?.sub_basin
                  }`,
            style: {
              color: chartColorLabel[index],
              fontWeight: "bold",
            },
          },
        };
        if (index > 0) {
          yaxis.opposite = true;
        }
        yaxis.showEmpty = false;
        yaxisdata.push(yaxis);
        yaxisSerise.push(seriseData);
        return null;
      });
      setHighChartData((prev) => {
        return {
          ...prev,
          xaxis: formattedDates,
          yaxis: yaxisdata,
          serise: yaxisSerise,
        };
      });
      setGraphTitle(
        `${compareIndicatorData?.indices_name} (${compareAggregation?.label}) - ${indicatorsData?.indices_name} (${aggregation?.label}) `,
      );
      setGraphShow(true);
    }
  }, [compareGraph]);

  const onHandleCharts = () => {
    setChartWidth(null);
    setCompareGraph("");
    setShowChart(false);
  };

  return (
    showChart && (
      <div
        className={
          containerWidth === chartWidth
            ? "drought-footer-area chart-design chart-hidden"
            : "drought-footer-area chart-design chart-flow"
        }
        id="graph1"
      >
        {showGraph && !errorData?.showError ? (
          <>
            {options?.xAxis[0]?.categories?.length ? (
              <div className="">
                <HighChartGraph options={options} />
              </div>
            ) : (
              <div className="not-available">
                <div className="graph-error-style ">
                  <Alert color="warning">{t("LOADING.NOT_FOUND")}</Alert>
                </div>
              </div>
            )}
            <div>
              <span className="time-show">
                {t("LOADING.DATE")} {dateFormatters(startDate)}{" "}
                {t("LOADING.TO")} {dateFormatters(endDate)}
              </span>
              <div className="close-icon-highcharts">
                <GrFormClose
                  className="close-graph-bth"
                  onClick={onHandleCharts}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ left: 30 }} className="close-icon-highcharts">
              {errorData?.showError && (
                <GrFormClose
                  className="close-graph-bth"
                  onClick={onHandleCharts}
                />
              )}
            </div>
            <GraphLoader />
            {errorData?.showError && (
              <div>
                <div className="graph-error-style">
                  <Alert color="warning">{errorData?.message}</Alert>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    )
  );
}
export default DroughtGraph;
