import WEATHER_CONST from "./actionTypes";

// by country-Id
export const getWeatherForecastSources = (req_body) => ({
  type: WEATHER_CONST.GET_FORECAST_DATA_SOURCES_REQUEST,
  payload: req_body,
});
export const getWeatherForecastSourcesSuccess = (response) => ({
  type: WEATHER_CONST.GET_FORECAST_DATA_SOURCES_SUCCESS,
  payload: response,
});

export const getWeatherForecastSourcesFailed = (error) => ({
  type: WEATHER_CONST.GET_FORECAST_DATA_SOURCES_FAILED,
  payload: error,
});
// IRI Available Dates
export const getIRIAvailableDatesDataRequest = (obj) => ({
  type: WEATHER_CONST.IRI_AVAILABLE_DATES_REQUEST,
  payload: obj,
});

export const getIRIAvailableDatesDataSuccess = (response) => ({
  type: WEATHER_CONST.IRI_AVAILABLE_DATES_SUCCESS,
  payload: response,
});

export const getIRIAvailableDatesDataFailed = (error) => ({
  type: WEATHER_CONST.IRI_AVAILABLE_DATES_FAILED,
  payload: error,
});

export const getWeatherForecastData = (obj, compareBool) => ({
  type: WEATHER_CONST.GET_FORECAST_DATA_REQUEST,
  payload: {
    requestObj: obj,
    isCompare: compareBool ? compareBool.isCompare : false,
  },
});

export const getWeatherForecastDataSuccess = (response) => ({
  type: WEATHER_CONST.GET_FORECAST_DATA_SUCCESS,
  payload: response,
});

export const getWeatherForecastDataFailed = (error) => ({
  type: WEATHER_CONST.GET_FORECAST_DATA_FAILED,
  payload: error,
});

export const getAddTimeSeriesRequest = (data, sample) => {
  return {
    type: WEATHER_CONST.ADD_TIMESERIES_REQUEST,
    payload: { data, Url: sample },
  };
};

export const getAddTimeSeriesSuccess = (response) => {
  return {
    type: WEATHER_CONST.ADD_TIMESERIES_SUCCESS,
    payload: response,
  };
};

export const getAddTimeSeriesError = (error) => {
  return {
    type: WEATHER_CONST.ADD_TIMESERIES_ERROR,
    payload: error,
  };
};

export const getStaticResourceData = (req_body) => ({
  type: WEATHER_CONST.GET_STATIC_RESOURCE_DATA_REQUEST,
  payload: req_body,
});

export const getStaticResourceDataSuccess = (response) => ({
  type: WEATHER_CONST.GET_STATIC_RESOURCE_DATA_SUCCESS,
  payload: response,
});

export const getStaticResourceDataFailed = (error) => ({
  type: WEATHER_CONST.GET_STATIC_RESOURCE_DATA_FAILED,
  payload: error,
});

export const getStaticResourceIRIImageRequest = (req_body) => ({
  type: WEATHER_CONST.GET_IRI_IMAGE_DATA_REQUEST,
  payload: req_body,
});

export const getStaticResourceIRIImageSuccess = (response) => ({
  type: WEATHER_CONST.GET_IRI_IMAGE_DATA_SUCCESS,
  payload: response,
});

export const getStaticResourceIRIImageFailed = (error) => ({
  type: WEATHER_CONST.GET_IRI_IMAGE_DATA_FAILED,
  payload: error,
});
