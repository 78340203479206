import React from "react";
import { useTranslation } from "react-i18next";

import InfiniteScroll from "react-infinite-scroll-component";
import { Alert, Col, Row } from "reactstrap";
import { ReactComponent as DateIcon } from "../../assest/img/Date_Icon.svg";
import { ReactComponent as SourceIcon } from "../../assest/img/Source_Icon.svg";
import { dateFormatters } from "../../helpers";

function Events({
  eventsData,
  handleSelectEvent,
  handleRenderDisasterIcon,
  pageNumber,
  pageCount,
  isLoading,
  fetchNextData,
}) {
  const { t } = useTranslation();

  return eventsData?.length ? (
    <Row className="w-100 mb-3 d-block events-container" id="scrollableDiv">
      <InfiniteScroll
        dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ""}
        next={fetchNextData}
        className="flex flex-wrap w-100"
        hasMore={pageNumber * 10 < pageCount}
        loader={
          isLoading && (
            <center>
              <h4>{t("LOADING.LOADING")} </h4>
            </center>
          )
        }
        scrollableTarget="scrollableDiv"
        style={{ overflowX: "hidden" }}
      >
        {eventsData?.map((event) => {
          const disasterIcon = handleRenderDisasterIcon(event.type);
          return (
            <Col
              key={event.name}
              sm={12}
              className="cursor-pointer w-100 mb-3"
              onClick={() => handleSelectEvent(event)}
            >
              <div className="my-2 event-info-container event-container-bkg">
                <div className="d-flex">
                  <img
                    src={disasterIcon[0]?.icon}
                    alt={event.type}
                    width={60}
                    height={60}
                    className="mr-3"
                  />
                  <div className="d-flex flex-column justify-content-between">
                    <h1 className="event-info-header mb-2">{event?.name}</h1>
                    <div className="d-flex flex-wrap align-items-center">
                      {event?.fromdate ? (
                        <p className="event-info-details mr-3">
                          <DateIcon
                            width={16}
                            height={16}
                            className="mr-1 event-svg"
                          />
                          {t("RAPID_RESPONSE.DATE")}:{" "}
                          {dateFormatters(event?.fromdate)}
                        </p>
                      ) : null}
                      {event.todate ? (
                        <p className="event-info-details mr-3">
                          - {dateFormatters(event?.todate)}
                        </p>
                      ) : null}
                      <p className="event-info-details mr-3">
                        <SourceIcon width={16} height={16} className="mr-1" />
                        {t("RAPID_RESPONSE.SOURCE")}: {event?.source}
                      </p>
                    </div>
                  </div>
                </div>

                <hr className="event-divder-line my-3" />

                {event?.severitytext ? (
                  <p className="event-info-severity-text my-3">
                    {event.severitytext}
                  </p>
                ) : null}

                <p className="event-info-description my-3">
                  {event?.description !== "" ? (
                    event?.description?.length > 250 ? (
                      <>
                        {event?.description?.slice(0, 250)}{" "}
                        <span className="event-view-more cursor-pointer">
                          ...View more
                        </span>
                      </>
                    ) : (
                      event?.description
                    )
                  ) : (
                    "No Description"
                  )}
                  {}
                </p>
              </div>
            </Col>
          );
        })}
      </InfiniteScroll>
    </Row>
  ) : (
    <div className=" events-container">
      <div className="floodgraph-error w-100 px-5">
        <div className="graph-error-style h-100 w-100">
          <Alert
            color="warning"
            className="d-flex justify-content-center align-items-center"
          >
            <p>{t("RAPID_RESPONSE.NO_NEWSFEED_AVAILABLE")}</p>
          </Alert>
        </div>
      </div>
    </div>
  );
}

export default Events;
