import BOUNDARY_CONST from "./actionTypes";

export const countryBoundaryRequest = (payload) => {
  return {
    type: BOUNDARY_CONST.COUNTRY_BOUNDRY_REQUEST,
    payload,
  };
};
export const countryBoundarySuccess = (user) => {
  return {
    type: BOUNDARY_CONST.COUNTRY_BOUNDRY_SUCCESS,
    payload: user,
  };
};
export const countryBoundaryError = (error) => {
  return {
    type: BOUNDARY_CONST.COUNTRY_BOUNDRY_ERROR,
    payload: error,
  };
};

// STATE / DISTRICTS BOUNDRY ACTIONS
export const stateBoundaryRequest = (payload) => {
  return {
    type: BOUNDARY_CONST.STATE_BOUNDRY_REQUEST,
    payload,
  };
};
export const stateBoundarySuccess = (user) => {
  return {
    type: BOUNDARY_CONST.STATE_BOUNDRY_SUCCESS,
    payload: user,
  };
};
export const stateBoundaryError = (error) => {
  return {
    type: BOUNDARY_CONST.STATE_BOUNDRY_ERROR,
    payload: error,
  };
};

// TALUKAS BOUNDRY ACTIONS
export const taluksBoundaryRequest = (payload) => {
  return {
    type: BOUNDARY_CONST.TALUKAS_BOUNDRY_REQUEST,
    payload,
  };
};
export const taluksBoundarySuccess = (user) => {
  return {
    type: BOUNDARY_CONST.TALUKAS_BOUNDRY_SUCCESS,
    payload: user,
  };
};
export const taluksBoundaryError = (error) => {
  return {
    type: BOUNDARY_CONST.TALUKAS_BOUNDRY_ERROR,
    payload: error,
  };
};
