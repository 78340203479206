import EARLY_WARNING_NUTRITION_CONST from "./actionTypes";

// Timeseries
export const getNutritionTimeSeriesRequest = (data) => ({
  type: EARLY_WARNING_NUTRITION_CONST.GET_NUTRITION_TIMESERIES_REQUEST,
  payload: {
    data,
  },
});

export const getNutritionTimeSeriesSuccess = (response) => ({
  type: EARLY_WARNING_NUTRITION_CONST.GET_NUTRITION_TIMESERIES_SUCCESS,
  payload: response,
});

export const getNutritionTimeSeriesError = (error) => ({
  type: EARLY_WARNING_NUTRITION_CONST.GET_NUTRITION_TIMESERIES_ERROR,
  payload: error,
});

// Nutrition DATA
export const getNutritionMapData = (obj) => ({
  type: EARLY_WARNING_NUTRITION_CONST.GET_NUTRITION_DATA_REQUEST,
  payload: {
    requestObj: obj,
  },
});

export const getNutritionMapDataSuccess = (response) => ({
  type: EARLY_WARNING_NUTRITION_CONST.GET_NUTRITION_DATA_SUCCESS,
  payload: response,
});

export const getNutritionMapDataFailed = (error) => ({
  type: EARLY_WARNING_NUTRITION_CONST.GET_NUTRITION_DATA_FAILED,
  payload: error,
});
