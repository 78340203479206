import AWARE_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};
const Aware = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case AWARE_CONST.AWARE_ABOUT_REQUEST:
      state = {
        ...state,
        loading: true,
        awareAboutData: null,
        error: "",
      };
      break;
    case AWARE_CONST.AWARE_ABOUT_SUCCESS:
      state = {
        ...state,
        loading: false,
        awareAboutData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case AWARE_CONST.AWARE_ABOUT_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        awareAboutData: null,
      };
      break;
    case AWARE_CONST.AWARE_TOOLS_REQUEST:
      state = {
        ...state,
        loading: true,
        awareToolsData: null,
        error: "",
      };
      break;
    case AWARE_CONST.AWARE_TOOLS_SUCCESS:
      state = {
        ...state,
        loading: false,
        awareToolsData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case AWARE_CONST.AWARE_TOOLS_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        awareToolsData: null,
      };
      break;
    case AWARE_CONST.AWARE_TEAM_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
        awareTeamList: null,
        error: "",
      };
      break;
    case AWARE_CONST.AWARE_TEAM_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        awareTeamList:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case AWARE_CONST.AWARE_TEAM_LIST_FAILURE:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        awareTeamList: null,
      };
      break;

    case AWARE_CONST.UPLOAD_DOCUMENTS_REQUEST:
      state = {
        ...state,
        loading: true,
        documentUpload: null,
        error: "",
      };
      break;
    case AWARE_CONST.UPLOAD_DOCUMENTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        documentUpload:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case AWARE_CONST.UPLOAD_DOCUMENTS_FAILURE:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        documentUpload: null,
      };
      break;

    case AWARE_CONST.UPLOAD_DOCUMENTS_LISTING_REQUEST:
      state = {
        ...state,
        loading: true,
        documentUploadList: null,
        documentUploadListloading: true,
        error: "",
      };
      break;
    case AWARE_CONST.UPLOAD_DOCUMENTS_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        documentUploadList:
          action.payload.statusCode === 200 ? action.payload.data : false,
        documentUploadListloading: false,
        error: "",
      };
      break;
    case AWARE_CONST.UPLOAD_DOCUMENTS_LISTING_FAILURE:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        documentUploadList: null,
        documentUploadListloading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
export default Aware;
