import EARLY_WARNING_NUTRITION_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};
const Nutrition = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    // Timeseries Request
    case EARLY_WARNING_NUTRITION_CONST.GET_NUTRITION_TIMESERIES_REQUEST:
      state = {
        ...state,
        nutritionTimeSeriesloading: true,
        nutritionTimeseriesData: null,
        nutritionTimeSeriesError: null,
      };
      break;
    case EARLY_WARNING_NUTRITION_CONST.GET_NUTRITION_TIMESERIES_SUCCESS:
      state = {
        ...state,
        nutritionTimeSeriesloading: false,
        nutritionTimeseriesData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        nutritionTimeSeriesError: null,
      };
      break;
    case EARLY_WARNING_NUTRITION_CONST.GET_NUTRITION_TIMESERIES_ERROR:
      state = {
        ...state,
        nutritionTimeSeriesError: action.payload.data,
        nutritionTimeSeriesloading: false,
        nutritionTimeseriesData: null,
      };
      break;

    // data request
    case EARLY_WARNING_NUTRITION_CONST.GET_NUTRITION_DATA_REQUEST:
      state = {
        ...state,
        isNutritionMapDataRequesting: true,
        nutritionMapData: false,
        nutritionMapDataError: false,
      };
      break;
    case EARLY_WARNING_NUTRITION_CONST.GET_NUTRITION_DATA_SUCCESS:
      state = {
        ...state,
        isNutritionMapDataRequesting: false,
        nutritionMapData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        nutritionMapDataError: false,
      };
      break;
    case EARLY_WARNING_NUTRITION_CONST.GET_NUTRITION_DATA_FAILED:
      state = {
        ...state,
        isNutritionMapDataRequesting: false,
        nutritionMapData: false,
        nutritionMapDataError: action.payload.data,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
export default Nutrition;
