import MAP_PREFERENCE_CONST from "./actionTypes";

export const mapPreferenceRequest = (payload) => {
  return {
    type: MAP_PREFERENCE_CONST.MAP_PREFERENCE_REQUEST,
    payload,
  };
};
export const mapPreferenceSuccess = (user) => {
  return {
    type: MAP_PREFERENCE_CONST.MAP_PREFERENCE_SUCCESS,
    payload: user,
  };
};
export const mapPreferenceError = (error) => {
  return {
    type: MAP_PREFERENCE_CONST.MAP_PREFERENCE_ERROR,
    payload: error,
  };
};

export const mapPreferenceDataRequest = (payload) => {
  return {
    type: MAP_PREFERENCE_CONST.MAP_PREFERENCE_DATA_REQUEST,
    payload,
  };
};
export const mapPreferenceDataSuccess = (user) => {
  return {
    type: MAP_PREFERENCE_CONST.MAP_PREFERENCE_DATA_SUCCESS,
    payload: user,
  };
};
export const mapPreferenceDataError = (error) => {
  return {
    type: MAP_PREFERENCE_CONST.MAP_PREFERENCE_DATA_ERROR,
    payload: error,
  };
};
