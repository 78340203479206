import ODK_CONST from "./odkConst";

export const getFieldDataRequest = (data) => ({
  type: ODK_CONST.FIELD_DATA_LIST_REQUEST,
  payload: data,
});

export const fieldDataListSuccess = (response) => ({
  type: ODK_CONST.FIELD_DATA_LIST_SUCCESS,
  payload: response,
});

export const fieldDataListFailed = (error) => ({
  type: ODK_CONST.FIELD_DATA_LIST_FAILED,
  payload: error,
});

export const fieldDataByIdRequest = (data) => ({
  type: ODK_CONST.FIELD_DATA_BY_ID_REQUEST,
  payload: data,
});

export const fieldDataByIdSuccess = (response) => ({
  type: ODK_CONST.FIELD_DATA_BY_ID_SUCCESS,
  payload: response,
});

export const fieldDataByIdFailed = (error) => ({
  type: ODK_CONST.FIELD_DATA_BY_ID_FAILED,
  payload: error,
});

export const getInsuranceCompanyListRequest = (data) => ({
  type: ODK_CONST.INSURANCE_COMPANY_LIST_REQUEST,
  payload: data,
});

export const insuranceCompanyListSuccess = (response) => ({
  type: ODK_CONST.INSURANCE_COMPANY_LIST_SUCCESS,
  payload: response,
});

export const insuranceCompanyListFailed = (error) => ({
  type: ODK_CONST.INSURANCE_COMPANY_LIST_FAILED,
  payload: error,
});

export const submitConfigurationRequest = (data) => ({
  type: ODK_CONST.SUBMIT_CONFIGURATION_REQUEST,
  payload: data,
});

export const submitConfigurationSuccess = (response) => ({
  type: ODK_CONST.SUBMIT_CONFIGURATION_SUCCESS,
  payload: response,
});

export const submitConfigurationFailed = (error) => ({
  type: ODK_CONST.SUBMIT_CONFIGURATION_FAILED,
  payload: error,
});

export const inviteConfigurationRequest = (data) => ({
  type: ODK_CONST.INVITE_CONFIGURATION_REQUEST,
  payload: data,
});

export const inviteConfigurationSuccess = (response) => ({
  type: ODK_CONST.INVITE_CONFIGURATION_SUCCESS,
  payload: response,
});

export const inviteConfigurationFailed = (error) => ({
  type: ODK_CONST.INVITE_CONFIGURATION_FAILED,
  payload: error,
});

export const componentsListRequest = (data) => ({
  type: ODK_CONST.COMPONENTS_LIST_REQUEST,
  payload: data,
});

export const componentsListSuccess = (response) => ({
  type: ODK_CONST.COMPONENTS_LIST_SUCCESS,
  payload: response,
});

export const componentsListFailed = (error) => ({
  type: ODK_CONST.COMPONENTS_LIST_FAILED,
  payload: error,
});

export const componentsByIdRequest = (data) => ({
  type: ODK_CONST.COMPONENT_BY_ID_REQUEST,
  payload: data,
});

export const componentsByIdSuccess = (response) => ({
  type: ODK_CONST.COMPONENT_BY_ID_SUCCESS,
  payload: response,
});

export const componentsByIdFailed = (error) => ({
  type: ODK_CONST.COMPONENT_BY_ID_FAILED,
  payload: error,
});

export const manageSurveyorsListRequest = (data) => ({
  type: ODK_CONST.MANAGE_SURVEYORS_LIST_REQUEST,
  payload: data,
});

export const manageSurveyorsListSuccess = (response) => ({
  type: ODK_CONST.MANAGE_SURVEYORS_LIST_SUCCESS,
  payload: response,
});

export const manageSurveyorsListFailed = (error) => ({
  type: ODK_CONST.MANAGE_SURVEYORS_LIST_FAILED,
  payload: error,
});

export const deleteSurveyorsRequest = (data) => ({
  type: ODK_CONST.DELETE_SURVEYORS_REQUEST,
  payload: data,
});

export const deleteSurveyorsSuccess = (response) => ({
  type: ODK_CONST.DELETE_SURVEYORS_SUCCESS,
  payload: response,
});

export const deleteSurveyorsFailed = (error) => ({
  type: ODK_CONST.DELETE_SURVEYORS_FAILED,
  payload: error,
});

export const surveyConfigurationRequest = (data) => ({
  type: ODK_CONST.SURVEY_CONFIGURATION_REQUEST,
  payload: data,
});

export const surveyConfigurationSuccess = (response) => ({
  type: ODK_CONST.SURVEY_CONFIGURATION_SUCCESS,
  payload: response,
});

export const surveyConfigurationFailed = (error) => ({
  type: ODK_CONST.SURVEY_CONFIGURATION_FAILED,
  payload: error,
});
