import React, { useEffect } from "react";
import { Button, FormGroup, Row, Col, Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import { formickTextField } from "../../../components/Common/FormickField";
import { lengthRequired } from "../../../helpers/validate";
import "react-multi-date-picker/styles/colors/green.css";

function RightSideModal({
  setAddNewRow,
  onHanldeAddEdit,
  initialValues,
  isOpenEditEditor,
  toggle,
  isOpenEditor,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    // Add a CSS class to the body element when the sideEditor is open
    if (isOpenEditor) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpenEditor]);

  const handleSubmit = (data) => {
    if (isOpenEditEditor) {
      onHanldeAddEdit(data, isOpenEditEditor);
    } else {
      onHanldeAddEdit(data, isOpenEditEditor);
    }
  };

  return (
    <div className="st-right-sidebar-wrapper-filter-intervention">
      <div className="st-filter-header-manageuser ea-justify-center">
        <h3 className="image-sidebar-header edit-header-text-center ">
          {isOpenEditEditor
            ? t("EARLY_ACTIONS.EDITING_ROW")
            : t("EARLY_ACTIONS.ADD_ROW")}
        </h3>
      </div>
      <hr className="mt-1 mb-2st-filter-header-manageuser" />
      <div className="p-2">
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {(formick) => {
            return (
              <Form>
                <Row>
                  <Col sm={12} className="mb-1">
                    <FormGroup className="">
                      <Label className="region-label mt-0">
                        {" "}
                        {t("EARLY_ACTIONS.ACTIVITIES")} {t("EARLY_ACTIONS.*")}
                      </Label>
                      <Field
                        classNameActive="input-class-intervention-text-area"
                        classNameInactive={
                          formick?.name?.email && formick?.name?.email
                            ? "input-class-intervention-text-area input-error-class"
                            : "input-class-intervention-text-area"
                        }
                        type="textarea"
                        name="activities"
                        component={formickTextField}
                        onChangeField={(e) =>
                          setAddNewRow((prev) => ({
                            ...prev,
                            activities: e.target.value,
                          }))
                        }
                        validate={(value) => lengthRequired(value, 400)}
                        placeholder={t("EARLY_ACTIONS.ACTIVITIES")}
                      />
                      {formick?.errors?.activities &&
                        formick?.touched?.activities && (
                          <p className=" error">
                            {t(formick?.errors?.activities)}
                          </p>
                        )}
                    </FormGroup>
                  </Col>
                  <Col sm={12} className="mb-1">
                    <FormGroup className="">
                      <Label className="region-label mt-0">
                        {" "}
                        {t("DASHBOARD.TIME_FRAME")} {t("EARLY_ACTIONS.*")}
                      </Label>
                      <Field
                        classNameActive="input-class-intervention"
                        classNameInactive={
                          formick?.name?.email && formick?.name?.email
                            ? "input-class-intervention input-error-class"
                            : "input-class-intervention"
                        }
                        type="text"
                        name="timeframe"
                        component={formickTextField}
                        validate={(value) => lengthRequired(value, 40)}
                        onChangeField={(e) =>
                          setAddNewRow((prev) => ({
                            ...prev,
                            timeframe: e.target.value,
                          }))
                        }
                        placeholder={t("DASHBOARD.TIME_FRAME")}
                      />
                      {formick?.errors?.timeframe &&
                        formick?.touched?.timeframe && (
                          <p className=" error">
                            {t(formick?.errors?.timeframe)}
                          </p>
                        )}
                    </FormGroup>
                  </Col>

                  <Col sm={12} className="mb-1">
                    <FormGroup className="">
                      <Label className="region-label mt-0">
                        {t("EARLY_ACTIONS.TARGET")} {t("EARLY_ACTIONS.*")}
                      </Label>
                      <Field
                        classNameActive="input-class-intervention"
                        classNameInactive={
                          formick?.name?.email && formick?.name?.email
                            ? "input-class-intervention input-error-class"
                            : "input-class-intervention"
                        }
                        type="text"
                        name="target"
                        component={formickTextField}
                        onChangeField={(e) =>
                          setAddNewRow((prev) => ({
                            ...prev,
                            target: e.target.value,
                          }))
                        }
                        validate={(value) => lengthRequired(value, 40)}
                        placeholder={t("EARLY_ACTIONS.TARGET")}
                      />
                      {formick?.errors?.target && formick?.touched?.target && (
                        <p className=" error">{t(formick?.errors?.target)}</p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col sm={12} className="mb-3 ea-table-btn-section">
                    <Button
                      onClick={() => toggle()}
                      className="back-office-cancel-btn"
                    >
                      {" "}
                      cancel
                    </Button>
                    <Button
                      type="submit"
                      className="back-office-ok-btn w-auto mb-1 px-5"
                      disabled={!formick.isValid || !formick.dirty}
                    >
                      {" "}
                      {isOpenEditEditor
                        ? t("EARLY_ACTIONS.EDIT")
                        : t("EARLY_ACTIONS.ADD")}{" "}
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default RightSideModal;
