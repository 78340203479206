import Select from "react-select";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { MdKeyboardArrowRight } from "react-icons/md";
import { renderReactOptionsArray } from "../../helpers";
import { searchByCriteriaManageUserGuideRequest } from "../../store/actions";

function ManageUserGuideFilter({
  catrgory,
  setSelectedCategory,
  selectedCatrgory,
  setSubSelectedCategory,
  selectedSubCatrgory,
  setSubList,
  subList,
  isHide,
  setIsHide,
  setUserGuideList,
  setPageNumber,
  setScrollValue,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onSelectCatergory = (e) => {
    setSubList(null);
    setSelectedCategory(e);
    catrgory?.map((eachValue) => {
      if (eachValue?.id === e.value) {
        setSubSelectedCategory([...eachValue?.sub_topic]);
      }
      return null;
    });
  };

  const onSelectSubCategory = (e) => {
    setSubList(e);
  };

  const onFilterUserData = () => {
    window.scrollTo({ top: 0 });
    const data = {
      is_backoffice: "True",
      is_delete: "False",
      status: "",
      topic_id: selectedCatrgory?.value,
    };
    if (subList) {
      data.subtopic_id = subList?.value;
    }
    setUserGuideList([]);
    setPageNumber(1);
    setScrollValue(0);
    dispatch(searchByCriteriaManageUserGuideRequest({ page: 1, data }));
  };
  return (
    <div className="user-guide-filter">
      <div
        onClick={() => setIsHide(!isHide)}
        onKeyUp={() => {}}
        role="button"
        tabIndex="0"
        className="hide-bar mt-3 user-guide-icon"
      >
        <p className="mr-2"> {t("MANAGE_USER.HIDE_BAR")} </p>
        <MdKeyboardArrowRight className="hide-icon expand-margin" size={25} />
      </div>
      <div className="filter-container-2">
        <div className="common-select">
          <Select
            options={renderReactOptionsArray(catrgory, "name", "id")}
            classNamePrefix="react-select"
            className=" common-select-r mt-4"
            value={selectedCatrgory}
            onChange={(e) => onSelectCatergory(e)}
            placeholder={t("MANAGE_USER.CATEGORY")}
          />
        </div>
        {selectedCatrgory !== null && (
          <div className="common-select">
            <Select
              options={renderReactOptionsArray(
                selectedSubCatrgory,
                "name",
                "id",
              )}
              onChange={onSelectSubCategory}
              value={subList}
              classNamePrefix="react-select"
              className=" common-select-r mt-4"
              placeholder={t("MANAGE_USER.SUB_CATEGORY")}
            />
          </div>
        )}
        <div className="userguide-aligncenter mt-4">
          <Button
            className="manage-user-filter text-center w-70"
            disabled={selectedCatrgory === null}
            onClick={onFilterUserData}
          >
            {" "}
            {t("MANAGE_USER.FILTER")}{" "}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ManageUserGuideFilter;
