import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "react-spinners/FadeLoader";

function GraphLoader(props) {
  const { t } = useTranslation();
  const { glofasLoader, isRapidResponse } = props;
  /*  -----nextprops------  */
  const nextProps = useSelector(
    (state) => ({
      droughtTimeSeriesloading: state.Drought.droughtTimeSeriesloading,
      floodTimeSeriesloading: state.Drought.floodTimeSeriesloading,
      isWeatherForecastGraphRequesting:
        state.Weather.isWeatherForecastGraphRequesting,
      isGraphLoading: state.Loader.isGraphLoading,
      graphLoading: state.Loader.graphLoading,
    }),
    shallowEqual,
  );
  return (
    (nextProps.droughtTimeSeriesloading ||
      nextProps.floodTimeSeriesloading ||
      nextProps.isWeatherForecastGraphRequesting ||
      nextProps.isGraphLoading ||
      nextProps.graphLoading ||
      glofasLoader ||
      isRapidResponse) && (
      <div className="graph-loader-container" id="loader">
        <div className=" loader-text-alignmant">
          <Loader
            color="#179B6B"
            loading={
              nextProps.droughtTimeSeriesloading ||
              nextProps.floodTimeSeriesloading ||
              nextProps.isWeatherForecastGraphRequesting ||
              nextProps.isGraphLoading ||
              nextProps.graphLoading ||
              glofasLoader ||
              isRapidResponse
            }
            margin={2}
            size={20}
          />
          <div className="loadet-text mt-3">
            <p> {t("LOADING.LOADING")} </p>
          </div>
        </div>
      </div>
    )
  );
}

export default GraphLoader;
