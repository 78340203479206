import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { projectStatusRequest } from "../../store/actions";
import successCheckmark from "../../assest/img/EF-currency.png";

function SuccessConfirmationModal({
  byDefaultCurrncy,
  currencyList,
  setSelectedCurrency,
  projectDetails,
  selectedCurrency,
  toggle,
}) {
  const { t } = useTranslation();

  const [defaultCurrencyCountry, setDefaultCurrency] = useState("");
  const [standardCurrency, setStandardCurrency] = useState("");

  const nextProps = useSelector((state) => ({
    projectStatus: state?.EaProjects?.projectStatus,
  }));

  // edit project sucess
  const isEditProjectSucess = useRef(true);
  useEffect(() => {
    if (isEditProjectSucess.current) {
      isEditProjectSucess.current = false;
      return;
    }
    if (nextProps.projectStatus) {
      toggle();
    }
  }, [nextProps.projectStatus]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currencyList?.length) {
      const defaultCurrencyValue1 = currencyList?.find(
        (each) => each.currency_code === byDefaultCurrncy.currency,
      );

      const usdCurrencyValue1 = currencyList?.find(
        (each) => each.currency_code === "USD",
      );

      setStandardCurrency(usdCurrencyValue1);
      setDefaultCurrency(defaultCurrencyValue1);
      setSelectedCurrency(defaultCurrencyValue1);
    }
  }, [currencyList, byDefaultCurrncy]);

  const onHandleCurrency = (e) => {
    e.target.value === defaultCurrencyCountry.id
      ? setSelectedCurrency(defaultCurrencyCountry)
      : setSelectedCurrency(standardCurrency);
  };

  const onSubmitCurrency = () => {
    const payload = {
      project_id: projectDetails?.id,
      currency_id: selectedCurrency.id,
    };

    localStorage.setItem(
      "projectDetails",
      JSON.stringify({
        ...projectDetails,
        currency_code: selectedCurrency.currency_code,
        currency_symbol: selectedCurrency?.currency_symbol,
        ef_currency_id: selectedCurrency.id,
      }),
    );
    dispatch(projectStatusRequest(payload));
  };

  return (
    <Modal isOpen size="md">
      <div className="success-modal">
        <ModalHeader className="p-0 delete-header user-flex-end mr-2 mt-5" />
        <ModalBody className="delete-modal-padding">
          <div className="reg-success">
            <img
              src={successCheckmark}
              alt="not-available"
              width={78}
              height={78}
            />
            <h2 className="ef-currency-header">
              {" "}
              {t("EARLY_FINANCE.CHOOSE_CURRENCY")}
            </h2>
            <p className="ef-currency-desc px-4">
              {t("EARLY_FINANCE.CURRENCY_TEXT")}
            </p>
            <div
              className="currncy-radio-btn-conatiner mb-3"
              onChange={onHandleCurrency}
            >
              <div className="currnecy-radio-btn mlr-4">
                <div>
                  <Input
                    type="radio"
                    name="dynamic"
                    className="currency-input-btn"
                    value={defaultCurrencyCountry?.id}
                    checked={
                      selectedCurrency?.id === defaultCurrencyCountry?.id
                    }
                  />
                </div>
                <p className="currency-text">
                  {" "}
                  {defaultCurrencyCountry?.currency_code}{" "}
                </p>
              </div>
              <div className="currnecy-radio-btn ml-4">
                <div>
                  <Input
                    type="radio"
                    name="dynamic"
                    className="currency-input-btn"
                    value={standardCurrency?.id}
                    checked={selectedCurrency?.id === standardCurrency?.id}
                  />
                </div>
                <p className="currency-text">
                  {" "}
                  {standardCurrency?.currency_code}{" "}
                </p>
              </div>
            </div>
            <Button
              type="button"
              className="delete-confirmation-ok-btn mb-1"
              onClick={onSubmitCurrency}
              disabled={selectedCurrency === ""}
            >
              {t("UPLOAD_PAST_DATA.OKAY")}
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default SuccessConfirmationModal;
