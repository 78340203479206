const GUIDE_LINES_CONST = {
  GET_ALL_GUIDE_LINES_REQUEST: "GET_ALL_GUIDE_LINES_REQUEST",
  GET_ALL_GUIDE_LINES_SUCCESS: "GET_ALL_GUIDE_LINES_SUCCESS",
  GET_ALL_GUIDE_LINES_ERROR: "GET_ALL_GUIDE_LINES_ERROR",

  GET_EDIT_GUIDE_LINES_REQUEST: "GET_EDIT_GUIDE_LINES_REQUEST",
  GET_EDIT_GUIDE_LINES_SUCCESS: "GET_EDIT_GUIDE_LINES_SUCCESS",
  GET_EDIT_GUIDE_LINES_ERROR: "GET_EDIT_GUIDE_LINES_ERROR",
};
export default GUIDE_LINES_CONST;
