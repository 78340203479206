import RAPID_RESPONSE_CONST from "./actionTypes";

// MARKET MAP DATA
export const getRainfallMapDataRequest = (payload) => ({
  type: RAPID_RESPONSE_CONST.GET_RAINFALL_MAP_DATA_REQUEST,
  payload,
});

export const getRainfallMapDataSuccess = (response) => ({
  type: RAPID_RESPONSE_CONST.GET_RAINFALL_MAP_DATA_SUCCESS,
  payload: response,
});

export const getRainfallMapDataFailed = (error) => ({
  type: RAPID_RESPONSE_CONST.GET_RAINFALL_MAP_DATA_FAILED,
  payload: error,
});

// NEWSFEED DATA
export const getRapidResponseNewsfeedDataRequest = (payload) => ({
  type: RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_NEWSFEED_DATA_REQUEST,
  payload,
});

export const getRapidResponseNewsfeedDataSuccess = (response) => ({
  type: RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_NEWSFEED_DATA_SUCCESS,
  payload: response,
});

export const getRapidResponseNewsfeedDataFailed = (error) => ({
  type: RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_NEWSFEED_DATA_FAILED,
  payload: error,
});

export const getDisasterEventsDataRequest = (payload) => ({
  type: RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_DISASTER_EVENTS_DATA_REQUEST,
  payload,
});

export const getDisasterEventsDataSuccess = (response) => ({
  type: RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_DISASTER_EVENTS_DATA_SUCCESS,
  payload: response,
});

export const getDisasterEventsDataFailed = (error) => ({
  type: RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_DISASTER_EVENTS_DATA_FAILED,
  payload: error,
});

export const getDisasterListDataRequest = (payload) => ({
  type: RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_DISASTER_TYPES_REQUEST,
  payload,
});

export const getDisasterListDataSuccess = (response) => ({
  type: RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_DISASTER_TYPES_SUCCESS,
  payload: response,
});

export const getDisasterListDataFailed = (error) => ({
  type: RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_DISASTER_TYPES_FAILED,
  payload: error,
});

export const createDisasterAlertRequest = (payload) => ({
  type: RAPID_RESPONSE_CONST.CREATE_DISASTER_ALERT_REQUEST,
  payload,
});

export const createDisasterAlertSuccess = (response) => ({
  type: RAPID_RESPONSE_CONST.CREATE_DISASTER_ALERT_SUCCESS,
  payload: response,
});

export const createDisasterAlertFailed = (error) => ({
  type: RAPID_RESPONSE_CONST.CREATE_DISASTER_ALERT_FAILED,
  payload: error,
});

export const getDisasterAlertListRequest = (payload) => ({
  type: RAPID_RESPONSE_CONST.GET_DISASTER_ALERT_LIST_REQUEST,
  payload,
});

export const getDisasterAlertListSuccess = (response) => ({
  type: RAPID_RESPONSE_CONST.GET_DISASTER_ALERT_LIST_SUCCESS,
  payload: response,
});

export const getDisasterAlertListFailed = (error) => ({
  type: RAPID_RESPONSE_CONST.GET_DISASTER_ALERT_LIST_FAILED,
  payload: error,
});

export const getApproveDisasterEventRequest = (payload) => ({
  type: RAPID_RESPONSE_CONST.GET_APPROVE_DISASTER_EVENT_REQUEST,
  payload,
});

export const getApproveDisasterEventSuccess = (response) => ({
  type: RAPID_RESPONSE_CONST.GET_APPROVE_DISASTER_EVENT_SUCCESS,
  payload: response,
});

export const getApproveDisasterEventFailed = (error) => ({
  type: RAPID_RESPONSE_CONST.GET_APPROVE_DISASTER_EVENT_FAILED,
  payload: error,
});
