import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { BsArrowLeft, BsPlusLg } from "react-icons/bs";
import { useNavigate, useOutletContext } from "react-router-dom";
import ICON from "../../../../assest/img/empy-img.png";
import EarlyActionTable from "../../../../components/Common/EarlyActionTable";
import { ReactComponent as EditIcon } from "../../../../assest/img/ea-edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assest/img/ea-delete.svg";
import { ReactComponent as DownloadIcon } from "../../../../assest/img/ea-download.svg";
import RightSideModal from "./editImageSidebar";
import ReadynessTable from "../readnessAddRow";
import DeleteModal from "../../DeleteModal";
import {
  createReadynessContentRequest,
  createReadynessContentSuccess,
} from "../../../../store/actions";
import { showSuccess } from "../../../../components/Common/Notification";
import { handleExportToCSVIntervantion } from "../../../../helpers/excelDownload";
import { ColumnsData } from "../../../../constants";

function NewIntervention() {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();

  const [, setOpen] = useOutletContext(); // for closing side bar when it is opening

  const [intervantationData, setInterventationdataList] = useState({
    name: "",
    indicator: "",
    people: "",
    houseHolds: "",
    file: "",
    base64: "",
  });

  const [deleteModal, setDeleteModal] = useState(false);

  const [addNewRow, setAddNewRow] = useState({
    id: "",
    activities: "",
    timeframe: "",
    target: "",
  });

  const [initialValues, setInitialValues] = useState({
    activities: "",
    timeframe: "",
    target: "",
  });
  const [readynessPPState, setReadynessPPState] = useState([]);
  const [readynessRRState, setReadynessRRState] = useState([]);
  const [readynessPrState, setReadynessPrState] = useState([]);
  const [selectTable, setSelectTable] = useState();
  const [imageData, setImageData] = useState("");
  const [base64, setBase64] = useState("");

  const [filterModal, setFilterModal] = useState(false);
  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [isOpenEditEditor, setIsOpenEditEditor] = useState(false);
  const [onChangeImage, setOnChangeImage] = useState(false);

  const handleClickBack = () => {
    localStorage.setItem("stepperActiveStep", "1");
    history(-1);
  };

  useEffect(() => {
    setOpen(true);
    return () => {
      dispatch(createReadynessContentSuccess());
    };
  }, []);

  const COLUMNS = [
    {
      Header: t("EARLY_ACTIONS.ACTIVITIES"),
      accessorKey: "Activities",
      size: 350,
    },
    {
      Header: t("EARLY_ACTIONS.TIME_FRAME"),
      accessorKey: "Timeframe",
      size: 100,
    },
    {
      Header: t("EARLY_ACTIONS.TARGET"),
      accessorKey: "Target",
      size: 60,
    },
    {
      Header: t("EARLY_ACTIONS.MANAGE_DATA"),
      accessorKey: "managedata",
      size: 60,
    },
  ];

  const nextProps = useSelector((state) => ({
    addSucess: state?.EAReadynessReducer?.createReadynessPhaseData,
  }));

  const isAddSuccess = useRef(true);
  useEffect(() => {
    if (isAddSuccess.current) {
      isAddSuccess.current = false;
      return;
    }
    if (nextProps.addSucess) {
      const {
        data: { result },
      } = nextProps.addSucess;
      if (result) {
        history(`/preparedness-phase?step=1`);
        localStorage.setItem("stepperActiveStep", "1");
      }
    }
  }, [nextProps.addSucess]);

  const handleAddRow = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setIsOpenEditor(true);
  };

  const onHanldeAddEdit = (data) => {
    const uniqueId = uuidv4();
    const modifedColumn = {};

    modifedColumn.id = isOpenEditEditor ? initialValues?.id : uniqueId;
    modifedColumn.Activities = data.activities;
    modifedColumn.Timeframe = data.timeframe;
    modifedColumn.Budget = "";
    modifedColumn.Target = data.target;

    modifedColumn.managedata = (
      <div className="d-flex align-items-center justify-content-center">
        <span>
          <EditIcon
            onClick={() => {
              setInitialValues({
                ...data,
                table: selectTable,
                id: isOpenEditEditor ? initialValues?.id : uniqueId,
              });
              setSelectTable(selectTable);
              setIsOpenEditor(true);
              setIsOpenEditEditor(true);
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
            className="cursor-pointer"
          />
        </span>
        <span className="ml-2">
          <DeleteIcon
            className="cursor-pointer"
            onClick={() => {
              setInitialValues({
                ...data,
                table: selectTable,
                id: isOpenEditEditor ? initialValues?.id : uniqueId,
              });
              setSelectTable(selectTable);
              setDeleteModal(true);
            }}
          />
        </span>
      </div>
    );
    if (isOpenEditEditor) {
      if (selectTable === 1) {
        const updatedArray = readynessPPState.map((obj) =>
          obj.id === initialValues?.id ? { ...modifedColumn, id: obj.id } : obj,
        );
        setReadynessPPState([...updatedArray]);
      }
      if (selectTable === 2) {
        const updatedArray = readynessRRState.map((obj) =>
          obj.id === initialValues?.id ? { ...modifedColumn, id: obj.id } : obj,
        );
        setReadynessRRState([...updatedArray]);
      }
      if (selectTable === 3) {
        const updatedArray = readynessPrState.map((obj) =>
          obj.id === initialValues?.id ? { ...modifedColumn, id: obj.id } : obj,
        );
        setReadynessPrState([...updatedArray]);
      }
      showSuccess("Data has been updated successfully.");
    } else {
      if (selectTable === 1) {
        setReadynessPPState([modifedColumn, ...readynessPPState]);
      }
      if (selectTable === 2) {
        setReadynessRRState([modifedColumn, ...readynessRRState]);
      }
      if (selectTable === 3) {
        setReadynessPrState([modifedColumn, ...readynessPrState]);
      }
    }
    setIsOpenEditor(false);
    setIsOpenEditEditor(false);

    setAddNewRow((prev) => ({
      ...prev,
      id: "",
      activities: "",
      timeframe: "",
      target: "",
      table: "",
    }));
    setInitialValues((prev) => ({
      ...prev,
      id: "",
      activities: "",
      timeframe: "",
      target: "",
    }));
    setSelectTable();
  };

  useEffect(() => {
    if (!isOpenEditor) {
      setAddNewRow((prev) => ({
        ...prev,
        id: "",
        activities: "",
        timeframe: "",
        target: "",
        table: "",
      }));
      setInitialValues((prev) => ({
        ...prev,
        id: "",
        activities: "",
        timeframe: "",
        target: "",
      }));
      setAddNewRow((prev) => ({
        ...prev,
        id: "",
        activities: "",
        timeframe: "",
        target: "",
        table: "",
      }));
      setSelectTable();
    }
  }, [isOpenEditor]);

  useEffect(() => {
    if (!deleteModal) {
      setInitialValues((prev) => ({
        ...prev,
        id: "",
        activities: "",
        timeframe: "",
        target: "",
      }));
      setAddNewRow((prev) => ({
        ...prev,
        id: "",
        activities: "",
        timeframe: "",
        target: "",
        table: "",
      }));
      setSelectTable();
    }
  }, [deleteModal]);

  const handleDeleteApiCall = () => {
    if (selectTable === 1) {
      const updatedArray = readynessPPState.filter(
        (obj) => obj.id !== initialValues?.id,
      );
      setReadynessPPState([...updatedArray]);
    }
    if (selectTable === 2) {
      const updatedArray = readynessRRState.filter(
        (obj) => obj.id !== initialValues?.id,
      );
      setReadynessRRState([...updatedArray]);
    }
    if (selectTable === 3) {
      const updatedArray = readynessPrState.filter(
        (obj) => obj.id !== initialValues?.id,
      );
      setReadynessPrState([...updatedArray]);
    }
    showSuccess("Data has been updated successfully.");
  };

  function removeKeyFromObject(obj, keyToRemove) {
    const newObj = { ...obj };
    delete newObj[keyToRemove];
    return newObj;
  }

  const onCreateNewIntervention = () => {
    const readynessPPStatenewArray = readynessPPState.map((obj) =>
      removeKeyFromObject(obj, "managedata"),
    );
    const readynessRRStatenewArray = readynessRRState.map((obj) =>
      removeKeyFromObject(obj, "managedata"),
    );
    const readynessPrStatenewArray = readynessPrState.map((obj) =>
      removeKeyFromObject(obj, "managedata"),
    );
    const payLoad = {
      project_id: JSON.parse(localStorage.getItem("projectDetails"))?.id,
      name: intervantationData?.name,
      indicator: intervantationData?.indicator,
      preparedness_content: readynessPPStatenewArray,
      readiness_content: readynessRRStatenewArray,
      prioritized_ea_content: readynessPrStatenewArray,
      households_targeted:
        intervantationData?.houseHolds === ""
          ? 0
          : intervantationData?.houseHolds,
      people_targeted:
        intervantationData?.people === "" ? 0 : intervantationData?.people,
    };
    if (base64) {
      payLoad.icon = {
        base64: base64.split(",")?.[1],
        file: imageData?.name,
      };
    }
    dispatch(
      createReadynessContentRequest({
        ...payLoad,
      }),
    );
  };

  const onHandleInterventionData = (data) => {
    setInterventationdataList((prev) => ({
      ...prev,
      name: data?.name,
      indicator: data?.indicator,
      people: data?.people,
      houseHolds: data?.houseHolds,
      file: imageData,
      base64,
    }));
  };

  const onHandleDownloadexcel = (data, fileNmae) => {
    handleExportToCSVIntervantion(data, ColumnsData, fileNmae);
  };

  return (
    <>
      <div className="ea-project-container">
        <div className="EA-Header ea-space-between">
          <div className="ea-header-image-position">
            <div>
              <BsArrowLeft
                className="back-icon-ea"
                onClick={handleClickBack}
                role="button"
              />
              <p className="ea-header-text">
                {t("EARLY_ACTIONS.NEW_INTERVENTION")}
              </p>
            </div>
            <div>
              <Button
                className="ea-submit-btn"
                onClick={onCreateNewIntervention}
                disabled={
                  intervantationData?.name === "" ||
                  readynessPPState?.length === 0 ||
                  readynessRRState?.length === 0 ||
                  readynessPrState?.length === 0 ||
                  isOpenEditor ||
                  filterModal
                }
              >
                {t("EARLY_ACTIONS.SUBMIT")}
              </Button>
            </div>
          </div>
        </div>
        <div className="ea-space">
          <Row className="new-inv-div">
            <Col lg={2}>
              {intervantationData?.base64 ? (
                <img
                  src={intervantationData?.base64}
                  alt=""
                  className="new-inv-img"
                />
              ) : (
                <img src={ICON} alt="" className="new-inv-img" />
              )}
            </Col>
            <Col lg={10} className="new-inv-heading">
              <EditIcon
                className="float-right pointer"
                onKeyDown={() => {}}
                onClick={() => setFilterModal(true)}
              />
              <h3>
                {intervantationData?.name === ""
                  ? t("EARLY_ACTIONS.NEW_INTERVENTION")
                  : intervantationData?.name}
              </h3>
              <p>
                {t("EARLY_ACTIONS.INDICATOR")}: {intervantationData?.indicator}
              </p>
              <p>
                {t("EARLY_ACTIONS.TARGETED_HOUSEHOLDS")}:{" "}
                {intervantationData?.houseHolds}
              </p>
              <p>
                {t("EARLY_ACTIONS.PEOPLE")}: {intervantationData?.people}
              </p>
            </Col>
          </Row>
          <div className="new-inv-section">
            <div className="new-inv-section-2">
              <div className="intervention-div">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="intervention-h2">
                    {t("EARLY_ACTIONS.PREPAREDNESS")}
                  </h2>
                  <div className="actions-container d-flex align-items-center justify-content-end">
                    <span
                      role="button"
                      className={
                        readynessPPState?.length > 0
                          ? "ea-upload-button"
                          : "download-opacity ea-upload-button"
                      }
                      onClick={() =>
                        onHandleDownloadexcel(
                          readynessPPState,
                          "preparedness_table",
                        )
                      }
                      onKeyUp={() => {}}
                      tabIndex="0"
                      aria-label="viewReadynessPPState"
                    >
                      <DownloadIcon />
                    </span>
                    <Button
                      className="readiness-btn"
                      onClick={() => {
                        setSelectTable(1);
                        handleAddRow("", true);
                      }}
                    >
                      {t("EARLY_ACTIONS.ADD_NEW_ROW")}
                      <BsPlusLg className="ml-2" />
                    </Button>
                  </div>
                </div>
                <div className="new-inv-table">
                  <EarlyActionTable
                    COLUMNS={COLUMNS}
                    tableData={readynessPPState}
                    step={1}
                  />
                </div>
              </div>
              <div className="intervention-div">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="intervention-h2">
                    {t("EARLY_ACTIONS.READINESS")}
                  </h2>
                  <div className="actions-container d-flex align-items-center justify-content-end">
                    <input type="file" style={{ display: "none" }} />

                    <span
                      role="button"
                      className={
                        readynessRRState?.length > 0
                          ? "ea-upload-button"
                          : "download-opacity ea-upload-button"
                      }
                      onClick={() =>
                        onHandleDownloadexcel(
                          readynessRRState,
                          "readyness_table",
                        )
                      }
                      onKeyUp={() => {}}
                      tabIndex="0"
                      aria-label="viewReadynessRRState"
                    >
                      <DownloadIcon />
                    </span>
                    <Button
                      className="readiness-btn"
                      onClick={() => {
                        handleAddRow("", true);
                        setSelectTable(2);
                      }}
                    >
                      {t("EARLY_ACTIONS.ADD_NEW_ROW")}
                      <BsPlusLg className="ml-2" />
                    </Button>
                  </div>
                </div>
                <div className="new-inv-table">
                  <EarlyActionTable
                    COLUMNS={COLUMNS}
                    tableData={readynessRRState}
                  />
                </div>
              </div>
              <div className="intervention-div">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="intervention-h2">
                    {t("EARLY_ACTIONS.PRIORITY_EA")}
                  </h2>
                  <div className="actions-container d-flex align-items-center justify-content-end">
                    <span
                      role="button"
                      className={
                        readynessPrState?.length > 0
                          ? "ea-upload-button"
                          : "download-opacity ea-upload-button"
                      }
                      onClick={() =>
                        onHandleDownloadexcel(
                          readynessPrState,
                          "priority_table",
                        )
                      }
                      onKeyUp={() => {}}
                      tabIndex="0"
                      aria-label="viewReadynessPrState"
                    >
                      <DownloadIcon />
                    </span>

                    <Button
                      className="readiness-btn"
                      onClick={() => {
                        handleAddRow("", true);
                        setSelectTable(3);
                      }}
                    >
                      {t("EARLY_ACTIONS.ADD_NEW_ROW")}
                      <BsPlusLg className="ml-2" />
                    </Button>
                  </div>
                </div>
                <div className="new-inv-table">
                  <EarlyActionTable
                    COLUMNS={COLUMNS}
                    tableData={readynessPrState}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {filterModal ? (
          <RightSideModal
            // setInterventationdataList={setInterventationdataList}
            onHandleInterventionData={onHandleInterventionData}
            intervantationData={intervantationData}
            toggle={() => {
              setFilterModal(!filterModal);
              setIsOpenEditEditor(false);
            }}
            isOpenEditEditor={isOpenEditEditor}
            setImageData={setImageData}
            imageData={imageData}
            setBase64={setBase64}
            base64={base64}
            filterModal={filterModal}
            setFilterModal={setFilterModal}
            setOnChangeImage={setOnChangeImage}
            onChangeImage={onChangeImage}
          />
        ) : (
          ""
        )}
        {isOpenEditor ? (
          <ReadynessTable
            setAddNewRow={setAddNewRow}
            addNewRow={addNewRow}
            onHanldeAddEdit={onHanldeAddEdit}
            initialValues={initialValues}
            isOpenEditEditor={isOpenEditEditor}
            toggle={() => {
              setIsOpenEditor(false);
              setIsOpenEditEditor(false);
            }}
            isOpenEditor={isOpenEditor}
            setIsOpenEditor={setIsOpenEditor}
          />
        ) : (
          ""
        )}
      </div>
      {deleteModal ? (
        <DeleteModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          handleDeleteApiCall={handleDeleteApiCall}
        />
      ) : (
        ""
      )}
      {(filterModal || isOpenEditor || deleteModal) && (
        <div className="ea-project-card-disabled " />
      )}
    </>
  );
}
export default NewIntervention;
