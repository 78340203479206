import React, { useEffect, useState } from "react";
import { Button, FormGroup, Row, Col, Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import { formickTextField } from "../../../../components/Common/FormickField";
import "react-multi-date-picker/styles/colors/green.css";
import Basic from "../../../../components/Common/DragAndDrop";
import { selectinteger, lengthRequired } from "../../../../helpers/validate";
import { dropzoneAccept } from "../../../../constants";

function RightSideModal({
  toggle,
  intervantationData,
  onHandleInterventionData,
  setImageData,
  imageData,
  setBase64,
  base64,
  setApiCall,
  filterModal,
  onChangeImage,
  setOnChangeImage,
}) {
  const { t } = useTranslation();

  const [, setInterventationdata] = useState({
    name: "",
    indicator: "",
    people: "",
    houseHolds: "",
  });

  const initialValues = {
    name: intervantationData?.name,
    people_targeted: intervantationData?.people,
    households_targeted: intervantationData?.houseHolds,
    indicator: intervantationData?.indicator,
  };

  useEffect(() => {
    // Add a CSS class to the body element when the sideEditor is open
    if (filterModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [filterModal]);

  const handleSubmit = (data) => {
    onHandleInterventionData({
      name: data?.name,
      indicator: data?.indicator,
      people: data?.people_targeted,
      houseHolds: data?.households_targeted,
      file: imageData,
      base64,
    });
    setApiCall(true);
    setOnChangeImage(false);
    toggle();
  };

  const onHandleUploadImage = (imgData, base64String) => {
    setOnChangeImage(true);
    setImageData(imgData);
    setBase64(base64String);
  };

  return (
    <div className="st-right-sidebar-wrapper-filter-intervention">
      <div className="st-filter-header-manageuser edit-header-text-center">
        <p className="image-sidebar-header">
          {initialValues?.name
            ? t("EARLY_ACTIONS.EDIT_SECTION")
            : t("EARLY_ACTIONS.ADD_SECTION")}{" "}
        </p>
      </div>
      <hr />
      <div className="p-2 h-100">
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {(formick) => {
            useEffect(() => {
              // Automatically set touched for all fields when the form loads
              formick.setFieldTouched("name", true);
              formick.setFieldTouched("textarea", true);
              formick.setFieldTouched("households_targeted", true);
              formick.setFieldTouched("people_targeted", true);
            }, []);

            return (
              <Form className="h-100">
                <Row className="edit-row-height">
                  <Col sm={12} className="mb-0">
                    <FormGroup className="">
                      <Label className="region-label mt-0">
                        {t("EARLY_ACTIONS.NEW_INTERVENTION_1")}{" "}
                        {t("EARLY_ACTIONS.*")}
                      </Label>
                      <Field
                        classNameActive="input-class-intervention"
                        classNameInactive={
                          formick?.name?.email && formick?.name?.email
                            ? "input-class-intervention input-error-class"
                            : "input-class-intervention"
                        }
                        type="text"
                        name="name"
                        validate={(value) => lengthRequired(value, 60)}
                        component={formickTextField}
                        onChangeField={(e) => {
                          setInterventationdata((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }));
                        }}
                        placeholder={t("EARLY_ACTIONS.INTERVENTION_NAME")}
                      />
                      {formick?.errors?.name && formick?.touched?.name && (
                        <p className=" error">{t(formick?.errors?.name)}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm={12} className="mb-0">
                    <FormGroup className="">
                      <Label className="region-label mt-0">
                        {" "}
                        {t("EARLY_ACTIONS.INDICATOR")} {t("EARLY_ACTIONS.*")}
                      </Label>
                      <Field
                        classNameActive="input-class-intervention"
                        classNameInactive={
                          formick?.name?.email && formick?.name?.email
                            ? "input-class-intervention input-error-class"
                            : "input-class-intervention"
                        }
                        type="textarea"
                        name="indicator"
                        component={formickTextField}
                        onChangeField={() => {}}
                        validate={(value) => lengthRequired(value, 200)}
                        placeholder={t("EARLY_ACTIONS.WRITE_YOUR_TEXT")}
                      />
                      {formick?.errors?.indicator &&
                        formick?.touched?.indicator && (
                          <p className=" error">
                            {t(formick?.errors?.indicator)}
                          </p>
                        )}
                    </FormGroup>
                  </Col>
                  <Col sm={12} className="mb-0">
                    <FormGroup className="">
                      <Label className="region-label mt-0">
                        {t("EARLY_ACTIONS.TARGETED_HOUSEHOLDS")}{" "}
                        {t("EARLY_ACTIONS.*")}
                      </Label>
                      <Field
                        classNameActive="input-class-intervention"
                        classNameInactive={
                          formick?.name?.email && formick?.name?.email
                            ? "input-class-intervention input-error-class"
                            : "input-class-intervention"
                        }
                        type="text"
                        name="households_targeted"
                        component={formickTextField}
                        onChangeField={() => {}}
                        validate={(value) => selectinteger(value, 10)}
                        placeholder={t("EARLY_ACTIONS.TARGETED_HOUSEHOLDS")}
                      />
                      {formick?.errors?.households_targeted &&
                        formick?.touched?.households_targeted && (
                          <p className=" error">
                            {t(formick?.errors?.households_targeted)}
                          </p>
                        )}
                    </FormGroup>
                  </Col>

                  <Col sm={12} className="mb-0">
                    <FormGroup className="">
                      <Label className="region-label mt-0">
                        {" "}
                        {t("EARLY_ACTIONS.PEOPLE")} {t("EARLY_ACTIONS.*")}
                      </Label>
                      <Field
                        classNameActive="input-class-intervention"
                        classNameInactive={
                          formick?.name?.email && formick?.name?.email
                            ? "input-class-intervention input-error-class"
                            : "input-class-intervention"
                        }
                        type="text"
                        name="people_targeted"
                        component={formickTextField}
                        onChangeField={() => {}}
                        validate={(value) => selectinteger(value, 10)}
                        placeholder={t("EARLY_ACTIONS.NUMBER_OF_PEOPLE")}
                      />
                      {formick?.errors?.people_targeted &&
                        formick?.touched?.people_targeted && (
                          <p className=" error">
                            {t(formick?.errors?.people_targeted)}
                          </p>
                        )}
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <Label className="region-label mt-0">
                      {" "}
                      {t("EARLY_ACTIONS.UPLOAD_IMAGE")}{" "}
                    </Label>
                    <Basic
                      onHandleUploadImage={onHandleUploadImage}
                      acceptList={dropzoneAccept}
                      isBasic
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="mb-3">
                    <div className="d-flex align-items-center justify-content-end editor-button-container mt-3">
                      <Button
                        className="back-office-cancel-btn"
                        type="button"
                        onClick={() => {
                          setOnChangeImage(false);
                          toggle();
                        }}
                      >
                        {t("APPROVE_DATA.CANCEL")}
                      </Button>
                      <Button
                        className="back-office-ok-btn w-auto mb-1 px-5"
                        type="submit"
                        // disabled={!formick.isValid}
                        disabled={
                          initialValues.name === ""
                            ? !formick.isValid || !formick.dirty
                            : onChangeImage
                            ? !formick.isValid
                            : !formick.isValid || !formick.dirty
                        }
                      >
                        {t("BULLET_IN.SAVE")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default RightSideModal;
