import DOMPurify from "dompurify";
import React from "react";
import { useTranslation } from "react-i18next";

import { Row } from "reactstrap";
import { ReactComponent as DateIcon } from "../../assest/img/Date_Icon.svg";
import { ReactComponent as SourceIcon } from "../../assest/img/Source_Icon.svg";
import BackButton from "../../assest/img/back-button.svg";
import { dateFormatters } from "../../helpers";

import MediaPreviewComponent from "../../components/Common/MediaPreviewComponent";
import { RAPID_RESPONSE_NEWSFEED_DISASTER_TYPES } from "../../constants";

function ViewEvent({
  selectedEvent,
  handleBackEvent,
  handleRenderDisasterIcon,
}) {
  const { t } = useTranslation();

  // ! IF any description is having links we are converting it to clickable links
  const descriptionWithLinks = selectedEvent?.description?.replace(
    /\[([^[]+)\]\(([^)]+)\)/g,
    '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>',
  );

  const sanitizedDescription =
    descriptionWithLinks?.length !== ""
      ? DOMPurify.sanitize(descriptionWithLinks)
      : DOMPurify.sanitize("No Description");
  const disasterIcon = handleRenderDisasterIcon(selectedEvent?.type);

  return (
    <div className="events-container p-0">
      <div className="my-2 event-view-container">
        <div className="d-flex align-items-center">
          <img
            src={BackButton}
            alt="back-button"
            onClick={() => handleBackEvent()}
            onKeyUp={() => {}}
            className="pointer view-event-back-btn"
            width={64}
            height={64}
          />
          <h1 className="event-info-header">{selectedEvent?.name}</h1>
        </div>

        <hr className="event-divder-line my-1" />

        <div className="d-flex flex-wrap flex-column my-3">
          <p className="event-info-details my-2">
            <img
              src={disasterIcon[0]?.icon}
              alt={selectedEvent.name}
              width={16}
              height={16}
              className="mr-1"
            />
            {t("RAPID_RESPONSE.DISASTER_TYPE")}:{" "}
            {RAPID_RESPONSE_NEWSFEED_DISASTER_TYPES.find(
              (disaster) => disaster.value === selectedEvent?.type,
            )?.label || ""}
          </p>
          {selectedEvent?.fromdate ? (
            <p className="event-info-details my-2">
              <DateIcon width={16} height={16} className="mr-1" />
              {t("RAPID_RESPONSE.DATE")}:{" "}
              {selectedEvent?.fromdate
                ? dateFormatters(selectedEvent?.fromdate)
                : null}{" "}
              {selectedEvent.todate
                ? `- ${dateFormatters(selectedEvent?.todate)}`
                : null}
            </p>
          ) : null}
          <p className="event-info-details my-2">
            <SourceIcon width={16} height={16} className="mr-1" />
            {t("RAPID_RESPONSE.SOURCE")}: {selectedEvent?.source}
          </p>
        </div>

        {selectedEvent?.severitytext ? (
          <p className="event-info-severity-text my-3">
            {selectedEvent.severitytext}
          </p>
        ) : null}

        <p dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />

        {selectedEvent?.media_urls?.length ? (
          <Row className="my-2">
            <MediaPreviewComponent data={selectedEvent} cardSize={4} />
          </Row>
        ) : null}
      </div>
    </div>
  );
}

export default ViewEvent;
