import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { GrFormClose } from "react-icons/gr";

function CarouselComponent({ toggle, modal, carouselData }) {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    // Add a CSS class to the body element when the sideEditor is open
    if (modal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [modal]);

  const closeBtn = (
    <GrFormClose
      size={24}
      onClick={() => toggle()}
      className="pointer media-modal-header-close-icon"
    />
  );

  return (
    <div>
      <Modal isOpen={modal} size="md">
        <ModalHeader
          toggle={toggle}
          className="align-items-center media-modal-header-background"
          close={closeBtn}
        >
          <h2 className="intervention-h2 m-0">
            {t("EARLY_FINANCE_REPORT.MEDIA")}
          </h2>
        </ModalHeader>
        <ModalBody className="p-3">
          <div className="carousel-container">
            <div className="carousel-images">
              {carouselData.map((item, index) => (
                <img
                  key={item.link}
                  src={item.link}
                  alt={`${item.link}`}
                  className={`carousel-image ${
                    index === activeIndex ? "activeImage" : ""
                  }`}
                />
              ))}
            </div>
            <div className="carousel-thumbnails">
              {carouselData.map((item, index) => (
                <img
                  key={item.link}
                  src={item.link}
                  alt={`Thumbnail ${index}`}
                  className={`thumbnail ${
                    index === activeIndex ? "activeImage" : ""
                  }`}
                  onClick={() => handleThumbnailClick(index)}
                  onKeyUp={() => {}}
                />
              ))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CarouselComponent;
