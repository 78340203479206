import React, { useState, useEffect, useRef } from "react";
import { Button, Collapse, NavLink } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
// import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { MdHome, MdDashboard } from "react-icons/md";
// import { MdHome } from "react-icons/md";
// import "react-tooltip/dist/react-tooltip.css";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { dashBoardMenuRequest } from "../../store/actions";
import DisclaimerImg from "../../assest/img/DisclaimerImg.svg";
import {
  ONLINE_BULLETIN_SLUG,
  USER_GUIDE_SLUG,
  EARLY_ACTION_SLUG,
  EARLY_FINANCE_SLUG,
} from "../../constants";
import { isLogedIn } from "./Utils";

function Sidebar(props) {
  const { open, setOpen, toggleModal } = props;
  const navigate = useNavigate();

  const [openSubMenuDropdown, setOpenSubMenuDropdown] = useState(false);
  const [openSubMenuChildDropdown, setopenSubMenuChildDropdown] =
    useState(false);
  const [dashBoardData, setdashboardData] = useState([]);
  const dispatch = useDispatch();
  const [CollaspeMenu, setCollaspeMenu] = useState("");
  const [CollaspedMenu, setCollaspedMenu] = useState("");
  const [CollaspeulMenu, setCollaspeulMenu] = useState("");
  const [CollaspeMenuToggle, setCollaspeMenuToggle] = useState("");
  const [isSameMenu, setIsSameMenu] = useState("");
  const [selectedModuleName, setSelectedModuleName] = useState("");

  const [nonCollaspeModule, setNonCollapseModule] = useState("");

  const [toolTipid, setTooltipId] = useState("");

  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const selectedModule = params.get("module");
  const selectedId = params.get("moduleId");
  const mainModule = params.get("mainModule");
  const subModule = params.get("subModule");

  const nonCollaspeModuleSlug = params.get("slug");

  const nextProps = useSelector((state) => ({
    dashBoardMenuList: state.Dashboard.dashBoardMenuData,
  }));

  // for opening and closing main collape
  const onCollaspeToggle = (id, data) => {
    setNonCollapseModule("");
    setIsSameMenu("");
    const selectedTool = nextProps.dashBoardMenuList.filter(
      (eachCard) => eachCard.id === id,
    );
    if (isLogedIn()) {
      if (data?.slug === CollaspeMenu) {
        setCollaspeMenu("");
        setCollaspeMenuToggle(data.slug);
      } else {
        setCollaspeMenu(data?.slug);
        setCollaspeMenuToggle(data.slug);
        setCollaspedMenu(data?.menu_list?.[0]?.slug);

        if (data?.menu_list?.[0]?.sub_menu_list?.length) {
          setCollaspeulMenu(data?.menu_list?.[0]?.sub_menu_list?.[0]?.slug);

          navigate(
            // eslint-disable-next-line max-len
            `/early-warnings/${data?.menu_list?.[0]?.slug}/${data?.menu_list?.[0]?.sub_menu_list?.[0]?.slug}?module=${data?.menu_list?.[0]?.sub_menu_list?.[0]?.slug}&moduleId=${data?.menu_list?.[0]?.id}&mainModule=${data?.slug}&subModule=${data?.menu_list?.[0]?.slug}&id=${data?.menu_list?.[0]?.sub_menu_list?.[0]?.id}`,
          );
        } else {
          data?.menu_list?.[0]?.sub_menu_list?.length === 0 &&
            navigate(
              // eslint-disable-next-line max-len
              `/early-warnings/${data?.menu_list?.[0].slug}?module=${data?.menu_list?.[0].slug}&moduleId=${data?.menu_list?.[0].id}&mainModule=${data?.slug}&subModule=${data?.menu_list?.[0].slug}&id=${data?.menu_list?.[0].id}`,
            );
        }
      }
    } else {
      if (data.slug === "early_warning") {
        navigate(`/login?prevPath=${data?.title}`, {
          state: {
            selectedLanguage: "en",
            queryData: selectedTool,
          },
        });
      } else if (data?.title === t("DASHBOARD.SECTORIAL_CLIMATE_RISKS")) {
        navigate(`/login?prevPath=${data?.title}`, {
          state: {
            selectedLanguage: "en",
            queryData: selectedTool,
          },
        });
      }
    }
  };

  // for main data when there is no collaspe
  const handleNavigate = (id, data) => {
    if (isLogedIn()) {
      setNonCollapseModule(data.slug);
      setCollaspeMenu("");
      setCollaspedMenu("");
      setSelectedModuleName("");
      setIsSameMenu("");
      if (data?.slug === ONLINE_BULLETIN_SLUG) {
        navigate(`/online-bulletin?slug=${data?.slug}`);
      } else if (data?.slug === USER_GUIDE_SLUG) {
        navigate(`/user-guide?slug=${data?.slug}`);
      } else if (data?.slug === EARLY_ACTION_SLUG) {
        navigate(`/earlyaction?slug=${data?.slug}`);
      } else if (data?.slug === EARLY_FINANCE_SLUG) {
        navigate(`/early-finance-page?isFinance=true&slug=${data?.slug}`);
      } else if (data?.slug === "rapid_response") {
        navigate(`/rapid-response-newsfeed?slug=rapid_response`);
      } else if (data?.slug === t("DASHBOARD.CROP_YIELD_SLUG")) {
        navigate(`/crop_yield_prediction?slug=${data?.slug}`);
      }
    } else {
      if (data.title === t("HOME.USER_GUIDE")) {
        navigate("/user-guide");
      } else if (data.title === t("DASHBOARD.EARLY_ACTION")) {
        if (isLogedIn()) {
          navigate(`/earlyaction?slug=${data?.slug}`);
        } else {
          navigate({
            pathname: "/login",
            search: `?prevPath=${data.title}&slug=${data?.slug}`,
          });
        }
      } else if (data.title === "Early Finance") {
        if (isLogedIn()) {
          navigate(`/early-finance-page?isFinance=true?slug=${data?.slug}`);
        } else {
          navigate({
            pathname: "/login",
            search: `?prevPath=${data.title}&slug=${data?.slug}`,
          });
        }
      } else if (data.title === t("HOME.ONLINE_BULLETIN")) {
        if (isLogedIn()) {
          navigate(`/online-bulletin?slug=${data?.slug}`);
        } else {
          navigate({
            pathname: "/login",
            search: `?prevPath=${data.title}&slug=${data?.slug}`,
          });
        }
      }
    }
  };

  // collape inside collaspe
  const onCollaspeModules = (id, eachValue, eachdata) => {
    // setSelectedUncollaspedList("");
    if (eachValue.sub_menu_list.length === 0) {
      navigate(
        `/early-warnings/${eachValue.slug}?module=${eachValue.slug}&moduleId=${eachValue.id}&mainModule=${eachdata?.slug}&subModule=${eachValue.slug}&id=${eachValue.id}`,
      );
    }
    if (eachValue?.slug === isSameMenu) {
      setIsSameMenu(eachValue?.slug);
    } else {
      setIsSameMenu(eachValue?.slug);
    }
    if (open) {
      setOpenSubMenuDropdown(!openSubMenuDropdown);
      setopenSubMenuChildDropdown(!openSubMenuChildDropdown);
      if (CollaspedMenu === eachValue?.slug) {
        setCollaspedMenu("");
      } else {
        setCollaspedMenu(eachValue?.slug);
        eachValue.sub_menu_list.length === 0
          ? setCollaspeulMenu("")
          : setCollaspeulMenu(eachValue?.sub_menu_list?.[0]?.slug);
        if (eachValue.sub_menu_list.length !== 0) {
          navigate(
            // eslint-disable-next-line max-len, max-len
            `/early-warnings/${eachValue?.slug}/${eachValue?.sub_menu_list?.[0].slug}?module=${eachValue?.sub_menu_list?.[0].slug}&moduleId=${eachValue?.sub_menu_list?.[0].aware_menu_id}&mainModule=${eachdata?.slug}&subModule=${eachValue?.slug}&id=${eachValue?.sub_menu_list?.[0]?.id}`,
          );
        }
      }
    } else {
      if (eachValue?.slug === CollaspedMenu) {
        setCollaspedMenu("");
        setSelectedModuleName("");
        eachValue.sub_menu_list.length === 0 && setCollaspeulMenu("");
      } else {
        setSelectedModuleName(eachValue?.slug);
        setCollaspedMenu(eachValue?.slug);
        eachValue.sub_menu_list.length === 0
          ? setCollaspeulMenu("")
          : setCollaspeulMenu(eachValue?.sub_menu_list?.[0]?.slug);
        if (eachValue.sub_menu_list.length !== 0) {
          navigate(
            // eslint-disable-next-line max-len, max-len
            `/early-warnings/${eachValue?.slug}/${eachValue?.sub_menu_list?.[0].slug}?module=${eachValue?.sub_menu_list?.[0].slug}&moduleId=${eachValue?.sub_menu_list?.[0].aware_menu_id}&mainModule=${eachdata?.slug}&subModule=${eachValue?.slug}&id=${eachValue?.sub_menu_list?.[0]?.id}`,
          );
        }
        // eachValue.sub_menu_list.length === 0 && setCollaspeulMenu("");
      }
    }
    setOpenSubMenuDropdown(true);
  };

  const onCollaspeSubModule = (eachMenu, eachValue, eachData) => {
    navigate(
      `/early-warnings/${isSameMenu || CollaspedMenu}/${eachMenu.slug}?module=${
        eachMenu.slug
      }&moduleId=${eachMenu.aware_menu_id}&mainModule=${
        eachData?.slug
      }&subModule=${eachValue?.slug}&id=${eachMenu?.id}`,
    );
    if (open) {
      setopenSubMenuChildDropdown(true);
      setCollaspeulMenu(eachMenu.slug);
    } else {
      if (eachMenu.slug === CollaspeulMenu) {
        setCollaspeulMenu("");
      } else {
        setCollaspeulMenu(eachMenu.slug);
      }
    }
    setOpenSubMenuDropdown(false);
  };

  useEffect(() => {
    if (!open) {
      if (!openSubMenuDropdown) setopenSubMenuChildDropdown(false);
    }
  }, [openSubMenuDropdown]);

  useEffect(() => {
    if (open) {
      setCollaspedMenu(CollaspedMenu || subModule);
      setCollaspeulMenu(selectedModule);
      setOpenSubMenuDropdown(true);
      setopenSubMenuChildDropdown(true);
    } else {
      setOpenSubMenuDropdown(false);
    }
  }, [open]);

  useEffect(() => {
    setCollaspeMenu(CollaspeMenu || mainModule);
    setCollaspeMenuToggle(CollaspeMenu || mainModule);
    setNonCollapseModule(nonCollaspeModuleSlug);

    if (open) {
      setCollaspedMenu(CollaspedMenu || subModule);
      setCollaspeulMenu(selectedModule);
      setOpenSubMenuDropdown(true);
      setopenSubMenuChildDropdown(true);
    } else {
      setSelectedModuleName(subModule);
      setCollaspeulMenu(selectedModule);
    }
  }, [selectedId, selectedModule, nonCollaspeModuleSlug]);

  useEffect(() => {
    dispatch(dashBoardMenuRequest());
  }, [localStorage.getItem("i18nextLng")]);

  const isPartnerImages = useRef(true);
  useEffect(() => {
    if (isPartnerImages.current) {
      isPartnerImages.current = false;
      return;
    }
    if (nextProps.dashBoardMenuList) {
      setdashboardData([...nextProps.dashBoardMenuList]);
    }
  }, [nextProps.dashBoardMenuList]);

  const dropShow = document.getElementsByClassName("sub-drop");
  window.onclick = () => {
    for (let i = 0; i < dropShow.length; i += 1) {
      const openDropdown = dropShow[i];
      if (openDropdown.classList.contains("show")) {
        setOpenSubMenuDropdown(false);
      }
    }
  };
  const content = document.getElementById("content");
  window.addEventListener("scroll", () => {
    if (content) {
      if (window.scrollY >= 110) {
        content.style.height = "70vh";
      } else {
        content.style.height = "88vh";
      }
    }
  });

  const onHandleDashboardnavigation = () => {
    setCollaspeMenu("");
    setCollaspedMenu("");
    setSelectedModuleName("");
    setIsSameMenu("");
    navigate("/early-warnings/alert_dashboard?slug=alertDashboard");
  };

  return (
    <>
      <div
        id="content"
        className={open ? "das-main" : " das-main"} // das-main-1
        style={{
          display:
            window.location.pathname === "/create-online-bulletin"
              ? "none"
              : "",
        }}
      >
        <Button
          className={
            open
              ? "button-expand button-expand-fixed-lg home-exp-clr"
              : "button-expand button-expand-fixed-md home-exp-clr"
          }
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          {open ? (
            <>
              <FaAngleLeft className="right-icon right-icon-md float-right" />
              <span className="dashboard-exp-text">
                {t("EARLY_FINANCE.COLLAPSE")}
              </span>
            </>
          ) : (
            <>
              <FaAngleRight className="right-icon float-left" />
              <span className="dashboard-exp-text">
                {t("EARLY_FINANCE.EXPAND")}
              </span>
            </>
          )}
        </Button>
        <div className="dashboard-sidebar">
          <div>
            <div className={open ? "navitems" : "navitems-d"}>
              <NavLink
                onClick={() => navigate("/home")}
                className="home-navlink"
                data-tip
                data-for={toolTipid}
                onMouseEnter={() => setTooltipId("Aware Home")}
                onMouseLeave={() => setTooltipId("")}
              >
                <div className="home-clr">
                  <MdHome className="green-clr" />
                  <p className="dashboard-text">{t("DASHBOARD.AWARE_HOME")}</p>
                </div>
              </NavLink>
              <NavLink
                onClick={() => onHandleDashboardnavigation()}
                className={
                  nonCollaspeModuleSlug === "alertDashboard"
                    ? "nav-link-3 Nav-hov "
                    : "das-link"
                }
                data-tip
                data-for={toolTipid}
                onMouseEnter={() => setTooltipId("Aware Dashboard")}
                onMouseLeave={() => setTooltipId("")}
              >
                <div className="home-clr dashboard-paading">
                  <MdDashboard className="green-clr" />
                  <p className="dashboard-text">
                    {t("DASHBOARD.ALERT_DASHBOARD")}
                  </p>
                </div>
              </NavLink>
              {dashBoardData?.map((eachdata) => {
                return (
                  <>
                    {eachdata && eachdata?.menu_list?.length !== 0 ? (
                      <NavLink
                        className={
                          CollaspeMenuToggle?.includes(eachdata.slug)
                            ? eachdata?.slug !== "early_warning"
                              ? "Nav-hov nav-link-1 nav-link-2"
                              : "Nav-hov nav-link-1 nav-link-2"
                            : "nav-link-1 nav-link-2"
                        }
                        onClick={() => onCollaspeToggle(eachdata.id, eachdata)}
                        onKeyUp={() => {}}
                        data-tip
                        data-for={toolTipid}
                        onMouseEnter={() => setTooltipId(eachdata.title)}
                        onMouseLeave={() => setTooltipId("")}
                        aria-controls="example-collapse-text"
                      >
                        <div className="home-clr">
                          <img
                            src={eachdata.icon}
                            alt={eachdata.title}
                            className="icon1"
                          />
                          <p className="dashboard-text">{eachdata.title} </p>
                          <div className="down-icon-container">
                            {CollaspeMenuToggle?.includes(eachdata.slug) ? (
                              <BsChevronUp className="down-icon" />
                            ) : (
                              <BsChevronDown className="down-icon" />
                            )}
                          </div>
                        </div>
                      </NavLink>
                    ) : eachdata?.slug !== t("DASHBOARD.WETIN_APP_SLUG") ? (
                      <NavLink
                        // className={
                        //   eachdata.slug === "early_finance" ||
                        //   eachdata.slug === "early_action"
                        //     ? `nav-link-1 nav-link-2 nav-div  ${
                        //         selecetedUncollaspedList === eachdata.slug
                        //           ? "Nav-hov"
                        //           : ""
                        //       }`
                        //     : `nav-link-3 nav-div ${
                        //         selecetedUncollaspedList === eachdata.slug
                        //           ? "Nav-hov"
                        //           : ""
                        //       }`
                        // }
                        className={
                          eachdata?.slug === nonCollaspeModule
                            ? "nav-link-3 Nav-hov"
                            : "nav-link-3"
                        }
                        onClick={() => handleNavigate(eachdata.id, eachdata)}
                        onKeyUp={() => {}}
                        data-tip
                        data-for={toolTipid}
                        onMouseEnter={() => setTooltipId(eachdata.title)}
                        onMouseLeave={() => setTooltipId("")}
                        aria-controls="example-collapse-text"
                      >
                        <div className="home-clr">
                          <img
                            src={eachdata.icon}
                            alt={eachdata.title}
                            className="icon1"
                          />
                          <p className="dashboard-text">{eachdata.title} </p>
                        </div>
                      </NavLink>
                    ) : (
                      ""
                    )}
                    {/* sub dropdown */}
                    <Collapse
                      isOpen={CollaspeMenu?.includes(eachdata.slug)}
                      className="main-dropdown"
                      onClick={() => setopenSubMenuChildDropdown(true)}
                    >
                      {eachdata?.menu_list.map((eachValue) => {
                        return (
                          <>
                            <NavLink
                              onClick={() => {
                                onCollaspeModules(
                                  eachValue.id,
                                  eachValue,
                                  eachdata,
                                );
                              }}
                              onKeyUp={() => {}}
                              aria-controls="example-collapse-text"
                              className={
                                eachValue.slug === CollaspedMenu ||
                                eachValue.slug === isSameMenu
                                  ? "Nav-hov nav-link-1"
                                  : "nav-link-1"
                              }
                              data-tip
                              data-for={toolTipid}
                              onMouseEnter={() => setTooltipId(eachValue.title)}
                              onMouseLeave={() => setTooltipId("")}
                            >
                              {eachValue &&
                              eachValue?.sub_menu_list?.length !== 0 ? (
                                <div>
                                  <div className="home-clr">
                                    <img
                                      src={
                                        open
                                          ? eachValue.slug === CollaspedMenu ||
                                            eachValue.slug === isSameMenu
                                            ? eachValue.icon
                                            : eachValue.icon_selected
                                          : eachValue.slug ===
                                            selectedModuleName
                                          ? eachValue.icon
                                          : eachValue.icon_selected
                                      }
                                      alt={eachValue.title}
                                      className="icon1"
                                    />
                                    <p className="dashboard-text">
                                      {eachValue.title}
                                    </p>

                                    <div className="down-icon-container">
                                      {CollaspedMenu?.includes(
                                        eachValue?.slug,
                                      ) && openSubMenuDropdown ? (
                                        <BsChevronUp className="down-icon" />
                                      ) : (
                                        <BsChevronDown className="down-icon" />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="home-clr">
                                  <img
                                    src={
                                      open
                                        ? eachValue.slug === CollaspedMenu ||
                                          eachValue.slug === isSameMenu
                                          ? eachValue.icon
                                          : eachValue.icon_selected
                                        : eachValue.slug === selectedModuleName
                                        ? eachValue.icon
                                        : eachValue.icon_selected
                                    }
                                    alt={eachValue.title}
                                    className="icon1"
                                  />
                                  <p className="dashboard-text">
                                    {eachValue.title}
                                  </p>
                                </div>
                              )}
                            </NavLink>
                            {/* SUB CHILD DROP-DOWN */}
                            {eachValue?.sub_menu_list?.length ? (
                              <Collapse
                                isOpen={
                                  CollaspedMenu?.includes(eachValue.slug) &&
                                  openSubMenuChildDropdown
                                }
                                className={
                                  open
                                    ? "sub-dropdown"
                                    : "sub-dropdown sub-drop"
                                }
                              >
                                {eachValue?.sub_menu_list.map((eachMenu) => {
                                  return (
                                    <NavLink
                                      key={(eachMenu.id, eachMenu)}
                                      onClick={() => {
                                        CollaspeulMenu !== eachMenu.slug
                                          ? onCollaspeSubModule(
                                              eachMenu,
                                              eachValue,
                                              eachdata,
                                            )
                                          : "";
                                      }}
                                      onKeyUp={() => {}}
                                      className={
                                        eachMenu.slug === CollaspeulMenu
                                          ? open
                                            ? " sub-collapse-dropdown-sidebar gaps"
                                            : "gaps"
                                          : "gap"
                                      }
                                    >
                                      <p>{eachMenu.title} </p>
                                    </NavLink>
                                  );
                                })}
                              </Collapse>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </Collapse>
                  </>
                );
              })}
              <NavLink
                onClick={() => toggleModal()}
                className="nav-link-3"
                data-tip
                data-for={toolTipid}
                onMouseEnter={() => setTooltipId(t("DASHBOARD.DISCLAMIER"))}
                onMouseLeave={() => setTooltipId("")}
              >
                <div className="home-clr disclaimer-clr">
                  <img src={DisclaimerImg} alt="disclaimer" className="icon1" />
                  <p className="dashboard-text">{t("DASHBOARD.DISCLAMIER")}</p>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      {!open ? (
        <ReactTooltip
          id={toolTipid}
          key={toolTipid}
          effect="float"
          place="right"
        >
          {toolTipid}
        </ReactTooltip>
      ) : (
        ""
      )}
    </>
  );
}

export default Sidebar;
