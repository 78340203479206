import { combineReducers } from "redux";

// Authentication
import Login from "./auth/reducer";
import Loader from "./loader/reducer";
import Climber from "./climber/reducer";
import Core from "./core/reducer";
import Aware from "./aware/reducer";
import Dashboard from "./dashBoard/reducer";
import OtherTools from "./otherTools/reducer";
import Register from "./register/reducer";
import User from "./user/reducer";
import Drought from "./drought/reducer";
import MapPreference from "./mapPreferences/reducer";
import Weather from "./weather/reducer";
import Geoglows from "./geoglows/reducer";
import Health from "./health/reducer";
import UserGuide from "./userGuide/userGuideReducer";
import ManageUser from "./manageUserGuide/reducer";
import Nutrition from "./nutrition/reducer";
import Market from "./market/reducer";
import Bulletin from "./bulletin/bulletinReducers";
import PopulationDisplacement from "./populationDisplacement/reducer";
import Boundry from "./boundary/reducer";
import UploadPastData from "./uploadPastData/reducer";
import staticResource from "./staticResource/staticResourceReducer";
import odk from "./odk/odkReducer";
import ManageUserReducer from "./manageUser/reducer";
import Permissions from "./permissions/reducer";
import CropYield from "./cropYield/reducer";
import EaProjects from "./earlyAction/reducer";
import GuideLines from "./guideLines/reducer";
import EAPreparednessPhaseReducer from "./preparednessPhase/reducer";
import EAReadynessReducer from "./readynessPhase/reducer";
import EFReportReducer from "./earlyFinanceReport/reducer";
import AlertDashboard from "./alertDashboard/reducer";
import RapidResponse from "./rapidResponse/reducer";

const rootReducer = combineReducers({
  Login,
  Loader,
  Climber,
  Core,
  Aware,
  Dashboard,
  OtherTools,
  Register,
  User,
  Drought,
  MapPreference,
  Weather,
  Geoglows,
  Health,
  UserGuide,
  ManageUser,
  Nutrition,
  Market,
  Bulletin,
  PopulationDisplacement,
  Boundry,
  UploadPastData,
  staticResource,
  odk,
  ManageUserReducer,
  Permissions,
  CropYield,
  EaProjects,
  GuideLines,
  EAPreparednessPhaseReducer,
  EAReadynessReducer,
  EFReportReducer,
  AlertDashboard,
  RapidResponse,
});

export default rootReducer;
