import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import { CLIENT_ID } from "../../constants/index";
import { getLoginUserDetailsRequest } from "../../store/user/action";

import BackButton from "../../assest/img/back-button.svg";
import ManageUser from "../../assest/img/manageuser.png";
import ManageUserGuide from "../../assest/img/ManageUserGuide.png";
import BackOffcieBulletin from "../../assest/img/Manage_OB.png";

import { showError } from "../../components/Common/Notification";

function Index() {
  const [userDetails, setUserDetails] = useState(null);
  const [path, setPath] = useState("");
  const [signedInUserDetails, setSignedInUserDetails] = useState("");
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();

  const nextProps = useSelector((state) => ({
    loginUserDetails: state.User.loginUserDetails,
  }));

  useEffect(() => {
    dispatch(getLoginUserDetailsRequest({}));
  }, []);

  useEffect(() => {
    if (nextProps.loginUserDetails) {
      setUserDetails({
        ...nextProps?.loginUserDetails?.user_profile,
        id: nextProps?.loginUserDetails?.user?.id,
      });
    }
  }, [nextProps.loginUserDetails]);

  const handleCallbackResponse = (response) => {
    const userObj = jwt_decode(response.credential);
    setSignedInUserDetails(userObj);
  };

  useEffect(() => {
    window?.google?.accounts?.id.initialize({
      client_id: CLIENT_ID,
      useOneTap: false,
      auto_select: true,
      hint: userDetails?.google_email || "",
      prompt: "",
      callback: handleCallbackResponse,
    });
  }, [userDetails]);

  // global google
  const handleClientLoad = () => {
    window.google.accounts.id.prompt((notification) => {
      if (notification?.isNotDisplayed() || notification?.isSkippedMoment()) {
        document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        window.google.accounts.id.prompt();
        if (notification.getNotDisplayedReason()) {
          showError(notification.getNotDisplayedReason());
        }
      }
    });
  };

  useEffect(() => {
    if (!_.isEmpty(signedInUserDetails?.email) && !_.isEmpty(userDetails)) {
      if (!_.isEmpty(userDetails?.google_email)) {
        if (userDetails?.google_email === signedInUserDetails?.email) {
          // localStorage.setItem('', JSON.stringify(tokenClient))
          localStorage.setItem(
            "googleAccountDetails",
            JSON.stringify(signedInUserDetails),
          );
          history(path);
        } else {
          const user_google_email = userDetails?.google_email;
          showError(t("ODK.EMAIL_VERIFICATION", { user_google_email }));
        }
      } else {
        localStorage.setItem(
          "googleAccountDetails",
          JSON.stringify(signedInUserDetails),
        );
        history(path);
      }
    }
  }, [signedInUserDetails, userDetails]);

  const handleCardClick = (redirectUrl) => {
    setPath(redirectUrl);
    handleClientLoad();
  };

  return (
    <div className="back-office-main-page">
      <div className="pb-1 back-office-main-header back-office-center">
        <img
          src={BackButton}
          alt="back-button"
          onClick={() => history("/settings")}
          onKeyUp={() => {}}
          className="pointer"
        />
        <h2 className="discription-text pl-3 heading-text-user-guide">
          {t("ODK.SURVEY_SETTING")}
        </h2>
      </div>
      <div>
        <Row className="setting-container">
          <Col
            sm={3}
            onClick={() => {
              handleCardClick("/surveyConfiguration");
            }}
          >
            <div className="setting-card h-auto">
              <img
                src={ManageUserGuide}
                alt={t("SETTINGS.SETTINGS")}
                className="setting-card-icon"
              />
              <p className="setting-card-text">{t("ODK.SURVEY_CONFIG")}</p>
              <small>{t("ODK.SURVEY_CONFIG_DES")}</small>
            </div>
          </Col>
          <Col sm={3} onClick={() => handleCardClick("/manageSurveyors")}>
            <div className="setting-card h-auto">
              <img
                src={ManageUser}
                alt={t("SETTINGS.SETTINGS")}
                className="setting-card-icon"
              />
              <p className="setting-card-text">{t("ODK.MANAGE_SURVEYORS")}</p>
              <small>{t("ODK.MANAGE_SURVEYOR_DES")}</small>
            </div>
          </Col>
          <Col sm={3}>
            <div className="setting-card h-auto">
              <img
                src={BackOffcieBulletin}
                alt={t("SETTINGS.SETTINGS")}
                className="setting-card-icon"
              />
              <p className="setting-card-text">{t("ODK.FIELD_DATA")}</p>
              <small>{t("ODK.FIELD_DATA_DES")}</small>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Index;
