import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "video-react/dist/video-react.css";
import { GrFormClose } from "react-icons/gr";
import NextArrow from "../../assest/img/nextArrowUpload.png";
import PreviosArrow from "../../assest/img/previousArrowUpload.png";

function CarouselComponent({
  toggle,
  modal,
  carouselData,
  selectedCategory,
  selectedVedio,
  selectedImage,
  setSelectedImage,
  setSelectedImagesIndex,
  selectedImagesIndex,
}) {
  // const { t } = useTranslation();

  const handleThumbnailClick = (index) => {
    setSelectedImage(index);
  };

  useEffect(() => {
    // Add a CSS class to the body element when the sideEditor is open
    if (modal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [modal]);

  const closeBtn = (
    <GrFormClose
      size={24}
      onClick={() => toggle()}
      className="pointer media-modal-header-close-icon"
    />
  );

  return (
    <div>
      <Modal
        isOpen={modal}
        size={selectedCategory?.slug === "image" ? "lg" : "lg"}
      >
        <ModalHeader
          toggle={toggle}
          className="align-items-center media-modal-header-background"
          close={closeBtn}
        >
          <h2 className="intervention-h2 m-0">
            {selectedCategory?.slug === "image"
              ? selectedImage.url?.split("/")?.pop()?.length > 45
                ? `${selectedImage.url
                    ?.split("/")
                    ?.pop()
                    .slice(0, 15)} ... ${selectedImage.url
                    ?.split("/")
                    ?.pop()
                    .slice(-10)} `
                : selectedImage.url?.split("/")?.pop()
              : selectedVedio.url?.split("/")?.pop()?.length > 45
              ? `${selectedVedio.url
                  ?.split("/")
                  ?.pop()
                  .slice(0, 15)} ... ${selectedVedio.url
                  ?.split("/")
                  ?.pop()
                  .slice(-10)} `
              : selectedVedio.url?.split("/")?.pop()}
          </h2>
        </ModalHeader>
        <ModalBody className="p-3">
          {selectedCategory?.slug === "image" ? (
            <div className="carousel-container">
              <div className="carousel-images-upload">
                <img
                  key={selectedImage.url}
                  src={selectedImage.url}
                  alt={`${selectedImage.url}`}
                  className="activeIndex carousel-image-selected"
                />
              </div>

              <div className="carousel-thumbnails-upload">
                {carouselData?.length > 4 ? (
                  <img
                    src={PreviosArrow}
                    alt="NextArrow"
                    onClick={() => {
                      selectedImagesIndex?.start === 0
                        ? {}
                        : setSelectedImagesIndex({
                            start: selectedImagesIndex.start - 1,
                            end: selectedImagesIndex?.end - 1,
                          });
                    }}
                    onKeyUp={() => {}}
                    disabled={selectedImagesIndex?.start === 0}
                    className={
                      selectedImagesIndex?.start === 0
                        ? "upload-next-icon-size mr-2 du-btn-disable "
                        : "upload-next-icon-size  mr-2 cursor-ponter "
                    }
                  />
                ) : (
                  ""
                )}

                {carouselData
                  .slice(selectedImagesIndex.start, selectedImagesIndex.end)
                  .map((item) => {
                    return (
                      <img
                        key={item.url}
                        src={item.url}
                        alt={`Thumbnail-upload ${item.url}`}
                        className={`thumbnail-upload ${
                          selectedImage?.url === item?.url ? "activeImage" : ""
                        }`}
                        onClick={() => handleThumbnailClick(item)}
                        onKeyUp={() => {}}
                      />
                    );
                  })}

                {carouselData?.length > 4 ? (
                  <img
                    src={NextArrow}
                    alt="NextArrow"
                    onClick={() => {
                      selectedImagesIndex?.end >= carouselData?.length - 1
                        ? {}
                        : setSelectedImagesIndex({
                            start: selectedImagesIndex.start + 1,
                            end: selectedImagesIndex?.end + 1,
                          });
                    }}
                    disabled={
                      selectedImagesIndex?.end >= carouselData?.length - 1
                    }
                    onKeyUp={() => {}}
                    className={
                      selectedImagesIndex?.end >= carouselData?.length - 1
                        ? "upload-next-icon-size ml-2 du-btn-disable"
                        : "upload-next-icon-size ml-2 cursor-ponter "
                    }
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <div>
              <video
                src={selectedVedio?.url}
                width="750"
                height="500"
                controls
                autoPlay
                className="document-upload-vedio"
              >
                <track kind="captions" src="" label="English" />
              </video>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CarouselComponent;
