import { useTranslation } from "react-i18next";
import React, { lazy, Suspense } from "react";

import Loader from "../../components/Common/Loader";

// lazy loading components
const ClimberFooter = lazy(() =>
  import("../../components/Common/ClimberFooter"),
);

function DashBoard() {
  const { t } = useTranslation();
  window.scrollTo(0, 0);

  return (
    <div className="dashboard-main-page common-dashboard-ftr">
      <Suspense fallback={<Loader isSuspended />}>
        <div className="d-flex">
          <div className="dashboard">{t("DASHBOARD.INPROGRESS")}</div>
        </div>
        <ClimberFooter />
      </Suspense>
    </div>
  );
}
export default DashBoard;
