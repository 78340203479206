const EARLY_WARNING_NUTRITION_CONST = {
  // GET NUTRITION DATA
  GET_NUTRITION_DATA_REQUEST: "GET_NUTRITION_DATA_REQUEST",
  GET_NUTRITION_DATA_SUCCESS: "GET_NUTRITION_DATA_SUCCESS",
  GET_NUTRITION_DATA_FAILED: "GET_NUTRITION_DATA_FAILED",

  // TIME SERIES DATA
  GET_NUTRITION_TIMESERIES_REQUEST: "GET_NUTRITION_TIMESERIES_REQUEST",
  GET_NUTRITION_TIMESERIES_SUCCESS: "GET_NUTRITION_TIMESERIES_SUCCESS",
  GET_NUTRITION_TIMESERIES_ERROR: "GET_NUTRITION_TIMESERIES_ERROR",
};
export default EARLY_WARNING_NUTRITION_CONST;
