import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// import Select from "react-select";
import { Formik, Form } from "formik";
import { Row, Col } from "reactstrap";
import { RenderReactSelectInputField } from "../../components/Common/RenderFormikFields";
import CommonTableViewButton from "../../components/Common/CommonTableViewButton";
import { renderAPIParameterOptions } from "../../components/Common/Utils";

import {
  coreRequest,
  corestateRequest,
  coredistrictRequest,
  alertIndicatorRequest,
} from "../../store/actions";
import { renderReactOptionsArraystate } from "../../helpers";
import { State, District } from "../../constants";
import CheckBoxDropDown from "./CheckBoxDropDown";

function DashboardFilter({
  defaultCountry,
  setInitialData,
  initialData,
  setRegionListOptions,
  regionListOptions,
  setDistrictsListOptions,
  districtsListOptions,
  setRiskListOptions,
  riskListOptions,
  setIndicatorListOptions,
  indicatorListOptions,
  setDefaultOptions,
  defaultOptions,
  setSelectedParameter,
  layerRef,
  onHandleZoom,
  setIsShowTableView,
  isShowTableView,
  IRIAvailableLeadTimeList,
  IRIForecastTime,
  setIRIForecastTime,
  selectedParameter,
  setIndicatorsData,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nextProps = useSelector((state) => ({
    countriesList: state.Core,
    statesList: state.Core.corestateList,
    districtList: state.Core.coredistrictList,
    indicatorList: state.AlertDashboard.alertIndicatorList,
  }));

  const selectedRegions = [];

  const selectedDistricts = [];

  useEffect(() => {
    dispatch(
      coreRequest({
        location_type: "country",
      }),
    );
    onHandleZoom(
      [defaultCountry.latitude, defaultCountry.longitude],
      defaultCountry.zoom_level,
    );
    setInitialData((preValues) => {
      return {
        ...preValues,
        country: defaultCountry,
      };
    });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(initialData.country)) {
      localStorage.setItem(
        "selectedCountry",
        JSON.stringify(initialData.country),
      );
      dispatch(
        corestateRequest({
          data: {
            parent_id: initialData?.country?.value,
            location_type: State,
          },
          isCompareLocation: false,
        }),
      );
      dispatch(alertIndicatorRequest({}));
      setRegionListOptions([]);
      setDistrictsListOptions([]);
    }
  }, [initialData.country]);

  useEffect(() => {
    if (
      !_.isEmpty(initialData?.region) &&
      initialData?.region?.label !== "All Regions"
    ) {
      dispatch(
        coredistrictRequest({
          requestObj: {
            parent_id: initialData?.region?.value,
            location_type: District,
          },
          isCompareLocation: false,
        }),
      );
    }
    setDistrictsListOptions([]);
  }, [initialData.region]);

  useEffect(() => {
    if (!_.isEmpty(nextProps?.statesList?.data)) {
      renderReactOptionsArraystate(
        nextProps.statesList?.data,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
      )?.map((eachValue) => selectedRegions.push(eachValue));
      if (!nextProps.statesList?.isCompare) {
        setRegionListOptions(selectedRegions);
        setInitialData((preValues) => {
          return {
            ...preValues,
            region: selectedRegions[0],
            // district: selectedRegions[0],
          };
        });
      }
    }
  }, [nextProps.statesList]);
  useEffect(() => {
    if (!_.isEmpty(nextProps.districtList)) {
      const result = nextProps.districtList?.data;
      if (result) {
        renderReactOptionsArraystate(
          result,
          "name",
          "id",
          "latitude",
          "longitude",
          "zoom_level",
        )?.map((eachValue) => selectedDistricts.push(eachValue));
      }
      setDistrictsListOptions(selectedDistricts);

      onHandleZoom(
        [selectedDistricts[1].latitude, selectedDistricts[1].longitude],
        selectedDistricts[1].zoom_level,
      );
      setInitialData((preValues) => {
        return {
          ...preValues,
          district: selectedDistricts[0],
        };
      });
    }
  }, [nextProps.districtList]);

  useEffect(() => {
    if (!_.isEmpty(nextProps?.indicatorList)) {
      const arr = nextProps?.indicatorList?.map((item) => {
        return {
          value: item?.category,
          label: item?.category,
        };
      });
      setRiskListOptions(arr);
      setInitialData((prev) => {
        return {
          ...prev,
          risk: arr[1],
        };
      });
    }
  }, [nextProps.indicatorList]);

  useEffect(() => {
    if (!_.isEmpty(initialData.risk) && nextProps?.indicatorList?.length) {
      const indicatorsArray = nextProps?.indicatorList?.find(
        (obj) => obj?.category === initialData?.risk?.label,
      );
      if (!_.isEmpty(indicatorsArray)) {
        let count = 0;
        const arr = indicatorsArray?.indices?.map((obj) => {
          count += 1;
          return {
            value: obj?.id,
            label: obj?.name,
            slug: obj?.slug,
            checked: false,
            paramIndex: count - 1,
            boundary_id: obj?.id,
            ...obj,
          };
        });
        setIndicatorListOptions(arr);
        setDefaultOptions(arr);

        setSelectedParameter(arr[0]);

        layerRef.current = [];

        if (arr?.length) {
          arr?.map(() => {
            const ref2 = React.createRef();
            layerRef.current.push(ref2);
            return null;
          });
        }
      }
    }
  }, [initialData.risk]);

  const clearInitialValues = () => {
    setInitialData((prev) => {
      return {
        ...prev,
        region: regionListOptions[1],
        district: districtsListOptions[1],
        indicators: [...indicatorListOptions],
        risk: riskListOptions[1],
      };
    });
    // setDistrictsListOptions([]);
  };

  const forecastLeadTimeHandler = (e) => {
    IRIForecastTime.lead_time = e;
    setIRIForecastTime({ ...IRIForecastTime });
  };

  const handleViewTable = () => {
    setIsShowTableView(!isShowTableView);
  };

  return (
    <div className="health-map">
      <Row className="mb-2">
        <Col lg={10} className="health-map-drop p-0">
          <Formik
            initialValues={initialData}
            enableReinitialize
            // validationSchema={validationSchema}
            // onSubmit={(values) => {
            //   console.log(values);
            // }}
          >
            {({
              // handleSubmit: onSubmit,
              // setFieldValue,
              values,
              // errors,
              // handleBlur,
              // handleChange,
              // resetForm,
            }) => (
              <Form
                //   onSubmit={onSubmit}
                className="filter-control-dp"
                noValidate
              >
                <Row>
                  <Col md={2} className="filter-control-dp-md">
                    <RenderReactSelectInputField
                      formInput
                      value={values?.country}
                      options={
                        nextProps?.countriesList?.coreList &&
                        renderReactOptionsArraystate(
                          nextProps?.countriesList?.coreList,
                          "name",
                          "id",
                          "latitude",
                          "longitude",
                          "zoom_level",
                          "code",
                        )
                      }
                      onChange={(e) => {
                        setSelectedParameter("");
                        onHandleZoom([e.latitude, e.longitude], e.zoom_level);
                        setInitialData((prevState) => ({
                          ...prevState,
                          country: e,
                          // commodity: {},
                          region: {},
                          district: {},
                        }));
                      }}
                      name="Country"
                      label={t("HEALTH.COUNTRY")}
                      placeholder={t("DASHBOARD.ALL_COUNTRIES")}
                      className="map-dash-select dash-select common-select-r"
                    />
                  </Col>

                  <Col md={2} className="filter-control-dp-md">
                    <RenderReactSelectInputField
                      formInput
                      value={values?.region}
                      options={regionListOptions}
                      onChange={(e) => {
                        onHandleZoom([e.latitude, e.longitude], e.zoom_level);
                        setInitialData((prevState) => ({
                          ...prevState,
                          region: e,
                          district: selectedDistricts[1],
                        }));
                      }}
                      name="region"
                      label={t("HEALTH.REGION")}
                      placeholder={t("EARLY_ACTIONS.STATE")}
                      className="map-dash-select dash-select common-select-r"
                    />
                  </Col>

                  <Col md={2} className="filter-control-dp-md">
                    <RenderReactSelectInputField
                      formInput
                      value={values?.district}
                      options={districtsListOptions}
                      onChange={(e) => {
                        // setSelectedParameter("");
                        onHandleZoom([e.latitude, e.longitude], e.zoom_level);
                        setInitialData((prevState) => ({
                          ...prevState,
                          district: e,
                        }));
                      }}
                      name="district"
                      label={t("DASHBOARD.DISTRICT")}
                      placeholder={t("DASHBOARD.DISTRICT")}
                      className="map-dash-select dash-select common-select-r"
                    />
                  </Col>

                  <Col md={2} className="filter-control-dp-md">
                    <RenderReactSelectInputField
                      formInput
                      value={values?.risk}
                      options={riskListOptions}
                      onChange={(e) => {
                        setIndicatorsData([]);
                        setSelectedParameter("");
                        setInitialData((prevState) => ({
                          ...prevState,
                          risk: e,
                          // indicators: [],
                        }));
                      }}
                      name="risk"
                      label={t("DASHBOARD.RISK_INDICATORS")}
                      placeholder={t("DASHBOARD.RISK_INDICATORS")}
                      className="map-dash-select dash-select common-select-r"
                    />
                  </Col>
                  <Col md={2} className="filter-control-dp-md d-none">
                    <CheckBoxDropDown
                      options={indicatorListOptions}
                      defaultValue={initialData.indicators}
                      key1={t("DASHBOARD.INDICATOR")}
                      name={t("DASHBOARD.INDICATOR")}
                      setInitialData={setInitialData}
                      defaultOptions={defaultOptions}
                    />
                  </Col>

                  {selectedParameter?.slug === "iri" ? (
                    <Col md={2} className="filter-control-dp-md">
                      <RenderReactSelectInputField
                        formInput
                        value={IRIForecastTime?.lead_time}
                        options={renderAPIParameterOptions(
                          IRIAvailableLeadTimeList,
                          "forecast_lead_time_list",
                        )}
                        onChange={forecastLeadTimeHandler}
                        name="leadtime"
                        label="lead time"
                        placeholder={t("DASHBOARD.RISK_INDICATORS")}
                        className="map-dash-select dash-select common-select-r"
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  <div className="col-md-2 d-flex align-items-center">
                    <button
                      className="clr-btn"
                      onClick={clearInitialValues}
                      type="button"
                    >
                      {t("DASHBOARD.CLEAR_ALL")}
                    </button>
                  </div>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
        <Col sm={2}>
          <CommonTableViewButton
            isShowTableView={isShowTableView}
            handleClick={handleViewTable}
          />
        </Col>
        {/* <Col lg={2} className="p-0">
              <CommonFullScreenButton
                openScreen={openScreen}
                handleClick={handleViewFullScreen}
              /> */}
        {/* {!hideTableView ? ( */}
        {/* <CommonTableviewButton
                isShowTableView={isShowTableView}
                handleClick={handleViewTable}
              /> */}
        {/* ) : (
                ""
              )} */}
        {/* </Col> */}
      </Row>
    </div>
  );
}

export default DashboardFilter;
