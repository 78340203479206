import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  ModalFooter,
  Badge,
} from "reactstrap";
import { HiOutlineXMark } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import moment from "moment";
import readXlsxFile from "read-excel-file";
// import FileImg from "../../assest/img/file-screen.png";

function ViewDetailsModal({ isOpen, toggle, selectedData, getColorLable }) {
  const { t } = useTranslation();

  const [pres, setPres] = useState([]);

  /* Fetch and update the state once */
  useEffect(() => {
    if (selectedData?.file_name) {
      setPres([]);
      fetch(selectedData?.file_name)
        .then((response) => response.blob())
        .then((blob) => readXlsxFile(blob))
        .then((rows) => {
          setPres(rows);
        });
    }
  }, [selectedData?.file_name]);

  return (
    <Modal
      isOpen={isOpen}
      onHide={toggle}
      animation={false}
      className="modal-dialog modal-lg Home-page-md appr-modal"
    >
      <ModalHeader closeButton className="border-content">
        {t("APPROVE_DATA.DETAILS")}
        <Button onClick={toggle}>
          <HiOutlineXMark />
        </Button>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <h3 className="approve-detail-h3">{t("SETTINGS.NAME")}</h3>
            <p className="approve-detail-p">{selectedData?.user_name}</p>
          </Col>
          <Col>
            <h3 className="approve-detail-h3">{t("APPROVE_DATA.COUNTRY")}</h3>
            <p className="approve-detail-p">{selectedData?.country_name}</p>
          </Col>
          <Col>
            <h3 className="approve-detail-h3">{t("APPROVE_DATA.MODULE")}</h3>
            <p className="approve-detail-p">{selectedData?.menu_slug}</p>
          </Col>
          <Col>
            <h3 className="approve-detail-h3">
              {t("APPROVE_DATA.SUB_MODULE")}
            </h3>
            <p className="approve-detail-p">{selectedData?.sub_menu_slug}</p>
          </Col>

          <Col lg={2}>
            <h3 className="approve-detail-h3">{t("SETTINGS.STATUS")}</h3>
            <p className="approve-detail-p">
              <Badge
                color={getColorLable(selectedData?.status)?.color}
                style={{ color: "white" }}
              >
                {getColorLable(selectedData?.status)?.label}
              </Badge>
            </p>
          </Col>
        </Row>

        <Row className="mt-4">
          {selectedData?.status === "reject" ? (
            <Col lg={9}>
              <h3 className="approve-detail-h3">
                {t("SETTINGS.REJECT_RESPONSE")}
              </h3>
              <p className="approve-detail-p">
                {selectedData?.reject_response || "N/A"}
              </p>
            </Col>
          ) : (
            " "
          )}
        </Row>

        <Row className="mt-4">
          <Col>
            <div className="approve-detail-file">
              <Row>
                <Col lg={10} className="p-0">
                  <h3>
                    {`${selectedData?.menu_slug}_${
                      selectedData?.sub_menu_slug
                    }_${moment(new Date(selectedData?.start_date))
                      .format("MMM/YYYY")
                      .toString()}_to_${moment(new Date(selectedData?.end_date))
                      .format("MMM/YYYY")
                      .toString()}`}
                  </h3>
                </Col>
                <Col lg={2} className="p-0">
                  {/* <input type="button" value="Download"  /> */}
                  <Badge
                    color="info"
                    style={{
                      color: "white",
                      cursor: "pointer",
                      position: "relative",
                      float: "right",
                    }}
                    href={selectedData?.file_name}
                  >
                    {t("APPROVE_DATA.FILE")}
                  </Badge>
                </Col>
              </Row>

              <div className="appr-file">
                <table className="w-100">
                  <thead>
                    {pres &&
                      pres[0]?.map((key) => (
                        <th
                          style={{
                            textAlign: "center",
                            border: "2px solid green",
                          }}
                        >
                          {key}
                        </th>
                      ))}
                  </thead>

                  <tbody>
                    {
                      /* generate row for each president */
                      pres?.map((pre, index) => {
                        if (index > 0) {
                          return (
                            <tr>
                              {pre.map((val) => (
                                <td
                                  style={{
                                    textAlign: "center",
                                    border: "2px solid green",
                                  }}
                                >
                                  {val}
                                </td>
                              ))}
                            </tr>
                          );
                        }
                        return false;
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        {/* <p className="approve-detail-h3 d-inline-block ml-4">
          {t("APPROVE_DATA.STATUS")}
        </p> */}
        {/* <ReactSelectFiled
          options={ApproveArray}
          className="climber-country-select common-select"
        /> */}
        <Button type="button" onClick={toggle} className="cancl-btn">
          {t("APPROVE_DATA.CANCEL")}
        </Button>
        {/* <Button type="button" className="approve-btn">
          {t("APPROVE_DATA.UPDATE")}
        </Button> */}
      </ModalFooter>
    </Modal>
  );
}

export default ViewDetailsModal;
