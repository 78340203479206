import UPLOAD_PAST_DATA_CONSTS from "./actionTypes";

const UploadPastData = (state, action) => {
  if (typeof state === "undefined") {
    state = {
      error: "",
      uploadPastDataError: null,
      loading: false,
    };
  }
  switch (action.type) {
    case UPLOAD_PAST_DATA_CONSTS.UPLOAD_PAST_DATA_REQUEST:
      state = {
        ...state,
        loading: true,
        uploadPastData: null,
        uploadPastDataError: null,
      };
      break;
    case UPLOAD_PAST_DATA_CONSTS.UPLOAD_PAST_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        uploadPastDataError: null,
        uploadPastData:
          action.payload.statusCode === 200 ? action.payload : false,
      };
      break;
    case UPLOAD_PAST_DATA_CONSTS.UPLOAD_PAST_DATA_FAILURE:
      state = {
        ...state,
        uploadPastDataError: action.payload.data ? action.payload : false,
        loading: false,
        uploadPastData: null,
      };
      break;
    case UPLOAD_PAST_DATA_CONSTS.GET_TEMPLATE_DATA_REQUEST:
      state = {
        ...state,
        loading: true,
        templateData: null,
        error: null,
      };
      break;
    case UPLOAD_PAST_DATA_CONSTS.GET_TEMPLATE_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        templateData:
          action.payload.statusCode === 200 ? action.payload.data : false,
      };
      break;
    case UPLOAD_PAST_DATA_CONSTS.GET_TEMPLATE_DATA_FAILURE:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        templateData: null,
      };
      break;

    case UPLOAD_PAST_DATA_CONSTS.GET_PAST_DATA_LIST_REQUEST:
      state = {
        ...state,
        listData: null,
      };
      break;
    case UPLOAD_PAST_DATA_CONSTS.GET_PAST_DATA_LIST_SUCCESS:
      state = {
        ...state,
        listData: action.payload.data,
      };
      break;
    case UPLOAD_PAST_DATA_CONSTS.GET_PAST_DATA_LIST_FAILURE:
      state = {
        ...state,
        listData: null,
      };
      break;

    //  CYP DOWNLOAD
    case UPLOAD_PAST_DATA_CONSTS.CROP_YIELD_EXCEL_TEMPLATE_REQUEST:
      state = {
        ...state,

        loading: true,
        CyptemplateData: null,
        error: null,
      };
      break;
    case UPLOAD_PAST_DATA_CONSTS.CROP_YIELD_EXCEL_TEMPLATE_SUCCESS:
      state = {
        ...state,

        loading: true,
        CyptemplateData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: null,
      };
      break;
    case UPLOAD_PAST_DATA_CONSTS.CROP_YIELD_EXCEL_TEMPLATE_FAILURE:
      state = {
        ...state,

        loading: true,
        CyptemplateData: null,
        error: action.payload.data ? action.payload.data.errorDescription : "",
      };
      break;

    //  CYP UPLOAD REDUCER
    case UPLOAD_PAST_DATA_CONSTS.CROP_YIELD_DATA_SUBMIT_REQUEST:
      state = {
        ...state,

        loading: true,
        cypUploadData: null,
        cypError: null,
      };
      break;
    case UPLOAD_PAST_DATA_CONSTS.CROP_YIELD_DATA_SUBMIT_SUCCESS:
      state = {
        ...state,

        loading: true,
        cypUploadData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        cypError: null,
      };
      break;
    case UPLOAD_PAST_DATA_CONSTS.CROP_YIELD_DATA_SUBMIT_FAILURE:
      state = {
        ...state,

        loading: true,
        cypUploadData: null,
        cypError: action.payload.data ? action.payload : false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UploadPastData;
