import Cookies from "js-cookie";
import { TileLayer, LayerGroup } from "react-leaflet";
import * as xlsx from "xlsx";
import * as FileSaver from "file-saver";
import moment from "moment";
import _ from "lodash";
import {
  DISPLAY_CHART_DATE,
  DisplyChartDateFormat,
  TimeFramesDateFormat,
  myConst,
  DATA_SOURCES,
  OPEN_WEATHER_PARAMETERS_CONST,
  GEOJSON_COUNTRY_DATA,
  OPEN_WEATHER_PARAMETER_SLUG,
  PARAMETER_SLUGS,
  SHOW_DAY_AND_QUANTILE,
  DATE_TIME_FORMAT,
  SHOW_VALIDTIME_AND_LAYERS,
} from "../../constants";
import i18n from "../../i18n";

export const signIn = (loginData) => {
  if (loginData) {
    Cookies.set("user", loginData, { expires: 1 });
  }
};

export const isLogedIn = () => {
  let userLoggedInData = [];
  userLoggedInData = Cookies.get("user");
  if (userLoggedInData) {
    return userLoggedInData;
  }
  return false;
};

export const isLogedout = () => {
  Cookies.remove("user");
  return Cookies.get("user");
};
export const LEGEND_UNIT_CONVERTER = [
  {
    unit: "°C",
    converter: "°F",
    convert: (c) => {
      const x = c ? ((9 * c) / 5 + 32).toFixed(1) : c;
      return x;
    },
  },
  {
    unit: "°F",
    converter: "°C",
    convert: (f) => {
      const x = f ? ((f - 32) * (5 / 9)).toFixed(1) : f;
      return x;
    },
  },
  {
    unit: "inch/h",
    converter: "mm/h",
    convert: (inch) => {
      return inch ? Math.round((25.4 * inch).toFixed(2)) : inch;
    },
  },
  {
    unit: "mm/h",
    converter: "inch/h",
    convert: (mm) => {
      return mm ? (0.0393701 * mm).toFixed(2) : mm;
    },
  },
  {
    unit: "km/h",
    converter: "m/s",
    convert: (km) => {
      return km ? ((5 / 18) * km).toFixed(2) : km;
    },
  },
  {
    unit: "m/s",
    converter: "km/h",
    convert: (m) => {
      return m ? ((18 / 5) * m).toFixed(2) : m;
    },
  },
  {
    unit: "hPa",
    converter: "inHg",
    convert: (hPa) => {
      return hPa ? (0.02953 * hPa).toFixed(1) : hPa;
    },
  },
  {
    unit: "inHg",
    converter: "hPa",
    convert: (inHg) => {
      return inHg ? Math.floor((33.863886666667 * inHg).toFixed(1)) : inHg;
    },
  },
];

// function for displaying date in charts
export const renderGenericDateFormat = (date) => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate).local().format(DisplyChartDateFormat);
  return formatedDate;
};

// function for displaying date in comments section
export const renderDateTimeFormat = (date) => {
  const formattedDatetime = moment(date).format(DATE_TIME_FORMAT);

  return formattedDatetime;
};

// function for displaying date in charts
export const renderChartTime = (date) => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate).local().format(DISPLAY_CHART_DATE);
  return formatedDate;
};

// function for displaying time frames in charts
export const renderTimeFrames = (date) => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate).local().format(TimeFramesDateFormat);
  return formatedDate;
};

let regionData = [];
export const setRegionData = (data) => {
  regionData = data;
};

export const getRegionData = () => {
  return regionData;
};

export const superAdmin = () => {
  if (Cookies.get("user")) {
    return !!JSON?.parse(Cookies.get("user"))?.user_details?.[0]?.is_superuser;
  } else false;
};

export const isPermissionsMatch = (permissionList, permission) => {
  const matchIndex = permissionList?.findIndex(
    (eachValue) => eachValue.codename === permission,
  );
  const isMatch = matchIndex >= 0;
  return isMatch;
};

export const removeRegionTooltip = (layers, elements) => {
  if (!_.isEmpty(layers)) {
    Object.values(layers).map(({ _leaflet_id }) => {
      const regionLayerData = getRegionData()?.length
        ? getRegionData()?.filter((l) => l.id === _leaflet_id)
        : [];
      let regionText = "";
      if (regionLayerData?.length) {
        if (regionLayerData[0]?.layerType === "polygon") {
          regionText = `polygon-${regionLayerData[0]?.polygonCount}`;
        } else {
          // eslint-disable-next-line no-unused-vars
          regionText = `(${regionLayerData[0].latlngs[0].toFixed(
            4,
          )},${regionLayerData[0].latlngs[1].toFixed(4)})`;
        }
        for (let i = 0; i < elements.length; i += 1) {
          elements[i].parentNode.removeChild(elements[i]);
        }
      }
      return null;
    });
  }
};
export const renderParamList = (paramList) => {
  if (paramList?.length) {
    const obj = paramList[0];
    if (!_.isEmpty(obj)) {
      const paramItems = [];
      obj.parameterData?.map((item) => {
        if (item) {
          item.dataSource = obj.name;
          item.type = obj.type;
          paramItems.push(item);
          return null;
        }
        return null;
      });
      return paramItems;
    }
  }
};
export const FORECAST_LEAD_TIME_LIST = () => {
  const { t } = i18n;
  const dateArray = [
    {
      value: 1,
      label: `${t("CLIMATE_WEATHER_FILTER.JANUARY")}`,
      id: 1.0,
      name: `${t("CLIMATE_WEATHER_FILTER.JANUARY")}`,
    },
    {
      value: 2,
      label: `${t("CLIMATE_WEATHER_FILTER.FEB")}`,
      id: 2.0,
      name: `${t("CLIMATE_WEATHER_FILTER.FEB")}`,
    },
    {
      value: 3,
      label: `${t("CLIMATE_WEATHER_FILTER.MAR")}`,
      id: 3.0,
      name: `${t("CLIMATE_WEATHER_FILTER.MAR")}`,
    },
    {
      value: 4,
      label: `${t("CLIMATE_WEATHER_FILTER.APR")}`,
      id: 4.0,
      name: `${t("CLIMATE_WEATHER_FILTER.APR")}`,
    },
    {
      value: 5,
      label: `${t("CLIMATE_WEATHER_FILTER.MAY")}`,
      id: 5.0,
      name: `${t("CLIMATE_WEATHER_FILTER.MAY")}`,
    },
    {
      value: 6,
      label: `${t("CLIMATE_WEATHER_FILTER.JUN")}`,
      id: 6.0,
      name: `${t("CLIMATE_WEATHER_FILTER.JUN")}`,
    },
    {
      value: 7,
      label: `${t("CLIMATE_WEATHER_FILTER.JUL")}`,
      id: 7.0,
      name: `${t("CLIMATE_WEATHER_FILTER.JUL")}`,
    },
    {
      value: 8,
      label: `${t("CLIMATE_WEATHER_FILTER.AUG")}`,
      id: 8.0,
      name: `${t("CLIMATE_WEATHER_FILTER.AUG")}`,
    },
    {
      value: 9,
      label: `${t("CLIMATE_WEATHER_FILTER.SEP")}`,
      id: 9.0,
      name: `${t("CLIMATE_WEATHER_FILTER.SEP")}`,
    },
    {
      value: 10,
      label: `${t("CLIMATE_WEATHER_FILTER.OCT")}`,
      id: 10.0,
      name: `${t("CLIMATE_WEATHER_FILTER.OCT")}`,
    },
    {
      value: 11,
      label: `${t("CLIMATE_WEATHER_FILTER.NOV")}`,
      id: 11.0,
      name: `${t("CLIMATE_WEATHER_FILTER.NOV")}`,
    },
    {
      value: 12,
      label: `${t("CLIMATE_WEATHER_FILTER.DEC")}`,
      id: 12.0,
      name: `${t("CLIMATE_WEATHER_FILTER.DEC")}`,
    },
  ];
  return dateArray;
};
export const handleOpenWeatherCompareRes = (layerData) => {
  const item = {};
  const result = [];

  result[0] = {
    Open_Weather: [
      {
        map_data: {
          ...layerData,
          code: 200,
          map_url: myConst.OPEN_WEATHER_TILE_LAYER_URL.replace(
            "{layername}",
            layerData.layerName,
          ),
          max: layerData.max,
          min: layerData.min,
          units: layerData.measure,
          palette: layerData?.palette || "",
        },
      },
    ],
  };
  item.data = {
    code: 200,
    result,
  };
  return item;
};

export const handleForecastLeadMonthFormat = (a) => {
  let b;
  FORECAST_LEAD_TIME_LIST().map((i) => {
    if (parseInt(i?.value, 10) === parseInt(a?.category, 10)) {
      b = {
        name: i?.name,
        value: i?.name,
        label: i?.name,
        id: i?.id,
        data: a?.data,
      };
    }
    return null;
  });
  return b;
};
export const handleForecastLeadTimeFormat = (a) => {
  const { t } = i18n;
  const list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST()));
  const option_list = list?.splice(parseInt(a?.forecast_time_month, 10), 4);
  const new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST()));
  const new_arr = new_list?.splice(0, 4 - option_list.length);
  const latest_list = option_list.concat(new_arr);
  latest_list?.map((i, index) => {
    i.name = `${i.name}(${index + 1} ${t("CLIMATE_WEATHER_FILTER.MONTH")})`;
    return null;
  });
  return latest_list[parseInt(a?.forecast_lead_time, 10) - 1];
};

export const renderParamLayer = (categoryParams, Overlay, layerRef) => {
  let isOpenWeather = "";
  if (categoryParams?.length) {
    return categoryParams.map((item, index) => {
      isOpenWeather = item.source_id === DATA_SOURCES.OPEN_WEATHER.id;
      const layer = OPEN_WEATHER_PARAMETERS_CONST.find(
        (layer_item) => layer_item.slug === item?.parameter?.slug,
      );

      let addOverlay;
      if (layer?.id) {
        addOverlay = true;
      } else {
        addOverlay = false;
      }
      return (
        <>
          {/* TODO: need to create separate component */}
          {addOverlay || !isOpenWeather ? (
            <Overlay name={item.parameter.name}>
              <LayerGroup ref={layerRef.current[index]}>
                <TileLayer
                  url={
                    layer
                      ? layer.layerName
                        ? myConst.OPEN_WEATHER_TILE_LAYER_URL.replace(
                            "{layername}",
                            layer.layerName,
                          )
                        : ""
                      : ""
                  }
                  id={item.parameter.name}
                />
              </LayerGroup>
            </Overlay>
          ) : (
            ""
          )}
        </>
      );
    });
  }
};
export const renderAPIParameterOptions = (list) => {
  if (list) {
    const params = [...list];
    const options = [];
    if (params.length) {
      params.map((param) => {
        const data = {
          value: param.name,
          label: `${param.name}`,
          id: param.id,
        };
        options.push(data);
        return true;
      });
      return options;
    }
  }
};
export const handleCommonParameters = (compareSource, comparePar, param) => {
  if (compareSource?.length) {
    if (comparePar && comparePar?.length) {
      if (param && param?.length) {
        const paramArr = [];
        const commonParam = JSON.parse(JSON.stringify(param[0]));
        commonParam.parameterData = [];
        const filteredParams = param[0]?.parameterData?.filter((o1) =>
          comparePar[0]?.parameterData?.some(
            (o2) => o1.parameter_id === o2.parameter_id,
          ),
        );
        if (filteredParams?.length) {
          filteredParams?.map((i) => commonParam?.parameterData?.push(i));
        }
        paramArr.push(commonParam);
        return paramArr;
        // setParams(paramArr);
      }
    }
  }
};
export const returnFilteredGeoJsonData = (
  selectedLayer,
  item,
  location,
  propertyName,
  locationName,
  selectedLocation,
) => {
  if (selectedLayer?.layer && selectedLayer?.layer?.current) {
    const data = JSON.parse(JSON.stringify(item.data));
    const selectedCountryData = GEOJSON_COUNTRY_DATA?.find(
      (item1) => item1.selectedCountry === selectedLocation?.country?.label,
    );
    const labelprop = selectedCountryData
      ? selectedCountryData[propertyName]
      : "";
    if (item.config.name === locationName && !_.isEmpty(location)) {
      data.features = item.data?.features.filter((x) => {
        if (
          _.startCase(_.lowerCase(x?.properties[labelprop])) ===
          _.startCase(_.lowerCase(location?.label))
        ) {
          selectedLayer.country = selectedLocation?.country;
          selectedLayer?.layer?.current?.clearLayers();
          return x;
        }
        return null;
      });

      selectedLayer.layer.current.addData(data);
      selectedLayer.color = item.config.color;
    }
    return selectedLayer;
  }
  return selectedLayer;
};
export const openWeatherParams = () => {
  const { t } = i18n;
  return [
    {
      name: `${t("WEATHER_FORCAST.PRECIPITATION")}`,
      slug: "precipitation",
      measure: "kg/m^2",
      visible: true,
      dash: "",
      type: "bar",
      mode: "",
    },
    {
      name: `${t("WEATHER_FORCAST.TEMPERATURE")}`,
      slug: "temperature",
      measure: "°C",
      visible: false,
      dash: "",
      type: "scatter",
      mode: "lines",
    },
    {
      name: `${t("WEATHER_FORCAST.WIND_SPEED")}`,
      slug: "wind_speed",
      measure: "m/s",
      visible: false,
      dash: "",
      type: "scatter",
      mode: "lines",
    },
    {
      name: `${t("WEATHER_FORCAST.HUMIDITY")}`,
      slug: "humidity",
      measure: "%",
      visible: false,
      dash: "",
      type: "scatter",
      mode: "lines",
    },
  ];
};

export const OpenWeatherChartLabels = (date) => {
  let formatedDate = new Date(date);

  formatedDate = moment(formatedDate).local().format("DD-MM-YYYY @ hA");
  return formatedDate;
};

export const glofasCategoriesParams = () => {
  return [
    {
      id: "1",
      name: "Standard",
      slug: "river_discharge",
    },
    {
      id: "2",
      name: "Mean",
      slug: "river_discharge_mean",
    },
    { id: "3", name: "Median", slug: "river_discharge_median" },
    {
      name: "Max",
      slug: "river_discharge_max",
    },
    {
      id: "4",
      name: "Min",
      slug: "river_discharge_min",
    },
    {
      id: "5",
      name: "P25",
      slug: "river_discharge_p25",
    },
    {
      id: "5",
      name: "P75",
      slug: "river_discharge_p75",
    },
  ];
};
export const glofasSeriesNames = {
  river_discharge: "River Discharge",
  river_discharge_mean: "River Discharge Mean",
  river_discharge_median: "River Discharge Median",
  river_discharge_max: "River Discharge Max",
  river_discharge_min: "River Discharge Min",
  river_discharge_p25: "River Discharge P25",
  river_discharge_p75: "River Discharge P75",
};

export const pastDaysOptions = [
  { id: "1", label: "Today", value: 0 },
  { id: "2", label: "1 day", value: 1 },
  { id: "3", label: "1 week", value: 7 },
  { id: "4", label: "1 month", value: 31 },
  { id: "5", label: "3 months", value: 92 },
  // { id: "6", label: "6 months", value: 183 },
  // { id: "7", label: "1 year", value: 356 },
];
export const forecastDaysOptions = [
  { id: "1", label: "1 day", value: 1 },
  { id: "2", label: "1 week", value: 7 },
  { id: "3", label: "2 weeks", value: 15 },
  { id: "4", label: "1 month", value: 31 },
  { id: "5", label: "3 months", value: 92 },
];
export const setWeatherForecastDefaultParam = () => {
  Cookies.set("weather-forecast-default-param", true);
};

export const renderOpenWeatherResponse = (result, config, selectedParam) => {
  const { t } = i18n;
  const {
    Open_Weather: { daily },
  } = result[0];
  let data = [];
  let Timestamp = [];
  const parameter = OPEN_WEATHER_PARAMETER_SLUG.find(
    (item) => item.value === selectedParam.parameter.name,
  );
  const { measure } = OPEN_WEATHER_PARAMETERS_CONST.find(
    (item) => item.name === parameter?.value,
  );
  const Units = [];
  let layerType = "";
  let location = "";
  Units[0] = parameter.measure;
  if (!_.isEmpty(parameter)) {
    data = daily.map((item) => {
      if (parameter.value === `${t("WEATHER_FORCAST.TEMPERATURE")}`) {
        return item[parameter.api_slug].day || 0;
      } else {
        return item[parameter.api_slug] || 0;
      }
    });
    Timestamp = daily?.map((item) => item.dt_txt);
    layerType = DATA_SOURCES.OPEN_WEATHER.name;
    location = config?.location || "";
    Units[0] = measure;
  }
  return {
    data,
    Timestamp,
    layerType,
    location,
    Units,
  };
};
export function htmlToText(html) {
  const temp = document.createElement("div");
  temp.innerHTML = html;
  return temp.textContent; // Or return temp.innerText if you need to return only visible text. It's slower.
}

export const renderDateFormat = (date, format) => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate).local().format(format);
  return formatedDate;
};

export const renderAPIForecastParameterOptions = (list) => {
  if (list) {
    const params = [...list];
    const options = [];
    if (params.length) {
      params.map((param) => {
        const data = {
          value: param.name,
          label: `${param.name}`,
          id: param.id,
        };
        options.push(data);
        return true;
      });
      return options;
    }
  }
};
//  setting login data in cookies
export const hasSuperUserPermissions = (data) => {
  Cookies.set("is_superuser", data, { expires: 1 });
};

export const hasStaffPermission = (data) => {
  Cookies.set("is_staff", data, { expires: 1 });
};

//  getting login data from cookies

export const isSuperUser = () => {
  const superUser = Cookies.get("is_superuser");
  return superUser === "true";
};

export const isStaffUser = () => {
  const staffUser = Cookies.get("is_staff");
  return staffUser === "true";
};

export const isSuperAdmin = () => {
  let isUserDetails = [];
  isUserDetails = JSON?.parse(Cookies?.get("user"));
  return isUserDetails?.user_details?.[0]?.is_superuser;
};

export const userDetails = () => {
  const isUserDetails = Cookies.get("user")
    ? JSON.parse(Cookies.get("user"))
    : "";
  return isUserDetails;
};

export const renderStaticParamList = (paramList) => {
  const ParameterList = [];
  paramList?.map((eachValue) => {
    ParameterList.push({
      ...eachValue.parameter,
      label: eachValue.parameter.name,
      value: eachValue.parameter.id,
    });
    return null;
  });
  return ParameterList;
};

export const renderStaticResourceImage = (
  staticResourceData,
  selectedValidLayer,
  selectedStaticParam,
  forecastType,
  quantileData,
  selectedValidTime,
) => {
  if (staticResourceData && staticResourceData.length) {
    if (selectedStaticParam?.slug === PARAMETER_SLUGS.SOIL_MOISTURE) {
      return selectedValidLayer;
    } else if (
      selectedStaticParam?.slug === PARAMETER_SLUGS.PRECEIPITATION_SEAS5
    ) {
      return forecastType?.selectedForecastType;
    } else if (SHOW_DAY_AND_QUANTILE.includes(selectedStaticParam?.slug)) {
      return quantileData?.selectedQuantile;
    } else {
      return selectedValidTime;
    }
  } else {
    return null;
  }
};

export const renderStaticResourceImageApi = (
  staticResourceData,
  selectedBasetime,
  selectedValidTime,
  selectedValidLayer,
  forecastType,
  daysData,
  quantileData,

  selectedStaticParam,
  selectedLocation,
) => {
  const requestData = {};

  if (staticResourceData && staticResourceData.length) {
    requestData.ecmwf_base_time = selectedBasetime?.value;
    requestData.country_id = selectedLocation?.country?.value;
    requestData.parameter_slug = selectedStaticParam?.slug;
    if (SHOW_VALIDTIME_AND_LAYERS.includes(selectedStaticParam?.slug)) {
      requestData.ecmwf_valid_time = selectedValidTime?.value;
    }
    // selectedStaticParam?.slug === PARAMETER_SLUGS.SOIL_MOISTURE
    if (selectedStaticParam?.slug === PARAMETER_SLUGS.SOIL_MOISTURE) {
      requestData.ecmwf_level = selectedValidLayer?.value;
    }

    if (selectedStaticParam?.slug === PARAMETER_SLUGS.PRECEIPITATION_SEAS5) {
      requestData.ecmwf_forecast_type =
        forecastType?.selectedForecastType?.value;
    }
    if (SHOW_DAY_AND_QUANTILE.includes(selectedStaticParam?.slug)) {
      // daysData?.selectedDay
      requestData.ecmwf_day = daysData?.selectedDay?.value;
      requestData.ecmwf_quantile = quantileData?.selectedQuantile?.value;
    }
  }
  return requestData;
}; // renderStaticResourceImageApi

export const hanldeExportToCSV = (data, slug) => {
  // Need to set the headers of xlsx
  const customData = data.map((item, index) => ({
    "No.": index + 1,
    Activities: item.Activities,
    Target: item.Target,
    Timeframe: item.Timeframe,
  }));

  const ws = xlsx.utils.json_to_sheet(customData);

  // Setting the header row style to bold
  const headerStyle = {
    font: { bold: true },
  };
  const headerRange = xlsx.utils.decode_range(ws["!ref"]);
  // eslint-disable-next-line no-plusplus
  for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
    const cellAddress = xlsx.utils.encode_cell({
      r: headerRange.s.r,
      c: col,
    });
    ws[cellAddress].s = headerStyle;
  }
  // Setting column widths
  const columnWidths = [
    { wpx: 80 }, // Width for "No." column
    { wpx: 150 }, // Width for "Direct Impacts" column
    { wpx: 150 }, // Width for "Indirect impacts" column
  ];

  ws["!cols"] = columnWidths;

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: "array" });
  const sheetData = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });
  FileSaver.saveAs(sheetData, `${slug}.xlsx`);
};

export const hanldeExportToBudgetCSV = (data, slug, projectDetails) => {
  // Need to set the headers of xlsx
  const customData = data.map((item, index) => ({
    "No.": index + 1,
    Activities: item.Activities,
    Target: item.Target,
    Timeframe: item.Timeframe,
    Budget: item.Budget
      ? `${projectDetails?.currency_symbol} ${item.Budget}`
      : 0,
  }));

  const ws = xlsx.utils.json_to_sheet(customData);

  // Setting the header row style to bold
  const headerStyle = {
    font: { bold: true },
  };
  const headerRange = xlsx.utils.decode_range(ws["!ref"]);
  // eslint-disable-next-line no-plusplus
  for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
    const cellAddress = xlsx.utils.encode_cell({
      r: headerRange.s.r,
      c: col,
    });
    ws[cellAddress].s = headerStyle;
  }
  // Setting column widths
  const columnWidths = [
    { wpx: 80 }, // Width for "No." column
    { wpx: 150 }, // Width for "Direct Impacts" column
    { wpx: 150 }, // Width for "Indirect impacts" column
  ];

  ws["!cols"] = columnWidths;

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: "array" });
  const sheetData = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });
  FileSaver.saveAs(sheetData, `${slug}.xlsx`);
};
