import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import errorImage from "../../assest/img/errorImage.svg";

function DeleteModal({ setDeleteModal, deleteModal, handleDeleteApiCall }) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={deleteModal} size="md">
      <div className="error-modal">
        <ModalHeader className="p-0 delete-header user-flex-end mr-2 mt-5" />
        <ModalBody className="delete-modal-padding">
          <div className="reg-success">
            <img
              src={errorImage}
              alt="not-available"
              className="reg-check-image"
            />
            <h2 className="delete-modal-header">
              {t("EARLY_ACTIONS.DELETE_ROW")}
            </h2>
            <p className="delete-modal-text mb-4">
              {t("EARLY_ACTIONS.DELETE_FOR_SURE")}
            </p>
            <div className="w-50 d-flex align-items-center justify-content-around">
              <Button
                type="button"
                className="back-office-cancel-btn"
                onClick={() => {
                  setDeleteModal();
                }}
              >
                {t("APPROVE_DATA.CANCEL")}
              </Button>
              <Button
                type="button"
                className="back-office-ok-btn w-auto mb-1 px-5"
                onClick={() => {
                  setDeleteModal();
                  handleDeleteApiCall();
                }}
              >
                {t("ODK.YES")}
              </Button>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default DeleteModal;
