import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import EARLY_WARNING_CONST from "./actionTypes";
import {
  addDroughtSuccess,
  addFloodSuccess,
  addFloodError,
  addDroughtError,
  getDroughtTimeSeriesSuccess,
  getDroughtTimeSeriesError,
  getFloodTimeSeriesSuccess,
  getFloodTimeSeriesError,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import {
  EARLY_WARNING_API,
  AXIOS_INSTANCE,
  AXIOS_INSTANCE_LOADER,
} from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* droughtlist(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.post(
        `${EARLY_WARNING_API}/${action.payload.url}map`,
        action.payload.data,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(addDroughtSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(addDroughtError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(addDroughtError(errorData));
  }
}

function* droughtTimeseries(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(
        `${EARLY_WARNING_API}/${action.payload.url}timeseries`,
        action.payload.data,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getDroughtTimeSeriesSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.message,
      };
      yield put(getDroughtTimeSeriesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDroughtTimeSeriesError(errorData));
  }
}

function* floodlist(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.post(
        `${EARLY_WARNING_API}/${action.payload.url}map`,
        action.payload.data,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
        slug: action?.payload?.data?.slug,
      };
      yield put(addFloodSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.message,
      };
      yield put(addFloodError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(addFloodError(errorData));
  }
}
function* floodTimeseries(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(
        `${EARLY_WARNING_API}/${action.payload.url}timeseries`,
        action.payload.data,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getFloodTimeSeriesSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.message,
      };
      yield put(getFloodTimeSeriesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getFloodTimeSeriesError(errorData));
  }
}

export function* watchDrought() {
  yield takeEvery(EARLY_WARNING_CONST.ADD_DROUGHT_REQUEST, droughtlist);
  yield takeEvery(
    EARLY_WARNING_CONST.GET_DROUGHT_TIMESERIES_REQUEST,
    droughtTimeseries,
  );
  yield takeEvery(EARLY_WARNING_CONST.ADD_FLOOD_REQUEST, floodlist);
  yield takeEvery(
    EARLY_WARNING_CONST.GET_FLOOD_TIMESERIES_REQUEST,
    floodTimeseries,
  );
}

function* DroughtSaga() {
  yield all([fork(watchDrought)]);
}

export default DroughtSaga;
