/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import MaterialReactTable from "material-react-table";
import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackButton from "../../assest/img/back-button.svg";
import { dateTimeFormat } from "../../helpers";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import RejectResponseModal from "./RejectResponseModal";
import ViewDetailsModal from "./ViewDetailsModal";
import {
  getPastDataListRequest,
  uploadPastDataRequest,
  uploadPastDataSuccess,
  uploadCypDataRequest,
  uploadCypDataSuccess,
} from "../../store/uploadPastData/actions";

function ApprovalPastData() {
  const [data, setData] = useState([]);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [finalPayload, setPayload] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [show, setShow] = useState(false);
  const [showRejectResponseModal, setShowRejectResponseModal] = useState(false);

  const handleShow = (rData) => {
    setSelectedData(rData);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const loggedInUserDetails = JSON.parse(
    localStorage.getItem("loggedInUserDetails"),
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useNavigate();
  useEffect(() => {
    dispatch(getPastDataListRequest());
  }, []);
  const nextProps = useSelector((state) => ({
    uploadedListData: state.UploadPastData.listData,
    uploadPastDataError: state.UploadPastData.uploadPastDataError,
    uploadedData: state.UploadPastData.uploadPastData,
    cypUploaddata: state.UploadPastData.cypUploadData,
    uploadCypError: state.UploadPastData?.cypError,
  }));

  useEffect(() => {
    if (nextProps.uploadPastDataError?.statusCode === 400) {
      setIsErrorModal(true);
      const message = nextProps.uploadPastDataError?.data?.message;
      if (nextProps.uploadPastDataError?.data) {
        if (Array.isArray(nextProps.uploadPastDataError?.data?.message)) {
          setIsErrorModal(false);
        } else {
          if (message) {
            setErrorMessage(nextProps.uploadPastDataError?.data?.message);
          }
        }
      }
    }
  }, [nextProps.uploadPastDataError]);

  useEffect(() => {
    if (nextProps.uploadCypError?.statusCode === 400) {
      setIsErrorModal(true);
      const message = nextProps.uploadCypError?.data?.message;
      if (nextProps.uploadCypError?.data) {
        if (Array.isArray(nextProps.uploadCypError?.data?.message)) {
          setIsErrorModal(false);
        } else {
          if (message) {
            setErrorMessage(nextProps.uploadCypError?.data?.message);
          }
        }
      }
    }
  }, [nextProps.uploadCypError]);

  useEffect(() => {
    if (nextProps.uploadedData?.statusCode === 200) {
      setIsSuccessModal(true);
      setSuccessMessage(nextProps.uploadedData.data);
      dispatch(getPastDataListRequest());
      dispatch(uploadPastDataSuccess(""));
    }
  }, [nextProps.uploadedData]);

  useEffect(() => {
    if (nextProps.cypUploaddata) {
      setIsSuccessModal(true);
      setSuccessMessage(nextProps.cypUploaddata);
      dispatch(getPastDataListRequest());
      dispatch(uploadCypDataSuccess(""));
    }
  }, [nextProps.cypUploaddata]);

  useEffect(() => {
    if (nextProps?.uploadedListData?.length) {
      setData(nextProps?.uploadedListData);
    }
  }, [nextProps.uploadedListData]);

  const getColorLable = (item) => {
    const finaldata =
      item === "pending"
        ? { color: "primary", label: t("SETTINGS.PENDING") }
        : item === "approved"
        ? { color: "success", label: t("SETTINGS.APPROVE") }
        : { color: "danger", label: t("SETTINGS.REJECT") };

    return finaldata;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "user_name",
        header: t("SETTINGS.NAME"),
      },
      {
        accessorKey: "email",
        header: t("SETTINGS.EMAIL"),
      },
      {
        accessorKey: "created_date",
        header: t("SETTINGS.UPLOAD_ON"),
        Cell: ({ cell }) => {
          return <div>{dateTimeFormat(new Date(cell.getValue()))}</div>;
        },
      },
      {
        accessorKey: "country_name",
        header: t("SETTINGS.COUNTRY"),
      },
      {
        accessorKey: "menu_slug",
        header: t("SETTINGS.MODULE"),
      },
      {
        accessorKey: "status",
        header: t("SETTINGS.STATUS"),
        Cell: ({ cell }) => {
          const value = getColorLable(cell.getValue());
          return (
            <Badge color={value?.color} style={{ color: "white" }}>
              {value?.label}
            </Badge>
          );
        },
      },
    ],
    [],
  );

  const confirmDecision = (rowData, type, data_override_decision) => {
    const payload = {
      decision_log_id: rowData.id,
      country_id: rowData.country_id,
      user_id: rowData.user_id,
      menu_slug: rowData.menu_slug,
      sub_menu_slug: rowData.sub_menu_slug, // sub_menu_slug
      data_override: data_override_decision,
      approved_by: loggedInUserDetails?.user_details?.[0]?.id,
      crop_type_id: rowData.crop_type_id,
    };

    if (rowData.menu_slug !== "crop_yield_prediction") {
      payload.start_date = rowData.start_date;
      payload.end_date = rowData.end_date;
      payload.crop_type_id = "";
    }

    if (type === "approved") {
      payload.status = "approved";
      rowData.menu_slug === "crop_yield_prediction"
        ? dispatch(uploadCypDataRequest(payload))
        : dispatch(uploadPastDataRequest(payload));
    } else if (type === "reject") {
      payload.status = "reject";
      rowData.menu_slug === "crop_yield_prediction"
        ? setShowRejectResponseModal(true)
        : setShowRejectResponseModal(true);
    }
    setPayload(payload);
  };

  const confirmRejection = (rejectComment) => {
    const payload = finalPayload;

    payload.reject_response = rejectComment || "N/A";
    payload.menu_slug === "crop_yield_prediction"
      ? dispatch(uploadCypDataRequest(payload))
      : dispatch(uploadPastDataRequest(payload));
    setShowRejectResponseModal(!showRejectResponseModal);
  };

  const handleErrorToggle = (type) => {
    if (type) {
      if (finalPayload) {
        const payload = finalPayload;
        payload.data_override = true;
        dispatch(uploadPastDataRequest(payload));
      }
      setIsErrorModal(!isErrorModal);
    } else {
      setIsErrorModal(!isErrorModal);
    }
  };
  return (
    <div className="back-office-main-page">
      {isSuccessModal && (
        <SuccessModal
          modal={isSuccessModal}
          toggle={setIsSuccessModal}
          payload={finalPayload}
          successMessage={successMessage}
        />
      )}
      {isErrorModal && (
        <ErrorModal
          modal={isErrorModal}
          toggle={setIsErrorModal}
          onClick={handleErrorToggle}
          message={errorMessage}
          errorType="dataOverride"
        />
      )}
      {showRejectResponseModal && (
        <RejectResponseModal
          modal={showRejectResponseModal}
          toggle={setShowRejectResponseModal}
          confirmRejection={confirmRejection}
        />
      )}
      <ViewDetailsModal
        selectedData={selectedData}
        isOpen={show}
        toggle={handleClose}
        getColorLable={getColorLable}
      />
      <div>
        <img
          src={BackButton}
          alt="back-button"
          onClick={() => history("/settings")}
          onKeyUp={() => {}}
          className="pointer"
        />
        <h2 className="approve-heading">{t("SETTINGS.APPROVAL_PAST_DATA")}</h2>
      </div>
      <div className="approve-table">
        <MaterialReactTable
          options={{ actionsColumnIndex: -1 }}
          columns={columns}
          data={data}
          enableRowActions
          enablePagination
          positionPagination="bottom"
          positionActionsColumn="last"
          // initialState={{ showColumnFilters: true }}
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <MenuItem
              key="view"
              onClick={() => {
                handleShow(row.original);
                closeMenu();
              }}
              role="button"
              tabIndex="0"
              onKeyUp={() => {}}
            >
              {t("SETTINGS.VIEW_DETAILS")}
            </MenuItem>,
            <MenuItem
              key="approve"
              disabled={row.original.status !== "pending"}
              onClick={() => {
                closeMenu();
                confirmDecision(row.original, "approved", false);
              }}
            >
              {t("SETTINGS.APPROVE")}
            </MenuItem>,
            <MenuItem
              key="reject"
              disabled={row.original.status !== "pending"}
              onClick={() => {
                closeMenu();
                confirmDecision(row.original, "reject", true);
              }}
            >
              {t("SETTINGS.REJECT")}
            </MenuItem>,
          ]}
        />
      </div>
    </div>
  );
}

export default ApprovalPastData;
