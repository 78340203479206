const EARLY_WARNING_POPULATION_DISPLACEMENT_CONST = {
  // GET POPULATION_DISPLACEMENT MAP DATA
  GET_POPULATION_DISPLACEMENT_MAP_DATA_REQUEST:
    "GET_POPULATION_DISPLACEMENT_MAP_DATA_REQUEST",
  GET_POPULATION_DISPLACEMENT_MAP_DATA_SUCCESS:
    "GET_POPULATION_DISPLACEMENT_MAP_DATA_SUCCESS",
  GET_POPULATION_DISPLACEMENT_MAP_DATA_FAILED:
    "GET_POPULATION_DISPLACEMENT_MAP_DATA_FAILED",

  // TIME SERIES
  GET_POPULATION_DISPLACEMENT_TIMESERIES_REQUEST:
    "GET_POPULATION_DISPLACEMENT_TIMESERIES_REQUEST",
  GET_POPULATION_DISPLACEMENT_TIMESERIES_SUCCESS:
    "GET_POPULATION_DISPLACEMENT_TIMESERIES_SUCCESS",
  GET_POPULATION_DISPLACEMENT_TIMESERIES_ERROR:
    "GET_POPULATION_DISPLACEMENT_TIMESERIES_ERROR",

  // HAZARD
  GET_POPULATION_DISPLACEMENT_HAZARD_REQUEST:
    "GET_POPULATION_DISPLACEMENT_HAZARD_REQUEST",
  GET_POPULATION_DISPLACEMENT_HAZARD_SUCCESS:
    "GET_POPULATION_DISPLACEMENT_HAZARD_SUCCESS",
  GET_POPULATION_DISPLACEMENT_HAZARD_ERROR:
    "GET_POPULATION_DISPLACEMENT_HAZARD_ERROR",
};
export default EARLY_WARNING_POPULATION_DISPLACEMENT_CONST;
