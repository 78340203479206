import GEOGLOWS_CONST from "./actionTypes";

const Geoglows = (state, action) => {
  if (typeof state === "undefined") {
    state = {
      error: "",
      loading: false,
    };
  }
  switch (action.type) {
    case GEOGLOWS_CONST.GEOGLOWS_DRAINAGE_REQUEST:
      return {
        ...state,
        isGeoglowsDrinageRequesting: true,
        geoglowsDrinageData: false,
        geoglowsDrinageDataError: false,
        geoglowsDrinageNetworkError: false,
      };
    case GEOGLOWS_CONST.GEOGLOWS_DRAINAGE_SUCCESS:
      return {
        ...state,
        isGeoglowsDrinageRequesting: false,
        geoglowsDrinageData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        geoglowsDrinageDataError: false,
        geoglowsDrinageNetworkError: false,
      };
    case GEOGLOWS_CONST.GEOGLOWS_DRAINAGE_FAILED:
      return {
        ...state,
        isGeoglowsDrinageRequesting: false,
        geoglowsDrinageData: false,
        geoglowsDrinageDataError: action.payload.error
          ? false
          : action.payload.response,
        geoglowsDrinageNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
    case GEOGLOWS_CONST.GEOGLOWS_CATCHMENT_REQUEST:
      return {
        ...state,
        isGeoglowsCatchmentRequesting: true,
        geoglowsCatchmentData: false,
        geoglowsCatchmentDataError: false,
        geoglowsCatchmentNetworkError: false,
      };
    case GEOGLOWS_CONST.GEOGLOWS_CATCHMENT_SUCCESS:
      return {
        ...state,
        isGeoglowsCatchmentRequesting: false,
        geoglowsCatchmentData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        geoglowsCatchmentDataError: false,
        geoglowsCatchmentNetworkError: false,
      };
    case GEOGLOWS_CONST.GEOGLOWS_CATCHMENT_FAILED:
      return {
        ...state,
        isGeoglowsCatchmentRequesting: false,
        geoglowsCatchmentData: false,
        geoglowsCatchmentDataError: action.payload.error
          ? false
          : action.payload.response,
        geoglowsCatchmentNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
    default:
      return state;
  }
};

export default Geoglows;
