import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
// import successCheckmark from "../../assest/img/delete-success.svg";

function SuccessConfirmationModal({ toggle, modal, title, message, icon }) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={modal} size="md">
      <div className="success-modal">
        <ModalHeader className="p-0 delete-header user-flex-end mr-2 mt-5" />
        <ModalBody className="delete-modal-padding">
          <div className="reg-success">
            <img src={icon} alt="not-available" className="reg-check-image" />
            <h2 className="back-office-succes-head-text"> {title}</h2>
            <p className="back-office-succes-desc pl-4 pr-4 mt-2">{message}</p>
            <Button
              type="button"
              className="delete-confirmation-ok-btn mb-1"
              onClick={() => {
                toggle();
              }}
            >
              {t("UPLOAD_PAST_DATA.OKAY")}
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default SuccessConfirmationModal;
