import React, { useEffect } from "react";
import { Tab, Tabs } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import { ReactComponent as LeftIcon } from "../../assest/img/LeftIcon.svg";
import { ReactComponent as RightIcon } from "../../assest/img/RightIcon.svg";

function ScrollTabs({
  className,
  tabIndex,
  indicatorList,
  setLayerData,
  setSelectedLocation,
  setMapLayers,
  setSelectedCompareIndicator,
}) {
  // define state with initial value to let the tabs start with that value
  const [activeTab, setActiveTab] = React.useState(0);

  useEffect(() => {
    setActiveTab(tabIndex);
  }, [tabIndex]);

  const onTabClick = (e, index) => {
    setActiveTab(index);
    setLayerData(indicatorList[index]);
    setSelectedCompareIndicator([]);
    setSelectedLocation((prev) => {
      return {
        ...prev,
        region: [],
      };
    });
    setMapLayers([]);
  };

  // To Reset the activeTab back to zero whenever data is changed
  useEffect(() => {
    setActiveTab(0);
  }, []);

  return (
    <Tabs
      activeTab={activeTab}
      tabsScrollAmount={1}
      onTabClick={onTabClick}
      className={className}
      rightBtnIcon={<RightIcon height={18} width={18} />}
      leftBtnIcon={<LeftIcon height={18} width={18} />}
    >
      {indicatorList?.map((item) => {
        return (
          <Tab key={item.name}>
            <b>{item.name}</b>
          </Tab>
        );
      })}
    </Tabs>
  );
}

export default ScrollTabs;
