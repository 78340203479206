import React, { useState, useEffect } from "react";
// import Highcharts from "highcharts";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import moment from "moment";
import HighChartGraph from "../../components/Common/HighCharts";
import { dateFormatters } from "../../helpers";

function NutritionGraph({
  graphData,
  allFieldsData,
  selectedState,
  EWRawDataDates,
  setDataFound,
  dataFound,
  isShowTableView,
}) {
  const { t } = useTranslation();
  const [highChartData, setHighChartData] = useState({
    yAxis: [],
    series: [],
    xAxis: [],
  });
  const LNG_CODE = localStorage.getItem("i18nextLng");

  let graphTitle = "";
  if (!_.isEmpty(selectedState)) {
    graphTitle = selectedState?.state_name;
  } else if (allFieldsData?.region?.value && allFieldsData?.district?.value) {
    graphTitle = `${allFieldsData?.region?.label} - ${allFieldsData?.district?.label}`;
  } else if (allFieldsData?.region?.value) {
    graphTitle = allFieldsData?.region?.label;
  } else {
    graphTitle = allFieldsData?.country?.label;
  }
  const [chartOptions, setChartOptions] = useState({
    chart: {
      zoomType: "x",
      resetZoomButton: {
        position: {
          x: -10,
          y: 10,
        },
      },
      type: "line",
    },
    title: {
      text: `Nutrition (${graphTitle})`,
      style: {
        color: " #33527a",
      },
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      itemMarginBottom: 15,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
      pie: {
        dataLabels: {
          enabled: true,
          format: `<b style="color: {point.color}">{point.name}</b>: {point.percentage:.1f} %<br>`,
        },
      },
      // column: {
      //   pointWidth: allFieldsData?.isShowAllYears
      //     ? 30
      //     : graphData?.length !== 1
      //     ? 30
      //     : 10,
      //   pointPadding: 0,
      //   groupPadding: allFieldsData?.isShowAllYears
      //     ? 0
      //     : graphData?.length !== 1
      //     ? 0
      //     : 0.4,
      // },
      column: {
        pointWidth: allFieldsData?.isShowAllYears
          ? 30
          : graphData?.length !== 1
          ? 30
          : 10,
      },
    },
    exporting: {
      enabled: true,
      filename: `Nutrition (${graphTitle})`,
      buttons: {
        contextButton: {
          align: "right",
          x: -35,
          y: -5,
        },
      },
    },

    xAxis: {
      type: "datetime",
      // labels: {
      //   formatter: (value) => {
      //     return allFieldsData.isShowAllYears
      //       ? moment(value.value).format("YYYY")
      //       : moment(value.value).format("YYYY-MMM");
      //   },
      // },
      categories: highChartData.xAxis,
      tickPixelInterval: 50,
      tickInterval: allFieldsData.isShowAllYears ? 0 : 1,
      gridLineWidth: 1,
      crosshair: true,
      // labels: {
      //   rotation: 90, // Rotate the labels to 90 degrees
      //   align: "left", // Align the labels to the left for better readability
      // },
    },
    yAxis: highChartData.yAxis,
    tooltip: {
      shared: true,
      headerFormat: `<b> {point.x}</b><br>`,
      pointFormat: allFieldsData?.isShowAllYears
        ? `<b>{point.series.name} : <b> <b>{point.y}</b><br>`
        : graphData?.length !== 1
        ? `<b>{point.series.name} : <b> <b>{point.y}</b><br>`
        : `<b>{point.name} : <b> <b>{point.y}</b><br>`,
    },
    series: highChartData.series,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
    credits: {
      enabled: false,
    },
  });

  // const totalChildrenData = {
  //   name: `${t("NUTRITION.TOTAL_CHILDREN")}`,
  //   // type: allFieldsData?.isShowAllYears
  //   //   ? "line"
  //   //   : graphData?.length !== 1
  //   //   ? "line"
  //   //   : "column",
  //   type: allFieldsData?.isShowAllYears
  //     ? "line"
  //     : graphData?.length !== 1
  //     ? "line"
  //     : "",
  //   color: "#28537D",
  //   yAxis: 0,
  //   zIndex: 3,
  //   data: [],
  //   sliced: true,
  //   selected: true,
  // };
  const stuntingData = {
    name: `${t("NUTRITION.STUNTING")}`,
    // type: allFieldsData?.isShowAllYears
    //   ? "line"
    //   : graphData?.length !== 1
    //   ? "line"
    //   : "column",
    type: allFieldsData?.isShowAllYears
      ? "line"
      : graphData?.length !== 1
      ? "line"
      : "",
    color: "#D87042",
    yAxis: 0,
    zIndex: 2,
    data: [],
  };
  const underweightData = {
    name: `${t("NUTRITION.UNDERWEIGHT")}`,
    // type: allFieldsData?.isShowAllYears
    //   ? "line"
    //   : graphData?.length !== 1
    //   ? "line"
    //   : "column",
    type: allFieldsData?.isShowAllYears
      ? "line"
      : graphData?.length !== 1
      ? "line"
      : "",
    color: "#22AD7A",
    yAxis: 1,
    zIndex: 1,
    data: [],
  };
  const wastingData = {
    name: `${t("NUTRITION.WASTING")}`,
    // type: "column",
    type: allFieldsData?.isShowAllYears
      ? "column"
      : graphData?.length !== 1
      ? "column"
      : "",
    color: "#68b9d1",
    yAxis: 2,
    zIndex: 0,
    data: [],
  };

  useEffect(() => {
    const yaxisdata = [];
    const xAxisCategoriesData = [];
    if (graphData?.length) {
      graphData.forEach((item) => {
        // totalChildrenData.data.push({ y: item.total_children });
        stuntingData.data.push({ y: item.stunting });
        underweightData.data.push({ y: item.underweight });
        wastingData.data.push({ y: item.wasting });
        xAxisCategoriesData.push(
          allFieldsData?.isShowAllYears
            ? moment(new Date(`${item.year}-${item.month}`)).format("YYYY")
            : // : `${moment(new Date(`${item.year}-${item.month}`)).format(
            //     "YYYY-MMM",
            //   )}`,
            !EWRawDataDates?.is_monthly && !EWRawDataDates?.is_daily
            ? graphData?.length !== 1
              ? `${item.state_name}`
              : `${item.state_name}1-${moment(new Date(`${item.year}`)).format(
                  "YYYY",
                )}`
            : `${item.state_name}2-${moment(
                new Date(`${item.year}-${item.month}`),
              ).format("MMM")}`,
        );
      });
      const finalData = [
        // totalChildrenData,
        stuntingData,
        underweightData,
        wastingData,
      ];
      const isDataFound = finalData
        ?.map((item) => {
          if (item?.data?.length === 1) {
            if (item?.data[0]?.y === 0) return 0;
          }
          return "";
        })
        ?.every((item) => {
          return item === 0;
        });
      setDataFound(isDataFound);

      // !For Pie chart and bar graph
      if (!isDataFound) {
        if (allFieldsData?.isShowAllYear) {
          finalData.forEach((item, index) => {
            const yaxis = {
              lineColor: item.color,
              index,
              zIndex: item.zIndex,
              lineWidth: 1,
              tickLength: 10,
              tickWidth: 1,
              tickColor: item.color,
              labels: {
                // format: "{value:.1f}",
                style: {
                  size: 12,
                  color: item.color,
                },
              },
              title: {
                text: item.name,
                style: {
                  color: item.color,
                },
              },
              showEmpty: false,
            };
            if (index > 0) {
              yaxis.opposite = true;
            }
            yaxisdata.push(yaxis);
          });
          setHighChartData((preval) => {
            return {
              ...preval,
              series: finalData,
              yAxis: yaxisdata,
              xAxis: xAxisCategoriesData,
            };
          });
        } else {
          if (graphData?.length !== 1) {
            finalData.forEach((item, index) => {
              const yaxis = {
                lineColor: item.color,
                index,
                zIndex: item.zIndex,
                lineWidth: 1,
                tickLength: 10,
                tickWidth: 1,
                tickColor: item.color,
                labels: {
                  // format: "{value:.1f}",
                  style: {
                    size: 12,
                    color: item.color,
                  },
                },
                title: {
                  text: item.name,
                  style: {
                    color: item.color,
                  },
                },
                showEmpty: false,
              };
              if (index > 0) {
                yaxis.opposite = true;
              }
              yaxisdata.push(yaxis);
            });
            setHighChartData((preval) => {
              return {
                ...preval,
                series: finalData,
                yAxis: yaxisdata,
                xAxis: xAxisCategoriesData,
              };
            });
          } else {
            const seriesData = [{ data: [] }];
            finalData.forEach((item) => {
              const yaxis = {
                name: item.name,
                y: item.data.reduce((n, { y }) => n + y, 0), // Adding all data as one for pie chart
                color: item.color,
              };
              seriesData[0]?.data?.push(yaxis);
            });
            setHighChartData(() => {
              return {
                series: seriesData,
                yAxis: [],
                xAxis: [],
              };
            });
          }
        }
      }
    }
  }, [graphData, LNG_CODE]);

  useEffect(() => {
    setChartOptions((prev) => {
      return {
        ...prev,
        chart: {
          zoomType: "x",
          resetZoomButton: {
            position: {
              x: -10,
              y: 10,
            },
          },
          type: allFieldsData?.isShowAllYears
            ? "line"
            : graphData?.length !== 1
            ? "line"
            : "pie",
        },
        title: {
          text: `Nutrition (${graphTitle})`,
        },
        series:
          highChartData.series?.length === 1
            ? [
                {
                  type: "pie",
                  data: highChartData?.series[0].data.map(
                    ({ name, y, color }) => ({
                      name,
                      y,
                      color,
                    }),
                  ),
                },
              ]
            : highChartData?.series,
        xAxis: highChartData?.xAxis?.length
          ? {
              type: "datetime",
              // labels: {
              //   formatter: (value) => {
              //     return allFieldsData.isShowAllYears
              //       ? moment(value.value).format("YYYY")
              //       : moment(value.value).format("YYYY-MMM");
              //   },
              // },
              categories: highChartData.xAxis,
              tickPixelInterval: 50,
              tickInterval: allFieldsData.isShowAllYears ? 0 : 1,
              gridLineWidth: 1,
              crosshair: true,
              // labels: {
              //   rotation: 90, // Rotate the labels to 90 degrees
              //   align: "left", // Align the labels to the left for better readability
              // },
            }
          : [],
        yAxis: highChartData.yAxis?.length ? highChartData?.yAxis : [],
      };
    });
  }, [highChartData.series]);

  return !dataFound ? (
    <div>
      <HighChartGraph
        options={chartOptions}
        style={{ width: "100%", height: "100%" }}
        className="drought-graph"
        oneToOne
      />
      {allFieldsData?.dates?.length ? (
        <span className="time-show">
          {t("LOADING.DATE")} {dateFormatters(allFieldsData?.dates[0])}{" "}
          {t("LOADING.TO")} {dateFormatters(allFieldsData?.dates[1])}
        </span>
      ) : (
        ""
      )}
    </div>
  ) : isShowTableView ? (
    <div className="mt-2 text-center">{t("HEALTH.NO_TABLE_GRAPH_DATA")}</div>
  ) : (
    <div className="mt-2 text-center">{t("HEALTH.NO_GRAPH_DATA")}</div>
  );
}

export default React.memo(NutritionGraph);
