import GUIDE_LINES_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};
const GuideLinesReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case GUIDE_LINES_CONST.GET_ALL_GUIDE_LINES_REQUEST:
      state = {
        ...state,
        loading: true,
        guideList: null,
        error: "",
      };
      break;
    case GUIDE_LINES_CONST.GET_ALL_GUIDE_LINES_SUCCESS:
      state = {
        ...state,
        loading: false,
        guideList:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case GUIDE_LINES_CONST.GET_ALL_GUIDE_LINES_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        guideList: null,
      };
      break;

    // edit docs

    case GUIDE_LINES_CONST.GET_EDIT_GUIDE_LINES_REQUEST:
      state = {
        ...state,
        loading: true,
        guideLineEditSuccess: null,
        error: "",
      };
      break;
    case GUIDE_LINES_CONST.GET_EDIT_GUIDE_LINES_SUCCESS:
      state = {
        ...state,
        loading: false,
        guideLineEditSuccess:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case GUIDE_LINES_CONST.GET_EDIT_GUIDE_LINES_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        guideLineEditSuccess: null,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};
export default GuideLinesReducer;
