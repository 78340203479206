import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Label, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FiDownload } from "react-icons/fi";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useOutletContext } from "react-router-dom";
import _ from "lodash";

import EarlyActionProcess from "../../components/Common/EarlyActionProcess";
import PdfImg from "../../assest/img/sample-pdf.png";
import DocIcon from "../../assest/img/doc-img.svg";
import {
  getAllGuideLinesRequest,
  editGuideLinesRequest,
  getPreparednessPhaseRequest,
  userDetailsRequest,
} from "../../store/actions";
import ChooseDataModal from "./ChooseDataModal";
import { showError } from "../../components/Common/Notification";
import { userDetails } from "../../components/Common/Utils";

function Assessment() {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();

  const params = new URLSearchParams(window.location.search);
  const selectedSubModule = params.get("slug");

  const [, setOpen] = useOutletContext(); // for closing side bar when it is opening

  const uploadRef = useRef();

  const [locationState, setLocationState] = useState({});
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [guideLineList, setGuideLinesList] = useState([]);
  const [fileName, setFileName] = useState({});
  const [selectedProject, setSelectedProject] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mainStep, setMainStep] = useState(0);

  const userData = userDetails();
  const data = JSON.parse(localStorage.getItem("projectDetails"));

  useEffect(() => {
    setLocationState(data);
    setOpen(true);
    dispatch(
      getAllGuideLinesRequest({
        location_type: data?.location_type,
        location_type_id: data?.location_type_id,
        risk_type_id: data?.risk_type,
      }),
    );
    dispatch(userDetailsRequest({ id: userData?.user_details?.[0]?.id }));

    // Setting main stepper based on project details
    if (data?.ea_ap_status) {
      setMainStep(2);
    } else if (data?.ea_rp_status) {
      setMainStep(2);
    } else {
      if (data?.ea_pp_status) {
        setMainStep(1);
      } else {
        setMainStep(0);
      }
    }
  }, []);

  const handleClickBack = () => {
    history(`/ea-projects?alug=${selectedSubModule}`);
  };

  const nextProps = useSelector((state) => ({
    guideLinesList: state?.GuideLines?.guideList,
    uploadSuccess: state?.GuideLines?.guideLineEditSuccess,
    preparednessPhaseData: state?.EaProjects?.preparednessPhaseData,
    userDetails: state.User?.userDetails,
    categoryLoader: state?.EaProjects.categoryLoader,
  }));

  const isGuideLines = useRef(true);
  useEffect(() => {
    if (isGuideLines.current) {
      isGuideLines.current = false;
      return;
    }
    if (nextProps.uploadSuccess) {
      setSelectedProject("");
      dispatch(
        getAllGuideLinesRequest({
          location_type: locationState?.location_type,
          location_type_id: locationState?.location_type_id,
          risk_type_id: locationState?.risk_type,
        }),
      );
    }
  }, [nextProps.uploadSuccess]);

  const isUpdateSucess = useRef(true);
  useEffect(() => {
    if (isUpdateSucess.current) {
      isUpdateSucess.current = false;
      return;
    }
    if (nextProps.guideLinesList) {
      const keyValuePairsArray = nextProps.guideLinesList.map((item) => {
        const { slug, default_doc_url, pp_guidelines } = item;
        const doc_url =
          pp_guidelines.length > 0 ? pp_guidelines[0].doc_url : default_doc_url;
        const name = doc_url.split("/").pop();

        return { slug, name };
      });
      setFileName(keyValuePairsArray);
      setGuideLinesList(nextProps?.guideLinesList);
    }
  }, [nextProps.guideLinesList]);

  const onHandleInputFilechange = (e) => {
    const fileName1 = e.target.files[0]?.name;
    const isValidate = fileName1?.toLowerCase().includes(selectedProject?.slug);
    e.preventDefault();
    if (isValidate) {
      const file = e.target.files[0];

      if (file) {
        const payloadList = {
          slug: selectedProject?.slug,
          user_id: locationState?.user,
          location_type: locationState?.location_type,
          location_type_id: locationState?.location_type_id,
          risk_type: locationState?.risk_type,
          file: e.target.files[0],
        };

        dispatch(editGuideLinesRequest(payloadList));
      }
    } else {
      showError(
        ` ${t(
          "VALIDATION.VALID_DOCUMENT_1",
        )} ${selectedProject?.slug.toUpperCase()}  ${t(
          "VALIDATION.VALID_DOCUMENT_2",
        )}`,
      );
      setSelectedProject("");
    }
  };

  function truncateFileName(fileName1, maxLength) {
    if (fileName1.length <= maxLength) {
      return fileName1;
    } else {
      const truncated = fileName1.substr(0, maxLength - 3);
      return `${truncated} ... ${fileName1.substr(-6)}`;
    }
  }

  const handleOpenModal = () => {
    setOpen(false);
    /**
     * Calling API to check whether Project has data or not
     */
    dispatch(
      getPreparednessPhaseRequest({
        slug: "ea_pp_category",
        project_id: locationState?.id,
        is_default: "false",
      }),
    );
  };

  /**
   * Based on the response we will show pop-up
   * If we have data then we will directly navigate to prepaparedness phase
   * If we dont have data then user need to select either of the options provided in pop-up
   * if isDefault is true -> User have data
   * if isDefault is false -> User need to add new data
   */

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (nextProps.preparednessPhaseData?.length && !nextProps?.categoryLoader) {
      if (nextProps.preparednessPhaseData[0]?.content?.length) {
        history({
          pathname: "/preparedness-phase",
          search: `?isDefault=false&slug=${selectedSubModule}`,
        });
        localStorage.setItem("isDefaultCategory", "false");
        localStorage.setItem("subStepperActivePhase", 0); // Sub stepper active step
      } else {
        setIsModalOpen(true);
      }
    } else {
      !nextProps?.categoryLoader && setIsModalOpen(true);
    }
  }, [nextProps.preparednessPhaseData]);

  return (
    <div className="ea-project-container">
      {isModalOpen && (
        <ChooseDataModal
          toggle={() => setIsModalOpen(!isModalOpen)}
          modal={isModalOpen}
          selectedSubModule={selectedSubModule}
        />
      )}
      <div className="EA-Header ea-space-between">
        <div>
          <BsArrowLeft
            className="back-icon-ea"
            onClick={handleClickBack}
            role="button"
          />
          <p className="ea-header-text">{locationState?.name} </p>
        </div>
      </div>
      <EarlyActionProcess activeStep={mainStep} />
      <div className="ea-space ea-space-b">
        <Row className="pred-row">
          {guideLineList?.length
            ? guideLineList?.map((each, index) => {
                const name1 = truncateFileName(fileName?.[index]?.name, 20);
                return (
                  <Col lg={4} className="pred-col p-0">
                    <div className="preparedness-project-card ">
                      <div>
                        <h2 className="mb-2">{each?.name}</h2>

                        <p className="mb-4 mt-2">{each?.description}</p>
                      </div>
                      <div className="preparedness-doc mb-3 mt-5">
                        <div className="open-doc-div">
                          <img
                            src={PdfImg}
                            alt=""
                            className="guidelines-image "
                          />
                        </div>

                        <div className="upload-section">
                          <img src={DocIcon} alt="" />
                          <div className="upload uploaded back-office-center">
                            <Label className="upload-area mb-0">
                              <input
                                type="file"
                                accept=".doc, .docx"
                                style={{ display: "none" }}
                                onChange={(e) =>
                                  onHandleInputFilechange(e, each)
                                }
                                ref={uploadRef}
                              />
                            </Label>
                            <p className="mb-0 ml-2"> {name1} </p>
                          </div>
                          <div className="upload d-flex">
                            <a
                              href={
                                each?.pp_guidelines.length > 0
                                  ? each?.pp_guidelines[0].doc_url
                                  : each?.default_doc_url
                              }
                              download
                              aria-label="downloadIcon"
                            >
                              <span className="upload- ">
                                <FiDownload />
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })
            : ""}
        </Row>
        <div className="m-4 d-flex flex-column justify-content-center align-items-center">
          {!_.isEmpty(locationState) && !locationState?.ea_rp_status ? (
            <div className="mb-3">
              <input
                type="checkbox"
                id="box"
                onClick={() => setIsTermsAccepted(!isTermsAccepted)}
              />
              <p className="ea-checkbox-text">
                {t("EARLY_ACTIONS.TERMS_TEXT")}
              </p>
            </div>
          ) : (
            ""
          )}
          <Button
            className="ea-create-btn"
            onClick={() => {
              handleOpenModal();
            }}
            disabled={!isTermsAccepted && !locationState?.ea_rp_status}
          >
            {" "}
            {t("EARLY_ACTIONS.PROCEED")}
          </Button>
        </div>
      </div>
    </div>
  );
}
export default Assessment;
