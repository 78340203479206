import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrFormClose } from "react-icons/gr";
import { Alert } from "reactstrap";
import HighChartGraph from "../../components/Common/HighCharts";
import { HighChartConstants } from "../../constants";
import { handleExportToCSVOpenWeather } from "../../helpers/excelDownload";

function Graph({
  data,
  selectedLocation,
  graphPanelHeight,
  startDate,
  endDate,
  openPanel,
  open,
  adminRadio,
  setShowChart,
}) {
  const { t } = useTranslation();

  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState();

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.reflow();
    }
  }, [graphPanelHeight]);

  useEffect(() => {
    if (data?.xaxis) {
      const myDiv1 = document.getElementById(`rapid-response`);
      const containerWidth2 = myDiv1?.offsetWidth;

      const width =
        data?.xaxis?.length >= HighChartConstants?.fifteen
          ? data?.xaxis?.length * HighChartConstants?.sixtyFive
          : null;

      containerWidth2 > width
        ? setChartWidth(containerWidth2 - 30)
        : setChartWidth(width);
    }
  }, [data?.xaxis, openPanel, open]);

  const isRapidResponse =
    selectedLocation?.state !== "" &&
    selectedLocation?.state?.label !== "All Regions"
      ? "Districts"
      : "States";

  const options = {
    chart: {
      type: "column",
      height: 320,
      width: chartWidth - 10,
      zoomType: "x",
      resetZoomButton: {
        position: {
          x: -10,
          y: 10,
        },
      },
    },
    title: {
      text: data?.isCrop
        ? `<b>Crop exposure (${moment(startDate).format(
            "D MMM, YYYY",
          )} -  ${moment(endDate).format("D MMM, YYYY")})</b>`
        : `<b>Population exposure (${moment(startDate).format(
            "D MMM, YYYY",
          )} -  ${moment(endDate).format("D MMM, YYYY")})</b>`,
      style: {
        fontSize: "14px",
        color: "#363636",
      },
      align: "center",
    },
    xAxis: {
      categories:
        adminRadio === "admin"
          ? selectedLocation?.state !== "" &&
            selectedLocation?.state?.label !== "All Regions"
            ? data.district_name
            : data?.state_name
          : selectedLocation?.basin !== "" &&
            selectedLocation?.basin?.label !== "Select Basin"
          ? data.sub_basin_name
          : data?.basin_name,

      gridLineColor: "#DDDDDD",
      crosshair: false,
      gridLineWidth: 1,
    },
    yAxis: {
      title: {
        text: data.yaxisTitle,
      },
      tickAmount: 6,
    },
    tooltip: {
      shared: false,
      useHTML: true,

      headerFormat: '<table><tr><th colspan="2">{point.x}</th></tr>',
      pointFormat: `<tr><td>{point.series.name}: </td><td style="text-align: right"><b>{point.y}</b></td></tr>`,

      footerFormat: "</table>",
      valueDecimals: 2,
    },
    exporting: {
      enabled: true,
      filname: "rapid-response",
      buttons: {
        contextButton: {
          menuItems: [
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            {
              text: "Export to CSV",
              onclick() {
                // eslint-disable-next-line react/no-this-in-sfc
                const seriesData = this.series.map((series) => ({
                  name: series.name,
                  data: series.data.map((point) => point.y),
                }));
                // eslint-disable-next-line react/no-this-in-sfc
                const xAxisData = this.xAxis[0].categories;
                handleExportToCSVOpenWeather(
                  seriesData,
                  xAxisData,
                  data?.isCrop ? "Crop exposure" : "Population exposure",
                  isRapidResponse,
                );
                // Log series data and x-axis data to the console
              },
            },
          ],
        },
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        pointWidth: 35,
      },

      series: {
        states: {
          inactive: {
            enabled: false,
          },
        },
      },
    },
    series: data?.isCrop
      ? [
          {
            name: "Total crop area",
            data: data?.total_crop_area,
            color: "#5088C6",
          },
          {
            name: "Affected crop area",
            data: data?.affected_crop_area,
            color: "#D87042",
          },
        ]
      : [
          {
            name: "Total Population",
            data: data?.total_population,
            color: "#5088C6",
          },
          {
            name: "Affected population",
            data: data?.population_number_affected,
            color: "#D87042",
          },
        ],
  };

  return (
    <div
      className="crop-yield-graph crop-predection-graph-modal chart-design chart-flow rp-rainfall-graph-no-data"
      id="rapid-response"
    >
      {data?.xaxis?.length ? (
        <>
          <HighChartGraph
            key={data.isCrop}
            options={options}
            style={{ width: "100%", height: "100%" }}
            className="drought-graph"
            chartRef={chartRef}
          />
          <div className="close-icon-highcharts-rapid-response">
            <GrFormClose
              className="close-graph-bth"
              onClick={() => {
                setShowChart(false);
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="close-icon-highcharts-rapid-response">
            <GrFormClose
              className="close-graph-bth"
              onClick={() => {
                setShowChart(false);
              }}
            />
          </div>
          <div className="floodgraph-error">
            <div className="graph-error-style h-100">
              <Alert color="warning" className="alert-warning-container">
                {t("LOADING.NOT_FOUND")}{" "}
              </Alert>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Graph;
