const CORE_CONST = {
  CORE_REQUEST: "CORE_REQUEST",
  CORE_SUCCESS: "CORE_SUCCESS",
  CORE_ERROR: "CORE_ERROR",

  CORE_STATE_REQUEST: "CORE_STATE_REQUEST",
  CORE_STATE_SUCCESS: "CORE_STATE_SUCCESS",
  CORE_STATE_ERROR: "CORE_STATE_ERROR",

  CORE_DISTRICT_REQUEST: "CORE_DISTRICT_REQUEST",
  CORE_DISTRICT_SUCCESS: "CORE_DISTRICT_SUCCESS",
  CORE_DISTRICT_ERROR: "CORE_DISTRICT_ERROR",

  CORE_BASIN_REQUEST: "CORE_BASIN_REQUEST",
  CORE_BASIN_SUCCESS: "CORE_BASIN_SUCCESS",
  CORE_BASIN_ERROR: "CORE_BASIN_ERROR",
};
export default CORE_CONST;
