/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@mui/material";
import MaterialReactTable from "material-react-table";
import ManageUserHeader from "./Manageuserheader";

import {
  getAllUsersRequest,
  coreRequest,
  organizationRequest,
  userDetailsRequest,
  getAllPermissionsRequest,
  assignUsersRequest,
  assignUsersSuccess,
  // userDetailsRequest
} from "../../store/actions";
import PaginationSection from "./PaginationSection";
import Filter from "./ManageUserFilter";
import DetailsModal from "./DetailsModal";
import { showSuccess } from "../../components/Common/Notification";
// import { renderReactOptionsArray } from "../../helpers";

function ManageUser() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [countryList, setCountryList] = useState([]);
  const [filter, setFilter] = useState({
    country: "",
    name: "",
    email: "",
    organization: "",
  });
  const [isDetailsModal, setIsDetailsModal] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [permissionList, setPermissionsList] = useState([]);
  const [groupNamesList, setGroupNamesList] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [loginUserPermissionList, setLoginUserPermissionList] = useState([]);

  const userId = JSON.parse(Cookies.get("user"))?.user_details?.[0]?.id;

  const nextProps = useSelector((state) => ({
    userList: state.ManageUserReducer,
    countryList: state.Core.coreList,
    organizationList: state.User?.organizationList,
    userDetails: state.User?.userDetails,
    initial: state.User.initial,
    permissionsList: state.Permissions?.otherToolsListData,
    assignPermission: state.Permissions?.assignPermissions,
  }));

  //  to get users list
  const isUserList = useRef(true);
  useEffect(() => {
    if (isUserList.current) {
      isUserList.current = false;
      return;
    }
    if (nextProps.userList.userListData) {
      const array = [];
      nextProps.userList.userListData?.results?.map((eachData) => {
        array.push({
          name: eachData.first_name,
          email: eachData.user.email,
          role: eachData?.user?.is_staff
            ? t("MANAGE_USERS.IWMI_ADMIN")
            : t("MANAGE_USERS.USER"),

          country: eachData?.country?.name,
          id: eachData.user.id,
        });
        return null;
      });
      setPageCount(nextProps.userList.userListData?.count);
      setData([...array]);
    }
  }, [nextProps.userList]);

  // to get country list
  const isCountrList = useRef(true);
  useEffect(() => {
    if (isCountrList.current) {
      isCountrList.current = false;
      return;
    }
    if (nextProps.countryList?.length) {
      setCountryList([...nextProps.countryList]);
    }
  }, [nextProps.countryList]);

  // to get country list
  const isPermissionRef = useRef(true);
  useEffect(() => {
    if (isPermissionRef.current) {
      isPermissionRef.current = false;
      return;
    }
    if (nextProps.permissionsList?.length) {
      const a = [];
      nextProps.permissionsList?.map((i) => {
        i.checked = false;
        return null;
      });
      setPermissionsList([...nextProps.permissionsList]);
      nextProps.permissionsList?.map((i) => {
        if (!a.includes(i.group_name)) {
          a.push(i.group_name);
        }
        return null;
      });
      setGroupNamesList(a);
    }
  }, [nextProps.permissionsList]);

  // to get user details
  const isUserDetailsRef = useRef(true);
  useEffect(() => {
    if (isUserDetailsRef.current) {
      isUserDetailsRef.current = false;
      return;
    }
    if (nextProps.userDetails) {
      setUserDetails(nextProps.userDetails);
      !nextProps.initial && setIsDetailsModal(true);
      nextProps.initial &&
        setLoginUserPermissionList(nextProps.userDetails?.user_permissions);
    }
  }, [nextProps.userDetails]);

  useEffect(() => {
    dispatch(
      getAllUsersRequest({
        name: "",
        page: 1,
        limit: pageSize,
        organization_id: filter.organization?.value || "",
        email: "",
        country_id: filter.country?.value || "",
        is_active: "True",
        is_verified: "True",
      }),
    );
    dispatch(coreRequest({ location_type: "country" }));
    dispatch(organizationRequest());
    dispatch(getAllPermissionsRequest());
    dispatch(userDetailsRequest({ id: userId, initial: "true" }));
  }, []);

  const clearFilters = () => {
    setPageSize(10);
    setPageLimit(10);
    setPageNumber(1);
    dispatch(
      getAllUsersRequest({
        name: "",
        page: 1,
        limit: 10,
        organization_id: "",
        email: "",
        country_id: "",
        is_active: "True",
        is_verified: "True",
      }),
    );
    setFilter({
      ...filter,
      country: "",
      name: "",
      email: "",
      organization: "",
    });
  };

  // to get country list
  const isUpdateSucessful = useRef(true);
  useEffect(() => {
    if (isUpdateSucessful.current) {
      isUpdateSucessful.current = false;
      return;
    }
    if (nextProps.assignPermission) {
      setIsDetailsModal(false);
      clearFilters();
      showSuccess(nextProps.assignPermission);
      dispatch(assignUsersSuccess({}));
    }
  }, [nextProps.assignPermission]);
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t("MANAGE_USERS.NAME"),
        filterable: false,
      },
      {
        accessorKey: "email",
        header: t("MANAGE_USERS.EMAIL"),
      },
      {
        accessorKey: "country",
        header: t("MANAGE_USERS.COUNTRY"),
      },
      {
        accessorKey: "role",
        header: t("MANAGE_USERS.ROLE"),
      },
    ],
    [],
  );

  const handleFilteredData = () => {
    setPageNumber(1);
    dispatch(
      getAllUsersRequest({
        name: filter.name,
        page: 1,
        limit: pageSize,
        email: filter.email,
        country_id: filter.country?.value || "",
        organization_id: filter.organization?.value || "",
        is_active: "True",
        is_verified: "True",
      }),
    );
  };

  const handleGoToPage = (value) => {
    setPageNumber(Number(value));
    dispatch(
      getAllUsersRequest({
        name: filter.name,
        page: value,
        limit: pageSize,
        email: filter.email,
        country_id: filter.country?.value || "",
        organization_id: filter.organization?.value || "",
        is_active: "True",
        is_verified: "True",
      }),
    );
  };
  const handlePageSize = (value) => {
    setPageSize(value);
    setPageLimit(value);
    setPageNumber(1);
    dispatch(
      getAllUsersRequest({
        page: 1,
        limit: value,
        email: filter.email,
        name: filter.name,
        country_id: filter.country?.value || "",
        organization_id: filter.organization?.value || "",
        is_active: "True",
        is_verified: "True",
      }),
    );
  };

  const handleShow = (userdata) => {
    dispatch(userDetailsRequest({ id: userdata.id }));
  };

  const onUpdateUserpermissions = () => {
    const data23 = {
      user_id: userDetails.user.id,
      permission_ids: selectedPermissions,
    };
    dispatch(assignUsersRequest(data23));
  };

  return (
    <div>
      <ManageUserHeader
        setFilterModal={setFilterModal}
        filterModal={filterModal}
      />
      <div className="manageUser-table">
        <MaterialReactTable
          columns={columns}
          data={data}
          rowCount={pageSize}
          manualPagination
          enableRowActions
          positionActionsColumn="last"
          initialState={{ showColumnFilters: false }}
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <MenuItem
              key="view"
              role="button"
              tabIndex="0"
              onKeyUp={() => {}}
              onClick={() => {
                handleShow(row.original);
                closeMenu();
              }}
            >
              {t("SETTINGS.VIEW_DETAILS")}
            </MenuItem>,
          ]}
        />
      </div>
      {data.length ? (
        <div className="mt-3">
          <PaginationSection
            pageCount={pageCount}
            pageLimit={pageLimit}
            setPageCount={setPageCount}
            setPageLimit={setPageLimit}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageSize={pageSize}
            handleGoToPage={handleGoToPage}
            handlePageSize={handlePageSize}
          />
        </div>
      ) : (
        ""
      )}
      {filterModal && (
        <Filter
          setFilterModal={setFilterModal}
          filterModal={filterModal}
          countryList={countryList}
          filter={filter}
          setFilter={setFilter}
          handleFilteredData={handleFilteredData}
          organizationList={nextProps.organizationList}
          clearFilters={clearFilters}
        />
      )}
      <DetailsModal
        isOpen={isDetailsModal}
        toggle={() => setIsDetailsModal(false)}
        userDetails={userDetails}
        permissionList={permissionList}
        groupNamesList={groupNamesList}
        preSelectedPermissions={userDetails.user_permissions}
        selectedPermissions={selectedPermissions}
        setSelectedPermissions={setSelectedPermissions}
        onUpdateUserpermissions={onUpdateUserpermissions}
        permissionsList={loginUserPermissionList}
      />
    </div>
  );
}

export default ManageUser;
