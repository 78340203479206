const CROP_YIELD_CONST = {
  CROP_YIELD_OPTIONS_REQUEST: "CROP_YIELD_OPTIONS_REQUEST",
  CROP_YIELD_OPTIONS_SUCCESS: "CROP_YIELD_OPTIONS_SUCCESS",
  CROP_YIELD_OPTIONS_ERROR: "CROP_YIELD_OPTIONS_ERROR",

  CROP_YIELD_PAST_DATA_REQUEST: "CROP_YIELD_PAST_DATA_REQUEST",
  CROP_YIELD_PAST_DATA_SUCCESS: "CROP_YIELD_PAST_DATA_SUCCESS",
  CROP_YIELD_PAST_DATA_ERROR: "CROP_YIELD_PAST_DATA_ERROR",

  // summary
  CROP_YIELD_SUMMARY_REQUEST: "CROP_YIELD_SUMMARY_REQUEST",
  CROP_YIELD_SUMMARY_SUCCESS: "CROP_YIELD_SUMMARY_SUCCESS",
  CROP_YIELD_SUMMARY_ERROR: "CROP_YIELD_SUMMARY_ERROR",

  // start and end date
  CROP_YIELD_START_END_DATE_REQUEST: "CROP_YIELD_START_END_DATE_REQUEST",
  CROP_YIELD_START_END_DATE_SUCCESS: "CROP_YIELD_START_END_DATE_SUCCESS",
  CROP_YIELD_START_END_DATE_ERROR: "CROP_YIELD_START_END_DATE_ERROR",

  CROP_YIELD_PREDECTION_REQUEST: "CROP_YIELD_PREDECTION_REQUEST",
  CROP_YIELD_PREDECTION_SUCCESS: "CROP_YIELD_PREDECTION_SUCCESS",
  CROP_YIELD_PREDECTION_ERROR: "CROP_YIELD_PREDECTION_ERROR",

  CROP_YIELD_CROP_LIST_REQUEST: "CROP_YIELD_CROP_LIST_REQUEST",
  CROP_YIELD_CROP_LIST_SUCCESS: "CROP_YIELD_CROP_LIST_SUCCESS",
  CROP_YIELD_CROP_LIST_ERROR: "CROP_YIELD_CROP_LIST_ERROR",

  // FEEDBACK
  CROP_YEILD_FEEDBACK_REQUEST: "CROP_YEILD_FEEDBACK_REQUEST",
  CROP_YEILD_FEEDBACK_SUCCESS: "CROP_YEILD_FEEDBACK_SUCCESS",
  CROP_YEILD_FEEDBACK_ERROR: "CROP_YEILD_FEEDBACK_ERROR",

  // GET FEEDBACK
  GET_CROP_YEILD_FEEDBACK_REQUEST: "GET_CROP_YEILD_FEEDBACK_REQUEST",
  GET_CROP_YEILD_FEEDBACK_SUCCESS: "GET_CROP_YEILD_FEEDBACK_SUCCESS",
  GET_CROP_YEILD_FEEDBACK_ERROR: "GET_CROP_YEILD_FEEDBACK_ERROR",

  // GET CROP CALENDER
  GET_CROP_CALENDER_REQUEST: "GET_CROP_CALENDER_REQUEST",
  GET_CROP_CALENDER_SUCCESS: "GET_CROP_CALENDER_SUCCESS",
  GET_CROP_CALENDER_ERROR: "GET_CROP_CALENDER_ERROR",
};
export default CROP_YIELD_CONST;
