import React, { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import ErrorBoundaries from "../../components/Common/ErrorBoundaries";
import { isLogedIn } from "../../components/Common/Utils";
import AwareHeader from "../../components/Common/AwareHeader";

function ProtectedLayout() {
  const navigate = useNavigate();

  useEffect(() => {
    const value = isLogedIn();
    let parseValue = "";

    if (value) {
      parseValue = JSON.parse(value);
    }
    if (value === false || !parseValue?.user_details?.[0]?.is_staff) {
      navigate("/");
    }
  }, []);
  return (
    <ErrorBoundaries>
      <div className="layout-div">
        <AwareHeader />
        <div className="second-container">
          {" "}
          <Outlet />
        </div>
      </div>
    </ErrorBoundaries>
  );
}

export default ProtectedLayout;
