import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import Cookies from "js-cookie";
// Login Redux States
import PREPAREDNESS_PHASE_CONST from "./actionTypes";
import {
  editPreparednessContentSuccess,
  editPreparednessContentError,
  deletePreparednessContentSuccess,
  deletePreparednessContentError,
  createPreparednessContentSuccess,
  createPreparednessContentError,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import {
  EARLY_ACTION_API,
  AXIOS_INSTANCE_LOADER,
  AXIOS_INSTANCE,
} from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

/**
 * EDIT API
 */
function* editPreparednessPhaseData(action) {
  if (Cookies.get("user")) {
    const userToken = JSON.parse(Cookies.get("user"))?.result?.token;
    AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  }
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.put(
        `${EARLY_ACTION_API}/pp/instance/edit`,
        action.payload,
      ),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
        isTable: !action.payload.comment,
      };
      yield put(editPreparednessContentSuccess(responseData));
    } else {
      const responseData = {
        data: response.data,
      };
      yield put(editPreparednessContentError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(editPreparednessContentError(errorData));
  }
}

/**
 * DELETE API
 * for sending payload we need to send it as params
 */
function* deletePreparednessPhaseData(action) {
  if (Cookies.get("user")) {
    const userToken = JSON.parse(Cookies.get("user"))?.result?.token;
    AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  }
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.delete(`${EARLY_ACTION_API}/pp/instance/delete`, {
        params: {
          project_id: action.payload.project_id,
          slug: action.payload.slug,
          content: JSON.stringify(action.payload.content),
        },
      }),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(deletePreparednessContentSuccess(responseData));
    } else {
      const responseData = {
        statusCode: 200,
        data: apiResponse.error,
      };
      yield put(deletePreparednessContentError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deletePreparednessContentError(errorData));
  }
}

/**
 * CREATE API
 */
function* createPreparednessPhaseData(action) {
  if (Cookies.get("user")) {
    const userToken = JSON.parse(Cookies.get("user"))?.result?.token;
    AXIOS_INSTANCE_LOADER.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  }
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.post(
        `${EARLY_ACTION_API}/pp/instance/create`,
        action.payload,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(createPreparednessContentSuccess(responseData));
    } else {
      const responseData = {
        data: response.data,
      };
      yield put(createPreparednessContentError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(createPreparednessContentError(errorData));
  }
}

export function* watchPreparednessPhase() {
  yield takeEvery(
    PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_EDIT_REQUEST,
    editPreparednessPhaseData,
  );
  yield takeEvery(
    PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_DELETE_REQUEST,
    deletePreparednessPhaseData,
  );
  yield takeEvery(
    PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_CREATE_REQUEST,
    createPreparednessPhaseData,
  );
}

function* EarlyActionPreparednessPhaseSaga() {
  yield all([fork(watchPreparednessPhase)]);
}

export default EarlyActionPreparednessPhaseSaga;
