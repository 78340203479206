import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import GEOGLOWS_CONST from "./actionTypes";
import {
  getGeoglowsDrainageFalied,
  getGeoglowsDrainageSuccess,
  getGeoglowsCatchmentSuccess,
  getGeoglowsCatchmentFalied,
} from "./action";
import checkHttpStatus from "../apiUtils";
import { AXIOS_INSTANCE, GEOGLOWS_URL } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

// forest geoglows Drinage by country Id
function* getGeoglowsDrainage(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${GEOGLOWS_URL}/drainage_layer/${action.payload.country_id}`,
      ),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getGeoglowsDrainageSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getGeoglowsDrainageFalied(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getGeoglowsDrainageFalied(errorData));
  }
}
// forest geoglows catchment  by country Id
function* getGeoglowsCatchment(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${GEOGLOWS_URL}/catchment_layer/${action.payload.country_id}`,
      ),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getGeoglowsCatchmentSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getGeoglowsCatchmentFalied(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getGeoglowsCatchmentFalied(errorData));
  }
}
export function* watchGeoglowsDrinage() {
  yield takeEvery(
    GEOGLOWS_CONST.GEOGLOWS_DRAINAGE_REQUEST,
    getGeoglowsDrainage,
  );
}
export function* watchGeoglowsCatchment() {
  yield takeEvery(
    GEOGLOWS_CONST.GEOGLOWS_CATCHMENT_REQUEST,
    getGeoglowsCatchment,
  );
}
function* GeoglowsSaga() {
  yield all([fork(watchGeoglowsDrinage), fork(watchGeoglowsCatchment)]);
}
export default GeoglowsSaga;
