import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "reactstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { useOutletContext } from "react-router-dom";
import SplitterLayout from "react-splitter-layout";
import "react-splitter-layout/lib/index.css";
import {
  coreRequest,
  corestateRequest,
  coredistrictRequest,
  cropYieldPredectionRequest,
  cropYieldCropListRequest,
  indicatorRequest,
  mapPreferenceRequest,
  getCropCalenderRequest,
} from "../../store/actions";
import TabContainer from "./TabsContainer";
import CropYieldFilter from "./Filter";
import MapContainer from "./Map";
import {
  renderReactOptionsArraystate,
  getMapSortedData,
  onHandleCropPredectionGraph,
  onHandleCropPredectionAnalytics,
  onhandleEndDate,
  // onHandleCropPredectionGraph,
} from "../../helpers";
import {
  State,
  District,
  ModalsConst,
  GEOJSON_COUNTRY_DATA,
  monitorIndicatorId,
  AccRainfallGpm,
  Fulfilled,
  Rejected,
  CropStages,
} from "../../constants";
import { AXIOS_INSTANCE_LOADER } from "../../store/apiUtils/config";
import Loader from "../../components/Common/Loader";
import FeedbackModal from "./FeedbackModal";
import FeedbackSuccessModal from "./FeedbackSuccessModal";
import { showError } from "../../components/Common/Notification";
import PredictionDataUploadModal from "./PredictionDataUploadModal";

function CropPrediction() {
  const { t } = useTranslation();
  const [open, setOpen] = useOutletContext();
  const dispatch = useDispatch();

  const wrap = useRef();

  const local = JSON.parse(localStorage?.getItem("selectedCountry"));

  const [startYear, setStartYear] = React.useState("");
  const [endYear, setEndyear] = React.useState("");
  const [trainingMaxDate, setTrainingMaxDate] = useState();

  const [selectedModal, setSelectedModal] = useState("");
  const [geoJsonKeys, setGeoJsonKeys] = useState("");
  const [districtBoundry, setDistricyBoundry] = useState("");
  const [districtJsonData, setDistrictJsonData] = useState("");

  const [position, setPosition] = useState([local?.latitude, local?.longitude]);
  const [zooms, setZooms] = useState(local?.zoom_level);

  const splitterRef = useRef();

  const layerRef = useRef([]);
  const divRef = useRef();

  const params = new URLSearchParams(window.location.search);
  const selectedModule = params.get("slug");

  const [openPanel, setOpenPanel] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [layerData, setLayerData] = useState("");

  const [legend, setLegend] = useState([]);
  const [selectedMapPreferLegendData, setSelectedMapPreferLegendData] =
    useState([]);
  const [selectedCSEndYear, setSelectedCSEndYear] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [seasonsList, setSeasonsList] = useState([]);
  const [regionListOptions, setRegionListOptions] = useState([]);
  const [districtsListOptions, setDistrictsListOptions] = useState([]);
  const [cropData, setCropData] = useState({ cropList: [] });
  const [selectedCrop, setSelectedCrop] = useState({
    crop: null,
  });
  const [geoJsonData, setGeoJsonData] = useState([]);

  const clickEventData = useRef(false);
  const predectionDataLoadingRef = useRef(false);
  const [predictState, setPredictstate] = useState(false);
  const [endDateRainfall, setEndDateRainfall] = useState("");

  const [mapData, setMapData] = useState("");
  const [selectedMap, setSelectedmap] = useState("");
  const [scrollableYear, setScrollableyear] = useState({
    yearList: [],
    selectedYear: "",
  });
  const [graphData, setGraphData] = useState({
    xaxis: [],
    yaxisActual: [],
    yaxisPredection: [],
  });
  const [currentGraphData, setcurrentGraphData] = useState({
    xaxis: [],
    yaxisPredection: [],
  });
  const [loaderlatLng, setLoaderLatLng] = useState({
    lat: "",
    lng: "",
  });

  const [yearGraphData, setYearGraphData] = useState({
    xaxis: [],
    yaxisActual: [],
    yaxisPredection: [],
  });

  const [modalperformance, setModalPerformance] = useState({
    rsq: "",
    rmse: "",
    cropYieldPredection: "",
    avgActual: "",
    avgPredict: "",
    highActual: "",
    highPredict: "",
    lowActulal: "",
    lowPredict: "",
    modal: "",
    startYear: "",
    endYear: "",
    trainingYears: "",
    predictionyears: "",
  });

  const [showChart, setShowChart] = useState(false);
  const [graphPanelHeight, setPlneHeight] = useState(0);
  const [chartLables, setChartLables] = useState({});
  const [cyModels, setCyModels] = useState(ModalsConst);
  const [adminRadio, setAdminRadio] = useState("admin");
  const [trainingDataEndDateGraph, setTrainingDataEndDateGraph] = useState({
    text: "",
    value: "",
  });
  const [mapPreferences, setMapPreferences] = useState({
    country: { layer: useRef(), color: "", data: "", country: "" },
    regions: { layer: useRef(), color: "", data: "", country: "" },
    districts_or_cities: { layer: useRef(), color: "", data: "", country: "" },

    discharge_station: { layer: useRef(), color: "", data: "", country: "" },
    basins: { layer: useRef(), color: "", data: "", country: "" },
    sub_basins: { layer: useRef(), color: "", data: "", country: "" },
    rivers_or_reservoirs: { layer: useRef(), color: "", data: "", country: "" },
    automatic_weather_station: {
      layer: useRef(),
      color: "",
      data: "",
      country: "",
    },
    talukas: { layer: useRef(), color: "", data: "", country: "" },
    major_rivers: { layer: useRef(), color: "", data: "", country: "" },
    minor_rivers: { layer: useRef(), color: "", data: "", country: "" },
    hydro_stations: { layer: useRef(), color: "", data: "", country: "" },
    meteorological_locations: {
      layer: useRef(),
      color: "",
      data: "",
      country: "",
    },
  });

  const [mapPreferencesData, setMapPreferencesData] = useState([]);

  const [mapPreferRefs] = useState([]);
  const [indexLayers, setIndexLayers] = useState([]);

  const [geoJsonAllData, setGeoJsonAllData] = useState([]);

  const [geoJsonLayersData, setGeoJsonLayerData] = useState([]);

  const [value, setValue] = useState(0); // USing for tab selection
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [cropCalender, setCropCalender] = useState("");
  const [selectedCropCalender, setSelectedCropCalender] = useState("");
  const [isUploadFilesOpen, setIsUploadFilesOpen] = useState(false);
  const [predictionFile, setPredictionFile] = useState([]);
  const [originalPredictionFile, setOriginalPredictionFile] = useState([]);
  const [trainingDataYear, setTrainingDataYear] = useState();
  const [trainingDataStartYear, setTrainingDataStartYear] = useState();

  const [havePredictionData, setHavePredictionData] = useState({
    label: "No",
    value: "No",
  });

  const selectedRegions = [];
  const selectedDistricts = [
    {
      label: t("DASHBOARD.ALL_DISTRICTS"),
      value: "",
      latitude: selectedLocation?.state?.latitude,
      longitude: selectedLocation?.state?.longitude,
      zoom_level: selectedLocation?.state?.zoom_level,
    },
  ];
  const nextProps = useSelector((state) => ({
    countryList: state.Core.coreList,
    mapPreferenceList: state.MapPreference,
    statesList: state.Core.corestateList,
    districtsList: state.Core.coredistrictList,
    cropsList: state.CropYield?.cpCropList,
    cropsPastList: state.CropYield?.pastDataList,
    cropPredectionData: state.CropYield?.cropPredectionData,
    indicator: state.Dashboard.indicatorList,
    postFeedbackData: state.CropYield?.crpPostFeedback,
    postErrorFeedbackData: state.CropYield?.crpPostFeedbackError,

    predectionLoading: state.CropYield?.predectionLoading,
    countryBoundry: state.Boundry?.countryBoundry,
    stateBoundry: state?.Boundry?.stateBoundry,
    taluksBoundry: state?.Boundry?.taluksBoundry,
    cropCalender: state.CropYield?.getCropCalenderData,
  }));

  useEffect(() => {
    if (nextProps?.predectionLoading) {
      predectionDataLoadingRef.current = true;
    } else {
      predectionDataLoadingRef.current = false;
    }
  }, [nextProps?.predectionLoading]);
  const findIndicesBySlug = (indicesSlug, data) => {
    return data?.flatMap((category) => {
      const indices = category?.indices?.find(
        (index) => index.indices_slug === indicesSlug,
      );
      return indices
        ? {
            category_name: category.category,
            ...indices,
          }
        : [];
    });
  };
  const isIndicatorList = useRef(true);
  useEffect(() => {
    if (isIndicatorList.current) {
      isIndicatorList.current = false;
      return;
    }
    if (nextProps?.indicator) {
      const result = findIndicesBySlug(AccRainfallGpm, nextProps?.indicator);

      const endDateConditionCheck = onhandleEndDate(result, ModalsConst);

      if (result?.length) {
        setTrainingMaxDate(endDateConditionCheck?.endyear - 2);
        setEndDateRainfall(
          moment(`${endDateConditionCheck?.endyear}-01-01`).format(
            "YYYY-MM-DD",
          ),
        );
      } else {
        setEndDateRainfall("");
        setTrainingMaxDate("");
      }
    }
  }, [nextProps.indicator]);

  useEffect(() => {
    if (
      nextProps?.mapPreferenceList &&
      nextProps?.mapPreferenceList?.mapPreferenceList?.length
    ) {
      const API_URL = [];
      mapPreferRefs.current = [];
      nextProps?.mapPreferenceList?.mapPreferenceList?.map((item) => {
        if (item?.layers && item?.layers.length) {
          item.layers.map((boundary) => {
            if (boundary?.json_url.length) {
              setGeoJsonData([]);
              setGeoJsonAllData([]);
              const mapPreferKeys = Object.keys(mapPreferences);
              if (mapPreferKeys) {
                mapPreferKeys.map((EachKey) => {
                  const selectedLayer = mapPreferences[EachKey];
                  if (selectedLayer?.layer && selectedLayer?.layer?.current)
                    selectedLayer?.layer?.current?.clearLayers();
                  selectedLayer.color = "";
                  setMapPreferences((prev) => {
                    return {
                      ...prev,
                      [EachKey]: selectedLayer,
                    };
                  });
                  return null;
                });
              }
              // setMapPreferenceGeoJsonloading(true);

              const config = {
                name: boundary?.slug,
                id: boundary?.id,
                color: boundary?.color_code,
                category: boundary?.map_preference_category_name,
                country_id: local.value,
              };

              boundary?.map_preference_category_name !== "Admin" &&
                API_URL.push(
                  AXIOS_INSTANCE_LOADER.get(boundary?.json_url, config),
                );
            } else {
              const ref1 = React.createRef();
              ref1.current = {
                boundary_id: boundary?.id,
                layer: React.createRef(),
              };
              mapPreferRefs.current.push(ref1);
            }

            return null;
          });
        }
        return null;
      });

      if (API_URL.length !== 0) {
        const errorMsg = [];
        const finalResponse = [];
        Promise.allSettled(API_URL).then((response) => {
          // setMapPreferenceGeoJsonloading(false);
          const res = JSON.parse(JSON.stringify(response));
          res?.map((item) => {
            if (item?.status === Fulfilled) {
              finalResponse.push(item?.value);
            } else if (item?.status === Rejected) {
              const errorResponse = item?.reason?.response;
              if (errorResponse?.status === 400) {
                if (errorResponse?.data?.code === 400) {
                  errorMsg.push(errorResponse?.data?.message);
                }
              }
            }
            return null;
          });
          setGeoJsonLayerData(finalResponse);
        });
      }
    }
  }, [nextProps?.mapPreferenceList?.mapPreferenceList]);

  const onHandleZoom = (center, zoom) => {
    setPosition(center);
    setZooms(zoom);
  };

  useEffect(() => {
    if (endYear) {
      const currentyear = new Date().getFullYear();
      setCyModels([]);
      if (currentyear === endYear) {
        const currentMonth = new Date().getMonth();
        const filtermodal = ModalsConst?.filter(
          (each) => each?.months < currentMonth,
        );

        setCyModels(filtermodal);
        setSelectedModal("");
      } else {
        setCyModels(ModalsConst);
      }
    }
  }, [endYear]);

  // Re-setting location whenever admin/Basin selection changes
  useEffect(() => {
    const CountryData = selectedLocation?.country;
    if (CountryData) {
      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: CountryData,
          state: "",
          district: "",
          basin: "",
          subBasin: "",
        };
      });
    }

    setShowChart(false); // close chart if open
    // setCompare(false);
  }, [adminRadio]);

  useEffect(() => {
    if (!showChart) {
      setChartLables((prev) => {
        return {
          ...prev,
          title: "",
          yaxisLable: "",
          legend: "",
        };
      });
    }
  }, [showChart]);
  // for getting the map layer url from api
  useEffect(() => {
    if (nextProps.maps) {
      setLayerData(nextProps.maps);
      setLegend([]);
    }
  }, [nextProps.maps]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setOpen(true);

    const ref2 = React.createRef();
    layerRef.current.push(ref2);
    const country = JSON.parse(localStorage.getItem("selectedCountry"));
    dispatch(
      coreRequest({
        location_type: "country",
        menu_slug: selectedModule,
      }),
    );

    setSelectedLocation((prev) => {
      return {
        ...prev,
        country,
        state: "",
        district: "",
      };
    });
    setShowChart(false);
  }, []);
  //  for country list
  const isCountryList = useRef(true);
  useEffect(() => {
    if (isCountryList.current) {
      isCountryList.current = false;
      return;
    }
    if (nextProps?.countryList) {
      const countryarray = [];
      renderReactOptionsArraystate(
        nextProps?.countryList,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
        "code",
        false,
        null,
        null,
        null,
        null,
        null,
        null,
        "basin_feature_collection",
      )?.map((eachValue) => countryarray.push(eachValue));
      setCountryList(countryarray);
    }
  }, [nextProps?.countryList]);

  //  for country list
  const isCropCalender = useRef(true);
  useEffect(() => {
    if (isCropCalender.current) {
      isCropCalender.current = false;
      return;
    }
    if (nextProps?.cropCalender) {
      setCropCalender(nextProps?.cropCalender);
    }
  }, [nextProps?.cropCalender]);

  useEffect(() => {
    if (splitterRef && splitterRef.current) {
      if (showChart) {
        splitterRef.current.setState({ secondaryPaneSize: 55 });
      } else {
        splitterRef.current.setState({ secondaryPaneSize: 0 });
      }
    }
  }, [showChart]);
  const isCropyieldList = useRef(true);
  useEffect(() => {
    if (isCropyieldList.current) {
      isCropyieldList.current = false;
      return;
    }
    if (
      nextProps?.cropPredectionData?.historical?.graph_data?.length ||
      nextProps?.cropPredectionData?.predicted?.graph_data?.length
    ) {
      if (endYear) {
        const yearlist = Array.from(
          { length: endYear - startYear + 1 },
          // eslint-disable-next-line no-shadow
          (_, index) => endYear - index,
        );

        setScrollableyear({
          yearList: yearlist,
          selectedYear: yearlist[0],
        });
        const mapList4 = getMapSortedData(
          nextProps?.cropPredectionData?.historical,
          nextProps?.cropPredectionData?.predicted,
        );

        const graphSegratedData = onHandleCropPredectionGraph(
          mapList4?.historicGraphData,
          selectedLocation,
          false,
        );

        const graphSegratedData1 = onHandleCropPredectionGraph(
          mapList4?.currentGraphData,
          selectedLocation,
          true,
        );
        const cypTableData = onHandleCropPredectionAnalytics(
          mapList4?.currentGraphData,
        );
        if (havePredictionData?.value === "Yes") {
          setTrainingDataEndDateGraph({
            text: havePredictionData?.value,
            value: trainingDataStartYear,
          });
        } else {
          setTrainingDataEndDateGraph({
            text: "",
            value: "",
          });
        }

        setModalPerformance((prev) => ({
          ...prev,
          rsq: cypTableData?.rsq,
          rmse: cypTableData?.rmse,
          cropYieldPredection: cypTableData?.PredictedYield,

          avgActual: {
            value: cypTableData?.avgActual,
            Year: scrollableYear?.selectedYear,
          },
          avgPredict: {
            value: cypTableData?.avgPredicted,
            Year: scrollableYear?.selectedYear,
          },
          highActual: cypTableData?.maxActual,
          highPredict: cypTableData?.maxPredicted,
          lowActulal: cypTableData?.minActual,
          lowPredict: cypTableData?.minPredicted,
          modal: selectedModal,
          startYear,
          endYear,
          trainingYears: nextProps?.cropPredectionData?.model_training_years,
          predictionyears: [startYear, endYear],
        }));

        if (graphSegratedData.xAxis?.length) {
          setGraphData((prev) => ({
            ...prev,
            xaxis: graphSegratedData.xAxis,
            yaxisActual: graphSegratedData.YAxisActual,
            yaxisPredection: graphSegratedData.YAxisPredection,
          }));
        } else {
          setGraphData((prev) => ({
            ...prev,
            xaxis: [],
            yaxisActual: [],
            yaxisPredection: [],
          }));
        }
        if (graphSegratedData1.xAxis?.length) {
          setcurrentGraphData((prev) => ({
            ...prev,
            xaxis: graphSegratedData1.xAxis,
            yaxisPredection: graphSegratedData1.YAxisPredection,
          }));
        } else {
          setcurrentGraphData((prev) => ({
            ...prev,
            xaxis: [],
            yaxisPredection: [],
          }));
        }
        // }
        setValue(0); // seting to defalt tab
        setMapData(mapList4?.predictedMap);
        setShowChart(true);
      } else {
        setScrollableyear({
          yearList: [],
          selectedYear: startYear,
        });
      }
    } else {
      setMapData("");
    }
  }, [nextProps?.cropPredectionData]);

  //  setting map and graph data
  const isCropYielddata = useRef(true);
  useEffect(() => {
    if (isCropYielddata.current) {
      isCropYielddata.current = false;
      return;
    }
    if (nextProps?.cropsList) {
      const crop = nextProps?.cropsList?.map((obj) => ({
        ...obj,
        value: obj?.name,
        label: obj?.name,
      }));

      setCropData({
        cropList: crop,
      });
    }
  }, [nextProps?.cropsList]);

  useEffect(() => {
    if (selectedLocation?.country) {
      const selectedObject1 = GEOJSON_COUNTRY_DATA?.find(
        (item) => item.selectedCountry === selectedLocation?.country?.label,
      );
      setGeoJsonKeys(selectedObject1);
      setShowChart(false);
      setCropData();
      setSelectedCrop((prev) => ({
        ...prev,
        crop: null,
      }));
      setSeasonsList([]);

      setStartYear("");
      setEndyear("");

      setScrollableyear({
        yearList: [],
        selectedYear: "",
      });

      dispatch(
        mapPreferenceRequest({
          country_id: selectedLocation?.country?.value,
          module: selectedModule,
        }),
      );

      // for removing the district layer when country is changed
      const selectedLayer = mapPreferences.districts_or_cities;
      selectedLayer?.layer?.current?.clearLayers();
      selectedLayer.country = selectedLocation?.country;

      dispatch(
        indicatorRequest({
          sub_menu_id: monitorIndicatorId,
          countryCode: selectedLocation?.country?.code,
        }),
      );

      dispatch(
        cropYieldCropListRequest({
          country_id: selectedLocation?.country?.value,
        }),
      );

      dispatch(
        corestateRequest({
          data: {
            parent_id: selectedLocation?.country?.value,
            location_type: State,
          },
          isCompareLocation: false,
        }),
      );

      dispatch(
        getCropCalenderRequest({
          country_id: selectedLocation?.country?.value,
        }),
      );
    }
  }, [selectedLocation?.country]);
  useEffect(() => {
    if (selectedLocation?.state && selectedLocation?.state?.value !== "") {
      setShowChart(false);
      dispatch(
        coredistrictRequest({
          requestObj: {
            parent_id: selectedLocation?.state?.value,
            location_type: District,
          },
          isCompareLocation: false,
        }),
      );
    } else {
      setShowChart(false);
      setDistrictsListOptions([]);
    }
  }, [selectedLocation?.state]);

  // Feedback related
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (nextProps?.postFeedbackData) {
      setIsSuccessModalOpen(!isSuccessModalOpen);
    } else if (nextProps.postErrorFeedbackData) {
      const errorMessage =
        nextProps.postErrorFeedbackData?.message?.[0] ||
        nextProps.postErrorFeedbackData?.errors ||
        t("LOGIN.SOMETHING_WENT_WRONG");
      showError(errorMessage);
    }
  }, [nextProps?.postFeedbackData, nextProps.postErrorFeedbackData]);

  const onHandleRadioBtn = () => {
    setSelectedCSEndYear(endYear);
    setShowChart(false);
    setMapData("");
    setSelectedmap("");

    setScrollableyear({
      yearList: [],
      selectedYear: "",
    });

    setOpen(false);
    const form = new FormData();
    form.append("country_name", selectedLocation?.country?.label);
    form.append("state_name", selectedLocation?.state?.label);
    form.append("crop_name", selectedCrop?.crop?.value);
    form.append("run_type", "Multiyear");
    form.append("model_input", selectedModal?.Modalvalue);
    form.append("start_year", startYear.toString());
    form.append("end_year", endYear.toString());
    if (
      selectedLocation?.district?.value !== "" &&
      selectedLocation?.district !== ""
    ) {
      setScrollableyear({
        yearList: [],
        selectedYear: "",
      });

      form.append("district_name", selectedLocation?.district?.label);
      form.append("run_type", "Single");
    }

    if (originalPredictionFile?.length && havePredictionData?.value === "Yes") {
      form.append("file", originalPredictionFile[0]);
    }

    dispatch(cropYieldPredectionRequest(form));
    setValue(0);
  };

  const selectedCountryData1 = GEOJSON_COUNTRY_DATA?.find(
    (item) => item.selectedCountry === selectedLocation?.country?.label,
  );

  const removeLayers = (selectedParamRef) => {
    if (
      wrap &&
      wrap?.current &&
      selectedParamRef &&
      selectedParamRef?.current
    ) {
      const map1 = wrap.current;

      const firstLayer = selectedParamRef.current;

      [firstLayer].forEach((layer) => map1.removeLayer(layer));
    }
  };

  const removeMapPreferenceLayer = (mapPreferRefs1, mapPreferencesData1) => {
    if (mapPreferRefs1?.current?.length && mapPreferencesData1?.length) {
      mapPreferencesData1?.map((item) => {
        const selectedMapIndex = _.findIndex(
          mapPreferRefs1.current,
          (list) => list.current.boundary_id === item.id,
        );

        const previousParamRef =
          mapPreferRefs1?.current[selectedMapIndex]?.current.layer;

        if (previousParamRef && previousParamRef?.current) {
          removeLayers(previousParamRef);
        }
        return null;
      });
    }
  };

  const removeMapLayers = (selectedParamLegendData) => {
    let previousParamRef = "";
    if (layerRef?.current?.length) {
      layerRef.current.map((i, index) => {
        previousParamRef = layerRef.current[index];
        removeLayers(previousParamRef);
        return null;
      });
    }

    const legendArray = [...selectedParamLegendData];
    if (selectedParamLegendData.length) {
      // eslint-disable-next-line no-shadow
      legendArray.forEach((value) => {
        if (value.add) {
          value.add = false;
        }
      });
    }
    setLegend(legendArray);
  };

  const onResetboundry = () => {
    const mapPreferKeys = Object.keys(mapPreferences);
    if (mapPreferKeys) {
      mapPreferKeys.map((EachKey) => {
        const selectedLayer = mapPreferences[EachKey];
        if (selectedLayer?.layer && selectedLayer?.layer?.current)
          selectedLayer?.layer?.current?.clearLayers();
        selectedLayer.color = "";
        setMapPreferences((prev) => {
          return {
            ...prev,
            [EachKey]: selectedLayer,
          };
        });
        return null;
      });
    }

    setGeoJsonData([]);
    setGeoJsonAllData([]);
  };

  const onHandleResetData = () => {
    setOriginalPredictionFile([]);
    setPredictionFile([]);
    removeMapLayers(legend);

    onResetboundry();

    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setMapPreferencesData([]);
    const selectedLegend = JSON.parse(
      JSON.stringify(selectedMapPreferLegendData),
    );
    // for legend addpled to map-preferences
    if (selectedLegend.length) {
      selectedLegend.forEach(function mappreference(value1) {
        if (value1.add) {
          value1.add = false;
        }
      });
      setSelectedMapPreferLegendData(selectedLegend);
    }

    setSelectedLocation((prev) => {
      return {
        ...prev,
        country: selectedLocation?.country,
        state: "",
        district: "",
        basin: "",
        subBasin: "",
      };
    });

    setModalPerformance((prev) => {
      return {
        ...prev,
        rsq: "",
        rmse: "",
        cropYieldPredection: "",
        avgActual: "",
        avgPredict: "",
        highActual: "",
        highPredict: "",
        lowActulal: "",
        lowPredict: "",
        modal: "",
        startYear,
        endYear,
        trainingYears: "",
        predictionyears: "",
      };
    });

    setScrollableyear({
      yearList: [],
      selectedYear: "",
    });
    setSelectedModal("");
    setSelectedCrop((prev) => ({
      ...prev,
      crop: null,
    }));
    setStartYear("");
    setEndyear("");
    setMapData("");
    setDistrictJsonData("");
    setDistricyBoundry("");
  };

  useEffect(() => {
    if (
      districtJsonData &&
      selectedCrop?.crop?.value &&
      selectedModal?.Modalvalue &&
      startYear &&
      endYear
    ) {
      const selectedCountryData = GEOJSON_COUNTRY_DATA?.find(
        (item) => item.selectedCountry === selectedLocation?.country?.label,
      );

      setOpen(false);
      const selectedDistrict = districtsListOptions?.find(
        (each) =>
          each?.label ===
          districtJsonData?.properties[
            `${selectedCountryData?.districts_or_cities}`
          ],
      );

      setSelectedLocation((prev) => {
        return {
          ...prev,
          district: selectedDistrict,
        };
      });

      const form = new FormData();
      form.append("country_name", selectedLocation?.country?.label);
      form.append("state_name", selectedLocation?.state?.label);
      form.append("district_name", selectedDistrict?.label);
      form.append("crop_name", selectedCrop?.crop?.value);
      form.append("run_type", "Single");
      form.append("model_input", selectedModal?.Modalvalue);
      form.append("start_year", startYear.toString());
      form.append("end_year", endYear.toString());

      if (originalPredictionFile?.length) {
        form.append("file", originalPredictionFile[0]);
      }

      dispatch(cropYieldPredectionRequest(form));
    }
  }, [districtJsonData]);

  useEffect(() => {
    if (!predictionFile?.length) {
      setOriginalPredictionFile([]);
    }
  }, [predictionFile]);
  useEffect(() => {
    if (havePredictionData?.value) {
      setOriginalPredictionFile([]);
      setPredictionFile([]);
      setStartYear("");
      setEndyear("");
    }
  }, [havePredictionData]);

  useEffect(() => {
    if (nextProps?.predectionLoading) {
      removeMapLayers(legend);
      setGraphData((prev) => ({
        ...prev,
        xaxis: [],
        yaxisActual: [],
        yaxisPredection: [],
      }));
      setcurrentGraphData((prev) => ({
        ...prev,
        xaxis: [],
        yaxisPredection: [],
      }));
    }
  }, [nextProps?.predectionLoading]);

  useEffect(() => {
    if (
      selectedCrop?.crop &&
      selectedModal?.Modalvalue &&
      startYear &&
      endYear &&
      selectedLocation?.state !== "" &&
      selectedLocation?.state?.value !== "" &&
      selectedLocation?.district !== ""
    ) {
      clickEventData.current = true;
      setPredictstate(true);
    } else {
      clickEventData.current = false;
      setPredictstate(false);
    }
  }, [
    selectedCrop?.crop,
    selectedModal,
    startYear,
    selectedLocation?.district,
    selectedLocation?.state,
    endYear,
  ]);

  const checkPhase = (month, phases) =>
    Object.keys(phases).find(
      (phase) => month >= phases[phase][0] && month < phases[phase][1],
    ) || "Not in any phase";

  useEffect(() => {
    if (selectedCrop) {
      Object.entries(cropCalender).map(([crop, stages]) => {
        if (crop === selectedCrop?.crop?.slug) {
          const currentMonth = moment().month();

          const phase = checkPhase(currentMonth, stages);

          const selectedIcon = CropStages?.find((each) => each?.slug === phase);
          setSelectedCropCalender(selectedIcon);
        }
        return null;
      });
    }
  }, [selectedCrop]);

  useEffect(() => {
    if (selectedLocation?.district?.value === "") {
      setDistrictJsonData("");
    }
  }, [selectedLocation?.district]);

  const isCountryBoundry = useRef(true);
  useEffect(() => {
    if (isCountryBoundry.current) {
      isCountryBoundry.current = false;
      return;
    }
    if (nextProps.countryBoundry) {
      // && adminRadio === "admin"
      const isCountrySelected = geoJsonData.find(
        (each) => each.name === "country",
      );
      if (isCountrySelected) {
        const selectedLayer = mapPreferences.country;
        selectedLayer.country = selectedLocation?.country;
        if (
          nextProps.countryBoundry?.data?.features?.[0]?.properties[
            `${selectedCountryData1?.country}`
          ] === selectedLocation?.country?.label
        ) {
          selectedLayer.layer.current.addData(nextProps.countryBoundry);
          selectedLayer.color = "#040404";
        }
      }
    }
  }, [nextProps.countryBoundry, adminRadio]);

  const isStateBoundry = useRef(true);
  useEffect(() => {
    if (isStateBoundry.current) {
      isStateBoundry.current = false;
      return;
    }
    if (nextProps.stateBoundry?.data) {
      // adminRadio === "admin"
      if (nextProps.stateBoundry?.isState) {
        // for state boundry
        const isRegionsSelected = geoJsonData.find(
          (each) => each.name === "regions",
        );
        if (isRegionsSelected) {
          const selectedLayer = mapPreferences.regions;
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.country = selectedLocation?.country;
          if (
            nextProps.stateBoundry?.data?.features?.[0]?.properties[
              `${selectedCountryData1?.country}`
            ] === selectedLocation?.country?.label
          ) {
            selectedLayer.layer.current.addData(nextProps.stateBoundry?.data);

            selectedLayer.color = "#121211";
          }
        }
      } else {
        // for district boundry  districts_or_cities
        const isDistrictSelected = geoJsonData.find(
          (each) => each.name === "districts_or_cities",
        );
        if (isDistrictSelected) {
          const selectedLayer = mapPreferences.districts_or_cities;
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.country = selectedLocation?.country;
          if (
            nextProps.stateBoundry?.data?.features?.[0]?.properties[
              `${selectedCountryData1?.country}`
            ] === selectedLocation?.country?.label
          ) {
            selectedLayer.layer.current.addData(nextProps.stateBoundry?.data);
            selectedLayer.color = "#36373A";
          }
        }
      }
    }
  }, [nextProps.stateBoundry, adminRadio]);

  const isTaluksBoundry = useRef(true);
  useEffect(() => {
    if (isTaluksBoundry.current) {
      isTaluksBoundry.current = false;
      return;
    }
    if (nextProps.taluksBoundry?.data) {
      const selectedLayer = mapPreferences.talukas;
      selectedLayer?.layer?.current?.clearLayers();
      selectedLayer.country = selectedLocation?.country;
      if (
        nextProps.taluksBoundry?.data?.features?.[0]?.properties[
          `${selectedCountryData1?.country}`
        ] === selectedLocation?.country?.label
      ) {
        selectedLayer.layer.current.addData(nextProps.taluksBoundry?.data);
        selectedLayer.color = "#964B00";
      }
    }
  }, [nextProps.taluksBoundry, adminRadio]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        const mapGeojsonItem = Object.keys(mapPreferences).find(
          (x) => x === item.config.name,
        );

        const selectedLayer = mapPreferences[mapGeojsonItem];

        if (selectedLayer?.layer && selectedLayer?.layer?.current) {
          selectedLayer.country = "";
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.color = "";
          setMapPreferences((prev) => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }
        return null;
      });

      setGeoJsonData([]);
      setGeoJsonAllData([]);
      setGeoJsonLayerData([]);
    }
  }, [selectedLocation?.country]);

  useEffect(() => {
    if (selectedLocation !== "") {
      if (
        selectedLocation?.district !== "" &&
        selectedLocation?.district?.value !== ""
      ) {
        setLoaderLatLng({
          lat: selectedLocation?.district.latitude,
          lng: selectedLocation?.district.longitude,
        });
      } else if (
        selectedLocation?.state !== "" &&
        selectedLocation?.state?.value !== ""
      ) {
        setLoaderLatLng({
          lat: selectedLocation?.state.latitude,
          lng: selectedLocation?.state.longitude,
        });
      } else {
        setLoaderLatLng({
          lat: selectedLocation?.country.latitude,
          lng: selectedLocation?.country.longitude,
        });
      }
    }
  }, [selectedLocation]);

  const uploadedFiles = [];
  const onHandleUploadPredictionFile = (file, originalFile) => {
    // Add the object to the uploadedFiles array
    uploadedFiles.push(file);
    if (uploadedFiles?.length) {
      setPredictionFile([...predictionFile, ...uploadedFiles]);
      setOriginalPredictionFile([originalFile]);
    }
  };

  return (
    <div className="dashboard-main-page common-dashboard-ftr">
      {nextProps?.loading && <Loader isSuspense indicator />}
      {openFeedbackModal ? (
        <FeedbackModal
          toggle={setOpenFeedbackModal}
          modal={openFeedbackModal}
          selectedLocation={selectedLocation}
          selectedCrop={selectedCrop}
          startYear={startYear}
          endYear={endYear}
          selectedModal={selectedModal}
          selectedModule={selectedModule}
        />
      ) : (
        ""
      )}
      {isSuccessModalOpen ? (
        <FeedbackSuccessModal
          toggle={setIsSuccessModalOpen}
          modal={isSuccessModalOpen}
          setOpenFeedbackModal={setOpenFeedbackModal}
          openFeedbackModal={openFeedbackModal}
        />
      ) : (
        ""
      )}
      {isUploadFilesOpen ? (
        <PredictionDataUploadModal
          toggle={setIsUploadFilesOpen}
          modal={isUploadFilesOpen}
          onHandleUploadImage={onHandleUploadPredictionFile}
          selectedLocation={selectedLocation}
        />
      ) : null}
      <div className="dash-main-row">
        <div className="dashboard-drought-crop-yield">
          <div className="dashboard-filter-div">
            <div>
              <div className="dashboard-filter">
                <h3 className="dashboard-filter-h3">
                  {t("CROP_YIELD.CROP_YIELD_ASSESMENT")}
                </h3>
              </div>
            </div>
            <Row className="dashboard-drought-section">
              <Col
                lg={3}
                className={
                  openPanel
                    ? "dasboard-drought-expand-1"
                    : "dashboard-drought-col1"
                }
              >
                <Button
                  className={
                    !openPanel
                      ? "button-expand-panel"
                      : "button-expand-panel-oepn"
                  }
                  onClick={() => setOpenPanel(!openPanel)}
                  aria-controls="example-collapse-text"
                  aria-expanded={openPanel}
                >
                  {openPanel ? (
                    <div className="panel-btn cy-expand-bar">
                      <span className="mr-2">Expand Bar</span>
                      <FaAngleRight className="right-icon" />
                    </div>
                  ) : (
                    <div className="tooltip-div">
                      <span className="mr-2 tooltip-text">Hide Bar</span>
                      <FaAngleLeft className="right-icon right-icon-md float-right" />
                    </div>
                  )}
                </Button>
                <div className={openPanel ? "d-none" : "dashboard-drought-div"}>
                  <CropYieldFilter
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    countryList={countryList}
                    onHandleZoom={onHandleZoom}
                    statesList={nextProps.statesList}
                    selectedRegions={selectedRegions}
                    regionListOptions={regionListOptions}
                    setRegionListOptions={setRegionListOptions}
                    districtsList={nextProps.districtsList}
                    selectedDistricts={selectedDistricts}
                    districtsListOptions={districtsListOptions}
                    setDistrictsListOptions={setDistrictsListOptions}
                    cropData={cropData}
                    setSelectedCrop={setSelectedCrop}
                    selectedCrop={selectedCrop}
                    setCropData={setCropData}
                    openPanel={openPanel}
                    seasonsList={seasonsList}
                    onHandleRadioBtn={onHandleRadioBtn}
                    onHandleResetData={onHandleResetData}
                    startYear={startYear}
                    setStartYear={setStartYear}
                    endYear={endYear}
                    setEndyear={setEndyear}
                    selectedModal={selectedModal}
                    setSelectedModal={setSelectedModal}
                    setCyModels={setCyModels}
                    cyModels={cyModels}
                    clickEventData={clickEventData}
                    predictState={predictState}
                    districtBoundry={districtBoundry}
                    geoJsonKeys={geoJsonKeys}
                    setDistrictJsonData={setDistrictJsonData}
                    setScrollableyear={setScrollableyear}
                    scrollableYear={scrollableYear}
                    setDistricyBoundry={setDistricyBoundry}
                    endDateRainfall={endDateRainfall}
                    selectedMap={selectedMap}
                    setAdminRadio={setAdminRadio}
                    adminRadio={adminRadio}
                    setMapPreferencesData={setMapPreferencesData}
                    mapPreferencesData={mapPreferencesData}
                    loading={nextProps?.predectionLoading}
                    setSelectedCSEndYear={setSelectedCSEndYear}
                    isUploadFilesOpen={isUploadFilesOpen}
                    setIsUploadFilesOpen={setIsUploadFilesOpen}
                    predictionFile={predictionFile}
                    setPredictionFile={setPredictionFile}
                    setTrainingDataYear={setTrainingDataYear}
                    trainingDataYear={trainingDataYear}
                    setHavePredictionData={setHavePredictionData}
                    havePredictionData={havePredictionData}
                    setOriginalPredictionFile={setOriginalPredictionFile}
                    setTrainingDataStartYear={setTrainingDataStartYear}
                    trainingDataStartYear={trainingDataStartYear}
                    trainingMaxDate={trainingMaxDate}
                  />
                </div>
              </Col>
              <Col
                lg={openPanel ? 12 : 9}
                className={openPanel ? "px-0" : "px-0 dashboard-drought-map"}
              >
                <div
                  className={
                    showChart
                      ? "map-space-div visible-splitter"
                      : "map-space-div invisible-splitter"
                  }
                >
                  <SplitterLayout
                    ref={splitterRef}
                    primaryIndex={0}
                    vertical
                    percentage
                    secondaryInitialSize={0}
                    primaryMinSize={40}
                    onSecondaryPaneSizeChange={(ratio) => {
                      setPlneHeight(ratio);
                    }}
                  >
                    <div
                      className={
                        showChart
                          ? "dashboard-drought-col-graph-view"
                          : "dashboard-drought-col2"
                      }
                      ref={divRef}
                    >
                      <MapContainer
                        onHandleZoom={onHandleZoom}
                        position={position}
                        zooms={zooms}
                        layerRef={layerRef}
                        layerData={layerData}
                        maps={nextProps.maps}
                        legend={legend}
                        setLegend={setLegend}
                        selectedLocation={selectedLocation}
                        setMapPreferences={setMapPreferences}
                        mapPreferences={mapPreferences}
                        selectedCrop={selectedCrop}
                        setShowChart={setShowChart}
                        geoJsonKeys={geoJsonKeys}
                        setGeoJsonKeys={setGeoJsonKeys}
                        setCyModels={setCyModels}
                        cyModels={cyModels}
                        setMapData={setMapData}
                        mapData={mapData}
                        districtJsonData={districtJsonData}
                        setDistrictJsonData={setDistrictJsonData}
                        startYear={startYear}
                        endYear={endYear}
                        selectedModal={selectedModal}
                        clickEventData={clickEventData}
                        selectedMap={selectedMap}
                        scrollableYear={scrollableYear}
                        setScrollableyear={setScrollableyear}
                        setSelectedmap={setSelectedmap}
                        setEndyear={setEndyear}
                        setDistricyBoundry={setDistricyBoundry}
                        districtBoundry={districtBoundry}
                        open={open}
                        openPanel={openPanel}
                        setModalPerformance={setModalPerformance}
                        graphData={graphData}
                        setGraphData={setGraphData}
                        setYearGraphData={setYearGraphData}
                        showChart={showChart}
                        setGeoJsonData={setGeoJsonData}
                        geoJsonData={geoJsonData}
                        mapPreferRefs={mapPreferRefs}
                        geoJsonAllData={geoJsonAllData}
                        geoJsonLayersData={geoJsonLayersData}
                        setGeoJsonAllData={setGeoJsonAllData}
                        setGeoJsonLayerData={setGeoJsonLayerData}
                        setOpen={setOpen}
                        setMapPreferencesData={setMapPreferencesData}
                        mapPreferencesData={mapPreferencesData}
                        adminRadio={adminRadio}
                        removeMapPreferenceLayer={removeMapPreferenceLayer}
                        removeMapLayers={removeMapLayers}
                        removeLayers={removeLayers}
                        wrap={wrap}
                        setIndexLayers={setIndexLayers}
                        indexLayers={indexLayers}
                        selectedMapPreferLegendData={
                          selectedMapPreferLegendData
                        }
                        setSelectedMapPreferLegendData={
                          setSelectedMapPreferLegendData
                        }
                        currentGraphData={currentGraphData}
                        setcurrentGraphData={setcurrentGraphData}
                        setLoaderLatLng={setLoaderLatLng}
                        loaderlatLng={loaderlatLng}
                        loading={nextProps?.predectionLoading}
                        predectionDataLoadingRef={predectionDataLoadingRef}
                      />
                    </div>
                    <div className="p-2 h-100" style={{ overflow: "hidden" }}>
                      <TabContainer
                        graphData={graphData}
                        graphPanelHeight={graphPanelHeight}
                        mapData={mapData}
                        divRef={divRef}
                        chartLables={chartLables}
                        showChart={showChart}
                        setShowChart={setShowChart}
                        open={open}
                        openPanel={openPanel}
                        setCyModels={setCyModels}
                        cyModels={cyModels}
                        setGraphData={setGraphData}
                        setYearGraphData={setYearGraphData}
                        yearGraphData={yearGraphData}
                        modalperformance={modalperformance}
                        selectedLocation={selectedLocation}
                        selectedCrop={selectedCrop}
                        startYear={startYear}
                        endYear={endYear}
                        selectedModal={selectedModal}
                        setModalPerformance={setModalPerformance}
                        scrollableYear={scrollableYear}
                        setValue={setValue}
                        value={value}
                        setOpenFeedbackModal={setOpenFeedbackModal}
                        openFeedbackModal={openFeedbackModal}
                        currentGraphData={currentGraphData}
                        loading={nextProps?.predectionLoading}
                        selectedCropCalender={selectedCropCalender}
                        selectedCSEndYear={selectedCSEndYear}
                        havePredictionData={havePredictionData}
                        trainingDataStartYear={trainingDataStartYear}
                        trainingDataEndDateGraph={trainingDataEndDateGraph}
                      />
                    </div>
                  </SplitterLayout>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CropPrediction;
