import React from "react";
import { useTranslation } from "react-i18next";
import { BsCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { IoMdCloseCircleOutline } from "react-icons/io";
import {
  Button,
  Collapse,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
} from "reactstrap";

function CompareComponent({
  isCompareOpen,
  setIsCompareOpen,
  setIsCollapse,
  isCollapse,
  compareIndicatorList,
  selectedCompareIndicator,
  layerData,
  toggleCheckbox,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isCompareOpen}
      toggle={() => {
        setIsCompareOpen(!isCompareOpen);
      }}
      animation={false}
      className="mapprefernce-modal"
    >
      <ModalHeader
        className="close-map-preference"
        toggle={() => {
          setIsCompareOpen(!isCompareOpen);
        }}
        close={
          <button className="close-button" type="button">
            ×
          </button>
        }
      >
        <p className="map-preferences-side-bar-title">
          {t("RAPID_RESPONSE.COMPARE_LAYERS")}
        </p>
        <span className="map-preferences-side-bar-sub-title">
          {t("RAPID_RESPONSE.SELECT_COMPARE")}
        </span>
        <Button
          className="close-btn-map"
          onClick={() => setIsCompareOpen(!isCompareOpen)}
        >
          <IoMdCloseCircleOutline className="close-svg" />
        </Button>
      </ModalHeader>
      <ModalBody className="px-0 py-2">
        <NavItem
          onClick={(e) => {
            setIsCollapse((prev) => {
              return {
                ...prev,
                rainfall: !isCollapse.rainfall,
              };
            });
            e.stopPropagation();
          }}
          data-tip
          className="map-preferences-labels"
        >
          {t("RAPID_RESPONSE.RAINFALL")}
          {isCollapse.rainfall ? (
            <BsFillCaretUpFill className="up-icon float-right " />
          ) : (
            <BsCaretDownFill className="up-icon float-right " />
          )}
        </NavItem>
        <Collapse isOpen={isCollapse.rainfall} className="rp-compare-collapse">
          {compareIndicatorList?.map((item) => {
            if (item.keyDatasource === "Rainfall") {
              const isChecked = selectedCompareIndicator.some(
                (selectedItem) => selectedItem.name === item.name,
              );
              return (
                <FormGroup check inline key={item.name}>
                  <Input
                    type="checkbox"
                    className="mr-2 cursor-pointer"
                    onChange={(e) => {
                      toggleCheckbox(e, item);
                      e.stopPropagation();
                    }}
                    checked={
                      selectedCompareIndicator.some(
                        (selectedItem) => selectedItem.name === item.name,
                      ) || layerData?.name === item.name
                    }
                    disabled={
                      (!isChecked &&
                        selectedCompareIndicator.length === 1 &&
                        !selectedCompareIndicator.some(
                          (selectedItem) => selectedItem.name === item.name,
                        )) ||
                      layerData?.name === item.name
                    }
                  />
                  <Label
                    check
                    className="pl-3"
                    style={{ lineHeight: "normal" }}
                  >
                    {item.name}
                  </Label>
                </FormGroup>
              );
            }
            return null;
          })}
        </Collapse>
        {compareIndicatorList?.some(
          (data) => data.keyDatasource === "Flood",
        ) ? (
          <>
            <NavItem
              onClick={(e) => {
                setIsCollapse((prev) => {
                  return {
                    ...prev,
                    flood: !isCollapse.flood,
                  };
                });
                e.stopPropagation();
              }}
              className="map-preferences-labels"
            >
              {t("RAPID_RESPONSE.FLOOD")}
              {isCollapse.flood ? (
                <BsFillCaretUpFill className="up-icon float-right " />
              ) : (
                <BsCaretDownFill className="up-icon float-right " />
              )}
            </NavItem>
            <Collapse isOpen={isCollapse.flood} className="rp-compare-collapse">
              {compareIndicatorList?.map((item) => {
                if (item.keyDatasource === "Flood") {
                  const isChecked = selectedCompareIndicator.some(
                    (selectedItem) => selectedItem.name === item.name,
                  );
                  return (
                    <FormGroup check inline key={item.name}>
                      <Input
                        type="checkbox"
                        className="mr-2 cursor-pointer"
                        onChange={(e) => {
                          toggleCheckbox(e, item);
                          e.stopPropagation();
                        }}
                        checked={
                          selectedCompareIndicator.some(
                            (selectedItem) => selectedItem.name === item.name,
                          ) || layerData?.name === item.name
                        }
                        disabled={
                          (!isChecked &&
                            selectedCompareIndicator.length === 1 &&
                            !selectedCompareIndicator.some(
                              (selectedItem) => selectedItem.name === item.name,
                            )) ||
                          layerData?.name === item.name
                        }
                      />
                      <Label check className="pl-3">
                        {item.name}
                      </Label>
                    </FormGroup>
                  );
                }
                return null;
              })}
            </Collapse>
          </>
        ) : null}

        {compareIndicatorList?.some(
          (data) => data.keyDatasource === "Disaster Exposure",
        ) ? (
          <>
            <NavItem
              onClick={(e) => {
                setIsCollapse((prev) => {
                  return {
                    ...prev,
                    disaster_exposure: !isCollapse.disaster_exposure,
                  };
                });
                e.stopPropagation();
              }}
              data-tip
              // className="compare-collapse-header compare-collapse px-4"
              className="map-preferences-labels"
            >
              {t("RAPID_RESPONSE.DISASTER_EXPOSURE")}
              {isCollapse.disaster_exposure ? (
                <BsFillCaretUpFill className="up-icon float-right " />
              ) : (
                <BsCaretDownFill className="up-icon float-right " />
              )}
            </NavItem>
            <Collapse
              isOpen={isCollapse.disaster_exposure}
              className="rp-compare-collapse"
            >
              {compareIndicatorList?.map((item) => {
                if (item.keyDatasource === "Disaster Exposure") {
                  const isChecked = selectedCompareIndicator.some(
                    (selectedItem) => selectedItem.name === item.name,
                  );
                  return (
                    <FormGroup check inline key={item.name}>
                      <Input
                        type="checkbox"
                        className="mr-2 cursor-pointer"
                        onChange={(e) => {
                          toggleCheckbox(e, item);
                          e.stopPropagation();
                        }}
                        checked={
                          selectedCompareIndicator.some(
                            (selectedItem) => selectedItem.name === item.name,
                          ) || layerData?.name === item.name
                        }
                        disabled={
                          (!isChecked &&
                            selectedCompareIndicator.length === 1 &&
                            !selectedCompareIndicator.some(
                              (selectedItem) => selectedItem.name === item.name,
                            )) ||
                          layerData?.name === item.name
                        }
                      />
                      <Label check className="pl-3">
                        {item.name}
                      </Label>
                    </FormGroup>
                  );
                }
                return null;
              })}
            </Collapse>
          </>
        ) : null}
      </ModalBody>
    </Modal>
  );
}

export default CompareComponent;
