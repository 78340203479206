import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import { Button, Row, Col } from "reactstrap";
import _ from "lodash";
import SplitterLayout from "react-splitter-layout";
import "react-splitter-layout/lib/index.css";
import { useTranslation } from "react-i18next";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import WeatherforecastGraph from "./WeatherforecastGraph";
import {
  mapPreferenceRequest,
  getWeatherForecastSources,
  getIRIAvailableDatesDataRequest,
  getWeatherForecastSourcesSuccess,
  coreRequest,
  stateBoundaryRequest,
  indicatorSuccess,
} from "../../store/actions";
import { renderReactOptionsArraystate } from "../../helpers"; // sortECMWFDays
import Loader from "../../components/Common/Loader";
import {
  renderParamList,
  renderStaticParamList,
} from "../../components/Common/Utils"; // renderStaticParamList
import {
  DATA_SOURCES,
  DATE_FORMAT,
  PARAMETER_SLUGS,
  SHOW_VALIDTIME_AND_LAYERS,
  SHOW_DAY_AND_QUANTILE,
  BASE_TIME_ECMWF,
  FORECAST_LIST_ECMWF,
  SOIL_MOISTURE_LAYER_ECMWF,
} from "../../constants/index";
import StaticResource from "./StaticResource";

// lazy loading components
const WeatherForcastMapContainer = lazy(() => import("./MapContainer"));
const WeatherForcastFilterIndex = lazy(() => import("./Filter/index"));

function WeatherForcast() {
  const [, setOpen] = useOutletContext();
  const [iriTimeSeriesData, setIRITimeSeriesData] = useState(null);
  const [chartSeriesData, setChartSeriesData] = useState([]);
  const [compareSeriseData, setCompareSeriseData] = useState([]);
  const [polygonMarkerSeriseData, setPolygonMarkerSeriseData] = useState([]);
  const { t } = useTranslation();
  const [openPanel, setOpenPanel] = useState(false);
  const splitterRef = useRef(null);
  const paramsData = new URLSearchParams(window.location.search);
  const selectedModule = paramsData.get("module");
  const module = paramsData.get("subModule");
  const [noaaTimeSeriesData, setNoaaTimeSeriesData] = useState(null);
  const [openweatherSeriseData, setOpenweatherSeriseData] = useState(null);
  const [layerControl, setLayerControl] = useState({
    firstLayer: {},
    secondLayer: {},
  });
  const [countryList, setCountryList] = useState([]);
  const [mapControl, setMapControl] = useState({});
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [indexLayers, setIndexLayers] = useState([]);
  const [legend, setLegend] = useState([]);
  const [comparelegend, setcompareLegend] = useState([]);

  const [fourUrl, setFourUrl] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedLocationPolygon, setSelectedLocationPolygon] = useState({});
  const [mapes, setMapes] = useState();
  const [graphName, setGraphName] = useState("");
  const [graphPanelHeight, setGraphPanelHeight] = useState(0);
  const dispatch = useDispatch();
  const nextProps = useSelector((state) => ({
    statesList: state.Core.corestateList,
    districtsList: state.Core.coredistrictList,
    countryList: state.Core.coreList,
    basinList: state.Core.coreBasinList,
    mapPreferenceList: state.MapPreference,
    Weather: state.Weather,
    Graph: state?.Weather?.weatherForecastGraphData,
    isCompareLocation: state.Core.coredistrictList?.config?.isCompareLocation,
    isWeatherForecastGraphRequesting:
      state.Weather.isWeatherForecastGraphRequesting,
    graphLoading: state.Loader.graphLoading,
    weatherForecastGraphDataError: state.Weather.weatherForecastGraphDataError,
    staticResourceData: state.Weather.staticResourceData,
    staticResourceDataImage: state.Weather.staticResourceDataControls,
    ecmwfImaheLoading: state.Weather.ecmwfImaheLoading,
  }));

  const [selectedStaticParam, setSelectedStaticParam] = useState("");

  const [local, setLocal] = useState(
    JSON.parse(localStorage.getItem("selectedCountry")),
  );
  const [position, setPosition] = useState([local?.latitude, local?.longitude]);
  const [zooms, setZooms] = useState(local?.zoom_level);
  const [errorData, setErrorData] = useState({ showError: false, message: "" });
  const updatePage = (title, title2) => {
    setPosition(title);
    setZooms(title2);
  };

  const [dataSources, setDataSources] = useState({
    firstDataSources: [],
    secondDataSources: [],
  });
  const [selectedDataSource, setSelectedDataSource] = useState({
    firstDataSource: [],
    secondDataSource: [],
  });
  const [selectedDataSourceId, setSelectedDataSourceId] = useState();
  const [staticResourceParams, setStaticResourceParams] = useState([]);
  const [staticOptionList, setStaticOptionList] = useState([]);
  const [params, setParams] = useState([]);
  const [categoryParams, setCategoryParams] = useState([]);
  const [initialSelectedDate, setSelectedDate] = useState({
    graph: {
      startDate: moment().format(DATE_FORMAT),
      endDate: moment().add(6, "days").format(DATE_FORMAT),
      minStartDate: "",
      maxStartDate: "",
      maxEndDate: "",
    },
    map: {
      startDate: moment().format(DATE_FORMAT),
      endDate: moment().add(6, "days").format(DATE_FORMAT),
      minStartDate: "",
      maxStartDate: "",
      maxEndDate: "",
    },
  });
  const [finalSelectedDate, setFinalSelectedDate] = useState({
    startDate: moment().format(DATE_FORMAT),
    endDate: moment().add(6, "days").format(DATE_FORMAT),
    minStartDate: "",
    maxStartDate: "",
    maxEndDate: "",
  });
  const [updatedSelectedDate, setUpdatedSelectedDate] = useState({
    startDate: moment().format(DATE_FORMAT),
    endDate: moment().add(6, "days").format(DATE_FORMAT),
    minStartDate: "",
    maxStartDate: "",
    maxEndDate: "",
  });

  const [finalNoaaEndDate, setFinalNoaaEndDate] = useState("");
  const [showChart, setShowChart] = useState(false);
  const [finalNoaaMaxEndDate, setFinalNoaaMaxEndDate] = useState("");
  const [noaaMaxEndDate, setNoaaMaxEndDate] = useState(false);
  const [selectedParam, setSelectedParam] = useState("");
  const [previousParam, setPreviousParam] = useState("");
  const [forecastAvailableDates, setForecastAvailableDates] = useState([]);
  const [IRIAvailableYearList, setIRIAvailableYearList] = useState([]);
  const [IRIAvailableMonthList, setIRIAvailableMonthList] = useState([]);
  const [IRIAvailableLeadTimeList, setIRIAvailableLeadTimeList] = useState([]);
  const [IRIForecastTime, setIRIForecastTime] = useState({
    year: null,
    month: null,
    lead_time: null,
  });
  const [compareIRIForecastTime, setCompareIRIForecastTime] = useState({
    year: null,
    month: null,
    lead_time: null,
  });
  const [compareIRIAvailableYearList, setCompareIRIAvailableYearList] =
    useState([]);
  const [compareIRIAvailableMonthList, setCompareIRIAvailableMonthList] =
    useState([]);
  const [compareIRIAvailableLeadTimeList, setCompareIRIAvailableLeadTimeList] =
    useState([]);
  const [compareDataSources, setCompareDataSources] = useState([]);
  const [noaaCompareMaxEndDate, setNoaaCompareMaxEndDate] = useState(false);
  const [finalNoaaCompareEndDate, setFinalNoaaCompareEndDate] = useState("");
  const [finalNoaaCompareMaxEndDate, setFinalNoaaCompareMaxEndDate] =
    useState("");
  const [aggregation, setAggregation] = useState("mean");
  const [forecastData, setForecastData] = useState([]);
  const [dateSourceWeatherData, setWeatherData] = useState({});
  const [selectedParams, setSelectedParams] = useState([]);
  const [compare, setCompare] = useState(false);
  const [updatedSelectedCompareDate, setUpdatedSelectedCompareDate] = useState({
    startDate: moment().format(DATE_FORMAT),
    endDate: moment().add(6, "days").format(DATE_FORMAT),
    minStartDate: "",
    maxStartDate: "",
    maxEndDate: "",
  });
  const [initialParams, setInitialParams] = useState("");
  const [compareParams, setCompareParams] = useState("");
  const [compareCountry, setCompareCountry] = useState();
  const [adminRadio, setAdminRadio] = useState("admin");
  const [isStaticResourceSelected, setIsStaticResourceSelected] =
    useState(false);
  const [staticResourceBaseTimeData, setStatiResourceBaseTimeData] = useState(
    [],
  );
  const [staticResourceData, setStatiResourceData] = useState([]);
  const [selectedValidTime, setSelectedValidTime] = useState({});
  const [validTimeOptions, setValidTimeOptions] = useState([]);
  const [validLayerOptions, setValidLayerOptions] = useState([]);
  const [selectedValidLayer, setSelectedValidLayer] = useState([]);
  const [daysData, setDaysData] = useState({
    daysOptions: [],
    selectedDay: null,
  });
  const [quantileData, setQuantileData] = useState({
    quantileOptions: [],
    selectedQuantile: null,
  });
  const [forecastType, setForecastType] = useState({
    forecastTypeOptions: [],
    selectedForecastType: null,
  });

  const [staticResourceImage, setStaticResourceImage] = useState({});
  const [ecmwfImage, setEcmwfImage] = useState("");

  useEffect(() => {
    if (nextProps.staticResourceData?.length) {
      setStatiResourceData(nextProps.staticResourceData);

      const baseTimeArrayData = nextProps.staticResourceData?.find(
        (each) => each?.name === BASE_TIME_ECMWF,
      );

      const ForecastListData = nextProps.staticResourceData?.find(
        (each) => each?.title === FORECAST_LIST_ECMWF,
      );

      const baseTimearr = baseTimeArrayData?.values?.map((item) => {
        if (item?.label) {
          return {
            value: item.value,
            label: item?.label,
            validTime: item?.linked_values,
          };
        }

        return {
          value: item.base_time,
          label:
            item?.valid_time?.[0]?.forecast_type?.[0]?.ecmwf_base_time_label,
        };
      });

      setStatiResourceBaseTimeData(baseTimearr);

      if (SHOW_VALIDTIME_AND_LAYERS.includes(selectedStaticParam?.slug)) {
        const validTimeArr = baseTimearr?.[0]?.validTime;

        setValidTimeOptions(validTimeArr); // by default selecting first element as options
        setSelectedValidTime(validTimeArr[0]); // by default selecting first element
        if (selectedStaticParam?.slug === PARAMETER_SLUGS.SOIL_MOISTURE) {
          let sortedLayerOptions = [];
          sortedLayerOptions = nextProps.staticResourceData?.find(
            (each) => each?.title === SOIL_MOISTURE_LAYER_ECMWF,
          );

          setValidLayerOptions(sortedLayerOptions?.values);
          setSelectedValidLayer(sortedLayerOptions?.values?.[0]);
        }
        if (
          selectedStaticParam?.slug === PARAMETER_SLUGS.PRECEIPITATION_SEAS5
        ) {
          let foreCastTypeOptions = [];
          if (ForecastListData) {
            foreCastTypeOptions = ForecastListData?.values;
          }
          setForecastType({
            forecastTypeOptions: foreCastTypeOptions,
            selectedForecastType: foreCastTypeOptions?.[0],
          });
        }
      }

      if (SHOW_DAY_AND_QUANTILE.includes(selectedStaticParam?.slug)) {
        const ecmwfDayData = nextProps.staticResourceData?.find(
          (each) => each.title === "Day",
        );

        const ecmwfQuantileData = nextProps.staticResourceData?.find(
          (each) => each?.title === "Quantile",
        );

        if (ecmwfQuantileData) {
          setDaysData({
            daysOptions: ecmwfDayData?.values,
            selectedDay: ecmwfDayData?.values?.[0],
          });
        }

        setQuantileData({
          quantileOptions: ecmwfQuantileData?.values,
          selectedQuantile: ecmwfQuantileData?.values?.[0],
        });
      }
    } else {
      setStatiResourceBaseTimeData([]);
    }
  }, [nextProps.staticResourceData]);

  // Re-setting location whenever admin/Basin selection changes
  useEffect(() => {
    const CountryData = selectedLocation?.country;
    if (CountryData) {
      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: CountryData,
          state: "",
          district: "",
          basin: "",
          subBasin: "",
        };
      });
    }
    setShowChart(false); // close chart if open
    setCompare(false);
  }, [adminRadio]);

  const layerRef = useRef([]);
  const wrap = useRef([]);
  const setParamData = ({ values, fromCompare }) => {
    layerRef.current = [];
    const arr = [];
    arr.push(values);
    if (!_.isEmpty(arr)) {
      const categoriedArr = renderParamList(arr);
      const updatedCategoriedArr = _.cloneDeep(categoriedArr);
      setCategoryParams(updatedCategoriedArr);
      categoriedArr?.map(() => {
        const ref = React.createRef();
        layerRef.current.push(ref);
        return null;
      });
    }
    setStaticResourceParams(arr);

    const paramList = renderStaticParamList(arr[0]?.parameterData);
    setStaticOptionList(paramList);
    setSelectedStaticParam(paramList[0]);
    if (!_.isEmpty(values)) {
      setInitialParams(arr);
      if (fromCompare || _.isEmpty(params) || params[0]?.id !== arr[0]?.id) {
        setParams(arr);
      }
    }
  };

  const setCompareParam = (values, firstDataSource) => {
    const arr = [];
    if (!_.isEmpty(values)) {
      arr.push(values);
    }
    if (_.isEmpty(arr)) {
      setCompareParams([]);

      setParamData({ values: firstDataSource, fromCompare: true });
    }
    setCompareParams(arr);
  };

  const addLayers = (selectedParamRef) => {
    if (wrap.current && selectedParamRef.current) {
      const leafletMapRef = wrap.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer) => {
        leafletMapRef.addLayer(layer);
      });
    }
    return false;
  };
  const removeLayers = (selectedParamRef) => {
    if (
      wrap &&
      wrap?.current &&
      selectedParamRef &&
      selectedParamRef?.current
    ) {
      const map1 = wrap.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer) => map1.removeLayer(layer));
    }
  };
  const removeLegend = (paramLegend) => {
    const selectedLegend = JSON.parse(JSON.stringify(paramLegend));
    if (selectedLegend.length) {
      if (selectedLegend.length) {
        selectedLegend.forEach((value) => {
          if (value.add) {
            value.add = false;
          }
        });
        setLegend(selectedLegend);
      }
    }
  };

  useEffect(() => {
    if (
      selectedDataSource?.firstDataSource?.[0]?.name ===
      t("WEATHER_FORCAST.OPEN_WEATHER")
    ) {
      // {t("DASHBOARD.WEATHER_FORCAST")}
      const leafletEle = document.getElementById("leaflet-map");
      if (leafletEle) {
        leafletEle.classList.add("open-weather-polygon-style");
      }
    } else {
      const leafletEle = document.getElementById("leaflet-map");
      if (leafletEle) {
        leafletEle.classList.remove("open-weather-polygon-style");
      }
    }
  }, [selectedDataSource]);

  const removeTransparencyToolLayers = (indexLayer, firstUrl, secondUrl) => {
    const data = _.cloneDeep(indexLayer);
    const updatedData = data?.filter((i) => {
      if (secondUrl) {
        if (i?.layer?._url !== firstUrl && i?.layer?._url !== secondUrl) {
          return i;
        }
      } else {
        if (i?.layer?._url !== firstUrl) {
          return i;
        }
      }
      return null;
    });
    setIndexLayers(updatedData);
  };
  const handleParameters = (paramsList) => {
    const selectedParamArr = selectedParams;
    let updatedCompareArray = [];
    const legendArray = legend;
    let selectedParamItem = "";
    const compareArray = _.cloneDeep(comparelegend);
    if (selectedParamArr[0] && !_.isEmpty(selectedParamArr[0])) {
      selectedParamItem = selectedParamArr[0]?.parameter;
    }
    const previousParamRef = layerRef.current[selectedParamItem?.paramIndex];
    previousParamRef?.current?.eachLayer((layer) => {
      removeTransparencyToolLayers(indexLayers, layer?._url);
    });
    removeLayers(previousParamRef);
    if (legendArray.length) {
      /* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
      for (const x of legendArray) {
        x.add = false;
      }
    }
    if (compareArray.length) {
      updatedCompareArray = compareArray?.map((value) => {
        if (value.add) value.add = false;
        return value;
      });
    }
    if (!_.isEmpty(layerControl?.firstLayer)) {
      mapes?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      mapes?.removeControl(layerControl?.secondLayer);
    }
    if (!_.isEmpty(mapControl)) {
      removeTransparencyToolLayers(
        indexLayers,
        layerControl?.firstLayer?._url,
        layerControl?.secondLayer?._url,
      );
      mapes?.removeControl(mapControl);
      setMapControl("");
      setLayerControl({
        firstLayer: {},
        secondLayer: {},
      });
    }
    if (!_.isEmpty(paramsList)) {
      selectedParamArr.push(paramsList);
      if (
        _.isEmpty(selectedParam) ||
        paramsList?.id !== selectedParam?.id ||
        paramsList?.parameter?.id !== selectedParam?.parameter?.id
      ) {
        setPreviousParam(selectedParam);
        setSelectedParam(paramsList);
      }
      setSelectedParams(selectedParamArr);
      setLegend(legendArray);
      setcompareLegend(updatedCompareArray);
    } else {
      setPreviousParam(selectedParam);
      setSelectedParam({});
      setSelectedParams([]);
    }
  };
  const onHandleZoom = (center, zoom) => {
    setPosition(center);
    setZooms(zoom);
  };

  const isCountryList = useRef(true);
  useEffect(() => {
    if (isCountryList.current) {
      isCountryList.current = false;
      return;
    }
    if (nextProps?.countryList) {
      const countryarray = [];
      renderReactOptionsArraystate(
        nextProps?.countryList,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
        "code",
        false,
        null,
        null,
        null,
        null,
        null,
        null,
        "basin_feature_collection",
      )?.map((eachValue) => countryarray.push(eachValue));
      setCountryList(countryarray);
    }
  }, [nextProps?.countryList]);

  const isEcmwfImage = useRef(true);
  useEffect(() => {
    if (isEcmwfImage.current) {
      isEcmwfImage.current = false;
      return;
    }
    if (nextProps?.staticResourceDataImage) {
      setEcmwfImage(nextProps?.staticResourceDataImage);
    }
  }, [nextProps?.staticResourceDataImage]);

  // function to call the selected distric and state
  const handleSelectedLocation = (filterLocation) => {
    setSelectedLocation(filterLocation);
  };
  useEffect(() => {
    setLocal(JSON.parse(localStorage.getItem("selectedCountry")));

    dispatch(
      coreRequest({
        location_type: "country",
        menu_slug: module,
        sub_menu_slug: selectedModule,
      }),
    );
    window.scrollTo(0, 0);
    setOpen(true);
    return () => {
      setParams([]);
      dispatch(getWeatherForecastSourcesSuccess(""));
      dispatch(indicatorSuccess({}));
    };
  }, []);

  useEffect(() => {
    setParams([]);
    const data = selectedLocation?.country;
    dispatch(getWeatherForecastSources({ country_id: data?.value }));
    setShowChart(false);
    setSelectedParam("");

    dispatch(
      stateBoundaryRequest({
        data: {
          country_name: data?.label,
          state_name: t("DASHBOARD.ALL_REGIONS"),
        },
        isState: true,
      }),
    );
  }, [selectedLocation?.country]);

  useEffect(() => {
    if (selectedLocationPolygon?.region?.length > 0) {
      setCompare(false);
    }
  }, [selectedLocationPolygon?.region]);

  useEffect(() => {
    const CountryData = selectedLocation?.country;

    if (localStorage.getItem("i18nextLng") && CountryData) {
      setLocal(JSON.parse(localStorage.getItem("selectedCountry")));
      dispatch(
        mapPreferenceRequest({
          country_id: CountryData?.value,
          module: selectedModule,
        }),
      );
      window.scrollTo(0, 0);
      setLocal(CountryData);
      setPosition([CountryData?.latitude, CountryData?.longitude]);
      setZooms(CountryData?.zoom_level);

      setDataSources((prev) => ({
        ...prev,
        firstDataSources: [],
        secondDataSources: [],
      }));
      setCompareCountry(CountryData);
      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: CountryData,
          state: "",
          district: "",
          basin: "",
          subBasin: "",
        };
      });
    }
  }, [selectedLocation?.country]);

  useEffect(() => {
    if (
      !_.isEmpty(selectedParam) &&
      (_.isEmpty(previousParam) ||
        previousParam?.id !== selectedParam?.id ||
        previousParam?.parameter?.id !== selectedParam?.parameter?.id)
    ) {
      if (selectedParam?.source_id === DATA_SOURCES.IRI.id) {
        dispatch(getIRIAvailableDatesDataRequest());
      }
    }
  }, [selectedParam]);
  useEffect(() => {
    if (!_.isEmpty(compareDataSources)) {
      if (compareDataSources[0].id === DATA_SOURCES.IRI.id) {
        dispatch(getIRIAvailableDatesDataRequest());
      }
    }
  }, [compareDataSources]);

  useEffect(() => {
    if (nextProps?.Weather?.weatherForecastSourcesData) {
      const result = nextProps?.Weather?.weatherForecastSourcesData;
      const options = [];
      if (result) {
        result?.map((item) => {
          const {
            id,
            name,
            type,
            category,
            weather_forecast_source_with_parameter: parameter,
          } = item?.source;

          return options.push({
            id,
            name,
            value: name,
            label: name,
            type,
            category,
            parameterData: parameter,
          });
        });

        setDataSources((prev) => ({
          ...prev,
          firstDataSources: options,
          secondDataSources: options,
        }));
        setSelectedDataSource((prev) => ({
          ...prev,
          firstDataSource: options,
        }));

        setFourUrl(options[0]);

        setParamData({ values: options[0], fromCompare: false });
      } else {
        setDataSources((prev) => ({
          ...prev,
          firstDataSources: [],
          secondDataSources: [],
        }));
      }
    }
  }, [nextProps?.Weather?.weatherForecastSourcesData]);
  useEffect(() => {
    if (nextProps?.Weather?.weatherForecastData) {
      setForecastData(nextProps?.Weather?.weatherForecastData);
      setcompareLegend([]);
      setShowChart(false);
    }
  }, [nextProps?.Weather?.weatherForecastData]);

  useEffect(() => {
    if (showChart === false) {
      setOpenweatherSeriseData(null);
      setPolygonMarkerSeriseData([]);
    }
  }, [showChart]);
  useEffect(() => {
    if (splitterRef && splitterRef.current) {
      if (showChart) {
        splitterRef.current.setState({ secondaryPaneSize: 55 });
      } else {
        splitterRef.current.setState({ secondaryPaneSize: 0 });
      }
    }
  }, [showChart]);

  return (
    <div className="dashboard-main-page common-dashboard-ftr">
      <Suspense fallback={<Loader isSuspense />}>
        <div className="dash-main-row">
          <div className="dashboard-drought">
            <div className="dashboard-filter-div">
              <div>
                <div className="dashboard-filter">
                  <h3 className="dashboard-filter-h3">
                    {t("WEATHER_FORCAST.FORECAST_INDICATORS")}
                  </h3>
                </div>
                <Row className="dashboard-drought-section weather-forecast-row">
                  <Col
                    lg={3}
                    className={
                      openPanel
                        ? "dasboard-drought-expand-1"
                        : "dashboard-drought-col1"
                    }
                  >
                    <Button
                      className={
                        !openPanel
                          ? "button-expand-panel"
                          : "button-expand-panel-oepn"
                      }
                      onClick={() => setOpenPanel(!openPanel)}
                      aria-controls="example-collapse-text"
                      aria-expanded={openPanel}
                    >
                      {openPanel ? (
                        <div className="panel-btn">
                          <span className="mr-2">Expand Bar</span>
                          <FaAngleRight className="right-icon" />
                        </div>
                      ) : (
                        <div className="tooltip-div">
                          <span className="mr-2 tooltip-text">Hide Bar</span>
                          <FaAngleLeft className="right-icon right-icon-md float-right" />
                        </div>
                      )}
                    </Button>
                    <div
                      className={openPanel ? "d-none" : "dashboard-drought-div"}
                    >
                      <WeatherForcastFilterIndex
                        statesList={nextProps.statesList}
                        staticResourceParams={staticResourceParams}
                        setForecastData={setForecastData}
                        districtsList={nextProps.districtsList}
                        isCompareLocation={nextProps.isCompareLocation}
                        setGraphName={setGraphName}
                        fourUrl={fourUrl}
                        setFourUrl={setFourUrl}
                        updatePage={updatePage}
                        setShowChart={setShowChart}
                        setLegend={setLegend}
                        setSelectedStaticParam={setSelectedStaticParam}
                        selectedStaticParam={selectedStaticParam}
                        iriTimeSeriesData={iriTimeSeriesData}
                        setIRITimeSeriesData={setIRITimeSeriesData}
                        layerControl={layerControl}
                        noaaTimeSeriesData={noaaTimeSeriesData}
                        setNoaaTimeSeriesData={setNoaaTimeSeriesData}
                        openweatherSeriseData={openweatherSeriseData}
                        setOpenweatherSeriseData={setOpenweatherSeriseData}
                        setLayerControl={setLayerControl}
                        selectedParams={selectedParams}
                        handleSelectedLocation={handleSelectedLocation}
                        indexLayers={indexLayers}
                        mapControl={mapControl}
                        setMapControl={setMapControl}
                        mapes={mapes}
                        setChartSeriesData={setChartSeriesData}
                        setCompareSeriseData={setCompareSeriseData}
                        setPolygonMarkerSeriseData={setPolygonMarkerSeriseData}
                        setMapes={setMapes}
                        selectedLocationPolygon={selectedLocationPolygon}
                        setSelectedLocationPolygon={setSelectedLocationPolygon}
                        mapPreferencesData={mapPreferencesData}
                        dataSources={dataSources}
                        selectedDataSource={selectedDataSource}
                        initialSelectedDate={initialSelectedDate}
                        setSelectedDate={setSelectedDate}
                        updatedSelectedDate={updatedSelectedDate}
                        setUpdatedSelectedDate={setUpdatedSelectedDate}
                        finalNoaaEndDate={finalNoaaEndDate}
                        setFinalNoaaEndDate={setFinalNoaaEndDate}
                        finalNoaaMaxEndDate={finalNoaaMaxEndDate}
                        setFinalNoaaMaxEndDate={setFinalNoaaMaxEndDate}
                        noaaMaxEndDate={noaaMaxEndDate}
                        setNoaaMaxEndDate={setNoaaMaxEndDate}
                        IRIAvailableYearList={IRIAvailableYearList}
                        IRIForecastTime={IRIForecastTime}
                        IRIAvailableMonthList={IRIAvailableMonthList}
                        IRIAvailableLeadTimeList={IRIAvailableLeadTimeList}
                        aggregation={aggregation}
                        setAggregation={setAggregation}
                        selectedParam={selectedParam}
                        setForecastAvailableDates={setForecastAvailableDates}
                        forecastAvailableDates={forecastAvailableDates}
                        setIRIAvailableYearList={setIRIAvailableYearList}
                        setIRIAvailableMonthList={setIRIAvailableMonthList}
                        setIRIAvailableLeadTimeList={
                          setIRIAvailableLeadTimeList
                        }
                        layerRef={layerRef}
                        removeLayers={removeLayers}
                        removeLegend={removeLegend}
                        legend={legend}
                        setSelectedDataSource={setSelectedDataSource}
                        setCompareIRIAvailableYearList={
                          setCompareIRIAvailableYearList
                        }
                        compareIRIAvailableYearList={
                          compareIRIAvailableYearList
                        }
                        compareIRIForecastTime={compareIRIForecastTime}
                        compareIRIAvailableMonthList={
                          compareIRIAvailableMonthList
                        }
                        compareIRIAvailableLeadTimeList={
                          compareIRIAvailableLeadTimeList
                        }
                        setCompareIRIAvailableMonthList={
                          setCompareIRIAvailableMonthList
                        }
                        setCompareIRIAvailableLeadTimeList={
                          setCompareIRIAvailableLeadTimeList
                        }
                        compare={compare}
                        setCompare={setCompare}
                        setFinalSelectedDate={setFinalSelectedDate}
                        updatedSelectedCompareDate={updatedSelectedCompareDate}
                        setUpdatedSelectedCompareDate={
                          setUpdatedSelectedCompareDate
                        }
                        noaaCompareMaxEndDate={noaaCompareMaxEndDate}
                        setNoaaCompareMaxEndDate={setNoaaCompareMaxEndDate}
                        finalNoaaCompareEndDate={finalNoaaCompareEndDate}
                        setFinalNoaaCompareEndDate={setFinalNoaaCompareEndDate}
                        finalNoaaCompareMaxEndDate={finalNoaaCompareMaxEndDate}
                        setFinalNoaaCompareMaxEndDate={
                          setFinalNoaaCompareMaxEndDate
                        }
                        finalSelectedDate={finalSelectedDate}
                        setIRIForecastTime={setIRIForecastTime}
                        setCompareIRIForecastTime={setCompareIRIForecastTime}
                        setSelectedDataSourceId={setSelectedDataSourceId}
                        setParamData={setParamData}
                        setCompareDataSources={setCompareDataSources}
                        handleCompareParamsData={setCompareParam}
                        initialParams={initialParams}
                        compareParams={compareParams}
                        setParams={setParams}
                        forecastData={forecastData}
                        setWeatherData={setWeatherData}
                        dateSourceWeatherData={dateSourceWeatherData}
                        compareDataSources={compareDataSources}
                        previousParam={previousParam}
                        setDataSources={setDataSources}
                        setcompareLegend={setcompareLegend}
                        comparelegend={comparelegend}
                        removeTransparencyToolLayers={
                          removeTransparencyToolLayers
                        }
                        selectedDataSourceId={selectedDataSourceId}
                        countryList={countryList}
                        errorData={errorData}
                        setErrorData={setErrorData}
                        setCompareCountry={setCompareCountry}
                        compareCountry={compareCountry}
                        setOpen={setOpen}
                        basinsList={nextProps.basinList}
                        setAdminRadio={setAdminRadio}
                        adminRadio={adminRadio}
                        isStaticResourceSelected={isStaticResourceSelected}
                        setIsStaticResourceSelected={
                          setIsStaticResourceSelected
                        }
                        staticResourceBaseTimeData={staticResourceBaseTimeData}
                        staticResourceData={staticResourceData}
                        selectedValidTime={selectedValidTime}
                        setSelectedValidTime={setSelectedValidTime}
                        validTimeOptions={validTimeOptions}
                        setValidTimeOptions={setValidTimeOptions}
                        setValidLayerOptions={setValidLayerOptions}
                        validLayerOptions={validLayerOptions}
                        setSelectedValidLayer={setSelectedValidLayer}
                        selectedValidLayer={selectedValidLayer}
                        daysData={daysData}
                        setDaysData={setDaysData}
                        quantileData={quantileData}
                        setQuantileData={setQuantileData}
                        forecastType={forecastType}
                        setForecastType={setForecastType}
                        staticOptionList={staticOptionList}
                        setStaticResourceImage={setStaticResourceImage}
                        staticResourceImage={staticResourceImage}
                        setSelectedLocation={setSelectedLocation}
                        selectedLocation={selectedLocation}
                        setEcmwfImage={setEcmwfImage}
                      />
                    </div>
                  </Col>
                  <Col
                    lg={openPanel ? 12 : 9}
                    className={
                      openPanel ? "px-0" : "px-0 dashboard-drought-map"
                    }
                  >
                    <div
                      className={
                        showChart
                          ? "map-space-div visible-splitter"
                          : "map-space-div invisible-splitter"
                      }
                    >
                      {(!isStaticResourceSelected ||
                        Object?.keys(staticResourceImage)?.length === 0) &&
                      ecmwfImage === "" ? (
                        <SplitterLayout
                          ref={splitterRef}
                          primaryIndex={0}
                          vertical
                          percentage
                          primaryMinSize={45}
                          secondaryInitialSize={0}
                          onSecondaryPaneSizeChange={(e) =>
                            setGraphPanelHeight(e)
                          }
                        >
                          <div
                            className={
                              showChart
                                ? "dashboard-drought-col-graph-view"
                                : "dashboard-drought-col2"
                            }
                          >
                            <WeatherForcastMapContainer
                              mapes={mapes}
                              local={local}
                              legend={legend}
                              setMapes={setMapes}
                              mapControl={mapControl}
                              mapPreferencesData={mapPreferencesData}
                              setMapPreferencesData={setMapPreferencesData}
                              setMapControl={setMapControl}
                              setLegend={setLegend}
                              layerControl={layerControl}
                              setLayerControl={setLayerControl}
                              comparelegend={comparelegend}
                              setcompareLegend={setcompareLegend}
                              position={position}
                              zooms={zooms}
                              onHandleZoom={onHandleZoom}
                              indexLayers={indexLayers}
                              setIndexLayers={setIndexLayers}
                              boundaryData={
                                nextProps.mapPreferenceList.mapPreferenceList
                              }
                              selectedLocation={selectedLocation}
                              setSelectedLocation={setSelectedLocation}
                              selectedDataSourceId={selectedDataSourceId}
                              staticResourceParam={staticResourceParams}
                              params={params}
                              categoryParams={categoryParams}
                              layerRef={layerRef}
                              handleParameters={handleParameters}
                              wrap={wrap}
                              addLayers={addLayers}
                              removeLayers={removeLayers}
                              dateSourceWeatherData={dateSourceWeatherData}
                              selectedParams={selectedParams}
                              selectedParam={selectedParam}
                              removeTransparencyToolLayers={
                                removeTransparencyToolLayers
                              }
                              selectedDataSource={selectedDataSource}
                              setSelectedParams={setSelectedParams}
                              IRIForecastTime={IRIForecastTime}
                              initialSelectedDate={initialSelectedDate}
                              compareIRIForecastTime={compareIRIForecastTime}
                              finalSelectedDate={finalSelectedDate}
                              setShowChart={setShowChart}
                              compare={compare}
                              setOpen={setOpen}
                              setAdminRadio={setAdminRadio}
                              adminRadio={adminRadio}
                              setSelectedLocationPolygon={
                                setSelectedLocationPolygon
                              }
                              selectedLocationPolygon={selectedLocationPolygon}
                            />
                          </div>
                          {(!_.isEmpty(chartSeriesData) || showChart) && (
                            <WeatherforecastGraph
                              indicatorsData={
                                selectedDataSource.firstDataSource[0]
                              }
                              setShowChart={setShowChart}
                              graphName={graphName}
                              openweatherSeriseData={openweatherSeriseData}
                              showChart={showChart}
                              chartData={chartSeriesData}
                              compareData={compareSeriseData}
                              aggregation={aggregation}
                              startDate={initialSelectedDate?.graph?.startDate}
                              endDate={initialSelectedDate?.graph?.endDate}
                              selectedParams={selectedParams}
                              markerPolygonData={polygonMarkerSeriseData}
                              compare={compare}
                              isWeatherForecastGraphRequesting={
                                nextProps?.isWeatherForecastGraphRequesting
                              }
                              graphLoading={nextProps?.graphLoading}
                              weatherForecastGraphDataError={
                                nextProps?.weatherForecastGraphDataError
                              }
                              errorData={errorData}
                              setErrorData={setErrorData}
                              setOpen={setOpen}
                              graphHight={graphPanelHeight}
                            />
                          )}
                        </SplitterLayout>
                      ) : (
                        <StaticResource
                          params={params}
                          handleParameters={handleParameters}
                          dataSourceId={selectedDataSourceId}
                          setShowChart={setShowChart}
                          compare={compare}
                          selectedValidTime={staticResourceImage}
                          ecmwfImage={ecmwfImage}
                          ecmwfImaheLoading={nextProps?.ecmwfImaheLoading}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </div>
  );
}
export default React.memo(WeatherForcast);
