// import { act } from "react-dom/test-utils";
import MAP_PREFERENCE_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};
const MapPreference = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case MAP_PREFERENCE_CONST.MAP_PREFERENCE_REQUEST:
      state = {
        ...state,
        loading: true,
        mapPreferenceList: null,
        error: "",
      };
      break;
    case MAP_PREFERENCE_CONST.MAP_PREFERENCE_SUCCESS:
      state = {
        ...state,
        loading: false,
        mapPreferenceList:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case MAP_PREFERENCE_CONST.MAP_PREFERENCE_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        mapPreferenceList: null,
      };
      break;
    case MAP_PREFERENCE_CONST.MAP_PREFERENCE_DATA_REQUEST:
      state = {
        ...state,

        isMapPreferenceBoundariesRequesting: true,
        mapPreferenceBoundariesData: false,
        mapPreferenceBoundariesDataError: false,
        mapPreferenceBoundariesDataNetworkError: false,
      };
      break;
    case MAP_PREFERENCE_CONST.MAP_PREFERENCE_DATA_SUCCESS:
      state = {
        ...state,

        isMapPreferenceBoundariesRequesting: false,
        mapPreferenceBoundariesData: action.payload,
        mapPreferenceBoundariesDataError: false,
        mapPreferenceBoundariesDataNetworkError: false,
      };
      break;
    case MAP_PREFERENCE_CONST.MAP_PREFERENCE_DATA_ERROR:
      state = {
        ...state,

        isMapPreferenceBoundariesRequesting: false,
        mapPreferenceBoundariesData: false,
        mapPreferenceBoundariesDataError: action.payload.error
          ? false
          : action.payload.response,
        mapPreferenceBoundariesDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
export default MapPreference;
