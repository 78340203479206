import READYNESSPHASE_PHASE_CONST from "./actionTypes";

export const editReadynessContentRequest = (payload) => {
  return {
    type: READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_EDIT_REQUEST,
    payload,
  };
};
export const editReadynessContentSuccess = (response) => {
  return {
    type: READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_EDIT_SUCCESS,
    payload: response,
  };
};
export const editReadynessContentError = (error) => {
  return {
    type: READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_EDIT_ERROR,
    payload: error,
  };
};

// CREATE READYNESS

export const createReadynessContentRequest = (payload) => {
  return {
    type: READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_CREATE_REQUEST,
    payload,
  };
};
export const createReadynessContentSuccess = (response) => {
  return {
    type: READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_CREATE_SUCCESS,
    payload: response,
  };
};
export const createReadynessContentError = (error) => {
  return {
    type: READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_CREATE_ERROR,
    payload: error,
  };
};

// DELETE READYNESS TABLE

export const deleteReadynessContentRequest = (payload) => {
  return {
    type: READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_DELETE_REQUEST,
    payload,
  };
};
export const deleteReadynessContentSuccess = (response) => {
  return {
    type: READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_DELETE_SUCCESS,
    payload: response,
  };
};
export const deleteReadynessContentError = (error) => {
  return {
    type: READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_DELETE_ERROR,
    payload: error,
  };
};

// Preapredness phase
export const getReadynessPhaseRequest = (payload) => {
  return {
    type: READYNESSPHASE_PHASE_CONST.GET_READYNESS_INTERVENTIONS_LIST_REQUEST,
    payload,
  };
};
export const getReadynessPhaseSuccess = (data) => {
  return {
    type: READYNESSPHASE_PHASE_CONST.GET_READYNESS_INTERVENTIONS_LIST_SUCCESS,
    payload: data,
  };
};
export const getReadynessPhaseError = (error) => {
  return {
    type: READYNESSPHASE_PHASE_CONST.GET_READYNESS_INTERVENTIONS_LIST_ERROR,
    payload: error,
  };
};

// intervention delete  phase
export const deleteInterventionRequest = (payload) => {
  return {
    type: READYNESSPHASE_PHASE_CONST.INTERVENTION_DELETE_REQUEST,
    payload,
  };
};
export const deleteInterventionSuccess = (data) => {
  return {
    type: READYNESSPHASE_PHASE_CONST.INTERVENTION_DELETE_SUCCESS,
    payload: data,
  };
};
export const deleteInterventionError = (error) => {
  return {
    type: READYNESSPHASE_PHASE_CONST.INTERVENTION_DELETE_ERROR,
    payload: error,
  };
};
