import React, { useMemo } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { useTranslation } from "react-i18next";
import { Row, Col, Table, Button } from "reactstrap";
import DisabledLeftArrowIcon from "../../assest/img/disable_previous_image.svg";
import DisabledRightArrowIcon from "../../assest/img/disable_next_image.svg";
import EnabledLeftArrowIcon from "../../assest/img/enable_previous_image.svg";
import EnabledRightArrowIcon from "../../assest/img/enable_next_image.svg";
import DetailsDropdown from "./DetailsDropdown";

function ManageSurveyorsTable(props) {
  const {
    loading = true,
    isLoading,
    req_data,
    handlePageSize,
    handlePageNumber,
    pageNumber,
    pageCount,
    handleLatestData,
    handleLatestDataForDelete,
    signedInUserDetails,
    signedInUser,
    userDetails,
    setIsLoading,
  } = props;
  const { t } = useTranslation();

  const COLUMNS = [
    {
      Header: t("ODK.EMAIL"),
      accessor: "email",
      sticky: "top",
    },
  ];
  const columns = useMemo(() => COLUMNS, []);

  const data = useMemo(() => req_data, [req_data]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    setPageSize,
    prepareRow,
  } = tableInstance;

  const pageSizeHandler = (e) => {
    setPageSize(Number(e.target.value));
    handlePageSize(Number(e.target.value));
  };

  const { pageSize } = state;
  const page_range = [10, 15, 20, 25];

  const handlepreviousPage = () => {
    handlePageNumber(pageNumber - 1);
  };
  const handlenextPage = () => {
    handlePageNumber(pageNumber + 1);
  };
  const handleGoToPage = (e) => {
    handlePageNumber(Number(e.target.value));
  };

  return (
    <div className="">
      <Table {...getTableProps()} className="table-list">
        <thead className="table-list-th tbl1">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="user-list-keys"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="name-list"
                  >
                    {column.render("Header")}
                  </th>
                );
              })}
              {/* <th style={{ width: '20%' }}>&nbsp;</th> */}
            </tr>
          ))}
        </thead>
        {loading ? (
          <tbody className="table-body">
            <tr>
              <td colSpan="10000" className="text-center">
                <img src="" alt="Loading..." />
              </td>
            </tr>
          </tbody>
        ) : page.length === 0 ? (
          <tbody>
            {isLoading ? (
              ""
            ) : (
              <tr>
                <td colSpan="4" style={{ textAlign: "center", width: "100%" }}>
                  {t("ODK.NO_SURVEYOR_FOUND")}
                </td>
              </tr>
            )}
          </tbody>
        ) : (
          <tbody
            {...getTableBodyProps()}
            className="body table-wrapper-scroll-y my-custom-scrollbar"
          >
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="user-list-details"
                  style={{ marginBottom: "20px" }}
                >
                  {row.cells.map((cell) => {
                    return (
                      cell.column.Header === t("ODK.EMAIL") && (
                        <>
                          <td
                            {...cell.getCellProps()}
                            //   style={{ width: '23%' , textAlign:"center" }}
                            // style={{ width: '90%' , textAlign:"center" }}
                            style={{ width: "84%" }}
                            className="  tbl-md pdngLft1"
                          >
                            {cell.render("Cell")}
                          </td>
                          <td style={{ width: "15%" }}>
                            {" "}
                            <DetailsDropdown
                              request_Id={row.original.email}
                              handleLatestData={handleLatestData}
                              handleLatestDataForDelete={
                                handleLatestDataForDelete
                              }
                              email={cell.row.cells?.[0]?.value}
                              signedInUser={signedInUser}
                              signedInUserDetails={signedInUserDetails}
                              userDetails={userDetails}
                              setIsLoading={setIsLoading}
                            />
                          </td>
                        </>
                      )
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
      {pageSize.length >= 10 && (
        <div className="pagination-section">
          <Row>
            <Col md="6" lg="6">
              <span className="item-text">{t("ODK.ITEMS_PER_PAGE")}</span>
              <select
                value={pageSize}
                onChange={(e) => pageSizeHandler(e)}
                className="page-count"
              >
                {page_range.map((page_size) => (
                  <option key={page_size} value={page_size}>
                    {page_size}
                  </option>
                ))}
              </select>
              <span className="num-of-items">
                {(pageNumber - 1) * pageSize + 1}-
                {pageCount < pageNumber * pageSize
                  ? pageCount
                  : pageNumber * pageSize}{" "}
                {t("ODK.OF")} {pageCount || ""} {t("ODK.ITEMS")}
              </span>
            </Col>
            <Col md="6" lg="6">
              <div className="pagination-right-col">
                <span>
                  <input
                    type="number"
                    value={pageNumber}
                    onChange={(e) => {
                      handleGoToPage(e);
                    }}
                    disabled={
                      !!(
                        (pageCount % pageSize
                          ? Math.trunc(pageCount / pageSize + 1)
                          : Math.trunc(pageCount / pageSize)) === pageNumber ||
                        (pageCount % pageSize
                          ? Math.trunc(pageCount / pageSize + 1)
                          : Math.trunc(pageCount / pageSize)) === 0
                      )
                    }
                    style={{ width: "50px", border: "none" }}
                  />
                </span>
                <span>
                  {t("ODK.OF")}{" "}
                  {pageCount % pageSize
                    ? Math.trunc(pageCount / pageSize + 1)
                    : Math.trunc(pageCount / pageSize)}{" "}
                  {t("ODK.PAGES")}
                </span>
                <span>
                  <Button
                    onClick={() => handlepreviousPage()}
                    className="previous-btn"
                    disabled={pageNumber <= 1}
                  >
                    {pageNumber <= 1 ? (
                      <img src={DisabledLeftArrowIcon} alt="not-available" />
                    ) : (
                      <img src={EnabledLeftArrowIcon} alt="not-available" />
                    )}
                  </Button>
                  <Button
                    onClick={() => handlenextPage()}
                    className="next-btn"
                    disabled={
                      !!(
                        (pageCount % pageSize
                          ? Math.trunc(pageCount / pageSize + 1)
                          : Math.trunc(pageCount / pageSize)) === pageNumber ||
                        (pageCount % pageSize
                          ? Math.trunc(pageCount / pageSize + 1)
                          : Math.trunc(pageCount / pageSize)) === 0
                      )
                    }
                  >
                    {(pageCount % pageSize
                      ? Math.trunc(pageCount / pageSize + 1)
                      : Math.trunc(pageCount / pageSize)) === pageNumber ||
                    (pageCount % pageSize
                      ? Math.trunc(pageCount / pageSize + 1)
                      : Math.trunc(pageCount / pageSize)) === 0 ? (
                      <img src={DisabledRightArrowIcon} alt="not-available" />
                    ) : (
                      <img src={EnabledRightArrowIcon} alt="not-available" />
                    )}
                  </Button>
                </span>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default ManageSurveyorsTable;
