import CROP_YIELD_CONST from "./actionTypes";

export const cropYieldOptionsRequest = (data) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_OPTIONS_REQUEST,
    payload: data,
  };
};
export const cropYieldOptionsSuccess = (user) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_OPTIONS_SUCCESS,
    payload: user,
  };
};
export const cropYieldOptionsError = (error) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_OPTIONS_ERROR,
    payload: error,
  };
};

export const cropYieldPastDataRequest = (data) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_PAST_DATA_REQUEST,
    payload: data,
  };
};
export const cropYieldPastDataSuccess = (user) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_PAST_DATA_SUCCESS,
    payload: user,
  };
};
export const cropYieldPastDataError = (error) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_PAST_DATA_ERROR,
    payload: error,
  };
};

// SUMMARY ACTIONS

export const cropYieldSummaryRequest = (data) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_SUMMARY_REQUEST,
    payload: data,
  };
};
export const cropYieldSummarySuccess = (user) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_SUMMARY_SUCCESS,
    payload: user,
  };
};
export const cropYieldSummaryError = (error) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_SUMMARY_ERROR,
    payload: error,
  };
};

// START AND END DATE ACTIONS

export const cropStartEndDateRequest = (data) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_START_END_DATE_REQUEST,
    payload: data,
  };
};
export const cropYieldStartEndDateSuccess = (user) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_START_END_DATE_SUCCESS,
    payload: user,
  };
};
export const cropYieldStartEndDateError = (error) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_START_END_DATE_ERROR,
    payload: error,
  };
};

// START AND END DATE ACTIONS

export const cropYieldPredectionRequest = (data) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_PREDECTION_REQUEST,
    payload: data,
  };
};
export const cropYieldPredectionSuccess = (user) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_PREDECTION_SUCCESS,
    payload: user,
  };
};

export const cropYieldPredectionError = (user) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_PREDECTION_ERROR,
    payload: user,
  };
};

export const cropYieldPredectionDateError = (error) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_START_END_DATE_ERROR,
    payload: error,
  };
};

// CROP AND SEASON LIST

export const cropYieldCropListRequest = (data) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_CROP_LIST_REQUEST,
    payload: data,
  };
};
export const cropYieldCropListSuccess = (user) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_CROP_LIST_SUCCESS,
    payload: user,
  };
};
export const cropYieldCropListDateError = (error) => {
  return {
    type: CROP_YIELD_CONST.CROP_YIELD_CROP_LIST_ERROR,
    payload: error,
  };
};

// FEEDBACK
export const cropYieldFeedbackRequest = (data) => {
  return {
    type: CROP_YIELD_CONST.CROP_YEILD_FEEDBACK_REQUEST,
    payload: data,
  };
};
export const cropYieldFeedbackSuccess = (user) => {
  return {
    type: CROP_YIELD_CONST.CROP_YEILD_FEEDBACK_SUCCESS,
    payload: user,
  };
};
export const cropYieldFeedbackError = (error) => {
  return {
    type: CROP_YIELD_CONST.CROP_YEILD_FEEDBACK_ERROR,
    payload: error,
  };
};

// GET FEEDBACK
export const getCropYieldFeedbackRequest = (data) => {
  return {
    type: CROP_YIELD_CONST.GET_CROP_YEILD_FEEDBACK_REQUEST,
    payload: data,
  };
};
export const getCropYieldFeedbackSuccess = (user) => {
  return {
    type: CROP_YIELD_CONST.GET_CROP_YEILD_FEEDBACK_SUCCESS,
    payload: user,
  };
};
export const getCropYieldFeedbackError = (error) => {
  return {
    type: CROP_YIELD_CONST.GET_CROP_YEILD_FEEDBACK_ERROR,
    payload: error,
  };
};

//  CROP CALENDER
export const getCropCalenderRequest = (data) => {
  return {
    type: CROP_YIELD_CONST.GET_CROP_CALENDER_REQUEST,
    payload: data,
  };
};
export const getCropCalenderSuccess = (user) => {
  return {
    type: CROP_YIELD_CONST.GET_CROP_CALENDER_SUCCESS,
    payload: user,
  };
};
export const getCropCalenderError = (error) => {
  return {
    type: CROP_YIELD_CONST.GET_CROP_CALENDER_ERROR,
    payload: error,
  };
};
