import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import { htmlToText } from "html-to-text";
import moment from "moment";
import { DISPLAY_DATE_FORMAT } from "../constants";

export const handleExportToCSVExcelImpacts = async (tableData, ColumnsData) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("data");

  // Define column headers
  worksheet.columns = ColumnsData;

  // Apply bold style to header row
  const headerRow = worksheet.getRow(1);
  headerRow.font = { bold: true };

  tableData.forEach((item) => {
    worksheet.addRow({
      id: item.id + 1,
      directimpacts: htmlToText(item.directImpacts[0], {
        unorderedListItemPrefix: "- ", // Set the prefix for unordered list items
      }),
      indirectimpacts: htmlToText(item.inDirectImpacts?.[0]),
    });
  });

  //  Enable text wrapping for the Respective column
  ColumnsData.forEach((item) => {
    worksheet.getColumn(item?.key).alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: item?.key === "id" ? "center" : "left",
    };
  });

  ColumnsData.forEach((column, columnIndex) => {
    headerRow.getCell(columnIndex + 1).alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
  });

  // Generate Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  const sheetData = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  FileSaver.saveAs(sheetData, `EA_PP_Impact.xlsx`);
};

export const handleExportToCSVExcelPaa = async (tableData, ColumnsData) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("APP");

  // Define column headers
  worksheet.columns = ColumnsData;

  // Apply bold style to header row
  const headerRow = worksheet.getRow(1);
  headerRow.font = { bold: true };

  // key names should match the key in column header
  tableData.forEach((item) => {
    worksheet.addRow({
      id: item.id + 1,
      directImpacts: htmlToText(item.directImpacts[0], {
        unorderedListItemPrefix: "- ", // Set the prefix for unordered list items
      }),
      inDirectImpacts: htmlToText(item.inDirectImpacts?.[0]),
      PAA: htmlToText(item.anticipatoryActions[0]),
    });
  });

  //  Enable text wrapping for the Respective column
  ColumnsData.forEach((item) => {
    worksheet.getColumn(item?.key).alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: item?.key === "id" ? "center" : "left",
    };
  });

  ColumnsData.forEach((column, columnIndex) => {
    headerRow.getCell(columnIndex + 1).alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
  });

  // Generate Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  const sheetData = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  FileSaver.saveAs(sheetData, `EA_APP_CATEGORY.xlsx`);
};

export const handleExportToCSVExcelAap = async (tableData, ColumnsData) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("APP");
  // Define column headers
  worksheet.columns = ColumnsData;

  // Apply bold style to header row
  const headerRow = worksheet.getRow(1);
  headerRow.font = { bold: true };

  // key names should match the key in column header
  tableData.forEach((item) => {
    worksheet.addRow({
      id: item.id + 1,
      objective: htmlToText(item.objective[0], {
        unorderedListItemPrefix: "- ", // Set the prefix for unordered list items Anticipatory Action Preparedness Phase
      }),

      AAPPhase: htmlToText(item.anticipatoryActionPhase[0], {
        unorderedListItemPrefix: "- ", // Set the prefix for unordered list items Anticipatory Action Preparedness Phase
      }),

      ARAPhase: htmlToText(item.readinessActivated[0], {
        unorderedListItemPrefix: "- ", // Set the prefix for unordered list items Anticipatory Action Preparedness Phase
      }),

      actionActivated: htmlToText(item.actionActivated[0], {
        unorderedListItemPrefix: "- ", // Set the prefix for unordered list items Anticipatory Action Preparedness Phase
      }),
    });
  });

  //  Enable text wrapping for the Respective column
  ColumnsData.forEach((item) => {
    worksheet.getColumn(item?.key).alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: item?.key === "id" ? "center" : "left",
    };
  });

  ColumnsData.forEach((column, columnIndex) => {
    headerRow.getCell(columnIndex + 1).alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
  });

  // Generate Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  const sheetData = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  FileSaver.saveAs(sheetData, `EA_APP_CATEGORY.xlsx`);
};

//  intervention
export const handleExportToCSVIntervantion = async (
  tableData,
  ColumnsData,
  fileNmae,
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(fileNmae);
  // Define column headers
  worksheet.columns = ColumnsData;

  // Apply bold style to header row
  const headerRow = worksheet.getRow(1);
  headerRow.font = { bold: true };

  // key names should match the key in column header
  tableData.forEach((item, index) => {
    worksheet.addRow({
      id: index + 1,
      Activities: item?.Activities,
      Target: item?.Target,
      Timeframe: item?.Timeframe,
    });
  });

  //  Enable text wrapping for the Respective column
  ColumnsData.forEach((item) => {
    worksheet.getColumn(item?.key).alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: item?.key === "id" ? "center" : "left",
    };
  });

  ColumnsData.forEach((column, columnIndex) => {
    headerRow.getCell(columnIndex + 1).alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
  });

  // Generate Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  const sheetData = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  FileSaver.saveAs(sheetData, fileNmae);
};

export const handleExportToCSVFinance = async (
  tableData,
  ColumnsData,
  fileNmae,
  currency,
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(fileNmae);
  // Define column headers
  worksheet.columns = ColumnsData;

  // Apply bold style to header row
  const headerRow = worksheet.getRow(1);
  headerRow.font = { bold: true };

  // key names should match the key in column header
  tableData.forEach((item, index) => {
    worksheet.addRow({
      id: index + 1,
      Activities: item?.Activities,
      Target: item?.Target,
      Timeframe: item?.Timeframe,
      Budget: item.Budget ? `${currency} ${item?.Budget} ` : `${currency} 0 `,
    });
  });

  //  Enable text wrapping for the Respective column
  ColumnsData.forEach((item) => {
    worksheet.getColumn(item?.key).alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: item?.key === "id" ? "center" : "left",
    };
  });

  ColumnsData.forEach((column, columnIndex) => {
    headerRow.getCell(columnIndex + 1).alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
  });

  // Generate Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  const sheetData = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  FileSaver.saveAs(sheetData, fileNmae);
};

export const handleExportToCSVOpenWeather = async (
  tableData,
  ColumnsData,
  fileNmae,
  isRapidResponse,
) => {
  const date1 = moment(new Date()).format(DISPLAY_DATE_FORMAT);
  const allSeries = tableData.map((series) => ({
    name: series.name,
    data: series.data.map((point) => point),
  }));
  const xAxisData = ColumnsData;

  // Create a workbook and add a worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(fileNmae || "Chart Data");

  // Set a fixed width of 20 for the first column (x-axis data)
  worksheet.getColumn(1).width = 20;

  // Add x-axis data to the worksheet
  worksheet.getColumn(1).values = [isRapidResponse || "Dates", ...xAxisData];

  // Add series data to the worksheet
  allSeries.forEach((series, index) => {
    const colIndex = index + 2; // Offset to skip the x-axis column
    worksheet.getColumn(colIndex).values = [series.name, ...series.data];
    worksheet.getColumn(colIndex).width = 20; // Set a fixed width for the series columns
  });

  // Generate a Blob from the workbook
  workbook.xlsx.writeBuffer().then((buffer) => {
    const excelBlob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(excelBlob);
    link.download = `${fileNmae} ${date1}` || "chart_data.xlsx";
    link.click();
  });
};
