import REGISTER_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};
const Register = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case REGISTER_CONST.PROVIENCE_REQUEST:
      state = {
        ...state,
        loading: true,
        provienceList: null,
        error: "",
      };
      break;
    case REGISTER_CONST.PROVIENCE_SUCCESS:
      state = {
        ...state,
        loading: false,
        provienceList:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case REGISTER_CONST.PROVIENCE_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.message : "",
        loading: false,
        provienceList: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
export default Register;
