import React from "react";
import { useTranslation } from "react-i18next";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { saveAs } from "file-saver";
import { Row, Col } from "reactstrap";
// import ParameterList from "./ParameterList";
import DownloadIcon from "../../assest/img/Static-Download.png";
import ZoomInIcon from "../../assest/img/Zoom_In.svg";
import ZoomOutIcon from "../../assest/img/Zoom-Out.svg";
import HomeIcon from "../../assest/img/Home.svg";

function StaticResource(props) {
  const { ecmwfImage, ecmwfImaheLoading } = props;
  const { t } = useTranslation();

  // For downloading image
  const handleDownload = async (imageUrl) => {
    const filename = imageUrl.split("/");
    saveAs(imageUrl, filename[filename?.length - 1]);
  };

  return (
    <div className="static-resource-container">
      <div className="static-resource-section">
        <TransformWrapper>
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              {ecmwfImage ? (
                <div className="tools">
                  <span className="home-img">
                    <img
                      src={HomeIcon}
                      alt="home"
                      onClick={() => resetTransform()}
                      onKeyDown={() => {}}
                      style={{ marginTop: "8px" }}
                    />
                  </span>
                  <span>
                    <img
                      src={ZoomInIcon}
                      alt="not-available"
                      onClick={() => zoomIn()}
                      onKeyDown={() => {}}
                    />
                  </span>
                  <span>
                    <img
                      src={ZoomOutIcon}
                      alt="not-available"
                      onClick={() => zoomOut()}
                      onKeyDown={() => {}}
                    />
                  </span>
                  <img
                    className="download-img"
                    src={DownloadIcon}
                    alt="not-available"
                    onClick={() => handleDownload(ecmwfImage)}
                    onKeyUp={() => {}}
                  />
                </div>
              ) : (
                ""
              )}
              <Row>
                <Col sm={12}>
                  <TransformComponent>
                    {ecmwfImage ? (
                      <img width="95%" src={ecmwfImage} alt="not-available" />
                    ) : (
                      <p className="no-static-record-found mt-5">
                        {ecmwfImaheLoading
                          ? ""
                          : t("CLIMATE_WEATHER_FILTER.DATA_NOT_FOUND")}
                      </p>
                    )}
                  </TransformComponent>
                </Col>
              </Row>
            </>
          )}
        </TransformWrapper>
      </div>
    </div>
  );
}

export default StaticResource;
