import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import { AiFillExclamationCircle } from "react-icons/ai";

function DeleteModal({ toggle, modal, onConfirmDelete, message }) {
  const { t } = useTranslation();
  return (
    <div>
      <Modal isOpen={modal} size="md">
        <ModalHeader className="p-0 delete-header user-flex-end mr-2">
          <GrFormClose size={30} onClick={() => toggle()} className="pointer" />
        </ModalHeader>
        <ModalBody className="delete-modal-padding">
          <div className="text-center mb-2">
            <AiFillExclamationCircle className="mb-3 exclamation-icon" />

            <p className="mt-1">
              {" "}
              <b> {t("DELETE_MODAL.ARE_YOU_SURE")} </b>
            </p>

            <p className="mt-2">{message}</p>
          </div>
          <div className="text-center mt-4 ">
            <Button onClick={() => toggle()} className="btn-cancel mr-2">
              {t("DELETE_MODAL.CANCEL")}
            </Button>{" "}
            <Button
              className="btn-news-feed-submit ml-2"
              onClick={() => onConfirmDelete()}
            >
              {t("DELETE_MODAL.DELETE")}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default DeleteModal;
