import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  addBulletinFailed,
  addBulletinSuccess,
  getAllBulletinFailed,
  getAllBulletinSuccess,
  getAllTemplateListFailed,
  getAllTemplateListSuccess,
  addTemplateSuccess,
  addTemplateFailed,
  getTemplateSuccess,
  getTemplateFailed,
  updateTemplateFailed,
  updateTemplateSuccess,
  approveRejectBulletinSuccess,
  approveRejectBulletinFailed,
  updateBulletinSuccess,
  updateBulletinFailed,
  bulletinPublishSuccess,
  bulletinPublishFailed,
  bulletinDeleteSuccess,
  bulletinDeleteFailed,
  previewBulletinFailed,
  previewBulletinSuccess,
  subscribeBulletinFailed,
  subscribeBulletinSuccess,
  previewTemplateSuccess,
  previewTemplateFailed,
  templateDeleteFailed,
  templateDeleteSuccess,
} from "./bulletinActions";
import BULLETIN_CONSTS from "./bulletinConsts";
import {
  AXIOS_INSTANCE,
  BULLETIN_URL,
  //  BACK_OFFICE_URL,
} from "../apiUtils/config";

const subscribeBulletinApiRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${BULLETIN_URL}/subscription`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* subscribeBulletinRequest(action) {
  try {
    const apiResponse = yield call(subscribeBulletinApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(subscribeBulletinSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(subscribeBulletinFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(subscribeBulletinFailed(errorData));
  }
}

const previewBulletinApiRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.get(
      `${BULLETIN_URL}/preview/${payload}`,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* previewBulletin(action) {
  try {
    const response = yield call(previewBulletinApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(previewBulletinSuccess(responseData));
    } else {
      const responseData = {
        response: { statusCode: 200, response: response.error },
      };
      yield put(previewBulletinFailed(responseData));
    }
  } catch (error) {
    const errorData = { error: { statusText: error, netWorkError: true } };
    yield put(previewBulletinFailed(errorData));
  }
}

const previewTemplateApiRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.get(
      `${BULLETIN_URL}/templatePreview/${payload}`,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* previewTemplate(action) {
  try {
    const response = yield call(previewTemplateApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(previewTemplateSuccess(responseData));
    } else {
      const responseData = {
        response: { statusCode: 200, response: response.error },
      };
      yield put(previewTemplateFailed(responseData));
    }
  } catch (error) {
    const errorData = { error: { statusText: error, netWorkError: true } };
    yield put(previewTemplateFailed(errorData));
  }
}

const deleteBulletinApiRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.delete(
      `${BULLETIN_URL}/delete/${payload}`,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* deleteBulletin(action) {
  try {
    const response = yield call(deleteBulletinApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(bulletinDeleteSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(bulletinDeleteFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(bulletinDeleteFailed(errorData));
  }
}

const publishAPIRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.put(
      `${BULLETIN_URL}/changeStatus`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* bulletinPublish(action) {
  try {
    const response = yield call(publishAPIRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(bulletinPublishSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(bulletinPublishFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(bulletinPublishFailed(errorData));
  }
}

const addBulletinApiRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${BULLETIN_URL}/add`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* addBulletin(action) {
  try {
    const response = yield call(addBulletinApiRequest, action.payload);
    if (response?.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(addBulletinSuccess(responseData));
    } else if (response?.status === 400) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(addBulletinFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(addBulletinFailed(errorData));
  }
}

const allBulletinApiRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${BULLETIN_URL}/getAllByCriteria${
        payload.page ? `?page=${payload.page}` : ""
      }`,
      payload.data,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getAllBulletinRequest(action) {
  try {
    const response = yield call(allBulletinApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(getAllBulletinSuccess(responseData));
    } else {
      const responseData = {
        response: { statusCode: 200, response: response.error },
      };
      yield put(getAllBulletinFailed(responseData));
    }
  } catch (error) {
    const errorData = { error: { statusText: error, netWorkError: true } };
    yield put(getAllBulletinFailed(errorData));
  }
}

const getAllTemplateListApiRequest = async () => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.get(
      `${BULLETIN_URL}/getAllTemplateCategories`,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getAllTemplateList() {
  try {
    const response = yield call(getAllTemplateListApiRequest);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(getAllTemplateListSuccess(responseData));
    } else {
      const responseData = {
        response: { statusCode: 200, response: response.error },
      };
      yield put(getAllTemplateListFailed(responseData));
    }
  } catch (error) {
    const errorData = { error: { statusText: error, netWorkError: true } };
    yield put(getAllTemplateListFailed(errorData));
  }
}

const addTemplateApiRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${BULLETIN_URL}/addTemplate`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* addTemplate(action) {
  try {
    const response = yield call(addTemplateApiRequest, action.payload);
    if (response?.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(addTemplateSuccess(responseData));
    } else if (response?.status === 400) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(addTemplateFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(addTemplateFailed(errorData));
  }
}

const getTemplateApiRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${BULLETIN_URL}/getTemplates${
        payload.page ? `?page=${payload.page}` : ""
      }`,
      payload.data,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getTemplate(action) {
  try {
    const response = yield call(getTemplateApiRequest, action.payload);
    if (response?.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(getTemplateSuccess(responseData));
    } else if (response?.status === 400) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(getTemplateFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getTemplateFailed(errorData));
  }
}

const updateTemplateApiRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.put(
      `${BULLETIN_URL}/updateTemplate`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* updateTemplate(action) {
  try {
    const response = yield call(updateTemplateApiRequest, action.payload);
    if (response?.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(updateTemplateSuccess(responseData));
    } else if (response?.status === 400) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(updateTemplateFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(updateTemplateFailed(errorData));
  }
}

const approveRejectBulletinApiRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${BULLETIN_URL}/approveOrReject`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* approveRejectBulletin(action) {
  try {
    const response = yield call(
      approveRejectBulletinApiRequest,
      action.payload,
    );
    if (response?.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(approveRejectBulletinSuccess(responseData));
    } else if (response?.status === 400) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(approveRejectBulletinFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(approveRejectBulletinFailed(errorData));
  }
}

const updateBulletinApiRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.put(
      `${BULLETIN_URL}/update`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* updateBulletin(action) {
  try {
    const response = yield call(updateBulletinApiRequest, action.payload);
    if (response?.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(updateBulletinSuccess(responseData));
    } else if (response?.status === 400) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(updateBulletinFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(updateBulletinFailed(errorData));
  }
}

const deleteTemplateApiRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.delete(
      `${BULLETIN_URL}/deleteTemplate/${payload}`,
    ); // set the template delete url
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* deleteTemplate(action) {
  try {
    const response = yield call(deleteTemplateApiRequest, action.payload);
    if (response?.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(templateDeleteSuccess(responseData));
    } else if (response?.status === 400) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(templateDeleteFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(templateDeleteFailed(errorData));
  }
}

function* watchBulletin() {
  yield takeEvery(BULLETIN_CONSTS.ADD_BULLETIN_REQUEST, addBulletin);
  yield takeEvery(
    BULLETIN_CONSTS.GET_ALL_BULLETIN_REQUEST,
    getAllBulletinRequest,
  );
  yield takeEvery(
    BULLETIN_CONSTS.GET_ALL_TEMPLATE_LIST_REQUEST,
    getAllTemplateList,
  );
  yield takeEvery(BULLETIN_CONSTS.ADD_TEMPLATE_REQUEST, addTemplate);
  yield takeEvery(BULLETIN_CONSTS.GET_TEMPLATE_REQUEST, getTemplate);
  yield takeEvery(BULLETIN_CONSTS.UPDATE_TEMPLATE_REQUEST, updateTemplate);
  yield takeEvery(
    BULLETIN_CONSTS.APPROVE_REJECT_BULLETIN_REQUEST,
    approveRejectBulletin,
  );
  yield takeEvery(BULLETIN_CONSTS.UPDATE_BULLETIN_REQUEST, updateBulletin);
  yield takeEvery(BULLETIN_CONSTS.BULLETIN_PUBLISH_REQUEST, bulletinPublish);
  yield takeEvery(BULLETIN_CONSTS.BULLETIN_DELETE_REQUEST, deleteBulletin);
  yield takeEvery(BULLETIN_CONSTS.PREVIEW_BULLETIN_REQUEST, previewBulletin);
  yield takeEvery(
    BULLETIN_CONSTS.BULLETIN_SUBSCRIBE_REQUEST,
    subscribeBulletinRequest,
  );
  yield takeEvery(BULLETIN_CONSTS.PREVIEW_TEMPLATE_REQUEST, previewTemplate);
  yield takeEvery(BULLETIN_CONSTS.TEMPLATE_DELETE_REQUEST, deleteTemplate);
}
function* bulletinSaga() {
  yield all([fork(watchBulletin)]);
}
export default bulletinSaga;
