export const STATIC_RESOURCE_CONST = {
  STATIC_RESOURCE_REQUEST: "STATIC_RESOURCE_REQUEST",
  STATIC_RESOURCE_SUCCESS: "STATIC_RESOURCE_SUCCESS",
  STATIC_RESOURCE_FAILED: "STATIC_RESOURCE_FAILED",
};

export const STATIC_RESOURCE_ADD_CONST = {
  STATIC_RESOURCE_ADD_REQUEST: "STATIC_RESOURCE_ADD_REQUEST",
  STATIC_RESOURCE_ADD_SUCCESS: "STATIC_RESOURCE_ADD_SUCCESS",
  STATIC_RESOURCE_ADD_FAILED: "STATIC_RESOURCE_ADD_FAILED",
};

export const STATIC_RESOURCE_ALL_DATE_CONST = {
  STATIC_RESOURCE_ALL_DATE_REQUEST: "STATIC_RESOURCE_ALL_DATE_REQUEST",
  STATIC_RESOURCE_ALL_DATE_SUCCESS: "STATIC_RESOURCE_ALL_DATE_SUCCESS",
  STATIC_RESOURCE_ALL_DATE_FAILED: "STATIC_RESOURCE_ALL_DATE_FAILED",
};

export const UPDATE_STATIC_RESOURCE_CONST = {
  UPDATE_STATIC_RESOURCE_REQUEST: "UPDATE_STATIC_RESOURCE_REQUEST",
  UPDATE_STATIC_RESOURCE_SUCCESS: "UPDATE_STATIC_RESOURCE_SUCCESS",
  UPDATE_STATIC_RESOURCE_FAILED: "UPDATE_STATIC_RESOURCE_FAILED",
};

export const DELETE_STATIC_RESOURCE_CONST = {
  DELETE_STATIC_RESOURCE_REQUEST: "DELETE_STATIC_RESOURCE_REQUEST",
  DELETE_STATIC_RESOURCE_SUCCESS: "DELETE_STATIC_RESOURCE_SUCCESS",
  DELETE_STATIC_RESOURCE_FAILED: "DELETE_STATIC_RESOURCE_FAILED",
};

export const STATIC_RESOURCE_YEAR_CONST = {
  STATIC_RESOURCE_YEAR_REQUEST: "STATIC_RESOURCE_YEAR_REQUEST",
  STATIC_RESOURCE_YEAR_SUCCESS: "STATIC_RESOURCE_YEAR_SUCCESS",
  STATIC_RESOURCE_YEAR_FAILED: "STATIC_RESOURCE_YEAR_FAILED",
};
