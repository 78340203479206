import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import WEATHER_CONST from "./actionTypes";
import {
  getWeatherForecastSourcesSuccess,
  getWeatherForecastSourcesFailed,
  getIRIAvailableDatesDataSuccess,
  getIRIAvailableDatesDataFailed,
  getWeatherForecastDataSuccess,
  getWeatherForecastDataFailed,
  getAddTimeSeriesSuccess,
  getAddTimeSeriesError,
  getStaticResourceDataSuccess,
  getStaticResourceDataFailed,
  getStaticResourceIRIImageFailed,
  getStaticResourceIRIImageSuccess,
} from "./action";
import checkHttpStatus from "../apiUtils";
import {
  WEATHER_FORECAST_URL,
  AXIOS_INSTANCE,
  AXIOS_INSTANCE_LOADER,
} from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

// forest data sources by country Id
function* getWeatherForecastSources(action) {
  AXIOS_INSTANCE_LOADER.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.get(
        `${WEATHER_FORECAST_URL}/get-forecast-data-sources/${action.payload.country_id}`,
      ),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getWeatherForecastSourcesSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getWeatherForecastSourcesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getWeatherForecastSourcesFailed(errorData));
  }
}

// forest data sources by country Id
function* getIRIAvailableDatesDataRequest() {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${WEATHER_FORECAST_URL}/iri/available_dates`),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getIRIAvailableDatesDataSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getIRIAvailableDatesDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getIRIAvailableDatesDataFailed(errorData));
  }
}

function* getWeatherForecastData(action) {
  try {
    const apiResponse = action?.payload?.requestObj?.loader
      ? baseMethod(
          AXIOS_INSTANCE_LOADER.post(
            `${WEATHER_FORECAST_URL}/data`,
            action?.payload?.requestObj,
          ),
          "",
          "",
        )
      : baseMethod(
          AXIOS_INSTANCE.post(
            `${WEATHER_FORECAST_URL}/data`,
            action?.payload?.requestObj,
          ),
          "",
          "",
        );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getWeatherForecastDataSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getWeatherForecastDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getWeatherForecastDataFailed(errorData));
  }
}

function* addTimeseriesData(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(
        `${WEATHER_FORECAST_URL}/${action.payload.Url}/timeseries`,
        action.payload.data,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getAddTimeSeriesSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(getAddTimeSeriesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAddTimeSeriesError(errorData));
  }
}

function* getStaticResourceData(action) {
  AXIOS_INSTANCE_LOADER.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  // controls
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.get(
        `${WEATHER_FORECAST_URL}/ecmwf/static/controls/${action.payload.paramter_slug}`,
      ),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getStaticResourceDataSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getStaticResourceDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getStaticResourceDataFailed(errorData));
  }
}

function* getIriControlsImageData(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${WEATHER_FORECAST_URL}/ecmwf/static/data_by_controls?${action?.payload?.url}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getStaticResourceIRIImageSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(getStaticResourceIRIImageFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getStaticResourceIRIImageFailed(errorData));
  }
}

export function* watchWeatherDataSources() {
  yield takeEvery(
    WEATHER_CONST.GET_FORECAST_DATA_SOURCES_REQUEST,
    getWeatherForecastSources,
  );
}
export function* watchIRIAvailableDates() {
  yield takeEvery(
    WEATHER_CONST.IRI_AVAILABLE_DATES_REQUEST,
    getIRIAvailableDatesDataRequest,
  );
}
export function* watchWeatherForecastData() {
  yield takeEvery(
    WEATHER_CONST.GET_FORECAST_DATA_REQUEST,
    getWeatherForecastData,
  );
}
export function* watchAddTimeseriesData() {
  yield takeEvery(WEATHER_CONST.ADD_TIMESERIES_REQUEST, addTimeseriesData);
}

export function* watchGetIriControlsImageData() {
  yield takeEvery(
    WEATHER_CONST.GET_IRI_IMAGE_DATA_REQUEST,
    getIriControlsImageData,
  );
}

export function* watchStaticResourceData() {
  yield takeEvery(
    WEATHER_CONST.GET_STATIC_RESOURCE_DATA_REQUEST,
    getStaticResourceData,
  );
}
function* WeatherSaga() {
  yield all([
    fork(watchWeatherDataSources),
    fork(watchIRIAvailableDates),
    fork(watchWeatherForecastData),
    fork(watchAddTimeseriesData),
    fork(watchStaticResourceData),
    fork(watchGetIriControlsImageData),
  ]);
}
export default WeatherSaga;
