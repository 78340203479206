import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Label, Input } from "reactstrap";
import ReactTooltip from "react-tooltip";
import {
  countryBoundarySuccess,
  stateBoundarySuccess,
} from "../../store/actions";

export default function RadioSubBasin({
  adminRadio,
  setAdminRadio,
  selectedCountry,
  disableBasin,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onChangeBasinSelection = (e) => {
    if (e.target.value === "basin") {
      dispatch(countryBoundarySuccess({}));
      dispatch(stateBoundarySuccess({}));
    }

    setAdminRadio(e.target.value);
  };
  return (
    <div className="sub-basin">
      <div className="radios" onChange={onChangeBasinSelection}>
        <Label className="label-item" check>
          <Input
            type="radio"
            className="radio-admin"
            name="mapBasinSelection"
            value="admin"
            checked={adminRadio === "admin"}
          />
          <span className="spanmap spanmap-1">{t("DASHBOARD.ADMIN")}</span>
        </Label>
        <Label className="label-item" check data-tip data-for="basin-tool-tip">
          <Input
            type="radio"
            className="radio-basin"
            name="mapBasinSelection"
            value="basin"
            disabled={
              !selectedCountry?.basin_feature_collection?.length || disableBasin
            }
            checked={adminRadio === "basin"}
          />
          <span className="spangraph-basin spanmap-1">
            {t("DASHBOARD.BASIN")}
          </span>
        </Label>
        {!selectedCountry?.basin_feature_collection?.length ? (
          <ReactTooltip
            effect="float"
            place="bottom"
            id="basin-tool-tip"
            arrowColor="#22ad7a"
            className="map-prefer-tooltip-st"
          >
            {t("DASHBOARD.COMING_SOON")}
          </ReactTooltip>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
