import PERMISSIONS_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};

const Permissions = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }

  switch (action.type) {
    case PERMISSIONS_CONST.GET_ALL_PERMISSIONS_REQUEST:
      state = {
        ...state,
        loading: true,
        otherToolsListData: null,
        error: "",
      };
      break;
    case PERMISSIONS_CONST.GET_ALL_PERMISSIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        otherToolsListData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case PERMISSIONS_CONST.GET_ALL_PERMISSIONS_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        otherToolsListData: null,
      };
      break;
    //  assign permissions
    case PERMISSIONS_CONST.ASSIGN_USER_REQUEST:
      state = {
        ...state,
        loading: true,
        isLoading: true,
        userListData: null,
        error: "",
      };
      break;
    case PERMISSIONS_CONST.ASSIGN_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isLoading: false,
        assignPermissions:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case PERMISSIONS_CONST.ASSIGN_USER_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        isLoading: false,
        assignPermissions: null,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Permissions;
