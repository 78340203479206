const USER_MANAGE_GUIDE_CONSTS = {
  // manage user guide

  GET_All_MANAGE_USER_GUIDE_LIST_REQUEST:
    "GET_All_MANAGE_USER_GUIDE_LIST_REQUEST",
  GET_All_MANAGE_USER_GUIDE_LIST_SUCCESS:
    "GET_All_MANAGE_USER_GUIDE_LIST_SUCCESS",
  GET_All_MANAGE_USER_GUIDE_LIST_FAILED:
    "GET_All_MANAGE_USER_GUIDE_LIST_FAILED",

  // manage user all sub topics

  GET_All_MANAGE_USER_SUB_TOPICS_REQUEST:
    "GET_All_MANAGE_USER_SUB_TOPICS_REQUEST",
  GET_All_MANAGE_USER_SUB_TOPICS_SUCCESS:
    "GET_All_MANAGE_USER_SUB_TOPICS_SUCCESS",
  GET_All_MANAGE_USER_SUB_TOPICS_FAILED:
    "GET_All_MANAGE_USER_SUB_TOPICS_FAILED",

  // ADD USER GUIDE
  ADD_USER_GUIDE_REQUEST: "ADD_USER_GUIDE_REQUEST",
  ADD_USER_GUIDE_SUCCESS: "ADD_USER_GUIDE_SUCCESS",
  ADD_USER_GUIDE_FAILURE: "ADD_USER_GUIDE_FAILURE",

  // UPDATE USER GUIDE
  UPDATE_USER_GUIDE_REQUEST: "UPDATE_USER_GUIDE_REQUEST",
  UPDATE_USER_GUIDE_SUCCESS: "UPDATE_USER_GUIDE_SUCCESS",
  UPDATE_USER_GUIDE_FAILURE: "UPDATE_USER_GUIDE_FAILURE",

  //   DELETE USER GUIDE
  DELETE_USER_GUIDE_REQUEST: "DELETE_USER_GUIDE_REQUEST",
  DELETE_USER_GUIDE_SUCCESS: "DELETE_USER_GUIDE_SUCCESS",
  DELETE_USER_GUIDE_FAILURE: "DELETE_USER_GUIDE_FAILURE",

  //   DELETE USER GUIDE
  PUBLISH_USER_GUIDE_REQUEST: "PUBLISH_USER_GUIDE_REQUEST",
  PUBLISH_USER_GUIDE_SUCCESS: "PUBLISH_USER_GUIDE_SUCCESS",
  PUBLISH_USER_GUIDE_FAILURE: "PUBLISH_USER_GUIDE_FAILURE",

  //   sub-category USER GUIDE
  GET_SUB_CATEGORY_GUIDE_REQUEST: "GET_SUB_CATEGORY_GUIDE_REQUEST",
  GET_SUB_CATEGORY_GUIDE_SUCCESS: "GET_SUB_CATEGORY_GUIDE_SUCCESS",
  GET_SUB_CATEGORY_GUIDE_FAILURE: "GET_SUB_CATEGORY_GUIDE_FAILURE",
};

export default USER_MANAGE_GUIDE_CONSTS;
