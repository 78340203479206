import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import CORE_CONST from "./actionTypes";
import {
  coreSuccess,
  coreError,
  corestateError,
  corestateSuccess,
  coredistrictError,
  coredistrictSuccess,
  coreBasinSuccess,
  coreBasinError,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import {
  CORE_API,
  AXIOS_INSTANCE,
  AXIOS_INSTANCE_LOADER,
} from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* corelist(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  const url =
    action.payload?.menu_slug === undefined
      ? `${CORE_API}/location/list/${action.payload?.location_type}` // &&sub_menu_slug=${action.payload?.sub_menu_slug}
      : `${CORE_API}/location/list/${action.payload?.location_type}?menu_slug=${
          action?.payload?.menu_slug
        }${
          action.payload?.sub_menu_slug
            ? `&&sub_menu_slug${action.payload?.sub_menu_slug}`
            : ""
        }`;

  try {
    const apiResponse = baseMethod(AXIOS_INSTANCE.get(url), "", "");

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(coreSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(coreError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(coreError(errorData));
  }
}
function* corestatelist(action) {
  AXIOS_INSTANCE_LOADER.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.get(
        `${CORE_API}/location/list/${action.payload?.data?.location_type}?parent_id=${action.payload?.data?.parent_id}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
        isCompare: action?.payload?.isCompareLocation,
        isCreate: action?.payload?.isCreate,
      };
      yield put(corestateSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(corestateError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(corestateError(errorData));
  }
}

function* coredistrictlist(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.get(
        `${CORE_API}/location/list/${action.payload?.requestObj?.location_type}?parent_id=${action.payload?.requestObj?.parent_id}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
        config: {
          isCompareLocation: action.payload?.isCompareLocation || false,
        },
      };
      yield put(coredistrictSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
        config: {
          isCompareLocation: action.payload?.isCompareLocation || false,
        },
      };
      yield put(coredistrictError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
        config: {
          isCompareLocation: action.payload?.isCompareLocation || false,
        },
      },
    };
    yield put(coredistrictError(errorData));
  }
}

// basins
function* coreBasinlist(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.get(
        `${CORE_API}/basins/${action.payload?.data?.country_id}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
        isCompare: action?.payload?.isCompareLocation,
      };
      yield put(coreBasinSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
        config: {
          isCompareLocation: action.payload?.isCompareLocation || false,
        },
      };
      yield put(coreBasinError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
        config: {
          isCompareLocation: action.payload?.isCompareLocation || false,
        },
      },
    };
    yield put(coreBasinError(errorData));
  }
}

export function* watchCore() {
  yield takeEvery(CORE_CONST.CORE_REQUEST, corelist);
}
export function* watchCoreState() {
  yield takeEvery(CORE_CONST.CORE_STATE_REQUEST, corestatelist);
}
export function* watchCoreDistrict() {
  yield takeEvery(CORE_CONST.CORE_DISTRICT_REQUEST, coredistrictlist);
}
export function* watchCoreBasin() {
  yield takeEvery(CORE_CONST.CORE_BASIN_REQUEST, coreBasinlist);
}

function* CoreSaga() {
  yield all([
    fork(watchCore),
    fork(watchCoreState),
    fork(watchCoreDistrict),
    fork(watchCoreBasin),
  ]);
}

export default CoreSaga;
