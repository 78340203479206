import EARLY_WARNING_MARKET_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};
const Market = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case EARLY_WARNING_MARKET_CONST.GET_MARKET_TIMESERIES_REQUEST:
      state = {
        ...state,
        marketTimeSeriesloading: true,
        marketTimeseriesData: null,
        marketTimeSeriesError: null,
      };
      break;
    case EARLY_WARNING_MARKET_CONST.GET_MARKET_TIMESERIES_SUCCESS:
      state = {
        ...state,
        marketTimeSeriesloading: false,
        marketTimeseriesData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        marketTimeSeriesError: null,
      };
      break;
    case EARLY_WARNING_MARKET_CONST.GET_MARKET_TIMESERIES_ERROR:
      state = {
        ...state,
        marketTimeSeriesError: action.payload.data,
        marketTimeSeriesloading: false,
        marketTimeseriesData: null,
      };
      break;

    // map data request
    case EARLY_WARNING_MARKET_CONST.GET_MARKET_MAP_DATA_REQUEST:
      state = {
        ...state,
        isMarketMapDataRequesting: true,
        marketMapData: false,
        marketMapDataError: false,
      };
      break;
    case EARLY_WARNING_MARKET_CONST.GET_MARKET_MAP_DATA_SUCCESS:
      state = {
        ...state,
        isMarketMapDataRequesting: false,
        marketMapData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        marketMapDataError: false,
      };
      break;
    case EARLY_WARNING_MARKET_CONST.GET_MARKET_MAP_DATA_FAILED:
      state = {
        ...state,
        isMarketMapDataRequesting: false,
        marketMapData: false,
        marketMapDataError: action.payload.data,
      };
      break;

    // commodity data request
    case EARLY_WARNING_MARKET_CONST.GET_MARKET_COMMODITY_REQUEST:
      state = {
        ...state,
        marketCommodityLoading: true,
        marketCommodityData: false,
        marketCommodityDataError: false,
      };
      break;
    case EARLY_WARNING_MARKET_CONST.GET_MARKET_COMMODITY_SUCCESS:
      state = {
        ...state,
        marketCommodityLoading: false,
        marketCommodityData:
          action.payload.statusCode === 200 ? action.payload.data : [],
        marketCommodityDataError: false,
      };
      break;
    case EARLY_WARNING_MARKET_CONST.GET_MARKET_COMMODITY_ERROR:
      state = {
        ...state,
        marketCommodityLoading: false,
        marketCommodityData: false,
        marketCommodityDataError: action.payload.data,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};
export default Market;
