/* eslint-disable no-restricted-globals */
import DocViewer from "@cyntler/react-doc-viewer";
import React, { useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { GrFormClose } from "react-icons/gr";
import readXlsxFile from "read-excel-file";

const overrideComponent = ({ document, fileName }) => {
  const fileText = fileName || document?.fileType || "";

  if (fileText) {
    return <div>no renderer for {fileText}</div>;
  }
  return <div>no renderer</div>;
};

const loadingComponent = ({ document, fileName }) => {
  const fileText = fileName || document?.fileType || "";
  if (fileText) {
    return <div>loading ({fileText})</div>;
  }
  return <div>loading</div>;
};

function MediaReferenceModal({ toggle, modal, docs }) {
  const [pres, setPres] = React.useState([]);

  const closeBtn = (
    <GrFormClose
      size={24}
      onClick={() => toggle()}
      className="pointer media-modal-header-close-icon"
    />
  );

  const formatDateString = (date) => {
    if (Object.prototype.toString.call(date) === "[object Date]") {
      if (!isNaN(date.getTime())) {
        return date.toISOString().split("T")[0]; // Format date as YYYY-MM-DD
      }
    }
    return date;
  };

  const formatRows = (rows) => {
    return rows.map((row) => row.map((cell) => formatDateString(cell)));
  };

  /* Fetch and update the state once */
  useEffect(() => {
    if (
      docs?.length &&
      (docs?.[0]?.fileType === "csv" ||
        docs?.[0]?.fileType === "xlsx" ||
        docs?.[0]?.fileType === "xls")
    ) {
      if (docs?.[0]?.fileType === "csv") {
        fetch(docs?.[0]?.uri)
          .then((response) => response.text()) // Parse response as text
          .then((csvText) => {
            // Parse CSV text
            const rows = csvText.split("\n").map((row) => row.split(","));
            const formattedRows = formatRows(rows);
            setPres(formattedRows);
          });
        // .catch((error) => {
        //   console.error("Error fetching or parsing CSV:", error);
        // });
      } else {
        fetch(docs?.[0]?.uri)
          .then((response) => response.blob())
          .then((blob) => readXlsxFile(blob))
          .then((rows) => {
            const formattedRows = formatRows(rows);
            setPres(formattedRows);
          });
      }
    }
  }, [docs]);

  return (
    <div>
      <Modal isOpen={modal} size="lg">
        <ModalHeader
          toggle={toggle}
          className="align-items-center media-modal-header-background"
          close={closeBtn}
        >
          <h2 className="intervention-h2 m-0">
            {docs[0]?.fileName?.length > 45
              ? `${docs[0]?.fileName.slice(
                  0,
                  30,
                )} ... ${docs[0]?.fileName.slice(-10)} `
              : docs[0]?.fileName}
          </h2>
        </ModalHeader>
        <ModalBody className="p-3">
          {docs?.[0]?.fileType !== "csv" &&
          docs?.[0]?.fileType !== "xlsx" &&
          docs?.[0]?.fileType !== "xls" ? (
            <div
              style={{ height: "500px" }}
              className="preview-modal-container"
            >
              <DocViewer
                documents={docs}
                config={{
                  noRenderer: {
                    overrideComponent,
                  },
                  loadingRenderer: {
                    overrideComponent: loadingComponent,
                  },
                  csvDelimiter: ",",
                  pdfZoom: {
                    defaultZoom: 1.1,
                    zoomJump: 0.2,
                  },
                  pdfVerticalScrollByDefault: true,
                }}
                language="pl"
              />

              {/* <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={docs}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: false,
                  },
                }}
                style={{ height: 500 }}
              /> */}
            </div>
          ) : (
            <div className="appr-file excel-upload-table-sizing">
              <table className="w-100">
                <thead>
                  {pres &&
                    pres[0]?.map((key) => (
                      <th
                        style={{
                          textAlign: "center",
                          border: "2px solid green",
                        }}
                      >
                        {key}
                      </th>
                    ))}
                </thead>

                <tbody>
                  {pres?.map((pre, index) => {
                    if (index > 0) {
                      return (
                        <tr>
                          {pre.map((val) => {
                            return (
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "2px solid green",
                                }}
                              >
                                {val}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    }
                    return false;
                  })}
                </tbody>
              </table>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default MediaReferenceModal;
