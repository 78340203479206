import EARLY_FINANCE_REPORT_CONST from "./actionTypes";

// Add Report
export const createEarlyFinanceReportRequest = (payload) => {
  return {
    type: EARLY_FINANCE_REPORT_CONST.ADD_EARLY_FINANCE_REPORT_REQUEST,
    payload,
  };
};

export const createEarlyFinanceReportSuccess = (user) => {
  return {
    type: EARLY_FINANCE_REPORT_CONST.ADD_EARLY_FINANCE_REPORT_SUCCESS,
    payload: user,
  };
};

export const createEarlyFinanceReportError = (error) => {
  return {
    type: EARLY_FINANCE_REPORT_CONST.ADD_EARLY_FINANCE_REPORT_ERROR,
    payload: error,
  };
};

// Get Report
export const getEarlyFinanceReportRequest = (data) => {
  return {
    type: EARLY_FINANCE_REPORT_CONST.GET_EARLY_FINANCE_REPORT_REQUEST,
    payload: data,
  };
};

export const getEarlyFinanceReportSuccess = (data) => {
  return {
    type: EARLY_FINANCE_REPORT_CONST.GET_EARLY_FINANCE_REPORT_SUCCESS,
    payload: data,
  };
};

export const getEarlyFinanceReportError = (error) => {
  return {
    type: EARLY_FINANCE_REPORT_CONST.GET_EARLY_FINANCE_REPORT_ERROR,
    payload: error,
  };
};

// EDIT REPORT
export const editEarlyFinanceReportRequest = (payload) => {
  return {
    type: EARLY_FINANCE_REPORT_CONST.EDIT_EARLY_FINANCE_REPORT_REQUEST,
    payload,
  };
};

export const editEarlyFinanceReportSuccess = (data) => {
  return {
    type: EARLY_FINANCE_REPORT_CONST.EDIT_EARLY_FINANCE_REPORT_SUCCESS,
    payload: data,
  };
};

export const editEarlyFinanceReportError = (error) => {
  return {
    type: EARLY_FINANCE_REPORT_CONST.EDIT_EARLY_FINANCE_REPORT_ERROR,
    payload: error,
  };
};

// DELETE REFERENCE
export const deleteEarlyFinanceReferencesRequest = (payload) => {
  return {
    type: EARLY_FINANCE_REPORT_CONST.DELETE_EARLY_FINANCE_REFERENCES_REQUEST,
    payload,
  };
};

export const deleteEarlyFinanceReferencesSuccess = (data) => {
  return {
    type: EARLY_FINANCE_REPORT_CONST.DELETE_EARLY_FINANCE_REFERENCES_SUCCESS,
    payload: data,
  };
};

export const deleteEarlyFinanceReferencesError = (error) => {
  return {
    type: EARLY_FINANCE_REPORT_CONST.DELETE_EARLY_FINANCE_REFERENCES_ERROR,
    payload: error,
  };
};

// send Report
export const sendEarlyFinanceReportRequest = (payload) => {
  return {
    type: EARLY_FINANCE_REPORT_CONST.SEND_EARLY_FINANCE_REPORT_REQUEST,
    payload,
  };
};

export const sendEarlyFinanceReportSuccess = (user) => {
  return {
    type: EARLY_FINANCE_REPORT_CONST.SEND_EARLY_FINANCE_REPORT_SUCCESS,
    payload: user,
  };
};

export const sendEarlyFinanceReportError = (error) => {
  return {
    type: EARLY_FINANCE_REPORT_CONST.SEND_EARLY_FINANCE_REPORT_ERROR,
    payload: error,
  };
};
