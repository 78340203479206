import MANAGE_USER_CONSTS from "./actionTypes";

// get all manage user guide data
export const getAllUsersRequest = (data) => ({
  type: MANAGE_USER_CONSTS.GET_All_USER_REQUEST,
  payload: data,
});

export const getAllUsersSuccess = (response) => ({
  type: MANAGE_USER_CONSTS.GET_All_USER_SUCCESS,
  payload: response,
});

export const getAllUsersFailure = (error) => ({
  type: MANAGE_USER_CONSTS.GET_All_USER_ERROR,
  payload: error,
});

// get all genders
export const getAllUsersGenderRequest = (data) => ({
  type: MANAGE_USER_CONSTS.GET_All_USER_GENDER_REQUEST,
  payload: data,
});

export const getAllUsersGenderSuccess = (response) => ({
  type: MANAGE_USER_CONSTS.GET_All_USER_GENDER_SUCCESS,
  payload: response,
});

export const getAllUsersGenderFailure = (error) => ({
  type: MANAGE_USER_CONSTS.GET_All_USER_GENDER_ERROR,
  payload: error,
});

// get all age range
export const getAllUsersAgeRequest = (data) => ({
  type: MANAGE_USER_CONSTS.GET_All_USER_AGE_REQUEST,
  payload: data,
});

export const getAllUsersAgeSuccess = (response) => ({
  type: MANAGE_USER_CONSTS.GET_All_USER_AGE_SUCCESS,
  payload: response,
});

export const getAllUsersAgeFailure = (error) => ({
  type: MANAGE_USER_CONSTS.GET_All_USER_AGE_ERROR,
  payload: error,
});
