import { BiSearchAlt2 } from "react-icons/bi";
import { FaFilter } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function Filter({ setSearchProject, setOpenfilter, searchProject, isFinance }) {
  const { t } = useTranslation();
  return (
    <div className="ea-space-between mt-4">
      <div>
        {isFinance ? (
          <p className="Ongoing-text"> {t("EARLY_ACTIONS.ACTIVE")} </p>
        ) : (
          <p className="Ongoing-text"> {t("EARLY_ACTIONS.ONGOING")} </p>
        )}
      </div>
      <div className="ea-filter-container">
        <div className="ea-search">
          <input
            type="text"
            className="search-project-field"
            value={searchProject}
            maxLength={30}
            placeholder={t("EARLY_ACTIONS.SEARCH_PLACEHOLDER")}
            onChange={(e) => setSearchProject(e?.target?.value)}
          />
          <BiSearchAlt2 className="ea-search-icons" />
        </div>
        <div className="filter-card-container ml-3">
          <FaFilter
            className="ea-filter-logo  pointer"
            onClick={() => setOpenfilter(true)}
          />
        </div>
      </div>
    </div>
  );
}

export default Filter;
