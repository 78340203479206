import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { BsChevronDown } from "react-icons/bs";
import { useOutletContext } from "react-router-dom";
import EarlyActionHeader from "./EarlyActionHeader";
import Warning from "../../../assest/img/Warning.png";
import Finance from "../../../assest/img/EF-Icon.png";
import EAImg from "../../../assest/img/hourglass.png";
import Mechanisms from "../../../assest/img/EF-step-4.png";
import Icon1 from "../../../assest/img/EF-step-1.png";
import Icon2 from "../../../assest/img/EF-step-2.png";
import Icon3 from "../../../assest/img/EF-step-3.png";
import EF_line from "../../../assest/img/EF_line.png";

function EarlyFinanceStatic() {
  const { t } = useTranslation();

  const [, setOpen] = useOutletContext(); // for closing side bar when it is opening

  const params = new URLSearchParams(window.location.search);
  const isFinance = params.get("isFinance");

  const selectedSubModule = params.get("slug");

  useEffect(() => {
    setOpen(true);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="dashboard-drought">
      <div className="ea-project-container">
        <EarlyActionHeader
          isFinance={isFinance}
          selectedSubModule={selectedSubModule}
        />
        <div className="awr-home pt-4">
          <Row className="mt-4 layout-spc">
            <Col lg={4} sm={4} md={4} className="home-col">
              <div className="home-col-section">
                <div>
                  <img
                    src={Warning}
                    alt="awareTools"
                    className="early-action-img"
                  />
                  <h3>{t("HOME.Early_Warning")}</h3>
                </div>
                <p>{t("EARLY_ACTIONS.EARLY_ACTION_CARD")}</p>
              </div>
            </Col>
            <Col lg={4} sm={4} md={4} className="home-col">
              <div className="home-col-section">
                <div>
                  <img
                    src={EAImg}
                    alt="awareTools"
                    className="early-action-img"
                  />
                  <h3>{t("HOME.EARLY_ACTION")}</h3>
                </div>
                <p>{t("EARLY_ACTIONS.EARLY_ACTION_PARA")}</p>
              </div>
            </Col>
            <Col lg={4} sm={4} md={4} className="home-col">
              <div className="home-col-section EF-card">
                <div>
                  <img
                    src={Finance}
                    alt="awareTools"
                    className="early-action-img"
                  />
                  <img
                    src={Mechanisms}
                    alt="awareTools"
                    className="EA-card-img"
                  />
                  <h3>{t("HOME.EARLY_FINANCE")}</h3>
                </div>
                <p>{t("EARLY_ACTIONS.EARLY_FINANCE_PARA")}</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="EF-line">
          <img src={EF_line} alt="" />
        </div>
        <div className="p-5">
          <h2 className="ea-heading">{t("EARLY_FINANCE.EARLY_FINANCE")}</h2>
          <Row className="EA-steps">
            <Col lg={8} className="p-0">
              <p className="line-v line-v-height" />
              <div className="EA-prep-section">
                <div className="EA-perp">
                  <img src={Icon1} alt="icon" />
                  <div className="prep-div">
                    <h2>{t("EARLY_FINANCE.DASHBOARD")}</h2>
                    <p>{t("EARLY_ACTIONS.EARLY_FINANCE_DASHBOARD")}</p>
                  </div>
                  <BsChevronDown className="ea-v-icon" />
                </div>
              </div>
              <div className="EA-prep-section mt-4">
                <div className="EA-perp">
                  <img src={Icon2} alt="icon" />
                  <div className="prep-div">
                    <h2>{t("EARLY_FINANCE.ALERT_REPORT")}</h2>
                    <p>{t("EARLY_ACTIONS.EARLY_FINANCE_ALERT_REPORT")}</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <img src={Icon3} alt="" />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
export default EarlyFinanceStatic;
