/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "reactstrap";
import CarouselComponent from "../../components/Common/Carousel";
import AwareWorks from "./AwareWork";
import HomeModal from "./HomeModal";
import ClimberFooter from "../../components/Common/ClimberFooter";
import { aware, orderBy, LanguagesArray } from "../../constants";
import {
  climberBannerRequest,
  awareAboutRequest,
  otherToolsRequest,
  awareToolsRequest,
} from "../../store/actions";
import { isLogedIn } from "../../components/Common/Utils";

function Home() {
  window.scrollTo(0, 0);
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [language, setlanguage] = useState("");
  const [modaldata, setModaldata] = useState("");
  const [countrySelected, setCountrySelected] = useState(
    JSON.parse(localStorage.getItem("selectedCountry")),
  );

  /* ------handling api data---------*/
  const nextProps = useSelector((state) => ({
    bannerImages: state.Climber,
    aboutUs: state.Aware?.awareAboutData,
    awareTools: state.Aware?.awareToolsData,
    otherToolsListData: state.OtherTools?.otherToolsListData,
    dashBoardMenuList: state.Dashboard.dashBoardMenuData,
  }));

  const handleShow = (id, name, data) => {
    // Redirecting directly into module when onClick
    const selectedTool = nextProps.dashBoardMenuList.filter(
      (eachCard) => eachCard.id === id,
    );
    const url = selectedTool?.map((menuData) => {
      // IF IN CASE OF CLIMATE RISKS WE NEED TO CHANGE URL BASED ON MODULE. HERE CHANGING BASED ON SUB MENU LIST LENGTH
      return name === t("DASHBOARD.SECTORIAL_CLIMATE_RISKS")
        ? menuData?.menu_list[0]?.sub_menu_list?.length
          ? `/early-warnings/${menuData?.menu_list[0]?.slug}/${menuData?.menu_list[0]?.sub_menu_list[0]?.slug}?module=${menuData?.menu_list[0]?.sub_menu_list[0]?.slug}&moduleId=${menuData?.menu_list[0]?.id}&mainModule=${menuData?.slug}&subModule=${menuData?.menu_list[0]?.slug}&id=${menuData?.id}`
          : `/early-warnings/${menuData?.menu_list[0]?.slug}?module=${menuData?.menu_list[0]?.slug}&moduleId=${menuData?.menu_list[0]?.id}&mainModule=${menuData?.slug}&subModule=${menuData?.menu_list[0]?.slug}&id=${menuData?.id}`
        : `/early-warnings/${menuData?.menu_list[0]?.slug}?module=${menuData?.menu_list?.[0]?.slug}&moduleId=${menuData?.menu_list[0]?.id}&mainModule=${menuData?.slug}&subModule=${menuData?.menu_list[0]?.slug}&id=${menuData?.id}`;
    });

    setShow(true);
    setModaldata(id);

    if (name === t("HOME.USER_GUIDE")) {
      history(`/user-guide?slug=${data.slug}`);
    } else if (name === t("DASHBOARD.EARLY_ACTION")) {
      if (isLogedIn()) {
        history(`/earlyaction?slug=${data.slug}`);
      } else {
        history({
          pathname: "/login",
          search: `?prevPath=${name}&slug=${data.slug}`,
        });
      }
    } else if (name === "Early Finance") {
      if (isLogedIn()) {
        history(`/early-finance-page?isFinance=true&slug=${data.slug}`);
      } else {
        history({
          pathname: "/login",
          search: `?prevPath=${name}&slug=${data.slug}`,
        });
      }
    } else if (name === t("HOME.ONLINE_BULLETIN")) {
      if (isLogedIn()) {
        history(`/online-bulletin?slug=${data.slug}`);
      } else {
        history({
          pathname: "/login",
          search: `?prevPath=${name}&slug=${data.slug}`,
        });
      }
    } else if (name === t("UPLOAD_PAST_DATA.EARLY_WARNING")) {
      if (isLogedIn()) {
        setShow(false);
        history(url[0]);
      } else {
        history(`/login?prevPath=${name}`, {
          state: {
            selectedLanguage: language,
            queryData: selectedTool,
          },
        });
      }
    } else if (name === t("DASHBOARD.SECTORIAL_CLIMATE_RISKS")) {
      if (isLogedIn()) {
        setShow(false);
        history(url[0]);
      } else {
        history(`/login?prevPath=${name}`, {
          state: {
            selectedLanguage: language,
            queryData: selectedTool,
          },
        });
      }
    } else if (data.slug === "rapid_response") {
      if (isLogedIn()) {
        history(`/rapid-response-newsfeed?slug=rapid_response`);
      } else {
        history({
          pathname: "/login",
          search: `?prevPath=${data.slug}&slug=${data.slug}`,
        });
      }
    } else if (data.slug === t("DASHBOARD.CROP_YIELD_SLUG")) {
      if (isLogedIn()) {
        history(`/crop_yield_prediction?slug=${data.slug}`);
      } else {
        history({
          pathname: "/login",
          search: `?prevPath=${data.slug}&slug=${data.slug}`,
        });
      }
    }
  };
  const handleClose = () => setShow(false);
  const [carouselBannerImages, setBannerImages] = useState([]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("selectedCountry"))) {
      history("/home");
    } else {
      history("/climber");
    }
  }, []);

  const isBannerImages = useRef(true);
  useEffect(() => {
    if (isBannerImages.current) {
      isBannerImages.current = false;
      return;
    }
    if (nextProps.bannerImages.climberBannerData) {
      setBannerImages([...nextProps.bannerImages.climberBannerData]);
    }
  }, [nextProps.bannerImages]);
  const savingLanguageArray = () => {
    LanguagesArray.map((eachValue) => {
      if (eachValue.value === localStorage.getItem("i18nextLng")) {
        setlanguage(eachValue);
      }
      return null;
    });
  };

  useEffect(() => {
    if (localStorage.getItem("selectedCountry")) {
      setCountrySelected(JSON.parse(localStorage.getItem("selectedCountry")));
    }
  }, [localStorage.getItem("selectedCountry")]);

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")) {
      dispatch(
        climberBannerRequest({
          country_id: countrySelected?.value,
          system: aware,
          order: orderBy,
        }),
      );
      dispatch(
        awareAboutRequest({
          system: aware,
        }),
      );
      dispatch(awareToolsRequest());
      dispatch(
        otherToolsRequest({
          country_id: countrySelected?.value,
          focus_area_id: "",
          order: orderBy,
        }),
      );
    }
    savingLanguageArray();
  }, [localStorage.getItem("i18nextLng")]);

  // based on selected country calling banner api
  useEffect(() => {
    dispatch(
      climberBannerRequest({
        country_id: countrySelected?.value,
        system: aware,
        order: orderBy,
      }),
    );
  }, [countrySelected]);
  return (
    <div>
      {carouselBannerImages.length === 0 ? null : (
        <CarouselComponent carouselData={carouselBannerImages} />
      )}
      <HomeModal
        isOpen={show}
        toggle={handleClose}
        data={modaldata}
        language={language}
        countrys={countrySelected}
      />
      <div className="awr-home">
        <div className="page-out-img">
          <div className="Home-main-section layout-spc">
            <div className="climber-link-div">
              <Link to="/climber" state={language} className="climber-link">
                {t("HOME.GO_TO_ClimBeR")}
              </Link>
              <span>{t("DASHBOARD.AWARE_HOME")}</span>
            </div>
            <div className="pr-4">
              <h3 className="d-inline-block">
                {nextProps?.aboutUs?.[0]?.title
                  ? nextProps?.aboutUs?.[0]?.title
                  : ""}
              </h3>
              <Link to="/dashboard" className="dashbaord-btn d-none ">
                {t("DASHBOARD.VIEW_DASHBOARD")}
              </Link>
            </div>
            <p>
              {nextProps?.aboutUs?.[0]?.desc
                ? nextProps?.aboutUs?.[0]?.desc
                : ""}
            </p>
          </div>
          <Row className="mt-4 layout-spc">
            {nextProps.dashBoardMenuList?.length &&
              nextProps?.awareTools?.map((eachCard) => {
                const awareTool = nextProps.dashBoardMenuList?.filter(
                  (menu) => eachCard.id === menu.id,
                );
                return (
                  <Col
                    lg={4}
                    sm={4}
                    md={4}
                    key={eachCard?.title}
                    className="home-col"
                    style={
                      // HIDING CLIMATE RISKS CARD WHICH DONT HAVE ANY MENUS
                      eachCard.title === t("DASHBOARD.SECTORIAL_CLIMATE_RISKS")
                        ? awareTool[0]?.menu_list?.length
                          ? {}
                          : { display: "none" }
                        : awareTool?.length
                        ? {}
                        : { display: "none" }
                    }
                  >
                    <div
                      className="home-col-section"
                      onClick={() =>
                        handleShow(eachCard.id, eachCard.title, eachCard)
                      }
                      role="button"
                      tabIndex="0"
                      onKeyUp={() => {}}
                    >
                      <div>
                        <img
                          src={eachCard?.icon}
                          alt={eachCard.title}
                          className="aware-home-module-icons"
                        />
                        <h3>{eachCard?.title} </h3>
                      </div>
                      <p>{eachCard?.desc}</p>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>

        <AwareWorks />
      </div>
      <ClimberFooter />
    </div>
  );
}

export default Home;
