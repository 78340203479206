import React from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import { Button, FormGroup } from "reactstrap";
// import UpIcon from "../../assest/img/up.png";
import Close from "../../assest/img/close.png";
import {
  formickTextField,
  formickReactSelectFiled,
} from "../../components/Common/FormickField";
import { renderReactOptionsArray } from "../../helpers";

function RightSideModal({
  setFilterModal,
  filterModal,
  countryList,
  handleFilteredData,
  filter,
  setFilter,
  organizationList,
  clearFilters,
}) {
  const { t } = useTranslation();
  const initialValues = {
    Username: filter.name,
    email: filter.email,
    country: filter.country,
    organization: filter.organization,
  };

  const onChangeName = (e) => {
    setFilter({
      ...filter,
      name: e.target.value,
    });
  };

  const onChangeEmail = (e) => {
    setFilter({
      ...filter,
      email: e.target.value,
    });
  };

  const onCountryChange = (e) => {
    setFilter({
      ...filter,
      country: e,
    });
  };

  const onChangeOrganization = (e) => {
    setFilter({
      ...filter,
      organization: e,
    });
  };
  return (
    <div className="st-right-sidebar-wrapper-filter-manage-user">
      <div className="st-filter-header-manageuser">
        <h3 className="st-text-green">{t("FILTER.FILTER")}</h3>
        <div
          className="st-close ml-auto cursor"
          role="button"
          tabIndex="0"
          onKeyUp={() => {}}
          onClick={() => setFilterModal(!filterModal)}
        >
          <img className="st-close-img" alt="not-available" src={Close} />
        </div>
      </div>
      <hr />
      <Formik initialValues={initialValues} enableReinitialize>
        {(formick) => (
          <Form id="filter-form">
            <div className="st-collapse-padding">
              <FormGroup className="">
                <Field
                  type="text"
                  name="Username"
                  component={formickTextField}
                  classNameActive="input-class-login"
                  classNameInactive="input-class-login"
                  placeholder={t("MANAGE_USERS.NAME")}
                  lable={t("MANAGE_USERS.NAME")}
                  onChangeField={(e) => onChangeName(e)}
                />
              </FormGroup>

              <FormGroup className="mt-4">
                <Field
                  type="email"
                  name="email"
                  component={formickTextField}
                  classNameActive="input-class-login"
                  classNameInactive="input-class-login"
                  placeholder={t("MANAGE_USERS.EMAIL")}
                  lable={t("MANAGE_USERS.EMAIL")}
                  onChangeField={(e) => onChangeEmail(e)}
                />
              </FormGroup>
              <FormGroup className="mt-4">
                <Field
                  type="select"
                  name="country"
                  component={formickReactSelectFiled}
                  placeholder={t("MANAGE_USERS.COUNTRY")}
                  options={renderReactOptionsArray(countryList, "name", "id")}
                  label={t("MANAGE_USERS.COUNTRY")}
                  className="common-select-r"
                  value={filter.country}
                  selectProvience={onCountryChange}
                />
              </FormGroup>
              <FormGroup className="mt-4">
                <Field
                  type="select"
                  name="organization"
                  component={formickReactSelectFiled}
                  placeholder={t("MANAGE_USERS.ORGANIZATION")}
                  options={renderReactOptionsArray(
                    organizationList,
                    "title",
                    "id",
                  )}
                  label={t("MANAGE_USERS.ORGANIZATION")}
                  className="common-select-r"
                  value={filter.organization}
                  selectProvience={onChangeOrganization}
                />
              </FormGroup>
            </div>

            <hr />
            <div className="filter-buttons">
              <Button
                className="btn clear-filter-btn-manageuser st-btn-custom-manageuser "
                onClick={() => {
                  formick.resetForm();
                  clearFilters();
                }}
              >
                {t("FILTER.CLEAR_FILTERS")}
              </Button>
              <Button
                className="btn manage-user-btn-custom manage-user-submit-btn"
                onClick={handleFilteredData}
              >
                {t("FILTER.SHOW_RESULT")}
              </Button>
            </div>
            <hr />
            <div style={{ height: "200px" }} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RightSideModal;
