import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { MdEdit } from "react-icons/md";
import { BsCheckLg } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import EaEditIcon from "../../assest/img/projectslogo.svg";
import projectTrashIcon from "../../assest/img/projectTrashIcon.png";
import HorizontalLinearStepper from "./EAStepper";
import Loader from "../../components/Common/Loader";
import {
  renderChartTime,
  isPermissionsMatch,
  superAdmin,
} from "../../components/Common/Utils";
import {
  EDIT_PROJECT,
  DELETE_PROJECT,
  ADD_INSTANCE,
  CHANGE_BUDGET,
} from "../../constants";
import { editprojectRequest } from "../../store/actions";
import { showError } from "../../components/Common/Notification";

function EAproject({
  projectList,
  onHandleDelete,
  setSelectedProject,
  selectedproject,
  setIsEditProjectName,
  editprojectName,
  isFinance,
  permissionsList,
  loader,
}) {
  const history = useNavigate();
  const { t } = useTranslation();

  const params = new URLSearchParams(window.location.search);
  const selectedSubModule = params.get("slug");

  const dispatch = useDispatch();
  const onHandleEditProjectname = (each) => {
    const payload = {
      project_id: selectedproject?.id,
      project_name: editprojectName,
      ea_pp_status: each.ea_pp_status,
      ea_rp_status: each.ea_rp_status,
      ea_ap_status: each.ea_ap_status,
    };
    dispatch(editprojectRequest({ payload, show: true }));
  };

  const canNvaigate =
    isPermissionsMatch(permissionsList, ADD_INSTANCE) || superAdmin();

  const canViewEF =
    isPermissionsMatch(permissionsList, CHANGE_BUDGET) || superAdmin();

  const onHandleProjectNavigation = (each) => {
    if (isFinance) {
      if (canViewEF || each?.ef_currency_id) {
        const url = `/early-finance?isFinance=true&slug=${selectedSubModule}`;
        selectedproject?.id !== each?.id && isFinance && history(url);
        selectedproject?.id !== each?.id &&
          localStorage.setItem("projectDetails", JSON.stringify(each));
      } else {
        showError(t("VALIDATION.EF_EDIT_PERMISSION"));
      }
    } else {
      if (each.ea_pp_category_status || canNvaigate) {
        selectedproject?.id !== each?.id &&
          history(`/assessments?slug=${selectedSubModule}`);
        selectedproject?.id !== each?.id &&
          localStorage.setItem("projectDetails", JSON.stringify(each));
      } else {
        showError(t("VALIDATION.PROJECT_PENDING_WARNING"));
      }
    }
  };

  return loader ? (
    <Loader isSuspense indicator />
  ) : projectList?.length === 0 ? (
    <div className="text-center">
      {" "}
      <p> {t("EARLY_ACTIONS.PROJECTS_NOT_FOUND")} </p>{" "}
    </div>
  ) : (
    projectList?.results?.map((each) => {
      const result = moment(each.updated_date).fromNow();
      return (
        <div className="ea-project-card mt-3 mb-3">
          <Row
            onClick={() => onHandleProjectNavigation(each)}
            className="cursor-pointer"
          >
            <Col
              sm={3}
              className="card-title-section"
              // style={{ border: "2px solid red" }}
            >
              <img src={EaEditIcon} alt="edit" />
              <div className="ml-2">
                <div className="back-office-center">
                  {selectedproject?.id === each?.id ? (
                    <div className="w-100">
                      <input
                        value={editprojectName}
                        className="card-title ml-3 mb-3 project-edit-input w-100"
                        maxLength={40}
                        onChange={(event) => {
                          event.stopPropagation();
                          setIsEditProjectName(event.target.value);
                        }}
                      />
                    </div>
                  ) : (
                    <p className="card-title ml-3 mb-3"> {each?.name} </p>
                  )}
                  {(isPermissionsMatch(permissionsList, EDIT_PROJECT) ||
                    superAdmin()) &&
                    !isFinance &&
                    !each.ea_ap_status &&
                    selectedproject?.id !== each?.id && (
                      <MdEdit
                        className="mb-3 ml-3 project-edit-icon"
                        onClick={(event) => {
                          event.stopPropagation();
                          setSelectedProject(each);
                          setIsEditProjectName(each?.name);
                        }}
                      />
                    )}

                  {selectedproject?.id === each?.id && (
                    <>
                      {editprojectName ? (
                        <BsCheckLg
                          className="mb-2 ml-3 project-check-icons"
                          onClick={(event) => {
                            event.stopPropagation();
                            onHandleEditProjectname(each);
                          }}
                        />
                      ) : (
                        ""
                      )}
                      <RxCross2
                        className="mb-2 ml-3 project-cross-icons"
                        onClick={(event) => {
                          event.stopPropagation();
                          setSelectedProject("");
                          setIsEditProjectName("");
                        }}
                      />
                    </>
                  )}
                </div>
                <div>
                  <span> {each?.country_name} ,</span>
                  <span> {each?.state_name} , </span>
                  <span> {each?.district_name} </span>
                </div>
              </div>
            </Col>

            <Col sm={5} className="ea-stepper-container">
              <HorizontalLinearStepper
                activeStep={
                  each?.ea_ap_status
                    ? 3
                    : each?.ea_rp_status
                    ? 2
                    : each?.ea_pp_status
                    ? 1
                    : 0
                }
                data={each}
              />
            </Col>

            <Col sm={2} className="card-time ">
              <p className="ml-1">
                {" "}
                {t("EARLY_ACTIONS.CREATED_ON")}{" "}
                {renderChartTime(each?.created_date)}{" "}
              </p>
              <p className="ml-1">
                {" "}
                {t("EARLY_ACTIONS.LAST_EDITED")} {result}{" "}
              </p>
            </Col>
            {(isPermissionsMatch(permissionsList, DELETE_PROJECT) ||
              superAdmin()) &&
            !isFinance ? (
              <Col sm={2} className="flex-container">
                <div className="projects-edit-container">
                  <img
                    src={projectTrashIcon}
                    className="project-trash-icon"
                    alt="trashicon"
                    onKeyUp={() => {}}
                    onClick={(event) => {
                      event.stopPropagation();
                      onHandleDelete(each);
                    }}
                  />
                </div>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </div>
      );
    })
  );
}

export default EAproject;
