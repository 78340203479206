import React, { useState, useEffect } from "react";
import { Alert, Label, Input, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Highcharts from "highcharts";
import Loader from "react-spinners/FadeLoader";
import {
  renderChartTime,
  OpenWeatherChartLabels,
} from "../../../components/Common/Utils";
import HighChartGraph from "../../../components/Common/HighCharts";
import {
  HighChartConstants,
  Line,
  CHART_LABEL_COLORS,
  chartColorLabel,
  Column,
} from "../../../constants";
import { handleExportToCSVOpenWeather } from "../../../helpers/excelDownload";
import { showError } from "../../../components/Common/Notification";

function Graph({
  initialData,
  graphData,
  selectedParams,
  openweatherSeriseData,
  errorData,
  isShowGraph,
  setIsShowGraph,
  graphStartDate,
  graphEndDate,
  setGraphStartDate,
  setGraphEndDate,
  onGraphDateChange,
  minAndMaxDate,
  seriesData,
  forecastDate,
  onForecastDateChange,
  setForecastDate,
}) {
  const { t } = useTranslation();
  const myDiv = document.getElementById("graph1");
  const containerWidth = myDiv?.offsetWidth;
  const containerHeight = myDiv?.offsetHeight;
  const [highChartData, setHighChartData] = useState({
    xaxis: [],
    yaxis: [],
    serise: [],
  });
  const [chartWidth, setChartWidth] = useState();
  // const [showGraph, setGraphShow] = useState(true);
  const [graphTitle, setGraphTitle] = useState("");
  const [graphButtondisable, setGraphButtonDisable] = useState(false);
  const options = {
    title: {
      text: graphTitle,
      style: {
        fontSize: "17px",
        color: "#435A52",
      },
      align: "left",
      x: 100,
    },
    chart: {
      type: "column",
      height: containerHeight - 15,
      zoomType: "x",
      width: chartWidth,
      resetZoomButton: {
        position: {
          align: "left",
          verticalAlign: "top",
          x: 10,
          y: 10,
        },
      },
    },
    legend: {
      layout: "horizontal",
      verticalAlign: "bottom",
      align: "left",
      offsetX: 0,
    },

    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          align: "left",
          x: 35,
          y: -5,

          menuItems: [
            "viewFullscreen", // View in full screen
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
            {
              text: "Export to CSV",
              onclick() {
                // eslint-disable-next-line react/no-this-in-sfc
                const seriesDta = this.series.map((series) => ({
                  name: series.name,
                  data: series.data.map((point) => point.y),
                }));
                // eslint-disable-next-line react/no-this-in-sfc
                const xAxisData = this.xAxis[0].categories;
                handleExportToCSVOpenWeather(
                  seriesDta,
                  xAxisData,
                  `AlertDashboard ${initialData?.district?.label} ${initialData.selectedIndicator}`,
                );
                // Log series data and x-axis data to the console
              },
            },
          ],
        },
      },
    },
    xAxis: [
      {
        categories: highChartData?.xaxis,
        crosshair: false,
        gridLineWidth: 1,
        labels: {
          style: {
            fontSize: "8px",
          },
        },
      },
    ],
    yAxis: highChartData?.yaxis,

    tooltip: {
      shared: true,
      useHTML: true,
      headerFormat: '<table><tr><th colspan="2">{point.x}</th></tr>',
      pointFormat:
        "<tr><td >{series.name} : </td>" +
        '<td style="text-align: right"><b>{point.y} </b></td></tr>',
      footerFormat: "</table>",
      valueDecimals: 2,
    },
    series: highChartData?.serise,
    plotOptions: {
      column: {
        pointWidth: 10,
      },
      series: {
        dataLabels: {
          enabled: true,
          borderRadius: HighChartConstants?.two,

          allowOverlap: true,
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };
  const geoGlowsOptions = {
    title: {
      text: `${t("GEOGLOWS.FORECASTED_FLOW")}`,
      style: {
        fontSize: "14px",
        color: "#435A52",
      },

      align: "center",
    },
    chart: {
      // type: "line",
      height: 300,
      zoomType: "x",
    },
    legend: {
      itemMarginBottom: 15,
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      x: 0,
      y: 20,
      width: 160,
    },
    series: seriesData,
    exporting: {
      enabled: true,
      filename: `${t("GEOGLOWS.FORECASTED_FLOW")}`,
      buttons: {
        contextButton: {
          align: "right",
          x: -35,
          y: -5,
        },
      },
    },
    xAxis: {
      type: "datetime",
      labels: {
        formatter: (value) => {
          return Highcharts.dateFormat("%d %b %Y", value.value);
        },
      },
      gridLineWidth: 1,
    },
    yAxis: {
      title: {
        text: `${t("GEOGLOWS.FLOW_CUB")}`,
      },
      lineWidth: 1,
      tickLength: 10,
      tickWidth: 1,
    },
    tooltip: {
      shared: true,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  };
  useEffect(() => {
    if (!_.isEmpty(seriesData)) setIsShowGraph(true);
  }, [seriesData]);

  // for open weather data
  useEffect(() => {
    if (!_.isEmpty(openweatherSeriseData)) {
      const yaxisdata = [];
      const yaxisSerise = [];
      let formattedDates = [];

      formattedDates = openweatherSeriseData?.map((item) =>
        OpenWeatherChartLabels(item?.dt_txt),
      );

      const popValues = openweatherSeriseData?.map((item) =>
        item?.rain?.["1h"] ? item?.rain["1h"] : 0,
      );

      const width =
        formattedDates?.length >= HighChartConstants?.fifteen
          ? formattedDates?.length * HighChartConstants?.sixtyFive
          : null;
      containerWidth > width
        ? setChartWidth(containerWidth)
        : setChartWidth(width);
      const array1 = [
        {
          slug: "precipitation",
          data: popValues,
          name: `${t("WEATHER_FORCAST.PRECIPITATION_LABEL")}`,
        },
      ];

      array1?.map((eachData, index) => {
        const eachParam = eachData?.name;
        const seriseData = {
          name: eachParam,
          type: index < 1 ? "column" : "line",
          data: eachData?.data ? eachData?.data : 0,
          visible:
            index === 0 || index === 1
              ? true
              : selectedParams?.[0]?.parameter?.slug === eachData?.slug,
          yAxis: index,
          color: chartColorLabel[index],
          dataLabels: {
            format: "{y:.2f}",
            backgroundColor: chartColorLabel[index],
            borderRadius: HighChartConstants?.three,
            padding: HighChartConstants?.three,
            y: HighChartConstants?.three,
            style: {
              color: HighChartConstants?.white,
              textOutline: "none",
            },
          },
        };
        const yaxis = {
          lineColor: chartColorLabel[index],
          lineWidth: 1,
          tickLength: 10,
          tickWidth: 1,

          tickAmount: 6,
          tickColor: chartColorLabel[index],
          labels: {
            format: "{value:.1f}",
            style: {
              fontSize: "10px",
              color: chartColorLabel[index],
            },
          },
          title: {
            text: `${eachParam}`,

            style: {
              fontSize: "10px",
              color: chartColorLabel[index],
              fontWeight: "bold",
            },
          },
        };

        if (index > 0) {
          yaxis.opposite = true;
        }
        yaxis.showEmpty = false;
        yaxisdata.push(yaxis);
        yaxisSerise.push(seriseData);

        return null;
      });

      setHighChartData((prev) => {
        return {
          ...prev,
          xaxis: formattedDates,
          yaxis: yaxisdata,
          serise: yaxisSerise,
        };
      });
      setGraphTitle(
        initialData?.pointer.length
          ? `${Number(initialData?.pointer[0]?.latlngs[0]).toFixed(
              2,
            )}  ${Number(initialData?.pointer[0]?.latlngs[1]).toFixed(2)} - ${
              initialData.selectedIndicator
            }`
          : `${initialData.region.label} - ${initialData.district.label} - ${initialData.selectedIndicator}`,
      );
      // setGraphShow(true);
      setIsShowGraph(true);
    }
  }, [openweatherSeriseData]);

  const onSetDataValue = (formattedDates, data) => {
    const b = [];

    data?.map((eachData, index) => b.push([formattedDates[index], eachData]));
    return b;
  };

  useEffect(() => {
    if (!_.isEmpty(graphData)) {
      const indicatorName =
        initialData?.selectedIndicator === "Dry Spell"
          ? "Daily Rainfall"
          : initialData?.selectedIndicator;
      if (graphData?.graph_data?.[0]) {
        const chartData = graphData;
        let dates;
        const yaxisdata = [];
        const yaxisSerise = [];
        let formattedDates = [];

        if (!_.isEmpty(chartData) && chartData?.graph_data?.length) {
          dates = chartData?.graph_data[0]?.millis;
          if (chartData?.graph_data?.length === 2) {
            // eslint-disable-next-line no-unused-vars
            dates =
              chartData?.graph_data[0]?.millis.length >
              chartData?.graph_data[1]?.millis.length
                ? chartData?.graph_data[0]?.millis
                : chartData?.graph_data[1]?.millis;
          }
        }
        // eslint-disable-next-line no-unused-vars
        formattedDates = dates.map((item) => {
          const dateFormat = new Date(item);
          return renderChartTime(dateFormat);
        });

        const width =
          formattedDates.length >= HighChartConstants?.three
            ? formattedDates?.length * HighChartConstants?.fiftyFive
            : null;
        containerWidth > width
          ? setChartWidth(containerWidth)
          : setChartWidth(width);
        chartData?.graph_data?.map((eachData, index) => {
          const data1 = onSetDataValue(formattedDates, eachData?.data);

          const seriseData = {
            name: "name",
            type: "line",
            data: data1,
            yAxis: index,
            color: chartColorLabel[index],
            dataLabels: {
              format: "{y:.2f}",
              backgroundColor: chartColorLabel[index],
              borderRadius: HighChartConstants?.three,
              padding: HighChartConstants?.three,
              y:
                index === 0
                  ? HighChartConstants?.minusTen
                  : HighChartConstants?.thirty,
              style: {
                color: HighChartConstants?.white,
                textOutline: "none",
              },
            },
          };
          const yaxis = {
            lineColor: chartColorLabel[index],
            lineWidth: 1,
            tickLength: 10,
            tickWidth: 1,
            tickAmount: 6,
            tickColor: chartColorLabel[index],
            labels: {
              format: "{value:.1f}",
              style: {
                size: 12,
                color: chartColorLabel[index],
              },
            },
            title: {
              text: "title",
              style: {
                color: chartColorLabel[index],
                fontWeight: "bold",
              },
            },
          };
          if (index > 0) {
            yaxis.opposite = true;
          }
          yaxis.showEmpty = false;
          yaxisdata.push(yaxis);
          yaxisSerise.push(seriseData);
          return null;
        });
        setHighChartData((prev) => {
          return {
            ...prev,
            xaxis: formattedDates,
            yaxis: yaxisdata,
            serise: yaxisSerise,
          };
        });
        // setGraphShow(true);
        setIsShowGraph(true);
      } else {
        const mills = graphData?.graph_data?.millis;
        const data = graphData?.graph_data?.data;

        const width =
          mills.length >= HighChartConstants?.thirty
            ? mills?.length * HighChartConstants?.fiftyFive
            : null;

        containerWidth > width
          ? setChartWidth(containerWidth)
          : setChartWidth(width);

        const yaxisdata = [];
        const yaxisSerise = [];
        let formattedDates = [];
        // setGraphShow(true);
        if (!_.isEmpty(mills)) {
          formattedDates = mills?.length
            ? mills.map((item) => {
                const dateFormat = new Date(item);
                return renderChartTime(dateFormat);
              })
            : "";
          const data1 = onSetDataValue(formattedDates, data);
          const a = {
            name: indicatorName,
            type: initialData?.selectedIndicator === "IRI" ? Column : Line,
            data: data1,
            dataLabels: {
              format: "{y:.2f}",
              backgroundColor: chartColorLabel[0],
              borderRadius: HighChartConstants?.three,
              padding: HighChartConstants?.three,

              style: {
                color: HighChartConstants?.white,
                textOutline: "none",
              },
            },
          };
          yaxisSerise.push(a);
          yaxisdata.push({
            lineColor: chartColorLabel[0],
            lineWidth: 1,
            tickLength: 10,
            tickWidth: 1,
            tickColor: chartColorLabel[0],
            tickAmount: 6,
            showEmpty: false,
            labels: {
              format: "{value:.1f}",
              style: {
                size: 12,
                color: CHART_LABEL_COLORS[1],
              },
            },
            title: {
              text: initialData?.pointer.length
                ? `${indicatorName} - ${Number(
                    initialData?.pointer[0]?.latlngs[0],
                  ).toFixed(2)}  ${Number(
                    initialData?.pointer[0]?.latlngs[1],
                  ).toFixed(2)}`
                : `${indicatorName} - ${
                    initialData?.region.label === ""
                      ? t("DASHBOARD.ALL_REGIONS")
                      : initialData.region.label
                  }- ${
                    initialData?.district === ""
                      ? t("DASHBOARD.ALL_DISTRICTS")
                      : initialData?.district?.label
                  }`,
              style: {
                size: 12,
                color: CHART_LABEL_COLORS[1],
              },
            },
          });
          setHighChartData((prev) => {
            return {
              ...prev,
              xaxis: formattedDates,
              yaxis: yaxisdata,
              serise: yaxisSerise,
            };
          });
        } else {
          setHighChartData((prev) => {
            return {
              ...prev,
              xaxis: [],
              yaxis: [],
              serise: [],
            };
          });
        }
        setIsShowGraph(true);
      }
      setGraphTitle(
        initialData?.pointer?.length
          ? `${Number(initialData?.pointer[0]?.latlngs[0]).toFixed(2)} ${Number(
              initialData?.pointer[0]?.latlngs[1],
            ).toFixed(2)} - ${indicatorName}`
          : `${initialData?.region?.label} - ${initialData?.district?.label} - ${indicatorName}`,
      );
    }
  }, [graphData]);

  const handleStartDate = (e) => {
    e.preventDefault();
    if (e?.target?.value) {
      setGraphStartDate(e?.target?.value);
      // onGraphStartDateChange(e?.target?.value);
    }
  };
  const handleEndDate = (e) => {
    e.preventDefault();
    if (e?.target?.value) {
      setGraphEndDate(e?.target?.value);
      // onGraphEndDateChange(e?.target?.value);
    }
  };
  const handleForecastDateChange = (e) => {
    e.preventDefault();
    if (e?.target?.value) {
      setForecastDate(e?.target?.value);
      // onForecastDateChange(e?.target?.value);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(graphStartDate) && !_.isEmpty(graphEndDate)) {
      const stDate = new Date(graphStartDate);
      const edDate = new Date(graphEndDate);
      if (stDate >= edDate) {
        showError("Start Date Should not be greater than End Date");
        setGraphButtonDisable(true);
      } else setGraphButtonDisable(false);
    }
  }, [graphStartDate, graphEndDate]);
  return (
    true && (
      <div
        className={
          containerWidth === chartWidth
            ? "alert-footer-area alert-chart-design chart-hidden"
            : "alert-footer-area alert-chart-design chart-flow"
        }
        id="graph1"
      >
        {isShowGraph && !errorData?.showError ? (
          <>
            <HighChartGraph
              options={_.isEmpty(seriesData) ? options : geoGlowsOptions}
              className="alert-graph"
              style={{ width: "100%", height: "100%" }}
            />
            <div>
              <div className="close-icon-highcharts" />
            </div>
          </>
        ) : (
          <>
            {!errorData?.showError && (
              <div className="graph-loader-container" id="loader">
                <div className=" loader-text-alignmant">
                  <Loader color="#179B6B" loading={1} margin={2} size={20} />
                  <div className="loadet-text mt-3">
                    <p> {t("LOADING.LOADING")} </p>
                  </div>
                </div>
              </div>
            )}
            {errorData?.showError && (
              <div className="alert-graph-error-container">
                <div className="graph-error-style">
                  <Alert color="warning" className="alert-warning-container">
                    {errorData?.message}
                  </Alert>
                </div>
              </div>
            )}
          </>
        )}
        {initialData.selectedIndicator !== "IRI" && isShowGraph && (
          <div className="graph-date-fields-container d-flex flex-row justify-content-end">
            {!initialData.selectedIndicator.includes("GeoGlows") &&
              !errorData?.showError && (
                <>
                  <div className="common-select-register alert-graph-dates">
                    <Label className="label alert-date-label">
                      {t("DASHBOARD.START_DATE")}
                    </Label>
                    <Input
                      type="date"
                      name="startDate"
                      max={minAndMaxDate.maxDate}
                      min={
                        initialData.selectedIndicator === "Open Weather"
                          ? minAndMaxDate.minDate
                          : ""
                      }
                      value={graphStartDate}
                      onChange={handleStartDate}
                      className="alert-common-select-r"
                    />
                  </div>
                  <div className="common-select-register alert-graph-dates ml-2">
                    <Label className="label alert-date-label">
                      {t("DASHBOARD.END_DATE")}
                    </Label>
                    <Input
                      type="date"
                      name="endDate"
                      max={minAndMaxDate.maxDate}
                      min={
                        initialData.selectedIndicator === "Open Weather"
                          ? minAndMaxDate.minDate
                          : ""
                      }
                      value={graphEndDate}
                      onChange={handleEndDate}
                      className="alert-common-select-r"
                    />
                  </div>
                  <Button
                    disabled={graphButtondisable}
                    onClick={onGraphDateChange}
                    className="mx-3 alert-graph-button"
                  >
                    {t("DASHBOARD.APPLY")}
                  </Button>
                </>
              )}

            {initialData.selectedIndicator === "GeoGlows" &&
              isShowGraph &&
              !errorData?.showError && (
                <>
                  <div
                    className="common-select-register ml-3"
                    style={{ marginLeft: "7rem !important" }}
                  >
                    <Label className="label alert-date-label">
                      {t("GEOGLOWS.FORECAST")}
                    </Label>
                    <Input
                      type="date"
                      name="endDate"
                      max={minAndMaxDate.maxDate}
                      min={minAndMaxDate.minDate}
                      value={forecastDate}
                      onChange={handleForecastDateChange}
                      className="alert-common-select-r"
                    />
                  </div>
                  <Button
                    onClick={onForecastDateChange}
                    className="mx-3 alert-graph-button"
                  >
                    {t("DASHBOARD.APPLY")}
                  </Button>
                </>
              )}
          </div>
        )}
      </div>
    )
  );
}
export default Graph;
