import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BsFillRecordFill, BsCheckAll } from "react-icons/bs";
import Cookies from "js-cookie";
import Select from "react-select";
import Button from "reactstrap-button-loader";
import {
  Card,
  CardImg,
  CardSubtitle,
  CardTitle,
  Row,
  Col,
  Input,
} from "reactstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getAllTemplateListRequest,
  getAllBulletinRequest,
  subscribeBulletinRequest,
} from "../../store/bulletin/bulletinActions";
import { coreRequest, indicatorRequest } from "../../store/actions";
import { DISPLAY_CHART_DATE, TemperatureAnomaly } from "../../constants";
import { isValidEmail } from "../../helpers/validate";
import GlobalLoader from "../../components/Common/Loader";
// import RightArrow from "../../assest/img/back-button.svg";
import NewDummyImage from "../../assest/img/Zambia_map.png";
import { showError } from "../../components/Common/Notification";
import OnlineBulletinModal from "./CreateOnlineBulletin/OnlineBulletinModal";

function HomePageBulletin() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, setOpen] = useOutletContext();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [isOpenBulletin, setIsOpenBulletin] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [indicatorsList, setIndicatorsList] = useState([]);
  const [isSubscribeClicked, setIsSubscribeClicked] = useState(false);
  const [isEmailSubmit, setIsEmailSubmit] = useState(false);
  const [email, setEmail] = useState();
  const [emailSubscribed, setEmailSubscribed] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [filteredYear, setFilteredYear] = useState(null);
  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(5), (val, index) => currentYear - index);
  const local = JSON.parse(localStorage.getItem("selectedCountry"));

  const options = years.map((year) => ({
    value: year,
    label: year.toString(),
  }));

  const toggleModal = () => {
    setIsOpenBulletin(!isOpenBulletin);
  };

  const {
    Bulletin,
    countryList,
    statesList,
    districtsList,
    DroughtIndicesData,
    dashBoardMenuList,
  } = useSelector((state) => ({
    Bulletin: state?.Bulletin,
    countryList: state.Core.coreList,
    DroughtIndicesData: state?.Dashboard.indicatorList,
    statesList: state.Core.corestateList,
    districtsList: state.Core.coredistrictList,
    dashBoardMenuList: state.Dashboard.dashBoardMenuData,
  }));

  const DroughtId = dashBoardMenuList?.[0]?.menu_list?.[1]?.id; // monnotoring indicator id

  useEffect(() => {
    if (Bulletin?.getAllTemplateListData?.data) {
      if (
        Bulletin?.getAllTemplateListData?.data?.status === 200 &&
        Bulletin?.getAllTemplateListData?.data?.data?.result
      ) {
        setCategoryFilter(Bulletin?.getAllTemplateListData?.data?.data?.result);
        setSelectedCategory(
          Bulletin?.getAllTemplateListData?.data?.data?.result[1]?.id,
        );
        dispatch(
          getAllBulletinRequest({
            page: pageNumber,
            data: {
              is_delete: "False",
              is_publish: "True",
              bulletin_status: "1",
              year: new Date().getFullYear().toString(),
              category_id:
                Bulletin?.getAllTemplateListData?.data?.data?.result[1]?.id,
            },
          }),
        );
      } else {
        setCategoryFilter([]);
      }
    }
  }, [Bulletin.getAllTemplateListData]);

  useEffect(() => {
    if (DroughtIndicesData) {
      let parameterArr = [];
      let count = 0;

      DroughtIndicesData.map((item) => {
        if (item?.category === t("CREATE_ONLINE_BULLETIN.DROUGHT")) {
          const x = _.clone(item);
          x.indices.map((i) => {
            i.checked = false;
            i.paramIndex = count;
            count += 1;
            parameterArr.push(i);
            return null;
          });
          return null;
        }
        return null;
      });

      parameterArr = parameterArr.filter((item) => {
        if (
          item.indices_slug !== TemperatureAnomaly &&
          item?.category_name === "Drought"
        ) {
          return true;
        } else {
          return false;
        }
      });

      setIndicatorsList(parameterArr);
    } else {
      setIndicatorsList([]);
    }
  }, [DroughtIndicesData]);

  useEffect(() => {
    dispatch(getAllTemplateListRequest({}));
    dispatch(coreRequest({ location_type: "country" }));
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    setFilteredYear(options?.[0]?.value);
    setDataList([]);
    setOpen(true);
    return () => {
      setDataList([]);
    };
  }, []);

  const handleSelectedItem = (value) => {
    setFilteredYear(value.toString());
    dispatch(
      getAllBulletinRequest({
        page: "1",
        data: {
          is_delete: "False",
          is_publish: "True",
          bulletin_status: "1",
          year: value.toString(),
          category_id: selectedCategory,
        },
      }),
    );
  };

  const pdfPreviewHandler = (e, data) => {
    if (data.bulletin_file) window.open(data.bulletin_file);
    e?.stopPropagation();
  };

  const emailSubmitHandler = () => {
    setIsEmailSubmit(false);
    dispatch(subscribeBulletinRequest({ email }));
  };

  const subscribeButtonHandler = () => {
    setIsSubscribeClicked(true);
  };

  const emailInputHandler = (value) => {
    setEmail(value);
    if (isValidEmail(value)) {
      setIsEmailSubmit(true);
    } else {
      setIsEmailSubmit(false);
    }
  };

  const subscribeLoggedInButtonHandler = () => {
    setIsSubscribeClicked(true);
    dispatch(
      subscribeBulletinRequest({ email: Cookies.get("loggedin_user_details") }),
    );
  };

  const handleEmailSubmitClick = () => {
    emailSubmitHandler();
  };
  const handleSubscribeClick = () => {
    subscribeButtonHandler();
  };
  const handleEmailInput = (e) => {
    emailInputHandler(e.target.value);
  };
  const handleSubscribeClickForLoggedIn = () => {
    subscribeLoggedInButtonHandler();
  };

  const fetchNextTemplateData = () => {
    setPageNumber(pageNumber + 1);
    dispatch(
      getAllBulletinRequest({
        page: pageNumber + 1,
        data: {
          is_delete: "False",
          is_publish: "True",
          bulletin_status: "1",
          year: filteredYear.toString(),
        },
      }),
    );
  };

  useEffect(() => {
    if (DroughtId) {
      dispatch(
        indicatorRequest({
          sub_menu_id: DroughtId,
          countryCode: local?.code,
        }),
      );
    }
  }, [DroughtId]);

  useEffect(() => {
    if (Bulletin?.getAllBulletinData?.data) {
      let new_list = [];
      const {
        data: { result },
      } = Bulletin?.getAllBulletinData;
      if (result) {
        if (dataList) {
          if (pageNumber > 1) {
            new_list = dataList?.concat(result?.results);
          } else {
            new_list = result?.results;
          }
        } else {
          new_list = result?.results;
        }
        setDataList(new_list);
        setPageCount(result?.count);
      } else {
        setDataList([]);
      }
    }
  }, [Bulletin.getAllBulletinData]);

  useEffect(() => {
    if (Bulletin.subscribeBulletinData) {
      const {
        data: { result },
      } = Bulletin.subscribeBulletinData;
      if (result) {
        setIsEmailSubmit(false);
        setIsSubscribeClicked(false);
        setEmail();
        setEmailSubscribed(true);
      }
    }
  }, [Bulletin.subscribeBulletinData]);

  useEffect(() => {
    if (Bulletin.subscribeBulletinDataError) {
      const {
        data: { response },
      } = Bulletin.subscribeBulletinDataError;
      if (response) {
        showError(response?.data?.message);
        setIsEmailSubmit(false);
        setIsSubscribeClicked(false);
        setEmail();
      }
    }
  }, [Bulletin.subscribeBulletinDataError]);

  useEffect(() => {
    if (Bulletin?.getAllTemplateListData?.data) {
      if (
        Bulletin?.getAllTemplateListData?.data?.status === 200 &&
        Bulletin?.getAllTemplateListData?.data?.data?.result
      ) {
        setCategoryFilter(Bulletin?.getAllTemplateListData?.data?.data?.result);
        setSelectedCategory(
          Bulletin?.getAllTemplateListData?.data?.data?.result[1]?.id,
        );
        dispatch(
          getAllBulletinRequest({
            page: pageNumber,
            data: {
              is_delete: "False",
              is_publish: "True",
              bulletin_status: "1",
              year: new Date().getFullYear().toString(),
              category_id:
                Bulletin?.getAllTemplateListData?.data?.data?.result[1]?.id,
            },
          }),
        );
      } else {
        setCategoryFilter([]);
      }
    }
  }, [Bulletin.getAllTemplateListData]);

  const handleSelectedCategory = (value) => {
    setDataList([]);
    setPageNumber(1);
    setSelectedCategory(value.id);
    dispatch(
      getAllBulletinRequest({
        page: "1",
        data: {
          is_delete: "False",
          is_publish: "True",
          bulletin_status: "1",
          year: filteredYear.toString(),
          category_id: value.id,
        },
      }),
    );
  };

  const handleChange = (selectedOption) => {
    setFilteredYear(selectedOption.value);
    handleSelectedItem(selectedOption.value);
  };
  return (
    <div className="bulletin-page">
      <div className="dash-main-row">
        <div className="dashboard-drought">
          <div className="dashboard-filter-div">
            <div className="dashboard-filter online-bulletin-header">
              {isOpenBulletin && (
                <OnlineBulletinModal
                  setIsOpenBulletin={setIsOpenBulletin}
                  isOpenBulletin={isOpenBulletin}
                  toggleModal={toggleModal}
                  bulletinCategories={categoryFilter}
                  indicatorsList={indicatorsList}
                  countryList={countryList}
                  statesList={statesList}
                  districtsList={districtsList}
                />
              )}
              <Row className="bulletin-top-text">
                <Col xs={4} lg={6}>
                  <h3 className="dashboard-filter-h3 heading-publish1">
                    {t("BULLET_IN.ALL_PUBLISHED_BULLETIN")}
                  </h3>
                </Col>
                <Col xs={8} lg={6} className="button-box">
                  <Button className="bulletin-cb" onClick={() => toggleModal()}>
                    {" "}
                    {t("CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.HEADER")}
                  </Button>
                  {!Cookies.get("user") ? (
                    !emailSubscribed &&
                    (isSubscribeClicked ? (
                      <div className="user-input">
                        <Row>
                          <Col sm="8" md="8" xs="8" lg="8">
                            <Input
                              type="email"
                              name="email"
                              id="exampleEmail"
                              placeholder="Your Email Address"
                              bsSize="md"
                              size="50"
                              className="email-input"
                              onChange={(e) => handleEmailInput(e)}
                            />
                          </Col>
                          <Col sm="4" md="4" xs="4" lg="4">
                            <Button
                              className="bulletin-sb"
                              onClick={handleEmailSubmitClick}
                              loading={Bulletin.isSubscribeBulletinRequesting}
                              disabled={!isEmailSubmit}
                            >
                              {t("BULLET_IN.SUBMIT")}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <Button
                        className="bulletin-sb"
                        onClick={handleSubscribeClick}
                      >
                        {t("BULLET_IN.SUBSCRIBE_NOW")}
                      </Button>
                    ))
                  ) : (
                    <Button
                      className="bulletin-sb"
                      onClick={handleSubscribeClickForLoggedIn}
                      loading={Bulletin.isSubscribeBulletinRequesting}
                      disabled={!isEmailSubmit}
                    >
                      {t("BULLET_IN.SUBSCRIBED")}
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </div>

          <Row className="content-section">
            <Col className="bulletin-data">
              <Row className="bulletin-menu">
                <Col xs={4}>
                  <Button
                    className={
                      selectedCategory === categoryFilter[1]?.id
                        ? " bulletin-sb"
                        : "  bulletin-cb"
                    }
                    onClick={() =>
                      categoryFilter[1] &&
                      handleSelectedCategory(categoryFilter[1])
                    }
                  >
                    {t("BULLET_IN.MONTHLY_BULLETIN")}
                  </Button>
                  <Button
                    type="button"
                    className={
                      selectedCategory === categoryFilter[0]?.id
                        ? "bulletin-sb"
                        : "bulletin-cb"
                    }
                    onClick={() =>
                      categoryFilter[0] &&
                      handleSelectedCategory(categoryFilter[0])
                    }
                  >
                    {t("BULLET_IN.BI_WEEKLY_BULLETIN")}
                  </Button>
                </Col>

                <Col xs={4} className="common-select-tem-anomaly year-dropdown">
                  <Select
                    classNamePrefix="react-select"
                    className=" common-select-r"
                    value={
                      filteredYear
                        ? {
                            value: filteredYear,
                            label: filteredYear.toString(),
                          }
                        : null
                    }
                    onChange={handleChange}
                    options={[...options]}
                    placeholder="Select year"
                  />
                </Col>
              </Row>
              <hr className="title-hr" />
              <div className="loader-div">
                {Bulletin.isGetAllBulletinRequesting && <GlobalLoader />}
              </div>
              {_.isEmpty(dataList) && !Bulletin.isGetAllBulletinRequesting && (
                <h2 className="bulletin-template-modal-no-records-found no-record-found-font-size">
                  {t("BULLET_IN.NO_RECORDS_FOUND")}
                </h2>
              )}
              <InfiniteScroll
                dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ""}
                next={fetchNextTemplateData}
                hasMore={pageNumber * 10 < pageCount}
                loader={Bulletin.isGetAllBulletinRequesting}
                className="flex flex-wrap"
              >
                <div className="mt-3">
                  <Row className="m-3">
                    {dataList?.length
                      ? dataList?.map((item) => {
                          return (
                            <Col
                              className="bulletin-template-modal-card-col"
                              lg={3}
                            >
                              <div className="position-relative bulliten-card-container">
                                <Card
                                  className="mt-3 pt-3 mb-5  bulletin-card-pointer h-100"
                                  onClick={(e) => {
                                    pdfPreviewHandler(e, item);
                                  }}
                                >
                                  <CardImg
                                    src={item?.content_file || NewDummyImage}
                                    top
                                    width="100%"
                                    className="front-office-bulletin-card-Image"
                                  />
                                  <div className="m-3">
                                    <CardTitle tag="h5">
                                      {item?.topic?.length > 20
                                        ? `${item?.topic?.substring(0, 20)} ...`
                                        : item?.topic}
                                    </CardTitle>
                                    <CardSubtitle
                                      className="mb-2 text-muted"
                                      tag="h6"
                                    >
                                      {item?.category_name}
                                    </CardSubtitle>

                                    <div className="d-flex">
                                      {!item?.is_publish ? (
                                        <div className="back-office-bulletin-In-progress">
                                          <BsFillRecordFill />
                                          <span>
                                            {t("BULLET_IN.IN_PROGRESS")}
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="back-office-bulletin-Publish">
                                          <BsCheckAll />
                                          <span>
                                            {t("BULLET_IN.PUBLISHED_ON")}{" "}
                                            {moment(item?.updated_date).format(
                                              DISPLAY_CHART_DATE,
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            </Col>
                          );
                        })
                      : ""}
                  </Row>
                </div>
              </InfiniteScroll>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
export default React.memo(HomePageBulletin);
