import React from "react";
import { Editor } from "@tinymce/tinymce-react";

function DraftEditor(props) {
  const { content, onEditorChange } = props;
  return (
    <div className="decription-editor" id="tiny-editor">
      <Editor
        value={content === undefined ? "" : content}
        apiKey="8sg2vaosizljdlh4xg6frgc3nsfs0wvg022tnp6ibklzdez6"
        onEditorChange={(Text) => onEditorChange(Text)}
        init={{
          height: "26rem",
          selector: "#tiny-editor",
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media  paste imagetools wordcount",
          ],
          toolbar:
            "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | link image | code",

          image_title: true,
          file_picker_types: "image",
          file_picker_callback(cb) {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            // eslint-disable-next-line func-names
            input.onchange = function () {
              // eslint-disable-next-line react/no-this-in-sfc
              const file = this.files[0];
              const reader = new FileReader();
              reader.onload = () => {
                const id = `blobid${new Date().getTime()}`;
                const blobCache =
                  window.tinymce.activeEditor.editorUpload.blobCache;
                const base64 = reader.result.split(",")[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
                cb(blobInfo.blobUri(), { title: file.name });
              };
              reader.readAsDataURL(file);
            };
            input.click();
          },
        }}
      />
    </div>
  );
}

export default DraftEditor;
