import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Optionicon from "../../assest/img/option.png";
import { deleteSurveyorsRequest } from "../../store/odk/odkActions";

import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { CLIENT_ID, SCOPE, API_KEY } from "../../constants/index";
import { showError } from "../../components/Common/Notification";
import { getODKAccessToken, setODKAccessToken } from "../../helpers";

function DetailsDropdown(props) {
  const {
    request_Id,
    loggedUserData,
    handleLatestDataForDelete,
    userDetails,
    setIsLoading,
    email,
  } = props;
  const history = useNavigate();
  const { t } = useTranslation();
  const nextProps = useSelector((state) => ({
    deleteUser: state.User.deleteUser,
    // deleteData: state.AccessKey.deleteData || null,
    deleteSurveyorsData: state.odk?.deleteSurveyorsData,
    managesurveyorsListData: state.odk?.managesurveyorsListData,
  }));
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tokenClient, setTokenClient] = useState("");
  const [driveFolderId, setDriveFolderId] = useState(null);
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    if (userDetails) {
      setDriveFolderId(userDetails?.drive_folder_id);
    }
  }, [userDetails]);

  useEffect(() => {
    if (loggedUserData?.length) {
      const a = [];
      loggedUserData.map((i) => {
        return a.push(i.codename);
      });
    }
  }, [loggedUserData]);

  useEffect(() => {
    if (nextProps.deleteUser) {
      const {
        data: { result },
      } = nextProps.deleteUser;
      if (result) {
        handleLatestDataForDelete();
      }
    }
  }, [nextProps.deleteUser]);

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  useEffect(() => {
    if (getODKAccessToken() && isTokenExpired) {
      // showError('Unable to proceed due to expired access token. please authorize and continue')
      if (userDetails?.google_email)
        tokenClient?.requestAccessToken({
          prompt: "",
          hint: userDetails?.google_email || "",
        });
      else tokenClient?.requestAccessToken({ prompt: "consent" });
    }
  }, [isTokenExpired]);

  async function deletePermissions(drive_FolderId, id, accessToken) {
    await fetch(
      `https://www.googleapis.com/drive/v3/files/${drive_FolderId}/permissions/${id}?key=${API_KEY}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
      .then((response) => {
        if (response?.error) throw response?.error;
        dispatch(deleteSurveyorsRequest({ email_id: request_Id }));
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err.code === 401 &&
          err?.status === "UNAUTHENTICATED" &&
          getODKAccessToken()
        ) {
          setIsTokenExpired(true);
        } else {
          if (err?.response?.code === 204) {
            setIsLoading(true);
            dispatch(deleteSurveyorsRequest({ email_id: request_Id }));
          }
          setIsTokenExpired(false);
        }
      });
  }

  async function getListOfPermissions(mail, accessToken) {
    await fetch(
      `https://www.googleapis.com/drive/v3/files/${driveFolderId}/permissions?key=${API_KEY}&supportsAllDrives=true&fields=permissions(id,emailAddress),nextPageToken`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
      .then((res) => res.json())
      .then((response) => {
        if (response?.error) throw response?.error;
        response?.permissions?.map((item) => {
          if (item.emailAddress.toString() === email?.toString()) {
            deletePermissions(driveFolderId, item.id, accessToken);
          }
          return false;
        });

        if (
          response?.permissions?.every((item) => item.emailAddress !== mail)
        ) {
          showError(
            "Surveyor do not have permission to access drive files. Unable to delete",
          );
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err.code === 401 &&
          err?.status === "UNAUTHENTICATED" &&
          getODKAccessToken()
        ) {
          setIsTokenExpired(true);
        } else setIsTokenExpired(false);
      });
  }

  const handleClientLoad = () => {
    // initializing token token Client
    const token_client = window?.google?.accounts?.oauth2?.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPE,
      prompt: userDetails?.google_email ? "" : "consent",
      hint: userDetails?.google_email || "",
      callback: (tokenResponse) => {
        if (tokenResponse && tokenResponse?.access_token) {
          setIsTokenExpired(false);
          setODKAccessToken(tokenResponse?.access_token);
        }
        // we now have access to a live token to use for any google api
      },
      error_callback: (err) => {
        if (err.type === "popup_failed_to_open") {
          // The popup window is failed to open
          if (err?.message) {
            showError(
              `${err.message}. Please allow the popup to open in browser settings if it is blocked.`,
            );
            history("/surveyDataSettingsDashboard");
          }
        } else if (err.type === "popup_closed") {
          // The popup window is closed before an OAuth response is returned
        }
      },
    });
    setTokenClient(token_client);
  };
  useEffect(() => {
    if (nextProps.managesurveyorsListData) {
      handleClientLoad();
    }
  }, [nextProps.managesurveyorsListData]);

  const handleDeleteConfirm = (boolean_value, value) => {
    setIsDeleteModalOpen(boolean_value);
    if (value === "yes") {
      // dispatch(deleteUserRequest({ id: request_Id }));
      // deletePermissions("")
      // permissionIdForEmail(props.email)
      setIsLoading(true);
      const access_token = getODKAccessToken();
      if (access_token) getListOfPermissions(email, access_token);
      else {
        if (userDetails?.google_email)
          tokenClient?.requestAccessToken({
            prompt: "",
            hint: userDetails?.google_email || "",
          });
        else tokenClient?.requestAccessToken({ prompt: "consent" });
        tokenClient.callback = (res) => {
          setODKAccessToken(res?.access_token);
          getListOfPermissions(email, res?.access_token);
        };
      }
    }
  };

  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className="dropdown-option-icon"
      >
        <DropdownToggle>
          <img src={Optionicon} alt="not-available" />
        </DropdownToggle>
        <DropdownMenu className="details-dropdown-menu">
          <DropdownItem
            className="details-dropdown-item"
            onClick={() => handleDelete()}
          >
            {t("MANAGE_USER.DELETE")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      {isDeleteModalOpen ? (
        <DeleteConfirmationModal
          handleDeleteConfirm={handleDeleteConfirm}
          isDeleteModalOpen={isDeleteModalOpen}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default DetailsDropdown;
