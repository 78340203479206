import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function EarlyFinanceHeader({ isFinance, selectedSubModule }) {
  const { t } = useTranslation();
  const history = useNavigate();
  return (
    <div className="EA-Header ea-space-between">
      <div className="back-office-center">
        <p className="ea-header-text">{t("EARLY_FINANCE.EARLY_FINANCE")} </p>
      </div>
      <Button
        className="ea-create-btn"
        onClick={() =>
          history(
            isFinance
              ? `/ea-projects?isFinance=true&slug=${selectedSubModule}`
              : `/early-finance?slug=${selectedSubModule}`,
          )
        }
      >
        {" "}
        {t("EARLY_ACTIONS.PROCEED")}
      </Button>
    </div>
  );
}

export default EarlyFinanceHeader;
