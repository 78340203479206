import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../../assest/img/ea-download.svg";
import ICON from "../../../assest/img/empy-img.png";
import EarlyActionTable from "../../../components/Common/EarlyActionTable";
import { ReactComponent as EditIcon } from "../../../assest/img/ea-edit.svg";
import {
  editReadynessContentRequest,
  getReadynessPhaseRequest,
} from "../../../store/actions";
import {
  hanldeExportToBudgetCSV,
  isLogedIn,
} from "../../../components/Common/Utils";
import RightSideModal from "./editImageSidebar";
import Comments from "../../../components/Common/Comments";
import { handleExportToCSVFinance } from "../../../helpers/excelDownload";
import { ColumnsDataFinance } from "../../../constants";

function budgetCell({ cell, currencySymbol }) {
  const convertedNumber = cell.getValue() ? Number(cell.getValue()) : 0;
  return (
    <div>{`${currencySymbol} ${Number(
      convertedNumber,
    )?.toLocaleString()}`}</div>
  );
}

function NewIntervention() {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();

  const [, setOpen] = useOutletContext();
  const scrollref = useRef();

  const [intervantationData, setInterventationdataList] = useState({
    name: "",
    indicator: "",
    people: "",
    houseHolds: "",
    imageUrl: "",
    budget: "",
  });

  const [initialValues, setInitialValues] = useState({
    activities: "",
    timeframe: "",
    target: "",
    budget: "",
  });
  const [readynessPPState, setReadynessPPState] = useState([]);
  const [readynessRRState, setReadynessRRState] = useState([]);
  const [readynessPrState, setReadynessPrState] = useState([]);
  const [selectTable, setSelectTable] = useState();
  const [callApi, setApiCall] = useState(false);
  const [filterModal, setFilterModal] = useState(false);

  const [sendComment, setSendComment] = useState("");
  const [commentList, setCommentList] = useState([]);

  const handleClickBack = () => {
    localStorage.setItem("stepperActiveStep", "1");
    history(-1);
  };

  const projectDetails = JSON.parse(localStorage.getItem("projectDetails"));

  const userDetails = JSON.parse(isLogedIn());

  // COLUMNS HEADER NAMES

  const COLUMNS = [
    {
      Header: t("EARLY_ACTIONS.ACTIVITIES"),
      accessorKey: "Activities",
      size: 300,
    },
    {
      Header: t("EARLY_ACTIONS.TIME_FRAME"),
      accessorKey: "Timeframe",
      size: 100,
    },
    {
      Header: t("EARLY_ACTIONS.TARGET"),
      accessorKey: "Target",
      size: 60,
    },
    {
      Header: t("EARLY_FINANCE.BUDGET"),
      accessorKey: "Budget",
      size: 60,
      Cell: ({ cell }) =>
        budgetCell({ cell, currencySymbol: projectDetails?.currency_symbol }),
    },
    {
      Header: t("EARLY_ACTIONS.MANAGE_DATA"),
      accessorKey: "managedata",
      size: 60,
    },
  ];

  const nextProps = useSelector((state) => ({
    addSucess: state?.EAReadynessReducer?.createReadynessPhaseData,
    editSuccess: state?.EAReadynessReducer?.editedReadynessPhaseData,
    interventionList: state?.EAReadynessReducer?.interventionList,
  }));

  const onHandleDownloadexcel = (data, fileNmae, currency) => {
    handleExportToCSVFinance(data, ColumnsDataFinance, fileNmae, currency);
  };

  const params = new URLSearchParams(window.location.search);
  const iId = params.get("interventionId");
  const interventionName = params.get("name");

  // CALIING THE GET API WHEN EVER EDIT API IS SUCCESS
  const isAddandEditRun = useRef(true);
  useEffect(() => {
    if (isAddandEditRun.current) {
      isAddandEditRun.current = false;
      return;
    }
    if (nextProps?.editSuccess) {
      dispatch(
        getReadynessPhaseRequest({
          module: "early_action",
          project_id: JSON.parse(localStorage.getItem("projectDetails"))?.id,
          data: 0,
        }),
      );
    }
  }, [nextProps.editSuccess]);

  //  FUNCTION FOR SETTING THE TABLE FORMAT DATA WHEN WE CALL API
  const modifyData = (dataArray, tableNum) => {
    const modifiedData = [];
    setApiCall(false);

    dataArray?.forEach((contentData) => {
      const uniqueId = uuidv4();
      const modifedColumn = {
        id: uniqueId,
        Activities: contentData.Activities,
        Target: contentData.Target,
        Budget: contentData.Budget,
        Timeframe: contentData.Timeframe,
        managedata: (
          <div className="d-flex align-items-center justify-content-around">
            <span>
              <EditIcon
                onClick={() => {
                  setInitialValues({
                    activities: contentData.Activities,
                    timeframe: contentData.Timeframe,
                    target: contentData.Target,
                    budget: contentData.Budget,
                    table: tableNum,
                    id: uniqueId,
                  });
                  setSelectTable(tableNum);
                  setFilterModal(true);
                }}
                className="cursor-pointer"
              />
            </span>
          </div>
        ),
      };

      modifiedData.push(modifedColumn);
    });

    return modifiedData;
  };

  //  UPDATEING THE  INTERVENTION LIST WHEN WE GET RESPONSE FROM API
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (nextProps?.interventionList?.length) {
      const selectedIntervention = nextProps.interventionList.find(
        (each) => each?.id === iId,
      );
      setSendComment("");
      setCommentList(selectedIntervention?.ef_comment);

      setInterventationdataList((prev) => ({
        ...prev,
        name: selectedIntervention?.name,
        indicator: selectedIntervention?.indicator,
        people: selectedIntervention?.people_targeted,
        houseHolds: selectedIntervention?.households_targeted,
        imageUrl: selectedIntervention?.icon_url,
        budget: selectedIntervention?.budget,
      }));
      const scrollableElement = scrollref.current;
      const timeoutId = setTimeout(() => {
        sendComment !== ""
          ? (scrollableElement.scrollTop = scrollableElement.scrollHeight)
          : "";
      }, 100);
      const ppPhase = modifyData(selectedIntervention?.preparedness_content, 1);
      const RRPhase = modifyData(selectedIntervention?.readiness_content, 2);
      const PRPhase = modifyData(
        selectedIntervention?.prioritized_ea_content,
        3,
      );
      setReadynessPPState(ppPhase);
      setReadynessRRState(RRPhase);
      setReadynessPrState(PRPhase);
      return () => clearTimeout(timeoutId);
    }
  }, [nextProps.interventionList]);
  const onHanldeAddEdit = (data) => {
    setApiCall(true);
    const modifedColumn = {};
    modifedColumn.id = data.id;
    modifedColumn.Activities = data.activities;
    modifedColumn.Timeframe = data.timeframe;
    modifedColumn.Budget = data.budget;
    modifedColumn.Target = data.target;

    modifedColumn.managedata = (
      <div className="d-flex align-items-center justify-content-center">
        <span>
          <EditIcon
            onClick={() => {
              setInitialValues({
                ...data,
                table: selectTable,
                id: data.id,
              });
              setSelectTable(selectTable);
            }}
            className="cursor-pointer"
          />
        </span>
      </div>
    );
    if (filterModal) {
      if (selectTable === 1) {
        const updatedArray = readynessPPState.map((obj) =>
          obj.id === data?.id ? { ...modifedColumn } : obj,
        );
        setReadynessPPState([...updatedArray]);
      }
      if (selectTable === 2) {
        const updatedArray = readynessRRState.map((obj) =>
          obj.id === initialValues?.id ? { ...modifedColumn } : obj,
        );
        setReadynessRRState([...updatedArray]);
      }
      if (selectTable === 3) {
        const updatedArray = readynessPrState.map((obj) =>
          obj.id === initialValues?.id ? { ...modifedColumn, id: obj.id } : obj,
        );
        setReadynessPrState([...updatedArray]);
      }
    }

    setFilterModal(false);
    setInitialValues((prev) => ({
      ...prev,
      budget: "",
      activities: "",
      timeframe: "",
      target: "",
      table: "",
    }));
    setSelectTable();
  };

  // API FOR GETTING THE INTERVENTION LIST INITIALLY
  useEffect(() => {
    dispatch(
      getReadynessPhaseRequest({
        module: "early_finance",
        project_id: JSON.parse(localStorage.getItem("projectDetails"))?.id,
        data: 0,
      }),
    );
    setOpen(true);
  }, []);

  // REMOVING THE MANAGE KEY DATA WHEN WE SEND TO THE API
  function removeKeyFromObject(obj, keyToRemove) {
    const newObj = { ...obj };
    delete newObj[keyToRemove];
    return newObj;
  }

  // FUNCTION TO CALL API WHEN WE UPDATE ANY VALUE IN INTERVENTION
  const onDataChange = () => {
    if (intervantationData?.name) {
      const readynessPPStatenewArray = readynessPPState.map((obj) =>
        removeKeyFromObject(obj, "managedata"),
      );
      const readynessRRStatenewArray = readynessRRState.map((obj) =>
        removeKeyFromObject(obj, "managedata"),
      );
      const readynessPrStatenewArray = readynessPrState.map((obj) =>
        removeKeyFromObject(obj, "managedata"),
      );

      const payLoad = {
        intervention_id: iId,
        project_id: JSON.parse(localStorage.getItem("projectDetails"))?.id,
        name: intervantationData?.name,
        currency_id: projectDetails?.ef_currency_id,
        indicator: intervantationData?.indicator,
        preparedness_content: readynessPPStatenewArray,
        readiness_content: readynessRRStatenewArray,
        prioritized_ea_content: readynessPrStatenewArray,
        households_targeted: !intervantationData?.houseHolds
          ? 0
          : intervantationData?.houseHolds,
        people_targeted: !intervantationData?.people
          ? 0
          : intervantationData?.people,
      };

      dispatch(editReadynessContentRequest(payLoad));
    }
  };

  // CALLING THE API FOR UPDATE WHEN THE WE UPDATE IMAGE SECTION
  useEffect(() => {
    if (!filterModal) {
      setInitialValues((prev) => ({
        ...prev,
        budget: "",
        activities: "",
        timeframe: "",
        target: "",
        table: "",
      }));
      if (callApi) {
        onDataChange();
      }
    }
  }, [filterModal]);

  useEffect(() => {
    // Add a CSS class to the body element when the sideEditor is open
    if (filterModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [filterModal]);

  const onHandleComments = () => {
    const payLoad = {
      intervention_id: iId,
      project_id: JSON.parse(localStorage.getItem("projectDetails"))?.id,
      ef_comment: [
        {
          name: userDetails?.user_details?.[1]?.first_name,
          comment: sendComment,
          date: new Date(),
        },
      ],
    };

    dispatch(editReadynessContentRequest(payLoad));
  };

  return (
    <>
      <div className="ea-project-container">
        <div className="EA-Header ea-space-between">
          <div className="ea-header-image-position">
            <div>
              <BsArrowLeft
                className="back-icon-ea"
                onClick={handleClickBack}
                role="button"
              />
              <p className="ea-header-text">{interventionName}</p>
            </div>
          </div>
        </div>
        <div className="ea-space">
          <Row className="new-inv-div">
            <Col lg={2}>
              {intervantationData?.imageUrl ? (
                <img
                  src={intervantationData?.imageUrl}
                  alt=""
                  className="new-inv-img"
                />
              ) : (
                <img src={ICON} alt="" className="new-inv-img" />
              )}
            </Col>
            <Col lg={10} className="new-inv-heading">
              <h3>
                {intervantationData?.name === ""
                  ? t("EARLY_ACTIONS.NEW_INTERVENTION")
                  : intervantationData?.name}
              </h3>
              <p>
                {t("EARLY_ACTIONS.INDICATOR")}: {intervantationData?.indicator}
              </p>
              <p>
                {t("EARLY_ACTIONS.TARGETED_HOUSEHOLDS")}:{" "}
                {intervantationData?.houseHolds}
              </p>
              <p>
                {t("EARLY_FINANCE.PEOPLE")}: {intervantationData?.people}
              </p>
              <h3 className="ef-total-budget">
                {t("EARLY_FINANCE.BUDGET")}: {projectDetails?.currency_symbol}{" "}
                {intervantationData?.budget?.total_budget?.toLocaleString()}
              </h3>
            </Col>
          </Row>
          <div className="new-inv-section">
            <div className="new-inv-section-2" ref={scrollref}>
              <div className="intervention-div">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <h2 className="intervention-h2">
                      {t("EARLY_FINANCE.EF_PREPAREDNESS_TEXT")}
                    </h2>
                    <h3 className="ef-total-budget">
                      {t("EARLY_FINANCE.BUDGET")}:{" "}
                      {projectDetails?.currency_symbol}{" "}
                      {intervantationData?.budget?.preparedness_budget?.toLocaleString()}
                    </h3>
                  </div>
                  <div className="actions-container d-flex align-items-center justify-content-end">
                    <span
                      role="button"
                      className="ea-upload-button"
                      onClick={() =>
                        onHandleDownloadexcel(
                          readynessPPState,
                          "preparedness_table",
                          projectDetails?.currency_symbol,
                        )
                      }
                      onKeyUp={() => {}}
                      tabIndex="0"
                      aria-label="EfEditreadynessPPState"
                    >
                      <DownloadIcon />
                    </span>
                  </div>
                </div>
                <div className="new-inv-table-1">
                  <EarlyActionTable
                    COLUMNS={COLUMNS}
                    tableData={readynessPPState}
                    step={1}
                  />
                </div>
              </div>
              <div className="intervention-div">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <h2 className="intervention-h2">
                      {t("EARLY_FINANCE.EF_READYNESS_TEXT")}
                    </h2>
                    <h3 className="ef-total-budget">
                      {t("EARLY_FINANCE.BUDGET")}:{" "}
                      {projectDetails?.currency_symbol}{" "}
                      {intervantationData?.budget?.readiness_budget?.toLocaleString()}
                    </h3>
                  </div>
                  <div className="actions-container d-flex align-items-center justify-content-end">
                    <input type="file" style={{ display: "none" }} />

                    <span
                      role="button"
                      className="ea-upload-button"
                      onClick={() =>
                        onHandleDownloadexcel(
                          readynessRRState,
                          "readyness_table",
                          projectDetails?.currency_symbol,
                        )
                      }
                      onKeyUp={() => {}}
                      tabIndex="0"
                      aria-label="EfEditreadynessRRState"
                    >
                      <DownloadIcon />
                    </span>
                  </div>
                </div>
                <div className="new-inv-table-1">
                  <EarlyActionTable
                    COLUMNS={COLUMNS}
                    tableData={readynessRRState}
                  />
                </div>
              </div>
              <div className="intervention-div">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <h2 className="intervention-h2">
                      {t("EARLY_FINANCE.EF_PIRORITYNESS_TEXT")}
                    </h2>
                    {/* intervention-h3 */}
                    <h3 className="ef-total-budget">
                      {t("EARLY_FINANCE.BUDGET")}:{" "}
                      {projectDetails?.currency_symbol}{" "}
                      {intervantationData?.budget?.prioritized_budget?.toLocaleString()}
                    </h3>
                  </div>
                  <div className="actions-container d-flex align-items-center justify-content-end">
                    <span
                      role="button"
                      className="ea-upload-button d-none"
                      onClick={() =>
                        hanldeExportToBudgetCSV(
                          readynessPrState,
                          "priority_table",
                        )
                      }
                      onKeyUp={() => {}}
                      tabIndex="0"
                      aria-label="EfEditreadynessPrState"
                    >
                      <DownloadIcon />
                    </span>

                    <span
                      role="button"
                      className="ea-upload-button"
                      onClick={() =>
                        onHandleDownloadexcel(
                          readynessPrState,
                          "priority_table",
                          projectDetails?.currency_symbol,
                        )
                      }
                      onKeyUp={() => {}}
                      tabIndex="0"
                      aria-label="EfEditreadynessPrState"
                    >
                      <DownloadIcon />
                    </span>
                  </div>
                </div>
                <div className="new-inv-table-1">
                  <EarlyActionTable
                    COLUMNS={COLUMNS}
                    tableData={readynessPrState}
                  />
                </div>
              </div>

              <div className="intervention-div ">
                <Comments
                  onHandleComments={onHandleComments}
                  setComment={setSendComment}
                  sendComment={sendComment}
                  commentList={commentList}
                  canAddComment
                />
              </div>
            </div>
          </div>
        </div>

        {filterModal ? (
          <RightSideModal
            toggle={() => setFilterModal(!filterModal)}
            setApiCall={setApiCall}
            onHanldeAddEdit={onHanldeAddEdit}
            initialValues={initialValues}
          />
        ) : (
          ""
        )}
      </div>
      {filterModal && <div className="ea-project-card-disabled " />}
    </>
  );
}
export default NewIntervention;
