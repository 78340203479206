import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  ModalFooter,
  Label,
  // Input,
  Collapse,
} from "reactstrap";
import { HiOutlineXMark } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import {
  DISPLAY_DATE_FORMAT,
  DELETE_PROJECT_ENABLE_BY_DEFAULT,
  EDIT_PROJECT_ENABLE_BY_DEFAULT,
} from "../../constants";
import { isPermissionsMatch, superAdmin } from "../../components/Common/Utils";

function ViewDetailsModal({
  isOpen,
  toggle,
  userDetails,
  permissionList,
  groupNamesList,
  preSelectedPermissions,
  setSelectedPermissions,
  selectedPermissions,
  onUpdateUserpermissions,
  permissionsList,
}) {
  const { t } = useTranslation();
  const [newList, setNewList] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false); // selectedAll
  const [selectedParentCategoryName, setSelectedParentCategoryName] =
    useState();
  function checkPermissionMatch(array1, array2) {
    return array1.some((obj1) =>
      array2.some((obj2) => obj1.permission_id === obj2.id),
    );
  }

  // initially for selecting predefined permissions and sorting based on groupname
  useEffect(() => {
    const y = [];
    if (groupNamesList && preSelectedPermissions) {
      const a = [];
      preSelectedPermissions?.map((i) => {
        a.push(i.id);
        return null;
      });
      setSelectedPermissions(a);
      groupNamesList?.map((i) => {
        const filterList = permissionList.filter(
          (item) => item.group_name === i,
        );
        const hasMatch =
          preSelectedPermissions &&
          checkPermissionMatch(filterList, preSelectedPermissions);

        y.push({
          category_name: i,
          list: filterList,
          checked: false,
          dropdownClicked: preSelectedPermissions ? hasMatch : false,
        });
        return null;
      });
      y.map((i) => {
        const b = [];
        i.list.map((item) => {
          if (a.includes(item.permission_id)) {
            b.push(item.permission_id);
          }
          return null;
        });
        i.parentList = b;
        if (i.parentList.length === i.list.length) {
          i.checked = true;
        }
        return null;
      });
      setNewList(y);
    }
  }, [groupNamesList, preSelectedPermissions]);

  // when we select the category name
  const handleParentOnchangePermissions = (e, i) => {
    let a = selectedPermissions;
    let b;
    if (e.target.checked) {
      i.list.forEach((item) => {
        if (!selectedPermissions.includes(item.permission_id)) {
          a.push(item.permission_id);
        }
        return null;
      });
      b = newList.map((item) => {
        if (item.category_name === i.category_name) {
          i.dropdownClicked = true;
          i.checked = true;
        }
        return item;
      });
    } else {
      b = newList.map((item) => {
        if (item.category_name === i.category_name) {
          a = selectedPermissions;
          i.list.map((data) => {
            a = a.filter((y) => {
              if (y !== data.permission_id) {
                return y;
              }
              return null;
            });
            return null;
          });
          i.checked = false;
          i.dropdownClicked = false;
        }
        return item;
      });
    }
    setNewList(b);
    setSelectedPermissions(JSON.parse(JSON.stringify(a)));
  };

  //  triggered when we select selectAll check box
  useEffect(() => {
    if (selectedPermissions && permissionList) {
      const a = [];

      permissionList?.forEach((i) => {
        a.push(i.permission_id);
      });
      const b = selectedPermissions?.sort().join(",") === a.sort().join(",");
      if (b) {
        setSelectedAll(true);
      } else {
        setSelectedAll(false);
      }
    }
  }, [selectedPermissions, permissionList]);

  useEffect(() => {
    const a = [];
    const y = [];
    if (selectedPermissions.length) {
      if (selectedParentCategoryName) {
        let c = newList;
        c = newList.map((i) => {
          if (selectedParentCategoryName === i.category_name) {
            i.list.map((x) => {
              y.push(x.permission_id);
              selectedPermissions.filter((permission) => {
                if (x.permission_id === permission) {
                  a.push(permission);
                }
                return null;
              });
              return null;
            });
            const b = a.sort().join(",") === y.sort().join(",");
            i.checked = b;
            // i.dropdownClicked= b
          }
          return i;
        });
        setNewList(c);
      }
    }
  }, [selectedPermissions, selectedParentCategoryName]);

  //  function call while selecting permission under collaspe
  const handleChildOnchangePermissions = (e, item, totalList) => {
    let a = [];
    a = selectedPermissions;
    setSelectedParentCategoryName(item.group_name);
    if (e.target.checked) {
      // if (selectedPermissions.includes(item.permission_id)) {
      //   let b = [];
      //   b = selectedPermissions;
      //   setSelectedPermissions(b.filter((i) => item.permission_id !== i));
      // } else {
      if (item?.code_name === "delete_project") {
        a.push(item?.permission_id);

        totalList.filter((each1) => {
          if (
            DELETE_PROJECT_ENABLE_BY_DEFAULT.includes(each1.code_name) &&
            !selectedPermissions?.includes(each1?.permission_id)
          ) {
            a.push(each1?.permission_id);
          }
          return null;
        });
        setSelectedPermissions(JSON.parse(JSON.stringify(a)));
      } else if (item?.code_name === "change_project") {
        a.push(item?.permission_id);

        totalList.filter((each1) => {
          if (
            EDIT_PROJECT_ENABLE_BY_DEFAULT.includes(each1.code_name) &&
            !selectedPermissions?.includes(each1?.permission_id)
          ) {
            a.push(each1?.permission_id);
          }
          return null;
        });
        setSelectedPermissions(JSON.parse(JSON.stringify(a)));
      } else {
        a.push(item.permission_id);

        setSelectedPermissions(JSON.parse(JSON.stringify(a)));
      }
    } else {
      let b = [];

      b = selectedPermissions;
      if (selectedPermissions.includes(item.permission_id)) {
        if (item?.code_name === "delete_project") {
          const deleteCat = [item.permission_id];
          totalList.filter((each1) => {
            if (DELETE_PROJECT_ENABLE_BY_DEFAULT?.includes(each1.code_name)) {
              deleteCat.push(each1?.permission_id);
            }
            return null;
          });

          setSelectedPermissions(b.filter((i) => !deleteCat?.includes(i)));
        } else if (item?.code_name === "change_project") {
          const deleteCat = [item.permission_id];
          totalList.filter((each1) => {
            if (EDIT_PROJECT_ENABLE_BY_DEFAULT?.includes(each1.code_name)) {
              deleteCat.push(each1?.permission_id);
            }
            return null;
          });

          setSelectedPermissions(b.filter((i) => !deleteCat?.includes(i)));
        } else {
          setSelectedPermissions(b.filter((i) => item.permission_id !== i));
        }

        const x = newList;
        x.forEach((data) => {
          if (item.group_name === data.category_name) {
            data.checked = false;
          }
        });
        setNewList(JSON.parse(JSON.stringify(x)));
      }
    }
  };

  //  function called when we selectAll check box
  const handleSelectAllPermissions = (e) => {
    const a = [];
    let b = [];
    if (!e.target.checked) {
      b = newList.map((data) => {
        data.checked = false;
        data.dropdownClicked = false;
        return data;
      });
    } else {
      permissionList.map((i) => {
        a.push(i.permission_id);
        return null;
      });
      b = newList.map((data) => {
        data.checked = true;
        data.dropdownClicked = true;
        return data;
      });
    }
    setNewList(b);
    setSelectedPermissions(a);
    setSelectedAll(true);
  };

  // for opening and closing the collaspe
  const handleDropdownClick = (data) => {
    const list1 = newList.map((i) => {
      if (i.category_name === data.category_name) {
        i.dropdownClicked = !i.dropdownClicked;
      }
      return i;
    });
    setNewList(list1);
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      className="modal-dialog  Home-page-md appr-modal"
    >
      <ModalHeader className="border-content">
        {t("APPROVE_DATA.DETAILS")}
        <Button onClick={toggle}>
          <HiOutlineXMark />
        </Button>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm={3} className="">
            <h3 className="manage-user-detail-h3">{t("SETTINGS.NAME")}</h3>
            <p className="approve-detail-p">
              {userDetails?.user_profile?.first_name}{" "}
            </p>
          </Col>
          <Col sm={3} className="">
            <h3 className="manage-user-detail-h3">
              {" "}
              {t("MANAGE_USERS.EMAIL")}
            </h3>
            <p className="approve-detail-p">{userDetails?.user?.email}</p>
          </Col>
          <Col sm={3} className="">
            <h3 className="manage-user-detail-h3">
              {" "}
              {t("MANAGE_USERS.COUNTRY")}
            </h3>
            <p className="approve-detail-p">
              {userDetails?.user_profile?.country_name}
            </p>
          </Col>
          <Col sm={3} className="">
            <h3 className="manage-user-detail-h3">
              {" "}
              {t("MANAGE_USERS.ORGANIZATION")}
            </h3>
            <p className="approve-detail-p">
              {userDetails?.user_profile?.organization_name}
            </p>
          </Col>

          <Col sm={3} className="mt-4">
            <h3 className="manage-user-detail-h3">
              {" "}
              {t("MANAGE_USERS.LAST_LOGIN")}
            </h3>
            <p className="approve-detail-p">
              {moment(userDetails?.user?.last_login).format(
                DISPLAY_DATE_FORMAT,
              )}
            </p>
          </Col>
          <Col sm={3} className="mt-4">
            <h3 className="manage-user-detail-h3">
              {" "}
              {t("MANAGE_USERS.DATE_OF_REGISTERATION")}
            </h3>
            <p className="approve-detail-p">
              {moment(userDetails?.user_profile?.created_date).format(
                DISPLAY_DATE_FORMAT,
              )}
            </p>
          </Col>
        </Row>
        {isPermissionsMatch(
          permissionsList,
          t("PERMISSIONS.ASSIGN_PERMISSION"),
        ) || superAdmin() ? (
          <hr />
        ) : (
          ""
        )}
        {isPermissionsMatch(
          permissionsList,
          t("PERMISSIONS.ASSIGN_PERMISSION"),
        ) || superAdmin() ? (
          <div className="manage-user-permission-section p-2">
            <Row className="mt-3 mb-4">
              <Col sm={6}>
                <p className="view-row-heading">
                  {" "}
                  {t("MANAGE_USERS.PERMISSIONS")}
                </p>
              </Col>
              <Col sm={6} className="manage-user-checkbox-alignmant text-end">
                <input
                  type="checkbox"
                  checked={selectedAll}
                  onClick={(e) => handleSelectAllPermissions(e)}
                />
                <Label className="mb-0 ml-2 view-select-option">
                  {" "}
                  {t("MANAGE_USERS.SELECT_ALL")}
                </Label>
              </Col>
            </Row>
            <Row>
              {newList.length &&
                newList?.map((i) => {
                  return (
                    <Col lg="4">
                      <div className="manage-user-checkbox-alignmant mb-3">
                        <input
                          type="checkbox"
                          className="input-margin"
                          checked={i?.checked}
                          onClick={(e) => handleParentOnchangePermissions(e, i)}
                        />
                        <Label className="md-filter-option dropdown-category-name m-0 mr-1">
                          {i?.category_name}
                        </Label>
                        {i.dropdownClicked ? (
                          <AiFillCaretDown
                            onClick={() => handleDropdownClick(i)}
                          />
                        ) : (
                          <AiFillCaretUp
                            onClick={() => handleDropdownClick(i)}
                          />
                        )}
                      </div>
                      <Collapse isOpen={i.dropdownClicked} className="ml-3">
                        {i.list.map((item) => {
                          return (
                            <div className="">
                              <Label
                                check
                                className="md-filter-option-collapse"
                              >
                                <input
                                  type="checkbox"
                                  className="input-margin"
                                  checked={selectedPermissions.includes(
                                    item.permission_id,
                                  )}
                                  onClick={(e) =>
                                    handleChildOnchangePermissions(
                                      e,
                                      item,
                                      i.list,
                                    )
                                  }
                                />
                                {item.permission_name}
                              </Label>
                            </div>
                          );
                        })}
                      </Collapse>
                    </Col>
                  );
                })}
            </Row>
          </div>
        ) : (
          ""
        )}
      </ModalBody>
      <ModalFooter className="text-end">
        <Button
          type="button"
          onClick={toggle}
          className="btn clear-filter-btn-manageuser st-btn-custom-manageuser"
        >
          {t("APPROVE_DATA.CANCEL")}
        </Button>

        <Button
          type="button"
          onClick={onUpdateUserpermissions}
          className="btn manage-user-btn-custom manage-user-submit-btn"
        >
          {t("MANAGE_USERS.UPDATE")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ViewDetailsModal;
