import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export default function CommonThresholdIndicators({ data }) {
  const { t } = useTranslation();

  return data?.length ? (
    <div className="moderate-div">
      <ul>
        {data?.map((item) => {
          const name = _.upperCase(item?.threshold_name);
          return (
            <li key={item?.threshold_name}>
              <span
                className="moderate-clr"
                style={{ background: item?.threshold_color }}
              />
              <p className="moderate-text">
                {t(`HEALTH.${name.split(" ").join("_")}`)}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  ) : (
    <div className="moderate-div">
      <ul>
        <li>
          <span className="moderate-clr" />
          <p className="moderate-text">{t("HEALTH.NORMAL")}</p>
        </li>
        <li>
          <span className="moderate-clr severe-clr" />
          <p className="moderate-text">{t("HEALTH.ALERT")}</p>
        </li>
        <li>
          <span className="moderate-clr extreme-clr" />
          <p className="moderate-text">{t("HEALTH.ALARM")}</p>
        </li>
      </ul>
    </div>
  );
}
