import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import EARLY_WARNING_MARKET_CONST from "./actionTypes";
import {
  getMarketTimeSeriesSuccess,
  getMarketTimeSeriesError,
  getMarketMapDataSuccess,
  getMarketMapDataFailed,
  getMarketCommodityDataSuccess,
  getMarkeCommoditypDataFailed,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import {
  EARLY_WARNING_API,
  AXIOS_INSTANCE,
  MARKET_URL,
  AXIOS_INSTANCE_LOADER,
} from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* getMarketTimeSeries(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(
        `${EARLY_WARNING_API}/early_warnings/market/timeseries`,
        action.payload.data,
      ),
      "",
      "",
      true,
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getMarketTimeSeriesSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.message || response.data.error,
      };
      yield put(getMarketTimeSeriesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getMarketTimeSeriesError(errorData));
  }
}

function* getMarketMapData(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.post(
        `${MARKET_URL}/map`,
        action?.payload?.requestObj,
      ),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getMarketMapDataSuccess(responseData));
    } else {
      const responseData = {
        data: response.data,
      };
      yield put(getMarketMapDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getMarketMapDataFailed(errorData));
  }
}

function* getMarketCommodities(action) {
  const { countryId, stateId, districtId } = action?.payload?.data;
  let queryParam = "";
  if (countryId && stateId && !districtId) {
    queryParam = `?country_id=${countryId}&state_id=${stateId}`;
  } else if (countryId && stateId && districtId) {
    queryParam = `?country_id=${countryId}&state_id=${stateId}&district_id=${districtId}`;
  } else {
    queryParam = `?country_id=${countryId}`;
  }
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${EARLY_WARNING_API}/early_warnings/market/commodity${queryParam}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getMarketCommodityDataSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.message || response.data.error,
      };
      yield put(getMarkeCommoditypDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getMarkeCommoditypDataFailed(errorData));
  }
}

export function* watchMarket() {
  yield takeEvery(
    EARLY_WARNING_MARKET_CONST.GET_MARKET_TIMESERIES_REQUEST,
    getMarketTimeSeries,
  );
  yield takeEvery(
    EARLY_WARNING_MARKET_CONST.GET_MARKET_MAP_DATA_REQUEST,
    getMarketMapData,
  );
  yield takeEvery(
    EARLY_WARNING_MARKET_CONST.GET_MARKET_COMMODITY_REQUEST,
    getMarketCommodities,
  );
}

function* MarketSaga() {
  yield all([fork(watchMarket)]);
}

export default MarketSaga;
