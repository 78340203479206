import React, { useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  useMap,
  ZoomControl,
  useMapEvents,
  LayersControl,
  LayerGroup,
  GeoJSON,
} from "react-leaflet";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  myConst,
  DATA_SOURCES,
  MAP_PREFERENCES_LABELS,
  GEOJSON_COUNTRY_DATA,
} from "../../../constants";
import CustomLegend from "../../../components/Common/CustomLegend";
import DiscreteLegend from "../../../components/Common/DiscreteLegend";
import DrawShape from "./drawShapes";

function AlterMap({
  wrap,
  setMapes,
  position,
  zooms,
  indicators,
  selectedParameter,
  removeMapLayers,
  removeLayers,
  layerRef,
  layerData,
  maps,
  setLegend,
  legend,
  onHandleZoom,
  setMapLayers,
  mapLayers,
  setInitialData,
  initialData,
  layersArray,
  selectedMapLayer,
  mapPreferences,
}) {
  const { Overlay } = LayersControl;
  const { t } = useTranslation();
  const setMapReference = (mapInstance) => {
    wrap.current = mapInstance;
  };
  useEffect(() => {
    setMapes(wrap.current);
  }, [wrap.current]);

  function ChangeView({ center, zoom }) {
    const mapEvents = useMapEvents({
      zoomend: () => {
        onHandleZoom(mapEvents.getCenter(), mapEvents.getZoom());
      },
      dragend: () => {
        onHandleZoom(mapEvents.getCenter(), mapEvents.getZoom());
      },
    });
    const map = useMap();
    setInterval(() => {
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) map.invalidateSize();
    }, 500);
    if (zoom && center) map.setView(center, zoom);
    return null;
  }

  const addLayers = (selectedParamRef) => {
    if (wrap.current && selectedParamRef.current) {
      const leafletMapRef = wrap.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer) => {
        leafletMapRef.addLayer(layer);
      });
    }
    return false;
  };

  // for applying layers when we click on apply layer button
  useEffect(() => {
    const currentParamRef = layerRef.current[selectedParameter?.paramIndex];

    if (maps && wrap !== null && selectedParameter?.slug === selectedMapLayer) {
      if (currentParamRef && currentParamRef.current !== null) {
        currentParamRef.current?.eachLayer((layer) => {
          if (maps?.map_data?.map_url) {
            layer.setUrl(maps?.map_data?.map_url);
          }
        });

        removeMapLayers(legend);

        const updateLegend = [...legend];
        let legendObj = {};
        legendObj = { ...maps?.map_data };
        legendObj.name = selectedParameter?.name;
        legendObj.parameter = selectedParameter?.name;
        legendObj.measure = "";
        legendObj.isCompare = false;
        legendObj.id = selectedParameter?.name;
        legendObj.dataSource_id = selectedParameter?.category_name;
        legendObj.parameter_slug = selectedParameter?.slug;
        legendObj.fromMapPreferences = false;
        legendObj.descret_legend = maps?.map_data?.descret_legend || false;
        legendObj.startDate = layerData.start_date || null;
        legendObj.endDate = layerData.end_date || null;
        if (selectedParameter?.slug === "iri") {
          legendObj.dataSource = selectedParameter?.name;
          legendObj.parameter =
            DATA_SOURCES?.IRI.id === selectedParameter?.indices_id
              ? DATA_SOURCES?.IRI.parameterName
              : selectedParameter?.category_name;
        } else {
          legendObj.dataSource = selectedParameter?.category_name;
        }
        legendObj.add = true;
        updateLegend.push(legendObj);

        setLegend(updateLegend);

        if (layerRef?.current?.length) {
          layerRef.current.map((i, index) => {
            removeLayers(layerRef.current[index]);
            return null;
          });
        }

        // adding the layer when we click on applied layers
        if (!_.isEmpty(currentParamRef) && currentParamRef?.current !== null) {
          addLayers(currentParamRef);
        }
      }
    }
  }, [layerData]);

  const renderParamLayer = (paramList) => {
    if (paramList && paramList?.length) {
      return paramList?.map((item) => {
        return (
          <>
            {/* TODO: need to create separate component */}
            <Overlay name={item.name}>
              <LayerGroup ref={layerRef.current[item.paramIndex]}>
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url=""
                  id={item.name}
                />
              </LayerGroup>
            </Overlay>
          </>
        );
      });
    }
  };

  //   function to set region
  const handleSelectedRegion = (region) => {
    const mapLayerData = [];
    let polyCount = 1;
    const mapData = JSON.parse(JSON.stringify(region));
    if (mapData?.length) {
      mapData.map((item) => {
        const x = Object.assign(item, {});
        if (item.layerType === "polygon") {
          x.latlngs = item.latlngs.map((v) => [v[1], v[0]]);
          x?.latlngs?.push(item.latlngs[0]);
          if (!x.hasOwnProperty("polygonCount")) {
            x.polygonCount = polyCount;
            polyCount += 1;
          }
          mapLayerData.push(x);
        } else {
          x.latlngs = [item.latlngs[1], item.latlngs[0]];
          mapLayerData.push(x);
        }
        return null;
      });
    }

    setInitialData((prev) => {
      return {
        ...prev,
        pointer: mapLayerData,
      };
    });
  };

  const handleEachFeatureChange = (countryData, locationType, feat, layer) => {
    const selectedCountryData = GEOJSON_COUNTRY_DATA?.find(
      (item) => item.selectedCountry === countryData?.label,
    );
    if (["major_rivers", "minor_rivers"].includes(locationType)) {
      const labelprop = feat?.properties?.Name;

      if (labelprop)
        layer.bindTooltip(labelprop, {
          sticky: true,
          direction: "auto",
        });
    } else {
      const labelprop = selectedCountryData
        ? selectedCountryData[locationType]
        : "";

      if (labelprop && feat.properties[labelprop])
        layer.bindTooltip(feat.properties[labelprop], {
          sticky: true,
          direction: "auto",
        });
    }
  };

  return (
    <MapContainer
      center={position}
      zoom={zooms}
      scrollWheelZoom
      zoomControl={false}
      className="health-map-container-dashboard"
      ref={setMapReference}
      id="leaflet-map"
    >
      <ChangeView center={position} zoom={zooms} />
      <ZoomControl position="topright" className="zoommap" />

      {layersArray.length && (
        <LayersControl position="topright">
          <>
            <TileLayer
              options={{ tileSize: 256 }}
              attribution={myConst.TITLE_LAYER_ATTRIBUTE}
              url={myConst.TITLE_LAYER_URL}
            />
            {layersArray.map((refs) => {
              return (
                <LayersControl.Overlay
                  checked={refs?.name === t("GEOGLOWS.DRINAGE")}
                  name={refs.name}
                >
                  <LayerGroup>
                    <TileLayer url={refs.layer} id={refs.name} />
                  </LayerGroup>
                </LayersControl.Overlay>
              );
            })}
          </>
        </LayersControl>
      )}

      <TileLayer
        options={{ tileSize: 256 }}
        attribution={myConst.TITLE_LAYER_ATTRIBUTE}
        url={myConst.TITLE_LAYER_URL}
      />

      <LayersControl position="topleft">
        {renderParamLayer(indicators)}
      </LayersControl>

      <GeoJSON
        key={MAP_PREFERENCES_LABELS.DISTRICTS}
        ref={mapPreferences.districts_or_cities.layer}
        style={{
          color: mapPreferences.districts_or_cities.color,
          fillColor: "#0000",
          weight: 1,
        }}
        onEachFeature={(feat, layer) =>
          handleEachFeatureChange(
            mapPreferences.districts_or_cities.country,
            "talukas",
            feat,
            layer,
          )
        }
      />

      {legend.length
        ? legend.map((item) => {
            return (
              <LayersControl key="layer3" position="topleft">
                {item?.descret_legend ? (
                  <DiscreteLegend
                    map={wrap.current}
                    add={item.add}
                    layer={item}
                    position="bottomleft"
                  />
                ) : (
                  <CustomLegend
                    map={wrap.current}
                    add={item.add}
                    layer={item}
                    position="bottomleft"
                  />
                )}
              </LayersControl>
            );
          })
        : ""}
      <DrawShape
        handleSelectedRegion={handleSelectedRegion}
        country={initialData?.country}
        state={initialData?.state}
        district={initialData?.District}
        setMapLayers={setMapLayers}
        mapLayers={mapLayers}
      />
    </MapContainer>
  );
}

export default AlterMap;
