import React, { useState, useEffect, memo } from "react";
import { Row, Col } from "reactstrap";
import {
  MapContainer,
  TileLayer,
  // useMap,
  useMapEvents,
  LayerGroup,
  LayersControl,
  ScaleControl,
  ZoomControl,
} from "react-leaflet";
// import _ from "lodash";
import "leaflet-side-by-side";
import "leaflet.control.opacity/dist/L.Control.Opacity.css";
import "leaflet.control.opacity";
import { myConst } from "../../../../constants";
import {
  // getBulletinDateFormat,
  getBulletinDateFormatwithOutSubScript,
} from "../../../../helpers/index";
import CustomLegend from "../../../../components/Common/CustomLegend";
import DiscreteLegend from "../../../../components/Common/DiscreteLegend";
// import ResetIcon from "../../../../assest/img/reset.png";
import Summary from "./Summary";
import IndicatorInfo from "./IndicatorInfo";

// eslint-disable-next-line no-unused-vars
const countryLoc = {
  cordinates: [-14.207133, 27.385223],
  zoom: 6,
};

function BulletinPreview(props) {
  const {
    queryPrams,
    mapURLResponse,
    indicatorRows,
    initialIndicesList,
    mapInstances,
    setMapReference,
    layerRef,
    cordinateAndZoom1,
    updateMapSettings,
    isLoadingPdf,
    // setCordinateAndZoom1,
  } = props;
  const [selectedIndicator, setSelectedIndicator] = useState();
  // eslint-disable-next-line no-unused-vars
  const [cordinateAndZoom, setCordinateAndZoom] = useState([]);

  const defaultCordinatesAndZoom = {
    cordinates: [
      parseFloat(queryPrams?.latitude),
      parseFloat(queryPrams.longitude),
    ],
    zoom: parseInt(queryPrams?.zoomLevel, 10),
  };

  const { Overlay } = LayersControl;
  let layerIndex = -1;

  const defaultCordinates = [];
  useEffect(() => {
    for (let i = 0; i < Object.keys(indicatorRows)?.length * 3; i += 1) {
      defaultCordinates.push(defaultCordinatesAndZoom);
      if (i + 1 === Object.keys(indicatorRows)?.length * 3) {
        setCordinateAndZoom(defaultCordinates);
      }
    }
  }, [indicatorRows]);

  // const Reset = (index) => {
  //   const currentLoc = [];
  //   for (let i = 0; i < Object.keys(indicatorRows)?.length * 3; i += 1) {
  //     currentLoc.push(defaultCordinatesAndZoom);
  //   }
  //   const currentCoordinateAndZoom = [...currentLoc];
  //   if (currentCoordinateAndZoom[index]) {
  //     currentCoordinateAndZoom[index].zoom = currentLoc?.zoom;
  //     currentCoordinateAndZoom[index].cordinates = currentLoc?.cordinates;
  //   }
  //   setCordinateAndZoom(currentCoordinateAndZoom);
  // };

  // const Reset = () => {
  //   setCordinateAndZoom1({
  //     center: [queryPrams?.latitude, queryPrams?.longitude],
  //     zoom: 7,
  //   });
  // };

  // const handleZoomLevel = (e) => {
  //   e?.stopPropagation();
  //   Reset();
  // };

  // eslint-disable-next-line no-shadow
  function ChangeView({ center, zoom, updateMapSettings }) {
    const map = useMapEvents({
      zoomend: () => {
        updateMapSettings(map.getCenter(), map.getZoom());
      },
      dragend: () => {
        updateMapSettings(map.getCenter(), map.getZoom());
      },
    });

    useEffect(() => {
      map.setView(center, zoom);
    }, [center, zoom, map]);

    return null;
  }

  const renderMapContainer = (keyName, index) => {
    return (
      <div className="mapcontainerlayout">
        <MapContainer
          center={cordinateAndZoom1.center}
          zoom={cordinateAndZoom1.zoom}
          id={`leaflet-map${keyName}`}
          minZoom={6}
          maxZoom={10}
          className="map-container b-radius"
          zoomControl={false}
          scrollWheelZoom
          whenReady={(mapInstance) =>
            setMapReference(mapInstance.target, keyName)
          }
        >
          {/* <ChangeView
            center={cordinateAndZoom[index]?.cordinates}
            index={index}
            zoom={cordinateAndZoom[index]?.zoom}
          /> */}

          <ChangeView
            center={cordinateAndZoom1.center}
            zoom={cordinateAndZoom1.zoom}
            updateMapSettings={updateMapSettings}
          />

          <ScaleControl position="bottomright" />
          {!isLoadingPdf ? (
            <ZoomControl position="topright" className="map-zoom-control" />
          ) : (
            ""
          )}

          {/* <LayersControl position="topleft">
            <Button
              className="reset-button"
              title="Click here to reset zoom and centre"
              onClick={(e) => {
                handleZoomLevel(e, index);
              }}
            >
              <img src={ResetIcon} alt="Reset" width={15} />
            </Button>
          </LayersControl> */}

          <LayersControl>
            <LayersControl.BaseLayer checked name="Street">
              <TileLayer
                options={{ tileSize: 256 }}
                attribution={myConst.TITLE_LAYER_ATTRIBUTE}
                url={myConst.TITLE_LAYER_URL}
              />
            </LayersControl.BaseLayer>
          </LayersControl>

          <LayersControl name="indicator" position="topright">
            <Overlay checked name="indicator-risk-force">
              <LayerGroup
                ref={layerRef.current[index]}
                style={{ zIndex: 10001 }}
              >
                <TileLayer
                  attribution='Weather from <a href="https://openweathermap.org/" alt="World Map and worldwide Weather Forecast online">OpenWeatherMap</a>'
                  url=""
                />
              </LayerGroup>
            </Overlay>
          </LayersControl>

          <LayersControl key={`legend-${keyName}`} position="topleft">
            {mapInstances[`legendObj${keyName}`]?.descret_legend ? (
              <DiscreteLegend
                map={mapInstances[`mapInstance${keyName}`]}
                add={mapInstances[`legendObj${keyName}`]?.add}
                layer={mapInstances[`legendObj${keyName}`]}
                position="bottomleft"
              />
            ) : (
              <CustomLegend
                map={mapInstances[`mapInstance${keyName}`]}
                add={mapInstances[`legendObj${keyName}`]?.add}
                layer={mapInstances[`legendObj${keyName}`]}
                position="bottomleft"
              />
            )}
          </LayersControl>
        </MapContainer>
      </div>
    );
  };
  return (
    <div>
      {Object.keys(indicatorRows)?.length
        ? Object.keys(indicatorRows).map((eachRow, key) => {
            const indicesSlugs = indicatorRows[`${eachRow}`]?.indicatorsList
              .map((item) => item.indices_name)
              .join(", ");

            return (
              <div id={`${eachRow?.indicatorRows?.[key]?.indices_slug}${key}`}>
                <div className="bullitenbanner d-flex align-items-center">
                  <h5 className="text-indi">
                    {" "}
                    Indicator summary of {"  "}
                    {indicesSlugs || ""} {"  "}- {key + 1}/
                    {Object.keys(indicatorRows)?.length}
                  </h5>
                </div>
                <Row key={eachRow} className="indicator-row">
                  {indicatorRows[eachRow]?.indicatorsList.map(
                    (eachIndicator) => {
                      layerIndex += 1;
                      return (
                        <Col
                          md={4}
                          className={`map-div ${key > 0 ? "mt-3" : ""}`}
                          key={eachIndicator.indices_name}
                        >
                          {" "}
                          {renderMapContainer(
                            `${eachRow}${eachIndicator.indices_name}`,
                            layerIndex,
                          )}
                          <ul className="sub-header map-text bulliten-list-stle-none pl-3 pt-2">
                            <li>Indicator : {eachIndicator.indices_name}</li>
                            <li>
                              Date :{" "}
                              {getBulletinDateFormatwithOutSubScript(
                                indicatorRows[eachRow].startDate,
                              )}{" "}
                              -{"  "}
                              {getBulletinDateFormatwithOutSubScript(
                                indicatorRows[eachRow].endDate,
                              )}
                            </li>
                          </ul>
                        </Col>
                      );
                    },
                  )}
                  <Col md={12}>
                    <div className="mt-2" />
                    <IndicatorInfo
                      mapURLResponse={mapURLResponse}
                      indicatorRows={indicatorRows}
                      rowKey={eachRow}
                      selectedIndicator={selectedIndicator}
                      setSelectedIndicator={setSelectedIndicator}
                    />
                  </Col>
                  <Col md={12}>
                    <div className="mt-2">
                      <Summary
                        name={`summary${key}`}
                        condition="Current"
                        indicatorsList={initialIndicesList}
                      />
                    </div>
                    <hr className="section-break" />
                  </Col>
                </Row>
              </div>
            );
          })
        : null}
    </div>
  );
}
export default memo(BulletinPreview);
