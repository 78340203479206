import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDispatch } from "react-redux";
import errorImage from "../../assest/img/errorImage.svg";
import {
  EMPTY_DATA,
  DATA_OVERRIDE,
  DATE_RANGE_ERROR,
  UN_MATCHED_FIELD,
  WRONG_STRUCTURE,
} from "../../constants";
import { getTemplateDataRequest } from "../../store/actions";

function ErrorModal({
  toggle,
  onClick,
  modal,
  message,
  errorType,
  selectedModule,
  selectedSubModule,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleErrorTypeMessage = () => {
    if (errorType === EMPTY_DATA) {
      return t("UPLOAD_PAST_DATA.EMPTY_FILE");
    } else if (errorType === DATA_OVERRIDE) {
      return t("UPLOAD_PAST_DATA.OVER_RIDE_DATA");
    } else if (errorType === DATE_RANGE_ERROR) {
      return t("UPLOAD_PAST_DATA.DATA_NOT_MATCHED");
    } else if (errorType === UN_MATCHED_FIELD) {
      return t("UPLOAD_PAST_DATA.UN_MATCHED_FIELD");
    } else if (errorType === WRONG_STRUCTURE) {
      return t("UPLOAD_PAST_DATA.WRONG_STRUCTURE");
    }
  };

  const handleChanges = () => {
    if (errorType === DATA_OVERRIDE) {
      onClick(true);
    } else {
      onClick(false);
    }
  };

  return (
    <Modal isOpen={modal} size="md">
      <div className="error-modal">
        <ModalHeader className="p-0 delete-header user-flex-end mr-2 mt-5" />
        <ModalBody className="delete-modal-padding">
          <div className="reg-success">
            <img
              src={errorImage}
              alt="not-available"
              className="reg-check-image"
            />
            <h2 className="back-office-error-header-text">
              {" "}
              {handleErrorTypeMessage()}{" "}
            </h2>
            <p className="back-office-error-desc px-5 py-3">{message}</p>
            <div
              className={
                errorType === WRONG_STRUCTURE
                  ? "button-container-wrng-structure"
                  : "w-50 button-container"
              }
            >
              {errorType === WRONG_STRUCTURE ? (
                <Button
                  type="button"
                  className="back-office-ok-btn w-auto mb-1 px-5"
                  onClick={() => {
                    toggle();
                    dispatch(
                      getTemplateDataRequest({
                        menu_slug: selectedModule ? selectedModule?.slug : "",
                        sub_menu_slug: selectedSubModule
                          ? selectedSubModule?.slug
                          : "",
                      }),
                    );
                  }}
                >
                  {t("UPLOAD_PAST_DATA.DOWNLOAD_TEMPLATE")}
                </Button>
              ) : (
                ""
              )}
              {errorType === DATA_OVERRIDE || errorType === WRONG_STRUCTURE ? (
                <Button
                  type="button"
                  className={
                    errorType === WRONG_STRUCTURE
                      ? "back-office-cancel-btn"
                      : "back-office-ok-btn"
                  }
                  onClick={() => {
                    toggle();
                  }}
                >
                  {t("UPLOAD_PAST_DATA.CANCEL")}
                </Button>
              ) : (
                ""
              )}

              {errorType === WRONG_STRUCTURE ? (
                ""
              ) : (
                <Button
                  type="button"
                  className={
                    errorType === DATA_OVERRIDE
                      ? "back-office-cancel-btn"
                      : "back-office-ok-btn"
                  }
                  onClick={() => {
                    handleChanges();
                  }}
                >
                  {t("UPLOAD_PAST_DATA.OKAY")}
                </Button>
              )}
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default ErrorModal;
