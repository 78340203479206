import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n";
import "./assest/css/basin.css";
import "./assest/css/cropYield.css";
import "./assest/css/bulletin.css";
import "./assest/css/Climber.css";
import "./assest/css/Common.css";
import "./assest/css/DashBoard.css";
import "./assest/css/Login.css";
import "./assest/css/health.css";
import "./assest/css/Register.css";
import "./assest/css/Custom.css";
import "./assest/css/LeafletWrapper.css";
import "./assest/css/WeatehrForecast.css";
import "./assest/css/UserGuide.css";
import "./assest/css/Settings.css";
import "./assest/css/ManageUserGuide.css";
import "./assest/css/ManageUsersData.css";
import "./assest/css/ManageUser.css";
import "./assest/css/earlyActions.css";
import "./assest/css/DroughtGraph.css";
import "./assest/css/EarlyFinanceReport.css";
import "./assest/css/earlyFinance.css";
import "./assest/css/uploadDocument.css";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import store from "./store";
import GlobalLoader from "./components/Common/Loader";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./app.global.scss";
import "./assest/css/Legend.css";
import "./assest/css/backOffice.css";
import "./assest/css/AlertDashboard.css";
import "./assest/css/RapidResponse.css";
import "./assest/css/ModalPerformanceFeedback.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        limit={1}
        // pauseOnFocusLoss
        // draggable
        // pauseOnHover
      />
      <GlobalLoader />
      <App />
    </BrowserRouter>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
