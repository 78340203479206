import { all } from "redux-saga/effects";
import AuthSaga from "./auth/saga";
import ClimberSaga from "./climber/saga";
import CoreSaga from "./core/saga";
import AwareSaga from "./aware/saga";
import DashBoardSaga from "./dashBoard/saga";
import OtherToolsSaga from "./otherTools/saga";
import RegisterSaga from "./register/saga";
import UserSaga from "./user/saga";
import DroughtSaga from "./drought/saga";
import watchMapPreferenceSaga from "./mapPreferences/saga";
import WeatherSaga from "./weather/saga";
import GeoglowsSaga from "./geoglows/saga";
import HealthSega from "./health/saga";
import UserGuideSaga from "./userGuide/userGuideSaga";
import ManageUserGuidesagaSaga from "./manageUserGuide/saga";
import NutritionSaga from "./nutrition/saga";
import MarketSaga from "./market/saga";
import bulletinSaga from "./bulletin/bulletinSaga";
import populationDisplacementSaga from "./populationDisplacement/saga";
import BoundarySaga from "./boundary/saga";
import uploadPastDataSaga from "./uploadPastData/saga";
import staticResourceSaga from "./staticResource/staticResourceSaga";
import odkSaga from "./odk/odkSaga";
import ManageUserSaga from "./manageUser/saga";
import PermissionsSaga from "./permissions/saga";
import CropYieldSaga from "./cropYield/saga";
import EaProjectSaga from "./earlyAction/saga";
import EarlyActionGuideLinesSaga from "./guideLines/saga";
import EarlyActionPreparednessPhaseSaga from "./preparednessPhase/saga";
import EarlyActionReaynessPhaseSaga from "./readynessPhase/saga";
import EarlyFinanceReport from "./earlyFinanceReport/saga";
import AlertDashboard from "./alertDashboard/saga";
import RapidResponseSaga from "./rapidResponse/saga";

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    ClimberSaga(),
    CoreSaga(),
    AwareSaga(),
    OtherToolsSaga(),
    OtherToolsSaga(),
    DashBoardSaga(),
    RegisterSaga(),
    UserSaga(),
    DroughtSaga(),
    watchMapPreferenceSaga(),
    WeatherSaga(),
    GeoglowsSaga(),
    HealthSega(),
    UserGuideSaga(),
    ManageUserGuidesagaSaga(),
    NutritionSaga(),
    MarketSaga(),
    bulletinSaga(),
    populationDisplacementSaga(),
    BoundarySaga(),
    uploadPastDataSaga(),
    staticResourceSaga(),
    odkSaga(),
    ManageUserSaga(),
    PermissionsSaga(),
    CropYieldSaga(),
    EaProjectSaga(),
    EarlyActionGuideLinesSaga(),
    EarlyActionPreparednessPhaseSaga(),
    EarlyActionReaynessPhaseSaga(),
    EarlyFinanceReport(),
    AlertDashboard(),
    RapidResponseSaga(),
  ]);
}
