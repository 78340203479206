import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import Textarea from "rc-textarea";

function Summary({ condition, name }) {
  const { t } = useTranslation();
  return (
    <div className="bulletin-summary">
      <h5 className="mt-3 text-div">Summary of {condition} Condition :</h5>
      <div className="hr-lines"> </div>
      <Textarea
        autoSize={{ minRows: 4, maxRows: 8 }}
        name={name}
        maxLength={500}
        className="mb-3 mt-2"
        placeholder={t(`BULLET_IN.RANDOM_TEXT`)}
      />
    </div>
  );
}
export default memo(Summary);
