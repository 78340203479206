const MAP_PREFERENCE_CONST = {
  MAP_PREFERENCE_REQUEST: "MAP_PREFERENCE_REQUEST",
  MAP_PREFERENCE_SUCCESS: "MAP_PREFERENCE_SUCCESS",
  MAP_PREFERENCE_ERROR: "MAP_PREFERENCE_ERROR",

  MAP_PREFERENCE_DATA_REQUEST: "MAP_PREFERENCE_DATA_REQUEST",
  MAP_PREFERENCE_DATA_SUCCESS: "MAP_PREFERENCE_DATA_SUCCESS",
  MAP_PREFERENCE_DATA_ERROR: "MAP_PREFERENCE_DATA_ERROR",
};

export default MAP_PREFERENCE_CONST;
