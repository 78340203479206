import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse,
  NavItem,
  NavLink,
  Input,
  Label,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import { BsInfo, BsFillCaretUpFill, BsCaretDownFill } from "react-icons/bs";
import { IoMdCloseCircleOutline } from "react-icons/io";
import ReactTooltip from "react-tooltip";
import {
  mapPreferenceDataRequest,
  mapPreferenceDataError,
  stateBoundarySuccess,
  countryBoundarySuccess,
} from "../../store/actions";
import CommonToolTip from "./CommonToolTip";
import {
  mapPrefreenceLabel,
  otherSection,
  mapPreferenceConstants,
  adminEng,
  adminFrench,
  Glofas,
  GEOJSON_COUNTRY_DATA,
} from "../../constants";
import { showError } from "./Notification";

function MapPreferenceModal({
  selectedParamLegendData,
  isToggle,
  handleToggle,
  preferenceList,
  categories,
  fromDroughtMonitor,
  geoJsonData,
  getMapPreferencesGeojsonData,
  getMapPreferencesData,
  mapPreferencesAllData,
  isFlood,
  selectedLocation,
  isMapPerferGeoJsonLoading,
  glofasState,
  setOpen,
  adminRadio,
  fromCropPredection,
  onHandleCropPredectionDefaultBoundry,
  isCallBoundary,
  isRapidResponse,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [collaspedMenu, setCollapsedMenu] = useState([]);
  const [geoJsonLayerData, setGeoJsonLayerData] = useState([]);

  const [selectedMapPreferences, setSelectedMapPreferences] = useState([]);
  const [mapPreferencesData, setMapPreferencesData] = useState([]);

  const [getBoundariesData, setGetBoundariesData] = useState([]);
  const [handleCateogry, setHandleCategory] = useState([]);

  const [otherSelection, setOtherSelection] = useState({
    soil: false,
    gdp: false,
  });
  const [startDate, setStartDate] = useState("");
  const [taluksDisabled, setTaluksDisabled] = useState(true);
  const mapRemove = [];

  const { boundries } = useSelector((state) => ({
    boundries: state.MapPreference,
  }));

  const {
    isMapPreferenceBoundariesRequesting,
    mapPreferenceBoundariesData,
    mapPreferenceBoundariesDataError,
  } = boundries;

  useEffect(() => {
    setModal(isToggle);
  }, [isToggle]);

  const onCollaspedToggle = (eachLayer) => {
    if (collaspedMenu?.includes(eachLayer.category)) {
      setCollapsedMenu(() =>
        collaspedMenu.filter((x) => x !== eachLayer.category),
      );
    } else {
      setCollapsedMenu([...collaspedMenu, eachLayer.category]);
    }
  };

  useEffect(() => {
    if (categories.length) {
      setCollapsedMenu(categories);
    }
  }, [categories]);

  useEffect(() => {
    if (fromDroughtMonitor || isFlood || fromCropPredection) {
      setMapPreferencesData([]);
      getMapPreferencesData([]);
    }
    return () => {
      dispatch(mapPreferenceDataError(""));
    };
  }, []);

  useEffect(() => {
    if (mapPreferencesAllData) {
      setMapPreferencesData(mapPreferencesAllData);
    }
  }, [mapPreferencesAllData]);

  let geoJsonLayers = geoJsonLayerData;

  // function for adding geojson boundary layer
  const addGeoJsonLayer = (boundry) => {
    if (geoJsonLayers) {
      geoJsonLayers.push({
        name: boundry?.slug,
        url: boundry?.json_url,
        color: boundry?.color_code,
        id: boundry?.id,
        category: boundry?.map_preference_category_name,
        country_id: selectedLocation?.country?.value,
      });

      getMapPreferencesGeojsonData(geoJsonLayers);
      setGeoJsonLayerData(geoJsonLayers);
    }
  };
  const mapPreferences = mapPreferencesData;

  const removeMapPreferencesLayer = (boundry) => {
    const mapPreferencesFiltered = mapPreferences.filter(
      (layer) => layer.id !== boundry?.id,
    );

    getMapPreferencesData(mapPreferencesFiltered);
    setMapPreferencesData(mapPreferencesFiltered);
    mapRemove.push(boundry?.boundarytype_id);
  };

  useEffect(() => {
    if (boundries.mapPreferenceBoundariesData) {
      const { data, soil, gdpYear, payload } =
        boundries.mapPreferenceBoundariesData;

      const { category, name, slug, legendName } =
        boundries.mapPreferenceBoundariesData.payload;
      if (data.code === 200) {
        let datas = {};
        datas = mapPreferencesData?.find(
          (x) => x.id === boundries.mapPreferenceBoundariesData.payload.id,
        );
        if (_.isEmpty(datas)) {
          const obj1 = {
            data,
            id: boundries.mapPreferenceBoundariesData.payload?.id,
            category,
            dataSource: name,
            slug,
            parameter: category,
            legendName,
          };

          if (soil) {
            obj1.soil = soil;
          }
          if (gdpYear) {
            obj1.gdpYear = gdpYear;
          }

          mapPreferences.push(obj1);

          setMapPreferencesData(mapPreferences);
          getMapPreferencesData(mapPreferences);
        }
      } else if (data.code === 400) {
        const preferenceLayer = selectedMapPreferences.filter(
          (layer) => layer.id !== payload?.id,
        );

        setSelectedMapPreferences(preferenceLayer);
        showError(data?.message);
      }
    }
  }, [
    isMapPreferenceBoundariesRequesting,
    mapPreferenceBoundariesData,
    mapPreferenceBoundariesDataError,
  ]);

  useEffect(() => {
    setGeoJsonLayerData(geoJsonData);
  }, [geoJsonData]);

  // function for removing the geojson layer
  const removeGeoJsonLayer = (boundry) => {
    const tempLayer = geoJsonLayers.filter((layer) => layer.id !== boundry?.id);
    geoJsonLayers = tempLayer;
    getMapPreferencesGeojsonData(geoJsonLayers);
    setGeoJsonLayerData(geoJsonLayers);
  };

  // function for adding the map-preference layer
  const addMapPreferencesLayer = (boundry) => {
    const data = {
      layer_id: boundry?.id,
      country_name: selectedLocation?.country?.label
        ? selectedLocation?.country?.label
        : "",
      state_name:
        selectedLocation?.state?.value !== ""
          ? selectedLocation?.state?.label
          : "",
      district_name:
        selectedLocation?.district?.value !== ""
          ? selectedLocation?.district?.label
          : "",
      basin_name:
        selectedLocation?.basin?.value !== ""
          ? selectedLocation?.basin?.label
          : "",
      sub_basin_name:
        selectedLocation?.subBasin?.value !== ""
          ? selectedLocation?.subBasin?.label
          : "",
    };

    if (boundry?.map_preference_category_name === t("MAP_PREFERENCES.OTHERS")) {
      if (boundry?.slug === mapPreferenceConstants.soilSlug) {
        data.soil_texture_class = boundry.soilTexture;
      }
      if (boundry?.slug === mapPreferenceConstants.gdpSlug) {
        data.gdp_year = boundry.year + 1900;
      }
    }
    dispatch(mapPreferenceDataRequest({ data, boundry }));
    setOpen(false);
  };
  useEffect(() => {
    const categoryList = JSON.parse(JSON.stringify(getBoundariesData));
    setGetBoundariesData(categoryList);
  }, [isMapPerferGeoJsonLoading]);
  useEffect(() => {
    if (boundries?.mapPreferenceList) {
      const data = boundries?.mapPreferenceList;
      const categoryList = [];
      let updatedCategoryList = [];
      if (data?.length) {
        data?.map((item) => {
          categoryList.push({ name: item.category, checked: true });
          return null;
        });
      }
      setHandleCategory(categoryList);
      if (data) {
        updatedCategoryList = data?.map((item) => {
          if (item && item.hasOwnProperty("isShowCategory")) {
            return item;
          } else {
            item.isShowCategory = true;
            return item;
          }
        });
      }
      if (updatedCategoryList?.length) {
        setGetBoundariesData(updatedCategoryList);
      }
    }
  }, [boundries?.mapPreferenceList]);

  const handleCateogryToggle = (item, { fromCpAdmin }) => {
    const categoryList = [...handleCateogry];
    const index = _.findIndex(
      categoryList,
      (list) => list.name === item.category,
    );
    if (index >= 0) {
      categoryList[index] = {
        ...categoryList[index],
        checked: fromCpAdmin ? true : !categoryList[index].checked,
      };
    }
    setHandleCategory(categoryList);
  };

  useEffect(() => {
    if (mapPreferencesAllData?.length === 0 && fromCropPredection) {
      setSelectedMapPreferences([]);
    }
  }, [mapPreferencesAllData]);

  const addMapLayer = (boundry) => {
    if (selectedMapPreferences?.length) {
      const selectedData = selectedMapPreferences?.find(
        (x) => x.id === boundry?.id,
      );
      if (_.isEmpty(selectedData)) {
        setSelectedMapPreferences((prev) => [...prev, boundry]);
        addMapPreferencesLayer(boundry);
      } else {
        if (selectedData.slug === mapPreferenceConstants.soilSlug) {
          setSelectedMapPreferences((prev) => [...prev, boundry]);
          addMapPreferencesLayer(boundry);
        } else if (selectedData.slug === mapPreferenceConstants.gdpSlug) {
          if (selectedData) {
            removeMapPreferencesLayer(selectedData);
          }

          addMapPreferencesLayer(boundry);
        } else {
          addMapPreferencesLayer(boundry);
          setSelectedMapPreferences((prev) => [...prev, boundry]);
        }
      }
    } else {
      addMapPreferencesLayer(boundry);
      setSelectedMapPreferences((prev) => [...prev, boundry]);
    }
  };

  const selectedMapArr = selectedMapPreferences;

  const removeMapLayer = (boundry) => {
    const filteredMapArr = selectedMapArr?.filter(
      (item) => item.id !== boundry.id,
    );

    setSelectedMapPreferences(filteredMapArr);

    removeMapPreferencesLayer(boundry);
  };

  const handleCheckbox = (e, boundry, { fromMapPreferences }) => {
    if (fromDroughtMonitor || isFlood || fromCropPredection) {
      const data = JSON.parse(JSON.stringify(boundry));
      data.fromMapPreferences = fromMapPreferences;
      if (e.target.checked) {
        boundry?.json_url.length ? addGeoJsonLayer(data) : addMapLayer(data);
      } else {
        boundry?.json_url.length
          ? removeGeoJsonLayer(data)
          : removeMapLayer(data);
      }
    }
  };
  // for handling the soil texture in others
  const onHandleSoilTexture = (e, boundry, { fromMapPreferences }) => {
    if (fromDroughtMonitor || isFlood || fromCropPredection) {
      const data = JSON.parse(JSON.stringify(boundry));
      const removeData = JSON.parse(JSON.stringify(boundry));
      data.fromMapPreferences = fromMapPreferences;
      removeData.fromMapPreferences = fromMapPreferences;

      if (e.target.checked) {
        if (e.target.value === mapPreferenceConstants.soilTextureTwenty) {
          data.soilTexture = e.target.value;
          removeData.soilTexture === mapPreferenceConstants.soilTextureFifty;
          removeMapLayer(removeData);
          addMapLayer(data);
        } else {
          data.soilTexture = e.target.value;
          removeData.soilTexture === mapPreferenceConstants.soilTextureTwenty;

          removeMapLayer(removeData);
          addMapLayer(data);
        }
      }
    }
  };
  // for handling the  gdp in others
  const onHandleGdpYear = (date, boundry, { fromMapPreferences }) => {
    if (fromDroughtMonitor || isFlood || fromCropPredection) {
      const data = JSON.parse(JSON.stringify(boundry));
      data.fromMapPreferences = fromMapPreferences;
      data.year = date.getYear();

      if (date.getYear()) {
        setStartDate(date);
        addMapLayer(data);
      } else {
        setStartDate("");
        removeMapLayer(data);
      }
    }
  };

  useEffect(() => {
    if (selectedMapPreferences.length === 2) {
      const selectedSoliTexture = selectedMapPreferences?.find(
        (item) => item?.name === mapPreferenceConstants.soil,
      );
      const selectedGdp = selectedMapPreferences?.find(
        (item) => item?.name === mapPreferenceConstants.gdp,
      );
      if (selectedSoliTexture === undefined) {
        setOtherSelection((prev) => {
          return {
            ...prev,
            soil: false,
          };
        });
      }

      if (selectedGdp === undefined) {
        setOtherSelection((prev) => {
          return {
            ...prev,
            gdp: false,
          };
        });
      }
    }
  }, [selectedMapPreferences]);

  const handleOtherCheckbox = (e, boundry, { fromMapPreferences }) => {
    if (fromDroughtMonitor || isFlood || fromCropPredection) {
      if (e.target.checked) {
        setOtherSelection((prev) => {
          return {
            ...prev,
            [e.target.name]: true,
          };
        });
      } else {
        setOtherSelection((prev) => {
          return {
            ...prev,
            [e.target.name]: false,
          };
        });
        if (e.target.id === mapPreferenceConstants.gdp) {
          setStartDate("");
        }

        const data = JSON.parse(JSON.stringify(boundry));
        data.fromMapPreferences = fromMapPreferences;
        removeMapLayer(data);
      }
    }
  };

  const handleDefaultCheckboxes = (
    boundaryData,
    selectedLocationName,
    { checked },
  ) => {
    boundaryData?.map((item) => {
      if (
        item.category === adminFrench ||
        item.category === adminEng ||
        item.category === t("GLOFAS.WATER_RESOURCES") ||
        item.category === t("GLOFAS.GROUND_STATION") ||
        item.category === t("DASHBOARD.BASIN")
      ) {
        if (item.layers?.length) {
          handleCateogryToggle(item, { fromCpAdmin: false });
          item.layers.map((x) => {
            const e = { target: {} };
            e.target.checked = checked;
            if (x.slug === selectedLocationName) {
              if (selectedLocationName === mapPrefreenceLabel.COUNTRY) {
                handleCheckbox(e, x, { fromMapPreferences: true });
              } else {
                handleCheckbox(e, x, { fromMapPreferences: false });
              }
            }
            return null;
          });
        }
      }
      return null;
    });
  };
  useEffect(() => {
    if (!_.isEmpty(selectedLocation.country) && getBoundariesData?.length) {
      setSelectedMapPreferences([]); //  to remove the legend data when changing the country
      setGeoJsonLayerData([]);
      const countryData = GEOJSON_COUNTRY_DATA.find(
        (each) => each?.selectedCountry === selectedLocation?.country?.label,
      );

      if (!_.isEmpty(glofasState) && glofasState === Glofas) {
        handleDefaultCheckboxes(getBoundariesData, countryData.glofas.river, {
          checked: true,
          fromMapPreferences: false,
        });
        handleDefaultCheckboxes(
          getBoundariesData,
          countryData.glofas.station,

          {
            checked: true,
            fromMapPreferences: false,
          },
        );
      } else {
        handleDefaultCheckboxes(getBoundariesData, countryData?.glofas?.river, {
          checked: false,
          fromMapPreferences: false,
        });
        handleDefaultCheckboxes(
          getBoundariesData,
          countryData?.glofas?.station,

          {
            checked: false,
            fromMapPreferences: false,
          },
        );
      }
    }
  }, [getBoundariesData, localStorage.getItem("i18nextLng"), glofasState]);

  useEffect(() => {
    if (fromDroughtMonitor) {
      if (
        !_.isEmpty(selectedLocation?.country) &&
        getBoundariesData?.length &&
        !selectedLocation?.state &&
        adminRadio !== "basin"
      ) {
        setGeoJsonLayerData([]);
        handleDefaultCheckboxes(getBoundariesData, mapPrefreenceLabel.STATES, {
          checked: true,
          fromMapPreferences: false,
        });
        handleDefaultCheckboxes(getBoundariesData, mapPrefreenceLabel.COUNTRY, {
          checked: true,
          fromMapPreferences: false,
        });
      }
    }
  }, [
    selectedLocation?.country,
    getBoundariesData,
    fromDroughtMonitor,
    adminRadio,
  ]);

  // for crop predection
  useEffect(() => {
    if (fromCropPredection) {
      if (
        !_.isEmpty(selectedLocation?.country) &&
        getBoundariesData?.length &&
        !selectedLocation?.state &&
        adminRadio !== "basin"
      ) {
        setGeoJsonLayerData([]);
        onHandleCropPredectionDefaultBoundry();
        handleDefaultCheckboxes(getBoundariesData, mapPrefreenceLabel.STATES, {
          checked: true,
          fromMapPreferences: false,
        });
        // handleDefaultCheckboxes(getBoundariesData, mapPrefreenceLabel.COUNTRY, {
        //   checked: true,
        //   fromMapPreferences: false,
        // });
      }
    }
  }, [
    selectedLocation?.country,
    getBoundariesData,
    fromCropPredection,
    adminRadio,
    isCallBoundary,
  ]);

  // Removing Basins and Sub Basins Layer when Admin is selected
  useEffect(() => {
    if (adminRadio === "admin") {
      getBoundariesData?.forEach((data) => {
        if (data.category === t("DASHBOARD.BASIN")) {
          data?.layers?.forEach((item) => {
            const layerData = JSON.parse(JSON.stringify(item));
            layerData.fromMapPreferences = false;
            item?.json_url.length
              ? removeGeoJsonLayer(layerData)
              : removeMapLayer(layerData);
          });
        }
      });
    }
  }, [adminRadio]);

  useEffect(() => {
    if (fromDroughtMonitor || fromCropPredection) {
      if (!_.isEmpty(selectedLocation?.state) && getBoundariesData?.length) {
        handleDefaultCheckboxes(
          getBoundariesData,
          mapPrefreenceLabel.DISTRICTS,
          { checked: true, fromMapPreferences: false },
        );
      }
    }
  }, [selectedLocation?.state, getBoundariesData, fromDroughtMonitor]);

  useEffect(() => {
    if (fromDroughtMonitor) {
      if (
        !_.isEmpty(selectedLocation?.district) &&
        getBoundariesData?.length &&
        taluksDisabled
      ) {
        handleDefaultCheckboxes(getBoundariesData, mapPrefreenceLabel.TALUKAS, {
          checked: true,
          fromMapPreferences: false,
        });
      }
    }
  }, [selectedLocation?.district, getBoundariesData, fromDroughtMonitor]);

  // for crop predection to add taluks when district is selected
  // useEffect(() => {
  //   if (fromCropPredection) {
  //     if (
  //       !_.isEmpty(selectedLocation?.district) &&
  //       selectedLocation?.district?.value !== "" &&
  //       getBoundariesData?.length &&
  //       taluksDisabled
  //     ) {
  //       handleDefaultCheckboxes(getBoundariesData, mapPrefreenceLabel.TALUKAS, {
  //         checked: true,
  //         fromMapPreferences: false,
  //       });
  //     }
  //   }
  // }, [selectedLocation?.district, getBoundariesData, fromCropPredection]);

  // Adding Basin boundaries and removing Regions and districts Boundaries
  useEffect(() => {
    if (fromDroughtMonitor || fromCropPredection) {
      if (adminRadio === "basin" && getBoundariesData?.length) {
        handleDefaultCheckboxes(getBoundariesData, mapPrefreenceLabel.BASINS, {
          checked: true,
          fromMapPreferences: false,
        });
        getBoundariesData?.forEach((data) => {
          if (data.category === t("DASHBOARD.ADMIN")) {
            data?.layers?.forEach((item) => {
              const layerData = JSON.parse(JSON.stringify(item));
              layerData.fromMapPreferences = false;
              item?.json_url.length
                ? removeGeoJsonLayer(layerData)
                : removeMapLayer(layerData);
            });
          }
        });
        dispatch(stateBoundarySuccess(""));
        dispatch(countryBoundarySuccess(""));
      }
    }
  }, [
    selectedLocation?.basin,
    getBoundariesData,
    fromDroughtMonitor,
    adminRadio,
    isCallBoundary,
  ]);

  // useEffect(() => {

  //   if (fromDroughtMonitor) {
  //     if (!_.isEmpty(selectedLocation?.basin)) {
  //       if (
  //         selectedLocation?.basin?.value !== "" &&
  //         getBoundariesData?.length
  //       ) {
  //         handleDefaultCheckboxes(
  //           getBoundariesData,
  //           mapPrefreenceLabel.MAJOR_RIVERS,
  //           {
  //             checked: true,
  //             fromMapPreferences: false,
  //           },
  //         );
  //       } else {
  //         getBoundariesData?.forEach((data) => {
  //           if (data.category === "Basin") {
  //             data?.layers?.forEach((item) => {
  //               if (["major_rivers", "minor_rivers"].includes(item?.slug)) {
  //                 const layerData = JSON.parse(JSON.stringify(item));
  //                 layerData.fromMapPreferences = false;
  //                 item?.json_url.length
  //                   ? removeGeoJsonLayer(layerData)
  //                   : removeMapLayer(layerData);
  //               }
  //             });
  //           }
  //         });
  //       }
  //     }
  //   }
  // }, [selectedLocation?.basin, getBoundariesData, fromDroughtMonitor]);

  // useEffect(() => {
  //   if (fromDroughtMonitor) {
  //     if (!_.isEmpty(selectedLocation?.subBasin)) {
  //       if (
  //         selectedLocation?.subBasin?.value !== "" &&
  //         getBoundariesData?.length
  //       ) {
  //         handleDefaultCheckboxes(
  //           getBoundariesData,
  //           mapPrefreenceLabel.MINOR_RIVERS,
  //           {
  //             checked: true,
  //             fromMapPreferences: false,
  //           },
  //         );
  //       } else {
  //         getBoundariesData?.forEach((data) => {
  //           if (data.category === "Basin") {
  //             data?.layers?.forEach((item) => {
  //               if (["minor_rivers"].includes(item?.slug)) {
  //                 const layerData = JSON.parse(JSON.stringify(item));
  //                 layerData.fromMapPreferences = false;
  //                 item?.json_url.length
  //                   ? removeGeoJsonLayer(layerData)
  //                   : removeMapLayer(layerData);
  //               }
  //             });
  //           }
  //         });
  //       }
  //     }
  //   }
  // }, [selectedLocation?.subBasin, getBoundariesData, fromDroughtMonitor]);

  useEffect(() => {
    if ((fromDroughtMonitor && adminRadio === "basin") || fromCropPredection) {
      if (!_.isEmpty(selectedLocation?.basin) && !fromCropPredection) {
        if (
          selectedLocation?.basin?.value !== "" &&
          getBoundariesData?.length
        ) {
          handleDefaultCheckboxes(
            getBoundariesData,
            mapPrefreenceLabel.SUB_BASIN,
            {
              checked: true,
              fromMapPreferences: false,
            },
          );
        } else {
          handleDefaultCheckboxes(
            getBoundariesData,
            mapPrefreenceLabel.SUB_BASIN,
            {
              checked: false,
              fromMapPreferences: false,
            },
          );
        }
      }
    }
  }, [
    selectedLocation?.basin,
    getBoundariesData,
    fromDroughtMonitor,
    isCallBoundary,
  ]);

  const toggle = () => {
    setModal(!modal);
    handleToggle(!modal);
  };

  const isCheckbox = (inputId) => {
    if (mapPreferencesData?.find((item) => item?.id === inputId)) {
      return true;
    }
    if (geoJsonLayerData?.find((item) => item?.id === inputId)) {
      return true;
    }
    return null;
  };

  const isRadioCheckbox = (inputId) => {
    if (mapPreferencesData?.find((item) => item?.soil === inputId)) {
      return true;
    }

    return null;
  };

  const showCheckBox = (mapPreferencesList, boundry) => {
    const url = boundry?.json_url;
    const id = boundry.id;

    const isAddedLayer = selectedParamLegendData?.some((i) => i.add);
    if (isRapidResponse) {
      if (mapPreferencesList?.length >= 1 && _.isEmpty(url)) {
        if (mapPreferencesList?.some((i) => i?.id === id)) {
          return false;
        } else {
          return true;
        }
      } else {
        if (
          selectedParamLegendData?.filter((i) => i.add)?.length > 1 &&
          _.isEmpty(url)
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (isAddedLayer && mapPreferencesList?.length === 1 && _.isEmpty(url)) {
        if (mapPreferencesList?.some((i) => i?.id === id)) {
          return false;
        } else {
          return true;
        }
      } else if (mapPreferencesList?.length === 2 && _.isEmpty(url)) {
        if (mapPreferencesList?.some((i) => i?.id === id)) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  };

  const showToolTip = (
    selectedMapPreferencesLayer,
    selectedParamLegendDataTool,
  ) => {
    if (isRapidResponse) {
      if (
        selectedMapPreferencesLayer?.length >= 1 ||
        selectedParamLegendDataTool?.filter((i) => i.add)?.length > 1
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        selectedMapPreferencesLayer?.length === 2 ||
        (selectedMapPreferencesLayer?.length === 1 &&
          selectedParamLegendDataTool?.filter((i) => i.add)?.length === 1)
      ) {
        return true;
      } else return false;
    }
  };

  useEffect(() => {
    if (preferenceList) {
      setTaluksDisabled(preferenceList[0]?.layers[3]?.status);
    }
  }, [preferenceList]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        animation={false}
        className="mapprefernce-modal"
      >
        <ModalHeader
          className="close-map-preference"
          toggle={toggle}
          close={
            <button className="close-button" type="button">
              ×
            </button>
          }
        >
          <p className="map-preferences-side-bar-title">
            {t("DASHBOARD.Map_preferences")}
          </p>
          <span className="map-preferences-side-bar-sub-title">
            {t("DASHBOARD.MAP_MOdal_P")}
          </span>
          <Button className="close-btn-map" onClick={() => toggle()}>
            <IoMdCloseCircleOutline className="close-svg" />
          </Button>
        </ModalHeader>
        <ModalBody className="px-0 py-2">
          {preferenceList &&
            preferenceList.map((eachLayer, index) => {
              return (
                eachLayer?.category !== t("MAP_PREFERENCES.OTHERS") && (
                  <div>
                    <NavItem
                      onClick={() => onCollaspedToggle(eachLayer)}
                      data-tip
                      data-for={
                        eachLayer.category === t("DASHBOARD.ADMIN")
                          ? "info-icon-tool-tip"
                          : ""
                      }
                      className="map-preferences-labels"
                    >
                      {eachLayer?.category}
                      {index === 0 && (
                        <CommonToolTip moduleName="Map Preferences">
                          <span className="info-style">
                            <BsInfo
                              className="info-icon ml-1"
                              id="info-icon-tool-tip"
                            />
                          </span>
                        </CommonToolTip>
                      )}
                      {collaspedMenu?.includes(eachLayer.category) ? (
                        <BsFillCaretUpFill className="up-icon float-right " />
                      ) : (
                        <BsCaretDownFill className="up-icon float-right " />
                      )}
                    </NavItem>

                    <Collapse
                      isOpen={collaspedMenu?.includes(eachLayer.category)}
                    >
                      {eachLayer &&
                        eachLayer?.layers?.map((eachLayerCheck) => {
                          if (
                            eachLayer?.category !== t("MAP_PREFERENCES.OTHERS")
                          ) {
                            return (
                              <>
                                <NavLink
                                  className="map-preferences-checkbox"
                                  id={
                                    !eachLayerCheck.status
                                      ? "no_data_available"
                                      : eachLayerCheck.map_url ||
                                        !showCheckBox(
                                          selectedMapPreferences,
                                          eachLayerCheck,
                                        )
                                      ? "geojson_input"
                                      : "map_preference_input2"
                                  }
                                  data-tip
                                  data-for={
                                    !eachLayerCheck.status
                                      ? "no_data_available"
                                      : eachLayerCheck.map_url ||
                                        !showCheckBox(
                                          selectedMapPreferences,
                                          eachLayerCheck,
                                        )
                                      ? "geojson_input"
                                      : "map_preference_input2"
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    className="mr-2"
                                    id={eachLayerCheck.name}
                                    checked={isCheckbox(eachLayerCheck.id)}
                                    onChange={(e) =>
                                      handleCheckbox(e, eachLayerCheck, {
                                        fromMapPreferences: true,
                                      })
                                    }
                                    disabled={
                                      !eachLayerCheck.status ||
                                      showCheckBox(
                                        selectedMapPreferences,
                                        eachLayerCheck,
                                      )
                                    }
                                    name={eachLayerCheck.name}
                                  />
                                  {eachLayerCheck.name}
                                </NavLink>
                                {!eachLayerCheck.status ||
                                showToolTip(
                                  selectedMapPreferences,
                                  selectedParamLegendData,
                                ) ? (
                                  <ReactTooltip
                                    id={
                                      !eachLayerCheck.status
                                        ? "no_data_available"
                                        : "map_preference_input2"
                                    }
                                    effect="float"
                                    place="bottom"
                                    arrowColor="#22ad7a"
                                    className={
                                      !eachLayerCheck.status
                                        ? "tooltip-comming-soon"
                                        : "map-prefer-tooltip-st"
                                    }
                                  >
                                    {!eachLayerCheck.status
                                      ? t("DASHBOARD.UPDATED_SOON")
                                      : t("MAP_PREFERENCES.DISABLE_MESSAGE")}
                                  </ReactTooltip>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          }
                          return null;
                        })}
                    </Collapse>
                  </div>
                )
              );
            })}
          <NavItem
            onClick={() =>
              onCollaspedToggle(preferenceList[preferenceList.length - 1])
            }
            data-tip
            className="map-preferences-labels"
          >
            {t("MAP_PREFERENCES.OTHERS")}
            {collaspedMenu?.includes(
              preferenceList[preferenceList.length - 1].category,
            ) ? (
              <BsFillCaretUpFill className="up-icon float-right " />
            ) : (
              <BsCaretDownFill className="up-icon float-right " />
            )}
          </NavItem>
          <Collapse
            isOpen={collaspedMenu?.includes(
              preferenceList[preferenceList.length - 1].category,
            )}
          >
            <NavLink className="map-preferences-checkbox">
              <div
                id={
                  !preferenceList[preferenceList.length - 1]?.layers?.[0]
                    ?.status
                    ? "no_data_available_soil"
                    : preferenceList[preferenceList.length - 1]?.layers?.[0]
                        .map_url ||
                      !showCheckBox(
                        selectedMapPreferences,
                        preferenceList[preferenceList.length - 1]?.layers?.[0],
                      )
                    ? "geojson_input_soil"
                    : "map_preference_input_soil"
                }
                data-tip
                data-for={
                  !preferenceList[preferenceList.length - 1]?.layers?.[0]
                    ?.status
                    ? "no_data_available_soil"
                    : preferenceList[preferenceList.length - 1]?.layers?.[0]
                        .map_url ||
                      !showCheckBox(
                        selectedMapPreferences,
                        preferenceList[preferenceList.length - 1]?.layers?.[0],
                      )
                    ? "geojson_input_soil"
                    : "map_preference_input_soil"
                }
              >
                <div className="">
                  <input
                    type="checkbox"
                    className="mr-2"
                    id={
                      preferenceList[preferenceList.length - 1]?.layers?.[0]
                        ?.name
                    }
                    checked={otherSelection.soil}
                    onChange={(e) =>
                      handleOtherCheckbox(
                        e,
                        preferenceList[preferenceList.length - 1]?.layers?.[0],
                        { fromMapPreferences: true },
                      )
                    }
                    disabled={
                      !preferenceList[preferenceList.length - 1]?.layers?.[0]
                        ?.status ||
                      showCheckBox(
                        selectedMapPreferences,
                        preferenceList[preferenceList.length - 1]?.layers?.[0],
                      )
                    }
                    name={
                      preferenceList[preferenceList.length - 1]?.layers?.[0]
                        ?.slug
                    }
                  />
                  {t("MAP_PREFERENCES.SOIL_TEXTURE")}
                </div>

                {!preferenceList[preferenceList.length - 1]?.layers?.[0]
                  ?.status ||
                showToolTip(selectedMapPreferences, selectedParamLegendData) ? (
                  <ReactTooltip
                    id={
                      !preferenceList[preferenceList.length - 1]?.layers?.[0]
                        ?.status
                        ? "no_data_available_soil"
                        : "map_preference_input_soil"
                    }
                    effect="float"
                    place="bottom"
                    arrowColor="#22ad7a"
                    className={
                      !preferenceList[preferenceList.length - 1]?.layers?.[0]
                        .status
                        ? "tooltip-comming-soon"
                        : "map-prefer-tooltip-st"
                    }
                  >
                    {!preferenceList[preferenceList.length - 1]?.layers?.[0]
                      ?.status
                      ? t("DASHBOARD.UPDATED_SOON")
                      : t("MAP_PREFERENCES.DISABLE_MESSAGE")}
                  </ReactTooltip>
                ) : (
                  ""
                )}
              </div>
              {otherSelection[
                `${
                  preferenceList[preferenceList.length - 1]?.layers?.[0]?.slug
                }`
              ] ? (
                <div className="space-between mt-2">
                  {otherSection &&
                    otherSection.map((eachLayer) => (
                      <div className="form-check align-center">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          value={eachLayer?.value}
                          id={eachLayer?.value}
                          checked={isRadioCheckbox(eachLayer.value)}
                          onChange={(e) =>
                            onHandleSoilTexture(
                              e,
                              preferenceList[preferenceList.length - 1]
                                ?.layers[0],
                              {
                                fromMapPreferences: true,
                              },
                            )
                          }
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          {eachLayer.lable}
                        </Label>
                      </div>
                    ))}
                </div>
              ) : (
                ""
              )}
            </NavLink>
            {/* // for gdp  */}
            <NavLink
              className="map-preferences-checkbox"
              id={
                !preferenceList[preferenceList.length - 1]?.layers[1]?.status
                  ? "no_data_available"
                  : preferenceList[preferenceList.length - 1]?.layers[1]
                      .map_url ||
                    !showCheckBox(
                      selectedMapPreferences,
                      preferenceList[preferenceList.length - 1]?.layers[1],
                    )
                  ? "geojson_input"
                  : "map_preference_input3"
              }
              data-tip
              data-for={
                !preferenceList[preferenceList.length - 1]?.layers[1]?.status
                  ? "no_data_available"
                  : preferenceList[preferenceList.length - 1]?.layers[1]
                      .map_url ||
                    !showCheckBox(
                      selectedMapPreferences,
                      preferenceList[preferenceList.length - 1]?.layers[1],
                    )
                  ? "geojson_input"
                  : "map_preference_input3"
              }
            >
              <div>
                <div className="">
                  <input
                    type="checkbox"
                    className="mr-2"
                    id={
                      preferenceList[preferenceList.length - 1]?.layers[1]?.name
                    }
                    checked={otherSelection.gdp}
                    onChange={(e) =>
                      handleOtherCheckbox(
                        e,
                        preferenceList[preferenceList.length - 1]?.layers[1],

                        { fromMapPreferences: true },
                      )
                    }
                    disabled={
                      !preferenceList[preferenceList.length - 1]?.layers[1]
                        ?.status ||
                      showCheckBox(
                        selectedMapPreferences,
                        preferenceList[preferenceList.length - 1]?.layers[1],
                      )
                    }
                    name={
                      preferenceList[preferenceList.length - 1]?.layers[1]?.slug
                    }
                  />
                  {t("MAP_PREFERENCES.GDP")}
                </div>
                {!preferenceList[preferenceList.length - 1]?.layers[1]
                  ?.status ||
                showToolTip(selectedMapPreferences, selectedParamLegendData) ? (
                  <ReactTooltip
                    id={
                      !preferenceList[preferenceList.length - 1]?.layers[1]
                        ?.status
                        ? "no_data_available"
                        : "map_preference_input3"
                    }
                    effect="float"
                    place="bottom"
                    arrowColor="#22ad7a"
                    className={
                      !preferenceList[preferenceList.length - 1]?.layers[1]
                        ?.status
                        ? "tooltip-comming-soon"
                        : "map-prefer-tooltip-st"
                    }
                  >
                    {!preferenceList[preferenceList.length - 1]?.layers[1]
                      ?.status
                      ? t("DASHBOARD.COMING_SOON")
                      : t("MAP_PREFERENCES.DISABLE_MESSAGE")}
                  </ReactTooltip>
                ) : (
                  ""
                )}
              </div>
              {otherSelection[
                `${preferenceList[preferenceList.length - 1]?.layers[1]?.slug}`
              ] ? (
                <div className="map-prefer-date mt-2">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      onHandleGdpYear(
                        date,
                        preferenceList[preferenceList.length - 1]?.layers[1],
                        {
                          fromMapPreferences: true,
                        },
                      );
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                    minDate={new Date("1990")}
                    maxDate={new Date("2015")}
                    placeholderText={t("DASHBOARD.START_DATE")}
                  />
                </div>
              ) : (
                ""
              )}
            </NavLink>
          </Collapse>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default MapPreferenceModal;
