import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import errorImage from "../../assest/img/errorImage.svg";

function ActiveModal({
  setDeleteModal,
  deleteModal,
  onHandleConfirmation,
  title,
  message,
}) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={deleteModal} size="md">
      <div className="error-modal">
        <ModalHeader className="p-0 delete-header user-flex-end mr-2 mt-5" />
        <ModalBody className="delete-modal-padding">
          <div className="reg-success">
            <img
              src={errorImage}
              alt="not-available"
              className="reg-check-image"
            />
            <h2 className="delete-modal-header">{title}</h2>
            <p className="delete-modal-text mb-4 p-3">{message}</p>
            <div className="w-50 d-flex align-items-center justify-content-around">
              <Button
                type="button"
                className="back-office-cancel-btn"
                onClick={() => {
                  setDeleteModal();
                }}
              >
                {t("APPROVE_DATA.CANCEL")}
              </Button>
              <Button
                type="button"
                className="activate-btn"
                onClick={() => {
                  setDeleteModal();
                  onHandleConfirmation();
                  //   handleDeleteApiCall();
                }}
              >
                Activate
              </Button>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default ActiveModal;
