const RAPID_RESPONSE_CONST = {
  // RAINFALL DATA
  GET_RAINFALL_MAP_DATA_REQUEST: "GET_RAINFALL_MAP_DATA_REQUEST",
  GET_RAINFALL_MAP_DATA_SUCCESS: "GET_RAINFALL_MAP_DATA_SUCCESS",
  GET_RAINFALL_MAP_DATA_FAILED: "GET_RAINFALL_MAP_DATA_FAILED",

  // RAPID RESPONSE NEWSFEED
  GET_RAPID_RESPONSE_NEWSFEED_DATA_REQUEST:
    "GET_RAPID_RESPONSE_NEWSFEED_DATA_REQUEST",
  GET_RAPID_RESPONSE_NEWSFEED_DATA_SUCCESS:
    "GET_RAPID_RESPONSE_NEWSFEED_DATA_SUCCESS",
  GET_RAPID_RESPONSE_NEWSFEED_DATA_FAILED:
    "GET_RAPID_RESPONSE_NEWSFEED_DATA_FAILED",

  GET_RAPID_RESPONSE_DISASTER_EVENTS_DATA_REQUEST:
    "GET_RAPID_RESPONSE_DISASTER_EVENTS_DATA_REQUEST",
  GET_RAPID_RESPONSE_DISASTER_EVENTS_DATA_SUCCESS:
    "GET_RAPID_RESPONSE_DISASTER_EVENTS_DATA_SUCCESS",
  GET_RAPID_RESPONSE_DISASTER_EVENTS_DATA_FAILED:
    "GET_RAPID_RESPONSE_DISASTER_EVENTS_DATA_FAILED",

  GET_RAPID_RESPONSE_DISASTER_TYPES_REQUEST:
    "GET_RAPID_RESPONSE_DISASTER_TYPES_REQUEST",
  GET_RAPID_RESPONSE_DISASTER_TYPES_SUCCESS:
    "GET_RAPID_RESPONSE_DISASTER_SUCCESS",
  GET_RAPID_RESPONSE_DISASTER_TYPES_FAILED:
    "GET_RAPID_RESPONSE_DISASTER_FAILED",

  CREATE_DISASTER_ALERT_REQUEST: "CREATE_DISASTER_ALERT_REQUEST",
  CREATE_DISASTER_ALERT_SUCCESS: "CREATE_DISASTER_ALERT_SUCCESS",
  CREATE_DISASTER_ALERT_FAILED: "CREATE_DISASTER_ALERT_FAILED",

  GET_DISASTER_ALERT_LIST_REQUEST: "GET_DISASTER_ALERT_LIST_REQUEST",
  GET_DISASTER_ALERT_LIST_SUCCESS: "GET_DISASTER_ALERT_LIST_SUCCESS",
  GET_DISASTER_ALERT_LIST_FAILED: "GET_DISASTER_ALERT_LIST_FAILED",

  // APPROVE DISASTER EVENT
  GET_APPROVE_DISASTER_EVENT_REQUEST: "GET_APPROVE_DISASTER_EVENT_REQUEST",
  GET_APPROVE_DISASTER_EVENT_SUCCESS: "GET_APPROVE_DISASTER_EVENT_SUCCESS",
  GET_APPROVE_DISASTER_EVENT_FAILED: "GET_APPROVE_DISASTER_EVENT_FAILED",
};

export default RAPID_RESPONSE_CONST;
