import USER_MANAGE_GUIDE_CONSTS from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};

const ManageUser = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }

  switch (action.type) {
    case USER_MANAGE_GUIDE_CONSTS.GET_All_MANAGE_USER_GUIDE_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
        isLoading: true,
        useMenuData: null,
        error: "",
      };
      break;
    case USER_MANAGE_GUIDE_CONSTS.GET_All_MANAGE_USER_GUIDE_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        isLoading: false,
        useMenuData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case USER_MANAGE_GUIDE_CONSTS.GET_All_MANAGE_USER_GUIDE_LIST_FAILED:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        isLoading: false,
        useMenuData: null,
      };
      break;

    // sub topic reducer
    case USER_MANAGE_GUIDE_CONSTS.GET_All_MANAGE_USER_SUB_TOPICS_REQUEST:
      state = {
        ...state,
        loading: true,
        subTopicData: null,
        error: "",
      };
      break;
    case USER_MANAGE_GUIDE_CONSTS.GET_All_MANAGE_USER_SUB_TOPICS_SUCCESS:
      state = {
        ...state,
        loading: false,
        subTopicData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case USER_MANAGE_GUIDE_CONSTS.GET_All_MANAGE_USER_SUB_TOPICS_FAILED:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        subTopicData: null,
      };
      break;

    // add user guide reducer
    case USER_MANAGE_GUIDE_CONSTS.ADD_USER_GUIDE_REQUEST:
      state = {
        ...state,
        loading: true,
        addUserData: null,
        error: "",
      };
      break;
    case USER_MANAGE_GUIDE_CONSTS.ADD_USER_GUIDE_SUCCESS:
      state = {
        ...state,
        loading: false,
        addUserData:
          action.payload.statusCode === 200 ? action.payload.data : false,
      };
      break;
    // delete user guide
    case USER_MANAGE_GUIDE_CONSTS.DELETE_USER_GUIDE_REQUEST:
      state = {
        ...state,
        loading: true,
        deleteUser: null,
        error: "",
      };
      break;
    case USER_MANAGE_GUIDE_CONSTS.DELETE_USER_GUIDE_SUCCESS:
      state = {
        ...state,
        loading: false,
        deleteUser:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case USER_MANAGE_GUIDE_CONSTS.ADD_USER_GUIDE_FAILURE:
    case USER_MANAGE_GUIDE_CONSTS.DELETE_USER_GUIDE_FAILURE:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        addUserData: null,
        deleteUser: null,
      };
      break;

    // UPDATE USER GUIDE

    case USER_MANAGE_GUIDE_CONSTS.UPDATE_USER_GUIDE_REQUEST:
      state = {
        ...state,
        loading: true,
        updateUser: null,
        error: "",
      };
      break;
    case USER_MANAGE_GUIDE_CONSTS.UPDATE_USER_GUIDE_SUCCESS:
      state = {
        ...state,
        loading: false,
        updateUser:
          action.payload.statusCode === 200 ? action.payload?.data : false,
        error: "",
      };
      break;
    case USER_MANAGE_GUIDE_CONSTS.UPDATE_USER_GUIDE_FAILURE:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        updateUser: null,
      };
      break;

    // PUBLISH USER GUIDE

    case USER_MANAGE_GUIDE_CONSTS.PUBLISH_USER_GUIDE_REQUEST:
      state = {
        ...state,
        loading: true,
        publishUser: null,
        error: "",
      };
      break;
    case USER_MANAGE_GUIDE_CONSTS.PUBLISH_USER_GUIDE_SUCCESS:
      state = {
        ...state,
        loading: false,
        publishUser:
          action.payload.statusCode === 200 ? action.payload?.data : false,
        error: "",
      };
      break;
    case USER_MANAGE_GUIDE_CONSTS.PUBLISH_USER_GUIDE_FAILURE:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        publishUser: null,
      };
      break;

    // SUB CATEGORY LIST USER GUIDE

    case USER_MANAGE_GUIDE_CONSTS.GET_SUB_CATEGORY_GUIDE_REQUEST:
      state = {
        ...state,
        loading: true,
        getSubCategoryList: null,
        error: "",
      };
      break;
    case USER_MANAGE_GUIDE_CONSTS.GET_SUB_CATEGORY_GUIDE_SUCCESS:
      state = {
        ...state,
        loading: false,
        getSubCategoryList:
          action.payload.statusCode === 200 ? action.payload?.data : false,
        error: "",
      };
      break;
    case USER_MANAGE_GUIDE_CONSTS.GET_SUB_CATEGORY_GUIDE_FAILURE:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        getSubCategoryList: null,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ManageUser;
