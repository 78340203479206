import React, { useState, memo } from "react";
import { FiCheck } from "react-icons/fi";
import { GrFormClose } from "react-icons/gr";
import { FaEdit } from "react-icons/fa";
import _ from "lodash";
import { Input } from "reactstrap";

function IndicatorsInfo({
  indicatorRows,
  selectedIndicator,
  setSelectedIndicator,
  rowKey,
}) {
  const [isTextEdit, setIsTextEdit] = useState(false);

  const INDICATORS_INFO = {
    NDVI: "NDVI stands for Normalized Difference Vegetation Index, a common remote sensing measurement, describing the difference between visible and near-infrared reflectance of vegetation cover.",
    VCI: "VCI stands for Vegetation Condition Index. The Vegetation Condition Index (VCI) compares the current NDVI to the range of values observed in the same period in previous years.",
    "Dry spell":
      "A dry spell is defined as the number of consecutive days with a daily precipitation amount below a certain threshold, such as 0.1, 1, 5, 10 mm.",
    IDSI: "IDSI stands for Integrated Drought Severity Index. IDSI is based on the integration of multi-scale VCI, PCI and TCI using data fusion techniques.",
    MAI: "MAI stands for Moisture Adequacy Index. Moisture Adequacy Index (MAI) is the ratio of actual evapotranspiration (AET) to the potential evapotranspiration (PET).",
    SMCI: "SMCI stands for Soil Moisture Condition Index.Soil Moisture Condition Index (SMCI) represents soil moisture condition similar like VCI.",
    NDWI: "NDWI stands for Normalized difference water index. It can be utilized for evaluating vegetation liquid water contents or water inundated areas.",
    "SPI (GPM)":
      "SPI stands for Standardized Precipitation Index, the measure of the number of standard deviations of observed cumulative precipitation deviates from the climatological average.",
    "Rainfall Difict": "",
    "Temparature Anomaly": "",
  };
  const [isText, setIsText] = useState(INDICATORS_INFO);

  const editInfo = (row) => {
    setSelectedIndicator(row);
    setIsTextEdit(true);
  };

  const handleTextEdit = (value, indicatorName) => {
    const updatedText = _.cloneDeep(isText);
    if (!_.isEmpty(updatedText)) {
      setIsText((prev) => ({
        ...prev,
        [indicatorName]: value,
      }));
    }
  };

  return (
    <div className="info-div">
      <h6 className="text-div">Indicators Basic Info:</h6>
      <div className="hr-lines"> </div>
      <div className="info-div" key={rowKey}>
        {indicatorRows[rowKey]?.indicatorsList.map((eachIndicator) => {
          const defaultText = INDICATORS_INFO[eachIndicator.indices_name];
          const indicator = `${rowKey}.${eachIndicator.indices_name}`;
          return (
            <div>
              {indicator === selectedIndicator && isTextEdit ? (
                <div className="d-flex">
                  <Input
                    value={isText[eachIndicator.indices_name]}
                    title="Text"
                    placeholder="Enter Title"
                    onChange={(e) =>
                      handleTextEdit(e.target.value, eachIndicator.indices_name)
                    }
                  />

                  <FiCheck
                    size={30}
                    onClick={() => setIsTextEdit(false)}
                    className="cursor-pointer green-icon mx-2 mt-1"
                  />
                  <GrFormClose
                    size={30}
                    color="#e86933"
                    className="cursor-pointer danger-icon mt-1"
                    onClick={() => {
                      setIsText((prev) => ({
                        ...prev,
                        [eachIndicator.indices_name]: defaultText,
                      }));
                      setSelectedIndicator("");
                    }}
                  />
                </div>
              ) : (
                <div key={eachIndicator.indices_name}>
                  <p className="bulliten-indicator-text">
                    {eachIndicator.indices_name} :{" "}
                    {isText[eachIndicator.indices_name]}
                    <FaEdit
                      id={`${rowKey}.${eachIndicator.indices_name}`}
                      color="#dd9103"
                      size={18}
                      className="cursor-pointer mx-2 indicator-icon"
                      onClick={() =>
                        editInfo(`${rowKey}.${eachIndicator.indices_name}`)
                      }
                    />
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default memo(IndicatorsInfo);
