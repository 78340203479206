import { useEffect } from "react";
import L from "leaflet";
import _ from "lodash";
import moment from "moment";
import { LEGEND_UNIT_CONVERTER } from "./Utils";

function Legend({
  map,
  add,
  layer,
  position,
  activeTabSwitch,
  selectedSubModule,
}) {
  const id = layer?.id + position;
  const handleUnitConverter = () => {
    const legenedEle = document.getElementById(`legend-unit-${id}`);
    const minEle = document.getElementById("minValue");
    const maxEle = document.getElementById("maxValue");
    let unitSelected = "";
    let minVal = "";
    let maxVal = "";
    if (legenedEle) {
      unitSelected = legenedEle.textContent;
    }
    if (minEle) {
      minVal = minEle.textContent;
    }
    if (maxEle) {
      maxVal = maxEle.textContent;
    }
    const converUnit = LEGEND_UNIT_CONVERTER.find(
      (item) => item.unit === unitSelected,
    );
    if (!_.isEmpty(converUnit)) {
      const min = converUnit.convert(minVal);
      const max = converUnit.convert(maxVal);
      if (minEle) {
        minEle.innerHTML = min;
      }
      if (maxEle) {
        maxEle.innerHTML = max;
      }
      const selectedUnit = LEGEND_UNIT_CONVERTER.find(
        (item) => item.unit === converUnit.converter,
      );
      if (!_.isEmpty(selectedUnit)) {
        if (legenedEle) {
          legenedEle.innerHTML = selectedUnit.unit;
        }
      }
    } else {
      return "";
    }
    return "";
  };

  const setUnitTitle = () => {
    const unitsEle = document.getElementById("units-col-div");
    if (unitsEle) {
      const legenedEle = document.getElementById(`legend-unit-${id}`);
      let unitSelected;
      if (legenedEle) {
        unitSelected = legenedEle.textContent;
      }
      const converUnit = LEGEND_UNIT_CONVERTER.find(
        (item) => item.unit === unitSelected,
      );
      if (!_.isEmpty(converUnit)) {
        unitsEle.setAttribute("name", converUnit?.converter);
        const nameEle = unitsEle.getAttribute("name");
        if (!_.isEmpty(nameEle) && nameEle === unitSelected) {
          unitsEle.removeAttribute("title");
        } else {
          const title = `Change units to "${converUnit?.converter}"`;
          unitsEle.setAttribute("title", title);
        }
      }
    }
  };

  const handleLayerLegendParameterName = (data) => {
    return layer?.parameter
      ? data.parameter + (data.measure ? " (" : "")
      : data?.measure
      ? " ("
      : "";
  };
  useEffect(() => {
    handleLayerLegendParameterName(layer);
  }, [layer]);

  useEffect(() => {
    if (map) {
      const length = layer?.palette?.length;
      const legend = L?.control({ position });
      const percentage = (Math.abs(0) + Math.abs(100)) / length;
      let backgroundColor = `linear-gradient(90deg,)`;
      if (layer?.palette?.length === 1) {
        backgroundColor = layer?.palette[0];
      } else {
        for (let i = 0; i < layer?.palette?.length; i += 1) {
          const colorCode =
            `${layer?.palette[i]} ${Math.round(percentage + i * percentage)}%` +
            `${i === layer.palette.length - 1 ? "" : ","}`;
          const index = backgroundColor.lastIndexOf(",");
          const newString =
            backgroundColor.slice(0, index + 1) +
            colorCode +
            backgroundColor.slice(index + 1);
          backgroundColor = newString;
        }
      }

      if (add) {
        legend.onAdd = () => {
          let parentDiv = "";
          parentDiv = L.DomUtil.create("div");
          parentDiv.id = id || "legend_div";
          if (position === "bottomleft") {
            parentDiv.className = "left-legend-style";
          } else {
            parentDiv.className = "right-legend-style";
          }

          const datesContainer = L.DomUtil.create("div");
          const spanContainer = L.DomUtil.create("div");
          datesContainer.className =
            position === "bottomleft"
              ? "datesContainLeftDiv"
              : "datesContainRightDiv";
          spanContainer.className = "dates-span-container";
          if (activeTabSwitch === "malaria") {
            if (layer?.startDate) {
              spanContainer.innerHTML = layer?.startDate
                ? layer?.startDate?.start_year
                  ? selectedSubModule === "diarrhea"
                    ? `${layer?.startDate?.start_year}`
                    : `${layer?.startDate?.label}, ${layer?.startDate?.start_year}`
                  : layer?.startDate?.label
                : "";
              datesContainer.appendChild(spanContainer);
              parentDiv.appendChild(datesContainer);
            }
          } else if (layer?.startDate && layer?.endDate) {
            spanContainer.innerHTML = `${moment(layer?.startDate).format(
              "DD-MM-YYYY",
            )} to ${moment(layer?.endDate).format("DD-MM-YYYY")} `;
            datesContainer.appendChild(spanContainer);
            parentDiv.appendChild(datesContainer);
          }
          const containerDiv = L.DomUtil.create("div");
          containerDiv.className =
            Array.isArray(layer?.palette) === false ||
            layer?.palette?.length !== 0
              ? "legend-container-custom-div"
              : "empty-legend-container-div";
          const dataSourceDiv = L.DomUtil.create("div");
          dataSourceDiv.className = "units-legend-style";
          const rowDiv = L.DomUtil.create("div");
          const unitsDiv = L.DomUtil.create("div");
          const dataSourceText = L.DomUtil.create("p");
          dataSourceText.className = "legend-units dataSource-style";
          dataSourceText.innerHTML = _.isEmpty(layer?.sensor_name)
            ? layer.dataSource
            : handleLayerLegendParameterName(layer);
          dataSourceDiv.appendChild(dataSourceText);
          const columnOne = L.DomUtil.create("div");
          columnOne.id = "units-col-div";
          columnOne.className = "units-legend-style";
          const coloneText = L.DomUtil.create("span");
          coloneText.className = "legend-units cursor-ponter";
          if (activeTabSwitch === "tab1") {
            coloneText.innerHTML = _.isEmpty(layer?.sensor_name)
              ? handleLayerLegendParameterName(layer)
              : _.isEmpty(layer.measure)
              ? `${layer.sensor_name}-${layer.dataSource}`
              : `${layer.sensor_name}-${layer.dataSource} (`;
          } else {
            coloneText.innerHTML = _.isEmpty(layer?.sensor_name)
              ? handleLayerLegendParameterName(layer)
              : _.isEmpty(layer.measure)
              ? `${layer.dataSource}-${layer.sensor_name}`
              : `${layer.dataSource}-${layer.sensor_name}(`;
          }

          const coltwoText = L.DomUtil.create("span");
          coltwoText.className = "legend-units cursor-ponter";
          coltwoText.id = `legend-unit-${id}`;
          const paramUnits = layer?.measure || "";
          const measure = paramUnits.includes("^")
            ? paramUnits.split("^")
            : paramUnits;
          if (Array.isArray(measure)) {
            let superScriptText = "";
            const [firstText, secondText] = measure;
            coltwoText.innerHTML = firstText;
            const measure2 = secondText?.includes("/")
              ? secondText?.split("/")
              : "";
            if (measure2) {
              superScriptText = L.DomUtil.create("sup");
              const [firstText2] = measure2;
              superScriptText.innerHTML = firstText2;
              coltwoText.appendChild(superScriptText);
              const otherUnit = L.DomUtil.create("span");
              otherUnit.innerHTML = `/${measure2[1]}`;
              coltwoText.appendChild(otherUnit);
            } else {
              superScriptText = L.DomUtil.create("sup");
              superScriptText.innerHTML = secondText;
              coltwoText.appendChild(superScriptText);
            }
          } else {
            coltwoText.innerHTML = measure;
          }
          const colThreeText = L.DomUtil.create("span");
          colThreeText.innerHTML = layer?.measure ? ")" : "";
          columnOne.appendChild(coloneText);
          columnOne.appendChild(coltwoText);
          columnOne.appendChild(colThreeText);
          columnOne.onclick = handleUnitConverter;
          columnOne.onmouseover = setUnitTitle;
          unitsDiv.appendChild(columnOne);

          rowDiv.className = "rowLegend legend-range";
          const columnTwo = L.DomUtil.create("div");
          columnTwo.className = "columnLegend legend-middle";
          const colTwoText = L.DomUtil.create("p");
          colTwoText.id = "minValue";
          colTwoText.innerHTML =
            Array.isArray(layer?.palette) === false ||
            layer?.palette?.length !== 0
              ? layer?.min.toLocaleString()
              : `min : ${layer?.min.toLocaleString()}`;
          colTwoText.className = "legend-text";
          columnTwo.appendChild(colTwoText);
          rowDiv.appendChild(columnTwo);

          if (
            Array.isArray(layer?.palette) === false ||
            layer?.palette?.length !== 0
          ) {
            const columnThree = L.DomUtil.create("div");
            columnThree.className = `legend-graphic columnLegend legend-right ${layer?.image}`;
            columnThree.style.background = layer?.palette && backgroundColor;
            rowDiv.appendChild(columnThree);
          }
          const columnFour = L.DomUtil.create("div");
          columnFour.className = "columnLegend";
          const colFourText = L.DomUtil.create("p");
          colFourText.id = "maxValue";
          colFourText.innerHTML =
            Array.isArray(layer?.palette) === false ||
            layer?.palette?.length !== 0
              ? layer?.max.toLocaleString()
              : `max : ${layer?.max.toLocaleString()}`;
          // colFourText.innerHTML = layer?.max
          colFourText.className = "legend-text";
          columnFour.appendChild(colFourText);
          rowDiv.appendChild(columnFour);

          containerDiv.appendChild(dataSourceDiv);
          containerDiv.appendChild(rowDiv);
          containerDiv.appendChild(unitsDiv);
          parentDiv.appendChild(containerDiv);
          return parentDiv;
        };
        if (layer && !_.isEmpty(legend) && !_.isEmpty(map)) {
          /* eslint no-underscore-dangle: 0 */
          if (!_.isEmpty(map?._layers)) {
            legend?.addTo(map);
          }
        }
      } else if (layer) {
        const image = document.getElementById(id || "legend_div");
        legend.onRemove = () => {
          if (image) {
            L.DomUtil.remove(image);
          }
        };
        legend.onRemove();
      }
    }
  }, [add, map, layer]);
  return null;
}

export default Legend;
