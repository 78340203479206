import React, { useRef, useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { thousandSepartor, monthsData } from "../../helpers";

function HealthTable({
  data,
  // tabIndex,
  allFieldsData,
  selectedModule,
  colSpan,
  EWRawDataDates,
  showHospitalData,
  showDeathsdataData,
  // EWRawDataDates,
}) {
  const { t } = useTranslation();
  const wrapRef1 = useRef(null);
  const wrapRef2 = useRef(null);
  const ref = useRef(null);

  const handleScroll = (e) => {
    const targetDiv = e.target;

    if (targetDiv === wrapRef1.current && wrapRef2.current) {
      wrapRef2.current.scrollLeft = targetDiv.scrollLeft;
    } else if (targetDiv === wrapRef2.current && wrapRef1.current) {
      wrapRef1.current.scrollLeft = targetDiv.scrollLeft;
    }
  };

  useEffect(() => {
    const width = ref.current ? ref.current.offsetWidth : 0;
    const divTwo = document.getElementById("secondDiv");
    divTwo.style.width = `${width}px`;
  }, [ref.current, data?.tableData, showHospitalData, showDeathsdataData]);

  return (
    <div className="health-table-div">
      <div
        ref={wrapRef1}
        className="wrapper1"
        onScroll={(e) => handleScroll(e)}
      >
        <div id="secondDiv" className="div1" />
      </div>
      <div
        className="health-table-scroll"
        ref={wrapRef2}
        onScroll={(e) => handleScroll(e)}
      >
        <Table className="health-table">
          <thead className="thead-health-main" ref={ref}>
            <tr>
              <th className="border-right border-white">
                {t("HEALTH.REGION")}
              </th>
              {allFieldsData.isShowAllYears
                ? data.tableData.map((element, index) => {
                    if (index === 0) {
                      return element.data.map((item) => {
                        return (
                          <th
                            colSpan={colSpan}
                            key={item.year}
                            className="border-right border-white"
                          >
                            {moment(new Date(`${item.year}`)).format("YYYY")}{" "}
                          </th>
                        );
                      });
                    }
                    return "";
                  })
                : data.tableData.map((element, index) => {
                    if (index === 0) {
                      return element.data.map((item) => {
                        return (
                          <th
                            colSpan={colSpan}
                            key={item.year}
                            className="border-right border-white"
                          >
                            {!EWRawDataDates?.is_monthly &&
                            !EWRawDataDates?.is_daily
                              ? moment(new Date(`${item.year}`)).format("YYYY")
                              : moment(
                                  new Date(`${item.year}/${item.month}`),
                                ).format("YYYY-MMM")}{" "}
                          </th>
                        );
                      });
                    }
                    return "";
                  })}
            </tr>
          </thead>
          <tbody>
            <tr className="health-tr">
              <th className="border-right border-white">-</th>
              {allFieldsData.isShowAllYears
                ? data.tableData.map((element, index) => {
                    if (index === 0) {
                      return element.data.map(() => {
                        if (selectedModule === "health") {
                          return (
                            <>
                              <th>{t("HEALTH.NO_OF_CASES")}</th>
                              {showHospitalData && (
                                <th>{t("HEALTH.NO_OF_HOSP_CASES")}</th>
                              )}
                              {showDeathsdataData && (
                                <th className="border-right border-white">
                                  {t("HEALTH.NO_OF_DEATHS")}
                                </th>
                              )}
                            </>
                          );
                        } else {
                          return (
                            <>
                              {/* <th>{t("NUTRITION.TOTAL_CHILDREN")}</th> */}
                              <th>
                                {t("NUTRITION.STUNTING")} {t("NUTRITION.%")}
                              </th>
                              <th>
                                {t("NUTRITION.UNDERWEIGHT")} {t("NUTRITION.%")}
                              </th>
                              <th className="border-right border-white">
                                {t("NUTRITION.WASTING")} {t("NUTRITION.%")}
                              </th>
                            </>
                          );
                        }
                      });
                    }
                    return false;
                  })
                : selectedModule !== "market"
                ? data.tableData.map((element, index) => {
                    if (index === 0) {
                      return element.data.map(() => {
                        if (selectedModule === "health") {
                          return (
                            <>
                              <th>{t("HEALTH.NO_OF_CASES")} </th>
                              {showHospitalData && (
                                <th>{t("HEALTH.NO_OF_HOSP_CASES")} </th>
                              )}
                              {showDeathsdataData && (
                                <th className="border-right border-white">
                                  {t("HEALTH.NO_OF_DEATHS")}
                                </th>
                              )}
                            </>
                          );
                        } else {
                          return (
                            <>
                              {/* <th>{t("NUTRITION.TOTAL_CHILDREN")}</th> */}
                              <th>{t("NUTRITION.STUNTING")}</th>
                              <th>{t("NUTRITION.UNDERWEIGHT")}</th>
                              <th>{t("NUTRITION.WASTING")}</th>
                            </>
                          );
                        }
                      });
                    }
                    return "";
                  })
                : monthsData.map((month) => {
                    if (selectedModule === "health") {
                      return (
                        <>
                          <th key={month}>{t("HEALTH.NO_OF_CASES")} </th>
                          <th>{t("HEALTH.NO_OF_HOSP_CASES")} </th>
                          <th className="border-right border-white">
                            {t("HEALTH.NO_OF_DEATHS")}
                          </th>
                        </>
                      );
                    } else {
                      return (
                        <>
                          {/* <th>{t("NUTRITION.TOTAL_CHILDREN")}</th> */}
                          <th>{t("NUTRITION.STUNTING")}</th>
                          <th>{t("NUTRITION.UNDERWEIGHT")}</th>
                          <th className="border-right border-white">
                            {t("NUTRITION.WASTING")}
                          </th>
                        </>
                      );
                    }
                  })}
            </tr>

            {data.tableData.map((element) => {
              if (selectedModule === "health") {
                return (
                  <tr key={element}>
                    <td className="border-right border-white">
                      {element.state_name}
                    </td>
                    {element.data.map((item) => {
                      return (
                        <>
                          <td
                            style={{
                              backgroundColor: item?.no_of_case_thresold || "",
                            }}
                            className="border-right border-white"
                          >
                            {thousandSepartor(item.no_of_case)}
                          </td>
                          {showHospitalData && (
                            <td className="border-right border-white">
                              {thousandSepartor(item.hospitalized_case)}
                            </td>
                          )}
                          {showDeathsdataData && (
                            <td className="border-right border-white">
                              {thousandSepartor(item.deaths)}
                            </td>
                          )}
                        </>
                      );
                    })}
                  </tr>
                );
              } else {
                return (
                  <tr key={element}>
                    <td>{element.state_name}</td>
                    {element.data.map((item) => {
                      return (
                        <>
                          {/* <td className="border-right border-white">
                            {item.total_children}
                          </td> */}
                          <td
                            className="border-right border-white"
                            style={{
                              backgroundColor: item?.stunting_thresold || "",
                            }}
                          >
                            {item.stunting}
                          </td>
                          <td
                            className="border-right border-white"
                            style={{
                              backgroundColor: item?.underweight_thresold || "",
                            }}
                          >
                            {item.underweight}
                          </td>
                          <td
                            className="border-right border-white"
                            style={{
                              backgroundColor: item?.wasting_thresold || "",
                            }}
                          >
                            {item.wasting}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                );
              }
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default React.memo(HealthTable);
