import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useOutletContext } from "react-router-dom";
import ICON from "../../assest/img/empy-img.png";
import EditIcon from "../../assest/img/readynessEdit.png";
import {
  getReadynessPhaseRequest,
  userDetailsRequest,
  currencyListingRequest,
  coreRequest,
} from "../../store/actions";
import ViewIcon from "../../assest/img/readynessView.png";
import {
  userDetails,
  isPermissionsMatch,
  superAdmin,
} from "../../components/Common/Utils";
import { CHANGE_BUDGET, GENERATE_REPORT } from "../../constants";
import CurrencyModal from "./CurrencyModal";

function EarlyFinancePhase() {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [, setOpen] = useOutletContext(); // for closing side bar when it is opening
  const [, setLocationState] = useState();
  const [interventationList, setInterventationList] = useState("");
  const [permissionList, setPermissionList] = useState([]);
  const [defaultCurrncy, setDefaultCurrency] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [isCurrencyModal, setIsCurrencyModal] = useState(false);

  const data = JSON.parse(localStorage.getItem("projectDetails"));

  const userData = userDetails();

  const params = new URLSearchParams(window.location.search);
  const isFinance = params.get("isFinance");
  const selectedSubModule = params.get("slug");

  useEffect(() => {
    setLocationState(data);
    dispatch(userDetailsRequest({ id: userData?.user_details?.[0]?.id }));
    dispatch(
      coreRequest({
        location_type: "country",
      }),
    );
    dispatch(currencyListingRequest({}));
    data?.ef_currency_id ? setIsCurrencyModal(false) : setIsCurrencyModal(true);
    setOpen(true);
    window.scrollTo(0, 0);
  }, []);

  const nextProps = useSelector((state) => ({
    interventionList: state?.EAReadynessReducer?.interventionList,
    deleteInterventionMessage: state?.EAReadynessReducer?.deleteIntervention,
    userDetails: state.User?.userDetails,
    countriesList: state.Core?.coreList,
    currency: state?.EaProjects?.currencyList,
  }));

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (nextProps?.interventionList?.length) {
      setInterventationList(nextProps?.interventionList);
    }
  }, [nextProps.interventionList]);
  const isPermissionRef = useRef(true);
  useEffect(() => {
    if (isPermissionRef.current) {
      isPermissionRef.current = false;
      return;
    }
    if (nextProps?.userDetails && Object?.keys(nextProps?.userDetails).length) {
      setPermissionList(nextProps?.userDetails?.user_permissions);
      dispatch(
        getReadynessPhaseRequest({
          module: "early_finance",
          project_id: data?.id,
          data: 0,
        }),
      );
    } else if (nextProps.userDetails?.length === 0) {
      setPermissionList([]);
      dispatch(
        getReadynessPhaseRequest({
          module: "early_finance",
          project_id: data?.id,
          data: 0,
        }),
      );
    }
  }, [nextProps.userDetails]);

  nextProps.climberToolsLits;
  const isOtherTools = useRef(true);
  useEffect(() => {
    if (isOtherTools.current) {
      isOtherTools.current = false;
      return;
    }
    if (nextProps.countriesList) {
      const selectedCountry = nextProps.countriesList.find(
        (each) => each.id === data.country_id,
      );
      setDefaultCurrency(selectedCountry);
    }
  }, [nextProps.countriesList]);

  return (
    <>
      <div className="EA-Header ea-space-between">
        <div>
          <BsArrowLeft
            className="back-icon-ea"
            onClick={() =>
              history(
                isFinance
                  ? `/ea-projects?isFinance=true&slug=${selectedSubModule}`
                  : "/preparedness-phase?isDefault=true&slug=early_action",
              )
            }
            role="button"
          />
          <p className="ea-header-text">{t("EARLY_FINANCE.EARLY_FINANCE")}</p>
        </div>
        {isPermissionsMatch(permissionList, GENERATE_REPORT) || superAdmin() ? (
          <Button
            type="button"
            className="generate-report-btn"
            onClick={() =>
              history(
                isFinance
                  ? `/early-finance-report?isFinance=true&slug=${selectedSubModule}`
                  : `/early-finance-report?slug=${selectedSubModule}`,
              )
            }
          >
            {t("EARLY_FINANCE.GENERATE_REPORT")}
          </Button>
        ) : (
          ""
        )}
      </div>
      <div className="ea-project-container">
        <div className="ea-space ea-space-b">
          <div className="readiness-div">
            <div
              className={
                interventationList?.length
                  ? "readinness-heading"
                  : "readinness-heading-no-data"
              }
            >
              <h2 className="readiness-h2">
                {" "}
                {t("EARLY_FINANCE.INTERVENTION")}
              </h2>
            </div>
            <Row className="pred-row">
              {Object?.entries(interventationList).map(([, value]) => {
                return (
                  <Col lg={4} className="read-col">
                    <div
                      className="readiness-project-card pointer"
                      role="button"
                      tabIndex="0"
                      onKeyUp={() => {}}
                    >
                      <div className="project-card-section-1">
                        <img
                          src={value?.icon_url ? value?.icon_url : ICON}
                          alt=""
                          className="mt-2 risk-card-image"
                        />
                        <p className="intervation-card-text mt-3 mb-2">
                          {value?.name}
                        </p>
                        <h3 className="ef-total-budget">
                          {t("EARLY_FINANCE.TOTAL_BUDGET")} :{" "}
                          {data?.currency_symbol}{" "}
                          {value?.budget?.total_budget?.toLocaleString()}{" "}
                        </h3>
                      </div>
                      <div className="project-card-section-2">
                        <div className="card-showing">
                          <div className="">
                            <img
                              src={ViewIcon}
                              alt="delete"
                              onKeyDown={() => {}}
                              onClick={() =>
                                history(
                                  isFinance
                                    ? `/view-finance?isFinance=true&interventionId=${value.id}&slug=${selectedSubModule}`
                                    : `/view-finance?interventionId=${value.id}&slug=${selectedSubModule}`,
                                )
                              }
                            />
                          </div>
                          {isPermissionsMatch(permissionList, CHANGE_BUDGET) ||
                          superAdmin() ? (
                            <div className="ml-4 mr-4">
                              <img
                                src={EditIcon}
                                alt="delete"
                                onKeyDown={() => {}}
                                onClick={() =>
                                  history(
                                    isFinance
                                      ? `/ef-finance??isFinance=true&interventionId=${
                                          value?.id
                                        }&default=${0}&name=${
                                          value.name
                                        }&slug=${selectedSubModule}`
                                      : `/ef-finance?interventionId=${
                                          value?.id
                                        }&default=${0}&name=${
                                          value.name
                                        }&slug=${selectedSubModule}`,
                                  )
                                }
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </div>
      {!isCurrencyModal ? (
        ""
      ) : (
        <CurrencyModal
          deleteModal={isCurrencyModal}
          byDefaultCurrncy={defaultCurrncy}
          currencyList={nextProps?.currency}
          setSelectedCurrency={setSelectedCurrency}
          selectedCurrency={selectedCurrency}
          projectDetails={data}
          toggle={() => setIsCurrencyModal(!isCurrencyModal)}
        />
      )}
    </>
  );
}
export default EarlyFinancePhase;
