import React, { useEffect } from "react";
import { useOutlet } from "react-router-dom";
import AwareHeader from "../../components/Common/AwareHeader";

function AwareLayout() {
  const Outlet = useOutlet();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="layout-div">
      <AwareHeader />
      <div className="second-container">{Outlet}</div>
    </div>
  );
}

export default AwareLayout;
