import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import Cookies from "js-cookie";
import i18n from "../../i18n";
// Login Redux States
import READYNESSPHASE_PHASE_CONST from "./actionTypes";
import {
  editReadynessContentError,
  editReadynessContentSuccess,
  createReadynessContentSuccess,
  createReadynessContentError,
  deleteReadynessContentSuccess,
  deleteReadynessContentError,
  getReadynessPhaseError,
  getReadynessPhaseSuccess,
  deleteInterventionError,
  deleteInterventionSuccess,
} from "./actions";
import { EARLY_ACTION_API, AXIOS_INSTANCE } from "../apiUtils/config";
import { showError, showSuccess } from "../../components/Common/Notification";
import baseMethod from "../../components/Common/BaseMethod";
import checkHttpStatus from "../apiUtils";

// crate readyness

const createContentReayNessApiRquest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(
      `${EARLY_ACTION_API}/rp/intervention/create`,
      payload,
    );

    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    if (error?.response && error?.response?.data?.message)
      showError(error.response.data.message);
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* createReadyNessPhaseData(action) {
  const { t } = i18n;
  if (Cookies.get("user")) {
    const userToken = JSON.parse(Cookies.get("user"))?.result?.token;
    AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  }
  try {
    const apiResponse = yield call(
      createContentReayNessApiRquest,
      action.payload,
    );

    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(createReadynessContentSuccess(responseData));
      showSuccess(t("VALIDATION.INTERVENTION_SUCCESS_MESSAGE"));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(createReadynessContentError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(createReadynessContentError(errorData));
  }
}

// delete reayness phase
const deleteReayNessApiRquest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.delete(
      `${EARLY_ACTION_API}/rp/intervention/delete/${payload?.project_id}/${payload?.slug}/${payload.content}`,
      payload,
    );

    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    if (error?.response && error?.response?.data?.message)
      showError(error.response.data.message);
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* deleteReadyNessPhaseData(action) {
  const { t } = i18n;
  if (Cookies.get("user")) {
    const userToken = JSON.parse(Cookies.get("user"))?.result?.token;
    AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  }
  try {
    const apiResponse = yield call(deleteReayNessApiRquest, action.payload);

    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      showSuccess(t("VALIDATION.INTERVENTION_SUCCESS_MESSAGE"));
      yield put(deleteReadynessContentSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(deleteReadynessContentError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteReadynessContentError(errorData));
  }
}

function* earlyActionReadynessPhase(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${EARLY_ACTION_API}/rp/intervention/get/${action?.payload?.project_id}/${action?.payload?.module}/${action.payload.data}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getReadynessPhaseSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getReadynessPhaseError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getReadynessPhaseError(errorData));
  }
}

// delete intervention
function* deleteInterventionSaga(action) {
  const { t } = i18n;
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.delete(
        `${EARLY_ACTION_API}/rp/intervention/delete/${action?.payload?.intervention_id}`,
      ),
      `${t("VALIDATION.INTERVENTION_DELETE_SUCESS_MESSAGE")}`,
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(deleteInterventionSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(deleteInterventionError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteInterventionError(errorData));
  }
}

// edit  intervention
function* editReadyNessPhaseData(action) {
  const { t } = i18n;
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.put(
        `${EARLY_ACTION_API}/rp/intervention/manage`,
        action.payload,
      ),
      action.payload.comment || action.payload.ef_comment
        ? ""
        : `${t("EARLY_ACTIONS.DATA_UPDATED_SUCESSFULLY")}`,
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(editReadynessContentSuccess(responseData));
    } else {
      const responseData = {
        data: response.data,
      };
      yield put(editReadynessContentError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(editReadynessContentError(errorData));
  }
}

export function* watchPreparednessPhase() {
  yield takeEvery(
    READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_EDIT_REQUEST,
    editReadyNessPhaseData,
  );
  yield takeEvery(
    READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_CREATE_REQUEST,
    createReadyNessPhaseData,
  );
  yield takeEvery(
    READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_DELETE_REQUEST,
    deleteReadyNessPhaseData,
  );
  yield takeEvery(
    READYNESSPHASE_PHASE_CONST.GET_READYNESS_INTERVENTIONS_LIST_REQUEST,
    earlyActionReadynessPhase,
  );
  yield takeEvery(
    READYNESSPHASE_PHASE_CONST.INTERVENTION_DELETE_REQUEST,
    deleteInterventionSaga,
  );
}

function* EarlyActionReaynessPhaseSaga() {
  yield all([fork(watchPreparednessPhase)]);
}

export default EarlyActionReaynessPhaseSaga;
