import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Highcharts from "highcharts";
import ClipLoader from "react-spinners/ClipLoader";
import DashboardFilter from "./DashboardFilter";
import IndicatorCard from "./IndicatorCard";
import AlterMap from "./AlertMap";
import AlertTable from "./AlertTable";
import AlertGraph from "./AlertGraph";
import {
  EARLY_ACTION_SLUG,
  ExtremeRainfall,
  Sum,
  AccumulatedRainfall,
  Idsi,
  Smci,
  DrysPell,
  FloodEsa,
  OPEN_WEATHER_PARAMETERS_CONST,
  DATE_FORMAT,
  DATA_SOURCES,
  Colors,
} from "../../constants";
import hourglassWithTick from "../../assest/img/hourglassWithTick.png";
import {
  getIRIAvailableDatesDataRequest,
  addFloodRequest,
  getWeatherForecastData,
  getGeoglowsDrainageRequest,
  getGeoglowsCatchmentRequest,
  taluksBoundaryRequest,
} from "../../store/actions";
import {
  handleForecastLeadMonthFormat,
  handleForecastLeadTimeFormat,
  handleOpenWeatherCompareRes,
  OpenWeatherChartLabels,
  renderChartTime,
} from "../../components/Common/Utils";
import { dateFormatter } from "../../helpers";
import {
  AXIOS_INSTANCE_LOADER,
  EARLY_WARNING_API,
} from "../../store/apiUtils/config";
import { showError } from "../../components/Common/Notification";

function AlertDashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [, setOpen] = useOutletContext();
  const dispatch = useDispatch();

  const wrap = useRef([]);
  const layerRef = useRef([]);

  const [initialData, setInitialData] = useState({
    country: {},
    region: null,
    district: null,
    risk: {},
    indicators: [],
    selectedIndicator: "",
    pointer: [],
  });

  const [mapLayers, setMapLayers] = useState([]);
  const [loader, setLoader] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [mapPreferences, setMapPreferences] = useState({
    districts_or_cities: { layer: useRef(), color: "", data: "", country: "" },
  });

  const defaultSelectedCountry = JSON.parse(
    localStorage.getItem("selectedCountry"),
  );
  const [initialRender, setInitialRender] = useState(true);
  const [mapes, setMapes] = useState();
  const [position, setPosition] = useState([
    defaultSelectedCountry.latitude,
    defaultSelectedCountry.longitude,
  ]);
  const [zooms, setZooms] = useState(defaultSelectedCountry.zoom_level);
  const [isShowTableView, setIsShowTableView] = useState(false);

  const [defaultOptions, setDefaultOptions] = useState([]);
  const [regionListOptions, setRegionListOptions] = useState([]);
  const [districtsListOptions, setDistrictsListOptions] = useState([]);
  const [riskListOptions, setRiskListOptions] = useState([]);
  const [indicatorListOptions, setIndicatorListOptions] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState("");
  const [layerData, setLayerData] = useState("");
  const [selectedMapLayer, setSelectedMapLayer] = useState("");
  const [legend, setLegend] = useState([]);
  const [forecastAvailableDates, setForecastAvailableDates] = useState([]);
  const [IRIAvailableYearList, setIRIAvailableYearList] = useState([]);
  const [IRIAvailableMonthList, setIRIAvailableMonthList] = useState([]);
  const [IRIAvailableLeadTimeList, setIRIAvailableLeadTimeList] = useState([]);
  const [layersArray, setLayersArray] = useState([]); // for geo glows
  const [column, setColumn] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isShowGraph, setIsShowGraph] = useState(false);
  const [graphStartDate, setGraphStartDate] = useState();
  const [graphEndDate, setGraphEndDate] = useState();
  const [minAndMaxDate, setMinAndMaxDate] = useState({
    maxDate: "",
    minDate: "",
  });
  const [errorData, setErrorData] = useState({
    showError: false,
    message: "",
  });
  const [IRIForecastTime, setIRIForecastTime] = useState({
    year: null,
    month: null,
    lead_time: null,
  });
  const [seriesData, setSeriseData] = useState();
  const nextProps = useSelector((state) => ({
    maps: state.Drought.floodList,
    updatedSlug: state?.Drought?.dashboardSlug,
    Weather: state.Weather,
    geoglowsDrinageData: state.Geoglows.geoglowsDrinageData,
    geoglowsCatchmentData: state.Geoglows.geoglowsCatchmentData,
    droughtListLoading: state.Drought.floodVisualizationLoading,
    geoGlowsList: state.AlertDashboard.alertGeoglowsList,
    taluksBoundry: state?.Boundry?.taluksBoundry,
  }));
  const [indicatorsData, setIndicatorsData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [openweatherSeriseData, setOpenweatherSeriseData] = useState();
  const [forecastDate, setForecastDate] = useState();

  const removeLayers = (selectedParamRef) => {
    if (
      wrap &&
      wrap?.current &&
      selectedParamRef &&
      selectedParamRef?.current
    ) {
      const map1 = wrap.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer) => map1.removeLayer(layer));
    }
  };

  // for removing map layer when clicked on apply layer
  const removeMapLayers = (selectedParamLegendData) => {
    let previousParamRef = "";
    if (layerRef?.current?.length) {
      layerRef.current.map((i, index) => {
        previousParamRef = layerRef.current[index];
        removeLayers(previousParamRef);
        return null;
      });
    }
    const legendArray = [...selectedParamLegendData];

    if (selectedParamLegendData.length) {
      legendArray.forEach((value) => {
        if (value.add) {
          value.add = false;
        }
      });
    }

    setLegend(legendArray);
  };

  const isTaluksBoundry = useRef(true);
  useEffect(() => {
    if (isTaluksBoundry.current) {
      isTaluksBoundry.current = false;
      return;
    }
    if (nextProps.taluksBoundry?.data) {
      const selectedLayer = mapPreferences.districts_or_cities;
      selectedLayer?.layer?.current?.clearLayers();
      selectedLayer.country = initialData?.country;
      selectedLayer.layer.current.addData(nextProps.taluksBoundry?.data);
      selectedLayer.color = "#964B00";
    }
  }, [nextProps.taluksBoundry]);

  // for applying map layer
  const onHandleMap = () => {
    removeMapLayers(legend);
    setLayersArray([]);
    const startDate = moment(selectedParameter?.end_date)
      .subtract(30, "days")
      .format(DATE_FORMAT);

    const data = {};
    data.loader = true;
    data.api_url = selectedParameter.api_url;
    data.country_name = initialData?.country?.label;
    data.state_name =
      initialData?.region?.value !== "" ? initialData?.region?.label : "";
    data.district_name =
      initialData?.district?.value !== "" ? initialData?.district?.label : "";
    data.temporal_aggregation =
      selectedParameter?.indices_slug === ExtremeRainfall ? Sum : "mean";
    data.start_date = dateFormatter(startDate);
    data.end_date = selectedParameter.end_date;
    data.weather_forecast_source_id = selectedParameter?.indices_id;
    if (selectedParameter?.slug === "iri") {
      data.forecast_time_year = IRIForecastTime?.year?.value;
      data.forecast_time_month =
        IRIForecastTime?.month?.id < 9
          ? `0${IRIForecastTime?.month?.id}`
          : IRIForecastTime?.month?.id;
      data.forecast_lead_time = parseInt(
        IRIForecastTime?.lead_time?.value?.substr(
          IRIForecastTime?.lead_time?.value?.indexOf("(") + 1,
          IRIForecastTime?.lead_time?.value?.indexOf(")") -
            IRIForecastTime?.lead_time?.value?.indexOf("(") -
            1,
        ),
        10,
      );
    }
    if (
      [AccumulatedRainfall, Idsi, Smci].includes(
        selectedParameter?.indices_slug,
      )
    ) {
      // data.min = "";
    }
    if (
      [AccumulatedRainfall, Idsi, Smci].includes(
        selectedParameter?.indices_slug,
      )
    ) {
      // data.max = "";
    }
    if ([DrysPell, ExtremeRainfall].includes(selectedParameter?.indices_slug)) {
      data.precipitation_threshold = "2.5";
    }
    if (selectedParameter?.indices_slug === FloodEsa) {
      data.precipitation_threshold = "2.5";
    }
    if (selectedParameter?.slug === "iri") {
      dispatch(getWeatherForecastData(data));
    } else if (selectedParameter?.slug === "open_weather") {
      const layer = OPEN_WEATHER_PARAMETERS_CONST.find(
        (layerObj) => layerObj?.slug === "precipitation",
      );
      const response = handleOpenWeatherCompareRes(layer);

      if (!_.isEmpty(response)) {
        // eslint-disable-next-line no-shadow
        const { data } = response;

        setLayerData(data.result?.[0]?.Open_Weather?.[0]);
        setSelectedMapLayer("open_weather");
      }
    } else if (selectedParameter?.slug === "geo_glows") {
      dispatch(
        getGeoglowsDrainageRequest({ country_id: initialData?.country?.value }),
      );
      dispatch(
        getGeoglowsCatchmentRequest({
          country_id: initialData?.country?.value,
        }),
      );
    } else {
      data.slug = selectedParameter?.slug;
      dispatch(addFloodRequest(data, selectedParameter.api_url));
    }
  };

  useEffect(() => {
    if (nextProps.maps) {
      setLayerData(nextProps.maps);
      setSelectedMapLayer(nextProps?.updatedSlug);
      setLegend([]);
    }
  }, [nextProps.maps]);

  const geoglowsSeriesData = (res) => {
    if (!_.isEmpty(res?.data?.result)) {
      const seriseMax = {
        name: "Max Flow",
        type: "area",
        dashStyle: "dash",
        color: "#FFA500",
        fillColor: "#FFD27F",
        marker: {
          enabled: false,
        },
        legend: {
          grouped: false,
        },
      };

      const seriseMin = {
        name: `Min Flow`,
        type: "area",
        dashStyle: "dash",
        color: "#008080",
        fillColor: "#80CCCC",
        marker: {
          enabled: false,
        },
        legend: {
          grouped: false,
        },
      };

      const seriseFlow75 = {
        name: `75% Flow`,
        type: "area",
        dashStyle: "dash",
        fillColor: "#8FBC8F",
        color: "#2E8B57",
        marker: {
          enabled: false,
        },
      };

      const seriseFlow25 = {
        name: "25% Flow",
        type: "area",
        dashStyle: "dash",
        fillColor: "#90EE90",
        color: "#006400",
        marker: {
          enabled: false,
        },
      };

      const seriseHistory = {
        name: t("GEOGLOWS.HISTORICAL_AVERAGE_FLOW"),
        type: "line",
        color: "red",
        marker: {
          enabled: false,
        },
        visible: false,
      };

      const seriseFlowCast = {
        name: "Forecasted Flow", // FORECASTED_FLOW
        type: "line",
        color: "rgb(0,0,255)",
        marker: {
          enabled: false,
        },
      };
      const data = res.data.result.graph_data?.forecast_flow;
      const returnPeriodsData = Object.keys(
        res.data.result.graph_data.return_period,
      )
        .map((key) => {
          // const yearValue = key.split("_")[2];
          return {
            year: Number(key),
            label: `${key} Years`,
            value: Math.trunc(res.data.result.graph_data.return_period[key]),
          };
        })
        .sort((a, b) => a.year - b.year);

      const ts = data;

      const dt_rv = ts?.date_time;

      const traceData1 = [];
      const datamax = [];
      const datamin = [];
      const dataFlow25 = [];
      const dataFlow75 = [];
      const dataCastFlow = [];
      const historyFlow = [];
      const maxList = ts.flow_max;
      const minList = ts.flow_min;
      const flowList25 = ts.flow_25p;
      const flowList75 = ts.flow_75p;
      dt_rv.map((eachvalue, index) => {
        datamax.push({
          x: new Date(eachvalue).getTime(),
          y: parseFloat(maxList[index].toFixed(5)),
        });
        return null;
      });

      // min list
      dt_rv.map((eachvalue, index) => {
        datamin.push({
          x: new Date(eachvalue).getTime(),
          y: parseFloat(minList[index].toFixed(5)),
        });
        return null;
      });

      // 25 flow
      dt_rv.map((eachvalue, index) => {
        dataFlow25.push({
          x: new Date(eachvalue).getTime(),
          y: parseFloat(flowList25[index].toFixed(5)),
        });
        return null;
      });

      // 75 flow
      dt_rv.map((eachvalue, index) => {
        dataFlow75.push({
          x: new Date(eachvalue).getTime(),
          y: parseFloat(flowList75[index].toFixed(5)),
        });
        return null;
      });

      // fore cast flow
      ts?.date_time.map((eachvalue, index) => {
        dataCastFlow.push({
          x: new Date(eachvalue).getTime(),
          y: parseFloat(ts.flow_avg[index].toFixed(5)),
        });
        return null;
      });

      seriseMax.data = datamax;
      seriseFlow25.data = dataFlow25;
      seriseFlowCast.data = dataCastFlow;
      seriseHistory.data = historyFlow;
      seriseMin.data = datamin;
      seriseFlow75.data = dataFlow75;

      traceData1.push(seriseMax);
      traceData1.push(seriseMin);
      traceData1.push(seriseFlow25);
      traceData1.push(seriseFlow75);
      traceData1.push(seriseFlowCast);
      traceData1.push(seriseHistory);

      const traces2 = [];
      const returntrance2 = [];
      if (returnPeriodsData?.length) {
        returnPeriodsData.forEach((eachItem, index) => {
          const returnSerise = {
            name: `${eachItem.label}: ${Math.round(eachItem.value)}`,
            type: "areaspline",
            color: Colors[index],
            dashStyle: "LongDash",
            events: {
              legendItemClick() {
                return false;
              },
            },
            fillColor: {
              linearGradient: [0, 0, 0, 360],
              stops: [
                [0, Colors[index]],
                [
                  1,
                  Highcharts.color(Highcharts.getOptions().colors[0])
                    .setOpacity(0)
                    .get("rgba"),
                ],
              ],
            },
            stack: "bought",
            visible: true,
          };
          const returndata = [];
          if (eachItem) {
            traces2.push({
              name: `${eachItem.label}: ${Math.round(eachItem.value)}`,
              legend: {
                grouped: true,
              },
              type: "area",
            });
            ts.date_time.map((eachvalue) => {
              returndata.push({
                x: new Date(eachvalue).getTime(),
                y: parseFloat(eachItem.value.toFixed(5)),
              });
              return null;
            });
            returnSerise.data = returndata;
            returntrance2.push(returnSerise);
          }
        });
        const returnPeriodAll = {
          name: `${t(
            "GEOGLOWS.RETURN_PERIODS_ALL",
          )}<br/><span style="font-size: 9px; color: #666; font-weight: normal">(${t(
            "GEOGLOWS.CLICK_HERE",
          )})</span>`,
          data: [],
          type: "scatter",
          visible: true,
          marker: {
            radius: 0,
            enabled: false,
          },
          events: {
            legendItemClick() {
              // eslint-disable-next-line react/no-this-in-sfc
              const chart = this.chart;
              const series1 = chart.series;
              series1.forEach((series) => {
                if (series.userOptions.stack === "bought") {
                  // eslint-disable-next-line react/no-this-in-sfc
                  series.setVisible(!this.visible);
                }
              });
            },
          },
        };
        returntrance2.push(returnPeriodAll);
      }

      const traceData2 = traceData1.concat(returntrance2.reverse());

      setSeriseData(traceData2);
      setErrorData({
        showError: false,
        message: "",
      });
    } else {
      setIsShowGraph(true);
      setErrorData({
        showError: true,
        message: "Data is not available",
      });
    }
  };
  const onHandelIndicator = (
    indicatorData,
    startDate,
    endDate,
    forecast_date,
  ) => {
    if (!_.isEmpty(indicatorData)) {
      setErrorData((prev) => {
        return {
          ...prev,
          showError: false,
          message: "",
        };
      });

      setSelectedParameter(indicatorData);
      setOpenweatherSeriseData();
      setGraphData();
      setSeriseData();
      setIsShowGraph(false);
      if (!_.isEmpty(indicatorData)) {
        const indicatorurl = indicatorData?.api_url;
        const timeSeriesData = {};
        const pointerLat = initialData?.pointer[0]?.latlngs[0];
        const pointerLong = initialData?.pointer[0]?.latlngs[1];
        if (pointerLat) {
          timeSeriesData.latitude = pointerLat;
          timeSeriesData.longitude = pointerLong;
        }
        setInitialData((prev) => {
          return {
            ...prev,
            selectedIndicator: indicatorData.label,
          };
        });
        if (indicatorData?.slug === "iri") {
          const promiseArray = [];
          IRIAvailableLeadTimeList.forEach((ele, i) => {
            timeSeriesData.api_url = indicatorData?.api_url;
            timeSeriesData.country_name = initialData?.country?.label;
            timeSeriesData.state_name = initialData?.region?.label;
            timeSeriesData.district_name = initialData?.district?.label;
            timeSeriesData.basin_name = "";
            timeSeriesData.sub_basin_name = "";
            timeSeriesData.spatial_aggregation = "mean";
            timeSeriesData.forecast_time_year = IRIForecastTime?.year?.value;
            timeSeriesData.category = initialData?.risk?.value;
            timeSeriesData.forecast_time_month =
              IRIForecastTime?.month?.id < 9
                ? `0${IRIForecastTime?.month?.id}`
                : IRIForecastTime?.month?.id;
            timeSeriesData.forecast_lead_time = i + 1;

            promiseArray.push(
              AXIOS_INSTANCE_LOADER.post(
                `${EARLY_WARNING_API}/${indicatorurl}timeseries`,
                timeSeriesData,
              ),
            );
          });
          Promise.all(promiseArray)
            .then((response) => {
              const data = [];
              const millis = [];
              response.forEach((obj) => {
                data.push(obj.data.result.graph_data.data[0]);
                millis.push(obj.data.result.graph_data.millis[0]);
              });
              const graph_data = {
                data,
                millis,
              };

              setGraphData({ graph_data });
              setErrorData({
                showError: false,
                message: "",
              });
            })
            .catch((err) => {
              const errorMessage = _.get(err, "response.data.message");
              if (errorMessage?.length) {
                showError(errorMessage);
                setIsShowGraph(true);
                setErrorData({
                  showError: true,
                  message: errorMessage,
                });
              }
            });
        } else if (indicatorData?.slug === "geo_glows") {
          const date =
            forecast_date || moment().subtract(1, "days").format(DATE_FORMAT);
          // .split("-")
          // .join("");

          AXIOS_INSTANCE_LOADER.get(
            `${EARLY_WARNING_API}/early_warnings/geo_glows_alert_data/${
              pointerLat || initialData?.district?.latitude
            }/${pointerLong || initialData?.district?.longitude}/${date}`,
          )
            .then((res) => {
              setIsShowGraph(true);
              geoglowsSeriesData(res);
            })
            .catch((err) => {
              const errorMessage = _.get(err, "response.data.message");
              if (errorMessage?.length) {
                showError(errorMessage);
                setIsShowGraph(true);
                setErrorData({
                  showError: true,
                  message: errorMessage,
                });
              }
            });
        } else if (indicatorData?.slug === "open_weather") {
          timeSeriesData.api_url = indicatorData?.api_url;
          timeSeriesData.country_name = initialData?.country?.label;
          timeSeriesData.state_name = initialData?.region?.label;
          timeSeriesData.district_name = initialData?.district?.label;
          timeSeriesData.basin_name = "";
          timeSeriesData.sub_basin_name = "";
          timeSeriesData.spatial_aggregation = "mean";
          timeSeriesData.latitude = initialData?.district?.latitude;
          timeSeriesData.longitude = initialData?.district?.longitude;
          timeSeriesData.start_date = startDate || moment().format(DATE_FORMAT);
          timeSeriesData.end_date =
            endDate || moment().add(6, "days").format(DATE_FORMAT);
          timeSeriesData.weather_forecast_source_id =
            DATA_SOURCES.OPEN_WEATHER.id;
          AXIOS_INSTANCE_LOADER.post(
            `${EARLY_WARNING_API}/${indicatorurl.slice(0, -1)}`,
            timeSeriesData,
          )
            .then((res) => {
              if (!_.isEmpty(res?.data?.result?.[0])) {
                setOpenweatherSeriseData(
                  res?.data?.result?.[0].Open_Weather?.daily,
                );
                setErrorData({
                  showError: false,
                  message: "",
                });
              }
            })
            .catch((err) => {
              const errorMessage = _.get(err, "response.data.message");
              if (errorMessage?.length) {
                showError(errorMessage);
                setIsShowGraph(true);
                setErrorData({
                  showError: true,
                  message: errorMessage,
                });
              }
            });
        } else {
          timeSeriesData.api_url = indicatorData?.api_url;
          timeSeriesData.country_name = initialData?.country?.label;
          timeSeriesData.state_name = initialData?.region?.label;
          timeSeriesData.district_name = initialData?.district?.label;
          timeSeriesData.basin_name = "";
          timeSeriesData.sub_basin_name = "";
          timeSeriesData.spatial_aggregation = "mean";
          timeSeriesData.start_date =
            startDate ||
            moment(indicatorData.end_date)
              .subtract(30, "days")
              .format(DATE_FORMAT);
          timeSeriesData.end_date = endDate || indicatorData.end_date;

          AXIOS_INSTANCE_LOADER.post(
            `${EARLY_WARNING_API}/${indicatorurl}timeseries`,
            timeSeriesData,
          )
            .then((res) => {
              if (!_.isEmpty(res?.data?.result)) {
                setGraphData(res?.data?.result);
                setErrorData({
                  showError: false,
                  message: "",
                });
              }
            })
            .catch((err) => {
              const errorMessage = _.get(err, "response.data.message");
              if (errorMessage?.length) {
                showError(errorMessage);
                setIsShowGraph(true);
                setErrorData({
                  showError: true,
                  message: errorMessage,
                });
              }
            });
        }
      }
    }
  };
  useEffect(() => {
    setLoader(true);
    const MAP_API_URLS = [];
    if (
      !_.isEmpty(initialData?.indicators) &&
      !_.isEmpty(IRIAvailableLeadTimeList) &&
      initialData?.district?.latitude &&
      initialData?.district
    ) {
      setSelectedParameter("");
      initialData.indicators.forEach((item) => {
        const timeSeriesData = {};
        const pointerLat = initialData?.pointer[0]?.latlngs[0];
        const pointerLong = initialData?.pointer[0]?.latlngs[1];
        if (pointerLat) {
          timeSeriesData.latitude = pointerLat;
          timeSeriesData.longitude = pointerLong;
        }
        if (item?.slug === "iri") {
          const indicatorurl = item?.api_url;
          timeSeriesData.api_url = item?.api_url;
          timeSeriesData.country_name = initialData?.country?.label;
          timeSeriesData.state_name = initialData?.region?.label;
          timeSeriesData.district_name = initialData?.district?.label;
          timeSeriesData.basin_name = "";
          timeSeriesData.sub_basin_name = "";
          timeSeriesData.spatial_aggregation = "mean";
          timeSeriesData.category = initialData?.risk?.value;
          timeSeriesData.forecast_time_year = IRIForecastTime?.year?.value;
          timeSeriesData.forecast_time_month =
            IRIForecastTime?.month?.id < 9
              ? `0${IRIForecastTime?.month?.id}`
              : IRIForecastTime?.month?.id;
          timeSeriesData.forecast_lead_time = 1;

          MAP_API_URLS.push(
            AXIOS_INSTANCE_LOADER.post(
              `${EARLY_WARNING_API}/${indicatorurl}timeseries`,
              timeSeriesData,
            ),
          );
        } else if (item?.slug === "geo_glows") {
          const date = moment().subtract(1, "days").format(DATE_FORMAT);
          // .split("-")
          // .join("");

          MAP_API_URLS.push(
            AXIOS_INSTANCE_LOADER.get(
              `${EARLY_WARNING_API}/early_warnings/geo_glows_alert_data/${initialData?.district?.latitude}/${initialData?.district?.longitude}/${date}`,
            ),
          );
        } else if (item?.slug === "open_weather") {
          const indicatorurl = item?.api_url;
          timeSeriesData.api_url = item?.api_url;
          timeSeriesData.country_name = initialData?.country?.label;
          timeSeriesData.state_name = initialData?.region?.label;
          timeSeriesData.district_name = initialData?.district?.label;
          timeSeriesData.basin_name = "";
          timeSeriesData.sub_basin_name = "";
          timeSeriesData.spatial_aggregation = "mean";
          timeSeriesData.start_date = moment().format(DATE_FORMAT);
          timeSeriesData.end_date = moment().add(6, "days").format(DATE_FORMAT);
          timeSeriesData.latitude = initialData?.district?.latitude;
          timeSeriesData.longitude = initialData?.district?.longitude;
          timeSeriesData.weather_forecast_source_id =
            DATA_SOURCES.OPEN_WEATHER.id;
          MAP_API_URLS.push(
            AXIOS_INSTANCE_LOADER.post(
              `${EARLY_WARNING_API}/${indicatorurl.slice(0, -1)}`,
              timeSeriesData,
            ),
          );
        } else {
          const indicatorurl = item?.api_url;
          timeSeriesData.api_url = item?.api_url;
          timeSeriesData.country_name = initialData?.country?.label;
          timeSeriesData.state_name = initialData?.region?.label;
          timeSeriesData.district_name = initialData?.district?.label;
          timeSeriesData.basin_name = "";
          timeSeriesData.sub_basin_name = "";
          timeSeriesData.spatial_aggregation = "mean";
          timeSeriesData.start_date = moment(item.end_date)
            .subtract(30, "days")
            .format(DATE_FORMAT);
          timeSeriesData.end_date = item.end_date;
          MAP_API_URLS.push(
            AXIOS_INSTANCE_LOADER.post(
              `${EARLY_WARNING_API}/${indicatorurl}timeseries`,
              timeSeriesData,
            ),
          );
        }
      });
      Promise.resolve(MAP_API_URLS[0])
        .then((res) => {
          setIndicatorsData([res]);
          if (!_.isEmpty(initialData?.indicators))
            onHandelIndicator(initialData?.indicators[0]);
          return Promise.allSettled(MAP_API_URLS);
        })
        .then((response) => {
          setLoader(false);

          const newList = [];

          response?.map((each) =>
            each?.status === "fulfilled"
              ? newList?.push(each?.value)
              : showError(each?.reason?.response?.data?.message),
          );

          setIndicatorsData(newList);
        })
        .catch((err) => {
          setLoader(false);

          const errorMessage = _.get(err, "response.data.message");
          if (errorMessage?.length) {
            showError(errorMessage);
          }
        })
        .finally(() => {});
    }
  }, [initialData.indicators, IRIAvailableLeadTimeList, initialData?.district]);

  const onHandleZoom = (center, zoom) => {
    setPosition(center);
    setZooms(zoom);
  };

  const onHandlefilterzoom = (center, zoom) => {
    setPosition(center);
    setZooms(zoom);
  };

  useEffect(() => {
    if (nextProps?.geoglowsDrinageData) {
      layerRef.current.push({
        layer: nextProps?.geoglowsDrinageData?.map_url,
        name: `${t("GEOGLOWS.DRINAGE")}`,
      });
      setLayersArray((preArray) => [
        ...preArray,
        {
          layer: nextProps?.geoglowsDrinageData?.map_url,
          name: `${t("GEOGLOWS.DRINAGE")}`,
        },
      ]);
    }
  }, [nextProps?.geoglowsDrinageData]);

  useEffect(() => {
    if (nextProps?.geoglowsCatchmentData) {
      layerRef.current.push({
        layer: nextProps?.geoglowsCatchmentData?.map_url,
        name: `${t("GEOGLOWS.CATCHMENT")}`,
      });
      setLayersArray((preArray) => [
        ...preArray,
        {
          layer: nextProps?.geoglowsCatchmentData?.map_url,
          name: `${t("GEOGLOWS.CATCHMENT")}`,
        },
      ]);
    }
  }, [nextProps?.geoglowsCatchmentData]);

  useEffect(() => {
    if (nextProps?.Weather?.iriAvailableDatesData) {
      setForecastAvailableDates(nextProps?.Weather?.iriAvailableDatesData);
    }
  }, [nextProps?.Weather?.iriAvailableDatesData]);

  useEffect(() => {
    if (nextProps?.Weather?.weatherForecastData) {
      nextProps?.Weather?.weatherForecastData.map((item) => {
        let val = {};
        Object.entries(item).forEach(([key, value]) => {
          val = value;
          if (val) {
            if (Array.isArray(val)) {
              val[0].name = key;
            } else {
              val.name = key;
            }
          }
        });
        setLayerData(val[0]);
        setSelectedMapLayer("iri");
        return null;
      });
    }
  }, [nextProps?.Weather?.weatherForecastData]);

  useEffect(() => {
    if (!_.isEmpty(forecastAvailableDates)) {
      const list = [];
      forecastAvailableDates?.map((i) => {
        list.push({
          id: i?.category,
          name: i?.category,
          value: i?.category,
          label: i?.category,
          data: i?.data,
        });
        return null;
      });

      IRIForecastTime.year = list[0];
      setIRIAvailableYearList(list);

      setIRIForecastTime({ ...IRIForecastTime });
    }
  }, [forecastAvailableDates]);

  useEffect(() => {
    if (!_.isEmpty(IRIForecastTime.year) && IRIAvailableYearList?.length) {
      let data;
      const list = [];
      IRIAvailableYearList?.map((i) => {
        if (i?.value === IRIForecastTime?.year?.value) {
          data = i?.data;
        }
        return null;
      });
      data?.map((a) => list.push(handleForecastLeadMonthFormat(a)));
      IRIForecastTime.month = list[0];
      setIRIForecastTime({ ...IRIForecastTime });

      setIRIAvailableMonthList(list);
    }
  }, [IRIForecastTime.year, IRIAvailableYearList]);

  useEffect(() => {
    if (!_.isEmpty(IRIForecastTime.month) && IRIAvailableMonthList?.length) {
      let data;
      const list = [];
      IRIAvailableMonthList.map((i) => {
        if (i?.value === IRIForecastTime.month?.value) {
          data = i?.data;
        }
        return null;
      });
      data?.map((a) => {
        list.push(handleForecastLeadTimeFormat(a));
        return null;
      });

      IRIForecastTime.lead_time = {
        ...list[0],
        value: list[0]?.name,
      };
      setIRIForecastTime({ ...IRIForecastTime });
      setIRIAvailableLeadTimeList(list);
    }
  }, [IRIForecastTime.month, IRIAvailableMonthList]);

  useEffect(() => {
    if (
      IRIForecastTime.lead_time &&
      selectedParameter?.slug === "iri" &&
      initialData?.district?.value !== ""
    ) {
      onHandleMap();
    }
  }, [IRIForecastTime?.lead_time, selectedParameter, initialData?.district]);

  useEffect(() => {
    if (
      IRIForecastTime.lead_time &&
      selectedParameter?.slug === "open_weather" &&
      initialData?.district?.value !== ""
    ) {
      onHandleMap();
    }
  }, [selectedParameter, initialData?.district]);

  useEffect(() => {
    if (selectedParameter) {
      if (
        selectedParameter?.slug !== "iri" &&
        selectedParameter?.slug !== "open_weather" &&
        selectedParameter?.slug !== "geo_glows" &&
        initialData?.district?.value !== "" // geo_glows
      ) {
        onHandleMap();
      }
    }
  }, [selectedParameter, initialData?.district]);

  // for adding geo glow layers
  useEffect(() => {
    if (selectedParameter) {
      if (selectedParameter?.slug === "geo_glows") {
        onHandleMap();
      }
    }
  }, [selectedParameter]);

  const severity = (data) => {
    if (data === "Readiness Phase") {
      return "Severe";
    } else if (data === "Active Phase") {
      return "Extreme";
    } else {
      return "Normal";
    }
  };

  useEffect(() => {
    if (!_.isEmpty(openweatherSeriseData)) {
      setColumn([
        {
          Header: initialData?.district?.label,
          id: "alert data table",
          columns: [
            {
              Header: t("LOADING.DATE"),
              accessorKey: "date",
            },
            {
              Header: t("WEATHER_FORCAST.OPEN_WEATHER"),
              accessorKey: "value",
            },
          ],
        },
      ]);
      const arr = [];
      openweatherSeriseData.forEach((item) => {
        arr.push({
          value: item.rain ? item.rain["1h"] : 0,
          date: OpenWeatherChartLabels(item.dt_txt),
        });
      });
      setTableData(arr);
    }
  }, [openweatherSeriseData]);

  useEffect(() => {
    if (!_.isEmpty(graphData)) {
      setColumn([
        {
          Header: initialData?.district?.label,
          id: "alert data table",
          columns: [
            {
              Header: t("LOADING.DATE"),
              accessorKey: "date",
            },
            {
              Header: initialData?.selectedIndicator,
              accessorKey: "value",
            },
          ],
        },
      ]);
      const arr = [];
      graphData.graph_data.data.forEach((item, i) => {
        arr.push({
          value: item,
          date: renderChartTime(new Date(graphData.graph_data.millis[i])),
        });
      });
      setTableData(arr);
    }
  }, [graphData]);

  useEffect(() => {
    setOpenweatherSeriseData();
    setGraphData();
    setIsShowTableView(false);
  }, [initialData.risk]);

  useEffect(() => {
    if (!_.isEmpty(initialData)) {
      setForecastDate();
      const obj = initialData?.indicators?.find(
        (ob) => ob.label === initialData?.selectedIndicator,
      );
      const endDat = obj?.end_date;
      if (initialData.selectedIndicator === "IRI") {
        setGraphStartDate();
        setGraphEndDate();
        setMinAndMaxDate({
          minDate: "",
          maxDate: "",
        });
      } else if (initialData.selectedIndicator === "Open Weather") {
        setGraphStartDate(moment().format(DATE_FORMAT));
        setGraphEndDate(moment().add(6, "days").format(DATE_FORMAT));
        setMinAndMaxDate({
          minDate: moment().format(DATE_FORMAT),
          maxDate: moment().add(6, "days").format(DATE_FORMAT),
        });
      } else if (initialData.selectedIndicator === "GeoGlows") {
        // setGraphStartDate(
        //   moment(endDat).subtract(30, "days").format(DATE_FORMAT),
        // );
        // setGraphEndDate(endDat);
        const end_date = moment().subtract(1, "days").format(DATE_FORMAT);
        setForecastDate(end_date);
        setMinAndMaxDate({
          minDate: moment(end_date).subtract(30, "days").format(DATE_FORMAT),
          maxDate: end_date,
        });
      } else {
        if (endDat) {
          setGraphStartDate(
            moment(endDat).subtract(30, "days").format(DATE_FORMAT),
          );
          setGraphEndDate(endDat);
          setMinAndMaxDate({
            minDate: moment(endDat).subtract(30, "days").format(DATE_FORMAT),
            maxDate: endDat,
          });
        }
      }
    }
  }, [initialData.selectedIndicator]);

  useEffect(() => {
    if (initialData?.district?.value && initialData?.district?.value !== "") {
      const selectedLayer = mapPreferences.districts_or_cities;
      selectedLayer?.layer?.current?.clearLayers();
      dispatch(
        taluksBoundaryRequest({
          data: {
            country_name: initialData?.country?.label,
            state_name:
              initialData?.region === ""
                ? t("DASHBOARD.ALL_REGIONS")
                : initialData?.region?.label,
            district_name:
              initialData?.district === ""
                ? t("DASHBOARD.ALL_DISTRICTS")
                : initialData?.district?.label,
            taluk_name: t("DASHBOARD.ALL_TALUKS"),
          },
        }),
      );
    }
  }, [initialData?.district]);

  // const onGraphStartDateChange = (startDate) => {
  //   const obj = initialData?.indicators?.find(
  //     (ob) => ob.label === initialData.selectedIndicator,
  //   );
  //   onHandelIndicator(obj, startDate, graphEndDate);
  // };

  const onGraphDateChange = () => {
    const obj = initialData?.indicators?.find(
      (ob) => ob.label === initialData.selectedIndicator,
    );
    if (graphStartDate && graphEndDate)
      onHandelIndicator(obj, graphStartDate, graphEndDate);
  };

  const onForecastDateChange = () => {
    const obj = initialData?.indicators?.find(
      (ob) => ob.label === initialData.selectedIndicator,
    );
    const date = forecastDate?.split("-").join("");
    if (date) onHandelIndicator(obj, graphStartDate, graphEndDate, date);
  };

  useEffect(() => {
    if (!_.isEmpty(initialData?.pointer)) {
      const obj = initialData?.indicators?.find(
        (ob) => ob.label === initialData?.selectedIndicator,
      );
      if (initialData.selectedIndicator === "GeoGlows") {
        onHandelIndicator(
          obj,
          undefined,
          undefined,
          forecastDate.split("-").join(""),
        );
      } else onHandelIndicator(obj, graphStartDate, graphEndDate);
    } else {
      if (!initialRender) {
        const obj = initialData?.indicators?.find(
          (ob) => ob.label === initialData?.selectedIndicator,
        );
        if (initialData.selectedIndicator === "GeoGlows") {
          onHandelIndicator(
            obj,
            undefined,
            undefined,
            forecastDate.split("-").join(""),
          );
        } else {
          onHandelIndicator(obj, graphStartDate, graphEndDate);
        }
      }
    }
  }, [initialData.pointer]);

  useEffect(() => {
    // Check if this is the initial render
    if (!_.isEmpty(initialData.pointer)) {
      // Update the state to indicate that the initial render has occurred
      setInitialRender(false);
    }
  }, [initialData.pointer]);

  useEffect(() => {
    dispatch(getIRIAvailableDatesDataRequest());
    setOpen(true);
  }, []);
  return (
    <div className="dashboard-filter-div health-map">
      <div className="dashboard-filter">
        <div>
          <h3 className="dashboard-filter-h3">
            {t("DASHBOARD.ALERT_DASHBOARD")}
          </h3>
        </div>
      </div>
      <Row className="dashboard-drought-section dashboard-health-section">
        <Col lg={12} className="dashboard-drought-col2 pr-0">
          <DashboardFilter
            defaultCountry={defaultSelectedCountry}
            setInitialData={setInitialData}
            initialData={initialData}
            setRegionListOptions={setRegionListOptions}
            regionListOptions={regionListOptions}
            setDistrictsListOptions={setDistrictsListOptions}
            districtsListOptions={districtsListOptions}
            setRiskListOptions={setRiskListOptions}
            riskListOptions={riskListOptions}
            setIndicatorListOptions={setIndicatorListOptions}
            indicatorListOptions={indicatorListOptions}
            setDefaultOptions={setDefaultOptions}
            defaultOptions={defaultOptions}
            setSelectedParameter={setSelectedParameter}
            selectedParameter={selectedParameter}
            layerRef={layerRef}
            onHandleZoom={onHandlefilterzoom}
            setIsShowTableView={setIsShowTableView}
            isShowTableView={isShowTableView}
            IRIAvailableLeadTimeList={IRIAvailableLeadTimeList}
            IRIForecastTime={IRIForecastTime}
            setIRIForecastTime={setIRIForecastTime}
            setIndicatorsData={setIndicatorsData}
            loader={loader}
            // setSelectedParameter={setSelectedParameter}
          />
          <Row>
            <Col md={3} className="no-gutters">
              <div className="indicators-container">
                <div className="indicators-inner-container">
                  <div className="alert-dashboard-loader-container">
                    <h3 className="indicator-header-text">Alerts</h3>
                    <ClipLoader color="#36d7b7" size={20} loading={loader} />
                  </div>
                  <div className="header-divider" />
                  <div className="indicators-outer-container">
                    <div className="indicators-outer-inner-container">
                      {!_.isEmpty(indicatorsData)
                        ? indicatorsData?.map((element, index) => {
                            if (
                              initialData?.indicators[index]?.label ===
                              "Open Weather"
                            )
                              return (
                                <IndicatorCard
                                  name={initialData?.indicators[index]?.label}
                                  value={
                                    !_.isEmpty(
                                      element?.data?.result[0]?.Open_Weather
                                        ?.alert,
                                    )
                                      ? element?.data?.result[0]?.Open_Weather
                                          ?.alert[0]?.["Current Value"]
                                      : "-"
                                  }
                                  ActiveThreshold={
                                    !_.isEmpty(
                                      element?.data?.result[0]?.Open_Weather
                                        ?.alert,
                                    )
                                      ? element?.data?.result[0]?.Open_Weather
                                          ?.alert[0]?.["Active Threshold"]
                                      : "-"
                                  }
                                  readinessThreshold={
                                    !_.isEmpty(
                                      element?.data?.result[0]?.Open_Weather
                                        ?.alert,
                                    )
                                      ? element?.data?.result[0]?.Open_Weather
                                          ?.alert[0]?.["Readiness Threshold"]
                                      : "-"
                                  }
                                  preparednessThreshold={
                                    !_.isEmpty(
                                      element?.data?.result[0]?.Open_Weather
                                        ?.alert,
                                    )
                                      ? element?.data?.result[0]?.Open_Weather
                                          ?.alert[0]?.["Preparedness Threshold"]
                                      : "-"
                                  }
                                  action={
                                    !_.isEmpty(
                                      element?.data?.result[0]?.Open_Weather
                                        ?.alert,
                                    )
                                      ? element?.data?.result[0]?.Open_Weather
                                          ?.alert[0]?.Action
                                      : "-"
                                  }
                                  severity={
                                    !_.isEmpty(
                                      element?.data?.result[0]?.Open_Weather
                                        ?.alert,
                                    )
                                      ? severity(
                                          element?.data?.result[0]?.Open_Weather
                                            ?.alert[0]?.Action,
                                        )
                                      : "-"
                                  }
                                  indicator={initialData?.indicators[index]}
                                  onHandelIndicator={onHandelIndicator}
                                  data={element}
                                  selectedParameter={selectedParameter}
                                />
                              );
                            else
                              return (
                                <IndicatorCard
                                  name={initialData?.indicators[index]?.label}
                                  value={
                                    !_.isEmpty(element?.data?.result?.alert)
                                      ? element?.data?.result?.alert[0]?.[
                                          "Current Value"
                                        ]
                                      : "-"
                                  }
                                  ActiveThreshold={
                                    !_.isEmpty(element?.data?.result?.alert)
                                      ? element?.data?.result?.alert[0]?.[
                                          "Active Threshold"
                                        ]
                                      : "-"
                                  }
                                  readinessThreshold={
                                    !_.isEmpty(element?.data?.result?.alert)
                                      ? element?.data?.result?.alert[0]?.[
                                          "Readiness Threshold"
                                        ]
                                      : "-"
                                  }
                                  preparednessThreshold={
                                    !_.isEmpty(element?.data?.result?.alert)
                                      ? element?.data?.result?.alert[0]?.[
                                          "Preparedness Threshold"
                                        ]
                                      : "-"
                                  }
                                  action={
                                    !_.isEmpty(element?.data?.result?.alert)
                                      ? element?.data?.result?.alert[0]?.Action
                                      : "-"
                                  }
                                  severity={
                                    !_.isEmpty(element?.data?.result?.alert)
                                      ? severity(
                                          element?.data?.result?.alert[0]
                                            ?.Action,
                                        )
                                      : "-"
                                  }
                                  indicator={initialData?.indicators[index]}
                                  onHandelIndicator={onHandelIndicator}
                                  data={element}
                                  selectedParameter={selectedParameter}
                                />
                              );
                          })
                        : null}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center indicator-button-container">
                  <div
                    className="indicator-button"
                    role="button"
                    tabIndex="0"
                    onKeyUp={() => {}}
                    onClick={() => {
                      navigate(`/earlyaction?slug=${EARLY_ACTION_SLUG}`);
                    }}
                  >
                    <img alt="" src={hourglassWithTick} />
                    {t("DASHBOARD.NAVIGATE_TO_EARLY_ACTION")}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={9}>
              <div
                className="map-section health-map-section"
                style={isShowTableView ? { display: "none" } : {}}
              >
                <AlterMap
                  setMapes={setMapes}
                  position={position}
                  setPosition={setPosition}
                  zooms={zooms}
                  setZooms={setZooms}
                  mapes={mapes}
                  wrap={wrap}
                  indicators={initialData?.indicators}
                  selectedParameter={selectedParameter}
                  initialData={initialData}
                  setInitialData={setInitialData}
                  maps={layerData}
                  layerRef={layerRef}
                  indicatorListOptions={indicatorListOptions}
                  layerData={layerData}
                  legend={legend}
                  setLegend={setLegend}
                  onHandleZoom={onHandleZoom}
                  setSelectedParameter={setSelectedParameter}
                  removeLayers={removeLayers}
                  removeMapLayers={removeMapLayers}
                  setMapLayers={setMapLayers}
                  mapLayers={mapLayers}
                  layersArray={layersArray}
                  selectedMapLayer={selectedMapLayer}
                  mapPreferences={mapPreferences}
                />
              </div>
              <div
                className=""
                style={!isShowTableView ? { display: "none" } : {}}
              >
                <AlertTable COLUMNS={column} tableData={tableData} />
              </div>
              <div className="alert-db-graph-container mt-3">
                <AlertGraph
                  openweatherSeriseData={openweatherSeriseData}
                  graphData={graphData}
                  initialData={initialData}
                  isShowGraph={isShowGraph}
                  setIsShowGraph={setIsShowGraph}
                  graphStartDate={graphStartDate}
                  graphEndDate={graphEndDate}
                  setGraphStartDate={setGraphStartDate}
                  setGraphEndDate={setGraphEndDate}
                  onGraphDateChange={onGraphDateChange}
                  minAndMaxDate={minAndMaxDate}
                  errorData={errorData}
                  seriesData={seriesData}
                  forecastDate={forecastDate}
                  setForecastDate={setForecastDate}
                  onForecastDateChange={onForecastDateChange}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default AlertDashboard;
