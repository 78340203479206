import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import EARLY_FINANCE_REPORT_CONST from "./actionTypes";
import {
  createEarlyFinanceReportSuccess,
  createEarlyFinanceReportError,
  getEarlyFinanceReportSuccess,
  getEarlyFinanceReportError,
  editEarlyFinanceReportSuccess,
  editEarlyFinanceReportError,
  deleteEarlyFinanceReferencesSuccess,
  deleteEarlyFinanceReferencesError,
  sendEarlyFinanceReportSuccess,
  sendEarlyFinanceReportError,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import { EARLY_ACTION_API, AXIOS_INSTANCE } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

// GET REPORT
function* getEarlyFinanceReport(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${EARLY_ACTION_API}/report/get?user_id=${action.payload.user_id}&project_id=${action.payload.project_id}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getEarlyFinanceReportSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getEarlyFinanceReportError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getEarlyFinanceReportError(errorData));
  }
}

// CREATE REPORT
function* createEarlyFinanceReport(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${EARLY_ACTION_API}/report/create`, action.payload),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(createEarlyFinanceReportSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(createEarlyFinanceReportError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(createEarlyFinanceReportError(errorData));
  }
}

// EDIT REPORT
function* editEarlyFinanceReport(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.put(`${EARLY_ACTION_API}/report/edit`, action.payload),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(editEarlyFinanceReportSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(editEarlyFinanceReportError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(editEarlyFinanceReportError(errorData));
  }
}

// DELETE REFERENCE
function* deleteEarlyFinaceReference(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.delete(`${EARLY_ACTION_API}/report/delete`, {
        params: action.payload,
      }),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(deleteEarlyFinanceReferencesSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(deleteEarlyFinanceReferencesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteEarlyFinanceReferencesError(errorData));
  }
}

// CREATE REPORT
function* sendEarlyFinanceReport(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${EARLY_ACTION_API}/report/send`, action.payload),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(sendEarlyFinanceReportSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(sendEarlyFinanceReportError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(sendEarlyFinanceReportError(errorData));
  }
}

export function* watchEarlyFinanceReport() {
  yield takeEvery(
    EARLY_FINANCE_REPORT_CONST.GET_EARLY_FINANCE_REPORT_REQUEST,
    getEarlyFinanceReport,
  );
  yield takeEvery(
    EARLY_FINANCE_REPORT_CONST.ADD_EARLY_FINANCE_REPORT_REQUEST,
    createEarlyFinanceReport,
  );
  yield takeEvery(
    EARLY_FINANCE_REPORT_CONST.EDIT_EARLY_FINANCE_REPORT_REQUEST,
    editEarlyFinanceReport,
  );
  yield takeEvery(
    EARLY_FINANCE_REPORT_CONST.DELETE_EARLY_FINANCE_REFERENCES_REQUEST,
    deleteEarlyFinaceReference,
  );
  yield takeEvery(
    EARLY_FINANCE_REPORT_CONST.SEND_EARLY_FINANCE_REPORT_REQUEST,
    sendEarlyFinanceReport,
  );
}

function* EarlyFinanceReport() {
  yield all([fork(watchEarlyFinanceReport)]);
}

export default EarlyFinanceReport;
