import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { searchContentUserGuideRequest } from "../../store/actions";
import RightArrow from "../../assest/img/back-button.svg";
import { htmlToText } from "../../components/Common/Utils";

function UserSearchContentPage() {
  const [searchContent, setSearchContent] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useNavigate();
  const { searchContentUserGuideData, isSearchContentUserGuideRequesting } =
    useSelector((state) => ({
      searchContentUserGuideData: state.UserGuide.searchContentUserGuideData,
      isSearchContentUserGuideRequesting:
        state.UserGuide.isSearchContentUserGuideRequesting,
    }));

  const params = new URLSearchParams(window.location.search);

  const selectedSubModule = params.get("slug");

  const search = window.location.search;
  const URLParams = new URLSearchParams(search);
  const searchWord = URLParams.get("q");
  useEffect(() => {
    if (searchWord?.length !== 0) {
      dispatch(searchContentUserGuideRequest({ content: searchWord }));
    }
  }, []);
  useEffect(() => {
    const serchedResults = [];
    setSearchContent([]);
    if (searchContentUserGuideData?.data) {
      const { data } = searchContentUserGuideData?.data;
      if (!_.isEmpty(data) && !_.isEmpty(data?.result)) {
        data.result.map((item) => {
          serchedResults.push(item);
          return null;
        });
        setSearchContent(serchedResults);
      }
    }
  }, [searchContentUserGuideData]);

  const handleSubTopicListItem = (
    Id,
    topicId,
    subtopicId,
    subtopic_name,
    topic_name,
    data,
  ) => {
    history(
      `/user-guide-details?topicId=${topicId}&&sub-topic-name=${subtopic_name}&topic_name=${topic_name}&categoryName=${data.topic_category_name}&id=${data.subtopic_id}&slug=${selectedSubModule}`,
    );
  };
  const handleManageCropsBackButton = () => {
    history(-1);
  };

  return (
    <div className="search-content-header">
      <div className="rendered-container pt-4">
        <img
          src={RightArrow}
          className="right-arrow-icon"
          alt="icon"
          onClick={() => handleManageCropsBackButton()}
          onKeyDown={() => handleManageCropsBackButton()}
        />
        <span className="searched-results">
          {t("USER_GUIDE.SEARCHED_RESULTS")} &quot;
          <b>{searchWord.toUpperCase()}</b>&quot;
        </span>
      </div>

      <div className="rendered-container">
        {!isSearchContentUserGuideRequesting &&
          _.isEmpty(searchContent) &&
          _.isEmpty(searchContentUserGuideData?.data?.data?.result) && (
            <center className="text-result">
              <h2> {t("USER_GUIDE.NO_DATA")}</h2>
            </center>
          )}
        <Row className="search-content-container">
          {searchContent?.map((item) => {
            return (
              <Col lg={4} sm={6} xs={12} className="card-column">
                <div>
                  <Card
                    className="custom-card-x cb-1 cursor-ponter"
                    onClick={() =>
                      handleSubTopicListItem(
                        item.id,
                        item.topic_id,
                        item.subtopic_id,
                        item.subtopic_name,
                        item.topic_name,
                        item,
                      )
                    }
                  >
                    <div className="content-exceeded">
                      <p className="user-guide-icon-text">
                        {item?.subtopic_name?.length > 21
                          ? `${item?.subtopic_name?.substring(0, 22)}...`
                          : item?.subtopic_name}
                      </p>
                      {htmlToText(item.content)?.length > 120 ? (
                        <div id="card-description">
                          <div className="user-guide-raw-data-section">
                            {`${htmlToText(item.content).substring(0, 237)}...`}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="user-guide-search-content  user-guide-card-content"
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        />
                      )}
                    </div>
                  </Card>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
}

export default React.memo(UserSearchContentPage);
