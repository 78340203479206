import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrFormClose } from "react-icons/gr";
import { useDispatch } from "react-redux";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { ReactComponent as BadFillIcon } from "../../assest/img/Bad-Selected.svg";
import { ReactComponent as BadIcon } from "../../assest/img/Bad.svg";
import { ReactComponent as GoodFillIcon } from "../../assest/img/Good-Selected.svg";
import { ReactComponent as GoodIcon } from "../../assest/img/Good.svg";
import { ReactComponent as GreatFillIcon } from "../../assest/img/Great-Selected.svg";
import { ReactComponent as GreatIcon } from "../../assest/img/Great.svg";
import { ReactComponent as OkayFillIcon } from "../../assest/img/Okay-Selected.svg";
import { ReactComponent as OkayIcon } from "../../assest/img/Okay.svg";
// import { ReactComponent as ExcellentIcon } from "../../assest/img/ExcellentIcon.svg";
import { ReactComponent as ExcellentIcon } from "../../assest/img/ExcellentIcon.svg";
import { ReactComponent as ExcellentFillIcon } from "../../assest/img/ExcellentFillIcon.svg";
// import { ReactComponent as TerribleFillIcon } from "../../assest/img/Terrible-Selected.svg";
// import { ReactComponent as TerribleIcon } from "../../assest/img/Terrible.svg";
import DragAndDrop from "../../components/Common/DragAndDrop";
import { showError } from "../../components/Common/Notification";
import { isLogedIn } from "../../components/Common/Utils";
import { feedbackUploadList } from "../../constants";
import { cropYieldFeedbackRequest } from "../../store/actions";

function FeedbackModal({
  toggle,
  modal,
  selectedLocation,
  startYear,
  endYear,
  selectedModal,
  selectedCrop,
  selectedModule,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedRating, setSelectedRating] = useState({});
  const [feedback, setFeedback] = useState("");
  const userDetails = JSON.parse(isLogedIn());

  const emojisIcons = [
    {
      icon: <BadIcon height={36} width={36} />,
      text: t("CROP_YIELD.POOR"),
      rating: 1,
      selectedIcon: <BadFillIcon height={36} width={36} />,
    },
    {
      icon: <OkayIcon height={36} width={36} />,
      text: t("CROP_YIELD.UNSATISFACTORY"),
      rating: 2,
      selectedIcon: <OkayFillIcon height={36} width={36} />,
    },
    {
      icon: <GoodIcon height={36} width={36} />,
      text: t("CROP_YIELD.SATISFACTORY"),
      rating: 3,
      selectedIcon: <GoodFillIcon height={36} width={36} />,
    },
    {
      icon: <GreatIcon height={36} width={36} />,
      text: t("CROP_YIELD.GOOD"),
      rating: 4,
      selectedIcon: <GreatFillIcon height={36} width={36} />,
    },
    {
      icon: <ExcellentIcon height={36} width={36} />,
      text: t("CROP_YIELD.EXCELLENT"),
      rating: 5,
      selectedIcon: <ExcellentFillIcon height={36} width={36} />,
    },
  ];

  useEffect(() => {
    // Add a CSS class to the body element when the sideEditor is open
    if (modal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [modal]);

  useEffect(() => {
    if (selectedFiles?.length > 5) {
      showError(t("CROP_YIELD.MAX_FILES_LIMIT"));
      setSelectedFiles([]); // Emptying if more than 5 images are uploaded
    }
  }, [selectedFiles]);

  const closeBtn = (
    <GrFormClose
      size={24}
      onClick={() => toggle()}
      className="pointer media-modal-header-close-icon"
    />
  );

  const uploadedFiles = [];
  const onHandleUploadImage = (file, base64String) => {
    // Create an object to store file and base64 pair
    const fileData = {
      file_name: file.name,
      base64: base64String.split(",")?.[1],
    };
    // Add the object to the uploadedFiles array
    uploadedFiles.push(fileData);
    if (uploadedFiles?.length) {
      setSelectedFiles([...selectedFiles, ...uploadedFiles]);
    }
  };

  const handleRemoveImage = (index) => {
    const files = [...selectedFiles];
    files.splice(index, 1);
    setSelectedFiles(files);
  };

  const handleSubmit = () => {
    const payload = {
      country_id: selectedLocation?.country?.value,
      state_id: selectedLocation?.state ? selectedLocation?.state?.value : "",
      user_id: userDetails?.user_details?.[0]?.id,
      crop_name: selectedCrop?.crop?.slug,
      user_name: userDetails?.user_details?.[1]?.first_name,
      comment: feedback,
      rating: !_.isEmpty(selectedRating) ? selectedRating?.rating : "",
      start_year: startYear,
      model_input: selectedModal?.Modalvalue,
      module_name: selectedModule,
      upload_file: [...selectedFiles],
    };
    if (
      selectedLocation?.district?.value !== "" &&
      selectedLocation?.district !== ""
    ) {
      payload.district_id = selectedLocation?.district?.value;
    }
    if (endYear !== "") {
      payload.end_year = endYear;
    }
    dispatch(cropYieldFeedbackRequest(payload));
    toggle();
  };

  const renderFiles = () => {
    return selectedFiles
      ? selectedFiles.map((item, index) => {
          return (
            <div
              className="img-upload-container d-flex justify-content-between align-items-center m-3"
              key={item?.file_name}
            >
              {item?.file_name}
              <span
                role="button"
                onClick={() => handleRemoveImage(index)}
                onKeyUp={() => {}}
                tabIndex="0"
                aria-label="MediaModal"
              >
                <GrFormClose
                  size={16}
                  className="pointer media-modal-header-close-icon"
                />
              </span>
            </div>
          );
        })
      : "";
  };

  const handleSelectRating = (data) => {
    setSelectedRating(data);
  };

  return (
    <div>
      <Modal isOpen={modal} size="md">
        <ModalHeader
          toggle={toggle}
          className="align-items-center media-modal-header-background"
          close={closeBtn}
        >
          <h2 className="intervention-h2 m-0">
            {t("CROP_YIELD.PROVIDE_FFEDBACK")}
          </h2>
        </ModalHeader>
        <ModalBody className="p-3">
          <div className="mb-2">
            <p className="feedback-questions m-0">
              {t("CROP_YIELD.MODAL_PERFORMACE_TEXT")}
            </p>
            <div className="emoji-container">
              {emojisIcons?.map((emojis) => (
                <div
                  key={emojis.text}
                  className="d-flex flex-column align-items-center cursor-pointer"
                  onClick={() => handleSelectRating(emojis)}
                  role="button"
                  tabIndex="0"
                  onKeyUp={() => {}}
                >
                  {!_.isEmpty(selectedRating)
                    ? selectedRating?.text === emojis.text
                      ? emojis.selectedIcon
                      : emojis.icon
                    : emojis.icon}{" "}
                  <p
                    className={`${
                      !_.isEmpty(selectedRating)
                        ? selectedRating?.text === emojis.text
                          ? "emoji-text-active"
                          : ""
                        : ""
                    } emoji-text pt-2`}
                  >
                    {emojis.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-2">
            <p className="feedback-questions m-0 mb-1">
              {t("CROP_YIELD.WRITE_COMMENTS")}
            </p>
            <div className="send-container">
              <Input
                type="textarea"
                classNamePrefix="react-select"
                className="feedback-text-field"
                placeholder={t("CROP_YIELD.FEEDBACK_PLACEHOLDER")}
                maxLength={300}
                value={feedback}
                onChange={(e) => {
                  setFeedback(e.target.value);
                }}
              />

              {feedback?.length > 250 ? (
                <p className="error">{t("VALIDATION.COMMENT_VALIDAATION")}</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="mb-2">
            <p className="feedback-questions m-0 mb-1">
              {t("CROP_YIELD.UPLOAD_FILE")}
            </p>
            <DragAndDrop
              onHandleUploadImage={onHandleUploadImage}
              acceptList={feedbackUploadList}
              isFeedback
            />
            {selectedFiles?.length ? (
              <div
                className="img-upload-main-container"
                style={{ maxHeight: "100px", height: "auto" }}
              >
                {renderFiles()}
              </div>
            ) : (
              ""
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="w-100 active-pahse-btn-section justify-content-end">
            <Button
              type="button"
              className="media-cancel-btn mr-3"
              onClick={() => {
                toggle();
              }}
            >
              {t("EARLY_ACTIONS.CANCLE")}
            </Button>
            <Button
              type="button"
              className="media-add-btn mb-1"
              onClick={() => handleSubmit()}
              disabled={_.isEmpty(selectedRating)}
            >
              {t("BULLET_IN.SUBMIT")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default FeedbackModal;
