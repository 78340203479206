import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Label, Input, Button } from "reactstrap";
import _ from "lodash";
import Select from "react-select";
import QuilTextEditor from "../../components/Common/QuillEditor";

function SideEditor({
  type,
  rowData,
  editableRowKeys,
  setEditableRowKeys,
  handleEditApiCall,
  isEditable,
  setAddNewRow,
  addNewRow,
  handleCloseModal,
  categoriesOptions,
  selectedNewRowOptions,
  openEditor,
}) {
  const { t } = useTranslation();
  const [newDescription, setNewDescription] = useState("");
  const [isOthersSelected, setIsOthersSelected] = useState(false);

  useEffect(() => {
    // Add a CSS class to the body element when the sideEditor is open
    if (openEditor) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [openEditor]);

  const handleAddCategory = (event) => {
    const newCategory = event.target.value;
    setAddNewRow((prevAddNewRow) => ({
      ...prevAddNewRow,
      "Category of impacts": newCategory,
    }));
  };

  const handleAddDescription = (data) => {
    setAddNewRow((prevAddNewRow) => ({
      ...prevAddNewRow,
      Description: String(data),
    }));
    setNewDescription(String(data));
  };

  const handleSelectCategory = (data) => {
    if (data.value !== "Others") {
      setAddNewRow((prevAddNewRow) => ({
        ...prevAddNewRow,
        "Category of impacts": data.value,
        Description: String(data.description),
      }));
      setIsOthersSelected(false);
    } else {
      setAddNewRow((prevAddNewRow) => ({
        ...prevAddNewRow,
        "Category of impacts": "",
      }));
      setIsOthersSelected(true);
    }
    setNewDescription(data.description);
  };

  const renderAddRow = () => {
    return (
      <>
        <div className="d-flex flex-column justify-content-start w-100 dash-drought-col-div">
          <Label className="region-label mt-0 ml-2">
            {" "}
            {t("EARLY_ACTIONS.CATEGORY_OF_IMPACTS")}{" "}
          </Label>
          <Select
            options={categoriesOptions}
            classNamePrefix="react-select"
            value={selectedNewRowOptions}
            className="dash-select common-select-r ea-select-options"
            placeholder="Select Category"
            onChange={handleSelectCategory}
          />
        </div>
        {isOthersSelected ? (
          <div className="d-flex flex-column justify-content-start w-100 mt-3">
            <Label className="region-label mt-0 ml-2">
              {" "}
              {t("EARLY_ACTIONS.NEW_CATEGORY_OF_IMPACTS")}{" "}
            </Label>
            <Input
              classNamePrefix="react-select"
              className="ea-text-field"
              onChange={handleAddCategory}
              value={addNewRow["Category of impacts"]}
            />
          </div>
        ) : (
          ""
        )}
        <div className="d-flex flex-column justify-content-start w-100 mt-3">
          <Label className="region-label mt-0 ml-2">
            {" "}
            {t("EARLY_ACTIONS.DESCRIPTION")}{" "}
          </Label>
          <QuilTextEditor
            content={newDescription}
            onEditorChange={handleAddDescription}
          />
        </div>
      </>
    );
  };

  const handleEditCategory = (event) => {
    const newCategory = event.target.value;
    setEditableRowKeys((prevEditableRowKeys) => ({
      ...prevEditableRowKeys,
      "Category of impacts": newCategory,
    }));
  };

  const handleEditDescription = (data) => {
    setEditableRowKeys((prevEditableRowKeys) => ({
      ...prevEditableRowKeys,
      Description: String(data),
    }));
  };

  const handleEditDirectImpacts = (data) => {
    setEditableRowKeys((prevEditableRowKeys) => ({
      ...prevEditableRowKeys,
      "Direct Impacts": String(data),
    }));
  };

  const handleEditInDirectImpacts = (data) => {
    setEditableRowKeys((prevEditableRowKeys) => ({
      ...prevEditableRowKeys,
      "Indirect impacts": String(data),
    }));
  };

  const handleEditAnticipatryActions = (data) => {
    setEditableRowKeys((prevEditableRowKeys) => ({
      ...prevEditableRowKeys,
      "Possible Impact based Anticipatory actions": String(data),
    }));
  };

  const handleEditObjective = (data) => {
    setEditableRowKeys((prevEditableRowKeys) => ({
      ...prevEditableRowKeys,
      Objective: String(data),
    }));
  };

  const handleEditPreparednessPhase = (data) => {
    setEditableRowKeys((prevEditableRowKeys) => ({
      ...prevEditableRowKeys,
      "Anticipatory Action Preparedness Phase": String(data),
    }));
  };

  const handleEditTriggeredReadiness = (data) => {
    // Create a copy of the state object using the spread operator
    const updatedState = { ...editableRowKeys };

    // Update the value of the specific field in the copied state object
    updatedState["Anticipatory Action Phase"][0][
      "Actions to be undertaken When Readiness trigger activated"
    ] = String(data);

    // Update the state with the modified state object
    setEditableRowKeys(updatedState);
  };

  const handleEditTriggeredAction = (data) => {
    // Create a copy of the state object using the spread operator
    const updatedState = { ...editableRowKeys };

    // Update the value of the specific field in the copied state object
    updatedState["Anticipatory Action Phase"][0][
      "Actions to be undertaken When Action trigger activated"
    ] = String(data);

    // Update the state with the modified state object
    setEditableRowKeys(updatedState);
  };

  function renderEditableRows() {
    switch (type) {
      case "category":
        return (
          <>
            <div className="d-flex flex-column justify-content-start w-100">
              <Label className="region-label mt-0 ml-2">
                {" "}
                {t("EARLY_ACTIONS.CATEGORY_OF_IMPACTS")}{" "}
              </Label>
              <Input
                type="text"
                classNamePrefix="react-select"
                className="ea-text-field"
                value={editableRowKeys["Category of impacts"]}
                onChange={handleEditCategory}
              />
            </div>
            <div className="d-flex flex-column justify-content-start w-100 mt-3">
              <Label className="region-label mt-0 ml-2">
                {t("EARLY_ACTIONS.DESCRIPTION")}
              </Label>
              <QuilTextEditor
                content={editableRowKeys?.Description}
                onEditorChange={handleEditDescription}
              />
            </div>
          </>
        );
      case "impacts":
        return (
          <>
            <div className="d-flex flex-column justify-content-start w-100 mt-3">
              <Label className="region-label mt-0 ml-2">
                {t("EARLY_ACTIONS.DIRECT_IMPACTS")}
              </Label>
              <QuilTextEditor
                content={editableRowKeys["Direct Impacts"]}
                onEditorChange={handleEditDirectImpacts}
              />
            </div>
            <div className="d-flex flex-column justify-content-start w-100 mt-3">
              <Label className="region-label mt-0 ml-2">
                {t("EARLY_ACTIONS.INDIRECT_IMPACTS")}
              </Label>
              <QuilTextEditor
                content={editableRowKeys["Indirect impacts"]}
                onEditorChange={handleEditInDirectImpacts}
              />
            </div>
          </>
        );
      case "anticipatoryActions":
        return (
          <div className="d-flex flex-column justify-content-start w-100 mt-3">
            <Label className="region-label mt-0 ml-2">
              {t("EARLY_ACTIONS.POSSIBLE_ANTICIPATORY_ACTIONS")}
            </Label>
            <QuilTextEditor
              content={
                editableRowKeys["Possible Impact based Anticipatory actions"]
              }
              onEditorChange={handleEditAnticipatryActions}
            />
          </div>
        );
      case "anticipatoryActionsPhase":
        return (
          <>
            <div className="d-flex flex-column justify-content-start w-100 mt-3">
              <Label className="region-label mt-0 ml-2">
                {t("EARLY_ACTIONS.OBJECTIVE")}
              </Label>
              <QuilTextEditor
                content={editableRowKeys.Objective}
                onEditorChange={handleEditObjective}
              />
            </div>
            <div className="d-flex flex-column justify-content-start w-100 mt-3">
              <Label className="region-label mt-0 ml-2">
                {t("EARLY_ACTIONS.ANTICIPATORY_PREPAREDNESS_PHASE")}
              </Label>
              <QuilTextEditor
                content={
                  editableRowKeys["Anticipatory Action Preparedness Phase"]
                }
                onEditorChange={handleEditPreparednessPhase}
              />
            </div>
            <div className="d-flex flex-column justify-content-start w-100 mt-3">
              <Label className="region-label mt-0 ml-2">
                {t("EARLY_ACTIONS.READINESS_ACTIVATED")}
              </Label>
              <QuilTextEditor
                content={
                  editableRowKeys["Anticipatory Action Phase"][0][
                    "Actions to be undertaken When Readiness trigger activated"
                  ]
                }
                onEditorChange={handleEditTriggeredReadiness}
              />
            </div>
            <div className="d-flex flex-column justify-content-start w-100 mt-3">
              <Label className="region-label mt-0 ml-2">
                {t("EARLY_ACTIONS.ACTION_ACTIVATED")}
              </Label>
              <QuilTextEditor
                content={
                  editableRowKeys["Anticipatory Action Phase"][0][
                    "Actions to be undertaken When Action trigger activated"
                  ]
                }
                onEditorChange={handleEditTriggeredAction}
              />
            </div>
          </>
        );
      default:
        return "Unknown stepIndex";
    }
  }

  return (
    <div className="ea-right-sidebar">
      <div className="d-flex justify-content-center align-items-center mt-3 px-3">
        <h3 className="sidebar-heading">
          {isEditable
            ? t("EARLY_ACTIONS.EDITING_ROW")
            : t("EARLY_ACTIONS.ADD_ROW")}
        </h3>
      </div>
      <hr />
      <div className="p-3">
        <div className="mb-3 fixed-side-editor-container">
          {/* Rendering Based row data */}
          {!_.isEmpty(rowData) ? renderEditableRows() : renderAddRow()}
        </div>
        <div className="d-flex align-items-center justify-content-end editor-button-container">
          <Button
            type="button"
            className="back-office-cancel-btn"
            onClick={() => {
              handleCloseModal();
            }}
          >
            {t("APPROVE_DATA.CANCEL")}
          </Button>
          <Button
            type="button"
            className="back-office-ok-btn w-auto mb-1 px-5"
            onClick={() => {
              handleEditApiCall(isEditable ? "Edit" : "New");
            }}
            disabled={
              isEditable
                ? !Object.values(editableRowKeys).every((value) => value !== "")
                : !Object.values(addNewRow).every((value) => value !== "")
            }
          >
            {t("BULLET_IN.SAVE")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SideEditor;
