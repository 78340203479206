import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import EARLY_WARNING_POPULATION_DISPLACEMENT_CONST from "./actionTypes";
import {
  getPopulationDisplacementTimeSeriesSuccess,
  getPopulationDisplacementTimeSeriesError,
  getPopulationDisplacementMapDataSuccess,
  getPopulationDisplacementMapDataFailed,
  getPopulationDisplacementHazardDataSuccess,
  getPopulationDisplacementHazardDataFailed,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import {
  AXIOS_INSTANCE,
  POPULATION_DISPLACEMENT_URL,
  AXIOS_INSTANCE_LOADER,
} from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* getPopulationDisplacementTimeSeries(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(
        `${POPULATION_DISPLACEMENT_URL}/timeseries`,
        action.payload.data,
      ),
      "",
      "",
      true,
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getPopulationDisplacementTimeSeriesSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.message || response.data.error,
      };
      yield put(getPopulationDisplacementTimeSeriesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getPopulationDisplacementTimeSeriesError(errorData));
  }
}

function* getPopulationDisplacementMapData(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.post(
        `${POPULATION_DISPLACEMENT_URL}/map`,
        action?.payload?.requestObj,
      ),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getPopulationDisplacementMapDataSuccess(responseData));
    } else {
      const responseData = {
        data: response.data,
      };
      yield put(getPopulationDisplacementMapDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getPopulationDisplacementMapDataFailed(errorData));
  }
}

function* getPopulationDisplacementHazard(action) {
  const { countryId, stateId, districtId } = action?.payload?.data;
  let queryParam = "";
  if (countryId && stateId && !districtId) {
    queryParam = `?country_id=${countryId}&state_id=${stateId}`;
  } else if (countryId && stateId && districtId) {
    queryParam = `?country_id=${countryId}&state_id=${stateId}&district_id=${districtId}`;
  } else {
    queryParam = `?country_id=${countryId}`;
  }
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${POPULATION_DISPLACEMENT_URL}/crisis${queryParam}`),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getPopulationDisplacementHazardDataSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.message || response.data.error,
      };
      yield put(getPopulationDisplacementHazardDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getPopulationDisplacementHazardDataFailed(errorData));
  }
}

export function* watchPopulationDisplacement() {
  yield takeEvery(
    EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_TIMESERIES_REQUEST,
    getPopulationDisplacementTimeSeries,
  );
  yield takeEvery(
    EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_MAP_DATA_REQUEST,
    getPopulationDisplacementMapData,
  );
  yield takeEvery(
    EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_HAZARD_REQUEST,
    getPopulationDisplacementHazard,
  );
}

function* PopulationDisplacementSaga() {
  yield all([fork(watchPopulationDisplacement)]);
}

export default PopulationDisplacementSaga;
