const UPLOAD_PAST_DATA_CONSTS = {
  // ADD PAST DATA
  UPLOAD_PAST_DATA_REQUEST: "UPLOAD_PAST_DATA_REQUEST",
  UPLOAD_PAST_DATA_SUCCESS: "UPLOAD_PAST_DATA_SUCCESS",
  UPLOAD_PAST_DATA_FAILURE: "UPLOAD_PAST_DATA_FAILURE",
  // GET TEMPLATE DATA
  GET_TEMPLATE_DATA_REQUEST: "GET_TEMPLATE_DATA_REQUEST",
  GET_TEMPLATE_DATA_SUCCESS: "GET_TEMPLATE_DATA_SUCCESS",
  GET_TEMPLATE_DATA_FAILURE: "GET_TEMPLATE_DATA_FAILURE",
  // GET PAST DATA LIST
  GET_PAST_DATA_LIST_REQUEST: "GET_PAST_DATA_LIST_REQUEST",
  GET_PAST_DATA_LIST_SUCCESS: "GET_PAST_DATA_LIST_SUCCESS",
  GET_PAST_DATA_LIST_FAILURE: "GET_PAST_DATA_LIST_FAILURE",

  // DOWNLOAD TEMPLATE FOR CROP YIELD
  CROP_YIELD_EXCEL_TEMPLATE_REQUEST: "CROP_YIELD_EXCEL_TEMPLATE_REQUEST",
  CROP_YIELD_EXCEL_TEMPLATE_SUCCESS: "CROP_YIELD_EXCEL_TEMPLATE_SUCCESS",
  CROP_YIELD_EXCEL_TEMPLATE_FAILURE: "CROP_YIELD_EXCEL_TEMPLATE_FAILURE",

  // SUBMIT DATA FOR CROP YIELD
  CROP_YIELD_DATA_SUBMIT_REQUEST: "CROP_YIELD_DATA_SUBMIT_REQUEST",
  CROP_YIELD_DATA_SUBMIT_SUCCESS: "CROP_YIELD_DATA_SUBMIT_SUCCESS",
  CROP_YIELD_DATA_SUBMIT_FAILURE: "CROP_YIELD_DATA_SUBMIT_FAILURE",
};

export default UPLOAD_PAST_DATA_CONSTS;
