import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { BsChevronDown } from "react-icons/bs";
import { HiOutlineGlobeAlt } from "react-icons/hi";
import { BiUser } from "react-icons/bi";
import { IoMdSettings } from "react-icons/io";
import { ReactSelectFiled } from "./FormickField";
import { isLogedout, isLogedIn } from "./Utils";
import { LanguagesArray, Authentication } from "../../constants";
import climberresilanceLogo from "../../assest/img/climberresilanceLogo.svg";

function Header() {
  const { i18n, t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoged, setIsLoged] = useState(isLogedIn());
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDropdownAbout, setOpenDropdownAbout] = useState(false);
  const [language, setLanguage] = useState({});
  const history = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const isWeb = urlParams.get("isWeb");

  const handleLogout = () => {
    setOpenDropdown(!openDropdown);
    isLogedout();
    setIsLoged(isLogedIn());
    localStorage.removeItem("loggedInUserDetails");
    localStorage.setItem("isLoged", JSON.stringify(false));
    history("/home");
  };

  useEffect(() => {
    if (Cookies.get("user")) {
      setIsLoged(isLogedIn());
      localStorage.setItem("isLoged", JSON.stringify(true));
    }
  }, [Cookies.get("user")]);
  const onSelectLanguage = (selectedLnag) => {
    setLanguage(selectedLnag);
    i18n.changeLanguage(selectedLnag.value);
  };

  useEffect(() => {
    const lang = LanguagesArray.find(
      (eachValue) => eachValue.value === localStorage.getItem("i18nextLng"),
    );
    setLanguage({ ...lang });
  }, []);

  function handleClick() {
    history("/climber");
  }

  return (
    <Navbar className="header-bg-color" dark expand="md">
      <NavbarBrand className="align-center header-responsivness">
        <span
          onClick={isWeb ? "" : () => handleClick()}
          role="button"
          tabIndex="0"
          onKeyUp={() => {}}
        >
          <img
            src={climberresilanceLogo}
            alt="logo"
            className="header-img-height"
          />{" "}
        </span>
        {/* common header */}
        <div className="hr" />
        <div className="">
          <span
            className="nav-bar-climber-text-1 ml-0"
            // onClick={() => history("/home")}

            onClick={isWeb ? "" : () => history("/home")}
            role="button"
            tabIndex="0"
            onKeyUp={() => {}}
          >
            {" "}
            {t("CLIMBER_HEADER.AWARE")}
          </span>

          <p className="aware-tag-line">
            {" "}
            {t("CLIMBER_HEADER.AWARE_PLATFORM_TAG_LINE")}{" "}
          </p>
        </div>
      </NavbarBrand>

      <NavbarToggler
        onClick={() => setIsOpen((prev) => !prev)}
        className="navtoogle-1"
      />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto align-center" navbar>
          {!isWeb ? (
            <div className="common-select header-ANI-btn-1 header-lang-select-climber-1 header-select-div mb-2">
              <Dropdown
                isOpen={openDropdownAbout}
                toggle={() => setOpenDropdownAbout(!openDropdownAbout)}
                className="dashboard-earlyaction"
              >
                <DropdownToggle className="ANI-btn header-patner-text">
                  {t("CLIMBER_HEADER.ABOUT")}
                  <BsChevronDown
                    onClick={() => setOpenDropdownAbout(!openDropdownAbout)}
                  />
                </DropdownToggle>
                <DropdownMenu>
                  {/* Hidding for time being  */}

                  <DropdownItem
                    onClick={() => history("/OurTeams")}
                    className="aware-header-dropdown"
                  >
                    <p className="aware-header-text">
                      {" "}
                      {t("CLIMBER_HEADER.TEAMS")}
                    </p>
                  </DropdownItem>
                  <DropdownItem
                    className="aware-header-dropdown"
                    onClick={() => history("/ourpartner")}
                  >
                    {" "}
                    <p className="aware-header-text">
                      {" "}
                      {t("CLIMBER_HEADER.PARTNER")}{" "}
                    </p>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          ) : (
            ""
          )}

          {!isLoged && !Authentication.includes(window.location.pathname) ? (
            <>
              <Link to="/login" className="sign-in">
                {t("CLIMBER_HEADER.SIGN_IN")}
              </Link>
              <button
                type="button"
                className="register-btn mx-4"
                onClick={() => history("/registerPage")}
              >
                {t("CLIMBER_HEADER.REGISTER")}
              </button>
            </>
          ) : (
            ""
          )}

          <div>
            <div className="d-flex">
              <div className="common-select header-lang-select header-select-div">
                <HiOutlineGlobeAlt className="lang-icon" />
                <ReactSelectFiled
                  options={LanguagesArray}
                  value={language}
                  currentValue={language}
                  onSelectOption={onSelectLanguage}
                  className="header-select common-select-r"
                />
              </div>
              {isLoged ? (
                <div className="common-select login-header-ANI-btn header-lang-select-climber header-select-div p-0">
                  <Dropdown
                    isOpen={openDropdown}
                    toggle={() => setOpenDropdown(!openDropdown)}
                    className="dashboard-earlyaction"
                  >
                    <DropdownToggle
                      className="login-button-conatiner header-patner-text"
                      aria-controls="example-collapse-text"
                      aria-expanded={!openDropdown}
                      data-title={
                        JSON.parse(isLoged)?.user_details?.[1]?.first_name
                          ?.length >= 8
                          ? `${
                              JSON.parse(isLoged)?.user_details?.[1]?.first_name
                                ? JSON.parse(isLoged)?.user_details?.[1]
                                    ?.first_name
                                : ""
                            } ${
                              JSON.parse(isLoged)?.user_details?.[1]
                                ?.middle_name
                                ? JSON.parse(isLoged)?.user_details?.[1]
                                    ?.middle_name
                                : ""
                            } ${
                              JSON.parse(isLoged)?.user_details?.[1]?.last_name
                                ? JSON.parse(isLoged)?.user_details?.[1]
                                    ?.last_name
                                : ""
                            }`
                          : null
                      }
                    >
                      <div className="login-user-btn">
                        <span className="login-user-content">
                          <BiUser />{" "}
                          {JSON.parse(isLoged)?.user_details?.[1]?.first_name}
                        </span>
                      </div>
                      <BsChevronDown
                        onClick={() => setOpenDropdown(!openDropdown)}
                        className="login-down-arrow"
                      />
                    </DropdownToggle>
                    <DropdownMenu>
                      {JSON.parse(isLoged)?.user_details?.[0]?.is_staff && (
                        <DropdownItem
                          onClick={() => history("/settings")}
                          className="aware-header-dropdown"
                        >
                          {" "}
                          <IoMdSettings className="aware-header-icon" />
                          <p className="aware-header-text">
                            {t("DASHBOARD.SETTING")}
                          </p>
                        </DropdownItem>
                      )}
                      <DropdownItem
                        onClick={handleLogout}
                        className="aware-header-dropdown"
                      >
                        <IoLogOutOutline className="aware-header-icon" />
                        <p className="aware-header-text">
                          {t("DASHBOARD.LOGOUT")}
                        </p>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              ) : null}
            </div>
            <div className="development-text">
              <span> {t("CLIMBER_HEADER.DEVELOPMENT_VERSION")} </span>
            </div>
          </div>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default Header;
