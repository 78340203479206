import DASH_BOARD_CONST from "./actionTypes";

export const dashBoardMenuRequest = (country) => {
  return {
    type: DASH_BOARD_CONST.DASH_BOARD_MENU_REQUEST,
    payload: country,
  };
};
export const dashBoardMenuSuccess = (user) => {
  return {
    type: DASH_BOARD_CONST.DASH_BOARD_MENU_SUCCESS,
    payload: user,
  };
};
export const dashBoardMenuError = (error) => {
  return {
    type: DASH_BOARD_CONST.DASH_BOARD_MENU_ERROR,
    payload: error,
  };
};

export const indicatorRequest = (data) => {
  return {
    type: DASH_BOARD_CONST.INDICATOR_REQUEST,
    payload: data,
  };
};
export const indicatorSuccess = (user) => {
  return {
    type: DASH_BOARD_CONST.INDICATOR_SUCCESS,
    payload: user,
  };
};
export const indicatorError = (error) => {
  return {
    type: DASH_BOARD_CONST.INDICATOR_ERROR,
    payload: error,
  };
};
