import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import DasboardCommon from "../../components/Common/DasboardCommon";
import ClimberFooter from "../../components/Common/ClimberFooter";
import AwareHeader from "../../components/Common/AwareHeader";
import { isLogedIn } from "../../components/Common/Utils";
import DisclaimerModal from "../../components/Common/Disclaimer";

function DashboardLayout() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const value = isLogedIn();
    let parseValue = "";

    if (value) {
      parseValue = JSON.parse(value);
    }
    if (value === false || !parseValue?.user_details?.[0]?.is_verified) {
      navigate("/");
    }
  }, [window.location.pathname]);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <div className="layout-div">
      <AwareHeader />
      <div className="second-container">
        <div className="dashboard-main-page common-dashboard-ftr">
          {showModal ? (
            <DisclaimerModal modal={showModal} toggleModal={toggleModal} />
          ) : (
            ""
          )}
          <div className="d-flex dashboard-md">
            <DasboardCommon
              setOpen={setOpen}
              open={open}
              toggleModal={toggleModal}
            />
            <div
              className={
                window.location.pathname === "/create-online-bulletin"
                  ? "dashboard-drought"
                  : open
                  ? "dashboard-drought dashboard-drought-full"
                  : "dashboard-drought dashboard-drought-sm"
              }
            >
              <Outlet context={[open, setOpen]} />
            </div>
          </div>
          <ClimberFooter />
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
