const GEOGLOWS_CONST = {
  // geoglows Drainage sources
  GEOGLOWS_DRAINAGE_REQUEST: "GEOGLOWS_INDICES_REQUEST",
  GEOGLOWS_DRAINAGE_SUCCESS: "GEOGLOWS_INDICES_SUCCESS",
  GEOGLOWS_DRAINAGE_FAILED: "GEOGLOWS_INDICES_FAILED",
  // geoglows catchment sources
  GEOGLOWS_CATCHMENT_REQUEST: "GEOGLOWS_CATCHMENT_REQUEST",
  GEOGLOWS_CATCHMENT_SUCCESS: "GEOGLOWS_CATCHMENT_SUCCESS",
  GEOGLOWS_CATCHMENT_FAILED: "GEOGLOWS_CATCHMENT_FAILED",
};

export default GEOGLOWS_CONST;
