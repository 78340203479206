import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

function TermsAndConditions() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="mt-5">
      <h1 className="text-center">
        {t("TERMS_AND_CONDITIONS.TERMS_AND_CONDITIONS")}{" "}
      </h1>
      <div className="pt-3 pl-5 pr-5 pb-5">
        <p className="terms-conditions-text">
          {" "}
          {t("TERMS_AND_CONDITIONS_PAGE.MAIN_CONDITIONS")}{" "}
        </p>

        <p className="terms-conditions-text">
          {" "}
          {t("TERMS_AND_CONDITIONS_PAGE.ACCESS_TEXT")}{" "}
        </p>

        <p className="terms-conditions-text">
          {" "}
          {t("TERMS_AND_CONDITIONS_PAGE.CLIMBER_3RD_TEXT")}{" "}
        </p>

        <div className="mt-4">
          <h2> {t("TERMS_AND_CONDITIONS_PAGE.PERSONAL_INFORMATION")} </h2>
          <p className="terms-conditions-text">
            {t("TERMS_AND_CONDITIONS_PAGE.PERSONAL_ACCESSINFORMATION")}{" "}
          </p>
        </div>
        <div className="mt-4">
          <h2> {t("TERMS_AND_CONDITIONS_PAGE.COOKIES_AND_BECONS")} </h2>

          <p className="terms-conditions-text">
            {" "}
            {t("TERMS_AND_CONDITIONS_PAGE.COOKIES_ACCESS")}{" "}
          </p>

          <p className="terms-conditions-text">
            {" "}
            {t("TERMS_AND_CONDITIONS_PAGE.COOKIES_DECLINE")}{" "}
          </p>
        </div>
        <div className="mt-4">
          <h2> {t("TERMS_AND_CONDITIONS_PAGE.IP_ADDERSS")} </h2>

          <p className="terms-conditions-text">
            {" "}
            {t("TERMS_AND_CONDITIONS_PAGE.IP_PARA_1")}
          </p>
        </div>
        <div className="mt-4">
          <h2> {t("TERMS_AND_CONDITIONS_PAGE.THIRD_PARTY_SITES")}</h2>
          <p className="terms-conditions-text">
            {" "}
            {t("TERMS_AND_CONDITIONS_PAGE.THIRD_PARTY_PARA_1")}
          </p>
        </div>
        <div className="mt-4">
          <h3> {t("TERMS_AND_CONDITIONS_PAGE.CONTACT_US")}</h3>
          <p className="terms-conditions-text">
            {" "}
            {t("TERMS_AND_CONDITIONS_PAGE.CONTACT_US_DESCRIPTION")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
