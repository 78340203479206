import React, { useEffect } from "react";
import "./App.css";
import ReactGA from "react-ga4";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
// import * as L from "leaflet";
import ProtectedLayout from "./layout/ProtectedLayout.js";
import CommonLayout from "./layout/CommonLayout.js";
import ClimberLayout from "./layout/ClimberLayout";
import AwareLayout from "./layout/AwareLayout";
import DashboardLayout from "./layout/DashboardLayout";
import DashboardLayoutUnprotected from "./layout/DashboardUnprotectedLayout";
import { MeasurementID, ProductionSiteURL } from "./constants";
import {
  userRoutes,
  climberRoutes,
  awareRoutes,
  DashboardRoutes,
  DashboardRoutesUnprotected,
} from "./routes/allRoutes";
import Login from "./pages/Authentication/Login";
import { AXIOS_INSTANCE } from "./store/apiUtils/config";

if (window.location.hostname === ProductionSiteURL) {
  ReactGA.initialize(MeasurementID);
}

function App() {
  const location = useLocation();

  useEffect(() => {
    AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
      localStorage.getItem("i18nextLng");
  }, [localStorage.getItem("i18nextLng")]);

  useEffect(() => {
    if (window.location.hostname === ProductionSiteURL) {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });
    }
  }, [location]);

  return (
    <Routes>
      <Route element={<ClimberLayout />}>
        {climberRoutes.map((eachComponent) => (
          <Route
            path={eachComponent.path}
            element={eachComponent.component}
            key={eachComponent.path}
          />
        ))}
        <Route path="/" element={<Navigate to="climber" replace />} />
      </Route>

      <Route element={<AwareLayout />}>
        {awareRoutes.map((eachComponent) => (
          <Route
            path={eachComponent.path}
            element={eachComponent.component}
            // element={lazy(() => import(eachComponent.component))}
            key={eachComponent.path}
          />
        ))}
      </Route>
      <Route element={<CommonLayout />}>
        <Route path="/login" element={<Login />} />
      </Route>

      <Route element={<ProtectedLayout />}>
        {userRoutes.map((eachComponent) => (
          <Route
            path={eachComponent.path}
            element={eachComponent.component}
            key={eachComponent.path}
          />
        ))}
      </Route>

      <Route element={<DashboardLayout />}>
        {DashboardRoutes.map((eachComponent) => (
          <Route
            path={eachComponent.path}
            element={eachComponent.component}
            key={eachComponent.path}
          />
        ))}

        <Route path="/" element={<Navigate to="Dashborad" replace />} />
      </Route>

      <Route element={<DashboardLayoutUnprotected />}>
        {DashboardRoutesUnprotected.map((eachComponent) => (
          <Route
            path={eachComponent.path}
            element={eachComponent.component}
            key={eachComponent.path}
          />
        ))}
      </Route>
    </Routes>
  );
}

export default App;
