/* eslint-disable max-len */
import React, { memo, useState, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Input, Row, Col } from "reactstrap";
import { FiCheck } from "react-icons/fi";
import Cookies from "js-cookie";
import { GrFormClose } from "react-icons/gr";
import Textarea from "rc-textarea";
import {
  // getBulletinDateFormat,
  getBulletinMonthFormat,
  getBulletinDateFormatwithOutSubScript,
} from "../../../../helpers/index";
import MEE_IMAGE from "../../../../assest/img/onlineBulletinImg1.png";
import CGIAR from "../../../../assest/img/onlineBulletinImg2.png";
import IWMI from "../../../../assest/img/onlineBulletinImg3.png";
import PlusIcon from "../../../../assest/img/RoundPlus.png";
import CrossIcon from "../../../../assest/img/crossYellow.png";
// import Header from "../../../../assest/img/headerBoard.png";
import { showError } from "../../../../components/Common/Notification";
import { MONTHLY_BULLETIN } from "../../../../constants";

const logoList = [
  {
    id: 0,
    image: MEE_IMAGE,
    name: "cgair_wle",
  },
  {
    id: 1,
    image: CGIAR,
    name: "sadms",
  },
  {
    id: 2,
    image: IWMI,
    name: "iwmi",
  },
];

const bulletinContent = `1. Tracks the likelihood of a dry spell or droughts occurring over the following four weeks, as well as decreased rainfall.. 
2. Maps drought conditions at regional and national levels for a range of products such as rainfall anomaly, SPI, vegetation index, and composite drought index (IDSI) to assess overall drought impacts.
3. Determine the areas with short- and long-term drought outlooks, as well as drought alert maps.
4. Media briefing on drought-related affects in the areas.
5. The AWARE bulletin is released on the fifteenth of each month. View and download the most recent issues at https://www.iwmi.cgiar.org/resources/drought-monitringsystem/drought_bulletin/`;

function Branding({ queryPrams, title, setTitle }) {
  const { t } = useTranslation();
  const [isTitleEdit, setIsTitleEdit] = useState(false);
  const [isPreparedBy, setIsPreparedBy] = useState(false);
  const [file, setFile] = useState([]);
  const [shouldHide, setShouldHide] = useState(false);
  const [newLogoList, setNewLogoList] = useState(logoList);
  const [imageUrl, setImageUrl] = useState(null);
  const [isImage, setIsImage] = useState(false);
  const [value, setValue] = useState("");

  const defaultTitle =
    queryPrams?.bulletinType === MONTHLY_BULLETIN
      ? `${getBulletinMonthFormat(queryPrams.endDate)}`
      : `${getBulletinDateFormatwithOutSubScript(
          queryPrams.startDate,
        )} -${getBulletinDateFormatwithOutSubScript(queryPrams.endDate)}`;

  function uploadSingleFile(e) {
    setFile([...file, URL.createObjectURL(e.target.files[0])]);
    if (file.length === 1) {
      setShouldHide(true);
    }
  }

  const removeFile = (e) => {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
    setShouldHide(false);
  };

  function uploadBgFile(e) {
    if (e.target.files.length !== 0) {
      setImageUrl(URL.createObjectURL(e.target.files[0]));
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
          if (image.height <= image.width && image.width > 500) {
            setIsImage(true);
          } else {
            setIsImage(false);
            showError(
              `${t("CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.BG_IMAGE_ERROR")}`,
            );
          }
        };
      };
    }
  }

  function removeImage(id) {
    setNewLogoList(newLogoList.filter((item) => item.id !== id));
  }

  const defaultPreparedBy = `${t("CREATE_ONLINE_BULLETIN.PREPARED_BY")} : `;

  useEffect(() => {
    setValue(bulletinContent);
  }, []);

  const onChangeBulletin = (e) => {
    const {
      target: { value: currentValue },
    } = e;
    const currentRows = currentValue.split("\n");
    const wordCount = currentValue.length;
    if (wordCount < 1200) {
      currentRows?.length < 11 && setValue(currentValue);
    }
  };

  const changeTitle = () => {
    if (title === "") {
      showError(t("CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.TITLE_NEEDED"));
    } else {
      setIsTitleEdit(false);
    }
  };

  useEffect(() => {
    setTitle(defaultTitle);
  }, [defaultTitle]);

  const userDetails = JSON.parse(Cookies.get("user"))?.user_details?.[1];

  const [preparedBy, setPreparedBy] = useState(defaultPreparedBy);
  return (
    <div>
      <Row
        className=" mx-4 pb-2 px-4 pt-4  d-flex justify-content-between header-div"
        id="bulliten-logo"
      >
        <Col>
          <ul className="list-unstyled d-flex  justify-content-start footer-logos">
            <div className="logo-image">
              {!shouldHide ? (
                <div className="file-input-wrapper">
                  <img
                    src={PlusIcon}
                    alt="addIcon"
                    className="plus-icon indicator-icon"
                  />
                  <input
                    accept="image/*"
                    type="file"
                    disabled={file.length === 2}
                    name="image"
                    onChange={uploadSingleFile}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <li>
              {file.length > 0 &&
                file.reverse().map((item, index) => {
                  return (
                    <>
                      <img src={item} className="preview-image" alt="" />
                      <img
                        src={CrossIcon}
                        className="indicator-icon delete-cross"
                        alt="crossIcon"
                        onClick={() => removeFile(index)}
                        onKeyDown={() => removeFile(index)}
                        style={{ cursor: "pointer" }}
                      />
                    </>
                  );
                })}
            </li>
            {newLogoList.map((item, index) => (
              <li className="online-bulliten-header-logo">
                <a href={item.url} target="_blank" rel="noreferrer">
                  <img
                    src={item.image}
                    alt={item.name}
                    className={
                      index === newLogoList?.length - 1
                        ? "online-bulliten-header-logo bulliten-iwmi-logo"
                        : "online-bulliten-header-logo "
                    }
                  />
                </a>

                <img
                  src={CrossIcon}
                  className="indicator-icon delete-cross"
                  alt="crossIcon"
                  onKeyDown={() => removeImage(item.id)}
                  onClick={() => removeImage(item.id)}
                  style={{ cursor: "pointer" }}
                />
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row
        className="d-flex justify-content-between online-bulletin-header-div"
        style={{ backgroundImage: isImage ? `url(${imageUrl})` : "" }}
        id="bulliten-banner"
      >
        <Col md={7} className="title-div bulletin-header-text-card ">
          <Col className="title-div d-flex justify-content-start h-100">
            <div className="heading-area">
              <h4 className="category-type-div">{queryPrams.bulletinType}</h4>
              <h5 className="category-type-div-country">
                Region : {queryPrams.country}{" "}
                {queryPrams.state ? `, ${queryPrams?.state}` : ""}{" "}
                {queryPrams.district ? `, ${queryPrams?.district}` : ""}
              </h5>
              <h5 className="mb-1 text-div1">
                {isTitleEdit ? (
                  <div className="d-flex">
                    <Input
                      value={title}
                      title="Title"
                      placeholder="Enter Title"
                      onChange={(e) => setTitle(e.target.value)}
                      defaultValue={defaultTitle}
                      maxLength={50}
                    />
                    <FiCheck
                      size={30}
                      onClick={() => changeTitle()}
                      className="cursor-pointer green-icon mx-2 mt-1"
                    />
                    <GrFormClose
                      size={30}
                      color="#e86933"
                      className="cursor-pointer danger-icon mt-1"
                      onClick={() => {
                        setTitle(defaultTitle);
                        setIsTitleEdit(false);
                      }}
                    />
                  </div>
                ) : (
                  <span>
                    Dates : {title}
                    <FaEdit
                      id="hide-icon-1"
                      color="#dd9103"
                      size={18}
                      className="cursor-pointer mx-2 indicator-icon"
                      onClick={() => setIsTitleEdit(true)}
                    />
                  </span>
                )}
              </h5>
              <h5 className=" text-div sub-header-margin pt-1">
                {isPreparedBy ? (
                  <div className="d-flex">
                    <Input
                      value={preparedBy}
                      title="Title"
                      placeholder="Enter Title"
                      onChange={(e) => setPreparedBy(e.target.value)}
                      defaultValue={defaultPreparedBy}
                      maxLength={50}
                    />
                    <FiCheck
                      size={30}
                      onClick={() => setIsPreparedBy(false)}
                      className="cursor-pointer green-icon mx-2 mt-1"
                    />
                    <GrFormClose
                      size={30}
                      color="#e86933"
                      className="cursor-pointer danger-icon mt-1"
                      onClick={() => {
                        setPreparedBy(defaultPreparedBy);
                        setIsPreparedBy(false);
                      }}
                    />
                  </div>
                ) : (
                  <div className="d-flex">
                    <span className="mb-1 text-div-span  "> {preparedBy} </span>
                    <span className="mb-1 text-div-preparedby ">
                      {userDetails?.first_name} {userDetails?.last_name} ,{" "}
                      {userDetails?.organization_name} on{" "}
                      {getBulletinDateFormatwithOutSubScript(new Date())}
                      <FaEdit
                        id="hide-icon-2"
                        color="#dd9103"
                        size={18}
                        className="cursor-pointer mx-2 indicator-icon d-none"
                        onClick={() => setIsPreparedBy(true)}
                      />
                    </span>
                  </div>
                )}
              </h5>
            </div>
          </Col>
        </Col>
        <Col>
          <div className="file-input-wrapper edit-image">
            <FaEdit
              className="cursor-pointer mx-2 indicator-icon"
              id="hide-icon-1"
              color="#dd9103"
              size={18}
            />
            <input
              accept="image/*"
              type="file"
              name="image"
              onChange={uploadBgFile}
              style={{ cursor: "pointer" }}
            />
          </div>
        </Col>
      </Row>
      <Row id="how-to-use-bulliten">
        <Col md={12}>
          <div className="bulletin-summary">
            <div className="bullitenbanner d-flex align-items-center mt-4 mx-4">
              <h5 className="text-indi">How to use The Bulletin ?</h5>
            </div>

            <Textarea
              className="mb-3 mt-2 mx-3 text-area-border-radius text-area-width"
              placeholder={t(`BULLET_IN.RANDOM_TEXT`)}
              autoSize={{ minRows: 7, maxRows: 20 }}
              value={value}
              onChange={onChangeBulletin}
              // autoSize
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default memo(Branding);
