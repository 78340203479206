/* eslint-disable no-await-in-loop */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import domtoimage from "dom-to-image-more";
import { jsPDF as JSPDF } from "jspdf";
import _ from "lodash";
import * as FileSaver from "file-saver";

import { Button } from "reactstrap";
import { BsArrowLeft } from "react-icons/bs";
import { ReactComponent as DownloadIcon } from "../../assest/img/ea-download.svg";
import {
  getReadynessPhaseRequest,
  getEarlyFinanceReportRequest,
  deleteEarlyFinanceReferencesSuccess,
  editEarlyFinanceReportRequest,
  editEarlyFinanceReportSuccess,
  createEarlyFinanceReportSuccess,
  createEarlyFinanceReportRequest,
  sendEarlyFinanceReportRequest,
  sendEarlyFinanceReportSuccess,
} from "../../store/actions";
import { showSuccess } from "../../components/Common/Notification";
import GlobalLoader from "../../components/Common/Loader";
import SummaryComponent from "./SummaryComponent";
import BudgetReportComponent from "./BudgetReportComponent";
import Annexures from "./Annexures";
import GeneratePdfModal from "./GeneratePdfModal";
import ShareModal from "./ShareModal";

function EarlyFinanceReport() {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();

  const [, setOpen] = useOutletContext(); // for closing side bar when it is opening
  const [isGeneratedSelected, setIsGeneratedSelected] = useState(false);
  const [projectDetails, setProjectDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [interventationList, setInterventationList] = useState("");
  const [isLoadingPdf, setIsLoadingPDF] = useState(false);
  const [componentArr, setComponentArr] = useState([
    "report-summary",
    "budget-container",
    "annexures",
    "impacts",
    "possible-anticipatory-action",
    "action-plan",
    "media-reference",
  ]);
  const [linksData, setLinksData] = useState([]);
  const [isEditData, setIsEditData] = useState(false);
  const [reportData, setReportData] = useState({});
  const [pdfBase64, setPdfBase64] = useState("");
  const [pdfFilename, setPdfFilename] = useState("");
  const [isGeneratePdf, setIsGeneratePdf] = useState(false);
  const [isgeneratePdfModalOpen, setIsGeneratePdfModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const nextProps = useSelector((state) => ({
    interventionList: state?.EAReadynessReducer?.interventionList,
    getReportData: state?.EFReportReducer?.getReportData,
    isGetReportLoading: state?.EFReportReducer?.isGetReportLoading,
    createReportData: state?.EFReportReducer?.createReport,
    editReportData: state?.EFReportReducer?.editReport,
    deleteReferenceData: state?.EFReportReducer?.deleteReference,
    sendReportData: state?.EFReportReducer?.sendReport,
  }));

  const params = new URLSearchParams(window.location.search);
  const isFinance = params.get("isFinance");
  useEffect(() => {
    setOpen(false);
    const data = JSON.parse(localStorage.getItem("projectDetails"));
    const user = JSON.parse(localStorage.getItem("loggedInUserDetails"));
    setUserDetails(user);
    setProjectDetails(data);

    dispatch(
      getReadynessPhaseRequest({
        module: "early_finance",
        project_id: data?.id,
        data: 0,
      }),
    );
    dispatch(
      getEarlyFinanceReportRequest({
        project_id: data?.id,
        user_id: user?.user_details[0]?.id,
      }),
    );
    window.scrollTo(0, 0);
    return () => {
      dispatch(deleteEarlyFinanceReferencesSuccess({}));
      dispatch(editEarlyFinanceReportSuccess({}));
      dispatch(createEarlyFinanceReportSuccess({}));
      dispatch(sendEarlyFinanceReportSuccess({}));
    };
  }, []);

  // INTERVENTION LIST DATA
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (nextProps?.interventionList?.length) {
      setInterventationList(nextProps?.interventionList);
      // Adding ids into components array for downloading pdf
      const updatedArr = [...componentArr];
      nextProps?.interventionList.forEach((item) => {
        const inputString = item?.name;
        const replacedString = inputString.replace(/ /g, "_");
        updatedArr.splice(updatedArr.length - 1, 0, replacedString);
      });
      setComponentArr(updatedArr);
    }
  }, [nextProps.interventionList]);

  // GET REPORT DATA
  const isSecondRun = useRef(true);
  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }

    if (!nextProps.isGetReportLoading) {
      if (nextProps?.getReportData?.length) {
        setIsEditData(true);
        const report = nextProps.getReportData[0];
        setReportData(report);
        if (
          report?.media_reference_file?.length ||
          report?.media_reference_url?.length
        ) {
          const media_reference_file = report.media_reference_file || [];
          const media_reference_url = report.media_reference_url || [];
          // Combine the arrays and remove null values
          const data = [...media_reference_file, ...media_reference_url].filter(
            (item) => item !== null || item !== "",
          );
          setLinksData(data);
        } else {
          setLinksData([]);
        }
      } else {
        setReportData({});
        setIsEditData(false);
      }
    }
  }, [nextProps.getReportData]);

  // EDIT REPORT API
  const isThridRun = useRef(true);
  useEffect(() => {
    if (isThridRun.current) {
      isThridRun.current = false;
      return;
    }
    if (nextProps?.editReportData?.length) {
      if (isGeneratePdf) {
        setIsGeneratePdfModalOpen(true);
      } else {
        showSuccess(nextProps?.editReportData);
      }
      dispatch(
        getEarlyFinanceReportRequest({
          project_id: projectDetails?.id,
          user_id: userDetails?.user_details[0]?.id,
        }),
      );
    }
  }, [nextProps.editReportData]);

  // CREATE REPORT API
  const isFourthRun = useRef(true);
  useEffect(() => {
    if (isFourthRun.current) {
      isFourthRun.current = false;
      return;
    }
    if (nextProps?.createReportData?.length) {
      if (isGeneratePdf) {
        setIsGeneratePdfModalOpen(true);
      } else {
        showSuccess(nextProps?.createReportData);
      }
      dispatch(
        getEarlyFinanceReportRequest({
          project_id: projectDetails?.id,
          user_id: userDetails?.user_details[0]?.id,
        }),
      );
    }
  }, [nextProps.createReportData]);

  // Delete REPORT DETAILS
  const isDeleteRun = useRef(true);
  useEffect(() => {
    if (isDeleteRun.current) {
      isDeleteRun.current = false;
      return;
    }
    if (nextProps?.deleteReferenceData?.length) {
      showSuccess(nextProps?.deleteReferenceData);
      dispatch(
        getEarlyFinanceReportRequest({
          project_id: projectDetails?.id,
          user_id: userDetails?.user_details[0]?.id,
        }),
      );
    }
  }, [nextProps.deleteReferenceData]);

  // SEND MAIL
  const isSendRun = useRef(true);
  useEffect(() => {
    if (isSendRun.current) {
      isSendRun.current = false;
      return;
    }
    if (nextProps?.sendReportData?.length) {
      showSuccess(nextProps?.sendReportData);
    }
  }, [nextProps.sendReportData]);

  const handleClickBack = () => {
    history(
      isFinance
        ? `/early-finance?isFinance=true&slug=early_finance`
        : `/early-finance?slug=early_finance`,
    );
  };

  /**
   *
   * @param {*} isSave
   * Function for generating PDF
   * Based on isSave parameter we will Download PDF or GENERATE PDF INTO BASE64
   */
  const generatePdf = async (isSave) => {
    setIsLoadingPDF(true);
    const margin = 30; // Adjust the margin

    const pdf = new JSPDF({ compress: true, format: "a4" });

    for (let i = 0; i < componentArr.length; i += 1) {
      const componentId = componentArr[i];

      const component = document.getElementById(componentId);

      if (componentId !== "media-reference") {
        const componentImage = await domtoimage.toPng(component);
        // Get width and height of the component element
        const height = component.offsetHeight;
        const width = component.offsetWidth;

        const newWidth = 1400 + 2 * margin; // adding margin to width
        const newHeight = height + 2 * margin; // adding margin to height

        pdf.internal.pageSize.width = newWidth; // setting pdf page width
        pdf.internal.pageSize.height = newHeight; // setting pdf page height

        pdf.addImage(componentImage, "PNG", margin, margin, width, height);
      } else {
        pdf.internal.pageSize.width = 1460; // setting pdf page width
        pdf.internal.pageSize.height = 1200; // setting pdf page height

        const handleFont = (fontSize) => {
          return fontSize * (pdf.internal.pageSize.width / 1460); // Adjust font size ratio
        };

        pdf.setFontSize(handleFont(70));
        linksData?.length ? pdf.text("Media References", 40, 40) : "";

        const startY = 100; // Initial Y-coordinate for the first link
        let currentY = startY; // Current Y-coordinate for adding links
        const lineHeight = 40; // Line height between links

        // Add a link
        pdf.setTextColor("blue");
        pdf.setDrawColor("blue");
        pdf.setFontSize(handleFont(48));
        linksData.forEach((item, index) => {
          pdf.textWithLink(`${index + 1}. ${item}`, 40, currentY, {
            url: item,
          });
          currentY += lineHeight; // Increment Y-coordinate for the next link
        });

        const footerComponent = document.getElementById("ef-footer");

        const componentImage = await domtoimage.toPng(footerComponent);
        // Get width and height of the component element
        const height = footerComponent.offsetHeight;
        const width = footerComponent.offsetWidth;

        const remainingHeight = 1200 - height - 2 * margin;

        if (height <= remainingHeight) {
          pdf.addImage(
            componentImage,
            "PNG",
            margin,
            1200 - height - margin,
            width,
            height,
          );
        } else {
          pdf.addImage(componentImage, "PNG", margin, margin, width, height);
        }
      }

      // For not adding blank page at last of pdf
      if (i !== componentArr.length - 1) {
        pdf.addPage();
      }
    }
    setPdfFilename("ALERT_REPORT.pdf");
    if (isSave) {
      // DOWNLOADING ATTACHMENTS WITH REPORT
      if (reportData?.attachments?.length) {
        reportData?.attachments.forEach((link) => {
          fetch(link)
            .then((response) => response.blob())
            .then((blob) => {
              // Trigger the download using the desired filename and file-saver
              FileSaver.saveAs(blob, link);
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.error("Error downloading file:", error);
            });
        });
      }
      pdf.save("ALERT_REPORT.pdf");
      setIsLoadingPDF(false);
    } else {
      // Convert the saved PDF to base64
      const reader = new FileReader();
      reader.onload = () => {
        const base64PDF = reader.result.split(",")[1]; // Get the base64 part of the data URI
        setIsLoadingPDF(false);
        // Now you have the base64 representation of the saved PDF
        setPdfBase64(base64PDF);
      };
      reader.readAsDataURL(pdf.output("blob"));
    }
  };

  const handleCreatePdf = () => {
    const payload = {
      project_id: projectDetails?.id,
      pdf: {
        base64: pdfBase64,
        file: pdfFilename,
      },
    };

    if (!_.isEmpty(reportData)) {
      payload.id = reportData.id;
      dispatch(editEarlyFinanceReportRequest(payload));
    } else {
      dispatch(createEarlyFinanceReportRequest(payload));
    }
  };

  useEffect(() => {
    if (pdfBase64?.length && pdfFilename?.length) {
      handleCreatePdf();
    }
  }, [pdfBase64, pdfFilename]);

  const handlePreviewReport = () => {
    // setIsGeneratedSelected(!isGeneratedSelected);
    setIsGeneratePdf(false);
    window.scrollTo(0, 0);
  };

  const handleEmail = (emails, description) => {
    const payload = {
      id: isEditData ? reportData?.id : "",
      email: emails,
      description,
    };
    dispatch(sendEarlyFinanceReportRequest(payload));
  };

  return (
    <div className="ea-project-container dashboard-filter-div">
      {isgeneratePdfModalOpen ? (
        <GeneratePdfModal
          modal={isgeneratePdfModalOpen}
          toggle={setIsGeneratePdfModalOpen}
          handlePreviewReport={handlePreviewReport}
        />
      ) : (
        ""
      )}
      {isShareModalOpen ? (
        <ShareModal
          modal={isShareModalOpen}
          toggle={setIsShareModalOpen}
          handleSendEmail={handleEmail}
        />
      ) : (
        ""
      )}
      <div className="loader-div">
        {isLoadingPdf && <GlobalLoader info="earlyFinanceReport" />}
      </div>
      <div className="EA-Header ea-space-between">
        <div>
          <BsArrowLeft
            className="back-icon-ea"
            onClick={handleClickBack}
            role="button"
          />
          <p className="ea-header-text">
            {!isGeneratedSelected
              ? t("EARLY_FINANCE_REPORT.ALERT_REPORT")
              : t("EARLY_FINANCE_REPORT.REPORT_PREVIEW")}
          </p>
        </div>
        <div className="d-flex buttons-gap">
          {isGeneratedSelected ? (
            <span
              role="button"
              className="ea-upload-button"
              onClick={() => {
                generatePdf(true);
              }}
              onKeyUp={() => {}}
              tabIndex="0"
              aria-label="EfGeneratePdf"
            >
              <DownloadIcon />
            </span>
          ) : (
            ""
          )}
          {!isGeneratedSelected ? (
            <Button
              type="button"
              className="generate-report-btn"
              onClick={() => {
                setOpen(false);
                generatePdf(false);
                setIsGeneratePdf(true);
                setIsGeneratedSelected(!isGeneratedSelected);
              }}
            >
              {t("EARLY_FINANCE_REPORT.PUBLISH_REPORT")}
            </Button>
          ) : (
            <Button
              type="button"
              className="generate-report-btn"
              onClick={() => {
                setIsShareModalOpen(true);
              }}
            >
              {t("EARLY_FINANCE_REPORT.SHARE")}
            </Button>
          )}
        </div>
      </div>
      <div id="ui-content">
        <SummaryComponent
          isGenerate={isGeneratedSelected}
          projectDetails={projectDetails}
          isEditData={isEditData}
          reportData={reportData}
        />
        <BudgetReportComponent
          tableListingData={interventationList}
          isGeneratedSelected={isGeneratedSelected}
          projectDetails={projectDetails}
          reportData={reportData}
          userDetails={userDetails}
        />
        <Annexures
          isGeneratedSelected={isGeneratedSelected}
          tableListingData={interventationList}
          setComponentArr={setComponentArr}
          componentArr={componentArr}
        />
      </div>
    </div>
  );
}

export default EarlyFinanceReport;
