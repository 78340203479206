import React from "react";
import { Row, Col } from "reactstrap";
import { BsGlobe } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import CGIAR from "../../assest/img/CigarLogoResearch.svg";
import IWMI from "../../assest/img/Iwmi.svg";

function EFFooter() {
  const { t } = useTranslation();
  const contactPersons = [
    {
      name: "Dr. Giriraj Amarnath",
      email: "a.giriraj@cgiar.org",
      icon: GrMail,
    },
    {
      name: "Mr. Niranga Alahacoon",
      email: "n.alahacoon@cgiar.org",
      icon: GrMail,
    },
  ];

  const contactSite = {
    name: "Website",
    email: "https://awaredemo.iwmi.org/",
    icon: BsGlobe,
  };

  const copyright = "@Copyrights 2023 AWARE";

  return (
    <div className="bulletin-footer pb-2 pt-4 w-100" id="ef-footer">
      <Row className="title">
        <Col md={5}>
          <h6 style={{ fontSize: "20px" }}>
            {t("CREATE_ONLINE_BULLETIN.BULLETIN_FOOTER.FURTHUR_INFO")}
          </h6>
          {contactPersons.map((item) => {
            return (
              <div className="contacts" key={item.email}>
                <p>
                  <span className="contact-icon">
                    <item.icon />
                  </span>{" "}
                  {item.name} : {item.email}
                </p>
              </div>
            );
          })}
        </Col>
        <Col md={7} className="text-center">
          <ul className="list-unstyled d-flex  justify-content-end">
            <li>
              <img src={CGIAR} alt="sadms" className="preview-image1" />{" "}
            </li>
            <li>
              <img src={IWMI} alt="iwmi" className="preview-image1" />{" "}
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="d-flex pt-3 div-footer">
        <Col md={6}>
          <h6 className="contacts1">{copyright}</h6>
        </Col>
        <Col md={6}>
          <div className="contacts1 space-div">
            <p>
              <span className="contact-icon">
                <contactSite.icon />
              </span>{" "}
              {contactSite.name} :{" "}
              <a
                href={contactSite.email}
                target="_blank"
                rel="noreferrer"
                className="report-ref-link"
              >
                {" "}
                {t("EARLY_FINANCE.FOOTER_LINK")}{" "}
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default EFFooter;
