import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function EarlyActionHeader() {
  const { t } = useTranslation();
  const history = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const selectedSubModule = params.get("slug");

  return (
    <div className="EA-Header ea-space-between">
      <div className="back-office-center">
        <p className="ea-header-text">{t("EARLY_ACTIONS.EARLY_ACTIONS")} </p>
      </div>
      <Button
        className="ea-create-btn"
        onClick={() => history(`/ea-projects?slug=${selectedSubModule}`)}
      >
        {" "}
        {t("EARLY_ACTIONS.PROCEED")}
      </Button>
    </div>
  );
}

export default EarlyActionHeader;
