import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import * as xlsx from "xlsx";
import * as FileSaver from "file-saver";
import _, { isArray } from "lodash";

import { ReactComponent as UploadIcon } from "../../../assest/img/ea-upload.svg";
import { ReactComponent as DownloadIcon } from "../../../assest/img/ea-download.svg";
import { ReactComponent as PlusIcon } from "../../../assest/img/plus-icon.svg";
import { ReactComponent as EditIcon } from "../../../assest/img/ea-edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assest/img/ea-delete.svg";
import {
  editPreparednessContentRequest,
  editPreparednessContentSuccess,
  deletePreparednessContentRequest,
  deletePreparednessContentSuccess,
  getPreparednessPhaseRequest,
  createPreparednessContentRequest,
  createPreparednessContentSuccess,
  projectStatusRequest,
  projectStatusSuccess,
} from "../../../store/actions";
import {
  showError,
  showSuccess,
} from "../../../components/Common/Notification";
import { htmlToText, isLogedIn } from "../../../components/Common/Utils";
import { MAX_ROWS } from "../../../constants";
import PreparednessPhaseTable from "../Table";
import SideEditor from "../SideEditor";
import DeleteModal from "../DeleteModal";
import Comments from "../../../components/Common/Comments";
import { handleExportToCSVExcel } from "../../../helpers";

function CategoriesComponent({
  data,
  handleNext,
  projectDetails,
  slugType,
  editableRowKeys,
  setEditableRowKeys,
  preparednessPhaseDataOptions,
  isDefaultData,
  addInstance,
  deleteInstance,
  editInstance,
  isAllStepsCompleted,
  isUpdateAll,
  onHanldeDisable,
  // setIsDisable
}) {
  const projectData = JSON.parse(localStorage.getItem("projectDetails"));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [openEditor, setOpenEditor] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [type, setType] = useState("");
  const [rowData, setRowData] = useState({});
  const [addNewRow, setAddNewRow] = useState({
    "Category of impacts": "",
    Description: [],
  });
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [sendComment, setSendComment] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [isUpdateComment, setIsUpdateComment] = useState("");

  const userDetails = JSON.parse(isLogedIn());
  const nextProps = useSelector((state) => ({
    editedPreparednessPhaseData:
      state?.EAPreparednessPhaseReducer?.editedPreparednessPhaseData,
    deletePreparednessPhaseData:
      state?.EAPreparednessPhaseReducer?.deletePreparednessPhaseData,
    createPreparednessPhaseData:
      state?.EAPreparednessPhaseReducer?.createPreparednessPhaseData,
    createContentError: state?.EAPreparednessPhaseReducer?.createContentError,
    editContentError: state?.EAPreparednessPhaseReducer?.editContentError,
    projectStatusMessage: state?.EaProjects?.projectStatus,
    isTableUpdate: state.EAPreparednessPhaseReducer?.tableUpdate,
  }));

  // to show diable effect when we open editor modal
  useEffect(() => {
    onHanldeDisable(openEditor); // deleteModal
  }, [openEditor]);

  // to show diable effect when we open delete modal
  useEffect(() => {
    onHanldeDisable(deleteModal); // deleteModal
  }, [deleteModal]);

  // updating local storage when the edit is sucessfull
  const isEditProjectSucess = useRef(true);
  useEffect(() => {
    if (isEditProjectSucess.current) {
      isEditProjectSucess.current = false;
      return;
    }
    if (nextProps.projectStatusMessage) {
      localStorage.setItem(
        "projectDetails",
        JSON.stringify({
          ...projectData,
          ea_pp_category_status: true,
        }),
      );
      if (editInstance) {
        handleNext(1);
      } else {
        showError(t("VALIDATION.PROJECT_PENDING_WARNING"));
      }
    }
  }, [nextProps.projectStatusMessage]);

  const onSubmit = () => {
    // checking if impacts status is true navigate directly to impacts
    if (projectData?.ea_pp_impact_status) {
      handleNext(1);
    } else {
      if (projectData.ea_pp_category_status) {
        // if user have edit instance the navigate diretly else showing error
        if (editInstance) {
          handleNext(1);
        } else {
          showError(t("VALIDATION.PROJECT_PENDING_WARNING"));
        }
      } else {
        const payload = {
          project_id: projectDetails?.id,
          ea_pp_category_status: true,
        };
        dispatch(projectStatusRequest(payload));
      }
    }
  };

  const handleCloseModal = () => {
    // onHanldeDisable(!openEditor)
    // setIsDisable()
    setOpenEditor(!openEditor);
    setRowData({});
    setAddNewRow({
      "Category of impacts": "",
      Description: [],
    });
    setEditableRowKeys({});
  };

  const handleEditRow = (content) => {
    localStorage.setItem("isDefaultCategory", "false");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // onHanldeDisable(true)
    setOpenEditor(true);
    setType("category");
    setRowData(content);
    setIsEditable(true);
    setEditableRowKeys(content);
  };

  const handleDeleteRow = (content) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    localStorage.setItem("isDefaultCategory", "false");
    setDeleteModal(true);
    setRowData(content);
  };

  const handleAddNewRow = () => {
    localStorage.setItem("isDefaultCategory", "false");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // onHanldeDisable(true)
    setOpenEditor(true);
    setType("category");
    setRowData({});
    setIsEditable(false);
  };

  const handleGetTableData = () => {
    dispatch(
      getPreparednessPhaseRequest({
        slug: slugType,
        project_id: projectDetails?.id,
        is_default: isDefaultData,
        isUpdateAll: false,
      }),
    );
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    return () => {
      dispatch(editPreparednessContentSuccess({}));
      dispatch(deletePreparednessContentSuccess({}));
      dispatch(createPreparednessContentSuccess({}));
      dispatch(projectStatusSuccess({}));
    };
  }, []);

  // For create
  const isCreateContentSuccess = useRef(true);
  useEffect(() => {
    if (isCreateContentSuccess.current) {
      isCreateContentSuccess.current = false;
      return;
    }
    if (!_.isEmpty(nextProps.createPreparednessPhaseData)) {
      setOpenEditor(false);
      setRowData({});
      setEditableRowKeys({});
      setAddNewRow({
        "Category of impacts": "",
        Description: [],
      });
      showSuccess(nextProps.createPreparednessPhaseData?.data);
      handleGetTableData();
    } else if (!_.isEmpty(nextProps.createContentError)) {
      setOpenEditor(false);
      setRowData({});
      setEditableRowKeys({});
      setAddNewRow({
        "Category of impacts": "",
        Description: [],
      });
    }
  }, [nextProps.createPreparednessPhaseData, nextProps.createContentError]);
  // For Edit
  const isEditContentSucess = useRef(true);
  useEffect(() => {
    if (isEditContentSucess.current) {
      isEditContentSucess.current = false;
      return;
    }
    if (!_.isEmpty(nextProps.editedPreparednessPhaseData)) {
      setOpenEditor(false);
      setRowData({});
      setEditableRowKeys({});
      setAddNewRow({
        "Category of impacts": "",
        Description: [],
      });
      sendComment === "" &&
        showSuccess(nextProps.editedPreparednessPhaseData?.data);

      handleGetTableData();
    } else if (!_.isEmpty(nextProps.editContentError)) {
      setOpenEditor(false);
      setRowData({});
      setEditableRowKeys({});
      setAddNewRow({
        "Category of impacts": "",
        Description: [],
      });
    }
  }, [nextProps.editedPreparednessPhaseData, nextProps.editContentError]);

  // For add row options
  useEffect(() => {
    if (preparednessPhaseDataOptions?.length) {
      const categoriesOptionsArr =
        preparednessPhaseDataOptions[0]?.content?.map((item) => {
          return {
            label: item["Category of impacts"],
            value: item["Category of impacts"],
            description: item.Description,
          };
        });
      categoriesOptionsArr.push({ label: "Others", value: "Others" });
      setCategoriesOptions([...categoriesOptionsArr]);
    }
  }, [preparednessPhaseDataOptions]);

  const isDeleteContentSucess = useRef(true);
  useEffect(() => {
    if (isDeleteContentSucess.current) {
      isDeleteContentSucess.current = false;
      return;
    }
    if (!_.isEmpty(nextProps.deletePreparednessPhaseData)) {
      showSuccess(nextProps.deletePreparednessPhaseData?.data);
      handleGetTableData();
    }
  }, [nextProps.deletePreparednessPhaseData]);

  const COLUMNS = [
    {
      Header: t("EARLY_ACTIONS.CATEGORY_OF_IMPACTS"),
      accessorKey: "categoryOfImpacts",
      size: 150,
    },
    {
      Header: t("EARLY_ACTIONS.DESCRIPTION"),
      accessorKey: "description",
      size: 350,
      Cell: (row) =>
        row.renderedCellValue.map((item) => (
          <div dangerouslySetInnerHTML={{ __html: item }} />
        )),
    },
    {
      Header: t("EARLY_ACTIONS.MANAGE_DATA"),
      accessorKey: "view",
      size: 60,
    },
  ];

  const FILTERED_COLUMNS = COLUMNS.filter(
    (column) => column.accessorKey !== "view",
  );

  // For table data
  useEffect(() => {
    const modifiedData = [];
    if (data?.length) {
      data[0]?.content.forEach((contentData, index) => {
        const modifedColumn = {};
        modifedColumn.id = index;
        modifedColumn.categoryOfImpacts = contentData["Category of impacts"];
        modifedColumn.description = isArray(contentData?.Description)
          ? [...contentData?.Description]
          : [contentData?.Description];
        modifedColumn.view = (
          <div className="d-flex align-items-center justify-content-around">
            {editInstance ? (
              <span>
                <EditIcon
                  onClick={() => {
                    handleEditRow(contentData);
                    setIsUpdateComment(false);
                  }}
                  className="cursor-pointer"
                />
              </span>
            ) : (
              ""
            )}
            {deleteInstance ? (
              <span>
                <DeleteIcon
                  className="cursor-pointer"
                  onClick={() => {
                    handleDeleteRow(contentData);
                    setIsUpdateComment(false);
                  }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        );
        modifiedData.push(modifedColumn);
      });
    }
    if (isUpdateAll) {
      setSendComment("");
      setCommentList(data[0]?.comment);
      modifiedData?.length ? setTableData(modifiedData) : setTableData([]);
    } else {
      if (isUpdateComment) {
        setSendComment("");
        setCommentList(data[0]?.comment);
      } else {
        if (modifiedData?.length) {
          setTableData(modifiedData);
        } else {
          setTableData([]);
        }
      }
    }
  }, [data]);

  const handleEditApiCall = (variant) => {
    let payload;
    if (variant === "New") {
      // !FOR ADDING NEW DATA THEIR IS NO NEED TO SEND OLD CONTENT AND SLUG
      payload = {
        project_id: projectDetails?.id,
        new_content: [addNewRow],
      };
      dispatch(createPreparednessContentRequest(payload));
    } else {
      // !FOR EDITING DATA WE NEED TO SEND OLD CONTENT AND NEW CONTENT
      payload = {
        project_id: projectDetails?.id,
        slug: slugType,
        old_content: [rowData],
        new_content: [editableRowKeys],
      };
      dispatch(editPreparednessContentRequest(payload));
    }
  };
  const handleDeleteApiCall = () => {
    const payload = {
      content: [
        {
          "Category of impacts": rowData["Category of impacts"],
          Description: rowData?.Description,
        },
      ],
      project_id: projectDetails?.id,
      slug: slugType,
    };
    dispatch(deletePreparednessContentRequest(payload));
  };

  // For uploading xlsx file
  const handleFileChange = (event) => {
    event.preventDefault();
    /**
     * ! Checking validation to upload only execel file
     */
    const validExtensions = [".xlsx", ".xls"];
    const fileName = event.target.files[0]?.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));
    if (!validExtensions.includes(fileExtension)) {
      showError(t("EARLY_ACTIONS.INVALID_FILE_TYPE"));
    } else if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const xlsxData = e.target.result;
        const workbook = xlsx.read(xlsxData, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);

        // Converting XLSX data into table data
        const modifiedData = [];
        if (json?.length) {
          json.forEach((contentData, index) => {
            const modifedColumn = {};
            modifedColumn.id = index;
            modifedColumn.categoryOfImpacts =
              contentData["Category of impacts"];
            modifedColumn.description = isArray(contentData?.Description)
              ? [...contentData?.Description]
              : [contentData?.Description];
            modifedColumn.view = (
              <div className="d-flex align-items-center justify-content-around">
                <span>
                  <EditIcon
                    onClick={() => handleEditRow(contentData)}
                    className="cursor-pointer"
                  />
                </span>
                <span>
                  <DeleteIcon
                    className="cursor-pointer"
                    onClick={() => handleDeleteRow(contentData)}
                  />
                </span>
              </div>
            );
            modifiedData.push(modifedColumn);
          });
        }

        const dataKeys = [...new Set(Object.keys(json[0]))];
        const fileKeysData = [
          ...new Set(["No.", "Category of impacts", "Description"]),
        ];

        /**
         * ! Checking if the headers are matching with the uploaded file
         * ! if not we will get an error
         * ! if file contains more than 20 rows then also we are showing an error
         */
        const isEqual = dataKeys?.length
          ? _.isEqual(dataKeys, fileKeysData)
          : false;
        if (isEqual) {
          if (modifiedData?.length <= MAX_ROWS) {
            // Need to set the data for API call
            // * Adding P tags to the description which don't have tags
            const customData = modifiedData.map((item) => {
              return {
                "Category of impacts": item.categoryOfImpacts,
                Description: item.description?.length
                  ? "<p>".includes(item.description[0])
                    ? item.description[0]
                    : item.description[0]?.length
                    ? `<p>${item.description[0]}</p>`
                    : ""
                  : ``,
              };
            });
            // If we have data then we will call EDIT API or else we will call CREATE API
            tableData?.length
              ? dispatch(
                  editPreparednessContentRequest({
                    project_id: projectDetails?.id,
                    slug: slugType,
                    new_content: customData,
                  }),
                )
              : dispatch(
                  createPreparednessContentRequest({
                    project_id: projectDetails?.id,
                    new_content: customData,
                  }),
                );
          } else {
            showError(t("EARLY_ACTIONS.MORE_ROWS_ERROR"));
          }
        } else {
          showError(t("EARLY_ACTIONS.INCORRECT_FILE_TYPE"));
        }
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const hanldeExportToCSV = () => {
    // Need to set the headers of xlsx
    const customData = tableData.map((item) => ({
      "No.": item.id + 1,
      "Category of impacts": item.categoryOfImpacts,
      Description: {
        t: "s", // Set the type to 's' for string
        v: htmlToText(item.description[0]), // Set the cell value
        s: {
          wrapText: true, // Enable text wrapping
        },
      },
      // Description: htmlToText(item.description[0]),
    }));

    const ws = xlsx.utils.json_to_sheet(customData);

    // Setting the header row style to bold
    const headerStyle = {
      font: { bold: true },
    };
    const headerRange = xlsx.utils.decode_range(ws["!ref"]);
    // eslint-disable-next-line no-plusplus
    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
      const cellAddress = xlsx.utils.encode_cell({
        r: headerRange.s.r,
        c: col,
      });
      ws[cellAddress].s = headerStyle;
    }
    // Setting column widths
    const columnWidths = [
      { wpx: 80 }, // Width for "No." column
      { wpx: 150 }, // Width for "Direct Impacts" column
      { wpx: 150 }, // Width for "Indirect impacts" column
    ];

    ws["!cols"] = columnWidths;

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: "array" });
    const sheetData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(sheetData, `EA_PP_CATEGORY.xlsx`);
  };

  const onHandleDownloadexcel = () => {
    const columns = [
      { header: "No.", key: "id", width: 5, array: false },
      {
        header: "Category of impacts",
        key: "categoryOfImpacts",
        width: 50,
        array: false,
      },
      { header: "Description", key: "description", width: 100, array: false },
    ];
    handleExportToCSVExcel(tableData, columns);
  };

  const onHandleComments = () => {
    const currentCommnet = {
      name: userDetails?.user_details?.[1]?.first_name,
      comment: sendComment,
      date: new Date(),
    };
    setIsUpdateComment(true);
    const commentData = [currentCommnet];
    dispatch(
      editPreparednessContentRequest({
        slug: slugType,
        project_id: projectDetails?.id,
        comment: commentData,
      }),
    );
  };

  return (
    <div>
      {openEditor && (
        <SideEditor
          setOpenEditor={setOpenEditor}
          openEditor={openEditor}
          type={type}
          setRowData={setRowData}
          rowData={rowData}
          editableRowKeys={editableRowKeys}
          setEditableRowKeys={setEditableRowKeys}
          handleEditApiCall={handleEditApiCall}
          isEditable={isEditable}
          setAddNewRow={setAddNewRow}
          addNewRow={addNewRow}
          handleCloseModal={handleCloseModal}
          categoriesOptions={categoriesOptions}
          isDefaultData={isDefaultData}
        />
      )}
      {deleteModal && (
        <DeleteModal
          setDeleteModal={setDeleteModal}
          deleteModal={deleteModal}
          handleDeleteApiCall={handleDeleteApiCall}
        />
      )}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 className="category-header">{t("EARLY_ACTIONS.CATEGORIES")}</h3>
        <div className="actions-container d-flex align-items-center justify-content-end">
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          {addInstance && !isAllStepsCompleted ? (
            <span
              role="button"
              className="ea-upload-button"
              onClick={handleIconClick}
              onKeyUp={() => {}}
              tabIndex="0"
              aria-label="UploadIconCategory"
            >
              <UploadIcon />
            </span>
          ) : (
            ""
          )}
          {tableData?.length ? (
            <>
              {/* hidden temporarly */}
              <span
                role="button"
                className="ea-upload-button d-none"
                onClick={hanldeExportToCSV}
                onKeyUp={() => {}}
                tabIndex="0"
                aria-label="expoertcsvcategory"
              >
                <DownloadIcon />
              </span>

              <span
                role="button"
                className="ea-upload-button"
                onClick={onHandleDownloadexcel}
                onKeyUp={() => {}}
                tabIndex="0"
                aria-label="DownloadexcelAAP"
              >
                <DownloadIcon />
              </span>
            </>
          ) : (
            ""
          )}
          {tableData?.length <= MAX_ROWS &&
          !isAllStepsCompleted &&
          addInstance ? (
            <Button className="add-row-btn" onClick={handleAddNewRow}>
              {t("EARLY_ACTIONS.ADD_NEW_ROW")}
              <PlusIcon />
            </Button>
          ) : (
            ""
          )}
          <Button
            className="submit-next-btn"
            onClick={onSubmit}
            disabled={
              !Object.values(tableData).every(
                (value) =>
                  !["", "<p></p>"].includes(value?.categoryOfImpacts) &&
                  value?.description[0] !== "",
              ) || !tableData?.length
            }
          >
            {t("EARLY_ACTIONS.SUBMIT_NEXT")}
          </Button>
        </div>
      </div>
      <PreparednessPhaseTable
        COLUMNS={
          isAllStepsCompleted || (!editInstance && !deleteInstance)
            ? FILTERED_COLUMNS
            : COLUMNS
        }
        tableData={tableData}
      />
      {tableData?.length ? (
        <div className="mt-5">
          <Comments
            onHandleComments={onHandleComments}
            setComment={setSendComment}
            sendComment={sendComment}
            comment={sendComment}
            commentList={commentList}
            canAddComment={editInstance}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default CategoriesComponent;
