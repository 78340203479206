import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Footer from "../../assest/img/Footer-2.png";
import ClimberFooter from "../../components/Common/ClimberFooter";
import {
  climberBannerRequest,
  climberPartnerRequest,
} from "../../store/actions";
import Carousel from "../../components/Common/Carousel";

function OurPartner() {
  const { t } = useTranslation();
  const history = useNavigate();

  const dispatch = useDispatch();

  const [partnerImages, setPartnerImages] = useState({});

  /* ------handling api data---------*/
  const nextProps = useSelector((state) => ({
    bannerImages: state.Climber,
    partnerImages: state.Climber?.climberPartnerData,
  }));
  const handleClickBack = () => {
    history("/climber");
  };

  useEffect(() => {
    dispatch(
      climberBannerRequest({
        country_id: "",
        system: "partners",
        order: "ASC",
      }),
    );

    dispatch(
      climberPartnerRequest({
        order: "ASC",
      }),
    );
  }, []);

  // const groupedByCountry = _.groupBy(TeamsData?.result, "country_name");

  function groupByCountryName(array) {
    const groupedData = _.groupBy(array, "country_name");
    return groupedData;
  }

  const isPartnerImages = useRef(true);
  useEffect(() => {
    if (isPartnerImages.current) {
      isPartnerImages.current = false;
      return;
    }
    if (nextProps.partnerImages) {
      const finalList = groupByCountryName(nextProps.partnerImages);
      setPartnerImages(finalList);
    }
  }, [nextProps.partnerImages]);

  return (
    <div>
      <div>
        <Carousel
          carouselData={nextProps?.bannerImages?.climberBannerData || []}
        />
      </div>
      <div className="mx-5">
        <h3 className="bread-crum mt-2">
          <span
            onClick={handleClickBack}
            role="button"
            tabIndex="0"
            onKeyUp={() => {}}
            className="partner-home"
          >
            {t("HOME.GO_TO_ClimBeR")}
          </span>{" "}
          <span className="partner-h3">
            {t("CLIMBER_HOME_PAGE.OUR_PARTNERS")}
          </span>{" "}
        </h3>

        {Object.keys(partnerImages).map((key) => {
          const value = partnerImages[key];
          return key !== "null" ? (
            <div>
              <h3 className="partner-heading">{key}</h3>
              <Row className="grid-container-partner-page">
                {value.length
                  ? value.map((each) => {
                      return (
                        <div className="partner-card">
                          <img
                            src={each?.image}
                            alt="icon"
                            className="partners-image"
                          />
                        </div>
                      );
                    })
                  : ""}
              </Row>
            </div>
          ) : (
            <div>
              <hr className="mt-5" />

              <Row className="">
                {value.length
                  ? value.map((each) => {
                      return (
                        <Col sm={12} className="mt-2 mb-1">
                          <li className="mt-3">
                            <span className="mr-2 partners-font-size">
                              {" "}
                              {each?.name}
                            </span>{" "}
                            |
                            <a
                              href={each?.link}
                              target="_blank"
                              key={each?.name}
                              rel="noreferrer"
                              className="ml-2  partner-link-color partners-font-size"
                            >
                              {" "}
                              {each?.link}{" "}
                            </a>
                          </li>
                        </Col>
                      );
                    })
                  : ""}
              </Row>
            </div>
          );
        })}
      </div>
      <div className="mt-5 p-0">
        <img src={Footer} alt="Footer" className="w-100" />
      </div>
      <ClimberFooter page="climber" />
    </div>
  );
}
export default OurPartner;
