import L from "leaflet";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
  useMap,
  useMapEvents,
} from "react-leaflet";
import {
  RAPID_RESPONSE_NEWSFEED_DISASTER_TYPES,
  myConst,
} from "../../constants";

function MapContainerComponent({
  onHandleZoom,
  wrap,
  position,
  zooms,
  eventsData,
  handleSelectEvent,
}) {
  const [markers, setMarkers] = useState([]);

  function ChangeView({ center, zoom }) {
    const mapEvents = useMapEvents({
      zoomend: () => {
        onHandleZoom(mapEvents.getCenter(), mapEvents.getZoom());
      },
      dragend: () => {
        onHandleZoom(mapEvents.getCenter(), mapEvents.getZoom());
      },
    });

    const map = useMap();
    setInterval(() => {
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) map.invalidateSize();
    }, 100);
    if (zoom && center) map.setView(center, zoom);
    return null;
  }

  const setMapReference = (mapInstance) => {
    wrap.current = mapInstance;
  };

  const headerStyles = {
    fontFamily: "National",
    fontSize: "14px",
    fontWeight: "650",
    lineHeight: "18.16px",
    color: "#D87042",
    marginBottom: "0 !important",
    marginTop: "0.5rem !important",
  };

  const descriptionStyles = {
    fontFamily: "National",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "11px",
    color: "#363636",
    marginBottom: "0 !important",
    marginTop: "0.5rem !important",
  };

  const sourceStyles = {
    fontFamily: "National",
    fontSize: "10px",
    fontWeight: "500",
    lineHeight: "11px",
    color: "#363636",
    fontStyle: "italics",
    marginBottom: "0.5rem !important",
    marginTop: "0.5rem !important",
  };

  function customIcon(url) {
    return new L.Icon({
      iconUrl: url,
      iconSize: new L.Point(24, 24),
      className: "event-map-icon",
    });
  }

  const handleRenderMarkerIcon = (type) => {
    const disasterIcon = RAPID_RESPONSE_NEWSFEED_DISASTER_TYPES.filter(
      (disaster) => disaster.label === type || disaster.value === type,
    );

    return disasterIcon?.length ? customIcon(disasterIcon?.[0]?.icon) : null;
  };

  const createMarkers = (events) => {
    return events.map((event) => {
      const lat = event?.geometry
        ? event.geometry.lat ??
          (event.geometry.coordinates ? event.geometry.coordinates[1] : null)
        : null;
      const lon = event?.geometry
        ? event.geometry.lon ??
          (event.geometry.coordinates ? event.geometry.coordinates[0] : null)
        : null;
      const markerKey = `${lat}-${lon}-${event.name}`;

      return lat !== null && lon !== null ? (
        <Marker
          position={[lat, lon]}
          key={markerKey}
          eventHandlers={{
            mouseover: (e) => e.target.openPopup(),
            mouseout: (e) => e.target.closePopup(),
            click: (e) => {
              handleSelectEvent(event);
              e.target.openPopup();
            },
          }}
          icon={handleRenderMarkerIcon(event.type)}
        >
          <Popup>
            <div style={{ height: "100px", width: "220px" }}>
              <p style={headerStyles} title={event.name}>
                {event.name?.length > 30
                  ? `${event.name.slice(0, 30)} ...`
                  : event.name}
              </p>
              <p style={descriptionStyles}>
                {event.description?.length > 150
                  ? event.description.slice(0, 150)
                  : event.description}
              </p>
              <p style={sourceStyles}>source: {event.source}</p>
            </div>
          </Popup>
        </Marker>
      ) : null;
    });
  };

  const createClusterMarkers = (events) => {
    // Create an array to hold all marker elements
    const clusterMarkers = events.map((event) => {
      const lat = event?.geometry
        ? event.geometry.lat ??
          (event.geometry.coordinates ? event.geometry.coordinates[1] : null)
        : null;
      const lon = event?.geometry
        ? event.geometry.lon ??
          (event.geometry.coordinates ? event.geometry.coordinates[0] : null)
        : null;
      const markerKey = `${lat}-${lon}-${event.name}`;

      return lat !== null && lon !== null ? (
        <Marker
          title={event.name}
          position={[lat, lon]}
          key={markerKey}
          eventHandlers={{
            mouseover: (e) => e.target.openPopup(),
            mouseout: (e) => e.target.closePopup(),
            click: (e) => {
              handleSelectEvent(event);
              e.target.openPopup();
            },
          }}
          icon={handleRenderMarkerIcon(event.type)}
        >
          <Popup>
            <div style={{ height: "100px", width: "220px" }}>
              <p style={headerStyles} title={event.name}>
                {event.name?.length > 30
                  ? `${event.name.slice(0, 30)} ...`
                  : event.name}
              </p>
              <p style={descriptionStyles}>
                {event.description?.length > 150
                  ? event.description.slice(0, 150)
                  : event.description}
              </p>
              <p style={sourceStyles}>source: {event.source}</p>
            </div>
          </Popup>
        </Marker>
      ) : null;
    });
    return clusterMarkers;
  };

  /**
   * Spliting locations based on lat nd lon
   */
  const splitEvents = (events) => {
    const sameLocationData = [];
    const differentLocationData = [];

    events.forEach((event, index) => {
      const lat = event?.geometry
        ? event.geometry.lat ??
          (event.geometry.coordinates ? event.geometry.coordinates[1] : null)
        : null;
      const lon = event?.geometry
        ? event.geometry.lon ??
          (event.geometry.coordinates ? event.geometry.coordinates[0] : null)
        : null;

      const isMatched = events.some((otherItem, otherIndex) => {
        if (index !== otherIndex && otherItem.geometry) {
          const otherLat =
            otherItem.geometry.lat ??
            (otherItem.geometry.coordinates
              ? otherItem.geometry.coordinates[1]
              : null);
          const otherLon =
            otherItem.geometry.lon ??
            (otherItem.geometry.coordinates
              ? otherItem.geometry.coordinates[1]
              : null);
          return lat === otherLat && lon === otherLon;
        }
        return false;
      });

      if (isMatched) {
        sameLocationData.push(event);
      } else {
        differentLocationData.push(event);
      }
    });

    return { sameLocationData, differentLocationData };
  };

  /**
   * Differentiating the location of markers based on their latitude and longitude
   */
  useEffect(() => {
    if (eventsData?.length) {
      const { sameLocationData, differentLocationData } =
        splitEvents(eventsData);

      const sameLocationMarkers = createClusterMarkers(sameLocationData);
      const differentLocationMarkers = createMarkers(differentLocationData);
      setMarkers([...sameLocationMarkers, ...differentLocationMarkers]);
    } else {
      setMarkers([]);
    }
  }, [eventsData]);

  return (
    <MapContainer
      center={position}
      zoom={zooms}
      scrollWheelZoom
      zoomControl={false}
      className="map-container rp-map-container h-100 newsfeed-map-container"
      ref={setMapReference}
      maxZoom={10}
    >
      <ChangeView center={position} zoom={zooms} />
      <ZoomControl position="topright" className="zoommap" />
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="Street">
          <TileLayer
            options={{ tileSize: 256 }}
            attribution={myConst.TITLE_LAYER_ATTRIBUTE}
            url={myConst.TITLE_LAYER_URL}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Satellite">
          <TileLayer
            url={myConst.SATELLITE_TILE_LAYER_URL}
            options={{ tileSize: 256 }}
            attribution={myConst.SATELLITE_TILE_LAYER_ATTRIBUTE}
          />
        </LayersControl.BaseLayer>
      </LayersControl>
      {markers}
    </MapContainer>
  );
}

export default MapContainerComponent;
