import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
// Import Material React Table Translations
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_FR } from "material-react-table/locales/fr";

function PreparednessPhaseTable(props) {
  const { COLUMNS, tableData } = props;

  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const data = useMemo(() => tableData, [tableData]);

  const app_lng = localStorage.getItem("i18nextLng");

  return (
    <div className="impact-table">
      <MaterialReactTable
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 15, 20],
        }}
        columns={columns}
        data={data}
        muiTableBodyCellSkeletonProps={{
          sx: {
            maxHeight: "600px",
            overflowY: "auto",
          },
        }}
        muiTableContainerProps={{
          sx: {
            height: "100%",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            "&::-webkit-scrollbar": {
              padding: "8px",
              width: "7px",
            },
            "&::-webkit-scrollbar-track": {
              borderRadius: "10px",
            },

            "&::-webkit-scrollbar-thumb": {
              background: "#8b8b8b",
              borderRadius: "10px",
            },

            "&::-webkit-scrollbar-thumb:hover": {
              background: "#8b8b8b",
            },
          },
        }}
        enableBottomToolbar={!(tableData?.length < 10)}
        enableTopToolbar={false}
        enablePagination={!(tableData?.length < 10)}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableHiding={false}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableGlobalFilter={false}
        enableSorting={false}
        enableColumnDragging={false}
        enableStickyHeader
        muiTableHeadCellProps={{
          align: "left",
          sx: {
            backgroundColor: "#DFE7F3",
            color: "#33527A",
            fontFamily: "National",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 650,
            lineHeight: "normal",
            "&:not(:last-child)": {
              borderRight: "1px solid white",
            },
            "& .css-1y3afkq": {
              whiteSpace: "normal",
            },
            "&:first-child": {
              borderTopLeftRadius: "12px",
            },
            "&:last-child": {
              borderTopRightRadius: "12px",
            },
          },
        }}
        muiTableBodyCellProps={{
          align: "left",
          sx: {
            borderLeft: "1px solid white",
            color: "#363636",
            fontWeight: 500,
            fontFamily: "National",
            fontSize: "16px",
            fontStyle: "normal",
            lineHeight: "normal",
          },
        }}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        localization={
          app_lng === "en" ? MRT_Localization_EN : MRT_Localization_FR
        }
      />
    </div>
  );
}

export default PreparednessPhaseTable;
