import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Card,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { MdKeyboardArrowLeft } from "react-icons/md";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { FORECAST_LEAD_TIME_LIST } from "../../constants";
import { isSuperAdmin } from "../../components/Common/Utils";
import RightArrow from "../../assest/img/back-button.svg";
import filterIcon from "../../assest/img/mi_filter.svg";
import Image_not_available from "../../assest/img/Image_not_available.svg";
import { staticResourceRequest } from "../../store/actions";

function ManageContent({
  isHideBar,
  setHideBar,
  activeTab,
  toggle,
  file,
  file2,
  imageExtension,
  staticResourceList,
  pageNumber,
  pageCount,
  haltInfiniteScroll,
  setPageNumber,
  handleDeleteDataSource,
  setConfirmationModalOpen,
  setToggleRightBar,
  toggleRightBar,
  formFilterData,
  setEditClicked,
  setInitialValues,
  setForecastMonth,
  fileHandler,
  setFile,
}) {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const handlePreForecastLeadTime = (lead_time, month) => {
    const list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
    const option_list = list?.splice(parseInt(month, 10), 4);
    let lead_time_label;
    const new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
    const new_arr = new_list?.splice(parseInt(0, 10), 4 - option_list.length);

    const latest_list = option_list.concat(new_arr);
    latest_list?.map((i, index) => {
      i.name = `${i.name}( ${index + 1}.0 )`;
      return null;
    });
    latest_list?.map((k) => {
      if (
        parseInt(lead_time, 10) ===
        parseInt(
          k.name?.substr(
            k.name?.indexOf("(") + 1,
            k.name?.indexOf(")") - k.name?.indexOf("(") - 1,
          ),
          10,
        )
      ) {
        lead_time_label = k;
      }
      return null;
    });
    return lead_time_label?.name;
  };

  const fetchNextStaticResources = () => {
    if (haltInfiniteScroll) {
      const reqData = {
        ...formFilterData,
        order_by: t("MANAGE_IRI.CREATED_DATE"),
        page: pageNumber + 1,
        weather_forecast_source_slug: [t("MANAGE_IRI.IRI_SLUG")],
        weather_forecast_parameter_slug: [
          t("MANAGE_IRI.STATIC_IRI_PARAMETER_SLUG"),
        ],
      };
      setPageNumber(pageNumber + 1);
      dispatch(staticResourceRequest(reqData));
    }
  };
  const forecastPreSelectedLeadTime = (y, month) => {
    const list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
    const option_list = list?.splice(parseInt(month, 10), 4);
    let item;
    // let latest_list;
    const new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
    const new_arr = new_list?.splice(parseInt(0, 10), 4 - option_list.length);
    const latest_list = option_list.concat(new_arr);
    latest_list?.map((i, index) => {
      i.name = `${i.name}(${index + 1}.0)`;
      i.label = `${i.label}(${index + 1}.0)`;
      return null;
    });
    latest_list?.map((k) => {
      if (
        parseInt(y, 10) ===
        parseInt(
          k.name?.substr(
            k.name?.indexOf("(") + 1,
            k.name?.indexOf(")") - k.name?.indexOf("(") - 1,
          ),
          10,
        )
      ) {
        item = k;
      }
      return null;
    });
    return item;
  };
  const forecastPreSelectedYearMonth = (x, y) => {
    return `${x}-${y}`;
  };

  const handleEditDataSource = (i) => {
    setFile("");
    setEditClicked(true);
    fileHandler(i?.image);
    setInitialValues((prev) => {
      return {
        ...prev,
        description: i?.description,
        addLink: i?.source_link,
        strtdate: forecastPreSelectedYearMonth(
          i?.forecast_time_year,
          i?.forecast_time_month,
        ),
        months: forecastPreSelectedLeadTime(
          i?.forecast_lead_time,
          i?.forecast_time_month,
        ),
        Upload: file,
        id: i?.id,
      };
    });
    setForecastMonth(`${i?.forecast_time_year}-0${i?.forecast_time_month}`);
  };
  return (
    <>
      <Row className="pt-4">
        {isHideBar && (
          <Col className="filter-expand-bar" sm={2}>
            <span className="cursor-ponter navyBlueColor ">
              {t("MANAGE_USER.EXPAND_BAR")}
              <span>
                <MdKeyboardArrowLeft
                  className="hide-icon expand-margin"
                  onClick={() => setHideBar(!isHideBar)}
                  size={25}
                />
              </span>
            </span>
          </Col>
        )}
      </Row>
      <Row>
        <Col sm={1}>
          <img
            src={RightArrow}
            className="data-content-backerror cursor-ponter"
            alt="right-arrow"
            onClick={() => history(-1)}
            onKeyUp={() => {}}
          />
        </Col>
        <Col sm={5} className="content-heading">
          <h3> {t("MANAGE_IRI.MANAGE_IRI_DATA")} </h3>
        </Col>
        {activeTab === "2" ? (
          <Col
            sm={6}
            className="content-filter pointer"
            onClick={() => {
              setToggleRightBar(!toggleRightBar);
            }}
          >
            <img
              src={filterIcon}
              className="data-content-filter"
              alt="right-arrow"
            />{" "}
            <h4 className="filter-heading mb-0 ml-2">
              {" "}
              {t("MANAGE_IRI.FILTER")}{" "}
            </h4>
          </Col>
        ) : (
          ""
        )}
      </Row>
      <div
        className={
          isHideBar ? "st-tab-wrapper st-tab-margin" : "st-tab-wrapper"
        }
      >
        <Row>
          <Nav className="st-tabs" tabs>
            <NavItem className="st-li">
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                {t("FILTER.NEW_UPLOADS")}
              </NavLink>
            </NavItem>
            <NavItem className="st-li">
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
              >
                {t("FILTER.PREVIOUSLY_UPLOADED")}
              </NavLink>
            </NavItem>
          </Nav>
        </Row>
      </div>

      <div
        className={
          isHideBar
            ? "st-tab-content mt-4 st-tab-margin"
            : "st-tab-content mt-4"
        }
      >
        {/* <Row> */}
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm={6} className="mx-auto text-center">
                <div className="mb-0">
                  {file2 ? (
                    file2.substr(file2.lastIndexOf(".") + 1) !== "tiff" ? (
                      imageExtension !== "tiff" ? (
                        <img
                          className="img-fluid"
                          src={file2}
                          alt="not-available"
                        />
                      ) : (
                        <img
                          className="img-fluid"
                          src={Image_not_available}
                          alt="preview not-available"
                        />
                      )
                    ) : (
                      <img
                        className="img-fluid"
                        src={Image_not_available}
                        alt="preview not-available"
                      />
                    )
                  ) : (
                    <p className="new-upload-no-data">
                      {t("MANAGE_IRI.NOT_AVAILABLE")}
                    </p>
                  )}
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col lg="12">
                <div className="st-card-wrapper">
                  <InfiniteScroll
                    dataLength={
                      pageNumber * 10 < pageCount ? pageNumber * 10 : ""
                    }
                    next={fetchNextStaticResources}
                    hasMore={pageNumber * 10 < pageCount && haltInfiniteScroll}
                  >
                    {staticResourceList?.map((i) => {
                      return (
                        <Card className="st-card card-one" key={i.index}>
                          {i?.image?.substr(i?.image.lastIndexOf(".") + 1) !==
                          "tiff" ? (
                            <img
                              src={i.image}
                              alt="not-available"
                              className="card-img-top"
                            />
                          ) : (
                            <img
                              className="img-fluid"
                              src={Image_not_available}
                              alt="preview not-available"
                            />
                          )}
                          <div className="card-body">
                            <h5 className="card-title font-weight-light st-txt-primary">
                              {i.description}
                            </h5>
                            <p className="card-text">
                              {t("FILTER.SOURCE")}:{" "}
                              {i.weather_forecast_source_name} |{" "}
                              {t("FILTER.PARAMETER")}:{" "}
                              {i.weather_forecast_parameter_name}
                            </p>

                            <p className="card-text">
                              {t("FILTER.FORECAST_DATE")} :{" "}
                              {moment(
                                `${i?.forecast_time_year}-${i?.forecast_time_month}`,
                              ).format("YYYY-MM")}
                            </p>
                            <p className="card-text">
                              {t("FILTER.FORECAST_LEAD_TIME")} :{" "}
                              {handlePreForecastLeadTime(
                                i?.forecast_lead_time,
                                i?.forecast_time_month,
                              )}
                            </p>

                            {i.source_link ? (
                              <p className="card-text">
                                {" "}
                                {t("FILTER.LINK")}:{" "}
                                <a
                                  href={i.source_link}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {i.source_link.length > 45
                                    ? `${i.source_link.substring(0, 45)}...`
                                    : i.source_link}
                                </a>
                              </p>
                            ) : (
                              ""
                            )}

                            {i.forecast_data_source_name !==
                              "ERPAS (Static)" && (
                              <div className="edit-delete-options new-option">
                                <Button
                                  color="secondary"
                                  className="manage-static-edit-btn del-btn"
                                  onClick={() => handleEditDataSource(i)}
                                >
                                  {t("FILTER.EDIT")}
                                </Button>
                                {isSuperAdmin() && (
                                  <Button
                                    color="secondary"
                                    className="manage-static-delete-btn del-btn"
                                    onClick={() => {
                                      setConfirmationModalOpen(true);
                                      handleDeleteDataSource(i.id);
                                    }}
                                  >
                                    {t("FILTER.DELETE")}
                                  </Button>
                                )}
                              </div>
                            )}
                          </div>
                        </Card>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
        {/* </Row> */}
      </div>
    </>
  );
}

export default ManageContent;
