import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Modal,
  Row,
  Col,
  ModalBody,
  ModalHeader,
  Input,
} from "reactstrap";

function ErrorModal({ toggle, modal, confirmRejection }) {
  const [rejectionComment, setRejectionComment] = useState("");
  const { t } = useTranslation();

  return (
    <Modal isOpen={modal} size="md" toggle={() => toggle(!modal)}>
      <div>
        <ModalHeader className="reason-modal-header justify-content-start bg-danger pb-2 text-white">
          <span className="font-weight-bold">
            {t("SETTINGS.REJECT_RESPONSE")}
          </span>
        </ModalHeader>
        <ModalBody className="delete-modal-padding">
          <div className="m-3">
            <Input
              type="textarea"
              placeholder="please enter comment here"
              onChange={(e) => setRejectionComment(e.target.value)}
            />
          </div>
          <Row>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="button"
                className="back-office-ok-btn mr-2"
                onClick={() => {
                  toggle(!modal);
                }}
              >
                {t("UPLOAD_PAST_DATA.CANCEL")}
              </Button>
              <Button
                type="button"
                onClick={() => confirmRejection(rejectionComment)}
                // disabled={rejectionComment?.length === 0}
                className="back-office-ok-btn"
              >
                {t("UPLOAD_PAST_DATA.SUBMIT")}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default ErrorModal;
