import { takeEvery, fork, all, call, put } from "redux-saga/effects";

// Login Redux States
import PERMISSIONS_CONST from "./actionTypes";
import {
  getAllPermissionsSuccess,
  getAllPermissionsError,
  assignUsersSuccess,
  assignUsersFailure,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import { PERMISSIONS, AXIOS_INSTANCE } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* getAllPermissions() {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${PERMISSIONS}/getAllPermission`),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };

      yield put(getAllPermissionsSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(getAllPermissionsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllPermissionsError(errorData));
  }
}

//  Assign  Permissions  saga
function* assignPermissions(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${PERMISSIONS}/assign`, action.payload),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(assignUsersSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(assignUsersFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(assignUsersFailure(errorData));
  }
}

export function* watchGetAllPermissions() {
  yield takeEvery(
    PERMISSIONS_CONST.GET_ALL_PERMISSIONS_REQUEST,
    getAllPermissions,
  );
}

export function* watchassignPermissions() {
  yield takeEvery(PERMISSIONS_CONST.ASSIGN_USER_REQUEST, assignPermissions); // AssignPermissions
}

function* PermissionsSaga() {
  yield all([fork(watchGetAllPermissions), fork(watchassignPermissions)]);
}

export default PermissionsSaga;
