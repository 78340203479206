import EARLY_WARNING_POPULATION_DISPLACEMENT_CONST from "./actionTypes";

export const getPopulationDisplacementTimeSeriesRequest = (data) => ({
  type: EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_TIMESERIES_REQUEST,
  payload: {
    data,
  },
});

export const getPopulationDisplacementTimeSeriesSuccess = (response) => ({
  type: EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_TIMESERIES_SUCCESS,
  payload: response,
});

export const getPopulationDisplacementTimeSeriesError = (error) => ({
  type: EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_TIMESERIES_ERROR,
  payload: error,
});

// POPULATION DISPLACEMENT MAP DATA
export const getPopulationDisplacementMapData = (obj) => ({
  type: EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_MAP_DATA_REQUEST,
  payload: {
    requestObj: obj,
  },
});

export const getPopulationDisplacementMapDataSuccess = (response) => ({
  type: EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_MAP_DATA_SUCCESS,
  payload: response,
});

export const getPopulationDisplacementMapDataFailed = (error) => ({
  type: EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_MAP_DATA_FAILED,
  payload: error,
});

// PopulationDisplacement Hazard DATA
export const getPopulationDisplacementHazardData = (data) => ({
  type: EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_HAZARD_REQUEST,
  payload: {
    data,
  },
});

export const getPopulationDisplacementHazardDataSuccess = (response) => ({
  type: EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_HAZARD_SUCCESS,
  payload: response,
});

export const getPopulationDisplacementHazardDataFailed = (error) => ({
  type: EARLY_WARNING_POPULATION_DISPLACEMENT_CONST.GET_POPULATION_DISPLACEMENT_HAZARD_ERROR,
  payload: error,
});
