import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsTable } from "react-icons/bs";

export default function CommonTableViewButton({
  isShowTableView,
  handleClick,
}) {
  const { t } = useTranslation();
  return (
    <Button className="table-view-btn" onClick={() => handleClick()}>
      {isShowTableView ? (
        <span>
          {t("HEALTH.MAP_VIEW")}
          <FaMapMarkerAlt className="ml-3" />
        </span>
      ) : (
        <span>
          {t("HEALTH.TABLE_VIEW")}
          <BsTable className="ml-3" />
        </span>
      )}
    </Button>
  );
}
