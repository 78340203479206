const OTHER_TOOLS_CONST = {
  OTHER_TOOLS_REQUEST: "OTHER_TOOLS_REQUEST",
  OTHER_TOOLS_SUCCESS: "OTHER_TOOLS_SUCCESS",
  OTHER_TOOLS_ERROR: "OTHER_TOOLS_ERROR",

  FOCUS_AREAS_REQUEST: "FOCUS_AREAS_REQUEST",
  FOCUS_AREAS_SUCCESS: "FOCUS_AREAS_SUCCESS",
  FOCUS_AREAS_ERROR: "FOCUS_AREAS_ERROR",
};

export default OTHER_TOOLS_CONST;
