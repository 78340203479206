import React from "react";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FaCloudShowersHeavy, FaLeaf } from "react-icons/fa";
// import {CropStages} from "../../constants"

function CyModalPerformance({
  modalperformance,

  selectedCropCalender,
  selectedCSEndYear,
}) {
  const { t } = useTranslation();
  return (
    <Row className="height-100">
      <Col sm={4} className="height-100 cp-performace-border custom-scroll-cy">
        <p className="modal-performace-text-format mb-2">
          {" "}
          {t("CROP_YIELD.MODAL_PERFORMACE")} - {modalperformance?.modal?.label}
        </p>
        <hr className="mt-0 mb-0" />
        <Row>
          <Col sm={12} className="pl-0">
            <p className="cy-modal-headings">
              {" "}
              {t("CROP_YIELD.PARAMETERS_USED")}{" "}
            </p>
          </Col>
          <Col sm={12} className="clound-Cy-rainfall">
            <FaCloudShowersHeavy className="cloud-icon" />
            <span className="ml-2 cy-layer-title-performance">
              {" "}
              {t("CROP_YIELD.Rainfall")} :{" "}
            </span>
            <span className="cy-icon-layer-text">
              {" "}
              {modalperformance?.modal?.RainFalltext
                ? modalperformance?.modal?.RainFalltext.split(":")[1]
                : ""}
            </span>
          </Col>

          <Col sm={12} className="clound-Cy-rainfall">
            <FaLeaf className="cy-layers-icon" />
            <span className="ml-2  cy-layer-title-performance">
              {" "}
              {t("CROP_YIELD.NDVI")} :{" "}
            </span>

            <span className="cy-icon-layer-text">
              {" "}
              {modalperformance?.modal?.Ndvitext
                ? modalperformance?.modal?.Ndvitext.split(":")[1]
                : ""}
            </span>
          </Col>
        </Row>

        {/* year range */}
        <Row>
          <Col sm={12} className="pl-0 mt-3 mb-2">
            <p className="cy-modal-headings">
              {" "}
              {t("CROP_YIELD.MODEL_DATA_SUBSET")}{" "}
            </p>
          </Col>
          <Col sm={12} className="cy-year-rage-container ">
            <p className="cy-year-rage-text-para">
              {" "}
              <span className="cy-year-rage-text-heading">
                {" "}
                {t("CROP_YIELD.TRAINING_YEARS_PERFORMANCE")}{" "}
              </span>{" "}
              {t("CROP_YIELD.TWO_THOUSAND_FIVE")} -{" "}
              {modalperformance?.trainingYears?.end_year}{" "}
            </p>
          </Col>

          <Col sm={12} className="cy-year-rage-container mt-2 ">
            <p className="cy-year-rage-text-para">
              {" "}
              <span className="cy-year-rage-text-heading">
                {" "}
                {t("CROP_YIELD.PREDECTION_YEARS_PERFORMACE")}{" "}
              </span>{" "}
              {modalperformance?.predictionyears?.[0]} -{" "}
              {modalperformance?.predictionyears?.[1]}{" "}
            </p>
          </Col>
        </Row>

        <Row>
          <Col sm={12} className="mt-3 mb-2 pl-0">
            <p className="cy-modal-headings"> {t("CROP_YIELD.PERFORMANCE")} </p>
          </Col>
        </Row>
        <Row className="modal-performance-container">
          <Col sm={5} className="cy-modal-permance-rmsq-container ">
            <span className="cy-modal-performace-title">
              {" "}
              {t("CROP_YIELD.R_SQUARE")}{" "}
            </span>
            <p className="cy-modal-permance-rmsq-value">
              {modalperformance?.rsq ? modalperformance?.rsq?.toFixed(2) : ""}
            </p>
          </Col>

          <Col sm={5} className="cy-modal-permance-rmsq-container ">
            <span className="cy-modal-performace-title">
              {" "}
              {t("CROP_YIELD.RMSE")}{" "}
            </span>
            <p className="cy-modal-permance-rmsq-value">
              {modalperformance?.rmse ? modalperformance?.rmse.toFixed(2) : ""}
            </p>
          </Col>
        </Row>
      </Col>

      <Col sm={8} className="height-100 custom-scroll-cy">
        <div id="performance-graph-data" className="h-100">
          <img
            src={selectedCropCalender?.img}
            alt="chart.hs"
            className="w-100 h-100"
          />
          <p className="crop-stage">
            {t("CROP_YIELD.CROP_STAGE")} ({selectedCSEndYear}) :{" "}
            {selectedCropCalender?.name}{" "}
          </p>
        </div>
      </Col>
    </Row>
  );
}

export default CyModalPerformance;
