import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
// import { RenderTextField } from "../../components/Common/RenderFormikFields";
import Button from "reactstrap-button-loader";
import { API_KEY } from "../../constants/index";
import { inviteConfigurationRequest } from "../../store/odk/odkActions";
import { getODKAccessToken } from "../../helpers";
import { showError } from "../../components/Common/Notification";
import { renderTextField } from "../../components/Common/FormickField";
import { validateEmail } from "../../helpers/validate";

function InviteSurveyour({
  toggle,
  modal,
  tokenClient,
  isTokenExpired,
  setIsTokenExpired,
  userDetails,
}) {
  const { t } = useTranslation();
  const initialData = {
    email: "",
  };
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const nextProps = useSelector((state) => ({
    isFieldDataListDataRequesting: state.odk.isFieldDataListDataRequesting,
    fieldDataListData: state?.odk?.FieldDataListData,
    loginDetailData: state.Login.loginDetailData || null,
    inviteConfigurationData: state.odk?.inviteConfigurationData,
    inviteConfigurationRequestingData:
      state.odk?.isInviteConfigurationRequesting,
    inviteConfigurationErrorData: state.odk?.inviteConfigurationError,
    inviteConfigurationError: state?.odk?.inviteConfigurationError,
  }));

  // create permissions
  async function createPermissions(drive_folder_id, accessToken, email_id) {
    await fetch(
      `https://www.googleapis.com/drive/v3/files/${drive_folder_id}/permissions?key=${API_KEY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          role: "writer",
          type: "user",
          emailAddress: email_id,
        }),
      },
    )
      .then((res) => res.json())
      .then((response) => {
        if (response?.error) throw response?.error;
        // Handle the results here (response.result has the parsed body).
        dispatch(
          inviteConfigurationRequest({
            email_id,
            successMesg: t("ODK.INVITED_SURVEYOR_SUCCESS"),
          }),
        );
        // showSuccess("Success.")
      })
      .catch((err) => {
        if (
          err.code === 401 &&
          err?.status === "UNAUTHENTICATED" &&
          getODKAccessToken()
        ) {
          setIsTokenExpired(true);
        } else {
          if (err.code === 400) showError(err?.message);
          setIsTokenExpired(false);
        }
      });
  }

  useEffect(() => {
    if (nextProps.inviteConfigurationData && email) {
      toggle();
    }
  }, [nextProps.inviteConfigurationData]);

  return (
    <Modal isOpen={modal} size="md" toggle={() => toggle()}>
      {/* <div className="success-modal"> */}
      <ModalHeader
        className="reason-modal-header justify-content-start"
        style={{ height: "unset" }}
      >
        {t("ODK.INVITE_PEOPLE")}
      </ModalHeader>
      <ModalBody className="delete-modal-padding mt-4">
        <Formik
          initialValues={initialData}
          validateOnMount
          // validationSchema={ValidateSchema}
          onSubmit={(values) => {
            if (values?.email) {
              const accessToken = getODKAccessToken();
              const driveFolderId = userDetails.drive_folder_id;
              if (accessToken && !isTokenExpired) {
                createPermissions(driveFolderId, accessToken, values.email);
              } else {
                tokenClient?.requestAccessToken({ prompt: "consent" });
                tokenClient.callback = (res) => {
                  if (res && res?.access_token) {
                    createPermissions(
                      driveFolderId,
                      res?.access_token,
                      values.email,
                    );
                  }
                };
              }
              setEmail(values?.email);
            }
          }}
        >
          {(formick) => {
            return (
              <Form noValidate className="my-auto">
                <Row>
                  <Col lg={8} className="filter-control-dp-md mx-auto">
                    <Field
                      classNameActive="input-class-login"
                      classNameInactive={
                        formick?.errors?.email && formick?.touched?.email
                          ? "input-class-empty-login input-error-class"
                          : "input-class-empty-login"
                      }
                      type="email"
                      name="email"
                      component={renderTextField}
                      validate={validateEmail}
                      placeholder={t("LOGIN.EMAIL")}
                      lable={t("LOGIN.EMAIL")}
                    />
                    {formick?.errors?.email && formick?.touched?.email && (
                      <span className=" error">
                        {t(formick?.errors?.email)}
                      </span>
                    )}
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Button
                    type="submit"
                    disabled={
                      !formick.isValid ||
                      nextProps.inviteConfigurationRequestingData
                    }
                    loading={nextProps.inviteConfigurationRequestingData}
                    className="back-office-ok-btn mx-auto"
                  >
                    {t("ODK.INVITE")}
                  </Button>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
      {/* </div> */}
    </Modal>
  );
}

export default InviteSurveyour;
