const WEATHER_CONST = {
  FETCH_REQUEST: "FETCH_REQUEST",
  FETCH_SUCCESS: "FETCH_SUCCESS",
  FETCH_FAILED: "FETCH_FAILED",

  // get forest data sources
  GET_FORECAST_DATA_SOURCES_REQUEST: "GET_FORECAST_DATA_SOURCES_REQUEST",
  GET_FORECAST_DATA_SOURCES_SUCCESS: "GET_FORECAST_DATA_SOURCES_SUCCESS",
  GET_FORECAST_DATA_SOURCES_FAILED: "GET_FORECAST_DATA_SOURCES_FAILED",

  GET_FORECAST_DATA_REQUEST: "GET_FORECAST_DATA_REQUEST",
  GET_FORECAST_DATA_SUCCESS: "GET_FORECAST_DATA_SUCCESS",
  GET_FORECAST_DATA_FAILED: "GET_FORECAST_DATA_FAILED",

  // TIMESERIES
  ADD_TIMESERIES_REQUEST: "ADD_TIMESERIES_REQUEST",
  ADD_TIMESERIES_SUCCESS: "ADD_TIMESERIES_SUCCESS",
  ADD_TIMESERIES_ERROR: "ADD_TIMESERIES_ERROR",

  // IRI AVAILABLE_DATES
  IRI_AVAILABLE_DATES_REQUEST: "IRI_AVAILABLE_DATES_REQUEST",
  IRI_AVAILABLE_DATES_SUCCESS: "IRI_AVAILABLE_DATES_SUCCESS",
  IRI_AVAILABLE_DATES_FAILED: "IRI_AVAILABLE_DATES_FAILED",

  // get static resource data
  GET_STATIC_RESOURCE_DATA_REQUEST: "GET_STATIC_RESOURCE_DATA_REQUEST",
  GET_STATIC_RESOURCE_DATA_SUCCESS: "GET_STATIC_RESOURCE_DATA_SUCCESS",
  GET_STATIC_RESOURCE_DATA_FAILED: "GET_STATIC_RESOURCE_DATA_FAILED",

  // GET  IRI IMAGE URL
  GET_IRI_IMAGE_DATA_REQUEST: "GET_IRI_IMAGE_DATA_REQUEST",
  GET_IRI_IMAGE_DATA_SUCCESS: "GET_IRI_IMAGE_DATA_SUCCESS",
  GET_IRI_IMAGE_DATA_FAILED: "GET_IRI_IMAGE_DATA_FAILED",
};

export default WEATHER_CONST;
