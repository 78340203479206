import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button } from "reactstrap";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import {
  formickReactSelectionFiled,
  formickTextingField,
} from "../../components/Common/FormickField";
import BackButton from "../../assest/img/back-button.svg";
import DraftEditor from "../../components/Common/DraftEditor";
import {
  manageUserGuideSubTopicsRequest,
  addUserGuideRequest,
  searchByCriteriaManageUserGuideRequest,
  updateUserGuideRequest,
  getUserSubCategoryGuideRequest,
} from "../../store/actions";
import { renderReactOptionsArray } from "../../helpers";
import { showSuccess } from "../../components/Common/Notification";
import { selectRequired, required } from "../../helpers/validate";

function ManageUserGuideForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [catrgory, setCategory] = useState([]);
  const [subCatrgoryList, setSubCategoryList] = useState([]);
  const [editordescription, setEditorDescription] = useState("");
  const [subtopics, setSubTopic] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [, setSelectedSubTopic] = useState(null);
  const [isPublish, setIsPublish] = useState(false);
  const [updatedGuide, setUpdatedGuide] = useState("");

  const search = window.location.search;
  const URLParams = new URLSearchParams(search);
  const topicId = URLParams.get("category");
  const Id = URLParams.get("unique");
  const subTopicId = URLParams.get("sub-category");
  const topic_name = URLParams.get("topic-name");

  const [initialValues, setInitialValues] = useState({
    topic_id: "",
    sub_topic_id: "",
    level: "",
    Beforesubtopic: "",
    subtopic_name: "",
  });

  const place = [
    {
      id: 1,
      label: t("MANAGE_USER.BEFORE"),
      value: t("MANAGE_USER.BEFORE_VALUE"),
    },
    {
      id: 2,
      label: t("MANAGE_USER.AFTER"),
      value: t("MANAGE_USER.AFTER_VALUE"),
    },
  ];

  const handleDescription = (data) => {
    setEditorDescription(data);
  };

  const handleSubmit = (data) => {
    const addFormData = {
      topic_id: data?.topic_id?.value,
      subtopic_name: data?.subtopic_name,
      level: data?.level?.id,
      topic_category_id: data?.sub_topic_id?.value
        ? data?.sub_topic_id?.value
        : "",
      content: editordescription,
      status: isPublish,
      order_subtopic_id: data?.Beforesubtopic?.value,
      order: data?.Beforesubtopic?.order,
    };
    const updateFormData = {
      topic_id: data?.topic_id?.value,
      subtopic_name: data?.subtopic_name,
      level: data?.level?.id,
      content: editordescription,
      status: isPublish,
      topic_category_id: data?.sub_topic_id?.value
        ? data?.sub_topic_id?.value
        : "",
      order_subtopic_id: data?.Beforesubtopic?.value,
      order: data?.Beforesubtopic?.order,
      id: Id,
      subtopic_id: subTopicId,
    };

    !search
      ? dispatch(addUserGuideRequest(addFormData))
      : dispatch(updateUserGuideRequest(updateFormData));
  };

  const nextProps = useSelector((state) => ({
    subTopicsList: state?.ManageUser?.subTopicData,
    addUserGuide: state?.ManageUser?.addUserData,
    GuideList: state?.ManageUser?.useMenuData,
    upDateUser: state?.ManageUser?.updateUser,
    subCategoryList: state?.ManageUser?.getSubCategoryList?.results,
  }));

  useEffect(() => {
    dispatch(manageUserGuideSubTopicsRequest({}));
    if (search) {
      dispatch(
        searchByCriteriaManageUserGuideRequest({
          page: 1,
          data: {
            id: Id,
            topic_id: topicId,
            subtopic_id: subTopicId,
            is_delete: "False",
            is_backoffice: "True",
          },
        }),
      );

      dispatch(
        getUserSubCategoryGuideRequest({
          topic_id: topicId, // category
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (Object.keys(selectedCategory).length) {
      dispatch(
        getUserSubCategoryGuideRequest({
          topic_id: selectedCategory?.value,
        }),
      );
    }
  }, [selectedCategory]);

  const isSubCategoryRef = useRef(true);
  useEffect(() => {
    if (isSubCategoryRef.current) {
      isSubCategoryRef.current = false;
      return;
    }
    if (nextProps.subCategoryList?.length) {
      setSubCategoryList(nextProps.subCategoryList);
    } else {
      setSubCategoryList([]);
    }
  }, [nextProps?.subCategoryList]);

  const isGuideListRef = useRef(true);
  useEffect(() => {
    if (isGuideListRef.current) {
      isGuideListRef.current = false;
      return;
    }
    if (nextProps.subTopicsList?.length) {
      setCategory(nextProps.subTopicsList);
      if (search) {
        const sub_topic = nextProps.subTopicsList.find(
          (eachValue) => eachValue?.id === topicId,
        );

        setSubTopic(sub_topic?.sub_topic);
      }
    }
  }, [nextProps?.subTopicsList]);

  useEffect(() => {
    if (
      subtopics &&
      subtopics?.length &&
      updatedGuide &&
      updatedGuide?.order_subtopic_id
    ) {
      const orderOption = subtopics.find(
        (i) => i.id === updatedGuide?.order_subtopic_id,
      );

      if (orderOption) {
        orderOption.value = orderOption?.id;
        orderOption.label = orderOption?.name;

        setInitialValues((prev) => {
          return {
            ...prev,
            Beforesubtopic: orderOption,
          };
        });
      }
    }
  }, [subtopics, updatedGuide]);

  const isAddUserGuideRef = useRef(true);
  useEffect(() => {
    if (isAddUserGuideRef.current) {
      isAddUserGuideRef.current = false;
      return;
    }
    if (nextProps.addUserGuide) {
      showSuccess(nextProps.addUserGuide);
      navigate("/manageUserGuide");
    }
  }, [nextProps?.addUserGuide]);

  const isUpdateUserGuideRef = useRef(true);
  useEffect(() => {
    if (isUpdateUserGuideRef.current) {
      isUpdateUserGuideRef.current = false;
      return;
    }
    if (nextProps.upDateUser) {
      showSuccess(nextProps.upDateUser);
      navigate("/manageUserGuide");
    }
  }, [nextProps?.upDateUser]);

  const onSelectCategroy = (e) => {
    setSelectedSubTopic(null);

    catrgory?.map((eachValue) => {
      if (eachValue?.id === e.value) {
        setSelectedCategory(e);

        if (eachValue.sub_topic?.length) {
          const isSubCategoryExist =
            eachValue.sub_topic?.[0]?.topic_category_name === null;
          const groupedData1 = isSubCategoryExist
            ? eachValue.sub_topic
            : _(eachValue.sub_topic).groupBy("topic_category_name").value();

          setSubTopic(
            isSubCategoryExist ? groupedData1 : groupedData1[e.label],
          );
        } else {
          setSubTopic([]);
        }
      }
      return null;
    });
  };

  const onSelectSubCategroy = (e) => {
    catrgory?.map((eachValue) => {
      if (eachValue?.id === selectedCategory.value) {
        if (eachValue.sub_topic?.length) {
          const groupedData1 = _(eachValue.sub_topic)
            .groupBy("topic_category_name")
            .value();

          setSubTopic(groupedData1[e.label]);
        } else {
          setSubTopic([]);
        }
      }
      return null;
    });
  };

  const handlePublishStatus = (e) => {
    setIsPublish(e.target.checked);
  };

  const onSelectingSubTopic = (e) => {
    setSelectedSubTopic(e);
  };

  const isSubTopicRef = useRef(true);
  useEffect(() => {
    if (isSubTopicRef.current) {
      isSubTopicRef.current = false;
      return;
    }
    if (nextProps.GuideList?.results?.length) {
      const selectedCard = nextProps.GuideList?.results?.[0];

      setUpdatedGuide(selectedCard);

      const level = place.find(
        (eachValue) => eachValue?.id === selectedCard?.level,
      );

      setInitialValues((prev) => {
        return {
          ...prev,
          subtopic_name: selectedCard.title,
          topic_id: { value: selectedCard?.topic_id, label: topic_name },
          sub_topic_id: selectedCard?.topic_category_name
            ? {
                value: selectedCard?.topic_category_id,
                label: selectedCard?.topic_category_name,
              }
            : "",
          level,
        };
      });
      search &&
        setSelectedCategory({
          value: selectedCard?.topic_id,
          label: topic_name,
        });
      setEditorDescription(selectedCard?.content);
      setIsPublish(selectedCard?.status);
    }
  }, [nextProps?.GuideList]);

  return (
    <div className="user-guide-form ">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, values, isValid, dirty, setFieldValue }) => {
          return (
            <Form className="">
              <Row className="form-row">
                <Col sm={1} className="center-alignment">
                  <div>
                    <img
                      src={BackButton}
                      alt="back-button"
                      onClick={() => navigate("/manageUserGuide")}
                      onKeyUp={() => {}}
                      className="pointer"
                    />
                  </div>
                </Col>
                <Col sm={11} className="center-alignment">
                  <h3 className="mr-5 manage-user-form-heading">
                    {" "}
                    {search
                      ? t("MANAGE_USER.MODIFY_USER_GUIDE")
                      : t("MANAGE_USER.ADD_USER_GUIDE")}
                  </h3>
                </Col>
                <Col sm={6} className="registeration-header pb-3">
                  <Field
                    type="select"
                    name="topic_id"
                    component={formickReactSelectionFiled}
                    placeholder={t("MANAGE_USER.SELECT_CATEGORY")}
                    options={renderReactOptionsArray(catrgory, "name", "id")}
                    className={
                      errors?.state_id && touched?.state_id
                        ? "common-select-r input-error-class"
                        : "common-select-r"
                    }
                    onChange={(e) => {
                      onSelectCategroy(e);
                      setFieldValue("sub_topic_id", "");
                      setFieldValue("Beforesubtopic", "");
                    }}
                    validate={selectRequired}
                    disabled={search && true}
                  />
                  {errors?.topic_id && touched?.topic_id && (
                    <p className="error">{t(errors?.topic_id)}</p>
                  )}
                </Col>

                {(!topicId && subCatrgoryList?.length) ||
                (topicId && initialValues.sub_topic_id) ? (
                  <Col sm={6} className="registeration-header pb-3">
                    <Field
                      type="select"
                      name="sub_topic_id"
                      component={formickReactSelectionFiled}
                      placeholder={t("MANAGE_USER.ENTER_SUB_CATEGORY")}
                      options={renderReactOptionsArray(
                        subCatrgoryList,
                        "name",
                        "id",
                      )}
                      className={
                        errors?.state_id && touched?.state_id
                          ? "common-select-r input-error-class"
                          : "common-select-r"
                      }
                      onChange={(e) => {
                        onSelectSubCategroy(e);
                        setFieldValue("Beforesubtopic", "");
                      }}
                      validate={
                        topicId ? "" : subCatrgoryList ? selectRequired : ""
                      }
                    />
                    {errors?.sub_topic_id && touched?.sub_topic_id && (
                      <p className=" error">{t(errors?.sub_topic_id)}</p>
                    )}
                  </Col>
                ) : (
                  ""
                )}

                <Col sm={6} className="registeration-header pb-3">
                  <Field
                    className="input-class-sub-category"
                    type="text"
                    name="subtopic_name"
                    component={formickTextingField}
                    placeholder={t("MANAGE_USER.ENTER_TITLE")}
                    validate={required}
                    onChange={() => {}}
                  />
                  {errors?.subtopic_name && touched?.subtopic_name && (
                    <p className=" error">{t(errors?.subtopic_name)}</p>
                  )}
                </Col>

                {values?.topic_id && values?.topic_id !== "" && (
                  <Col sm={3} className="registeration-header pb-3">
                    <Field
                      type="select"
                      name="level"
                      component={formickReactSelectionFiled}
                      placeholder={t("MANAGE_USER.SELECT")}
                      options={place}
                      className={
                        errors?.state_id && touched?.state_id
                          ? "common-select-r input-error-class"
                          : "common-select-r"
                      }
                      onChange={() => {}}
                      disabled={
                        values.subtopic_name === "" || subtopics?.length === 0
                      }
                      validate={
                        values.subtopic_name === "" || subtopics?.length === 0
                          ? null
                          : selectRequired
                      }
                    />
                    {errors?.level && touched?.level && (
                      <p className=" error">{t(errors?.level)}</p>
                    )}
                  </Col>
                )}
                {values?.topic_id && values?.topic_id !== "" && (
                  <Col sm={5} className="registeration-header pb-3">
                    <Field
                      type="select"
                      name="Beforesubtopic"
                      component={formickReactSelectionFiled}
                      placeholder={t("MANAGE_USER.CHOOSE_SUB_CATEGORY")}
                      options={renderReactOptionsArray(
                        subtopics,
                        "name",
                        "id",
                        "order",
                      )}
                      className={
                        errors?.state_id && touched?.state_id
                          ? "common-select-r input-error-class"
                          : "common-select-r"
                      }
                      disabled={
                        values.subtopic_name === "" || subtopics?.length === 0
                      }
                      validate={
                        search
                          ? subtopics?.length
                            ? selectRequired
                            : ""
                          : subtopics?.length
                          ? selectRequired
                          : ""
                      }
                      onChange={onSelectingSubTopic}
                    />
                    {errors?.Beforesubtopic && touched?.Beforesubtopic && (
                      <p className=" error">{t(errors?.Beforesubtopic)}</p>
                    )}
                  </Col>
                )}
                <Col sm={12}>
                  <Field
                    component={DraftEditor}
                    name="content"
                    className="mt-1 my-2 filter-select-placeholder "
                    onEditorChange={handleDescription}
                    required={false}
                    type="textarea"
                    isMulti={false}
                    content={editordescription}
                    placeholder={t("USER_GUIDE.ADD_DESCRIPTION")}
                  />
                </Col>
                <Col sm={12} className="userguide-verticalcenter mb-2">
                  <Field
                    component="input"
                    type="checkbox"
                    id="publish_status"
                    name="status"
                    checked={isPublish}
                    className="my-2 filter-select-placeholder mr-1 user-guide-publish-checkbox "
                    onChange={(e) => handlePublishStatus(e)}
                  />
                  <label htmlFor="publish_status" className=" mb-0">
                    {t("MANAGE_USER.PUBLISH_DRAFT")}
                  </label>
                </Col>
                <Col sm={12} className="mb-3 text-center">
                  <Button
                    type="submit"
                    className="save-button"
                    disabled={
                      !search
                        ? !isValid || !dirty || editordescription === ""
                        : !isValid || editordescription === ""
                    }
                  >
                    {search ? "Update" : "Save"}
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      {/* </Row> */}
    </div>
  );
}

export default ManageUserGuideForm;
