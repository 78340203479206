import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import * as xlsx from "xlsx";
import * as FileSaver from "file-saver";
import _, { isArray } from "lodash";
import { ADD_INTERVENTION } from "../../../constants";

import { ReactComponent as DownloadIcon } from "../../../assest/img/ea-download.svg";
import { ReactComponent as EditIcon } from "../../../assest/img/ea-edit.svg";
import {
  editPreparednessContentRequest,
  editPreparednessContentSuccess,
  getPreparednessPhaseRequest,
  deletePreparednessContentSuccess,
  projectStatusRequest,
} from "../../../store/actions";
import {
  showSuccess,
  showError,
} from "../../../components/Common/Notification";
import PreparednessPhaseTable from "../Table";
import SideEditor from "../SideEditor";
import {
  htmlToText,
  isLogedIn,
  isPermissionsMatch,
  superAdmin,
} from "../../../components/Common/Utils";
import Comments from "../../../components/Common/Comments";
import { handleExportToCSVExcelAap } from "../../../helpers/excelDownload";

function AnticipatoryActionPhaseComponent({
  handleNext,
  data,
  projectDetails,
  slugType,
  editableRowKeys,
  setEditableRowKeys,
  isDefaultData,
  editInstance,
  isAllStepsCompleted,
  permissionsList,
  onHanldeDisable,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [openEditor, setOpenEditor] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [type, setType] = useState("");
  const [rowData, setRowData] = useState({});

  const [sendComment, setSendComment] = useState("");
  const [commentList, setCommentList] = useState([]);

  const userDetails = JSON.parse(isLogedIn());
  const projectData = JSON.parse(localStorage.getItem("projectDetails"));

  const nextProps = useSelector((state) => ({
    editedPreparednessPhaseData:
      state?.EAPreparednessPhaseReducer?.editedPreparednessPhaseData,
    editContentError: state?.EAPreparednessPhaseReducer?.editContentError,
    editProjectMessage: state?.EaProjects?.editProjectMessage,
    projectStatusMessage: state?.EaProjects?.projectStatus,
  }));

  // to show diable effect when we open editor modal
  useEffect(() => {
    onHanldeDisable(openEditor);
  }, [openEditor]);

  const isAddIntervention =
    isPermissionsMatch(permissionsList, ADD_INTERVENTION) || superAdmin();

  const isEditProjectSucess = useRef(true);
  useEffect(() => {
    if (isEditProjectSucess.current) {
      isEditProjectSucess.current = false;
      return;
    }
    if (nextProps.projectStatusMessage) {
      localStorage.setItem(
        "projectDetails",
        JSON.stringify({
          ...projectData,
          ea_pp_aap_status: true,
          ea_pp_status: true,
        }),
      );

      if (isAddIntervention || projectData?.ea_rp_status) {
        handleNext(4);
      } else {
        showError(t("VALIDATION.PROJECT_PENDING_WARNING"));
      }
    }
  }, [nextProps.projectStatusMessage]);

  const onSubmit = () => {
    // checking if readyness status is true navigate directly to Intervention listing
    if (projectData?.ea_rp_status) {
      handleNext(4);
    } else {
      // checking the anticaptory status change and add intervention permission
      if (projectData.ea_pp_aap_status) {
        if (isAddIntervention) {
          handleNext(4);
        } else {
          showError(t("VALIDATION.PROJECT_PENDING_WARNING"));
        }
      } else {
        const payload = {
          project_id: projectDetails?.id,
          ea_pp_status: true,
          ea_pp_aap_status: true,
        };
        dispatch(projectStatusRequest(payload));
      }
    }
  };

  const handleEditRow = (content) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setOpenEditor(true);
    setType("anticipatoryActionsPhase");
    setRowData(content);
    setIsEditable(true);
    setEditableRowKeys(content);
  };

  const handleCloseModal = () => {
    setOpenEditor(!openEditor);
    setRowData({});
    setEditableRowKeys({});
  };

  const handleGetTableData = () => {
    dispatch(
      getPreparednessPhaseRequest({
        slug: slugType,
        project_id: projectDetails?.id,
        is_default: isDefaultData,
      }),
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(editPreparednessContentSuccess({}));
      dispatch(deletePreparednessContentSuccess({}));
    };
  }, []);

  const isEditContentSucess = useRef(true);
  useEffect(() => {
    if (isEditContentSucess.current) {
      isEditContentSucess.current = false;
      return;
    }
    if (!_.isEmpty(nextProps.editedPreparednessPhaseData)) {
      setOpenEditor(false);
      setRowData({});
      setEditableRowKeys({});
      sendComment === "" &&
        showSuccess(nextProps.editedPreparednessPhaseData?.data);
      handleGetTableData();
    } else if (!_.isEmpty(nextProps.editContentError)) {
      setOpenEditor(false);
      setRowData({});
      setEditableRowKeys({});
    }
  }, [nextProps.editedPreparednessPhaseData, nextProps.editContentError]);

  const COLUMNS = [
    {
      Header: t("EARLY_ACTIONS.CATEGORY"),
      accessorKey: "category",
      size: 80,
    },
    {
      Header: t("EARLY_ACTIONS.OBJECTIVE"),
      accessorKey: "objective",
      Cell: (row) =>
        row.renderedCellValue.map((item) => (
          <div dangerouslySetInnerHTML={{ __html: item }} />
        )),
      size: 100,
    },
    {
      Header: t("EARLY_ACTIONS.ANTICIPATORY_PREPAREDNESS_PHASE"),
      accessorKey: "anticipatoryActionPhase",
      Cell: (row) =>
        row.renderedCellValue.map((item) => (
          <div dangerouslySetInnerHTML={{ __html: item }} />
        )),
      size: 150,
    },
    {
      Header: t("EARLY_ACTIONS.ANTICIPATORY_ACTION_PHASE"),
      id: "Anticipatory Action Phase",
      columns: [
        {
          Header: t("EARLY_ACTIONS.READINESS_ACTIVATED"),
          accessorKey: "readinessActivated",
          Cell: (row) =>
            row.renderedCellValue.map((item) => (
              <div dangerouslySetInnerHTML={{ __html: item }} />
            )),
          size: 150,
        },
        {
          Header: t("EARLY_ACTIONS.ACTION_ACTIVATED"),
          accessorKey: "actionActivated",
          Cell: (row) =>
            row.renderedCellValue.map((item) => (
              <div dangerouslySetInnerHTML={{ __html: item }} />
            )),
          size: 150,
        },
      ],
      size: 300,
    },
    {
      Header: t("EARLY_ACTIONS.MANAGE_DATA"),
      accessorKey: "view",
      size: 40,
    },
  ];

  const FILTERED_COLUMNS = COLUMNS.filter(
    (column) => column.accessorKey !== "view",
  );

  useEffect(() => {
    const modifiedData = [];
    if (data?.length) {
      data?.[0]?.content.forEach((contentData, index) => {
        const modifedColumn = {};
        modifedColumn.id = index;
        modifedColumn.category = contentData.Category;
        modifedColumn.objective = isArray(contentData.Objective)
          ? [...contentData.Objective]
          : [contentData.Objective];
        modifedColumn.anticipatoryActionPhase = isArray(
          contentData["Anticipatory Action Preparedness Phase"],
        )
          ? [...contentData["Anticipatory Action Preparedness Phase"]]
          : [contentData["Anticipatory Action Preparedness Phase"]];
        modifedColumn.readinessActivated = contentData[
          "Anticipatory Action Phase"
        ]
          ? isArray(
              contentData["Anticipatory Action Phase"][0][
                "Actions to be undertaken When Readiness trigger activated"
              ],
            )
            ? [
                ...contentData["Anticipatory Action Phase"][0][
                  "Actions to be undertaken When Readiness trigger activated"
                ],
              ]
            : [
                contentData["Anticipatory Action Phase"][0][
                  "Actions to be undertaken When Readiness trigger activated"
                ],
              ]
          : [];
        modifedColumn.actionActivated = contentData["Anticipatory Action Phase"]
          ? isArray(
              contentData["Anticipatory Action Phase"][0][
                "Actions to be undertaken When Action trigger activated"
              ],
            )
            ? [
                ...contentData["Anticipatory Action Phase"][0][
                  "Actions to be undertaken When Action trigger activated"
                ],
              ]
            : [
                contentData["Anticipatory Action Phase"][0][
                  "Actions to be undertaken When Action trigger activated"
                ],
              ]
          : [];
        modifedColumn.view = (
          <div className="d-flex align-items-center justify-content-center">
            <span>
              <EditIcon
                onClick={() => handleEditRow(contentData)}
                className="cursor-pointer"
              />
            </span>
          </div>
        );
        modifiedData.push(modifedColumn);
      });
    }
    setSendComment("");
    setCommentList(data[0]?.comment);
    if (modifiedData?.length) {
      setTableData(modifiedData);
    }
  }, [data]);

  const handleEditApiCall = (variant) => {
    let payload;
    if (variant === "Edit") {
      // !FOR EDITING DATA WE NEED TO SEND OLD CONTENT AND NEW CONTENT
      payload = {
        project_id: projectDetails?.id,
        slug: slugType,
        old_content: [
          {
            Category: rowData.Category,
            Objective: rowData.Objective,
            "Anticipatory Action Preparedness Phase":
              rowData["Anticipatory Action Preparedness Phase"],
            "Anticipatory Action Phase": [
              {
                "Actions to be undertaken When Action trigger activated":
                  rowData["Anticipatory Action Phase"][0][
                    "Actions to be undertaken When Action trigger activated"
                  ],
                "Actions to be undertaken When Readiness trigger activated":
                  rowData["Anticipatory Action Phase"][0][
                    "Actions to be undertaken When Readiness trigger activated"
                  ],
              },
            ],
          },
        ],
        new_content: [
          {
            Category: editableRowKeys.Category,
            Objective: editableRowKeys.Objective,
            "Anticipatory Action Preparedness Phase":
              editableRowKeys["Anticipatory Action Preparedness Phase"],
            "Anticipatory Action Phase": [
              {
                "Actions to be undertaken When Action trigger activated":
                  editableRowKeys["Anticipatory Action Phase"][0][
                    "Actions to be undertaken When Action trigger activated"
                  ],
                "Actions to be undertaken When Readiness trigger activated":
                  editableRowKeys["Anticipatory Action Phase"][0][
                    "Actions to be undertaken When Readiness trigger activated"
                  ],
              },
            ],
          },
        ],
      };
    }

    dispatch(editPreparednessContentRequest(payload));
  };

  const hanldeExportToCSV = () => {
    // Need to set the headers of xlsx
    const customData = tableData.map((item) => ({
      "No.": item.id + 1,
      Category: item.category,
      Objective: htmlToText(item.objective[0]),
      "Anticipatory Action Preparedness Phase": htmlToText(
        item.anticipatoryActionPhase[0],
      ),
      "Actions to be undertaken When Readiness trigger activated": htmlToText(
        item.readinessActivated[0],
      ),
      "Actions to be undertaken When Action trigger activated": htmlToText(
        item.actionActivated[0],
      ),
    }));
    const ws = xlsx.utils.json_to_sheet(customData);
    const wrapTextStyle = {
      alignment: {
        wrapText: true,
        vertical: "top",
      },
    };
    // Setting the header row style to bold
    const headerStyle = {
      font: { bold: true },
    };
    const headerRange = xlsx.utils.decode_range(ws["!ref"]);
    // eslint-disable-next-line no-plusplus
    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
      const cellAddress = xlsx.utils.encode_cell({
        r: headerRange.s.r,
        c: col,
      });
      ws[cellAddress].s = { ...headerStyle, ...wrapTextStyle };
    }
    // Setting column widths
    const columnWidths = [
      { wpx: 80 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
    ];

    ws["!cols"] = columnWidths;
    // Apply wrapTextStyle to data rows
    // eslint-disable-next-line no-plusplus
    for (let row = headerRange.s.r + 1; row <= headerRange.e.r; row++) {
      // eslint-disable-next-line no-plusplus
      for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
        const cellAddress = xlsx.utils.encode_cell({
          r: row,
          c: col,
        });
        ws[cellAddress].s = wrapTextStyle;
      }
    }
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: "array" });
    const sheetData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(sheetData, `EA_PP_ANTICIPATORY_ACTION_PLAN.xlsx`);
  };

  const onHandleDownloadexcel = () => {
    const ColumnsData = [
      {
        header: "No.",
        key: "id",
        width: 5,
        array: false,
      },
      {
        header: "Objective",
        key: "objective",
        width: 50,
        array: true,
      },
      {
        header: "Anticipatory Action Preparedness Phase",
        key: "AAPPhase",
        width: 100,
        array: true,
      },
      {
        header: "Actions to be undertaken When Readiness trigger activated",
        key: "ARAPhase",
        width: 100,
        array: true,
      },
      {
        header: "Actions to be undertaken When Readiness trigger activated",
        key: "actionActivated",
        width: 100,
        array: true,
      },
    ];

    handleExportToCSVExcelAap(tableData, ColumnsData);
  };

  const onHandleComments = () => {
    const currentCommnet = {
      name: userDetails?.user_details?.[1]?.first_name,
      comment: sendComment,
      date: new Date(),
    };
    const commentData = [currentCommnet];
    //  setCommentList([...commentList, currentCommnet])
    dispatch(
      editPreparednessContentRequest({
        slug: slugType,
        project_id: projectDetails?.id,
        comment: commentData,
      }),
    );
  };

  return (
    <>
      {openEditor && (
        <SideEditor
          setOpenEditor={setOpenEditor}
          openEditor={openEditor}
          type={type}
          setRowData={setRowData}
          rowData={rowData}
          editableRowKeys={editableRowKeys}
          setEditableRowKeys={setEditableRowKeys}
          handleEditApiCall={handleEditApiCall}
          isEditable={isEditable}
          handleCloseModal={handleCloseModal}
        />
      )}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 className="category-header">
          {t("EARLY_ACTIONS.ANTICIPATORY_ACTION_PLAN")}
        </h3>
        <div className="actions-container d-flex align-items-center justify-content-end">
          <span
            role="button"
            className="ea-upload-button d-none" // hidden temporarly
            onClick={hanldeExportToCSV}
            onKeyUp={() => {}}
            tabIndex="0"
            aria-label="exportCSVAAP"
          >
            <DownloadIcon />
          </span>
          <span
            role="button"
            className="ea-upload-button"
            onClick={onHandleDownloadexcel}
            onKeyUp={() => {}}
            tabIndex="0"
            aria-label="DownloadexcelAAP"
          >
            <DownloadIcon />
          </span>
          <Button
            className="submit-next-btn"
            onClick={onSubmit}
            disabled={
              !Object.values(tableData).every(
                (value) =>
                  value?.category !== "" &&
                  value?.objective[0] !== "" &&
                  value?.anticipatoryActionPhase[0] !== "" &&
                  value?.readinessActivated[0] !== "" &&
                  value?.actionActivated[0] !== "",
              )
            }
          >
            {t("EARLY_ACTIONS.SUBMIT_NEXT")}
          </Button>
        </div>
      </div>
      <PreparednessPhaseTable
        COLUMNS={
          isAllStepsCompleted || !editInstance ? FILTERED_COLUMNS : COLUMNS
        }
        tableData={tableData}
        loading={false}
      />
      {tableData?.length ? (
        <div className="mt-5">
          <Comments
            onHandleComments={onHandleComments}
            setComment={setSendComment}
            sendComment={sendComment}
            comment={sendComment}
            commentList={commentList}
            canAddComment={editInstance}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default AnticipatoryActionPhaseComponent;
