import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// Login Redux States
import Cookies from "js-cookie";
import baseMethod from "../../components/Common/BaseMethod";
import checkHttpStatus from "../apiUtils";
import {
  AXIOS_INSTANCE,
  AXIOS_INSTANCE_LOADER,
  RAPID_RESPONSE,
} from "../apiUtils/config";
import RAPID_RESPONSE_CONST from "./actionTypes";
import {
  createDisasterAlertFailed,
  createDisasterAlertSuccess,
  getApproveDisasterEventFailed,
  getApproveDisasterEventSuccess,
  getDisasterAlertListSuccess,
  getDisasterEventsDataFailed,
  getDisasterEventsDataSuccess,
  getDisasterListDataFailed,
  getDisasterListDataSuccess,
  getRainfallMapDataFailed,
  getRainfallMapDataSuccess,
  getRapidResponseNewsfeedDataFailed,
  getRapidResponseNewsfeedDataSuccess,
} from "./actions";

function* getRainfallMapData(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.post(
        `${RAPID_RESPONSE}/${action.payload.url}`,
        action?.payload?.payload,
      ),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getRainfallMapDataSuccess(responseData));
    } else {
      const responseData = {
        data: response.data,
      };
      yield put(getRainfallMapDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getRainfallMapDataFailed(errorData));
  }
}

function* rapidResponseNewsfeedData(action) {
  const { event_type, event_limit } = action.payload;
  const event = event_type !== "" ? `event_type=${event_type}` : "";
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.get(
        `${RAPID_RESPONSE}/gdacs_events?${event}&event_limit=${event_limit}&country_id=${action.payload.country_id}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getRapidResponseNewsfeedDataSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.message || response.data.error,
      };
      yield put(getRapidResponseNewsfeedDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getRapidResponseNewsfeedDataFailed(errorData));
  }
}

function* disasterEventsData(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.post(
        `${RAPID_RESPONSE}/disaster_events`,
        action?.payload,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getDisasterEventsDataSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.message || response.data.error,
      };
      yield put(getDisasterEventsDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDisasterEventsDataFailed(errorData));
  }
}

// Disaster types
function* rapidResponseDisasterTypeList() {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.get(`${RAPID_RESPONSE}/disaster_lists`),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getDisasterListDataSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.message || response.data.error,
      };
      yield put(getDisasterListDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDisasterListDataFailed(errorData));
  }
}

function* createDisasterAlert(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(
        `${RAPID_RESPONSE}/report_disaster_event`,
        action?.payload,
      ),
      "",
      "",
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(createDisasterAlertSuccess(responseData));
    } else {
      const responseData = {
        data: response.data,
      };
      yield put(createDisasterAlertFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(createDisasterAlertFailed(errorData));
  }
}

function* getDisasterAlertListData(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  if (Cookies.get("user")) {
    const userToken = JSON.parse(Cookies.get("user"))?.result?.token;
    AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  }

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${RAPID_RESPONSE}/reported_disaster_events?is_backoffice=${
          action.payload.is_backoffice
        }&country_id=${action.payload.country_id}${
          action.payload.disaster_type
            ? `&disaster_type=${action.payload.disaster_type}`
            : ""
        }${action.payload.limit ? `&limit=${action.payload.limit}` : ""}${
          action.payload.page ? `&page=${action.payload.page}` : ""
        }${action.payload.status ? `&status=${action.payload.status}` : ""}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getDisasterAlertListSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getDisasterEventsDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDisasterEventsDataFailed(errorData));
  }
}

function* getDisasterEventApproval(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  if (Cookies.get("user")) {
    const userToken = JSON.parse(Cookies.get("user"))?.result?.token;
    AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  }

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${RAPID_RESPONSE}/approve_disaster_events?is_approved=${
          action.payload.is_approved
        }&disaster_event_id=${action.payload.disaster_event_id}${
          action.payload.reason ? `&reason=${action.payload.reason}` : ""
        }`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data,
      };
      yield put(getApproveDisasterEventSuccess(responseData));
    } else {
      const responseData = {
        data: response.data,
      };
      yield put(getApproveDisasterEventFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getApproveDisasterEventFailed(errorData));
  }
}

export function* watchRapidResponse() {
  yield takeEvery(
    RAPID_RESPONSE_CONST.GET_RAINFALL_MAP_DATA_REQUEST,
    getRainfallMapData,
  );

  yield takeEvery(
    RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_NEWSFEED_DATA_REQUEST,
    rapidResponseNewsfeedData,
  );

  yield takeEvery(
    RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_DISASTER_EVENTS_DATA_REQUEST,
    disasterEventsData,
  );

  yield takeEvery(
    RAPID_RESPONSE_CONST.GET_RAPID_RESPONSE_DISASTER_TYPES_REQUEST,
    rapidResponseDisasterTypeList,
  );

  yield takeEvery(
    RAPID_RESPONSE_CONST.CREATE_DISASTER_ALERT_REQUEST,
    createDisasterAlert,
  );

  yield takeEvery(
    RAPID_RESPONSE_CONST.GET_DISASTER_ALERT_LIST_REQUEST,
    getDisasterAlertListData,
  );

  yield takeEvery(
    RAPID_RESPONSE_CONST.GET_APPROVE_DISASTER_EVENT_REQUEST,
    getDisasterEventApproval,
  );
}

function* RapidResponseSaga() {
  yield all([fork(watchRapidResponse)]);
}

export default RapidResponseSaga;
