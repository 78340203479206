import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { page_range } from "../../constants";
import { ReactComponent as LeftArrow } from "../../assest/img/left-arrow-pagination.svg";
import { ReactComponent as RightArrow } from "../../assest/img/right-arrow-pagination.svg";

function PaginationSection({
  pageSize,
  pageNumber,
  pageCount,
  handleGoToPage,
  handlePageSize,
  disabled,
}) {
  const { t } = useTranslation();
  return (
    <Row className="pagination-center-alignment pagination-padding">
      <Col sm={4} className="pagination-center-alignment">
        <p className="mr-3"> {t("MANAGE_USERS.ITEMS_PER_PAGE")} </p> {"  "}
        <select
          value={pageSize}
          onChange={(e) => handlePageSize(Number(e.target.value))}
          className="select-pages"
        >
          {page_range.map((pageSize1) => (
            <option key={pageSize1} value={pageSize1}>
              {pageSize1}
            </option>
          ))}
        </select>
        <span className="num-of-items">
          {(pageNumber - 1) * pageSize + 1}-
          {pageCount < pageNumber * pageSize
            ? pageCount
            : pageNumber * pageSize}{" "}
          {t("MANAGE_USERS.OF")} {pageCount || ""} {t("MANAGE_USERS.ITEMS")}
        </span>
      </Col>
      <Col sm={8} className="text-end ">
        <span>
          <input
            type="number"
            className="User-pagination"
            value={pageNumber}
            onChange={(e) => {
              handleGoToPage(e.target.value);
            }}
            disabled={
              disabled ||
              !!(
                (pageCount % pageSize
                  ? Math.trunc(pageCount / pageSize + 1)
                  : Math.trunc(pageCount / pageSize)) === pageNumber ||
                (pageCount % pageSize
                  ? Math.trunc(pageCount / pageSize + 1)
                  : Math.trunc(pageCount / pageSize)) === 0
              )
            }
            style={{ width: "50px", border: "none" }}
          />

          <span style={{ fontSize: "1rem" }}>
            {t("MANAGE_USERS.OF")}{" "}
            {pageCount % pageSize
              ? Math.trunc(pageCount / pageSize + 1)
              : Math.trunc(pageCount / pageSize)}{" "}
            {t("MANAGE_USERS.PAGES")}
          </span>
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={() => handleGoToPage(pageNumber - 1)}
            className="pagination-btn-previous pagination-arrows"
          >
            {" "}
            <LeftArrow />{" "}
          </button>
          <button
            type="button"
            onClick={() => handleGoToPage(pageNumber + 1)}
            disabled={
              !!(
                (pageCount % pageSize
                  ? Math.trunc(pageCount / pageSize + 1)
                  : Math.trunc(pageCount / pageSize)) === pageNumber ||
                (pageCount % pageSize
                  ? Math.trunc(pageCount / pageSize + 1)
                  : Math.trunc(pageCount / pageSize)) === 0
              )
            }
            className="pagination-arrows"
          >
            {" "}
            <RightArrow />{" "}
          </button>
        </span>
      </Col>
    </Row>
  );
}

export default PaginationSection;
