import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import { Row, Col, Container, Input, FormGroup, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Loader from "../../components/Common/Loader";
import { showError } from "../../components/Common/Notification";
import ManageSurveyorsTable from "./ManageSurveyorsTable";
import { manageSurveyorsListRequest } from "../../store/odk/odkActions";
import BackButton from "../../assest/img/back-button.svg";
import { getLoginUserDetailsRequest } from "../../store/user/action";
import { getToken } from "../../helpers";

function ManageFieldData() {
  const { t } = useTranslation();
  const nextProps = useSelector((state) => ({
    isFieldDataListDataRequesting: state.odk.isFieldDataListDataRequesting,
    fieldDataListData: state?.odk?.FieldDataListData,
    inviteConfigurationData: state?.odk?.inviteConfigurationData,
    managesurveyorsListData: state?.odk?.managesurveyorsListData,
    managesurveyorsListRequesting: state?.odk?.managesurveyorsListRequesting,
    loginUserDetails: state.User.loginUserDetails,
    deleteSurveyorsRequesting: state.odk.deleteSurveyorsRequesting || null,
    deleteSurveyorsData: state.odk.deleteSurveyorsData || null,
  }));

  const dispatch = useDispatch();
  const history = useNavigate();

  const [newRequestList, setNewRequestList] = useState();
  const [pageLimit, setPageLimit] = useState(10);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [recordsCount, setRecordsCount] = useState();

  const [openFilter, setOpenFilter] = useState(false);

  const [email, setemail] = useState(null);

  const [signedInUser, setSignedInUser] = useState("");

  const [signedInUserDetails, setSignedInUserDetails] = useState("");

  const [userDetails, setUserDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (getToken()) {
      dispatch(getLoginUserDetailsRequest({}));
      dispatch(
        manageSurveyorsListRequest({
          page: pageNumber,
          limit: pageLimit,
        }),
      );

      let signed_InUser = localStorage.getItem("googleAccountDetails");
      if (signed_InUser) {
        signed_InUser = JSON.parse(signed_InUser);
        setSignedInUser(signed_InUser);
        setSignedInUserDetails(signedInUser?.email);
      } else history.push("/surveyDataSettingsDashboard");
    } else {
      history.push("/home");
    }
  }, []);

  useEffect(() => {
    if (nextProps.deleteSurveyorsData) {
      dispatch(
        manageSurveyorsListRequest({
          page: pageNumber,
          limit: pageLimit,
        }),
      );
      setIsLoading(false);
    }
  }, [nextProps.deleteSurveyorsRequesting, nextProps.deleteSurveyorsData]);

  useEffect(() => {
    if (nextProps.managesurveyorsListData) {
      if (nextProps?.managesurveyorsListData?.data?.result?.results?.length) {
        // setNewRequestList(nextProps?.fieldDataListData?.data?.results);
        setNewRequestList(
          nextProps?.managesurveyorsListData?.data?.result?.results,
        );
        setPageCount(nextProps?.managesurveyorsListData?.data?.result?.count);
        setRecordsCount(
          nextProps?.managesurveyorsListData?.data?.result?.results?.length,
        );
      } else {
        setNewRequestList([]);
        setPageCount(0);
        setRecordsCount(0);
      }
    } else {
      setNewRequestList([]);
    }
  }, [nextProps.managesurveyorsListData]);

  const handlePageNumber = (value) => {
    setPageNumber(value);
    dispatch(
      manageSurveyorsListRequest({
        page: value,
        limit: pageLimit,
      }),
    );
  };

  const handlePageSize = (value) => {
    setPageLimit(value);
    setPageNumber(1);
    dispatch(
      manageSurveyorsListRequest({
        page: pageNumber,
        limit: value,
      }),
    );
  };

  useEffect(() => {
    if (nextProps.loginUserDetails) {
      setUserDetails(nextProps?.loginUserDetails?.user_profile);
    }
  }, [nextProps.loginUserDetails]);

  const handleSignOutClick = () => {
    history(-1);
  };

  useEffect(() => {
    if (
      !_.isEmpty(userDetails?.google_email) &&
      !_.isEmpty(signedInUserDetails)
    ) {
      if (
        signedInUserDetails &&
        userDetails &&
        userDetails?.google_email !== signedInUserDetails
      ) {
        const user_google_email = userDetails?.google_email;
        showError(t("ODK.EMAIL_VERIFICATION", { user_google_email }));
        handleSignOutClick();
        // history.goBack()
      }
    }
  }, [signedInUserDetails, userDetails]);

  const handleLatestData = (value) => {
    // dispatch(userListSuccess({}));
    setNewRequestList([]);

    dispatch(
      manageSurveyorsListRequest({
        page: pageNumber,
        limitKey: "limit",
        limit: value,
      }),
    );
  };

  const handleSearch = () => {
    if (!email) {
      showError(t("ODK.ENTER_EMAIL"));
    } else {
      if (email) {
        dispatch(
          manageSurveyorsListRequest({
            page: pageNumber,
            // limitKey: 'limit',
            limit: 10,
            email,
          }),
        );
      } else {
        dispatch(
          manageSurveyorsListRequest({
            page: pageNumber,
            // limitKey: 'limit',
            limit: 10,
            // email:email
          }),
        );
      }
    }
    setemail(null);
  };

  const handleEmail = (e) => {
    setemail(e);
  };

  const reset = () => {
    document.getElementById("email").value = "";
    dispatch(
      manageSurveyorsListRequest({
        page: pageNumber,
        limit: 10,
      }),
    );
  };

  const handleFilter = () => {
    setOpenFilter(!openFilter);
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <Container fluid>
        {(nextProps.managesurveyorsListRequesting || isLoading) && <Loader />}

        <div className="right-img-wrapper api-key-list">
          <Row
            style={{ display: "flex", alignItems: "center" }}
            className="manage-surveyor-dsn"
          >
            <Col lg={6}>
              <div className="pb-1 back-office-main-header back-office-center">
                <img
                  src={BackButton}
                  alt="back-button"
                  onClick={() => history("/surveyConfigurationDashboard")}
                  onKeyUp={() => {}}
                  className="pointer"
                />
                <h2 className="discription-text pl-3 heading-text-user-guide">
                  {t("ODK.MANAGE_SURVEYORS")}
                </h2>
              </div>
            </Col>

            <Col lg={6} md={6}>
              <Row>
                {openFilter && (
                  <>
                    <Col lg={6} md={6}>
                      <FormGroup
                        className="my-auto"
                        style={{ marginRight: "10px" }}
                      >
                        <Input
                          type="text"
                          name="email"
                          placeholder="Email"
                          // component={renderTextField}
                          className="my-auto"
                          id="email"
                          onChange={(e) => handleEmail(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="ODK-MD">
                      <Button
                        style={{
                          backgroundColor: "#3870AF",
                          color: "#FFF",
                          marginRight: "10px",
                          borderRadius: "5px",
                          borderStyle: "none",
                          padding: "7px",
                          width: "100%",
                        }}
                        className="search-button-disabled"
                        onClick={(e) => handleSearch(e)}
                        disabled={_.isEmpty(email)}
                      >
                        {t("ODK.SEARCH")}{" "}
                      </Button>
                    </Col>
                    <Col className="ODK-MD">
                      <Button
                        style={{
                          backgroundColor: "#3870AF",
                          color: "#FFF",
                          marginRight: "10px",
                          borderRadius: "5px",
                          borderStyle: "none",
                          padding: "7px",
                        }}
                        className="search-button-disabled"
                        onClick={reset}
                      >
                        {t("ODK.CLEAR")}
                      </Button>
                    </Col>
                  </>
                )}
                <Col className="ODK-MD">
                  <Button
                    className="float-right ODK-filter"
                    onClick={(e) => handleFilter(e)}
                    style={{ marginRight: "10px" }}
                  >
                    <span className="text-left">{t("ODK.FILTER")}</span>
                    <FaFilter className="manage-user-header-back-button ml-1" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg="12" md="12" className="">
              {newRequestList && (
                <ManageSurveyorsTable
                  loading={false}
                  req_data={newRequestList}
                  handleLatestData={handleLatestData}
                  // handleLatestDataForDelete={handleLatestDataForDelete}
                  handlePageSize={handlePageSize}
                  pageCount={pageCount}
                  handlePageNumber={handlePageNumber}
                  signedInUser={signedInUser}
                  signedInUserDetails={signedInUserDetails}
                  pageNumber={pageNumber}
                  userDetails={userDetails}
                  setIsLoading={setIsLoading}
                  recordsCount={recordsCount}
                />
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default ManageFieldData;
