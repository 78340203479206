import React, { useState, useEffect } from "react";
// import Highcharts from "highcharts";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import moment from "moment";
import HighChartGraph from "../../components/Common/HighCharts";
import { dateFormatters } from "../../helpers";
// import { handleExportToCSVOpenWeather } from "../../helpers/excelDownload";

function Graph({ graphData, allFieldsData, selectedState, tabsData }) {
  const { t } = useTranslation();
  const [highChartData, setHighChartData] = useState({
    yAxis: [],
    series: [],
    xAxis: [],
  });
  const LNG_CODE = localStorage.getItem("i18nextLng");

  const internalDisplacementData = {
    name: `Internal Displacement`,
    type: "line",
    color: "#28537D",
    yAxis: 0,
    data: [],
  };

  useEffect(() => {
    const yaxisdata = [];
    const xAxisCategoriesData = [];
    if (graphData?.length) {
      graphData.forEach((item) => {
        internalDisplacementData.data.push({
          y: item?.internal_displacement,
        });

        xAxisCategoriesData.push(
          allFieldsData?.isShowAllYears
            ? moment(new Date(`${item.year}-${item.month}`)).format("YYYY")
            : `${item.state_name ? item.state_name : ""} ${moment(
                new Date(`${item.year}-${item.month}`),
              ).format("MMM")}`,
        );
      });
      const finalData = [internalDisplacementData];
      finalData.forEach((item, index) => {
        const yaxis = {
          lineColor: item.color,
          index,
          lineWidth: 1,
          tickLength: 10,
          tickWidth: 1,
          tickColor: item.color,
          labels: {
            // format: "{value:.1f}",
            style: {
              size: 12,
              color: item.color,
            },
          },
          title: {
            text: item.name,
            style: {
              color: item.color,
            },
          },
          showEmpty: false,
        };
        if (index > 0) {
          yaxis.opposite = true;
        }
        yaxisdata.push(yaxis);
      });

      setHighChartData((preval) => {
        return {
          ...preval,
          series: finalData,
          yAxis: yaxisdata,
          xAxis: xAxisCategoriesData,
        };
      });
    }
  }, [graphData, LNG_CODE]);

  let graphTitle = "";
  if (!_.isEmpty(selectedState)) {
    graphTitle = selectedState?.state_name;
  } else if (allFieldsData?.region?.value && allFieldsData?.district?.value) {
    graphTitle = `${allFieldsData?.region?.label} - ${allFieldsData?.district?.label}`;
  } else if (allFieldsData?.region?.value) {
    graphTitle = allFieldsData?.region?.label;
  } else {
    graphTitle = allFieldsData?.country?.label;
  }

  const getTickInterval = () => {
    let interval = 2;
    if (allFieldsData.isShowAllYears) {
      interval = 0;
    } else if (!allFieldsData.isShowAllYears) {
      if (tabsData?.data?.length === 1 && allFieldsData.region?.value === "") {
        interval = 0;
      } else if (
        tabsData?.data?.length > 1 &&
        allFieldsData.region?.value !== ""
      ) {
        interval = 0;
      } else if (
        tabsData?.data?.length > 1 &&
        allFieldsData.region?.value === ""
      ) {
        interval = 2;
      }
    } else {
      interval = 0;
    }
    return interval;
  };

  const options = {
    lang: {
      decimalPoint: ",",
    },
    chart: {
      zoomType: "x",
      resetZoomButton: {
        position: {
          x: -10,
          y: 10,
        },
      },
    },
    title: {
      text: `Population Displacement : ${allFieldsData?.hazard?.label} (${graphTitle})`,
      style: {
        color: " #33527a",
      },
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      itemMarginBottom: 15,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },
    exporting: {
      enabled: true,
      filename: `Population Displacement : ${allFieldsData?.hazard?.label} (${graphTitle})`,
      buttons: {
        contextButton: {
          align: "right",
          x: -35,
          y: -5,
          menuItems: [
            "viewFullscreen", // View in full screen
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
            // code for download csv format
            // {
            //   text: "Export to CSV",
            //   onclick() {
            //     // eslint-disable-next-line react/no-this-in-sfc
            //     const seriesData = this.series.map((series) => ({
            //       name: series.name,
            //       data: series.data.map((point) => point.y),
            //     }));
            //     // eslint-disable-next-line react/no-this-in-sfc
            //     const xAxisData = this.xAxis[0].categories;

            //     handleExportToCSVOpenWeather(
            //       seriesData,
            //       xAxisData,
            //       `Population Displacment ${graphTitle}`,
            //     );
            //     // Log series data and x-axis data to the console
            //   },
            // },
          ],
        },
      },
    },

    xAxis: {
      type: "datetime",
      categories: highChartData.xAxis,
      tickPixelInterval: 50,
      tickInterval: getTickInterval(),
      gridLineWidth: 1,
      crosshair: true,
      // labels: {
      //   rotation: 90, // Rotate the labels to 90 degrees
      //   align: "left", // Align the labels to the left for better readability
      // },
    },
    yAxis: highChartData.yAxis,
    tooltip: {
      shared: true,
      headerFormat: `<b> {point.x}</b><br>`,
      pointFormat: `<b style="color: {point.series.color}">{point.series.name} : <b> <b>{point.y:,.2f}</b> <br>`,
    },
    series: highChartData.series,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <div>
      <HighChartGraph
        options={options}
        style={{ width: "100%", height: "100%" }}
        className="drought-graph"
      />
      {allFieldsData?.dates?.length ? (
        <span className="time-show">
          {t("LOADING.DATE")} {dateFormatters(allFieldsData?.dates[0])}{" "}
          {t("LOADING.TO")} {dateFormatters(allFieldsData?.dates[1])}
        </span>
      ) : (
        ""
      )}
    </div>
  );
}

export default React.memo(Graph);
