import React, { useEffect } from "react";
import Filter from "./Filter";
import { renderReactOptionsArraystate } from "../../../helpers";
import RadioSubBasin from "../../../components/Common/RadioSubBasin";

function CropYieldFilter({
  selectedLocation,

  countryList,
  setSelectedLocation,
  onHandleZoom,
  statesList,
  selectedRegions,
  regionListOptions,
  setRegionListOptions,
  selectedDistricts,
  districtsList,
  districtsListOptions,
  setDistrictsListOptions,
  cropData,
  setSelectedCrop,
  selectedCrop,
  setCropData,
  setIndependentvaribles,
  independentvaribles,
  setSelectedIndependentVarible,
  selectedIndependentVarible,
  seasonsList,
  onHandleRadioBtn,
  onHandleResetData,
  startYear,
  setStartYear,
  endYear,
  setEndyear,
  selectedModal,
  setSelectedModal,
  setCyModels,
  cyModels,
  clickEventData,
  predictState,
  districtBoundry,
  geoJsonKeys,
  setDistrictJsonData,
  setScrollableyear,
  setDistricyBoundry,
  endDateRainfall,
  selectedMap,
  setAdminRadio,
  adminRadio,
  mapPreferencesData,
  loading,
  isUploadFilesOpen,
  setIsUploadFilesOpen,
  predictionFile,
  setPredictionFile,
  setTrainingDataYear,
  trainingDataYear,
  setHavePredictionData,
  havePredictionData,
  setOriginalPredictionFile,
  setTrainingDataStartYear,
  trainingDataStartYear,
  trainingMaxDate,
}) {
  useEffect(() => {
    if (statesList?.data) {
      renderReactOptionsArraystate(
        statesList?.data,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
      )?.map((eachValue) => selectedRegions.push(eachValue));

      if (!statesList?.isCompare) {
        setRegionListOptions(selectedRegions);
      }
    }
  }, [statesList]);

  useEffect(() => {
    if (districtsList) {
      const result = districtsList?.data;
      if (result) {
        renderReactOptionsArraystate(
          result,
          "name",
          "id",
          "latitude",
          "longitude",
          "zoom_level",
        )?.map((eachValue) => selectedDistricts.push(eachValue));
      }

      setDistrictsListOptions(selectedDistricts);
    }
  }, [districtsList]);

  return (
    <>
      <RadioSubBasin
        adminRadio={adminRadio}
        setAdminRadio={setAdminRadio}
        selectedCountry={selectedLocation?.country}
        disableBasin
      />

      <div className="dashboard-flood-fliter cy-predection-filter">
        <Filter
          selectedLocation={selectedLocation}
          countryList={countryList}
          setSelectedLocation={setSelectedLocation}
          onHandleZoom={onHandleZoom}
          regionListOptions={regionListOptions}
          districtsListOptions={districtsListOptions}
          cropData={cropData}
          setSelectedCrop={setSelectedCrop}
          setCropData={setCropData}
          selectedCrop={selectedCrop}
          setIndependentvaribles={setIndependentvaribles}
          independentvaribles={independentvaribles}
          setSelectedIndependentVarible={setSelectedIndependentVarible}
          seasonsList={seasonsList}
          onHandleRadioBtn={onHandleRadioBtn}
          selectedIndependentVarible={selectedIndependentVarible}
          onHandleResetData={onHandleResetData}
          startYear={startYear}
          setStartYear={setStartYear}
          endYear={endYear}
          setEndyear={setEndyear}
          selectedModal={selectedModal}
          setSelectedModal={setSelectedModal}
          setCyModels={setCyModels}
          cyModels={cyModels}
          clickEventData={clickEventData}
          predictState={predictState}
          districtBoundry={districtBoundry}
          geoJsonKeys={geoJsonKeys}
          setDistrictJsonData={setDistrictJsonData}
          setScrollableyear={setScrollableyear}
          setDistricyBoundry={setDistricyBoundry}
          endDateRainfall={endDateRainfall}
          selectedMap={selectedMap}
          mapPreferencesData={mapPreferencesData}
          setAdminRadio={setAdminRadio}
          loading={loading}
          isUploadFilesOpen={isUploadFilesOpen}
          setIsUploadFilesOpen={setIsUploadFilesOpen}
          predictionFile={predictionFile}
          setPredictionFile={setPredictionFile}
          setTrainingDataYear={setTrainingDataYear}
          trainingDataYear={trainingDataYear}
          setHavePredictionData={setHavePredictionData}
          havePredictionData={havePredictionData}
          setOriginalPredictionFile={setOriginalPredictionFile}
          setTrainingDataStartYear={setTrainingDataStartYear}
          trainingDataStartYear={trainingDataStartYear}
          trainingMaxDate={trainingMaxDate}
        />
      </div>
    </>
  );
}

export default CropYieldFilter;
