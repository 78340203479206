import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import { Formik, Form, Field } from "formik";
import { lengthRequired } from "../../helpers/validate";
import { formickTextField } from "../../components/Common/FormickField";
import DragAndDrop from "../../components/Common/DragAndDrop";
import { dropzoneDocumentUpload } from "../../constants";
import { showError } from "../../components/Common/Notification";

function MediaReferenceModal({
  toggle,
  modal,
  handleSetMediaReferences,
  setTitle,
  title,
}) {
  const { t } = useTranslation();
  const [mediaSelection, setMediaSelection] = useState("Image");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [url, setUrl] = useState([]);
  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    // Add a CSS class to the body element when the sideEditor is open
    if (modal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [modal]);
  const initialValues = {
    budget: "",
  };
  const closeBtn = (
    <GrFormClose
      size={24}
      onClick={() => toggle()}
      className="pointer media-modal-header-close-icon"
    />
  );

  const handleMediaSelection = (e) => {
    setMediaSelection(e.target.value);
  };

  const uploadedFiles = [];
  const onHandleUploadImage = (file, base64) => {
    // Create an object to store file and base64 pair
    const fileData = {
      file_name: file?.name,
      base64: base64.split(",")?.[1],
    };

    // Add the object to the uploadedFiles array
    uploadedFiles.push(fileData);
    if (uploadedFiles?.length) {
      setSelectedFiles([...selectedFiles, ...uploadedFiles]);
    }
  };

  const handleAddUrls = (e) => {
    setCurrentUrl(e.target.value);
  };

  const handleRemoveEmail = (index) => {
    const files = [...selectedFiles];
    files.splice(index, 1);
    setSelectedFiles(files);
  };

  const handleRemoveUrl = (index) => {
    const url1 = [...url];
    url1.splice(index, 1);
    setUrl(url1);
  };

  const handleSubmit = () => {
    let urlData = {};
    const arr = [...selectedFiles];

    if (url?.length > 0 && selectedFiles?.length > 0) {
      showError("Sorry, Can upload File/link at a time only");
    } else {
      if (url?.length) {
        urlData = { data: url, type: "url" };
        arr.push(urlData);

        handleSetMediaReferences(urlData);
      } else {
        handleSetMediaReferences({ type: "file", data: selectedFiles });
      }
    }
  };

  const renderFiles = () => {
    return selectedFiles?.length
      ? selectedFiles.map((item, index) => {
          return (
            item?.type !== "url" && (
              <div
                className="img-upload-container  d-flex justify-content-between m-3"
                key={item?.file_name}
              >
                <p className="url-document-modal">
                  {" "}
                  {item?.file_name?.length > 100
                    ? `${item?.file_name.slice(
                        0,
                        70,
                      )}....${item?.file_name.slice(-10)} `
                    : `${item?.file_name}`}{" "}
                </p>
                <span
                  role="button"
                  onClick={() => handleRemoveEmail(index)}
                  onKeyUp={() => {}}
                  tabIndex="0"
                  aria-label="MediaModal"
                >
                  <GrFormClose
                    size={16}
                    className="pointer media-modal-header-close-icon"
                  />
                </span>
              </div>
            )
          );
        })
      : "";
  };

  const renderUrl = () => {
    return url?.length
      ? url.map((item, index) => {
          return (
            item?.type !== "url" && (
              <div
                className="img-upload-container  d-flex justify-content-between m-3"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <p className="url-document-modal">
                  {" "}
                  {item?.url?.length > 80
                    ? `${item?.url.slice(0, 70)}.... `
                    : item?.url}{" "}
                </p>
                <span
                  role="button"
                  onClick={() => handleRemoveUrl(index)}
                  onKeyUp={() => {}}
                  tabIndex="0"
                  aria-label="MediaModal"
                  className="ml-1"
                >
                  <GrFormClose
                    size={16}
                    className="pointer media-modal-header-close-icon"
                  />
                </span>
              </div>
            )
          );
        })
      : "";
  };

  const handleUrlData = () => {
    setUrl([
      ...url,
      {
        title,
        url: currentUrl,
      },
    ]);
    setCurrentUrl("");
  };

  return (
    <div>
      <Modal isOpen={modal} size="md">
        <ModalHeader
          toggle={toggle}
          className="align-items-center media-modal-header-background"
          close={closeBtn}
        >
          <h2 className="intervention-h2 m-0">{t("DOCUMENT_UPLOAD.UPLOAD")}</h2>
        </ModalHeader>
        <ModalBody
          className="p-3"
          style={
            url?.length || selectedFiles?.length ? { height: "500px" } : {}
          }
        >
          <div className="selection-radio" onChange={handleMediaSelection}>
            <Label className="label-item" check>
              <Input
                type="radio"
                className="radio-admin"
                name="mediaReferenceSelection"
                value="Image"
                checked={mediaSelection === "Image"}
              />
              <span className="spanmap spanmap-1">
                {" "}
                {t("DOCUMENT_UPLOAD.FILE")}
              </span>
            </Label>
            <Label
              className="label-item"
              check
              data-tip
              data-for="basin-tool-tip"
            >
              <Input
                type="radio"
                className="radio-basin"
                name="mediaReferenceSelection"
                value="URL"
                checked={mediaSelection === "URL"}
              />
              <span className="spangraph spanmap-1">
                {t("EARLY_FINANCE_REPORT.URL")}
              </span>
            </Label>
          </div>

          <Formik
            validateOnMount
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {(formick) => (
              <Form>
                <Row>
                  {mediaSelection !== "Image" ? (
                    <Col sm={12} className="mb-0">
                      <FormGroup className="">
                        <Label className="region-label mt-0">
                          {t("UPLOAD_DOCUMENTS.TITLE")}
                        </Label>
                        <Field
                          classNameActive="input-class-intervention"
                          classNameInactive={
                            formick?.name?.email && formick?.name?.email
                              ? "input-class-intervention input-error-class"
                              : "input-class-intervention"
                          }
                          type="text"
                          name="budget"
                          component={formickTextField}
                          onChangeField={(e) => {
                            setTitle(e.target?.value);
                          }}
                          validate={(value) => lengthRequired(value, 30)}
                          placeholder={t("UPLOAD_DOCUMENTS.TITLE")}
                        />
                        {formick?.errors?.budget &&
                          formick?.touched?.budget && (
                            <p className=" error">
                              {t(formick?.errors?.budget)}
                            </p>
                          )}
                      </FormGroup>
                    </Col>
                  ) : (
                    ""
                  )}
                  <div className="media-body-height  mb-3">
                    {mediaSelection === "Image" ? (
                      <>
                        <div className="mt-4">
                          <DragAndDrop
                            onHandleUploadImage={onHandleUploadImage}
                            isUpload
                            acceptList={dropzoneDocumentUpload}
                            isBasic
                            isUploadDocs
                          />
                        </div>
                        <div className="img-upload-main-container-docs">
                          {renderFiles()}
                        </div>
                      </>
                    ) : (
                      <>
                        <Label className="region-label mt-0 ml-2">
                          {" "}
                          {t("EARLY_FINANCE_REPORT.URL")}{" "}
                        </Label>
                        <div className="send-container">
                          <Row>
                            <Col
                              sm={12}
                              md={12}
                              className="align-reports-center-btn"
                            >
                              <Input
                                type="url"
                                className="ef-report-text-field"
                                placeholder="Type or paste URL"
                                onChange={handleAddUrls}
                                value={currentUrl}
                              />
                            </Col>

                            <Col sm={12} md={12} className="ud-add-btn mt-3">
                              <Button
                                type="button"
                                className="media-add-btn-repo mb-1"
                                onClick={() => {
                                  handleUrlData();
                                  setTitle("");
                                  formick.setFieldTouched("budget", false);
                                  formick.setFieldValue("budget", "");
                                }}
                                disabled={currentUrl === ""}
                              >
                                {t("EARLY_ACTIONS.ADD")}
                              </Button>
                            </Col>
                          </Row>
                        </div>
                        <div className="urlupload-main-container-docs">
                          {renderUrl()}
                        </div>
                      </>
                    )}
                  </div>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>

        {url?.length || selectedFiles?.length ? (
          <ModalFooter className="w-100">
            <div className="w-100 active-pahse-btn-section justify-content-end">
              <Button
                type="button"
                className="media-cancel-btn mr-3"
                onClick={() => {
                  toggle();
                }}
              >
                {t("EARLY_ACTIONS.CANCLE")}
              </Button>
              <Button
                type="button"
                className="media-add-btn mb-1"
                onClick={() => handleSubmit()}
                disabled={
                  title !== "" ||
                  currentUrl !== "" ||
                  (selectedFiles?.length === 0 && url?.length === 0) ||
                  (selectedFiles?.length > 0 && url?.length > 0)
                }
              >
                {t("BULLET_IN.SUBMIT")}
              </Button>
            </div>
          </ModalFooter>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
}

export default MediaReferenceModal;
