import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { BsFillInfoCircleFill } from "react-icons/bs";
import PreparednessPhaseIcon from "../../assest/img/preparedness-phase-icon.svg";
import ReadinessPhaseIcon from "../../assest/img/readiness-phase-icon.svg";
import ActivePhaseIcon from "../../assest/img/active-phase-icon.svg";
import CompletedPreparednessPhaseIcon from "../../assest/img/completed-preparedness-phase-icon.svg";
import CompletedReadinessPhaseIcon from "../../assest/img/completed-readiness-phase-icon.svg";
import CompletedActivePhaseIcon from "../../assest/img/completed-active-phase-icon.svg";

function EarlyActionProcess({ activeStep, setStepSize }) {
  const { t } = useTranslation();
  const location = useLocation();
  const [isStepperDisabled, setIsStepperDisabled] = useState(false);

  useEffect(() => {
    if (location?.pathname?.length) {
      location?.pathname.includes("/assessments")
        ? setIsStepperDisabled(true)
        : setIsStepperDisabled(false);
    }
  }, [location]);

  return (
    <div className="ea-space-y">
      <Row
        className="proces-card"
        style={isStepperDisabled ? { pointerEvents: "none" } : {}}
      >
        <Col lg={4} className="proces-card-col">
          <img
            src={
              activeStep === 0
                ? PreparednessPhaseIcon
                : CompletedPreparednessPhaseIcon
            }
            alt="icon"
            className={activeStep >= 0 ? "cursor-pointer" : "img-gray"}
            onClick={() => {
              setStepSize(0);
              localStorage.setItem("stepperActiveStep", "0"); // Main stepper active step
              localStorage.setItem("subStepperActivePhase", 0); // Sub stepper active step
            }}
            onKeyUp={() => {}}
          />
          <h2>
            {t("EARLY_ACTIONS.PREPAREDNESS")}
            <BsFillInfoCircleFill className="info-pre" />
          </h2>
        </Col>
        <div
          className={
            activeStep >= 1
              ? "line-hori line-hori-l line-hori-2"
              : "line-hori line-hori-l "
          }
        />
        <Col lg={4} className="proces-card-col">
          <img
            src={
              activeStep > 1 ? CompletedReadinessPhaseIcon : ReadinessPhaseIcon
            }
            alt="icon"
            className={activeStep >= 1 ? "cursor-pointer" : "img-gray"}
            onClick={() => {
              setStepSize(1);
              localStorage.setItem("stepperActiveStep", "1");
            }}
            onKeyUp={() => {}}
            style={activeStep === 1 ? {} : { pointerEvents: "none" }}
          />
          <h2 className={activeStep >= 1 ? "" : "disable-h2"}>
            {t("EARLY_ACTIONS.READINESS")}
            <BsFillInfoCircleFill className="info-pre" />
          </h2>
        </Col>
        <div
          className={
            activeStep > 1
              ? "line-hori line-hori-r line-hori-2"
              : "line-hori line-hori-r"
          }
        />
        <Col lg={4} className="proces-card-col">
          <img
            src={activeStep >= 2 ? CompletedActivePhaseIcon : ActivePhaseIcon}
            alt="icon"
            // className="img-gray cursor-pointer"
            className={activeStep === 2 ? "cursor-pointer" : "img-gray"}
            onClick={() => {
              setStepSize(2);
              localStorage.setItem("stepperActiveStep", "2");
            }}
            onKeyUp={() => {}}
            style={activeStep === 2 ? {} : { pointerEvents: "none" }}
          />
          <h2 className={activeStep > 1 ? "" : "disable-h2"}>
            {t("EARLY_ACTIONS.ACTIVE_PHASE")}
            <BsFillInfoCircleFill className="info-pre" />
          </h2>
        </Col>
      </Row>
    </div>
  );
}

export default EarlyActionProcess;
