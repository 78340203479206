import Home from "../pages/Home";
import Login from "../pages/Authentication/Login";
import ResetPassword from "../pages/Authentication/ResetPassword";
import ClimberHome from "../pages/Climber";
import OtherTools from "../pages/Othertools";
import DocumentUpload from "../pages/DocumentUpload";
import DashBoard from "../pages/DashBoard";
import RegisterUser from "../pages/Authentication/Register";
import Health from "../pages/Health";
import Market from "../pages/Market";
import Nutrition from "../pages/Nutrition";
import PolutationDisplacement from "../pages/PopulationDisplacement";
import DefaultPage from "../pages/Defaultpage";
import RegisterVerification from "../pages/Authentication/RegisterVerification";
import TermsAndConditions from "../pages/Authentication/TermsAndConditions";
import Drought from "../pages/Drought";
import Flood from "../pages/Flood";
import WeatherForcast from "../pages/WeatherForcast";
import UserGuide from "../pages/UserGuide/index";
import UserSearchContentPage from "../pages/UserGuide/UserSearchContentPage";
import UserGuideDetailPage from "../pages/UserGuide/UserGuideDetailPage";
import Settings from "../pages/Settings";
import ManageUserGuide from "../pages/ManageUserGuide";
import ManageUserGuideForm from "../pages/ManageUserGuideForm.js";
import HomePageBulletin from "../pages/Bulletin/HomePageBulletin";
import OnlineBulletin from "../pages/Bulletin/CreateOnlineBulletin";
import BackOffcieBulletin from "../pages/BulletinBackOffice";
import CreateBulletinPdf from "../pages/BulletinBackOffice/CreateBulletinPdf";
import UploadPastData from "../pages/UploadPastData";
import ApprovalPastData from "../pages/ApprovalPastData";
import ManageStaticData from "../pages/ManageStaticData";
import ODK from "../pages/ODK";
import SurveyConfiguration from "../pages/ODK/SurveyConfiguration";
import ManageSurveyors from "../pages/ODK/ManageSurveyors";
import OurTeams from "../pages/OurTeams/index";
import OurPartner from "../pages/OurPartners/index";
import ManageUser from "../pages/ManageUser/index";
import CropPrediction from "../pages/CropPrediction";
import EarlyAction from "../pages/EarlyAction/index";
import EAProjetcs from "../pages/EAProjects";
import PreparednessPhase from "../pages/EAPhases";
import Assessments from "../pages/Assesments";
import ReadinessPhase from "../pages/ReadinessPhase";
import NewIntervention from "../pages/EAPhases/readinessPhase/NewIntervention";
import RiskForecasting from "../pages/EAPhases/readinessPhase/EditIntervention";
import ViewIntervention from "../pages/EAPhases/readinessPhase/RiskForecasting";
import EarlyFinancePhase from "../pages/EarlyFinance";
import EFNewIntervention from "../pages/EarlyFinance/EditIntervention";
import ViewFinance from "../pages/EarlyFinance/RiskForecasting";
import EarlyFinanceStatic from "../pages/EarlyFinance/EarlyFinanceStatic";
import ActivePhase from "../pages/ActivePhase";
import EarlyFinanceReport from "../pages/EarlyFinanceReport";
import AlertDashboard from "../pages/AlertDashboard";
import RapidResponse from "../pages/RapidResponse";
import ModalPerformanceFeedback from "../pages/ModalPerformanceFeedback";
import RapidResponseNewsfeed from "../pages/rapidResponseNewsfeed";
import ApproveDisasterEvents from "../pages/ApproveDisasterEvents";

export const userRoutes = [
  {
    path: "/settings",
    component: <Settings />,
  },
  {
    path: "/manageUserGuide",
    component: <ManageUserGuide />,
  },
  {
    path: "/manageUserGuide-form",
    component: <ManageUserGuideForm />,
  },
  {
    path: "/back-office-bulletin/:currentTab",
    component: <BackOffcieBulletin />,
  },
  {
    path: "/create-bulletin-pdf",
    component: <CreateBulletinPdf />,
  },
  {
    path: "/uploadPastData",
    component: <UploadPastData />,
  },
  {
    path: "/approvalPastData",
    component: <ApprovalPastData />,
  },
  {
    path: "/ManageStaticData",
    component: <ManageStaticData />,
  },
  {
    path: "/surveyConfigurationDashboard",
    component: <ODK />,
  },
  {
    path: "/surveyConfiguration",
    component: <SurveyConfiguration />,
  },
  {
    path: "/manageSurveyors",
    component: <ManageSurveyors />,
  },
  {
    path: "/model-performance-feedback",
    component: <ModalPerformanceFeedback />,
  },
  {
    path: "/approve-disaster-events",
    component: <ApproveDisasterEvents />,
  },
];

export const authRoutes = [];

export const climberRoutes = [
  {
    path: "/climber",
    component: <ClimberHome />,
  },

  {
    path: "/othertools",
    component: <OtherTools />,
  },
  {
    path: "/document-upload",
    component: <DocumentUpload />,
  },
  {
    path: "/climadapt",
    component: <DefaultPage />,
  },
];

export const DashboardRoutesUnprotected = [
  {
    path: "/user-guide",
    component: <UserGuide />,
  },
  {
    path: "/user-guide-search",
    component: <UserSearchContentPage />,
  },
  {
    path: "/user-guide-details",
    component: <UserGuideDetailPage />,
  },
];
export const DashboardRoutes = [
  {
    path: "/early-warnings/nutrition",
    component: <Nutrition />,
  },
  {
    path: "/early-warnings/nutrition/stunting",
    component: <Nutrition />,
  },
  {
    path: "/early-warnings/nutrition/total_children",
    component: <Nutrition />,
  },
  {
    path: "/early-warnings/nutrition/underweight",
    component: <Nutrition />,
  },
  {
    path: "/early-warnings/nutrition/wasting",
    component: <Nutrition />,
  },
  {
    path: "/early-warnings/population_displacement",
    component: <PolutationDisplacement />,
  },
  {
    path: "/early-warnings/monitoring_indicators",
    // component: <Drought />,
    component: <Flood />,
  },
  {
    path: "/early-warnings/forecast_indicators",
    component: <WeatherForcast />,
  },
  {
    path: "/early-warnings/monitoring_indicators/drought",
    component: <Drought />,
  },
  {
    path: "/early-warnings/monitoring_indicators/flood",
    component: <Flood />,
  },
  {
    path: "/early-warnings/health",
    component: <Health />,
  },
  {
    path: "/crop_yield_prediction",
    component: <CropPrediction />,
  },
  {
    path: "/early-warnings/health/malaria",
    component: <Health />,
  },
  {
    path: "/early-warnings/health/diarrhea",
    component: <Health />,
  },
  {
    path: "/early-warnings/health/dengue",
    component: <Health />,
  },
  {
    path: "/early-warnings/health/other_infection_disease",
    component: <Health />,
  },
  {
    path: "/early-warnings/market",
    component: <Market />,
  },
  {
    path: "/early-warnings/market/food_prices",
    component: <Market />,
  },
  {
    path: "/early-warnings/agriculture",
    component: <DefaultPage />,
  },
  {
    path: "/early-warnings/disasters",
    component: <DefaultPage />,
  },
  {
    path: "/early-warnings/fund_reports",
    component: <DefaultPage />,
  },
  {
    path: "/online-bulletin",
    component: <HomePageBulletin />,
  },
  {
    path: "/create-online-bulletin",
    component: <OnlineBulletin />,
  },
  {
    path: "/ea-projects",
    component: <EAProjetcs />,
  },
  {
    path: "/earlyaction",
    component: <EarlyAction />,
  },
  {
    path: "/preparedness-phase",
    component: <PreparednessPhase />,
  },
  {
    path: "/assessments",
    component: <Assessments />,
  },
  {
    path: "/readiness-phase",
    component: <ReadinessPhase />,
  },
  {
    path: "/new-intervention",
    component: <NewIntervention />,
  },
  {
    path: "/ef-finance",
    component: <EFNewIntervention />,
  },
  {
    path: "/view-finance",
    component: <ViewFinance />,
  },
  {
    path: "/early-finance",
    component: <EarlyFinancePhase />,
  },
  {
    path: "risk-forecasting",
    component: <RiskForecasting />,
  },
  {
    path: "/view-intervention",
    component: <ViewIntervention />,
  },
  {
    path: "active-phase",
    component: <ActivePhase />,
  },
  {
    path: "/early-finance-report",
    component: <EarlyFinanceReport />,
  },
  {
    path: "/early-finance-page",
    component: <EarlyFinanceStatic />,
  },
  {
    path: "/early-warnings/alert_dashboard",
    component: <AlertDashboard />,
  },
  {
    path: "/rapid_response",
    component: <RapidResponse />,
  },
  {
    path: "/rapid-response-newsfeed",
    component: <RapidResponseNewsfeed />,
  },
];
export const awareRoutes = [
  {
    path: "/home",
    component: <Home />,
  },
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/forgot-password",
    component: <ResetPassword />,
  },
  {
    path: "/reset-password",
    component: <ResetPassword />,
  },
  {
    path: "/dashboard",
    component: <DashBoard />,
  },
  {
    path: "/registerPage",
    component: <RegisterUser />,
  },
  { path: "/dashboard", component: <DashBoard /> },
  {
    path: "/register",
    component: <RegisterVerification />,
  },
  {
    path: "terms",
    component: <TermsAndConditions />,
  },
  {
    path: "/manageUser",
    component: <ManageUser />,
  },
  {
    path: "/ourpartner",
    component: <OurPartner />,
  },
  {
    path: "/OurTeams",
    component: <OurTeams />,
  },
];
