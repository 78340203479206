import React, { useCallback, useEffect, useState, Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { BsInfo } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import _, { debounce } from "lodash";
import moment from "moment";
import { useNavigate, useOutletContext } from "react-router-dom";
import GraphLoader from "react-spinners/FadeLoader";
import {
  getEWRawDataDatesRequest,
  getHealthTimeSeriesRequest,
  mapPreferenceRequest,
  getHealthMalariaData,
  mapPreferenceSuccess,
  getHealthMalariaDataSuccess,
} from "../../store/actions";
import {
  sectorialClimateId,
  healthMalariaPredectionList,
} from "../../constants";
// import Loader from "../../components/Common/Loader";
import CommonThresholdIndicators from "../../components/Common/CommonThresholdIndicators";
import HealthGraph from "./Graph";
import HealthTable from "./Table";
// import { debounce } from "../../helpers/index";

// lazy loading components
const HealthMapContainer = lazy(() => import("./MapContainer"));
const HealthFilter = lazy(() => import("./HealthFilter"));

function Health() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [, setOpen] = useOutletContext();

  const paramsData = new URLSearchParams(window.location.search);
  const selectedModule = paramsData.get("subModule");
  const selectedSubModule = paramsData.get("module");
  const [local, setLocal] = useState(
    JSON.parse(localStorage.getItem("selectedCountry")),
  );
  const [position, setPosition] = useState([]);
  const [zooms, setZooms] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [indexLayers, setIndexLayers] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [allFieldsData, setAllFieldsData] = useState({});

  const [openScreen, setOpenScreen] = useState(false);
  const [isShowTableView, setIsShowTableView] = useState(false);
  const [tabsData, setTabsData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [malariaGeoJsonData, setMalariaGeoJsonData] = useState([]);
  const [mapes, setMapes] = useState();
  const [selectedLegendData, setSelectedLegendData] = useState([]);
  const [initialSelectedDate, setInitialSelectedDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [tableData, setTableData] = useState([]);
  const [selectedState, setSelecetedState] = useState({});
  const [thresholdData, setThresholdData] = useState([]);
  const [showHospitalData, setShowHospitalData] = useState(false);
  const [showDeathsdataData, setShowDeathsdataData] = useState(false);
  const [predictedData, setPredictedData] = useState({
    map: [],
    graph: [],
  });
  const [isActualGraph, setisActualGraph] = useState(true);
  const history = useNavigate();

  const nextProps = useSelector((state) => ({
    statesList: state.Core.corestateList,
    districtsList: state.Core.coredistrictList,
    mapPreferenceList: state.MapPreference,
    Weather: state.Weather,
    Graph: state?.Weather?.weatherForecastGraphData,
    isCompareLocation: state.Core.coredistrictList?.config?.isCompareLocation,
    healthTimeseriesData: state.Health.healthTimeseriesData,
    healthTimeSeriesLoading: state.Health.healthTimeSeriesLoading,
    healthTimeSeriesError: state.Health.healthTimeSeriesError,
    EWRawDataDates: state.Health.getRawDataDates,
    getRawDataDatesError: state.Health.getRawDataDatesError,
    healthMalariaData: state.Health.healthMalariaData,
    Health: state.Health,
    isHealthMapDataRequesting: state.Health.isHealthMalariaDataRequesting,

    healthMalariaPredectionData: state.Health.healthMalariaPredectionData,
  }));

  // For getting Selected Country and updating map initial load
  useEffect(() => {
    window.scrollTo(0, 0);

    const CountryData = JSON.parse(localStorage.getItem("selectedCountry"));
    if (CountryData && selectedSubModule) {
      setOpen(true);
      setPosition([local.latitude, local.longitude]);
      setZooms(local.zoom_level);

      setLocal(JSON.parse(localStorage.getItem("selectedCountry")));
      dispatch(
        mapPreferenceRequest({
          country_id: JSON.parse(localStorage.getItem("selectedCountry"))
            ?.value,
          module: selectedModule,
        }),
      );
      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: CountryData,
          state: "",
          district: "",
        };
      });
      return () => {
        dispatch(mapPreferenceSuccess(""));
        dispatch(getHealthMalariaDataSuccess(""));
        setOpen(false);
      };
    } else {
      history("/climber");
    }
  }, [selectedSubModule]);

  const noOfPredictedCases = {
    name: t("HEALTH.PREDICTED_MALARIA_CASES"),
    type: "column",
    color: "#22ad7a",
    yAxis: 0,
    marker: {
      enabled: false,
    },
    data: [],
  };

  const onHandleZoom = (center, zoom) => {
    setPosition(center);
    setZooms(zoom);
  };

  // function to call the selected distric and state
  const handleSelectedLocation = (filterLocation) => {
    setSelectedLocation(filterLocation);
  };

  const getEarlyWarningRawDataDates = (countryId) => {
    const payload = {
      countryId,
      moduleName: selectedModule,
      subModuleSlug: selectedSubModule,
    };
    dispatch(getEWRawDataDatesRequest(payload));
  };

  useEffect(() => {
    getEarlyWarningRawDataDates(
      JSON.parse(localStorage.getItem("selectedCountry"))?.value,
    );
  }, []);

  // function for handle all fields data and based fields data calling api's
  const handleAllFieldsData = (data, selectedTabIndex) => {
    setPredictedData((prev) => {
      return {
        ...prev,
        map: [],
        graph: [],
      };
    });
    setisActualGraph(true);

    const { country, region, dates, district, avialble_years } = data;

    const startDate = dates.length ? dates[0] : null;
    const endDate = dates.length > 1 ? dates[1] : null;

    const payload = {
      country_id: country?.value,
      state_id: region ? region.value : "",
      district_id: district ? district.value : "",

      start_year: new Date(startDate).getFullYear(),
      end_year: new Date(endDate).getFullYear(),
      // start_month: "",
      // end_month: "",
      start_month: data?.EWRawDataDates?.is_monthly
        ? new Date(startDate).getMonth() + 1
        : "",
      end_month: data?.EWRawDataDates?.is_monthly
        ? new Date(endDate).getMonth() + 1
        : "",
      start_day: "",
      end_day: "",
      sub_menu_slug: selectedSubModule,
      graph_type: "table_view",
    };

    // map view only we are doing these chnages
    const a = moment([
      new Date(startDate).getFullYear(),
      new Date(startDate).getMonth(),
    ]);
    const b = moment([
      new Date(endDate).getFullYear(),
      new Date(endDate).getMonth(),
    ]);
    let tabsFinalData = [];

    const years = [];
    for (let i = 0; i <= b.diff(a, "year", true).toFixed(2); i += 1) {
      if (
        avialble_years.includes(new Date(startDate).getFullYear() + i) &&
        b.diff(a, "year") >= 1
      ) {
        years.push({
          value: new Date(startDate).getFullYear() + i,
          label: new Date(startDate).getFullYear() + i,
        });
      }
    }
    // If user selects only 1 Year in filter
    if (!years?.length) {
      years.push({
        value: new Date(startDate).getFullYear(),
        label: new Date(startDate).getFullYear(),
      });
    }
    tabsFinalData = {
      type: "years",
      data: years.reverse(),
      start_month: new Date(startDate).getMonth() + 1,
      end_month: new Date(endDate).getMonth() + 1,
    };

    const availableTabsData = tabsFinalData?.data?.filter(
      (tab) => tab !== false,
    );

    let mapPayload;
    if (availableTabsData && availableTabsData?.length) {
      // const selcetedYear = availableTabsData[selectedTabIndex || 0].value;

      mapPayload = {
        country_id: country?.value,
        state_id: region ? region.value : "",
        district_id: district ? district.value : "",
        start_year:
          b.diff(a, "year") >= 1
            ? availableTabsData[selectedTabIndex || 0]?.value
            : new Date(startDate).getFullYear(),
        end_year:
          b.diff(a, "year") >= 1
            ? availableTabsData[selectedTabIndex || 0]?.value
            : new Date(endDate).getFullYear(),
        start_month: data?.EWRawDataDates?.is_monthly
          ? b.diff(a, "year") >= 1
            ? 1
            : new Date(startDate).getMonth() + 1
          : "",

        end_month: data?.EWRawDataDates?.is_monthly
          ? b.diff(a, "year") >= 1
            ? 1
            : new Date(endDate).getMonth() + 1
          : "",

        // end_month:
        //   b.diff(a, "year") >= 1 ? 12 : new Date(endDate).getMonth() + 1,
        start_day: "",
        end_day: "",
        sub_menu_slug: selectedSubModule,
        population_crisis_type: "",
        market_commodity: [],
      };
      setTabIndex(selectedTabIndex || 0);
      setInitialSelectedDate((prev) => {
        return {
          ...prev,
          startDate: selectedTabIndex
            ? availableTabsData[selectedTabIndex || 0]
            : availableTabsData[0],
        };
      });

      setTabsData(tabsFinalData);
      setAllFieldsData({
        ...data,
        payload,
        isShowAllYears: tabsFinalData?.data?.length !== 1,
      });
    }
    if (payload?.start_year && payload?.end_year) {
      if (new Date(startDate) <= new Date(endDate)) {
        if (selectedSubModule === "diarrhea") {
          const diarrheaPayload = { ...payload };
          delete diarrheaPayload.start_month;
          delete diarrheaPayload.end_month;
          // For Diarrhea
          setGraphData([]);
          dispatch(getHealthTimeSeriesRequest(diarrheaPayload));
        } else {
          // For Others
          setGraphData([]);
          dispatch(getHealthTimeSeriesRequest(payload));
        }

        if (mapPayload?.start_year && mapPayload?.end_year) {
          if (selectedSubModule === "diarrhea") {
            const diarrheaPayload = { ...mapPayload };
            delete diarrheaPayload.start_month;
            delete diarrheaPayload.end_month;
            // For Diarrhea
            dispatch(getHealthMalariaData(diarrheaPayload));
          } else {
            // For Others
            dispatch(getHealthMalariaData(mapPayload));
          }
        }
      }
    }
  };

  const optimizedFn = useCallback(debounce(handleAllFieldsData, 500), [
    selectedSubModule,
  ]);

  useEffect(() => {
    if (!_.isEmpty(nextProps?.healthMalariaPredectionData)) {
      setPredictedData((prev) => {
        return {
          ...prev,
          map: nextProps?.healthMalariaPredectionData?.map,
          graph:
            selectedLocation?.state === "" ||
            selectedLocation?.state?.value === ""
              ? []
              : nextProps?.healthMalariaPredectionData?.graph,
        };
      });

      const updatedData = malariaGeoJsonData.map((item) => {
        const foundItem = nextProps?.healthMalariaPredectionData?.map.find(
          (stateName) => stateName?.state_name === item?.state_name,
        );
        if (foundItem) {
          item.predictionValue = foundItem?.pred_val?.[0];
          item.id = uuidv4();
          // Add any other updates you need for matched items
          return item;
        } else {
          // If no match, return the original item without any changes
          return item;
        }
      });

      // setGraphData([...graphData , ...nextProps?.healthMalariaPredectionData?.graph])
      setMalariaGeoJsonData(updatedData);
    }
  }, [nextProps?.healthMalariaPredectionData]);

  useEffect(() => {
    if (nextProps.healthTimeseriesData) {
      let output = {};
      if (!_.isEmpty(selectedState)) {
        // For showing only data for selected state on map
        const selectedStateData = nextProps.healthTimeseriesData?.filter(
          (item) => item?.state_name === selectedState?.state_name,
        );
        output = _(selectedStateData).groupBy("state_name").value();
      } else {
        output = _(nextProps.healthTimeseriesData)
          .groupBy("state_name")
          .value();
      }

      let dataBaseonRegion = [];
      if (allFieldsData.isShowAllYears) {
        dataBaseonRegion = Object.keys(output).map((item) => {
          return {
            state_name: item,
            data: _(output[item])
              .groupBy("year")
              .map((objs, key) => {
                return {
                  year: key,
                  state_name: item,
                  no_of_case: Number(_.sumBy(objs, "no_of_case")),
                  deaths: Number(_.sumBy(objs, "deaths")),
                  hospitalized_case: Number(_.sumBy(objs, "hospitalized_case")),
                  no_of_case_thresold: objs[0]?.no_of_case_thresold
                    ? objs[0]?.no_of_case_thresold
                    : "",
                };
              })
              .value(),
          };
        });
        setTableData(dataBaseonRegion);
      } else {
        dataBaseonRegion = Object.keys(output).map((region) => {
          return {
            data: output[region],
            state_name: region,
          };
        });
        setTableData(dataBaseonRegion);
      }

      // setTableData(dataBaseonRegion);
      if (allFieldsData.isShowAllYears) {
        let data = [];
        if (!_.isEmpty(selectedState)) {
          // For showing only data for selected state on map
          const selectedStateData = nextProps.healthTimeseriesData?.filter(
            (item) => item?.state_name === selectedState?.state_name,
          );
          data = _(selectedStateData)
            .groupBy("year")
            .map((objs, key) => {
              return {
                year: key,
                month: 1,
                no_of_case: Number(_.sumBy(objs, "no_of_case")),
                deaths: Number(_.sumBy(objs, "deaths")),
                hospitalized_case: Number(_.sumBy(objs, "hospitalized_case")),
              };
            })
            .value();
        } else {
          data = _(nextProps.healthTimeseriesData)
            .groupBy("year")
            .map((objs, key) => {
              return {
                year: key,
                month: 1,
                no_of_case: Number(_.sumBy(objs, "no_of_case")),
                deaths: Number(_.sumBy(objs, "deaths")),
                hospitalized_case: Number(_.sumBy(objs, "hospitalized_case")),
              };
            })
            .value();
        }
        setGraphData(data);
      } else {
        if (!_.isEmpty(selectedState)) {
          // For showing only data for selected state on map
          const selectedStateData = nextProps.healthTimeseriesData?.filter(
            (item) => item?.state_name === selectedState?.state_name,
          );
          setGraphData(selectedStateData);
        } else {
          setGraphData(nextProps.healthTimeseriesData);
        }
      }
    }
  }, [nextProps.healthTimeseriesData, selectedState]);

  useEffect(() => {
    if (
      nextProps.getRawDataDatesError?.length ||
      nextProps.healthTimeSeriesError?.length
    ) {
      setGraphData([]);
    }
  }, [nextProps.getRawDataDatesError, nextProps.healthTimeSeriesError]);

  useEffect(() => {
    if (nextProps.isHealthMapDataRequesting) {
      setTableData([]);
    } else if (
      nextProps.healthMalariaData &&
      nextProps.healthMalariaData?.length
    ) {
      setMalariaGeoJsonData([]);
      // setIsLoading(false);
      const dataArray = [];
      nextProps.healthMalariaData?.map((item) => {
        if (item?.no_of_case !== undefined) {
          dataArray.push(item);
        }
        return null;
      });
      if (dataArray?.length) {
        setMalariaGeoJsonData(dataArray);
      }
    } else if (!_.isEmpty(nextProps.Health.healthMalariaDataError)) {
      const { code } = nextProps.Health.healthMalariaDataError;
      if (code === 400) {
        setMalariaGeoJsonData([]);
      }
    }
  }, [
    nextProps.healthMalariaData,
    nextProps.Health.healthMalariaDataError,
    nextProps.isHealthMapDataRequesting,
  ]);

  useEffect(() => {
    setShowDeathsdataData(false);
    setShowHospitalData(false);
  }, [selectedSubModule, selectedLocation.country]);

  useEffect(() => {
    if (nextProps.EWRawDataDates) {
      if (nextProps.EWRawDataDates?.threshold_data) {
        setThresholdData(nextProps.EWRawDataDates?.threshold_data);
      } else {
        setThresholdData([]);
      }
    }
  }, [nextProps.EWRawDataDates]);

  const handleSubmoduleTitle = () => {
    if (selectedSubModule === "diarrhea") {
      return t("HEALTH.DIARRHEA");
    } else if (selectedSubModule === "malaria") {
      return t("HEALTH.MALARIA");
    }
  };

  const handleInfo = () => {
    const name = selectedSubModule === "malaria" ? "Malaria" : "Diarrhea";
    window.open(
      `/user-guide-details?topicId=${sectorialClimateId}&sub-topic-name=${name}&slug=user_guide`,
    );
  };

  return (
    <div className="dashboard-filter-div health-map">
      <Suspense fallback="">
        <div className="dashboard-filter">
          <span className="dashboard-filter-h3">{t("DASHBOARD.HEALTH")}</span>
          <span className="m-1 font-weight-8">:</span>
          <h3
            className="dashboard-filter-h3"
            style={{ textTransform: "capitalize" }}
          >
            {handleSubmoduleTitle()}
          </h3>
          <BsInfo
            className="info-icon cursor-pointer ml-2 mb-2"
            onClick={() => handleInfo()}
          />

          {isShowTableView && (
            <CommonThresholdIndicators data={thresholdData} />
          )}
        </div>
        <Row className="dashboard-drought-section dashboard-health-section">
          <Col
            lg={12}
            className={
              openScreen
                ? "dashboard-drought-col2 pr-0 fullmap"
                : "dashboard-drought-col2 pr-0"
            }
          >
            <HealthFilter
              openScreen={openScreen}
              setOpenScreen={setOpenScreen}
              isShowTableView={isShowTableView}
              setIsShowTableView={setIsShowTableView}
              setPosition={setPosition}
              setZooms={setZooms}
              handleAllFieldsData={optimizedFn}
              EWRawDataDates={nextProps?.EWRawDataDates}
              getEarlyWarningRawDataDates={getEarlyWarningRawDataDates}
              defaultCountry={JSON.parse(
                localStorage.getItem("selectedCountry"),
              )}
              setSelectedLegendData={setSelectedLegendData}
              selectedLegendData={selectedLegendData}
              malariaGeoJsonData={malariaGeoJsonData}
              setMalariaGeoJsonData={setMalariaGeoJsonData}
              handleSelectedLocation={handleSelectedLocation}
              setSelecetedState={setSelecetedState}
              selectedState={selectedState}
              tabIndex={tabIndex}
              selectedModule={selectedModule}
              selectedSubModule={selectedSubModule}
              setTabIndex={setTabIndex}
              setShowHospitalData={setShowHospitalData}
              showHospitalData={showHospitalData}
              setShowDeathsdataData={setShowDeathsdataData}
              showDeathsdataData={showDeathsdataData}
              healthMalariaPredectionList={healthMalariaPredectionList}
              allFieldsData={allFieldsData}
              setPredictedData={setPredictedData}
              predictedData={predictedData}
              // selectedSubModule={selectedSubModule}
              // onHandlePredectionData={onHandlePredectionData}
            />
            {isShowTableView && (
              <HealthTable
                data={{
                  tableData,
                  avilableDates: tabsData,
                }}
                tabIndex={tabIndex}
                allFieldsData={allFieldsData}
                selectedModule={selectedModule}
                colSpan={
                  showDeathsdataData && showHospitalData
                    ? 3
                    : showHospitalData || showDeathsdataData
                    ? 2
                    : 1
                }
                EWRawDataDates={nextProps?.EWRawDataDates}
                hideTableView={!tableData?.length}
                setShowHospitalData={setShowHospitalData}
                showHospitalData={showHospitalData}
                setShowDeathsdataData={setShowDeathsdataData}
                showDeathsdataData={showDeathsdataData}
              />
            )}
            <HealthMapContainer
              position={position}
              zooms={zooms}
              onHandleZoom={onHandleZoom}
              local={local}
              selectedLocation={selectedLocation}
              indexLayers={indexLayers}
              setIndexLayers={setIndexLayers}
              mapPreferencesData={mapPreferencesData}
              setMapPreferencesData={setMapPreferencesData}
              malariaGeoJsonData={malariaGeoJsonData}
              mapes={mapes}
              setMapes={setMapes}
              selectedLegendData={selectedLegendData}
              setSelectedLegendData={setSelectedLegendData}
              initialSelectedDate={initialSelectedDate}
              avilableYears={tabsData}
              handleAllFieldsData={optimizedFn}
              allFieldsData={allFieldsData}
              setMalariaGeoJsonData={setMalariaGeoJsonData}
              setSelecetedState={setSelecetedState}
              isShowTableView={isShowTableView}
              selectedSubModule={selectedSubModule}
              legendName={
                selectedSubModule === "diarrhea"
                  ? t("HEALTH.DIARRHEA")
                  : t("HEALTH.MALARIA")
              }
              parameterName={t("HEALTH.NO_OF_CASES")}
              tabIndex={tabIndex}
              selectedModule={selectedModule}
              regionsData={nextProps?.EWRawDataDates?.regions}
              showLoader={nextProps.isHealthMapDataRequesting}
              setOpen={setOpen}
              predictedData={predictedData}
            />
          </Col>

          <Col
            lg={12}
            className={
              openScreen
                ? "graph-health pr-0 mt-3 graph-dn"
                : "graph-health pr-0 mt-3"
            }
          >
            {graphData?.length ? (
              <HealthGraph
                openScreen={openScreen}
                setOpenScreen={setOpenScreen}
                graphData={graphData}
                healthCategorySlug={selectedSubModule}
                allFieldsData={allFieldsData}
                selectedState={selectedState}
                EWRawDataDates={nextProps?.EWRawDataDates}
                tabsData={tabsData}
                selectedSubModule={selectedSubModule}
                predictedDataGraph={predictedData?.graph}
                setisActualGraph={setisActualGraph}
                isActualGraph={isActualGraph}
                noOfPredictedCases={predictedData?.graph && noOfPredictedCases}
              />
            ) : (
              <div className="mt-2 text-center">
                {nextProps.healthTimeSeriesLoading ? (
                  <div className="graph-loader-container" id="loader">
                    <div className=" loader-text-alignmant">
                      <GraphLoader
                        color="#179B6B"
                        loading={nextProps.healthTimeSeriesLoading}
                        margin={2}
                        height={10}
                        radius={2}
                      />
                      {t("HOME.LOADING_GRAPH_DATA")}
                    </div>
                  </div>
                ) : (
                  t("HEALTH.NO_GRAPH_DATA")
                )}
              </div>
            )}
          </Col>
        </Row>
      </Suspense>
    </div>
  );
}

export default React.memo(Health);
