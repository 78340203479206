import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import moment from "moment";
import HighChartGraph from "../../components/Common/HighCharts";
import { dateFormatters } from "../../helpers";
// import { handleExportToCSVOpenWeather } from "../../helpers/excelDownload";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

function HealthGraph({
  graphData,
  healthCategorySlug,
  allFieldsData,
  selectedState,
  predictedDataGraph,
  tabsData,
  selectedSubModule,
  noOfPredictedCases,
}) {
  const { t } = useTranslation();
  const [highChartData, setHighChartData] = useState({
    yAxis: [],
    series: [],
    xAxis: [],
  });
  const [xaxisPlotLines, setXaxisPlotLines] = useState([]);
  const LNG_CODE = localStorage.getItem("i18nextLng");
  const categorySlug = t(`HEALTH.${healthCategorySlug?.toUpperCase()}`);

  const noOfCases = {
    name: t("HEALTH.NO_CASES"),
    type: "column",
    color: "#28537D",
    yAxis: 0,
    marker: {
      enabled: false,
    },
    data: [],
  };

  const noOfHospitalCases = {
    name: t("HEALTH.NO_HOSP_CASES"),
    type: "line",
    color: "#22AD7A",
    yAxis: 1,
    data: [],
  };
  const noOfDeathCases = {
    name: t("HEALTH.NO_DEATHS"),
    type: "line",
    color: "#E86933",
    yAxis: 2,
    data: [],
  };

  useEffect(() => {
    const yaxisdata = [];
    const xAxisCategoriesData = [];
    setXaxisPlotLines([]);
    if (graphData?.length) {
      graphData.forEach((item) => {
        noOfCases.data.push({
          y: item.no_of_case
            ? Number(item?.no_of_case?.toFixed(2))
            : item.no_of_case,
        });
        noOfHospitalCases.data.push({
          y: item?.hospitalized_case
            ? Number(item.hospitalized_case?.toFixed(2))
            : item?.hospitalized_case,
          percentage: `| Percentage : <b> ${(
            (item.hospitalized_case / item.no_of_case) *
            100
          ).toFixed(2)} % </b>`,
        });
        noOfDeathCases.data.push({
          y: item?.deaths ? Number(item?.deaths?.toFixed(2)) : item?.deaths,
          percentage: `| Percentage : <b> ${(
            (item.deaths / item.no_of_case) *
            100
          ).toFixed(2)} % </b>`,
        });
        xAxisCategoriesData.push(
          allFieldsData?.isShowAllYears
            ? moment(new Date(`${item.year}-${item.month}`)).format("YYYY")
            : selectedSubModule === "diarrhea"
            ? `${item.state_name}-${moment(new Date(`${item.year}`)).format(
                "YYYY",
              )}`
            : item?.month
            ? `${item.state_name}-${moment(
                new Date(`${item.year}-${item.month}`),
              ).format("MMM")}`
            : `${item.state_name}-${moment(new Date(`${item.year}`)).format(
                "YYYY",
              )}`,
        );
        if (predictedDataGraph?.length > 0) {
          noOfPredictedCases.data.push({
            y: null,
          });
        }
      });
      if (predictedDataGraph?.length > 0) {
        setXaxisPlotLines([
          {
            color: "red",
            width: 1,
            value: xAxisCategoriesData?.length - 0.5, // May is the 5th point on the X-axis (0-indexed)
            zIndex: 5, // Ensure the line is above the chart's gridlines
            label: {
              align: "center",
              style: {
                color: "#eb2b1e",
              },
            },
          },
        ]);
        predictedDataGraph.forEach((item) => {
          noOfPredictedCases.data.push({
            y: Number(item?.pred_val?.[0]?.toFixed(2)),
          });

          xAxisCategoriesData.push(
            `${item.state_name}-${moment(
              new Date(`${item.year}-${item.month}`),
            ).format("MMM")}`,
          );
        });
      }

      const finalData =
        predictedDataGraph?.length > 0
          ? [noOfCases, noOfHospitalCases, noOfDeathCases, noOfPredictedCases]
          : [noOfCases, noOfHospitalCases, noOfDeathCases];
      finalData.forEach((item, index) => {
        const yaxis = {
          lineColor: item.color,
          index,
          lineWidth: 1,
          tickLength: 10,
          tickWidth: 1,
          tickColor: item.color,
          labels: {
            style: {
              size: 12,
              color: item.color,
            },
          },
          title: {
            text: item.name,
            style: {
              color: item.color,
            },
          },
          showEmpty: false,
        };
        if (index > 0) {
          yaxis.opposite = true;
        }
        yaxisdata.push(yaxis);
      });

      setHighChartData((preval) => {
        return {
          ...preval,
          series: finalData,
          yAxis: yaxisdata,
          xAxis: xAxisCategoriesData,
        };
      });
    }
  }, [graphData, LNG_CODE, predictedDataGraph]);

  const getTickInterval = () => {
    let interval = 2;
    if (allFieldsData.isShowAllYears) {
      interval = 0; // for all years all regions
    } else if (
      !allFieldsData.isShowAllYears &&
      selectedSubModule !== "diarrhea"
    ) {
      if (tabsData?.data?.length <= 12 && allFieldsData.region?.value === "") {
        interval = 0; // for less than equal to 12 data and all regions
      } else if (
        tabsData?.data?.length > 1 &&
        allFieldsData.region?.value !== ""
      ) {
        interval = 0; // for multiple data and single region
      } else if (
        tabsData?.data?.length > 1 &&
        allFieldsData.region?.value === ""
      ) {
        interval = 2; // for multiple data and all regions
      }
    } else {
      if (selectedSubModule === "diarrhea") {
        interval = 0;
      } else {
        interval = 0;
      }
    }
    return interval;
  };

  let graphTitle = "";
  if (!_.isEmpty(selectedState)) {
    graphTitle = selectedState?.state_name;
  } else if (allFieldsData?.region?.value) {
    graphTitle = `${allFieldsData?.region?.label}`;
  } else {
    graphTitle = allFieldsData?.country?.label;
  }

  const options = {
    chart: {
      zoomType: "x",
      resetZoomButton: {
        position: {
          x: -10,
          y: 10,
        },
      },
    },
    title: {
      text: `${t("HEALTH.GRAPH_TITLE", { categorySlug })} (${graphTitle})`,
      style: {
        color: " #33527a",
      },
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      itemMarginBottom: 15,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },
    exporting: {
      enabled: true,
      filename: `${t("HEALTH.GRAPH_TITLE", { categorySlug })} (${graphTitle})`,
      buttons: {
        contextButton: {
          align: "right",
          x: -35,
          y: -5,
          menuItems: [
            "viewFullscreen", // View in full screen
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
            // code for download csv format
            // {
            //   text: "Export to CSV",
            //   onclick() {
            //     // eslint-disable-next-line react/no-this-in-sfc
            //     const seriesData = this.series.map((series) => ({
            //       name: series.name,
            //       data: series.data.map((point) => point.y),
            //     }));
            //     // eslint-disable-next-line react/no-this-in-sfc
            //     const xAxisData = this.xAxis[0].categories;
            //     handleExportToCSVOpenWeather(
            //       seriesData,
            //       xAxisData,
            //       `${selectedSubModule} ${graphTitle}`,
            //     );
            //     // Log series data and x-axis data to the console
            //   },
            // },
          ],
        },
      },
    },

    xAxis: {
      type: "datetime",
      // labels: {
      //   formatter: (value) => {
      //     return allFieldsData.isShowAllYears
      //       ? moment(value.value).format("YYYY")
      //       : moment(value.value).format("YYYY-MMM");
      //   },
      // },
      labels: {
        rotation: allFieldsData?.isShowAllYears ? 0 : 90, // Rotate the labels to 90 degrees
        align: "left", // Align the labels to the left for better readability
      },

      categories: highChartData.xAxis,
      tickPixelInterval: 50,
      tickInterval: getTickInterval(),
      gridLineWidth: 1,
      crosshair: true,
      plotLines: xaxisPlotLines,
    },
    yAxis: highChartData.yAxis,
    tooltip: {
      shared: true,
      headerFormat: `<b> {point.x}</b><br>`,
      pointFormat: `<b style="color: {point.series.color}">{point.series.name} : <b> <b>{point.y:,.0f}</b> {point.percentage} <br>`,
    },
    series: highChartData.series,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <div>
      <HighChartGraph
        options={options}
        style={{ width: "100%", height: "100%" }}
        className="drought-graph"
      />
      {allFieldsData?.dates?.length ? (
        <span className="time-show">
          {t("LOADING.DATE")} {dateFormatters(allFieldsData?.dates[0])}{" "}
          {t("LOADING.TO")} {dateFormatters(allFieldsData?.dates[1])}
        </span>
      ) : (
        ""
      )}
    </div>
  );
}

export default React.memo(HealthGraph);
