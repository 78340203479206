import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, Label, Input } from "reactstrap";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  addBulletinRequest,
  getAllTemplateListRequest,
  addBulletinSuccess,
} from "../../store/actions";
import { showError, showSuccess } from "../../components/Common/Notification";
import RightArrow from "../../assest/img/back-button.svg";
import UploadIcon from "../../assest/img/upload-image.svg";

function CreateBulletinPdf() {
  const [formData, setFormData] = useState({
    topic: "",
    content_file: "",
    bulletin_file: "",
    category_id: "",
    id: "",
  });
  const [templateListOptions, setTemplateListOptions] = useState([]);
  const [uploadedImageName, setUploadedImageName] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const dispatch = useDispatch();
  const form = new FormData();
  const { Bulletin } = useSelector((state) => ({
    Bulletin: state.Bulletin,
  }));

  const history = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const renderTemplateListOptions = (data) => {
    if (data) {
      const list = data?.data?.data?.result;
      const params = [...list];
      const options = [];
      if (params?.length) {
        params.map((param) => {
          const data1 = {
            value: param?.id,
            label: `${param?.name}`,
            id: param?.id,
          };
          options.push(data1);
          return true;
        });
        return options;
      }
    }
  };
  useEffect(() => {
    dispatch(getAllTemplateListRequest());
    window.scroll(0, 0);
    return () => {
      dispatch(addBulletinSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (Bulletin?.addBulletinData?.data) {
      showSuccess(Bulletin?.addBulletinData?.data?.data?.result);
      history("/back-office-bulletin/:currentTab");
    }
  }, [Bulletin.addBulletinData]);

  useEffect(() => {
    if (Bulletin?.addBulletinDataError) {
      if (
        Bulletin?.addBulletinDataError?.data?.response?.data?.exception?.message
      ) {
        showError(
          Bulletin?.addBulletinDataError?.data?.response?.data?.exception
            ?.message,
        );
      }
      if (Bulletin?.addBulletinDataError?.data?.message) {
        showError(Bulletin?.addBulletinDataError.data?.message.slice(0, 15));
      }
    }
  }, [Bulletin?.addBulletinDataError]);

  useEffect(() => {
    if (Bulletin?.getAllTemplateListData) {
      const options = renderTemplateListOptions(
        Bulletin?.getAllTemplateListData,
      )
        ? renderTemplateListOptions(Bulletin?.getAllTemplateListData)
        : null;
      if (options?.length && options) {
        setTemplateListOptions(options);
      }
    }
  }, [Bulletin]);

  const handleCategoryChange = (e) => {
    setFormData({ ...formData, category_id: e });
  };

  const handleImage = (e) => {
    const imageFile = e?.target?.files[0];
    const reader = new FileReader();
    if (e?.target?.files?.length !== 0 && reader) {
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event) => {
        const image = new Image();
        image.src = event?.target?.result;
        image.onload = () => {
          if (image.height <= image.width && image.width > 450) {
            setFormData({ ...formData, content_file: imageFile });
            setIsImageUploaded(true);
            if (imageFile?.name) {
              setUploadedImageName(imageFile?.name);
            }
            setIsValid(false);
          } else {
            setIsValid(true);
            setIsImageUploaded(false);
            setUploadedImageName("");
            setFormData({ ...formData, content_file: "" });
            showError(
              `${t("CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.IMAGE_ERROR")}`,
            );
          }
        };
      };
    } else {
      setIsValid(true);
      showError(`${t("CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.IMAGE_ERROR")}`);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleContent = async (e) => {
    const base64 = await toBase64(e?.target?.files[0]);
    const slicedBase64 = base64.slice(28);
    setFormData({ ...formData, bulletin_file: slicedBase64 });

    if (e?.target?.files[0]?.name) {
      setUploadedFileName(e?.target?.files[0]?.name);
    }
  };
  const handleTitle = (e) => {
    setFormData({ ...formData, topic: e?.target?.value });
  };

  const handleSubmit = () => {
    form.append("topic", formData?.topic);
    form.append("content_file", formData?.content_file);
    form.append("bulletin_content", formData?.bulletin_file);
    form.append("category_id", formData?.category_id?.value);
    form.append("template_id", "");
    form.append("html_content", "");
    form.append("json_content", "");
    dispatch(addBulletinRequest(form));
  };

  const submitEnableDisable = () => {
    if (
      !isImageUploaded ||
      _.isEmpty(formData.bulletin_file) ||
      _.isEmpty(formData.topic) ||
      _.isEmpty(formData.category_id)
    )
      return true;
    else return false;
  };

  return (
    <div className="search-container">
      <div className="online-bulletin-header">
        <Row className="bulletin-top-text">
          <Col xs={2} lg={4}>
            <img
              src={RightArrow}
              className="pointer arrow-back"
              onClick={() => history("/back-office-bulletin/:currentTab")}
              onKeyUp={() => {}}
              alt="right-arrow"
            />
          </Col>
        </Row>
        <div className="create-bulletin-pdf-editor">
          <div className="bulletin-top-text d-flex justify-content-center mb-2">
            <h5>{t("BULLET_IN.ADD_BULLETIN")} </h5>
          </div>
          <div className="bulletin-editor-field">
            <Label className="bulletin-field-label">
              {t("BULLET_IN.CATEGORY")}:
            </Label>
            <div className="field-width common-select-tem-anomaly">
              <Select
                classNamePrefix="react-select"
                className="upload-image common-select-r"
                name="category"
                id="category"
                options={templateListOptions}
                value={formData?.category_id}
                onChange={(e) => {
                  handleCategoryChange(e);
                }}
                placeholder={t("BULLET_IN.SELECT_CATEGORY")}
              />
            </div>
          </div>
          <div className="bulletin-editor-field">
            <Label className="bulletin-field-label">
              {t("BULLET_IN.SELECT_TITLE")}:
            </Label>
            <div className="field-width common-select-tem-anomaly">
              <Input
                className="upload-image common-select-r"
                name="title"
                id="tile"
                type="text"
                onChange={(e) => {
                  handleTitle(e);
                }}
                value={formData?.topic}
                placeholder={t("BULLET_IN.SELECT_TITLE")}
              />
            </div>
          </div>
          <div className="file-input-wrapper bulletin-editor-field">
            <Label className="bulletin-field-label">
              {t("BULLET_IN.UPLOAD_IMAGE")}:
            </Label>
            <div
              className={
                isValid
                  ? "bulletin-img-error mb-2 field-width common-select-tem-anomaly"
                  : "mb-2 field-width common-select-tem-anomaly"
              }
            >
              <Label htmlFor="file" className=" form-control common-select-r">
                {t("BULLET_IN.UPLOAD_IMAGE")}
                &nbsp;&nbsp;
                <img
                  src={UploadIcon}
                  alt="not-available"
                  className="upload-icon"
                />
              </Label>
            </div>
            <input
              id="upload_image"
              name="file"
              type="file"
              className="upload-image "
              onChange={(event) => {
                handleImage(event);
              }}
            />
          </div>
          <div className=" mb-2 selected-image-name">
            {!uploadedImageName && location?.state
              ? location?.state[2]?.content_file?.split("/").reverse()[0]
                  ?.length > 27
                ? `${
                    location?.state[2]?.bulletin_file
                      ?.split("/")
                      .reverse()[0]
                      ?.substring(0, 23) ?? ""
                  }...`
                : location?.state[2]?.content_file?.split("/").reverse()[0]
              : uploadedImageName?.length > 27
              ? `${uploadedImageName?.substring(0, 23)}...`
              : uploadedImageName}
          </div>
          <div className="file-input-wrapper bulletin-editor-field">
            <Label className="bulletin-field-label">
              {t("BULLET_IN.CONTENT_FILE")}:
            </Label>
            <div className="field-width common-select-tem-anomaly">
              <Label htmlFor="pdffile" className="form-control common-select-r">
                {t("BULLET_IN.UPLOAD_CONTENT")}
                &nbsp;&nbsp;
                <img
                  src={UploadIcon}
                  alt="not-available"
                  className="upload-icon"
                />
              </Label>
            </div>
            <input
              id="upload_pdf"
              name="pdffile"
              type="file"
              accept="application/pdf"
              className="upload-image "
              onChange={(e) => {
                handleContent(e);
              }}
            />
          </div>

          <div className=" mb-2 selected-image-name">
            {!uploadedFileName && location?.state
              ? location?.state[2]?.bulletin_file?.split("/").reverse()[0]
                  ?.length > 27
                ? `${
                    location?.state[2]?.bulletin_file
                      ?.split("/")
                      .reverse()[0]
                      ?.substring(0, 23) ?? ""
                  }...`
                : location?.state[2]?.bulletin_file?.split("/").reverse()[0]
              : uploadedFileName?.length > 27
              ? `${uploadedImageName?.substring(0, 23)}...`
              : uploadedFileName}
          </div>
          <div className="text-center bulletin-editor-field">
            {!location?.state?.[3] ? (
              <Button
                onClick={handleSubmit}
                className="btn btn-bulletin-submit mx-2"
                loading={Bulletin.isAddBulletinRequesting}
                disabled={submitEnableDisable()}
              >
                {t("BULLET_IN.SUBMIT")}
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateBulletinPdf;
