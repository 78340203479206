import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { DISCLAIMER_CGIAR_LINK } from "../../constants";

function DisclaimerModal(props) {
  const { modal, toggleModal } = props;
  const { t } = useTranslation();

  return (
    <Modal isOpen={modal} toggle={() => toggleModal()} size="lg">
      <ModalHeader
        className="weather-forecast-modal-title"
        toggle={() => toggleModal()}
      >
        <div className="d-flex flex-row justify-content-end">
          <div>{t("DASHBOARD.DISCLAMIER")}</div>
          <button
            type="button"
            className=" btn btn-outline-none button-close"
            onClick={toggleModal}
          >
            X
          </button>
        </div>
      </ModalHeader>
      <ModalBody className="disclaimer-content">
        <div>{t("DASHBOARD.DISCLAMIER_CONTENT_1")}</div>
        <div className="mt-3">{t("DASHBOARD.DISCLAMIER_CONTENT_2")}</div>
        <div className="mt-3">{t("DASHBOARD.DISCLAMIER_CONTENT_3")}</div>
        <div className="mt-3">
          {t("DASHBOARD.DISCLAMIER_CONTENT_4")} (
          <a
            href={DISCLAIMER_CGIAR_LINK}
            rel="noreferrer"
            target="_blank"
            className="cgiar_link_style"
          >
            {t("DASHBOARD.DISCLAMIER_CGIAR")}
          </a>
          ).
        </div>
      </ModalBody>
    </Modal>
  );
}
export default DisclaimerModal;
