import React, { useState, useEffect } from "react";
import { Alert } from "reactstrap";
import { useTranslation } from "react-i18next";
import { GrFormClose } from "react-icons/gr";
import _ from "lodash";
import {
  renderChartTime,
  openWeatherParams,
  OpenWeatherChartLabels,
} from "../../components/Common/Utils";
import HighChartGraph from "../../components/Common/HighCharts";
import { chartColorLabel, HighChartConstants } from "../../constants";
import { dateFormatters } from "../../helpers";
import GraphLoader from "../../components/GraphLoader";
import { handleExportToCSVOpenWeather } from "../../helpers/excelDownload";

function WeatherGraph({
  indicatorsData,
  setShowChart,
  showChart,
  chartData,
  aggregation,
  startDate,
  endDate,
  selectedParams,
  compareData,
  markerPolygonData,
  openweatherSeriseData,
  compare,
  errorData,
  setErrorData,
  isWeatherForecastGraphRequesting,
  graphLoading,
  weatherForecastGraphDataError,
}) {
  const { t } = useTranslation();
  const myDiv = document.getElementById("graph1");
  const containerWidth = myDiv?.offsetWidth;
  const containerHeight = myDiv?.offsetHeight;

  const [highChartData, setHighChartData] = useState({
    xaxis: [],
    yaxis: [],
    serise: [],
  });
  const [chartWidth, setChartWidth] = useState();
  const [showGraph, setGraphShow] = useState(false);

  const [graphTitle, setGraphTitle] = useState("");
  const options = {
    title: {
      text: graphTitle,
      style: {
        fontSize: "17px",
        color: "#435A52",
      },
    },
    chart: {
      type: "column",
      height: containerHeight - 15,
      zoomType: "x",
      width: chartWidth,
      resetZoomButton: {
        position: {
          align: "left",
          verticalAlign: "top",
          x: 10,
          y: 10,
        },
      },
    },
    legend: {
      layout: "horizontal",
      verticalAlign: "bottom",
      align: "left",
      offsetX: 0,
    },

    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          align: "left",
          x: 35,
          y: -5,

          menuItems: [
            "viewFullscreen", // View in full screen
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
            {
              text: "Export to CSV",
              onclick() {
                // eslint-disable-next-line react/no-this-in-sfc
                const seriesData = this.series.map((series) => ({
                  name: series.name,
                  data: series.data.map((point) => point.y),
                }));
                // eslint-disable-next-line react/no-this-in-sfc
                const xAxisData = this.xAxis[0].categories;
                handleExportToCSVOpenWeather(
                  seriesData,
                  xAxisData,
                  "open_weather",
                );
                // Log series data and x-axis data to the console
              },
            },
          ],
        },
      },
    },
    xAxis: [
      {
        categories: highChartData?.xaxis,
        crosshair: false,
        gridLineWidth: 1,
        labels: {
          style: {
            fontSize: "8px",
          },
        },
      },
    ],
    yAxis: highChartData?.yaxis,

    tooltip: {
      shared: true,
      useHTML: true,
      headerFormat: '<table><tr><th colspan="2">{point.x}</th></tr>',
      pointFormat:
        "<tr><td >{series.name} : </td>" +
        '<td style="text-align: right"><b>{point.y} </b></td></tr>',
      footerFormat: "</table>",
      valueDecimals: 2,
    },
    series: highChartData?.serise,
    plotOptions: {
      column: {
        pointWidth: 10,
      },
      series: {
        dataLabels: {
          enabled: true,
          borderRadius: HighChartConstants?.two,

          allowOverlap: true,
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    setGraphShow(isWeatherForecastGraphRequesting);
    setErrorData({
      showError: false,
      message: "",
    });
  }, [isWeatherForecastGraphRequesting]);

  useEffect(() => {
    setGraphShow(!graphLoading);
  }, [graphLoading]);

  useEffect(() => {
    if (!_.isEmpty(weatherForecastGraphDataError)) {
      setErrorData({
        showError: true,
        message: weatherForecastGraphDataError,
      });
    }
  }, [weatherForecastGraphDataError]);

  useEffect(() => {
    if (!_.isEmpty(chartData)) {
      const yaxisdata = [];
      const yaxisSerise = [];
      let formattedDates = [];
      const dates = chartData?.precipitation?.millis;

      formattedDates = dates.map((item) => {
        const dateFormat = new Date(item);
        return renderChartTime(dateFormat);
      });
      openWeatherParams()?.map((eachData, index) => {
        const eachParam = eachData?.name;
        if (chartData?.[eachData?.slug]) {
          const seriseData = {
            name: eachParam,
            type: eachData?.slug === "precipitation" ? "column" : "line",
            data: chartData?.[eachData?.slug]?.data,
            visible:
              index === 0 || index === 1
                ? true
                : selectedParams?.[0]?.parameter?.slug === eachData?.slug,
            yAxis: index,
            color: chartColorLabel[index],
            dataLabels: {
              format: "{y:.2f}",
              backgroundColor: chartColorLabel[index],
              borderRadius: HighChartConstants?.three,
              padding: HighChartConstants?.three,
              y: HighChartConstants?.three,
              style: {
                color: HighChartConstants?.white,
                textOutline: "none",
              },
            },
          };
          const yaxis = {
            lineColor: chartColorLabel[index],
            lineWidth: 1,
            tickLength: 10,
            tickWidth: 1,
            tickAmount: 6,
            tickColor: chartColorLabel[index],
            labels: {
              format: "{value:.1f}",
              style: {
                size: 14,
                color: chartColorLabel[index],
              },
            },
            title: {
              text: `${eachParam} (${chartData?.[eachData?.slug]?.Units?.[0]})`,
              style: {
                color: chartColorLabel[index],
              },
            },
          };

          if (index > 0) {
            yaxis.opposite = true;
          }
          yaxis.showEmpty = false;
          yaxisdata.push(yaxis);
          yaxisSerise.push(seriseData);
        }
        return null;
      });
      setHighChartData((prev) => {
        return {
          ...prev,
          xaxis: formattedDates,
          yaxis: yaxisdata,
          serise: yaxisSerise,
        };
      });
      setGraphShow(true);

      setGraphTitle(
        `${t("HOME_POPUP.DATA_SOURCES")} :${indicatorsData?.label} ${
          indicatorsData?.label === "IRI" ? "" : `(${aggregation})`
        } `,
      );
    }
  }, [chartData]);

  // for open weather data
  useEffect(() => {
    if (
      !_.isEmpty(openweatherSeriseData) &&
      indicatorsData?.name === t("WEATHER_FORCAST.OPEN_WEATHER") &&
      compare === false
    ) {
      const yaxisdata = [];
      const yaxisSerise = [];
      let formattedDates = [];

      formattedDates = openweatherSeriseData?.map((item) =>
        OpenWeatherChartLabels(item.dt_txt),
      );

      const tempValues = openweatherSeriseData?.map((item) => item.temp);
      const popValues = openweatherSeriseData?.map((item) =>
        item.rain ? item.rain["1h"] : 0,
      );

      const windSpeedValues = openweatherSeriseData?.map(
        (item) => item.wind_speed,
      );

      const pressureValues = openweatherSeriseData?.map(
        (item) => item?.pressure,
      );

      const cloudValues = openweatherSeriseData?.map((item) => item?.clouds);

      const width =
        formattedDates?.length >= HighChartConstants?.fifteen
          ? formattedDates?.length * HighChartConstants?.sixtyFive
          : null;
      containerWidth > width
        ? setChartWidth(containerWidth)
        : setChartWidth(width);

      const array1 = [
        {
          slug: "precipitation",
          data: popValues,
          name: `${t("WEATHER_FORCAST.PRECIPITATION_LABEL")}`,
        },
        {
          slug: "temperature",
          data: tempValues,
          name: `${t("WEATHER_FORCAST.TEMPERATURE_LABEL")}`,
        },
        {
          slug: "wind_speed",
          data: windSpeedValues,
          name: `${t("WEATHER_FORCAST.WIND_SPEED_LABEL")}`,
        },
        {
          slug: "pressure",
          data: pressureValues,
          name: `${t("WEATHER_FORCAST.PRESSURE_LABEL")}`,
        },
        {
          slug: "cloud",
          data: cloudValues,
          name: `${t("WEATHER_FORCAST.CLOUD_LABEL")}`,
        },
      ];

      array1?.map((eachData, index) => {
        const eachParam = eachData?.name;
        const seriseData = {
          name: eachParam,
          type: index < 1 ? "column" : "line",
          data: eachData?.data,
          visible:
            index === 0 || index === 1
              ? true
              : selectedParams?.[0]?.parameter?.slug === eachData?.slug,
          yAxis: index,
          color: chartColorLabel[index],
          dataLabels: {
            format: "{y:.2f}",
            backgroundColor: chartColorLabel[index],
            borderRadius: HighChartConstants?.three,
            padding: HighChartConstants?.three,
            y: HighChartConstants?.three,
            style: {
              color: HighChartConstants?.white,
              textOutline: "none",
            },
          },
        };
        const yaxis = {
          lineColor: chartColorLabel[index],
          lineWidth: 1,
          tickLength: 10,
          tickWidth: 1,

          tickAmount: 6,
          tickColor: chartColorLabel[index],
          labels: {
            format: "{value:.1f}",
            style: {
              fontSize: "10px",
              color: chartColorLabel[index],
            },
          },
          title: {
            text: `${eachParam}`,

            style: {
              fontSize: "10px",
              color: chartColorLabel[index],
              fontWeight: "bold",
            },
          },
        };

        if (index > 0) {
          yaxis.opposite = true;
        }
        yaxis.showEmpty = false;
        yaxisdata.push(yaxis);
        yaxisSerise.push(seriseData);

        return null;
      });

      setHighChartData((prev) => {
        return {
          ...prev,
          xaxis: formattedDates,
          yaxis: yaxisdata,
          serise: yaxisSerise,
        };
      });

      options.xAxis[0].labels.rotation = -65;
      options.xAxis[0].labels.align = "right";

      setGraphTitle(
        `${t("HOME_POPUP.DATA_SOURCES")} :${indicatorsData?.label} `,
      );
      setGraphShow(true);
    }
  }, [openweatherSeriseData]);

  useEffect(() => {
    if (!_.isEmpty(compareData) && compare) {
      // compare graph
      let dates;
      let formattedDates = [];
      const yaxisdata = [];
      const yaxisSerise = [];
      if (!_.isEmpty(compareData) && compareData?.length) {
        if (indicatorsData?.name === "IRI") {
          dates = compareData?.[0]?.millis;
          if (compareData?.length === 2) {
            // eslint-disable-next-line no-unused-vars
            dates =
              compareData?.[0]?.millis?.length >
              compareData?.[1]?.millis?.length
                ? compareData?.[0]?.millis
                : compareData?.[1]?.millis;
          }
        } else {
          dates = compareData?.[0]?.Timestamp;
          if (compareData?.length === 2) {
            // eslint-disable-next-line no-unused-vars
            dates =
              compareData?.[0]?.Timestamp?.length >
              compareData?.[1]?.Timestamp?.length
                ? compareData?.[0]?.Timestamp
                : compareData?.[1]?.Timestamp;
          }
        }
      }
      formattedDates = dates?.map((item) => {
        const dateFormat = new Date(item);
        return renderChartTime(dateFormat);
      });

      const width =
        formattedDates?.length >= HighChartConstants?.fifteen
          ? formattedDates?.length * HighChartConstants?.sixtyFive
          : null;
      containerWidth > width
        ? setChartWidth(containerWidth)
        : setChartWidth(width);
      compareData?.map((eachData, index) => {
        const seriseData = {
          name: `${eachData.layerType} (${eachData.location})`,
          type: "line",
          data: eachData?.data,
          yAxis: index,
          color: chartColorLabel[index],
          visible: true,
          dataLabels: {
            format: "{y:.2f}",
            backgroundColor: chartColorLabel[index],
            borderRadius: HighChartConstants?.three,
            padding: HighChartConstants?.three,
            y: HighChartConstants?.three,
            style: {
              color: HighChartConstants?.white,
              textOutline: "none",
            },
          },
        };
        const yaxis = {
          lineColor: chartColorLabel[index],
          lineWidth: 1,
          tickLength: 10,
          tickAmount: 6,
          tickWidth: 1,
          tickColor: chartColorLabel[index],
          labels: {
            format: "{value:.1f}",
            style: {
              size: "10px",
              color: chartColorLabel[index],
            },
          },
          title: {
            text: `${eachData.layerType} - ${eachData.location}`,
            style: {
              color: chartColorLabel[index],
              fontWeight: "bold",
            },
          },
        };
        if (index > 0) {
          yaxis.opposite = true;
        }
        yaxis.showEmpty = false;
        yaxisdata.push(yaxis);
        yaxisSerise.push(seriseData);
        return null;
      });
      setHighChartData((prev) => {
        return {
          ...prev,
          xaxis: formattedDates,
          yaxis: yaxisdata,
          serise: yaxisSerise,
        };
      });

      setGraphTitle(
        `${t("WEATHER_FORCAST.PARAMETER")}: ${
          selectedParams[selectedParams?.length - 1]?.parameter?.name
        }`,
      );
    }
  }, [compareData]);

  useEffect(() => {
    if (!_.isEmpty(markerPolygonData)) {
      let dates;
      const yaxisdata = [];
      const yaxisSerise = [];
      let formattedDates = [];
      if (
        !_.isEmpty(markerPolygonData) &&
        markerPolygonData?.graph_data?.length
      ) {
        dates = markerPolygonData?.graph_data[0]?.millisData;
        if (markerPolygonData?.graph_data?.length === 2) {
          // eslint-disable-next-line no-unused-vars
          dates =
            markerPolygonData?.graph_data[0]?.millisData?.length >
            markerPolygonData?.graph_data[1]?.millisData?.length
              ? markerPolygonData?.graph_data[0]?.millisData
              : markerPolygonData?.graph_data[1]?.millisData;
        }
      }

      // eslint-disable-next-line no-unused-vars
      formattedDates = dates.map((item) => {
        const dateFormat = new Date(item);
        return renderChartTime(dateFormat);
      });

      const width =
        formattedDates.length >= HighChartConstants?.fifteen
          ? formattedDates?.length * HighChartConstants?.sixtyFive
          : null;
      containerWidth > width
        ? setChartWidth(containerWidth)
        : setChartWidth(width);

      markerPolygonData?.graph_data?.map((eachData, index) => {
        const seriseData = {
          name:
            eachData.layerType === "marker"
              ? `Point (${eachData.latlng[1].toFixed(
                  2,
                )},${eachData.latlng[0].toFixed(2)})`
              : `${t("DRAW_SHAPE.PLOYGON_GRAPH")}-${eachData?.polygonCount}`, // PLOYGON_GRAPH
          type: "line",
          data: eachData?.data,
          yAxis: index,
          color: chartColorLabel[index],
          visible: true,
          dataLabels: {
            format: "{y:.2f}",
            backgroundColor: chartColorLabel[index],
            borderRadius: HighChartConstants?.three,
            padding: HighChartConstants?.three,
            y: HighChartConstants?.three,
            style: {
              color: HighChartConstants?.white,
              textOutline: "none",
            },
          },
        };
        const yaxis = {
          lineColor: chartColorLabel[index],
          lineWidth: 1,
          tickLength: 10,
          tickAmount: 6,
          tickWidth: 1,
          tickColor: chartColorLabel[index],
          labels: {
            format: "{value:.1f}",
            style: {
              size: 12,
              color: chartColorLabel[index],
              fontWeight: "bold",
            },
          },

          title: {
            useHTML: true,
            text:
              eachData.layerType === "marker"
                ? `Point<br/> (${eachData.latlng[1].toFixed(
                    1,
                  )},${eachData.latlng[0].toFixed(1)})`
                : `${t("DRAW_SHAPE.PLOYGON_GRAPH")}-${eachData?.polygonCount}`,

            style: {
              maxWidth: "200px",
              color: chartColorLabel[index],
              textAlign: "center",
            },
          },
        };
        if (index > 0) {
          yaxis.opposite = true;
        }
        yaxis.showEmpty = false;
        yaxisdata.push(yaxis);
        yaxisSerise.push(seriseData);
        return null;
      });
      setHighChartData((prev) => {
        return {
          ...prev,
          xaxis: formattedDates,
          yaxis: yaxisdata,
          serise: yaxisSerise,
        };
      });

      setGraphTitle(
        `${t("HOME_POPUP.DATA_SOURCES")} :${
          indicatorsData?.label
        } ( ${aggregation} )`,
      );
      setGraphShow(true);
    }
  }, [markerPolygonData]);

  return (
    showChart && (
      <div
        className={
          containerWidth === chartWidth
            ? "drought-footer-area chart-design chart-hidden"
            : "drought-footer-area chart-design chart-flow"
        }
        id="graph1"
      >
        {showGraph && !errorData?.showError ? (
          <>
            <HighChartGraph options={options} />
            <span className="time-show">
              {t("LOADING.DATE")} {dateFormatters(startDate)} {t("LOADING.TO")}{" "}
              {dateFormatters(endDate)}
            </span>
            <div>
              <div className="close-icon-highcharts">
                <GrFormClose
                  className="close-graph-bth"
                  onClick={() => setShowChart(false)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <GraphLoader />
            {errorData?.showError && (
              <div>
                <div className="graph-error-style">
                  <Alert color="warning" className="alert-warning-container">
                    {errorData?.message}
                  </Alert>
                </div>
              </div>
            )}
            <div style={{ right: 30 }} className="close-icon-highcharts">
              <GrFormClose
                className="close-graph-bth"
                onClick={() => {
                  setChartWidth(null);
                  setShowChart(false);
                }}
              />
            </div>
          </>
        )}
      </div>
    )
  );
}
export default WeatherGraph;
