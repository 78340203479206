import { takeEvery, fork, all, call, put } from "redux-saga/effects";

// Login Redux States
import ALERT_DASH_BOARD_CONST from "./actionTypes";
import { alertIndicatorError, alertIndicatorSuccess } from "./actions";
import checkHttpStatus from "../apiUtils";
import {
  EARLY_WARNING_API,
  AXIOS_INSTANCE,
  AXIOS_INSTANCE_LOADER,
} from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* alertIndicatorlist() {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  const defaultSelectedCountry = JSON.parse(
    localStorage.getItem("selectedCountry"),
  );
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.get(
        `${EARLY_WARNING_API}/early_warnings/get_alert_dashboard_indices/${defaultSelectedCountry.code}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(alertIndicatorSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(alertIndicatorError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(alertIndicatorError(errorData));
  }
}

export function* watchDashBoardIndicator() {
  yield takeEvery(
    ALERT_DASH_BOARD_CONST.ALERT_INDICATOR_REQUEST,
    alertIndicatorlist,
  );
}
function* AlertDashBoardSaga() {
  yield all([fork(watchDashBoardIndicator)]);
}

export default AlertDashBoardSaga;
