import React from "react";
import Select from "react-dropdown-select";
import { Label } from "reactstrap";
import { useTranslation } from "react-i18next";

function CheckBoxDropDown({
  options,
  defaultOptions,
  name,
  onDropdownOpen,
  key1,
  setInitialData,
}) {
  const { t } = useTranslation();

  const onselectValues = (data) => {
    setInitialData((prev) => {
      return {
        ...prev,
        indicators: data,
      };
    });
  };
  // eslint-disable-next-line no-unused-vars
  const customItemRenderer = ({ item, methods }) => {
    return (
      <div
        onClick={() => {
          methods.addItem(item);
        }}
        role="button"
        tabIndex="0"
        onKeyUp={() => {}}
        className="drop-down-select"
      >
        {item.label}
        <input type="checkbox" checked={methods.isSelected(item)} />{" "}
      </div>
    );
  };

  const customContentRenderer = ({ props, state }) => {
    const names = state?.values.map((obj) => obj.label);
    const commaSeparatedNames =
      names.length > 0
        ? ` ${names.length} ${t("DASHBOARD.SELECTED")}`
        : props?.placeholder;

    return state.loading ? (
      <div>Loading...</div>
    ) : (
      <div>{commaSeparatedNames}</div>
    );
  };
  return (
    <div className="check-box-drop-down-div">
      <Label className="label">{name}</Label>
      <Select
        classNamePrefix="react-select"
        placeholder={name}
        key={key1}
        multi
        values={defaultOptions}
        name={name}
        onChange={onselectValues}
        contentRenderer={customContentRenderer}
        itemRenderer={customItemRenderer}
        options={options}
        onDropdownOpen={onDropdownOpen}
        className="map-dash-select dash-select common-select-r filter-control-dp-md check-box-drop-down"
      />
    </div>
  );
}

export default CheckBoxDropDown;
