import React from "react";
import Sidebar from "./Sidebar";

function DashboardCommon({ open, setOpen, toggleModal }) {
  window.scrollTo(0, 0);

  return (
    <div className="sidebar-common">
      <Sidebar setOpen={setOpen} open={open} toggleModal={toggleModal} />
    </div>
  );
}
export default DashboardCommon;
