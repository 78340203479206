import { takeEvery, fork, all, call, put } from "redux-saga/effects";

// Login Redux States
import MANAGE_USER_CONSTS from "./actionTypes";
import {
  getAllUsersSuccess,
  getAllUsersFailure,
  getAllUsersGenderSuccess,
  getAllUsersGenderFailure,
  getAllUsersAgeSuccess,
  getAllUsersAgeFailure,
} from "./action";
import checkHttpStatus from "../apiUtils";
import { USER_API, AXIOS_INSTANCE } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* ManageUserList(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  const data = action.payload;
  const filterString = `email=${data.email}&is_active=${data.is_active}&is_verified=${data.is_active}&country_id=${data.country_id}&organization_id=${data.organization_id}`;
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${USER_API}/getAllByCriteria?page=${data.page}&limit=${data.limit}&name=${data.name}&${filterString}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getAllUsersSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(getAllUsersFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllUsersFailure(errorData));
  }
}

function* UsersGenderList() {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${USER_API}/gender_list`),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getAllUsersGenderSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(getAllUsersGenderFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllUsersGenderFailure(errorData));
  }
}

//  age saga
function* UsersAgeList() {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${USER_API}/age_group`),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getAllUsersAgeSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(getAllUsersAgeFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllUsersAgeFailure(errorData));
  }
}

export function* watchManageUserList() {
  yield takeEvery(MANAGE_USER_CONSTS.GET_All_USER_REQUEST, ManageUserList); // AssignPermissions
}

export function* watchUsersGenderList() {
  yield takeEvery(
    MANAGE_USER_CONSTS.GET_All_USER_GENDER_REQUEST,
    UsersGenderList,
  );
}

export function* watchUsersAgeList() {
  yield takeEvery(MANAGE_USER_CONSTS.GET_All_USER_AGE_REQUEST, UsersAgeList);
}

function* ManageUserSaga() {
  yield all([
    fork(watchManageUserList),
    fork(watchUsersGenderList),
    fork(watchUsersAgeList),
  ]);
}

export default ManageUserSaga;
