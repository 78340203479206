import { ImFlickr } from "react-icons/im";
import moment from "moment";
import { BsFacebook, BsTwitter } from "react-icons/bs";
import { FaLinkedinIn, FaEnvelope, FaYoutube } from "react-icons/fa";
import { MdOutlineRssFeed } from "react-icons/md";
import i18n from "../i18n";
import Kenya from "../assest/img/Kenya.png";
import Senegal from "../assest/img/Senegal.png";
import Zambai from "../assest/img/Zambia.png";
import { ReactComponent as ClearSky } from "../assest/img/Clear-sky.svg";
import { ReactComponent as Clouds } from "../assest/img/Clouds.svg";
import { ReactComponent as Rain } from "../assest/img/Rain.svg";
import DocumentIcon from "../assest/img/DcoumentIcon.png";
import PdfIcon from "../assest/img/PdfIcon.png";
import ExcelIcon from "../assest/img/XLS.png";
import ImageIcon from "../assest/img/ImageIcon.png";
import VideoIcon from "../assest/img/VedioIcon.png";
import LinkIcon from "../assest/img/linkicon.png";
import CYPStage0 from "../assest/img/CYPStage0.png";
import CYPStage1 from "../assest/img/CYPStage1.png";
import CYPStage2 from "../assest/img/CYPStage2.png";
import CYPStage3 from "../assest/img/CYPStage3.png";
import DroughtIcon from "../assest/img/drought-rounded.svg";
import FloodIcon from "../assest/img/flood-rounded.svg";
import CycloneIcon from "../assest/img/cyclone-rounded.svg";
import LandslideIcon from "../assest/img/landslide-rounded.svg";

export const status200 = 200;
export const status300 = 300;
export const status400 = 400;
export const status401 = 401;
export const status600 = 600;

export const CropStages = [
  {
    slug: "plant",
    img: CYPStage1,
    name: "Plant",
  },
  {
    slug: "mid_season",
    img: CYPStage2,
    name: "Mid Season",
  },
  {
    slug: "harvest",
    img: CYPStage3,
    name: "Harvest",
  },
  {
    slug: "Not in any phase",
    img: CYPStage0,
    name: "Harvested",
  },
];

// regex
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const NO_SPACE_REGEX = /^\S*$/; // ^[1-9]\d*$

export const INTEGER_REGEX = [0 - 9];

// .env file varibles
export const defaultLanguage = "en";
export const developmentURL = `http://15.206.41.40:8000`;

export const getUserData = () => {
  const userDetails = localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo"))
    : null;
  return { userDetails };
};

export const googleRecaptchaSitekey =
  "6LcIoakjAAAAADDZNw6FBTzFZeMYjbqlOIYd0ol9";
export const googleRecaptchaSecretKey =
  "6LcIoakjAAAAABnzSdQdWU9xsw7CpfF1vSLnA31R";

export const sliderArray = [
  { image: Kenya },
  { image: Senegal },
  { image: Zambai },
];

export const socialItems = [
  {
    image: <BsFacebook />,
    link: "https://www.facebook.com/IWMIonFB",
  },
  {
    image: <ImFlickr />,
    link: "https://www.flickr.com/photos/iwmi",
  },
  {
    image: <FaLinkedinIn />,
    link: "https://www.linkedin.com/company/international-water-management-institute-iwmi/",
  },
  {
    image: <FaEnvelope />,
    link: `https://login.microsoftonline.com/common/oauth2/authorize
    ?client_id=00000002-0000-0ff1-ce00-000000000000&redirect_uri=https%3a%2f%2foutlook.office.com%2fowa%2f&resource=00000002-0000-0ff1-ce00-000000000000&response_mode=form_post&response_
    type=code+id_token&scope=openid&msafed=1&msaredir=1&client-request-id=5d51d2a5-b360-9330-4477-b1ade6276c19&protectedtoken=true&claims=%7b%22id_token%22%3a%7b%22xms_cc%22%3a%7b%22values
    %22%3a%5b%22CP1%22%5d%7d%7d%7d&nonce=638037488639983812.fc34949e-f6db-40ab-adbe-471c43bc78c9&state=TYzNboJAFIXRPgvu0GFmBGZhumiipBGSohZlN39acEYMjFJ8BJ-6U9smXZyb75x7zx04jvNkNbQaADucMEARQCGO
    ogAREqHIh-M9R5hgIr19IJiHAWUeFUx6OPQ5RoyHEScD270PJ3VHJ8-alsrUl6ac_ZCLaNnp0sWAH0rajOvm4KJ9e2GV5MZFwoVTCP-sZWDk5wPgqDXUyJk_aqQoG7te1zMaZ4DHSbDsyVVss5ZB0iw10YVWVbGaVgyCK8vnZ_ZCjkX-3rGFutCe2Nzv
    GErV8kbMLletWBCf6yN53MbZ9XWdftCtMHS1AckiPdF83tu_fdFvbsUp9Xf6rHboTSXQqtr0yf_s28eZkvEvH74A`,
  },
  {
    image: <MdOutlineRssFeed />,
    link: "https://www.iwmi.cgiar.org/news/rss-feeds/",
  },
  {
    image: <BsTwitter />,
    link: "https://twitter.com/IWMI_",
  },
  {
    image: <FaYoutube />,
    link: "https://www.youtube.com/user/iwmimedia/videos",
  },
];

export const LanguagesArray = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "French",
    value: "fr",
  },
];

export const climberTools = [
  {
    id: "8132d221-ec22-430f-8196-33e298b595cf",
    path: "/home",
  },
  {
    id: "60e578dd-519b-41f0-9189-7f8bb91b37a9",
  },
];

export const climber = "climber";
export const aware = "aware";
export const countryId = "8b8333fc-2d92-4e75-a646-2a29b8a80578";
export const orderBy = "ASC";
export const generalTools = "general_tools";
export const otherTools = "other_tools";
export const GENERAL_TOOLS = "general_tools";
export const riverDischarge = "river_discharge";
export const riverDischargeMean = "river_discharge_mean";
export const riverDischargeMedian = "river_discharge_median";
export const riverDischargeMax = "river_discharge_max";
export const riverDischargeMin = "river_discharge_min";
export const riverDischargeP25 = "river_discharge_p25";
export const riverDischargeP75 = "river_discharge_p75";

export const Authentication = [
  "/register",
  "/login",
  "/forgot-password",
  "/registerPage",
  "/terms",
  "/reset-password",
];
export const Temporal = [
  {
    id: 1,
    label: "Mean",
    value: "mean",
  },
  {
    id: 2,
    label: "Median",
    value: "median",
  },
  {
    id: 3,
    label: "Minimum",
    value: "min",
  },
  {
    id: 4,
    label: "Maximum",
    value: "max",
  },
];
export const TemporalAggregation = [
  {
    id: 1,
    label: "Sum",
    value: "sum",
  },
  {
    id: 2,
    label: "Mean",
    value: "mean",
  },
  {
    id: 3,
    label: "Minimum",
    value: "min",
  },
  {
    id: 4,
    label: "Maximum",
    value: "max",
  },
];
export const Months = [
  { value: "01", label: "January", id: 1 },
  { value: "02", label: "February", id: 2 },
  { value: "03", label: "March", id: 3 },
  { value: "04", label: "April", id: 4 },
  { value: "05", label: "May", id: 5 },
  { value: "06", label: "June", id: 6 },
  { value: "07", label: "July", id: 7 },
  { value: "08", label: "August", id: 8 },
  { value: "09", label: "September", id: 9 },
  { value: "10", label: "October", id: 10 },
  { value: "11", label: "November", id: 11 },
  { value: "12", label: "December", id: 12 },
];

export const healthMalariaPredectionList = [
  { value: "", label: "Select" },
  { value: "01", label: "Next 1 Month" },
  { value: "03", label: "Next 3 Months" },
  { value: "06", label: "Next 6 Months" },
];

export const TypeOptions = [
  { name: "Day", value: "day", selected: true },
  { name: "Night", value: "night", selected: false },
];

export const TemperatureAnomaly = "temperature_anomaly";
export const RainfallDeficit = "rainfall_deficit";
export const DrysPell = "dryspell";
export const SarRgb = "sar_rgb";
export const Precipitation = "Precipitation";
const OPEN_WEATHER_API_KEY = "42170930ba0a32525707af376632a32f";
export const Line = "line";
export const Column = "column";
export const Blue = "blue";
export const GRAPH_YAXIS_COLORS = [
  "#29619F",
  "#F4A85E",
  "#0FA094",
  "#D40000",
  "#1A9850",
];
export const NOAA_PARAMETER_SLUG = [
  {
    name: "Precipitation",
    slug: "precipitation",
    value: "Precipitation",
    api_slug: "total_precipitation_surface",
  },
  {
    name: "Temperature",
    value: "Temperature",
    slug: "temperature",
    api_slug: "temperature_2m_above_ground",
  },
  {
    name: "Wind_speed",
    value: "Wind speed",
    slug: "wind_speed",
    api_slug: "u_component_of_wind_10m_above_ground",
  },
  {
    name: "Humidity",
    value: "Humidity",
    slug: "humidity",
    api_slug: "relative_humidity_2m_above_ground",
  },
];

export const OPEN_WEATHER_PARAMETER_SLUG = () => {
  const { t } = i18n;
  const precipitationValue = [
    {
      name: `${t("WEATHER_FORCAST.PRECIPITATION")}`, // "Precipitation"
      value: `${t("WEATHER_FORCAST.PRECIPITATION")}`,
      api_slug: `rain`,
      slug: `precipitation`,
      parameter_slug: `${t("WEATHER_FORCAST.PARAMETER_SLUG_PRECIPITATION")}`,
      constant: "rain",
    },
    {
      name: `${t("WEATHER_FORCAST.TEMPERATURE")}`,
      value: `${t("WEATHER_FORCAST.TEMPERATURE")}`,
      api_slug: `${t("WEATHER_FORCAST.TEMPERATURE_OPEN-WEATHEER")}`,
      slug: `${t("WEATHER_FORCAST.TEMPERATURE_SLUG")}`,
      parameter_slug: `${t("WEATHER_FORCAST.PARAMETER_SLUG_TEMPERATURE")}`,
      constant: `${t("WEATHER_FORCAST.TEMPERATURE_OPEN-WEATHEER")}`,
    },
    {
      name: `${t("WEATHER_FORCAST.WIND-SPEED")}`,
      value: `${t("WEATHER_FORCAST.WIND_SPEED")}`,
      api_slug: `${t("WEATHER_FORCAST.WIND-SPEED")}`,
      slug: `${t("WEATHER_FORCAST.WIND-SPEED")}`,
      parameter_slug: `${t("WEATHER_FORCAST.PARAMETER_SLUG_WIND")}`,
      constant: `${t("WEATHER_FORCAST.PARAMETER_CONTANT_WIND")}`,
    },
    {
      name: `${t("WEATHER_FORCAST.CLOUD")}`,
      value: `${t("WEATHER_FORCAST.CLOUD")}`,
      api_slug: `${t("WEATHER_FORCAST.CLOUDS_SLUG")}`, // CLOUDS_SLUG
      slug: `${t("WEATHER_FORCAST.CLOUD_SLUG")}`,
      parameter_slug: `${t("WEATHER_FORCAST.PARAMETER_SLUG_CLOUD")}`,
      constant: `${t("WEATHER_FORCAST.CLOUDS_SLUG")}`,
    },
    {
      name: `${t("WEATHER_FORCAST.PRESSURE")}`,
      value: `${t("WEATHER_FORCAST.PRESSURE")}`,
      api_slug: `${t("WEATHER_FORCAST.PRESSURE_SLUG")}`,
      slug: `${t("WEATHER_FORCAST.PRESSURE_SLUG")}`,
      parameter_slug: `${t("WEATHER_FORCAST.PARAMETER_SLUG_PRESSURE")}`,
      constant: `${t("WEATHER_FORCAST.PRESSURE_SLUG")}`,
    },
  ];
  return precipitationValue;
};

// project stepper function
export const PROJECTS_STEPPER = () => {
  const { t } = i18n;
  const precipitationValue = [
    { lable: t("EARLY_ACTIONS.PREPAREDNESS_STEPPER"), index: 1 },
    { lable: t("EARLY_ACTIONS.READINESS_STEPPER"), index: 2 },
    { lable: t("EARLY_ACTIONS.ACTIVE_STEPPER"), index: 3 },
  ];
  return precipitationValue;
};

export const DISPLAY_DATE_FORMAT = "DD-MM-YYYY";
const apiKey =
  "pk.eyJ1IjoicHJhczEyMzQiLCJhIjoiY2xhdjA0N2x2MDExdDNyc2o4M2s3cDV0ZyJ9.uHjrCHup5y40yJ9MCIPhvg";
export const myConst = {
  TITLE_LAYER_URL: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}?access_token=${apiKey}`,
  SATELLITE_TILE_LAYER_URL: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/256/{z}/{x}/{y}?access_token=${apiKey}`,
  TITLE_LAYER_ATTRIBUTE:
    'Weather from <a href="https://openweathermap.org/" alt="World Map and worldwide Weather Forecast online">OpenWeatherMap</a>',
  SATELLITE_TILE_LAYER_ATTRIBUTE: `© <a href='https://www.mapbox.com/about/maps/' target='_blank'>Mapbox</a>
    © <a href='http://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a>
     <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong> ©<a href="https://www.maxar.com/" target='_blank'> Maxar</a>`,
  OPEN_WEATHER_TILE_LAYER_URL: `https://{s}.tile.openweathermap.org/map/{layername}/{z}/{x}/{y}.png?appid=${OPEN_WEATHER_API_KEY}`,
};
// export const mapPrefrencesLabels = {
export const MAP_PREFERENCES_LABELS = {
  COUNTRY: "country",
  STATES: "states_or_provinces",
  DISTRICTS: "districts_or_cities",
  TALUKAS: "talukas",
  NPSC_LULC: "npsc_lulc",
  JRC: "jrc",
  MOD44: "mod44",
  BASINS: "basins",
  SUB_BASIN: "sub_basins",
  RIVERS: "rivers_or_reservoirs",
  METEOROLOGICAL_LOCATIONS: "meteorological_locations",
  PROBA_LULC: "proba_lulc",
  SRILANKA_LULC: "sri_lanka_lulc",
  OTHERS: "Others",
  AWS: "AWS",
  DISCHARGE_STATION: "discharge_station",
  HEALTH_CENTER: "health_center",
  MARKET_CENTER: "market_center",
  FOOD_SHELTER: "food_shelter",
  MAJOR_RIVERS: "major_rivers",
  MINOR_RIVERS: "minor_rivers",
  HYDRO_STATIONS: "hydro_stations",
};

export const PARAMETER_SLUGS = {
  PRECIPITATION: "efi_precipitation_erf",
  TEMPERATURE: "efi_temperature_erf",
  TCA: "total_accumulated_precipitation",
  LEFT_AREA_INDEX: "leaf_area_index",
  SOIL_MOISTURE: "soil_moisture",
  EFI_WIND_GUEST: "efi_wind_gust",
  EFI_WIND_SPEED: "efi_wind_speed",
  EFI_PRECEIPITATION: "efi_precipitation",
  PRECEIPITATION_SEAS5: "precipitation_ses_5",
};

export const SHOW_VALIDTIME_AND_LAYERS = [
  PARAMETER_SLUGS.PRECIPITATION,
  PARAMETER_SLUGS.TEMPERATURE,
  PARAMETER_SLUGS.TCA,
  PARAMETER_SLUGS.LEFT_AREA_INDEX,
  PARAMETER_SLUGS.SOIL_MOISTURE,
  PARAMETER_SLUGS.PRECEIPITATION_SEAS5,
];

export const SHOW_DAY_AND_QUANTILE = [
  PARAMETER_SLUGS.EFI_WIND_GUEST,
  PARAMETER_SLUGS.EFI_WIND_SPEED,
  PARAMETER_SLUGS.EFI_PRECEIPITATION,
];

export const sectorialClimateId = "6a8745c6-706a-430d-91d3-dc4c8b16f151";

export const CHART_LABEL_COLORS = [
  "#435A52",
  "#363636",
  "#00e396",
  "#feb019",
  "#ff4560",
  "#775dd0",
];
export const chartColorLabel = [
  "#008ffb",
  "#00e396",
  "#feb019",
  "#ff4560",
  "#775dd0",
  "#b43531",
  "#f0d195",
  "#363636",
  "#ff4560",
  "#775dd0",
  "#008ffb",
  "#00e396",
  "#feb019",
  "#ff4560",
  "#775dd0",
];
export const DownloadPNG = "Download PNG";
export const Close = "Close";
export const TimeFramesDateFormat = "MMM, YY";

export const POLYGON = "Polygon";
export const DISPLAY_CHART_DATE = "DD/MM/YYYY";
export const dateFormat = "dd/MM/yyyy";
export const DisplyChartDateFormat = "D MMM YYYY";
/* eslint max-len: ["error", { "code": 1000 }] */
export const Floodesa = "Flood (ESA)";
export const Floodnasa = "Flood (NASA)";
export const PlotlyDowloadlogo = {
  svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_7214_19748)"><path d="M4 16V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V16" stroke="#435a52" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7 10L12 15L17 10" stroke="#435a52" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 3V15" stroke="#435a52" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_7214_19748"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>',
};
export const PlotlyCloselogo = {
  name: "newplotlyCloselogo",
  svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 11.7051C21 6.73633 16.9688 2.70508 12 2.70508C7.03125 2.70508 3 6.73633 3 11.7051C3 16.6738 7.03125 20.7051 12 20.7051C16.9688 20.7051 21 16.6738 21 11.7051Z" stroke="#435a52" stroke-width="1.875" stroke-miterlimit="10"/><path d="M15 14.7051L9 8.70508M9 14.7051L15 8.70508" stroke="#435a52" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/></svg>',
};

export const adminMapPreferenceLink = `https://www.ifpri.org/sites/default/files/guidelines_countries-territories-maps.pdf`;

export const mapPrefreenceLabel = {
  COUNTRY: "country",
  STATES: "regions",
  DISTRICTS: "districts_or_cities",
  TALUKAS: "talukas",
  NPSC_LULC: "npsc_lulc",
  JRC: "jrc",
  MOD44: "mod44",
  BASINS: "basins",
  SUB_BASIN: "sub_basins",
  RIVERS: "rivers_or_reservoirs",
  METEOROLOGICAL_LOCATIONS: "meteorological_locations",
  DischargeStation: "discharge_station",
  MAJOR_RIVERS: "major_rivers",
  MINOR_RIVERS: "minor_rivers",
  HYDRO_STATIONS: "hydro_stations",
};

export const DRAW_SHAPE = {
  MAXIMUM_SHAPE: 2,
  MAXIMUM_DRAW_SHAPE: 3,
  MAXIMUM_MARKERS: 1,
};

export const GEOJSON_COUNTRY_DATA = [
  {
    selectedCountry: "Zambia",
    country: "Country",
    states_or_provinces: "Province",
    districts_or_cities: "District",
    talukas: "sdtname",
    meteorological_locations: "",
    basins: "Name_2",
    sub_basins: "NAME",
    rivers_or_reservoirs: "",
    discharge_station: "National_I",
    glofas: {
      river: mapPrefreenceLabel?.RIVERS,
      station: mapPrefreenceLabel.DischargeStation,
    },
  },
  {
    selectedCountry: "Kenya",
    country: "NAME_0",
    states_or_provinces: "NAME_1",
    districts_or_cities: "NAME_2",
    talukas: "NAME_3",
    meteorological_locations: "",
    basins: "Basin_Name",
    sub_basins: "NAME",
    rivers_or_reservoirs: "",
    discharge_station: "",
    glofas: {
      river: mapPrefreenceLabel?.RIVERS,
      station: mapPrefreenceLabel.DischargeStation,
    },
  },
  {
    selectedCountry: "Morocco",
    country: "NAME_0",
    states_or_provinces: "NAME_1",
    districts_or_cities: "NAME_2",
    talukas: "NAME_3",
    meteorological_locations: "",
    basins: "MAJ_NAME",
    sub_basins: "NAME",
    rivers_or_reservoirs: "",
    discharge_station: "",
    glofas: {
      river: mapPrefreenceLabel?.RIVERS,
      station: mapPrefreenceLabel.DischargeStation,
    },
  },
  {
    selectedCountry: "Guatemala",
    country: "NAME_0",
    states_or_provinces: "NAME_1",
    districts_or_cities: "NAME_2",
    talukas: "NAME_3",
    meteorological_locations: "",
    basins: "MAJ_NAME",
    sub_basins: "NAME",
    rivers_or_reservoirs: "",
    discharge_station: "",
    glofas: {
      river: mapPrefreenceLabel?.RIVERS,
      station: mapPrefreenceLabel.DischargeStation,
    },
  },
  {
    selectedCountry: "Senegal",
    country: "NAME_0",
    states_or_provinces: "NAME_1",
    districts_or_cities: "NAME_2",
    talukas: "NAME_3",
    meteorological_locations: "",
    basins: "WMOBB_BASI",
    sub_basins: "NAME",
    rivers_or_reservoirs: "",
    discharge_station: "",
    glofas: {
      river: mapPrefreenceLabel?.RIVERS,
      station: mapPrefreenceLabel.DischargeStation,
    },
  },
  {
    selectedCountry: "Philippines",
    country: "NAME_0",
    states_or_provinces: "NAME_1",
    districts_or_cities: "NAME_2",
    talukas: "NAME_3",
    sub_basins: "NAME",
    meteorological_locations: "",
    basins: "MAJ_NAME",
    rivers_or_reservoirs: "",
    discharge_station: "",
    glofas: {
      river: mapPrefreenceLabel?.RIVERS,
      station: mapPrefreenceLabel.DischargeStation,
    },
  },
  {
    selectedCountry: "Sri Lanka",
    country: "ADM0_EN",
    states_or_provinces: "ADM1_EN",
    districts_or_cities: "ADM2_EN",
    talukas: "NAME_3",
    meteorological_locations: "",
    basins: "basin_name",
    sub_basins: "NAME",
    rivers_or_reservoirs: "",
    discharge_station: "",
    glofas: {
      river: mapPrefreenceLabel?.RIVERS,
      station: mapPrefreenceLabel.HYDRO_STATIONS,
    },
  },
  {
    selectedCountry: "Rwanda",
    country: "ADM0_EN",
    states_or_provinces: "ADM1_EN",
    districts_or_cities: "ADM2_EN",
    talukas: "NAME_3",
    meteorological_locations: "",
    basins: "basin_name",
    sub_basins: "NAME",
    rivers_or_reservoirs: "",
    discharge_station: "",
    glofas: {
      river: mapPrefreenceLabel?.RIVERS,
      station: mapPrefreenceLabel.HYDRO_STATIONS,
    },
  },
  {
    selectedCountry: "Nigeria",
    country: "ADM0_EN",
    states_or_provinces: "ADM1_EN",
    districts_or_cities: "ADM2_EN",
    talukas: "NAME_3",
    meteorological_locations: "",
    basins: "basin_name",
    sub_basins: "NAME",
    rivers_or_reservoirs: "",
    discharge_station: "",
    glofas: {
      river: mapPrefreenceLabel?.RIVERS,
      station: mapPrefreenceLabel.HYDRO_STATIONS,
    },
  },
];
export const Region = [
  {
    label: "All Regions",
    value: "Regions",
  },
  {
    label: "Dakar",
    value: "Dakar",
  },
  {
    label: "Kaffrine",
    value: "Kaffrine",
  },
  {
    label: "Kaolack",
    value: "Kaolack ",
  },
  {
    label: "Fatick ",
    value: "Fatick ",
  },
  {
    label: "Kédougou",
    value: "Kédougou ",
  },
  {
    label: "Dakar",
    value: "Dakar",
  },
  {
    label: "Kaffrine",
    value: "Kaffrine",
  },
  {
    label: "Kaolack",
    value: "Kaolack ",
  },
  {
    label: "Fatick ",
    value: "Fatick ",
  },
  {
    label: "Kédougou",
    value: "Kédougou ",
  },
];
export const Districts = [
  {
    label: "All Districts",
    value: "Regions",
  },
  {
    label: "Dakar",
    value: "Dakar",
  },
  {
    label: "Kaffrine",
    value: "Kaffrine",
  },
  {
    label: "Kaolack",
    value: "Kaolack ",
  },
  {
    label: "Fatick ",
    value: "Fatick ",
  },
  {
    label: "Kédougou",
    value: "Kédougou ",
  },
  {
    label: "Dakar",
    value: "Dakar",
  },
  {
    label: "Kaffrine",
    value: "Kaffrine",
  },
  {
    label: "Kaolack",
    value: "Kaolack ",
  },
  {
    label: "Fatick ",
    value: "Fatick ",
  },
  {
    label: "Kédougou",
    value: "Kédougou ",
  },
];
export const GEOJSON_COUNTRY_TOOLTIP_DATA = [
  {
    selectedCountry: "Zambia",
    states_or_provinces: "ADM1_EN",
    districts_or_cities: "ADM2_EN",
    talukas: "ADM3_EN",
    meteorological_locations: "ST_Name",
    basins: "basin_name",
    rivers_or_reservoirs: "Name",
  },
];
export const precipitationExtremeRainfall = "124";
export const precipitationFloodesa = "-14";
export const otherSection = [
  {
    lable: "0-20",
    value: "0_20",
  },
  {
    lable: "20-50",
    value: "20_50",
  },
];

export const climaapat = () => {
  const { t } = i18n;
  return {
    CLIMAADAPT: t("CLIMBER_HOME_PAGE.CLIMA_ADAPT"),
    Climaapt_URL:
      "https://www.figma.com/proto/ZVn7UeIvNktZE8OW6CH0FD/ClimaAdapt-Gov?node-id=597%3A2119&scaling=min-zoom&page-id=102%3A235",
  };
};

export const uploaderDocumentCardId = "bece6118-5f12-49b3-afa7-dfc8ad9d69a7";

export const EarlyWarningApiKey = "95dc0b67-fb72-4448-8280-cc9f437dff24";
export const Flood = "flood";
export const Drought = "drought";
export const ClimateName = "climate_vegetation";
export const Climate = "ade34d1c-9018-4e2f-a64d-23f1253a487e";
export const mapPreferenceConstants = {
  soil: "Soil",
  gdp: "GDP",
  soilTextureTwenty: "0_20",
  soilTextureFifty: "20_50",
  soilSlug: "soil",
  gdpSlug: "gdp",
  soliTextureText: "Soil Texture",
  gdpText: "GDP",
  soilTextureTwentyValue: "0-20",
  soilTextureFiftyValue: "20-50",
};
export const ExtremeRainfall = "extreme_rainfall_gpm";
export const FloodEsa = "flood_esa";
export const DroughtApiKey = "e1b13f9e-a16f-4766-838e-736e21cd1685";
export const FloodApiKey = "d98f7034-0f57-4912-949f-8f47c61609c5";
export const State = "state";
export const AccumulatedRainfall = "accumulated_rainfall_gpm";
export const Geoglowes = "Geo Glows";
export const Geoglow = "geo_glows";
export const Glofas = "glofas";
export const Discharge = "Discharge";
export const FloodExtent = "Flood extent";
export const District = "district";
export const Fulfilled = "fulfilled";
export const Rejected = "rejected";
export const AccRainfallGpm = "accumulated_rainfall_gpm";
export const ExtremeRainfallGpm = "extreme_rainfall_gpm";
export const FloodNasa = "flood_nasa";
export const GeoGlows = "geo_glows";
export const Smci = "smci";
export const Idsi = "idsi";
export const MonthsArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const Colors = [
  "#04f7ca",
  "#3cc4ab",
  "#f2ea09",
  "#bcb727",
  "#e209f2",
  "#903a96",
];
export const ColorsGlofas = [
  "#ffd27f",
  "#006400",
  "#04f7ca",
  "#ff7f0e",
  "#3cc4ab",
  "#f2ea09",
  "#bcb727",
  "#e209f2",
  "#903a96",
];

export const adminEng = "Admin";
export const adminFrench = "Administratrice";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATA_SOURCES = {
  OPEN_WEATHER: {
    id: "7da693ed-588a-41a4-bf7f-c4b6405698a0",
    name: "Open Weather",
    dateRange: {
      endDate: moment().add(6, "days").format(DATE_FORMAT),
      minStartDate: moment().format(DATE_FORMAT),
      startDate: moment().format(DATE_FORMAT),
      maxStartDate: moment().add(6, "days").format(DATE_FORMAT),
      maxEndDate: moment().add(6, "days").format(DATE_FORMAT),
    },
  },
  GFS_NOAA: {
    id: "e2f886a7-3299-4c82-af2e-123fe92faf83",
    name: "GFS - NOAA",
    dateRange: {
      endDate: moment().add(15, "days").format(DATE_FORMAT),
      minStartDate: moment("2015-07-01T00:00:00Z").format(DATE_FORMAT),
      startDate: moment().format(DATE_FORMAT),
      maxStartDate: moment().add(15, "days").format(DATE_FORMAT),
      maxEndDate: moment().add(15, "days").format(DATE_FORMAT),
    },
  },
  IRI: {
    id: "e2f886a7-3299-4c82-af2e-123fe92faf84",
    name: "IRI",
    parameterName: "Probability (%) of Most Likely Category",
  },
};
export const DEFAULT_PARAMETERS_CONST = () => {
  const precipitationValue = {
    PRECIPITATION_SLUG: `precipitation`,
    EFI_PRECEIPITATION_SLUG: `efi_precipitation`,
    PRECIPITATION_SES_5_SLUG: "precipitation_ses_5",
  };
  return precipitationValue;
};
export const OPEN_WEATHER_PARAMETERS_CONST = [
  {
    id: "Temperature",
    name: "Temperature",
    layerName: "temp_new",
    measure: "°C",
    min: -40,
    max: +40,
    dataSource: "Open Weather",
    parameter: "Temperature",
    image: "temp-background",
    slug: "temperature",
  },
  {
    id: "Rainfall",
    name: "Rainfall",
    layerName: "rain_cls",
    measure: "mm",
    min: 0.1,
    max: 100,
    dataSource: "Open Weather",
    parameter: "Rainfall",
    image: "rain-background",
  },
  {
    id: "Precipitation",
    name: "Precipitation",
    layerName: "precipitation_cls",
    measure: "mm/h",
    min: 0,
    max: 60,
    slug: "precipitation",
    palette: [
      "#0000BB",
      "#0000F7",
      "#004FFE",
      "#00ADFE",
      "#07FDF7",
      "#4FFDAF",
      "#9BFE63",
      "#E3FE1B",
      "#FED300",
      "#FE8B00",
      "#FF3F00",
      "#F70000",
      "#BB0000",
    ],
    dataSource: "Open Weather",
    parameter: "Precipitation",
    image: "",
  },
  {
    id: "Wind speed",
    name: "Wind speed",
    layerName: "wind_new",
    slug: "wind_speed",
    measure: "m/s",
    min: 0,
    max: 100,
    dataSource: "Open Weather",
    parameter: "Wind speed",
    image: "wind-background",
  },
  {
    id: "Cloud",
    name: "Cloud",
    layerName: "clouds_cls",
    measure: "%",
    min: 0,
    slug: "cloud",
    max: 100,
    dataSource: "Open Weather",
    parameter: "Cloud",
    image: "cloud-background",
  },
  {
    id: "Pressure",
    name: "Pressure",
    layerName: "pressure_new",
    measure: "hPa",
    min: 950,
    slug: "pressure",
    max: 1070,
    dataSource: "Open Weather",
    parameter: "Pressure",
    image: "pressure-background",
  },
];

export const FORECAST_TYPE_ORDER = [
  "Short Range",
  "Medium Range",
  "Long Range",
];

export const delayedIndicators = ["idsi", "rainfall_deficit"];
export const delayedFloodIndicators = ["flood_nasa"];
export const Sum = "sum";
export const Map = "map";
export const Graph = "graph";

export const integerRegex = /^-?\d*\.?\d*$/;

export const MeasurementID = "G-W33TLZCTMF";
export const ProductionSiteURL = "awaredemo.iwmi.org";
export const DateFormat = "DD-MM-YYYY";

export const MonthsList = [
  { value: 0, label: "Jan" },
  { value: 1, label: "Feb" },
  { value: 2, label: "Mar" },
  { value: 3, label: "Apr" },
  { value: 4, label: "May" },
  { value: 5, label: "Jun" },
  { value: 6, label: "Jul" },
  { value: 7, label: "Aug" },
  { value: 8, label: "Sep" },
  { value: 9, label: "Oct" },
  { value: 10, label: "Nov" },
  { value: 11, label: "Dec" },
];

export const HighChartConstants = {
  twenty: 20,
  thirtyFive: 35,
  three: 3,
  datalabelcolor: "rgb(0, 143, 251)",
  minusTen: -10,
  thirty: 30,
  white: "white",
  two: 2,
  fifteen: 15,
  sixtyFive: 65,
  fiftyFive: 55,
  fourtyFive: 45,
};

export const onlineBuliten = "9fb2ac87-0142-4184-83a8-f7febf897978";

export const Bi_Weekly_Bulletin = "8407d77b-ec23-48e8-8279-bc3ed5be3790";
export const Montly_bulletin = "90bff4b4-c8d0-4d7e-9ee0-85174597264c";

export const page_range = [10, 15, 20, 25];

export const LOCATION = {
  Region: "region",
  Country: "Country",
  State: "State",
  District: "District",
  Province: "Province",
  Divisions: "Divisions",
};

export const MAP_PREFERENCES_lABELS_OB = {
  COUNTRY: "country",
  STATES: "states_or_provinces",
  DISTRICTS: "districts_or_cities",
  TALUKAS: "talukas",
  NPSC_LULC: "npsc_lulc",
  JRC: "jrc",
  MOD44: "mod44",
  BASINS: "basins",
  RIVERS: "rivers_or_reservoirs",
  METEOROLOGICAL_LOCATIONS: "meteorological_locations",
};

export const BULLETIN_CODES = [
  "add_bulletin",
  "change_bulletin",
  "delete_bulletin",
  "approve_reject_bulletin",
  "publish_bulletin",
];

export const BULLETIN_TAB_CONSTS = {
  CREATED: "Created",
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
};

export const BI_WEEKLY_BULLETIN = "Bi-Weekly Bulletin";
export const MONTHLY_BULLETIN = "Monthly Bulletin";

export const FORECAST_LEAD_TIME_LIST = [
  { value: 1, label: "January", id: 1.0, name: "January" },
  { value: 2, label: "February", id: 2.0, name: "February" },
  { value: 3, label: "March", id: 3.0, name: "March" },
  { value: 4, label: "April", id: 4.0, name: "April" },
  { value: 5, label: "May", id: 5.0, name: "May" },
  { value: 6, label: "June", id: 6.0, name: "June" },
  { value: 7, label: "July", id: 7.0, name: "July" },
  { value: 8, label: "August", id: 8.0, name: "August" },
  { value: 9, label: "September", id: 9.0, name: "September" },
  { value: 10, label: "October", id: 10.0, name: "October" },
  { value: 11, label: "November", id: 11.0, name: "November" },
  { value: 12, label: "December", id: 12.0, name: "December" },
];

export const IRI_DATA = () => {
  const { t } = i18n;
  const precipitationValue = [
    {
      id: "e2f886a7-3299-4c82-af2e-123fe92faf84",
      label: `${t("MANAGE_IRI.STATIC_IRI_DATA_SOURCE")}`,
      name: `${t("MANAGE_IRI.STATIC_IRI_DATA_SOURCE")}`,
      type: "non-static",
      value: "e2f886a7-3299-4c82-af2e-123fe92faf84",
    },
    {
      label: `${t("MANAGE_IRI.STATIC_IRI_PARAMETER")}`,
      slug: `${t("MANAGE_IRI.STATIC_IRI_PARAMETER_SLUG")}`,
      value: "e2f886a7-3299-4c82-af2e-123fe92fab02",
    },
  ];
  return precipitationValue;
};
export const DISCLAIMER_CGIAR_LINK = "https://www.cgiar.org/funders";

export const maxColor = "#eb2b1e"; // Hex color code only
export const minColor = "#ffe710"; // Hex color code only

export const ONLINE_BULLETIN_SLUG = "onlne_bulletin";
export const USER_GUIDE_SLUG = "user_guide";
export const EMPTY_DATA = "emptyData";
export const DATA_OVERRIDE = "dataOverride";
export const DATE_RANGE_ERROR = "DatesRangeError";
export const UN_MATCHED_FIELD = "unMatchedField";
export const COMMING_SOON = "coming_soon";
export const WRONG_STRUCTURE = "wrongStructure";
export const EARLY_ACTION_SLUG = "early_action";
export const EARLY_FINANCE_SLUG = "early_finance";

export const BO_OPTIONS = [
  {
    title: "Health",
    slug: "health",
    sub_menu_list: [
      {
        id: "780b037e-d9cb-4a98-ac77-811a50dd420e",
        title: "Malaria",
        slug: "malaria",
      },
      {
        id: "b13bc93d-fdc1-4676-80cd-528fb69accd5",
        title: "Diarrhea",
        slug: "diarrhea",
      },
    ],
  },
  {
    title: "Market",
    slug: "market",
    sub_menu_list: [
      {
        id: "38a2458c-8366-44c0-898f-10ac9c591f03",
        title: "Food Prices",
        slug: "food_prices",
      },
    ],
  },
  {
    title: "Nutrition",
    slug: "nutrition",
    sub_menu_list: [],
  },
  {
    title: "Population Displacement",
    slug: "population_displacement",
    sub_menu_list: [],
  },
  {
    title: "Crop Yield Assessment",
    slug: "crop_yield_prediction",
    sub_menu_list: [],
  },
];

// google authorization
export const SCOPE = "https://www.googleapis.com/auth/drive";
export const discoveryUrl = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
];
export const deleteDiscoveryUrl = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v2/rest",
];

// local
export const API_KEY = "AIzaSyCwL-u_jRZKLlZ3I4Dn9BrIT7NfQeifFMo";
export const CLIENT_ID =
  "713327898387-tpm2bo48o6lgq9vf3rgtdkdge1kedl6o.apps.googleusercontent.com";

export const SHEETS_SCOPE = "https://www.googleapis.com/auth/drive.file";
export const SHEETS_DISCOVERY_URL = [
  "https://www.googleapis.com/discovery/v1/apis/sheets/v4/rest",
];

export const teamsBgColor = [
  "#68b9d1",
  "#d29433",
  "#d87042",
  "#22ad7a",
  "#4395a4",
];

export const cropYieldMonthsList = [
  {
    value: 1,
    label: "Jan",
  },
  {
    value: 2,
    label: "Feb",
  },
  {
    value: 3,
    label: "Mar",
  },
  {
    value: 4,
    label: "Apr",
  },
  {
    value: 5,
    label: "May",
  },
  {
    value: 6,
    label: "Jun",
  },
  {
    value: 7,
    label: "Jul",
  },
  {
    value: 8,
    label: "Aug",
  },
  {
    value: 9,
    label: "Sept",
  },
  {
    value: 10,
    label: "Oct",
  },
  {
    value: 11,
    label: "Nov",
  },
  {
    value: 12,
    label: "Dec",
  },
];

export const Country = "country";
export const REACT_APP_GG_APP_ID =
  "172121233933-nk60ctqd8eo4q68ifph7jk0ka3t6pcs1.apps.googleusercontent.com";
export const REACT_APP_FB_APP_ID = "820548645984409";
export const REACT_APP_MICROSOFT_APP_ID =
  "b02e05f0-d5f7-4be9-9a9b-08550c93cdaa";
export const REACT_APP_LINKEDIN_APP_SECRET = "gdymsY6afkD2J4H3";
export const REACT_APP_LINKEDIN_APP_ID = "86duaganbhjj9u";

export const PREPAREDNESS_PHASE_SLUGS = [
  {
    id: 0,
    slug: "ea_pp_category",
  },
  {
    id: 1,
    slug: "ea_pp_impact",
  },
  {
    id: 2,
    slug: "ea_pp_possible_aa",
  },
  {
    id: 3,
    slug: "ea_pp_aa_plan",
  },
];

export const MAX_ROWS = 20;
export const ADD_PROJECT = "add_project";
export const EDIT_PROJECT = "change_project";
export const DELETE_PROJECT = "delete_project";
export const CHANGE_GUIDE_LINES = "change_guideline";
export const EDIT_INTERVENTION = "change_intervention";
export const DELETE_INTERVENTION = "delete_intervention";
export const ADD_INTERVENTION = "add_intervention";
export const ADD_INSTANCE = "add_instance";
export const EDIT_INSTANCE = "change_instance";
export const DELETE_INSTANCE = "delete_instance";
export const CHANGE_BUDGET = "change_budget";
export const GENERATE_REPORT = "add_generatereport";
export const CAN_aCTIVATE_PLAN = "can_activate_plan";

export const DATE_TIME_FORMAT = "DD MMM, YYYY | hh.mmA";

export const EDIT_PROJECT_CODE_NAME = "change_project";
export const DELETE_PROJECT_CODE_NAME = "delete_project";
export const MONOTORING_INDICATORS = "Monitoring Indicators";
export const FORECAST_INDICATORS = "Forecast Indicators";
export const CLOUD = "Cloud";

export const IDSI = "idsi";

export const DELETE_PROJECT_ENABLE_BY_DEFAULT = [
  "delete_instance",
  "delete_intervention",
];

export const EDIT_PROJECT_ENABLE_BY_DEFAULT = [
  "change_instance",
  "change_intervention",
];

export const ColumnsData = [
  {
    header: "No.",
    key: "id",
    width: 5,
    array: false,
  },
  {
    header: "Activities",
    key: "Activities",
    width: 75,
    array: true,
  },
  {
    header: "Timeframe",
    key: "Timeframe",
    width: 20,
    array: true,
  },
  {
    header: "Target",
    key: "Target",
    width: 20,
    array: true,
  },
];

export const ColumnsDataFinance = [
  {
    header: "No.",
    key: "id",
    width: 5,
    array: false,
  },
  {
    header: "Activities",
    key: "Activities",
    width: 75,
    array: true,
  },
  {
    header: "Timeframe",
    key: "Timeframe",
    width: 20,
    array: true,
  },
  {
    header: "Target",
    key: "Target",
    width: 20,
    array: true,
  },
  {
    header: "Budget",
    key: "Budget",
    width: 20,
    array: true,
  },
];

export const SENTINAL_POLORIZATION = [
  {
    value: "VV",
    label: "VV",
  },
  {
    value: "VH",
    label: "VH",
  },
  {
    value: "Both",
    label: "Both",
  },
];

export const SENTINAL_ORBIT_POLORIZATION = [
  {
    value: "Ascending",
    label: "Ascending",
  },
  {
    value: "Descending",
    label: "Descending",
  },
  {
    value: "Both",
    label: "Both",
  },
];

export const dropzoneAccept = {
  "image/png": [],
};

export const dropzonePfd = {
  "application/pdf": [".pdf"], // Accept PDF files
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ], // Accept DOCX files
  "application/msword": [".doc"], // Accept DOC files
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ], // Accept XLSX (Excel) files
  "application/vnd.ms-excel": [".xls"], // Accept XLS (legacy Excel) files
  "video/*": [],
  "image/*": [],
};

export const dropzoneDocumentUpload = {
  "application/pdf": [".pdf"], // Accept PDF files
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ], // Accept DOCX files
  "application/msword": [".doc"], // Accept DOC files
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ], // Accept XLSX (Excel) files
  "application/vnd.ms-excel": [".xls"], // Accept XLS (legacy Excel) files
  "text/csv": [".csv"], // Accept CSV files
  "video/*": [],
  "image/*": [],
};

export const feedbackUploadList = {
  "application/pdf": [".pdf"], // Accept PDF files
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ], // Accept DOCX files
  "application/vnd.ms-excel": [".xls"], // Accept XLS (legacy Excel) files
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ], // Accept XLSX (Excel) files
  "text/csv": [".csv"], // Accept CSV files
  "image/jpeg": [".jpg", ".jpeg"], // Accept JPG files
  "image/png": [".png"], // Accept PNG files
  "video/*": [],
};

export const imageExtensions = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "tiff",
  "tif",
  "psd",
  "svg",
  "ai",
  "eps",
];

export const videoExtensions = ["mp4", "mov", "avi", "mkv", "wmv", "flv"];
export const FooterLogoHidenRoutes = ["/climber", "/othertools"];

// ECMWF STATIC KEY WORDS

export const BASE_TIME_ECMWF = "base_time";
export const FORECAST_LIST_ECMWF = "Forecast type";
export const SOIL_MOISTURE_LAYER_ECMWF = "Level";

export const CropSeasonList = [
  {
    label: "Maize - Wet Season",
    value: "Maize",
  },
  {
    label: "Maize - Season",
    value: "Maize",
  },
  {
    label: "Maize - dry Season",
    value: "Maize",
  },
];

export const ModalsConst = [
  {
    RainFalltext: "Rainfall: May-Jun",
    Ndvitext: "NDVI: Jun",
    modalName: "M1",
    months: 5,
    Modalvalue: "Model_1",
    label: "Model M1",
  },
  {
    RainFalltext: "Rainfall: May-Jun-Jul",
    Ndvitext: "NDVI: Jun",
    modalName: "M2",
    months: 6,
    Modalvalue: "Model_2",
    label: "Model M2",
  },
  {
    RainFalltext: "Rainfall: May-Jun-Jul-Aug",
    Ndvitext: "NDVI: Jun",
    modalName: "M3",
    months: 7,
    Modalvalue: "Model_3",
    label: "Model M3",
  },
];

export const monitorIndicatorId = "409f6796-1b33-45f8-80fa-9fb44b54e8d9";

export const WEATHER_STATUS = [
  { label: "Clear", icon: <ClearSky /> },
  { label: "Clouds", icon: <Clouds /> },
  { label: "Rain", icon: <Rain /> },
];

export const RAPID_RESPONSE_DATA_SOURCES = {
  rainfall: [
    {
      value: "",
      label: "Select Data source",
    },
    {
      value: "CHIRPS",
      label: "CHIRPS",
      graph_url: "/rapid_response/rainfall/timeseries",
    },
    {
      value: "GPM",
      label: "GPM",
      graph_url: "/rapid_response/rainfall/timeseries",
    },
    {
      value: "GSMAP",
      label: "GSMAP",
      graph_url: "/rapid_response/rainfall/timeseries",
    },
  ],
  flood: [
    {
      value: "",
      label: "Select Data source",
    },
    {
      value: "MODIS",
      label: "MODIS",
    },
    {
      value: "SENTINEL_1",
      label: "Sentinel 1",
    },
    {
      value: "LANDSAT",
      label: "Landsat",
    },
  ],
  exposures: [
    {
      value: "CROP_EXPOSURE",
      label: "Crop/ Agricultural land exposure",
      api_url: "/rapid_response/crop_exposure/map",
      graph_url: "/rapid_response/crop_exposure/timeseries",
    },
    {
      value: "POPULATION_EXPOSURE",
      label: "Population exposure",
      api_url: "/rapid_response/population_exposure/map",
      graph_url: "/rapid_response/population_exposure/timeseries",
    },
    {
      value: "INFRASTRUCTURAL_EXPOSURE",
      label: "Infrastructural exposure",
      api_url: "/rapid_response/affected/road/buildings/map",
    },
  ],
};

export const RAPID_RESPONSE_CROP_GRAPH_PARAMETERS = [
  "district_wise_cropland",
  "district_wise_affected_crop_area",
  "affected_crop_area",
];

export const RAPID_RESPONSE_POPULATION_GRAPH_PARAMETERS = [
  "district_wise_total_population",
  "district_wise_population_affected",
  "flooded_population_density",
  "population_affected",
];

export const documentUploadListing = [
  {
    Documents: [
      {
        lable: "docx",
        value: "Docx",
        slug: "docx",
        icon: DocumentIcon,
      },
      {
        lable: "excel",
        value: "Excels",
        slug: "xlsx",
        icon: ExcelIcon,
      },
      {
        lable: "pfd",
        value: "Pdfs",
        slug: "pdf",
        icon: PdfIcon,
      },
    ],
  },

  {
    Media: [
      {
        lable: "Images",
        value: "Images",
        slug: "image",
        icon: ImageIcon,
      },
      {
        lable: "Video",
        value: "Video",
        slug: "video",
        icon: VideoIcon,
      },
    ],
  },

  {
    Links: [
      {
        lable: "Links",
        value: "Url's",
        slug: "links",
        icon: LinkIcon,
      },
    ],
  },
];

export const RAINFALL_TIMESERIES_INTERVALS = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
];

export const FEEDBACK_MODULES = [
  { label: "Crop Yield Assessment", value: "crop_yield_prediction" },
  { label: "WetIn Mobile", value: "wetin_app" },
];

export const RAPID_RESPONSE_NEWSFEED_DISASTER_TYPES = [
  { label: "Flood", value: "FL", icon: FloodIcon },
  { label: "Drought", value: "DR", icon: DroughtIcon },
  { label: "Tropical Cyclone", value: "TC", icon: CycloneIcon },
  { label: "Landslide", value: "LS", icon: LandslideIcon },
  { label: "Flash Flood", value: "FL", icon: FloodIcon },
  { label: "Epidemic", value: "FL", icon: LandslideIcon },
];

export const NEWSFEED_APPROVAL_TYPES = [
  { label: "All", value: "" },
  { label: "Approved", value: "APPROVED" },
  { label: "Rejected", value: "REJECTED" },
  { label: "Pending", value: "PENDING" },
];

export const CROP_YEILD_TRAINING_OPTIONS = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
