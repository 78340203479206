import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import UploadHeader from "./documentHeaderUpload";
import DocumentListing from "./documentsListing";
import MediaReferenceModal from "./uploadFilesModal";
import SuccessConfirmationModal from "../../components/Common/SucessConfirmModel";
import {
  uploadDocumentsListingRequest,
  uploadDocumentsRequest,
} from "../../store/actions";
import ClimberFooter from "../../components/Common/ClimberFooter";
import ImageSuccess from "../../assest/img/fileSuccessImg.png";
import urlSuccess from "../../assest/img/urlSuccessImg.png";
import DcoumentPreviewModal from "../../components/Common/documentPreviewModal";
import MeidaPreviewModal from "../../components/Common/MediaPreviewModal";

function DocumentUpload() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [isMediaPreviewModal, setIsMediaPreviewModal] = useState(false);
  const [selectedVedio, setSelectedVideo] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImagesIndex, setSelectedImagesIndex] = useState({
    start: 0,
    end: 4,
  });
  const [uploadFile, setUploadFile] = useState({
    base64: "",
    file_name: "",
  });

  const [documentListing, setDocumentsList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState({
    collaspe: "Documents",
    param: "Docx",
    slug: "docx",
  });

  const [pageLimit, setPageLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [searchDocument, setSearchDocument] = useState("");
  const [title, setTitle] = useState("");

  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [isPreviewModal, setisPreviewModal] = useState("");
  const [isSelectedFile, setIsSelectedFile] = useState({
    uri: "",
    fileType: "",
  });
  const [isUploadType, setIsUploadType] = useState("");
  /* ------handling api data---------*/
  const nextProps = useSelector((state) => ({
    uploadSuccess: state.Aware?.documentUpload,
    documentUploadList: state.Aware?.documentUploadList,
    documentUploadListloading: state.Aware?.documentUploadListloading,
  }));

  const handleGoToPage = (value) => {
    setPageNumber(Number(value));
    dispatch(
      uploadDocumentsListingRequest({
        page: value,
        limit: pageLimit,
        type: selectedCategory?.slug,
      }),
    );
  };
  const handlePageSize = (value) => {
    setPageSize(value);
    setPageLimit(value);
    setPageNumber(1);
    dispatch(
      uploadDocumentsListingRequest({
        page: 1,
        limit: value,
        type: selectedCategory?.slug,
      }),
    );
  };

  const isBannerImages = useRef(true);
  useEffect(() => {
    if (isBannerImages.current) {
      isBannerImages.current = false;
      return;
    }
    if (nextProps.uploadSuccess) {
      setIsUploadModal(false);
      document.body.classList.remove("no-scroll");
      setIsUploadSuccess(true);

      setTitle("");
      setPageNumber(1);
      setPageLimit(10);
      setPageSize(10);

      dispatch(
        uploadDocumentsListingRequest({
          page: 1,
          limit: 10,
          type: selectedCategory?.slug,
        }),
      );
    }
  }, [nextProps.uploadSuccess]);

  useEffect(() => {
    if (selectedCategory?.param) {
      dispatch(
        uploadDocumentsListingRequest({
          page: 1,
          limit: 10,
          type: selectedCategory?.slug,
        }),
      );
    }
  }, [selectedCategory?.param]);

  const isProjectList = useRef(true);
  useEffect(() => {
    if (isProjectList.current) {
      isProjectList.current = false;
      return;
    }

    if (nextProps.documentUploadList?.results) {
      setDocumentsList(nextProps.documentUploadList?.results);

      setPageCount(nextProps.documentUploadList?.count);
    } else {
      if (nextProps?.documentUploadListloading === false) {
        setDocumentsList([]);

        setPageNumber(1);
        setPageLimit(10);

        setPageSize(10);
        setPageCount();
      }
    }
  }, [nextProps.documentUploadList]);

  const handleSetMediaReferences = (data) => {
    const payLoad = {};

    data?.type === "file"
      ? (payLoad.file = data?.data)
      : (payLoad.url = data?.data);
    setIsUploadType(data?.type);
    dispatch(uploadDocumentsRequest(payLoad));
  };

  useEffect(() => {
    if (!isUploadModal) {
      setUploadFile((prev) => {
        return {
          ...prev,
          base64: "",
          file_name: "",
        };
      });
    }
  }, [isUploadModal]);

  const onHandleFiles = (fileData) => {
    setisPreviewModal(true);
    const fileExtension = fileData?.url.slice(
      fileData?.url.lastIndexOf(".") + 1,
    );
    const fileName = fileData?.url.substring(
      fileData?.url.lastIndexOf("/") + 1,
    );
    setIsSelectedFile([
      {
        uri: fileData?.url,
        fileType: fileExtension !== "csv" ? fileExtension : fileExtension,
        fileName,
      },
    ]);
  };

  return (
    <>
      <div className="upload-doc-container">
        <UploadHeader
          setIsUploadModal={setIsUploadModal}
          isUploadModal={isUploadModal}
          setSearchDocument={setSearchDocument}
          searchDocument={searchDocument}
        />

        <div className="documents-download-container mt-4 pb-3">
          <div className="du-cards-container projetcts-scroll">
            {nextProps?.documentUploadListloading === false ? (
              <DocumentListing
                documentsList={documentListing}
                setSelectedCategory={setSelectedCategory}
                selectedCategory={selectedCategory}
                pageCount={pageCount}
                setisPreviewModal={setisPreviewModal}
                isPreviewModal={isPreviewModal}
                onHandleFiles={onHandleFiles}
                setIsMediaPreviewModal={setIsMediaPreviewModal}
                setSelectedVideo={setSelectedVideo}
                selectedVedio={selectedVedio}
                setSelectedImage={setSelectedImage}
                selectedImage={selectedImage}
                setSelectedImagesIndex={setSelectedImagesIndex}
                selectedImagesIndex={selectedImagesIndex}
                pageLimit={pageLimit}
                setPageCount={setPageCount}
                setPageLimit={setPageLimit}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                setPageSize={setPageSize}
                pageSize={pageSize}
                handleGoToPage={handleGoToPage}
                handlePageSize={handlePageSize}
                disabled
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {isUploadModal && (
        <MediaReferenceModal
          modal={isUploadModal}
          toggle={() => setIsUploadModal(!isUploadModal)}
          setUploadFile={setUploadFile}
          uploadFile={uploadFile}
          handleSetMediaReferences={handleSetMediaReferences}
          title={title}
          setTitle={setTitle}
        />
      )}
      <ClimberFooter page="climber" />
      {isUploadSuccess && (
        <SuccessConfirmationModal
          modal
          icon={isUploadType === "file" ? ImageSuccess : urlSuccess}
          message={
            isUploadType === "file"
              ? t("UPLOAD_DOCUMENTS.IMAGE_UPLOAD_SUCCESS_MESSAGE")
              : t("UPLOAD_DOCUMENTS.URL_UPLOAD_SUCCESS_MESSAGE")
          }
          title={
            isUploadType === "file"
              ? t("UPLOAD_DOCUMENTS.IMAGE_UPLOAD_SUCCESS_TITLE")
              : t("UPLOAD_DOCUMENTS.URL_UPLOAD_SUCCESS_TITLE")
          }
          toggle={() => {
            setIsUploadSuccess(false);
            setIsUploadType("");
          }}
        />
      )}

      {isPreviewModal && (
        <DcoumentPreviewModal
          toggle={() => setisPreviewModal(!isPreviewModal)}
          modal={isPreviewModal}
          docs={isSelectedFile}
        />
      )}

      {isMediaPreviewModal && (
        <MeidaPreviewModal
          modal={isMediaPreviewModal}
          toggle={() => setIsMediaPreviewModal(!isMediaPreviewModal)}
          carouselData={documentListing}
          selectedCategory={selectedCategory}
          selectedVedio={selectedVedio}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          setSelectedImagesIndex={setSelectedImagesIndex}
          selectedImagesIndex={selectedImagesIndex}
        />
      )}
    </>
  );
}

export default DocumentUpload;
