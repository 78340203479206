const USER_GUIDE_CONSTS = {
  GET_All_TOPICS_LIST_REQUEST: "GET_All_TOPICS_LIST_REQUEST",
  GET_All_TOPICS_LIST_SUCCESS: "GET_All_TOPICS_LIST_SUCCESS",
  GET_All_TOPICS_LIST_FAILED: "GET_All_TOPICS_LIST_FAILED",

  SEARCH_CONTENT_USER_GUIDE_REQUEST: "SEARCH_CONTENT_USER_GUIDE_REQUEST",
  SEARCH_CONTENT_USER_GUIDE_SUCCESS: "SEARCH_CONTENT_USER_GUIDE_SUCCESS",
  SEARCH_CONTENT_USER_GUIDE_FAILED: "SEARCH_CONTENT_USER_GUIDE_FAILED",

  GET_ALL_USER_GUIDE_REQUEST: "GET_ALL_USER_GUIDE_REQUEST",
  GET_ALL_USER_GUIDE_SUCCESS: "GET_ALL_USER_GUIDE_SUCCESS",
  GET_ALL_USER_GUIDE_FAILED: "GET_ALL_USER_GUIDE_FAILED",

  GET_ALL_TOPICS_REQUEST: " GET_ALL_TOPICS_REQUEST",
  GET_ALL_TOPICS_SUCCESS: " GET_ALL_TOPICS_SUCCESS",
  GET_ALL_TOPICS_FAILED: " GET_ALL_TOPICS_FAILED",

  SEARCH_BY_CRITERIA_USER_GUIDE_REQUEST:
    "SEARCH_BY_CRITERIA_USER_GUIDE_REQUEST",
  SEARCH_BY_CRITERIA_USER_GUIDE_SUCCESS:
    "SEARCH_BY_CRITERIA_USER_GUIDE_SUCCESS",
  SEARCH_BY_CRITERIA_USER_GUIDE_FAILED: "SEARCH_BY_CRITERIA_USER_GUIDE_FAILED",
};

export default USER_GUIDE_CONSTS;
