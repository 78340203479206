import EARLY_ACTIONS_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};
const EAProjectReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case EARLY_ACTIONS_CONST.EARLY_ACTION_RISK_TYPE_REQUEST:
      state = {
        ...state,
        loading: true,
        riskList: null,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_RISK_TYPE_SUCCESS:
      state = {
        ...state,
        loading: false,
        riskList:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_RISK_TYPE_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        riskList: null,
      };
      break;

    case EARLY_ACTIONS_CONST.EARLY_ACTION_CREATE_PROJECT_REQUEST:
      state = {
        ...state,
        loading: true,
        createProject: null,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_CREATE_PROJECT_SUCCESS:
      state = {
        ...state,
        loading: false,
        createProject:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_CREATE_PROJECT_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        riskList: null,
      };
      break;

    case EARLY_ACTIONS_CONST.EARLY_ACTION_EDIT_PROJECT_REQUEST:
      state = {
        ...state,
        loading: true,
        editProjectMessage: null,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_EDIT_PROJECT_SUCCESS:
      state = {
        ...state,
        loading: false,
        editProjectMessage:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_EDIT_PROJECT_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        editProjectMessage: null,
      };
      break;

    case EARLY_ACTIONS_CONST.EARLY_ACTION_PROJECT_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
        projectList: null,
        error: "",
        projectLoading: true,
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_PROJECT_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        projectList:
          action.payload.statusCode === 200 ? action.payload.data : false,
        isUpdate: action.payload?.isUpdate,
        error: "",
        projectLoading: false,
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_PROJECT_LIST_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        projectList: null,
        projectLoading: false,
      };
      break;

    case EARLY_ACTIONS_CONST.EARLY_ACTION_DELETE_PROJECT_REQUEST:
      state = {
        ...state,
        loading: true,
        deleteProject: null,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_DELETE_PROJECT_SUCCESS:
      state = {
        ...state,
        loading: false,
        deleteProject:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_DELETE_PROJECT_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        deleteProject: null,
      };
      break;

    //  Preparedness phase
    case EARLY_ACTIONS_CONST.EARLY_ACTION_PREPAREDNESS_PHASE_REQUEST:
      state = {
        ...state,
        loading: true,
        categoryLoader: true,
        preparednessPhaseData: null,
        isUpdateAll: null,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_PREPAREDNESS_PHASE_SUCCESS:
      state = {
        ...state,
        loading: false,
        categoryLoader: false,
        preparednessPhaseData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        isUpdateAll: action.payload.isUpdateAll,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_PREPAREDNESS_PHASE_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        categoryLoader: false,
        preparednessPhaseData: null,
        isUpdateAll: null,
      };
      break;

    //  Preparedness phase table data suggestions
    case EARLY_ACTIONS_CONST.EARLY_ACTION_PREPAREDNESS_PHASE_SUGGESTIONS_REQUEST:
      state = {
        ...state,
        loading: true,
        preparednessPhaseSuggestionsData: null,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_PREPAREDNESS_PHASE_SUGGESTIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        preparednessPhaseSuggestionsData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_PREPAREDNESS_PHASE_SUGGESTIONS_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        preparednessPhaseSuggestionsData: null,
      };
      break;

    //  Preparedness phase table data suggestions
    case EARLY_ACTIONS_CONST.EARLY_ACTION_PROJECT_STATUS_REQUEST:
      state = {
        ...state,
        loading: true,
        projectStatus: null,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_PROJECT_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        projectStatus:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.EARLY_ACTION_PROJECT_STATUS_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        projectStatus: null,
      };
      break;

    //  currency listing reducer
    case EARLY_ACTIONS_CONST.CURRENCY_LISTING_REQUEST:
      state = {
        ...state,
        loading: true,
        currencyList: null,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.CURRENCY_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        currencyList:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case EARLY_ACTIONS_CONST.CURRENCY_LISTING_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        currencyList: null,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};
export default EAProjectReducer;
