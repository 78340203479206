import AWARE_CONST from "./actionTypes";

export const awareAboutRequest = (payload) => {
  return {
    type: AWARE_CONST.AWARE_ABOUT_REQUEST,
    payload,
  };
};
export const awareAboutSuccess = (user) => {
  return {
    type: AWARE_CONST.AWARE_ABOUT_SUCCESS,
    payload: user,
  };
};
export const awareAboutError = (error) => {
  return {
    type: AWARE_CONST.AWARE_ABOUT_ERROR,
    payload: error,
  };
};

export const awareToolsRequest = () => {
  return {
    type: AWARE_CONST.AWARE_TOOLS_REQUEST,
    payload: {},
  };
};
export const awareToolsSuccess = (user) => {
  return {
    type: AWARE_CONST.AWARE_TOOLS_SUCCESS,
    payload: user,
  };
};
export const awareToolsError = (error) => {
  return {
    type: AWARE_CONST.AWARE_TOOLS_ERROR,
    payload: error,
  };
};

// TEAM LISTING  ACTIONS
export const awareTeamListRequest = () => {
  return {
    type: AWARE_CONST.AWARE_TEAM_LIST_REQUEST,
    payload: {},
  };
};
export const awareTeamListSuccess = (user) => {
  return {
    type: AWARE_CONST.AWARE_TEAM_LIST_SUCCESS,
    payload: user,
  };
};
export const awareTeamListError = (error) => {
  return {
    type: AWARE_CONST.AWARE_TEAM_LIST_FAILURE,
    payload: error,
  };
};

// upload documents
export const uploadDocumentsRequest = (payload) => {
  return {
    type: AWARE_CONST.UPLOAD_DOCUMENTS_REQUEST,
    payload,
  };
};
export const uploadDocumentsSuccess = (user) => {
  return {
    type: AWARE_CONST.UPLOAD_DOCUMENTS_SUCCESS,
    payload: user,
  };
};
export const uploadDocumentsError = (error) => {
  return {
    type: AWARE_CONST.UPLOAD_DOCUMENTS_FAILURE,
    payload: error,
  };
};

// upload  listing documents
export const uploadDocumentsListingRequest = (payload) => {
  return {
    type: AWARE_CONST.UPLOAD_DOCUMENTS_LISTING_REQUEST,
    payload,
  };
};
export const uploadDocumentsListingSuccess = (user) => {
  return {
    type: AWARE_CONST.UPLOAD_DOCUMENTS_LISTING_SUCCESS,
    payload: user,
  };
};
export const uploadDocumentsListingError = (error) => {
  return {
    type: AWARE_CONST.UPLOAD_DOCUMENTS_LISTING_FAILURE,
    payload: error,
  };
};
