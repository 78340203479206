import React from "react";
import { Button, FormGroup, Row, Col, Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import { formickTextField } from "../../../components/Common/FormickField";
import "react-multi-date-picker/styles/colors/green.css";
import { selectinteger } from "../../../helpers/validate";

function RightSideModal({
  toggle,
  onHanldeAddEdit,
  initialValues,
  setApiCall,
}) {
  const { t } = useTranslation();
  const handleSubmit = (data) => {
    onHanldeAddEdit(data);
    setApiCall(true);

    toggle();
  };

  return (
    <div className="st-right-sidebar-wrapper-filter-intervention">
      <div className="st-filter-header-manageuser edit-header-text-center">
        <p className="image-sidebar-header">
          {t("EARLY_ACTIONS.EDIT_SECTION")}
        </p>
      </div>
      <hr />
      <div className="p-2">
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {(formick) => (
            <Form>
              <Row>
                <Col sm={12} className="mb-0">
                  <FormGroup className="">
                    <Label className="region-label mt-0">Budget</Label>
                    <Field
                      classNameActive="input-class-intervention"
                      classNameInactive={
                        formick?.name?.email && formick?.name?.email
                          ? "input-class-intervention input-error-class"
                          : "input-class-intervention"
                      }
                      type="text"
                      name="budget"
                      component={formickTextField}
                      onChangeField={() => {}}
                      validate={(value) => selectinteger(value, 30)}
                      placeholder="Budget"
                    />
                    {formick?.errors?.budget && formick?.touched?.budget && (
                      <p className=" error">{t(formick?.errors?.budget)}</p>
                    )}
                  </FormGroup>
                </Col>

                <Col sm={12} className="mb-3">
                  <div className="d-flex align-items-center justify-content-end editor-button-container mt-3">
                    <Button
                      className="back-office-cancel-btn"
                      type="button"
                      onClick={toggle}
                    >
                      {t("APPROVE_DATA.CANCEL")}
                    </Button>
                    <Button
                      className="back-office-ok-btn w-auto mb-1 px-5"
                      type="submit"
                      disabled={!formick.isValid || !formick.dirty}
                    >
                      {t("BULLET_IN.SAVE")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default RightSideModal;
