import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import EARLY_WARNING_HEALTH_CONST from "./actionTypes";
import {
  getHealthTimeSeriesSuccess,
  getHealthTimeSeriesError,
  getEWRawDataDatesSuccess,
  getEWRawDataDatesError,
  getHealthMalariaDataSuccess,
  getHealthMalariaDataFailed,
  getHealthMalariaPredectionSuccess,
  getHealthMalariaPredectionFailed,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import {
  EARLY_WARNING_API,
  AXIOS_INSTANCE,
  HEALTH_URL,
  MALARIA_PREDECTION_URL,
  AXIOS_INSTANCE_LOADER,
} from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* getHealthTimeSeries(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.post(
        `${EARLY_WARNING_API}/early_warnings/health/timeseries`,
        action.payload.data,
      ),
      "",
      "",
      true,
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getHealthTimeSeriesSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.message || response.data.error,
      };
      yield put(getHealthTimeSeriesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getHealthTimeSeriesError(errorData));
  }
}

function* getRawDataDates(action) {
  const { moduleName, subModuleSlug, countryId } = action.payload;
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${EARLY_WARNING_API}/early_warnings/raw_data/controls?country_id=${countryId}&menu_slug=${moduleName}&sub_menu_slug_or_name=${subModuleSlug}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getEWRawDataDatesSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.message || response.data.error,
      };
      yield put(getEWRawDataDatesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getHealthTimeSeriesError(errorData));
  }
}

function* getHealthMalariaData(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.post(
        `${HEALTH_URL}/map`,
        action?.payload?.requestObj,
      ),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getHealthMalariaDataSuccess(responseData));
    } else {
      const responseData = {
        data: response.data,
      };
      yield put(getHealthMalariaDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getHealthMalariaDataFailed(errorData));
  }
}

function* getHealthMalariaPredectionData(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${MALARIA_PREDECTION_URL}`, action?.payload),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getHealthMalariaPredectionSuccess(responseData));
    } else {
      const responseData = {
        data: response.data,
      };
      yield put(getHealthMalariaPredectionFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getHealthMalariaPredectionFailed(errorData));
  }
}

export function* watchHealth() {
  yield takeEvery(
    EARLY_WARNING_HEALTH_CONST.GET_HEALTH_TIMESERIES_REQUEST,
    getHealthTimeSeries,
  );
  yield takeEvery(
    EARLY_WARNING_HEALTH_CONST.GET_EW_RAW_DATA_DATES_REQUEST,
    getRawDataDates,
  );
  yield takeEvery(
    EARLY_WARNING_HEALTH_CONST.GET_HEALTH_MALARIA_DATA_REQUEST,
    getHealthMalariaData,
  );
  yield takeEvery(
    EARLY_WARNING_HEALTH_CONST.GET_HEALTH_PREDECTION_DATA_REQUEST,
    getHealthMalariaPredectionData,
  );
}

function* HealthSaga() {
  yield all([fork(watchHealth)]);
}

export default HealthSaga;
