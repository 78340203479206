import BOUNDARY_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};
const Boundry = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case BOUNDARY_CONST.COUNTRY_BOUNDRY_REQUEST:
      state = {
        ...state,
        loading: true,
        countryBoundry: null,
        error: "",
        countryloading: true,
      };
      break;
    case BOUNDARY_CONST.COUNTRY_BOUNDRY_SUCCESS:
      state = {
        ...state,
        loading: false,
        countryBoundry:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
        countryloading: false,
      };
      break;
    case BOUNDARY_CONST.COUNTRY_BOUNDRY_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        countryBoundry: null,
        countryloading: false,
      };
      break;
    // state boundry
    case BOUNDARY_CONST.STATE_BOUNDRY_REQUEST:
      state = {
        ...state,
        loading: true,
        stateLoading: true,
        stateBoundry: null,
        error: "",
      };
      break;
    case BOUNDARY_CONST.STATE_BOUNDRY_SUCCESS:
      state = {
        ...state,
        loading: false,
        stateLoading: false,
        stateBoundry:
          action.payload.statusCode === 200 ? action.payload : false,
        error: "",
      };
      break;
    case BOUNDARY_CONST.STATE_BOUNDRY_ERROR:
      state = {
        ...state,
        stateLoading: false,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        stateBoundry: null,
      };
      break;

    // taluks boundry
    case BOUNDARY_CONST.TALUKAS_BOUNDRY_REQUEST:
      state = {
        ...state,
        loading: true,
        taluksBoundry: null,
        error: "",
      };
      break;
    case BOUNDARY_CONST.TALUKAS_BOUNDRY_SUCCESS:
      state = {
        ...state,
        loading: false,
        taluksBoundry:
          action.payload.statusCode === 200 ? action.payload : false,
        error: "",
      };
      break;
    case BOUNDARY_CONST.TALUKAS_BOUNDRY_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        taluksBoundry: null,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};
export default Boundry;
