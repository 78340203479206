import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import { AiFillExclamationCircle, AiFillCheckCircle } from "react-icons/ai";

function PublishModal({ toggle, modal, data, setPublishItem, onChangeStatus }) {
  const { t } = useTranslation();

  return (
    <div>
      <Modal isOpen={modal} size="md">
        <ModalHeader className="p-0 delete-header user-flex-end mr-2">
          <GrFormClose
            size={30}
            onClick={() => {
              setPublishItem();
              toggle();
            }}
            className="pointer"
          />
        </ModalHeader>
        <ModalBody className="delete-modal-padding">
          <div className="text-center mb-2">
            {data?.status ? (
              <AiFillExclamationCircle className="mb-3 exclamation-icon" />
            ) : (
              <AiFillCheckCircle className="mb-3 tick-publish-icon" />
            )}

            <p className="mt-1">
              {" "}
              <b> {t("DELETE_MODAL.ARE_YOU_SURE")} </b>
            </p>

            <p className="mt-2">
              {!data?.status
                ? t("DELETE_MODAL.PUBLISH_MODAL_MESSAGE_SUCCESS")
                : t("DELETE_MODAL.PUBLISH_MODAL_MESSAGE")}
            </p>
          </div>
          <div className="text-center mt-4 ">
            <Button
              onClick={() => {
                setPublishItem();
                toggle();
              }}
              className="btn-cancel mr-2"
            >
              {t("DELETE_MODAL.CANCEL")}
            </Button>{" "}
            <Button
              className={
                data?.status
                  ? "btn-news-feed-submit ml-2"
                  : "btn-news-feed-submit-publish ml-2"
              }
              onClick={() => {
                onChangeStatus(data);
                toggle();
              }}
            >
              {data?.status ? t("DELETE_MODAL.UN_PUBLISH_BUTTON") : "Publish"}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default PublishModal;
