import React from "react";
import Select from "react-select";
import { ErrorMessage } from "formik";
import { Input, Label } from "reactstrap";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/green.css";
// import InputIcon from "react-multi-date-picker/components/input_icon";
// import DatePanel from "react-multi-date-picker/plugins/date_panel";

export function RenderTextField({
  placeholder,
  className,
  readOnly,
  autoFocus,
  type,
  isDisabled,
  required,
  min,
  max,
  label,
  name,
  formInput,
  onChange,
  ...rest
}) {
  return (
    <>
      {label && <Label className="label">{label}</Label>}
      <Input
        {...rest}
        placeholder={placeholder}
        className={className}
        type={type}
        onChange={onChange}
        autoFocus={autoFocus}
        readOnly={readOnly}
        required={required}
        disabled={isDisabled}
        min={min || ""}
        max={max || ""}
      />
      {formInput && (
        <div className="error-msg">
          <ErrorMessage name={name} />
        </div>
      )}
    </>
  );
}

export function RenderReactSelectInputField({
  input,
  options,
  name,
  id,
  placeholder,
  isClearable,
  isDisabled,
  className,
  isMulti,
  isLoading,
  onMenuOpen,
  onMenuClose,
  label,
  formInput,
  ...rest
}) {
  return (
    <>
      {label && <Label className="label">{label}</Label>}
      <Select
        {...rest}
        classNamePrefix="react-select"
        name={name}
        className={className}
        placeholder={placeholder}
        isClearable={isClearable}
        isDisabled={isDisabled}
        options={options}
        isMulti={isMulti}
        maxMenuHeight={150}
      />
      {formInput && (
        <div className="error-msg">
          <ErrorMessage name={name} />
        </div>
      )}
    </>
  );
}

export function RenderDateField({
  values,
  onChange,
  minDate,
  maxDate,
  format,
  children,
  className,
  label,
  onClose,
  range,
  datePickerRef,
  onlyMonthPicker,
  onlyYearPicker,
  ...rest
}) {
  return (
    <>
      {label && <Label className="label">{label}</Label>}
      <DatePicker
        // render={<InputIcon style={{ width: "10px" }} />}
        arrow={false}
        hideOnScroll
        value={values}
        className={`green ${className}`}
        onChange={onChange}
        onlyMonthPicker={onlyMonthPicker}
        onlyYearPicker={onlyYearPicker}
        range={range}
        minDate={minDate}
        maxDate={maxDate}
        format={format}
        onClose={onClose}
        ref={datePickerRef}
        {...rest}
        // plugins={[<DatePanel />]}
      >
        {children}
      </DatePicker>
    </>
  );
}
