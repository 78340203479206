import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import BOUNDARY_CONST from "./actionTypes";
import {
  countryBoundarySuccess,
  countryBoundaryError,
  stateBoundarySuccess,
  stateBoundaryError,
  taluksBoundarySuccess,
  taluksBoundaryError,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import { BOUNDARY_URL, AXIOS_INSTANCE_LOADER } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* countryBoundry(action) {
  AXIOS_INSTANCE_LOADER.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.post(`${BOUNDARY_URL}`, action.payload?.data),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(countryBoundarySuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(countryBoundaryError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(countryBoundaryError(errorData));
  }
}

function* stateBoundry(action) {
  AXIOS_INSTANCE_LOADER.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.post(`${BOUNDARY_URL}`, action.payload?.data),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
        isState: action.payload?.isState,
      };
      yield put(stateBoundarySuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(stateBoundaryError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(stateBoundaryError(errorData));
  }
}

// talukas saga
function* talukasBoundry(action) {
  AXIOS_INSTANCE_LOADER.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.post(`${BOUNDARY_URL}`, action.payload?.data),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
        // isState: action.payload?.isState,
      };
      yield put(taluksBoundarySuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(taluksBoundaryError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(taluksBoundaryError(errorData));
  }
}

export function* watchCountryBoundry() {
  yield takeEvery(BOUNDARY_CONST.COUNTRY_BOUNDRY_REQUEST, countryBoundry);
}

export function* watchStateBoundry() {
  yield takeEvery(BOUNDARY_CONST.STATE_BOUNDRY_REQUEST, stateBoundry);
}

export function* watchTaluaksBoundry() {
  yield takeEvery(BOUNDARY_CONST.TALUKAS_BOUNDRY_REQUEST, talukasBoundry);
}

function* BoundarySaga() {
  yield all([
    fork(watchCountryBoundry),
    fork(watchStateBoundry),
    fork(watchTaluaksBoundry),
  ]);
}

export default BoundarySaga;
