const AWARE_CONST = {
  AWARE_ABOUT_REQUEST: "AWARE_ABOUT_REQUEST",
  AWARE_ABOUT_SUCCESS: "AWARE_ABOUT_SUCCESS",
  AWARE_ABOUT_ERROR: "AWARE_ABOUT_ERROR",
  AWARE_TOOLS_REQUEST: "AWARE_TOOLS_REQUEST",
  AWARE_TOOLS_SUCCESS: "AWARE_TOOLS_SUCCESS",
  AWARE_TOOLS_ERROR: "AWARE_TOOLS_ERROR",

  // TEAM LIST
  AWARE_TEAM_LIST_REQUEST: "AWARE_TEAM_LIST_REQUEST",
  AWARE_TEAM_LIST_SUCCESS: "AWARE_TEAM_LIST_SUCCESS",
  AWARE_TEAM_LIST_FAILURE: "AWARE_TEAM_LIST_FAILURE",

  // UPLOAD DOCUMENTS
  UPLOAD_DOCUMENTS_REQUEST: "UPLOAD_DOCUMENTS_REQUEST",
  UPLOAD_DOCUMENTS_SUCCESS: "UPLOAD_DOCUMENTS_SUCCESS",
  UPLOAD_DOCUMENTS_FAILURE: "AWARE_TEAM_LIST_FAILURE",

  // UPLOAD DOCUMENTS LISTING
  UPLOAD_DOCUMENTS_LISTING_REQUEST: "UPLOAD_DOCUMENTS_LISTING_REQUEST",
  UPLOAD_DOCUMENTS_LISTING_SUCCESS: "UPLOAD_DOCUMENTS_LISTING_SUCCESS",
  UPLOAD_DOCUMENTS_LISTING_FAILURE: "UPLOAD_DOCUMENTS_LISTING_FAILURE",
};

export default AWARE_CONST;
