import { Button } from "reactstrap";
import { BsPlus, BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isPermissionsMatch, superAdmin } from "../../components/Common/Utils";
import { ADD_PROJECT } from "../../constants";

function Header({ setIsCreateModal, permissionsList, isFinance }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const selectedSubModule = params.get("slug");

  return (
    <div className="EA-Header ea-space-between">
      <div className="back-office-center">
        <BsArrowLeft
          className="back-icon-ea"
          onClick={() =>
            navigate(
              isFinance
                ? `/early-finance-page?isFinance=true&slug=${selectedSubModule}`
                : `/earlyaction?slug=${selectedSubModule}`,
            )
          }
          role="button"
        />
        <p className="ea-header-text">{t("EARLY_ACTIONS.PROJECTS_LIST")} </p>
      </div>
      {(isPermissionsMatch(permissionsList, ADD_PROJECT) || superAdmin()) &&
      !isFinance ? (
        <Button
          className="ea-create-btn"
          onClick={() => setIsCreateModal(true)}
        >
          {" "}
          {t("EARLY_ACTIONS.CREATE_PROJECT")} <BsPlus className="add-icon" />
        </Button>
      ) : (
        ""
      )}
    </div>
  );
}

export default Header;
