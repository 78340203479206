import PREPAREDNESS_PHASE_CONST from "./actionTypes";

// EDIT
export const editPreparednessContentRequest = (payload) => {
  return {
    type: PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_EDIT_REQUEST,
    payload,
  };
};
export const editPreparednessContentSuccess = (response) => {
  return {
    type: PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_EDIT_SUCCESS,
    payload: response,
  };
};
export const editPreparednessContentError = (error) => {
  return {
    type: PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_EDIT_ERROR,
    payload: error,
  };
};

// DELETE
export const deletePreparednessContentRequest = (payload) => {
  return {
    type: PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_DELETE_REQUEST,
    payload,
  };
};
export const deletePreparednessContentSuccess = (response) => {
  return {
    type: PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_DELETE_SUCCESS,
    payload: response,
  };
};
export const deletePreparednessContentError = (error) => {
  return {
    type: PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_DELETE_ERROR,
    payload: error,
  };
};

// Create
export const createPreparednessContentRequest = (payload) => {
  return {
    type: PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_CREATE_REQUEST,
    payload,
  };
};
export const createPreparednessContentSuccess = (response) => {
  return {
    type: PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_CREATE_SUCCESS,
    payload: response,
  };
};
export const createPreparednessContentError = (error) => {
  return {
    type: PREPAREDNESS_PHASE_CONST.PREPAREDNESS_PHASE_CREATE_ERROR,
    payload: error,
  };
};
