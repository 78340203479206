import L from "leaflet";
import "leaflet-side-by-side";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GeoJSON,
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  TileLayer,
  ZoomControl,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";

import ReactTooltip from "react-tooltip";
import Button from "reactstrap-button-loader";
import CompareIcon from "../../assest/img/Compare_Icon.svg";
import Maprefer from "../../assest/img/Map-prefer.svg";
import loader from "../../assest/img/green-loading.gif";
import CustomLegend from "../../components/Common/CustomLegend";
import DiscreteLegend from "../../components/Common/DiscreteLegend";
import MapPreferenceModal from "../../components/Common/MapPreferences";
import { returnFilteredGeoJsonData } from "../../components/Common/Utils";
import {
  Fulfilled,
  GEOJSON_COUNTRY_DATA,
  MAP_PREFERENCES_LABELS,
  Rejected,
  mapPreferenceConstants,
  myConst,
} from "../../constants";
import {
  countryBoundaryRequest,
  stateBoundaryRequest,
} from "../../store/actions";
import { AXIOS_INSTANCE_LOADER } from "../../store/apiUtils/config";
import DrawShape from "./DrawShapes";

function Filter({
  position,
  zooms,
  onHandleZoom,
  startDate,
  endDate,
  legend,
  setLegend,
  layerData,
  layerControl,
  selectedLocation,
  setOpaci,
  indexLayers,
  setIndexLayers,
  comparelegend,
  mapes,
  setMapes,
  mapControl,
  indicatorList,
  mapPreferencesData,
  setMapPreferencesData,
  loadingMap,
  setOpen,
  adminRadio,
  selectedRainfallDataSource,
  layerRef,
  compareRef,
  activeStep,
  removeGeoJsonLayer,
  setMapPreferences,
  mapPreferences,
  wrap,
  removeLayers,
  removeMapLayers,
  removeMapPreferenceLayer,
  mapPreferRefs,
  selectedMapPreferLegendData,
  setSelectedMapPreferLegendData,
  selectedFloodDataSource,
  isCallBoundary,
  setIsCallBoundary,
  mapLayers,
  setMapLayers,
  setSelectedLocation,
  isDataSourceChanged,
  setShowChart,
  selectedCompareIndicator,
  setcompareLegend,
  compareIndicatorList,
  setIsCompareOpen,
  isCompareOpen,
  // setMapControl,
  // setLayerControl,
  setMapUrl,
  mapUrl,
}) {
  const { Overlay } = LayersControl;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [getBoundariesData, setGetBoundariesData] = useState([]);
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [geoJsonAllData, setGeoJsonAllData] = useState([]);
  const [geoJsonLayersData, setGeoJsonLayerData] = useState([]);
  const [mapPreferenceCategories, setMapPreferenceCategories] = useState([]);

  const [mapPreferUrl, setMapPreferUrl] = useState("");
  const [isOpenMapModel, setIsOpenMapModel] = useState(false);
  const [loaderLatLng, setLoaderLatLng] = useState({
    lat: selectedLocation?.country?.latitude,
    lng: selectedLocation?.country?.longitude,
  });

  const LoaderIcon = new L.Icon({ iconUrl: loader, iconSize: [80, 80] });

  const [, setMapLayerOpacity] = useState({ layer: "" });
  const [opacityControl, setOpacityControl] = useState("");
  const mapRef = useRef([]);
  mapRef.current = [];
  const ref = React.createRef();
  mapRef.current.push(ref);

  const nextProps = useSelector((state) => ({
    mapPreferenceList: state.MapPreference,
    countryBoundry: state.Boundry?.countryBoundry,
    stateBoundry: state?.Boundry?.stateBoundry,
  }));

  function ChangeView({ center, zoom }) {
    const mapEvents = useMapEvents({
      zoomend: () => {
        onHandleZoom(mapEvents.getCenter(), mapEvents.getZoom());
      },
      dragend: () => {
        onHandleZoom(mapEvents.getCenter(), mapEvents.getZoom());
      },
    });

    const map = useMap();
    setInterval(() => {
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) map.invalidateSize();
    }, 100);
    if (zoom && center) map.setView(center, zoom);
    return null;
  }

  const setMapReference = (mapInstance) => {
    wrap.current = mapInstance;
  };

  useEffect(() => {
    setMapes(wrap.current);
  }, [wrap.current]);

  const resetOpacity = () => {
    indexLayers.map((eachLayer) => {
      eachLayer?.layer.setOpacity(1);
      return null;
    });
  };

  const addLayers = (selectedParamRef) => {
    if (wrap.current && selectedParamRef.current) {
      const leafletMapRef = wrap.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer) => {
        leafletMapRef.addLayer(layer);
      });
    }
    return false;
  };

  // function for removing transparency tool layers
  useEffect(() => {
    // eslint-disable-next-line no-undef, no-underscore-dangle
    if (!_.isEmpty(mapes) && !_.isEmpty(mapes?._layers)) {
      mapes.on("baselayerchange", (element) => {
        element?.layer?.bringToBack();
      });
    }
  }, [mapes]);

  useEffect(() => {
    if (
      legend?.length &&
      legend?.some((i) => i.add && !i.descret_legend) &&
      selectedMapPreferLegendData?.length &&
      selectedMapPreferLegendData?.some((i) => i.add && i.descret_legend)
    ) {
      const leafletEle = document.getElementsByClassName("right-legend-style");

      if (leafletEle && leafletEle[0]) {
        leafletEle[0].classList.add("right-legend-pos-st");
      }
      const discreteLeafletEle = document.getElementsByClassName(
        "right-discrete-legend-style",
      );

      if (discreteLeafletEle && discreteLeafletEle[0]) {
        discreteLeafletEle[0].classList.add("right-discrete-legend-pos-st");
      }
    } else {
      const leafletEle = document.getElementsByClassName("right-legend-style");
      const rightPosLeafletEle = document.getElementsByClassName(
        "right-legend-pos-st",
      );
      const discreteLeafletEle = document.getElementsByClassName(
        "right-discrete-legend-style",
      );
      const rightPosdiscreteLeafletEle = document.getElementsByClassName(
        "right-discrete-legend-pos-st",
      );
      if (leafletEle && leafletEle[0] && rightPosLeafletEle) {
        leafletEle[0].classList.remove("right-legend-pos-st");
      }
      if (
        discreteLeafletEle &&
        discreteLeafletEle[0] &&
        rightPosdiscreteLeafletEle
      ) {
        discreteLeafletEle[0].classList.remove("right-discrete-legend-pos-st");
      }
    }
  }, [legend, selectedMapPreferLegendData]);

  useEffect(() => {
    if (
      nextProps?.mapPreferenceList &&
      nextProps?.mapPreferenceList?.mapPreferenceList?.length
    ) {
      const API_URL = [];
      mapPreferRefs.current = [];
      nextProps?.mapPreferenceList?.mapPreferenceList?.map((item) => {
        if (item?.layers && item?.layers.length) {
          item.layers.map((boundary) => {
            if (boundary?.json_url.length) {
              setGeoJsonData([]);
              setGeoJsonAllData([]);
              const mapPreferKeys = Object.keys(mapPreferences);
              if (mapPreferKeys) {
                mapPreferKeys.map((EachKey) => {
                  const selectedLayer = mapPreferences[EachKey];
                  if (selectedLayer?.layer && selectedLayer?.layer?.current)
                    // selectedLayer?.layer?.current?.clearLayers();
                    selectedLayer.color = "";
                  setMapPreferences((prev) => {
                    return {
                      ...prev,
                      [EachKey]: selectedLayer,
                    };
                  });
                  return null;
                });
              }
              const config = {
                name: boundary?.slug,
                id: boundary?.id,
                color: boundary?.color_code,
                category: boundary?.map_preference_category_name,
                country_id: selectedLocation?.country?.value,
              };

              boundary?.map_preference_category_name !== "Admin" &&
                API_URL.push(
                  AXIOS_INSTANCE_LOADER.get(boundary?.json_url, config),
                );
              // setMapPreferenceGeoJsonloading(true);
            } else {
              const ref1 = React.createRef();
              ref1.current = {
                boundary_id: boundary?.id,
                layer: React.createRef(),
              };
              mapPreferRefs.current.push(ref1);
            }

            return null;
          });
        }
        return null;
      });

      if (API_URL.length !== 0) {
        const errorMsg = [];
        const finalResponse = [];
        Promise.allSettled(API_URL).then((response) => {
          // setMapPreferenceGeoJsonloading(false);
          const res = JSON.parse(JSON.stringify(response));
          res?.map((item) => {
            if (item?.status === Fulfilled) {
              finalResponse.push(item?.value);
            } else if (item?.status === Rejected) {
              const errorResponse = item?.reason?.response;
              if (errorResponse?.status === 400) {
                if (errorResponse?.data?.code === 400) {
                  errorMsg.push(errorResponse?.data?.message);
                }
              }
            }
            return null;
          });
          setGeoJsonLayerData(finalResponse);
        });
      }
    }
  }, [nextProps?.mapPreferenceList?.mapPreferenceList]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        const mapGeojsonItem = Object.keys(mapPreferences).find(
          (x) => x === item.config.name,
        );

        const selectedLayer = mapPreferences[mapGeojsonItem];

        if (selectedLayer?.layer && selectedLayer?.layer?.current) {
          selectedLayer.country = "";
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.color = "";
          setMapPreferences((prev) => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }
        return null;
      });
      setGeoJsonData([]);
      setGeoJsonAllData([]);
      setGeoJsonLayerData([]);
    }
  }, [selectedLocation?.country]);

  useEffect(() => {
    let selectedGeoJsonData = [];
    if (geoJsonLayersData?.length && geoJsonData?.length) {
      geoJsonData?.map((item) => {
        const filteredObj = geoJsonLayersData?.find(
          (layer) =>
            layer.config.id === item.id &&
            layer.config.country_id === item.country_id,
        );

        if (!_.isEmpty(filteredObj)) {
          filteredObj.fromMapPreferences = item.fromMapPreferences;
          selectedGeoJsonData.push(filteredObj);
        }
        return null;
      });
    } else {
      selectedGeoJsonData = [];
    }

    setGeoJsonAllData(selectedGeoJsonData);
  }, [geoJsonLayersData, geoJsonData]);

  const isMapPreferenceList = useRef(true);
  useEffect(() => {
    if (isMapPreferenceList.current) {
      isMapPreferenceList.current = false;
      return;
    }
    if (nextProps.mapPreferenceList.mapPreferenceList?.length > 0) {
      const array = [];
      setGetBoundariesData([...nextProps.mapPreferenceList.mapPreferenceList]);
      nextProps.mapPreferenceList.mapPreferenceList.map((eachValue) =>
        array.push(eachValue.category),
      );

      setMapPreferenceCategories(array);
    }
  }, [nextProps.mapPreferenceList]);

  useEffect(() => {
    if (loadingMap) {
      removeMapLayers(legend, comparelegend, layerControl);
    }
  }, [loadingMap, layerData]);

  const isCountryBoundry = useRef(true);
  useEffect(() => {
    if (isCountryBoundry.current) {
      isCountryBoundry.current = false;
      return;
    }
    if (nextProps.countryBoundry && adminRadio === "admin") {
      const selectedLayer = mapPreferences.country;
      selectedLayer.country = selectedLocation?.country;
      selectedLayer.layer.current.addData(nextProps.countryBoundry);
      selectedLayer.color = "#040404";
    }
  }, [nextProps.countryBoundry, adminRadio]);

  const isStateBoundry = useRef(true);
  useEffect(() => {
    if (isStateBoundry.current) {
      isStateBoundry.current = false;
      return;
    }
    if (nextProps.stateBoundry?.data && adminRadio === "admin") {
      if (nextProps.stateBoundry?.isState) {
        // for state boundry
        const selectedLayer = mapPreferences.regions;
        selectedLayer?.layer?.current?.clearLayers();
        selectedLayer.country = selectedLocation?.country;
        selectedLayer.layer.current.addData(nextProps.stateBoundry?.data);
        selectedLayer.color = "#121211";
      } else {
        // for district boundry
        const selectedLayer = mapPreferences.districts_or_cities;
        selectedLayer?.layer?.current?.clearLayers();
        selectedLayer.country = selectedLocation?.country;
        selectedLayer.layer.current.addData(nextProps.stateBoundry?.data);
        selectedLayer.color = "#36373A";
      }
    } else if (adminRadio === "basin") {
      // for state boundry
      const stateLayer = mapPreferences.regions;
      stateLayer?.layer?.current?.clearLayers();
      const districtLayer = mapPreferences.districts_or_cities;
      districtLayer?.layer?.current?.clearLayers();
    }
  }, [nextProps.stateBoundry, adminRadio]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      if (mapPreferRefs?.current?.length) {
        mapPreferencesData?.map((item) => {
          const selectedMapPreferences = mapPreferRefs.current.find(
            (x) => x.current.boundary_id === item.id,
          );

          const currentParamRef = selectedMapPreferences?.current?.layer;

          currentParamRef?.current?.eachLayer((mapLayer) => {
            mapLayer.setUrl(item?.data?.map_url);
            mapLayer.setOpacity(1);
            const updatedIndexLayer = _.cloneDeep(indexLayers);

            updatedIndexLayer.push({ isCompare: false, layer: mapLayer });

            setIndexLayers(updatedIndexLayer);

            setMapPreferUrl({
              name: undefined,
              url: item?.data?.map_url,
              layer: mapLayer,
            });
            setMapLayerOpacity({ layer: mapLayer });
          });

          if (currentParamRef && currentParamRef?.current) {
            addLayers(currentParamRef);
          }
          return null;
        });
      }
    }
  }, [mapPreferencesData, mapPreferRefs.current]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData?.map((item) => {
        const mapGeojsonItem = Object.keys(mapPreferences).find(
          (x) => x === item.config.name,
        );

        const selectedLayer = mapPreferences[mapGeojsonItem];
        if (
          selectedLayer?.layer &&
          selectedLayer?.layer?.current &&
          item?.config?.category !== "Admin"
        ) {
          selectedLayer.country = selectedLocation?.country;
          // selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.layer.current.addData(item.data);
          selectedLayer.color = item.config.color;
          setMapPreferences((prev) => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }

        // Filtering sub basins based on basin
        if (!_.isEmpty(selectedLocation?.basin)) {
          // ! In retunrFilterGeoJsonData need to change basins and sub basin for each country based on the geojson properties
          const filteredLayer = returnFilteredGeoJsonData(
            selectedLayer,
            item,
            selectedLocation?.basin,
            MAP_PREFERENCES_LABELS.BASINS,
            MAP_PREFERENCES_LABELS.SUB_BASIN,
            selectedLocation,
          );
          if (filteredLayer) {
            setMapPreferences((prev) => {
              return {
                ...prev,
                [mapGeojsonItem]: filteredLayer,
              };
            });
          }
        }

        return null;
      });
    }
  }, [geoJsonAllData]);

  // for showing opacity slider when visualization  layer changes
  useEffect(() => {
    if (
      (selectedMapPreferLegendData?.length &&
        selectedMapPreferLegendData?.every((i) => !i.add)) ||
      !selectedMapPreferLegendData?.length
    ) {
      if (
        !_.isEmpty(layerControl?.firstLayer) &&
        !_.isEmpty(layerControl?.secondLayer)
      ) {
        const array = [];

        array.push({ isCompare: true, layer: layerControl?.firstLayer });
        array.push({ isCompare: true, layer: layerControl?.secondLayer });

        setIndexLayers(array);
      } else if (mapUrl?.url) {
        setMapLayerOpacity({ layer: mapUrl?.layer });
        const array = [];
        array.push({ isCompare: false, layer: mapUrl?.layer });

        setIndexLayers(array);
      }
    } else if (selectedMapPreferLegendData?.length) {
      if (mapPreferUrl?.url) {
        const array = [];
        array.push({ isCompare: false, layer: mapPreferUrl?.layer });
        const updatedIndexLayer = _.cloneDeep(indexLayers);
        updatedIndexLayer.push({
          isCompare: false,
          layer: mapPreferUrl?.layer,
        });

        setIndexLayers(updatedIndexLayer);

        setMapLayerOpacity({ layer: mapPreferUrl?.layer });
      }
    }
  }, [selectedMapPreferLegendData]);

  useEffect(() => {
    if (indicatorList?.length) {
      const selectedLegend = JSON.parse(
        JSON.stringify(selectedMapPreferLegendData),
      );

      if (selectedLegend.length) {
        selectedLegend.forEach(function selectLegend(value) {
          if (value.add) {
            value.add = false;
          }
        });
        setSelectedMapPreferLegendData(selectedLegend);
      }

      removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
      setMapPreferencesData([]);
    }
  }, [indicatorList]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      const MapPrefereArr = [];
      mapPreferencesData?.map((item) => {
        const legendItem = JSON.parse(JSON.stringify(item.data));
        legendItem.id = item?.dataSource;

        legendItem.parameter = item?.legendName;
        legendItem.add = true;
        legendItem.measure = legendItem.units;
        legendItem.isCompare = false;
        legendItem.id = item?.dataSource;
        legendItem.legendId = item.id;

        if (item.slug === mapPreferenceConstants?.soilSlug) {
          legendItem.dataSource =
            item.soil === mapPreferenceConstants?.soilTextureTwenty
              ? `${mapPreferenceConstants?.soliTextureText} (${mapPreferenceConstants?.soilTextureTwentyValue})`
              : `${mapPreferenceConstants?.soliTextureText} (${mapPreferenceConstants?.soilTextureFiftyValue})`;
        } else if (item.slug === mapPreferenceConstants?.gdpSlug) {
          legendItem.dataSource = `${mapPreferenceConstants.gdpText} ${item.gdpYear}`;
        } else {
          legendItem.dataSource = item.category;
        }

        legendItem.fromMapPreferences = true;
        if (selectedMapPreferLegendData?.length) {
          const isDuplicate = selectedMapPreferLegendData?.find(
            (x) => x.legendId === item.id && item.add === true,
          );
          if (_.isEmpty(isDuplicate)) {
            MapPrefereArr.push(legendItem);
          }
        } else {
          MapPrefereArr.push(legendItem);
        }
        return null;
      });

      setSelectedMapPreferLegendData(MapPrefereArr);
    }
  }, [mapPreferencesData]);

  useEffect(() => {
    if (selectedLocation?.country) {
      setMapPreferencesData([]);
      removeGeoJsonLayer(mapPreferences);
      setGeoJsonData([]);
      const selectedLegend = JSON.parse(
        JSON.stringify(selectedMapPreferLegendData),
      );
      let updatedLegend = [];
      if (selectedLegend.length) {
        updatedLegend = selectedLegend?.map((value) => {
          if (value.add) {
            value.add = false;
          }
          return value;
        });

        setSelectedMapPreferLegendData(updatedLegend);
      }
    }
  }, [selectedLocation?.country]);

  const getMapPreferencesData = (mapPreferencesLayerData) => {
    /** removing previously added map preferences layers before setting the state */
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    // setIndexLayers([]);
    /** setting map preferences state   */
    const arr = JSON.parse(JSON.stringify(mapPreferencesLayerData));
    let paramData = "";
    let paramArr = "";
    if (selectedMapPreferLegendData?.length) {
      paramData = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
      paramData?.map((item, index) => {
        if (item.add) {
          const legendItem = { ...item };
          legendItem.add = false;
          paramData[index] = legendItem;
        }
        return null;
      });
      paramArr = [...paramData];
    }

    setSelectedMapPreferLegendData(paramArr);
    setMapPreferencesData(arr);
  };

  useEffect(() => {
    if (!_.isEmpty(mapControl)) {
      mapControl?.on("dividermove", mapes.dragging.enable());
    } else if (!_.isEmpty(mapes)) {
      mapes?.dragging?.enable();
    }
  }, [mapControl]);

  //   useeffect to reset all the map preferences states data when country gets changed
  useEffect(() => {
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setMapPreferencesData([]);
    const selectedLegend = JSON.parse(
      JSON.stringify(selectedMapPreferLegendData),
    );
    // for legend addpled to map-preferences
    if (selectedLegend.length) {
      selectedLegend.forEach(function mappreference(value) {
        if (value.add) {
          value.add = false;
        }
      });
      setSelectedMapPreferLegendData(selectedLegend);
    }

    removeMapLayers(legend, comparelegend, layerControl);
  }, [selectedLocation.state, adminRadio, selectedLocation?.basin]);

  // for applying layers when we click on apply layer button
  useEffect(() => {
    if (layerData && wrap !== null && !selectedCompareIndicator?.length) {
      const currentParamRef = layerRef.current[layerData?.paramIndex];
      if (currentParamRef && currentParamRef.current !== null) {
        currentParamRef.current?.eachLayer((layer) => {
          if (layerData?.map_url) {
            layer.setUrl(layerData?.map_url);
            setMapUrl({ name: "", url: layerData?.map_url, layer });
            const updatedIndexLayer = _.cloneDeep(indexLayers);
            updatedIndexLayer.push({ isCompare: false, layer });
            setIndexLayers(updatedIndexLayer);
          }
        });
        removeMapLayers(legend, comparelegend, layerControl);
        const updateLegend = [...legend];
        let legendObj = {};
        legendObj = { ...layerData };
        legendObj.name = layerData.descret_legend ? "" : "rapid response";
        legendObj.parameter = layerData.descret_legend ? "" : layerData?.name;
        legendObj.measure = layerData?.units;
        legendObj.dataSource = layerData.descret_legend
          ? ""
          : activeStep === 0 || activeStep === 1
          ? selectedRainfallDataSource?.value
          : activeStep === 2
          ? selectedFloodDataSource?.value
          : "Disaster Exposure";
        legendObj.isCompare = false;
        legendObj.id = layerData.descret_legend
          ? layerData?.palette[0]?.name
          : layerData?.datasource;
        legendObj.dataSource_id = "";
        legendObj.parameter_slug = "slug";
        legendObj.fromMapPreferences = false;
        legendObj.descret_legend = layerData.descret_legend || false;
        legendObj.startDate = startDate || null;
        legendObj.endDate = endDate || null;

        legendObj.add = true;
        updateLegend.push(legendObj);

        setLegend(updateLegend);

        if (layerRef?.current?.length) {
          layerRef.current.map((i, index) => {
            removeLayers(layerRef.current[index]);
            return null;
          });
        }

        if (compareRef?.current?.length) {
          compareRef.current.map((i, index) => {
            removeLayers(compareRef.current[index]);
            return null;
          });
        }

        // adding the layer when we click on applied layers
        if (!_.isEmpty(currentParamRef) && currentParamRef?.current !== null) {
          addLayers(currentParamRef);
        }
      }
    }
    setOpaci(mapRef.current[0]);
  }, [layerData]);

  // for compare layers when we click on apply layer button
  useEffect(() => {
    if (selectedCompareIndicator?.length && wrap !== null) {
      const currentParamRef =
        compareRef.current[selectedCompareIndicator[0]?.compareParamIndex];

      if (currentParamRef && currentParamRef.current !== null) {
        currentParamRef.current?.eachLayer((layer) => {
          if (selectedCompareIndicator[0]?.map_url) {
            layer.setUrl(selectedCompareIndicator[0]?.map_url);
            setMapUrl({
              name: "",
              url: selectedCompareIndicator[0]?.map_url,
              layer,
            });
            const updatedIndexLayer = _.cloneDeep(indexLayers);
            updatedIndexLayer.push({ isCompare: false, layer });
            setIndexLayers(updatedIndexLayer);
          }
        });

        const updateLegend = [...comparelegend];
        let legendObj = {};
        legendObj = { ...selectedCompareIndicator[0] };
        legendObj.name = selectedCompareIndicator[0].descret_legend
          ? ""
          : "rapid response";
        legendObj.parameter = selectedCompareIndicator[0].descret_legend
          ? ""
          : selectedCompareIndicator[0]?.name;
        legendObj.measure = selectedCompareIndicator[0]?.units;
        legendObj.dataSource = selectedCompareIndicator[0].descret_legend
          ? ""
          : activeStep === 0 || activeStep === 1
          ? selectedRainfallDataSource?.value
          : activeStep === 2
          ? selectedFloodDataSource?.value
          : "Disaster Exposure";
        legendObj.isCompare = true;
        legendObj.id = selectedCompareIndicator[0].descret_legend
          ? selectedCompareIndicator[0]?.palette[0]?.name
          : selectedCompareIndicator[0]?.datasource;
        legendObj.dataSource_id = "";
        legendObj.parameter_slug = "slug";
        legendObj.fromMapPreferences = false;
        legendObj.descret_legend =
          selectedCompareIndicator[0].descret_legend || false;
        legendObj.startDate = startDate || null;
        legendObj.endDate = endDate || null;

        legendObj.add = true;
        updateLegend.push(legendObj);

        setcompareLegend(updateLegend);

        // adding the layer when we click on applied layers
        if (!_.isEmpty(currentParamRef) && currentParamRef?.current !== null) {
          addLayers(currentParamRef);
        }
      }
    }
    setOpaci(mapRef.current[0]);
  }, [selectedCompareIndicator]);

  //  function to remove the un selected check box in map-preferences for boundry
  const removingSelectedBoundary = (currentData, previousData) => {
    const currentList = [];
    const previousList = [];
    currentData.map((eachData) => currentList.push(eachData.name));
    previousData.map((eachData) => previousList.push(eachData.name));
    previousList.map((eachValue) => {
      if (!currentList.includes(eachValue)) {
        const selectedLayer = mapPreferences[eachValue];
        selectedLayer?.layer?.current?.clearLayers();
      }
      return null;
    });
  };

  const onCallingBoundryApi = (data, jsondata) => {
    if (data.length === jsondata.length && selectedLocation.country?.label) {
      if (data[data?.length - 1].name === "country") {
        dispatch(
          countryBoundaryRequest({
            data: {
              country_name: selectedLocation?.country?.label,
            },
          }),
        );
      }
      // const isStateAvailable = data.find((item) => item.name === "regions");
      if (data[data?.length - 1].name === "regions") {
        dispatch(
          stateBoundaryRequest({
            data: {
              country_name: selectedLocation?.country?.label,
              state_name:
                selectedLocation?.state === ""
                  ? t("DASHBOARD.ALL_REGIONS")
                  : selectedLocation?.state?.label,
            },
            isState: true,
          }),
        );
      }
    }
  };

  const getMapPreferencesGeojsonData = (geoJsonList) => {
    geoJsonList.length && onCallingBoundryApi(geoJsonList, geoJsonData);
    const data = JSON.parse(JSON.stringify(geoJsonList));
    geoJsonList.length < geoJsonData.length &&
      removingSelectedBoundary(geoJsonList, geoJsonData);
    setGeoJsonData(data);
  };

  //  removing map-preference layer when country is changed
  useEffect(() => {
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
  }, [selectedLocation?.country]);

  // ! rendering compare param layer
  const renderCompareParamLayer = (paramList) => {
    if (paramList && paramList?.length) {
      return paramList?.map((item, index) => {
        return (
          <>
            {/* TODO: need to create separate component */}
            <LayerGroup
              ref={compareRef.current[index]}
              key={item.name}
              name={item.name}
            >
              <TileLayer
                key={item.name}
                name={item.name}
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url=""
                id={item.name}
                options={{ tileSize: 256 }}
              />
            </LayerGroup>
          </>
        );
      });
    }
  };

  const renderParamLayer = (paramList) => {
    if (paramList && paramList?.length) {
      return paramList?.map((item, index) => {
        return (
          <>
            {/* TODO: need to create separate component */}
            <LayerGroup
              ref={layerRef.current[index]}
              key={item.name}
              name={item.name}
            >
              <TileLayer
                key={item.name}
                name={item.name}
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url=""
                id={item.name}
                options={{ tileSize: 256 }}
              />
            </LayerGroup>
          </>
        );
      });
    }
  };

  function addOpacitySlider() {
    if (indexLayers?.length) {
      L.Control.OpacitySlider = L.Control.extend({
        sliding: false,
        updateLayerOpacity(opacity) {
          const lastIndex = indexLayers.length - 1;
          if (indexLayers?.length >= 2) {
            const secondLastIndex = indexLayers.length - 2;
            if (
              indexLayers[lastIndex]?.isCompare &&
              indexLayers[secondLastIndex]?.isCompare
            ) {
              indexLayers.forEach((item, i) => {
                if (i === lastIndex || i === secondLastIndex)
                  item?.layer.setOpacity(opacity / 100);
              });
            } else {
              indexLayers.forEach((item, i) => {
                if (i === lastIndex) {
                  item?.layer.setOpacity(opacity / 100);
                }
              });
            }
          } else {
            indexLayers.forEach((item, i) => {
              if (i === lastIndex) {
                item?.layer?.setOpacity(opacity / 100);
              }
            });
          }
        },
        onAdd(map) {
          if (opacityControl) {
            map?.removeControl(opacityControl);
          }
          const container = L.DomUtil.create("div", "rpOpacityContainer");
          container.innerHTML =
            '<div id=layer-opacity-control><input type="range" min="1" max="100" value="100" defaultValue="100" class="opacityslider"></div>';
          if (!L.Browser.touch) {
            L.DomEvent.disableClickPropagation(container);
            L.DomEvent.on(
              container,
              "mousewheel",
              L?.DomEvent?.stopPropagation,
            );
          } else {
            L.DomEvent.on(
              container,
              "mousedrag click",
              L?.DomEvent?.stopPropagation,
            );
          }
          const slider = container.querySelector(".opacityslider");
          slider.onclick = (e) => {
            // eslint-disable-next-line react/no-this-in-sfc
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousedown = (e) => {
            // eslint-disable-next-line react/no-this-in-sfc
            this.sliding = true;
            // eslint-disable-next-line react/no-this-in-sfc
            this.updateLayerOpacity(e.target.value);
          };
          slider.ontouchmove = (e) => {
            // eslint-disable-next-line react/no-this-in-sfc
            this.sliding = true;
            // eslint-disable-next-line react/no-this-in-sfc
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousemove = (e) => {
            e?.stopPropagation();
            // eslint-disable-next-line react/no-this-in-sfc
            if (this.sliding) this.updateLayerOpacity(e.target.value);
          };
          slider.onmouseup = () => {
            // eslint-disable-next-line react/no-this-in-sfc
            this.sliding = false;
          };
          return container;
        },
      });
      L.control.opacitySlider = (opts) => {
        return new L.Control.OpacitySlider(opts);
      };
      const customOpacityControl = L.control.opacitySlider({
        position: "topright",
      });

      // eslint-disable-next-line no-undef, no-underscore-dangle
      if (!_.isEmpty(mapes) && !_.isEmpty(mapes?._layers)) {
        customOpacityControl?.addTo(mapes);
      }
      setOpacityControl(customOpacityControl);
    }
  }

  const handleMapModel = () => {
    setIsOpenMapModel(!isOpenMapModel);
  };

  const handleCompareModal = () => {
    setIsCompareOpen(!isCompareOpen);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (!_.isEmpty(indexLayers)) {
      resetOpacity(indexLayers);
      addOpacitySlider();
      if (indexLayers?.length) {
        indexLayers.map((l) => l?.layer?.bringToFront());
      }
    }
  }, [indexLayers]);

  const handleEachFeatureChange = (countryData, locationType, feat, layer) => {
    const selectedCountryData = GEOJSON_COUNTRY_DATA?.find(
      (item) => item.selectedCountry === countryData?.label,
    );

    if (["major_rivers", "minor_rivers"].includes(locationType)) {
      const labelprop = feat?.properties?.Name;

      if (labelprop)
        layer.bindTooltip(labelprop, {
          sticky: true,
          direction: "auto",
        });
    } else {
      const labelprop = selectedCountryData
        ? selectedCountryData[locationType]
        : "";

      if (labelprop && feat.properties[labelprop])
        layer.bindTooltip(feat.properties[labelprop], {
          sticky: true,
          direction: "auto",
        });
    }
  };

  const handleBasinFeature = (feat, layer) => {
    const labelprop = feat?.properties?.WMOBB_BASI;

    if (labelprop) {
      layer.bindTooltip(labelprop, {
        sticky: true,
        direction: "auto",
      });
    }
  };

  const handleHydroFeatureChange = (feat, layer) => {
    const labelprop = feat?.properties?.Name ? feat?.properties?.Name : "";
    if (labelprop)
      layer.bindTooltip(labelprop, {
        sticky: true,
        direction: "auto",
      });
  };

  const myIcon = L.divIcon({
    className: "custom-div-icon",
    html: "<div style='background-color:red; color:red; width: 8px; height: 8px; border-radius: 50%;'></div>",
  });

  const pointToLayer = (feature, latlng) => {
    return L.marker(latlng, { icon: myIcon });
  };

  const onHandleCropPredectionDefaultBoundry = () => {
    setIsCallBoundary(false);
    dispatch(
      stateBoundaryRequest({
        data: {
          country_name: selectedLocation?.country?.label,
          state_name:
            selectedLocation?.state === ""
              ? t("DASHBOARD.ALL_REGIONS")
              : selectedLocation?.state?.label,
        },
        isState: true,
      }),
    );
  };

  useEffect(() => {
    if (adminRadio === "admin") {
      if (selectedLocation?.state) {
        setLoaderLatLng({
          lat: selectedLocation?.state?.latitude,
          lng: selectedLocation?.state?.longitude,
        });
      } else if (selectedLocation?.country) {
        setLoaderLatLng({
          lat: selectedLocation?.country?.latitude,
          lng: selectedLocation?.country?.longitude,
        });
      }
    } else {
      if (selectedLocation?.basin) {
        setLoaderLatLng({
          lat: selectedLocation?.basin?.latitude,
          lng: selectedLocation?.basin?.longitude,
        });
      }
    }
  }, [selectedLocation]);

  //   function to set region
  const handleSelectedRegion = (region) => {
    const mapLayerData = [];
    const mapData = JSON.parse(JSON.stringify(region));
    if (mapData?.length === 1) {
      mapData.map((item) => {
        const x = Object.assign(item, {});

        x.latlngs = [item.latlngs[1], item.latlngs[0]];
        mapLayerData.push(x);
        return null;
      });

      setSelectedLocation((prev) => {
        return {
          ...prev,
          region: mapLayerData,
        };
      });
    }
  };

  return (
    <>
      <MapContainer
        center={position}
        zoom={zooms}
        scrollWheelZoom
        zoomControl={false}
        className="map-container rp-map-container"
        ref={setMapReference}
      >
        {loadingMap ? (
          <Marker
            position={[loaderLatLng?.lat, loaderLatLng?.lng]}
            icon={LoaderIcon}
          />
        ) : (
          ""
        )}
        <ChangeView center={position} zoom={zooms} />
        <ZoomControl position="topright" className="zoommap" />
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Street">
            <TileLayer
              options={{ tileSize: 256 }}
              attribution={myConst.TITLE_LAYER_ATTRIBUTE}
              url={myConst.TITLE_LAYER_URL}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite">
            <TileLayer
              url={myConst.SATELLITE_TILE_LAYER_URL}
              options={{ tileSize: 256 }}
              attribution={myConst.SATELLITE_TILE_LAYER_ATTRIBUTE}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <GeoJSON
          key={MAP_PREFERENCES_LABELS.COUNTRY}
          ref={mapPreferences.country.layer}
          style={{
            color: mapPreferences.country.color,
            fillColor: "#0000",
            weight: 1,
          }}
          onEachFeature={(feat, layer) =>
            handleEachFeatureChange(
              mapPreferences.country.country,
              "selectedCountry",
              feat,
              layer,
            )
          }
        />
        <GeoJSON
          key={MAP_PREFERENCES_LABELS.STATES}
          ref={mapPreferences.regions.layer}
          style={{
            color: mapPreferences.regions.color,
            fillColor: "#0000",
            weight: 1.6,
          }}
          onEachFeature={(feat, layer) =>
            handleEachFeatureChange(
              mapPreferences.regions.country,
              "states_or_provinces",
              feat,
              layer,
            )
          }
        />
        <GeoJSON
          key={MAP_PREFERENCES_LABELS.RIVERS}
          ref={mapPreferences.rivers_or_reservoirs.layer}
          style={{
            color: mapPreferences.rivers_or_reservoirs.color,
            fillColor: "#0000",
            weight: 1,
          }}
        />
        <GeoJSON
          key={MAP_PREFERENCES_LABELS.BASINS}
          ref={mapPreferences.basins.layer}
          style={{
            color: mapPreferences.basins.color,
            fillColor: "#0000",
            weight: 1,
          }}
          onEachFeature={(feat, layer) => handleBasinFeature(feat, layer)}
        />
        <GeoJSON
          key={MAP_PREFERENCES_LABELS.SUB_BASIN}
          ref={mapPreferences.sub_basins.layer}
          style={{
            color: mapPreferences.sub_basins.color,
            fillColor: "#0000",
            weight: 1,
          }}
          onEachFeature={(feat, layer) => handleBasinFeature(feat, layer)}
        />
        <GeoJSON
          key={MAP_PREFERENCES_LABELS.AWS}
          ref={mapPreferences.automatic_weather_station.layer}
          style={{
            color: mapPreferences.automatic_weather_station.color,
            fillColor: "#0000",
            weight: 1,
          }}
        />
        <GeoJSON
          key={MAP_PREFERENCES_LABELS.MAJOR_RIVERS}
          ref={mapPreferences.major_rivers.layer}
          style={{
            color: mapPreferences.major_rivers.color,
            fillColor: "#0000",
            weight: 1,
          }}
          onEachFeature={(feat, layer) =>
            handleEachFeatureChange(
              mapPreferences.major_rivers.country,
              "major_rivers",
              feat,
              layer,
            )
          }
        />
        <GeoJSON
          key={MAP_PREFERENCES_LABELS.MINOR_RIVERS}
          ref={mapPreferences.minor_rivers.layer}
          style={{
            color: mapPreferences.minor_rivers.color,
            fillColor: "#0000",
            weight: 1,
          }}
          onEachFeature={(feat, layer) =>
            handleEachFeatureChange(
              mapPreferences.minor_rivers.country,
              "minor_rivers",
              feat,
              layer,
            )
          }
        />
        <GeoJSON
          key={MAP_PREFERENCES_LABELS.HYDRO_STATIONS}
          ref={mapPreferences.hydro_stations.layer}
          style={{
            color: mapPreferences.hydro_stations.color,
            fillColor: "#0000",
            weight: 1,
          }}
          onEachFeature={(feat, layer) => {
            handleHydroFeatureChange(feat, layer);
          }}
          pointToLayer={pointToLayer}
        />

        <div className="logoContainer1">
          <Button className="map-preferences-button" onClick={handleMapModel}>
            <img src={Maprefer} alt="map preference" width={15} />
          </Button>
        </div>
        <div className="logoContainer1">
          <Button
            className="rp-compare-button"
            onClick={handleCompareModal}
            title="Compare Layers"
            disabled={
              loadingMap ||
              (selectedMapPreferLegendData?.length &&
                selectedMapPreferLegendData?.some((i) => i.add)) ||
              !compareIndicatorList?.length
            }
            data-tip
            data-for="compare-btn"
          >
            <img src={CompareIcon} alt="Compare" width={15} />
            {selectedMapPreferLegendData?.length &&
            selectedMapPreferLegendData?.some((i) => i.add) ? (
              <ReactTooltip
                id="compare-btn"
                effect="float"
                place="bottom"
                arrowColor="#22ad7a"
                className="map-prefer-tooltip-st"
              >
                {t("MAP_PREFERENCES.INDICATOR_MESSAGES")}
              </ReactTooltip>
            ) : (
              ""
            )}
          </Button>
        </div>

        <LayersControl position="topleft">
          {renderParamLayer(indicatorList)}
        </LayersControl>
        <LayersControl position="topleft">
          {renderCompareParamLayer(compareIndicatorList)}
        </LayersControl>
        <LayersControl key="layer2" position="topleft">
          {mapPreferRefs?.current?.map((mapLayerRef) => {
            if (!_.isEmpty(mapLayerRef)) {
              return (
                <Overlay>
                  <LayerGroup ref={mapLayerRef.current.layer}>
                    <TileLayer
                      attribution='Weather from <a href="https://openweathermap.org/" alt="World Map and worldwide Weather Forecast online">OpenWeatherMap</a>'
                      url=""
                    />
                  </LayerGroup>
                </Overlay>
              );
            }
            return null;
          })}
        </LayersControl>
        {legend.length
          ? legend.map((item) => {
              return (
                <LayersControl
                  key="layer3"
                  position="topleft"
                  // key={item.datasource}
                >
                  {item?.descret_legend ? (
                    <DiscreteLegend
                      map={wrap.current}
                      add={item.add}
                      layer={item}
                      position="bottomleft"
                    />
                  ) : (
                    <CustomLegend
                      map={wrap.current}
                      add={item.add}
                      layer={item}
                      position="bottomright"
                    />
                  )}
                </LayersControl>
              );
            })
          : ""}
        {comparelegend.length
          ? comparelegend.map((item) => {
              return (
                <LayersControl
                  key="layer3"
                  position="topleft"
                  // key={item.datasource}
                >
                  {item?.descret_legend ? (
                    <DiscreteLegend
                      map={wrap.current}
                      add={item.add}
                      layer={item}
                      position="bottomleft"
                    />
                  ) : (
                    <CustomLegend
                      map={wrap.current}
                      add={item.add}
                      layer={item}
                      position="bottomright"
                    />
                  )}
                </LayersControl>
              );
            })
          : ""}
        {selectedMapPreferLegendData.length
          ? selectedMapPreferLegendData.map((item) => {
              return (
                <LayersControl key="layer4" position="topleft">
                  {item?.descret_legend ? (
                    <DiscreteLegend
                      map={wrap.current}
                      add={item.add}
                      layer={item}
                      position="bottomleft"
                    />
                  ) : (
                    <CustomLegend
                      map={wrap.current}
                      add={item.add}
                      layer={item}
                      position="bottomright"
                    />
                  )}
                </LayersControl>
              );
            })
          : ""}
        {!_.isEmpty(layerData) &&
        layerData?.name === "Accumulated Rainfall" &&
        activeStep >= 0 &&
        activeStep <= 1 ? (
          <DrawShape
            handleSelectedRegion={handleSelectedRegion}
            country={selectedLocation?.country}
            state={selectedLocation?.state}
            basin={selectedLocation.basin}
            mapLayers={mapLayers}
            setMapLayers={setMapLayers}
            isDataSourceChanged={isDataSourceChanged}
            setSelectedLocation={setSelectedLocation}
            setShowChart={setShowChart}
          />
        ) : (
          ""
        )}
      </MapContainer>
      {getBoundariesData?.length && (
        <MapPreferenceModal
          isToggle={isOpenMapModel}
          handleToggle={handleMapModel}
          fromCropPredection
          preferenceList={getBoundariesData}
          categories={mapPreferenceCategories}
          getMapPreferencesGeojsonData={getMapPreferencesGeojsonData}
          geoJsonData={geoJsonData}
          getMapPreferencesData={getMapPreferencesData}
          mapPreferencesAllData={mapPreferencesData}
          selectedParamLegendData={[...legend, ...comparelegend]}
          selectedLocation={selectedLocation}
          // isMapPerferGeoJsonLoading={mapPreferenceGeoJsonloading}
          setOpen={setOpen}
          adminRadio={adminRadio}
          onHandleCropPredectionDefaultBoundry={
            onHandleCropPredectionDefaultBoundry
          }
          isCallBoundary={isCallBoundary}
          isRapidResponse
        />
      )}
    </>
  );
}

export default React.memo(Filter);
