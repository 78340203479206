import React, { useEffect, useState } from "react";

import "leaflet-defaulticon-compatibility";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css"; // Re-uses images from ~leaflet package
import "leaflet-draw";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Circle, FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "../../../node_modules/leaflet-draw/dist/leaflet.draw.css";
import "../../../node_modules/leaflet/dist/leaflet.css";
import { showError } from "../../components/Common/Notification";
import {
  removeRegionTooltip,
  setRegionData,
} from "../../components/Common/Utils";
import { DRAW_SHAPE } from "../../constants";

function DrawShape(props) {
  const {
    handleSelectedRegion,
    country,
    state,
    mapLayers,
    setMapLayers,
    basin,
    isDataSourceChanged,
    setShowChart,
    setSelectedLocation,
  } = props;

  const { t } = useTranslation();
  const drawControlRef = React.useRef();
  const [editableFG, setEditableFG] = useState(null);

  const tempArray = [];

  useEffect(() => {
    if (mapLayers?.length) {
      const mapData = JSON.parse(JSON.stringify(mapLayers));
      handleSelectedRegion(mapData);
      setRegionData(mapData);
    }
  }, [mapLayers]);

  const removeLayers = () => {
    if (!_.isEmpty(editableFG)) {
      // here you have all the stored layers
      const drawnItems = editableFG?._layers;
      // if the number of layers is bigger than 1 then delete the first
      if (!_.isEmpty(drawnItems)) {
        Object.keys(drawnItems).forEach((layerid) => {
          const layer = drawnItems[layerid];
          editableFG.removeLayer(layer);
        });
        setMapLayers([]);
      }
    }
  };

  useEffect(() => {
    removeLayers(editableFG);
  }, [country, state, basin, isDataSourceChanged]);

  const onFeatureGroupReady = (reactFGref) => {
    if (reactFGref) {
      const drawnItems = reactFGref?._layers;
      const markerElements = document.getElementsByClassName("draw-marker");

      removeRegionTooltip(drawnItems, markerElements);

      Object.values(drawnItems)?.forEach((layer) => {
        if (!_.isEmpty(layer)) {
          if (layer?.hasOwnProperty("_latlng")) {
            const tooltipName = `(${layer.editing?._marker?._latlng.lat?.toFixed(
              2 || "",
            )},${layer.editing?._marker?._latlng.lng?.toFixed(2) || ""})`;
            layer.bindTooltip(tooltipName, {
              className: "draw-marker",
              permanent: true,
              direction: "top",
            });
          }
        }
      });
    }
    // store the ref for future access to content
    setEditableFG(reactFGref);
  };

  const onDelete = () => {
    setMapLayers([]);
    setShowChart(false);
    setSelectedLocation((prev) => {
      return {
        ...prev,
        region: [],
      };
    });
    return null;
  };

  useEffect(() => {
    if (editableFG) {
      // here you have all the stored layers
      const drawnItems = editableFG?._layers;
      if (!_.isEmpty(drawnItems)) {
        Object.keys(drawnItems).forEach((layerid, index) => {
          if (index === 0) {
            if (_.isEmpty(mapLayers)) {
              editableFG.removeLayer(drawnItems[layerid]);
            }
          }

          if (index < DRAW_SHAPE.MAXIMUM_MARKERS) {
            return null;
          } else {
            const layer = drawnItems[layerid];
            editableFG.removeLayer(layer);
            showError(t("DASHBOARD.PLEASE_REMOVE_MARKER"));
          }
        });
      }
    }
  }, [editableFG]);

  const onCreated = (e) => {
    const { layerType, layer } = e;
    if (layerType === "marker") {
      if (mapLayers.some((data) => data.layerType === "marker")) {
        // Remove the newly created marker
        editableFG.removeLayer(layer);
        return;
      }
      const latlang = [layer._latlng.lat, layer._latlng.lng];
      setMapLayers((data) => [
        ...data,
        {
          id: layer._leaflet_id,
          layerType,
          latlngs: [...latlang],
        },
      ]);
      tempArray.push(e);
      setEditableFG(tempArray);
    }
  };

  const onEdited = (e) => {
    const {
      layers: { _layers },
    } = e;
    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      if (!_.isEmpty(editing._marker._latlng)) {
        const cordinates = editing._marker._latlng;

        setMapLayers((layers) =>
          layers.map((l) =>
            l.id === _leaflet_id
              ? { ...l, latlngs: [cordinates.lat, cordinates.lng] }
              : l,
          ),
        );
      }
      return null;
    });
  };

  const handleMarker = () => {};
  const onMounted = (e) => {
    drawControlRef.current = e;
  };

  return (
    <div>
      <FeatureGroup
        ref={(featureGroupRef) => {
          onFeatureGroupReady(featureGroupRef);
        }}
      >
        <EditControl
          position="topright"
          onCreated={onCreated}
          onDeleted={onDelete}
          onMounted={onMounted}
          onEdited={onEdited}
          draw={{
            polygon: false,
            polyline: false,
            rectangle: false,
            circle: false,
            circlemarker: false,
          }}
          marker={handleMarker}
        />
        <Circle center={[51.51, -0.06]} radius={200} />
      </FeatureGroup>
    </div>
  );
}

export default DrawShape;
