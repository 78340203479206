import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Card } from "reactstrap";
import { Formik, Form } from "formik";
import _ from "lodash";
import BackButton from "../../assest/img/back-button.svg";
import InvitePeopleModal from "./InviteSurveyour";
import {
  componentsListRequest,
  surveyConfigurationRequest,
  surveyConfigurationSuccess,
} from "../../store/odk/odkActions";
import {
  userOdkInfoUpdateRequest,
  getLoginUserDetailsRequest,
} from "../../store/user/action";
import { showError, showSuccess } from "../../components/Common/Notification";
import { getToken, getODKAccessToken, setODKAccessToken } from "../../helpers";
import { CLIENT_ID, SCOPE, discoveryUrl, API_KEY } from "../../constants/index";

import {
  RenderReactSelectInputField,
  RenderDateField,
} from "../../components/Common/RenderFormikFields";
import Loader from "../../components/Common/Loader";

function Index() {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState({
    year: "",
    component: "",
  });
  const [isOpenInviteModal, setIsOpenInviteModal] = useState(false);
  const [componentOptions, setComponentOptions] = useState([]);
  const [signedInUserDetails, setSignedInUserDetails] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [folderId, setFolderId] = useState(null);
  const [tokenClient, setTokenClient] = useState({});
  const [accessToken, setAccessToken] = useState("");
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isConfigure, setIsConfigure] = useState(false);
  const [excelUrl, setExcelUrl] = useState("");
  // const [showLoadingText, setShowLoadingText] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [configurationSelectedDate, setConfigurationSelectedDate] =
    useState(null);
  const [xmlfileUrl, setXmlFileUrl] = useState("");

  const loggInUserData = JSON.parse(
    localStorage.getItem("loggedInUserDetails"),
  );

  const nextProps = useSelector((state) => ({
    componentsList: state?.odk?.componentsListData,
    componentsByIdRequesting: state?.odk?.componentsByIdRequesting,
    componentsByIdData: state?.odk?.componentsByIdData,
    submitConfiguration: state?.odk?.submitConfiguration,
    isSubmitConfigurationRequesting:
      state?.odk?.isSubmitConfigurationRequesting,
    isSurveyConfigurationRequesting:
      state?.odk?.isSurveyConfigurationRequesting,
    surveyConfigurationData: state?.odk?.surveyConfigurationData,
    surveyConfigurationDataError: state?.odk?.surveyConfigurationDataError,

    updateUserOdkInfoData: state.User.updateUserOdkInfoData,
    updateUserOdkInfoError: state.User.updateUserOdkInfoError,
    updateUserOdkInfoRequesting: state.User.updateUserOdkInfoRequesting,
    loginUserDetails: state.User.loginUserDetails,
  }));

  useEffect(() => {
    if (getODKAccessToken() && isTokenExpired) {
      setLoading(false);
      // setShowLoadingText(false);
      // showError(t("ODK.TOKEN_EXPIRED"));
      if (userDetails?.google_email)
        tokenClient?.requestAccessToken({
          prompt: "",
          hint: userDetails?.google_email || "",
        });
      else {
        tokenClient?.requestAccessToken({ prompt: "consent" });
      }
    }
  }, [isTokenExpired]);

  const handleClientLoad = async () => {
    // initializing token token Client
    const client = window?.google?.accounts?.oauth2?.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPE,
      discoveryDocs: discoveryUrl,
      hint: userDetails?.google_email || "",
      prompt: userDetails?.google_email ? "" : "consent",
      callback: (tokenResponse) => {
        setIsTokenExpired(false);
        if (tokenResponse && tokenResponse?.access_token) {
          setAccessToken(tokenResponse?.access_token);
          setODKAccessToken(tokenResponse?.access_token);
        }
        // we now have access to a live token to use for any google api
      },
      error_callback: (err) => {
        if (err.type === "popup_failed_to_open") {
          // The popup window is failed to open
          if (err?.message) {
            const message = err.message;
            showError(t("ODK.POPUP_UNBLOCK", { message }));
            history("/surveyConfigurationDashboard");
          }
        } else if (err.type === "popup_closed") {
          // The popup window is closed before an OAuth response is returned
        }
      },
    });
    setTokenClient(client);
  };

  useEffect(() => {
    setInitialData({});
    setExcelUrl("");
    setXmlFileUrl("");
    setLoading(false);
    setIsLoading(false);
    dispatch(componentsListRequest());
    if (getToken()) {
      // google authorization
      dispatch(getLoginUserDetailsRequest({}));
      let signedInUser = localStorage.getItem("googleAccountDetails");
      if (signedInUser) {
        signedInUser = JSON.parse(signedInUser);
        setSignedInUserDetails(signedInUser);
      } else history("/surveyConfigurationDashboard");
    } else {
      history("/home");
    }
  }, []);

  useEffect(() => {
    if (nextProps.loginUserDetails) {
      setFolderId(nextProps?.loginUserDetails?.user_profile?.drive_folder_id);
      setUserDetails({
        ...nextProps?.loginUserDetails?.user_profile,
        id: nextProps?.loginUserDetails?.user?.id,
      });
      handleClientLoad();
    }
  }, [nextProps.loginUserDetails]);

  useEffect(() => {
    if (nextProps?.componentsList?.data?.result?.length) {
      let arr = [];
      arr = nextProps?.componentsList?.data?.result?.map((item) => {
        const obj = { ...item };
        obj.value = item.name;
        obj.label = item.name;
        return obj;
      });
      if (arr?.length) setComponentOptions(arr);
    }
  }, [nextProps?.componentsList]);

  // create file url
  const createFileUrl = (name, files) => {
    setExcelUrl(`https://docs.google.com/spreadsheets/d/${files}/edit#gid=0`);
  };

  const formTitle = () => {
    const driveFolderName =
      loggInUserData?.user_details?.[1]?.organization_name;
    if (driveFolderName) {
      return driveFolderName;
    }
  };

  const createPermissions = async (name, fileId, access_Token) => {
    await fetch(
      `https://www.googleapis.com/drive/v3/files/${fileId}/permissions?key=${API_KEY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_Token}`,
        },
        body: JSON.stringify({
          type: "anyone",
          role: "writer",
        }),
      },
    )
      .then((res) => res.json())
      .then(
        (response) => {
          if (response?.error) {
            if (
              response?.error.code === 401 &&
              response?.error?.status === "UNAUTHENTICATED" &&
              getODKAccessToken()
            ) {
              setIsTokenExpired(true);
            } else setIsTokenExpired(false);
          }
          // Handle the results here (response.result has the parsed body).
        },
        // function (err) { },
      );
  };

  const createFolder = async (signedInUser, email, access_token) => {
    setLoading(true);
    // setShowLoadingText(true);
    await fetch("https://www.googleapis.com/drive/v3/files", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify({
        // title: formTitle(),
        name: formTitle(),
        mimeType: "application/vnd.google-apps.folder",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.error) throw data?.error;
        if (email && _.isEmpty(folderId)) {
          dispatch(
            userOdkInfoUpdateRequest({
              user_id: userDetails?.id,
              drive_folder_id: data.id,
              google_email: email,
            }),
          );
        }
      })
      .catch((err) => {
        if (
          err.code === 401 &&
          err?.status === "UNAUTHENTICATED" &&
          getODKAccessToken()
        ) {
          setIsTokenExpired(true);
        } else setIsTokenExpired(false);
      });
  };

  // create excel sheets
  const createExcelFiles = (searchTerm, folder_Id, name, access_Token) => {
    const fileMetadata = {
      name: `${searchTerm}`,
      mimeType: "application/vnd.google-apps.spreadsheet",
      // 'parents': [folderId],//some folderId of a folder under which to create the new folder
      // 'allowFileDiscovery': true,
      parents: [folder_Id], // some folderId of a folder under which to create the new folder
    };
    fetch("https://www.googleapis.com/drive/v3/files", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_Token}`,
      },
      body: JSON.stringify(fileMetadata),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.error) throw response?.error;
        // const file = response.result;
        setIsConfigure(false);
        createFileUrl(name, response.id);
        createPermissions(name, response.id, accessToken);
      })
      .catch((err) => {
        setLoading(false);
        // setShowLoadingText(false);
        if (
          err.code === 401 &&
          err?.status === "UNAUTHENTICATED" &&
          getODKAccessToken()
        ) {
          setIsTokenExpired(true);
        } else {
          setIsTokenExpired(false);
          showError(t("ODK.FILE_NOT_FOUND"));
        }
      });
  };

  // filter files
  const filerFilesList = (responseData, searchTerm, name) => {
    responseData?.filter((item) => {
      if (item.name === searchTerm) {
        createFileUrl(name, item.id);
        return false;
      } else {
        return false;
      }
    });
  };

  // get list of files in folder
  const listFiles = async (searchTerm, folder_Id, name, access_Token) => {
    const query = `name='${searchTerm}' and '${folder_Id}' in parents`;
    await fetch(
      `https://www.googleapis.com/drive/v3/files?key=${API_KEY}&q=${query}&fields=files(name,id)`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_Token}`,
        },
      },
    )
      .then((res) => res.json())
      .then((response) => {
        if (response?.error) {
          throw response?.error;
        }
        if (response?.files.length === 0) {
          setLoading(true);
          // setShowLoadingText(true);
          createExcelFiles(searchTerm, folderId, name, access_Token);
        } else {
          setLoading(true);
          // setShowLoadingText(true);
          filerFilesList(response.result.files, searchTerm, name, access_Token);
        }
      })
      .catch((err) => {
        const errorData = err?.response;
        setLoading(false);
        // setShowLoadingText(false);
        if (
          err.code === 401 &&
          err?.status === "UNAUTHENTICATED" &&
          getODKAccessToken()
        ) {
          setIsTokenExpired(true);
        } else {
          setIsTokenExpired(false);
          if (err?.response) showError(errorData?.error.message);
          else showError(t("ODK.SURVEY_CONFIG_EXISTED"));
        }
      });
  };

  // create files
  const createFiles = (folder_Id, name, access_token) => {
    listFiles(
      `${name}_${configurationSelectedDate}`,
      folder_Id,
      name,
      access_token,
    );
  };

  const handleSignOutClick = () => {
    history(-1);
  };

  const handleConfigure = () => {
    if (signedInUserDetails) {
      const access_Token = getODKAccessToken();
      if (access_Token) {
        if (!folderId) {
          createFolder(
            signedInUserDetails,
            signedInUserDetails.email,
            accessToken,
          );
        } else {
          if (
            folderId &&
            userDetails?.google_email &&
            signedInUserDetails?.email === userDetails?.google_email
          ) {
            createFiles(folderId, selectedComponent?.name, access_Token);
          }
        }
      } else {
        setLoading(false);
        // setShowLoadingText(false);
        if (userDetails?.google_email) {
          tokenClient?.requestAccessToken({
            prompt: "",
            hint: userDetails?.google_email || "",
          });
        } else {
          tokenClient?.requestAccessToken({ prompt: "consent" });
          tokenClient.callback = (res) => {
            setIsTokenExpired(false);
            if (res && res?.access_token) {
              setAccessToken(res?.access_token);
              setODKAccessToken(res?.access_token);
              if (folderId === null) {
                createFolder(
                  signedInUserDetails,
                  signedInUserDetails?.email,
                  res?.access_token,
                );
              } else {
                if (
                  folderId &&
                  userDetails?.google_email &&
                  signedInUserDetails?.email === userDetails?.google_email
                ) {
                  createFiles(
                    folderId,
                    selectedComponent?.name,
                    res?.access_token,
                  );
                }
              }
            }
          };
        }
      }
    } else {
      showError(t("ODK.AUTHENTICATE"));
    }
  };

  // upload xml files
  const uploadXMLFiles = (xmlData, access_Token) => {
    if (xmlData) {
      const fileMetadata = {
        name: `${selectedComponent?.name}_${configurationSelectedDate}.xml`,
        parents: [folderId],
        mimeType: "text/xml",
      }; // Please set filename
      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" }),
      );
      form.append("file", xmlData);
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id,name",
        {
          method: "POST",
          headers: new Headers({ Authorization: `Bearer ${access_Token}` }),
          body: form,
        },
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res) {
            if (res?.error) throw res?.error;
            await createPermissions(res.name, res.id, access_Token);
            setLoading(false);
            // setShowLoadingText(false);
            history("/surveyConfigurationDashboard");
            const user_selected_component = selectedComponent?.name;
            const user_selected_year = configurationSelectedDate;
            showSuccess(
              t("ODK.SURVEY_CONFIG_SUCCESS", {
                user_selected_component,
                user_selected_year,
              }),
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          // setShowLoadingText(false);
          if (
            err.code === 401 &&
            err?.status === "UNAUTHENTICATED" &&
            getODKAccessToken()
          ) {
            setIsTokenExpired(true);
          } else setIsTokenExpired(false);
        });
    }
  };

  useEffect(() => {
    if (nextProps.updateUserOdkInfoData) {
      dispatch(getLoginUserDetailsRequest());
    } else if (nextProps.updateUserOdkInfoError) {
      setLoading(false);
      // showError()
    }
  }, [nextProps.updateUserOdkInfoData, nextProps.updateUserOdkInfoError]);

  useEffect(() => {
    if (
      folderId &&
      !_.isEmpty(signedInUserDetails) &&
      !_.isEmpty(userDetails?.google_email) &&
      nextProps?.componentsList &&
      configurationSelectedDate
    ) {
      if (signedInUserDetails.email === userDetails?.google_email) {
        const odkAccessToken = getODKAccessToken();
        if (isConfigure)
          createFiles(folderId, selectedComponent?.name, odkAccessToken);
      } else {
        const user_google_email = userDetails?.google_email;
        showError(t("ODK.EMAIL_VERIFICATION", { user_google_email }));

        handleSignOutClick();
      }
    }
  }, [
    folderId,
    signedInUserDetails,
    nextProps.componentsList,
    configurationSelectedDate,
    isConfigure,
  ]);

  useEffect(() => {
    if (
      excelUrl &&
      userDetails?.id &&
      selectedComponent &&
      configurationSelectedDate
    ) {
      const finalPayload = {
        user_id: userDetails?.id?.toString(),
        component_id: selectedComponent?.id,
        year: configurationSelectedDate,
        excel_url: excelUrl,
      };
      dispatch(surveyConfigurationRequest(finalPayload));
    }
  }, [excelUrl, userDetails, selectedComponent, configurationSelectedDate]);

  useEffect(() => {
    if (nextProps.isSurveyConfigurationRequesting) {
      setLoading(true);
    } else if (nextProps.surveyConfigurationData) {
      setExcelUrl("");
      if (nextProps.surveyConfigurationData?.data) {
        setXmlFileUrl(nextProps.surveyConfigurationData.data);
        dispatch(surveyConfigurationSuccess({}));
      }
    } else if (nextProps.surveyConfigurationDataError) {
      setLoading(false);
      // setShowLoadingText(false);
      setIsLoading(false);
    }
  }, [
    nextProps.isSurveyConfigurationRequesting,
    nextProps.surveyConfigurationData,
    nextProps.surveyConfigurationDataError,
  ]);

  useEffect(() => {
    if (xmlfileUrl !== "") {
      (async () => {
        const xmlData = await fetch(xmlfileUrl).then((e) => {
          return e.blob();
        });
        if (xmlData) {
          const odkAccessToken = getODKAccessToken();
          uploadXMLFiles(xmlData, odkAccessToken);
        } else {
          setLoading(false);
          // setShowLoadingText(false);
          showError(t("ODK.UNABLE_GET_XML"));
        }
      })();
    }
  }, [xmlfileUrl]);

  return (
    <div className="back-office-main-page">
      {loading && !isLoading && (
        <Loader message="Please wait! Configuration under process." indicator />
      )}
      {isLoading && !loading && (
        <Loader message="Please wait! Configuration under process." indicator />
      )}
      {isOpenInviteModal && (
        <InvitePeopleModal
          modal={isOpenInviteModal}
          toggle={setIsOpenInviteModal}
          tokenClient={tokenClient}
          signedInUserDetails={signedInUserDetails}
          isTokenExpired={isTokenExpired}
          setIsTokenExpired={setIsTokenExpired}
          userDetails={userDetails}
        />
      )}
      <Row>
        <Col lg={10}>
          <div className="pb-1 back-office-main-header back-office-center">
            <img
              src={BackButton}
              alt="back-button"
              onClick={() => history("/surveyConfigurationDashboard")}
              onKeyUp={() => {}}
              className="pointer"
            />
            <h2 className="discription-text pl-3 heading-text-user-guide">
              {t("ODK.SURVEY_CONFIG")}
            </h2>
          </div>
        </Col>
        {nextProps?.loginUserDetails?.user_profile?.drive_folder_id && (
          <Col lg={2} className="my-auto">
            <Button
              className="float-right top-right-button"
              onClick={() => setIsOpenInviteModal(true)}
            >
              {t("ODK.INVITE_PEOPLE")}
            </Button>
          </Col>
        )}
      </Row>

      <Card
        style={{
          boxShadow: "box-shadow: 0px 2px 4px rgba(80, 136, 198, 0.25)",
          height: "60vh",
        }}
        className="py-auto"
      >
        <Formik
          initialValues={initialData}
          enableReinitialize
          onSubmit={() => {
            handleConfigure();
          }}
        >
          {({
            setFieldValue,
            values,
            // errors,
            // handleBlur,
            // handleChange,
            // resetForm,
          }) => {
            return (
              <Form noValidate className="my-auto">
                <Row style={{ marginBottom: "30px" }}>
                  <Col
                    md={6}
                    lg={6}
                    className="filter-control-dp-md mx-auto survey-config"
                  >
                    <RenderDateField
                      name="year"
                      label={t("ODK.YEAR")}
                      className="h-auto"
                      minDate={new Date("2000-01-01T00:00:00Z")}
                      maxDate={new Date()}
                      format="YYYY"
                      values={values.dates}
                      onlyYearPicker
                      placeholder={t("ODK.SELECT_YEAR")}
                      onChange={(e) => {
                        setConfigurationSelectedDate(new Date(e).getFullYear());
                        setExcelUrl("");
                        setXmlFileUrl("");
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} className="filter-control-dp-md mx-auto">
                    <RenderReactSelectInputField
                      formInput
                      value={values?.component}
                      options={componentOptions}
                      onChange={(e) => {
                        setFieldValue(e);
                        setSelectedComponent(e);
                        setExcelUrl("");
                        setXmlFileUrl("");
                      }}
                      name="component"
                      label={t("ODK.COMPONENTS")}
                      placeholder={t("ODK.SELECT_COMPONENT")}
                      className="map-dash-select dash-select common-select-r"
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Button
                    type="submit"
                    className="back-office-ok-btn mx-auto"
                    disabled={
                      selectedComponent === null ||
                      configurationSelectedDate === null ||
                      configurationSelectedDate < 2000
                    }
                  >
                    {t("ODK.CONFIGURE")}
                  </Button>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Card>
    </div>
  );
}

export default Index;
