import BULLETIN_CONSTS from "./bulletinConsts";

// subscribe
export const subscribeBulletinRequest = (data) => ({
  type: BULLETIN_CONSTS.BULLETIN_SUBSCRIBE_REQUEST,
  payload: data,
});

export const subscribeBulletinSuccess = (response) => ({
  type: BULLETIN_CONSTS.BULLETIN_SUBSCRIBE_SUCCESS,
  payload: response,
});

export const subscribeBulletinFailed = (error) => ({
  type: BULLETIN_CONSTS.BULLETIN_SUBSCRIBE_FAILED,
  payload: error,
});

// preview
export const previewBulletinRequest = (payload) => ({
  type: BULLETIN_CONSTS.PREVIEW_BULLETIN_REQUEST,
  payload,
});

export const previewBulletinSuccess = (response) => ({
  type: BULLETIN_CONSTS.PREVIEW_BULLETIN_SUCCESS,
  payload: response,
});

export const previewBulletinFailed = (error) => ({
  type: BULLETIN_CONSTS.PREVIEW_BULLETIN_FAILED,
  payload: error,
});

// preview template

export const previewTemplateRequest = (payload) => ({
  type: BULLETIN_CONSTS.PREVIEW_TEMPLATE_REQUEST,
  payload,
});

export const previewTemplateSuccess = (response) => ({
  type: BULLETIN_CONSTS.PREVIEW_TEMPLATE_SUCCESS,
  payload: response,
});

export const previewTemplateFailed = (error) => ({
  type: BULLETIN_CONSTS.PREVIEW_TEMPLATE_FAILED,
  payload: error,
});

// delete

export const bulletinDeleteRequest = (payload) => ({
  type: BULLETIN_CONSTS.BULLETIN_DELETE_REQUEST,
  payload,
});

export const bulletinDeleteSuccess = (response) => ({
  type: BULLETIN_CONSTS.BULLETIN_DELETE_SUCCESS,
  payload: response,
});

export const bulletinDeleteFailed = (error) => ({
  type: BULLETIN_CONSTS.BULLETIN_DELETE_FAILED,
  payload: error,
});

// publish
export const bulletinPublishRequest = (payload) => ({
  type: BULLETIN_CONSTS.BULLETIN_PUBLISH_REQUEST,
  payload,
});

export const bulletinPublishSuccess = (response) => ({
  type: BULLETIN_CONSTS.BULLETIN_PUBLISH_SUCCESS,
  payload: response,
});

export const bulletinPublishFailed = (error) => ({
  type: BULLETIN_CONSTS.BULLETIN_PUBLISH_FAILED,
  payload: error,
});

// add template
export const addTemplateRequest = (data) => ({
  type: BULLETIN_CONSTS.ADD_TEMPLATE_REQUEST,
  payload: data,
});

export const addTemplateSuccess = (response) => ({
  type: BULLETIN_CONSTS.ADD_TEMPLATE_SUCCESS,
  payload: response,
});

export const addTemplateFailed = (error) => ({
  type: BULLETIN_CONSTS.ADD_TEMPLATE_FAILED,
  payload: error,
});

// ADD bulletin REQUEST
export const addBulletinRequest = (data) => ({
  type: BULLETIN_CONSTS.ADD_BULLETIN_REQUEST,
  payload: data,
});

export const addBulletinSuccess = (response) => ({
  type: BULLETIN_CONSTS.ADD_BULLETIN_SUCCESS,
  payload: response,
});

export const addBulletinFailed = (error) => ({
  type: BULLETIN_CONSTS.ADD_BULLETIN_FAILED,
  payload: error,
});
// get all bullletin
export const getAllBulletinRequest = (data) => ({
  type: BULLETIN_CONSTS.GET_ALL_BULLETIN_REQUEST,
  payload: data,
});

export const getAllBulletinSuccess = (response) => ({
  type: BULLETIN_CONSTS.GET_ALL_BULLETIN_SUCCESS,
  payload: response,
});

export const getAllBulletinFailed = (error) => ({
  type: BULLETIN_CONSTS.GET_ALL_BULLETIN_FAILED,
  payload: error,
});

// get all template list
export const getAllTemplateListRequest = () => ({
  type: BULLETIN_CONSTS.GET_ALL_TEMPLATE_LIST_REQUEST,
  payload: null,
});

export const getAllTemplateListSuccess = (response) => ({
  type: BULLETIN_CONSTS.GET_ALL_TEMPLATE_LIST_SUCCESS,
  payload: response,
});

export const getAllTemplateListFailed = (error) => ({
  type: BULLETIN_CONSTS.GET_ALL_TEMPLATE_LIST_FAILED,
  payload: error,
});

// UPDATE BULLETIN BUGS
export const updateBulletinRequest = (data) => ({
  type: BULLETIN_CONSTS.UPDATE_BULLETIN_REQUEST,
  payload: data,
});

export const updateBulletinSuccess = (response) => ({
  type: BULLETIN_CONSTS.UPDATE_BULLETIN_SUCCESS,
  payload: response,
});

export const updateBulletinFailed = (error) => ({
  type: BULLETIN_CONSTS.UPDATE_BULLETIN_FAILED,
  payload: error,
});

// get Template
export const getTemplateRequest = (data) => ({
  type: BULLETIN_CONSTS.GET_TEMPLATE_REQUEST,
  payload: data,
});

export const getTemplateSuccess = (response) => ({
  type: BULLETIN_CONSTS.GET_TEMPLATE_SUCCESS,
  payload: response,
});

export const getTemplateFailed = (error) => ({
  type: BULLETIN_CONSTS.GET_TEMPLATE_FAILED,
  payload: error,
});

// put Template
export const updateTemplateRequest = (data) => ({
  type: BULLETIN_CONSTS.UPDATE_TEMPLATE_REQUEST,
  payload: data,
});

export const updateTemplateSuccess = (response) => ({
  type: BULLETIN_CONSTS.UPDATE_TEMPLATE_SUCCESS,
  payload: response,
});

export const updateTemplateFailed = (error) => ({
  type: BULLETIN_CONSTS.UPDATE_TEMPLATE_FAILED,
  payload: error,
});

// Approve reject bulletin
export const approveRejectBulletinRequest = (data) => ({
  type: BULLETIN_CONSTS.APPROVE_REJECT_BULLETIN_REQUEST,
  payload: data,
});

export const approveRejectBulletinSuccess = (response) => ({
  type: BULLETIN_CONSTS.APPROVE_REJECT_BULLETIN_SUCCESS,
  payload: response,
});

export const approveRejectBulletinFailed = (error) => ({
  type: BULLETIN_CONSTS.APPROVE_REJECT_BULLETIN_FAILED,
  payload: error,
});

// deleteTEMPLATE
export const templateDeleteRequest = (payload) => ({
  type: BULLETIN_CONSTS.TEMPLATE_DELETE_REQUEST,
  payload,
});

export const templateDeleteSuccess = (response) => ({
  type: BULLETIN_CONSTS.TEMPLATE_DELETE_SUCCESS,
  payload: response,
});

export const templateDeleteFailed = (error) => ({
  type: BULLETIN_CONSTS.TEMPLATE_DELETE_FAILED,
  payload: error,
});
