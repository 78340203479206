import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { BiErrorCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { renderReactOptionsArraystate } from "../../helpers";
import OtherTools from "../../assest/img/other_tools.png";
import {
  climber,
  orderBy,
  LanguagesArray,
  climaapat,
  GENERAL_TOOLS,
  uploaderDocumentCardId,
} from "../../constants";
import Footer from "../../assest/img/Footer-2.png";

import {
  climberBannerRequest,
  climberPartnerRequest,
  climberAboutUsRequest,
  coreRequest,
  climberOtherToolsRequest,
  // climberFooterRequest,
} from "../../store/actions";
import ClimateAdaptModal from "./climateAdaptModal";

// lazy loading components
const ClimberFooter = lazy(() =>
  import("../../components/Common/ClimberFooter"),
);
const CarouselComponent = lazy(() =>
  import("../../components/Common/Carousel"),
);

function ClimberHome() {
  const [country, setCountry] = useState("");
  const [selectTool, setSelectedTool] = useState("");
  const [carouselBannerImages, setBannerImages] = useState([]);
  const [otherTools, setOtherTools] = useState([]);
  const [climateAdapturl, setClimateAdaptUrl] = useState("");
  const [selectedLanguage, setLanguage] = useState({});
  const [climateModal, setClimateModal] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* ------handling api data---------*/
  const nextProps = useSelector((state) => ({
    bannerImages: state.Climber,
    partnerImages: state.Climber?.climberPartnerData,
    aboutUs: state.Climber?.climberAboutUs,
    countriesList: state.Core,
    climberToolsLits: state.Climber?.climberOtherTools,
    awareTools: state.Aware?.awareToolsData,
  }));
  const history = useNavigate();
  function handleClick() {
    history("/othertools", { state: { country } });
  }

  useEffect(() => {
    setCountry("");

    dispatch(
      climberPartnerRequest({
        order: orderBy,
      }),
    );

    dispatch(
      coreRequest({
        location_type: "country",
      }),
    );
  }, []);
  useEffect(() => {
    if (nextProps?.awareTools?.length) {
      const countrySelct = JSON.parse(localStorage.getItem("selectedCountry"));
      setCountry(countrySelct);
      if (countrySelct) {
        dispatch(
          climberPartnerRequest({
            country_id: countrySelct?.value,
            order: orderBy,
          }),
        );
      }
    }
  }, [nextProps.awareTools]);
  const savingLanguageArray = () => {
    LanguagesArray.map((eachValue) => {
      if (eachValue.value === localStorage.getItem("i18nextLng")) {
        setLanguage(eachValue);
      }
      return null;
    });
  };
  useEffect(() => {
    if (localStorage.getItem("i18nextLng")) {
      dispatch(
        climberBannerRequest({
          country_id: "",
          system: climber,
          order: orderBy,
        }),
      );
      dispatch(
        climberAboutUsRequest({
          system: climber,
        }),
      );
      dispatch(
        climberOtherToolsRequest({
          order: GENERAL_TOOLS,
        }),
      );
    }

    savingLanguageArray();
  }, [localStorage.getItem("i18nextLng")]);

  const isBannerImages = useRef(true);
  useEffect(() => {
    if (isBannerImages.current) {
      isBannerImages.current = false;
      return;
    }
    if (nextProps.bannerImages.climberBannerData) {
      setBannerImages([...nextProps.bannerImages.climberBannerData]);
    }
  }, [nextProps.bannerImages]);

  // nextProps.climberToolsLits
  const isOtherTools = useRef(true);
  useEffect(() => {
    if (isOtherTools.current) {
      isOtherTools.current = false;
      return;
    }
    if (nextProps.climberToolsLits) {
      setOtherTools([
        { ...nextProps.climberToolsLits[0], path: "/home" },
        { ...nextProps.climberToolsLits[1] },
        { ...nextProps.climberToolsLits[2], path: "/document-upload" },
      ]);
    }
  }, [nextProps.climberToolsLits]);

  const onSelectCountry = (selectedCountry) => {
    setCountry(selectedCountry);
    setSelectedTool("");
    // dispatch(
    //   climberPartnerRequest({
    //     country_id: selectedCountry.value,
    //     order: orderBy,
    //   }),
    // );
    // dispatch(climberFooterRequest(selectedCountry.value));
  };

  const handleClimberTools = (value) => {
    setSelectedTool(value.id);
    otherTools?.map((eachValue) => {
      if (
        eachValue.id === value.id &&
        eachValue.path &&
        country !== "" &&
        eachValue.id === value.id
      ) {
        history(eachValue.path, { state: { selectedLanguage } });
        localStorage.setItem("selectedCountry", JSON.stringify(country));
      }
      return null;
    });
  };

  const onHandleSubmitData = () => {
    setClimateModal(false);
    window.open(climateAdapturl?.url, "_blank");
  };
  return (
    <>
      <div className="climber-main-div">
        <Suspense fallback={<div>Loading..</div>}>
          <div>
            {carouselBannerImages.length === 0 ? null : (
              <CarouselComponent carouselData={carouselBannerImages} />
            )}
            <div className="climber-section-1">
              <Row className="climber-page ">
                <Col sm={12} className="mt-1">
                  <h1 className="climber-heading">
                    {" "}
                    {nextProps?.aboutUs?.[0]?.title
                      ? nextProps?.aboutUs?.[0]?.title
                      : ""}
                  </h1>
                </Col>
                <Col sm={12}>
                  {" "}
                  {nextProps?.aboutUs?.[0]?.desc
                    ? nextProps?.aboutUs?.[0]?.desc
                    : ""}
                </Col>
                <Col sm={6} className="align-center mt-2 climber-country-div">
                  <div className="climber-country">
                    <div className="common-select climber-country-select">
                      <Select
                        classNamePrefix="react-select"
                        placeholder={t("CLIMBER_HOME_PAGE.SELECT_COUNTRY")}
                        options={
                          nextProps?.countriesList?.coreList &&
                          renderReactOptionsArraystate(
                            nextProps?.countriesList?.coreList,
                            "name",
                            "id",
                            "latitude",
                            "longitude",
                            "zoom_level",
                            "code",
                            false,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            "basin_feature_collection",
                          )
                        }
                        className={
                          country === "" && selectTool !== ""
                            ? "common-select-error common-select-r"
                            : "common-select-r"
                        }
                        value={country}
                        onChange={(e) => onSelectCountry(e)}
                      />
                    </div>
                  </div>
                  {country === "" && selectTool !== "" && (
                    <span className="ml-3">
                      {" "}
                      <BiErrorCircle color="red" />{" "}
                      <span className="error">
                        {" "}
                        {t("CLIMBER_HOME_PAGE.PLEASE_SLELECT_COUNTRY")}
                      </span>{" "}
                    </span>
                  )}
                </Col>
                <Col sm={12} className="our-tools-text">
                  <h4 className="our-tools-text">
                    {" "}
                    {t("CLIMBER_HOME_PAGE.OUR_TOOLS")}{" "}
                  </h4>
                </Col>
              </Row>
              <div className="climber-page climber-cards-row ">
                {nextProps.climberToolsLits
                  ? nextProps.climberToolsLits.map((eachCard, index) => {
                      if (index < 3) {
                        return (
                          <div
                            className={
                              country === "" && selectTool === eachCard.id
                                ? "climber-card-error "
                                : "climber-card"
                            }
                            onClick={
                              eachCard.title === climaapat().CLIMAADAPT
                                ? () => {
                                    setClimateModal(true);
                                    setClimateAdaptUrl(eachCard);
                                  }
                                : eachCard?.id === uploaderDocumentCardId
                                ? () => {
                                    history("/document-upload", {
                                      state: { selectedLanguage },
                                    });
                                  }
                                : () => {
                                    handleClimberTools(eachCard, index);
                                    setClimateAdaptUrl("");
                                  }
                            }
                            role="button"
                            tabIndex="0"
                            key={eachCard.title}
                            onKeyUp={() => {}}
                          >
                            <img
                              src={eachCard.icon}
                              alt="OtherTools"
                              className="climber-tools-img"
                            />
                            <h5 className="mt-3 mb-2 climaber-card-heading">
                              {eachCard.title}
                            </h5>
                            <p className="climaber-card-para">
                              {eachCard.desc}
                            </p>
                          </div>
                        );
                      }
                      return null;
                    })
                  : ""}

                <div
                  className="climber-card"
                  onClick={handleClick}
                  role="button"
                  tabIndex="0"
                  onKeyUp={() => {}}
                >
                  <img
                    src={OtherTools}
                    alt="OtherTools"
                    className="climber-tools-img"
                  />
                  <h5 className="mt-3 mb-2 climaber-card-heading">
                    {" "}
                    {t("CLIMBER_HOME_PAGE.OTHERS")}
                  </h5>
                  <p className="climaber-card-para">
                    {" "}
                    {t("CLIMBER_HOME_PAGE.OTHERS_TEXT")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="climber-section-2">
            <Row className="">
              <Col sm={12} className="mt-3 p-0">
                <img src={Footer} alt="Footer" className="w-100" />
              </Col>
            </Row>
          </div>
          <ClimberFooter
            page="climber"
            icon={nextProps.footerList}
            selectedCountry={country}
          />
        </Suspense>
      </div>
      {climateModal && (
        <ClimateAdaptModal
          toggle={() => setClimateModal(false)}
          modal={climateModal}
          title={nextProps.climberToolsLits?.[1]?.title}
          description={t("CLIMBER_HOME_PAGE.CLIMA_ADAPT_DESC")}
          onHandleSubmitData={onHandleSubmitData}
        />
      )}
    </>
  );
}

export default React.memo(ClimberHome);
