import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import Header from "./EAProjectsHeader";
import Filter from "./EAProjectsSearch";
import CreateModal from "./CreateProjectModal";
import Projects from "./EAProjects";
import RightSideModal from "./EAProjectFilter";
import {
  getAllRiskTypeRequest,
  coreRequest,
  corestateRequest,
  coredistrictRequest,
  createProjectRequest,
  getAllProjectListRequest,
  corestateSuccess,
  coredistrictSuccess,
  deleteprojectRequest,
  userDetailsRequest,
} from "../../store/actions";
import {
  renderReactOptionsArray,
  renderReactOptionsArraystate,
} from "../../helpers";
import { State, District, Country } from "../../constants";
import PaginationSection from "../ManageUser/PaginationSection";
import DeleteConfirmationModal from "../../components/Common/DeleteConfirmationModal";
import SuccessConfirmationModal from "../../components/Common/SucessConfirmModel";
import { isStaffUser, userDetails } from "../../components/Common/Utils";
import successCheckmark from "../../assest/img/delete-success.svg";
import createProjectIcon from "../../assest/img/createProjectIcon.png";

function EAProjects() {
  const [, setOpen] = useOutletContext(); // for closing side bar when it is opening
  const search = window.location.search;
  const URLParams = new URLSearchParams(search);
  const isFinance = URLParams.get("isFinance");

  const defaultCountryData = JSON.parse(
    localStorage.getItem("selectedCountry"),
  );

  const dispatch = useDispatch();

  const projectRef = useRef();

  const { t } = useTranslation();

  const [riskList, setRiskList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedLocationCreate, setSelectedLocationCreate] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtsList, setDistrictsList] = useState([]);

  const [stateListCreate, setStateListCreate] = useState([]);
  const [districtsListCreate, setDistrictsListCreate] = useState([]);

  const [createModal, setIsCreateModal] = useState(false);
  const [communtyName, setCommunityName] = useState("");
  const [selectedRisk, setSelectedRisk] = useState("");
  const [createSelectRisk, setCreateSelectRisk] = useState("");
  const [projectList, setProjectList] = useState();
  const [availableDate, setAvailableDate] = useState({});
  const [yearList, setYearList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [selectedyear, setSelectedYear] = useState("");
  const [selectedMonths, setSelectedMonths] = useState("");
  const [searchProject, setSearchProject] = useState("");
  const [openFilter, setOpenfilter] = useState(false);

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isSucessModal, setIsSucessModal] = useState(false);
  const [isCreateSucessModal, setIsCreateSucessModal] = useState(false);
  const [deleteData, setDeleteData] = useState("");

  const [selectedproject, setSelectedProject] = useState("");
  const [editprojectName, setIsEditProjectName] = useState("");

  const [pageLimit, setPageLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [pageSize, setPageSize] = useState(10);

  const userData = userDetails();
  useEffect(() => {
    dispatch(getAllRiskTypeRequest({}));

    setSelectedLocation((prev) => {
      return {
        ...prev,
        country: defaultCountryData,
        state: "",
        district: "",
      };
    });
    dispatch(
      coreRequest({
        location_type: "country",
      }),
    );
    setOpen(true);
    dispatch(userDetailsRequest({ id: userData?.user_details?.[0]?.id }));

    // !Setting state for main stepper if need we need to change based on project listing
    localStorage.setItem("stepperActiveStep", "0");
    // !Need from API to set preparedness phase state
    localStorage.setItem("preparednessPhaseCompletedSteps", "");
    return () => {
      dispatch(corestateSuccess({}));
      dispatch(coredistrictSuccess({}));
    };
  }, []);

  const nextProps = useSelector((state) => ({
    riskList: state?.EaProjects?.riskList,
    statesList: state.Core.corestateList?.data,
    districtsList: state.Core.coredistrictList,
    countryList: state.Core.coreList,
    addProject: state?.EaProjects?.createProject,
    projectList: state?.EaProjects?.projectList,
    isUpdate: state?.EaProjects.isUpdate,
    isCreateState: state.Core?.isCreateState,
    isCreateDistrict: state.Core?.isCreateDistrict,
    deleteMessage: state?.EaProjects?.deleteProject, // projectLoading
    projectLoading: state?.EaProjects?.projectLoading, // projectLoading
    editProjectMessage: state?.EaProjects?.editProjectMessage,
    userDetails: state.User?.userDetails,
  }));

  const isOtherTools = useRef(true);
  useEffect(() => {
    if (isOtherTools.current) {
      isOtherTools.current = false;
      return;
    }
    if (nextProps.riskList) {
      const list = renderReactOptionsArray(nextProps.riskList, "name", "id");

      setRiskList([{ value: "", label: "Select" }, ...list]);
    }
  }, [nextProps.riskList]);

  // edit project sucess

  const isEditProjectSucess = useRef(true);
  useEffect(() => {
    if (isEditProjectSucess.current) {
      isEditProjectSucess.current = false;
      return;
    }
    if (nextProps.editProjectMessage) {
      setOpenfilter(false);
      setOpen(false);
      projectRef.current.scrollTop = 0;
      setSelectedProject("");
      setIsEditProjectName("");
      setSearchProject("");
      setSelectedMonths("");
      setSelectedYear("");
      setDistrictsList([]);
      setStateList([]);
      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: "",
          state: "",
          district: "",
        };
      });
      const data = {
        searchProject: "",
        risk_type_id: "",
        year: "",
        month: "",
        page: 1,
        limit: 10,
      };

      dispatch(getAllProjectListRequest({ payLoad: data, isUpdate: true }));
    }
  }, [nextProps.editProjectMessage]);

  const isDeleteProject = useRef(true);
  useEffect(() => {
    if (isDeleteProject.current) {
      isDeleteProject.current = false;
      return;
    }
    if (nextProps.deleteMessage) {
      setIsDeleteModal(false);
      setOpen(false);
      projectRef.current.scrollTop = 0;
      setIsSucessModal(true);
      setDeleteData("");
      setSearchProject("");
      setSelectedMonths("");
      setSelectedYear("");
      setDistrictsList([]);
      setStateList([]);
      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: "",
          state: "",
          district: "",
        };
      });
      const data = {
        searchProject: "",
        risk_type_id: "",
        year: "",
        month: "",
        page: 1,
        limit: 10,
      };
      dispatch(getAllProjectListRequest({ payLoad: data, isUpdate: true }));
    }
  }, [nextProps.deleteMessage]);

  const isProjectList = useRef(true);
  useEffect(() => {
    if (isProjectList.current) {
      isProjectList.current = false;
      return;
    }

    if (nextProps.projectList?.project_list?.length === 0) {
      setSelectedProject("");
      setIsEditProjectName("");

      setAvailableDate({});
      setYearList([]);
      setMonthList([]);
      setPageNumber(1);
      setPageLimit(10);
      setPageCount();
      setSelectedMonths("");
      setSelectedYear("");
      setProjectList(nextProps.projectList?.project_list);
    } else if (nextProps.projectList?.project_list?.results?.length) {
      const yearlist1 = [];
      setAvailableDate(nextProps.projectList?.available_dates);
      Object.keys(nextProps.projectList?.available_dates)?.map((each) =>
        yearlist1?.push({
          value: each,
          label: each,
        }),
      );
      setYearList([
        {
          value: "",
          label: "Select",
        },
        ...yearlist1,
      ]);
      const filteredData = nextProps.projectList?.project_list?.results?.filter(
        (item) => item?.ea_rp_status || item?.ea_ap_status,
      );
      if (isStaffUser()) {
        setProjectList(nextProps.projectList?.project_list);
        setPageCount(nextProps.projectList?.project_list?.count);
      } else {
        setProjectList({ results: filteredData });
        setPageCount(filteredData?.length);
      }
      setSelectedProject("");
      setIsEditProjectName("");
      if (nextProps?.isUpdate) {
        setPageNumber(1);
        setPageLimit(10);
        setPageSize(10);
      }
    }
  }, [nextProps.projectList]);

  // when create project sucessful
  const isCreateProject = useRef(true);
  useEffect(() => {
    if (isCreateProject.current) {
      isCreateProject.current = false;
      return;
    }
    if (nextProps.addProject) {
      setIsCreateModal(false);
      setOpen(false);
      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: "",
          state: "",
          district: "",
        };
      });

      setSelectedLocationCreate((prev) => {
        return {
          ...prev,
          country: "",
          state: "",
          district: "",
        };
      });
      setCommunityName("");
      setCreateSelectRisk("");
      setSelectedRisk("");
      setSearchProject("");
      setSelectedMonths("");
      setSelectedYear("");
      setDistrictsList([]);
      setStateList([]);
      setIsCreateSucessModal(true);
      dispatch(
        getAllProjectListRequest({
          payLoad: { searchProject, page: 1, limit: 10 },
          isUpdate: true,
        }),
      );
    }
  }, [nextProps.addProject]);

  const payLoad = {
    location_type_id:
      selectedLocation?.district !== "" &&
      selectedLocation?.district?.value !== ""
        ? selectedLocation?.district?.value
        : selectedLocation?.state !== "" &&
          selectedLocation?.state?.value !== ""
        ? selectedLocation?.state?.value
        : selectedLocation?.country?.value,
    searchProject,
    location_type:
      selectedLocation?.district !== "" &&
      selectedLocation?.district?.value !== ""
        ? District
        : selectedLocation?.state !== "" &&
          selectedLocation?.state?.value !== ""
        ? State
        : Country,
    risk_type_id: selectedRisk?.value,
    month: selectedMonths?.value || "",
    year: selectedyear?.value || "",
  };

  if (isFinance) {
    payLoad.is_early_finance = "true";
  }

  const fetchData = () => {
    dispatch(
      getAllProjectListRequest({
        payLoad: { ...payLoad, page: 1, limit: 10 },
        isUpdate: true,
      }),
    );
  };

  // user for not calling api multiple time for each stroke in search field
  const debouncedFetchData = debounce(fetchData, 500);
  useEffect(() => {
    if (selectedLocation?.country) {
      debouncedFetchData();
      return () => {
        debouncedFetchData.cancel();
      };
    }
  }, [searchProject, selectedLocation?.country]);

  useEffect(() => {
    if (!createModal) {
      setSelectedLocationCreate((prev) => {
        return {
          ...prev,
          country: "",
          state: "",
          district: "",
        };
      });

      setStateListCreate([]);
      setDistrictsListCreate([]);
    }
  }, [createModal]);

  useEffect(() => {
    if (selectedLocation?.country && selectedLocation?.country?.value !== "") {
      dispatch(
        corestateRequest({
          data: {
            parent_id: createModal
              ? selectedLocationCreate?.country?.value
              : selectedLocation?.country?.value,
            location_type: State,
          },
          isCompareLocation: false,
          isCreate: createModal,
        }),
      );
    }
  }, [selectedLocation?.country]);

  useEffect(() => {
    if (
      selectedLocationCreate?.country &&
      selectedLocationCreate?.country?.value !== ""
    ) {
      dispatch(
        corestateRequest({
          data: {
            parent_id: createModal
              ? selectedLocationCreate?.country?.value
              : selectedLocation?.country?.value,
            location_type: State,
          },
          isCompareLocation: false,
          isCreate: createModal,
        }),
      );
    }
  }, [selectedLocationCreate?.country]);

  useEffect(() => {
    if (nextProps?.statesList) {
      const data = renderReactOptionsArraystate(
        nextProps?.statesList,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
      )?.map((eachValue) => eachValue);
      if (nextProps?.isCreateState) {
        setStateListCreate([...data]);
      } else {
        setStateList([{ value: "", label: "Select" }, ...data]);
      }
    }
  }, [nextProps?.statesList]);

  useEffect(() => {
    if (nextProps.districtsList?.data) {
      if (nextProps.districtsList?.data) {
        const data1 = renderReactOptionsArraystate(
          nextProps.districtsList?.data,
          "name",
          "id",
          "latitude",
          "longitude",
          "zoom_level",
        )?.map((eachValue) => eachValue);

        if (nextProps?.isCreateDistrict) {
          setDistrictsListCreate([...data1]);
        } else {
          setDistrictsList([{ value: "", label: "Select" }, ...data1]);
        }
      }
    }
  }, [nextProps.districtsList]);

  useEffect(() => {
    if (
      selectedLocation?.state &&
      selectedLocation?.state?.value !== "" &&
      !createModal
    ) {
      dispatch(
        coredistrictRequest({
          requestObj: {
            parent_id: createModal
              ? selectedLocationCreate?.state?.value
              : selectedLocation?.state?.value,
            location_type: District,
          },
          isCompareLocation: false,
          isCreate: createModal,
        }),
      );
    }
  }, [selectedLocation?.state]);

  useEffect(() => {
    if (
      selectedLocationCreate?.state &&
      selectedLocationCreate?.state?.value !== "" &&
      createModal
    ) {
      dispatch(
        coredistrictRequest({
          requestObj: {
            parent_id: createModal
              ? selectedLocationCreate?.state?.value
              : selectedLocation?.state?.value,
            location_type: District,
          },
          isCompareLocation: false,
          isCreate: createModal,
        }),
      );
    }
  }, [selectedLocationCreate?.state]);

  const isCountryList = useRef(true);
  useEffect(() => {
    if (isCountryList.current) {
      isCountryList.current = false;
      return;
    }
    if (nextProps?.countryList) {
      const countryarray = [
        {
          value: "",
          label: "Select",
        },
      ];
      renderReactOptionsArraystate(
        nextProps?.countryList,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
        "code",
        false,
        null,
        null,
        null,
        null,
        null,
        null,
        "basin_feature_collection",
      )?.map((eachValue) => countryarray.push(eachValue));
      setCountryList(countryarray);
    }
  }, [nextProps?.countryList]);

  const onCreateProject = () => {
    const data = {
      location_type:
        selectedLocationCreate?.district !== "" &&
        selectedLocationCreate?.district?.value !== ""
          ? District
          : selectedLocationCreate?.state !== "" &&
            selectedLocationCreate?.state?.value !== ""
          ? State
          : Country,
      location_type_id:
        selectedLocationCreate?.district !== "" &&
        selectedLocationCreate?.district?.value !== ""
          ? selectedLocationCreate?.district?.value
          : selectedLocationCreate?.state !== "" &&
            selectedLocation?.state?.value !== ""
          ? selectedLocationCreate?.state?.value
          : selectedLocationCreate?.country?.value,
      project_name: communtyName,
      risk_type_id: createSelectRisk?.value,
    };

    dispatch(createProjectRequest(data));
  };

  // for submitting the filter data
  const handleFilteredData = () => {
    const location_type =
      selectedLocation?.district !== "" &&
      selectedLocation?.district?.value !== ""
        ? District
        : selectedLocation?.state !== "" &&
          selectedLocation?.state?.value !== ""
        ? State
        : Country;

    dispatch(
      getAllProjectListRequest({
        payLoad: { ...payLoad, location_type, page: 1, limit: 10 },
        isUpdate: true,
      }),
    );
  };

  const clearFilters = () => {
    if (searchProject === "") {
      const data = {
        searchProject: "",
        risk_type_id: "",
        year: "",
        month: "",
        page: 1,
        limit: 10,
        location_type_id: selectedLocation?.country?.value,
        location_type: Country,
      };
      dispatch(getAllProjectListRequest({ payLoad: data, isUpdate: true }));
    }
    setSelectedLocation((prev) => {
      return {
        ...prev,

        state: "",
        district: "",
      };
    });
    setStateList([]);
    setDistrictsList([]);
    setSelectedRisk("");
    setSearchProject("");
    setSelectedMonths("");
    setSelectedYear("");
  };

  const handleGoToPage = (value) => {
    setPageNumber(Number(value));
    dispatch(
      getAllProjectListRequest({
        payLoad: { ...payLoad, page: value, limit: pageLimit },
        isUpdate: false,
      }),
    );
  };
  const handlePageSize = (value) => {
    setPageSize(value);
    setPageLimit(value);
    setPageNumber(1);
    dispatch(
      getAllProjectListRequest({
        payLoad: { ...payLoad, page: 1, limit: value },
        isUpdate: false,
      }),
    );
  };

  const onHandleDelete = (data) => {
    setIsDeleteModal(true);
    setDeleteData(data);
  };

  const onDeleteConfirmation = () => {
    dispatch(deleteprojectRequest({ project_id: deleteData?.id }));
  };

  return (
    <div>
      <div className="ea-project-container">
        <div>
          <Header
            setIsCreateModal={setIsCreateModal}
            permissionsList={nextProps.userDetails?.user_permissions}
            isFinance={isFinance}
          />
          <div className="filter-card-section">
            <Filter
              setSearchProject={setSearchProject}
              searchProject={searchProject}
              setOpenfilter={setOpenfilter}
              isFinance={isFinance}
            />
            <hr className="ea-filter-divider" />
          </div>
        </div>

        <div
          className="project-cards-container projetcts-scroll"
          ref={projectRef}
        >
          <Projects
            projectList={projectList}
            onHandleDelete={onHandleDelete}
            setSelectedProject={setSelectedProject}
            selectedproject={selectedproject}
            setIsEditProjectName={setIsEditProjectName}
            editprojectName={editprojectName}
            isFinance={isFinance}
            permissionsList={nextProps.userDetails?.user_permissions}
            loader={nextProps?.projectLoading}
          />
        </div>
        {projectList?.length !== 0 ? (
          <div className="mt-3">
            <PaginationSection
              pageCount={pageCount}
              pageLimit={pageLimit}
              setPageCount={setPageCount}
              setPageLimit={setPageLimit}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setPageSize={setPageSize}
              pageSize={pageSize}
              handleGoToPage={handleGoToPage}
              handlePageSize={handlePageSize}
              disabled
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div />
      {openFilter && (
        <RightSideModal
          countryList={countryList}
          selectedLocation={selectedLocation}
          stateList={stateList}
          districtsList={districtsList}
          setSelectedLocation={setSelectedLocation}
          setOpenfilter={setOpenfilter}
          handleFilteredData={handleFilteredData}
          riskList={riskList}
          setSelectedRisk={setSelectedRisk}
          clearFilters={clearFilters}
          selectedRisk={selectedRisk}
          yearList={yearList}
          setMonthList={setMonthList}
          monthList={monthList}
          availableDate={availableDate}
          setSelectedYear={setSelectedYear}
          selectedyear={selectedyear}
          setSelectedMonths={setSelectedMonths}
          selectedMonths={selectedMonths}
          setDistrictsList={setDistrictsList}
          setStateList={setStateList}
          openFilter={openFilter}
        />
      )}
      {createModal && (
        <CreateModal
          toggle={() => setIsCreateModal(false)}
          modal={createModal}
          riskList={riskList}
          countryList={countryList}
          stateList={stateListCreate}
          districtsList={districtsListCreate}
          setSelectedLocation={setSelectedLocationCreate}
          setCommunityName={setCommunityName}
          communtyName={communtyName}
          selectedRisk={createSelectRisk}
          setSelectedRisk={setCreateSelectRisk}
          selectedLocation={selectedLocationCreate}
          onCreateProject={onCreateProject}
          setDistrictsListCreate={setDistrictsListCreate}
          setStateListCreate={setStateListCreate}
        />
      )}
      {isDeleteModal && (
        <DeleteConfirmationModal
          modal={isDeleteModal}
          toggle={() => setIsDeleteModal(!isDeleteModal)}
          title={t("EARLY_ACTIONS.DELETE_PROJECT")}
          message={t("EARLY_ACTIONS.DELETE_PROJECT_MESSAGE")}
          onConfirmDelete={onDeleteConfirmation}
        />
      )}
      {isSucessModal && (
        <SuccessConfirmationModal
          toggle={() => setIsSucessModal(!isSucessModal)}
          modal={isSucessModal}
          title={t("EARLY_ACTIONS.DELETED_SUCESS")}
          message={t("EARLY_ACTIONS.DELETED_SUCESS_MESSAGE")}
          icon={successCheckmark}
        />
      )}

      {isCreateSucessModal && (
        <SuccessConfirmationModal
          toggle={() => setIsCreateSucessModal(!isCreateSucessModal)}
          modal={isCreateSucessModal}
          title={t("EARLY_ACTIONS.CREATE_SUCESS_TITLE")}
          message={t("EARLY_ACTIONS.CREATE_SUCESS_MESSAGE")}
          icon={createProjectIcon}
        />
      )}
      {(openFilter || createModal || isDeleteModal) && (
        <div className="ea-project-card-disabled " />
      )}
    </div>
  );
}

export default EAProjects;
