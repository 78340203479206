import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { status200, status300, status400, status600 } from "../../constants";

/*  -------function that handle all type of error and success notifications----*/
function NotificationHandle(status, message, isSuccess, ishide) {
  let content;
  if (status >= status200 && status < status300 && isSuccess) {
    if (message) {
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } else {
    if (
      (status >= status400 && status < status600) ||
      (status >= status200 && status < status300)
    ) {
      if (
        window.location.pathname !== "/login" &&
        message === "Given token not valid for any token type"
      ) {
        localStorage.removeItem("loggedInUserDetails");
        localStorage.removeItem("isLoged");
        Cookies.remove("user");
        window.location.href = "/login";
      }

      content = message;
    }
    if (!ishide) {
      if (content) {
        toast.error(content, {
          position: "top-right",
          autoClose: 3000,
          transition: Slide,
        });
      }
    }
    // else {
    //   toast.error(content, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     transition: Slide,
    //   });
    // }
  }
}

export default NotificationHandle;
