import EARLY_WARNING_CONST from "./actionTypes";

export const addDroughtRequest = (data, indicatorurl) => {
  return {
    type: EARLY_WARNING_CONST.ADD_DROUGHT_REQUEST,
    payload: { data, url: indicatorurl },
  };
};
export const addDroughtSuccess = (user) => {
  return {
    type: EARLY_WARNING_CONST.ADD_DROUGHT_SUCCESS,
    payload: user,
  };
};
export const addDroughtError = (error) => {
  return {
    type: EARLY_WARNING_CONST.ADD_DROUGHT_ERROR,
    payload: error,
  };
};

export const getDroughtTimeSeriesRequest = (data, indicatorurl) => ({
  type: EARLY_WARNING_CONST.GET_DROUGHT_TIMESERIES_REQUEST,
  payload: {
    data,
    url: indicatorurl,
  },
});

export const getDroughtTimeSeriesSuccess = (response) => ({
  type: EARLY_WARNING_CONST.GET_DROUGHT_TIMESERIES_SUCCESS,
  payload: response,
});

export const getDroughtTimeSeriesError = (error) => ({
  type: EARLY_WARNING_CONST.GET_DROUGHT_TIMESERIES_ERROR,
  payload: error,
});
export const addFloodRequest = (data, indicatorurl) => {
  return {
    type: EARLY_WARNING_CONST.ADD_FLOOD_REQUEST,
    payload: { data, url: indicatorurl },
  };
};
export const addFloodSuccess = (user) => {
  return {
    type: EARLY_WARNING_CONST.ADD_FLOOD_SUCCESS,
    payload: user,
  };
};
export const addFloodError = (error) => {
  return {
    type: EARLY_WARNING_CONST.ADD_FLOOD_ERROR,
    payload: error,
  };
};
export const getFloodTimeSeriesRequest = (data, indicatorurl) => ({
  type: EARLY_WARNING_CONST.GET_FLOOD_TIMESERIES_REQUEST,
  payload: {
    data,
    url: indicatorurl,
  },
});

export const getFloodTimeSeriesSuccess = (response) => ({
  type: EARLY_WARNING_CONST.GET_FLOOD_TIMESERIES_SUCCESS,
  payload: response,
});

export const getFloodTimeSeriesError = (error) => ({
  type: EARLY_WARNING_CONST.GET_FLOOD_TIMESERIES_ERROR,
  payload: error,
});
