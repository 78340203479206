import USER_GUIDE_CONSTS from "./userGuideConsts";

// get all topics user guide request
export const getAllTopicsListRequest = (data) => ({
  type: USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_REQUEST,
  payload: data,
});

export const getAllTopicsListSuccess = (response) => ({
  type: USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_SUCCESS,
  payload: response,
});

export const getAllTopicsListFailed = (error) => ({
  type: USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_FAILED,
  payload: error,
});

// search by content api request
export const searchContentUserGuideRequest = (data) => ({
  type: USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_REQUEST,
  payload: data,
});

export const searchContentUserGuideSuccess = (response) => ({
  type: USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_SUCCESS,
  payload: response,
});

export const searchContentUserGuideFailed = (error) => ({
  type: USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_FAILED,
  payload: error,
});

// get all by criteria api request
export const getAllUserGuideRequest = (data) => ({
  type: USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_REQUEST,
  payload: data,
});

export const getAllUserGuideSuccess = (response) => ({
  type: USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_SUCCESS,
  payload: response,
});

export const getAllUserGuideFailed = (error) => ({
  type: USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_FAILED,
  payload: error,
});

// get all topic and subtopic api request
export const getAllTopicsRequest = (data) => ({
  type: USER_GUIDE_CONSTS.GET_ALL_TOPICS_REQUEST,
  payload: data,
});

export const getAllTopicSuccess = (response) => ({
  type: USER_GUIDE_CONSTS.GET_ALL_TOPICS_SUCCESS,
  payload: response,
});

export const getAllTopicFailed = (error) => ({
  type: USER_GUIDE_CONSTS.GET_ALL_TOPICS_FAILED,
  payload: error,
});

// search by criteria api request
export const searchByCriteriaUserGuideRequest = (data) => ({
  type: USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_REQUEST,
  payload: data,
});

export const searchByCriteriaUserGuideSuccess = (response) => ({
  type: USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_SUCCESS,
  payload: response,
});

export const searchByCriteriaUserGuideFailed = (error) => ({
  type: USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_FAILED,
  payload: error,
});
