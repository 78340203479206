import React, { useRef, useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  useMap,
  ZoomControl,
  LayersControl,
  LayerGroup,
  useMapEvents,
  Marker,
} from "react-leaflet";
import L from "leaflet";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { myConst, mapPreferenceConstants } from "../../../constants";
import CustomLegend from "../../../components/Common/CustomLegend";
import GeojsonComponent from "./geoJsonLayers";
import loader from "../../../assest/img/green-loading.gif";

import DiscreteLegend from "../../../components/Common/DiscreteLegend";
import Maprefer from "../../../assest/img/Map-prefer.svg";
import MapPreferenceModal from "../../../components/Common/MapPreferences";
import {
  taluksBoundaryRequest,
  stateBoundaryRequest,
  countryBoundaryRequest,
} from "../../../store/actions";

function CropYieldMapContainer({
  onHandleZoom,
  position,
  zooms,

  legend,
  setLegend,
  selectedLocation,
  setMapPreferences,
  mapPreferences,
  setShowChart,
  geoJsonKeys,
  mapData,
  districtJsonData,
  setDistrictJsonData,
  selectedCrop,
  startYear,
  endYear,
  selectedModal,
  clickEventData,
  selectedMap,
  setSelectedmap,
  scrollableYear,
  setScrollableyear,
  setEndyear,
  setDistricyBoundry,
  districtBoundry,
  // setModalPerformance,
  // setGraphData,
  setGeoJsonData,
  geoJsonData,
  mapPreferRefs,
  layerRef,
  geoJsonAllData,
  geoJsonLayersData,
  setGeoJsonAllData,
  setOpen,
  setMapPreferencesData,
  mapPreferencesData,
  adminRadio,
  wrap,
  removeMapPreferenceLayer,
  removeMapLayers,
  setIndexLayers,
  indexLayers,
  selectedMapPreferLegendData,
  setSelectedMapPreferLegendData,
  // setcurrentGraphData,
  loaderlatLng,
  predectionDataLoadingRef,
}) {
  const { Overlay } = LayersControl;

  const dispatch = useDispatch();
  const nextProps = useSelector((state) => ({
    countryBoundry: state.Boundry?.countryBoundry,
    stateBoundry: state?.Boundry?.stateBoundry,
    taluksBoundry: state?.Boundry?.taluksBoundry,
    cropPredectionData: state.CropYield?.cropPredectionData,
    mapPreferenceList: state.MapPreference,
    predectionLoading: state.CropYield?.predectionLoading,
  }));

  const { t } = useTranslation();

  const [isOpenMapModel, setIsOpenMapModel] = useState(false);
  const [mapPreferenceCategories, setMapPreferenceCategories] = useState([]);
  const [getBoundariesData, setGetBoundariesData] = useState([]);

  const [mapUrl, setMapUrl] = useState("");
  const [mapPreferUrl, setMapPreferUrl] = useState("");

  const [opacityControl, setOpacityControl] = React.useState("");

  function ChangeView({ center, zoom }) {
    const mapEvents = useMapEvents({
      zoomend: () => {
        onHandleZoom(mapEvents.getCenter(), mapEvents.getZoom());
      },
      dragend: () => {
        onHandleZoom(mapEvents.getCenter(), mapEvents.getZoom());
      },
    });

    const map = useMap();
    setInterval(() => {
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) map.invalidateSize();
    }, 100);
    if (zoom && center) map.setView(center, zoom);
    return null;
  }

  const setMapReference = (mapInstance) => {
    wrap.current = mapInstance;
  };

  useEffect(() => {
    if (selectedLocation?.country) {
      removeMapLayers(legend);
      removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    }
  }, [selectedLocation?.country, selectedLocation?.state]);

  useEffect(() => {
    if (selectedLocation?.country || selectedLocation?.district) {
      removeMapLayers(legend);
      setShowChart(false);
    }
  }, [selectedLocation?.country, selectedLocation?.district]);

  useEffect(() => {
    if (
      scrollableYear?.selectedYear &&
      nextProps?.cropPredectionData?.predicted?.graph_data
    ) {
      removeMapLayers(legend);

      const filteredMap = mapData?.find(
        (each) => each?.Year === scrollableYear?.selectedYear,
      );
      setSelectedmap(filteredMap);
    }
  }, [scrollableYear?.selectedYear, nextProps?.cropPredectionData]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      const MapPrefereArr = [];
      mapPreferencesData?.map((item) => {
        const legendItem = JSON.parse(JSON.stringify(item.data));
        legendItem.id = item?.dataSource;

        legendItem.parameter = item?.legendName;
        legendItem.add = true;
        legendItem.measure = legendItem.units;
        legendItem.isCompare = false;
        legendItem.id = item?.dataSource;
        legendItem.legendId = item.id;

        if (item.slug === mapPreferenceConstants?.soilSlug) {
          legendItem.dataSource =
            item.soil === mapPreferenceConstants?.soilTextureTwenty
              ? `${mapPreferenceConstants?.soliTextureText} (${mapPreferenceConstants?.soilTextureTwentyValue})`
              : `${mapPreferenceConstants?.soliTextureText} (${mapPreferenceConstants?.soilTextureFiftyValue})`;
        } else if (item.slug === mapPreferenceConstants?.gdpSlug) {
          legendItem.dataSource = `${mapPreferenceConstants.gdpText} ${item.gdpYear}`;
        } else {
          legendItem.dataSource = item.category;
        }

        legendItem.fromMapPreferences = true;
        if (selectedMapPreferLegendData?.length) {
          const isDuplicate = selectedMapPreferLegendData?.find(
            (x) => x.legendId === item.id && item.add === true,
          );
          if (_.isEmpty(isDuplicate)) {
            MapPrefereArr.push(legendItem);
          }
        } else {
          MapPrefereArr.push(legendItem);
        }
        return null;
      });

      setSelectedMapPreferLegendData(MapPrefereArr);
    }
  }, [mapPreferencesData]);

  const addLayers = (selectedParamRef) => {
    if (wrap.current && selectedParamRef.current) {
      const leafletMapRef = wrap.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer) => {
        leafletMapRef.addLayer(layer);
      });
    }
    return false;
  };

  useEffect(() => {
    if (mapData?.length && selectedMap) {
      const currentParamRef = layerRef.current[0];

      if (currentParamRef && currentParamRef.current !== null) {
        currentParamRef.current?.eachLayer((layer) => {
          if (selectedMap?.map_data?.map_url) {
            layer.setUrl(selectedMap?.map_data?.map_url);
            layer.setOpacity(1);
            setMapUrl({ name: "", url: selectedMap?.map_data?.map_url, layer });

            const updatedIndexLayer = _.cloneDeep(indexLayers);
            updatedIndexLayer.push({ isCompare: false, layer });

            setIndexLayers(updatedIndexLayer);
          }
        });
      }

      const updateLegend = [...legend];
      let legendObj = {};
      legendObj = { ...selectedMap?.map_data };
      legendObj.name = "Cropyield Predection";
      legendObj.parameter = "";
      legendObj.measure = "";
      legendObj.dataSource = "Crop yield (kg/ha)";
      legendObj.isCompare = false;
      legendObj.id = "";
      legendObj.dataSource_id = "";
      legendObj.parameter_slug = "SLUG";
      legendObj.fromMapPreferences = false;
      legendObj.descret_legend = false;
      legendObj.startDate = null;
      legendObj.endDate = null;
      // legendObj.min = Math.floor(selectedMap?.map_data?.min);
      // legendObj.max = Math.floor(selectedMap?.map_data?.max);
      legendObj.add = true;
      updateLegend.push(legendObj);
      setLegend(updateLegend);

      if (!_.isEmpty(currentParamRef) && currentParamRef?.current !== null) {
        addLayers(currentParamRef);
      }
    }
  }, [selectedMap]);
  const isStateBoundry = useRef(true);
  useEffect(() => {
    if (isStateBoundry.current) {
      isStateBoundry.current = false;
      return;
    }
    if (nextProps.stateBoundry?.data) {
      if (nextProps.stateBoundry?.isState) {
        // for state boundry
        const selectedLayer = mapPreferences.regions;
        selectedLayer?.layer?.current?.clearLayers();
        selectedLayer.country = selectedLocation?.country;
        selectedLayer.layer.current.addData(nextProps.stateBoundry?.data);

        selectedLayer.color = "#121211";
        setMapPreferences((prev) => {
          return {
            ...prev,
            regions: selectedLayer,
          };
        });
      } else {
        // for district boundry
        const selectedLayer = mapPreferences.districts_or_cities;
        selectedLayer?.layer?.current?.clearLayers();
        selectedLayer.country = selectedLocation?.country;
        selectedLayer.layer.current.addData(nextProps.stateBoundry?.data);
        selectedLayer.color = "#36373A";
        setDistricyBoundry(nextProps.stateBoundry?.data);
        setMapPreferences((prev) => {
          return {
            ...prev,
            districts_or_cities: selectedLayer,
          };
        });
      }
    }
  }, [nextProps.stateBoundry]);

  useEffect(() => {
    if (selectedLocation?.country) {
      setDistricyBoundry("");
      setDistrictJsonData("");
    }
  }, [selectedLocation?.country]);

  function addOpacitySlider() {
    if (indexLayers?.length) {
      L.Control.OpacitySlider = L.Control.extend({
        sliding: false,
        updateLayerOpacity(opacity) {
          const lastIndex = indexLayers.length - 1;
          if (indexLayers?.length >= 2) {
            const secondLastIndex = indexLayers.length - 2;
            if (
              indexLayers[lastIndex]?.isCompare &&
              indexLayers[secondLastIndex]?.isCompare
            ) {
              indexLayers.forEach((item, i) => {
                if (i === lastIndex || i === secondLastIndex)
                  item?.layer.setOpacity(opacity / 100);
              });
            } else {
              indexLayers.forEach((item, i) => {
                if (i === lastIndex) item?.layer.setOpacity(opacity / 100);
              });
            }
          } else {
            indexLayers.forEach((item, i) => {
              if (i === lastIndex) {
                item?.layer?.setOpacity(opacity / 100);
              }
            });
          }
        },
        onAdd(map) {
          if (opacityControl) {
            map?.removeControl(opacityControl);
          }
          const container = L.DomUtil.create("div", "opacityContainer");
          container.id = "opacityContainerCropYield";
          container.classList.add("opacityContainer-margin");
          container.innerHTML =
            '<div id=layer-opacity-control><input type="range" min="1" max="100" value="100" defaultValue="100" class="opacityslider"></div>';
          if (!L.Browser.touch) {
            L.DomEvent.disableClickPropagation(container);
            L.DomEvent.on(
              container,
              "mousewheel",
              L?.DomEvent?.stopPropagation,
            );
          } else {
            L.DomEvent.on(
              container,
              "mousedrag click",
              L?.DomEvent?.stopPropagation,
            );
          }
          const slider = container.querySelector(".opacityslider");
          slider.onclick = (e) => {
            // eslint-disable-next-line react/no-this-in-sfc
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousedown = (e) => {
            // eslint-disable-next-line react/no-this-in-sfc
            this.sliding = true;
            // eslint-disable-next-line react/no-this-in-sfc
            this.updateLayerOpacity(e.target.value);
          };
          slider.ontouchmove = (e) => {
            // eslint-disable-next-line react/no-this-in-sfc
            this.sliding = true;
            // eslint-disable-next-line react/no-this-in-sfc
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousemove = (e) => {
            e?.stopPropagation();
            // eslint-disable-next-line react/no-this-in-sfc
            if (this.sliding) this.updateLayerOpacity(e.target.value);
          };
          slider.onmouseup = () => {
            // eslint-disable-next-line react/no-this-in-sfc
            this.sliding = false;
          };
          return container;
        },
      });
      L.control.opacitySlider = (opts) => {
        return new L.Control.OpacitySlider(opts);
      };
      const customOpacityControl = L.control.opacitySlider({
        position: "topright",
      });

      if (!_.isEmpty(wrap.current) && !_.isEmpty(wrap.current?._layers)) {
        customOpacityControl?.addTo(wrap.current);
      }
      setOpacityControl(customOpacityControl);
    }
  }

  const resetOpacity = () => {
    indexLayers.map((eachLayer) => {
      eachLayer?.layer.setOpacity(1);
      return null;
    });
  };

  useEffect(() => {
    if (!_.isEmpty(indexLayers)) {
      resetOpacity(indexLayers);

      const legendExist = legend?.some((obj) => obj.add === true);
      const mapPreferenceLegendExist = selectedMapPreferLegendData?.some(
        (obj) => obj.add === true,
      );

      addOpacitySlider();

      if (mapPreferenceLegendExist || legendExist) {
        const opacityContainer = document.getElementById(
          "opacityContainerCropYield",
        );

        opacityContainer?.classList?.remove("cy-hide-opacity");
      } else {
        const opacityContainer = document.getElementById(
          "opacityContainerCropYield",
        );

        opacityContainer?.classList?.add("cy-hide-opacity");
      }

      if (indexLayers?.length) {
        indexLayers.map((l) => l?.layer?.bringToFront());
      }
    } else {
      const legendExist = legend?.some((obj) => obj.add === true);
      const mapPreferenceLegendExist = selectedMapPreferLegendData?.some(
        (obj) => obj.add === true,
      );

      const opacityContainer = document.getElementById(
        "opacityContainerCropYield",
      );
      opacityContainer?.classList?.add("cy-hide-opacity");

      if (mapPreferenceLegendExist || legendExist) {
        opacityContainer?.classList?.remove("cy-hide-opacity");
      } else {
        opacityContainer?.classList?.add("cy-hide-opacity");
      }
    }
  }, [indexLayers]);

  // for boundaries
  useEffect(() => {
    let selectedGeoJsonData = [];
    if (geoJsonLayersData?.length && geoJsonData?.length) {
      geoJsonData?.map((item) => {
        const filteredObj = geoJsonLayersData?.find(
          (layer) =>
            layer.config.id === item.id &&
            layer.config.country_id === item.country_id,
        );

        if (!_.isEmpty(filteredObj)) {
          filteredObj.fromMapPreferences = item.fromMapPreferences;
          selectedGeoJsonData.push(filteredObj);
        }
        return null;
      });
    } else {
      selectedGeoJsonData = [];
    }

    setGeoJsonAllData(selectedGeoJsonData);
  }, [geoJsonLayersData, geoJsonData]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        const mapGeojsonItem = Object.keys(mapPreferences).find(
          (x) => x === item.config.name,
        );

        const selectedLayer = mapPreferences[mapGeojsonItem];

        if (
          selectedLayer?.layer &&
          selectedLayer?.layer?.current &&
          item?.config?.category !== "Admin"
        ) {
          selectedLayer.country = selectedLocation?.country;
          selectedLayer.layer.current.addData(item.data);
          selectedLayer.color = item.config.color;
          setMapPreferences((prev) => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }

        return null;
      });
    }
  }, [geoJsonAllData]);

  //   useeffect to reset all the map preferences states data when country gets changed
  useEffect(() => {
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setMapPreferencesData([]);
    const selectedLegend = JSON.parse(
      JSON.stringify(selectedMapPreferLegendData),
    );
    // for legend addpled to map-preferences
    if (selectedLegend.length) {
      selectedLegend.forEach(function mappreference(value) {
        if (value.add) {
          value.add = false;
        }
      });
      setSelectedMapPreferLegendData(selectedLegend);
    }

    removeMapLayers(legend); //  for removing map-preferences when country is changed in header
  }, [
    selectedLocation.country,
    selectedLocation.state,
    selectedLocation.district,
    selectedLocation?.basin,
    selectedLocation?.subBasin,
  ]);

  const handleMapModel = () => {
    setIsOpenMapModel(!isOpenMapModel);
  };

  const isMapPreferenceList = useRef(true);
  useEffect(() => {
    if (isMapPreferenceList.current) {
      isMapPreferenceList.current = false;
      return;
    }
    if (nextProps.mapPreferenceList.mapPreferenceList?.length > 0) {
      const array = [];

      setGetBoundariesData([...nextProps.mapPreferenceList.mapPreferenceList]);
      nextProps.mapPreferenceList.mapPreferenceList.map((eachValue) =>
        array.push(eachValue.category),
      );
      setMapPreferenceCategories(array);
    }
  }, [nextProps.mapPreferenceList]);

  // for showing opacity slider when visualization  layer changes
  useEffect(() => {
    if (
      (selectedMapPreferLegendData?.length &&
        selectedMapPreferLegendData?.every((i) => !i.add)) ||
      !selectedMapPreferLegendData?.length
    ) {
      if (mapUrl?.url) {
        // setMapLayerOpacity({ layer: mapUrl?.layer });
        const array = [];
        array.push({ isCompare: false, layer: mapUrl?.layer });

        setIndexLayers(array);
      }
    } else if (selectedMapPreferLegendData?.length) {
      if (mapPreferUrl?.url) {
        const array = [];
        array.push({ isCompare: false, layer: mapPreferUrl?.layer });

        // setIndexLayers(array);
        const updatedIndexLayer = _.cloneDeep(indexLayers);
        updatedIndexLayer.push({
          isCompare: false,
          layer: mapPreferUrl?.layer,
        });

        setIndexLayers(updatedIndexLayer);
      }
    }
  }, [selectedMapPreferLegendData]);

  const renderParamLayer = () => {
    if (selectedMap?.Year) {
      return (
        <>
          {/* TODO: need to create separate component */}
          <Overlay name={selectedMap?.Year}>
            <LayerGroup ref={layerRef.current[0]}>
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url=""
                id={selectedMap?.Year}
              />
            </LayerGroup>
          </Overlay>
        </>
      );
    }
  };

  const onHandleCropPredectionDefaultBoundry = () => {
    dispatch(
      stateBoundaryRequest({
        data: {
          country_name: selectedLocation?.country?.label,
          state_name:
            selectedLocation?.state === ""
              ? t("DASHBOARD.ALL_REGIONS")
              : selectedLocation?.state?.label,
        },
        isState: true,
      }),
    );
  };

  const onSelectionAdminCategory = (data, jsondata) => {
    if (data.length === jsondata.length && selectedLocation.country?.label) {
      if (data[data?.length - 1].name === "country") {
        dispatch(
          countryBoundaryRequest({
            data: {
              country_name: selectedLocation?.country?.label,
            },
          }),
        );
      }
      if (data[data?.length - 1].name === "regions") {
        dispatch(
          stateBoundaryRequest({
            data: {
              country_name: selectedLocation?.country?.label,
              state_name:
                selectedLocation?.state === ""
                  ? t("DASHBOARD.ALL_REGIONS")
                  : selectedLocation?.state?.label,
            },
            isState: true,
          }),
        );
      }
      if (data[data?.length - 1].name === "districts_or_cities") {
        dispatch(
          stateBoundaryRequest({
            data: {
              country_name: selectedLocation?.country?.label,
              state_name:
                selectedLocation?.state === ""
                  ? t("DASHBOARD.ALL_REGIONS")
                  : selectedLocation?.state?.label,
              district_name:
                selectedLocation?.district === ""
                  ? t("DASHBOARD.ALL_DISTRICTS")
                  : selectedLocation?.district?.label,
            },
            isState: false,
          }),
        );
      }
      if (data[data?.length - 1].name === "talukas") {
        dispatch(
          taluksBoundaryRequest({
            data: {
              country_name: selectedLocation?.country?.label,
              state_name:
                selectedLocation?.state === ""
                  ? t("DASHBOARD.ALL_REGIONS")
                  : selectedLocation?.state?.label,
              district_name:
                selectedLocation?.district === ""
                  ? t("DASHBOARD.ALL_DISTRICTS")
                  : selectedLocation?.district?.label,
              taluk_name: t("DASHBOARD.ALL_TALUKS"),
            },
          }),
        );
      }
    }
  };

  //  function to remove the un selected check box in map-preferences for boundry
  const removingSelectedBoundary = (currentData, previousData) => {
    const currentList = [];
    const previousList = [];
    currentData.map((eachData) => currentList.push(eachData.name));
    previousData.map((eachData) => previousList.push(eachData.name));
    previousList.map((eachValue) => {
      if (!currentList.includes(eachValue)) {
        const selectedLayer = mapPreferences[eachValue];
        selectedLayer?.layer?.current?.clearLayers();
      }
      return null;
    });
  };
  const getMapPreferencesGeojsonData = (geoJsonList) => {
    geoJsonList.length && onSelectionAdminCategory(geoJsonList, geoJsonData);
    const data = JSON.parse(JSON.stringify(geoJsonList));
    geoJsonList.length < geoJsonData.length &&
      removingSelectedBoundary(geoJsonList, geoJsonData);
    setGeoJsonData(data);
  };

  const getMapPreferencesData = (mapPreferencesLayerData) => {
    /** removing previously added map preferences layers before setting the state */
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);

    setIndexLayers([]);
    /** setting map preferences state   */
    const arr = JSON.parse(JSON.stringify(mapPreferencesLayerData));
    let paramData = "";
    let paramArr = [];
    if (selectedMapPreferLegendData?.length) {
      paramData = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
      paramData?.map((item, index) => {
        if (item.add) {
          const legendItem = { ...item };
          legendItem.add = false;
          paramData[index] = legendItem;
        }
        return null;
      });
      paramArr = [...paramData];
    }

    setSelectedMapPreferLegendData(paramArr);
    setMapPreferencesData(arr);
  };

  useEffect(() => {
    if (mapPreferencesData?.length) {
      if (mapPreferRefs?.current?.length) {
        mapPreferencesData?.map((item) => {
          const selectedMapPreferences = mapPreferRefs.current.find(
            (x) => x.current.boundary_id === item.id,
          );

          const currentParamRef = selectedMapPreferences?.current?.layer;

          currentParamRef?.current?.eachLayer((mapLayer) => {
            mapLayer.setUrl(item?.data?.map_url);
            const updatedIndexLayer = _.cloneDeep(indexLayers);

            updatedIndexLayer.push({ isCompare: false, layer: mapLayer });

            setIndexLayers(updatedIndexLayer);
            setMapPreferUrl({
              name: undefined,
              url: item?.data?.map_url,
              layer: mapLayer,
            });
            mapLayer.setOpacity(1);
          });

          if (currentParamRef && currentParamRef?.current) {
            addLayers(currentParamRef);
          }
          return null;
        });
      }
    }
  }, [mapPreferencesData, mapPreferRefs.current]);

  const LoaderIcon = new L.Icon({ iconUrl: loader, iconSize: [80, 80] });

  return (
    <>
      <MapContainer
        center={position}
        zoom={zooms}
        scrollWheelZoom
        zoomControl={false}
        className="map-container"
        ref={setMapReference}
      >
        <ChangeView center={position} zoom={zooms} />
        <ZoomControl position="topright" className="zoommap" />

        {nextProps.predectionLoading ? (
          <Marker
            position={[loaderlatLng?.lat, loaderlatLng?.lng]}
            icon={LoaderIcon}
          />
        ) : (
          ""
        )}

        <GeojsonComponent
          mapPreferences={mapPreferences}
          districtBoundry={districtBoundry}
          setDistrictJsonData={setDistrictJsonData}
          districtJsonData={districtJsonData}
          geoJsonKeys={geoJsonKeys}
          selectedCrop={selectedCrop}
          startYear={startYear}
          endYear={endYear}
          selectedModal={selectedModal}
          setScrollableyear={setScrollableyear}
          clickEventData={clickEventData}
          setEndyear={setEndyear}
          predectionDataLoadingRef={predectionDataLoadingRef}
        />

        <LayersControl position="topleft">{renderParamLayer()}</LayersControl>

        <div className="logoContainer1">
          <Button className="map-preferences-button" onClick={handleMapModel}>
            <img src={Maprefer} alt="map preference" width={15} />
          </Button>
        </div>

        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Street">
            <TileLayer
              options={{ tileSize: 256 }}
              attribution={myConst.TITLE_LAYER_ATTRIBUTE}
              url={myConst.TITLE_LAYER_URL}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite">
            <TileLayer
              url={myConst.SATELLITE_TILE_LAYER_URL}
              options={{ tileSize: 256 }}
              attribution={myConst.SATELLITE_TILE_LAYER_ATTRIBUTE}
            />
          </LayersControl.BaseLayer>
        </LayersControl>

        <LayersControl key="layer2" position="topleft">
          {mapPreferRefs?.current?.map((mapLayerRef) => {
            if (!_.isEmpty(mapLayerRef)) {
              return (
                <Overlay>
                  <LayerGroup ref={mapLayerRef.current.layer}>
                    <TileLayer
                      attribution='Weather from <a href="https://openweathermap.org/" alt="World Map and worldwide Weather Forecast online">OpenWeatherMap</a>'
                      url=""
                    />
                  </LayerGroup>
                </Overlay>
              );
            }
            return null;
          })}
        </LayersControl>

        {/* {selectedMap?.map_data ? (
          <TileLayer
            ref={customLayerRef}
            url={selectedMap?.map_data?.map_url}
            options={{ tileSize: 256 }}
            zIndex={500}
          />
        ) : null} */}

        {legend.length
          ? legend.map((item) => {
              return (
                <LayersControl key="layer3" position="topleft">
                  <CustomLegend
                    map={wrap.current}
                    add={item.add}
                    layer={item}
                    position="bottomright"
                  />
                </LayersControl>
              );
            })
          : ""}

        {selectedMapPreferLegendData.length
          ? selectedMapPreferLegendData.map((item) => {
              return (
                <LayersControl key="layer4" position="topleft">
                  {item?.descret_legend ? (
                    <DiscreteLegend
                      map={wrap.current}
                      add={item.add}
                      layer={item}
                      position="bottomleft"
                    />
                  ) : (
                    <CustomLegend
                      map={wrap.current}
                      add={item.add}
                      layer={item}
                      position="bottomright"
                    />
                  )}
                </LayersControl>
              );
            })
          : ""}
      </MapContainer>

      {getBoundariesData?.length && (
        <MapPreferenceModal
          isToggle={isOpenMapModel}
          handleToggle={handleMapModel}
          preferenceList={getBoundariesData}
          categories={mapPreferenceCategories}
          geoJsonData={geoJsonData}
          getMapPreferencesData={getMapPreferencesData}
          mapPreferencesAllData={mapPreferencesData}
          selectedParamLegendData={legend}
          selectedLocation={selectedLocation}
          getMapPreferencesGeojsonData={getMapPreferencesGeojsonData}
          setOpen={setOpen}
          adminRadio={adminRadio}
          glofasState=""
          fromCropPredection
          onHandleCropPredectionDefaultBoundry={
            onHandleCropPredectionDefaultBoundry
          }
        />
      )}
    </>
  );
}

export default CropYieldMapContainer;
