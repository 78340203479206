import React from "react";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RegisterCheckMarck from "../../assest/img/RegisterCheckmark.png";

function SuccessModal(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { modalSuccessMessage, modalHeaderMessage, navigateLink, isWeb } =
    props;
  return (
    <div className="reg-success">
      <img
        src={RegisterCheckMarck}
        alt="not-available"
        className="reg-check-image"
      />
      <h2 className="reset-succes-head-text"> {modalHeaderMessage}</h2>
      <p className="reset-succes-desc">{modalSuccessMessage}</p>
      {!isWeb ? (
        <Button
          type="button"
          className="sucess-ok-btn"
          onClick={() => navigate(navigateLink)}
        >
          {t("REGISTER.OK")}
        </Button>
      ) : (
        ""
      )}
    </div>
  );
}
export default SuccessModal;
