import React from "react";
import { Row, Col } from "reactstrap";
import { BsGlobe } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { useTranslation } from "react-i18next";
// import MEE_IMAGE from "../../../../assest/img/zadm-w.png"; depends on client requirement
import CGIAR from "../../../../assest/img/CigarLogoResearch.svg";
import IWMI from "../../../../assest/img/Iwmi.svg";

function BulletinFooter() {
  const { t } = useTranslation();
  const contactPersons = [
    {
      name: "AWARE Platform team at IWMI, ",
      email: "wrd@iwmi.org",
      icon: GrMail,
    },
  ];

  const contactSite = {
    name: "Website",
    email: "AWARE Drought Monitor",
    icon: BsGlobe,
  };

  const copyright = "@ CopyRights 2022 AWARE";

  return (
    <>
      <div className="ty-div pb-3" id="footer-disclamer" style={{}}>
        <Row className="mb-2 mt-2 ty-Disclaimer">
          <span className="ol-bu-disclamer text-center">
            {t("CREATE_ONLINE_BULLETIN.BULLETIN_FOOTER.DISCLAIMER")} :
          </span>
          <span className=" ol-bu-disclamer-content">
            {t("CREATE_ONLINE_BULLETIN.BULLETIN_FOOTER.STATIC")}
          </span>
        </Row>
      </div>
      <div className="bulletin-footer" id="footer-logo">
        <Row className="title">
          <Col md={5}>
            <h6 style={{ fontSize: "20px" }}>
              {t("CREATE_ONLINE_BULLETIN.BULLETIN_FOOTER.FURTHUR_INFO")}
            </h6>
            {contactPersons.map((item) => {
              return (
                <div className="contacts" key={item.email}>
                  <p>
                    <span className="contact-icon">{/* <item.icon /> */}</span>{" "}
                    {item.name}{" "}
                    <a
                      href={`mailto:${item.email}`}
                      className="email-font-color "
                    >
                      {item.email}
                    </a>
                  </p>
                </div>
              );
            })}
          </Col>
          <Col md={7} className="text-center">
            <ul className="list-unstyled d-flex  justify-content-end">
              {/* <li>
                <img
                  src={MEE_IMAGE}
                  alt="cgair_wle"
                  className="preview-image1"
                />{" "}
              </li> */}
              <li>
                <img src={CGIAR} alt="sadms" className="preview-image1" />{" "}
              </li>
              <li>
                <img src={IWMI} alt="iwmi" className="preview-image1" />{" "}
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="d-flex pt-2 div-footer">
          <Col md={6} className="footer-copy-section ">
            <p className="contacts1 footer-copy-section ">{copyright}</p>
          </Col>
          <Col md={6} className="footer-copy-section ">
            <div className="contacts1 space-div">
              <p className=" footer-copy-section ">
                <span className="contact-icon">
                  <contactSite.icon />
                </span>{" "}
                {contactSite.name} : {contactSite.email}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default BulletinFooter;
