import REGISTER_CONST from "./actionTypes";

export const provienceRequest = (payload) => {
  return {
    type: REGISTER_CONST.PROVIENCE_REQUEST,
    payload,
  };
};
export const provienceSuccess = (user) => {
  return {
    type: REGISTER_CONST.PROVIENCE_SUCCESS,
    payload: user,
  };
};
export const provienceError = (error) => {
  return {
    type: REGISTER_CONST.PROVIENCE_ERROR,
    payload: error,
  };
};
