import EARLY_WARNING_HEALTH_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  healthTimeSeriesLoading: true,
};
const Health = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case EARLY_WARNING_HEALTH_CONST.GET_HEALTH_TIMESERIES_REQUEST:
      state = {
        ...state,
        healthTimeSeriesLoading: true,
        healthTimeseriesData: null,
        healthTimeSeriesError: null,
      };
      break;
    case EARLY_WARNING_HEALTH_CONST.GET_HEALTH_TIMESERIES_SUCCESS:
      state = {
        ...state,
        healthTimeSeriesLoading: false,
        healthTimeseriesData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        healthTimeSeriesError: null,
      };
      break;
    case EARLY_WARNING_HEALTH_CONST.GET_HEALTH_TIMESERIES_ERROR:
      state = {
        ...state,
        healthTimeSeriesError: action.payload.data,
        healthTimeSeriesLoading: false,
        healthTimeseriesData: null,
      };
      break;

    case EARLY_WARNING_HEALTH_CONST.GET_EW_RAW_DATA_DATES_REQUEST:
      state = {
        ...state,
        getRawDataDatesLoading: true,
        getRawDataDates: null,
        getRawDataDatesError: null,
      };
      break;
    case EARLY_WARNING_HEALTH_CONST.GET_EW_RAW_DATA_DATES_SUCCESS:
      state = {
        ...state,
        getRawDataDatesLoading: false,
        getRawDataDates:
          action.payload.statusCode === 200 ? action.payload.data : false,
        getRawDataDatesError: null,
      };
      break;
    case EARLY_WARNING_HEALTH_CONST.GET_EW_RAW_DATA_DATES_ERROR:
      state = {
        ...state,
        getRawDataDatesError: action.payload.data,
        getRawDataDatesLoading: false,
        getRawDataDates: null,
      };
      break;

    // data request
    case EARLY_WARNING_HEALTH_CONST.GET_HEALTH_MALARIA_DATA_REQUEST:
      state = {
        ...state,
        isHealthMalariaDataRequesting: true,
        healthMalariaData: false,
        healthMalariaDataError: false,
      };
      break;
    case EARLY_WARNING_HEALTH_CONST.GET_HEALTH_MALARIA_DATA_SUCCESS:
      state = {
        ...state,
        isHealthMalariaDataRequesting: false,
        healthMalariaData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        healthMalariaDataError: false,
      };
      break;
    case EARLY_WARNING_HEALTH_CONST.GET_HEALTH_MALARIA_DATA_FAILED:
      state = {
        ...state,
        isHealthMalariaDataRequesting: false,
        healthMalariaData: false,
        healthMalariaDataError: action.payload.data,
      };
      break;

    // data request
    case EARLY_WARNING_HEALTH_CONST.GET_HEALTH_PREDECTION_DATA_REQUEST:
      state = {
        ...state,
        loading: true,
        healthMalariaPredectionData: false,
        error: false,
      };
      break;
    case EARLY_WARNING_HEALTH_CONST.GET_HEALTH_PREDECTION_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        healthMalariaPredectionData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: false,
      };
      break;
    case EARLY_WARNING_HEALTH_CONST.GET_HEALTH_PREDECTION_DATA_ERROR:
      state = {
        ...state,
        loading: false,
        healthMalariaPredectionData: false,
        error: action.payload.data,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
export default Health;
