import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import ManageUser from "../../assest/img/manageuser.png";
import Setting3 from "../../assest/img/setting3.png";
import uploadIcon from "../../assest/img/uploadIcon.png";
import approvalIcon from "../../assest/img/approvalimg.png";
import ClimberFooter from "../../components/Common/ClimberFooter";
import ManageStaticDataGuide from "../../assest/img/Manage_Static_Data.png";
import ManageUserGuide from "../../assest/img/Manage_User_guide.png";
import { userDetailsRequest } from "../../store/actions";
import { isPermissionsMatch, superAdmin } from "../../components/Common/Utils";
import BackOffcieBulletin from "../../assest/img/Manage_Bulletin.png";
import SurveySettingIcon from "../../assest/img/Survey_Data_Settings.png";
import ModalPerformanceIcon from "../../assest/img/Modal-Performance-feedback-icon.png";
import DisasterEventsIcon from "../../assest/img/Disaster_events_icon.png";

function Settings() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();

  const userId = JSON.parse(Cookies.get("user"))?.user_details?.[0]?.id;

  const loggedInUserDetails = JSON.parse(
    localStorage.getItem("loggedInUserDetails"),
  );

  const nextProps = useSelector((state) => ({
    userDetails: state.User?.userDetails,
  }));

  useEffect(() => {
    dispatch(userDetailsRequest({ id: userId, initial: "true" }));
  }, []);
  return (
    <>
      <div className="setting-background">
        <Row>
          <Col sm={12} className="setting-header setting-center">
            <img
              src={Setting3}
              alt={t("SETTINGS.SETTINGS")}
              className="setting-icon"
            />
            <p className="setting-text"> {t("SETTINGS.SETTINGS")} </p>
          </Col>
        </Row>

        <div className="setting-container setting-container-grid-layout">
          <div
            // sm={3}
            onClick={() => history("/ManageStaticData")}
            className="setting-card-main"
            role="button"
            tabIndex="0"
            onKeyUp={() => {}}
          >
            <div className="setting-card">
              <img
                src={ManageStaticDataGuide}
                alt={t("SETTINGS.SETTINGS")}
                className="setting-card-icon"
              />
              <p className="setting-card-text"> Manage Static Data</p>
            </div>
          </div>

          {isPermissionsMatch(
            nextProps.userDetails?.user_permissions,
            t("PERMISSIONS.VIEW_USER"),
          ) || superAdmin() ? (
            <div
              // sm={3}
              className="setting-card-main"
              onClick={() => history("/manageUser")}
              role="button"
              tabIndex="0"
              onKeyUp={() => {}}
            >
              <div
                className="setting-card"
                id="userData"
                data-tip
                data-for="userData"
              >
                <img
                  src={ManageUser}
                  alt={t("SETTINGS.SETTINGS")}
                  className="setting-card-icon"
                />
                <p className="setting-card-text">
                  {" "}
                  {t("SETTINGS.MANAGE_USER")}{" "}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            // sm={3}
            onClick={() => history("/uploadPastData")}
            className="setting-card-main"
            role="button"
            tabIndex="0"
            onKeyUp={() => {}}
          >
            <div className="setting-card" id="uploadData">
              <img
                src={uploadIcon}
                alt={t("SETTINGS.SETTINGS")}
                className="setting-card-icon"
              />
              <p className="setting-card-text">
                {" "}
                {t("SETTINGS.UPLOAD_PAST_DATA")}{" "}
              </p>
            </div>
          </div>
          {loggedInUserDetails?.user_details[0]?.is_superuser ? (
            <div
              // sm={3}
              onClick={() => history("/approvalPastData")}
              className="setting-card-main"
              role="button"
              tabIndex="0"
              onKeyUp={() => {}}
            >
              <div className="setting-card" id="approvalData">
                <img
                  src={approvalIcon}
                  alt={t("SETTINGS.SETTINGS")}
                  className="setting-card-icon"
                />
                <p className="setting-card-text">
                  {" "}
                  {t("SETTINGS.APPROVAL_PAST_DATA")}{" "}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            // sm={3}
            onClick={() => history("/manageUserGuide")}
            className="setting-card-main"
            role="button"
            tabIndex="0"
            onKeyUp={() => {}}
          >
            <div className="setting-card">
              <img
                src={ManageUserGuide}
                alt={t("SETTINGS.SETTINGS")}
                className="setting-card-icon"
              />
              <p className="setting-card-text">
                {" "}
                {t("SETTINGS.MANAGE_USER_GUIDE")}{" "}
              </p>
            </div>
          </div>
          <div
            // sm={3}
            onClick={() => history("/back-office-bulletin/:currentTab")}
            className="setting-card-main"
            role="button"
            tabIndex="0"
            onKeyUp={() => {}}
          >
            <div className="setting-card">
              <img
                src={BackOffcieBulletin}
                alt={t("SETTINGS.SETTINGS")}
                className="setting-card-icon"
              />
              <p className="setting-card-text">
                {" "}
                {t("SETTINGS.MANAGE_BULLETIN")}{" "}
              </p>
            </div>
          </div>
          <div
            // sm={3}
            onClick={() => history("/surveyConfigurationDashboard")}
            className="setting-card-main"
            role="button"
            tabIndex="0"
            onKeyUp={() => {}}
          >
            <div className="setting-card">
              <img
                src={SurveySettingIcon}
                alt={t("SETTINGS.SETTINGS")}
                className="setting-card-icon"
              />
              <p className="setting-card-text">{t("ODK.SURVEY_SETTING")}</p>
            </div>
          </div>
          {isPermissionsMatch(
            nextProps.userDetails?.user_permissions,
            t("PERMISSIONS.VIEW_FEEDBACK"),
          ) || superAdmin() ? (
            <div
              // sm={3}
              onClick={() => history("/model-performance-feedback")}
              className="setting-card-main"
              role="button"
              tabIndex="0"
              onKeyUp={() => {}}
            >
              <div className="setting-card">
                <img
                  src={ModalPerformanceIcon}
                  alt={t("SETTINGS.MODEL_PERFORMANCE_FEEDBACK")}
                  className="setting-card-icon"
                />
                <p className="setting-card-text">
                  {t("SETTINGS.MODEL_PERFORMANCE_FEEDBACK")}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          {isPermissionsMatch(
            nextProps.userDetails?.user_permissions,
            t("PERMISSIONS.APPROVED_DISATEREVENTS"),
          ) || superAdmin() ? (
            <div
              // sm={3}
              onClick={() => history("/approve-disaster-events")}
              className="setting-card-main"
              role="button"
              tabIndex="0"
              onKeyUp={() => {}}
            >
              <div className="setting-card">
                <img
                  src={DisasterEventsIcon}
                  alt={t("SETTINGS.AAPROVE_DIASATER_EVENTS")}
                  className="setting-card-icon"
                />
                <p className="setting-card-text">
                  {t("SETTINGS.AAPROVE_DIASATER_EVENTS")}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <ClimberFooter />
    </>
  );
}

export default Settings;
