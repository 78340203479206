import USER_GUIDE_CONSTS from "./actionTypes";

// get all manage user guide data
export const searchByCriteriaManageUserGuideRequest = (data) => ({
  type: USER_GUIDE_CONSTS.GET_All_MANAGE_USER_GUIDE_LIST_REQUEST,
  payload: data,
});

export const searchByCriteriaManageUserGuideSuccess = (response) => ({
  type: USER_GUIDE_CONSTS.GET_All_MANAGE_USER_GUIDE_LIST_SUCCESS,
  payload: response,
});

export const searchByCriteriaManageUserGuideFailed = (error) => ({
  type: USER_GUIDE_CONSTS.GET_All_MANAGE_USER_GUIDE_LIST_FAILED,
  payload: error,
});

// get all manage user sub topics  data
export const manageUserGuideSubTopicsRequest = (data) => ({
  type: USER_GUIDE_CONSTS.GET_All_MANAGE_USER_SUB_TOPICS_REQUEST,
  payload: data,
});

export const manageUserGuideSubTopicsSuccess = (response) => ({
  type: USER_GUIDE_CONSTS.GET_All_MANAGE_USER_SUB_TOPICS_SUCCESS,
  payload: response,
});

export const manageUserGuideSubTopicsFailed = (error) => ({
  type: USER_GUIDE_CONSTS.GET_All_MANAGE_USER_SUB_TOPICS_FAILED,
  payload: error,
});

// ADD USER GUIDE
export const addUserGuideRequest = (data) => ({
  type: USER_GUIDE_CONSTS.ADD_USER_GUIDE_REQUEST,
  payload: data,
});

export const addUserGuideSuccess = (response) => ({
  type: USER_GUIDE_CONSTS.ADD_USER_GUIDE_SUCCESS,
  payload: response,
});

export const addUserGuideFailure = (error) => ({
  type: USER_GUIDE_CONSTS.ADD_USER_GUIDE_FAILURE,
  payload: error,
});

// UPDATE USER GUIDE
export const updateUserGuideRequest = (data) => ({
  type: USER_GUIDE_CONSTS.UPDATE_USER_GUIDE_REQUEST,
  payload: data,
});

export const updateUserGuideSuccess = (response) => ({
  type: USER_GUIDE_CONSTS.UPDATE_USER_GUIDE_SUCCESS,
  payload: response,
});

export const updateUserGuideFailure = (error) => ({
  type: USER_GUIDE_CONSTS.UPDATE_USER_GUIDE_FAILURE,
  payload: error,
});

// DELTE USER GUIDE
export const deleteManageUserGuideRequest = (data) => ({
  type: USER_GUIDE_CONSTS.DELETE_USER_GUIDE_REQUEST,
  payload: data,
});

export const deleteManageUserGuideSuccess = (response) => ({
  type: USER_GUIDE_CONSTS.DELETE_USER_GUIDE_SUCCESS,
  payload: response,
});

export const deleteManageUserGuideFailure = (error) => ({
  type: USER_GUIDE_CONSTS.DELETE_USER_GUIDE_FAILURE,
  payload: error,
});

// PUBLISH USER GUIDE
export const publishManageUserGuideRequest = (data) => ({
  type: USER_GUIDE_CONSTS.PUBLISH_USER_GUIDE_REQUEST,
  payload: data,
});

export const publishManageUserGuideSuccess = (response) => ({
  type: USER_GUIDE_CONSTS.PUBLISH_USER_GUIDE_SUCCESS,
  payload: response,
});

export const publishManageUserGuideFailure = (error) => ({
  type: USER_GUIDE_CONSTS.PUBLISH_USER_GUIDE_FAILURE,
  payload: error,
});

// SUB CATEGORY  GUIDE
export const getUserSubCategoryGuideRequest = (data) => ({
  type: USER_GUIDE_CONSTS.GET_SUB_CATEGORY_GUIDE_REQUEST,
  payload: data,
});

export const getUserSubCategoryGuideSuccess = (response) => ({
  type: USER_GUIDE_CONSTS.GET_SUB_CATEGORY_GUIDE_SUCCESS,
  payload: response,
});

export const getUserSubCategoryGuideFailure = (error) => ({
  type: USER_GUIDE_CONSTS.GET_SUB_CATEGORY_GUIDE_FAILURE,
  payload: error,
});
