import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector from "@mui/material/StepConnector";
import { withStyles } from "@material-ui/core/styles";
import { PROJECTS_STEPPER } from "../../constants/index";

function HorizontalLinearStepper({ activeStep }) {
  const ColorStepConnector = withStyles({
    active: {
      "& $line": {
        borderColor: "#D29433",
        borderTopWidth: 2,
      },
    },
    completed: {
      "& $line": {
        borderColor: "#D29433",
      },
    },
    line: {
      borderColor: "#9e9e9e",
      borderTopWidth: 3,
      borderRadius: 2,
    },
  })(StepConnector);

  const ActiveStepConnector = withStyles({
    active: {
      "& $line": {
        borderColor: "green",
        borderTopWidth: 2,
      },
    },
    completed: {
      "& $line": {
        borderColor: "green",
      },
    },
    line: {
      borderColor: "green",
      borderTopWidth: 6,
      borderRadius: 2,
    },
  })(StepConnector);

  return (
    <div
      className={
        activeStep === 3
          ? "Ea-projects-stepper-activated"
          : "Ea-projects-stepper"
      }
    >
      <Box sx={{ width: "100%" }}>
        <Stepper
          activeStep={activeStep}
          connector={
            activeStep === 3 ? <ActiveStepConnector /> : <ColorStepConnector />
          }
        >
          {PROJECTS_STEPPER().map((label) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label?.index} {...stepProps}>
                <StepLabel {...labelProps}>{label?.lable}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </div>
  );
}

export default HorizontalLinearStepper;
