// import { number } from "prop-types";
import { EMAIL_REGEX, NO_SPACE_REGEX } from "../constants";

export const required = (value) => {
  return !value ? "VALIDATION.REQUIRED" : "";
};

export const selectRequired = (value) => {
  return value === "" ? "VALIDATION.SELECT_REQUIRED" : "";
};

export const lengthRequired = (value, limit) => {
  return value === ""
    ? "VALIDATION.SELECT_REQUIRED"
    : value?.length > limit
    ? `Max length is ${limit} character`
    : "";
};

export const selectinteger = (value, limit) => {
  // eslint-disable-next-line no-restricted-globals
  const isNumber = isNaN(value);
  return value === "" || value === null
    ? "VALIDATION.SELECT_REQUIRED"
    : isNumber
    ? "Plase enter a number"
    : value?.length > limit
    ? `Max length is ${limit} character`
    : "";
};

export const validatePassword = (value) => {
  return !value
    ? "VALIDATION.SELECT_REQUIRED"
    : !NO_SPACE_REGEX.test(value)
    ? "VALIDATION.SPACE_NOT_ALLOWED"
    : value.length < 8 || value.length > 21
    ? "VALIDATION.PASSWORD_LENGTH"
    : "";
};

// export const noSpaceRequired = (value) => {
//   return !value
//     ? t("VALIDATE.REQUIRED")
//     : !NO_SPACE_REGEX.test(value)
//     ? t("VALIDATE.SPACE_NOT_ALLOWED")
//     : "";
// };

export const validateConfirmNewPassword = (value) => {
  return value === undefined ? "VALIDATION.SELECT_REQUIRED" : "";
};

export const validateEmail = (value) => {
  return !value
    ? "VALIDATION.IN_VALID_MAIL"
    : !EMAIL_REGEX.test(value)
    ? "VALIDATION.EMAIL_INVALID"
    : "";
};
/**
 * Email pattern
 */
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Email valid or not
 */
export const isValidEmail = (email) => {
  return emailRegex.test(String(email).trim().toLowerCase());
};
export const isValidDate = (endDate) => {
  return endDate >= new Date() ? new Date() : endDate;
};

export const regMatch = (value) => {
  return /^[0-9]*\.?[0-9]*$/.test(value) || /^-\d*\.?\d+$/.test(value);
};
