import USER_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};
const User = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case USER_CONST.ORGANIZATION_REQUEST:
      state = {
        ...state,
        loading: true,
        organizationList: null,
        error: "",
      };
      break;
    case USER_CONST.ORGANIZATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        organizationList:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case USER_CONST.ORGANIZATION_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        organizationList: null,
      };
      break;

    case USER_CONST.REGISTERATION_REQUEST:
      state = {
        ...state,
        loading: true,
        registration: null,
        error: "",
      };
      break;
    case USER_CONST.REGISTERATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        registration:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case USER_CONST.REGISTERATION_FAILURE:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        registration: null,
      };
      break;
    case USER_CONST.REGISTERATION_VERIFICATION_REQUEST:
      state = {
        ...state,
        loading: true,
        VerifyRegisteration: null,
        error: "",
      };
      break;
    case USER_CONST.REGISTERATION_VERIFICATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        VerifyRegisteration:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case USER_CONST.REGISTERATION_VERIFICATION_FAILURE:
      state = {
        ...state,
        VerifyRegisterationError:
          action.payload.data?.code === 400 ? action.payload.data.message : "",
        loading: false,
        VerifyRegisteration: null,
      };
      break;

    case USER_CONST.USER_DETAILS_REQUEST:
      state = {
        ...state,
        loading: true,
        userDetails: null,
        error: "",
      };
      break;
    case USER_CONST.USER_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        userDetails:
          action.payload.statusCode === 200 ? action.payload.data : false,
        initial: action.payload.initial,
        error: "",
      };
      break;
    case USER_CONST.USER_DETAILS_FAILURE:
      state = {
        ...state,
        error:
          action.payload.data?.code === 400 ? action.payload.data.message : "",
        loading: false,
        userDetails: null,
      };
      break;

    case USER_CONST.USER_ODK_INFO_UPDATE_REQUEST:
      state = {
        ...state,
        updateUserOdkInfoRequesting: true,
        updateUserOdkInfoData: false,
        updateUserOdkInfoError: false,
        updateUserOdkInfoNetworkError: false,
      };
      break;
    case USER_CONST.USER_ODK_INFO_UPDATE_SUCCESS:
      state = {
        ...state,
        updateUserOdkInfoRequesting: false,
        updateUserOdkInfoData: action.payload.response,
        updateUserOdkInfoError: false,
        updateUserOdkInfoNetworkError: false,
      };
      break;
    case USER_CONST.USER_ODK_INFO_UPDATE_FAILED:
      state = {
        ...state,
        updateUserOdkInfoRequesting: true,
        updateUserOdkInfoData: false,
        updateUserOdkInfoError: action.payload.error
          ? false
          : action.payload.response,
        updateUserOdkInfoNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;

    case USER_CONST.LOGIN_USER_DETAILS_REQUEST:
      state = {
        ...state,
        loginUserDetails: null,
      };
      break;
    case USER_CONST.LOGIN_USER_DETAILS_SUCCESS:
      state = {
        ...state,
        loginUserDetails: action?.payload?.response?.data.result,
      };
      break;
    case USER_CONST.LOGIN_USER_DETAILS_FAILED:
      state = {
        ...state,
        loginUserDetails: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
export default User;
