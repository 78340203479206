import React from "react";
// import GraphFilter from "./alertGraphFilter.js";
import Graph from "./Graph.js";

function AlertGraph({
  openweatherSeriseData,
  initialData,
  graphData,
  isShowGraph,
  setIsShowGraph,
  graphStartDate,
  graphEndDate,
  setGraphStartDate,
  setGraphEndDate,
  onGraphDateChange,
  minAndMaxDate,
  errorData,
  seriesData,
  forecastDate,
  onForecastDateChange,
  setForecastDate,
}) {
  return (
    // <div className="alert-dashboard-container">
    <Graph
      openweatherSeriseData={openweatherSeriseData}
      graphData={graphData}
      initialData={initialData}
      isShowGraph={isShowGraph}
      setIsShowGraph={setIsShowGraph}
      graphStartDate={graphStartDate}
      graphEndDate={graphEndDate}
      setGraphStartDate={setGraphStartDate}
      setGraphEndDate={setGraphEndDate}
      onGraphDateChange={onGraphDateChange}
      minAndMaxDate={minAndMaxDate}
      errorData={errorData}
      seriesData={seriesData}
      forecastDate={forecastDate}
      onForecastDateChange={onForecastDateChange}
      setForecastDate={setForecastDate}
    />
  );
}

export default AlertGraph;
