import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BsPlusLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import ICON from "../../../assest/img/empy-img.png";
import {
  getReadynessPhaseRequest,
  deleteInterventionRequest,
  projectStatusRequest,
} from "../../../store/actions";
import DeleteIcon from "../../../assest/img/readynessDelete.png";
import EditIcon from "../../../assest/img/readynessEdit.png";
import ViewIcon from "../../../assest/img/readynessView.png";
import DeleteConfirmationModal from "../../../components/Common/DeleteConfirmationModal";
import ActivePhaseModal from "../ActivePhaseModal";
import ActiveModal from "../ActiveConfirmationModal";
import ReviewModal from "../../../components/Common/ReviewInterventionModal";
import {
  isPermissionsMatch,
  superAdmin,
} from "../../../components/Common/Utils";
import { showError } from "../../../components/Common/Notification";
import {
  DELETE_INTERVENTION,
  EDIT_INTERVENTION,
  ADD_INTERVENTION,
  CAN_aCTIVATE_PLAN,
  CHANGE_BUDGET,
} from "../../../constants";

function ReadinessPhase({
  projectDetails,
  setStepSize,
  permissionsList,
  isAllStepsCompleted,
  setOpen,
}) {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [, setLocationState] = useState();
  const [interventationList, setInterventationList] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [interventionId, setIsInterventionid] = useState("");
  const [defaultValue, setDefaultValue] = useState(0);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [isEarlyFinance, setIsEarlyFinance] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const canActivatePlan =
    isPermissionsMatch(permissionsList, CAN_aCTIVATE_PLAN) || superAdmin();

  const canEditEF =
    isPermissionsMatch(permissionsList, CHANGE_BUDGET) || superAdmin();
  const data = JSON.parse(localStorage.getItem("projectDetails"));

  const params = new URLSearchParams(window.location.search);

  const selectedSubModule = params.get("slug");

  useEffect(() => {
    setLocationState(data);
    setOpen(true);
    dispatch(
      getReadynessPhaseRequest({
        module: "early_action",
        project_id: data?.id,
        data: defaultValue,
      }),
    );
  }, []);

  const nextProps = useSelector((state) => ({
    interventionList: state?.EAReadynessReducer?.interventionList,
    deleteInterventionMessage: state?.EAReadynessReducer?.deleteIntervention,
    projectStatusMessage: state?.EaProjects?.projectStatus,
  }));

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (nextProps?.interventionList?.length) {
      // setOpen(false);
      setInterventationList(nextProps?.interventionList);
    } else if (nextProps?.interventionList?.length === 0) {
      setInterventationList([]);
      setReviewModal(true);
      // setOpen(false);
    }
  }, [nextProps.interventionList]);

  const isDeleteRun = useRef(true);
  useEffect(() => {
    if (isDeleteRun.current) {
      isDeleteRun.current = false;
      return;
    }
    if (nextProps?.deleteInterventionMessage) {
      setIsInterventionid("");
      setIsDeleteModal(false);
      dispatch(
        getReadynessPhaseRequest({
          module: "early_action",
          project_id: data?.id,
          data: defaultValue,
        }),
      );
    }
  }, [nextProps.deleteInterventionMessage]);

  const onDeleteConfirmation = () => {
    dispatch(
      deleteInterventionRequest({
        intervention_id: interventionId,
      }),
    );
  };
  const handleDefaultIntervention = (value) => {
    setDefaultValue(value);
    if (value === 0) {
      history(`/new-intervention?slug=${selectedSubModule}`);
    } else if (value === 1)
      dispatch(
        getReadynessPhaseRequest({
          module: "early_action",
          project_id: data?.id,
          data: value,
        }),
      );
  };

  const isEditProjectSucess = useRef(true);
  useEffect(() => {
    if (isEditProjectSucess.current) {
      isEditProjectSucess.current = false;
      return;
    }
    if (nextProps.projectStatusMessage) {
      if (canActivatePlan) {
        if (data?.ea_rp_status) {
          // if (canEditEF || data.ef_currency_id !== null) {
          // setIsEarlyFinance(true);
          setStepSize(2);
          localStorage.setItem("stepperActiveStep", "2");
          localStorage.setItem(
            "projectDetails",
            JSON.stringify({
              ...data,
              ea_ap_status: true,
            }),
          );
          if (canEditEF || data.ef_currency_id !== null) {
            setIsEarlyFinance(true);
          } else {
            showError(t("VALIDATION.EF_EDIT_PERMISSION"));
          }
        } else if (!data?.ea_rp_status) {
          localStorage.setItem(
            "projectDetails",
            JSON.stringify({
              ...data,
              ea_rp_status: true,
            }),
          );
          setIsActiveModal(true);
        }
      } else {
        if (!data?.ea_rp_status) {
          localStorage.setItem(
            "projectDetails",
            JSON.stringify({
              ...data,
              ea_rp_status: true,
            }),
          );
        }
        showError(t("VALIDATION.ACTIVATE_PHASE_VALIDATION_MESSAGE"));
      }
    }
  }, [nextProps.projectStatusMessage]);

  // when click on proceed this function executed
  const onHandleUpdateStatus = () => {
    if (data.ea_ap_status) {
      canEditEF || data.ef_currency_id !== null
        ? setIsEarlyFinance(true)
        : showError(t("VALIDATION.EF_EDIT_PERMISSION"));

      // when it is already activated
    } else {
      if (canActivatePlan) {
        if (data?.ea_rp_status) {
          setIsActiveModal(true);
        } else {
          const payload = {
            project_id: data?.id,
            ea_rp_status: true,
          };
          dispatch(projectStatusRequest(payload));
        }
      } else {
        if (data?.ea_rp_status) {
          showError(t("VALIDATION.ACTIVATE_PHASE_VALIDATION_MESSAGE"));
        } else {
          const payload = {
            project_id: data?.id,
            ea_rp_status: true,
          };
          dispatch(projectStatusRequest(payload));
        }
      }
    }
  };

  const onHandleConfirmation = () => {
    if (projectDetails?.ea_ap_status) {
      setIsActiveModal(false);
      localStorage.setItem("stepperActiveStep", "2");
      setStepSize(2);
    } else {
      const payload = {
        project_id: projectDetails?.id,
        ea_pp_status: true,
        ea_rp_status: true,
        ea_ap_status: true,
      };
      dispatch(projectStatusRequest(payload));
    }
  };
  const onHandleNewIntervention = () => {
    if (interventationList?.length >= 15) {
      showError(t("VALIDATION.INTERVENTION_LIMIT_ERROR"));
    } else {
      history(`/new-intervention?slug=${selectedSubModule}`);
    }
  };
  return (
    <>
      <div className="">
        <div className="ea-space ea-space-b">
          <div className="readiness-div">
            <div
              className={
                interventationList?.length
                  ? "readinness-heading"
                  : "readinness-heading-no-data"
              }
            >
              <h2 className="readiness-h2">
                {" "}
                {t("EARLY_ACTIONS.PLANNED_INTERVENTION")}
              </h2>
              {(isPermissionsMatch(permissionsList, ADD_INTERVENTION) ||
                superAdmin()) &&
              !isAllStepsCompleted ? (
                <Button
                  className="readiness-btn"
                  onClick={() => onHandleNewIntervention()}
                >
                  {t("EARLY_ACTIONS.ADD_NEW_INTERVENTION")}
                  <BsPlusLg className="ml-2" />
                </Button>
              ) : (
                ""
              )}
            </div>
            <Row className="pred-row">
              {Object?.entries(interventationList).map(([, value]) => {
                return (
                  <Col lg={4} className="read-col">
                    <div
                      className="readiness-project-card pointer"
                      role="button"
                      tabIndex="0"
                      onKeyUp={() => {}}
                    >
                      <div className="project-card-section-1">
                        <img
                          src={value?.icon_url ? value?.icon_url : ICON}
                          alt=""
                          className="mt-2 risk-card-image"
                        />
                        <p className="intervation-card-text mt-3 mb-2">
                          {value?.name}
                        </p>
                      </div>
                      <div className="project-card-section-2">
                        <div className="card-showing">
                          <div className="">
                            <img
                              src={ViewIcon}
                              alt="View"
                              onKeyDown={() => {}}
                              onClick={() =>
                                history(
                                  `/view-intervention?interventionId=${value.id}&slug=${selectedSubModule}`,
                                )
                              }
                            />
                          </div>
                          {(isPermissionsMatch(
                            permissionsList,
                            EDIT_INTERVENTION,
                          ) ||
                            superAdmin()) &&
                          !isAllStepsCompleted ? (
                            <div className="ml-4 mr-2">
                              <img
                                src={EditIcon}
                                alt="delete"
                                onKeyDown={() => {}}
                                onClick={() =>
                                  history(
                                    `/risk-forecasting?interventionId=${value?.id}&default=${defaultValue}&name=${value.name}&slug=${selectedSubModule}`,
                                  )
                                }
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {(isPermissionsMatch(
                            permissionsList,
                            DELETE_INTERVENTION, // CAN_aCTIVATE_PLAN
                          ) ||
                            superAdmin()) &&
                          !isAllStepsCompleted ? (
                            <div className="ml-2 mr-2">
                              <img
                                src={DeleteIcon}
                                alt="delete"
                                onKeyDown={() => {}}
                                onClick={() => {
                                  setIsDeleteModal(true);
                                  setIsInterventionid(value?.id);
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>

          <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
            {!_.isEmpty(projectDetails) && !projectDetails?.ea_rp_status ? (
              <div className="mb-3">
                <input
                  type="checkbox"
                  id="box"
                  onClick={() => setIsTermsAccepted(!isTermsAccepted)}
                />
                <p className="ea-checkbox-text">
                  {t("EARLY_ACTIONS.READYNESS_SUBMIT_TEXT")}
                </p>
              </div>
            ) : (
              ""
            )}
            <Button
              className="ea-readyness-proceed-next-btn"
              onClick={onHandleUpdateStatus}
              disabled={
                !interventationList.length ||
                (!isTermsAccepted && !projectDetails?.ea_rp_status)
              }
            >
              {t("EARLY_ACTIONS.PROCEED")}
            </Button>
          </div>
        </div>
      </div>
      {isDeleteModal ? (
        <DeleteConfirmationModal
          modal={isDeleteModal}
          toggle={() => setIsDeleteModal(false)}
          title={t("EARLY_ACTIONS.DELETE_INTERVENTION")}
          message={t("EARLY_ACTIONS.DELETE_ARE_SURE")}
          onConfirmDelete={onDeleteConfirmation}
        />
      ) : (
        ""
      )}
      {isActiveModal ? (
        <ActiveModal
          deleteModal={isActiveModal}
          setDeleteModal={() => setIsActiveModal(!isActiveModal)}
          projectDetails={projectDetails}
          title={t("EARLY_ACTIONS.ACTIVATE_YOUR_PLAN")}
          message={`${t("EARLY_ACTIONS.ACTIVATE_CONFIRMATION_MESSAGE")}  ${
            projectDetails?.district_name
          } ${projectDetails?.risk_type_name} ${t("EARLY_ACTIONS.ALERT")}`}
          onHandleConfirmation={onHandleConfirmation}
        />
      ) : (
        ""
      )}

      {reviewModal ? (
        <ReviewModal
          isOpenModal={reviewModal}
          setIsOpenModal={() => setReviewModal(!reviewModal)}
          setDefaultValue={setDefaultValue}
          reviewModal={reviewModal}
          handleMethod={handleDefaultIntervention}
        />
      ) : (
        ""
      )}

      {isEarlyFinance ? (
        <ActivePhaseModal
          modal={isEarlyFinance}
          toggle={() => {
            setIsEarlyFinance(false);
            history(`/early-finance-page?slug=early_finance`);
          }}
          cancle={() => setIsEarlyFinance(false)}
          projectDetails={projectDetails}
        />
      ) : (
        ""
      )}
    </>
  );
}
export default ReadinessPhase;
