import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import successCheckmark from "../../assest/img/successCheckmark.svg";

function SuccessConfirmationModal({ toggle, modal, cancle, projectDetails }) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={modal} size="md">
      <div className="success-modal">
        <ModalHeader className="p-0 delete-header user-flex-end mr-2 mt-5" />
        <ModalBody className="delete-modal-padding">
          <div className="reg-success">
            <img
              src={successCheckmark}
              alt="not-available"
              className="reg-check-image"
            />
            <h2 className="back-office-succes-head-text">
              {t("EARLY_ACTIONS.PLAN_ACTIVATED")}
            </h2>
            <p className="back-office-succes-desc pl-4 pr-4 mt-2">
              {`${t("EARLY_ACTIONS.EARLY_WARNING_PLAN_MESSAGE")} ${
                projectDetails?.district_name
              } ${projectDetails?.risk_type_name}
              ${t("EARLY_ACTIONS.ALERT")}`}
            </p>
            <div className="w-100 active-pahse-btn-section">
              <Button
                type="button"
                className="cancle-btn-active-modal mr-3"
                onClick={() => {
                  cancle();
                }}
              >
                {t("EARLY_ACTIONS.CANCLE")}
              </Button>
              <Button
                type="button"
                className="delete-confirmation-ok-btn mb-1"
                onClick={() => {
                  toggle();
                }}
              >
                {t("EARLY_FINANCE.EARLY_FINANCE")}
              </Button>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default SuccessConfirmationModal;
