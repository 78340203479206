import React, { useCallback, useEffect, useState, Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";

import { BsInfo } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import _ from "lodash";
import moment from "moment";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  getEWRawDataDatesRequest,
  getMarketTimeSeriesRequest,
  mapPreferenceRequest,
  getMarketMapData,
  mapPreferenceSuccess,
  getMarketMapDataSuccess,
  getMarketCommodityData,
  getMarketTimeSeriesSuccess,
} from "../../store/actions";
// import Loader from "../../components/Common/Loader";
import CommonThresholdIndicators from "../../components/Common/CommonThresholdIndicators";
import MarketGraph from "./Graph";
import MarketTable from "./Table";
import { debounce } from "../../helpers/index";
import { MonthsList, sectorialClimateId } from "../../constants";

// lazy loading components
const HealthMapContainer = lazy(() => import("../Health/MapContainer"));
const HealthFilter = lazy(() => import("../Health/HealthFilter"));

function Market() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [, setOpen] = useOutletContext();
  const paramsData = new URLSearchParams(window.location.search);
  const selectedModule = paramsData.get("subModule");
  const selectedSubModule = paramsData.get("module");
  const [local, setLocal] = useState(
    JSON.parse(localStorage.getItem("selectedCountry")),
  );
  const [position, setPosition] = useState([]);
  const [zooms, setZooms] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [indexLayers, setIndexLayers] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [allFieldsData, setAllFieldsData] = useState({});
  const [monthArrayList, setMonthArrayList] = useState([]);

  const [openScreen, setOpenScreen] = useState(false);
  const [isShowTableView, setIsShowTableView] = useState(false);
  const [tabsData, setTabsData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [malariaGeoJsonData, setMalariaGeoJsonData] = useState([]);
  const [mapes, setMapes] = useState();
  const [selectedLegendData, setSelectedLegendData] = useState([]);
  const [initialSelectedDate, setInitialSelectedDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [rawDates, setRawDates] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedState, setSelecetedState] = useState({});
  // const [initialLoader, setInitialLoader] = useState(true);
  const [maxTableData, setMaxTableData] = useState({});
  const [selectedPrice, setSelectedPrice] = useState("Local");
  const history = useNavigate();

  const nextProps = useSelector((state) => ({
    mapPreferenceList: state.MapPreference,
    Weather: state.Weather,
    Graph: state?.Weather?.weatherForecastGraphData,
    isCompareLocation: state.Core.coredistrictList?.config?.isCompareLocation,
    marketTimeseriesData: state.Market.marketTimeseriesData,
    marketTimeSeriesError: state.Market.marketTimeSeriesError,
    EWRawDataDates: state.Health.getRawDataDates,
    getRawDataDatesError: state.Health.getRawDataDatesError,
    marketMapData: state.Market.marketMapData,
    marketMapDataError: state.Market.marketMapDataError,
    Health: state.Health,
    isMarketMapDataRequesting: state.Market.isMarketMapDataRequesting,
    marketCommodityData: state.Market.marketCommodityData,
  }));

  const getDatesBasedOnCommodity = (commodityData) => {
    if (commodityData?.min_year) {
      const dates = { ...nextProps.EWRawDataDates };
      dates.min_year = commodityData.min_year;
      dates.max_year = commodityData.max_year;
      dates.is_monthly = commodityData.is_monthly;
      dates.is_daily = commodityData.is_daily;
      dates.avialble_years = commodityData.available_years;
      dates.avialble_months = commodityData.avialble_months;
      setRawDates(dates);
    }
  };

  // handles price switch
  const updatePriceValue = (val) => {
    setMalariaGeoJsonData([]);
    if (val.value === "Both") {
      setGraphData([]);
    }
    setSelectedPrice(val.value);
  };

  useEffect(() => {
    if (nextProps.marketCommodityData?.length && nextProps?.EWRawDataDates) {
      const firstCommodity = nextProps.marketCommodityData[0];
      getDatesBasedOnCommodity(firstCommodity);
    }
  }, [nextProps?.EWRawDataDates, nextProps.marketCommodityData]);

  // For getting Selected Country and updating map initial load
  useEffect(() => {
    window.scrollTo(0, 0);
    // setTimeout(() => {
    //   setInitialLoader(false);
    // }, 3000);

    const CountryData = JSON.parse(localStorage.getItem("selectedCountry"));
    if (CountryData) {
      setPosition([local.latitude, local.longitude]);
      setZooms(local.zoom_level);
      setOpen(true);
      setLocal(JSON.parse(localStorage.getItem("selectedCountry")));
      dispatch(
        mapPreferenceRequest({
          country_id: JSON.parse(localStorage.getItem("selectedCountry"))
            ?.value,
          module: selectedModule,
        }),
      );
      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: CountryData,
          state: "",
          district: "",
        };
      });
      return () => {
        dispatch(mapPreferenceSuccess(""));
        dispatch(getMarketMapDataSuccess(""));
        dispatch(getMarketTimeSeriesSuccess({}));
        setOpen(false);
      };
    } else {
      history("/climber");
    }
  }, []);

  const onHandleZoom = (center, zoom) => {
    setPosition(center);
    setZooms(zoom);
  };

  // function to call the selected distric and state
  const handleSelectedLocation = (filterLocation) => {
    setSelectedLocation(filterLocation);
  };

  const getEarlyWarningRawDataDates = (countryId) => {
    const payload = {
      countryId,
      moduleName: selectedModule,
      subModuleSlug: selectedSubModule,
    };
    dispatch(getEWRawDataDatesRequest(payload));
  };

  const getCommoditiesData = (data) => {
    const payload = {
      countryId: data.countryId,
      stateId: data.stateId,
      districtId: data.districtId,
    };
    dispatch(getMarketCommodityData(payload));
  };

  function generateMonthYearArray(startDate, endDate) {
    const monthYearArray = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      const monthYearString = `${month}-${year}`;
      monthYearArray.push(monthYearString);
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    // setMonthArrayList(monthArrayList);
    return monthYearArray;
  }

  useEffect(() => {
    getEarlyWarningRawDataDates(
      JSON.parse(localStorage.getItem("selectedCountry"))?.value,
    );
    getCommoditiesData({
      countryId: JSON.parse(localStorage.getItem("selectedCountry"))?.value,
    });
  }, []);

  // function for handle all fields data and based fields data calling api's
  const handleAllFieldsData = (data, selectedTabIndex) => {
    const { country, region, district, dates, commodity } = data;

    const startDate = dates?.length ? dates[0] : null;
    const endDate = dates?.length > 1 ? dates[1] : null;

    const available_months = commodity ? commodity?.avialble_months : null;
    const available_years = commodity ? commodity?.available_years : null;

    const payload = {
      country_id: country?.value,
      state_id: region ? region.value : "",
      district_id: district ? district.value : "",
      start_year: new Date(startDate).getFullYear(),
      end_year: new Date(endDate).getFullYear(),
      start_month: new Date(startDate).getMonth() + 1,
      end_month: new Date(endDate).getMonth() + 1,
      start_day: "",
      end_day: "",
      graph_type: "table_view",
      sub_menu_slug: selectedSubModule,
      market_commodity: commodity ? [commodity.value] : null,
    };

    // map view only we are doing these chnages
    const a = moment([
      new Date(startDate).getFullYear(),
      new Date(startDate).getMonth(),
    ]);
    const b = moment([
      new Date(endDate).getFullYear(),
      new Date(endDate).getMonth(),
    ]);

    const monthsData =
      available_months && available_months[new Date(startDate)?.getFullYear()];

    let tabsFinalData = [];
    const totalMonthDiff1 =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      endDate.getMonth() -
      startDate.getMonth();
    // for checking if it is monthly data or not
    if (totalMonthDiff1 <= 11) {
      if (b.diff(a, "year", true) < 1) {
        if (b.diff(a, "month", true) > 0) {
          if (
            new Date(startDate).getFullYear() ===
            new Date(endDate).getFullYear()
          ) {
            tabsFinalData = {
              type: "months",
              data: MonthsList.slice(
                new Date(startDate).getMonth(),
                new Date(endDate).getMonth() + 1,
              ).map((value) => {
                if (monthsData.includes(value.value + 1)) {
                  return {
                    ...value,
                    start_year: new Date(startDate).getFullYear(),
                    end_year: new Date(endDate).getFullYear(),
                  };
                } else {
                  return false;
                }
              }),
            };
          } else {
            // in this case start year and end year should be different
            const endDateMonthsData =
              available_months &&
              available_months[new Date(endDate)?.getFullYear()]; // months data for end date

            tabsFinalData = {
              type: "months",
              data: MonthsList.slice(new Date(startDate).getMonth(), 12)
                .map((value) => {
                  if (monthsData.includes(value.value + 1)) {
                    return {
                      ...value,
                      start_year: new Date(startDate).getFullYear(),
                      end_year: new Date(startDate).getFullYear(),
                    };
                  } else {
                    return false;
                  }
                })
                .concat(
                  MonthsList.slice(0, new Date(endDate).getMonth() + 1).map(
                    (value) => {
                      if (endDateMonthsData.includes(value.value + 1)) {
                        return {
                          ...value,
                          start_year: new Date(endDate).getFullYear(),
                          end_year: new Date(endDate).getFullYear(),
                        };
                      } else {
                        return false;
                      }
                    },
                  ),
                ),
            };
          }
        } else if (b.diff(a, "month", true) === 0) {
          tabsFinalData = {
            type: "months",
            data: MonthsList.filter(
              (item) => item.value === new Date(startDate).getMonth(),
            ).map((value) => {
              if (monthsData.includes(value.value + 1)) {
                return {
                  ...value,
                  start_year: new Date(startDate).getFullYear(),
                  end_year: new Date(endDate).getFullYear(),
                };
              } else {
                return false;
              }
            }),
          };
        }
      } else if (b.diff(a, "year", true) === 1) {
        if (
          new Date(startDate).getFullYear() === new Date(endDate).getFullYear()
        ) {
          tabsFinalData = {
            type: "months",
            data: MonthsList.map((value) => {
              if (monthsData.includes(value.value + 1)) {
                return {
                  ...value,
                  start_year: new Date(startDate).getFullYear(),
                  end_year: new Date(endDate).getFullYear(),
                };
              } else {
                return false;
              }
            }),
          };
        } else {
          // in this case start year and end year should be different
          const endDateMonthsData =
            available_months?.length &&
            available_months[new Date(endDate).getFullYear()]; // months data for end date
          tabsFinalData = {
            type: "months",
            data: MonthsList.slice(new Date(startDate).getMonth(), 12)
              .map((value) => {
                if (monthsData.includes(value.value + 1)) {
                  return {
                    ...value,
                    start_year: new Date(startDate).getFullYear(),
                    end_year: new Date(startDate).getFullYear(),
                  };
                } else {
                  return false;
                }
              })
              .concat(
                MonthsList.slice(0, new Date(endDate).getMonth()).map(
                  (value) => {
                    if (endDateMonthsData.includes(value.value + 1)) {
                      return {
                        ...value,
                        start_year: new Date(endDate).getFullYear(),
                        end_year: new Date(endDate).getFullYear(),
                      };
                    } else {
                      return false;
                    }
                  },
                ),
              ),
          };
        }
      }
      const monthsArrayList1 = generateMonthYearArray(startDate, endDate);
      setMonthArrayList(monthsArrayList1);
    } else {
      setMonthArrayList([]);
      const years = [];
      for (let i = 0; i <= b.diff(a, "year", true).toFixed(2); i += 1) {
        if (
          available_years?.length &&
          available_years.includes(new Date(startDate).getFullYear() + i)
        ) {
          years.push({
            value: new Date(startDate).getFullYear() + i,
            label: new Date(startDate).getFullYear() + i,
          });
        }
      }
      tabsFinalData = {
        type: "years",
        data: years,
        start_month: new Date(startDate).getMonth() + 1,
        end_month: new Date(endDate).getMonth() + 1,
      };
    }

    const mapPayload = {
      country_id: country?.value,
      state_id: region ? region.value : "",
      district_id: district ? district.value : "",
      start_day: "",
      end_day: "",
      sub_menu_slug: selectedSubModule,
      population_crisis_type: "",
      market_commodity: commodity ? [commodity.value] : null,
    };
    const availableTabsData = tabsFinalData?.data?.filter(
      (tab) => tab !== false,
    );
    if (availableTabsData && availableTabsData?.length) {
      if (tabsFinalData.type === "years") {
        const selcetedYear = availableTabsData[selectedTabIndex || 0]?.value;
        mapPayload.start_year = selcetedYear;
        mapPayload.end_year = selcetedYear;
        mapPayload.start_month = 1;
        mapPayload.end_month =
          selcetedYear === new Date(endDate).getFullYear()
            ? tabsFinalData.end_month
            : 12;
      } else {
        mapPayload.start_year =
          availableTabsData.length > 1
            ? availableTabsData[selectedTabIndex || 0]?.start_year
            : availableTabsData[0]?.start_year;
        mapPayload.end_year =
          availableTabsData.length > 1
            ? availableTabsData[selectedTabIndex || 0]?.end_year
            : availableTabsData[0]?.end_year;
        mapPayload.start_month =
          availableTabsData.length > 1
            ? availableTabsData[selectedTabIndex || 0]?.value + 1
            : availableTabsData[0].value + 1;
        mapPayload.end_month =
          availableTabsData.length > 1
            ? availableTabsData[selectedTabIndex || 0]?.value + 1
            : availableTabsData[0].value + 1;
      }
      setTabIndex(selectedTabIndex || 0);
      setInitialSelectedDate((prev) => {
        return {
          ...prev,
          startDate: selectedTabIndex
            ? availableTabsData[selectedTabIndex || 0]
            : availableTabsData[0],
        };
      });

      setTabsData(tabsFinalData);

      setAllFieldsData({
        ...data,
        payload,
        isShowAllYears: tabsFinalData.type === "years",
      });
    }
    if (
      payload?.start_year &&
      payload?.end_year &&
      payload?.market_commodity[0]
    ) {
      if (new Date(startDate) <= new Date(endDate)) {
        dispatch(getMarketTimeSeriesRequest(payload));
        if (mapPayload?.start_year && mapPayload?.end_year) {
          dispatch(getMarketMapData(mapPayload));
        }
      }
      const legendData = [...selectedLegendData];
      if (legendData?.length) {
        legendData.map((preLegend) => {
          preLegend.add = false;
          return preLegend;
        });
        setSelectedLegendData(legendData);
      }
      // Resetting geoJson data
      if (malariaGeoJsonData?.length) {
        setMalariaGeoJsonData([]);
      }
    }
  };

  const optimizedFn = useCallback(debounce(handleAllFieldsData), []);

  useEffect(() => {
    if (nextProps.marketTimeseriesData) {
      let output = {};
      if (!_.isEmpty(selectedState)) {
        // For showing only data for selected state on map
        const selectedStateData = nextProps.marketTimeseriesData?.filter(
          (item) => item?.state_name === selectedState?.state_name,
        );
        output = _(selectedStateData).groupBy("state_name").value();
      } else {
        output = _(nextProps.marketTimeseriesData)
          .groupBy("state_name")
          .value();
      }

      let dataBaseonRegion = [];
      if (allFieldsData.isShowAllYears) {
        dataBaseonRegion = Object.keys(output).map((item) => {
          return {
            state_name: item,
            data: _(output[item])
              .groupBy("year")
              .map((objs, key) => {
                return {
                  year: key,
                  state_name: item,
                  price: Number(_.sumBy(objs, "price").toFixed(2)),
                  usd_price: Number(_.sumBy(objs, "usd_price").toFixed(2)),
                  price_thresold: objs[0]?.price_thresold
                    ? objs[0]?.price_thresold
                    : "",
                  usd_price_thresold: objs[0]?.usd_price_thresold
                    ? objs[0]?.usd_price_thresold
                    : "",
                };
              })
              .value(),
          };
        });
        setTableData(dataBaseonRegion);
      } else {
        dataBaseonRegion = Object.keys(output).map((region) => {
          return {
            data: output[region],
            state_name: region,
          };
        });
        setTableData(dataBaseonRegion);
      }

      // setTableData(dataBaseonRegion);
      if (allFieldsData.isShowAllYears) {
        let data = [];
        if (!_.isEmpty(selectedState)) {
          // For showing only data for selected state on map
          const selectedStateData = nextProps.marketTimeseriesData?.filter(
            (item) => item?.state_name === selectedState?.state_name,
          );
          data = _(selectedStateData)
            .groupBy("year")
            .map((objs, key) => {
              return {
                year: key,
                month: 1,
                price: Number(_.sumBy(objs, "price").toFixed(2)),
                usd_price: Number(_.sumBy(objs, "usd_price").toFixed(2)),
              };
            })
            .value();
        } else {
          data = _(nextProps.marketTimeseriesData)
            .groupBy("year")
            .map((objs, key) => {
              return {
                year: key,
                month: 1,
                price: Number(_.sumBy(objs, "price").toFixed(2)),
                usd_price: Number(_.sumBy(objs, "usd_price").toFixed(2)),
              };
            })
            .value();
        }
        setGraphData(data);
      } else {
        if (!_.isEmpty(selectedState)) {
          // For showing only data for selected state on map
          const selectedStateData = nextProps.marketTimeseriesData?.filter(
            (item) => item?.state_name === selectedState?.state_name,
          );
          setGraphData(selectedStateData);
        } else {
          setGraphData(nextProps.marketTimeseriesData);
        }
      }
    }
  }, [nextProps.marketTimeseriesData, selectedState, selectedPrice]);

  useEffect(() => {
    if (
      nextProps.getRawDataDatesError?.length ||
      nextProps.marketTimeSeriesError?.length
    ) {
      setGraphData([]);
    }
  }, [nextProps.getRawDataDatesError, nextProps.marketTimeSeriesError]);

  useEffect(() => {
    if (nextProps.isMarketMapDataRequesting) {
      setTableData([]);
      setMaxTableData({});
    } else if (nextProps.marketMapData && nextProps.marketMapData?.length) {
      // setIsLoading(false);
      const dataArray = [];
      nextProps.marketMapData?.map((item) => {
        // if (item?.xof_price !== undefined) {
        dataArray.push(item);
        // }
        return null;
      });
      if (dataArray?.length) {
        setMalariaGeoJsonData(dataArray);
      }
    } else if (!_.isEmpty(nextProps.marketMapDataError)) {
      const { code } = nextProps.marketMapDataError;
      if (code === 400) {
        setMalariaGeoJsonData([]);
      }
    }
  }, [
    nextProps.marketMapData,
    nextProps.marketMapDataError,
    nextProps.isMarketMapDataRequesting,
    selectedPrice,
  ]);

  const handleInfo = () => {
    const name = "Food Prices";
    window.open(
      `/user-guide-details?topicId=${sectorialClimateId}&sub-topic-name=${name}&slug=user_guide`,
    );
  };

  return (
    <div className="dashboard-filter-div health-map">
      {/* {initialLoader && <Loader isSuspense indicator />} */}
      <Suspense fallback="">
        <div className="dashboard-filter">
          <span className="dashboard-filter-h3">{t("MARKET.TITLE")}</span>
          <span className="m-1 font-weight-8">:</span>
          <h3
            className="dashboard-filter-h3"
            style={{ textTransform: "capitalize" }}
          >
            {t("MARKET.FOOD_PRICES")}
          </h3>
          <BsInfo
            className="info-icon cursor-pointer ml-2 mb-2"
            onClick={() => handleInfo()}
          />

          {isShowTableView && <CommonThresholdIndicators />}
        </div>
        <Row className="dashboard-drought-section dashboard-health-section">
          <Col
            lg={12}
            className={
              openScreen
                ? "dashboard-drought-col2 pr-0 fullmap"
                : "dashboard-drought-col2 pr-0"
            }
          >
            <HealthFilter
              openScreen={openScreen}
              setOpenScreen={setOpenScreen}
              isShowTableView={isShowTableView}
              setIsShowTableView={setIsShowTableView}
              setPosition={setPosition}
              setZooms={setZooms}
              handleAllFieldsData={optimizedFn}
              EWRawDataDates={rawDates}
              getEarlyWarningRawDataDates={getEarlyWarningRawDataDates}
              defaultCountry={JSON.parse(
                localStorage.getItem("selectedCountry"),
              )}
              setSelectedLegendData={setSelectedLegendData}
              selectedLegendData={selectedLegendData}
              malariaGeoJsonData={malariaGeoJsonData}
              setMalariaGeoJsonData={setMalariaGeoJsonData}
              handleSelectedLocation={handleSelectedLocation}
              setSelecetedState={setSelecetedState}
              selectedState={selectedState}
              tabIndex={tabIndex}
              selectedModule={selectedModule}
              selectedSubModule={selectedSubModule}
              commoditiesList={nextProps?.marketCommodityData}
              getCommoditiesData={getCommoditiesData}
              getDatesBasedOnCommodity={getDatesBasedOnCommodity}
              setTabIndex={setTabIndex}
              hideTableView={!tableData?.length}
              updatePriceValue={updatePriceValue}
              priceSwitchValues={[
                {
                  label: nextProps?.marketTimeseriesData?.length
                    ? nextProps?.marketTimeseriesData[0]?.price_currency
                    : "",
                  value: "Local",
                },
                { label: "USD", value: "NonLocal" },
                { label: "Both", value: "Both" },
              ]}
              defaultPrice={
                selectedPrice
                  ? [
                      {
                        label: nextProps?.marketTimeseriesData?.length
                          ? nextProps?.marketTimeseriesData[0]?.price_currency
                          : "",
                        value: "Local",
                      },
                      { label: "USD", value: "NonLocal" },
                      { label: "Both", value: "Both" },
                    ].find((val) => val.value === selectedPrice)
                  : {
                      label: nextProps?.marketTimeseriesData?.length
                        ? nextProps?.marketTimeseriesData[0]?.price_currency
                        : "",
                      value: "Local",
                    }
              }
              setSelectedPrice={setSelectedPrice}
            />
            {isShowTableView && (
              <MarketTable
                data={{
                  tableData,
                  avilableDates: tabsData,
                }}
                tabIndex={tabIndex}
                allFieldsData={allFieldsData}
                selectedModule={selectedModule}
                colSpan={2}
                EWRawDataDates={nextProps?.EWRawDataDates}
                initialSelectedDate={initialSelectedDate}
                priceCurrency={
                  nextProps?.marketTimeseriesData?.length
                    ? nextProps?.marketTimeseriesData[0]?.price_currency
                    : ""
                }
                setMaxTableData={setMaxTableData}
                maxTableData={maxTableData}
                selectedPrice={selectedPrice}
                monthArrayList={monthArrayList}
                setMonthArrayList={setMonthArrayList}
              />
            )}
            <HealthMapContainer
              position={position}
              zooms={zooms}
              onHandleZoom={onHandleZoom}
              local={local}
              selectedLocation={selectedLocation}
              indexLayers={indexLayers}
              setIndexLayers={setIndexLayers}
              mapPreferencesData={mapPreferencesData}
              setMapPreferencesData={setMapPreferencesData}
              malariaGeoJsonData={malariaGeoJsonData}
              mapes={mapes}
              setMapes={setMapes}
              selectedLegendData={selectedLegendData}
              setSelectedLegendData={setSelectedLegendData}
              initialSelectedDate={initialSelectedDate}
              avilableYears={tabsData}
              handleAllFieldsData={optimizedFn}
              allFieldsData={allFieldsData}
              setMalariaGeoJsonData={setMalariaGeoJsonData}
              setSelecetedState={setSelecetedState}
              isShowTableView={isShowTableView}
              selectedSubModule={selectedSubModule}
              legendName={_.capitalize(selectedModule)}
              selectedModule={selectedModule}
              parameterName={`${_.startCase(
                selectedSubModule.split("_").join(" "),
              )} - ${allFieldsData?.commodity?.value}`}
              tabIndex={tabIndex}
              selectedPrice={selectedPrice}
              regionsData={nextProps?.EWRawDataDates?.regions}
              showLoader={nextProps.isMarketMapDataRequesting}
              setOpen={setOpen}
            />
          </Col>

          <Col
            lg={12}
            className={
              openScreen
                ? "graph-health pr-0 mt-3 graph-dn"
                : "graph-health pr-0 mt-3"
            }
          >
            {graphData?.length ? (
              <MarketGraph
                openScreen={openScreen}
                setOpenScreen={setOpenScreen}
                graphData={graphData}
                healthCategorySlug={selectedSubModule}
                allFieldsData={allFieldsData}
                selectedState={selectedState}
                priceCurrency={
                  nextProps?.marketTimeseriesData?.length
                    ? nextProps?.marketTimeseriesData[0]?.price_currency
                    : ""
                }
                tabsData={tabsData}
                selectedPrice={selectedPrice}
              />
            ) : (
              <div className="mt-2 text-center">
                {t("HEALTH.NO_GRAPH_DATA")}
              </div>
            )}
          </Col>
        </Row>
      </Suspense>
    </div>
  );
}

export default React.memo(Market);
