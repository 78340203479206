import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import _ from "lodash";

import { ReactComponent as CategoryIcon } from "../../../assest/img/Category.svg";
import { ReactComponent as Impacts } from "../../../assest/img/Impacts.svg";
import { ReactComponent as Anticipatory } from "../../../assest/img/Anticipatory-action.svg";
import { ReactComponent as ActionPlan } from "../../../assest/img/Action-plan.svg";
import {
  PREPAREDNESS_PHASE_SLUGS,
  ADD_INSTANCE,
  EDIT_INSTANCE,
  DELETE_INSTANCE,
} from "../../../constants";
import {
  getPreparednessPhaseRequest,
  editPreparednessContentSuccess,
  deletePreparednessContentSuccess,
  getPreparednessPhaseSuggestionsRequest,
} from "../../../store/actions";
import CategoriesComponent from "./Categories";
import ImpactsComponent from "./Impacts";
import PossibleAnticipatoryComponent from "./PossibleAnticipatoryActions";
import AnticipatoryActionPhaseComponent from "./AnticipatoryActionsPhase";
import ReviewModal from "../../../components/Common/ReviewModal";
import {
  isPermissionsMatch,
  superAdmin,
} from "../../../components/Common/Utils";

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#363636",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#22AD7A",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    backgroundColor: "#363636",
    opacity: "0.3",
    marginRight: "10%",
    marginLeft: "10%",
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));

// Customizing Icons
const ColorlibStepIconRoot = styled("div")(({ ownerState }) => ({
  backgroundColor: "#363636",
  opacity: "0.3",
  zIndex: 1,
  color: "#fff",
  width: 36,
  height: 36,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    opacity: "1",
    backgroundColor: "#D29433",
  }),
  ...(ownerState.completed && {
    opacity: "1",
    backgroundColor: "#22AD7A",
  }),
}));

// Adding custom Icons
function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  const icons = {
    1: <CategoryIcon />,
    2: <Impacts />,
    3: <Anticipatory />,
    4: <ActionPlan />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

function PreparednessPhase({
  setStepSize,
  isAllStepsCompleted,
  permissionsList,
  setOpen,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [nextStep, setnextStep] = useState(0);
  const [completedStep, setCompletedStep] = useState({});
  const [preparednessPhaseData, setPreparednessPhaseData] = useState([]);
  const [slugType, setSlugType] = useState("");
  const [editableRowKeys, setEditableRowKeys] = useState({});
  const [params, setParams] = useState("");
  const [preparednessPhaseDataOptions, setPreparednessPhaseDataOptions] =
    useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const steps = [
    t("EARLY_ACTIONS.CATEGORIES"),
    t("EARLY_ACTIONS.IMPACTS"),
    t("EARLY_ACTIONS.ACTIONS"),
    t("EARLY_ACTIONS.ANTICIPATORY_ACTION_PLAN"),
  ];
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [addInstance, setAddInstance] = useState(false);
  const [editInstance, setEditInstance] = useState(false);
  const [deleteInstance, setDeleteInstance] = useState(false);
  const [projectData, setProjectData] = useState({});

  const nextProps = useSelector((state) => ({
    preparednessPhaseData: state?.EaProjects?.preparednessPhaseData,
    isUpdateAll: state?.EaProjects?.isUpdateAll,
    preparednessPhaseSuggestionsData:
      state?.EaProjects?.preparednessPhaseSuggestionsData,
    isTableUpdate: state.EAPreparednessPhaseReducer?.tableUpdate,
  }));

  useEffect(() => {
    const myParam = localStorage.getItem("isDefaultCategory");
    if (myParam) {
      setParams(myParam);
    }
    const slugData = PREPAREDNESS_PHASE_SLUGS.filter(
      (data) => data.id === activeStep,
    );
    setOpen(true);
    const data = JSON.parse(localStorage.getItem("projectDetails"));
    setProjectData(data);

    // Calling API when main stepper changes from other phase to preparedness phase
    if (!_.isEmpty(data) && myParam) {
      dispatch(
        getPreparednessPhaseRequest({
          slug: slugData[0]?.slug,
          project_id: data?.id,
          is_default: myParam,
          isUpdateAll: true,
        }),
      );
    }

    // Getting stepper Activestep based on back and stepper click
    const subStepperActiveStep = localStorage.getItem("subStepperActivePhase");
    setActiveStep(Number(subStepperActiveStep));

    // getting completed steps data
    if (data?.ea_pp_status) {
      setCompletedStep({ 0: true, 1: true, 2: true, 3: true });
    } else {
      const propertiesToInclude = [
        "ea_pp_category_status",
        "ea_pp_impact_status",
        "ea_pp_paa_status",
        "ea_pp_aap_status",
      ];
      const completedStepsObjects = {};
      // let stepper = 0;
      propertiesToInclude.forEach((property, index) => {
        // Check if the property exists in the API response
        if (data.hasOwnProperty(property)) {
          completedStepsObjects[index] = data[property];
          // stepper = index;
        }
      });
      // setActiveStep(stepper);
      setCompletedStep(completedStepsObjects);
    }

    if (permissionsList?.length || superAdmin()) {
      const addinstance =
        isPermissionsMatch(permissionsList, ADD_INSTANCE) || superAdmin();
      const deleteinstance =
        isPermissionsMatch(permissionsList, DELETE_INSTANCE) || superAdmin();
      const editinstance =
        isPermissionsMatch(permissionsList, EDIT_INSTANCE) || superAdmin();
      setAddInstance(addinstance);
      setDeleteInstance(deleteinstance);
      setEditInstance(editinstance);
    }
  }, [permissionsList]);

  const onHanldeDisable = (data) => {
    setIsDisable(data);
  };

  useEffect(() => {
    const slugData = PREPAREDNESS_PHASE_SLUGS.filter(
      (data) => data.id === activeStep,
    );
    setSlugType(slugData[0]?.slug);
    if (!_.isEmpty(projectData) && params) {
      dispatch(
        getPreparednessPhaseRequest({
          slug: slugData[0]?.slug,
          project_id: projectData?.id,
          is_default: params,
          isUpdateAll: true,
        }),
      );
      /**
       * Calling API for categories options in add new row
       */
      if (slugData[0]?.slug === "ea_pp_category") {
        dispatch(
          getPreparednessPhaseSuggestionsRequest({
            slug: slugData[0]?.slug,
          }),
        );
      }
    }
    return () => {
      dispatch(editPreparednessContentSuccess({}));
      dispatch(deletePreparednessContentSuccess({}));
    };
  }, [activeStep, projectData]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (!nextProps?.categoryLoader) {
      if (nextProps.preparednessPhaseData?.length) {
        setParams("false");
        // to scroll to bottom when the comments are added
        if (nextProps.isTableUpdate !== undefined) {
          nextProps?.isTableUpdate === false &&
            window.scrollTo(0, document.documentElement.scrollHeight);
        } else {
          window.scrollTo(0, 0);
        }

        // isTableUpdate: state.EAPreparednessPhaseReducer?.tableUpdate,
        setPreparednessPhaseData(nextProps?.preparednessPhaseData);
      } else {
        setPreparednessPhaseData([]);
      }
    }
  }, [nextProps.preparednessPhaseData]);

  const isSecondRun = useRef(true);
  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }
    if (nextProps.preparednessPhaseSuggestionsData?.length) {
      setPreparednessPhaseDataOptions(
        nextProps?.preparednessPhaseSuggestionsData,
      );
    } else {
      setPreparednessPhaseDataOptions([]);
    }
  }, [nextProps.preparednessPhaseSuggestionsData]);

  const handleNextMethod = (index) => {
    setActiveStep(index);

    const newCompleted = completedStep;
    newCompleted[index - 1] = true;
    setCompletedStep(newCompleted);

    setPreparednessPhaseData([]);
    setIsReviewModalOpen(false);
  };

  const handleNext = (index) => {
    if (projectData?.ea_pp_status) {
      if (index !== 4) {
        setActiveStep(index);
      } else {
        setStepSize(1);
        localStorage.setItem("stepperActiveStep", "1");
      }
    } else {
      if (index !== 4) {
        // Opening review modal
        if (completedStep[index === 0 ? 0 : index - 1]) {
          setActiveStep(index);
        } else {
          setIsReviewModalOpen(true);
          setnextStep(index);
        }
      } else {
        setStepSize(1);
        localStorage.setItem("stepperActiveStep", "1");
      }
    }
  };

  const handlePreviousStep = (index) => {
    if (projectData?.ea_pp_status) {
      setActiveStep(index);
    } else {
      if (completedStep[index]) {
        const newCompleted = completedStep;
        const keys = Object.keys(completedStep);
        keys.forEach((key) => {
          if (Number(key) >= index) {
            newCompleted[key] = false;
          }
        });
        setCompletedStep(newCompleted);
        setActiveStep(index);
      }
    }
  };

  // step contents
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <CategoriesComponent
            handleNext={handleNext}
            data={preparednessPhaseData}
            projectDetails={projectData}
            slugType={slugType}
            permissionsList={permissionsList}
            setEditableRowKeys={setEditableRowKeys}
            editableRowKeys={editableRowKeys}
            preparednessPhaseDataOptions={preparednessPhaseDataOptions}
            isDefaultData={params}
            addInstance={addInstance}
            deleteInstance={deleteInstance}
            editInstance={editInstance}
            isAllStepsCompleted={isAllStepsCompleted}
            isUpdateAll={nextProps?.isUpdateAll}
            setIsDisable={setIsDisable}
            onHanldeDisable={onHanldeDisable}
          />
        );
      case 1:
        return (
          <ImpactsComponent
            handleNext={handleNext}
            data={preparednessPhaseData}
            projectDetails={projectData}
            slugType={slugType}
            isUpdateAll={nextProps?.isUpdateAll}
            setEditableRowKeys={setEditableRowKeys}
            editableRowKeys={editableRowKeys}
            isDefaultData={params}
            addInstance={addInstance}
            deleteInstance={deleteInstance}
            editInstance={editInstance}
            isAllStepsCompleted={isAllStepsCompleted}
            onHanldeDisable={onHanldeDisable}
          />
        );
      case 2:
        return (
          <PossibleAnticipatoryComponent
            handleNext={handleNext}
            data={preparednessPhaseData}
            projectDetails={projectData}
            slugType={slugType}
            // comment={comment}
            setEditableRowKeys={setEditableRowKeys}
            editableRowKeys={editableRowKeys}
            isDefaultData={params}
            addInstance={addInstance}
            deleteInstance={deleteInstance}
            editInstance={editInstance}
            isAllStepsCompleted={isAllStepsCompleted}
            onHanldeDisable={onHanldeDisable}
          />
        );
      case 3:
        return (
          <AnticipatoryActionPhaseComponent
            handleNext={handleNext}
            data={preparednessPhaseData}
            projectDetails={projectData}
            slugType={slugType}
            setEditableRowKeys={setEditableRowKeys}
            permissionsList={permissionsList}
            editableRowKeys={editableRowKeys}
            isDefaultData={params}
            addInstance={addInstance}
            deleteInstance={deleteInstance}
            editInstance={editInstance}
            isAllStepsCompleted={isAllStepsCompleted}
            onHanldeDisable={onHanldeDisable}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  }

  return (
    <>
      <div className="m-3 preparedness-container">
        {isReviewModalOpen ? (
          <ReviewModal
            isOpenModal={isReviewModalOpen}
            setIsOpenModal={setIsReviewModalOpen}
            handleMethod={handleNextMethod}
            step={nextStep}
          />
        ) : (
          ""
        )}
        <div className="stepper-container">
          <Row>
            <Col md={11} className="mx-auto">
              <Stepper activeStep={activeStep} connector={<QontoConnector />}>
                {steps.map((label, index) => (
                  <Step key={label} completed={completedStep[index]}>
                    <StepLabel
                      StepIconComponent={ColorlibStepIcon}
                      onClick={() => handlePreviousStep(index)}
                      className={completedStep[index] ? "cursor-pointer" : ""}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Col>
          </Row>
        </div>
        <hr className="w-100 divider-line" />
        {activeStep === steps.length ? (
          ""
        ) : (
          <Row>
            <Col md={12} className="mx-auto mb-3">
              {/* Rendring steps content */}
              {getStepContent(activeStep)}
            </Col>
          </Row>
        )}
        {/* Hiding for now */}
      </div>
      {isDisable && <div className="ea-project-card-disabled " />}
    </>
  );
}

export default PreparednessPhase;
