import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import CompletedDisasterIcon from "../../assest/img/Finished-disaster.svg";
import CompletedFloodIcon from "../../assest/img/Finished-flood.svg";
import CompletedRainfallIcon from "../../assest/img/Finished-rainfall.svg";
import ActiveDisasterIcon from "../../assest/img/Unfinished-disaster.svg";
import ActiveRainfallIcon from "../../assest/img/Unfinished-rainfall.svg";
import ActiveFloodIcon from "../../assest/img/Unfinsihed-flood.svg";

function RapidResponseStepper({ activeStep, setStepSize, setIsStepperClick }) {
  const { t } = useTranslation();
  const location = useLocation();
  const [isStepperDisabled, setIsStepperDisabled] = useState(false);

  useEffect(() => {
    if (location?.pathname?.length) {
      location?.pathname.includes("/assessments")
        ? setIsStepperDisabled(true)
        : setIsStepperDisabled(false);
    }
  }, [location]);

  return (
    <Row
      className="rp-stepper-conatiner w-100"
      style={isStepperDisabled ? { pointerEvents: "none" } : {}}
    >
      <div className="rp-step">
        <img
          src={
            activeStep === null
              ? ActiveRainfallIcon
              : activeStep === 0
              ? ActiveRainfallIcon
              : CompletedRainfallIcon
          }
          alt="icon"
          className={
            activeStep === null
              ? "img-gray mr-2 rp-img-size"
              : activeStep >= 0
              ? "cursor-pointer mr-2 rp-img-size"
              : "img-gray mr-2 rp-img-size"
          }
          onClick={() => {
            setStepSize(0);
            setIsStepperClick(true);
          }}
          onKeyUp={() => {}}
          style={activeStep !== null ? {} : { pointerEvents: "none" }}
        />
        <h2 className={activeStep === null ? "disable-h2" : " cursor-pointer"}>
          {t("RAPID_RESPONSE.RAINFALL")}
        </h2>
      </div>
      <div
        className={
          activeStep >= 1
            ? "rp-line-hori rp-line-hori-l rp-line-hori-2"
            : "rp-line-hori rp-line-hori-l "
        }
      />
      <div className="rp-step">
        <img
          src={activeStep > 1 ? CompletedFloodIcon : ActiveFloodIcon}
          alt="icon"
          className={
            activeStep >= 1
              ? "cursor-pointer mr-2 rp-img-size"
              : "img-gray mr-2 rp-img-size"
          }
          onClick={() => {
            setStepSize(1);
            setIsStepperClick(true);
          }}
          onKeyUp={() => {}}
          style={activeStep >= 1 ? {} : { pointerEvents: "none" }}
        />
        <h2 className={activeStep >= 1 ? " cursor-pointer" : "disable-h2"}>
          {t("RAPID_RESPONSE.FLOOD")}
        </h2>
      </div>
      <div
        className={
          activeStep > 1
            ? "rp-line-hori rp-line-hori-r rp-line-hori-2"
            : "rp-line-hori rp-line-hori-r"
        }
      />
      <div className="rp-step">
        <img
          src={
            activeStep > 2 && !(activeStep < 2)
              ? CompletedDisasterIcon
              : ActiveDisasterIcon
          }
          alt="icon"
          className={
            activeStep >= 2
              ? "cursor-pointer mr-2 rp-img-size"
              : "img-gray mr-2 rp-img-size"
          }
          onClick={() => {
            setStepSize(2);
            setIsStepperClick(true);
          }}
          onKeyUp={() => {}}
          style={activeStep >= 2 ? {} : { pointerEvents: "none" }}
        />
        <h2 className={activeStep > 2 ? " cursor-pointer" : "disable-h2"}>
          {t("RAPID_RESPONSE.DISASTER_EXPOSURE")}
        </h2>
      </div>
    </Row>
  );
}

export default RapidResponseStepper;
