import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Label, Input, Button } from "reactstrap";
import { dropzoneAccept } from "../../constants";

import Basic from "../../components/Common/DragAndDrop";

function SideEditor({
  isEditorOpen,
  setIsEditorOpen,
  title,
  handleEditCall,
  setImageFileData,
}) {
  const { t } = useTranslation();
  const [header, setHeader] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    setHeader(title);
    // Add a CSS class to the body element when the sideEditor is open
    if (isEditorOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isEditorOpen]);

  const onHandleUploadImage = (imageData, base64String) => {
    setImageFileData(imageData);
    setImage(base64String);
  };

  const renderEditFields = () => {
    return (
      <>
        <div className="d-flex flex-column justify-content-start w-100 mb-3">
          <Label className="region-label mt-0 ml-2">
            {" "}
            {t("EARLY_FINANCE_REPORT.TITLE")}{" "}
          </Label>
          <Input
            type="text"
            classNamePrefix="react-select"
            className="ea-text-field"
            onChange={(e) => setHeader(e.target.value)}
            value={header}
          />
        </div>
        <div className="d-flex flex-column justify-content-start w-100">
          <Label className="region-label mt-0 ml-2">
            {" "}
            {t("EARLY_ACTIONS.UPLOAD_IMAGE")}{" "}
          </Label>
          <Basic
            onHandleUploadImage={onHandleUploadImage}
            isBasic
            acceptList={dropzoneAccept}
          />
        </div>
      </>
    );
  };

  return (
    <div className="ea-right-sidebar">
      <div className="d-flex justify-content-center align-items-center mt-3 px-3">
        <h3 className="sidebar-heading">{t("EARLY_ACTIONS.EDIT_SECTION")}</h3>
      </div>
      <hr />
      <div className="p-3">
        <div className="mb-3 EF-fixed-side-editor-container">
          {/* Rendering Based row data */}
          {renderEditFields()}
        </div>
        <div className="d-flex align-items-center justify-content-end editor-button-container">
          <Button
            type="button"
            className="back-office-cancel-btn"
            onClick={() => setIsEditorOpen(!isEditorOpen)}
          >
            {t("APPROVE_DATA.CANCEL")}
          </Button>
          <Button
            type="button"
            className="back-office-ok-btn w-auto mb-1 px-5"
            onClick={() => {
              setIsEditorOpen(!isEditorOpen);
              handleEditCall(header, image);
            }}
            disabled={!header?.length}
          >
            {t("BULLET_IN.SAVE")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SideEditor;
