import EARLY_WARNING_MARKET_CONST from "./actionTypes";

export const getMarketTimeSeriesRequest = (data) => ({
  type: EARLY_WARNING_MARKET_CONST.GET_MARKET_TIMESERIES_REQUEST,
  payload: {
    data,
  },
});

export const getMarketTimeSeriesSuccess = (response) => ({
  type: EARLY_WARNING_MARKET_CONST.GET_MARKET_TIMESERIES_SUCCESS,
  payload: response,
});

export const getMarketTimeSeriesError = (error) => ({
  type: EARLY_WARNING_MARKET_CONST.GET_MARKET_TIMESERIES_ERROR,
  payload: error,
});

// MARKET MAP DATA
export const getMarketMapData = (obj) => ({
  type: EARLY_WARNING_MARKET_CONST.GET_MARKET_MAP_DATA_REQUEST,
  payload: {
    requestObj: obj,
  },
});

export const getMarketMapDataSuccess = (response) => ({
  type: EARLY_WARNING_MARKET_CONST.GET_MARKET_MAP_DATA_SUCCESS,
  payload: response,
});

export const getMarketMapDataFailed = (error) => ({
  type: EARLY_WARNING_MARKET_CONST.GET_MARKET_MAP_DATA_FAILED,
  payload: error,
});

// MARKET COMMODITY DATA
export const getMarketCommodityData = (data) => ({
  type: EARLY_WARNING_MARKET_CONST.GET_MARKET_COMMODITY_REQUEST,
  payload: {
    data,
  },
});

export const getMarketCommodityDataSuccess = (response) => ({
  type: EARLY_WARNING_MARKET_CONST.GET_MARKET_COMMODITY_SUCCESS,
  payload: response,
});

export const getMarkeCommoditypDataFailed = (error) => ({
  type: EARLY_WARNING_MARKET_CONST.GET_MARKET_COMMODITY_ERROR,
  payload: error,
});
