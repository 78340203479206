import React from "react";
import { useTranslation } from "react-i18next";
import { GrFormClose } from "react-icons/gr";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import successCheckmark from "../../assest/img/Solid-check-icon.svg";

function FeedbackSuccessModal({ toggle, modal }) {
  const { t } = useTranslation();
  const closeBtn = (
    <GrFormClose
      size={24}
      onClick={() => toggle()}
      className="pointer media-modal-header-close-icon"
    />
  );

  return (
    <Modal isOpen={modal} size="md">
      <div className="success-modal">
        <ModalHeader
          className="p-0 delete-header user-flex-end mr-2 my-2"
          close={closeBtn}
        />
        <ModalBody className="delete-modal-padding">
          <div className="reg-success my-5">
            <img
              src={successCheckmark}
              alt="not-available"
              className="reg-check-image"
              height={60}
              width={60}
            />
            <h2 className="success-text">{t("CROP_YIELD.SUCCESS_MESSAGE")}</h2>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default FeedbackSuccessModal;
