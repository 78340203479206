import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Row, Col, FormGroup, Button, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  formickReactSelectionFiled,
  formickTextField,
  renderImageField,
} from "../../components/Common/FormickField";
import { FORECAST_LEAD_TIME_LIST } from "../../constants";
import {
  staticResourceAddRequest,
  updateStaticResourceRequest,
} from "../../store/actions";
import { renderAPIForecastParameterOptions } from "../../components/Common/Utils";

function Filter({
  initialValues,
  dataSourceList,
  parameter,
  setForecastMonth,
  foreCastMonth,
  fileHandler,
  setFile,
  file,
  editClicked,
  isImgaeUploaded,
  setIsImgaeUploaded,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [forecastLeadTimeList, setForecastLeadTimeList] = useState([]);

  const handleforecastYearMonth = (e) => {
    const val = e.target.value;
    setForecastMonth(val);
  };

  const handleFileChange = (e) => {
    if (e.target.value) {
      fileHandler(URL.createObjectURL(e?.target?.files[0]), e);
      setFile(e?.target?.files[0]);
      setIsImgaeUploaded(true);
    }
  };
  useEffect(() => {
    if (foreCastMonth) {
      const month = foreCastMonth.split("-")[1];
      const list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
      const option_list = list?.splice(parseInt(month, 10), 4);
      let latest_list;
      if (month >= 9) {
        const new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
        const new_arr = new_list?.splice(
          parseInt(0, 10),
          4 - option_list.length,
        );
        latest_list = option_list.concat(new_arr);
      } else {
        latest_list = option_list;
      }
      latest_list?.map((i, index) => {
        i.name = `${i.name} (${index + 1} Month)`;
        return null;
      });
      setForecastLeadTimeList(latest_list);
    }
  }, [foreCastMonth]);

  const onSubmit = (data) => {
    const formData = {
      weather_forecast_source_slug: t("MANAGE_IRI.IRI_SLUG"),
      weather_forecast_parameter_slug: data?.parameter?.slug,
      description: data?.description,
      forecast_lead_time: parseInt(
        data.months.label.substr(
          data.months.label?.indexOf("(") + 1,
          data.months.label?.indexOf(")") -
            data.months.label?.indexOf("(") -
            1 -
            1,
        ),
        10,
      ),
      forecast_time_year: data?.strtdate?.split("-")[0],
      forecast_time_month: data?.strtdate?.split("-")[1],
      source_link: data?.addLink,
    };
    if (editClicked) {
      if (isImgaeUploaded) {
        const form = new FormData();
        form.append("weather_forecast_source_slug", t("MANAGE_IRI.IRI_SLUG"));
        form.append("weather_forecast_parameter_slug", data?.parameter?.slug);
        form.append("description", data?.description);
        form.append(
          "forecast_lead_time",
          parseInt(
            data.months.label.substr(
              data.months.label?.indexOf("(") + 1,
              data.months.label?.indexOf(")") -
                data.months.label?.indexOf("(") -
                1 -
                1,
            ),
            10,
          ),
        );
        form.append("forecast_time_year", data?.strtdate?.split("-")[0]);
        form.append("forecast_time_month", data?.strtdate?.split("-")[1]);
        form.append("source_link", data?.addLink);
        form.append("weather_forecast_content_id", data?.id);
        form.append("image", file);
        dispatch(updateStaticResourceRequest(form));
      } else {
        dispatch(
          updateStaticResourceRequest({
            ...formData,
            weather_forecast_content_id: data?.id,
          }),
        );
      }
    } else {
      dispatch(staticResourceAddRequest({ ...formData, image: file }));
    }
  };
  const getCurrentYearMonth = () => {
    const dateObj = new Date();
    const month =
      (dateObj.getMonth() + 1) % 10 !== dateObj.getMonth() + 1
        ? dateObj.getMonth() + 1
        : `0${dateObj.getMonth() + 1}`;
    return `${dateObj.getUTCFullYear()}-${month}`;
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values) => onSubmit(values)}
      >
        {(formick) => {
          return (
            <Form>
              <Row>
                <Label className="ml-3">
                  {" "}
                  {t("MANAGE_IRI.DATA_AND_PARAMETERS")}{" "}
                </Label>
                <Col sm={12} className="mb-3">
                  <FormGroup className="">
                    <Field
                      type="select"
                      name="Source"
                      component={formickReactSelectionFiled}
                      placeholder={t("MANAGE_IRI.SELECT_DATA_SOURCE")}
                      options={dataSourceList}
                      className="common-select-r"
                      disabled
                      onChange={() => {}}
                    />
                  </FormGroup>
                </Col>

                <Col sm={12} className="mb-3">
                  <FormGroup className="">
                    <Field
                      type="select"
                      name="parameter"
                      component={formickReactSelectionFiled}
                      placeholder={t("MANAGE_IRI.SELECT_PARAMETER")}
                      options={parameter}
                      className="common-select-r"
                      disabled
                      onChange={() => {}}
                    />
                  </FormGroup>
                </Col>
                <Label className="ml-3"> {t("MANAGE_IRI.DATE_RANGE")} </Label>
                <Col sm={12} className="mb-3">
                  <FormGroup className="">
                    <Field
                      type="month"
                      name="strtdate"
                      component={formickTextField}
                      max={getCurrentYearMonth()}
                      placeholder={t("MANAGE_IRI.DATE_RANGE")}
                      className="common-select-r"
                      onChangeField={(e) => {
                        handleforecastYearMonth(e);
                        formick.setFieldValue("months", "");
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col sm={12} className="mb-3">
                  <FormGroup className="">
                    <Field
                      type="select"
                      name="months"
                      component={formickReactSelectionFiled}
                      placeholder={t("MANAGE_IRI.FORECAST_LEAD_TIME")}
                      options={renderAPIForecastParameterOptions(
                        forecastLeadTimeList,
                      )}
                      className="common-select-r"
                      disabled={!foreCastMonth}
                      onChange={() => {}}
                    />
                  </FormGroup>
                </Col>

                <Label className="ml-3">
                  {" "}
                  {t("STATIC_RESOURCE_ADMIN.UPLOAD_CONTENT")}{" "}
                </Label>
                <Col sm={12} className="mb-3">
                  <FormGroup className="">
                    <Field
                      type="textarea"
                      name="addLink"
                      component={formickTextField}
                      placeholder={t("MANAGE_IRI.ADD_LINK")}
                      className="common-select-r"
                      onChangeField={() => {}}
                    />
                    {formick.errors?.addLink && formick.touched?.addLink && (
                      <p className=" error">{t(formick.errors?.addLink)}</p>
                    )}
                  </FormGroup>
                </Col>

                <Col sm={12} className="mb-3">
                  <FormGroup className="">
                    <Field
                      type="textarea"
                      name="description"
                      component={formickTextField}
                      placeholder={t("MANAGE_IRI.ADD_DESCRIPTION")}
                      className="common-select-r"
                      onChangeField={() => {}}
                    />
                    {formick.errors?.description &&
                      formick.touched?.description && (
                        <p className=" error">{t(formick.errors?.addLink)}</p>
                      )}
                  </FormGroup>
                </Col>

                <Col sm={12} className="mb-3">
                  <FormGroup className="">
                    <Field
                      id="Upload"
                      name="Upload"
                      type="file"
                      component={renderImageField}
                      className="common-select-r"
                      placeholder={t("MANAGE_IRI.UPLOAD_IMAGES")}
                      accept="image/tiff"
                      onChangeField={(e) => handleFileChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Button
                  id="resetBtn"
                  type="button"
                  onClick={() => {
                    formick.resetForm({});
                  }}
                  className="cancel-none-button close-button"
                >
                  {t("UPLOAD_PAST_DATA.CANCEL")}
                </Button>
                {!editClicked ? (
                  <Col sm={12} className="mb-3">
                    <Button
                      type="submit"
                      className="sign-in-button w-100"
                      disabled={
                        !formick.dirty || !formick.values.months || !file
                      }
                    >
                      {t("MANAGE_IRI.PUBLISH")}
                    </Button>
                  </Col>
                ) : (
                  <Col sm={12} className="mb-3">
                    <Button
                      type="submit"
                      className="sign-in-button w-100"
                      disabled={
                        !(formick.isValid && formick.dirty) ||
                        !formick.values.months
                      }
                    >
                      {t("STATIC_RESOURCE_ADMIN.UPDATE")}
                    </Button>
                  </Col>
                )}
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default Filter;
