import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Input, Label, Row } from "reactstrap";
import { useFormik } from "formik";
import _ from "lodash";
import BackButton from "../../assest/img/back-button.svg";
import { RenderReactSelectInputField } from "../../components/Common/RenderFormikFields";
import {
  coreRequest,
  dashBoardMenuRequest,
  uploadPastDataRequest,
  getTemplateDataRequest,
  uploadPastDataSuccess,
  getTemplateDataSuccess,
  uploadPastDataFailure,
  getDownloadCypTemplateRequest,
  cropYieldCropListRequest,
  uploadCypDataRequest,
  uploadCypDataSuccess,
} from "../../store/actions";
import {
  renderReactOptionsArraystate,
  renderBackOfficeOptionsArray,
} from "../../helpers";
import { ReactComponent as DownloadIcon } from "../../assest/img/Download.svg";
import UploadIcon from "../../assest/img/upload-image.svg";
import CrossIcon from "../../assest/img/Cross-icon.svg";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import errorImage from "../../assest/img/errorImage.svg";
import { BO_OPTIONS } from "../../constants";
import { showError } from "../../components/Common/Notification";

function UploadPastData() {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [modulesOptions, setModulesOptions] = useState([]);
  const [selectedModule, setSelectedModule] = useState({});
  const [selectedSubModule, setSelectedSubModule] = useState({});
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileData, setFileData] = useState([]);
  const [errorType, setErrorType] = useState("");
  const [formData, setFormData] = useState({});
  const [bulkErrors, setBulkErrors] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [cropData, setCropData] = useState();

  const loggedInUserDetails = JSON.parse(
    localStorage.getItem("loggedInUserDetails"),
  );

  const nextProps = useSelector((state) => ({
    countriesList: state.Core,
    dashBoardMenuList: state.Dashboard.dashBoardMenuData,
    uploadedData: state.UploadPastData.uploadPastData,
    getTemplateData: state.UploadPastData.templateData,
    uploadPastDataError: state.UploadPastData.uploadPastDataError,
    CyptemplateData: state?.UploadPastData?.CyptemplateData,
    cropsList: state.CropYield?.cpCropList,
    cropListingLoading: state?.CropYield?.cropListLoading,
    cypUploaddata: state.UploadPastData.cypUploadData,
    uploadCypError: state.UploadPastData?.cypError,
  }));

  // useEffect for load data initially
  useEffect(() => {
    window.scroll(0, 0);
    dispatch(
      coreRequest({
        location_type: "country",
      }),
    );
    dispatch(dashBoardMenuRequest());
    setModulesOptions(BO_OPTIONS);
    // dispatch(
    //   cropYieldCropListRequest({
    //     country_id: selectedLocation?.country?.value,
    //   })
    // )
    return () => {
      dispatch(uploadPastDataSuccess(""));
      dispatch(getTemplateDataSuccess(""));
      dispatch(uploadPastDataFailure(""));
      dispatch(uploadCypDataSuccess(""));
    };
  }, []);

  //  setting map and graph data
  const isCropYielddata = React.useRef(true);
  useEffect(() => {
    if (isCropYielddata.current) {
      isCropYielddata.current = false;
      return;
    }
    if (!nextProps?.cropListingLoading) {
      if (nextProps?.cropsList?.length) {
        const crop = nextProps?.cropsList?.map((obj) => ({
          ...obj,
          value: obj?.id,
          label: obj?.name,
        }));

        setCropData(crop);
      } else {
        showError("Crop data is not available for the selected location");
      }
    }
  }, [nextProps?.cropsList]);

  useEffect(() => {
    if (selectedModule?.slug === "crop_yield_prediction") {
      dispatch(
        cropYieldCropListRequest({
          country_id: selectedCountry?.value,
        }),
      );
    }
  }, [selectedModule]);

  const downloadExcelFile = async (template) => {
    // Create an anchor tag element with download attribute
    const link = document.createElement("a");
    link.setAttribute("download", `${selectedModule?.label}.xlsx`);

    // Set the href attribute to the URL of the Excel file
    link.setAttribute("href", template);

    // Append the anchor tag to the document body
    document.body.appendChild(link);

    // Trigger a click event on the anchor tag to start the download
    link.click();

    // Remove the anchor tag from the document body
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (nextProps.getTemplateData?.length) {
      nextProps.getTemplateData?.forEach((item) => {
        if (item?.template !== "") {
          downloadExcelFile(item?.template);
        }
      });
    }
  }, [nextProps.getTemplateData]);

  useEffect(() => {
    if (nextProps?.CyptemplateData?.length) {
      nextProps.CyptemplateData?.forEach((item) => {
        if (item?.template !== "") {
          downloadExcelFile(item?.template);
        }
      });
    }
  }, [nextProps.CyptemplateData]);

  useEffect(() => {
    if (nextProps.uploadedData?.statusCode === 200) {
      setIsSuccessModal(true);
      // !reseting the form
      const ele = document.getElementsByClassName("cancel-btn");
      if (ele.length) {
        document.getElementById("upload_file_BO").value = "";
        ele[0].click();
        setFileData([]);
        setBulkErrors([]);
        setSelectedCountry({});
      }
    }
  }, [nextProps.uploadedData]);
  // for crop yield
  useEffect(() => {
    if (nextProps.cypUploaddata) {
      setIsSuccessModal(true);
      // !reseting the form
      const ele = document.getElementsByClassName("cancel-btn");
      if (ele.length) {
        document.getElementById("upload_file_BO").value = "";
        ele[0].click();
        setFileData([]);
        setBulkErrors([]);
        setSelectedCountry({});
      }
    }
  }, [nextProps.cypUploaddata]);

  useEffect(() => {
    if (nextProps.uploadPastDataError?.statusCode === 400) {
      setIsErrorModal(true);
      const message = nextProps.uploadPastDataError?.data?.message;
      if (nextProps.uploadPastDataError?.data) {
        if (Array.isArray(nextProps.uploadPastDataError?.data?.message)) {
          setBulkErrors(nextProps.uploadPastDataError?.data?.message);
          setIsErrorModal(false);
        } else {
          if (message) {
            setErrorMessage(nextProps.uploadPastDataError?.data?.message);
          }
        }
      }
    }
  }, [nextProps.uploadPastDataError]);

  //  upload error for cyp data
  useEffect(() => {
    if (nextProps.uploadCypError?.statusCode === 400) {
      setIsErrorModal(true);
      const message = nextProps.uploadCypError?.data?.message;
      if (nextProps.uploadCypError?.data) {
        if (Array.isArray(nextProps.uploadCypError?.data?.message)) {
          setBulkErrors(nextProps.uploadCypError?.data?.message);
          setIsErrorModal(false);
        } else {
          if (message) {
            setErrorMessage(nextProps.uploadCypError?.data?.message);
          }
        }
      }
    }
  }, [nextProps.uploadCypError]);

  useEffect(() => {
    if (errorMessage?.length) {
      if (errorMessage.includes("excel sheet does not have data")) {
        setErrorType("emptyData");
      } else if (errorMessage.includes("Do you want to override")) {
        setErrorType("dataOverride");
      } else if (
        errorMessage.includes(
          "Your uploaded excel sheet dates are not in between the selected dates.",
        )
      ) {
        setErrorType("DatesRangeError");
      } else if (
        errorMessage.includes(
          "Region name provided in row 2 is not exist into our system",
        )
      ) {
        setErrorType("unMatchedField");
      } else if (errorMessage.includes("not matching with selected criteria")) {
        setErrorType("wrongStructure");
      }
    }
  }, [errorMessage]);

  const handleResetForm = () => {
    setSelectedModule({});
    setSelectedSubModule({});
    document.getElementById("upload_file_BO").value = "";
    setFileData([]);
    setBulkErrors([]);
    setSelectedCountry({});
  };

  const uploadFile = (e) => {
    e.stopPropagation();
    const ele = document.getElementsByClassName("upload-file");
    if (ele.length) {
      ele[0].click();
    }
  };

  const removeUploadedFile = (e) => {
    e.stopPropagation();
    document.getElementById("upload_file_BO").value = "";
    setFileData([]);
    setBulkErrors([]);
  };

  const formik = useFormik({
    initialValues: {
      country: "",
      module: "",
      subModule: "",
      startDate: "",
      endDate: "",
      file: "",
      crop_type_id: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.country) {
        errors.country = "VALIDATION.SELECT_REQUIRED";
      }
      if (!values.module) {
        errors.module = "VALIDATION.SELECT_REQUIRED";
      }
      // if (!values.subModule) {
      //   errors.subModule = "VALIDATION.SELECT_REQUIRED";
      // }
      if (!values.startDate && values.module.slug !== "crop_yield_prediction") {
        errors.startDate = "VALIDATION.SELECT_REQUIRED";
      }
      if (!values.endDate && values.module.slug !== "crop_yield_prediction") {
        errors.endDate = "VALIDATION.SELECT_REQUIRED";
      }
      if (!values.file) {
        errors.file = "VALIDATION.SELECT_REQUIRED";
      }
      if (
        !values.crop_type_id &&
        values.module.slug === "crop_yield_prediction"
      ) {
        errors.crop_type_id = "VALIDATION.SELECT_REQUIRED";
      }

      return errors;
    },
    onSubmit: (values) => {
      const form = new FormData();
      const startDate = `${values?.startDate}-01`;
      const endDate = `${values?.endDate}-01`;

      form.append("country_id", values?.country?.value);
      form.append("menu_slug", values?.module?.slug);

      form.append("user_id", loggedInUserDetails?.user_details?.[0]?.id);
      form.append("status", "pending");
      if (selectedModule?.slug === "crop_yield_prediction") {
        form.append("file", values?.file[0]);
        form.append("crop_type_id", values?.crop_type_id?.id);
        form.append("sub_menu_slug", "");
      } else {
        form.append("sub_menu_slug", values?.subModule?.slug || "");
        form.append("crop_type_id", "");
        form.append("start_date", startDate);
        form.append("end_date", endDate);
        form.append("data_override", true);
        form.append("upload_file", values?.file[0]);
      }
      setFormData(form);

      selectedModule?.slug === "crop_yield_prediction"
        ? dispatch(uploadCypDataRequest(form))
        : dispatch(uploadPastDataRequest(form));
    },
  });
  const handleErrorToggle = (type) => {
    if (type) {
      if (formData) {
        formData.set("data_override", true);
        dispatch(uploadPastDataRequest(formData));
      }
      setIsErrorModal(!isErrorModal);
    } else {
      setIsErrorModal(!isErrorModal);
    }
  };

  const handleRemoveBulkErrors = () => {
    setBulkErrors([]);
    document.getElementById("upload_file_BO").value = "";
    setFileData([]);
  };

  const onHandleDownloadtemplate = () => {
    selectedModule?.slug === "crop_yield_prediction"
      ? dispatch(
          getDownloadCypTemplateRequest({
            menu_slug: selectedModule ? selectedModule?.slug : "",
            sub_menu_slug: selectedSubModule ? selectedSubModule?.slug : "",
            country_id: selectedCountry?.value,
          }),
        )
      : dispatch(
          getTemplateDataRequest({
            menu_slug: selectedModule ? selectedModule?.slug : "",
            sub_menu_slug: selectedSubModule ? selectedSubModule?.slug : "",
            country_id: selectedCountry?.value,
          }),
        );
  };

  return (
    <>
      {isSuccessModal && (
        <SuccessModal modal={isSuccessModal} toggle={setIsSuccessModal} />
      )}
      {isErrorModal && (
        <ErrorModal
          modal={isErrorModal}
          toggle={setIsErrorModal}
          onClick={handleErrorToggle}
          message={errorMessage}
          errorType={errorType}
          selectedModule={selectedModule}
          selectedSubModule={selectedSubModule}
          selectedCountry={selectedCountry}
        />
      )}
      <div className="back-office-main-page">
        <div className="pb-1 back-office-main-header back-office-center">
          <img
            src={BackButton}
            alt="back-button"
            onClick={() => history("/settings")}
            onKeyUp={() => {}}
            className="pointer"
          />
          <h2 className="discription-text pl-3 heading-text-user-guide">
            {t("UPLOAD_PAST_DATA.UPLOAD_FILE")}
          </h2>
        </div>
        <Card className="back-office-main-card">
          <div className="back-office-header">
            <p className="pl-5">{t("SETTINGS.UPLOAD_PAST_DATA")}</p>
            {!selectedModule?.list?.length && !_.isEmpty(selectedModule) ? (
              <button
                type="button"
                className="mr-5 download-template"
                onClick={onHandleDownloadtemplate}
              >
                <DownloadIcon />
                {t("UPLOAD_PAST_DATA.DOWNLOAD_TEMPLATE")}
              </button>
            ) : !_.isEmpty(selectedSubModule) ? (
              <button
                type="button"
                className="mr-5 download-template"
                onClick={() =>
                  dispatch(
                    getTemplateDataRequest({
                      menu_slug: selectedModule ? selectedModule?.slug : "",
                      sub_menu_slug: selectedSubModule
                        ? selectedSubModule?.slug
                        : "",
                      country_id: selectedCountry?.value,
                    }),
                  )
                }
              >
                <DownloadIcon />
                {t("UPLOAD_PAST_DATA.DOWNLOAD_TEMPLATE")}
              </button>
            ) : (
              ""
            )}
          </div>
          <form onSubmit={formik.handleSubmit} className="m-3">
            <Row className="back-office-form p-3">
              <Col md={4} className="pb-4">
                <RenderReactSelectInputField
                  id="country"
                  // formInput
                  value={formik.values?.country}
                  options={
                    nextProps?.countriesList?.coreList &&
                    renderReactOptionsArraystate(
                      nextProps?.countriesList?.coreList,
                      "name",
                      "id",
                      "latitude",
                      "longitude",
                      "zoom_level",
                    )
                  }
                  onChange={(e) => {
                    formik.setFieldValue("country", e);

                    setSelectedCountry(e);
                    formik.setFieldValue("module", "");
                    formik.setFieldValue("subModule", "");
                    formik.setFieldValue("crop_type_id", "");
                    formik.setFieldValue("startDate", "");
                    formik.setFieldValue("endDate", "");
                    formik.setFieldValue("file", "");
                    setFileData([]);
                    setSelectedModule({});
                    setSelectedSubModule({});
                    document.getElementById("upload_file_BO").value = "";
                  }}
                  name="country"
                  label={`${t("UPLOAD_PAST_DATA.COUNTRY")} *`}
                  placeholder={t("UPLOAD_PAST_DATA.SELECT")}
                  className={
                    formik.errors?.country && formik.touched?.country
                      ? "error-field dash-select common-select-r"
                      : "map-dash-select dash-select common-select-r"
                  }
                />
                {formik.errors?.country && formik.touched?.country && (
                  <p className=" error">{t(formik.errors?.country)}</p>
                )}
              </Col>
              <Col
                md={4}
                className="pb-4"
                style={_.isEmpty(selectedCountry) ? { opacity: "0.65" } : {}}
              >
                <RenderReactSelectInputField
                  id="module"
                  // formInput
                  value={formik.values?.module}
                  options={
                    modulesOptions?.length &&
                    renderBackOfficeOptionsArray(
                      modulesOptions,
                      "title",
                      "title",
                      "slug",
                      "sub_menu_list",
                    )
                  }
                  onChange={(e) => {
                    formik.setFieldValue("module", e);

                    formik.setFieldValue("subModule", "");
                    setSelectedModule(e);
                    setSelectedSubModule({});
                    formik.setFieldValue("crop_type_id", "");
                    formik.setFieldValue("startDate", "");
                    formik.setFieldValue("endDate", "");
                    formik.setFieldValue("file", "");
                    setFileData([]);
                    document.getElementById("upload_file_BO").value = "";
                  }}
                  name="module"
                  label={`${t("UPLOAD_PAST_DATA.MODULE")} *`}
                  placeholder={t("UPLOAD_PAST_DATA.SELECT")}
                  isDisabled={_.isEmpty(selectedCountry)}
                  className={
                    formik.errors?.module && formik.touched?.module
                      ? "error-field dash-select common-select-r"
                      : "map-dash-select dash-select common-select-r"
                  }
                />
                {formik.errors?.module && formik.touched?.module && (
                  <p className=" error">{t(formik.errors?.module)}</p>
                )}
              </Col>
              <Col
                md={4}
                className="pb-4"
                style={!selectedModule?.list?.length ? { opacity: "0.65" } : {}}
              >
                <RenderReactSelectInputField
                  id="subModule"
                  // formInput
                  value={formik.values?.subModule}
                  options={
                    selectedModule?.list?.length &&
                    renderBackOfficeOptionsArray(
                      selectedModule?.list,
                      "title",
                      "title",
                      "slug",
                      "sub_menu_list",
                    )
                  }
                  onChange={(e) => {
                    formik.setFieldValue("subModule", e);
                    setSelectedSubModule(e);
                    formik.setFieldValue("startDate", "");
                    formik.setFieldValue("endDate", "");
                    formik.setFieldValue("file", "");
                    setFileData([]);
                    document.getElementById("upload_file_BO").value = "";
                  }}
                  name="subModule"
                  label={t("UPLOAD_PAST_DATA.SUB_MODULE")}
                  placeholder={t("UPLOAD_PAST_DATA.SELECT")}
                  className={
                    formik.errors?.subModule && formik.touched?.subModule
                      ? "error-field dash-select common-select-r"
                      : "map-dash-select dash-select common-select-r"
                  }
                  isDisabled={!selectedModule?.list?.length}
                />
                {formik.errors?.subModule && formik.touched?.subModule && (
                  <p className=" error">{t(formik.errors?.subModule)}</p>
                )}
              </Col>

              {selectedModule?.slug === "crop_yield_prediction" ? (
                <Col md={4} className="pb-4">
                  <RenderReactSelectInputField
                    id="crop_type_id"
                    // formInput
                    value={formik.values?.crop_type_id}
                    options={cropData}
                    onChange={(e) => {
                      formik.setFieldValue("crop_type_id", e);
                    }}
                    name="crop_type_id"
                    label="Crop & Season *"
                    placeholder="Select"
                    className={
                      formik.errors?.crop_type_id &&
                      formik.touched?.crop_type_id
                        ? "error-field dash-select common-select-r"
                        : "map-dash-select dash-select common-select-r"
                    }
                  />
                  {formik.errors?.crop_type_id &&
                    formik.touched?.crop_type_id && (
                      <p className=" error">{t(formik.errors?.crop_type_id)}</p>
                    )}
                </Col>
              ) : (
                ""
              )}

              {selectedModule?.slug === "crop_yield_prediction" ? (
                ""
              ) : (
                <Col md={4} className="pb-4">
                  <Label>{`${t("UPLOAD_PAST_DATA.START_YEAR")} *`}</Label>
                  <Input
                    type="month"
                    name="startDate"
                    id="startDate"
                    className={
                      formik.errors?.startDate && formik.touched?.startDate
                        ? "error-field back-office-dates"
                        : "back-office-dates"
                    }
                    onChange={(e) => {
                      formik.setFieldValue("startDate", e.target.value);
                      formik.setFieldValue("file", "");
                      setFileData([]);
                      document.getElementById("upload_file_BO").value = "";
                    }}
                    value={formik.values?.startDate}
                  />
                  {formik.errors?.startDate && formik.touched?.startDate && (
                    <p className=" error">{t(formik.errors?.startDate)}</p>
                  )}
                </Col>
              )}
              {selectedModule?.slug === "crop_yield_prediction" ? (
                ""
              ) : (
                <Col md={4} className="pb-4">
                  <Label>{`${t("UPLOAD_PAST_DATA.END_YEAR")} *`}</Label>
                  <Input
                    type="month"
                    name="endDate"
                    id="endDate"
                    className={
                      formik.errors?.endDate && formik.touched?.endDate
                        ? "error-field back-office-dates"
                        : "back-office-dates"
                    }
                    onChange={(e) => {
                      formik.setFieldValue("endDate", e.target.value);
                      formik.setFieldValue("file", "");
                      setFileData([]);
                      document.getElementById("upload_file_BO").value = "";
                    }}
                    value={formik.values?.endDate}
                  />
                  {formik.errors?.endDate && formik.touched?.endDate && (
                    <p className=" error">{t(formik.errors?.endDate)}</p>
                  )}
                </Col>
              )}
              <Col md={4} className="pb-4 back-office-upload">
                <Label>{`${t("UPLOAD_PAST_DATA.UPLOAD_FILE")} *`}</Label>
                <div className="mb-2 w-100">
                  <Label
                    htmlFor="file"
                    className={
                      formik.errors?.file && formik.touched?.file
                        ? "error-field form-control  upload-field mb-0"
                        : "form-control  upload-field mb-0"
                    }
                    onClick={(e) => uploadFile(e)}
                  >
                    {fileData?.length ? fileData[0]?.name : "Browse files"}{" "}
                    &nbsp;&nbsp;
                    {fileData?.length ? (
                      <img
                        src={CrossIcon}
                        alt="not-available"
                        className="upload-icon"
                        onClick={(e) => removeUploadedFile(e)}
                        onKeyUp={() => {}}
                        style={{ height: "18px", paddingTop: "6px" }}
                      />
                    ) : (
                      <img
                        src={UploadIcon}
                        alt="not-available"
                        className="upload-icon"
                        style={{ height: "24px", paddingTop: "6px" }}
                      />
                    )}
                  </Label>
                  {formik.errors?.file && formik.touched?.file && (
                    <p className=" error">{t(formik.errors?.file)}</p>
                  )}
                  <input
                    id="upload_file_BO"
                    name="file"
                    type="file"
                    // style={{ display: "none" }}
                    className="upload-file"
                    onChange={(e) => {
                      formik.setFieldValue("file", e.target.files);
                      setFileData(e.target.files);
                      setBulkErrors([]);
                    }}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </div>
              </Col>
              <hr style={{ border: "1px solid #C4C4C4", width: "100%" }} />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {bulkErrors?.length ? (
                    <div className="bulk-errors-container px-3 py-2">
                      <div className="space-between">
                        <span className="bulk-errors-no">
                          {bulkErrors?.length} errors found in the excel file!
                        </span>
                        <img
                          src={CrossIcon}
                          alt="not-available"
                          className="upload-icon cursor-ponter"
                          onClick={() => handleRemoveBulkErrors()}
                          onKeyUp={() => {}}
                          style={{ height: "18px", paddingTop: "6px" }}
                        />
                      </div>
                      <div className="bulk-data-container py-3">
                        {bulkErrors?.map((error) => (
                          <>
                            <img
                              src={errorImage}
                              alt="not-available"
                              className="upload-icon"
                              style={{ height: "16px" }}
                            />
                            &nbsp;&nbsp;
                            <span>{error}</span>
                            <br />
                          </>
                        ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <button
                    id="resetBtn"
                    type="button"
                    onClick={() => {
                      formik.resetForm({});
                      handleResetForm();
                    }}
                    className="cancel-btn mx-3 float-right"
                  >
                    {t("UPLOAD_PAST_DATA.CANCEL")}
                  </button>
                  <button
                    id="submit-button"
                    type="submit"
                    className="upload-btn mx-2 float-right"
                    disabled={
                      !(formik.isValid && formik.dirty && fileData?.length)
                    }
                  >
                    {t("UPLOAD_PAST_DATA.SUBMIT")}
                  </button>
                </div>
              </div>
            </Row>
          </form>
        </Card>
      </div>
    </>
  );
}

export default UploadPastData;
