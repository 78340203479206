import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CropYieldMapContainer from "./mapContainer";
import CommonScrollableTabs from "../ScrollableTabs";
import { stateBoundaryRequest } from "../../../store/actions";

// import MapPreferenceModal from "../../../components/Common/MapPreferences";

function MapContainer({
  onHandleZoom,
  position,
  zooms,
  layerRef,
  maps,
  layerData,
  setLegend,
  legend,
  selectedLocation,
  setMapPreferences,
  mapPreferences,
  setShowChart,
  geoJsonKeys,
  setGeoJsonKeys,
  setMapData,
  mapData,
  districtJsonData,
  setDistrictJsonData,
  selectedCrop,
  startYear,
  endYear,
  selectedModal,
  clickEventData,
  selectedMap,
  scrollableYear,
  setScrollableyear,
  setSelectedmap,
  setEndyear,
  setDistricyBoundry,
  districtBoundry,
  setModalPerformance,
  setGraphData,
  setYearGraphData,
  graphData,
  openPanel,
  setGeoJsonData,
  geoJsonData,
  mapPreferRefs,
  geoJsonAllData,
  geoJsonLayersData,
  setGeoJsonAllData,
  setGeoJsonLayerData,
  setOpen,
  setMapPreferencesData,
  mapPreferencesData,
  adminRadio,
  removeMapPreferenceLayer,
  removeMapLayers,
  removeLayers,
  wrap,
  setIndexLayers,
  indexLayers,
  selectedMapPreferLegendData,
  setSelectedMapPreferLegendData,
  setcurrentGraphData,
  setLoaderLatLng,
  loaderlatLng,
  predectionDataLoadingRef,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (selectedLocation?.state && selectedLocation?.state?.value !== "") {
      dispatch(
        stateBoundaryRequest({
          data: {
            country_name: selectedLocation?.country?.label,
            state_name:
              selectedLocation?.state === ""
                ? t("DASHBOARD.ALL_REGIONS")
                : selectedLocation?.state?.label,
            district_name:
              selectedLocation?.district === ""
                ? t("DASHBOARD.ALL_DISTRICTS")
                : selectedLocation?.district?.label,
          },
          isState: false,
        }),
      );
    }
  }, [selectedLocation?.state]);

  return (
    <div>
      <div>
        <CropYieldMapContainer
          onHandleZoom={onHandleZoom}
          position={position}
          zooms={zooms}
          layerRef={layerRef}
          maps={maps}
          layerData={layerData}
          setLegend={setLegend}
          legend={legend}
          selectedLocation={selectedLocation}
          setMapPreferences={setMapPreferences}
          mapPreferences={mapPreferences}
          setShowChart={setShowChart}
          geoJsonKeys={geoJsonKeys}
          setGeoJsonKeys={setGeoJsonKeys}
          setMapData={setMapData}
          mapData={mapData}
          districtJsonData={districtJsonData}
          setDistrictJsonData={setDistrictJsonData}
          selectedCrop={selectedCrop}
          startYear={startYear}
          endYear={endYear}
          setEndyear={setEndyear}
          selectedModal={selectedModal}
          clickEventData={clickEventData}
          selectedMap={selectedMap}
          scrollableYear={scrollableYear}
          setSelectedmap={setSelectedmap}
          setDistricyBoundry={setDistricyBoundry}
          districtBoundry={districtBoundry}
          setScrollableyear={setScrollableyear}
          setModalPerformance={setModalPerformance}
          setGraphData={setGraphData}
          graphData={graphData}
          setYearGraphData={setYearGraphData}
          setGeoJsonData={setGeoJsonData}
          geoJsonData={geoJsonData}
          mapPreferRefs={mapPreferRefs}
          geoJsonAllData={geoJsonAllData}
          geoJsonLayersData={geoJsonLayersData}
          setGeoJsonAllData={setGeoJsonAllData}
          setGeoJsonLayerData={setGeoJsonLayerData}
          setOpen={setOpen}
          setMapPreferencesData={setMapPreferencesData}
          mapPreferencesData={mapPreferencesData}
          adminRadio={adminRadio}
          removeMapPreferenceLayer={removeMapPreferenceLayer}
          removeMapLayers={removeMapLayers}
          removeLayers={removeLayers}
          wrap={wrap}
          setIndexLayers={setIndexLayers}
          indexLayers={indexLayers}
          selectedMapPreferLegendData={selectedMapPreferLegendData}
          setSelectedMapPreferLegendData={setSelectedMapPreferLegendData}
          setcurrentGraphData={setcurrentGraphData}
          setLoaderLatLng={setLoaderLatLng}
          loaderlatLng={loaderlatLng}
          predectionDataLoadingRef={predectionDataLoadingRef}
        />
      </div>
      {scrollableYear?.yearList?.length ? (
        <div
          className={
            openPanel
              ? "map-crop-predection-tabs mt-5"
              : "map-crop-predection-tabs"
          }
        >
          <CommonScrollableTabs
            setTabIndex={setTabIndex}
            tabIndex={tabIndex}
            className=""
            scrollableYear={scrollableYear}
            setScrollableyear={setScrollableyear}
            setSelectedmap={setSelectedmap}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default MapContainer;
