import EARLY_ACTIONS_CONST from "./actionTypes";

export const getAllRiskTypeRequest = () => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_RISK_TYPE_REQUEST,
    payload: {},
  };
};
export const getAllRiskTypeSuccess = (user) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_RISK_TYPE_SUCCESS,
    payload: user,
  };
};
export const getAllRiskTypeError = (error) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_RISK_TYPE_ERROR,
    payload: error,
  };
};

export const createProjectRequest = (payload) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_CREATE_PROJECT_REQUEST,
    payload,
  };
};
export const createProjectSuccess = (user) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_CREATE_PROJECT_SUCCESS,
    payload: user,
  };
};
export const createProjectError = (error) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_CREATE_PROJECT_ERROR,
    payload: error,
  };
};

//  PROJECT LIST

export const getAllProjectListRequest = (payload) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_PROJECT_LIST_REQUEST,
    payload: { payload },
  };
};
export const getAllProjectListSuccess = (user) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_PROJECT_LIST_SUCCESS,
    payload: user,
  };
};
export const getAllProjectListError = (error) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_PROJECT_LIST_ERROR,
    payload: error,
  };
};

//  DELETE  LIST

export const deleteprojectRequest = (payload) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_DELETE_PROJECT_REQUEST,
    payload,
  };
};
export const deleteprojectSuccess = (user) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_DELETE_PROJECT_SUCCESS,
    payload: user,
  };
};
export const deleteprojectError = (error) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_DELETE_PROJECT_ERROR,
    payload: error,
  };
};

// edit project actions
export const editprojectRequest = (payload) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_EDIT_PROJECT_REQUEST,
    payload,
  };
};
export const editprojectSuccess = (user) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_EDIT_PROJECT_SUCCESS,
    payload: user,
  };
};
export const editprojectError = (error) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_EDIT_PROJECT_ERROR,
    payload: error,
  };
};

// Preapredness phase
export const getPreparednessPhaseRequest = (payload) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_PREPAREDNESS_PHASE_REQUEST,
    payload,
  };
};
export const getPreparednessPhaseSuccess = (data) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_PREPAREDNESS_PHASE_SUCCESS,
    payload: data,
  };
};
export const getPreparednessPhaseError = (error) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_PREPAREDNESS_PHASE_ERROR,
    payload: error,
  };
};

// Preapredness phase suggestions
export const getPreparednessPhaseSuggestionsRequest = (payload) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_PREPAREDNESS_PHASE_SUGGESTIONS_REQUEST,
    payload,
  };
};
export const getPreparednessPhaseSuggestionsSuccess = (data) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_PREPAREDNESS_PHASE_SUGGESTIONS_SUCCESS,
    payload: data,
  };
};
export const getPreparednessPhaseSuggestionsError = (error) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_PREPAREDNESS_PHASE_SUGGESTIONS_ERROR,
    payload: error,
  };
};

// project status
export const projectStatusRequest = (payload) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_PROJECT_STATUS_REQUEST,
    payload,
  };
};
export const projectStatusSuccess = (data) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_PROJECT_STATUS_SUCCESS,
    payload: data,
  };
};
export const projectStatusError = (error) => {
  return {
    type: EARLY_ACTIONS_CONST.EARLY_ACTION_PROJECT_STATUS_ERROR,
    payload: error,
  };
};

// currency listing
export const currencyListingRequest = (payload) => {
  return {
    type: EARLY_ACTIONS_CONST.CURRENCY_LISTING_REQUEST,
    payload,
  };
};
export const currencyListingSuccess = (data) => {
  return {
    type: EARLY_ACTIONS_CONST.CURRENCY_LISTING_SUCCESS,
    payload: data,
  };
};
export const currencyListingError = (error) => {
  return {
    type: EARLY_ACTIONS_CONST.CURRENCY_LISTING_ERROR,
    payload: error,
  };
};
