import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BsLinkedin, BsGlobe } from "react-icons/bs";
import Footer from "../../assest/img/Footer-2.png";
import ClimberFooter from "../../components/Common/ClimberFooter";
import CarouselComponent from "../../components/Common/Carousel";
import {
  awareTeamListRequest,
  climberBannerRequest,
} from "../../store/actions";

function OurTeams() {
  const history = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [carouselBannerImages, setBannerImages] = useState([]);
  const [teamsList, setTeamsList] = useState([]);

  const handleClickBack = () => {
    history("/climber");
  };

  const nextProps = useSelector((state) => ({
    teamsList: state.Aware?.awareTeamList,
    bannerImages: state.Climber,
  }));

  useEffect(() => {
    dispatch(awareTeamListRequest());
    dispatch(
      climberBannerRequest({
        order: "ASC",
        country_id: "",
        system: "teams",
      }),
    );
  }, []);

  const isBannerImages = useRef(true);
  useEffect(() => {
    if (isBannerImages.current) {
      isBannerImages.current = false;
      return;
    }
    if (nextProps.bannerImages.climberBannerData) {
      setBannerImages([...nextProps.bannerImages.climberBannerData]);
    }
  }, [nextProps.bannerImages]);

  const isTeamList = useRef(true);
  useEffect(() => {
    if (isTeamList.current) {
      isTeamList.current = false;
      return;
    }
    if (nextProps?.teamsList) {
      setTeamsList([...nextProps?.teamsList]);
    }
  }, [nextProps?.teamsList]);

  return (
    <>
      <div>
        <CarouselComponent carouselData={carouselBannerImages} />
      </div>
      <div className="mx-5 mx-md-0">
        <h3 className="bread-crum mt-2 bread-crum-teams">
          <span
            onClick={handleClickBack}
            role="button"
            tabIndex="0"
            onKeyUp={() => {}}
            className="partner-home"
          >
            {t("HOME.GO_TO_ClimBeR")}
          </span>{" "}
          <span className="partner-h3">{t("HOME.OUR_TEAM")}</span>{" "}
        </h3>
        <div>
          <h2 className="teams-heading">{t("HOME.WE_ARE")}</h2>
          <p className="team-border" />
        </div>
        <div className="mem-row">
          {teamsList?.length
            ? teamsList?.map((eachData) => {
                return (
                  <>
                    <Row className="mb-5">
                      <Col sm={12}>
                        <h2 className="partner-category-color">
                          {" "}
                          {eachData?.category}
                        </h2>
                      </Col>
                    </Row>
                    <Row>
                      {eachData?.names?.map((each) => {
                        const desc = each?.organization_name
                          ? `${each?.designation} at ${each?.organization_name}`
                          : `${each?.designation} `;
                        return (
                          <Col lg={3} className="mem-col mb-5">
                            <div className="member-img-bg">
                              <img
                                src={each?.profile_image}
                                alt="icon"
                                className="member-img"
                              />
                            </div>
                            <h3 className="mem-name">{each.name}</h3>
                            <p className="mem-details" title={desc}>
                              {desc}
                            </p>
                            <div className="mem-info">
                              {each?.linkedin_url ? (
                                <BsLinkedin
                                  className="mem-info-in"
                                  onClick={() =>
                                    window.open(each?.linkedin_url)
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {each?.website_url ? (
                                <BsGlobe
                                  className="mem-info-gl"
                                  onClick={() => window.open(each?.website_url)}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </>
                );
              })
            : ""}
          {/* {teamsList?.map((each) => {
              const desc = each?.organization_name
                ? `${each?.designation} at ${each?.organization_name}`
                : `${each?.designation} `;

              return (
                <Col lg={3} className="mem-col mb-5">
                  <div className="member-img-bg">
                    <img
                      src={each?.profile_image}
                      alt="icon"
                      className="member-img"
                    />
                  </div>
                  <h3 className="mem-name">{each.name}</h3>
                  <p className="mem-details" title={desc}>
                    {desc}
                  </p>
                  <div className="mem-info">
                    {each?.linkedin_url ? (
                      <BsLinkedin
                        className="mem-info-in"
                        onClick={() => window.open(each?.linkedin_url)}
                      />
                    ) : (
                      ""
                    )}
                    {each?.website_url ? (
                      <BsGlobe
                        className="mem-info-gl"
                        onClick={() => window.open(each?.website_url)}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              );
            })} */}
        </div>
      </div>
      <Col sm={12} className="mt-5 p-0">
        <img src={Footer} alt="Footer" className="w-100" />
      </Col>
      <ClimberFooter page="climber" />
    </>
  );
}
export default OurTeams;
