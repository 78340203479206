import React, { useState, useEffect, useRef } from "react";
import { FormGroup, Row, Col, Container, Button, Input } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsEyeSlashFill } from "react-icons/bs";
import { IoEyeSharp } from "react-icons/io5";

import { Formik, Form, Field } from "formik";
import {
  formickTextField,
  formickPasswordField,
  formickReactSelectFiled,
  formickTextAreaField,
} from "../../components/Common/FormickField";
import { renderReactOptionsArray, renderOptionsArray } from "../../helpers";
import {
  selectRequired,
  validatePassword,
  validateEmail,
} from "../../helpers/validate";
import registerBackgroundImage from "../../assest/img/RegisterBackgroundImage.png";
import ClimberFooter from "../../components/Common/ClimberFooter";
import SuccessModal from "./RegisterSuccess";
import {
  provienceRequest,
  organizationRequest,
  registerationRequest,
  coreRequest,
  getAllUsersAgeRequest,
  getAllUsersGenderRequest,
  loginUser,
} from "../../store/actions";
import SocialLogins from "./SocialLogins";

function RegisterUser() {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({});
  const [provienceValue, setProvience] = useState({});
  const [country, setCountry] = useState({});
  const [showEyeIcon, setEyeIcon] = useState(false);
  const [ageList, setAgeList] = useState([]);
  const [genderList, setGenderList] = useState([]);

  const [organizationDropdownList, setOrganizationDropdownList] = useState([]);

  const [termsCheckbox, setTermsCheckbox] = useState();
  const [sucessModal, setSucessModal] = useState(false);
  const toggleEye = () => {
    setEyeIcon(!showEyeIcon);
  };

  const selectProvience = (e) => {
    setProvience(e);
  };
  const selectCoutry = (e) => {
    if (e?.value !== country?.value) {
      setCountry(e);
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const isWeb = urlParams.get("isWeb");

  useEffect(() => {
    window.scrollTo(0, 0);
    setInitialValues({
      state_id: "",
      email: "",
      password: "",
      organization: "",
      organization_name: "",
      firstName: "",
      lastName: "",
      middleName: "",
      mobileNumber: "",
      age: "",
      gender: "",
      remarks: "",
      country: "",
    });
    const selectedCountry = JSON.parse(localStorage.getItem("selectedCountry"));
    setCountry({
      label: selectedCountry?.label,
      value: selectedCountry?.value,
    });
    dispatch(organizationRequest());
    dispatch(coreRequest({ location_type: "country" }));
    dispatch(getAllUsersAgeRequest());
    dispatch(getAllUsersGenderRequest());
  }, []);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("selectedCountry"))) {
      const selectedCountry = JSON.parse(
        localStorage.getItem("selectedCountry"),
      );
      setCountry({
        label: selectedCountry?.label,
        value: selectedCountry?.value,
      });
      setInitialValues((prev) => ({
        ...prev,
        country: {
          label: selectedCountry?.label,
          value: selectedCountry?.value,
        },
      }));
    }
  }, [JSON.parse(localStorage.getItem("selectedCountry"))?.value]);

  useEffect(() => {
    if (country?.value) {
      dispatch(
        provienceRequest({
          location_type: "state",
          parent_id: country?.value,
        }),
      );
      setProvience({});
    }
  }, [country]);

  /* ------handling api data---------*/
  const nextProps = useSelector((state) => ({
    provsionList: state.Register?.provienceList,
    organizationList: state.User?.organizationList,
    register: state.User?.registration,
    countriesList: state.Core?.coreList,
    ageDetails: state?.ManageUserReducer?.ageList,
    genderDetails: state?.ManageUserReducer?.genderList,
    loginData: state.Login.loginData,
  }));
  const isOtherTools = useRef(true);
  useEffect(() => {
    if (isOtherTools.current) {
      isOtherTools.current = false;
      return;
    }
    if (nextProps.organizationList) {
      const list = renderReactOptionsArray(
        nextProps.organizationList,
        "title",
        "id",
      );
      list.push({ label: "others", value: "" });
      setOrganizationDropdownList([...list]);
    }
  }, [nextProps.organizationList]);

  const isAgeList = useRef(true);
  useEffect(() => {
    if (isAgeList.current) {
      isAgeList.current = false;
      return;
    }
    if (nextProps.ageDetails) {
      const list = renderOptionsArray(nextProps.ageDetails, "age_group", "id");
      setAgeList([...list]);
    }
  }, [nextProps.ageDetails]);

  const isgenderList = useRef(true);
  useEffect(() => {
    if (isgenderList.current) {
      isgenderList.current = false;
      return;
    }
    if (nextProps.genderDetails) {
      const list = renderOptionsArray(
        nextProps.genderDetails,
        "gender_name",
        "id",
      );
      setGenderList([...list]);
    }
  }, [nextProps.genderDetails]);

  const isRegisterSucess = useRef(true);
  useEffect(() => {
    if (isRegisterSucess.current) {
      isRegisterSucess.current = false;
      return;
    }
    if (nextProps.register) {
      setSucessModal(true);
      window.scrollTo(0, 0);
    }
  }, [nextProps.register]);

  // When user logins using social media
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (nextProps.loginData?.result?.token) {
      history("/home");
    } else {
      history("/registerPage");
    }
  }, [nextProps.loginData]);

  const onSelectCheckbox = (e) => {
    setTermsCheckbox(e.target.checked);
  };
  const onSubmitRefisterationForm = (value) => {
    const register = {
      first_name: value.firstName,
      last_name: value.lastName,
      middle_name: value.middleName,
      state: value.state_id?.value ? value.state_id?.value : "",
      email: value.email,
      password: value.password,
      country: value?.country?.value,
      organization_type: value.organization?.value,
      gender: value.gender.value,
      age: value.age.value,
      remarks: value.remarks,
    };
    register.organization_name = value.organization_name;
    dispatch(registerationRequest(register));
  };

  const handleLogin = (provider, data) => {
    const payload = {
      email: "",
      password: "",
      social_media: true,
      social_media_type: provider,
      social_media_token:
        provider === "google" ? data : data?.access_token || data?.accessToken,
    };

    dispatch(loginUser(payload));
  };

  return (
    <div>
      <div className="register-container">
        <div
          className={
            !sucessModal
              ? "register-background flex-container register-card-padding"
              : "register-background flex-container   sucess-card-padding"
          }
        >
          <Container fluid className="flex-container index-z">
            {!sucessModal ? (
              <div className="registeration-card">
                <div>
                  <Row className="d-flex align-items-center">
                    <Col sm={6}>
                      <h2 className="register-heading">
                        {" "}
                        {t("REGISTER.REGISTERATION")}{" "}
                      </h2>
                    </Col>
                    {!isWeb ? (
                      <Col sm={12} md={6}>
                        <div className="social-main-container">
                          <p className="register-social-text">
                            {t("REGISTER.SOCIAL_SIGN_UP")}
                          </p>
                          <SocialLogins
                            iconContainerClassName="social-register-icon-container"
                            handleLogin={handleLogin}
                            size={40}
                            socialConatinerClassName="register-social-container"
                            googleBorderNone="google-border-none"
                          />
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validate={(values) => {
                      const errors = {};
                      if (values.confirmPassword === "") {
                        errors.confirmPassword = "VALIDATION.SELECT_REQUIRED";
                      }
                      if (
                        values.confirmPassword &&
                        values.confirmPassword !== values.password
                      ) {
                        errors.confirmPassword = "VALIDATION.CONFIRM_PASSWORD";
                      }
                      return errors;
                    }}
                    onSubmit={onSubmitRefisterationForm}
                    validateOnMount
                  >
                    {({
                      errors,
                      touched,
                      isValid,
                      dirty,
                      setFieldValue,
                      values,
                    }) => {
                      return (
                        <Form>
                          <Row>
                            <Col sm={12} className="mt-4 mb-3 ">
                              <p className="register-section-heading">
                                {t("REGISTER.GENERAL_DETAILS")}
                              </p>
                            </Col>
                            <Col sm={4} className="mb-3">
                              <Field
                                classNameActive="input-class"
                                classNameInactive={
                                  errors?.name && touched?.name
                                    ? "input-class-empty input-error-class"
                                    : "input-class-empty"
                                }
                                type="text"
                                name="firstName"
                                onChangeField={() => {}}
                                component={formickTextField}
                                placeholder={t(
                                  "REGISTER.FIRST_NAME_PLACEHOLDER",
                                )}
                                lable={t("REGISTER.FIRST_NAME_LABLE")}
                                validate={selectRequired}
                                showValidation="true"
                              />
                              {errors?.firstName && touched?.firstName && (
                                <p className=" error">{t(errors?.firstName)}</p>
                              )}
                            </Col>

                            <Col sm={4} className="mb-4">
                              <Field
                                classNameActive="input-class"
                                classNameInactive={
                                  errors?.name && touched?.name
                                    ? "input-class-empty input-error-class"
                                    : "input-class-empty"
                                }
                                type="text"
                                name="middleName"
                                component={formickTextField}
                                placeholder={t(
                                  "REGISTER.MIDDLE_NAME_PLACEHOLDER",
                                )}
                                lable={t("REGISTER.MIDDLE_NAME_LABLE")}
                                onChangeField={() => {}}
                              />
                              {errors?.middleName && touched?.middleName && (
                                <p className=" error">
                                  {t(errors?.middleName)}
                                </p>
                              )}
                            </Col>

                            <Col sm={4} className="mb-4">
                              <Field
                                classNameActive="input-class"
                                classNameInactive={
                                  errors?.name && touched?.name
                                    ? "input-class-empty input-error-class"
                                    : "input-class-empty"
                                }
                                type="text"
                                name="lastName"
                                component={formickTextField}
                                placeholder={t(
                                  "REGISTER.LAST_NAME_PLACEHOLDER",
                                )}
                                lable={t("REGISTER.LAST_NAME_LABLE")}
                                validate={selectRequired}
                                onChangeField={() => {}}
                                showValidation="true"
                              />
                              {errors?.lastName && touched?.lastName && (
                                <p className=" error">{t(errors?.lastName)}</p>
                              )}
                            </Col>

                            <Col sm={4} className="mb-3">
                              <Field
                                type="select"
                                name="gender"
                                component={formickReactSelectFiled}
                                placeholder={t("REGISTER.GENDER_PLACEHOLDER")}
                                options={genderList}
                                label={t("REGISTER.GENDER_LABLE")}
                                className={
                                  errors?.gender &&
                                  touched?.gender &&
                                  values?.gender === ""
                                    ? "common-select-r input-error-class"
                                    : "common-select-r"
                                }
                                validate={selectRequired}
                                selectProvience={() => {}}
                              />
                              {errors?.gender &&
                                touched?.gender &&
                                values?.gender === "" && (
                                  <p className=" error">{t(errors?.gender)}</p>
                                )}
                            </Col>

                            <Col sm={4} className="mb-3">
                              <Field
                                type="select"
                                name="age"
                                key={touched?.age}
                                component={formickReactSelectFiled}
                                placeholder={t("REGISTER.AGE_PLACEHOLDER")}
                                options={ageList}
                                label={t("REGISTER.AGE_LABLE")}
                                className={
                                  errors?.age &&
                                  touched?.age &&
                                  values?.age === ""
                                    ? "common-select-r input-error-class"
                                    : "common-select-r"
                                }
                                // value={provienceValue}
                                validate={selectRequired}
                                selectProvience={() => {}}
                              />
                              {errors?.age &&
                                touched?.age &&
                                values?.age === "" && (
                                  <p className=" error">{t(errors?.age)}</p>
                                )}
                            </Col>
                          </Row>
                          <hr className="register-seperator" />

                          <Row>
                            <Col sm={12} className="mb-3">
                              <p className="register-section-heading">
                                {t("REGISTER.CREDENTIALS")}
                              </p>
                            </Col>

                            <Col sm={6} className="mb-3">
                              <FormGroup className="text-field-margin">
                                <Field
                                  classNameActive="input-class"
                                  classNameInactive={
                                    errors?.email && touched?.email
                                      ? "input-class-empty input-error-class"
                                      : "input-class-empty"
                                  }
                                  type="email"
                                  name="email"
                                  component={formickTextField}
                                  onChangeField={() => {}}
                                  placeholder={t("REGISTER.EMAIL_PLACEHOLDER")}
                                  lable={t("REGISTER.EMAIL_PLACEHOLDER")}
                                  validate={validateEmail}
                                />
                                {errors?.email && touched?.email && (
                                  <p className=" error">{t(errors?.email)}</p>
                                )}
                              </FormGroup>
                            </Col>

                            <Col sm={6} className="mb-3">
                              <FormGroup className="mb-0 text-field-margin">
                                <Field
                                  classNameActive="input-class"
                                  classNameInactive={
                                    errors?.password && touched?.password
                                      ? "input-class-empty input-error-class"
                                      : "input-class-empty"
                                  }
                                  type={showEyeIcon ? "text" : "password"}
                                  name="password"
                                  component={formickPasswordField}
                                  placeholder={t(
                                    "REGISTER.PASSWORD_PLACEHOLDER",
                                  )}
                                  lable={t("REGISTER.PASSWORD_PLACEHOLDER")}
                                  icon={
                                    showEyeIcon ? (
                                      <IoEyeSharp
                                        fontSize={20}
                                        onClick={toggleEye}
                                      />
                                    ) : (
                                      <BsEyeSlashFill
                                        fontSize={20}
                                        onClick={toggleEye}
                                      />
                                    )
                                  }
                                  validate={validatePassword}
                                />
                                {errors?.password && touched?.password && (
                                  <p className=" error">
                                    {t(errors?.password)}
                                  </p>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>

                          <hr className="register-seperator" />

                          <Row>
                            <Col sm={12} className="mb-3">
                              <p className="register-section-heading">
                                {t("REGISTER.OTHER_DETAILS")}
                              </p>
                            </Col>

                            <Col sm={6} className=" mb-4">
                              <Field
                                type="select"
                                name="organization"
                                component={formickReactSelectFiled}
                                placeholder={t(
                                  "REGISTER.TYPE_OF_ORGANIZATION_PLACEHOLDER",
                                )}
                                options={renderReactOptionsArray(
                                  organizationDropdownList,
                                  "label",
                                  "value",
                                )}
                                label={t("REGISTER.TYPE_OF_ORGANIZATION_LABLE")}
                                className={
                                  errors?.organization &&
                                  touched?.organization &&
                                  values?.organization === ""
                                    ? "common-select-r input-error-class"
                                    : "common-select-r"
                                }
                                // value={provienceValue}
                                validate={selectRequired}
                                selectProvience={() => {}}
                              />
                              {errors?.organization &&
                                touched?.organization &&
                                values?.organization === "" && (
                                  <p className=" error">
                                    {t(errors?.organization)}
                                  </p>
                                )}
                            </Col>

                            <Col sm={6} className="registeration-header mb-4">
                              <Field
                                classNameActive="input-class"
                                classNameInactive={
                                  errors?.organization_name &&
                                  touched?.organization_name
                                    ? "input-class-empty input-error-class"
                                    : "input-class-empty"
                                }
                                type="text"
                                name="organization_name"
                                component={formickTextField}
                                onChangeField={() => {}}
                                placeholder={t(
                                  "REGISTER.ORGINIZATION_PLACEHOLDER",
                                )}
                                lable={t("REGISTER.ORGINIZATION_PLACEHOLDER")}
                                validate={selectRequired}
                                OTHER_ORGANIZATION
                              />
                              {errors?.organization_name &&
                                touched?.organization_name && (
                                  <p className=" error">
                                    {t(errors?.organization_name)}
                                  </p>
                                )}
                            </Col>

                            <Col sm={6} className="registeration-header mb-3">
                              <Field
                                type="select"
                                name="country"
                                component={formickReactSelectFiled}
                                placeholder={t("REGISTER.COUNTRY")}
                                options={renderReactOptionsArray(
                                  nextProps?.countriesList,
                                  "name",
                                  "id",
                                )}
                                label={t("REGISTER.COUNTRY")}
                                className={
                                  errors?.country &&
                                  touched?.country &&
                                  values?.country === ""
                                    ? "common-select-r input-error-class"
                                    : "common-select-r"
                                }
                                validate={selectRequired}
                                selectProvience={(e) => {
                                  e?.value !== country?.value &&
                                    setFieldValue("state_id", null);
                                  selectCoutry(e);
                                }}
                              />

                              {errors?.country &&
                                touched?.country &&
                                values?.country === "" && (
                                  <p className=" error">{t(errors?.country)}</p>
                                )}
                            </Col>
                            <Col sm={6} className="registeration-header">
                              <Field
                                type="select"
                                name="state_id"
                                component={formickReactSelectFiled}
                                placeholder={t(
                                  "REGISTER.PROVIENCE_PLACEHOLDER",
                                )}
                                options={renderReactOptionsArray(
                                  nextProps?.provsionList,
                                  "name",
                                  "id",
                                )}
                                label={t("REGISTER.PROVIENCE_PLACEHOLDER")}
                                className={
                                  errors?.state_id && touched?.state_id
                                    ? "common-select-r input-error-class"
                                    : "common-select-r"
                                }
                                value={provienceValue}
                                selectProvience={selectProvience}
                              />
                              {errors?.state_id && touched?.state_id && (
                                <p className=" error">{t(errors?.state_id)}</p>
                              )}
                            </Col>
                          </Row>

                          <hr className="register-seperator" />
                          <Row>
                            <Col sm={12} className="mt-3 mb-3">
                              <FormGroup className="">
                                <Field
                                  classNameActive="input-class-text-area"
                                  classNameInactive={
                                    errors?.name && touched?.name
                                      ? "input-class-empty-text-area input-error-class"
                                      : "input-class-empty-text-area"
                                  }
                                  type="textarea"
                                  name="remarks"
                                  component={formickTextAreaField}
                                  placeholder={t(
                                    "REGISTER.ANY_REMARKS_PLACEHOLDER",
                                  )}
                                  lable={t("REGISTER.ANY_REMARKS")}
                                  onChangeField={() => {}}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col
                              sm={11}
                              className="d-flex map-preferences-checkbox-registeration team-patner-section"
                            >
                              <Input
                                type="checkbox"
                                className="agree-checkbox "
                                onChange={onSelectCheckbox}
                              />
                              <span className="checkbox-text ml-2">
                                {t("REGISTER.I_AGREE")}
                              </span>
                              <span className="term-condt ml-2">
                                {" "}
                                <a href="/terms" target="_blank">
                                  {t("REGISTER.CONDITIONS")}
                                </a>{" "}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12} className="mt-4">
                              <Button
                                type="submit"
                                className="register-button"
                                disabled={!termsCheckbox || !isValid || !dirty}
                              >
                                {" "}
                                {t("REGISTER.REGISTER")}{" "}
                              </Button>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={12} className=" justify-center mt-4">
                              <p className="register-account m-0">
                                {" "}
                                {t("REGISTER.ALREADY_HAVE_ACCOUNT")}
                              </p>
                              {"   "}
                              <span
                                onClick={() => history("/login")}
                                role="button"
                                tabIndex="0"
                                onKeyUp={() => {}}
                                className="sign-in-register ml-2"
                              >
                                {"    "}
                                {t("REGISTER.SIGN_IN")}
                              </span>
                            </Col>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            ) : (
              <div className="sign-in-card-register reset-success-card">
                <Row className="mb-4">
                  <Col sm={12}>
                    <SuccessModal
                      modalHeaderMessage={t("REGISTER.SUCESS_MESSAGE_HEADING")}
                      navigateLink="/home"
                      modalSuccessMessage={t("REGISTER.SUCESS_MESSAGE_BODY")}
                      isWeb={isWeb}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Container>
        </div>
        <div>
          <img
            src={registerBackgroundImage}
            alt="pic"
            className="register-bg-image"
          />
        </div>
      </div>
      <ClimberFooter />
    </div>
  );
}
export default RegisterUser;
