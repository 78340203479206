import React from "react";
import { useTranslation } from "react-i18next";
import { AiFillFileWord, AiOutlineFilePdf } from "react-icons/ai";
import { FaFileCsv, FaImage, FaUserCircle } from "react-icons/fa";
import { MdOutlineOndemandVideo } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroll-component";
import { Col, Row } from "reactstrap";

import csvImage from "../../assest/img/csvlogo.png";
import docxUploadImage from "../../assest/img/docx_icon123.svg.png";
import PdfImg from "../../assest/img/pdflogo.png";
import ProgressBar from "../../components/Common/ProgressBar";
import { renderDateTimeFormat } from "../../components/Common/Utils";

function CropYieldAssessmentModule({
  totalRating,
  pageCount,
  progressBar,
  maxRatingCount,
  pageNumber,
  isLoading,
  fetchNextData,
  feedbackList,
  renderRatingIcon,
  selectedModule,
}) {
  const { t } = useTranslation();

  return (
    <Row>
      <Col sm={12}>
        <h2 className="module-name">{selectedModule.label}</h2>
        <hr className="feedback-divder-line" />
      </Col>
      <Col sm={4}>
        <div>
          <h1 className="rating-header">
            {totalRating} <span>Rating</span>
          </h1>
          {pageCount ? (
            <p className="total-feedbacks">{pageCount} feedbacks</p>
          ) : (
            ""
          )}
          <hr className="feedback-divder-line" />
          <ProgressBar
            progressBar={progressBar}
            maxRatingCount={maxRatingCount}
          />
        </div>
      </Col>
      <Col sm={8} className="d-flex ">
        <div className="feedback-vertical-line" />
        <div className="infinite-scroll-container" id="scrollableDiv">
          <InfiniteScroll
            dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ""}
            next={fetchNextData}
            className="flex flex-wrap feedback-infinite-scroll"
            hasMore={pageNumber * 10 < pageCount}
            loader={
              isLoading && (
                <center>
                  <h4>{t("LOADING.LOADING")} </h4>
                </center>
              )
            }
            scrollableTarget="scrollableDiv"
            style={{ overflowX: "hidden" }}
          >
            {feedbackList?.length
              ? feedbackList?.map((feedback) => (
                  <>
                    <div
                      className="d-flex"
                      key={`${feedback?.user_name}${feedback?.comment}`}
                    >
                      <div>
                        <FaUserCircle className="feedback-user-img" />
                      </div>
                      <div className="mx-2" />
                      <div className="pl-0 w-100">
                        <p className="feedback-user-name">
                          {feedback?.user_name}
                        </p>
                        {/*
                         * Need to change in future if we have more modules
                         * Or need to create separate components for each module
                         */}
                        {selectedModule?.label === "Crop Yield Assessment" ? (
                          <p className="feedback-user-selection mb-3">
                            {`${feedback?.country_name}${
                              feedback?.state_name
                                ? `, ${feedback.state_name}`
                                : ""
                            }${
                              feedback?.district_name
                                ? `, ${feedback.district_name}`
                                : ""
                            }`}{" "}
                            | {feedback?.crop_name} | {feedback?.cyp_model} |{" "}
                            {`${feedback?.start_year} - ${feedback?.end_year}`}
                          </p>
                        ) : null}
                        <div className="d-flex align-items-center mb-3 mt-2">
                          {renderRatingIcon(feedback.rating)}
                        </div>
                        {feedback?.comment ? (
                          <p className="feedback-comment mb-3">
                            {feedback?.comment}
                          </p>
                        ) : null}
                        {feedback?.media_reference_files?.length ? (
                          <Row className="mb-2">
                            {feedback?.media_reference_files?.map((image) => {
                              const fileExtenstion = image?.split(".")?.pop();
                              const fileName = image?.split("/")?.pop();
                              return (
                                <Col sm={3} className="mb-3 pl-0 w-100">
                                  <a
                                    key={image}
                                    href={image}
                                    target="_blank"
                                    download
                                    rel="noreferrer"
                                  >
                                    <div className="feedback-image-conatiner">
                                      <img
                                        src={
                                          ["xlsx", "csv"].includes(
                                            fileExtenstion,
                                          )
                                            ? csvImage
                                            : fileExtenstion === "pdf"
                                            ? PdfImg
                                            : fileExtenstion === "docx"
                                            ? docxUploadImage
                                            : image
                                        }
                                        alt={image}
                                        className="feedback-image"
                                      />
                                      <div className="d-flex align-items-center p-3 feedback-file-label">
                                        <span>
                                          {" "}
                                          {fileExtenstion === "pdf" ? (
                                            <AiOutlineFilePdf className="pdf-download-icon" />
                                          ) : ["xlsx", "csv"].includes(
                                              fileExtenstion,
                                            ) ? (
                                            <FaFileCsv className="excel-download-icon-color" />
                                          ) : fileExtenstion === "docx" ? (
                                            <AiFillFileWord className="docx-download-icon" />
                                          ) : ["jpeg", "jpg", "png"].includes(
                                              fileExtenstion,
                                            ) ? (
                                            <FaImage className="docx-download-icon" />
                                          ) : (
                                            <MdOutlineOndemandVideo className="docx-download-icon" />
                                          )}{" "}
                                        </span>
                                        <p className="ml-1 tile-doc-upload">
                                          {" "}
                                          {fileName?.length > 35
                                            ? `${fileName.slice(
                                                0,
                                                13,
                                              )} ... ${fileName.slice(-14)} `
                                            : fileName}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </Col>
                              );
                            })}
                          </Row>
                        ) : null}
                        <p className="feedback-user-selection mb-2">
                          {renderDateTimeFormat(feedback?.created_date)}
                        </p>
                      </div>
                    </div>
                    <hr className="feedback-divder-line w-100 mx-1" />
                  </>
                ))
              : null}
          </InfiniteScroll>
        </div>
      </Col>
    </Row>
  );
}

export default CropYieldAssessmentModule;
