import { takeEvery, fork, all, call, put } from "redux-saga/effects";

// Login Redux States
import UPLOAD_PAST_DATA_CONSTS from "./actionTypes";
import {
  uploadPastDataSuccess,
  uploadPastDataFailure,
  getTemplateDataSuccess,
  getTemplateDataError,
  getPastDataListSuccess,
  getPastDataListError,
  getDownloadCypTemplateError,
  getDownloadCypTemplateSuccess,
  uploadCypDataSuccess,
  uploadCypDataError,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import { AXIOS_INSTANCE, BACKOFFICE_URL, CROP_YIELD } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

// upload pas data
function* uploadPastData(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${BACKOFFICE_URL}/add`, action.payload),
      "",
      "",
      true,
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(uploadPastDataSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.status,
        data: response.data,
      };
      yield put(uploadPastDataFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(uploadPastDataFailure(errorData));
  }
}

// get template data
function* getTemplateData(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  const url = `${BACKOFFICE_URL}/getMenuSubMenuTemplates?menu_slug=${
    action?.payload?.menu_slug
  }&sub_menu_slug=${
    action.payload?.sub_menu_slug ? action.payload?.sub_menu_slug : ""
  }&country_id=${action?.payload?.country_id}`;

  try {
    const apiResponse = baseMethod(AXIOS_INSTANCE.get(url), "", "");

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getTemplateDataSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(getTemplateDataError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getTemplateDataError(errorData));
  }
}

// get past data list
function* getPastDataList() {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${BACKOFFICE_URL}/raw_data_decision_log_list`),
      "",
      "",
      true,
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getPastDataListSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.status,
        data: response.data,
      };
      yield put(getPastDataListError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getPastDataListError(errorData));
  }
}

// get past data list
function* downloadCYPTemplate(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  const url = `${CROP_YIELD}/backoffice/getMenuSubMenuTemplates?menu_slug=${
    action?.payload?.menu_slug
  }&sub_menu_slug=${
    action.payload?.sub_menu_slug ? action.payload?.sub_menu_slug : ""
  }&country_id=${action?.payload?.country_id}`;
  try {
    const apiResponse = baseMethod(AXIOS_INSTANCE.get(`${url}`), "", "", true);

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getDownloadCypTemplateSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.status,
        data: response.data,
      };
      yield put(getDownloadCypTemplateError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDownloadCypTemplateError(errorData));
  }
}

// upload cyp data
function* cypDataUpload(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  const url = `${CROP_YIELD}/backoffice/add`;
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${url}`, action.payload),
      "",
      "",
      true,
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(uploadCypDataSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.status,
        data: response.data,
      };
      yield put(uploadCypDataError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(uploadCypDataError(errorData));
  }
}

export function* watchUploadPastData() {
  yield takeEvery(
    UPLOAD_PAST_DATA_CONSTS.UPLOAD_PAST_DATA_REQUEST,
    uploadPastData,
  );
}

export function* watchGetTemplateData() {
  yield takeEvery(
    UPLOAD_PAST_DATA_CONSTS.GET_TEMPLATE_DATA_REQUEST,
    getTemplateData,
  );
}

export function* watchGetPasrDataList() {
  yield takeEvery(
    UPLOAD_PAST_DATA_CONSTS.GET_PAST_DATA_LIST_REQUEST,
    getPastDataList,
  );
}

export function* watchDownloadCYPTemplate() {
  yield takeEvery(
    UPLOAD_PAST_DATA_CONSTS.CROP_YIELD_EXCEL_TEMPLATE_REQUEST,
    downloadCYPTemplate,
  );
}

export function* watchCypDataUpload() {
  yield takeEvery(
    UPLOAD_PAST_DATA_CONSTS.CROP_YIELD_DATA_SUBMIT_REQUEST,
    cypDataUpload,
  );
}
function* uploadPastDataSaga() {
  yield all([
    fork(watchUploadPastData),
    fork(watchGetTemplateData),
    fork(watchGetPasrDataList),
    fork(watchDownloadCYPTemplate),
    fork(watchCypDataUpload),
  ]);
}

export default uploadPastDataSaga;
