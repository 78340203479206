const MANAGE_USER_CONSTS = {
  // manage user
  GET_All_USER_REQUEST: "GET_All_USER_REQUEST",
  GET_All_USER_SUCCESS: "GET_All_USER_SUCCESS",
  GET_All_USER_ERROR: "GET_All_USER_ERROR",

  GET_All_USER_GENDER_REQUEST: "GET_All_USER_GENDER_REQUEST",
  GET_All_USER_GENDER_SUCCESS: "GET_All_USER_GENDER_SUCCESS",
  GET_All_USER_GENDER_ERROR: "GET_All_USER_GENDER_ERROR",

  GET_All_USER_AGE_REQUEST: "GET_All_USER_AGE_REQUEST",
  GET_All_USER_AGE_SUCCESS: "GET_All_USER_AGE_SUCCESS",
  GET_All_USER_AGE_ERROR: "GET_All_USER_AGE_ERROR",
};

export default MANAGE_USER_CONSTS;
