import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "reactstrap";
import _ from "lodash";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { htmlToText } from "../../components/Common/Utils";
import { getAllTopicsListRequest } from "../../store/actions";
import UserGuideIconImage from "../../assest/img/UserGuideIcon.png";

function UserGuide() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [getAllTopicsList, setGetAllTopicsList] = useState([]);
  const [searchContentWordEntered, setSearchContentWordEntered] = useState("");
  const { UserGuideState } = useSelector((state) => ({
    UserGuideState: state.UserGuide,
  }));

  const [, setOpen] = useOutletContext();

  const params = new URLSearchParams(window.location.search);

  const selectedSubModule = params.get("slug");

  const [isHoverid, setIsHoverId] = useState("");

  useEffect(() => {
    setGetAllTopicsList([]);
    window.scrollTo(0, 0);
    dispatch(getAllTopicsListRequest({ page: 1 }));
    setOpen(true);
    return () => {
      setGetAllTopicsList([]);
    };
  }, []);

  useEffect(() => {
    if (UserGuideState?.getAllTopicsListData?.data?.data?.result) {
      let new_list = [];
      const {
        data: { result },
      } = UserGuideState?.getAllTopicsListData?.data;
      if (result) {
        if (getAllTopicsList) {
          if (pageNumber > 1) {
            new_list = getAllTopicsList.concat(result.results);
          } else {
            new_list = result.results;
          }
        } else {
          new_list = result.results;
        }
        setGetAllTopicsList(new_list);
        setPageCount(result?.count);
      } else {
        setGetAllTopicsList([]);
      }
    }
  }, [UserGuideState.getAllTopicsListData]);

  const handleSearchContent = (e) => {
    const searchContent = e.target.value;
    setSearchContentWordEntered(searchContent);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.value?.length !== 0) {
      history({
        pathname: "/user-guide-search",
        search: `?q=${searchContentWordEntered}&slug=${selectedSubModule}`,
      });
    }
  };

  const handleClick = () => {
    if (searchContentWordEntered?.length !== 0) {
      history({
        pathname: "/user-guide-search",
        search: `?q=${searchContentWordEntered}&slug=${selectedSubModule}`,
      });
    }
  };

  const fetchNextTemplateData = () => {
    setPageNumber(pageNumber + 1);
    dispatch(
      getAllTopicsListRequest({
        page: pageNumber + 1,
      }),
    );
  };

  const renderTools = () => {
    return (
      <>
        {/* User guide Main page rendering cards info */}
        {getAllTopicsList?.length
          ? getAllTopicsList.map((item) => {
              return (
                <Col lg={4} sm={6} xs={12} className="userguide-col">
                  <Card
                    className="custom-card-x cb-1 cursor-ponter"
                    onClick={() =>
                      history(
                        `/user-guide-details?topicId=${item.id}&topic_name=${item.name}&slug=${selectedSubModule}`,
                      )
                    }
                    onMouseOver={() => (item.icon ? setIsHoverId(item.id) : {})}
                    onMouseOut={() => (item.icon_hover ? setIsHoverId("") : {})}
                  >
                    <div>
                      <img
                        src={
                          isHoverid === item.id ? item.icon_hover : item?.icon
                        }
                        alt="user-guide"
                        className="user-guide-icon-size"
                      />
                      <p className="icon-text mt-3">{item?.name}</p>

                      {htmlToText(item.description)?.length > 100 ? (
                        <div>
                          <div className="news-feed-raw-data-section">
                            {htmlToText(item.description).substring(0, 140)}{" "}
                            <span> {t("DASHBOARD.SPREAD")} </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="news-feed-raw-data-section"
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      )}
                    </div>
                  </Card>
                </Col>
              );
            })
          : ""}
      </>
    );
  };

  return (
    <div className="user-guide-main-page">
      <div className="user-guide-heading EA-Header">
        <img
          src={UserGuideIconImage}
          alt="user-guide"
          className="user-guide-icon-size d-none"
        />
        <h2 className="discription-text pt-2 pl-3 heading-text-user-guide">
          {t("DASHBOARD.USER_GUIDE")}
        </h2>
      </div>
      <Card className="user-guide-main-cards">
        <div className="user-guide-bottom-container">
          <div className="common-sidebar common-search-bar search-size ">
            <input
              className="inputField input-box"
              type="text"
              placeholder={t("USER_GUIDE.INPUT_TEXT")}
              value={searchContentWordEntered}
              onChange={handleSearchContent}
              onKeyPress={handleKeyPress}
            />
            <BsSearch
              onClick={handleClick}
              className="searchIcons user-guide-search-icon"
            />
          </div>
        </div>
        {_.isEmpty(getAllTopicsList) &&
          !UserGuideState.isGetAllTopicsListRequesting && (
            <h2 className="user-guide-home-page-no-records-found">
              {t("USER_GUIDE.NO_RECORDS_FOUND")}
            </h2>
          )}
        <InfiniteScroll
          dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ""}
          next={fetchNextTemplateData}
          hasMore={pageNumber * 10 < pageCount}
          loader={
            UserGuideState.isGetAllTopicsListRequesting && (
              <center>
                <h4>{t("LOADING.LOADING")} </h4>
              </center>
            )
          }
        >
          <div className="rendered-container cards-row">
            <Row>{renderTools()}</Row>
          </div>
        </InfiniteScroll>
      </Card>
    </div>
  );
}

export default UserGuide;
