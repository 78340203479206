import CLIMBER_CONST from "./actionTypes";

export const climberBannerRequest = (payload) => {
  return {
    type: CLIMBER_CONST.CLIMBER_BANNER_REQUEST,
    payload,
  };
};
export const climberBannerSuccess = (user) => {
  return {
    type: CLIMBER_CONST.CLIMBER_BANNER_SUCCESS,
    payload: user,
  };
};
export const climberBannerError = (error) => {
  return {
    type: CLIMBER_CONST.CLIMBER_BANNER_ERROR,
    payload: error,
  };
};

export const climberPartnerRequest = (data) => {
  return {
    type: CLIMBER_CONST.CLIMBER_PARTNERS_REQUEST,
    payload: data,
  };
};
export const climberPartnerSuccess = (user) => {
  return {
    type: CLIMBER_CONST.CLIMBER_PARTNERS_SUCCESS,
    payload: user,
  };
};
export const climberPartnerError = (error) => {
  return {
    type: CLIMBER_CONST.CLIMBER_PARTNERS_ERROR,
    payload: error,
  };
};

export const climberAboutUsRequest = (system) => {
  return {
    type: CLIMBER_CONST.CLIMBER_ABOUT_US_REQUEST,
    payload: system,
  };
};
export const climberAboutUsSuccess = (user) => {
  return {
    type: CLIMBER_CONST.CLIMBER_ABOUT_US_SUCCESS,
    payload: user,
  };
};
export const climberAboutUsError = (error) => {
  return {
    type: CLIMBER_CONST.CLIMBER_ABOUT_US_ERROR,
    payload: error,
  };
};

export const climberOtherToolsRequest = (data) => {
  return {
    type: CLIMBER_CONST.CLIMBER_OTHER_TOOLS_REQUEST,
    payload: data,
  };
};
export const climberOtherToolsSuccess = (user) => {
  return {
    type: CLIMBER_CONST.CLIMBER_OTHER_TOOLS_SUCCESS,
    payload: user,
  };
};
export const climberOtherToolsError = (error) => {
  return {
    type: CLIMBER_CONST.CLIMBER_OTHER_TOOLS_ERROR,
    payload: error,
  };
};

export const climberFooterRequest = (data) => {
  return {
    type: CLIMBER_CONST.CLIMBER_FOOTER_REQUEST,
    payload: data,
  };
};
export const climberFooterSuccess = (user) => {
  return {
    type: CLIMBER_CONST.CLIMBER_FOOTER_SUCCESS,
    payload: user,
  };
};
export const climberFooterError = (error) => {
  return {
    type: CLIMBER_CONST.CLIMBER_FOOTER_ERROR,
    payload: error,
  };
};
