import { takeEvery, fork, all, call, put } from "redux-saga/effects";

// Login Redux States
import DASH_BOARD_CONST from "./actionTypes";
import {
  dashBoardMenuSuccess,
  dashBoardMenuError,
  indicatorError,
  indicatorSuccess,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import { DASH_BOARD_API, AXIOS_INSTANCE } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* dashBoard(data) {
  const selectedCountry =
    data?.payload || JSON.parse(localStorage.getItem("selectedCountry"));

  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  if (selectedCountry) {
    try {
      const apiResponse = baseMethod(
        AXIOS_INSTANCE.get(`${DASH_BOARD_API}/menu/${selectedCountry?.code}`),
        "",
        "",
      );

      const response = yield call(checkHttpStatus, apiResponse);

      if (response && response.status === 200) {
        const responseData = {
          statusCode: 200,
          data: response.data.result,
        };
        yield put(dashBoardMenuSuccess(responseData));
      } else {
        const responseData = {
          // statusCode: response.data.error.error || 400,
          data: response.data.error,
        };
        yield put(dashBoardMenuError(responseData));
      }
    } catch (error) {
      const errorData = {
        error: {
          statusText: error,
          netWorkError: true,
        },
      };
      yield put(dashBoardMenuError(errorData));
    }
  }
}

function* indicatorlist(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${DASH_BOARD_API}/indices?menu_id=${action.payload?.sub_menu_id}&country_code=${action.payload?.countryCode}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(indicatorSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(indicatorError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(indicatorError(errorData));
  }
}

export function* watchDashBoardMenu() {
  yield takeEvery(DASH_BOARD_CONST.DASH_BOARD_MENU_REQUEST, dashBoard);
}
export function* watchDashBoardIndicator() {
  yield takeEvery(DASH_BOARD_CONST.INDICATOR_REQUEST, indicatorlist);
}
function* DashBoardSaga() {
  yield all([fork(watchDashBoardMenu), fork(watchDashBoardIndicator)]);
}

export default DashBoardSaga;
