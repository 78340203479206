import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BsInfo } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ReactComponent as UploadIcon } from "../../assest/img/ea-upload.svg";
import { showError } from "../../components/Common/Notification";
import { downloadExcelFile, renderFilesRemoveOfUpload } from "../../helpers";
import { getDownloadCypTemplateRequest } from "../../store/actions";

function PredictionDataUploadModal({
  toggle,
  modal,
  onHandleUploadImage,
  selectedLocation,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [originalFile, setOriginalFile] = useState([]);

  const closeBtn = (
    <GrFormClose
      size={24}
      onClick={() => toggle()}
      className="pointer media-modal-header-close-icon"
    />
  );

  const nextProps = useSelector((state) => ({
    CyptemplateData: state?.UploadPastData?.CyptemplateData,
  }));

  useEffect(() => {
    if (nextProps?.CyptemplateData?.length) {
      nextProps.CyptemplateData?.forEach((item) => {
        if (item?.template !== "") {
          downloadExcelFile(item?.template, "crop_yield_prediction");
        }
      });
    }
  }, [nextProps.CyptemplateData]);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    /**
     * ! Checking validation to upload only execel file
     */
    const validExtensions = [".xlsx", ".xls"];
    const fileName = event.target.files[0]?.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));
    if (!validExtensions.includes(fileExtension)) {
      showError(t("EARLY_ACTIONS.INVALID_FILE_TYPE"));
    } else if (event.target.files?.length) {
      const filesArray = Array.from(event.target.files);
      setOriginalFile(event.target.files[0]);
      setUploadedFile(filesArray);
    }
    event.target.value = null;
  };

  const handleUploadFile = () => {
    onHandleUploadImage(uploadedFile[0], originalFile);
    toggle();
  };

  const handleGetDownloadTemplate = () => {
    dispatch(
      getDownloadCypTemplateRequest({
        country_id: selectedLocation?.country?.value,
        menu_slug: "crop_yield_prediction",
      }),
    );
  };

  return (
    <div>
      <Modal isOpen={modal} size="md">
        <ModalHeader
          toggle={toggle}
          className="align-items-center media-modal-header-background"
          close={closeBtn}
        >
          <h2 className="intervention-h2 m-0">
            {t("CROP_YIELD.UPLOAD_PREDICTION_DATA")}
          </h2>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-between mb-3">
            <BsInfo className="crp-info-icon width100" width={16} height={16} />
            <p className="ml-2 w-100 crp-template-btn">
              {t("CROP_YIELD.UPLOAD_PREDITION_DATA_NOTE")}
              <span
                onClick={() => handleGetDownloadTemplate()}
                role="button"
                tabIndex="0"
                onKeyUp={() => {}}
              >
                {t("UPLOAD_PAST_DATA.DOWNLOAD_TEMPLATE")}
              </span>
            </p>
          </div>
          <section
            className="up-docx-dropzone mb-3"
            role="button"
            onClick={handleIconClick}
            onKeyUp={() => {}}
            tabIndex="0"
          >
            <div className="dropzone">
              <input
                className="cursor-pointer"
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <div className="text-center">
                <p className="feedback-upload-label">
                  <UploadIcon />
                </p>

                <p className="up-upload-text">
                  Drag your files here or
                  <span className="ml-1 up-browser">browse files</span>
                </p>
              </div>
            </div>
          </section>
          {uploadedFile?.length ? (
            <section>
              {renderFilesRemoveOfUpload(uploadedFile, setUploadedFile)}
            </section>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <div className="w-100 active-pahse-btn-section justify-content-end">
            <Button
              type="button"
              className="media-cancel-btn mr-3"
              onClick={() => {
                toggle();
              }}
            >
              {t("EARLY_ACTIONS.CANCLE")}
            </Button>
            <Button
              type="button"
              className="media-add-btn mb-1"
              onClick={() => handleUploadFile()}
              disabled={!uploadedFile?.length}
            >
              {t("BULLET_IN.SUBMIT")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default PredictionDataUploadModal;
