import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import Loader from "react-spinners/FadeLoader";
import { useTranslation } from "react-i18next";

function GlobalLoader(props) {
  const { t } = useTranslation();
  /*  -----nextprops------  */
  const { info, isSuspense, indicator, message } = props;
  const nextProps = useSelector(
    (state) => ({
      isLoading: state.Loader.isLoading,
      isGraphLoading: state.Loader.isGraphLoading,
      droughtTimeSeriesloading: state.Drought.droughtTimeSeriesloading,
      floodTimeSeriesloading: state.Drought.floodTimeSeriesloading,
      isWeatherForecastGraphRequesting:
        state.Weather.isWeatherForecastGraphRequesting,
      projectLoading: state?.EaProjects?.projectLoading,
      marketCommodityLoading: state.Market.marketCommodityLoading,
      getRawDataDatesLoading: state.Health.getRawDataDatesLoading,
    }),
    shallowEqual,
  );

  return (nextProps.isLoading &&
    !nextProps.droughtTimeSeriesloading &&
    !nextProps.floodTimeSeriesloading &&
    !nextProps.isWeatherForecastGraphRequesting) ||
    info === "fromGeoglows" ||
    info === "onlineBulletin" ||
    info === "earlyFinanceReport" ||
    nextProps.isGraphLoading ||
    nextProps?.getRawDataDatesLoading ||
    nextProps?.marketCommodityLoading ? (
    <div className="backdrop-loader" id="loader">
      <div className="overlay loader-text-alignmant">
        <Loader
          color="#179B6B"
          loading={
            nextProps.isLoading ||
            info === "fromGeoglows" ||
            info === "onlineBulletin" ||
            nextProps.isGraphLoading ||
            nextProps?.projectLoading ||
            nextProps?.getRawDataDatesLoading ||
            nextProps?.marketCommodityLoading
          }
          margin={2}
          size={20}
        />
        <div className="loadet-text mt-3">
          <p> {t("LOADING.LOADING")} </p>
        </div>
      </div>
    </div>
  ) : isSuspense || indicator ? (
    <div className="backdrop-loader" id="loader">
      <div className="overlay loader-text-alignmant">
        <Loader
          color="#179B6B"
          loading={nextProps.isLoading || indicator}
          margin={2}
          size={20}
        />
        <div className="loadet-text mt-3">
          <p> {message || t("LOADING.LOADING")} </p>
        </div>
      </div>
    </div>
  ) : null;
}

export default GlobalLoader;
