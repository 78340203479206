import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AwareStep from "../../assest/img/steps-1.png";
import HourGlass from "../../assest/img/hourglass.png";
import Finance from "../../assest/img/Finance.png";
import Warning from "../../assest/img/Warning.png";
import Note from "../../assest/img/note.png";
import AwareStep1 from "../../assest/img/steps-2.png";
import MapImg from "../../assest/img/steps-map.png";

function AwareWorks() {
  const { t } = useTranslation();
  /* ------handling api data---------*/
  const nextProps = useSelector((state) => ({
    bannerImages: state.Climber,
    aboutUs: state.Aware?.awareAboutData,
  }));

  return (
    <div>
      <div className="layout-spc row mt-5">
        <div className="Home-main-section col-lg-3 col-md-3 col-12 p-0">
          <div>
            <h3>
              {nextProps?.aboutUs?.[1]?.title
                ? nextProps?.aboutUs?.[1]?.title
                : ""}
            </h3>
            <p>
              {" "}
              {nextProps?.aboutUs?.[1]?.desc
                ? nextProps?.aboutUs?.[1]?.desc
                : ""}
            </p>
          </div>
        </div>
        <div className="home-row-section about-aware col-lg-9 col-md-9 col-12">
          <img src={AwareStep1} alt="img" />
          <div className="col-lg-4 col-md-4 col-12 about-aware-section">
            <div className="about-aware-col">
              <h3 className="early-warning">{t("HOME.RISK")}</h3>
              <ul className="ul-org">
                <li> {t("HOME.RISK1")}</li>

                <li>{t("HOME.RISK2")}</li>

                <li>{t("HOME.RISK3")}</li>

                <li>{t("HOME.RISK4")}</li>

                <li>{t("HOME.RISK5")}</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-12 about-aware-section">
            <div className="about-aware-col">
              <h3 className="finance">{t("HOME.ANALIST")}</h3>
              <ul className="ul-green">
                <li>{t("HOME.ANALIST1")}</li>

                <li>{t("HOME.ANALIST2")}</li>

                <li>{t("HOME.ANALIST3")}</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-12 about-aware-section">
            <div className="about-aware-col">
              <h3 className="bulletin">{t("HOME.DISSEMINATION")}</h3>

              <ul className="ul-ylw">
                <li>{t("HOME.DISSEMINATION1")}</li>

                <li>{t("HOME.DISSEMINATION2")}</li>

                <li>{t("HOME.DISSEMINATION3")}</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-12 about-aware-section">
            <div className="about-aware-col">
              <h3 className="news">{t("HOME.CAPABILITY")}</h3>
              <ul className="ul-blue">
                <li>{t("HOME.CAPABILITY1")}</li>

                <li>{t("HOME.CAPABILITY2")}</li>

                <li>{t("HOME.CAPABILITY3")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="layout-spc mb-5">
        <div className="Home-main-section col-lg-12 ">
          <h3> {t("HOME.WHY_AWARE")} </h3>
          <p>{t("HOME.WHY_AWARE_CONTENT")}</p>
        </div>
      </div>

      <div className="page-out-img page-out-img-1">
        <div className="layout-spc">
          <div className="Home-main-section col-lg-12 ">
            <h3>
              {nextProps?.aboutUs?.[0]?.title_how_its_works
                ? nextProps?.aboutUs?.[0]?.title_how_its_works
                : ""}
            </h3>
            <p>
              {nextProps?.aboutUs?.[0]?.desc_how_its_works
                ? nextProps?.aboutUs?.[0]?.desc_how_its_works
                : ""}
            </p>
          </div>
          <div className="home-row-section how-aware-works">
            <img src={AwareStep} alt="img" className="steps-img" />
            <div className="col-lg-6 col-md-6 col-8 home-col">
              <div className="home-col-section">
                <img src={Note} alt="icon" className="icon p-0" />
                <div className="step-1">
                  <h3>{t("HOME.STEP1")}</h3>
                  <p>{t("HOME.STEP1_P")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-8 home-col">
              <div className="home-col-section">
                <img src={HourGlass} alt="icon" className="icon p-0" />
                <div className="step-1">
                  <h3>{t("HOME.STEP2")}</h3>
                  <p>{t("HOME.STEP2_P")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-8 home-col">
              <div className="home-col-section">
                <img src={Warning} alt="icon" className="icon p-0" />
                <div className="step-1">
                  <h3>{t("HOME.STEP3")}</h3>
                  <p>{t("HOME.STEP3_P")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-8 home-col">
              <div className="home-col-section">
                <img src={Finance} alt="icon" className="icon p-0" />
                <div className="step-1">
                  <h3>{t("HOME.STEP4")}</h3>
                  <p>{t("HOME.STEP4_P")}</p>
                </div>
              </div>
            </div>
            <img src={MapImg} alt="img1" className="map-img" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AwareWorks;
