import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import i18n from "../../i18n";
import {
  loginSuccess,
  loginError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from "./actions";
import { USER_API, AXIOS_INSTANCE } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";
import LOGIN_CONST from "./loginConst";
import checkHttpStatus from "../apiUtils";
import { signIn } from "../../components/Common/Utils";

function* loginUser(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${USER_API}/login`, action.payload),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data,
      };
      localStorage.setItem(
        "loggedInUserDetails",
        JSON.stringify(response.data),
      );
      signIn(JSON.stringify(response.data));
      yield put(loginSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };

      yield put(loginError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(loginError(errorData));
  }
}

function* forgotPassword(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${USER_API}/forgot-password`, action.payload),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data,
      };
      yield put(forgotPasswordSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };

      yield put(forgotPasswordError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(forgotPasswordError(errorData));
  }
}

function* resetPassword(action) {
  const { t } = i18n;
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${USER_API}/reset-password`, action.payload),
      t("LOGIN.RESET_PASSWORD_SUCCESS"),
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data,
      };
      yield put(resetPasswordSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };

      yield put(resetPasswordError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(resetPasswordError(errorData));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_CONST.LOGIN_REQUEST, loginUser);
  yield takeEvery(LOGIN_CONST.FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeEvery(LOGIN_CONST.RESET_PASSWORD_REQUEST, resetPassword);
}

function* AuthSaga() {
  yield all([fork(watchUserLogin)]);
}

export default AuthSaga;
