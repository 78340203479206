import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";

function AlertTable(props) {
  const { COLUMNS, tableData } = props;

  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const data = useMemo(() => tableData, [tableData]);

  return (
    <div className="new-inv-table-dashboard">
      <MaterialReactTable
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 15, 20],
        }}
        className="example-table"
        columns={columns}
        data={data}
        muiTableContainerProps={{
          sx: {
            height: "300px",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
            "&::-webkit-scrollbar": {
              padding: "8px",
              width: "7px",
            },
            "&::-webkit-scrollbar-track": {
              borderRadius: "10px",
            },

            "&::-webkit-scrollbar-thumb": {
              background: "#8b8b8b",
              borderRadius: "10px",
            },

            "&::-webkit-scrollbar-thumb:hover": {
              background: "#8b8b8b",
            },
          },
        }}
        enableBottomToolbar={!(tableData?.length < 10)}
        enableTopToolbar={false}
        enablePagination={!(tableData?.length < 10)}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableHiding={false}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableGlobalFilter={false}
        enableSorting={false}
        enableColumnDragging={false}
        enableStickyHeader
      />
    </div>
  );
}

export default AlertTable;
