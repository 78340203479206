import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import * as xlsx from "xlsx";
import * as FileSaver from "file-saver";
import _, { isArray } from "lodash";
import { ReactComponent as DownloadIcon } from "../../../assest/img/ea-download.svg";
import { ReactComponent as EditIcon } from "../../../assest/img/ea-edit.svg";
import {
  editPreparednessContentRequest,
  editPreparednessContentSuccess,
  getPreparednessPhaseRequest,
  deletePreparednessContentSuccess,
  projectStatusRequest,
  projectStatusSuccess,
} from "../../../store/actions";
import {
  showError,
  showSuccess,
} from "../../../components/Common/Notification";
import PreparednessPhaseTable from "../Table";
import SideEditor from "../SideEditor";
import { htmlToText, isLogedIn } from "../../../components/Common/Utils";
import Comments from "../../../components/Common/Comments";
import { handleExportToCSVExcelImpacts } from "../../../helpers/excelDownload";

function ImpactsComponent({
  handleNext,
  data,
  projectDetails,
  slugType,
  editableRowKeys,
  setEditableRowKeys,
  isDefaultData,
  editInstance,
  isAllStepsCompleted,
  onHanldeDisable,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [openEditor, setOpenEditor] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [type, setType] = useState("");
  const [rowData, setRowData] = useState({});

  const [sendComment, setSendComment] = useState("");
  const [commentList, setCommentList] = useState([]);

  const userDetails = JSON.parse(isLogedIn());

  const projectData = JSON.parse(localStorage.getItem("projectDetails"));

  const nextProps = useSelector((state) => ({
    editedPreparednessPhaseData:
      state?.EAPreparednessPhaseReducer?.editedPreparednessPhaseData,
    editContentError: state?.EAPreparednessPhaseReducer?.editContentError,
    projectStatusMessage: state?.EaProjects?.projectStatus,
  }));

  // to show diable effect when we open editor modal
  useEffect(() => {
    onHanldeDisable(openEditor);
  }, [openEditor]);

  const isEditProjectSucess = useRef(true);
  useEffect(() => {
    if (isEditProjectSucess.current) {
      isEditProjectSucess.current = false;
      return;
    }
    if (nextProps.projectStatusMessage) {
      localStorage.setItem(
        "projectDetails",
        JSON.stringify({
          ...projectData,
          ea_pp_impact_status: true,
        }),
      );
      if (editInstance) {
        handleNext(2);
      } else {
        showError(t("VALIDATION.PROJECT_PENDING_WARNING"));
      }
    }
  }, [nextProps.projectStatusMessage]);

  const onSubmit = () => {
    // checking if impacts status is true navigate directly to impacts
    if (projectData?.ea_pp_paa_status) {
      handleNext(2);
    } else {
      if (projectData.ea_pp_impact_status) {
        // if user have edit instance the navigate diretly else showing error
        if (editInstance) {
          handleNext(2);
        } else {
          showError(t("VALIDATION.PROJECT_PENDING_WARNING"));
        }
      } else {
        const payload = {
          project_id: projectDetails?.id,
          ea_pp_impact_status: true,
        };
        dispatch(projectStatusRequest(payload));
      }
    }
  };

  const handleEditRow = (content) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setOpenEditor(true);
    setType("impacts");
    setRowData(content);
    setIsEditable(true);
    setEditableRowKeys(content);
  };

  const handleCloseModal = () => {
    setOpenEditor(!openEditor);
    setRowData({});
    setEditableRowKeys({});
  };

  const handleGetTableData = () => {
    dispatch(
      getPreparednessPhaseRequest({
        slug: slugType,
        project_id: projectDetails?.id,
        is_default: isDefaultData,
      }),
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(editPreparednessContentSuccess({}));
      dispatch(deletePreparednessContentSuccess({}));
      dispatch(projectStatusSuccess({}));
    };
  }, []);

  const isEditContentSucess = useRef(true);
  useEffect(() => {
    if (isEditContentSucess.current) {
      isEditContentSucess.current = false;
      return;
    }
    if (!_.isEmpty(nextProps.editedPreparednessPhaseData)) {
      setOpenEditor(false);
      setRowData({});
      setEditableRowKeys({});
      sendComment === "" &&
        showSuccess(nextProps.editedPreparednessPhaseData?.data);
      handleGetTableData();
    } else if (!_.isEmpty(nextProps.editContentError)) {
      setOpenEditor(false);
      setRowData({});
      setEditableRowKeys({});
    }
  }, [nextProps.editedPreparednessPhaseData, nextProps.editContentError]);

  const COLUMNS = [
    {
      Header: t("EARLY_ACTIONS.CATEGORY_OF_IMPACTS"),
      accessorKey: "categoryOfImpacts",
      size: 70,
    },
    {
      Header: t("EARLY_ACTIONS.DIRECT_IMPACTS"),
      accessorKey: "directImpacts",
      size: 150,
      Cell: (row) =>
        row.renderedCellValue.map((item) => (
          <div dangerouslySetInnerHTML={{ __html: item }} />
        )),
    },
    {
      Header: t("EARLY_ACTIONS.INDIRECT_IMPACTS"),
      accessorKey: "inDirectImpacts",
      size: 150,
      Cell: (row) =>
        row.renderedCellValue.map((item) => (
          <div dangerouslySetInnerHTML={{ __html: item }} />
        )),
    },
    {
      Header: t("EARLY_ACTIONS.MANAGE_DATA"),
      accessorKey: "view",
      size: 40,
    },
  ];

  const FILTERED_COLUMNS = COLUMNS.filter(
    (column) => column.accessorKey !== "view",
  );

  useEffect(() => {
    const modifiedData = [];
    if (data?.length) {
      data[0]?.content.forEach((contentData, index) => {
        const modifedColumn = {};
        modifedColumn.id = index;
        modifedColumn.categoryOfImpacts = contentData["Category of impacts"];
        modifedColumn.directImpacts = isArray(contentData["Direct Impacts"])
          ? [...contentData["Direct Impacts"]]
          : [contentData["Direct Impacts"]];
        modifedColumn.inDirectImpacts = isArray(contentData["Indirect impacts"])
          ? [...contentData["Indirect impacts"]]
          : [contentData["Indirect impacts"]];
        modifedColumn.view = (
          <div className="d-flex align-items-center justify-content-center">
            {editInstance ? (
              <span>
                <EditIcon
                  onClick={() => {
                    handleEditRow(contentData);
                  }}
                  className="cursor-pointer"
                />
              </span>
            ) : (
              ""
            )}
          </div>
        );
        modifiedData.push(modifedColumn);
      });
    }
    setSendComment("");
    setCommentList(data[0]?.comment);
    if (modifiedData?.length) {
      setTableData(modifiedData);
    }
  }, [data]);

  const handleEditApiCall = (variant) => {
    let payload;
    if (variant === "Edit") {
      // !FOR EDITING DATA WE NEED TO SEND OLD CONTENT AND NEW CONTENT
      payload = {
        project_id: projectDetails?.id,
        slug: slugType,
        old_content: [
          {
            "Category of impacts": rowData["Category of impacts"],
            "Direct Impacts": rowData["Direct Impacts"],
            "Indirect impacts": rowData["Indirect impacts"],
          },
        ],
        new_content: [
          {
            "Category of impacts": editableRowKeys["Category of impacts"],
            "Direct Impacts": editableRowKeys["Direct Impacts"],
            "Indirect impacts": editableRowKeys["Indirect impacts"],
          },
        ],
      };
    }

    dispatch(editPreparednessContentRequest(payload));
  };

  const hanldeExportToCSV = () => {
    // Need to set the headers of xlsx
    const customData = tableData.map((item) => ({
      "No.": item.id + 1,
      "Direct Impacts": htmlToText(item.directImpacts[0]),
      "Indirect impacts": htmlToText(item.inDirectImpacts[0]),
    }));
    const ws = xlsx.utils.json_to_sheet(customData);
    // Setting the header row style to bold
    const headerStyle = {
      font: { bold: true },
    };
    const headerRange = xlsx.utils.decode_range(ws["!ref"]);
    // eslint-disable-next-line no-plusplus
    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
      const cellAddress = xlsx.utils.encode_cell({
        r: headerRange.s.r,
        c: col,
      });
      ws[cellAddress].s = headerStyle;
    }
    // Setting column widths
    const columnWidths = [
      { wpx: 80 }, // Width for "No." column
      { wpx: 150 }, // Width for "Direct Impacts" column
      { wpx: 150 }, // Width for "Indirect impacts" column
    ];

    ws["!cols"] = columnWidths;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: "array" });
    const sheetData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(sheetData, `EA_PP_IMPACTS.xlsx`);
  };

  const onHandleDownloadexcel = () => {
    const columns = [
      { header: "No.", key: "id", width: 5, array: false },
      {
        header: "Direct Impacts",
        key: "directimpacts",
        width: 50,
        array: true,
      },
      {
        header: "Indirect impacts",
        key: "indirectimpacts",
        width: 100,
        array: true,
      },
    ];
    handleExportToCSVExcelImpacts(tableData, columns);
  };

  const onHandleComments = () => {
    const currentCommnet = {
      name: userDetails?.user_details?.[1]?.first_name,
      comment: sendComment,
      date: new Date(),
    };
    const commentData = [currentCommnet];
    dispatch(
      editPreparednessContentRequest({
        slug: slugType,
        project_id: projectDetails?.id,
        comment: commentData,
      }),
    );
  };

  return (
    <>
      {openEditor && (
        <SideEditor
          setOpenEditor={setOpenEditor}
          openEditor={openEditor}
          type={type}
          setRowData={setRowData}
          rowData={rowData}
          editableRowKeys={editableRowKeys}
          setEditableRowKeys={setEditableRowKeys}
          handleEditApiCall={handleEditApiCall}
          isEditable={isEditable}
          handleCloseModal={handleCloseModal}
        />
      )}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 className="category-header">{t("EARLY_ACTIONS.IMPACTS")}</h3>
        <div className="actions-container d-flex align-items-center justify-content-end">
          <span
            role="button"
            className="ea-upload-button d-none" // {/* hidden temporarly */}
            onClick={hanldeExportToCSV}
            onKeyUp={() => {}}
            tabIndex="0"
            aria-label="DownloadCsvImpact"
          >
            <DownloadIcon />
          </span>
          <span
            role="button"
            className="ea-upload-button"
            onClick={onHandleDownloadexcel}
            onKeyUp={() => {}}
            tabIndex="0"
            aria-label="DownloadexcelImpacts"
          >
            <DownloadIcon />
          </span>

          <Button
            className="submit-next-btn"
            onClick={onSubmit}
            disabled={
              !Object.values(tableData).every(
                (value) =>
                  value?.categoryOfImpacts !== "" &&
                  value?.directImpacts[0] !== "" &&
                  value?.inDirectImpacts[0] !== "",
              )
            }
          >
            {t("EARLY_ACTIONS.SUBMIT_NEXT")}
          </Button>
        </div>
      </div>

      <PreparednessPhaseTable
        COLUMNS={
          isAllStepsCompleted || !editInstance ? FILTERED_COLUMNS : COLUMNS
        }
        tableData={tableData}
        loading={false}
        id="test-table-xls-button"
      />
      {/* </ReactHTMLTableToExcel> */}
      {tableData?.length ? (
        <div className="mt-5">
          <Comments
            onHandleComments={onHandleComments}
            setComment={setSendComment}
            sendComment={sendComment}
            comment={sendComment}
            commentList={commentList}
            canAddComment={editInstance}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default ImpactsComponent;
