import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cropyieldgraph from "../cropYieldHistoricGraph";
import CyModalPerformance from "../cropYieldModalPerformance";
import { ReactComponent as FeedbackIcon } from "../../../assest/img/feedback-icon.svg";
import CurrentYieldGraph from "../currentYieldGraph";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function BasicTabs({
  graphPanelHeight,
  graphData,
  setGraphData,
  setYearGraphData,
  open,
  openPanel,
  modalperformance,
  selectedLocation,
  startYear,
  endYear,
  selectedModal,
  scrollableYear,
  value,
  setValue,
  showChart,
  openFeedbackModal,
  setOpenFeedbackModal,
  currentGraphData,
  selectedCropCalender,
  selectedCSEndYear,
  havePredictionData,
  trainingDataStartYear,
  trainingDataEndDateGraph,
}) {
  const { t } = useTranslation();
  const [height, setheight] = useState(0);
  useEffect(() => {
    const observeElementResize = (elementId) => {
      const myDiv = document.getElementById(elementId);
      const resizeObserver = new ResizeObserver((entries) => {
        const containerHeight = entries[0]?.target.offsetHeight;
        setheight(containerHeight);
      });
      resizeObserver.observe(myDiv);
      return () => {
        resizeObserver.disconnect();
      };
    };

    value === 1 && observeElementResize("tab-scroll-height-1");
    value === 2 && observeElementResize("tab-scroll-height-2");

    // Add more IDs as needed
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabsArray = [
    { label: t("CROP_YIELD.MODEL_PERFORMANCE") },

    { label: t("CROP_YIELD.PREDECTION_TAB_YIELD") },
    { label: t("CROP_YIELD.HISTORIC_CURRENT_YIEDL") },
  ];

  const customTabPanelArray = [
    {
      key: "modal-performance",
      className: "tab-scroll-height-crop-stage p-3",
      child: (
        <CyModalPerformance
          modalperformance={modalperformance}
          selectedModal={selectedModal}
          open={open}
          openPanel={openPanel}
          selectedLocation={selectedLocation}
          startYear={startYear}
          endYear={endYear}
          scrollableYear={scrollableYear}
          activetab={value}
          showChart={showChart}
          selectedCropCalender={selectedCropCalender}
          selectedCSEndYear={selectedCSEndYear}
        />
      ),
    },

    {
      key: "yeild-data",
      className: "tab-scroll-height",
      child: (
        <CurrentYieldGraph
          graphPanelHeight={graphPanelHeight}
          height12={height}
          setGraphData={setGraphData}
          setYearGraphData={setYearGraphData}
          graphData={currentGraphData}
          open={open}
          openPanel={openPanel}
          scrollableYear={scrollableYear}
          selectedLocation={selectedLocation}
          startYear={startYear}
          endYear={endYear}
          activetab={value}
          showChart={showChart}
          modalperformance={modalperformance}
        />
      ),
    },

    {
      key: "current-yeild-data",
      className: "tab-scroll-height",
      child: (
        <Cropyieldgraph
          graphPanelHeight={graphPanelHeight}
          height12={height}
          setGraphData={setGraphData}
          setYearGraphData={setYearGraphData}
          graphData={graphData}
          open={open}
          openPanel={openPanel}
          scrollableYear={scrollableYear}
          selectedLocation={selectedLocation}
          startYear={startYear}
          endYear={endYear}
          activetab={value}
          showChart={showChart}
          modalperformance={modalperformance}
          havePredictionData={havePredictionData}
          trainingDataStartYear={trainingDataStartYear}
          trainingDataEndDateGraph={trainingDataEndDateGraph}
        />
      ),
    },
  ];

  const handleOpenFeedbackModal = () => {
    setOpenFeedbackModal(!openFeedbackModal);
  };

  return (
    <Box
      sx={{ width: "100%", height: "100%", position: "relative" }}
      className="tabs-crop-predection"
      key={value}
    >
      <div
        role="button"
        tabIndex="0"
        className="feedback-btn"
        onClick={handleOpenFeedbackModal}
        onKeyUp={() => {}}
      >
        <FeedbackIcon className="mr-2" height={18} width={18} />
        <span className="feedback-label">Provide feedback</span>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              borderRadius: "12px",
              backgroundColor: "rgba(34, 173, 122, 1)",
              height: "4px",
              color: "black !important",
              fontFamily: "National !important",
              fontSize: "16px !important",
              textTransform: "none !important",
            },

            "& .MuiTab-root": {
              color: "rgba(54, 54, 54, 0.8)",
              fontSize: "16px !important",
              fontFamily: "National !important",
            },
          }}
        >
          {tabsArray?.map((tab) => (
            <Tab
              key={tab.label}
              label={tab.label}
              sx={{
                textTransform: "none !important",
                "&.Mui-selected": {
                  color: "black",
                  fontWeight: "600",
                  fontFamily: "Archivo",
                  outline: "0",
                  boxShadow: "none",
                  textTransform: "none !important",
                },
              }}
            />
          ))}
        </Tabs>
      </Box>
      {customTabPanelArray?.map((customTab, index) => (
        <CustomTabPanel
          key={customTab.key}
          value={value}
          index={index}
          className={customTab.className}
          id={`tab-scroll-height-${index}`}
        >
          {customTab.child}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
