import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import ReviewDataIcon from "../../assest/img/review-data-icon.svg";

function ReviewModal({ setIsOpenModal, isOpenModal, handleMethod, step }) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpenModal} size="md">
      <div className="success-modal">
        <ModalHeader className="p-0 delete-header user-flex-end mr-2 mt-5" />
        <ModalBody className="delete-modal-padding">
          <div className="reg-success">
            <img
              src={ReviewDataIcon}
              alt="review icon"
              className="reg-check-image"
            />
            <h2 className="back-office-succes-head-text">
              {t("EARLY_ACTIONS.REVIEW_CONFIRM")}
            </h2>
            <p className="delete-modal-text mb-4 px-5">
              {t("EARLY_ACTIONS.PROCEED_NEXT")}
            </p>
            <div
              className="w-100 d-flex align-items-center justify-content-center"
              style={{ gap: "12px" }}
            >
              <Button
                type="button"
                className="back-office-cancel-btn"
                onClick={() => {
                  setIsOpenModal();
                }}
              >
                {t("APPROVE_DATA.CANCEL")}
              </Button>
              <Button
                type="button"
                className="back-office-ok-btn w-auto mb-1 px-3"
                onClick={() => {
                  handleMethod(step);
                }}
              >
                {t("EARLY_ACTIONS.REVIEWED_AND_CONFIRMED")}
              </Button>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default ReviewModal;
