// import { act } from "react-dom/test-utils";
import READYNESSPHASE_PHASE_CONST from "./actionTypes";

const initialState = {
  isContentLoading: false,
  editContentError: "",
};
const EAPreparednessPhaseReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_EDIT_REQUEST:
      state = {
        ...state,
        isContentLoading: true,
        editedReadynessPhaseData: false,
        editContentError: false,
        editContentNetworkError: false,
      };
      break;
    case READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_EDIT_SUCCESS:
      state = {
        ...state,
        isContentLoading: false,
        editedReadynessPhaseData:
          action?.payload?.statusCode === 200 ? action.payload?.data : "",
        editContentError: false,
        editContentNetworkError: false,
      };
      break;
    case READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_EDIT_ERROR:
      state = {
        ...state,
        isContentLoading: false,
        editedReadynessPhaseData: false,
        editContentError: action?.payload?.data?.message,

        editContentNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;

    // create intervention

    case READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_CREATE_REQUEST:
      state = {
        ...state,
        isContentLoading: true,
        createReadynessPhaseData: false,
        createContentError: false,
        createContentNetworkError: false,
      };
      break;
    case READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_CREATE_SUCCESS:
      state = {
        ...state,
        isContentLoading: false,
        createReadynessPhaseData: action?.payload?.response,
        createContentError: false,
        createContentNetworkError: false,
      };
      break;
    case READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_CREATE_ERROR:
      state = {
        ...state,
        isContentLoading: false,
        createReadynessPhaseData: false,
        createContentError: action.payload.error
          ? false
          : action.payload.response,
        createContentNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;

    // delete intervention

    case READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_DELETE_REQUEST:
      state = {
        ...state,
        isContentLoading: true,
        deleteReadynessPhaseData: false,
        deleteContentError: false,
        deleteContentNetworkError: false,
      };
      break;
    case READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_DELETE_SUCCESS:
      state = {
        ...state,
        isContentLoading: false,
        deleteReadynessPhaseData: action?.payload?.response,
        deleteContentError: false,
        deleteContentNetworkError: false,
      };
      break;
    case READYNESSPHASE_PHASE_CONST.READYNESS_PHASE_DELETE_ERROR:
      state = {
        ...state,
        isContentLoading: false,
        deleteReadynessPhaseData: false,
        deleteContentError: action.payload.error
          ? false
          : action.payload.response,
        deleteContentNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
      break;

    // LISTING  intervention

    case READYNESSPHASE_PHASE_CONST.GET_READYNESS_INTERVENTIONS_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
        interventionList: false,
        error: false,
      };
      break;
    case READYNESSPHASE_PHASE_CONST.GET_READYNESS_INTERVENTIONS_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        interventionList: action?.payload?.data,
        error: false,
      };
      break;
    case READYNESSPHASE_PHASE_CONST.GET_READYNESS_INTERVENTIONS_LIST_ERROR:
      state = {
        ...state,
        loading: false,
        interventionList: false,
        error: action.payload.error ? false : action.payload.response,
      };
      break;

    // delete intervention
    case READYNESSPHASE_PHASE_CONST.INTERVENTION_DELETE_REQUEST:
      state = {
        ...state,
        loading: true,
        deleteIntervention: false,
        error: false,
      };
      break;
    case READYNESSPHASE_PHASE_CONST.INTERVENTION_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        deleteIntervention: action?.payload?.data,
        error: false,
      };
      break;
    case READYNESSPHASE_PHASE_CONST.INTERVENTION_DELETE_ERROR:
      state = {
        ...state,
        loading: false,
        deleteIntervention: false,
        error: action.payload.error ? false : action.payload.response,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};
export default EAPreparednessPhaseReducer;
