import ALERT_DASH_BOARD_CONST from "./actionTypes";

export const alertIndicatorRequest = (data) => {
  return {
    type: ALERT_DASH_BOARD_CONST.ALERT_INDICATOR_REQUEST,
    payload: data,
  };
};
export const alertIndicatorSuccess = (user) => {
  return {
    type: ALERT_DASH_BOARD_CONST.ALERT_INDICATOR_SUCCESS,
    payload: user,
  };
};
export const alertIndicatorError = (error) => {
  return {
    type: ALERT_DASH_BOARD_CONST.ALERT_INDICATOR_ERROR,
    payload: error,
  };
};
