import React, { useState, useEffect, useRef } from "react";
import { FormGroup, Row, Col, Container, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import { BsEyeSlashFill } from "react-icons/bs";
import { IoEyeSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import SuccessModal from "./Success";
import { formickTextField } from "../../components/Common/FormickField";
import LoginBackgroundImage from "../../assest/img/LoginBackgroundImage.png";
import ClimberFooter from "../../components/Common/ClimberFooter";
import { validatePassword, validateEmail } from "../../helpers/validate";
import { forgotPassword, resetPassword } from "../../store/auth/actions";

function ResetPassword() {
  const [initialValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const history = useNavigate();
  const dispatch = useDispatch();
  const [passwordType, setPasswordType] = useState(true);
  const [showEyeIconConfirmPassword, setEyeIconConfirmPassword] =
    useState(true);
  const [uniqueCode, setUniqueCode] = useState();

  const nextProps = useSelector((state) => ({
    forgotPasswordData: state.Login.forgotPasswordData,
    resetPasswordData: state.Login.resetPasswordData,
  }));
  const [successModal, setSuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [mobileRest, setMobileReset] = useState(false);
  const { t } = useTranslation();

  const urlParams = new URLSearchParams(window.location.search);

  const isWeb = urlParams.get("isWeb");

  const handleClick = () => {
    history("/login");
  };

  const handleOkayButtonClick = () => {
    setSuccessModal(false);
    history("/home");
  };

  const showPassword = () => {
    setPasswordType(!passwordType);
  };
  const toggleEyeConfirmPassword = () => {
    setEyeIconConfirmPassword(!showEyeIconConfirmPassword);
  };

  const handleResetDescription = () => {
    if (!uniqueCode) {
      return <p className="reset-desc">{t("LOGIN.FORGOT_PASSWORD_DESC")}</p>;
    }
    return <p className="reset-desc">{t("LOGIN.RESET_PASSWORD_DESC")}</p>;
  };
  const handleInputFields = (formick) => {
    if (!uniqueCode) {
      return (
        <Row>
          <Col sm={12} className="mb-4">
            <FormGroup className="">
              <Field
                classNameActive="input-class-login"
                classNameInactive={
                  formick?.errors?.email && formick?.touched?.email
                    ? "input-class-empty-login input-error-class"
                    : "input-class-empty-login"
                }
                type="email"
                name="email"
                component={formickTextField}
                onChangeField={() => {}}
                validate={validateEmail}
                placeholder={t("LOGIN.EMAIL")}
                lable={t("LOGIN.EMAIL")}
              />
              {formick?.errors?.email && formick?.touched?.email && (
                <p className=" error">{t(formick?.errors?.email)}</p>
              )}
            </FormGroup>
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col sm={12} className="mb-4">
          <FormGroup>
            <Field
              classNameActive="input-class-login"
              classNameInactive={
                formick?.errors?.newPassword && formick?.touched?.newPassword
                  ? "input-class-empty-login input-error-class"
                  : "input-class-empty-login"
              }
              type={passwordType ? "password" : "text"}
              name="newPassword"
              component={formickTextField}
              onChangeField={() => {}}
              validate={validatePassword}
              placeholder={t("LOGIN.NEW_PASSWORD")}
              lable={t("LOGIN.NEW_PASSWORD")}
            />
            <span
              className="show-reset-eye-password"
              role="button"
              tabIndex="0"
              onClick={showPassword}
              onKeyUp={() => {}}
            >
              {passwordType ? (
                <BsEyeSlashFill className="close-eye" />
              ) : (
                <IoEyeSharp className="show-eye" />
              )}
            </span>
            {formick?.errors?.newPassword && formick?.touched?.newPassword && (
              <p className=" error">{t(formick?.errors?.newPassword)}</p>
            )}
          </FormGroup>
        </Col>
        <Col sm={12} className="mb-4">
          <FormGroup>
            <Field
              classNameActive="input-class-login"
              classNameInactive={
                formick?.errors?.confirmPassword &&
                formick?.touched?.confirmPassword
                  ? "input-class-empty-login input-error-class"
                  : "input-class-empty-login"
              }
              type={showEyeIconConfirmPassword ? "password" : "text"}
              name="confirmPassword"
              component={formickTextField}
              onChangeField={() => {}}
              placeholder={t("LOGIN.CONFIRM_PASSWORD")}
              lable={t("LOGIN.CONFIRM_PASSWORD")}
            />
            <span
              className="show-reset-eye-password"
              role="button"
              tabIndex="0"
              onClick={toggleEyeConfirmPassword}
              onKeyUp={() => {}}
            >
              {showEyeIconConfirmPassword ? (
                <BsEyeSlashFill className="close-eye" />
              ) : (
                <IoEyeSharp className="show-eye" />
              )}
            </span>
            {formick?.errors?.confirmPassword &&
              formick?.touched?.confirmPassword && (
                <p className=" error">{t(formick?.errors?.confirmPassword)}</p>
              )}
          </FormGroup>
        </Col>
      </Row>
    );
  };

  const handleSendResetButtons = (formick) => {
    if (!uniqueCode) {
      return (
        <Row>
          <Col sm={12} className="">
            <Button
              type="submit"
              className="sign-in-button w-100"
              disabled={
                !formick.dirty ||
                !formick.isValid ||
                Object.keys(formick.values)?.length === 0
              }
            >
              {" "}
              {t("LOGIN.SEND")}
            </Button>
          </Col>
          {isWeb ? (
            ""
          ) : (
            <div className="text-center w-100 mb-3">
              <p className="m-0 p-0 login-term-text mt-3 cursor-ponter">
                {" "}
                {t("LOGIN.BACK_TO")}
                <span
                  onClick={handleClick}
                  role="button"
                  tabIndex="0"
                  onKeyUp={() => {}}
                  className="register-link"
                >
                  {t("LOGIN.SIGN_IN")}
                </span>
              </p>
            </div>
          )}
        </Row>
      );
    }
    return (
      <Row>
        <Col sm={12} className="reset-button-card">
          <Button
            type="submit"
            className="sign-in-button w-100"
            disabled={
              !formick.isValid || Object.keys(formick.values)?.length < 2
            }
          >
            {" "}
            {t("LOGIN.RESET")}
          </Button>
        </Col>
        {isWeb ? (
          ""
        ) : (
          <div className="text-center w-100 mb-3">
            <p className="m-0 p-0 login-term-text mt-3 cursor-ponter">
              {" "}
              {t("LOGIN.BACK_TO")}
              <span
                onClick={handleClick}
                role="button"
                tabIndex="0"
                onKeyUp={() => {}}
                className="register-link"
              >
                {t("LOGIN.SIGN_IN")}
              </span>
            </p>
          </div>
        )}
      </Row>
    );
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const keyValue = params.get("key");
    if (window.location.href.includes("key")) {
      setUniqueCode(keyValue);
    }
    setSuccessModal(false);
  }, []);

  const onFormSubmit = (values) => {
    if (uniqueCode) {
      const formsData = {
        unique_code: uniqueCode,
        new_password: values.newPassword,
      };
      dispatch(resetPassword(formsData));
    } else {
      const data = {
        email: values.email,
        is_web: !!isWeb,
        // is_web: true,
      };
      dispatch(forgotPassword(data));
    }
  };
  const isForgotRun = useRef(true);
  useEffect(() => {
    if (isForgotRun.current) {
      isForgotRun.current = false;
      return;
    }
    if (nextProps?.forgotPasswordData) {
      const { result } = nextProps.forgotPasswordData;
      if (result) {
        setSuccessModal(true);
        setModalMessage(result);
      }
    }
  }, [nextProps.forgotPasswordData]);
  const isResetRun = useRef(true);
  useEffect(() => {
    if (isResetRun.current) {
      isResetRun.current = false;
      return;
    }
    if (nextProps?.resetPasswordData) {
      const { result } = nextProps.resetPasswordData;
      if (result) {
        if (!isWeb) {
          history("/login");
        } else {
          setMobileReset(true);
        }
      }
    }
  }, [nextProps.resetPasswordData]);

  return (
    <div>
      <div className="sign-in-background flex-container">
        <Container fluid className="flex-container index-z">
          {!mobileRest ? (
            !successModal ? (
              <div className="sign-in-card-reset-password">
                <Row className="mb-4">
                  <Col sm={12}>
                    <h2 className="sign-in-text">
                      {t("LOGIN.RESET_PASSWORD")}
                    </h2>
                    {handleResetDescription()}
                  </Col>
                </Row>

                <Formik
                  initialValues={initialValues}
                  onSubmit={onFormSubmit}
                  validate={(values) => {
                    const errors = {};
                    if (uniqueCode && values.confirmPassword === "") {
                      errors.confirmPassword = "VALIDATION.REQUIRED";
                    }
                    if (
                      values.confirmPassword &&
                      values.newPassword &&
                      values.confirmPassword !== values.newPassword
                    ) {
                      errors.confirmPassword = "VALIDATION.CONFIRM_PASSWORD";
                    }
                    return errors;
                  }}
                >
                  {(formick) => (
                    <Form>
                      {handleInputFields(formick)}
                      {handleSendResetButtons(formick)}
                    </Form>
                  )}
                </Formik>
              </div>
            ) : (
              <div className="sign-in-card reset-success-card">
                <Row className="mb-4">
                  <Col sm={12}>
                    <SuccessModal
                      modalHeaderMessage={t("LOGIN.SENT_VERIFICATION_LINK")}
                      okayButtonClickHandler={handleOkayButtonClick}
                      modalSuccessMessage={modalMessage}
                      isWeb={isWeb}
                    />
                  </Col>
                </Row>
              </div>
            )
          ) : (
            <div className="sign-in-card reset-success-card">
              <Row className="mb-4">
                <Col sm={12}>
                  <SuccessModal
                    modalHeaderMessage="Password Reset Successful"
                    okayButtonClickHandler={() => {}}
                    modalSuccessMessage="Please use your new credentials to log in to your mobile device."
                    isWeb={isWeb}
                  />
                </Col>
              </Row>
            </div>
          )}

          {/* {isWeb && mobileRest ? (
            <div className="sign-in-card reset-success-card">
              <Row className="mb-4">
                <Col sm={12}>
                  <SuccessModal
                    modalHeaderMessage="Password Reset Successful"
                    okayButtonClickHandler={handleOkayButtonClick}
                    modalSuccessMessage="Please use your new credentials to log in to your mobile device."
                    isWeb={isWeb}
                  />
                </Col>
              </Row>
            </div>
          ) : (
            ""
          )} */}
        </Container>
      </div>
      <div className="login-footer">
        <img
          src={LoginBackgroundImage}
          alt="pic"
          className="sign-in-bg-image"
        />
      </div>
      <ClimberFooter />
    </div>
  );
}

export default ResetPassword;
