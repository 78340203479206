import { useEffect } from "react";
import L from "leaflet";
import _ from "lodash";
import moment from "moment";
import { MAP_PREFERENCES_LABELS, DATA_SOURCES } from "../../constants";

function Legend({ map, add, layer, position }) {
  const categoryNames = [
    "Vegetation",
    "Basic",
    "Flood",
    "LULC (ESA)",
    "Water Resources",
    "Permanent Water",
    "Meteorological",
    "Agriculture",
  ];
  const id = layer?.id + position;
  useEffect(() => {
    if (map) {
      const legend = L?.control({ position });
      if (add) {
        legend.onAdd = () => {
          const legendDiv = L.DomUtil.create("div");
          legendDiv.id = id || "legend_div";
          // legend.key = id;
          if (position === "bottomright") {
            legendDiv.className = "right-discrete-legend-style";
          } else {
            // legendDiv.className = "left-discrete-legend-style";
            legendDiv.className = "left-discrete-legend-style";
          }
          if (layer?.slug !== "cwp") {
            const datesContainer = L.DomUtil.create("div");
            const spanContainer = L.DomUtil.create("div");
            const monthContainer = L.DomUtil.create("div");
            const monthSpanContainer = L.DomUtil.create("div");
            datesContainer.className =
              position === "bottomleft"
                ? "datesContainLeftDiv"
                : layer?.dataSource === DATA_SOURCES?.IRI?.name
                ? "datesContainRightDiv datesContainRightIRIDiv"
                : "datesContainRightDiv";
            spanContainer.className = "dates-span-container";
            monthContainer.className =
              position === "bottomleft"
                ? "datesContainLeftDiv"
                : layer?.dataSource === DATA_SOURCES?.IRI?.name
                ? "datesContainRightDiv datesContainRightIRIDiv"
                : "datesContainRightDiv";
            monthSpanContainer.className = "dates-span-container";
            if (layer?.startDate && layer?.endDate) {
              if (layer?.dataSource === DATA_SOURCES?.IRI?.name) {
                spanContainer.innerHTML = `${layer?.startDate} to ${layer?.endDate} `;
                datesContainer.appendChild(spanContainer);
                legendDiv.appendChild(datesContainer);
                if (layer?.forecastMonth) {
                  monthSpanContainer.innerHTML = `${layer?.forecastMonth} `;
                  monthContainer.appendChild(monthSpanContainer);
                  legendDiv.appendChild(monthContainer);
                }
              } else {
                spanContainer.innerHTML = `${moment(layer?.startDate).format(
                  "DD-MM-YYYY",
                )} to ${moment(layer?.endDate).format("DD-MM-YYYY")} `;
                datesContainer.appendChild(spanContainer);
                legendDiv.appendChild(datesContainer);
              }
            }
          }
          const containerDiv = L.DomUtil.create("div");
          if (layer?.dataSource === "SMCI") {
            if (layer.fromMapPreferences) {
              containerDiv.className =
                "legend-container-div discrete-legend-for-indices info-discrete smci-legend";
            } else {
              containerDiv.className =
                "legend-container-div discrete-legend info-discrete smci-legend";
            }
          } else if (layer.fromMapPreferences) {
            containerDiv.className =
              "legend-container-div discrete-legend-for-indices info-discrete";
          } else {
            containerDiv.className =
              "legend-container-div discrete-legend info-discrete";
          }
          const labels = [];
          const grades = layer?.palette;

          let names = "";
          for (let i = 0; i < grades?.length; i += 1) {
            if (!Array.isArray(grades[i])) {
              names = grades[i].name;
              labels.push(
                `<i style="background:${grades[i].color}"></i> ${names}`,
              );
            }
          }
          if (layer.dataSource === "Dry Spell") {
            containerDiv.innerHTML =
              `<b style="font-size:16px;color:#000"">${layer.dataSource}("${layer.units}")</b>` +
              `<div style="padding-top:5px"></div>${labels.join("<br>")}`;
          } else if (layer.units === undefined) {
            containerDiv.innerHTML = `
                                    <b style="font-size:16px;color:#000">${
                                      layer.dataSource
                                    }</b>
                                    <div style="padding-top:10px"></div>
                                    ${labels.join("<br>")}
                                    <div style="padding-top:10px"></div>
                                    <b style="font-size:16px;color:#000">${
                                      layer.name
                                    }</b>
`;

            // containerDiv.innerHTML =
            //   `<b style="font-size:16px;color:#000"">${layer.dataSource} </b>` +
            //   `<div style="padding-top:5px"></div>${labels.join("<br>")}` +

            //   `<div style="padding-top:10px"></div><b style="font-size:16px;color:#000"">${layer.name} 999</b>`;
          } else {
            if (layer.units) {
              containerDiv.innerHTML =
                `<b style="font-size:14px;color:#000">${
                  layer.fromMapPreferences
                    ? layer?.slug === MAP_PREFERENCES_LABELS.PROBA_LULC ||
                      layer?.slug === MAP_PREFERENCES_LABELS.SRILANKA_LULC
                      ? layer?.id
                      : layer?.dataSource
                    : layer.dataSource
                } </b>${
                  layer.dataSource === "IRI"
                    ? `<br><br><b style="font-size:14px;color:#000;white-space: pre-wrap;max-width: 100%">White indicates Climatological odds</b>`
                    : ""
                }<br><br>${labels.join("<br>")} ` +
                `<br><br><b style="font-size:14px;color:#000;white-space: pre-wrap;max-width: 100%">${
                  layer.fromMapPreferences
                    ? layer?.slug === MAP_PREFERENCES_LABELS.PROBA_LULC ||
                      layer?.slug === MAP_PREFERENCES_LABELS.SRILANKA_LULC
                      ? layer?.id
                      : `${layer?.dataSource} `
                    : `${layer.parameter} `
                } ` +
                `(${layer.units})`;
            } else if (categoryNames.includes(layer?.parameter)) {
              containerDiv.innerHTML = `<b style="font-size:14px;color:#000">${
                layer.dataSource
              }</b><br><br>${labels.join("<br>")}`;
            } else {
              containerDiv.innerHTML = `<b style="font-size:14px;color:#000">${
                layer?.fromMapPreferences
                  ? layer?.slug === MAP_PREFERENCES_LABELS.PROBA_LULC ||
                    layer?.slug === MAP_PREFERENCES_LABELS.SRILANKA_LULC
                    ? layer?.id
                    : `${layer?.dataSource} `
                  : `${layer.parameter}  `
              }</b><br><br>${labels.join("<br>")}`;
            }
          }
          legendDiv.append(containerDiv);
          return legendDiv;
        };
        if (layer && !_.isEmpty(legend) && !_.isEmpty(map)) {
          /* eslint no-underscore-dangle: 0 */
          if (!_.isEmpty(map?._layers)) {
            legend?.addTo(map);
          }
        }
      } else if (layer) {
        const image = document.getElementById(id || "legend_div");
        legend.onRemove = () => {
          if (image) {
            L.DomUtil.remove(image);
          }
        };
        legend.onRemove();
      }
    }
  }, [add, map, layer]);
  return null;
}

export default Legend;
