import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import REGISTER_CONST from "./actionTypes";
import {
  organizationSuccess,
  organizationError,
  registerationSuccess,
  registerationError,
  userVerificationSuccess,
  userVerificationError,
  userDetailsSuccess,
  userDetailsError,
  userOdkInfoUpdateSuccess,
  userOdkInfoUpdateFailed,
  getLoginUserDetailsSuccess,
  getLoginUserDetailsFailed,
} from "./action";
import checkHttpStatus from "../apiUtils";
import { USER_API, AXIOS_INSTANCE } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* organizationlist() {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${USER_API}/organization_type`),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(organizationSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(organizationError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(organizationError(errorData));
  }
}

function* userRegisteration(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${USER_API}/register`, action.payload),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(registerationSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(registerationError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(registerationError(errorData));
  }
}

function* userVerification(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${USER_API}/verification`, action.payload),
      "",
      "",
      true,
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(userVerificationSuccess(responseData));
    } else {
      const responseData = {
        data: response.data,
      };
      yield put(userVerificationError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(userVerificationError(errorData));
  }
}

// user details
function* userDetails(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${USER_API}/getById?id=${action.payload.id}`,
        action.payload,
      ),
      "",
      "",
      true,
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
        initial: !!action.payload.initial,
      };
      yield put(userDetailsSuccess(responseData));
    } else {
      const responseData = {
        data: response.data,
      };
      yield put(userDetailsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(userDetailsError(errorData));
  }
}

function* updateUserOdkInfo(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.put(`${USER_API}/update_odk`, action.payload),
      "",
      false,
      false,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(userOdkInfoUpdateSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(userOdkInfoUpdateFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(userOdkInfoUpdateFailed(errorData));
  }
}

function* getLoginUserDetails() {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${USER_API}/loginUserDetail`),
      "",
      false,
      false,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(getLoginUserDetailsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(getLoginUserDetailsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getLoginUserDetailsFailed(errorData));
  }
}

export function* watchOrganizationList() {
  yield takeEvery(REGISTER_CONST.ORGANIZATION_REQUEST, organizationlist);
}

export function* watchUserRegisteration() {
  yield takeEvery(REGISTER_CONST.REGISTERATION_REQUEST, userRegisteration);
}

export function* watchUserDetails() {
  yield takeEvery(REGISTER_CONST.USER_DETAILS_REQUEST, userDetails);
}
export function* watchUserVerification() {
  yield takeEvery(
    REGISTER_CONST.REGISTERATION_VERIFICATION_REQUEST,
    userVerification,
  );
}
export function* watchUpdateUserOdkInfo() {
  yield takeEvery(
    REGISTER_CONST.USER_ODK_INFO_UPDATE_REQUEST,
    updateUserOdkInfo,
  );
}
export function* watchGetLoginUser() {
  yield takeEvery(
    REGISTER_CONST.LOGIN_USER_DETAILS_REQUEST,
    getLoginUserDetails,
  );
}

function* UserSaga() {
  yield all([
    fork(watchOrganizationList),
    fork(watchUserRegisteration),
    fork(watchUserVerification),
    fork(watchUserDetails),
    fork(watchUpdateUserOdkInfo),
    fork(watchGetLoginUser),
  ]);
}

export default UserSaga;
