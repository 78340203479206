import React, { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "reactstrap-button-loader";
import domtoimage from "dom-to-image-more";
import jsPDF from "jspdf";
import _ from "lodash";
import {
  showError,
  showSuccess,
} from "../../../../components/Common/Notification";
import {
  isPermissionsMatch,
  isSuperUser,
} from "../../../../components/Common/Utils";

import {
  addBulletinRequest,
  addBulletinSuccess,
  addBulletinFailed,
} from "../../../../store/bulletin/bulletinActions";

import PublishModel from "./PublishModel";

function DownloadWrapper({
  elementToPrint,
  downloadFileName,
  queryPrams,
  title,
  indicatorRows,
  setIsLoadingPDF,
  isLoadingPdf,
  permissionsList,
  userAdminDetails,
}) {
  const { t } = useTranslation();
  const [isLoadingPublish, setIsLoadingPublish] = useState(false);
  // const [isLoadingPdf, setIsLoadingPDF] = useState(false);
  const [isLoadingPng, setIsLoadingPng] = useState(false);
  const [isPublishModal, setIsPublishModal] = useState(false);
  const [imageSaveForPdf, setImageSaveForPdf] = useState([]);
  const [imageSaveForPublishPdf, setImageSaveForPublishPdf] = useState([]);

  const dispatch = useDispatch();

  const category = `${queryPrams.bulletinId}`;

  const [formData, setFormData] = useState({
    title,
    json: "",
    html: "",
    preview_image: "",
    category_id: category,
    id: "",
  });

  const form = new FormData();
  form.append("category_id", category);
  form.append("html_content", ``);
  form.append("json_content", ``);
  form.append("templete_id", ``);

  const { Bulletin } = useSelector((state) => ({
    Bulletin: state.Bulletin,
  }));

  useEffect(() => {
    const message =
      Bulletin?.addBulletinDataError?.data?.response?.data?.message ||
      Bulletin?.updateBulletinDataError?.data?.response?.data?.message;
    if (message) {
      showError(message);
    }
  }, [Bulletin]);

  useEffect(() => {
    if (Bulletin?.addBulletinData?.data) {
      showSuccess(Bulletin?.addBulletinData?.data?.data?.result);
      setIsLoadingPublish(false);
    }
  }, [Bulletin.addBulletinData]);

  useEffect(() => {
    return () => {
      dispatch(addBulletinSuccess({}));
      dispatch(addBulletinFailed({}));
    };
  }, []);

  const handlePreviewImage = (pic) => {
    if (pic) {
      setFormData({ ...formData, preview_image: pic });
    }
  };

  const handleHideAndShowIcons = (value) => {
    const icons = document.getElementsByClassName("indicator-icon");

    if (value) {
      for (let i = 0; i < icons?.length; i += 1) {
        icons[i].style.display = value;
      }
    }
  };

  /**
   * to download as image based on selected element
   */
  const downloadAsImage = () => {
    setIsLoadingPng(true);
    const options = { backgroundColor: "#fcfcfc" };
    const element = document.getElementById(elementToPrint);
    element.style = "background-color:#fcfcfc";
    handleHideAndShowIcons("none");
    domtoimage
      .toBlob(element, options)
      .then((blob) => {
        setIsLoadingPng(false);
        window.saveAs(blob, `${downloadFileName}.png`);
        element.style = "background-color:unset";
        handleHideAndShowIcons("initial");
      })
      .catch(() => {
        setIsLoadingPng(false);
        handleHideAndShowIcons("initial");
        showError(t("CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.PNG_ERROR"));
      });
  };

  // <--------- saving dynamic component images for pdf---------->

  const saveAsImage = () => {
    setIsLoadingPDF(true);
    const options = { backgroundColor: "#fcfcfc", border: "none" };
    // <-------------- storing promises created from htlm elements in an array through looping ------------>
    const promiseArrayOfElements = [];

    const brandingElement = document.getElementById("branding");
    // background-color:#fcfcfc; margin: 0; border: none; padding: 0;"
    brandingElement.style =
      "background-color:#fcfcfc; margin:  0px 10px 0px 10px ; border: none";
    promiseArrayOfElements.push(domtoimage.toPng(brandingElement, options));

    Object.keys(indicatorRows).map((eachRow, key) => {
      const element = document.getElementById(
        `${eachRow?.indicatorsList?.[key]?.indices_slug}${key}`,
      );
      element.style =
        "background-color:#fcfcfc; margin:  0px 0px 0px 10px ; border: none";
      promiseArrayOfElements.push(domtoimage.toPng(element, options));
      return null;
    });

    const footerElementDisclamer = document.getElementById("footer-disclamer");
    footerElementDisclamer.style =
      "background-color:#fcfcfc; margin:  0px 0px 0px 10px ; border: none";
    promiseArrayOfElements.push(
      domtoimage.toPng(footerElementDisclamer, options),
    );

    const footerElement = document.getElementById("footer-logo");
    footerElement.style =
      "background-color:#fcfcfc; margin:  0px 0px 0px 10px ; border: none";
    promiseArrayOfElements.push(domtoimage.toPng(footerElement, options));

    handleHideAndShowIcons("none");

    // <----------handling images after resolved or rejected--------------->

    Promise.all(promiseArrayOfElements)
      .then((res) => {
        setImageSaveForPdf(res);
        setIsLoadingPDF(false);
        handleHideAndShowIcons("initial");
      })
      .catch(() => {
        setIsLoadingPDF(false);
        handleHideAndShowIcons("initial");
        showError(t("CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.PDF_ERROR"));
      });
  };
  const addImageProcess = async (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  };

  const addImageToPdf = async (doc, imageUrls) => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const imageurlsdata1 = imageUrls.slice(0, imageUrls?.length - 1);
    const footerSection = imageUrls[imageUrls?.length - 1];

    const componentHeight =
      imageurlsdata1?.length === 3 ? pageHeight / 2 - 28 : pageHeight / 2; // Adjusted for a 5px gap between components
    let y = 5; // Starting y-coordinate with a 5px top margin
    let count = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const imageUrl of imageurlsdata1) {
      count += 1;
      // eslint-disable-next-line no-await-in-loop
      const imgData = await addImageProcess(imageUrl);

      if (imageUrls?.length - 1 === 3 ? count % 2 === 3 : count % 2 === 1) {
        if (count !== 1) {
          doc.addPage();
        }
        y = 5;
      }

      if (imageUrls?.length === count || imageUrls?.length - 1 === count) {
        doc.addImage(imgData, "PNG", 5, y, pageWidth - 10, 17);
        y += 17;
      } else {
        doc.addImage(imgData, "PNG", 5, y, pageWidth - 10, componentHeight);
        y += componentHeight + 0;
      }
    }

    doc.addImage(footerSection, "PNG", 5, y, pageWidth - 10, 28);
  };

  const generatePdf = async (imageUrls) => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF("p", "mm", "a4"); // Initialize PDF document
    await addImageToPdf(doc, imageUrls); // Add images to the PDF
    return doc; // Return the document
  };

  const savePdf = async (imageUrls) => {
    const pdfDoc = await generatePdf(imageUrls);

    pdfDoc.save(`${downloadFileName}.pdf`);
  };

  // <------------------- creating pdf based on dynamic component imaged--------->

  useEffect(() => {
    if (imageSaveForPdf.length && imageSaveForPdf) {
      savePdf(imageSaveForPdf);
    }
  }, [imageSaveForPdf]);

  const saveImageForPublishPdf = () => {
    setIsLoadingPublish(true);
    const options = { backgroundColor: "#fcfcfc" };
    // <-------------- storing promises created from htlm elements in an array through looping ------------>
    const promiseArrayOfElements = [];

    const brandingElement = document.getElementById("branding");
    brandingElement.style = "background-color:#fcfcfc";

    promiseArrayOfElements.push(
      domtoimage.toPng(brandingElement, { ...options }),
    );

    Object.keys(indicatorRows).map((eachRow, key) => {
      const element = document.getElementById(
        `${eachRow?.indicatorsList?.[key]?.indices_slug}${key}`,
      );
      element.style = "background-color:#fcfcfc";

      promiseArrayOfElements.push(domtoimage.toPng(element, { options }));
      return null;
    });
    const footerElement = document.getElementById("footer");
    footerElement.style = "background-color:#fcfcfc ";
    promiseArrayOfElements.push(domtoimage.toPng(footerElement, options));

    handleHideAndShowIcons("none");

    // <----------handling images after resolved or rejected--------------->

    Promise.all(promiseArrayOfElements)
      .then((res) => {
        setImageSaveForPublishPdf(res);
        handleHideAndShowIcons("initial");
        setIsLoadingPublish(false);
      })
      .catch(() => {
        setIsLoadingPDF(false);
        handleHideAndShowIcons("initial");
        showError(t("CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.PUBLISH_ERROR"));
      });
  };

  const publishPdf = async (imageUrls) => {
    const multiPng = await generatePdf(imageUrls);
    const pdf = multiPng.output("blob");
    const reader = new window.FileReader();
    const geo_location = queryPrams.district
      ? queryPrams.district
      : queryPrams.state
      ? queryPrams.state
      : queryPrams.country;

    reader.readAsDataURL(pdf);
    reader.onloadend = () => {
      const pdfResult = reader.result;
      const slicedResult = pdfResult.slice(28);
      if (!_.isEmpty(pdfResult)) {
        form.append("topic", `${geo_location} ${title}`);
        form.append("content_file", formData?.preview_image);
        form.append("bulletin_content", slicedResult);
        dispatch(addBulletinRequest(form));
      }
    };
  };

  useEffect(() => {
    if (imageSaveForPublishPdf.length && imageSaveForPublishPdf) {
      publishPdf(imageSaveForPublishPdf);
    }
  }, [imageSaveForPublishPdf]);

  const handlePublishBulletinModal = (e) => {
    e?.stopPropagation();
    setIsPublishModal(!isPublishModal);
  };

  const publishBulletin = () => {
    saveImageForPublishPdf();

    handleHideAndShowIcons("none");
  };

  return (
    <>
      <PublishModel
        modal={isPublishModal}
        toggle={handlePublishBulletinModal}
        publishBulletin={publishBulletin}
        handlePreviewImage={handlePreviewImage}
      />
      {userAdminDetails?.is_superuser ||
      isPermissionsMatch(permissionsList, "publist_bulletin") ||
      isSuperUser() ? (
        <div className="d-inline" data-tip data-for="publish-button-tooltip">
          <Button
            className="bulletin-cb"
            spinColor="#22ad7a"
            loading={isLoadingPublish}
            style={{ width: "initial", height: "auto" }}
            onClick={(e) => {
              handlePublishBulletinModal(e);
            }}
          >
            {t("CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.PUBLISH")}
          </Button>
        </div>
      ) : (
        ""
      )}

      <Button
        className="bulletin-cb"
        onClick={downloadAsImage}
        loading={isLoadingPng}
        spinColor="#22ad7a"
        style={{ width: "initial", height: "auto" }}
      >
        {t("CREATE_ONLINE_BULLETIN.SAVE_PNG")}
      </Button>
      <Button
        style={{ width: "initial", height: "auto" }}
        className="bulletin-cb"
        loading={isLoadingPdf}
        onClick={saveAsImage}
        spinColor="#22ad7a"
      >
        {t("CREATE_ONLINE_BULLETIN.EXPORT_PDF")}
      </Button>
    </>
  );
}

export default memo(DownloadWrapper);
