import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackButton from "../../assest/img/back-button.svg";
import filterIcon from "../../assest/img/mi_filter.svg";

function ManageUserHeader({ setFilterModal, filterModal }) {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  return (
    <Row>
      <Col sm={6}>
        <img
          src={BackButton}
          alt="back-button"
          onKeyUp={() => {}}
          onClick={() => Navigate("/settings")}
          className="pointer"
        />
        <h2 className="approve-heading"> {t("MANAGE_USERS.MANAGE_USERS")} </h2>
      </Col>
      <Col
        sm={6}
        className="filter-alignment pagination-padding nav-link"
        onClick={() => setFilterModal(!filterModal)}
        onKeyUp={() => {}}
      >
        <img
          src={filterIcon}
          alt="back-button"
          className="pointer filter-icon"
        />
        <h2 className="approve-heading"> {t("MANAGE_USERS.FILTER")} </h2>
      </Col>
    </Row>
  );
}

export default ManageUserHeader;
