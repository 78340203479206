import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader, Input } from "reactstrap";

import { GrFormClose } from "react-icons/gr";
import SendBtn from "../../assest/img/commentSend.svg";
import { isValidEmail } from "../../helpers/validate";

function ShareModal({ toggle, modal, handleSendEmail }) {
  const { t } = useTranslation();
  const [emailInput, setEmailInput] = useState("");
  const [emails, setEmails] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    // Add a CSS class to the body element when the sideEditor is open
    if (modal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [modal]);

  const closeBtn = (
    <GrFormClose
      size={24}
      onClick={() => toggle()}
      className="pointer media-modal-header-close-icon"
    />
  );

  const handleInputChange = (e) => {
    setEmailInput(e.target.value);
    setEmailError(false);
  };

  const handleAddEmail = () => {
    const isValid = isValidEmail(emailInput);

    if (isValid) {
      setEmails([...emails, emailInput]);
      setEmailInput("");
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handleRemoveEmail = (index) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddEmail();
    }
  };
  const handleClickIcon = () => {
    if (emailInput?.length) {
      handleAddEmail();
    }
  };

  return (
    <Modal isOpen={modal} size="md">
      <ModalHeader
        toggle={toggle}
        className="align-items-center media-modal-header-background"
        close={closeBtn}
      >
        <h2 className="intervention-h2 m-0">
          {t("EARLY_FINANCE_REPORT.SEND_TO")}
        </h2>
      </ModalHeader>
      <ModalBody className="p-3">
        <div className="send-container mb-3">
          <Input
            type="text"
            className="ef-email-input mb-2"
            placeholder="Enter email address"
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            value={emailInput}
          />
          <img
            src={SendBtn}
            alt="send"
            className="send-btn cursor-pointer"
            onClick={handleClickIcon}
            onKeyDown={() => {}}
            disabled={!emailInput?.length}
          />
          {emailError ? (
            <p className="error">{t("VALIDATION.EMAIL_INVALID")}</p>
          ) : (
            ""
          )}
          {emails?.length ? (
            <div className="ef-email-container">
              {emails.map((email, index) => (
                <div
                  key={email}
                  className="img-upload-container d-flex justify-content-between mb-1"
                >
                  {email}{" "}
                  <span
                    role="button"
                    onClick={() => handleRemoveEmail(index)}
                    onKeyUp={() => {}}
                    tabIndex="0"
                    aria-label="reportMediaREShareMail"
                  >
                    <GrFormClose
                      size={16}
                      className="pointer media-modal-header-close-icon"
                    />
                  </span>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>

        <Input
          className="ef-email-input mb-3"
          placeholder="Description"
          type="textarea"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          rows="4"
          cols="50"
        />
        <Button
          type="button"
          className="media-add-btn mb-3 w-100"
          onClick={() => {
            toggle();
            handleSendEmail(emails, description);
          }}
          disabled={!emails?.length}
        >
          {t("LOGIN.SEND")}
        </Button>
      </ModalBody>
    </Modal>
  );
}

export default ShareModal;
