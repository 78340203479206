import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { isLogedIn } from "../../components/Common/Utils";

function CommonLayout() {
  const navigate = useNavigate();

  useEffect(() => {
    const value = isLogedIn();
    if (value !== undefined) {
      navigate("/home");
    }
  }, []);

  return (
    <div>
      <Outlet />
    </div>
  );
}

export default CommonLayout;
