import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Filter from "./Filter";
import ManageContent from "./ManageDataContent";
import {
  getWeatherForecastSources,
  staticResourceRequest,
  deleteStaticResourceRequest,
  getIRIAvailableDatesDataRequest,
  userDetailsRequest,
  staticResourceAddSuccess,
  updateStaticResourceSuccess,
} from "../../store/actions";
import { showSuccess, showError } from "../../components/Common/Notification";
import DeleteModal from "../../components/Common/DeleteModal";
import RightModal from "./RightSidebar";
import { IRI_DATA } from "../../constants";
import { userDetails } from "../../components/Common/Utils";

function ManageStaticData() {
  const dispatch = useDispatch();
  const [isHideBar, setHideBar] = useState(false);
  const [initialValues, setInitialValues] = useState({
    source: "",
    parameter: "",
    description: "",
    addLink: "",
    strtdate: "",
    months: "",
    id: "",
  });
  const filter_req_data = {
    weather_forecast_source_slug: [],
    weather_forecast_parameter_slug: [],
    start_date: null,
    end_date: null,
    limit: 0,
    iri_start_date: "",
    iri_end_date: "",
  };
  const [activeTab, setActiveTab] = useState("2");
  const [foreCastMonth, setForecastMonth] = useState();
  const [formFilterData, setFormFilterData] = useState(filter_req_data);

  const { t } = useTranslation();

  const [dataSourceList, setDataSourceList] = useState();
  const [selectedParameter, setSelectedParameter] = useState();
  const [file, setFile] = useState();
  const [file2, setFile2] = useState();
  const [imageExtension, setImageExtension] = useState();
  const [haltInfiniteScroll, setHaltInfiniteScroll] = useState(true);
  const [staticResourceList, setStaticResourcesList] = useState([]);
  const [pageCount, setCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedStaticResourceId, setSelectedStaticResourceId] = useState();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [forecastAvailableDates, setForecastAvailableDates] = useState([]);
  const [IRIAvailableYearList, setIRIAvailableYearList] = useState([]);
  const [IRIForecastTime, setIRIForecastTime] = useState({
    year: null,
    month: null,
    lead_time: null,
  });

  const [endIRIForecastTime, setEndIRIForecastTime] = useState({
    year: null,
    month: null,
    lead_time: null,
  });

  const [endIRIAvailableYearList, setEndIRIAvailableYearList] = useState([]);
  const [IRIAvailableMonthList, setIRIAvailableMonthList] = useState([]);
  const [endIRIAvailableMonthList, setEndIRIAvailableMonthList] = useState([]);
  const [toggleRightBar, setToggleRightBar] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [isImgaeUploaded, setIsImgaeUploaded] = useState(false);

  useEffect(() => {
    const id = JSON.parse(localStorage.getItem("selectedCountry"))?.value;
    dispatch(getWeatherForecastSources({ country_id: id }));
    dispatch(
      staticResourceRequest({
        order_by: t("MANAGE_IRI.CREATED_DATE"),
        page: 1,
        weather_forecast_source_slug: [t("MANAGE_IRI.IRI_SLUG")],
        weather_forecast_parameter_slug: [
          t("MANAGE_IRI.STATIC_IRI_PARAMETER_SLUG"),
        ],
      }),
    );
    dispatch(getIRIAvailableDatesDataRequest());
    setDataSourceList(IRI_DATA()[0]);
    setSelectedParameter(IRI_DATA()[1]);
    setInitialValues((prev) => {
      return {
        ...prev,
        Source: IRI_DATA()[0],
        parameter: IRI_DATA()[1],
      };
    });
    const userData = userDetails();
    dispatch(userDetailsRequest({ id: userData?.user_details?.[0]?.id }));
    return () => {
      dispatch(staticResourceAddSuccess({}));
      dispatch(updateStaticResourceSuccess({}));
    };
  }, []);

  const nextProps = useSelector((state) => ({
    staticResourceData: state.staticResource?.staticResourceData,
    Weather: state.Weather,
    staticResource: state.staticResource,
    userDetails: state.User?.userDetails,
    updateStaticResourceData: state.staticResource?.updateStaticResourceData,
  }));
  useEffect(() => {
    if (nextProps?.Weather?.iriAvailableDatesData) {
      setForecastAvailableDates(nextProps?.Weather?.iriAvailableDatesData);
    }
  }, [nextProps?.Weather?.iriAvailableDatesData]);

  const {
    staticResourceAddData,
    staticResourceAddDataError,
    deleteStaticResourceData,
  } = nextProps.staticResource;

  const { staticResourceData } = nextProps;
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    if (tab === "2") {
      setIRIForecastTime({
        year: null,
        month: null,
        lead_time: null,
      });
      setEndIRIForecastTime({
        year: null,
        month: null,
        lead_time: null,
      });
      setHaltInfiniteScroll(true);
      const reqData = {
        order_by: t("MANAGE_IRI.CREATED_DATE"),
        page: pageNumber,
        weather_forecast_source_slug: [t("MANAGE_IRI.IRI_SLUG")],
        weather_forecast_parameter_slug: [
          t("MANAGE_IRI.STATIC_IRI_PARAMETER_SLUG"),
        ],
      };
      // setNoRecordsFound(false);
      dispatch(staticResourceRequest(reqData));
    } else {
      setHaltInfiniteScroll(false);
    }
  };

  const handleUpdatedListData = () => {
    setPageNumber(1);
    const reqData = {
      ...formFilterData,
      order_by: t("MANAGE_IRI.CREATED_DATE"),
      page: 1,
      weather_forecast_source_slug: [t("MANAGE_IRI.IRI_SLUG")],
      weather_forecast_parameter_slug: [
        t("MANAGE_IRI.STATIC_IRI_PARAMETER_SLUG"),
      ],
    };
    dispatch(staticResourceRequest(reqData));
  };

  useEffect(() => {
    if (deleteStaticResourceData) {
      const {
        data: { result },
      } = deleteStaticResourceData;
      if (result) {
        showSuccess(result);
        handleUpdatedListData();
      }
    }
  }, [deleteStaticResourceData]);

  const handleSelectedTab = () => {
    setActiveTab("2");
    setHaltInfiniteScroll(true);
    const reqData = {
      order_by: t("MANAGE_IRI.CREATED_DATE"),
      page: pageNumber,
      weather_forecast_source_slug: [t("MANAGE_IRI.IRI_SLUG")],
      weather_forecast_parameter_slug: [
        t("MANAGE_IRI.STATIC_IRI_PARAMETER_SLUG"),
      ],
    };

    dispatch(staticResourceRequest(reqData));
  };

  const handleFilterFormData = (data) => {
    setPageNumber(1);
    setFormFilterData({ ...data });
  };

  // after sucess
  useEffect(() => {
    if (staticResourceAddData) {
      const {
        data: { result },
      } = staticResourceAddData;
      if (result) {
        showSuccess(result);
        handleSelectedTab();
        setFile2("");
        const ele = document.getElementsByClassName("cancel-none-button");
        if (ele.length) {
          ele?.[0].click();
        }
      }
    } else {
      if (staticResourceAddDataError) {
        showError(staticResourceAddDataError?.data?.response?.data?.message);
      }
    }
  }, [staticResourceAddData, staticResourceAddDataError]);

  useEffect(() => {
    if (nextProps?.updateStaticResourceData) {
      const {
        data: { result },
      } = nextProps?.updateStaticResourceData;
      if (result) {
        setFile2("");
        showSuccess(result);
        handleSelectedTab();
        setIsImgaeUploaded(false);
        setEditClicked(false);
        setInitialValues((prev) => {
          return {
            ...prev,
            description: "",
            addLink: "",
            strtdate: "",
            months: "",
            id: "",
          };
        });
      }
    }
  }, [nextProps?.updateStaticResourceData]);

  useEffect(() => {
    if (staticResourceData && haltInfiniteScroll) {
      const {
        data: { result },
      } = staticResourceData;
      let new_list = [];
      if (staticResourceList) {
        if (pageNumber > 1) {
          new_list = staticResourceList.concat(result.results);
        } else {
          new_list = result.results;
        }
      } else {
        new_list = result.results;
      }
      setStaticResourcesList(new_list);
      setCount(result.count);
    }
  }, [staticResourceData]);

  const fileHandler = (value, e) => {
    if (e?.target?.value) {
      setImageExtension(
        e?.target?.value?.substr(e?.target?.value?.lastIndexOf(".") + 1),
      );
    }
    setHaltInfiniteScroll(false);
    setActiveTab("1");
    setFile2(value);
  };

  const handleDeleteDataSource = (id) => {
    setSelectedStaticResourceId(id);
  };

  const onConfirmDelete = () => {
    setConfirmationModalOpen(false);
    dispatch(deleteStaticResourceRequest(selectedStaticResourceId));
  };

  const handleFilter = (data) => {
    setActiveTab("2");
    setHaltInfiniteScroll(true);
    setFormFilterData(data);
    const reqData = {
      ...data,
      order_by: t("MANAGE_IRI.CREATED_DATE"),
      page: 1,
      weather_forecast_source_slug: [t("MANAGE_IRI.IRI_SLUG")],
      weather_forecast_parameter_slug: [
        t("MANAGE_IRI.STATIC_IRI_PARAMETER_SLUG"),
      ],
    };
    setPageNumber(1);
    dispatch(staticResourceRequest(reqData));
  };

  return (
    <>
      <Row>
        {!isHideBar && (
          <Col sm={2} className="filter-col">
            <div className="hide-bar-1 static-hidebar">
              <span className="cursor-ponter navyBlueColor ">
                {t("MANAGE_USER.HIDE_BAR")}
                <span>
                  <MdKeyboardArrowRight
                    className="hide-icon expand-margin"
                    onClick={() => setHideBar(!isHideBar)}
                    size={25}
                  />
                </span>
              </span>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        {!isHideBar ? (
          <Col lg={2} md={2} className="filter-col">
            <div className="filter-section-scroll">
              <Filter
                // dataSource={dataSource}
                parameter={selectedParameter}
                dataSourceList={dataSourceList}
                initialValues={initialValues}
                setForecastMonth={setForecastMonth}
                foreCastMonth={foreCastMonth}
                fileHandler={fileHandler}
                setFile={setFile}
                file={file}
                editClicked={editClicked}
                setEditClicked={setEditClicked}
                isImgaeUploaded={isImgaeUploaded}
                setIsImgaeUploaded={setIsImgaeUploaded}
              />
            </div>
          </Col>
        ) : (
          ""
        )}
        <Col lg={isHideBar ? 12 : 10} md={10} className="p-0">
          <ManageContent
            isHideBar={isHideBar}
            setHideBar={setHideBar}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            setFile={setFile}
            file={file}
            file2={file2}
            toggle={toggle}
            imageExtension={imageExtension}
            staticResourceList={staticResourceList}
            pageNumber={pageNumber}
            pageCount={pageCount}
            haltInfiniteScroll={haltInfiniteScroll}
            setPageNumber={setPageNumber}
            setInitialValues={setInitialValues}
            selectedParameter={selectedParameter}
            // dataSource={dataSource}
            fileHandler={fileHandler}
            handleDeleteDataSource={handleDeleteDataSource}
            setConfirmationModalOpen={setConfirmationModalOpen}
            toggleRightBar={toggleRightBar}
            setToggleRightBar={setToggleRightBar}
            formFilterData={formFilterData}
            setEditClicked={setEditClicked}
            setForecastMonth={setForecastMonth}
          />
        </Col>
      </Row>
      {confirmationModalOpen && (
        <DeleteModal
          modal={confirmationModalOpen}
          toggle={setConfirmationModalOpen}
          message={t("DELETE_MODAL.DELETE_IRI_MESSAGE")}
          onConfirmDelete={onConfirmDelete}
        />
      )}

      {toggleRightBar && (
        <RightModal
          className="modal-dialog-centered modal-dialog-scrollable"
          setIRIAvailableYearList={setIRIAvailableYearList}
          IRIAvailableYearList={IRIAvailableYearList}
          setIRIForecastTime={setIRIForecastTime}
          IRIForecastTime={IRIForecastTime}
          setEndIRIForecastTime={setEndIRIForecastTime}
          endIRIForecastTime={endIRIForecastTime}
          endIRIAvailableYearList={endIRIAvailableYearList}
          setEndIRIAvailableYearList={setEndIRIAvailableYearList}
          IRIAvailableMonthList={IRIAvailableMonthList}
          setIRIAvailableMonthList={setIRIAvailableMonthList}
          setEndIRIAvailableMonthList={setEndIRIAvailableMonthList}
          endIRIAvailableMonthList={endIRIAvailableMonthList}
          setForecastAvailableDates={setForecastAvailableDates}
          forecastAvailableDates={forecastAvailableDates}
          setFormFilterData={setFormFilterData}
          formFilterData={formFilterData}
          handleFilterFormData={handleFilterFormData}
          initialData={initialValues}
          handleFilter={handleFilter}
          toggleRightBar={toggleRightBar}
          setToggleRightBar={setToggleRightBar}
        />
      )}
    </>
  );
}

export default ManageStaticData;
