import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { BsChevronRight, BsChevronDown } from "react-icons/bs";
import { useOutletContext } from "react-router-dom";
import EarlyActionHeader from "./EarlyActionHeader";
import Warning from "../../assest/img/Warning.png";
import Finance from "../../assest/img/Finance.png";
import EAImg from "../../assest/img/EA-Icon.png";
import Mechanisms from "../../assest/img/Mechanisms.png";
import Icon1 from "../../assest/img/early-action-icon1.png";
import Icon2 from "../../assest/img/early-action-icon2.png";
import Icon3 from "../../assest/img/EA-icon-3.svg";
import Icon4 from "../../assest/img/EA-icon-2.svg";
import Icon5 from "../../assest/img/EA-icon-1.svg";
import Icon6 from "../../assest/img/EA-icon-4.svg";
import Icon7 from "../../assest/img/EA-icon-5.png";
import EA_line from "../../assest/img/EA_line.png";
import Right_icon from "../../assest/img/EA-right.png";

function EarlyAction() {
  const { t } = useTranslation();
  const [, setOpen] = useOutletContext(); // for closing side bar when it is opening

  useEffect(() => {
    setOpen(true);
  }, []);
  return (
    <div className="dashboard-drought">
      <div className="ea-project-container">
        <EarlyActionHeader />
        <div className="awr-home pt-4">
          <Row className="mt-4 layout-spc">
            <Col lg={4} sm={4} md={4} className="home-col">
              <div className="home-col-section">
                <div>
                  <img
                    src={Warning}
                    alt="awareTools"
                    className="early-action-img"
                  />
                  <h3>{t("HOME.Early_Warning")}</h3>
                </div>
                <p>{t("EARLY_ACTIONS.EARLY_ACTION_CARD")}</p>
              </div>
            </Col>
            <Col lg={4} sm={4} md={4} className="home-col">
              <div className="home-col-section EA-card">
                <div>
                  <img
                    src={EAImg}
                    alt="awareTools"
                    className="early-action-img"
                  />
                  <img
                    src={Mechanisms}
                    alt="awareTools"
                    className="EA-card-img"
                  />
                  <h3>{t("HOME.EARLY_ACTION")}</h3>
                </div>
                <p>{t("EARLY_ACTIONS.EARLY_ACTION_PARA")}</p>
              </div>
            </Col>
            <Col lg={4} sm={4} md={4} className="home-col">
              <div className="home-col-section">
                <div>
                  <img
                    src={Finance}
                    alt="awareTools"
                    className="early-action-img"
                  />
                  <h3>{t("HOME.EARLY_FINANCE")}</h3>
                </div>
                <p>{t("EARLY_ACTIONS.EARLY_FINANCE_PARA")}</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="EA-line">
          <img src={EA_line} alt="" />
        </div>
        <div className="p-5">
          <h2 className="ea-heading">{t("HOME.EARLY_ACTION")}</h2>
          <div className="EA-steps">
            <p className="line-v" />

            <div className="EA-perp">
              <img src={Icon1} alt="icon" />
              <div className="EA-prep-section">
                <div className="prep-div">
                  <h2>{t("EARLY_ACTIONS.PREPAREDNESS")}</h2>
                  <p>{t("EARLY_ACTIONS.PREPARED_STATIC_CONTENT")}</p>
                </div>
              </div>
              <Row className="m-3 early-action-div ">
                <p className="line-h" />
                <Col lg={3} className="early-action-col">
                  <div className="early-action-label">
                    <img src={Icon6} alt="icon" />
                    <p>{t("EARLY_ACTIONS.CATEGORY")}</p>
                  </div>
                  <BsChevronRight className="ea-icon" />
                </Col>
                <Col lg={3} className="early-action-col">
                  <div className="early-action-label">
                    <img src={Icon3} alt="icon" />
                    <p>{t("EARLY_ACTIONS.IMPACT")}</p>
                  </div>
                  <BsChevronRight className="ea-icon" />
                </Col>
                <Col lg={3} className="early-action-col p-0">
                  <div className="early-action-label early-action-label-1">
                    <img src={Icon4} alt="icon" />
                    <p>{t("EARLY_ACTIONS.ACTIONS")}</p>
                  </div>
                  <BsChevronRight className="ea-icon right-ea-icon" />
                </Col>
                <Col lg={3} className="early-action-col p-0">
                  <div className="early-action-label float-right">
                    <img src={Icon5} alt="icon" />
                    <p>{t("EARLY_ACTIONS.ANTICIPATORY_ACTION_PLAN")}</p>
                  </div>
                </Col>
              </Row>
              <BsChevronDown className="ea-v-icon" />
            </div>
            <div className="EA-prep-section">
              <div className="EA-perp">
                <img src={Icon2} alt="icon" />
                <div className="prep-div">
                  <h2>{t("EARLY_ACTIONS.READINESS")}</h2>
                  <p>{t("EARLY_ACTIONS.READYNESS_STATIC_CONTENT")}</p>
                </div>
                <BsChevronDown className="ea-v-icon" />
              </div>
              <div className="EA-perp">
                <img src={Icon7} alt="icon" />
                <div className="prep-div">
                  <h2>{t("EARLY_ACTIONS.ACTIVE_PHASE")}</h2>
                  <br />
                  <p>{t("EARLY_ACTIONS.ACTIVE_STATIC_CONTENT")}</p>
                </div>
              </div>
            </div>
            <img src={Right_icon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default EarlyAction;
