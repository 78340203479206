import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BiCopyright } from "react-icons/bi";
import { Row, Col } from "reactstrap";
import Iwmi from "../../assest/img/Iwmi.svg";
// import CIGARFoodSystem from "../../assest/img/footerLogoFoodSupply.png";
import Climber from "../../assest/img/climber-footer-logo.png";
import { socialItems, FooterLogoHidenRoutes } from "../../constants";
import { climberFooterRequest } from "../../store/actions";

function ClimberFooter() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoged, setIsLoged] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const isWeb = urlParams.get("isWeb");

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("selectedCountry"))?.value !== undefined
    ) {
      dispatch(
        climberFooterRequest(
          JSON.parse(localStorage.getItem("selectedCountry"))?.value,
        ),
      );
    }
  }, [localStorage.getItem("selectedCountry")]);
  useEffect(() => {
    setIsLoged(JSON.parse(localStorage.getItem("isLoged")));
  }, [localStorage.getItem("isLoged")]);

  return (
    <div>
      {/* common footer please hide in climber */}
      {window.location.href.includes("home") && !isLoged ? (
        <div className="pre-footer">
          <div className="pre-footer-div">
            <p>{t("CLIMBER_HEADER.REGISTER")}</p>
            <div>
              <span>{t("CLIMBER_HEADER.KEEP_IN")}</span>
              <button
                type="button"
                className="btn"
                onClick={() => navigate("/registerPage")}
              >
                {t("CLIMBER_HEADER.REGISTER")}
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="footer-container">
        <div className="footer">
          <Row className="">
            <Col xs={6} md={4} lg={1} className="climber-footer-img">
              <img
                src={Climber}
                alt="Climber"
                className="cigar-logo-footer-1"
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={1}
              className="climber-footer-img climber-footer-img-margin mt-1"
            >
              <img src={Iwmi} alt="iwmi" className="cigar-logo-footer" />
            </Col>

            {/* {!FooterLogoHidenRoutes?.includes(window?.location?.pathname) ? (
              <Col xs={6} md={4} lg={2} className="climber-footer-img  mt-1">
                <img
                  src={CIGARFoodSystem}
                  alt="iwmi"
                  className="cigar-logo-footer-food"
                />
              </Col>
            ) : (
              ""
            )} */}

            <Col
              xs={12}
              sm={12}
              lg={
                FooterLogoHidenRoutes?.includes(window?.location?.pathname)
                  ? 10
                  : 10
              }
              className="social-media-icons "
            >
              <div>
                <div>
                  <div className="mb-2">
                    <p> {t("CLIMBER_FOOTER.FOLLOW_US")}</p>
                  </div>
                  <div>
                    {socialItems &&
                      socialItems.map((eachValue) => {
                        return (
                          <a
                            href={eachValue.link}
                            target="_blank"
                            rel="noreferrer"
                            className="social-links contach-icons"
                            key={eachValue.link}
                            tabIndex="-1"
                          >
                            {eachValue.image}
                          </a>
                        );
                      })}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="footer-copywrite-section footer-padding-terms ">
          <Col sm={6} className="copy-right">
            <BiCopyright />{" "}
            <span className="copyright-text climber-footer d-none">
              {" "}
              {new Date().getFullYear()} {t("CLIMBER_FOOTER.CLIMBER_G4_PORTAL")}{" "}
            </span>
            <span className="copyright-text aware-footer">
              {" "}
              {t("CLIMBER_FOOTER.COPYRIGHT_AWARE")}{" "}
            </span>
          </Col>
          <Col sm={6} className="text-end terms-conditions copy-right">
            <span
              onClick={
                isWeb
                  ? () => navigate("/terms?isWeb=true")
                  : () => navigate("/terms")
              }
              role="button"
              tabIndex="0"
              onKeyUp={() => {}}
              className="ml-4 "
            >
              {" "}
              {t("CLIMBER_FOOTER.TERMS_CONDITIONS")}{" "}
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ClimberFooter;
