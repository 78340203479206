import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";
import * as xlsx from "xlsx";
import * as FileSaver from "file-saver";

import { ReactComponent as DownloadIcon } from "../../assest/img/ea-download.svg";
import { formatNumber, thousandSepartor } from "../../helpers";
import { htmlToText } from "../../components/Common/Utils";
import MediaReferenceComponent from "./MediaReferenceComponent";
import PreviewTable from "../../components/Common/PreviewTable";
import AttachmentsComponent from "./Attachments";

function BudgetReportComponent({
  isGeneratedSelected,
  tableListingData,
  projectDetails,
  reportData,
  userDetails,
}) {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [totalBudget, setTotalbudget] = useState([]);

  const COLUMNS = [
    {
      Header: "Category",
      accessorKey: "category",
      size: 250,
    },
    {
      Header: "Indicator",
      accessorKey: "indicator",
      size: 350,
    },
    {
      Header: "Target",
      accessorKey: "target",
      size: 150,
    },
    {
      Header: "Budget",
      accessorKey: "budget",
      size: 150,
    },
  ];

  // SETTING BUDGET TABLE
  useEffect(() => {
    if (tableListingData?.length) {
      const modifiedData = [];
      let count = 0;
      tableListingData.forEach((element, index) => {
        const modifedColumn = {};
        modifedColumn.id = index;
        modifedColumn.category = element.name;
        modifedColumn.indicator = (
          <span className="budget-indicator-text">{element.indicator}</span>
        );
        modifedColumn.target =
          element.people_targeted && thousandSepartor(element.people_targeted);
        modifedColumn.budget =
          element.budget.total_budget &&
          formatNumber(element.budget.total_budget)
            ? `${projectDetails?.currency_symbol} ${formatNumber(
                element.budget.total_budget,
              )}`
            : 0;
        if (element.budget.total_budget) count += element.budget.total_budget;
        modifiedData.push(modifedColumn);
      });
      if (modifiedData?.length) {
        setTotalbudget(count);
        setTableData(modifiedData);
      } else {
        setTableData([]);
      }
    }
  }, [tableListingData]);

  const hanldeExportToCSV = () => {
    // Need to set the headers of xlsx
    const customData = tableListingData.map((item, index) => ({
      "No.": index + 1,
      Category: item.name,
      Indicator: htmlToText(item.indicator),
      Target: item.people_targeted,
      Budget: `${projectDetails?.currency_symbol} ${item.budget.total_budget}`,
    }));

    const ws = xlsx.utils.json_to_sheet(customData);

    // Setting the header row style to bold
    const headerStyle = {
      font: { bold: true },
    };
    const headerRange = xlsx.utils.decode_range(ws["!ref"]);
    // eslint-disable-next-line no-plusplus
    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
      const cellAddress = xlsx.utils.encode_cell({
        r: headerRange.s.r,
        c: col,
      });
      ws[cellAddress].s = headerStyle;
    }
    // Setting column widths
    const columnWidths = [
      { wpx: 80 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
    ];

    ws["!cols"] = columnWidths;

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: "array" });
    const sheetData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(sheetData, `BUDGET_REPORT.xlsx`);
  };

  return (
    <div className="ea-space-y mb-3">
      <Row className="summary-report-container">
        <div id="budget-container" className="m-0 p-0">
          <div className="ef-conatiner-div w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div className="w-100">
                <h2 className="intervention-h2 mb-3">
                  {t("EARLY_FINANCE_REPORT.BUDGET_REPORT")}
                </h2>
                <h3 className="intervention-h3">
                  {t("EARLY_FINANCE_REPORT.TOTAL_BUDGET")}:{" "}
                  {projectDetails?.currency_symbol}{" "}
                  {totalBudget && formatNumber(totalBudget)}{" "}
                </h3>
              </div>
              <div className="actions-container d-flex align-items-center justify-content-end">
                {!isGeneratedSelected ? (
                  <span
                    role="button"
                    className="ea-upload-button"
                    onClick={() => hanldeExportToCSV()}
                    onKeyUp={() => {}}
                    tabIndex="0"
                    aria-label="BudgetComponentcsv"
                  >
                    <DownloadIcon />
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            className="new-inv-table mb-4"
            style={
              isGeneratedSelected
                ? { maxHeight: "100vh", width: "100%", display: "inline-table" }
                : {}
            }
          >
            <PreviewTable
              tableData={tableData}
              COLUMNS={COLUMNS}
              isPreview={isGeneratedSelected}
              isBudget
            />
          </div>
        </div>
        {/* Hiding Media References and Attachments When generating PDF */}
        {!isGeneratedSelected ? (
          <>
            <MediaReferenceComponent
              isGeneratedSelected={isGeneratedSelected}
              projectDetails={projectDetails}
              reportData={reportData}
              userDetails={userDetails}
            />
            <hr />
            <AttachmentsComponent
              isGeneratedSelected={isGeneratedSelected}
              projectDetails={projectDetails}
              reportData={reportData}
              userDetails={userDetails}
            />
          </>
        ) : (
          ""
        )}
      </Row>
    </div>
  );
}

export default BudgetReportComponent;
