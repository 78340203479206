import React, { useEffect, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import _ from "lodash";
import moment from "moment";
import { GrFormClose } from "react-icons/gr";
import { ReactComponent as LocationIcon } from "../../assest/img/Location-pointer.svg";
import { WEATHER_STATUS } from "../../constants";
import { handleGenerateWeekDate } from "../../helpers";

const WeatherForecastModal = React.memo(
  ({ isOpen, forecastData, selectedLocation, toggle, adminRadio }) => {
    const { t } = useTranslation();
    const closeBtn = (
      <GrFormClose
        size={24}
        onClick={() => toggle()}
        className="pointer media-modal-header-close-icon"
      />
    );

    const [tableData, setTableData] = useState([]);
    useEffect(() => {
      if (forecastData?.length) {
        const { Open_Weather: openWeatherData } = forecastData[0];
        if (openWeatherData && !_.isEmpty(openWeatherData?.daily)) {
          setTableData(openWeatherData.daily);
        }
      }
    }, [forecastData]);

    useEffect(() => {
      // Add a CSS class to the body element when the sideEditor is open
      if (isOpen) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }

      // Clean up the effect
      return () => {
        document.body.classList.remove("no-scroll");
      };
    }, [isOpen]);

    const handleRenderHeaderText = () => {
      if (!_.isEmpty(selectedLocation?.country)) {
        return `${selectedLocation?.country?.label} - ${
          adminRadio === "admin"
            ? selectedLocation?.state === "" || selectedLocation?.state === null
              ? "All Regions"
              : selectedLocation?.state?.label
            : selectedLocation?.basin === "" || selectedLocation?.state === null
            ? "All Basins"
            : selectedLocation?.basin?.label
        }`;
      }
    };

    return (
      <div>
        <Modal isOpen={isOpen} className="rp-modal-content" size="lg">
          <ModalHeader
            toggle={toggle}
            close={closeBtn}
            className="align-items-center rp-modal-header px-0 pb-1"
          >
            <LocationIcon className="mr-1" /> {handleRenderHeaderText()}
          </ModalHeader>
          <ModalBody className="px-0">
            <p className="rp-forecast-date">
              {t("RAPID_RESPONSE.FORECAST")} (
              {handleGenerateWeekDate(new Date())})
            </p>
            {tableData?.length
              ? tableData?.map((item) => {
                  return (
                    <Row className="rp-forecase-card my-2" key={item.dt}>
                      <Col sm={2}>
                        <p className="rp-date-label">
                          {moment(new Date(item.dt_txt)).local().format("ddd")}
                        </p>
                        <p className="rp-date-label">
                          {moment(new Date(item.dt_txt))
                            .local()
                            .format("MMM D")}
                        </p>
                      </Col>
                      <Col sm={2} className="text-right">
                        {WEATHER_STATUS.map((x) => {
                          if (x?.label === item?.weather[0]?.main) {
                            return x.icon;
                          } else {
                            return null;
                          }
                        })}
                      </Col>
                      <Col sm={8}>
                        <Row>
                          <Col col={3} className="rp-vertical-line text-center">
                            <p className="rp-climate-label pb-1">
                              {t("RAPID_RESPONSE.TEMPERATURE")}
                            </p>
                            <p className="rp-date-label">
                              {item?.temp?.min.toFixed(0) || 0}&deg;/
                              {item?.temp?.max.toFixed(0) || 0}&deg;C
                            </p>
                          </Col>
                          <Col col={3} className="rp-vertical-line text-center">
                            <p className="rp-climate-label pb-1">
                              {t("RAPID_RESPONSE.RAINFALL")}
                            </p>
                            <p className="rp-date-label">{item?.rain || 0}mm</p>
                          </Col>
                          <Col col={3} className="text-center">
                            <p className="rp-climate-label pb-1">
                              {t("RAPID_RESPONSE.HUMIDITY")}
                            </p>
                            <p className="rp-date-label">
                              {item?.humidity || 0}%
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })
              : ""}
          </ModalBody>
        </Modal>
      </div>
    );
  },
);

export default WeatherForecastModal;
