import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  getAllTopicsListSuccess,
  getAllTopicsListFailed,
  searchContentUserGuideSuccess,
  searchContentUserGuideFailed,
  getAllUserGuideFailed,
  getAllUserGuideSuccess,
  getAllTopicFailed,
  getAllTopicSuccess,
  searchByCriteriaUserGuideFailed,
  searchByCriteriaUserGuideSuccess,
} from "./userGuideActions";
import USER_GUIDE_CONSTS from "./userGuideConsts";
import {
  AXIOS_INSTANCE,
  AXIOS_INSTANCE_LOADER,
  USER_GUIDE_URL,
} from "../apiUtils/config";

const getAllTopicsListApiRequest = async (payload) => {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  let response = {};

  try {
    const apiResponseData = await AXIOS_INSTANCE_LOADER.get(
      `${USER_GUIDE_URL}/topic/getAll${
        payload.page ? `?page=${payload.page}` : ""
      }`,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getAllTopicsList(action) {
  try {
    const response = yield call(getAllTopicsListApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(getAllTopicsListSuccess(responseData));
    } else {
      const responseData = {
        response: { statusCode: 200, response: response.error },
      };
      yield put(getAllTopicsListFailed(responseData));
    }
  } catch (error) {
    const errorData = { error: { statusText: error, netWorkError: true } };
    yield put(getAllTopicsListFailed(errorData));
  }
}

const searchContentUserGuideApiRequest = async (payload) => {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.get(
      `${USER_GUIDE_URL}/search-content/${payload.content}`,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};
function* searchContentUserGuide(action) {
  try {
    const response = yield call(
      searchContentUserGuideApiRequest,
      action.payload,
    );
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(searchContentUserGuideSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(searchContentUserGuideFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(searchContentUserGuideFailed(errorData));
  }
}

const allUserguideApiRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE_LOADER.post(
      `${USER_GUIDE_URL}/getAllByCriteria${
        payload.page ? `?page=${payload.page}` : ""
      }`,
      payload.data,
    );
    response = {
      data: apiResponseData.data,
      pageNumber: payload.page,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      pageNumber: payload.page,
      status: 400,
    };
  }
  return response;
};

function* getAllUserGuideRequest(action) {
  try {
    const response = yield call(allUserguideApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
          pageNumber: response.pageNumber,
        },
      };
      yield put(getAllUserGuideSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          response: response.error,
          pageNumber: response.pageNumber,
        },
      };
      yield put(getAllUserGuideFailed(responseData));
    }
  } catch (error) {
    const errorData = { error: { statusText: error, netWorkError: true } };
    yield put(getAllUserGuideFailed(errorData));
  }
}

const allTopicsApiRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${USER_GUIDE_URL}/getAllTopicSubtopic`,
      payload,
      // payload ? payload : {},
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getAllTopics(action) {
  try {
    const response = yield call(allTopicsApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(getAllTopicSuccess(responseData));
    } else {
      const responseData = {
        response: { statusCode: 200, response: response.error },
      };
      yield put(getAllTopicFailed(responseData));
    }
  } catch (error) {
    const errorData = { error: { statusText: error, netWorkError: true } };
    yield put(getAllTopicFailed(errorData));
  }
}
const searchByCriteriaUserGuideApiRequest = async (payload) => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE_LOADER.get(
      `${USER_GUIDE_URL}/sub-topic/searchByCriteria/${payload.topic_name}/${payload.name}`,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* searchByCriteriaUserGuide(action) {
  try {
    const response = yield call(
      searchByCriteriaUserGuideApiRequest,
      action.payload,
    );
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(searchByCriteriaUserGuideSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(searchByCriteriaUserGuideFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(searchByCriteriaUserGuideFailed(errorData));
  }
}

function* watchAddUserGuide() {
  yield takeEvery(
    USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_REQUEST,
    getAllTopicsList,
  );
  yield takeEvery(
    USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_REQUEST,
    searchContentUserGuide,
  );
  yield takeEvery(
    USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_REQUEST,
    getAllUserGuideRequest,
  );
  yield takeEvery(USER_GUIDE_CONSTS.GET_ALL_TOPICS_REQUEST, getAllTopics);
  yield takeEvery(
    USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_REQUEST,
    searchByCriteriaUserGuide,
  );
}

function* userGuideSaga() {
  yield all([fork(watchAddUserGuide)]);
}

export default userGuideSaga;
