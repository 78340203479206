import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import _ from "lodash";

import { GrFormClose } from "react-icons/gr";
import { ReactComponent as PlusIcon } from "../../assest/img/plus-icon.svg";
import { ReactComponent as AddMedia } from "../../assest/img/add-media.svg";
import { ReactComponent as DocIcon } from "../../assest/img/ef-doc.svg";

import {
  createEarlyFinanceReportRequest,
  deleteEarlyFinanceReferencesRequest,
} from "../../store/actions";
import { showError } from "../../components/Common/Notification";
import DeleteModal from "../../components/Common/DeleteConfirmationModal";

function AttachmentsComponent({
  isGeneratedSelected,
  projectDetails,
  reportData,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [attachments, setAttachments] = useState([]);
  // const [remark, setRemark] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteReference, setDeleteReference] = useState({});

  useEffect(() => {
    if (!_.isEmpty(reportData)) {
      setDeleteReference({});
      if (attachments?.length > 5) {
        showError(t("EARLY_FINANCE_REPORT.MAX_FILES_LIMIT"));
      } else {
        if (reportData?.attachments?.length) {
          setAttachments([...reportData?.attachments]);
        } else {
          setAttachments([]);
        }
      }
    }
  }, [reportData]);

  const handleAddMediaReference = () => {
    fileInputRef.current.click();
  };

  function truncateFileName(fileName1, maxLength) {
    if (fileName1.length <= maxLength) {
      return fileName1;
    } else {
      const truncated = fileName1.substr(0, maxLength - 3);
      return `${truncated} ... ${fileName1.substr(-6)}`;
    }
  }

  // RENDERING ATTACHMENTS
  const renderAttachments = () => {
    return attachments?.length
      ? attachments.map((each) => {
          const name = truncateFileName(each, 100);
          return (
            <div
              className="attachment-doc d-flex justify-content-between"
              key={name}
            >
              <div className="upload-section ml-2">
                <DocIcon />
                <p className="mb-0 ml-2"> {name} </p>
              </div>
              {!isGeneratedSelected ? (
                <span
                  role="button"
                  className="report-delete-attachment-btn"
                  onKeyUp={() => {}}
                  tabIndex="0"
                  onClick={() => {
                    setIsDeleteModalOpen(!isDeleteModalOpen);
                    setDeleteReference({ url: each });
                  }}
                  aria-label="reportDeleteAttachmentBtn"
                >
                  <GrFormClose size={24} />
                </span>
              ) : (
                ""
              )}
            </div>
          );
        })
      : "";
  };

  // API CALL FOR ADDING ATTACHMENTS
  const handleAddDoc = (file, base64) => {
    const payload = {
      project_id: projectDetails?.id,
      id: !_.isEmpty(reportData) ? reportData?.id : "",
    };
    if (file && base64) {
      payload.attachments = [{ file, base64 }];
    }
    dispatch(createEarlyFinanceReportRequest(payload));
  };

  // API CALL FOR DELETING ATTACHMENTS
  const handleDeleteMediaReference = () => {
    const payload = {
      id: reportData?.id,
      attachment_url: deleteReference?.url,
    };
    setIsDeleteModalOpen(!isDeleteModalOpen);
    dispatch(deleteEarlyFinanceReferencesRequest(payload));
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const maxSize = 3 * 1024 * 1024; // 3MB

    if (file.size > maxSize) {
      event.preventDefault();
      showError(t("EARLY_FINANCE_REPORT.FILE_LIMIT_SIZE"));
    } else {
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const base64String = e.target.result.split(",")[1]; // Extract Base64 data
          handleAddDoc(file.name, base64String);
        };

        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <>
      {isDeleteModalOpen ? (
        <DeleteModal
          modal={isDeleteModalOpen}
          toggle={setIsDeleteModalOpen}
          onConfirmDelete={handleDeleteMediaReference}
          message={t("EARLY_FINANCE_REPORT.DELETE_ATTACHMENT")}
          title={t("EARLY_FINANCE_REPORT.DELETE_ATTACHMENT_QUESTION")}
        />
      ) : (
        ""
      )}
      <div className="intervention-div w-100">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2 className="intervention-h2" id="media-reference">
            {t("EARLY_FINANCE_REPORT.ATTACHMENTS")}
          </h2>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept=".doc, .docx"
          />
          {attachments?.length ? (
            <div className="actions-container d-flex align-items-center justify-content-end">
              {!isGeneratedSelected ? (
                <Button
                  className="ef-add-row-btn"
                  onClick={handleAddMediaReference}
                >
                  {t("EARLY_FINANCE_REPORT.ADD_NEW_ATTACHMENTS")}
                  <PlusIcon />
                </Button>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        <Row className={attachments?.length ? "my-1 w-100" : "mt-3"}>
          <div className="attachment-container w-100">
            {attachments?.length ? (
              renderAttachments()
            ) : (
              <Col
                xs={12}
                className="ef-media-add-container cursor-pointer w-100"
                onClick={handleAddMediaReference}
              >
                <AddMedia width={46} height={46} />
                <span className="add-media-text">
                  {t("EARLY_FINANCE_REPORT.CLICK_HERE_ATTACHMENTS")}
                </span>
              </Col>
            )}
          </div>
        </Row>
      </div>
    </>
  );
}

export default AttachmentsComponent;
