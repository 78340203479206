import React from "react";

import { AiFillFileWord, AiOutlineFilePdf } from "react-icons/ai";
import { FaFileCsv, FaImage } from "react-icons/fa";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { Col } from "reactstrap";

import csvImage from "../../assest/img/csvlogo.png";
import docxUploadImage from "../../assest/img/docx_icon123.svg.png";
import PdfImg from "../../assest/img/pdflogo.png";

function MediaPreviewComponent({ data, cardSize }) {
  return data?.media_urls?.map((image) => {
    const fileExtenstion = image?.split(".")?.pop();
    const fileName = image?.split("/")?.pop();
    return (
      <Col sm={cardSize || 4} className="mb-3 pl-0 w-100">
        <a key={image} href={image} target="_blank" download rel="noreferrer">
          <div className="feedback-image-conatiner">
            <img
              src={
                ["xlsx", "csv"].includes(fileExtenstion)
                  ? csvImage
                  : fileExtenstion === "pdf"
                  ? PdfImg
                  : fileExtenstion === "docx"
                  ? docxUploadImage
                  : image
              }
              alt={image}
              className="feedback-image"
            />
            <div className="p-2 feedback-file-label img-label">
              <span>
                {" "}
                {fileExtenstion === "pdf" ? (
                  <AiOutlineFilePdf className="pdf-download-icon" />
                ) : ["xlsx", "csv"].includes(fileExtenstion) ? (
                  <FaFileCsv className="excel-download-icon-color" />
                ) : fileExtenstion === "docx" ? (
                  <AiFillFileWord className="docx-download-icon" />
                ) : ["jpeg", "jpg", "png"].includes(fileExtenstion) ? (
                  <FaImage className="docx-download-icon" />
                ) : (
                  <MdOutlineOndemandVideo className="docx-download-icon" />
                )}{" "}
              </span>
              <p className="ml-1 tile-doc-upload">
                {" "}
                {fileName?.length > 35
                  ? `${fileName.slice(0, 13)} ... ${fileName.slice(-14)} `
                  : fileName}
              </p>
            </div>
          </div>
        </a>
      </Col>
    );
  });
}

export default MediaPreviewComponent;
