import React from "react";
import { Col, Progress, Row } from "reactstrap";

function ProgressBar({ progressBar, maxRatingCount }) {
  return progressBar?.map((data) => (
    <Row key={data.label} className="d-flex align-items-center">
      <Col sm={1} className="p-0">
        <img src={data.icon} alt={data.label} height={36} width={36} />
      </Col>
      <Col sm={2} className="feedback-icon-label">
        {data.label}
      </Col>
      <Col sm={8} className="pl-0 feedback-icon-label">
        <Progress
          className="feedback-progress-bar"
          max={maxRatingCount}
          value={data.value}
          color={data.color}
        />
      </Col>
      <Col sm={1} className="p-0 feedback-icon-label">
        {data.value}
      </Col>
    </Row>
  ));
}

export default ProgressBar;
