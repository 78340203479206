import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Cookies from "js-cookie";
import ODK_CONST from "./odkConst";
import {
  submitConfigurationSuccess,
  componentsListSuccess,
  componentsListFailed,
  componentsByIdSuccess,
  componentsByIdFailed,
  submitConfigurationFailed,
  inviteConfigurationSuccess,
  inviteConfigurationFailed,
  manageSurveyorsListSuccess,
  manageSurveyorsListFailed,
  deleteSurveyorsSuccess,
  deleteSurveyorsFailed,
  surveyConfigurationSuccess,
  surveyConfigurationFailed,
} from "./odkActions";

import checkHttpStatus from "../apiUtils";
import { AXIOS_INSTANCE, ODK_URL } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

const addingToken = () => {
  const userToken = JSON.parse(Cookies.get("user"))?.result?.token;
  AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  return AXIOS_INSTANCE;
};

function* submitConfigurationDataRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${ODK_URL}/uploadSurveyData`, action.payload),
      "",
      true,
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(submitConfigurationSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(submitConfigurationFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(submitConfigurationFailed(errorData));
  }
}

function* surveyConfigurationRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${ODK_URL}/surveyConfiguration`, action.payload),
      "",
      false,
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(surveyConfigurationSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(surveyConfigurationFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(surveyConfigurationFailed(errorData));
  }
}

// const inviteConfigurationRequest = (payload) => {
//   const payloadData = { email_id: payload.email_id };
//   let response = {};
//   try {
//     const data = baseMethod(
//       addingToken().post(`${ODK_URL}/inviteSurveyor`, payloadData),
//       payload.successMesg,
//       "",
//     );
//     response = {
//       data: data.data,
//       status: 200,
//     };
//   } catch (error) {
//     const data = error;
//     response = {
//       error: data,
//       status: 400,
//     };
//   }
//   return response;
// };

function* inviteConfigurationDataRequest(action) {
  const payloadData = { email_id: action?.payload?.email_id };
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${ODK_URL}/inviteSurveyor`, payloadData),
      action?.payload?.successMesg,
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(inviteConfigurationSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(inviteConfigurationFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(inviteConfigurationFailed(errorData));
  }
}

const componentsListRequest = async (payload) => {
  let response = {};
  try {
    const data = await addingToken().get(
      `${ODK_URL}/surveyComponentList`,
      payload,
    );
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* componentsListDataRequest(action) {
  try {
    const apiResponse = yield call(componentsListRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(componentsListSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(componentsListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(componentsListFailed(errorData));
  }
}

const componentsByIdRequest = (payload) => {
  let response = {};
  try {
    const data = baseMethod(
      AXIOS_INSTANCE.post(`${ODK_URL}/getSurveyComponentDetails`, payload),
      "",
      "",
    );
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* componentsByIdDataRequest(action) {
  try {
    const apiResponse = yield call(componentsByIdRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(componentsByIdSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(componentsByIdFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(componentsByIdFailed(errorData));
  }
}

function* manageSurveyorsDataRequest(action) {
  try {
    const apiResponse = baseMethod(
      addingToken().get(
        action.payload.email
          ? `${ODK_URL}/InvitedSurveyorsList?page=${action.payload.page}&limit=${action.payload.limit}&email=${action.payload.email}`
          : `${ODK_URL}/InvitedSurveyorsList?page=${action.payload.page}&limit=${action.payload.limit}`,
        action.payload,
      ),
      "",
      true,
      // true,
      false,
    );
    const response = yield call(checkHttpStatus, apiResponse);

    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(manageSurveyorsListSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(manageSurveyorsListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(manageSurveyorsListFailed(errorData));
  }
}

function* deleteSurveyorsRequest(action) {
  try {
    const apiResponse = baseMethod(
      // AXIOS_INSTANCE.post(`${ODK_URL}/deleteSurveyor`, action.payload),
      AXIOS_INSTANCE.delete(
        `${ODK_URL}/deleteSurveyor/${action.payload.email_id}`,
      ),
      "",
      true,
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(deleteSurveyorsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(deleteSurveyorsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteSurveyorsFailed(errorData));
  }
}

export function* watchOdk() {
  yield takeEvery(
    ODK_CONST.SUBMIT_CONFIGURATION_REQUEST,
    submitConfigurationDataRequest,
  );
  yield takeEvery(
    ODK_CONST.INVITE_CONFIGURATION_REQUEST,
    inviteConfigurationDataRequest,
  );
  yield takeEvery(ODK_CONST.COMPONENTS_LIST_REQUEST, componentsListDataRequest);
  yield takeEvery(ODK_CONST.COMPONENT_BY_ID_REQUEST, componentsByIdDataRequest);
  yield takeEvery(
    ODK_CONST.MANAGE_SURVEYORS_LIST_REQUEST,
    manageSurveyorsDataRequest,
  );
  yield takeEvery(ODK_CONST.DELETE_SURVEYORS_REQUEST, deleteSurveyorsRequest);
  yield takeEvery(
    ODK_CONST.SURVEY_CONFIGURATION_REQUEST,
    surveyConfigurationRequest,
  );
}

function* odkSaga() {
  yield all([fork(watchOdk)]);
}

export default odkSaga;
