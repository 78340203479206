import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrFormClose } from "react-icons/gr";
import Select from "react-select";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import DragAndDrop from "../../components/Common/DragAndDrop";
import {
  RAPID_RESPONSE_NEWSFEED_DISASTER_TYPES,
  feedbackUploadList,
} from "../../constants";
import { showError } from "../../components/Common/Notification";

function CreateDisaster({
  toggle,
  isOpen,
  regionList,
  selectedRegion,
  handleSelectRegion,
  districtList,
  handleSelectDistrict,
  selectedDistrict,
  coordinates,
  handleCoordinates,
  selectedDisaster,
  handleSelectDisaster,
  handleDates,
  startDate,
  endDate,
  selectedFiles,
  setSelectedFiles,
  description,
  handleDescription,
  handleCancelCreate,
  handleSubmit,
  title,
  handleTitle,
  handleSubDistrict,
  subDistrict,
  handleCity,
  city,
}) {
  const { t } = useTranslation();

  const [isEndDateChecked, setIsEndDateChecked] = useState(false);

  useEffect(() => {
    // Add a CSS class to the body element when the modal is open
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  useEffect(() => {
    if (selectedFiles?.length > 5) {
      showError(t("CROP_YIELD.MAX_FILES_LIMIT"));
      setSelectedFiles([]); // Emptying if more than 5 images are uploaded
    }
  }, [selectedFiles]);

  const closeBtn = (
    <GrFormClose
      size={24}
      onClick={() => toggle()}
      className="pointer media-modal-header-close-icon"
    />
  );

  const handleSelectEndDate = (e) => {
    if (e.target.checked) {
      setIsEndDateChecked(e.target.checked);
    } else {
      setIsEndDateChecked(e.target.checked);
      handleDates("", "end");
    }
  };

  const uploadedFiles = [];
  const onHandleUploadImage = (file, base64String) => {
    // Create an object to store file and base64 pair
    const fileData = {
      file_name: file.name,
      base64: base64String.split(",")?.[1],
    };
    // Add the object to the uploadedFiles array
    uploadedFiles.push(fileData);
    if (uploadedFiles?.length) {
      setSelectedFiles([...selectedFiles, ...uploadedFiles]);
    }
  };

  const handleRemoveImage = (index) => {
    const files = [...selectedFiles];
    files.splice(index, 1);
    setSelectedFiles(files);
  };

  const renderFiles = () => {
    return selectedFiles
      ? selectedFiles.map((item, index) => {
          return (
            <div
              className="img-upload-container d-flex justify-content-between align-items-center m-3"
              key={item?.file_name}
            >
              {item?.file_name}
              <span
                role="button"
                onClick={() => handleRemoveImage(index)}
                onKeyUp={() => {}}
                tabIndex="0"
                aria-label="MediaModal"
              >
                <GrFormClose
                  size={16}
                  className="pointer media-modal-header-close-icon"
                />
              </span>
            </div>
          );
        })
      : null;
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        size="md"
        toggle={toggle}
        className="rp-create-disaster"
      >
        <ModalHeader
          toggle={toggle}
          close={closeBtn}
          className="align-items-center p-0 pb-1"
        >
          {t("RAPID_RESPONSE.REPORT_DISASTER_EVENT")}
        </ModalHeader>
        <ModalBody className="px-0">
          <div className="mb-2">
            <p className="mb-1 disaster-inputs-label">
              {t("RAPID_RESPONSE.TITLE")} *
            </p>
            <Input
              type="text"
              name="title"
              className="disaster-date-input"
              placeholder="Title of the Disaster"
              onChange={(e) => handleTitle(e.target.value)}
              value={title}
            />
          </div>
          <div className="mb-2">
            <p className="mb-1 disaster-inputs-label">
              {t("RAPID_RESPONSE.DISASTER_TYPE")} *
            </p>
            <div className="event-filter-container">
              {RAPID_RESPONSE_NEWSFEED_DISASTER_TYPES?.map((option) => {
                return [
                  "Drought",
                  "Flood",
                  "Tropical Cyclone",
                  "Landslide",
                ].includes(option.label) ? (
                  <div
                    key={option.label}
                    onKeyUp={() => {}}
                    role="button"
                    tabIndex={0}
                    className={`event-filter-options mr-2 mb-1 ${
                      option.label === selectedDisaster.label
                        ? "event-active-filter"
                        : ""
                    }`}
                    onClick={() => handleSelectDisaster(option)}
                  >
                    <img
                      src={option?.icon}
                      alt={option.label}
                      width={16}
                      height={16}
                      className="mr-1"
                    />
                    {option.label}
                  </div>
                ) : null;
              })}
            </div>
          </div>
          <div className="mb-2">
            <p className="mb-1 disaster-inputs-label">{t("LOADING.DATE")} *</p>
            <Input
              type="date"
              name="startDate"
              id="startDate"
              max={new Date().toJSON().slice(0, 10)}
              className="disaster-date-input mb-1"
              onChange={(e) => handleDates(e.target.value, "start")}
              value={startDate}
            />
            {moment(startDate).isAfter(moment(new Date())) ? (
              <p className="error px-0">
                {t("RAPID_RESPONSE.START_DATE_ERROR")}
              </p>
            ) : null}
            <FormGroup check inline>
              <Input
                type="checkbox"
                onClick={(e) => handleSelectEndDate(e)}
                checked={isEndDateChecked}
              />
              <Label check className="disaster-inputs-label">
                {t("RAPID_RESPONSE.SPECIFY_END_DATE")}
              </Label>
            </FormGroup>
            {isEndDateChecked ? (
              <>
                <p className="mb-1 disaster-inputs-label">
                  {t("DASHBOARD.END_DATE")}
                </p>
                <Input
                  type="date"
                  name="endDate"
                  id="endDate"
                  max={new Date().toJSON().slice(0, 10)}
                  className="disaster-date-input mb-1"
                  onChange={(e) => handleDates(e.target.value, "end")}
                  value={endDate}
                />
                {moment(endDate).isSameOrBefore(moment(startDate)) ? (
                  <p className="error px-0">
                    {t("RAPID_RESPONSE.END_DATE_ERROR")}
                  </p>
                ) : null}
              </>
            ) : null}
          </div>
          <Row>
            <Col sm={6} className="pl-0 mb-2">
              <Label className="disaster-inputs-label">
                {t("DASHBOARD.REGION")} *
              </Label>
              <Select
                options={regionList}
                value={selectedRegion}
                classNamePrefix="react-select"
                onChange={(e) => handleSelectRegion(e)}
                className="dash-select common-select-r disaster-date-input"
                placeholder="Select Region"
              />
            </Col>
            <Col sm={6} className="pr-0 mb-2">
              <Label className="disaster-inputs-label">
                {t("DASHBOARD.DISTRICT")} *
              </Label>
              <Select
                options={districtList}
                value={selectedDistrict}
                classNamePrefix="react-select"
                onChange={(e) => handleSelectDistrict(e)}
                className="dash-select common-select-r disaster-date-input"
                placeholder="Select District"
              />
            </Col>
            <Col sm={6} className="pl-0 mb-2">
              <Label className="disaster-inputs-label">
                {t("RAPID_RESPONSE.SUB_DISTRICT")} *
              </Label>
              <Input
                type="text"
                name="sub-district"
                className="disaster-date-input"
                placeholder="Enter Sub-district"
                onChange={(e) => handleSubDistrict(e.target.value)}
                value={subDistrict}
              />
            </Col>
            <Col sm={6} className="pr-0 mb-2">
              <Label className="disaster-inputs-label">
                {t("RAPID_RESPONSE.CITY")} *
              </Label>
              <Input
                type="text"
                name="City"
                className="disaster-date-input"
                placeholder="Enter city"
                onChange={(e) => handleCity(e.target.value)}
                value={city}
              />
            </Col>
            <Col sm={6} className="pl-0 mb-2">
              <Label className="disaster-inputs-label">
                {t("RAPID_RESPONSE.LATITUDE_OPTIONAL")}
              </Label>
              <Input
                type="number"
                className="disaster-date-input"
                placeholder="00"
                value={coordinates?.latitude}
                onChange={(e) => handleCoordinates(e.target.value, "latitude")}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    // eslint-disable-next-line func-names
                    function (eve) {
                      eve.preventDefault();
                    },
                    { passive: false },
                  )
                }
              />
            </Col>
            <Col sm={6} className="pr-0 mb-2">
              <Label className="disaster-inputs-label">
                {t("RAPID_RESPONSE.LONGITUDE_OPTIONAL")}
              </Label>
              <Input
                type="number"
                className="disaster-date-input"
                placeholder="00"
                value={coordinates.longitude}
                onChange={(e) => handleCoordinates(e.target.value, "longitude")}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    // eslint-disable-next-line func-names
                    function (eve) {
                      eve.preventDefault();
                    },
                    { passive: false },
                  )
                }
              />
            </Col>
            <Col sm={12} className="px-0 mb-2">
              <Label className="disaster-inputs-label">
                {t("RAPID_RESPONSE.DESCRIPTION")} *
              </Label>
              <Input
                type="textarea"
                className="disaster-date-input"
                onChange={(e) => handleDescription(e.target.value)}
                value={description}
                placeholder="Write your description here..."
              />
            </Col>
            <Col sm={12} className="px-0 mb-2">
              <Label className="disaster-inputs-label">
                {t("RAPID_RESPONSE.FILE_UPLOAD_OPTIONAL")}
              </Label>
              <DragAndDrop
                onHandleUploadImage={onHandleUploadImage}
                acceptList={feedbackUploadList}
                isFeedback
              />
              {selectedFiles?.length ? (
                <div
                  className="img-upload-main-container"
                  style={{ maxHeight: "100px", height: "auto" }}
                >
                  {renderFiles()}
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="py-0">
          <div className="w-100 active-pahse-btn-section justify-content-end">
            <Button
              type="button"
              className="media-cancel-btn mr-3 disaster-create-btns"
              onClick={() => {
                toggle();
                handleCancelCreate();
              }}
            >
              {t("EARLY_ACTIONS.CANCLE")}
            </Button>
            <Button
              type="button"
              className="media-add-btn mb-1 disaster-create-btns"
              disabled={
                title === "" ||
                _.isEmpty(selectedDisaster) ||
                (startDate === ""
                  ? true
                  : moment(startDate).isAfter(moment(new Date()))) ||
                (isEndDateChecked
                  ? endDate === ""
                    ? true
                    : moment(endDate).isSameOrBefore(moment(startDate))
                  : false) ||
                description === "" ||
                selectedDistrict?.value === "" ||
                selectedRegion?.value === "" ||
                subDistrict === "" ||
                city === ""
              }
              onClick={() => handleSubmit()}
            >
              {t("BULLET_IN.SUBMIT")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CreateDisaster;
