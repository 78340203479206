import LOGIN_CONST from "./loginConst";

export const loginUser = (data) => ({
  type: LOGIN_CONST.LOGIN_REQUEST,
  payload: data,
});
export const loginSuccess = (user) => ({
  type: LOGIN_CONST.LOGIN_SUCCESS,
  payload: user,
});
export const loginError = (error) => ({
  type: LOGIN_CONST.LOGIN_ERROR,
  payload: error,
});

export const forgotPassword = (data) => ({
  type: LOGIN_CONST.FORGOT_PASSWORD_REQUEST,
  payload: data,
});

export const forgotPasswordSuccess = (response) => ({
  type: LOGIN_CONST.FORGOT_PASSWORD_SUCCESS,
  payload: response,
});

export const forgotPasswordError = (error) => ({
  type: LOGIN_CONST.FORGOT_PASSWORD_ERROR,
  payload: error,
});

export const resetPassword = (data) => ({
  type: LOGIN_CONST.RESET_PASSWORD_REQUEST,
  payload: data,
});

export const resetPasswordSuccess = (response) => ({
  type: LOGIN_CONST.RESET_PASSWORD_SUCCESS,
  payload: response,
});

export const resetPasswordError = (error) => ({
  type: LOGIN_CONST.RESET_PASSWORD_ERROR,
  payload: error,
});
