import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import * as xlsx from "xlsx";
import * as FileSaver from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useOutletContext } from "react-router-dom";
import ICON from "../../../../assest/img/readiness-icon.png";
import EarlyActionTable from "../../../../components/Common/EarlyActionTable";
import { getReadynessPhaseRequest } from "../../../../store/actions";
import { ReactComponent as DownloadIcon } from "../../../../assest/img/ea-download.svg";
import Comments from "../../../../components/Common/Comments";
import { handleExportToCSVIntervantion } from "../../../../helpers/excelDownload";
import { ColumnsData } from "../../../../constants";

function RiskForecasting() {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();

  const [, setOpen] = useOutletContext(); // for closing side bar when it is opening

  const params = new URLSearchParams(window.location.search);
  const iId = params.get("interventionId");

  const [interventationList, setInterventationList] = useState("");
  const [readynessPPState, setReadynessPPState] = useState([]);
  const [readynessRRState, setReadynessRRState] = useState([]);
  const [readynessPRState, setReadynessPRState] = useState([]);

  const [sendComment, setSendComment] = useState("");
  const [commentList, setCommentList] = useState([]);

  const handleClickBack = () => {
    localStorage.setItem("stepperActiveStep", "1");
    history(-1);
  };
  const nextProps = useSelector((state) => ({
    interventionList: state?.EAReadynessReducer?.interventionList,
  }));

  const modifyData = (dataArray) => {
    const modifiedData = [];

    dataArray?.forEach((contentData, index) => {
      const modifedColumn = {
        id: index,
        Activities: contentData.Activities,
        Target: contentData.Target,
        Timeframe: contentData.Timeframe,
      };

      modifiedData.push(modifedColumn);
    });

    return modifiedData;
  };

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (nextProps?.interventionList) {
      const selectedIntervention = nextProps.interventionList.find(
        (each) => each?.id === iId,
      );
      setCommentList(selectedIntervention?.comment);
      setInterventationList(selectedIntervention);
      const ppPhase = modifyData(
        selectedIntervention?.preparedness_content,
        "ea_rp_preparedness",
      );
      const RRPhase = modifyData(
        selectedIntervention?.readiness_content,
        "ea_rp_readiness",
      );
      const PRPhase = modifyData(
        selectedIntervention?.prioritized_ea_content,
        "ea_rp_prioritised",
      );
      setReadynessPPState(ppPhase);
      setReadynessRRState(RRPhase);
      setReadynessPRState(PRPhase);
    }
  }, [nextProps.interventionList]);

  // add and edit data

  useEffect(() => {
    setOpen(true);
    dispatch(
      getReadynessPhaseRequest({
        module: "early_action",
        project_id: JSON.parse(localStorage.getItem("projectDetails"))?.id,
        data: 0,
      }),
    );
  }, []);

  const COLUMNS = [
    {
      Header: t("EARLY_ACTIONS.ACTIVITIES"),
      accessorKey: "Activities",
      size: 350,
    },
    {
      Header: t("EARLY_ACTIONS.TIME_FRAME"),
      accessorKey: "Timeframe",
      size: 100,
    },
    {
      Header: t("EARLY_ACTIONS.TARGET"),
      accessorKey: "Target",
      size: 60,
    },
  ];

  const hanldeExportToCSV = (data, slug) => {
    const customData = data.map((item) => ({
      "No.": item.id + 1,
      Activities: item.Activities,
      Target: item.Target,
      Timeframe: item.Timeframe,
    }));

    const ws = xlsx.utils.json_to_sheet(customData);

    // Setting the header row style to bold
    const headerStyle = {
      font: { bold: true },
    };
    const headerRange = xlsx.utils.decode_range(ws["!ref"]);
    // eslint-disable-next-line no-plusplus
    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
      const cellAddress = xlsx.utils.encode_cell({
        r: headerRange.s.r,
        c: col,
      });
      ws[cellAddress].s = headerStyle;
    }
    // Setting column widths
    const columnWidths = [
      { wpx: 80 }, // Width for "No." column
      { wpx: 150 }, // Width for "Direct Impacts" column
      { wpx: 150 }, // Width for "Indirect impacts" column
      { wpx: 150 }, // Width for "Indirect impacts" column
    ];

    ws["!cols"] = columnWidths;

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: "array" });
    const sheetData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(sheetData, `${slug}.xlsx`);
  };

  const onHandleDownloadexcel = (data, fileNmae) => {
    handleExportToCSVIntervantion(data, ColumnsData, fileNmae);
  };

  return (
    <div className="ea-project-container">
      <div className="EA-Header ea-space-between">
        <div>
          <BsArrowLeft
            className="back-icon-ea"
            onClick={handleClickBack}
            role="button"
          />
          <p className="ea-header-text">{interventationList?.name}</p>
        </div>
      </div>
      <div className="ea-space">
        <Row className="new-inv-div">
          <Col lg={2}>
            <img
              src={
                interventationList?.icon_url
                  ? interventationList?.icon_url
                  : ICON
              }
              alt=""
              className="new-inv-img"
            />
          </Col>
          <Col lg={10} className="new-inv-heading">
            <h3>{interventationList?.name}</h3>
            <p>
              {t("EARLY_ACTIONS.INDICATOR")}: {"     "}
              <span>{interventationList?.indicator}</span>
            </p>
            <p>
              {t("EARLY_ACTIONS.TARGETED_HOUSEHOLDS")}:{" "}
              <span>{interventationList?.households_targeted}</span>
            </p>
            <p>
              {t("EARLY_ACTIONS.PEOPLE")}:
              <span>{interventationList?.people_targeted}</span>
            </p>
          </Col>
        </Row>
        <div className="new-inv-section">
          <div className="new-inv-section-2">
            <div className="intervention-div">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className="intervention-h2">
                  {t("EARLY_ACTIONS.PREPAREDNESS")}
                </h2>
                <div className="actions-container d-flex align-items-center justify-content-end">
                  <input type="file" style={{ display: "none" }} />

                  <span
                    role="button"
                    className="ea-upload-button d-none"
                    onClick={() =>
                      hanldeExportToCSV(readynessPPState, "ea_rp_preparedness")
                    }
                    onKeyUp={() => {}}
                    tabIndex="0"
                    aria-label="viewearppreparedness"
                  >
                    <DownloadIcon />
                  </span>

                  <span
                    role="button"
                    className="ea-upload-button"
                    onClick={() =>
                      onHandleDownloadexcel(
                        readynessPPState,
                        "ea_rp_preparedness",
                      )
                    }
                    onKeyUp={() => {}}
                    tabIndex="0"
                    aria-label="vieweaDownload"
                  >
                    <DownloadIcon />
                  </span>
                </div>
              </div>
              <div className="new-inv-table">
                <EarlyActionTable
                  COLUMNS={COLUMNS}
                  tableData={readynessPPState}
                />
              </div>
            </div>
            <div className="intervention-div">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className="intervention-h2">
                  {t("EARLY_ACTIONS.READINESS")}
                </h2>
                <div className="actions-container d-flex align-items-center justify-content-end">
                  <input type="file" style={{ display: "none" }} />

                  <span
                    role="button"
                    className="ea-upload-button d-none"
                    onClick={() =>
                      hanldeExportToCSV(readynessRRState, "ea_rp_readiness")
                    }
                    onKeyUp={() => {}}
                    tabIndex="0"
                    aria-label="viewEaRpReadiness"
                  >
                    <DownloadIcon />
                  </span>

                  <span
                    role="button"
                    className="ea-upload-button"
                    onClick={() =>
                      onHandleDownloadexcel(readynessRRState, "ea_rr_readiness")
                    }
                    onKeyUp={() => {}}
                    tabIndex="0"
                    aria-label="viewReadynessRRDownload"
                  >
                    <DownloadIcon />
                  </span>
                </div>
              </div>
              <div className=" new-inv-table">
                <EarlyActionTable
                  COLUMNS={COLUMNS}
                  tableData={readynessRRState}
                />
              </div>
            </div>

            {/* 3rd table  */}

            <div className="intervention-div">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className="intervention-h2">
                  {t("EARLY_ACTIONS.PRIORITY_EA")}
                </h2>
                <div className="actions-container d-flex align-items-center justify-content-end">
                  <input type="file" style={{ display: "none" }} />

                  <span
                    role="button"
                    className="ea-upload-button d-none"
                    onClick={() =>
                      hanldeExportToCSV(readynessPRState, "ea_rp_prioritised")
                    }
                    onKeyUp={() => {}}
                    tabIndex="0"
                    aria-label="readynessRRState"
                  >
                    <DownloadIcon />
                  </span>

                  <span
                    role="button"
                    className="ea-upload-button"
                    onClick={() =>
                      onHandleDownloadexcel(
                        readynessPRState,
                        "ea_pr_preparedness",
                      )
                    }
                    onKeyUp={() => {}}
                    tabIndex="0"
                    aria-label="ViewDocreadynessPRState"
                  >
                    <DownloadIcon />
                  </span>
                </div>
              </div>
              <div className=" new-inv-table">
                <EarlyActionTable
                  COLUMNS={COLUMNS}
                  tableData={readynessPRState}
                />
              </div>
            </div>
            {commentList ? (
              <div className="intervention-div">
                <Comments
                  setComment={setSendComment}
                  sendComment={sendComment}
                  comment={sendComment}
                  commentList={commentList}
                  canAddComment={false}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default RiskForecasting;
