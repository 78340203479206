import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";

import { myConst } from "../../constants";

function StaticMapContainer({ position, zoom, classname }) {
  return (
    <MapContainer
      center={position}
      zoom={zoom}
      className={classname}
      zoomControl={false}
      dragging={false}
      doubleClickZoom={false}
      scrollWheelZoom={false}
      keyboard={false}
      touchZoom={false}
      boxZoom={false}
    >
      <TileLayer
        options={{ tileSize: 256 }}
        attribution={myConst.TITLE_LAYER_ATTRIBUTE}
        url={myConst.TITLE_LAYER_URL}
      />
    </MapContainer>
  );
}

export default StaticMapContainer;
