import React, { useEffect, useRef, useState, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  coreRequest,
  indicatorRequest,
  corestateRequest,
  coredistrictRequest,
  getAllTemplateListRequest,
  userDetailsRequest,
} from "../../../store/actions";
import { userDetails } from "../../../components/Common/Utils";
import { createJSON, renderReactOptionsArraystate } from "../../../helpers";
import {
  State,
  District,
  Temporal,
  TemperatureAnomaly,
} from "../../../constants/index";
import {
  EARLY_WARNING_API,
  AXIOS_INSTANCE_LOADER,
} from "../../../store/apiUtils/config";
import OnlineBulletinModal from "./OnlineBulletinModal";
import GlobalLoader from "../../../components/Common/Loader";
import { showError } from "../../../components/Common/Notification";
import Header from "./Header";
import Branding from "./Content/BulletinBranding";
import BulletinPreview from "./Content/BulletinPreview";
import BulletinFooter from "./Footer";

function OnlineBulletin() {
  const elementToPrint = "contentToPrint";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedLocation, setSelectedLocation] = useState({
    country: "",
    district: "",
    state: "",
  });
  const [loadingLayers, setLoadingLayers] = useState(false);
  const [mapURLResponse, setMapURLResponse] = useState({});
  const [initialIndicesList, setInitialIndicesList] = useState([]);
  const [mapInstances, setMapInstances] = useState({});
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [indicatorRows, setIndicatorRows] = useState({});
  const [eachRowAPIPayload, setEachRowAPIPayload] = useState({});
  const [mapRefs, setMapRefs] = useState({});
  const layerRef = useRef([]);
  const [title, setTitle] = useState();
  const [provienceList, setProvienceList] = useState([]);
  const [queryPrams, setQueryPrams] = useState("");
  const [isLoadingPdf, setIsLoadingPDF] = useState(false);

  const aggregation = Temporal[0];

  const [cordinateAndZoom1, setCordinateAndZoom1] = useState({
    center: "",
    zoom: 6,
  });
  /**
   * value and toggle for create bulletin model
   */
  const [isOpenBulletin, setIsOpenBulletin] = useState(false);

  const toggleModal = () => {
    setIsOpenBulletin(!isOpenBulletin);
  };

  const userData = userDetails();

  /**
   * on mounting
   */
  useEffect(() => {
    /** country value from local storage */
    window.scrollTo(0, 0);
    const selectedCountry = JSON.parse(localStorage.getItem("selectedCountry"));
    setSelectedLocation((prev) => ({ ...prev, country: selectedCountry }));
    dispatch(coreRequest({ location_type: "country" }));
    dispatch(getAllTemplateListRequest({}));
    dispatch(userDetailsRequest({ id: userData?.user_details?.[0]?.id }));
    const params =
      (window.location.search &&
        createJSON(window.location.search.substring(1))) ||
      {};

    if (params) {
      setQueryPrams(params);

      setCordinateAndZoom1({
        center: [params?.latitude, params?.longitude],
        zoom: 8,
      });
      // setZooms(6);
    }
    // unmounting
    return () => {
      setMapInstances({});
      setMapRefs({});
    };
  }, []);

  // retrieve  country ,state ,district and boundaries from store
  const {
    statesList,
    districtsList,
    DroughtIndicesData,
    getAllTemplateListData,
    dashBoardMenuList,
    userDetailsData,
  } = useSelector((state) => ({
    getAllTemplateListData: state.Bulletin.getAllTemplateListData,
    DroughtIndicesData: state.Dashboard.indicatorList,
    statesList: state.Core.corestateList,
    dashBoardMenuList: state.Dashboard.dashBoardMenuData,
    districtsList: state.Core.coredistrictList,
    userDetailsData: state.User?.userDetails,
  }));
  /** drought id for indicators list */
  const DroughtId = dashBoardMenuList?.[0]?.menu_list?.[1]?.id;

  useEffect(() => {
    if (statesList) {
      const newProvienceList = renderReactOptionsArraystate(
        statesList?.data,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
      )?.map((eachValue) => eachValue);
      setProvienceList(newProvienceList);
    }
  }, [statesList]);

  useEffect(() => {
    if (
      queryPrams.country &&
      queryPrams?.indicators?.split(",")?.length &&
      statesList &&
      districtsList
    ) {
      let selectedState = "";
      selectedState = statesList.data.find((e) => e.name === queryPrams.state);
      let selectedDistrict = "";
      selectedDistrict = districtsList.data.find(
        (e) => e.name === queryPrams.district,
      );
      setSelectedLocation((prev) => {
        return {
          ...prev,
          state: queryPrams.state ? selectedState : prev.state,
          district: queryPrams.district ? selectedDistrict : prev.district,
        };
      });
    }
  }, [
    queryPrams.country,
    queryPrams.indicators,
    statesList,
    districtsList,
    queryPrams.district,
  ]);

  /**
   * to call multiple map api's based on indicators on mounting with queryparams
   */
  useEffect(() => {
    if (
      Object.keys(queryPrams)?.length &&
      queryPrams.country &&
      queryPrams.startDate &&
      queryPrams.endDate &&
      initialIndicesList?.length &&
      queryPrams?.indicators?.split(",")?.length
    ) {
      layerRef.current = [];
      const MAP_API_URLS = [];
      queryPrams.indicatorsList = [];
      queryPrams.indicators?.split(",").forEach((item) => {
        initialIndicesList.forEach((indicator) => {
          if (item === indicator.indices_name) {
            queryPrams.indicatorsList.push(indicator);
          }
        });
      });
      queryPrams?.indicatorsList?.forEach((item) => {
        const ref = createRef();
        layerRef.current.push(ref);
        const requestObj = {
          api_url: item.api_url,
          country_name: queryPrams.country,
          state_name: queryPrams.state,
          district_name: queryPrams.district,
          temporal_aggregation: aggregation?.value,
          start_date: queryPrams.startDate,
          end_date: queryPrams.endDate,
        };
        const categorydata = {
          category_name: item?.category_name,
        };
        MAP_API_URLS.push(
          AXIOS_INSTANCE_LOADER.post(
            `${EARLY_WARNING_API}/${item.api_url}map`,
            requestObj,
            categorydata,
          ),
        );
      });
      setLoadingLayers(true);

      const promises = [];
      MAP_API_URLS.forEach((item) => {
        promises.push(Promise.resolve(item).catch((err) => err.response));
      });
      Promise.all(promises)
        .then((response) => {
          const res = JSON.parse(JSON.stringify(response));
          setMapURLResponse({ [`row0`]: res });
          setLoadingLayers(false);
        })
        .catch((err) => {
          setLoadingLayers(false);
          const errorMessage = _.get(err, "response.data.message");
          if (errorMessage?.length) {
            showError(errorMessage[0]);
          }
        });
      // saving first row as base row
      setIndicatorRows((prev) => ({
        ...prev,
        [`row0`]: { ...queryPrams },
      }));
    }
  }, [
    queryPrams.state,
    queryPrams.district,
    initialIndicesList,
    queryPrams.indicators,
    queryPrams.startDate,
    queryPrams.endDate,
    queryPrams.country,
  ]);
  /**
   * to call multiple map api's at once based on indicators list everytime when row added
   */
  const isAPICalling = useRef(true);
  useEffect(() => {
    if (isAPICalling.current) {
      isAPICalling.current = false;
      return;
    }
    const MAP_API_URLS = [];
    if (Object.keys(eachRowAPIPayload)?.length) {
      const row = eachRowAPIPayload;

      row.indicatorsList.forEach((item) => {
        const ref = createRef();
        layerRef.current.push(ref);
        const requestObj = {
          api_url: item.api_url,
          temporal_aggregation: aggregation?.value,
          start_date: row.startDate,
          end_date: row.endDate,
        };
        if (row.district) {
          requestObj.district_name = row.district;
        } else {
          requestObj.district_name = selectedLocation?.district?.name;
        }
        if (row.state !== "") {
          requestObj.state_name = row.state;
        } else {
          requestObj.state_name = selectedLocation?.state?.name;
        }
        if (row.country) {
          requestObj.country_name = row.country;
        } else {
          requestObj.country_name = selectedLocation?.country?.name;
        }
        const categorydata = {
          category_name: item?.category_name,
        };
        MAP_API_URLS.push(
          AXIOS_INSTANCE_LOADER.post(
            `${EARLY_WARNING_API}/${item.api_url}map`,
            requestObj,
            categorydata,
          ),
        );
      });

      if (MAP_API_URLS?.length) {
        setLoadingLayers(true);
        const promises = [];
        const uniqURLS = _.uniq(MAP_API_URLS);
        uniqURLS.forEach((item) => {
          promises.push(Promise.resolve(item).catch((err) => err.response));
        });

        Promise.all(promises)
          .then((response) => {
            const res = JSON.parse(JSON.stringify(response));
            setMapURLResponse((prev) => ({
              ...prev,
              [`row${Object.keys(indicatorRows).length - 1}`]: [...res],
            }));

            setLoadingLayers(false);
          })
          .catch((err) => {
            setLoadingLayers(false);
            const errorMessage = _.get(err, "response.data.message");
            if (typeof errorMessage !== "string" && errorMessage?.length) {
              showError(errorMessage[0]);
            } else {
              showError(errorMessage);
            }
          });
      }
    }
  }, [eachRowAPIPayload]);

  /**
   * to get province and indices based on country id
   */
  const isCountryAPI = useRef(true);
  useEffect(() => {
    if (isCountryAPI.current) {
      isCountryAPI.current = false;
      return;
    }
    if (selectedLocation?.country) {
      if (_.isEmpty(DroughtIndicesData)) {
        if (DroughtId) {
          dispatch(
            indicatorRequest({
              sub_menu_id: DroughtId,
              countryCode: selectedLocation?.country?.code,
            }),
          );
        }
        dispatch(
          corestateRequest({
            data: {
              parent_id: selectedLocation?.country?.value,
              location_type: State,
            },
            isCompareLocation: false,
          }),
        );
      }
      if (selectedLocation?.state?.id) {
        if (selectedLocation.state.id !== selectedStateId) {
          setSelectedStateId(selectedLocation.state.id);
          dispatch(
            coredistrictRequest({
              parent_id: selectedLocation?.state?.value,
              location_type: District,
            }),
          );
        }
      }
    }
  }, [
    DroughtIndicesData,
    DroughtId,
    selectedLocation.country,
    selectedLocation.state,
  ]);

  /** assigning indicators */
  useEffect(() => {
    if (DroughtIndicesData) {
      let parameterArr = [];
      let count = 0;
      DroughtIndicesData.map((item) => {
        if (item?.category === t("CREATE_ONLINE_BULLETIN.DROUGHT")) {
          const x = _.clone(item);
          x.indices.map((i) => {
            i.checked = false;
            i.paramIndex = count;
            count += 1;
            parameterArr.push(i);
            return null;
          });
        }
        return null;
      });
      parameterArr = parameterArr.filter((item) => {
        if (
          item.indices_slug !== TemperatureAnomaly &&
          item?.category_name === "Drought" // we need to show indicators related to drought
        ) {
          return true;
        } else {
          return false;
        }
      });
      setInitialIndicesList(parameterArr);
    } else {
      setInitialIndicesList([]);
    }
  }, [DroughtIndicesData]);

  /**
   *function to store dynamic map instances based on indicators
   */
  const setMapReference = (mapInstance, keyName) => {
    if (mapRefs[keyName]) {
      mapRefs[keyName].current = mapInstance;
    }
    setMapInstances((prev) => ({
      ...prev,
      [`mapInstance${keyName}`]: mapInstance,
    }));
  };

  /**
   *function to add layers in the map
   */
  const addLayers = (selectedParamRef, keyName) => {
    if (mapRefs[keyName]?.current && selectedParamRef.current) {
      const leafletMapRef = mapRefs[keyName].current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer) => {
        leafletMapRef.addLayer(layer);
      });
    }
  };
  /**
   *calls whenever mapURLresponses changes
   */

  const isSkipRow = useRef(true);
  useEffect(() => {
    if (isSkipRow.current) {
      isSkipRow.current = false;
      return;
    }
    let layerIndex = 0;
    if (
      Object.keys(mapURLResponse).length === Object.keys(indicatorRows).length
    ) {
      Object.keys(indicatorRows).forEach((eachResponse, index) => {
        const row = indicatorRows[eachResponse];
        mapURLResponse[eachResponse].forEach((item, key) => {
          const indicators = row.indicatorsList;
          if (indicators[key]) {
            const keyName = `${eachResponse}${indicators[key].indices_name}`;
            if (item.status === 200) {
              const map_data = item.data.result.map_data;
              const legendObj = {
                ...map_data,
                name: indicators[key].indices_name,
                id: indicators[key].indices_name,
                add: true,
                parameter: indicators[key].indices_name,
                measure: map_data?.units || "",
                dataSource: indicators[key].category_name,
                fromMapPreferences: false,
                isCompare: false,
                dataSource_id: indicators[key].category_name,
                parameter_slug: indicators[key].indices_slug,
                startDate: indicators[key].startDate || null,
                endDate: indicators[key].endState || null,
              };
              const currentParamRef = layerRef.current[layerIndex];
              if (!mapInstances[`legendObj${keyName}`]) {
                setMapInstances((prev) => ({
                  ...prev,
                  [`legendObj${keyName}`]: legendObj,
                }));
              }
              if (
                !_.isEmpty(currentParamRef) &&
                !_.isEmpty(currentParamRef?.current)
              ) {
                currentParamRef.current?.eachLayer((layer) => {
                  if (map_data) {
                    layer.setUrl(map_data.map_url);
                  }
                });
              }
              if (
                !_.isEmpty(currentParamRef) &&
                currentParamRef?.current !== null
              ) {
                addLayers(currentParamRef, keyName);
              }
            } else {
              if (Object.keys(indicatorRows).length - 1 === index) {
                const errorMessage = _.get(item, "data.message");
                if (typeof errorMessage !== "string" && errorMessage?.length) {
                  showError(`For ${errorMessage[0]} `);
                } else {
                  showError(`For ${errorMessage}`);
                }
              }
            }
            layerIndex += 1;
          }
        });
      });
    }
  }, [mapURLResponse]);

  const provience = provienceList?.find(
    (item) => item.label === queryPrams?.state,
  );

  const district = districtsList?.data?.find(
    (item) => item.name === queryPrams.district,
  );

  const bulletinType = _.get(getAllTemplateListData, "data.data.result")?.find(
    (e) => e.name === queryPrams.bulletinType,
  );

  const renderedValues = {
    bulletinType: {
      ...bulletinType,
      label: bulletinType?.name,
      value: bulletinType?.id,
    },
    provience: provience
      ? {
          ...provience,
          label: provience?.label,
          value: provience?.value,
        }
      : { label: "All Regions", value: "All Regions" },

    district: district
      ? { ...district, label: district?.name, value: district?.name }
      : {
          label: "All Districts",
          value: "All Districts",
        },
  };

  const updateMapSettings = (center, zoom) => {
    setCordinateAndZoom1({ center, zoom });
  };

  return (
    <div className="global-top-new">
      <div className="w-100 ">
        <div>
          <Header
            queryPrams={queryPrams}
            elementToPrint={elementToPrint}
            setIsOpenBulletin={setIsOpenBulletin}
            title={title}
            indicatorRows={indicatorRows}
            setIsLoadingPDF={setIsLoadingPDF}
            isLoadingPdf={isLoadingPdf}
            permissionsList={userDetailsData?.user_permissions}
            userAdminDetails={userDetailsData?.user}
          />
          {loadingLayers && <GlobalLoader info="onlineBulletin" />}
          <div className="online-bulletin">
            {isOpenBulletin && (
              <OnlineBulletinModal
                isBulletinPage
                statesList={statesList}
                setIsOpenBulletin={setIsOpenBulletin}
                isOpenBulletin={isOpenBulletin}
                toggleModal={toggleModal}
                indicatorsList={initialIndicesList}
                renderedValues={renderedValues}
                setIndicatorRows={setIndicatorRows}
                setMapRefs={setMapRefs}
                setEachRowAPIPayload={setEachRowAPIPayload}
                indicatorRows={indicatorRows}
                eachRowAPIPayload={eachRowAPIPayload}
                bulletinCategories={_.get(
                  getAllTemplateListData,
                  "data.data.result",
                )}
                setSelectedLocation={setSelectedLocation}
              />
            )}
            <div className="empty-space" />
            <div className="contentToPrint">
              <div id={elementToPrint}>
                <div id="branding">
                  <Branding
                    queryPrams={queryPrams}
                    setTitle={setTitle}
                    title={title}
                  />
                  <hr className=" mx-4" />
                </div>
                <div className="mx-3">
                  {Object.keys(indicatorRows)?.length ? (
                    <BulletinPreview
                      queryPrams={queryPrams}
                      selectedLocation={selectedLocation}
                      setMapReference={setMapReference}
                      layerRef={layerRef}
                      indicatorRows={indicatorRows}
                      mapInstances={mapInstances}
                      mapURLResponse={mapURLResponse}
                      initialIndicesList={initialIndicesList}
                      setTitle={setTitle}
                      // onHandleZoom={onHandleZoom}
                      isLoadingPdf={isLoadingPdf}
                      updateMapSettings={updateMapSettings}
                      setCordinateAndZoom1={setCordinateAndZoom1}
                      cordinateAndZoom1={cordinateAndZoom1}
                    />
                  ) : null}
                </div>
                <div id="footer">
                  <BulletinFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OnlineBulletin;
