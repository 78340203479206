import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrFormClose } from "react-icons/gr";
import { Alert } from "reactstrap";
import HighChartGraph from "../../components/Common/HighCharts";
import { renderChartTime } from "../../components/Common/Utils";
import {
  HighChartConstants,
  RAINFALL_TIMESERIES_INTERVALS,
} from "../../constants";
import { handleExportToCSVOpenWeather } from "../../helpers/excelDownload";

function RainfallGraph({
  data,
  graphPanelHeight,
  startDate,
  endDate,
  openPanel,
  open,
  selectedRainfallDataSource,
  setShowChart,
  rainfallInterval,
  setRainfallInterval,
  graphErrorMessage,
}) {
  const { t } = useTranslation();

  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState();

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.reflow();
    }
  }, [graphPanelHeight]);

  useEffect(() => {
    if (data?.data) {
      const myDiv1 = document.getElementById(`rapid-response`);
      const containerWidth2 = myDiv1?.offsetWidth;

      const width =
        data?.data?.length >= HighChartConstants?.fifteen
          ? data?.data?.length * HighChartConstants?.sixtyFive
          : null;

      containerWidth2 > width
        ? setChartWidth(containerWidth2 - 30)
        : setChartWidth(width);
    }
  }, [data?.data, openPanel, open]);

  const options = {
    chart: {
      type: "line",
      height: 320,
      width: chartWidth - 10,
      zoomType: "x",
      resetZoomButton: {
        position: {
          x: -10,
          y: 10,
        },
      },
    },
    title: {
      text: `<b>Accumlated Rainfall (${
        selectedRainfallDataSource?.label
      }) (${moment(startDate).format("D MMM, YYYY")} -  ${moment(
        endDate,
      ).format("D MMM, YYYY")})</b>`,
      style: {
        fontSize: "14px",
        color: "#363636",
      },
      align: "left",
      x: 75,
      y: 10,
    },
    xAxis: {
      categories: data?.date.map((item) => {
        const dateFormat = new Date(item);
        return renderChartTime(dateFormat);
      }),

      gridLineColor: "#DDDDDD",
      crosshair: false,
      gridLineWidth: 1,
    },
    yAxis: {
      title: {
        text: `Rainfall (mm)`,
      },
      lineWidth: 1,
      tickLength: 10,
      tickWidth: 1,
      tickAmount: 6,
      gridLineColor: "#FFFFFF",
    },
    tooltip: {
      shared: false,
      useHTML: true,

      headerFormat: '<table><tr><th colspan="2">{point.x}</th></tr>',
      pointFormat: `<tr><td>{point.series.name}: </td><td style="text-align: right"><b>{point.y}</b></td></tr>`,

      footerFormat: "</table>",
      valueDecimals: 2,
    },
    legend: {
      layout: "horizontal",
      align: "left",
      verticalAlign: "bottom",
    },
    exporting: {
      enabled: true,
      filname: "rapid-response",
      buttons: {
        contextButton: {
          align: "left",
          x: 35,
          y: -5,
          menuItems: [
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            {
              text: "Export to CSV",
              onclick() {
                // eslint-disable-next-line react/no-this-in-sfc
                const seriesData = this.series.map((series) => ({
                  name: series.name,
                  data: series.data.map((point) => point.y),
                }));
                // eslint-disable-next-line react/no-this-in-sfc
                const xAxisData = this.xAxis[0].categories;
                handleExportToCSVOpenWeather(
                  seriesData,
                  xAxisData,
                  selectedRainfallDataSource?.label,
                );
                // Log series data and x-axis data to the console
              },
            },
          ],
        },
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        pointWidth: 35,
      },

      series: {
        states: {
          inactive: {
            enabled: false,
          },
        },
      },
    },
  };

  const handleIntervalChange = (value) => {
    setRainfallInterval(value);
  };

  return (
    <div
      className="crop-yield-graph crop-predection-graph-modal chart-design chart-flow rp-rainfall-graph-no-data"
      id="rapid-response"
    >
      {data?.data?.length ? (
        <>
          <HighChartGraph
            key={data?.layerType}
            options={{
              ...options,
              series: [
                {
                  name: `Point (${data?.latlng[1].toFixed(
                    2,
                  )},${data?.latlng[0].toFixed(2)})`,
                  data: data?.data,
                  color: "#5088C6",
                },
              ],
            }}
            style={{ width: "100%", height: "100%" }}
            className="drought-graph"
            chartRef={chartRef}
          />
          <div className="close-icon-highcharts-rapid-response">
            <GrFormClose
              className="close-graph-bth"
              onClick={() => {
                setShowChart(false);
              }}
            />
          </div>
          <div className="rp-rainfall-radio-btns mt-1">
            {RAINFALL_TIMESERIES_INTERVALS?.map((item) => (
              <span
                className={`rp-rainfall-radio-btns-label ${
                  rainfallInterval === item.value
                    ? "rp-rainfall-radio-btns-selected"
                    : "rp-rainfall-radio-btns-label-unselected"
                }`}
                onClick={() => handleIntervalChange(item.value)}
                role="button"
                tabIndex="0"
                onKeyUp={() => {}}
                key={item.label}
              >
                {item.label}
              </span>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="close-icon-highcharts-rapid-response">
            <GrFormClose
              className="close-graph-bth"
              onClick={() => {
                setShowChart(false);
              }}
            />
          </div>
          <div className="floodgraph-error">
            <div className="graph-error-style h-100">
              <Alert color="warning" className="alert-warning-container">
                {graphErrorMessage || t("LOADING.NOT_FOUND")}{" "}
              </Alert>
            </div>
          </div>
          <div className="rp-rainfall-radio-btns mt-1">
            {RAINFALL_TIMESERIES_INTERVALS?.map((item) => (
              <span
                className={`rp-rainfall-radio-btns-label ${
                  rainfallInterval === item.value
                    ? "rp-rainfall-radio-btns-selected"
                    : "rp-rainfall-radio-btns-label-unselected"
                }`}
                onClick={() => handleIntervalChange(item.value)}
                role="button"
                tabIndex="0"
                onKeyUp={() => {}}
                key={item.label}
              >
                {item.label}
              </span>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default RainfallGraph;
