import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import { AiFillDelete } from "react-icons/ai";
import { HiOutlineXMark } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import DownloadWrapper from "./DownloadWrapper";
import RightArrow from "../../../../assest/img/back-button.svg";

function Header({
  queryPrams,
  elementToPrint,
  title,
  setIsOpenBulletin,
  indicatorRows,
  setIsLoadingPDF,
  isLoadingPdf,
  permissionsList,
  userAdminDetails,
}) {
  const history = useNavigate();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <Modal isOpen={openModal} className="bullet-modal">
        <ModalHeader toggle={handleModal} className="reason-modal-header">
          <HiOutlineXMark
            size={25}
            className="cursor-ponter"
            onClick={handleModal}
            style={{ marginTop: "-20px" }}
          />
        </ModalHeader>
        <ModalBody className="text-center delete-modal-body">
          <AiFillDelete size={30} />
          <div className="mt-3 text-dark">
            <b>{t("BULLET_IN.ARE_YOU_SURE")}</b>
            <br />
            <b>{t("CREATE_ONLINE_BULLETIN.MESSAGE")}</b>
          </div>
          <div className="mt-3">
            <Button
              className="btn bulletin-cb mx-2"
              type="button"
              onClick={handleModal}
            >
              {t("CREATE_ONLINE_BULLETIN.STAY")}
            </Button>
            <Button
              className="btn btn-bulletin-submit mx-2"
              onClick={() => history("/online-bulletin?slug=onlne_bulletin")}
            >
              {t("CREATE_ONLINE_BULLETIN.GO_BACK")}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Row className="bulletin-nav p-2 px-4  pt-2 d-flex bulletin-top-text">
        <Col xs={4} lg={4} className="title-div-text">
          <img
            src={RightArrow}
            className="pointer arrow-back"
            onClick={() => setOpenModal(true)}
            onKeyUp={() => {}}
            alt="right-arrow"
          />
          <h3 className="dashboard-filter-h3 heading-publish">
            {t("CREATE_ONLINE_BULLETIN.CREATE_BULLETIN.HEADER")}
          </h3>
        </Col>
        <Col md={8} className=" text-right text-box">
          <DownloadWrapper
            queryPrams={queryPrams}
            elementToPrint={elementToPrint}
            downloadFileName={`${queryPrams.bulletinType}-${
              queryPrams.district
                ? queryPrams.district
                : queryPrams.state
                ? queryPrams.state
                : queryPrams.country
            }`}
            title={title}
            indicatorRows={indicatorRows}
            setIsLoadingPDF={setIsLoadingPDF}
            isLoadingPdf={isLoadingPdf}
            permissionsList={permissionsList}
            userAdminDetails={userAdminDetails}
          />
          <Button
            className="bulletin-sb"
            onClick={() => setIsOpenBulletin(true)}
          >
            {" "}
            + {t("CREATE_ONLINE_BULLETIN.ADD_MORE")}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default memo(Header);
