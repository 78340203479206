import EARLY_WARNING_HEALTH_CONST from "./actionTypes";

export const getHealthTimeSeriesRequest = (data) => ({
  type: EARLY_WARNING_HEALTH_CONST.GET_HEALTH_TIMESERIES_REQUEST,
  payload: {
    data,
  },
});

export const getHealthTimeSeriesSuccess = (response) => ({
  type: EARLY_WARNING_HEALTH_CONST.GET_HEALTH_TIMESERIES_SUCCESS,
  payload: response,
});

export const getHealthTimeSeriesError = (error) => ({
  type: EARLY_WARNING_HEALTH_CONST.GET_HEALTH_TIMESERIES_ERROR,
  payload: error,
});

export const getEWRawDataDatesRequest = (data) => ({
  type: EARLY_WARNING_HEALTH_CONST.GET_EW_RAW_DATA_DATES_REQUEST,
  payload: data,
});

export const getEWRawDataDatesSuccess = (response) => ({
  type: EARLY_WARNING_HEALTH_CONST.GET_EW_RAW_DATA_DATES_SUCCESS,
  payload: response,
});

export const getEWRawDataDatesError = (error) => ({
  type: EARLY_WARNING_HEALTH_CONST.GET_EW_RAW_DATA_DATES_ERROR,
  payload: error,
});

// MALARIA DATA
export const getHealthMalariaData = (obj) => ({
  type: EARLY_WARNING_HEALTH_CONST.GET_HEALTH_MALARIA_DATA_REQUEST,
  payload: {
    requestObj: obj,
  },
});

export const getHealthMalariaDataSuccess = (response) => ({
  type: EARLY_WARNING_HEALTH_CONST.GET_HEALTH_MALARIA_DATA_SUCCESS,
  payload: response,
});

export const getHealthMalariaDataFailed = (error) => ({
  type: EARLY_WARNING_HEALTH_CONST.GET_HEALTH_MALARIA_DATA_FAILED,
  payload: error,
});

// MALARIA DATA PREDECTION
export const getHealthMalariaPredectionData = (obj) => ({
  type: EARLY_WARNING_HEALTH_CONST.GET_HEALTH_PREDECTION_DATA_REQUEST,
  payload: obj,
});

export const getHealthMalariaPredectionSuccess = (response) => ({
  type: EARLY_WARNING_HEALTH_CONST.GET_HEALTH_PREDECTION_DATA_SUCCESS,
  payload: response,
});

export const getHealthMalariaPredectionFailed = (error) => ({
  type: EARLY_WARNING_HEALTH_CONST.GET_HEALTH_PREDECTION_DATA_ERROR,
  payload: error,
});
