import React from "react";
import { useTranslation } from "react-i18next";

export default function index() {
  const { t } = useTranslation();
  return (
    <div className="default-page">
      <h1>{t("TERMS_AND_CONDITIONS.PAGE_NOT_READY")}</h1>
    </div>
  );
}
