import USER_GUIDE_CONSTS from "./userGuideConsts";

const UserGuide = (state, action) => {
  if (typeof state === "undefined") {
    state = {
      error: "",
      loading: false,
    };
  }
  switch (action.type) {
    case USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_REQUEST:
      return {
        ...state,
        isGetAllTopicsListRequesting: true,
        getAllTopicsListData: false,
        getAllTopicsListDataError: false,
        getAllTopicsListDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_SUCCESS:
      return {
        ...state,
        isGetAllTopicsListRequesting: false,
        getAllTopicsListData: action.payload.response,
        getAllTopicsListDataError: false,
        getAllTopicsListDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_FAILED:
      return {
        ...state,
        isGetAllTopicsListRequesting: false,
        getAllTopicsListData: false,
        getAllTopicsListDataError: action.payload.error
          ? false
          : action.payload.response,
        getAllTopicsListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
    case USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_REQUEST:
      return {
        ...state,
        isSearchContentUserGuideRequesting: true,
        searchContentUserGuideData: false,
        searchContentUserGuideDataError: false,
        searchContentUserGuideDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_SUCCESS:
      return {
        ...state,
        isSearchContentUserGuideRequesting: false,
        searchContentUserGuideData: action.payload.response,
        searchContentUserGuideDataError: false,
        searchContentUserGuideDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_FAILED:
      return {
        ...state,
        isSearchContentUserGuideRequesting: false,
        searchContentUserGuideData: false,
        searchContentUserGuideDataError: action.payload.error
          ? false
          : action.payload.response,
        searchContentUserGuideDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
    case USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_REQUEST:
      return {
        ...state,
        isGetAllUserGuideRequesting: true,
        getAllUserGuideData: false,
        getAllUserGuideDataError: false,
        getAllUserGuideDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_SUCCESS:
      return {
        ...state,
        isGetAllUserGuideRequesting: false,
        getAllUserGuideData: action.payload.response,
        getAllUserGuideDataError: false,
        getAllUserGuideDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_FAILED:
      return {
        ...state,
        isGetAllUserGuideRequesting: false,
        getAllUserGuideData: false,
        getAllUserGuideDataError: action.payload.error
          ? false
          : action.payload.response,
        getAllUserGuideDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
    case USER_GUIDE_CONSTS.GET_ALL_TOPICS_REQUEST:
      return {
        ...state,
        isGetAllTopicsRequesting: true,
        getAllTopicData: false,
        getAllTopicDataError: false,
        getAllTopicDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.GET_ALL_TOPICS_SUCCESS:
      return {
        ...state,
        isGetAllTopicsRequesting: false,
        getAllTopicData: action.payload.response,
        getAllTopicDataError: false,
        getAllTopicDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.GET_ALL_TOPICS_FAILED:
      return {
        ...state,
        isGetAllTopicsRequesting: false,
        getAllTopicData: false,
        getAllTopicDataError: action.payload.error
          ? false
          : action.payload.response,
        getAllTopicDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
    case USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_REQUEST:
      return {
        ...state,
        isSearchByCriteriaUserGuideRequesting: true,
        searchByCriteriaUserGuideData: false,
        searchByCriteriaUserGuideDataError: false,
        searchByCriteriaUserGuideDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_SUCCESS:
      return {
        ...state,
        isSearchByCriteriaUserGuideRequesting: false,
        searchByCriteriaUserGuideData: action.payload.response,
        searchByCriteriaUserGuideDataError: false,
        searchByCriteriaUserGuideDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_FAILED:
      return {
        ...state,
        isSearchByCriteriaUserGuideRequesting: false,
        searchByCriteriaUserGuideData: false,
        searchByCriteriaUserGuideDataError: action.payload.error
          ? false
          : action.payload.response,
        searchByCriteriaUserGuideDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
    default:
      return state;
  }
};

export default UserGuide;
