import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";

import _ from "lodash";
import { getPreparednessPhaseRequest } from "../../store/actions";
import PreviewTable from "../../components/Common/PreviewTable";
import EFFooter from "./Footer";

function Annexures({ isGeneratedSelected, tableListingData }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [categoriesData, setCategoriesData] = useState([]);
  const [impactsTableData, setImpactsTableData] = useState([]);
  const [possibleAATableData, setPossbileAAtableData] = useState([]);
  const [anticipatoryActionTableData, setAnticipatoryActionTableData] =
    useState([]);

  const nextProps = useSelector((state) => ({
    preparednessPhaseData: state?.EaProjects?.preparednessPhaseData,
  }));

  useEffect(() => {
    const projectDetails = JSON.parse(localStorage.getItem("projectDetails"));
    // SENDING EMPTY STRING IN SLUG GIVES ALL DATA
    dispatch(
      getPreparednessPhaseRequest({
        slug: "",
        project_id: projectDetails?.id,
        is_default: "false",
      }),
    );
  }, []);

  const CATEGORY_COLUMNS = [
    {
      Header: t("EARLY_ACTIONS.CATEGORY_OF_IMPACTS"),
      accessorKey: "categoryOfImpacts",
      size: 150,
    },
    {
      Header: t("EARLY_ACTIONS.DESCRIPTION"),
      accessorKey: "description",
      size: 350,
      Cell: (row) =>
        row.renderedCellValue.map((item) => (
          <div dangerouslySetInnerHTML={{ __html: item }} />
        )),
    },
  ];

  const IMPACTS_COLUMNS = [
    {
      Header: t("EARLY_ACTIONS.CATEGORY_OF_IMPACTS"),
      accessorKey: "categoryOfImpacts",
      size: 70,
    },
    {
      Header: t("EARLY_ACTIONS.DIRECT_IMPACTS"),
      accessorKey: "directImpacts",
      size: 150,
      Cell: (row) =>
        row.renderedCellValue.map((item) => (
          <div dangerouslySetInnerHTML={{ __html: item }} />
        )),
    },
    {
      Header: t("EARLY_ACTIONS.INDIRECT_IMPACTS"),
      accessorKey: "inDirectImpacts",
      size: 150,
      Cell: (row) =>
        row.renderedCellValue.map((item) => (
          <div dangerouslySetInnerHTML={{ __html: item }} />
        )),
    },
  ];

  const POSSIBLE_AA_COLUMNS = [
    {
      Header: t("EARLY_ACTIONS.CATEGORY_OF_IMPACTS"),
      accessorKey: "categoryOfImpacts",
      size: 80,
    },
    {
      Header: t("EARLY_ACTIONS.DIRECT_IMPACTS"),
      accessorKey: "directImpacts",
      Cell: (row) =>
        row.renderedCellValue.map((item) => (
          <div dangerouslySetInnerHTML={{ __html: item }} />
        )),
      size: 150,
    },
    {
      Header: t("EARLY_ACTIONS.INDIRECT_IMPACTS"),
      accessorKey: "inDirectImpacts",
      Cell: (row) =>
        row.renderedCellValue.map((item) => (
          <div dangerouslySetInnerHTML={{ __html: item }} />
        )),
      size: 150,
    },
    {
      Header: t("EARLY_ACTIONS.POSSIBLE_ANTICIPATORY_ACTIONS"),
      accessorKey: "anticipatoryActions",
      Cell: (row) =>
        row.renderedCellValue.map((item) => (
          <div dangerouslySetInnerHTML={{ __html: item }} />
        )),
      size: 150,
    },
  ];

  const ANTICIPATORY_COLUMNS = [
    {
      Header: t("EARLY_ACTIONS.CATEGORY"),
      accessorKey: "category",
      size: 80,
    },
    {
      Header: t("EARLY_ACTIONS.OBJECTIVE"),
      accessorKey: "objective",
      Cell: (row) =>
        row.renderedCellValue.map((item) => (
          <div dangerouslySetInnerHTML={{ __html: item }} />
        )),
      size: 100,
    },
    {
      Header: t("EARLY_ACTIONS.ANTICIPATORY_PREPAREDNESS_PHASE"),
      accessorKey: "anticipatoryActionPhase",
      Cell: (row) =>
        row.renderedCellValue.map((item) => (
          <div dangerouslySetInnerHTML={{ __html: item }} />
        )),
      size: 150,
    },
    {
      Header: t("EARLY_ACTIONS.ANTICIPATORY_ACTION_PHASE"),
      id: "Anticipatory Action Phase",
      columns: [
        {
          Header: t("EARLY_ACTIONS.READINESS_ACTIVATED"),
          accessorKey: "readinessActivated",
          Cell: (row) =>
            row.renderedCellValue.map((item) => (
              <div dangerouslySetInnerHTML={{ __html: item }} />
            )),
          size: 150,
        },
        {
          Header: t("EARLY_ACTIONS.ACTION_ACTIVATED"),
          accessorKey: "actionActivated",
          Cell: (row) =>
            row.renderedCellValue.map((item) => (
              <div dangerouslySetInnerHTML={{ __html: item }} />
            )),
          size: 150,
        },
      ],
      size: 300,
    },
  ];

  const INTERVENTION_COLUMNS = [
    {
      Header: t("EARLY_ACTIONS.ACTIVITIES"),
      accessorKey: "Activities",
      size: 350,
    },
    {
      Header: t("EARLY_ACTIONS.TIME_FRAME"),
      accessorKey: "Timeframe",
      size: 100,
    },
    {
      Header: t("EARLY_ACTIONS.TARGET"),
      accessorKey: "Target",
      size: 60,
    },
    {
      Header: t("EARLY_FINANCE.BUDGET"),
      accessorKey: "Budget",
      size: 60,
    },
  ];

  useEffect(() => {
    const categoryData = [];
    const impactsData = [];
    const possibleAAData = [];
    const anticipatoryData = [];

    if (!_.isEmpty(nextProps.preparednessPhaseData)) {
      // Categories data
      nextProps.preparednessPhaseData?.category?.[0]?.content.forEach(
        (contentData, index) => {
          const modifedColumn = {};
          modifedColumn.id = index;
          modifedColumn.categoryOfImpacts = contentData["Category of impacts"];
          modifedColumn.description = [contentData?.Description];
          categoryData.push(modifedColumn);
        },
      );
      if (categoryData?.length) {
        setCategoriesData(categoryData);
      } else {
        setCategoriesData([]);
      }
      // Impacts
      nextProps.preparednessPhaseData?.impact?.[0]?.content.forEach(
        (contentData, index) => {
          const modifedColumn = {};
          modifedColumn.id = index;
          modifedColumn.categoryOfImpacts = contentData["Category of impacts"];
          modifedColumn.directImpacts = [contentData["Direct Impacts"]];
          modifedColumn.inDirectImpacts = [contentData["Indirect impacts"]];
          impactsData.push(modifedColumn);
        },
      );
      if (impactsData?.length) {
        setImpactsTableData(impactsData);
      } else {
        setImpactsTableData([]);
      }
      // POSSIBLE ANTICIPATORY ACTIONS
      nextProps.preparednessPhaseData?.posibble_aa?.[0]?.content.forEach(
        (contentData, index) => {
          const modifedColumn = {};
          modifedColumn.id = index;
          modifedColumn.categoryOfImpacts = contentData["Category of impacts"];
          modifedColumn.directImpacts = [contentData["Direct Impacts"]];
          modifedColumn.inDirectImpacts = [contentData["Indirect impacts"]];
          modifedColumn.anticipatoryActions = [
            contentData["Possible Impact based Anticipatory actions"],
          ];
          possibleAAData.push(modifedColumn);
        },
      );
      if (possibleAAData?.length) {
        setPossbileAAtableData(possibleAAData);
      } else {
        setPossbileAAtableData([]);
      }
      // ANTICIPATORY ACTION PLANS
      nextProps.preparednessPhaseData?.aa_plan?.[0]?.content.forEach(
        (contentData, index) => {
          const modifedColumn = {};
          modifedColumn.id = index;
          modifedColumn.category = contentData.Category;
          modifedColumn.objective = [contentData.Objective];
          modifedColumn.anticipatoryActionPhase = [
            contentData["Anticipatory Action Preparedness Phase"],
          ];
          modifedColumn.readinessActivated = contentData[
            "Anticipatory Action Phase"
          ]
            ? [
                contentData["Anticipatory Action Phase"][0][
                  "Actions to be undertaken When Readiness trigger activated"
                ],
              ]
            : [];
          modifedColumn.actionActivated = contentData[
            "Anticipatory Action Phase"
          ]
            ? [
                contentData["Anticipatory Action Phase"][0][
                  "Actions to be undertaken When Action trigger activated"
                ],
              ]
            : [];
          anticipatoryData.push(modifedColumn);
        },
      );
      if (anticipatoryData?.length) {
        setAnticipatoryActionTableData(anticipatoryData);
      } else {
        setAnticipatoryActionTableData([]);
      }
    }
  }, [nextProps.preparednessPhaseData]);

  const modifyData = (dataArray) => {
    const modifiedData = [];
    dataArray?.forEach((contentData, index) => {
      const modifedColumn = {
        id: index,
        Activities: contentData.Activities,
        Target: contentData.Target,
        Timeframe: contentData.Timeframe,
        Budget: contentData.Budget,
      };
      modifiedData.push(modifedColumn);
    });
    return modifiedData;
  };

  return (
    <div className="ea-space-y mb-3">
      <Row className="annexures-report-container">
        <div id="annexures" className="m-0 p-0">
          <div className="ef-annexures-header w-100">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="intervention-h2">
                {t("EARLY_FINANCE_REPORT.ANNEXURES")}
              </h2>
            </div>
          </div>
          {/* Categories */}
          <h6 className="annexures-h2">{t("EARLY_ACTIONS.CATEGORIES")}</h6>
          <div
            className="new-inv-table"
            style={
              isGeneratedSelected
                ? { maxHeight: "100vh", width: "100%", display: "inline-table" }
                : {}
            }
          >
            <PreviewTable
              tableData={categoriesData}
              COLUMNS={CATEGORY_COLUMNS}
              isPreview={isGeneratedSelected}
            />
          </div>
        </div>
        <div id="impacts" className="m-0 p-0">
          {/* impacts */}
          <h6 className="annexures-h2">{t("EARLY_ACTIONS.IMPACTS")}</h6>
          <div
            className="new-inv-table"
            style={
              isGeneratedSelected
                ? { maxHeight: "100vh", width: "100%", display: "inline-table" }
                : {}
            }
          >
            <PreviewTable
              tableData={impactsTableData}
              COLUMNS={IMPACTS_COLUMNS}
              isPreview={isGeneratedSelected}
            />
          </div>
        </div>
        <div id="possible-anticipatory-action" className="m-0 p-0">
          {/* Possbile Anticipatory Actions  */}
          <h6 className="annexures-h2">
            {t("EARLY_ACTIONS.ANTICIPATORY_ACTION")}
          </h6>
          <div
            className="new-inv-table"
            style={
              isGeneratedSelected
                ? { maxHeight: "100vh", width: "100%", display: "inline-table" }
                : {}
            }
          >
            <PreviewTable
              tableData={possibleAATableData}
              COLUMNS={POSSIBLE_AA_COLUMNS}
              isPreview={isGeneratedSelected}
            />
          </div>
        </div>
        <div id="action-plan" className="m-0 p-0">
          {/* Anticipatory Action plan */}
          <h6 className="annexures-h2">
            {t("EARLY_ACTIONS.ANTICIPATORY_ACTION_PLAN")}
          </h6>
          <div
            className="new-inv-table"
            style={
              isGeneratedSelected
                ? { maxHeight: "100vh", width: "100%", display: "inline-table" }
                : {}
            }
          >
            <PreviewTable
              tableData={anticipatoryActionTableData}
              COLUMNS={ANTICIPATORY_COLUMNS}
              isPreview={isGeneratedSelected}
            />
          </div>
        </div>
        {tableListingData?.length &&
          tableListingData?.map((item) => {
            const inputString = item?.name;
            const replacedString = inputString.replace(/ /g, "_");

            // Preparedness phase
            const preparednessStageData = modifyData(
              item?.preparedness_content,
            );
            // readiness phase
            const readinessStageData = modifyData(item?.readiness_content);
            // prioritized phase
            const prioritizedStageData = modifyData(
              item?.prioritized_ea_content,
            );
            return (
              <div id={replacedString} className="m-0 p-0" key={item.name}>
                {/* INTERVENTION TABLES */}
                <div className="ef-intervention-heading-conatiner">
                  <h6 className="ef-intervention-header">{item.name}</h6>
                </div>
                {/* PREPAPREDNESS PHASE */}
                <h6 className="annexures-h2">
                  {t("EARLY_ACTIONS.PREPAREDNESS")}
                </h6>
                <div
                  className="new-inv-table mb-3"
                  style={
                    isGeneratedSelected
                      ? {
                          maxHeight: "100vh",
                          width: "100%",
                          display: "inline-table",
                        }
                      : {}
                  }
                >
                  <PreviewTable
                    tableData={preparednessStageData}
                    COLUMNS={INTERVENTION_COLUMNS}
                    isPreview={isGeneratedSelected}
                    isBudget
                  />
                </div>
                {/* READINESS PHASE */}
                <h6 className="annexures-h2 mb-3">
                  {t("EARLY_ACTIONS.READINESS")}
                </h6>
                <div
                  className="new-inv-table mb-5"
                  style={
                    isGeneratedSelected
                      ? {
                          maxHeight: "100vh",
                          width: "100%",
                          display: "inline-table",
                        }
                      : {}
                  }
                >
                  <PreviewTable
                    tableData={readinessStageData}
                    COLUMNS={INTERVENTION_COLUMNS}
                    isPreview={isGeneratedSelected}
                    isBudget
                  />
                </div>
                {/* PRIORITIZED PHASE */}
                <h6 className="annexures-h2 mb-3">
                  {t("EARLY_ACTIONS.PRIORITY_EA")}
                </h6>
                <div
                  className="new-inv-table mb-5"
                  style={
                    isGeneratedSelected
                      ? {
                          maxHeight: "100vh",
                          width: "100%",
                          display: "inline-table",
                        }
                      : {}
                  }
                >
                  <PreviewTable
                    tableData={prioritizedStageData}
                    COLUMNS={INTERVENTION_COLUMNS}
                    isPreview={isGeneratedSelected}
                    isBudget
                  />
                </div>
              </div>
            );
          })}
        <EFFooter />
      </Row>
    </div>
  );
}

export default Annexures;
