import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineGlobeAlt } from "react-icons/hi";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav } from "reactstrap";

import { useTranslation } from "react-i18next";
import Select from "react-select";
import { LanguagesArray } from "../../constants";
import cigarlogo from "../../assest/img/climberresilanceLogo.svg";

function Header() {
  const { i18n, t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState({});

  const onSelectLanguage = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage.value);
  };
  const history = useNavigate();
  function handleClick() {
    history("/climber");
  }
  useEffect(() => {
    const lang = LanguagesArray.find(
      (eachValue) => eachValue.value === localStorage.getItem("i18nextLng"),
    );
    setLanguage(lang);
  }, []);

  return (
    <Navbar className="header-bg-color" dark expand="sm">
      <NavbarBrand className="align-center">
        <span
          onClick={handleClick}
          role="button"
          tabIndex="0"
          onKeyUp={() => {}}
        >
          <img src={cigarlogo} alt="logo" className="" />
        </span>{" "}
        <div className="hr-climber" />
        <div className="nav-bar-climber-text">
          {" "}
          {t("CLIMBER_HEADER.CLIMBER_G4_PORTAL")}
        </div>
      </NavbarBrand>
      <NavbarToggler
        onClick={() => setIsOpen((prev) => !prev)}
        className="navtoogle-1"
      />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto align-center" navbar>
          <div className="common-select header-lang-select-climber header-select-div">
            <HiOutlineGlobeAlt className="lang-icon" />
            <div>
              <Select
                value={language}
                classNamePrefix="react-select"
                options={LanguagesArray}
                className="header-select common-select-r"
                placeholder=""
                isSearchable={false}
                onChange={(e) => onSelectLanguage(e)}
              />
            </div>
          </div>
        </Nav>
      </Collapse>
    </Navbar>
  );
}
export default Header;
