import GEOGLOWS_CONST from "./actionTypes";

export const getGeoglowsDrainageRequest = (req_body) => ({
  type: GEOGLOWS_CONST.GEOGLOWS_DRAINAGE_REQUEST,
  payload: req_body,
});
export const getGeoglowsDrainageSuccess = (response) => ({
  type: GEOGLOWS_CONST.GEOGLOWS_DRAINAGE_SUCCESS,
  payload: response,
});

export const getGeoglowsDrainageFalied = (error) => ({
  type: GEOGLOWS_CONST.GEOGLOWS_DRAINAGE_FAILED,
  payload: error,
});

export const getGeoglowsCatchmentRequest = (req_body) => ({
  type: GEOGLOWS_CONST.GEOGLOWS_CATCHMENT_REQUEST,
  payload: req_body,
});
export const getGeoglowsCatchmentSuccess = (response) => ({
  type: GEOGLOWS_CONST.GEOGLOWS_CATCHMENT_SUCCESS,
  payload: response,
});

export const getGeoglowsCatchmentFalied = (error) => ({
  type: GEOGLOWS_CONST.GEOGLOWS_CATCHMENT_FAILED,
  payload: error,
});
