import React, { useEffect, useRef } from "react";
import Select from "react-select";
import { Label, Button, Row, Col } from "reactstrap";
import moment from "moment";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import { IoIosEyeOff, IoMdEye } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { dashBoardMenuRequest } from "../../../store/actions";
import CommonToolTip from "../../../components/Common/CommonToolTip";
import { CROP_YEILD_TRAINING_OPTIONS } from "../../../constants";
import { ReactComponent as UploadIcon } from "../../../assest/img/ea-upload.svg";
import { renderFilesRemoveOfUpload } from "../../../helpers";
import { showError } from "../../../components/Common/Notification";

function Filter({
  selectedLocation,
  countryList,
  setSelectedLocation,
  onHandleZoom,
  adminRadio,
  basinListOptions,
  regionListOptions,
  districtsListOptions,
  selectedCrop,
  setSelectedCrop,
  onHandleRadioBtn,
  onHandleResetData,
  startYear,
  setStartYear,
  endYear,
  setEndyear,
  selectedModal,
  setSelectedModal,
  cyModels,
  cropData,
  predictState,
  districtBoundry,
  geoJsonKeys,
  setDistrictJsonData,
  setScrollableyear,
  setDistricyBoundry,
  endDateRainfall,
  mapPreferencesData,
  loading,
  isUploadFilesOpen,
  setIsUploadFilesOpen,
  predictionFile,
  setPredictionFile,
  setTrainingDataYear,
  trainingDataYear,
  setHavePredictionData,
  havePredictionData,
  setOriginalPredictionFile,
  setTrainingDataStartYear,
  trainingDataStartYear,
  trainingMaxDate,
}) {
  const { t } = useTranslation();
  const scrollRef = useRef();
  const dispatch = useDispatch();

  const onCountrySelection = (e) => {
    dispatch(dashBoardMenuRequest(e));

    setSelectedLocation((prev) => {
      return {
        ...prev,
        country: e,
        state: "",
        district: "",
        basin: "",
        subBasin: "",
      };
    });
    localStorage.setItem("selectedCountry", JSON.stringify(e));
    onHandleZoom([e.latitude, e.longitude], e.zoom_level);
    scrollRef.current.scrollTop = 0;
  };
  const onSelectStates = (e, stateList, type) => {
    if (type !== "basin") {
      setDistrictJsonData("");
      setDistricyBoundry("");

      const selectedOption = stateList.find((item) => item.label === e?.label);
      onHandleZoom([e.latitude, e.longitude], e.zoom_level);
      setSelectedLocation((prev) => {
        return {
          ...prev,
          state: selectedOption,
          district: "",
          basin: "",
          subBasin: "",
        };
      });
    }
    scrollRef.current.scrollTop = 0;
  };

  const onSelectDistrict = (e, districtlist, type) => {
    // setEndyear("");
    if (type === "admin") {
      const selectedOption = districtlist.find(
        (item) => item.label === e?.label,
      );

      const districtBoundrySelected = districtBoundry?.features?.find(
        (each) =>
          each?.properties?.[`${geoJsonKeys?.districts_or_cities}`] ===
          selectedOption?.label,
      );
      if (districtBoundrySelected) {
        setScrollableyear({
          yearList: [],
          selectedYear: "",
        });
        setDistrictJsonData(districtBoundrySelected);
      }

      setSelectedLocation((prev) => {
        return {
          ...prev,
          district: selectedOption,
        };
      });

      onHandleZoom([e.latitude, e.longitude], e.zoom_level);
    }
    scrollRef.current.scrollTop = 0;
  };

  const handleChange = (date) => {
    // Extract the year from the selected date
    const year = date.getFullYear();

    setStartYear(year);
    setEndyear("");
  };

  const onHandlePredict = () => {
    onHandleRadioBtn();
  };

  const onHandleReset = () => {
    onHandleResetData();
  };
  const onHandleUploadTrainningData = () => {
    setIsUploadFilesOpen(!isUploadFilesOpen);
  };

  const onHandleUploadPredictionData = (e) => {
    setHavePredictionData(e);

    e.label === "No" ? setTrainingDataYear() : null;
  };

  useEffect(() => {
    if (predictionFile) {
      const readExcelFile = () => {
        if (predictionFile.length === 0) {
          return;
        }

        const file = predictionFile[0];
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonSheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          if (
            // eslint-disable-next-line no-restricted-globals
            isNaN(Number(jsonSheet[0][jsonSheet[0]?.length - 1])) ||
            jsonSheet[0][0] !== "Regions" ||
            jsonSheet[0][1] !== "Departments"
          ) {
            showError("Please Enter a Valid Excel file");
            setPredictionFile([]);
            setOriginalPredictionFile([]);
            setTrainingDataYear();
          } else {
            if (
              Number(jsonSheet[0][jsonSheet[0]?.length - 1]) <= trainingMaxDate
            ) {
              setTrainingDataYear(
                Number(jsonSheet[0][jsonSheet[0]?.length - 1]),
              );
              setTrainingDataStartYear(jsonSheet[0]?.[2]);
              setStartYear(Number(jsonSheet[0][jsonSheet[0]?.length - 1]) + 1);
              setEndyear("");
            } else {
              showError(`Training data should be upto ${trainingMaxDate}`);
              setPredictionFile([]);
              setOriginalPredictionFile([]);
              setTrainingDataYear();
              setStartYear("");
              setEndyear("");
            }
          }
        };

        reader.readAsArrayBuffer(file);
      };
      readExcelFile();
    }
  }, [predictionFile]);
  return (
    <>
      <div
        className="dash-drought-col-div"
        ref={scrollRef}
        id="crop-filter-container"
      >
        <Label className="region-label mt-0">{t("OTHER_TOOLS.COUNTRY")}</Label>
        <Select
          options={countryList}
          value={selectedLocation?.country}
          classNamePrefix="react-select"
          onChange={onCountrySelection}
          className="dash-select common-select-r"
          placeholder={t("OTHER_TOOLS.COUNTRY")}
          isDisabled={loading}
        />
        <Label className="region-label">
          {adminRadio === "basin"
            ? t("DASHBOARD.BASIN")
            : t("DASHBOARD.REGION")}
        </Label>
        <Select
          options={
            adminRadio === "basin" ? basinListOptions : regionListOptions
          }
          value={
            adminRadio === "basin"
              ? selectedLocation?.basin
              : selectedLocation?.state
          }
          classNamePrefix="react-select"
          onChange={(e) => {
            adminRadio === "basin"
              ? onSelectStates(e, basinListOptions, false, "basin")
              : onSelectStates(e, regionListOptions, false, "");
          }}
          className="dash-select common-select-r"
          placeholder={t(
            adminRadio === "basin"
              ? "DASHBOARD.SELECT_BASIN"
              : "DASHBOARD.SELECT_REGION",
          )}
          isDisabled={loading}
        />
        <Label className="region-label">{t("DASHBOARD.DISTRICT")}</Label>
        <Select
          classNamePrefix="react-select"
          options={districtsListOptions}
          value={selectedLocation?.district}
          onChange={(e) => {
            onSelectDistrict(e, districtsListOptions, "admin");
          }}
          className="dash-select common-select-r"
          placeholder={t("DASHBOARD.SELECT_DISTRICT")}
          isDisabled={loading}
        />
        <Label className="region-label">
          {t("CROP_YIELD.CROP_AND_SEASON")}{" "}
        </Label>
        <Select
          classNamePrefix="react-select"
          options={cropData?.cropList}
          value={selectedCrop?.crop}
          onChange={(e) => {
            setSelectedCrop((prev) => {
              return {
                ...prev,
                crop: e,
              };
            });
          }}
          className="dash-select common-select-r"
          placeholder={t("CROP_YIELD.CROP_AND_SEASON")}
          isDisabled={loading}
        />

        {/* Training data option selection */}
        <Label className="region-label">
          {t("CROP_YIELD.HAVE_PREDICTION_DATA")}
        </Label>
        <Select
          classNamePrefix="react-select"
          options={CROP_YEILD_TRAINING_OPTIONS}
          value={havePredictionData}
          onChange={(e) => {
            onHandleUploadPredictionData(e);
          }}
          className="dash-select common-select-r mb-3"
          isDisabled={loading}
        />

        {havePredictionData?.value === "Yes" && !predictionFile?.length ? (
          <Button
            className="w-100 add-row-btn"
            onClick={onHandleUploadTrainningData}
          >
            {" "}
            <UploadIcon className="ml-2" /> {t("CROP_YIELD.UPLOAD_FILES")}
          </Button>
        ) : null}
        {havePredictionData?.value === "Yes"
          ? renderFilesRemoveOfUpload(predictionFile, setPredictionFile)
          : ""}

        <Label className="region-label">
          {" "}
          {t("CROP_YIELD.PREDECTION_YEAR_RANGE")}{" "}
        </Label>
        <div className="crp-datepicker crop-predection-datepicker">
          <DatePicker
            selected={startYear ? new Date(new Date(startYear, 11, 31)) : ""}
            onChange={handleChange}
            dateFormat="yyyy"
            showYearPicker
            minDate={
              havePredictionData?.value === "Yes"
                ? trainingDataYear
                  ? new Date(new Date(trainingDataYear + 1, 11, 31))
                  : ""
                : // :
                  // startYear
                  // ? new Date(new Date(startYear, 11, 31))
                  new Date(2010, 0, 1)
            }
            // minDate={new Date(2010, 0, 1)}
            maxDate={
              havePredictionData?.value === "Yes"
                ? null
                : moment(endDateRainfall).subtract(1, "years").toDate()
            }
            placeholderText={t("CROP_YIELD.ENTER_START_DATE")}
            value={startYear}
            disabled={loading || havePredictionData?.value === "Yes"}
          />
        </div>
        <div className="mt-3 crp-datepicker crop-predection-datepicker">
          <DatePicker
            selected={endYear ? new Date(new Date(endYear, 11, 31)) : ""}
            onChange={(e) => setEndyear(e.getFullYear())}
            dateFormat="yyyy"
            showYearPicker
            minDate={
              (startYear && new Date(startYear + 1, 11, 31)) ||
              new Date(2011, 0, 1)
            }
            maxDate={new Date(endDateRainfall)}
            // maxDate={havePredictionData?.value === "Yes" ? null : endDateRainfall ? new Date(endDateRainfall) : ""}
            placeholderText={t("CROP_YIELD.ENTER_END_DATE")}
            value={endYear}
            disabled={loading}
          />
        </div>
        {endYear && startYear ? (
          <div className="region-label-crop-predection">
            <span className="region-label">
              {t("CROP_YIELD.MODAL_TRAINING_YEARS_CYP")} :{" "}
            </span>{" "}
            <span>
              {" "}
              {havePredictionData?.value === "Yes"
                ? trainingDataStartYear
                : 2005}{" "}
              - {startYear - 1}{" "}
            </span>
          </div>
        ) : (
          ""
        )}

        <Label className="region-label"> {t("CROP_YIELD.MODEL_FILTER")} </Label>
        <div className="modal-container-cy">
          {cyModels.map((each, index) => {
            return (
              <Row
                className={
                  index === cyModels.length - 1
                    ? "pt-2 pb-2 "
                    : "pt-2 pb-2 modal-item-border"
                }
                key={each?.modalName}
              >
                <Col sm={2} className="p-0 modals-text-alignment ">
                  <p> {each?.modalName} </p>
                </Col>
                <Col sm={8} className="p-0 modals-text-alignment-modal-text ">
                  <p className="modal-months-color"> {each?.RainFalltext} </p>
                  <p className="modal-months-color"> {each?.Ndvitext} </p>
                </Col>
                <Col sm={2} className="p-0 modals-text-alignment ">
                  {selectedModal?.Modalvalue === each?.Modalvalue ? (
                    <IoMdEye
                      onClick={() => setSelectedModal("")}
                      color="green"
                      className="pointer cy-eye-icons"
                    />
                  ) : (
                    <IoIosEyeOff
                      onClick={() => setSelectedModal(each)}
                      className="pointer"
                    />
                  )}
                </Col>
              </Row>
            );
          })}
        </div>
      </div>

      <div className="btns-fixed-sidebar ">
        <div data-tip data-for="disablebtn">
          {mapPreferencesData?.length === 2 ? (
            <CommonToolTip
              message={t("MAP_PREFERENCES.INDICATOR_MESSAGES")}
              disabled
            >
              <Button className="green-btn-lg-disable">
                {t("CROP_YIELD.CROP_PREDICT_BUTTON")}
              </Button>
            </CommonToolTip>
          ) : (
            <Button
              className="green-btn-lg"
              onClick={onHandlePredict}
              disabled={
                !predictState ||
                loading ||
                selectedLocation?.district === "" ||
                selectedLocation?.state === "" ||
                !(havePredictionData?.value === "No"
                  ? true
                  : predictionFile?.length)
              }
            >
              {t("CROP_YIELD.CROP_PREDICT_BUTTON")}
            </Button>
          )}

          {!loading ? (
            <p className="reset" onKeyUp={() => {}} onClick={onHandleReset}>
              {" "}
              {t("CROP_YIELD.RESET")}{" "}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default Filter;
