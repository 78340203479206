import React, { useState, useEffect, lazy, Suspense, useRef } from "react";
import moment from "moment";
// import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import _ from "lodash";
import SplitterLayout from "react-splitter-layout";
import { useTranslation } from "react-i18next";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import Loader from "../../components/Common/Loader";
import FloodGraph from "./FloodGraph";
import "react-splitter-layout/lib/index.css";
import {
  mapPreferenceRequest,
  getGeoglowsDrainageSuccess,
  getGeoglowsCatchmentSuccess,
  coreRequest,
  stateBoundaryRequest,
  countryBoundaryRequest,
  taluksBoundaryRequest,
  coreBasinRequest,
  indicatorSuccess,
} from "../../store/actions";
import {
  renderReactOptionsArraystate,
  renderBasinsArrayOptions,
} from "../../helpers";
import {
  GEOJSON_COUNTRY_DATA,
  SENTINAL_POLORIZATION,
  SENTINAL_ORBIT_POLORIZATION,
  SarRgb,
} from "../../constants";

// lazy loading components
const FloodMapContainer = lazy(() => import("./MapContainer"));
const DashboardFlood = lazy(() => import("./Fliter"));

function Flood() {
  const [, setOpen] = useOutletContext();
  const { t } = useTranslation();
  const [openPanel, setOpenPanel] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const splitterRef = useRef(null);
  const [aggregation, setAggregation] = useState("mean");
  const [Sum, setSum] = useState("sum");
  const [secondaggregation, setsecondAggregation] = useState("mean");
  const [layerControl, setLayerControl] = useState({
    firstLayer: {},
    secondLayer: {},
  });
  const [chartData, setChartData] = useState("");
  const [mapControl, setMapControl] = useState({});
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [mapes, setMapes] = useState();
  const [date, setDate] = useState("");
  const [seconddate, setsecondDate] = useState("");
  const [opaci, setOpaci] = useState();
  const [comparelegend, setcompareLegend] = useState([]);
  const [layerData, setLayerData] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [getGraphCall, setGraphCall] = useState(false);
  const [secondstartDate, setsecondStartDate] = useState("");
  const [secondendDate, setsecondEndDate] = useState("");
  const [indexLayers, setIndexLayers] = useState([]);
  const params = new URLSearchParams(window.location.search);
  const [legend, setLegend] = useState([]);
  const [indicatorList, setIndicatorList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const selectedModule = params.get("module");
  const module = params.get("subModule");
  const [local, setLocal] = useState(
    JSON.parse(localStorage.getItem("selectedCountry")),
  );
  const [selectedLocation, setSelectedLocation] = useState("");
  const [position, setPosition] = useState([local?.latitude, local?.longitude]);
  const [zooms, setZooms] = useState(local?.zoom_level);
  const [geoGlowsState, setGeoGlowsState] = useState("");
  const [glofasState, setGlofasState] = useState("");
  const [layersArray, setLayersArray] = useState([]);
  const [mapLoading, setMapLoading] = useState(false);
  const [errorData, setErrorData] = useState({ showError: false, message: "" });
  const [compareCountry, setCompareCountry] = useState();
  const [CollaspeMenu, setCollaspeMenu] = useState("");
  const [secondCollaspeMenu, setsecondCollaspeMenu] = useState("");
  const [geoglowsApply, setGeoglowsApply] = useState(false);
  const [specialStartdate, setStartEnddate] = useState("");
  const [mins, setMin] = useState("");
  const [maxs, setMax] = useState("");
  const [compare, setCompare] = useState(false);
  const [indicatorsData, setIndicatorsData] = useState({});
  const [compareIndicatorData, setCompareIndicatorData] = useState({});

  const [aggregationData, setAggregationData] = useState();
  const [compareAggregationData, setCompareAggregationData] = useState();
  const [graphStartDate, setGraphStartDate] = useState();
  const [graphEndDate, setGraphEndDate] = useState();

  const [secondspecialStartdate, setsecondStartEnddate] = useState("");
  const [secondmins, setsecondMin] = useState("");
  const [secondmaxs, setsecondMax] = useState("");
  const [compareRegionListOptions, setCompareRegionListOptions] = useState([]);
  const [compareStates, setCompareState] = useState("");

  const [regionListOptions, setRegionListOptions] = useState([]);
  const [states, setState] = useState("");
  const [basinListOptions, setBasinListOptions] = useState([]);
  const [basin, setBasin] = useState("");
  const [compareBasinListOptions, setCompareBasinListOptions] = useState([]);
  const [compareBasin, setCompareBasin] = useState("");
  const [adminRadio, setAdminRadio] = useState("admin");
  const [graphPanelHeight, setGraphPanelHeight] = useState(0);
  const [isHydrostaticClick, setIsHydrostaticClick] = useState("1");
  const [rgbDateRange, setRgbDateRange] = useState({
    summer_start_date: "",
    summer_end_date: "",
    monsoon_start_date: "",
    monsoon_end_date: "",
    winter_start_date: "",
    winter_end_date: "",
  });

  const [compareRgbDateRange, setCompareRgbDateRange] = useState({
    summer_start_date: "",
    summer_end_date: "",
    monsoon_start_date: "",
    monsoon_end_date: "",
    winter_start_date: "",
    winter_end_date: "",
  });

  const [polorization, setPolorization] = useState({});
  const [orbitProperties, setOrbitProperties] = useState({});

  const [comparePolorization, setComparePolorization] = useState({});
  const [compareOrbitProperties, setCompateOrbitProperties] = useState({});

  const dispatch = useDispatch();
  const nextProps = useSelector((state) => ({
    statesList: state.Core.corestateList,
    districtsList: state.Core.coredistrictList,
    countryList: state.Core.coreList,
    basinList: state.Core.coreBasinList,
    maps: state.Drought.floodList,
    floodTimeseriesData: state.Drought.floodTimeseriesData,
    indicator: state.Dashboard.indicatorList,
    secondindicator: state.Dashboard.indicatorList,
    mapPreferenceList: state.MapPreference,
    dashBoardMenuData: state.Dashboard.dashBoardMenuData,
  }));

  const selectedRegions = [
    {
      label: t("DASHBOARD.ALL_REGIONS"),
      value: "",
      latitude: JSON.parse(localStorage.getItem("selectedCountry"))?.latitude,
      longitude: JSON.parse(localStorage.getItem("selectedCountry"))?.longitude,
      zoom_level: JSON.parse(localStorage.getItem("selectedCountry"))
        ?.zoom_level,
    },
  ];
  const selectedBasin = [
    {
      label: t("DASHBOARD.SELECT_BASIN"),
      value: "",
      latitude: selectedLocation?.country?.latitude,
      longitude: selectedLocation?.country?.longitude,
      zoom_level: selectedLocation?.country?.zoom_level,
    },
  ];
  const selectedSubBasin = [
    {
      label: t("DASHBOARD.ALL_SUB_BASIN"),
      value: "",
      latitude: selectedLocation?.basin?.latitude,
      longitude: selectedLocation?.basin?.longitude,
      zoom_level: selectedLocation?.basin?.zoom_level,
    },
  ];

  // Re-setting location whenever admin/Basin selection changes
  useEffect(() => {
    const CountryData = selectedLocation?.country;
    if (CountryData) {
      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: CountryData,
          state: "",
          district: "",
          basin: "",
          subBasin: "",
        };
      });
    }
    setShowChart(false); // close chart if open
    setCompare(false);
  }, [adminRadio]);

  useEffect(() => {
    setShowChart(false);
    setOpen(true);
    dispatch(
      coreRequest({
        location_type: "country",
        menu_slug: module,
        sub_menu_slug: selectedModule,
      }),
    );
    return () => {
      dispatch(getGeoglowsDrainageSuccess({}));
      dispatch(getGeoglowsCatchmentSuccess({}));
      dispatch(indicatorSuccess({}));
      setLayersArray([]);
    };
  }, []);
  const selectedDistricts = [
    {
      label: t("DASHBOARD.ALL_DISTRICTS"),
      value: "",
      latitude: selectedLocation?.state?.latitude,
      longitude: selectedLocation?.state?.longitude,
      zoom_level: selectedLocation?.state?.zoom_level,
    },
  ];
  useEffect(() => {
    if (nextProps?.indicator) {
      const parameterArr = [];
      let count = 0;

      nextProps?.indicator?.map((item) => {
        const x = _.clone(item);
        x.indices.map((i) => {
          i.checked = false;
          i.paramIndex = count;
          count += 1;
          parameterArr.push(i);
          return null;
        });
        return null;
      });

      setIndicatorList(parameterArr);
    } else {
      setIndicatorList([]);
    }
  }, [nextProps.indicator]);
  // to get country list
  const isCountryList = useRef(true);
  useEffect(() => {
    if (isCountryList.current) {
      isCountryList.current = false;
      return;
    }
    if (nextProps?.countryList) {
      const countryarray = [];
      renderReactOptionsArraystate(
        nextProps?.countryList,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
        "code",
        false,
        null,
        null,
        null,
        null,
        null,
        null,
        "basin_feature_collection",
      )?.map((eachValue) => countryarray.push(eachValue));
      setCountryList(countryarray);
    }
  }, [nextProps?.countryList]);

  useEffect(() => {
    if (nextProps.maps) {
      setLayerData(nextProps.maps);
      setLegend([]);
      setcompareLegend([]);
    }
  }, [nextProps.maps]);

  const datas = local?.value;
  useEffect(() => {
    const CountryData = selectedLocation?.country;
    setLayersArray([]);
    if (localStorage.getItem("i18nextLng") && CountryData) {
      setLocal(JSON.parse(localStorage.getItem("selectedCountry")));
      dispatch(
        mapPreferenceRequest({
          country_id: CountryData?.value,
          module: selectedModule,
        }),
      );
      window.scrollTo(0, 0);
      setLocal(CountryData);
      setPosition([CountryData?.latitude, CountryData?.longitude]);
      setZooms(CountryData?.zoom_level);

      const selectedJsonCountry = GEOJSON_COUNTRY_DATA.find(
        (each) => each.selectedCountry === CountryData?.label,
      );
      if (selectedJsonCountry?.glofas?.station) {
        setIsHydrostaticClick(selectedJsonCountry?.glofas?.station);
      } else {
        setIsHydrostaticClick("");
      }

      dispatch(
        stateBoundaryRequest({
          data: {
            country_name: CountryData?.label,
            state_name: t("DASHBOARD.ALL_REGIONS"),
          },
          isState: true,
        }),
      );

      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: CountryData,
          state: "",
          district: "",
        };
      });
      setGeoGlowsState("");
      setGlofasState("");
      dispatch(
        coreBasinRequest({
          data: {
            country_id: selectedLocation?.country?.value,
          },
          isCompareLocation: false,
        }),
      );
    }
  }, [selectedLocation?.country?.label, localStorage.getItem("i18nextLng")]);
  const updatePage = (title, title2) => {
    setPosition(title);
    setZooms(title2);
  };
  const onHandleZoom = (center, zoom) => {
    setPosition(center);
    setZooms(zoom);
  };
  const handleChartDisplay = () => {
    setShowChart(!showChart);
  };
  const onHandleDrawshapeGraph = (chartData1) => {
    // dispatch(addDroughtSuccess({}));
    setChartData(chartData1);
  };
  // function to call the selected distric and state
  const handleSelectedLocation = (filterLocation) => {
    setSelectedLocation(filterLocation);
  };

  useEffect(() => {
    if (splitterRef && splitterRef.current) {
      if (showChart) {
        splitterRef.current.setState({ secondaryPaneSize: 60 });
      } else {
        splitterRef.current.setState({ secondaryPaneSize: 0 });
      }
    }
  }, [showChart]);

  useEffect(() => {
    if (!_.isEmpty(nextProps.indicator)) {
      setCollaspeMenu(nextProps.indicator?.[0]?.category);
      setsecondCollaspeMenu(nextProps.indicator?.[0]?.category);
    }
  }, [nextProps.indicator]);

  useEffect(() => {
    if (date !== "") {
      if (date?.indices_slug === SarRgb) {
        const startInitial = moment(date?.indices_end_date)
          .subtract(30, "days")
          .toDate();
        const endInitial = moment(date?.indices_end_date).toDate();

        setRgbDateRange((prev) => ({
          ...prev,
          summer_start_date: startInitial,
          summer_end_date: endInitial,
          monsoon_start_date: startInitial,
          monsoon_end_date: endInitial,
          winter_start_date: startInitial,
          winter_end_date: endInitial,
        }));
        // SENTINAL_POLORIZATION,
        // SENTINAL_ORBIT_POLORIZATION,
        setPolorization(SENTINAL_POLORIZATION[0]);
        setOrbitProperties(SENTINAL_ORBIT_POLORIZATION[0]);
      } else {
        setStartEnddate(date?.indices_start_date);
        setStartDate(
          moment(date?.indices_end_date).subtract(30, "days").toDate(),
        );
        setEndDate(moment(date?.indices_end_date).toDate());
      }
    } else {
      setMin("");
      setMax("");
      setStartDate("");
      setEndDate("");
    }
  }, [date]);

  useEffect(() => {
    if (seconddate !== "") {
      if (seconddate?.indices_slug === SarRgb) {
        const startInitial = moment(seconddate?.indices_end_date)
          .subtract(30, "days")
          .toDate();
        const endInitial = moment(seconddate?.indices_end_date).toDate();

        setCompareRgbDateRange((prev) => ({
          ...prev,
          summer_start_date: startInitial,
          summer_end_date: endInitial,
          monsoon_start_date: startInitial,
          monsoon_end_date: endInitial,
          winter_start_date: startInitial,
          winter_end_date: endInitial,
        }));

        setComparePolorization(SENTINAL_POLORIZATION[0]);
        setCompateOrbitProperties(SENTINAL_ORBIT_POLORIZATION[0]);
      } else {
        setsecondStartEnddate(seconddate?.indices_start_date);
        setsecondStartDate(
          moment(seconddate?.indices_end_date).subtract(30, "days").toDate(),
        );
        setsecondEndDate(moment(seconddate?.indices_end_date).toDate());
      }
    } else {
      setsecondMin("");
      setsecondMax("");
      setsecondStartDate("");
      setsecondEndDate("");
    }
  }, [seconddate]);

  useEffect(() => {
    if (nextProps?.statesList?.data) {
      renderReactOptionsArraystate(
        nextProps?.statesList?.data,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
      )?.map((eachValue) => selectedRegions.push(eachValue));
      if (!nextProps?.statesList?.isCompare) {
        setRegionListOptions(selectedRegions);
        setState(selectedRegions?.[0]);
      } else {
        setCompareRegionListOptions(selectedRegions);
        setCompareState(selectedRegions?.[0]);
      }
    }
  }, [nextProps?.statesList]);

  useEffect(() => {
    if (nextProps?.basinList?.data) {
      renderBasinsArrayOptions(
        nextProps?.basinList?.data,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
        "sub_basins",
      )?.map((eachValue) => selectedBasin.push(eachValue));
      // if (!nextProps?.basinList?.isCompare) {
      setBasinListOptions(selectedBasin);
      setBasin(selectedBasin?.[0]);
      setCompareBasinListOptions(selectedBasin);
      setCompareBasin(selectedBasin?.[0]);
      // } else {
      //   setCompareBasinListOptions(selectedBasin);
      //   setCompareBasin(selectedBasin?.[0]);
      // }
    }
  }, [nextProps?.basinList]);

  const onSelectionAdminCategory = (data, jsondata) => {
    if (data.length === jsondata.length && selectedLocation.country?.label) {
      if (data[data?.length - 1].name === "country") {
        dispatch(
          countryBoundaryRequest({
            data: {
              country_name: selectedLocation?.country?.label,
            },
          }),
        );
      }
      if (data[data?.length - 1].name === "regions") {
        dispatch(
          stateBoundaryRequest({
            data: {
              country_name: selectedLocation?.country?.label,
              state_name:
                selectedLocation?.state === ""
                  ? t("DASHBOARD.ALL_REGIONS")
                  : selectedLocation?.state?.label,
            },
            isState: true,
          }),
        );
      }
      if (data[data?.length - 1].name === "districts_or_cities") {
        dispatch(
          stateBoundaryRequest({
            data: {
              country_name: selectedLocation?.country?.label,
              state_name:
                selectedLocation?.state === ""
                  ? t("DASHBOARD.ALL_REGIONS")
                  : selectedLocation?.state?.label,
              district_name:
                selectedLocation?.district === ""
                  ? t("DASHBOARD.ALL_DISTRICTS")
                  : selectedLocation?.district?.label,
            },
            isState: false,
          }),
        );
      }
      if (data[data?.length - 1].name === "talukas") {
        dispatch(
          taluksBoundaryRequest({
            data: {
              country_name: selectedLocation?.country?.label,
              state_name:
                selectedLocation?.state === ""
                  ? t("DASHBOARD.ALL_REGIONS")
                  : selectedLocation?.state?.label,
              district_name:
                selectedLocation?.district === ""
                  ? t("DASHBOARD.ALL_DISTRICTS")
                  : selectedLocation?.district?.label,
              taluk_name: t("DASHBOARD.ALL_TALUKS"),
            },
          }),
        );
      }
    }
  };

  return (
    <div className="dashboard-filter-div">
      <Suspense fallback={<Loader isSuspense />}>
        <div className="dashboard-filter">
          <h3 className="dashboard-filter-h3">
            {t("WEATHER_FORCAST.MONITORING_AND_INDICATORS")}
          </h3>
        </div>
        <Row className="dashboard-drought-section">
          <Col
            lg={3}
            className={
              openPanel ? "dasboard-drought-expand-1" : "dashboard-drought-col1"
            }
          >
            <Button
              className={
                !openPanel ? "button-expand-panel" : "button-expand-panel-oepn"
              }
              onClick={() => setOpenPanel(!openPanel)}
              aria-controls="example-collapse-text"
              aria-expanded={openPanel}
            >
              {openPanel ? (
                <div className="panel-btn">
                  <span className="mr-2">Expand Bar</span>
                  <FaAngleRight className="right-icon" />
                </div>
              ) : (
                <div className="tooltip-div">
                  <span className="mr-2 tooltip-text">Hide Bar</span>
                  <FaAngleLeft className="right-icon right-icon-md float-right" />
                </div>
              )}
            </Button>
            <div className={openPanel ? "d-none" : "dashboard-drought-div"}>
              <DashboardFlood
                // statesList={nextProps.statesList}
                districtsList={nextProps.districtsList}
                indicator={nextProps.indicator}
                secondindicator={nextProps.secondindicator}
                maps={nextProps.maps}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                secondstartDate={secondstartDate}
                setsecondStartDate={setsecondStartDate}
                secondendDate={secondendDate}
                setsecondEndDate={setsecondEndDate}
                position={position}
                setPosition={setPosition}
                zooms={zooms}
                setZooms={setZooms}
                datas={datas}
                updatePage={updatePage}
                legend={legend}
                setLegend={setLegend}
                indexLayers={indexLayers}
                opaci={opaci}
                setIndexLayers={setIndexLayers}
                mapes={mapes}
                setMapes={setMapes}
                comparelegend={comparelegend}
                setcompareLegend={setcompareLegend}
                layerControl={layerControl}
                setLayerControl={setLayerControl}
                mapControl={mapControl}
                setMapControl={setMapControl}
                handleSelectedLocation={handleSelectedLocation}
                selectedLocation={selectedLocation}
                dashBoardMenuData={nextProps.dashBoardMenuData}
                date={date}
                setDate={setDate}
                seconddate={seconddate}
                setsecondDate={setsecondDate}
                mapPreferencesData={mapPreferencesData}
                selectedLocationPolygon={selectedLocation}
                selectedDistricts={selectedDistricts}
                geoGlowsState={geoGlowsState}
                setGeoGlowsState={setGeoGlowsState}
                setGlofasState={setGlofasState}
                setShowChart={setShowChart}
                onHandleDrawshapeGraph={onHandleDrawshapeGraph}
                aggregation={aggregation}
                secondaggregation={secondaggregation}
                setAggregation={setAggregation}
                setsecondAggregation={setsecondAggregation}
                Sum={Sum}
                setSum={setSum}
                setLayersArray={setLayersArray}
                layersArray={layersArray}
                setMapLoading={setMapLoading}
                mapLoading={mapLoading}
                countryList={countryList}
                errorData={errorData}
                setErrorData={setErrorData}
                setCompareCountry={setCompareCountry}
                compareCountry={compareCountry}
                CollaspeMenu={CollaspeMenu}
                setCollaspeMenu={setCollaspeMenu}
                secondCollaspeMenu={secondCollaspeMenu}
                setsecondCollaspeMenu={setsecondCollaspeMenu}
                setGeoglowsApply={setGeoglowsApply}
                geoglowsApply={geoglowsApply}
                specialStartdate={specialStartdate}
                mins={mins}
                setMin={setMin}
                maxs={maxs}
                setMax={setMax}
                setCompare={setCompare}
                compare={compare}
                setOpen={setOpen}
                setIndicatorsData={setIndicatorsData}
                setCompareIndicatorData={setCompareIndicatorData}
                setAggregationData={setAggregationData}
                setCompareAggregationData={setCompareAggregationData}
                setGraphStartDate={setGraphStartDate}
                setGraphEndDate={setGraphEndDate}
                secondspecialStartdate={secondspecialStartdate}
                setsecondStartEnddate={setsecondStartEnddate}
                secondmins={secondmins}
                setsecondMin={setsecondMin}
                secondmaxs={secondmaxs}
                setsecondMax={setsecondMax}
                regionListOptions={regionListOptions}
                states={states}
                setState={setState}
                compareRegionListOptions={compareRegionListOptions}
                compareStates={compareStates}
                setCompareState={setCompareState}
                selectedSubBasin={selectedSubBasin}
                basinsList={nextProps.basinList}
                basin={basin}
                setBasin={setBasin}
                basinListOptions={basinListOptions}
                compareBasin={compareBasin}
                compareBasinListOptions={compareBasinListOptions}
                selectedBasin={selectedBasin}
                setCompareBasin={setCompareBasin}
                setAdminRadio={setAdminRadio}
                adminRadio={adminRadio}
                setRgbDateRange={setRgbDateRange}
                rgbDateRange={rgbDateRange}
                compareRgbDateRange={compareRgbDateRange}
                setCompareRgbDateRange={setCompareRgbDateRange}
                setOrbitProperties={setOrbitProperties}
                orbitProperties={orbitProperties}
                setPolorization={setPolorization}
                polorization={polorization}
                setComparePolorization={setComparePolorization}
                comparePolorization={comparePolorization}
                compareOrbitProperties={compareOrbitProperties}
                setCompateOrbitProperties={setCompateOrbitProperties}
              />
            </div>
          </Col>
          <Col
            lg={openPanel ? 12 : 9}
            className={openPanel ? "px-0" : "px-0 dashboard-drought-map"}
          >
            <div
              className={
                showChart
                  ? "map-space-div visible-splitter"
                  : "map-space-div invisible-splitter"
              }
            >
              <SplitterLayout
                ref={splitterRef}
                primaryIndex={0}
                vertical
                percentage
                primaryMinSize={40}
                secondaryInitialSize={0}
                onSecondaryPaneSizeChange={(e) => setGraphPanelHeight(e)}
              >
                <div
                  className={
                    showChart
                      ? "dashboard-drought-col-graph-view"
                      : "dashboard-drought-col2"
                  }
                >
                  <FloodMapContainer
                    position={position}
                    handleClickGraph={setGraphCall}
                    getGraphCall={getGraphCall}
                    mapes={mapes}
                    showChart={showChart}
                    setMapes={setMapes}
                    maps={nextProps.maps}
                    secondindicator={nextProps.secondindicator}
                    startDate={startDate}
                    endDate={endDate}
                    date={date}
                    mapPreferencesData={mapPreferencesData}
                    setMapPreferencesData={setMapPreferencesData}
                    setDate={setDate}
                    seconddate={seconddate}
                    setsecondDate={setsecondDate}
                    mapControl={mapControl}
                    setMapControl={setMapControl}
                    layerControl={layerControl}
                    setLayerControl={setLayerControl}
                    secondstartDate={secondstartDate}
                    secondendDate={secondendDate}
                    comparelegend={comparelegend}
                    setcompareLegend={setcompareLegend}
                    indexLayers={indexLayers}
                    setIndexLayers={setIndexLayers}
                    setPosition={setPosition}
                    zooms={zooms}
                    setZooms={setZooms}
                    datas={datas}
                    onHandleZoom={onHandleZoom}
                    legend={legend}
                    setLegend={setLegend}
                    setOpaci={setOpaci}
                    layerData={layerData}
                    selectedLocation={selectedLocation}
                    local={local}
                    boundaryData={nextProps.mapPreferenceList.mapPreferenceList}
                    setSelectedLocation={setSelectedLocation}
                    geoGlowsState={geoGlowsState}
                    glofasState={glofasState}
                    indicatorList={indicatorList}
                    handleChartDisplay={handleChartDisplay}
                    layersArray={layersArray}
                    setLayersArray={setLayersArray}
                    mapLoading={mapLoading}
                    geoglowsApply={geoglowsApply}
                    setOpen={setOpen}
                    onSelectionAdminCategory={onSelectionAdminCategory}
                    setAdminRadio={setAdminRadio}
                    adminRadio={adminRadio}
                    setIsHydrostaticClick={setIsHydrostaticClick}
                    isHydrostaticClick={isHydrostaticClick}
                  />
                </div>
                <FloodGraph
                  indicatorsData={
                    indicatorsData?.indices_slug === "dryspell"
                      ? { ...indicatorsData, indices_name: "Daily Rainfall" }
                      : indicatorsData
                  }
                  compareIndicatorData={
                    compareIndicatorData?.indices_slug === "dryspell"
                      ? {
                          ...compareIndicatorData,
                          indices_name: "Daily Rainfall",
                        }
                      : compareIndicatorData
                  }
                  setShowChart={setShowChart}
                  showChart={showChart}
                  chartDatas={chartData}
                  aggregation={aggregationData}
                  secondaggregation={compareAggregationData}
                  startDate={graphStartDate}
                  endDate={graphEndDate}
                  selectedLocation={selectedLocation}
                  errorData={errorData}
                  setErrorData={setErrorData}
                  compare={compare}
                  setOpen={setOpen}
                  adminRadio={adminRadio}
                  graphHight={graphPanelHeight}
                />
              </SplitterLayout>
            </div>
          </Col>
        </Row>
      </Suspense>
    </div>
  );
}

export default React.memo(Flood);
