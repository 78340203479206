import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HighChartGraph from "../../components/Common/HighCharts";
import { HighChartConstants } from "../../constants";

function CropYieldGrap({
  graphPanelHeight,
  key,
  height12,
  graphData,
  open,
  openPanel,
  selectedLocation,
  activetab,
  modalperformance,
  trainingDataEndDateGraph,
}) {
  const { t } = useTranslation();
  const chartRef = useRef(null);

  const myDiv = document.getElementById(`tab-scroll-height-2`);
  const containerWidth = myDiv?.offsetWidth;

  const [chartWidth, setChartWidth] = useState();

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.reflow();
    }
  }, [graphPanelHeight]);

  useEffect(() => {
    if (graphData?.xaxis) {
      const myDiv1 = document.getElementById(`tab-scroll-height-2`);
      const containerWidth2 = myDiv1?.offsetWidth;

      const width =
        graphData?.xaxis?.length >= HighChartConstants?.fifteen
          ? graphData?.xaxis?.length * HighChartConstants?.sixtyFive
          : null;
      // const containerWidth1 = myDiv1?.offsetWidth;
      containerWidth2 > width
        ? setChartWidth(containerWidth2 - 30)
        : setChartWidth(width);
    }
  }, [graphData?.xaxis, open, openPanel, activetab]);

  const opt2 = {
    title: {
      text: `${t("CROP_YIELD.HISTORIC_YIELD_TITLE")}  - ${
        selectedLocation?.district?.value === "" ||
        selectedLocation?.district === ""
          ? `${selectedLocation?.state?.label}  ${t("DASHBOARD.REGION")} (${
              trainingDataEndDateGraph?.text === "Yes"
                ? trainingDataEndDateGraph?.value
                : t("CROP_YIELD.TWO_THOUSAND_FIVE")
            } - ${modalperformance?.trainingYears?.end_year}) `
          : `${selectedLocation?.district?.label} ${t(
              "DASHBOARD.DISTRICT",
            )}  (${
              trainingDataEndDateGraph?.text === "Yes"
                ? trainingDataEndDateGraph?.value
                : t("CROP_YIELD.TWO_THOUSAND_FIVE")
            } - ${modalperformance?.trainingYears?.end_year})`
      } `,

      style: {
        fontSize: "20px",
        fontWeight: "normal",
        fontFamily: "National",
      },
      align: "center",
    },
    chart: {
      type: "column",
      height: height12 - 10,
      width: chartWidth - 20,

      resetZoomButton: {
        position: {
          align: "left",
          verticalAlign: "top",
          x: 10,
          y: 10,
        },
      },
      zoomType: "x",
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
    },
    tooltip: {
      shared: false,
      useHTML: true,
      headerFormat: '<table><tr><th colspan="2">{point.x}</th></tr>',
      pointFormat:
        '<tr><td >{series.name} : </td><td style="text-align: right"><b>{point.y:.0f} </b></td></tr>',
      footerFormat: "</table>",
      valueDecimals: 2,
    },
    exporting: {
      enabled: true,
      filename: "cropYieldGraph",

      buttons: {
        contextButton: {
          align: "right",
          menuItems: [
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
          ],
        },
      },
    },
    xAxis: [
      {
        labels: {
          // rotation:
          //   graphData?.xaxis?.length < 10
          //     ? 0
          //     : selectedLocation?.district?.value
          //     ? 0
          //     : -45,

          style: {
            fontFamily: "National",
            color: "#435A52",
            fontSize: "13px",
          },
        },
        categories: graphData?.xaxis,
        crosshair: false,
        // gridLineWidth: 2,
        // gridLineColor: "#DDDDDD",
      },
    ],
    yAxis: {
      title: {
        text: t("CROP_YIELD.YIELD_GRAPH_UNITS"),
        style: {
          fontSize: "15px",
          fontWeight: "normal",
          fontFamily: "National",
        },
      },
      tickAmount: 4,
      labels: {
        style: {
          fontFamily: "National",
          color: "#435A52",
          fontSize: "13px",
        },
      },
      // lineColor: '#f0f0f0',
      // lineWidth: 1,
      // tickColor: '#435A52',
      // tickWidth: 0.5,
      // tickColor:"#435A52"
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          borderRadius: 2,
          allowOverlap: true,
          crop: true,
        },
        states: {
          inactive: {
            enabled: false,
          },
        },
      },

      column: {
        pointPadding: 0.1, // Adjust the point padding for small columns
        groupPadding: 0.1, // Adjust the group padding for small columns
        borderWidth: 0, // Remove border around columns
        color: "#80bf80", // Custom color for columns
        pointWidth: 25,
        grouping: true,
        dataLabels: {
          enabled: false, // Disable data labels for columns
        },
      },
    },
    series: [
      {
        name: "Actual",
        data: graphData?.yaxisActual,
        color: "#5088C6",
      },
      {
        name: "Prediction",
        data: graphData?.yaxisPredection,
        color: "#D87042",
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            plotOptions: {
              column: {
                pointPadding: 0.05, // Adjust the point padding for small screens
                groupPadding: 0.1, // Adjust the group padding for small screens
              },
            },
          },
          _id: "highcharts-8zah4ki-69",
        },
      ],
    },
  };

  // drought-footer-area chart-design chart-flow style={{ border: "4px solid red" }}
  return graphData?.xaxis?.length ? (
    <div
      key={key}
      className={
        containerWidth === chartWidth
          ? "crop-yield-graph chart-design chart-hidden"
          : "crop-yield-graph chart-design chart-flow"
      }
    >
      <HighChartGraph
        options={opt2}
        className="drought-graph"
        chartRef={chartRef}
      />
    </div>
  ) : (
    <div className="current-yield-nodata-found">
      <h4 className="error-data-current-yield">
        {" "}
        {t("CROP_YIELD.HISTROIC_YIELD_NO_DATA")}{" "}
      </h4>
    </div>
  );
}

export default CropYieldGrap;
