import React, { useState, useEffect } from "react";
import {
  Card,
  CardImg,
  CardSubtitle,
  CardTitle,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Cookies from "js-cookie";
import { BsFillRecordFill, BsCheckAll } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
import { AiFillCheckCircle, AiFillEye, AiFillDelete } from "react-icons/ai";
import { FcApproval, FcCancel } from "react-icons/fc";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import _ from "lodash";
import { HiOutlineXMark } from "react-icons/hi2";
import {
  getAllBulletinRequest,
  bulletinPublishRequest,
  bulletinDeleteRequest,
  bulletinPublishSuccess,
  bulletinDeleteSuccess,
  approveRejectBulletinRequest,
  previewBulletinRequest,
  previewBulletinSuccess,
} from "../../store/bulletin/bulletinActions";
import { userDetailsRequest } from "../../store/actions";
import AlertModal from "./AlertModal";
import { showError, showSuccess } from "../../components/Common/Notification";
import { DISPLAY_CHART_DATE, BULLETIN_TAB_CONSTS } from "../../constants/index";
import RightArrow from "../../assest/img/Left-arrow-icon.svg";
import GlobalLoader from "../../components/Common/Loader";
import NewDummyImage from "../../assest/img/Zambia_map.png";
import { isPermissionsMatch, superAdmin } from "../../components/Common/Utils";

function BackOffcieBulletin() {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const form = new FormData();
  const userId = JSON.parse(Cookies.get("user"))?.user_details?.[0]?.id;

  const [textAreaCount, setTextAreaCount] = useState(0);
  const [rejectionText, setRejectionText] = useState("");
  const [activeTab, setActiveTab] = useState(BULLETIN_TAB_CONSTS.PENDING);
  const [openModal, setOpenModal] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [dataList, setDataList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [bulletinStatus, setBulletinStatus] = useState(0);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [updateBulletin, setUpdateBulletin] = useState("");
  const [isPublishModal, setIsPublishModal] = useState(false);
  const [Buttonclicked, setButtonclicked] = useState(false);
  const [pageCount, setPageCount] = useState();

  const { Bulletin, isApproveRejectRequesting, userDetails } = useSelector(
    (state) => ({
      isApproveRejectRequesting: state.Bulletin.isApproveRejectRequesting,
      Bulletin: state.Bulletin,
      userDetails: state.User?.userDetails,
    }),
  );

  const bulletinStatusConstants = {
    Created: "Created",
    Pending: "0",
    Approved: "1",
    Rejected: "2",
  };

  useEffect(() => {
    setDataList([]);
    dispatch(userDetailsRequest({ id: userId }));
    return () => {
      setDataList([]);
      dispatch(bulletinPublishSuccess({}));
      dispatch(bulletinDeleteSuccess({}));
      dispatch(previewBulletinSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(Bulletin?.bulletinPublishData?.data?.data)) {
      setDataList([]);
      showSuccess(Bulletin?.bulletinPublishData?.data?.data?.result);
    }
    setIsPublishModal(false);
  }, [Bulletin.bulletinPublishData]);

  useEffect(() => {
    if (!_.isEmpty(Bulletin?.isBulletinDeleteData?.data?.data)) {
      setDataList([]);
      showSuccess(Bulletin?.isBulletinDeleteData?.data?.data?.result);
    }
    setIsDeleteModal(false);
  }, [Bulletin.isBulletinDeleteData]);

  useEffect(() => {
    const currentTab = window.location.pathname;
    if (history.action === "POP" && currentTab === ":currentTab") {
      history("/settings");
    } else if (currentTab) {
      switch (currentTab) {
        case BULLETIN_TAB_CONSTS.CREATED:
          setActiveTab(BULLETIN_TAB_CONSTS.CREATED);
          break;
        case BULLETIN_TAB_CONSTS.PENDING:
          setActiveTab(BULLETIN_TAB_CONSTS.PENDING);
          break;
        case BULLETIN_TAB_CONSTS.APPROVED:
          setActiveTab(BULLETIN_TAB_CONSTS.APPROVED);
          break;
        case BULLETIN_TAB_CONSTS.REJECTED:
          setActiveTab(BULLETIN_TAB_CONSTS.REJECTED);
          break;
        default:
      }
    }
  }, [window.location.pathname]);

  useEffect(() => {
    setDataList([]);
    if (_.isEmpty(dataList)) {
      setPageNumber(1);
      setDataList([]);
      setBulletinStatus(bulletinStatusConstants[activeTab]);
      dispatch(
        getAllBulletinRequest({
          page: 1,
          data: {
            bulletin_status: bulletinStatusConstants[activeTab],
            is_delete: "False",
          },
        }),
      );
    }
  }, [activeTab]);

  useEffect(() => {
    if (_.isEmpty(dataList)) {
      setPageNumber(1);
      setBulletinStatus(bulletinStatusConstants[activeTab]);
      dispatch(
        getAllBulletinRequest({
          page: 1,
          data: {
            bulletin_status: bulletinStatusConstants[activeTab],
            is_delete: "False",
          },
        }),
      );
    }
  }, [dataList]);

  useEffect(() => {
    if (Bulletin?.getAllBulletinData?.data) {
      let new_list = [];
      const {
        data: { result },
      } = Bulletin?.getAllBulletinData;
      if (result) {
        if (dataList?.length) {
          if (pageNumber > 1) {
            new_list = dataList?.concat(result?.results);
          } else {
            new_list = result?.results;
          }
        } else {
          new_list = result?.results;
        }
        setDataList(new_list);
        setPageCount(result?.count);
      } else {
        setDataList([]);
      }
    }
  }, [Bulletin.getAllBulletinData]);

  useEffect(() => {
    if (Bulletin?.getPreviewBulletinData?.data?.data?.result) {
      window.open(Bulletin?.getPreviewBulletinData?.data?.data?.result);
    }
  }, [Bulletin.getPreviewBulletinData]);

  useEffect(() => {
    if (!_.isEmpty(Bulletin?.isBulletinDeleteData?.data?.data)) {
      setDataList([]);
      showSuccess(Bulletin?.isBulletinDeleteData?.data?.data?.result);
    }
    setIsDeleteModal(false);
  }, [Bulletin.isBulletinDeleteData]);

  useEffect(() => {
    if (Bulletin?.approveRejectData && Buttonclicked) {
      showSuccess(Bulletin?.approveRejectData?.data?.data?.result);
    }
  }, [Bulletin.approveRejectData]);

  useEffect(() => {
    if (
      Bulletin?.approveRejectDataError?.data?.response?.data?.message &&
      Buttonclicked
    ) {
      showError(
        Bulletin?.approveRejectDataError?.data?.response?.data?.message,
      );
    }
  }, [Bulletin.approveRejectDataError]);

  const fetchNextTemplateData = () => {
    setPageNumber(pageNumber + 1);
    dispatch(
      getAllBulletinRequest({
        page: pageNumber + 1,
        data: { bulletin_status: bulletinStatus, is_delete: "False" },
      }),
    );
  };

  const handleBulletinPublish = (data, { is_publish }) => {
    dispatch(bulletinPublishRequest({ id: data?.id, is_publish }));
  };

  const handlePublishModal = (e, data) => {
    e?.stopPropagation();
    setIsPublishModal(!isPublishModal);
    if (!isPublishModal) setUpdateBulletin(data);
  };

  const handlePreview = (e, i) => {
    e?.stopPropagation();
    dispatch(previewBulletinRequest(i.id));
  };

  const handleDeleteModal = (e, data) => {
    e?.stopPropagation();
    setIsDeleteModal(!isDeleteModal);
    if (!isDeleteModal) setUpdateBulletin(data);
  };

  const handleDelete = (value) => {
    dispatch(bulletinDeleteRequest(value));
  };

  const handleModalSubmitReject = () => {
    form.append("id", deleteData.id);
    form.append("reject_response", rejectionText);
    form.append("bulletin_status", "2");
    dispatch(approveRejectBulletinRequest(form));
    setButtonclicked(true);
    setOpenModal(!openModal);
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleApprove = (e, i) => {
    e?.stopPropagation();
    form.append("id", i.id);
    form.append("bulletin_status", "1");
    dispatch(approveRejectBulletinRequest(form));
    setButtonclicked(true);
  };

  const handleRejectionText = (e) => {
    setRejectionText(e?.target?.value);
    setTextAreaCount(e?.target?.value?.length);
  };

  const handleReject = (e, i) => {
    e?.stopPropagation();
    setOpenModal(true);
    setDeleteData(i);
  };

  const toggle = (tab) => {
    history(`/back-office-bulletin/${tab}`);
    setActiveTab(tab);
  };

  return (
    <div className="bulletin-page">
      {isPublishModal && (
        <AlertModal
          modal={isPublishModal}
          isToggle={handlePublishModal}
          onClick={handleBulletinPublish}
          data={updateBulletin}
          onPublishClick
          onDeleteClick={false}
        />
      )}
      {isDeleteModal && (
        <AlertModal
          modal={isDeleteModal}
          isToggle={handleDeleteModal}
          onClick={handleDelete}
          data={updateBulletin}
          onDeleteClick
          onPublishClick={false}
        />
      )}
      <Modal isOpen={openModal} className="bullet-modal">
        <ModalHeader toggle={handleModal} className="reason-modal-header">
          <HiOutlineXMark
            size={25}
            className="cursor-ponter"
            onClick={handleModal}
            style={{ marginTop: "-20px" }}
          />
        </ModalHeader>
        <ModalBody>
          <div className="reject-char-count">
            <p>{t("BULLET_IN.REJECTION_REASON")}</p>
            <p>{textAreaCount}/500</p>
          </div>
          <textarea
            type="text"
            rows={5}
            placeholder={t("BULLET_IN.ENTER_SOMETHING_HERE")}
            className="approve-editor-input"
            maxLength={500}
            onChange={(e) => {
              handleRejectionText(e);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-block st-btn-custom mt-0 bulletin-sb"
            disabled={_.isEmpty(rejectionText?.replace(/\s/g, ""))}
            onClick={() => {
              handleModalSubmitReject();
            }}
            loading={isApproveRejectRequesting}
          >
            {t("BULLET_IN.SUBMIT")}
          </Button>
        </ModalFooter>
      </Modal>
      <div className="dash-main-row">
        <div className="dashboard-drought">
          <div className="dashboard-filter-div">
            <div className="dashboard-filter filter-container-2">
              <Row className="bulletin-top-text">
                <Col
                  xs={2}
                  lg={4}
                  className="title-div-text d-flex align-items-center m-0"
                >
                  <img
                    src={RightArrow}
                    className="pointer event-back-btn mr-1"
                    onClick={() => history("/settings")}
                    onKeyUp={() => {}}
                    alt="right-arrow"
                    width={36}
                    height={36}
                  />
                  <h3 className="dashboard-filter-h3 d-block">
                    {t("BULLET_IN.ALL_BULLETINS")}
                  </h3>
                </Col>
                {isPermissionsMatch(
                  userDetails?.user_permissions,
                  t("PERMISSIONS.ADD_BULLITEN"),
                ) || superAdmin() ? (
                  <Col xs={8} lg={6}>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="bulletin-cb"
                        onClick={() => history("/create-bulletin-pdf")}
                      >
                        {t("BULLET_IN.CREATE_BULLETIN_PDF")}
                      </Button>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </div>
          </div>

          <div className="content-section">
            <Row className="bulletin-tab">
              <Col xs={1}>
                <Button
                  className={
                    activeTab === BULLETIN_TAB_CONSTS.PENDING
                      ? " bulletin-sb"
                      : "  bulletin-cb"
                  }
                  onClick={() => {
                    toggle(BULLETIN_TAB_CONSTS.PENDING);
                  }}
                >
                  {t("BULLET_IN.PENDING")}
                </Button>
              </Col>
              <Col xs={1}>
                <Button
                  className={
                    activeTab === BULLETIN_TAB_CONSTS.APPROVED
                      ? " bulletin-sb"
                      : "  bulletin-cb"
                  }
                  onClick={() => {
                    toggle(BULLETIN_TAB_CONSTS.APPROVED);
                  }}
                >
                  {t("BULLET_IN.APPROVED")}
                </Button>
              </Col>
              <Col xs={1}>
                <Button
                  className={
                    activeTab === BULLETIN_TAB_CONSTS.REJECTED
                      ? " bulletin-sb"
                      : "  bulletin-cb"
                  }
                  onClick={() => {
                    toggle(BULLETIN_TAB_CONSTS.REJECTED);
                  }}
                >
                  {t("BULLET_IN.REJECTED")}
                </Button>
              </Col>
            </Row>
            <hr className="title-hr" />
            <div className="loader-div">
              {Bulletin.isGetAllBulletinRequesting && <GlobalLoader />}
            </div>
            {_.isEmpty(dataList) &&
              !Bulletin.isGetAllBulletinRequesting &&
              Bulletin.getAllBulletinData && (
                <h2 className="bulletin-template-modal-no-records-found">
                  {t("BULLET_IN.NO_RECORDS_FOUND")}
                </h2>
              )}
            <InfiniteScroll
              dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ""}
              next={fetchNextTemplateData}
              hasMore={pageNumber * 10 < pageCount}
              loader={
                Bulletin.isGetAllBulletinRequesting && (
                  <center>
                    <h4>{t("BULLET_IN.LOADING")} </h4>
                  </center>
                )
              }
              className="flex flex-wrap"
            >
              <div className="mt-3">
                <Row className="m-3">
                  {dataList?.length ? (
                    dataList?.map((i) => {
                      return (
                        <Col
                          className=" bulletin-template-modal-card-col"
                          lg={3}
                        >
                          <div className="position-relative">
                            <Card className="bulletin-modal-cards mt-3 ">
                              <CardImg
                                src={i?.content_file || NewDummyImage}
                                top
                                width="100%"
                                height="10%"
                                className="back-office-bulletin-card-Image"
                              />
                              <div className="m-3">
                                <CardTitle tag="h5">
                                  {i?.topic?.length
                                    ? i?.topic?.length > 20
                                      ? `${i?.topic?.substring(0, 20)}...`
                                      : i?.topic
                                    : ""}
                                </CardTitle>
                                <CardSubtitle
                                  className="mb-2 text-muted"
                                  tag="h6"
                                >
                                  {moment(i?.updated_date).format(
                                    DISPLAY_CHART_DATE,
                                  )}
                                </CardSubtitle>
                                {!i?.is_publish ? (
                                  activeTab !== BULLETIN_TAB_CONSTS.REJECTED ? (
                                    activeTab ===
                                    BULLETIN_TAB_CONSTS.APPROVED ? (
                                      <div className="back-office-bulletin-In-progress">
                                        <BsFillRecordFill />
                                        <span>{t("BULLET_IN.APPROVED")}</span>
                                      </div>
                                    ) : (
                                      <div className="back-office-bulletin-In-progress">
                                        <BsFillRecordFill />
                                        <span>
                                          {t("BULLET_IN.IN_PROGRESS")}
                                        </span>
                                      </div>
                                    )
                                  ) : (
                                    <div className="back-office-bulletin-In-progress">
                                      <span>{t("BULLET_IN.REJECTED")}</span>
                                    </div>
                                  )
                                ) : activeTab !==
                                  BULLETIN_TAB_CONSTS.REJECTED ? (
                                  <div className="back-office-bulletin-Publish">
                                    <BsCheckAll />
                                    <span>{t("BULLET_IN.PUBLISHED")}</span>
                                  </div>
                                ) : (
                                  <div className="back-office-bulletin-In-progress">
                                    <span>{t("BULLET_IN.REJECTED")}</span>
                                  </div>
                                )}
                              </div>{" "}
                              <div className="icon-div1">
                                <AiFillEye
                                  className="fa fa-eye bulletin-delete-btn"
                                  aria-hidden="true"
                                  onClick={(e) => {
                                    handlePreview(e, i);
                                  }}
                                  id="eye-bulletin"
                                  data-tip
                                  data-for="eye-bulletin"
                                />
                                {(isPermissionsMatch(
                                  userDetails?.user_permissions,
                                  t("PERMISSIONS.APPROVE_REJECT_BULLITEN"),
                                ) ||
                                  superAdmin()) &&
                                activeTab === BULLETIN_TAB_CONSTS.PENDING ? (
                                  <FcApproval
                                    className="fa fa-check bulletin-delete-btn"
                                    aria-hidden="true"
                                    onClick={(e) => {
                                      handleApprove(e, i);
                                    }}
                                    // loading={Bulletin.isApproveRejectRequesting && approveButtonLoader}
                                    id="approve-bulletin"
                                    data-tip
                                    data-for="approve-bulletin"
                                  />
                                ) : (
                                  ""
                                )}
                                {(isPermissionsMatch(
                                  userDetails?.user_permissions,
                                  t("PERMISSIONS.APPROVE_REJECT_BULLITEN"),
                                ) ||
                                  superAdmin()) &&
                                activeTab !== BULLETIN_TAB_CONSTS.REJECTED ? (
                                  <FcCancel
                                    className="fa fa-ban bulletin-delete-btn"
                                    aria-hidden="true"
                                    onClick={(e) => {
                                      handleReject(e, i);
                                    }}
                                    id="ban-bulletin"
                                    data-tip
                                    data-for="ban-bulletin"
                                  />
                                ) : (
                                  ""
                                )}
                                {((activeTab === BULLETIN_TAB_CONSTS.PENDING ||
                                  BULLETIN_TAB_CONSTS.REJECTED) &&
                                  isPermissionsMatch(
                                    userDetails?.user_permissions,
                                    t("PERMISSIONS.DELETE_BULLITEN"),
                                  )) ||
                                superAdmin() ? (
                                  <AiFillDelete
                                    className="fa fa-trash bulletin-delete-btn"
                                    aria-hidden="true"
                                    onClick={(e) => {
                                      handleDeleteModal(e, i);
                                    }}
                                    id="delete-bulletin"
                                    data-tip
                                    data-for="delete-bulletin"
                                  />
                                ) : (
                                  ""
                                )}
                                {(isPermissionsMatch(
                                  userDetails?.user_permissions,
                                  t("PERMISSIONS.PUBLISH_BULLITEN"),
                                ) ||
                                  superAdmin()) &&
                                activeTab === BULLETIN_TAB_CONSTS.APPROVED &&
                                !i?.is_publish ? (
                                  <AiFillCheckCircle
                                    id="publish_bulletin"
                                    className="bulletin-delete-btn"
                                    data-tip
                                    data-for="publish_bulletin"
                                    // size={23}
                                    onClick={(e) => {
                                      handlePublishModal(e, i);
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <ReactTooltip
                                id="publish_bulletin"
                                effect="solid"
                                place="top"
                                className="tooltip-comming-soon"
                                arrowColor="#22ad7a"
                              >
                                {t("BULLET_IN.CLICK_TO_PUBLISH")}
                              </ReactTooltip>
                              <ReactTooltip
                                id="delete-bulletin"
                                effect="solid"
                                place="top"
                                className="tooltip-comming-soon"
                                arrowColor="#22ad7a"
                              >
                                {t("BULLET_IN.CLICK_TO_DELETE")}
                              </ReactTooltip>
                              <ReactTooltip
                                id="ban-bulletin"
                                effect="solid"
                                place="top"
                                className="tooltip-comming-soon"
                                arrowColor="#22ad7a"
                              >
                                {t("BULLET_IN.CLICK_TO_REJECT")}
                              </ReactTooltip>
                              <ReactTooltip
                                id="eye-bulletin"
                                effect="solid"
                                place="top"
                                className="tooltip-comming-soon"
                                arrowColor="#22ad7a"
                              >
                                {t("BULLET_IN.CLICK_TO_PREVIEW")}
                              </ReactTooltip>
                              <ReactTooltip
                                id="approve-bulletin"
                                effect="solid"
                                place="top"
                                className="tooltip-comming-soon"
                                arrowColor="#22ad7a"
                              >
                                {t("BULLET_IN.CLICK_TO_APPROVE")}
                              </ReactTooltip>
                            </Card>
                          </div>
                        </Col>
                      );
                    })
                  ) : (
                    <div />
                  )}
                </Row>
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BackOffcieBulletin;
