import i18n from "../../i18n";
import EARLY_WARNING_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};
const Drought = (state, action) => {
  const { t } = i18n;
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case EARLY_WARNING_CONST.ADD_DROUGHT_REQUEST:
      state = {
        ...state,
        droughtListLoading: true,
        droughtList: null,
        error: "",
      };
      break;
    case EARLY_WARNING_CONST.ADD_DROUGHT_SUCCESS:
      state = {
        ...state,
        droughtListLoading: false,
        droughtList:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case EARLY_WARNING_CONST.ADD_DROUGHT_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        droughtListLoading: false,
        droughtList: null,
      };
      break;
    case EARLY_WARNING_CONST.GET_DROUGHT_TIMESERIES_REQUEST:
      state = {
        ...state,
        droughtTimeSeriesloading: true,
        droughtTimeseriesData: null,
        error: "",
      };
      break;
    case EARLY_WARNING_CONST.GET_DROUGHT_TIMESERIES_SUCCESS:
      state = {
        ...state,
        droughtTimeSeriesloading: false,
        droughtTimeseriesData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case EARLY_WARNING_CONST.GET_DROUGHT_TIMESERIES_ERROR:
      state = {
        ...state,
        error: action.payload.data
          ? action.payload.data
          : `${t("GEOGLOWS.ERROR_MESSAGE")}`,
        droughtTimeSeriesloading: false,
        droughtTimeseriesData: null,
      };
      break;
    case EARLY_WARNING_CONST.ADD_FLOOD_REQUEST:
      state = {
        ...state,
        floodVisualizationLoading: true,
        floodList: null,
        error: "",
        floodListError: "",
        dashboardSlug: "",
      };
      break;
    case EARLY_WARNING_CONST.ADD_FLOOD_SUCCESS:
      state = {
        ...state,
        floodVisualizationLoading: false,
        floodList:
          action.payload.statusCode === 200 ? action.payload.data : false,
        dashboardSlug:
          action.payload.statusCode === 200 ? action.payload.slug : false,
        error: "",
        floodListError: "",
      };
      break;
    case EARLY_WARNING_CONST.ADD_FLOOD_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data : "",
        floodVisualizationLoading: false,
        floodList: null,
        floodListError: action.payload.data ? action.payload.data : "",
        dashboardSlug: "",
      };
      break;
    case EARLY_WARNING_CONST.GET_FLOOD_TIMESERIES_REQUEST:
      state = {
        ...state,
        floodTimeSeriesloading: true,
        floodTimeseriesData: null,
        error: "",
      };
      break;
    case EARLY_WARNING_CONST.GET_FLOOD_TIMESERIES_SUCCESS:
      state = {
        ...state,
        floodTimeSeriesloading: false,
        floodTimeseriesData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case EARLY_WARNING_CONST.GET_FLOOD_TIMESERIES_ERROR:
      state = {
        ...state,
        error: action.payload.data
          ? action.payload.data
          : `${t("GEOGLOWS.ERROR_MESSAGE")}`,
        floodTimeSeriesloading: false,
        floodTimeseriesData: null,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};
export default Drought;
