import { Button } from "reactstrap";
import { BsPlus, BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Header({ setIsUploadModal }) {
  const { t } = useTranslation();
  const history = useNavigate();
  return (
    <div className="DU-Header ea-space-between">
      <div className="back-office-center">
        <BsArrowLeft
          className="back-icon-ea"
          role="button"
          onClick={() => history("/climber")}
        />
        <p className="ea-header-text">
          {" "}
          {t("DOCUMENT_UPLOAD.AWARE_KNOWLEDGE_PRODUCT")}{" "}
        </p>
      </div>

      <div className="d-flex">
        <div>
          <Button
            className="ea-create-btn ml-3"
            onClick={() => setIsUploadModal(true)}
          >
            {" "}
            {t("DOCUMENT_UPLOAD.UPLOAD_FILE")} <BsPlus className="add-icon" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Header;
