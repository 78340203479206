import React, { useRef, useEffect, useState } from "react";
import { Table } from "reactstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { thousandSepartor, monthsData } from "../../helpers";

function PopulationDisplacementTable({
  data,
  // tabIndex,
  allFieldsData,
  colSpan,
  initialSelectedDate,
  // EWRawDataDates,
  availableDataInYears,
  defaultCountry,
}) {
  const { t } = useTranslation();
  const wrapRef1 = useRef(null);
  const wrapRef2 = useRef(null);
  const ref = useRef(null);
  const [availableYears, setAvailableYears] = useState([]);

  const handleScroll = (e) => {
    const targetDiv = e.target;

    if (targetDiv === wrapRef1.current && wrapRef2.current) {
      wrapRef2.current.scrollLeft = targetDiv.scrollLeft;
    } else if (targetDiv === wrapRef2.current && wrapRef1.current) {
      wrapRef1.current.scrollLeft = targetDiv.scrollLeft;
    }
  };

  useEffect(() => {
    if (availableDataInYears && availableDataInYears?.length) {
      setAvailableYears(availableDataInYears);
    }
  }, [availableDataInYears]);

  const MaxLen = data.tableData
    .map((val) => {
      return {
        ...val,
        len: val.data.length,
      };
    })
    .map((val) => val.len)
    .reduce((a, b) => Math.max(a, b), 0);

  useEffect(() => {
    const width = ref.current ? ref.current.offsetWidth : 0;
    const divTwo = document.getElementById("secondDiv");
    divTwo.style.width = `${width}px`;
  }, [ref.current, data?.tableData]);

  // useEffect(() => {
  //   if (data?.tableData?.length) {
  //     const years = data.tableData.map((obj) => obj.data[0].year);
  //     if (years?.length) {
  //       setAvailableYears(years);
  //     }
  //   }
  // }, [data?.tableData]);

  return (
    <div className="health-table-div">
      <div
        ref={wrapRef1}
        className="wrapper1"
        onScroll={(e) => handleScroll(e)}
      >
        <div id="secondDiv" className="div1" />
      </div>
      <div
        className="health-table-scroll"
        ref={wrapRef2}
        onScroll={(e) => handleScroll(e)}
      >
        <Table className="health-table">
          <thead className="thead-health-main" ref={ref}>
            <tr>
              {/* <th>{t("HEALTH.REGION")}</th> */}
              <th className="border-right border-white">
                {["undefined", "null"].includes(data.tableData[0]?.state_name)
                  ? t("UPLOAD_PAST_DATA.COUNTRY")
                  : t("HEALTH.REGION")}
              </th>
              {allFieldsData.isShowAllYears
                ? availableYears.map((item) => {
                    return (
                      <th
                        colSpan={colSpan}
                        key={item}
                        className="border-right border-white"
                      >
                        {moment(new Date(`${item}`)).format("YYYY")}
                      </th>
                    );
                  })
                : monthsData.map((month) => {
                    let item = false;
                    if (data?.tableData.length) {
                      item = data?.tableData
                        .find((val) => val.data.length === MaxLen)
                        .data?.find((val2) => val2.month === month);
                    }
                    if (item) {
                      return (
                        <th
                          colSpan={colSpan}
                          key={month}
                          className="border-right border-white"
                        >
                          {moment(
                            new Date(
                              `${
                                initialSelectedDate?.startDate?.start_year
                                  ? initialSelectedDate?.startDate?.start_year
                                  : initialSelectedDate?.startDate?.value
                              }/${month}`,
                            ),
                          ).format("YYYY-MMM")}
                        </th>
                      );
                    } else {
                      return false;
                    }
                  })}
            </tr>
          </thead>
          <tbody>
            {data.tableData.map((element) => {
              return (
                <tr key={element}>
                  <td className="border-right border-white">
                    {["undefined", "null"].includes(element.state_name)
                      ? // ? t("DASHBOARD.ALL_REGIONS")
                        defaultCountry?.country?.label
                      : element.state_name}
                  </td>
                  {allFieldsData.isShowAllYears
                    ? availableYears.map((year) => {
                        const item = element.data.find(
                          (value) => Number(value.year) === year,
                        );
                        if (item) {
                          return (
                            <td
                              style={{
                                backgroundColor:
                                  item?.internal_displacement_thresold || "",
                                textAlign: "center",
                              }}
                              colSpan={colSpan}
                              className="border-right border-white"
                            >
                              {thousandSepartor(item.internal_displacement)}
                            </td>
                          );
                        } else {
                          return <td className="text-center">-</td>;
                        }
                      })
                    : monthsData
                        .slice(
                          new Date(
                            allFieldsData?.dates?.length
                              ? allFieldsData?.dates[0]
                              : 0,
                          ).getMonth(),
                          new Date(
                            allFieldsData?.dates?.length
                              ? allFieldsData?.dates[1]
                              : 0,
                          ).getMonth() + 1,
                        )
                        .map((month) => {
                          const item = element.data.find(
                            (value) => value.month === month,
                          );

                          if (item) {
                            return (
                              <td
                                style={{
                                  backgroundColor:
                                    item?.internal_displacement_thresold || "",
                                  textAlign: "center",
                                }}
                                className="border-right border-white"
                              >
                                {thousandSepartor(item.internal_displacement)}
                              </td>
                            );
                          } else {
                            return false;
                          }
                        })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default React.memo(PopulationDisplacementTable);
