import OTHER_TOOLS_CONST from "./actionTypes";

export const otherToolsRequest = (payload) => {
  return {
    type: OTHER_TOOLS_CONST.OTHER_TOOLS_REQUEST,
    payload,
  };
};
export const otherToolsSuccess = (user) => {
  return {
    type: OTHER_TOOLS_CONST.OTHER_TOOLS_SUCCESS,
    payload: user,
  };
};
export const otherToolsError = (error) => {
  return {
    type: OTHER_TOOLS_CONST.OTHER_TOOLS_ERROR,
    payload: error,
  };
};

export const focusAreasRequest = () => {
  return {
    type: OTHER_TOOLS_CONST.FOCUS_AREAS_REQUEST,
    payload: {},
  };
};
export const focusAreasSuccess = (user) => {
  return {
    type: OTHER_TOOLS_CONST.FOCUS_AREAS_SUCCESS,
    payload: user,
  };
};
export const focusAreasError = (error) => {
  return {
    type: OTHER_TOOLS_CONST.FOCUS_AREAS_ERROR,
    payload: error,
  };
};
