const PERMISSIONS_CONST = {
  GET_ALL_PERMISSIONS_REQUEST: "GET_ALL_PERMISSIONS_REQUEST",
  GET_ALL_PERMISSIONS_SUCCESS: "GET_ALL_PERMISSIONS_SUCCESS",
  GET_ALL_PERMISSIONS_ERROR: "GET_ALL_PERMISSIONS_ERROR",

  // assigns user
  ASSIGN_USER_REQUEST: "ASSIGN_USER_REQUEST",
  ASSIGN_USER_SUCCESS: "ASSIGN_USER_SUCCESS",
  ASSIGN_USER_ERROR: "ASSIGN_USER_ERROR",
};

export default PERMISSIONS_CONST;
