import React, { useRef, useEffect, useState } from "react";
import { Table } from "reactstrap";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../helpers";

function MarketTable({
  data,
  // tabIndex,
  allFieldsData,
  colSpan,
  // initialSelectedDate,
  priceCurrency,
  // EWRawDataDates,
  setMaxTableData,
  maxTableData,
  selectedPrice,
  // setMonthArrayList,
  monthArrayList,
}) {
  const { t } = useTranslation();
  const wrapRef1 = useRef(null);
  const wrapRef2 = useRef(null);
  const ref = useRef(null);
  const [priceType, setPriceType] = useState({ local: true, nonLocal: false });

  useEffect(() => {
    if (selectedPrice === "Local") {
      setPriceType({
        local: true,
        nonLocal: false,
      });
    } else if (selectedPrice === "NonLocal") {
      setPriceType({
        local: false,
        nonLocal: true,
      });
    } else {
      setPriceType({
        local: true,
        nonLocal: true,
      });
    }
  }, [selectedPrice]);

  const handleScroll = (e) => {
    const targetDiv = e.target;

    if (targetDiv === wrapRef1.current && wrapRef2.current) {
      wrapRef2.current.scrollLeft = targetDiv.scrollLeft;
    } else if (targetDiv === wrapRef2.current && wrapRef1.current) {
      wrapRef1.current.scrollLeft = targetDiv.scrollLeft;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const MaxLen = data.tableData
    .map((val) => {
      return {
        ...val,
        len: val.data.length,
      };
    })
    .map((val) => val.len)
    .reduce((a, b) => Math.max(a, b), 0);

  // * Getting maximum table data for setting table Headers
  useEffect(() => {
    if (data?.tableData?.length) {
      const maxObj = data?.tableData.reduce((prev, curr) =>
        prev.data.length > curr.data.length ? prev : curr,
      );
      if (maxObj.data?.length) {
        setMaxTableData(maxObj);
      }
    }
  }, [data?.tableData]);

  useEffect(() => {
    const width = ref.current ? ref.current.offsetWidth : 0;
    const divTwo = document.getElementById("secondDiv");
    divTwo.style.width = `${width}px`;
  }, [ref.current, data?.tableData]);

  return (
    <div className="health-table-div">
      <div
        ref={wrapRef1}
        className="wrapper1"
        onScroll={(e) => handleScroll(e)}
      >
        <div id="secondDiv" className="div1" />
      </div>
      <div
        className="health-table-scroll"
        ref={wrapRef2}
        onScroll={(e) => handleScroll(e)}
      >
        <Table className="health-table">
          <thead className="thead-health-main" ref={ref}>
            <tr>
              <th className="border-right border-white">-</th>
              {allFieldsData.isShowAllYears
                ? !_.isEmpty(maxTableData) &&
                  maxTableData?.data?.map((item) => {
                    return (
                      <th
                        colSpan={
                          priceType.local && priceType.nonLocal ? colSpan : 1
                        }
                        key={item.year}
                        className="border-right border-white"
                      >
                        {moment(new Date(`${item.year}`)).format("YYYY")}
                      </th>
                    );
                  })
                : monthArrayList?.length
                ? monthArrayList?.map((month) => {
                    return (
                      <th
                        colSpan={
                          priceType.local && priceType.nonLocal ? colSpan : 1
                        }
                        key={month}
                        className="border-right border-white"
                      >
                        {moment(month, "M-YYYY").format("YYYY-MMM")}
                        {/* {moment(new Date(`${month}`)).format("YYYY-MMM")}{" "} */}
                      </th>
                    );
                  })
                : ""}
            </tr>
          </thead>
          <tbody>
            <tr className="health-tr">
              <th className="border-right border-white">
                {t("MARKET.PRICE")}{" "}
                <hr style={{ marginTop: "5px", marginBottom: "5px" }} />{" "}
                {t("HEALTH.REGION")}
              </th>
              {allFieldsData.isShowAllYears
                ? !_.isEmpty(maxTableData) &&
                  maxTableData?.data?.map(() => {
                    return (
                      <>
                        {priceType.local ? (
                          <th className="text-center border-right border-white">
                            {priceCurrency}
                          </th>
                        ) : (
                          ""
                        )}
                        {priceType.nonLocal ? (
                          <th className="text-center border-right border-white">
                            {t("MARKET.USD")}
                          </th>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })
                : monthArrayList.map((item) => {
                    if (item) {
                      return (
                        <>
                          {priceType.local ? (
                            <th
                              key={item}
                              className="text-center border border-white"
                            >
                              {priceCurrency}
                            </th>
                          ) : (
                            ""
                          )}
                          {priceType.nonLocal ? (
                            <th className="text-center border border-white">
                              {t("MARKET.USD")}
                            </th>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    } else {
                      return false;
                    }
                  })}
            </tr>

            {data.tableData.map((element) => {
              return (
                <tr key={element}>
                  <td className="border border-white">{element.state_name}</td>
                  {allFieldsData.isShowAllYears
                    ? data.avilableDates.data.map((item) => {
                        const selectedItem = element.data.find(
                          (v) => Number(v.year) === Number(item.value),
                        );

                        if (selectedItem) {
                          return (
                            <>
                              {priceType.local ? (
                                <td
                                  style={{
                                    backgroundColor: item?.price_thresold || "",
                                  }}
                                  className="text-center border border-white"
                                >
                                  {formatNumber(selectedItem.price)}
                                </td>
                              ) : (
                                ""
                              )}
                              {priceType.nonLocal ? (
                                <td
                                  style={{
                                    backgroundColor:
                                      item?.usd_price_thresold || "",
                                  }}
                                  className="text-center border border-white"
                                >
                                  {formatNumber(selectedItem.usd_price)}
                                </td>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        } else {
                          return (
                            <>
                              {priceType.local ? (
                                <td className="text-center  border border-white">
                                  -
                                </td>
                              ) : (
                                ""
                              )}
                              {priceType.nonLocal ? (
                                <td className="text-center  border border-white">
                                  -
                                </td>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }
                      })
                    : monthArrayList.map((eachMonth) => {
                        const item = element?.data?.find(
                          (each1) =>
                            `${each1?.month}-${each1?.year}` === eachMonth,
                        );

                        if (item) {
                          return (
                            <>
                              {priceType.local ? (
                                <td
                                  style={{
                                    backgroundColor: item?.price_thresold || "",
                                  }}
                                  className="text-center border border-white"
                                >
                                  {formatNumber(item.price)}
                                </td>
                              ) : (
                                ""
                              )}
                              {priceType.nonLocal ? (
                                <td
                                  style={{
                                    backgroundColor:
                                      item?.usd_price_thresold || "",
                                  }}
                                  className="text-center border border-white"
                                >
                                  {formatNumber(item.usd_price)}
                                </td>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        } else {
                          return (
                            <>
                              {priceType.local ? (
                                <td className="text-center border border-white">
                                  -
                                </td>
                              ) : (
                                ""
                              )}
                              {priceType.nonLocal ? (
                                <td className="text-center border border-white">
                                  -
                                </td>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }
                      })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default React.memo(MarketTable);
