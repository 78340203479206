/* eslint-disable no-plusplus */
import axios from "axios";
import Cookies from "js-cookie";
import { startLoader, stopLoader } from "../loader/actions";
import store from "../index";

export const GEO_GLOWS_BASE_URL = "https://geoglows.ecmwf.int/api/";

export const BASE_URL =
  window.location.hostname === "aware.innomick.com"
    ? `${process.env.REACT_APP_DOMAIN_API_URL}`
    : `${process.env.REACT_APP_API_URL}`;

// export const ngRoxUrl = `https://aware.innomick.com:8443/geoglows_service/geo_glows`;

export const ngRoxUrl =
  window.location.hostname === "aware.innomick.com"
    ? `https://aware.innomick.com:8443/geoglows_service/geo_glows`
    : `https://awaredemo.iwmi.org:8443/geoglows_service/geo_glows`;

export const GLOFAS_BASE_URL = "https://flood-api.open-meteo.com/v1/";

// creating global instance for the axios to call apis
export const AXIOS_INSTANCE = axios.create({});

export const AXIOS_INSTANCE_LOADER = axios.create({});

AXIOS_INSTANCE_LOADER.defaults.headers["Content-Type"] = "application/json";

AXIOS_INSTANCE.defaults.headers["Content-Type"] = "application/json";

AXIOS_INSTANCE_LOADER.interceptors.request.use(function startLoaderRequest(
  config,
) {
  if (Cookies.get("user")) {
    const userToken = JSON.parse(Cookies.get("user"))?.result?.token;
    AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  }

  return config;
});

// Add a request interceptor
AXIOS_INSTANCE.interceptors.request.use(
  function startLoaderRequest(config) {
    if (Cookies.get("user")) {
      const userToken = JSON.parse(Cookies.get("user"))?.result?.token;
      AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${userToken}`;
    }
    store.dispatch(startLoader());

    return config;
  },
  function startLoaderError(error) {
    store.dispatch(stopLoader());
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
AXIOS_INSTANCE.interceptors.response.use(
  function startLoaderResponseSuccess(response) {
    store.dispatch(stopLoader());
    return response;
  },
  function startLoaderResponseError(error) {
    store.dispatch(stopLoader());
    return Promise.reject(error);
  },
);

// let activeRequests = 0; // Counter for active API requests

// // Add a request interceptor
// AXIOS_INSTANCE.interceptors.request.use(
//   function startLoaderRequest(config) {
//     if (Cookies.get("user")) {
//       const userToken = JSON.parse(Cookies.get("user"))?.result?.token;
//       AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${userToken}`;
//     }

//     // Increment the active requests counter
//     activeRequests++;
//     store.dispatch(startLoader());

//     return config;
//   },
//   function startLoaderError(error) {
//     // Decrement the active requests counter
//     activeRequests--;
//     if (activeRequests === 0) {
//       // Stop the loader when all API calls are done
//       store.dispatch(stopLoader());
//     }

//     // Do something with request error
//     return Promise.reject(error);
//   },
// );

// // Add a response interceptor
// AXIOS_INSTANCE.interceptors.response.use(
//   function (response) {
//     // Decrement the active requests counter
//     activeRequests--;
//     if (activeRequests === 0) {
//       // Stop the loader when all API calls are done
//       store.dispatch(stopLoader());
//     }

//     return response;
//   },
//   function (error) {
//     // Decrement the active requests counter
//     activeRequests--;
//     if (activeRequests === 0) {
//       // Stop the loader when all API calls are done
//       store.dispatch(stopLoader());
//     }

//     // Do something with response error
//     return Promise.reject(error);
//   },
// );

const addToken = () => {
  if (Cookies.get("user")) {
    const userToken = JSON.parse(Cookies.get("user"))?.result?.token;
    AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  }
};
addToken();

export const LOGIN_API = `${BASE_URL}`;

export const CLIMBER_BANNER_API = `${BASE_URL}/home`;

export const CORE_API = `${BASE_URL}/core`;
export const DASH_BOARD_API = `${BASE_URL}/dashboard`;

export const USER_API = `${BASE_URL}/user`;

export const MAP_PREFERENCES = `${BASE_URL}/map_preference`;

export const EARLY_WARNING_API = `${BASE_URL}`;

export const EARLY_ACTION_API = `${BASE_URL}/early_action`;

export const WEATHER_FORECAST_URL = `${BASE_URL}/weather_forecast`;
export const WEATHER_FORECAST_COMPARE_URL = `${BASE_URL}/weather_forecast/data`;
export const GEOGLOWS_URL = `${BASE_URL}/early_warnings/geoglows`;
export const USER_GUIDE_URL = `${BASE_URL}/user-guide`;

export const HEALTH_URL = `${BASE_URL}/early_warnings/health`;
export const NUTRITION_URL = `${BASE_URL}/early_warnings/nutrition`;

export const MALARIA_PREDECTION_URL = `${BASE_URL}/prediction_model/early_warning/scr/pred`;

export const MARKET_URL = `${BASE_URL}/early_warnings/market`;
export const POPULATION_DISPLACEMENT_URL = `${BASE_URL}/early_warnings/population_displacement`;

export const BULLETIN_URL = `${BASE_URL}/bulletin`;

export const BOUNDARY_URL = `${BASE_URL}/boundary/get_boundary`;
export const BACKOFFICE_URL = `${BASE_URL}/early_warnings/backoffice`;

export const STATIC_RESOURCE_URL = `${BASE_URL}/weather_forecast/back_office/weather_forecast_content`;

export const ODK_URL = `${BASE_URL}/odk`;

export const PERMISSIONS = `${BASE_URL}/permission`;

export const CROP_YIELD = `${BASE_URL}/crop_yield`;
export const RAPID_RESPONSE = `${BASE_URL}/rapid_response`;
