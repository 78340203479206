import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { GrFormClose } from "react-icons/gr";
import DragAndDrop from "./DragAndDrop";
import { dropzoneAccept } from "../../constants";

function MediaReferenceModal({ toggle, modal, handleSetMediaReferences }) {
  const { t } = useTranslation();
  const [mediaSelection, setMediaSelection] = useState("Image");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [url, setUrl] = useState([]);

  useEffect(() => {
    // Add a CSS class to the body element when the sideEditor is open
    if (modal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [modal]);

  const closeBtn = (
    <GrFormClose
      size={24}
      onClick={() => toggle()}
      className="pointer media-modal-header-close-icon"
    />
  );

  const handleMediaSelection = (e) => {
    setMediaSelection(e.target.value);
  };

  const uploadedFiles = [];
  const onHandleUploadImage = (file, base64) => {
    // Create an object to store file and base64 pair
    const fileData = {
      file,
      base64,
    };

    // Add the object to the uploadedFiles array
    uploadedFiles.push(fileData);
    if (uploadedFiles?.length) {
      setSelectedFiles([...selectedFiles, ...uploadedFiles]);
    }
  };

  const handleAddUrls = (event) => {
    setUrl(event.target.value);
  };

  const handleRemoveEmail = (index) => {
    const files = [...selectedFiles];
    files.splice(index, 1);
    setSelectedFiles(files);
  };

  const handleSubmit = () => {
    toggle();
    let urlData = {};
    const arr = [...selectedFiles];
    if (url?.length) {
      urlData = { url, type: "url" };
      arr.push(urlData);
      handleSetMediaReferences(arr);
    } else {
      handleSetMediaReferences(selectedFiles);
    }
  };

  const renderFiles = () => {
    return selectedFiles?.length
      ? selectedFiles.map((item, index) => {
          return (
            item?.type !== "url" && (
              <div
                className="img-upload-container  d-flex justify-content-between m-3"
                key={item?.file?.name}
              >
                {item?.file?.name}
                <span
                  role="button"
                  onClick={() => handleRemoveEmail(index)}
                  onKeyUp={() => {}}
                  tabIndex="0"
                  aria-label="MediaModal"
                >
                  <GrFormClose
                    size={16}
                    className="pointer media-modal-header-close-icon"
                  />
                </span>
              </div>
            )
          );
        })
      : "";
  };

  return (
    <div>
      <Modal isOpen={modal} size="md">
        <ModalHeader
          toggle={toggle}
          className="align-items-center media-modal-header-background"
          close={closeBtn}
        >
          <h2 className="intervention-h2 m-0">
            {t("EARLY_FINANCE_REPORT.NEW_MEDIA_REFERENCE")}
          </h2>
        </ModalHeader>
        <ModalBody className="p-3" style={{ height: "400px" }}>
          <div className="selection-radio" onChange={handleMediaSelection}>
            <Label className="label-item" check>
              <Input
                type="radio"
                className="radio-admin"
                name="mediaReferenceSelection"
                value="Image"
                checked={mediaSelection === "Image"}
              />
              <span className="spanmap spanmap-1">
                {t("EARLY_FINANCE_REPORT.IMAGE")}
              </span>
            </Label>
            <Label
              className="label-item"
              check
              data-tip
              data-for="basin-tool-tip"
            >
              <Input
                type="radio"
                className="radio-basin"
                name="mediaReferenceSelection"
                value="URL"
                checked={mediaSelection === "URL"}
              />
              <span className="spangraph spanmap-1">
                {t("EARLY_FINANCE_REPORT.URL")}
              </span>
            </Label>
          </div>
          <div className="media-body-height">
            {mediaSelection === "Image" ? (
              <>
                <DragAndDrop
                  onHandleUploadImage={onHandleUploadImage}
                  isMedia
                  acceptList={dropzoneAccept}
                />
                <div className="img-upload-main-container">{renderFiles()}</div>
              </>
            ) : (
              <>
                <Label className="region-label mt-0 ml-2">
                  {" "}
                  {t("EARLY_FINANCE_REPORT.URL")}{" "}
                </Label>
                <Input
                  type="url"
                  className="ef-report-text-field"
                  placeholder="Type or paste URL"
                  onChange={handleAddUrls}
                  value={url}
                />
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="w-100 active-pahse-btn-section justify-content-end">
            <Button
              type="button"
              className="media-cancel-btn mr-3"
              onClick={() => {
                toggle();
              }}
            >
              {t("EARLY_ACTIONS.CANCLE")}
            </Button>
            <Button
              type="button"
              className="media-add-btn mb-1"
              onClick={() => handleSubmit()}
              disabled={!(url?.length || selectedFiles?.length)}
            >
              {t("EARLY_ACTIONS.ADD")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default MediaReferenceModal;
