import EARLY_FINANCE_REPORT_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};
const EFReportReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case EARLY_FINANCE_REPORT_CONST.ADD_EARLY_FINANCE_REPORT_REQUEST:
      state = {
        ...state,
        loading: true,
        createReport: null,
        error: "",
      };
      break;
    case EARLY_FINANCE_REPORT_CONST.ADD_EARLY_FINANCE_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        createReport:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case EARLY_FINANCE_REPORT_CONST.ADD_EARLY_FINANCE_REPORT_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        createReport: null,
      };
      break;

    // GET REPORT
    case EARLY_FINANCE_REPORT_CONST.GET_EARLY_FINANCE_REPORT_REQUEST:
      state = {
        ...state,
        loading: true,
        getReportData: null,
        error: "",
        isGetReportLoading: true,
      };
      break;
    case EARLY_FINANCE_REPORT_CONST.GET_EARLY_FINANCE_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        getReportData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
        isGetReportLoading: false,
      };
      break;
    case EARLY_FINANCE_REPORT_CONST.GET_EARLY_FINANCE_REPORT_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        getReportData: null,
        isGetReportLoading: false,
      };
      break;

    // EDIT REPORT
    case EARLY_FINANCE_REPORT_CONST.EDIT_EARLY_FINANCE_REPORT_REQUEST:
      state = {
        ...state,
        loading: true,
        editReport: null,
        error: "",
      };
      break;
    case EARLY_FINANCE_REPORT_CONST.EDIT_EARLY_FINANCE_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        editReport:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case EARLY_FINANCE_REPORT_CONST.EDIT_EARLY_FINANCE_REPORT_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        editReport: null,
      };
      break;

    // DELETE REFERENCE
    case EARLY_FINANCE_REPORT_CONST.DELETE_EARLY_FINANCE_REFERENCES_REQUEST:
      state = {
        ...state,
        loading: true,
        deleteReference: null,
        error: "",
      };
      break;
    case EARLY_FINANCE_REPORT_CONST.DELETE_EARLY_FINANCE_REFERENCES_SUCCESS:
      state = {
        ...state,
        loading: false,
        deleteReference:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case EARLY_FINANCE_REPORT_CONST.DELETE_EARLY_FINANCE_REFERENCES_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        deleteReference: null,
      };
      break;

    // SEND REPORT
    case EARLY_FINANCE_REPORT_CONST.SEND_EARLY_FINANCE_REPORT_REQUEST:
      state = {
        ...state,
        loading: true,
        sendReport: null,
        error: "",
      };
      break;
    case EARLY_FINANCE_REPORT_CONST.SEND_EARLY_FINANCE_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        sendReport:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case EARLY_FINANCE_REPORT_CONST.SEND_EARLY_FINANCE_REPORT_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        sendReport: null,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};
export default EFReportReducer;
