const USER_CONST = {
  ORGANIZATION_REQUEST: "ORGANIZATION_REQUEST",
  ORGANIZATION_SUCCESS: "ORGANIZATION_SUCCESS",
  ORGANIZATION_ERROR: "PROVIENCE_ERROR",

  REGISTERATION_REQUEST: "REGISTERATION_REQUEST",
  REGISTERATION_SUCCESS: "REGISTERATION_SUCCESS",
  REGISTERATION_FAILURE: "REGISTERATION_FAILURE",

  REGISTERATION_VERIFICATION_REQUEST: "REGISTERATION_VERIFICATION_REQUEST",
  REGISTERATION_VERIFICATION_SUCCESS: "REGISTERATION_VERIFICATION_SUCCESS",
  REGISTERATION_VERIFICATION_FAILURE: "REGISTERATION_VERIFICATION_FAILURE",

  USER_DETAILS_REQUEST: "USER_DETAILS_REQUEST",
  USER_DETAILS_SUCCESS: "USER_DETAILS_SUCCESS",
  USER_DETAILS_FAILURE: "USER_DETAILS_FAILURE",

  USER_ODK_INFO_UPDATE_REQUEST: "USER_ODK_INFO_UPDATE_REQUEST",
  USER_ODK_INFO_UPDATE_SUCCESS: "USER_ODK_INFO_UPDATE_SUCCESS",
  USER_ODK_INFO_UPDATE_FAILED: "USER_ODK_INFO_UPDATE_FAILED",

  LOGIN_USER_DETAILS_REQUEST: "LOGIN_USER_DETAILS_REQUEST",
  LOGIN_USER_DETAILS_SUCCESS: "LOGIN_USER_DETAILS_SUCCESS",
  LOGIN_USER_DETAILS_FAILED: "LOGIN_USER_DETAILS_FAILED",
};

export default USER_CONST;
