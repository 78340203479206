const EARLY_WARNING_MARKET_CONST = {
  // GET MARKET MAP DATA
  GET_MARKET_MAP_DATA_REQUEST: "GET_MARKET_MAP_DATA_REQUEST",
  GET_MARKET_MAP_DATA_SUCCESS: "GET_MARKET_MAP_DATA_SUCCESS",
  GET_MARKET_MAP_DATA_FAILED: "GET_MARKET_MAP_DATA_FAILED",

  // TIME SERIES
  GET_MARKET_TIMESERIES_REQUEST: "GET_MARKET_TIMESERIES_REQUEST",
  GET_MARKET_TIMESERIES_SUCCESS: "GET_MARKET_TIMESERIES_SUCCESS",
  GET_MARKET_TIMESERIES_ERROR: "GET_MARKET_TIMESERIES_ERROR",

  // COMMODITY
  GET_MARKET_COMMODITY_REQUEST: "GET_MARKET_COMMODITY_REQUEST",
  GET_MARKET_COMMODITY_SUCCESS: "GET_MARKET_COMMODITY_SUCCESS",
  GET_MARKET_COMMODITY_ERROR: "GET_MARKET_COMMODITY_ERROR",
};
export default EARLY_WARNING_MARKET_CONST;
