import React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

class ErrorBoundaries extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
      error: error.toString(),
    });
  }

  render() {
    const { children } = this.props;
    const { hasError, error } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Container className="pt-5">
          <div className="text-center mt-5">
            <Link to="/home">
              <h4 className="cursor-pointer">Back to Home</h4>
            </Link>
            <h2 className="text-danger ">{error}</h2>
          </div>
        </Container>
      );
    }

    return children;
  }
}

ErrorBoundaries.propTypes = {
  children: PropTypes.node.isRequired,
};
export default ErrorBoundaries;
