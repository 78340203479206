import LOGIN_CONST from "./loginConst";

const initialState = {
  error: "",
  loading: false,
};

const Login = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case LOGIN_CONST.LOGIN_REQUEST:
      state = {
        ...state,
        loading: true,
        loginData: undefined,
        error: "",
      };
      break;
    case LOGIN_CONST.LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        loginData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case LOGIN_CONST.LOGIN_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        loginData: undefined,
      };
      break;
    case LOGIN_CONST.FORGOT_PASSWORD_REQUEST:
      state = {
        ...state,
        loading: true,
        forgotPasswordData: null,
        error: "",
      };
      break;
    case LOGIN_CONST.FORGOT_PASSWORD_SUCCESS:
      state = {
        ...state,
        loading: false,
        forgotPasswordData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case LOGIN_CONST.FORGOT_PASSWORD_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        forgotPasswordData: null,
      };
      break;
    case LOGIN_CONST.RESET_PASSWORD_REQUEST:
      state = {
        ...state,
        loading: true,
        resetPasswordData: null,
        error: "",
      };
      break;
    case LOGIN_CONST.RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        loading: false,
        resetPasswordData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case LOGIN_CONST.RESET_PASSWORD_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        resetPasswordData: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Login;
