import React, { useCallback, useEffect, useState, Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";

import { BsInfo } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import _ from "lodash";
import moment from "moment";

import { useNavigate, useOutletContext } from "react-router-dom";
import {
  getEWRawDataDatesRequest,
  getNutritionTimeSeriesRequest,
  mapPreferenceRequest,
  getNutritionMapData,
  mapPreferenceSuccess,
  getNutritionMapDataSuccess,
} from "../../store/actions";
// import Loader from "../../components/Common/Loader";
import CommonThresholdIndicators from "../../components/Common/CommonThresholdIndicators";
import NutritionGraph from "./Graph";
import HealthTable from "../Health/Table";
import { debounce } from "../../helpers/index";
import { MonthsList, sectorialClimateId } from "../../constants";

// lazy loading components
const HealthMapContainer = lazy(() => import("../Health/MapContainer"));
const HealthFilter = lazy(() => import("../Health/HealthFilter"));

function Nutrition() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [, setOpen] = useOutletContext();
  const paramsData = new URLSearchParams(window.location.search);
  const selectedModule = paramsData.get("subModule");
  const selectedSubModule = paramsData.get("module");
  const [local, setLocal] = useState(
    JSON.parse(localStorage.getItem("selectedCountry")),
  );
  const [position, setPosition] = useState([]);
  const [zooms, setZooms] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [indexLayers, setIndexLayers] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [allFieldsData, setAllFieldsData] = useState({});

  const [openScreen, setOpenScreen] = useState(false);
  const [isShowTableView, setIsShowTableView] = useState(false);
  const [tabsData, setTabsData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [malariaGeoJsonData, setMalariaGeoJsonData] = useState([]);
  const [mapes, setMapes] = useState();
  const [selectedLegendData, setSelectedLegendData] = useState([]);
  const [initialSelectedDate, setInitialSelectedDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [rawDates, setRawDates] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedState, setSelecetedState] = useState({});
  // const [initialLoader, setInitialLoader] = useState(true);
  const [dataFound, setDataFound] = useState(false);
  const history = useNavigate();

  const nextProps = useSelector((state) => ({
    mapPreferenceList: state.MapPreference,
    Weather: state.Weather,
    Graph: state?.Weather?.weatherForecastGraphData,
    isCompareLocation: state.Core.coredistrictList?.config?.isCompareLocation,
    nutritionTimeseriesData: state.Nutrition.nutritionTimeseriesData,
    nutritionTimeSeriesError: state.Nutrition.nutritionTimeSeriesError,
    EWRawDataDates: state.Health.getRawDataDates,
    getRawDataDatesError: state.Health.getRawDataDatesError,
    nutritionMapData: state.Nutrition.nutritionMapData,
    nutritionMapDataError: state.Nutrition.nutritionMapDataError,
    Health: state.Health,
    isNutritionMapDataRequesting: state.Nutrition.isNutritionMapDataRequesting,
  }));

  useEffect(() => {
    if (nextProps?.EWRawDataDates) {
      setRawDates(nextProps.EWRawDataDates);
    }
  }, [nextProps?.EWRawDataDates]);

  // For getting Selected Country and updating map initial load
  useEffect(() => {
    window.scrollTo(0, 0);
    // setTimeout(() => {
    //   setInitialLoader(false);
    // }, 2000);

    const CountryData = JSON.parse(localStorage.getItem("selectedCountry"));
    if (CountryData) {
      setPosition([local.latitude, local.longitude]);
      setZooms(local.zoom_level);
      setOpen(true);
      setLocal(JSON.parse(localStorage.getItem("selectedCountry")));
      dispatch(
        mapPreferenceRequest({
          country_id: JSON.parse(localStorage.getItem("selectedCountry"))
            ?.value,
          module: "nutrition",
        }),
      );
      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: CountryData,
          state: "",
          district: "",
        };
      });
      return () => {
        dispatch(mapPreferenceSuccess(""));
        dispatch(getNutritionMapDataSuccess(""));
      };
    } else {
      history("/climber");
    }
  }, []);

  const onHandleZoom = (center, zoom) => {
    setPosition(center);
    setZooms(zoom);
  };

  // function to call the selected distric and state
  const handleSelectedLocation = (filterLocation) => {
    setSelectedLocation(filterLocation);
  };

  const getEarlyWarningRawDataDates = (countryId) => {
    const payload = {
      countryId,
      moduleName: selectedModule,
      subModuleSlug: selectedSubModule,
    };
    dispatch(getEWRawDataDatesRequest(payload));
  };

  useEffect(() => {
    getEarlyWarningRawDataDates(
      JSON.parse(localStorage.getItem("selectedCountry"))?.value,
    );
  }, []);

  // function for handle all fields data and based fields data calling api's
  const handleAllFieldsData = (data, selectedTabIndex) => {
    const { country, region, district, dates, avialble_years } = data;

    const startDate = dates.length ? dates[0] : null;
    const endDate = dates.length > 1 ? dates[1] : null;

    const payload = {
      country_id: country?.value,
      state_id: region ? region.value : "",
      district_id: district ? district.value : "",
      start_year: new Date(startDate).getFullYear(),
      end_year: new Date(endDate).getFullYear(),
      start_month: "",
      end_month: "",
      start_day: "",
      end_day: "",
      graph_type: "table_view",
      // sub_menu_slug: selectedSubModule,
      market_commodity: [],
    };

    // map view only we are doing these chnages
    const a = moment([
      new Date(startDate).getFullYear(),
      new Date(startDate).getMonth(),
    ]);
    const b = moment([
      new Date(endDate).getFullYear(),
      new Date(endDate).getMonth(),
    ]);

    let tabsFinalData = [];
    if (b.diff(a, "year", true) === 1 || b.diff(a, "year", true) < 1) {
      if (b.diff(a, "year", true) < 1) {
        if (b.diff(a, "month", true) > 0) {
          tabsFinalData = {
            type: "months",
            data: MonthsList.slice(
              new Date(startDate).getMonth(),
              new Date(endDate).getMonth(),
            ).map((value) => {
              return {
                ...value,
                start_year: new Date(startDate).getFullYear(),
                end_year: new Date(endDate).getFullYear(),
              };
            }),
          };
        } else if (b.diff(a, "month", true) === 0) {
          tabsFinalData = {
            type: "months",
            data: MonthsList.filter(
              (item) => item.value === new Date(startDate).getMonth(),
            ).map((value) => {
              return {
                ...value,
                start_year: new Date(startDate).getFullYear(),
                end_year: new Date(endDate).getFullYear(),
              };
            }),
          };
        }
      } else if (b.diff(a, "year", true) === 1) {
        if (
          new Date(startDate).getFullYear() === new Date(endDate).getFullYear()
        ) {
          tabsFinalData = {
            type: "months",
            data: MonthsList.map((value) => {
              return {
                ...value,
                start_year: new Date(startDate).getFullYear(),
                end_year: new Date(endDate).getFullYear(),
              };
            }),
          };
        } else {
          tabsFinalData = {
            type: "months",
            data: MonthsList.slice(new Date(startDate).getMonth(), 12)
              .map((value) => {
                return {
                  ...value,
                  start_year: new Date(startDate).getFullYear(),
                  end_year: new Date(startDate).getFullYear(),
                };
              })
              .concat(
                MonthsList.slice(0, new Date(endDate).getMonth()).map(
                  (value) => {
                    return {
                      ...value,
                      start_year: new Date(endDate).getFullYear(),
                      end_year: new Date(endDate).getFullYear(),
                    };
                  },
                ),
              ),
          };
        }
      }
    } else {
      const years = [];
      for (let i = 0; i < b.diff(a, "year", true).toFixed(2); i += 1) {
        if (avialble_years.includes(new Date(startDate).getFullYear() + i)) {
          years.push({
            value: new Date(startDate).getFullYear() + i,
            label: new Date(startDate).getFullYear() + i,
          });
        }
      }
      tabsFinalData = {
        type: "years",
        data: years.reverse(),
        start_month: new Date(startDate).getMonth() + 1,
        end_month: new Date(endDate).getMonth() + 1,
      };
    }

    const availableTabsData = tabsFinalData?.data?.filter(
      (tab) => tab !== false,
    );

    let mapPayload;
    if (availableTabsData && availableTabsData?.length) {
      if (tabsFinalData.type === "years") {
        const selcetedYear = availableTabsData[selectedTabIndex || 0].value;
        mapPayload = {
          country_id: country?.value,
          state_id: region ? region.value : "",
          district_id: "",
          start_year: selcetedYear,
          end_year: selcetedYear,
          start_month: "",
          end_month: "",
          start_day: "",
          end_day: "",
          // sub_menu_slug: selectedSubModule,
          population_crisis_type: "",
          market_commodity: [],
        };
      } else {
        mapPayload = {
          country_id: country?.value,
          state_id: region ? region.value : "",
          district_id: "",
          start_year:
            availableTabsData.length > 1
              ? availableTabsData[selectedTabIndex || 0].start_year
              : availableTabsData[0].start_year,
          end_year:
            availableTabsData.length > 1
              ? availableTabsData[selectedTabIndex || 0].end_year
              : availableTabsData[0].end_year,
          start_month: "",
          end_month: "",
          start_day: "",
          end_day: "",
          // sub_menu_slug: selectedSubModule,
          population_crisis_type: "",
          market_commodity: [],
        };
      }
      setTabIndex(selectedTabIndex || 0);
      setInitialSelectedDate((prev) => {
        return {
          ...prev,
          startDate: selectedTabIndex
            ? availableTabsData[selectedTabIndex || 0]
            : availableTabsData[0],
        };
      });

      setTabsData(tabsFinalData);
      setAllFieldsData({
        ...data,
        payload,
        isShowAllYears: tabsFinalData.type === "years",
      });
    }
    if (payload?.start_year && payload?.end_year) {
      if (new Date(startDate) <= new Date(endDate)) {
        dispatch(getNutritionTimeSeriesRequest(payload));
        if (mapPayload?.start_year && mapPayload?.end_year) {
          dispatch(getNutritionMapData(mapPayload));
        }
      }
      const legendData = [...selectedLegendData];
      if (legendData?.length) {
        legendData.map((preLegend) => {
          preLegend.add = false;
          return preLegend;
        });
        setSelectedLegendData(legendData);
      }
      // Resetting geoJson data
      if (malariaGeoJsonData?.length) {
        setMalariaGeoJsonData([]);
      }
    }
  };

  const optimizedFn = useCallback(debounce(handleAllFieldsData), []);

  useEffect(() => {
    if (nextProps.nutritionTimeseriesData) {
      let output = {};
      if (!_.isEmpty(selectedState)) {
        // For showing only data for selected state on map
        const selectedStateData = nextProps.nutritionTimeseriesData?.filter(
          (item) => item?.state_name === selectedState?.state_name,
        );
        output = _(selectedStateData).groupBy("state_name").value();
      } else {
        output = _(nextProps.nutritionTimeseriesData)
          .groupBy("state_name")
          .value();
      }

      let dataBaseonRegion = [];
      if (allFieldsData.isShowAllYears) {
        dataBaseonRegion = Object.keys(output).map((item) => {
          return {
            state_name: item,
            data: _(output[item])
              .groupBy("year")
              .map((objs, key) => {
                return {
                  year: key,
                  state_name: item,
                  stunting: Number(_.sumBy(objs, "stunting")),
                  total_children: Number(_.sumBy(objs, "total_children")),
                  underweight: Number(_.sumBy(objs, "underweight")),
                  wasting: Number(_.sumBy(objs, "wasting")),
                  stunting_thresold: objs[0]?.stunting_thresold
                    ? objs[0]?.stunting_thresold
                    : "",
                  underweight_thresold: objs[0]?.underweight_thresold
                    ? objs[0]?.underweight_thresold
                    : "",
                  wasting_thresold: objs[0]?.wasting_thresold
                    ? objs[0]?.wasting_thresold
                    : "",
                };
              })
              .value(),
          };
        });
        setTableData(dataBaseonRegion);
      } else {
        dataBaseonRegion = Object.keys(output).map((region) => {
          return {
            data: output[region],
            state_name: region,
          };
        });
        setTableData(dataBaseonRegion);
      }

      // setTableData(dataBaseonRegion);
      if (allFieldsData.isShowAllYears) {
        let data = [];
        if (!_.isEmpty(selectedState)) {
          // For showing only data for selected state on map
          const selectedStateData = nextProps.nutritionTimeseriesData?.filter(
            (item) => item?.state_name === selectedState?.state_name,
          );
          data = _(selectedStateData)
            .groupBy("year")
            .map((objs, key) => {
              return {
                year: key,
                month: 1,
                stunting: Number(_.sumBy(objs, "stunting")),
                total_children: Number(_.sumBy(objs, "total_children")),
                underweight: Number(_.sumBy(objs, "underweight")),
                wasting: Number(_.sumBy(objs, "wasting")),
              };
            })
            .value();
        } else {
          data = _(nextProps.nutritionTimeseriesData)
            .groupBy("year")
            .map((objs, key) => {
              return {
                year: key,
                month: 1,
                stunting: Number(_.sumBy(objs, "stunting")),
                total_children: Number(_.sumBy(objs, "total_children")),
                underweight: Number(_.sumBy(objs, "underweight")),
                wasting: Number(_.sumBy(objs, "wasting")),
              };
            })
            .value();
        }
        setGraphData(data);
      } else {
        if (!_.isEmpty(selectedState)) {
          // For showing only data for selected state on map
          const selectedStateData = nextProps.nutritionTimeseriesData?.filter(
            (item) => item?.state_name === selectedState?.state_name,
          );
          setGraphData(selectedStateData);
        } else {
          setGraphData(nextProps.nutritionTimeseriesData);
        }
      }
    }
  }, [nextProps.nutritionTimeseriesData, selectedState]);

  useEffect(() => {
    if (
      nextProps.getRawDataDatesError?.length ||
      nextProps.nutritionTimeSeriesError?.length
    ) {
      setGraphData([]);
    }
  }, [nextProps.getRawDataDatesError, nextProps.nutritionTimeSeriesError]);

  useEffect(() => {
    if (nextProps.nutritionMapData && nextProps.nutritionMapData?.length) {
      const dataArray = [];
      nextProps.nutritionMapData?.map((item) => {
        dataArray.push(item);
        return null;
      });
      if (dataArray?.length) {
        setMalariaGeoJsonData(dataArray);
      }
    } else if (!_.isEmpty(nextProps.nutritionMapDataError)) {
      const { code } = nextProps.nutritionMapDataError;
      if (code === 400) {
        setMalariaGeoJsonData([]);
      }
    }
  }, [nextProps.nutritionMapData, nextProps.nutritionMapDataError]);

  const handleInfo = () => {
    window.open(
      `/user-guide-details?topicId=${sectorialClimateId}&sub-topic-name=nutrition&slug=user_guide`,
    );
  };

  return (
    <div className="dashboard-filter-div health-map">
      {/* {initialLoader && <Loader isSuspense indicator />} */}
      <Suspense fallback="">
        <div className="dashboard-filter">
          <h3
            className="dashboard-filter-h3"
            style={{ textTransform: "capitalize" }}
          >
            {t("NUTRITION.TITLE")}
          </h3>
          <BsInfo
            className="info-icon cursor-pointer ml-2 mb-2"
            onClick={() => handleInfo()}
          />

          {isShowTableView && <CommonThresholdIndicators />}
        </div>
        <Row className="dashboard-drought-section dashboard-health-section">
          <Col
            lg={12}
            className={
              openScreen
                ? "dashboard-drought-col2 pr-0 fullmap"
                : "dashboard-drought-col2 pr-0"
            }
          >
            <HealthFilter
              openScreen={openScreen}
              setOpenScreen={setOpenScreen}
              isShowTableView={isShowTableView}
              setIsShowTableView={setIsShowTableView}
              setPosition={setPosition}
              setZooms={setZooms}
              handleAllFieldsData={optimizedFn}
              EWRawDataDates={rawDates}
              getEarlyWarningRawDataDates={getEarlyWarningRawDataDates}
              defaultCountry={JSON.parse(
                localStorage.getItem("selectedCountry"),
              )}
              setSelectedLegendData={setSelectedLegendData}
              selectedLegendData={selectedLegendData}
              malariaGeoJsonData={malariaGeoJsonData}
              setMalariaGeoJsonData={setMalariaGeoJsonData}
              handleSelectedLocation={handleSelectedLocation}
              setSelecetedState={setSelecetedState}
              selectedState={selectedState}
              tabIndex={tabIndex}
              selectedModule={selectedModule}
              selectedSubModule={selectedSubModule}
              setTabIndex={setTabIndex}
            />
            {isShowTableView && !dataFound && (
              <HealthTable
                data={{
                  tableData,
                  avilableDates: tabsData,
                }}
                tabIndex={tabIndex}
                allFieldsData={allFieldsData}
                selectedModule={selectedModule}
                colSpan={3}
              />
            )}
            <HealthMapContainer
              position={position}
              zooms={zooms}
              onHandleZoom={onHandleZoom}
              local={local}
              selectedLocation={selectedLocation}
              indexLayers={indexLayers}
              setIndexLayers={setIndexLayers}
              mapPreferencesData={mapPreferencesData}
              setMapPreferencesData={setMapPreferencesData}
              malariaGeoJsonData={malariaGeoJsonData}
              mapes={mapes}
              setMapes={setMapes}
              selectedLegendData={selectedLegendData}
              setSelectedLegendData={setSelectedLegendData}
              initialSelectedDate={initialSelectedDate}
              avilableYears={tabsData}
              handleAllFieldsData={optimizedFn}
              allFieldsData={allFieldsData}
              setMalariaGeoJsonData={setMalariaGeoJsonData}
              setSelecetedState={setSelecetedState}
              isShowTableView={isShowTableView}
              selectedSubModule={selectedSubModule}
              legendName={_.capitalize(selectedModule)}
              selectedModule={selectedModule}
              parameterName={t("NUTRITION.TOTAL_CHILDREN")}
              regionsData={nextProps?.EWRawDataDates?.regions}
              showLoader={nextProps.isNutritionMapDataRequesting}
              setOpen={setOpen}
            />
          </Col>

          <Col
            lg={12}
            className={
              openScreen
                ? "graph-health pr-0 mt-3 graph-dn"
                : "graph-health pr-0 mt-3"
            }
          >
            {graphData?.length ? (
              <NutritionGraph
                openScreen={openScreen}
                setOpenScreen={setOpenScreen}
                graphData={graphData}
                healthCategorySlug={selectedSubModule}
                allFieldsData={allFieldsData}
                selectedState={selectedState}
                EWRawDataDates={nextProps?.EWRawDataDates}
                setDataFound={setDataFound}
                dataFound={dataFound}
                isShowTableView={isShowTableView}
              />
            ) : (
              <div className="mt-2 text-center">
                {t("HEALTH.NO_GRAPH_DATA")}
              </div>
            )}
          </Col>
        </Row>
      </Suspense>
    </div>
  );
}

export default React.memo(Nutrition);
