import UPLOAD_PAST_DATA_CONSTS from "./actionTypes";

// ADD USER GUIDE
export const uploadPastDataRequest = (data) => ({
  type: UPLOAD_PAST_DATA_CONSTS.UPLOAD_PAST_DATA_REQUEST,
  payload: data,
});

export const uploadPastDataSuccess = (response) => ({
  type: UPLOAD_PAST_DATA_CONSTS.UPLOAD_PAST_DATA_SUCCESS,
  payload: response,
});

export const uploadPastDataFailure = (error) => ({
  type: UPLOAD_PAST_DATA_CONSTS.UPLOAD_PAST_DATA_FAILURE,
  payload: error,
});

export const getTemplateDataRequest = (data) => {
  return {
    type: UPLOAD_PAST_DATA_CONSTS.GET_TEMPLATE_DATA_REQUEST,
    payload: data,
  };
};
export const getTemplateDataSuccess = (user) => {
  return {
    type: UPLOAD_PAST_DATA_CONSTS.GET_TEMPLATE_DATA_SUCCESS,
    payload: user,
  };
};
export const getTemplateDataError = (error) => {
  return {
    type: UPLOAD_PAST_DATA_CONSTS.GET_TEMPLATE_DATA_FAILURE,
    payload: error,
  };
};

// action for get past data
export const getPastDataListRequest = (data) => {
  return {
    type: UPLOAD_PAST_DATA_CONSTS.GET_PAST_DATA_LIST_REQUEST,
    payload: data,
  };
};
export const getPastDataListSuccess = (user) => {
  return {
    type: UPLOAD_PAST_DATA_CONSTS.GET_PAST_DATA_LIST_SUCCESS,
    payload: user,
  };
};
export const getPastDataListError = (error) => {
  return {
    type: UPLOAD_PAST_DATA_CONSTS.GET_PAST_DATA_LIST_FAILURE,
    payload: error,
  };
};

// DOWNLOAD TEMPLATE FOR CROP YIELD
export const getDownloadCypTemplateRequest = (data) => {
  return {
    type: UPLOAD_PAST_DATA_CONSTS.CROP_YIELD_EXCEL_TEMPLATE_REQUEST,
    payload: data,
  };
};
export const getDownloadCypTemplateSuccess = (user) => {
  return {
    type: UPLOAD_PAST_DATA_CONSTS.CROP_YIELD_EXCEL_TEMPLATE_SUCCESS,
    payload: user,
  };
};
export const getDownloadCypTemplateError = (error) => {
  return {
    type: UPLOAD_PAST_DATA_CONSTS.CROP_YIELD_EXCEL_TEMPLATE_FAILURE,
    payload: error,
  };
};

// SUBMIT TEMPLATE FOR CROP YIELD
export const uploadCypDataRequest = (data) => {
  return {
    type: UPLOAD_PAST_DATA_CONSTS.CROP_YIELD_DATA_SUBMIT_REQUEST,
    payload: data,
  };
};
export const uploadCypDataSuccess = (user) => {
  return {
    type: UPLOAD_PAST_DATA_CONSTS.CROP_YIELD_DATA_SUBMIT_SUCCESS,
    payload: user,
  };
};
export const uploadCypDataError = (error) => {
  return {
    type: UPLOAD_PAST_DATA_CONSTS.CROP_YIELD_DATA_SUBMIT_FAILURE,
    payload: error,
  };
};
