const EARLY_WARNING_CONST = {
  ADD_DROUGHT_REQUEST: "ADD_DROUGHT_REQUEST",
  ADD_DROUGHT_SUCCESS: "ADD_DROUGHT_SUCCESS",
  ADD_DROUGHT_ERROR: "ADD_DROUGHT_ERROR",

  GET_DROUGHT_TIMESERIES_REQUEST: "GET_DROUGHT_TIMESERIES_REQUEST",
  GET_DROUGHT_TIMESERIES_SUCCESS: "GET_DROUGHT_TIMESERIES_SUCCESS",
  GET_DROUGHT_TIMESERIES_ERROR: "GET_DROUGHT_TIMESERIES_ERROR",

  ADD_FLOOD_REQUEST: "ADD_FLOOD_REQUEST",
  ADD_FLOOD_SUCCESS: "ADD_FLOOD_SUCCESS",
  ADD_FLOOD_ERROR: "ADD_FLOOD_ERROR",
  GET_FLOOD_TIMESERIES_REQUEST: "GET_FLOOD_TIMESERIES_REQUEST",
  GET_FLOOD_TIMESERIES_SUCCESS: "GET_FLOOD_TIMESERIES_SUCCESS",
  GET_FLOOD_TIMESERIES_ERROR: "GET_FLOOD_TIMESERIES_ERROR",
};
export default EARLY_WARNING_CONST;
