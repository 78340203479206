import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { adminMapPreferenceLink } from "../../constants";

export default function CommonToolTip({ moduleName, message, children }) {
  const { t } = useTranslation();
  const useStylesBootstrap = makeStyles(() => ({
    arrow: {
      color: "#22ad7a",
    },
  }));
  const HtmlTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: "#ffffff",
      color: "#363636",
      maxWidth: 220,
      fontSize: "13px",
      fontFamily: "National",
      borderRadius: "10px",
      padding: "8px 21px",
      border: "#22ad7a 2px solid",
      boxShadow: "0px 2px 8px rgb(34 173 122 / 5%)",
    },
  }))(Tooltip);
  const classes = useStylesBootstrap();
  return moduleName === "Map Preferences" ? (
    <HtmlTooltip
      classes={classes}
      arrow
      title={
        <>
          <span>{t("LOADING.ADMIN_TOOL_TIP")}</span>{" "}
          <a
            style={{ color: "#363636" }}
            href={adminMapPreferenceLink}
            target="_BLANK"
            rel="noreferrer"
          >
            {adminMapPreferenceLink}
          </a>
        </>
      }
      interactive
    >
      {children}
    </HtmlTooltip>
  ) : (
    <HtmlTooltip
      classes={classes}
      arrow
      // disableFocusListener
      // disableTouchListener
      title={
        <>
          <span>{message}</span>{" "}
        </>
      }
      interactive
    >
      {children}
    </HtmlTooltip>
  );
}
