import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useNavigate } from "react-router-dom";
import SplitterLayout from "react-splitter-layout";
import "react-splitter-layout/lib/index.css";
import { Button, Col, Row } from "reactstrap";

import BackButton from "../../assest/img/Left-arrow-icon.svg";
import { showError } from "../../components/Common/Notification";
import GraphLoader from "../../components/GraphLoader";
import {
  DATA_SOURCES,
  Fulfilled,
  IRI_DATA,
  RAPID_RESPONSE_CROP_GRAPH_PARAMETERS,
  RAPID_RESPONSE_POPULATION_GRAPH_PARAMETERS,
  Rejected,
  State,
} from "../../constants";
import {
  convertToTitleCase,
  dateFormatter,
  renderReactOptionsArraystate,
} from "../../helpers";
import {
  coreBasinRequest,
  coreRequest,
  corestateRequest,
  getRainfallMapDataRequest,
  getWeatherForecastData,
  mapPreferenceRequest,
  startGraphLoader,
  stopGraphLoader,
} from "../../store/actions";
import { AXIOS_INSTANCE_LOADER, BASE_URL } from "../../store/apiUtils/config";
import CompareComponent from "./CompareComponent";
import Filter from "./Filter";
import Graph from "./Graph";
import MapContainer from "./MapContainer";
import RainfallGraph from "./RainfallGraph";
import RapidResponseStepper from "./RapidRepsonseStepper";
import ScrollTabs from "./ScrollTabs";
import WeatherForecastModal from "./WeatherForecastModal";

function RapidResponse() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const wrap = useRef();
  const splitterRef = useRef(null);
  const layerRef = useRef([]);
  const compareRef = useRef([]);
  const divRef = useRef();
  const [mapPreferRefs] = useState([]);

  const [open, setOpen] = useOutletContext();
  const [openPanel, setOpenPanel] = useState(false);
  const [layerControl, setLayerControl] = useState({
    firstLayer: {},
    secondLayer: {},
  });
  const [mapControl, setMapControl] = useState({});
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [indexLayers, setIndexLayers] = useState([]);
  const [legend, setLegend] = useState([]);
  const [comparelegend, setcompareLegend] = useState([]);
  const [, setOpaci] = useState();
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(31, "days").format("YYYY-MM-DD"),
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
  );
  const [, setGetBoundariesData] = useState([]);
  const [layerData, setLayerData] = useState("");
  const [, setMapPreferenceCategories] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [mapes, setMapes] = useState();
  const [local, setLocal] = useState(
    JSON.parse(localStorage?.getItem("selectedCountry")),
  );
  const [position, setPosition] = useState([local?.latitude, local?.longitude]);
  const [zooms, setZooms] = useState(local?.zoom_level);

  const [activeStep, setActiveStep] = useState(null);
  const [isStepperClick, setIsStepperClick] = useState(false);
  const [adminRadio, setAdminRadio] = useState("admin");
  const [selectedRainfallDataSource, setSelectedRainfallDataSource] = useState({
    value: "GPM",
    label: "GPM",
    graph_url: "/rapid_response/rainfall/timeseries",
  });
  const [rainfallThreshold, setRainfallThreshold] = useState(10);
  const [isWeatherForecastOpen, setIsWeatherForecastOpen] = useState(false);
  const [forecastData, setForecastData] = useState([]);
  const [indicatorList, setIndicatorList] = useState([]);
  const [selectedFloodDataSource, setSelectedFloodDataSource] = useState({
    value: "",
    label: "Select Data source",
  });
  const [selectedExposureTypes, setSelectedExposureTypes] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [graphPanelHeight, setPaneleHeight] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [isCallBoundary, setIsCallBoundary] = useState(false);

  const [mapPreferences, setMapPreferences] = useState({
    country: { layer: useRef(), color: "", data: "", country: "" },
    regions: { layer: useRef(), color: "", data: "", country: "" },
    basins: { layer: useRef(), color: "", data: "", country: "" },
    sub_basins: { layer: useRef(), color: "", data: "", country: "" },
    rivers_or_reservoirs: { layer: useRef(), color: "", data: "", country: "" },
    automatic_weather_station: {
      layer: useRef(),
      color: "",
      data: "",
      country: "",
    },
    major_rivers: { layer: useRef(), color: "", data: "", country: "" },
    minor_rivers: { layer: useRef(), color: "", data: "", country: "" },
    hydro_stations: { layer: useRef(), color: "", data: "", country: "" },
  });
  const [selectedMapPreferLegendData, setSelectedMapPreferLegendData] =
    useState([]);
  const [mapLayers, setMapLayers] = useState([]);
  const [isDataSourceChanged, setIsDataSoruceChanged] = useState(false);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [isCompareOpen, setIsCompareOpen] = useState(false);
  const [isCollapse, setIsCollapse] = useState({
    rainfall: true,
    flood: true,
    disaster_exposure: true,
  });
  const [compareIndicatorList, setCompareIndicatorList] = useState([]);
  const [selectedCompareIndicator, setSelectedCompareIndicator] = useState([]);
  const [mapUrl, setMapUrl] = useState("");
  const [rainfallInterval, setRainfallInterval] = useState("daily");
  const [graphErrorMessage, setGraphErrorMessage] = useState("");

  const updatePage = (title, title2) => {
    setPosition(title);
    setZooms(title2);
  };

  const nextProps = useSelector((state) => ({
    statesList: state.Core.corestateList,
    countryList: state.Core.coreList,
    basinList: state.Core.coreBasinList,
    mapPreferenceList: state.MapPreference,
    dashBoardMenuData: state.Dashboard.dashBoardMenuData,
    weatherForecastData: state.Weather.weatherForecastData,
    indicator: state.RapidResponse.rainfallMapData,
    isRainfallMapLoading: state.RapidResponse.isRainfallMapDataRequesting,
    rapidResponseMapError: state.RapidResponse.rainfallMapDataError,
    rapidResponseNetworkError: state.RapidResponse.rainfallMapDataNetworkError,
  }));

  const selectedRegions = [
    {
      label: t("DASHBOARD.ALL_REGIONS"),
      value: "",
      latitude: selectedLocation?.country?.latitude,
      longitude: selectedLocation?.country?.longitude,
      zoom_level: selectedLocation?.country?.zoom_level,
    },
  ];

  const selectedBasin = [
    {
      label: t("DASHBOARD.SELECT_BASIN"),
      value: "",
      latitude: selectedLocation?.country?.latitude,
      longitude: selectedLocation?.country?.longitude,
      zoom_level: selectedLocation?.country?.zoom_level,
    },
  ];

  useEffect(() => {
    setOpen(true);
    dispatch(
      coreRequest({
        location_type: "country",
      }),
    );
    window.scrollTo(0, 0);
  }, []);

  const isMapPreferenceList = useRef(true);
  useEffect(() => {
    if (isMapPreferenceList.current) {
      isMapPreferenceList.current = false;
      return;
    }
    if (nextProps.mapPreferenceList.mapPreferenceList) {
      const array = [];
      setGetBoundariesData([...nextProps.mapPreferenceList.mapPreferenceList]);
      nextProps.mapPreferenceList.mapPreferenceList.map((eachValue) =>
        array.push(eachValue.category),
      );
      setMapPreferenceCategories(array);
    }
  }, [nextProps.mapPreferenceList]);

  const isCountryList = useRef(true);
  useEffect(() => {
    if (isCountryList.current) {
      isCountryList.current = false;
      return;
    }
    if (nextProps?.countryList) {
      const countryarray = [];
      renderReactOptionsArraystate(
        nextProps?.countryList,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
        "code",
        false,
        null,
        null,
        null,
        null,
        null,
        null,
        "basin_feature_collection",
      )?.map((eachValue) => countryarray.push(eachValue));
      setCountryList(countryarray);
    }
  }, [nextProps?.countryList]);

  // Forecast data response
  const isForecastRun = useRef(true);
  useEffect(() => {
    if (isForecastRun.current) {
      isForecastRun.current = false;
      return;
    }
    if (nextProps?.weatherForecastData) {
      setForecastData(nextProps?.weatherForecastData);
      setIsWeatherForecastOpen(!isWeatherForecastOpen);
    }
  }, [nextProps?.weatherForecastData]);

  useEffect(() => {
    if (nextProps?.indicator) {
      setIsDataSoruceChanged(false);
      const parameterArr = [];
      let count = 0;
      let compareCount = compareIndicatorList?.length;
      // activeStep === null || activeStep === 0
      //   ? 0
      //   : compareIndicatorList?.length;
      // Convert result object into an array of objects
      const resultArray = Object.keys(nextProps?.indicator).map((key) => ({
        [key]: nextProps?.indicator[key],
      }));

      // ** CONVERTING THEM INTO ARRAY OF OBJECTS
      const newArray = resultArray.map((obj) => {
        const datasource = Object.keys(obj)[0];
        const name = convertToTitleCase(datasource);
        return {
          ...obj[datasource],
          datasource,
          name,
          keyDatasource: [
            "max_rainfall_event",
            "rainfall_frequency",
            "accumulated_rainfall",
            "rainfall_contours",
          ].includes(datasource)
            ? "Rainfall"
            : "Flood",
        };
      });

      newArray?.map((item) => {
        item.checked = false;
        item.paramIndex = count;
        item.compareParamIndex = compareCount;
        count += 1;
        compareCount += 1;
        parameterArr.push(item);
        return null;
      });
      // Filtering out duplicates before updating the state
      setCompareIndicatorList((prev) => {
        const updatedList = [...prev, ...parameterArr];
        const uniqueList = updatedList.filter(
          (item, index, self) =>
            index ===
            self.findIndex((data) => data.datasource === item.datasource),
        );

        compareRef.current = [];
        if (uniqueList?.length) {
          uniqueList?.map(() => {
            const compare = React.createRef();
            compareRef.current.push(compare);
            return null;
          });
        }
        return uniqueList;
      });

      layerRef.current = [];
      if (parameterArr?.length) {
        parameterArr?.map(() => {
          const ref2 = React.createRef();
          layerRef.current.push(ref2);
          return null;
        });
      }
      setIndicatorList(parameterArr);
      setLayerData(parameterArr[0]);
      activeStep === null ? setActiveStep(0) : setActiveStep(activeStep + 1);
      setLegend([]);
      setcompareLegend([]);
    } else {
      setIndicatorList([]);
    }
  }, [nextProps.indicator]);

  // ! Error message for network issues
  useEffect(() => {
    if (nextProps.rapidResponseNetworkError)
      showError(t("ERROR.API_FAILURE_ERROR"));
  }, [nextProps.rapidResponseNetworkError]);

  useEffect(() => {
    if (layerData) {
      if (
        RAPID_RESPONSE_CROP_GRAPH_PARAMETERS.includes(layerData?.datasource)
      ) {
        setShowChart(true);
        if (graphData?.length) {
          const districtCropItem = graphData.find(
            (item) => item?.name === "District Crop",
          );

          if (districtCropItem) {
            const { total_crop_area } = districtCropItem;

            const newChartData = {
              ...districtCropItem,
              xaxis: total_crop_area,
              yaxisTitle: t("RAPID_RESPONSE.CROP_AREA"),
              isCrop: true,
            };
            setChartData(newChartData);
          }
        }
      } else if (
        RAPID_RESPONSE_POPULATION_GRAPH_PARAMETERS.includes(
          layerData?.datasource,
        )
      ) {
        setShowChart(true);
        if (graphData?.length) {
          const populationItem = graphData.find(
            (item) => item?.name === "Population Data",
          );

          if (populationItem) {
            const { total_population } = populationItem;

            const newChartData = {
              ...populationItem,
              xaxis: total_population,
              yaxisTitle: t("RAPID_RESPONSE.POPULATION_AFFECTED"),
              isCrop: false,
            };
            setChartData(newChartData);
          }
        }
      } else {
        setShowChart(false);
      }
    }
  }, [layerData]);

  // * REMOVE GEOJSON LAYER
  const removeGeoJsonLayer = (mapPreferencesData1) => {
    const mapPreferKeys = Object.keys(mapPreferencesData1);
    if (mapPreferKeys) {
      mapPreferKeys.map((item) => {
        const selectedLayer = mapPreferences[item];

        if (selectedLayer?.layer && selectedLayer?.layer?.current)
          // selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.color = "";
        setMapPreferences((prev) => {
          return {
            ...prev,
            [item]: selectedLayer,
          };
        });
        return null;
      });
    }
  };

  // * REMOVING MAP LAYERS
  const removeLayers = (selectedParamRef) => {
    if (
      wrap &&
      wrap?.current &&
      selectedParamRef &&
      selectedParamRef?.current
    ) {
      const map1 = wrap.current;

      const firstLayer = selectedParamRef.current;

      [firstLayer].forEach((layer) => map1.removeLayer(layer));
    }
  };

  const removeMapLayers = (selectedParamLegendData, compareParamLegendData) => {
    let previousParamRef = "";
    let previousCompareParamRef = "";
    if (layerRef?.current?.length) {
      layerRef.current.map((i, index) => {
        previousParamRef = layerRef.current[index];
        removeLayers(previousParamRef);
        return null;
      });
    }
    if (compareRef?.current?.length) {
      compareRef.current.map((i, index) => {
        previousCompareParamRef = compareRef.current[index];
        removeLayers(previousCompareParamRef);
        return null;
      });
    }
    const legendArray = [...selectedParamLegendData];
    const compareLegendArray = [...compareParamLegendData];
    if (legendArray.length) {
      legendArray.map((preLegend) => {
        preLegend.add = false;
        return preLegend;
      });
    }
    if (compareLegendArray.length) {
      compareLegendArray.map((preLegend) => {
        preLegend.add = false;
        return preLegend;
      });
    }
    if (!_.isEmpty(layerControl?.firstLayer)) {
      mapes?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      mapes?.removeControl(layerControl?.secondLayer);
    }
    if (!_.isEmpty(mapControl)) {
      mapes?.removeControl(mapControl);
    }
    setMapControl("");

    setLayerControl({
      firstLayer: {},
      secondLayer: {},
    });
    setLegend(legendArray);
    setcompareLegend(compareLegendArray);
  };

  // * Removing MAP PREFERENCE LAYER
  const removeMapPreferenceLayer = (mapPreferRefs1, mapPreferencesData1) => {
    if (mapPreferRefs1?.current?.length && mapPreferencesData1?.length) {
      mapPreferencesData1?.map((item) => {
        const selectedMapIndex = _.findIndex(
          mapPreferRefs1.current,
          (list) => list.current.boundary_id === item.id,
        );

        const previousParamRef =
          mapPreferRefs1?.current[selectedMapIndex]?.current.layer;

        if (previousParamRef && previousParamRef?.current) {
          removeLayers(previousParamRef);
        }
        return null;
      });
    }
  };

  const onHandleZoom = (center, zoom) => {
    setPosition(center);
    setZooms(zoom);
  };

  // function to call the selected distric and state
  const handleSelectedLocation = (filterLocation) => {
    setSelectedLocation(filterLocation);
  };

  useEffect(() => {
    if (splitterRef && splitterRef.current) {
      if (showChart) {
        splitterRef.current.setState({ secondaryPaneSize: 60 });
      } else {
        splitterRef.current.setState({ secondaryPaneSize: 0 });
      }
    }
  }, [showChart]);

  // * WEATHER FORECAST DATA
  const handleForecastData = () => {
    const requestObj = {};
    requestObj.weather_forecast_source_id = DATA_SOURCES.OPEN_WEATHER?.id; // * OPEN WEATHER ID REQURIED
    requestObj.start_date = DATA_SOURCES.OPEN_WEATHER?.dateRange?.startDate;
    requestObj.end_date = DATA_SOURCES.OPEN_WEATHER?.dateRange?.endDate;
    requestObj.spatial_aggregation = "mean";
    requestObj.weather_forecast_parameter_id = IRI_DATA()[1].value; // * PRECIPITATION ID REQURIED

    if (!_.isEmpty(selectedLocation.country)) {
      requestObj.country_name = selectedLocation?.country?.label;
      requestObj.latitude = selectedLocation?.country?.latitude;
      requestObj.longitude = selectedLocation?.country?.longitude;
    }
    if (
      !_.isEmpty(selectedLocation.basin) &&
      selectedLocation?.basin?.label !== "Select Basin"
    ) {
      requestObj.basin_name = selectedLocation?.basin?.label;
      requestObj.latitude = selectedLocation?.basin?.latitude;
      requestObj.longitude = selectedLocation?.basin?.longitude;
    }
    if (
      !_.isEmpty(selectedLocation?.state) &&
      selectedLocation?.state?.label !== "All Regions"
    ) {
      requestObj.state_name = selectedLocation?.state?.label;
      requestObj.latitude = selectedLocation?.state?.latitude;
      requestObj.longitude = selectedLocation?.state?.longitude;
    }

    dispatch(getWeatherForecastData(requestObj));
  };

  // * handle Map API call
  const handleGetMap = () => {
    window.scrollTo(0, 0);
    setIsCompareOpen(false);
    setIndicatorList([]);
    setSelectedCompareIndicator([]);
    setIsDataSoruceChanged(true);
    setShowChart(false);
    setChartData([]);

    const payload = {};
    if (!_.isEmpty(selectedLocation.country)) {
      payload.country_name = selectedLocation?.country?.label;
    }
    if (!_.isEmpty(selectedLocation.basin)) {
      payload.basin_name = selectedLocation?.basin?.label;
    }
    if (
      !_.isEmpty(selectedLocation?.state) &&
      selectedLocation?.state?.label !== "All Regions"
    ) {
      payload.state_name = selectedLocation?.state?.label;
    }
    payload.start_date = moment(startDate).format("YYYY-MM-DD");
    payload.end_date = moment(endDate).format("YYYY-MM-DD");
    if (activeStep !== null) {
      payload.precipitation_threshold = Number(rainfallThreshold);
    }

    if (activeStep === 0 || activeStep === null) {
      payload.rainfall_dataset = !_.isEmpty(selectedRainfallDataSource)
        ? selectedRainfallDataSource?.value
        : "";
      dispatch(getRainfallMapDataRequest({ payload, url: "rainfall/map" }));
    } else if (activeStep === 1) {
      payload.flood_dataset = !_.isEmpty(selectedFloodDataSource)
        ? selectedFloodDataSource?.value
        : "";
      dispatch(
        getRainfallMapDataRequest({
          payload,
          url: "flooded_area/map",
        }),
      );
    } else if (activeStep === 2) {
      if (selectedExposureTypes?.length) {
        setIsLoading(!isLoading);
        const API_URL = [];
        selectedExposureTypes?.map((exposure) => {
          /**
           * * differentiating api response and graph response based on payload
           * * rainfall_dataset
           */
          const exposurePayload = {
            ...payload,
            flood_dataset: !_.isEmpty(selectedFloodDataSource)
              ? selectedFloodDataSource?.value
              : "",
          };
          API_URL.push(
            AXIOS_INSTANCE_LOADER.post(`${BASE_URL}${exposure?.api_url}`, {
              ...payload,
              rainfall_dataset: exposure.value,
              flood_dataset: !_.isEmpty(selectedFloodDataSource)
                ? selectedFloodDataSource?.value
                : "",
            }),
          );
          if (
            ["CROP_EXPOSURE", "POPULATION_EXPOSURE"].includes(exposure.value)
          ) {
            API_URL.push(
              AXIOS_INSTANCE_LOADER.post(
                `${BASE_URL}${exposure?.graph_url}`,
                exposurePayload,
              ),
            );
          }
          return null;
        });

        if (API_URL.length !== 0) {
          const errorMsg = [];
          const finalResponse = [];
          const graphResponse = [];
          Promise.allSettled(API_URL).then((response) => {
            const res = JSON.parse(JSON.stringify(response));
            res?.map((item) => {
              if (item?.status === Fulfilled) {
                const responseData = item?.value?.data; // Extract the data from the response
                const requestPayload = JSON.parse(item?.value?.config?.data); // Extract the payload sent with the request
                if (
                  requestPayload.rainfall_dataset === "CROP_EXPOSURE" ||
                  requestPayload.rainfall_dataset === "POPULATION_EXPOSURE" ||
                  requestPayload.rainfall_dataset === "INFRASTRUCTURAL_EXPOSURE"
                ) {
                  // This is a API URL request
                  finalResponse.push(responseData?.result);
                } else {
                  // This is an Graph URL request
                  graphResponse.push(responseData?.result);
                }
              } else if (item?.status === Rejected) {
                const errorResponse = item?.reason;
                if (
                  errorResponse?.status === 400 ||
                  errorResponse?.status === 500
                ) {
                  errorMsg.push(errorResponse?.message);
                  showError(
                    errorResponse?.message || t("LOGIN.SOMETHING_WENT_WRONG"),
                  );
                } else {
                  showError(t("LOGIN.SOMETHING_WENT_WRONG"));
                }
              }
              return null;
            });
            setIsLoading(false);

            if (finalResponse?.length) {
              setIsLoading(false);
              const parameterArr = [];
              let count = 0;
              let compareCount = compareIndicatorList?.length;

              const resultArray = finalResponse.flatMap((item) =>
                Object.keys(item).map((key) => ({
                  [key]: item[key],
                })),
              );

              // ** CONVERTING THEM INTO ARRAY OF OBJECTS
              const newArray = resultArray.flatMap((obj) => {
                const datasource = Object.keys(obj)[0];
                const name = convertToTitleCase(datasource);
                return {
                  ...obj[datasource],
                  datasource,
                  name,
                  keyDatasource: "Disaster Exposure",
                };
              });

              newArray?.map((item) => {
                item.checked = false;
                item.paramIndex = count;
                item.compareParamIndex = compareCount;
                count += 1;
                compareCount += 1;
                parameterArr.push(item);
                return null;
              });

              // Filtering out duplicates before updating the state
              setCompareIndicatorList((prev) => {
                const updatedList = [...prev, ...parameterArr];
                const uniqueList = updatedList.filter(
                  (item, index, self) =>
                    index ===
                    self.findIndex(
                      (data) => data.datasource === item.datasource,
                    ),
                );

                compareRef.current = [];
                if (uniqueList?.length) {
                  uniqueList?.map(() => {
                    const compare = React.createRef();
                    compareRef.current.push(compare);
                    return null;
                  });
                }

                return uniqueList;
              });

              layerRef.current = [];
              if (parameterArr?.length) {
                parameterArr?.map(() => {
                  const ref2 = React.createRef();
                  layerRef.current.push(ref2);
                  return null;
                });
              }

              setIndicatorList(parameterArr);
              setLayerData(parameterArr[0]);
              setActiveStep(activeStep + 1);

              setLegend([]);
              setcompareLegend([]);
            } else {
              setIndicatorList([]);
            }

            if (graphResponse?.length) {
              const resultArray = graphResponse.flatMap((item) =>
                Object.keys(item).map((key) => ({
                  [key]: item[key],
                })),
              );

              // ** CONVERTING THEM INTO ARRAY OF OBJECTS
              const newArray = resultArray.flatMap((obj) => {
                const datasource = Object.keys(obj)[0];
                const name = convertToTitleCase(datasource);
                return {
                  ...obj[datasource],
                  datasource,
                  name,
                };
              });
              setGraphData(newArray);
            } else {
              setGraphData([]);
            }
          });
        }
      }
    }
    setIsStepperClick(false);
  };

  // *reset function
  const handleResetFunc = () => {
    removeGeoJsonLayer(mapPreferences);

    if (layerRef?.current?.length) {
      layerRef.current.map((i, index) => {
        removeLayers(layerRef.current[index]);
        return null;
      });
    }
    if (compareRef?.current?.length) {
      compareRef.current.map((i, index) => {
        removeLayers(compareRef.current[index]);
        return null;
      });
    }
    removeMapLayers(legend, comparelegend, layerControl);

    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setMapPreferencesData([]);
    const selectedLegend = JSON.parse(
      JSON.stringify(selectedMapPreferLegendData),
    );
    // for legend addpled to map-preferences
    if (selectedLegend.length) {
      selectedLegend.forEach(function mappreference(value) {
        if (value.add) {
          value.add = false;
        }
      });
      setSelectedMapPreferLegendData(selectedLegend);
    }

    setSelectedLocation((prev) => {
      return {
        ...prev,
        country: selectedLocation?.country,
        state: "",
        basin: "",
        region: [],
      };
    });

    setActiveStep(null);
    setStartDate(moment(new Date()).subtract(31, "days").format("YYYY-MM-DD"));
    setEndDate(moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"));
    setSelectedRainfallDataSource({
      value: "GPM",
      label: "GPM",
      graph_url: "/rapid_response/rainfall/timeseries",
    });
    setRainfallThreshold(10);
    setSelectedFloodDataSource({
      value: "",
      label: "Select Data source",
    });
    setSelectedExposureTypes([]);
    setLayerData("");
    updatePage(
      [selectedLocation?.country.latitude, selectedLocation?.country.longitude],
      selectedLocation?.country.zoom_level,
    );
    setIsLoading(false);
    setGraphData([]);
    setShowChart(false);
    setChartData([]);
    setIsStepperClick(false);
    setIsCallBoundary(true);
    setMapLayers([]);
    setIndicatorList([]);
    setIsDataSoruceChanged(false);
    setIsCompareOpen(false);
    setSelectedCompareIndicator([]);
    setCompareIndicatorList([]);
    setIsCollapse({
      rainfall: true,
      flood: true,
      disaster_exposure: true,
    });
    setRainfallInterval("daily");
    setGraphErrorMessage("");
  };

  // * Re-setting location whenever admin/Basin selection changes
  useEffect(() => {
    const CountryData = selectedLocation?.country;
    if (CountryData) {
      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: CountryData,
          state: "",
          basin: "",
          region: [],
        };
      });
      handleResetFunc();
    }
    setShowChart(false); // close chart if open
  }, [adminRadio]);

  // * useeffect for stepper click changes
  useEffect(() => {
    if (isStepperClick) {
      setIsLoading(false);
      setGraphData([]);
      setShowChart(false);
      setChartData([]);
      setIndicatorList([]);
      setIsCompareOpen(false);
      setSelectedCompareIndicator([]);

      if (layerRef?.current?.length) {
        layerRef.current.map((i, index) => {
          removeLayers(layerRef.current[index]);
          return null;
        });
      }

      if (compareRef?.current?.length) {
        compareRef.current.map((i, index) => {
          removeLayers(compareRef.current[index]);
          return null;
        });
      }

      removeMapLayers(legend, comparelegend, layerControl);
      if (activeStep === 0) {
        setSelectedExposureTypes([]);
        setSelectedFloodDataSource({
          value: "",
          label: "Select Data source",
        });

        setCompareIndicatorList([]);
      } else if (activeStep === 1) {
        setSelectedExposureTypes([]);
        const filteredArr = compareIndicatorList?.filter((item) =>
          ["Rainfall"].includes(item.keyDatasource),
        );
        setCompareIndicatorList(filteredArr);
      }
    }
  }, [activeStep]);

  useEffect(() => {
    const CountryData = selectedLocation?.country;

    if (localStorage.getItem("i18nextLng") && CountryData) {
      setLocal(JSON.parse(localStorage.getItem("selectedCountry")));
      dispatch(
        mapPreferenceRequest({
          country_id: CountryData?.value,
          module: "rapid_response",
        }),
      );

      dispatch(
        corestateRequest({
          data: {
            parent_id: selectedLocation?.country?.value,
            location_type: State,
          },
          isCompareLocation: false,
        }),
      );

      window.scrollTo(0, 0);
      setLocal(CountryData);
      setPosition([CountryData?.latitude, CountryData?.longitude]);
      setZooms(CountryData?.zoom_level);

      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: CountryData,
          state: "",
          basin: "",
          region: [],
        };
      });

      dispatch(
        coreBasinRequest({
          data: {
            country_id: selectedLocation?.country?.value,
          },
          isCompareLocation: false,
        }),
      );
    }
    removeMapLayers(legend, comparelegend, layerControl);

    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setActiveStep(null);
    setIndicatorList([]);
    setCompareIndicatorList([]);
    setMapLayers([]);
    setIsCompareOpen(false);
    setSelectedCompareIndicator([]);
    setLayerData("");
    setRainfallInterval("daily");
    setGraphErrorMessage("");
  }, [selectedLocation?.country, localStorage.getItem("i18nextLng")]);

  // * Point graph data
  useEffect(() => {
    if (activeStep === null || activeStep <= 1) {
      if (selectedLocation?.region?.length) {
        const timeSeriesData = {};
        setShowChart(true);
        setIsGraphLoading(true);

        if (adminRadio === "admin") {
          timeSeriesData.country_name = selectedLocation?.country?.label;
          timeSeriesData.state_name = selectedLocation?.state?.label;
          timeSeriesData.start_date = dateFormatter(startDate);
          timeSeriesData.end_date = dateFormatter(endDate);
          timeSeriesData.rainfall_dataset = !_.isEmpty(
            selectedRainfallDataSource,
          )
            ? selectedRainfallDataSource?.value
            : "";
          timeSeriesData.interval = rainfallInterval;
        } else {
          timeSeriesData.country_name = selectedLocation?.country?.label;
          timeSeriesData.basin_name =
            selectedLocation?.basin?.value !== "" &&
            selectedLocation?.basin !== ""
              ? selectedLocation?.basin?.label
              : "";
          timeSeriesData.start_date = dateFormatter(startDate);
          timeSeriesData.end_date = dateFormatter(endDate);
          timeSeriesData.rainfall_dataset = !_.isEmpty(
            selectedRainfallDataSource,
          )
            ? selectedRainfallDataSource?.value
            : "";
          timeSeriesData.interval = rainfallInterval;
        }

        if (selectedLocation?.region && selectedLocation?.region?.length) {
          const API_URL = [];
          selectedLocation?.region.map((item) => {
            const arr = [];
            arr.push(item?.latlngs);
            timeSeriesData.geometry =
              item.layerType === "marker" ? arr : arr[0];
            const config = {
              layerType: item.layerType,
              latlng: item.layerType === "marker" ? item.latlngs : "",
            };

            API_URL.push(
              AXIOS_INSTANCE_LOADER.post(
                `${BASE_URL}/${selectedRainfallDataSource?.graph_url}`,
                timeSeriesData,
                config,
              ),
            );
            dispatch(startGraphLoader());
            return null;
          });
          Promise.all(API_URL)
            .then((response) => {
              const tempData = {};
              tempData.graph_data = response.map((item) => {
                const graph_data = item?.data?.result;
                graph_data.layerType = item?.config?.layerType;
                graph_data.latlng = item?.config?.latlng;
                return graph_data;
              });
              dispatch(stopGraphLoader());
              if (tempData?.graph_data?.length) {
                setChartData(tempData?.graph_data);
                setIsGraphLoading(false);
              } else {
                setChartData([]);
                setIsGraphLoading(false);
              }
            })
            .catch((error) => {
              setGraphErrorMessage(error?.response?.data?.message);
              dispatch(stopGraphLoader());
              setIsGraphLoading(false);
              setChartData([]);
            });
        }
      } else {
        setChartData([]);
        setShowChart(false);
        setIsGraphLoading(false);
        setMapLayers([]);
      }
    }
  }, [selectedLocation?.region, rainfallInterval]);

  // * Compare function
  const toggleCheckbox = (e, item) => {
    setShowChart(false);
    setIsDataSoruceChanged(true);
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedCompareIndicator((prev) => [...prev, item]);
    } else {
      setSelectedCompareIndicator((prev) =>
        prev.filter((indicator) => indicator.name !== item.name),
      );
      if (compareRef?.current?.length) {
        compareRef.current.map(() => {
          removeLayers(compareRef.current[item?.compareParamIndex]);
          return null;
        });
      }

      const compareLegendArray = [...comparelegend];

      if (compareLegendArray.length) {
        compareLegendArray.forEach((value) => {
          if (value.add) {
            value.add = false;
          }
        });
      }
      setcompareLegend(compareLegendArray);

      // * Setting opacity slider for previous layer
      const currentParamRef = layerRef.current[layerData?.paramIndex];
      if (currentParamRef && currentParamRef.current !== null) {
        currentParamRef.current?.eachLayer((layer) => {
          if (layerData?.map_url) {
            layer.setUrl(layerData?.map_url);
            setMapUrl({ name: "", url: layerData?.map_url, layer });
            const updatedIndexLayer = _.cloneDeep(indexLayers);
            updatedIndexLayer.push({ isCompare: false, layer });
            setIndexLayers(updatedIndexLayer);
          }
        });
      }

      removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
      setMapPreferencesData([]);
      const selectedLegend = JSON.parse(
        JSON.stringify(selectedMapPreferLegendData),
      );
      // for legend addpled to map-preferences
      if (selectedLegend.length) {
        selectedLegend.forEach(function mappreference(value) {
          if (value.add) {
            value.add = false;
          }
        });
        setSelectedMapPreferLegendData(selectedLegend);
      }
    }
  };

  return (
    <div className="dashboard-main-page common-dashboard-ftr">
      {isWeatherForecastOpen ? (
        <WeatherForecastModal
          isOpen={isWeatherForecastOpen}
          forecastData={forecastData}
          selectedLocation={selectedLocation}
          toggle={setIsWeatherForecastOpen}
          adminRadio={adminRadio}
        />
      ) : null}

      {isCompareOpen ? (
        <CompareComponent
          setSelectedCompareIndicator={setSelectedCompareIndicator}
          isCompareOpen={isCompareOpen}
          setIsCompareOpen={setIsCompareOpen}
          setIsCollapse={setIsCollapse}
          isCollapse={isCollapse}
          compareIndicatorList={compareIndicatorList}
          selectedCompareIndicator={selectedCompareIndicator}
          layerData={layerData}
          toggleCheckbox={toggleCheckbox}
        />
      ) : null}

      <div className="dash-main-row">
        <div className="dashboard-drought">
          <div className="dashboard-filter-div">
            <div>
              <div className="rapid-response-filter p-0 px-5 d-flex align-items-center">
                <Row className="my-1 w-100 d-flex align-items-center">
                  <Col
                    sm={3}
                    className="px-0 d-inline-flex align-items-center justify-content-start"
                  >
                    <img
                      src={BackButton}
                      alt="back-button"
                      onClick={() =>
                        navigate(`/rapid-response-newsfeed?slug=rapid_response`)
                      }
                      onKeyUp={() => {}}
                      className="pointer event-back-btn mr-1"
                      width={36}
                      height={36}
                    />
                    <h6 className="dashboard-filter-h3 m-0 rp-header-text">
                      {t("RAPID_RESPONSE.RAPID_RESPONSE")}
                    </h6>
                  </Col>
                  <Col sm={6} className="d-none d-lg-block">
                    <RapidResponseStepper
                      activeStep={activeStep}
                      setStepSize={setActiveStep}
                      setIsStepperClick={setIsStepperClick}
                    />
                  </Col>
                  <Col sm={3}>
                    <Button
                      className="rp-forecast-btn green-btn-sm"
                      type="button"
                      onClick={() => {
                        handleForecastData();
                      }}
                    >
                      {t("DASHBOARD.WEATHER_FORCAST")}
                    </Button>
                  </Col>
                </Row>
              </div>
              <Row className="dashboard-drought-section">
                <Col
                  lg={3}
                  className={
                    openPanel
                      ? "dasboard-drought-expand-1"
                      : "dashboard-drought-col1"
                  }
                >
                  <Button
                    className={
                      !openPanel
                        ? "button-expand-panel"
                        : "button-expand-panel-oepn"
                    }
                    onClick={() => setOpenPanel(!openPanel)}
                    aria-controls="example-collapse-text"
                    aria-expanded={openPanel}
                  >
                    {openPanel ? (
                      <div className="panel-btn">
                        <span className="mr-2">Expand Bar</span>
                        <FaAngleRight className="right-icon" />
                      </div>
                    ) : (
                      <div className="tooltip-div">
                        <span className="mr-2 tooltip-text">Hide Bar</span>
                        <FaAngleLeft className="right-icon right-icon-md float-right" />
                      </div>
                    )}
                  </Button>
                  <div
                    className={openPanel ? "d-none" : "dashboard-drought-div"}
                  >
                    <Filter
                      statesList={nextProps.statesList}
                      countryList={countryList}
                      updatePage={updatePage}
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      selectedLocation={selectedLocation}
                      handleSelectedLocation={handleSelectedLocation}
                      selectedRegions={selectedRegions}
                      loading={nextProps.isRainfallMapLoading}
                      selectedBasin={selectedBasin}
                      basinsList={nextProps.basinList}
                      setAdminRadio={setAdminRadio}
                      adminRadio={adminRadio}
                      setRainfallThreshold={setRainfallThreshold}
                      rainfallThreshold={rainfallThreshold}
                      setSelectedRainfallDataSource={
                        setSelectedRainfallDataSource
                      }
                      selectedRainfallDataSource={selectedRainfallDataSource}
                      activeStep={activeStep}
                      handleGetMap={handleGetMap}
                      mapPreferencesData={mapPreferencesData}
                      setSelectedFloodDataSource={setSelectedFloodDataSource}
                      selectedFloodDataSource={selectedFloodDataSource}
                      handleResetFunc={handleResetFunc}
                      setSelectedLocation={setSelectedLocation}
                      setSelectedExposureTypes={setSelectedExposureTypes}
                      selectedExposureTypes={selectedExposureTypes}
                      loadingMap={nextProps.isRainfallMapLoading || isLoading}
                      setIsDataSoruceChanged={setIsDataSoruceChanged}
                      setShowChart={setShowChart}
                      setIndicatorList={setIndicatorList}
                    />
                  </div>
                </Col>
                <Col
                  lg={openPanel ? 12 : 9}
                  className={openPanel ? "px-0" : "px-0 dashboard-drought-map"}
                >
                  <div
                    className={
                      showChart
                        ? "map-space-div visible-splitter"
                        : "map-space-div invisible-splitter"
                    }
                  >
                    <SplitterLayout
                      ref={splitterRef}
                      primaryIndex={0}
                      vertical
                      percentage
                      primaryMinSize={40}
                      secondaryInitialSize={0}
                      onSecondaryPaneSizeChange={(e) => setPaneleHeight(e)}
                    >
                      <div
                        className={
                          showChart
                            ? "dashboard-drought-col-graph-view"
                            : "dashboard-drought-col2"
                        }
                        ref={divRef}
                      >
                        <MapContainer
                          local={local}
                          legend={legend}
                          mapes={mapes}
                          setMapes={setMapes}
                          mapControl={mapControl}
                          mapPreferencesData={mapPreferencesData}
                          setMapPreferencesData={setMapPreferencesData}
                          setMapControl={setMapControl}
                          setLegend={setLegend}
                          layerControl={layerControl}
                          setLayerControl={setLayerControl}
                          comparelegend={comparelegend}
                          setcompareLegend={setcompareLegend}
                          layerData={layerData}
                          position={position}
                          zooms={zooms}
                          onHandleZoom={onHandleZoom}
                          setOpaci={setOpaci}
                          indexLayers={indexLayers}
                          setIndexLayers={setIndexLayers}
                          startDate={startDate}
                          endDate={endDate}
                          boundaryData={
                            nextProps.mapPreferenceList.mapPreferenceList
                          }
                          selectedLocation={selectedLocation}
                          setSelectedLocation={setSelectedLocation}
                          loadingMap={
                            nextProps.isRainfallMapLoading || isLoading
                          }
                          setOpen={setOpen}
                          adminRadio={adminRadio}
                          indicatorList={indicatorList}
                          layerRef={layerRef}
                          compareRef={compareRef}
                          selectedRainfallDataSource={
                            selectedRainfallDataSource
                          }
                          activeStep={activeStep}
                          setLayerData={setLayerData}
                          removeGeoJsonLayer={removeGeoJsonLayer}
                          setMapPreferences={setMapPreferences}
                          mapPreferences={mapPreferences}
                          removeLayers={removeLayers}
                          wrap={wrap}
                          removeMapLayers={removeMapLayers}
                          removeMapPreferenceLayer={removeMapPreferenceLayer}
                          mapPreferRefs={mapPreferRefs}
                          selectedMapPreferLegendData={
                            selectedMapPreferLegendData
                          }
                          setSelectedMapPreferLegendData={
                            setSelectedMapPreferLegendData
                          }
                          selectedFloodDataSource={selectedFloodDataSource}
                          setIsCallBoundary={setIsCallBoundary}
                          isCallBoundary={isCallBoundary}
                          setMapLayers={setMapLayers}
                          mapLayers={mapLayers}
                          isDataSourceChanged={isDataSourceChanged}
                          setShowChart={setShowChart}
                          selectedCompareIndicator={selectedCompareIndicator}
                          compareIndicatorList={compareIndicatorList}
                          setIsCompareOpen={setIsCompareOpen}
                          isCompareOpen={isCompareOpen}
                          mapUrl={mapUrl}
                          setMapUrl={setMapUrl}
                        />
                        {indicatorList.length && activeStep >= 0 ? (
                          <div
                            className={
                              openPanel
                                ? "rapid-response-tabs mt-5"
                                : "rapid-response-tabs"
                            }
                          >
                            <ScrollTabs
                              setTabIndex={setTabIndex}
                              tabIndex={tabIndex}
                              className=""
                              indicatorList={indicatorList}
                              layerData={layerData}
                              setLayerData={setLayerData}
                              setSelectedLocation={setSelectedLocation}
                              setMapLayers={setMapLayers}
                              setSelectedCompareIndicator={
                                setSelectedCompareIndicator
                              }
                              setIndexLayers={setIndexLayers}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="p-2 h-100" style={{ overflow: "hidden" }}>
                        {/* Rainfall Graph */}
                        {activeStep === null || activeStep <= 1 ? (
                          selectedLocation?.region?.length ? (
                            isGraphLoading ? (
                              <GraphLoader isRapidResponse={isGraphLoading} />
                            ) : (
                              <RainfallGraph
                                data={chartData[0]}
                                graphPanelHeight={graphPanelHeight}
                                selectedLocation={selectedLocation}
                                openPanel={openPanel}
                                startDate={startDate}
                                endDate={endDate}
                                open={open}
                                adminRadio={adminRadio}
                                selectedRainfallDataSource={
                                  selectedRainfallDataSource
                                }
                                setShowChart={setShowChart}
                                showChart={showChart}
                                isGraphLoading={isGraphLoading}
                                setRainfallInterval={setRainfallInterval}
                                rainfallInterval={rainfallInterval}
                                graphErrorMessage={graphErrorMessage}
                              />
                            )
                          ) : null
                        ) : null}
                        {/* Population and crop graph */}
                        {activeStep > 1 && activeStep !== null ? (
                          <Graph
                            data={chartData}
                            graphPanelHeight={graphPanelHeight}
                            selectedLocation={selectedLocation}
                            openPanel={openPanel}
                            startDate={startDate}
                            endDate={endDate}
                            open={open}
                            adminRadio={adminRadio}
                            setShowChart={setShowChart}
                          />
                        ) : null}
                      </div>
                    </SplitterLayout>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(RapidResponse);
