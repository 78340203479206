import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import EARLY_WARNING_HEALTH_CONST from "./actionTypes";
import {
  getNutritionTimeSeriesSuccess,
  getNutritionTimeSeriesError,
  getNutritionMapDataSuccess,
  getNutritionMapDataFailed,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import {
  // EARLY_WARNING_API,
  // AXIOS_INSTANCE,
  NUTRITION_URL,
  AXIOS_INSTANCE_LOADER,
} from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* getNutritionTimeSeriesRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.post(
        `${NUTRITION_URL}/timeseries`,
        action?.payload?.data,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getNutritionTimeSeriesSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.message || response.data.error,
      };
      yield put(getNutritionTimeSeriesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getNutritionTimeSeriesError(errorData));
  }
}

function* getNutritionMapData(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.post(
        `${NUTRITION_URL}/map`,
        action?.payload?.requestObj,
      ),
      "",
      "",
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(getNutritionMapDataSuccess(responseData));
    } else {
      const responseData = {
        data: response.data,
      };
      yield put(getNutritionMapDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getNutritionMapDataFailed(errorData));
  }
}

export function* watchHealth() {
  yield takeEvery(
    EARLY_WARNING_HEALTH_CONST.GET_NUTRITION_TIMESERIES_REQUEST,
    getNutritionTimeSeriesRequest,
  );
  yield takeEvery(
    EARLY_WARNING_HEALTH_CONST.GET_NUTRITION_DATA_REQUEST,
    getNutritionMapData,
  );
}

function* NutritionSaga() {
  yield all([fork(watchHealth)]);
}

export default NutritionSaga;
