const DASH_BOARD_CONST = {
  DASH_BOARD_MENU_REQUEST: "DASH_BOARD_MENU_REQUEST",
  DASH_BOARD_MENU_SUCCESS: "DASH_BOARD_MENU_SUCCESS",
  DASH_BOARD_MENU_ERROR: "DASH_BOARD_MENU_ERROR",

  INDICATOR_REQUEST: "INDICATOR_REQUEST",
  INDICATOR_SUCCESS: "INDICATOR_SUCCESS",
  INDICATOR_ERROR: "INDICATOR_ERROR",
};

export default DASH_BOARD_CONST;
