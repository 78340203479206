export const startLoader = () => ({
  type: "START_LOADING",
  payload: null,
});

export const stopLoader = () => ({
  type: "STOP_LOADING",
  payload: null,
});

export const weatherGraphStartLoader = () => ({
  type: "WEATHER_START_LOADING",
  payload: null,
});

export const weatherGraphStopLoader = () => ({
  type: "WEATHER_STOP_LOADING",
  payload: null,
});

export const startGraphLoader = () => ({
  type: "START_GRAPH_LOADING",
  payload: null,
});

export const stopGraphLoader = () => ({
  type: "STOP_GRAPH_LOADING",
  payload: null,
});
