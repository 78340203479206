const CLIMBER_CONST = {
  CLIMBER_BANNER_REQUEST: "CLIMBER_BANNER_REQUEST",
  CLIMBER_BANNER_SUCCESS: "CLIMBER_BANNER_SUCCESS",
  CLIMBER_BANNER_ERROR: "CLIMBER_BANNER_ERROR",

  CLIMBER_PARTNERS_REQUEST: "CLIMBER_PARTNERS_REQUEST",
  CLIMBER_PARTNERS_SUCCESS: "CLIMBER_PARTNERS_SUCCESS",
  CLIMBER_PARTNERS_ERROR: "CLIMBER_PARTNERS_ERROR",

  CLIMBER_ABOUT_US_REQUEST: "CLIMBER_ABOUT_US_REQUEST",
  CLIMBER_ABOUT_US_SUCCESS: "CLIMBER_ABOUT_US_SUCCESS",
  CLIMBER_ABOUT_US_ERROR: "CLIMBER_ABOUT_US_ERROR",

  CLIMBER_OTHER_TOOLS_REQUEST: "CLIMBER_OTHER_TOOLS_REQUEST",
  CLIMBER_OTHER_TOOLS_SUCCESS: "CLIMBER_OTHER_TOOLS_SUCCESS",
  CLIMBER_OTHER_TOOLS_ERROR: "CLIMBER_OTHER_TOOLS_ERROR",

  CLIMBER_FOOTER_REQUEST: "CLIMBER_FOOTER_REQUEST",
  CLIMBER_FOOTER_SUCCESS: "CLIMBER_FOOTER_SUCCESS",
  CLIMBER_FOOTER_ERROR: "CLIMBER_FOOTER_ERROR",
};

export default CLIMBER_CONST;
