import React from "react";
import { useOutlet } from "react-router-dom";
import Header from "../../components/Common/ClimberHeader";

function ClimberLayout() {
  const Outlet = useOutlet();
  window.scrollTo(0, 0);

  return (
    <div className="layout-div">
      <Header />
      <div className="second-container">{Outlet}</div>
    </div>
  );
}

export default ClimberLayout;
