import React, { useEffect } from "react";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";

function CommonScrollableTabs({
  className,
  tabIndex,
  scrollableYear,
  setScrollableyear,
  setSelectedmap,
}) {
  // define state with initial value to let the tabs start with that value
  const [activeTab, setActiveTab] = React.useState(0);

  useEffect(() => {
    setActiveTab(tabIndex);
  }, [tabIndex]);

  const onTabClick = (e, index) => {
    setActiveTab(index);
    setSelectedmap("");
    setScrollableyear((prev) => {
      return {
        ...prev,
        selectedYear: scrollableYear?.yearList?.[index],
      };
    });
  };

  // To Reset the activeTab back to zero whenever data is changed
  useEffect(() => {
    setActiveTab(0);
  }, []);

  return (
    <Tabs
      activeTab={activeTab}
      tabsScrollAmount={3}
      onTabClick={onTabClick}
      className={className}
    >
      {/* generating an array to loop through it  */}
      {scrollableYear?.yearList?.map((item) => {
        return (
          <Tab key={item}>
            <b>{item}</b>
          </Tab>
        );
      })}
    </Tabs>
  );
}

export default CommonScrollableTabs;
