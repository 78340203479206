import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import _ from "lodash";

import { ReactComponent as AlertIcon } from "../../assest/img/alert-icon.svg";
import { ReactComponent as EditIcon } from "../../assest/img/ea-edit.svg";
import ClimberLogo from "../../assest/img/ef-climber-logo.png";
import IWMILogo from "../../assest/img/ef-iwmi-logo.png";
import Flood from "../../assest/img/EF-Flood-Banner.png";
import Drought from "../../assest/img/EF-Drought-Banner.png";
import LandSlide from "../../assest/img/EF-Landslide-Banner.png";
import {
  editEarlyFinanceReportRequest,
  createEarlyFinanceReportRequest,
} from "../../store/actions";
import StaticMapContainer from "../../components/Common/StaticMapContainer";
import SideEditor from "./Editor";
import { dateFormatters } from "../../helpers";

function SummaryComponent({
  isGenerate,
  projectDetails,
  reportData,
  isEditData,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [position, setPosition] = useState([]);
  const [zooms, setZooms] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageFileData, setImageFileData] = useState("");
  const [alertData, setAlertDara] = useState([]);

  // For getting Selected Country and updating map initial load
  useEffect(() => {
    if (!_.isEmpty(projectDetails)) {
      setSelectedCountry(projectDetails);
      setPosition([projectDetails?.latitude, projectDetails?.longitude]);
      setZooms(12);

      const bannerData = [
        {
          type: "Flood",
          path: Flood,
        },
        {
          type: "Drought",
          path: Drought,
        },
        {
          type: "LandSlide",
          path: LandSlide,
        },
      ];

      const defaultBanner = bannerData.filter(
        (item) => item?.type === projectDetails?.risk_type_name,
      );

      const alert = [
        {
          id: 0,
          label: "Country: ",
          value: projectDetails?.country_name,
        },
        {
          id: 1,
          label: "Region: ",
          value: projectDetails?.state_name,
        },
        {
          id: 2,
          label: "District: ",
          value: projectDetails?.district_name,
        },
        {
          id: 3,
          label: "Risk Type: ",
          value: "Flood", // Will get from ALERT API
        },
        {
          id: 4,
          label: "Alert Type: ",
          value: "Severe", // Will get from ALERT API
        },
      ];
      setAlertDara(alert);

      const reportTitle = reportData?.title
        ? reportData?.title
        : `${projectDetails?.country_name} Aware Report`;

      const reportBanner = reportData?.banner_image
        ? reportData?.banner_image
        : defaultBanner[0]?.path;
      setTitle(reportTitle);
      setImageUrl(reportBanner);
    }
  }, [reportData]);

  const backgrundStyle = {
    background: imageUrl?.length
      ? `linear-gradient(to right, #354030 0%, rgba(0, 0, 0, 0.51) 38.02%, rgba(0, 0, 0, 0.00) 62.50%), url(${imageUrl}) center/cover no-repeat`
      : "none",
  };

  const handleEditCall = (header, image) => {
    const payload = {
      project_id: projectDetails?.id,
    };

    if (isEditData) {
      payload.id = isEditData ? reportData?.id : "";
    }

    if (header) {
      payload.title = header;
    }
    if (image) {
      payload.banner_image = {
        base64: image.split(",")?.[1],
        file: imageFileData?.name,
      };
    }

    if (isEditData) {
      dispatch(editEarlyFinanceReportRequest(payload));
    } else {
      dispatch(createEarlyFinanceReportRequest(payload));
    }
  };

  return (
    <div className="ea-space-y mb-3">
      {isEditorOpen ? (
        <SideEditor
          setIsEditorOpen={setIsEditorOpen}
          isEditorOpen={isEditorOpen}
          setTitle={setTitle}
          title={title}
          setImageUrl={setImageUrl}
          setImageFileData={setImageFileData}
          handleEditCall={handleEditCall}
        />
      ) : (
        ""
      )}
      <Row className="summary-report-container" id="report-summary">
        <Col xs={12} className="ef-header-logos">
          <img
            src={ClimberLogo}
            alt="climber-logo"
            className="ef-header-logo"
            height={48}
          />
          <img
            src={IWMILogo}
            alt="iwmi-logo"
            className="ef-header-logo"
            height={60}
          />
        </Col>
        <Col
          xs={12}
          className={
            imageUrl
              ? "report-background-img mb-3 "
              : "report-background-img mb-3 background-image-deafult"
          }
          style={imageUrl ? backgrundStyle : {}}
        >
          {!isGenerate ? (
            <span
              role="button"
              className="report-edit-button"
              onKeyUp={() => {}}
              onClick={() => setIsEditorOpen(!isEditorOpen)}
              tabIndex="0"
              aria-label="summaryEditButton"
            >
              <EditIcon />
            </span>
          ) : (
            ""
          )}
          <div className="d-flex flex-column align-items-start h-100 button-container pl-3">
            <div>
              <p className="report-text">
                {t("EARLY_FINANCE_REPORT.ALERT_REPORT")}
              </p>
              <h1 className="report-main-header">{title}</h1>
            </div>
            {/* We will get from API */}
            <span className="report-prepared-text">
              Prepared on : {dateFormatters(new Date())}
            </span>
          </div>
        </Col>
        <Col xs={3} className="alert-container">
          <div className="alert-header">
            <AlertIcon width={36} height={36} />{" "}
            <span className="alert-card-header" title={selectedCountry?.name}>
              {selectedCountry?.name}
            </span>
          </div>
          <hr className="alert-divider" />
          {/* We will get from API */}
          <div className="alert-data">
            {alertData?.length
              ? alertData?.map((item) => (
                  <div key={item.id}>
                    <span className="alert-data-label">{item.label}</span>
                    <span className="alert-data-text">{item.value}</span>
                  </div>
                ))
              : ""}
          </div>
        </Col>
        <Col xs={9} className="pr-0">
          {position?.length ? (
            <StaticMapContainer
              position={position}
              zoom={zooms}
              classname="summary-map-container"
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
    </div>
  );
}

export default SummaryComponent;
