import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";

function EarlyActionTable(props) {
  const { COLUMNS, tableData } = props;

  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const data = useMemo(() => tableData, [tableData]);

  return (
    <div className="early-action-table">
      <MaterialReactTable
        columns={columns}
        data={data}
        muiTableBodyCellSkeletonProps={{
          sx: {
            maxHeight: "600px",
            overflowY: "none",
          },
        }}
        muiTableContainerProps={{
          sx: {
            height: "100%",
          },
        }}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        enablePagination={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableHiding={false}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableGlobalFilter={false}
        enableSorting={false}
        enableColumnDragging={false}
        enableStickyHeader
        muiTableHeadCellProps={{
          align: "center",
          sx: {
            border: "0px",
            fontFamily: "National",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 650,
            lineHeight: "normal",
          },
        }}
        muiTableBodyCellProps={{
          align: "center",
          sx: {
            border: "0px",
            fontWeight: 500,
            fontFamily: "National",
            fontSize: "16px",
            fontStyle: "normal",
            lineHeight: "normal",
          },
        }}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
      />
    </div>
  );
}

export default EarlyActionTable;
