import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import MAP_PREFERENCE_CONST from "./actionTypes";
import {
  mapPreferenceSuccess,
  mapPreferenceError,
  mapPreferenceDataSuccess,
  mapPreferenceDataError,
} from "./action";
import checkHttpStatus from "../apiUtils";
import {
  MAP_PREFERENCES,
  AXIOS_INSTANCE,
  AXIOS_INSTANCE_LOADER,
} from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* mapPreferenceList(action) {
  AXIOS_INSTANCE_LOADER.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE_LOADER.get(
        `${MAP_PREFERENCES}/get-layers/${action.payload.country_id}/${action.payload.module}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };
      yield put(mapPreferenceSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(mapPreferenceError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(mapPreferenceError(errorData));
  }
}

function* mapPreferenceDataList(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");

  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${MAP_PREFERENCES}/data`, action.payload?.data),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
        soil: action?.payload?.data?.soil_texture_class
          ? action?.payload?.data?.soil_texture_class
          : "",
        gdpYear: action?.payload?.data?.gdp_year
          ? action?.payload?.data?.gdp_year
          : "",

        payload: {
          ...action.payload?.boundry,
          category: action.payload.boundry.map_preference_category_name,
          legendName: action.payload.boundry.legend_name,
        },
      };
      yield put(mapPreferenceDataSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(mapPreferenceDataError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(mapPreferenceDataError(errorData));
  }
}

export function* watchMapPreferenceList() {
  yield takeEvery(
    MAP_PREFERENCE_CONST.MAP_PREFERENCE_REQUEST,
    mapPreferenceList,
  );
}

export function* watchMapPreferenceDataList() {
  yield takeEvery(
    MAP_PREFERENCE_CONST.MAP_PREFERENCE_DATA_REQUEST,
    mapPreferenceDataList,
  );
}

function* watchMapPreferenceSaga() {
  yield all([fork(watchMapPreferenceList), fork(watchMapPreferenceDataList)]);
}

export default watchMapPreferenceSaga;
