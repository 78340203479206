import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { BsArrowLeft } from "react-icons/bs";
import PreparednessPhase from "./preparednessPhase";
import ReadynessPhase from "./readinessPhase";
import EarlyActionProcess from "../../components/Common/EarlyActionProcess";
import { userDetailsRequest } from "../../store/actions";
import { userDetails } from "../../components/Common/Utils";

function PhasesComponent() {
  const [, setOpen] = useOutletContext(); // for closing side bar when it is opening
  const params = new URLSearchParams(window.location.search);

  const selectedSubModule = params.get("slug");

  const history = useNavigate();
  const dispatch = useDispatch();
  const [locationState, setLocationState] = useState();
  const [stepSize, setStepSize] = useState();
  const [isAllStepsCompleted, setIsAllStepsCompleted] = useState(false);

  const nextProps = useSelector((state) => ({
    userDetails: state.User?.userDetails,
  }));

  useEffect(() => {
    const userData = userDetails();
    dispatch(userDetailsRequest({ id: userData?.user_details?.[0]?.id }));
  }, []);

  const stepURL = localStorage.getItem("stepperActiveStep");
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("projectDetails"));
    setLocationState(data);
    if (data?.ea_ap_status) setIsAllStepsCompleted(true);
    if (stepURL) {
      setStepSize(Number(stepURL));
    }
  }, [stepSize]);

  const handleClickBack = () => {
    localStorage.setItem("stepperActiveStep", Number(stepURL) - 1);
    const activeStep1 = localStorage.getItem("stepperActiveStep");
    if (!_.isEmpty(locationState)) {
      if (locationState?.ea_pp_status) {
        localStorage.setItem("subStepperActivePhase", 3);
      }
    }

    if (Number(activeStep1) >= 0) {
      setStepSize(Number(activeStep1));
    } else {
      history(`/ea-projects?slug=${selectedSubModule}`);
    }
  };

  function getStepContent() {
    switch (stepSize) {
      case 0:
        return (
          <PreparednessPhase
            projectDetails={locationState}
            setStepSize={setStepSize}
            permissionsList={nextProps.userDetails?.user_permissions}
            isAllStepsCompleted={isAllStepsCompleted}
            setOpen={setOpen}
          />
        );
      case 1:
      case 2:
        return (
          <ReadynessPhase
            projectDetails={locationState}
            setStepSize={setStepSize}
            permissionsList={nextProps.userDetails?.user_permissions}
            isAllStepsCompleted={isAllStepsCompleted}
            setOpen={setOpen}
          />
        );

      default:
        return "Unknown stepIndex";
    }
  }

  return (
    <div className="ea-project-container dashboard-filter-div">
      <div className="EA-Header ea-space-between">
        <div>
          <BsArrowLeft
            className="back-icon-ea"
            onClick={handleClickBack}
            role="button"
          />
          <p className="ea-header-text">{locationState?.name} </p>
        </div>
      </div>
      <EarlyActionProcess setStepSize={setStepSize} activeStep={stepSize} />
      {/* Need to change based on stepper */}
      {getStepContent()}
    </div>
  );
}

export default PhasesComponent;
