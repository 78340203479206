import CLIMBER_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};

const Climber = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case CLIMBER_CONST.CLIMBER_BANNER_REQUEST:
      state = {
        ...state,
        loading: true,
        climberBannerData: null,
        error: "",
      };
      break;
    case CLIMBER_CONST.CLIMBER_BANNER_SUCCESS:
      state = {
        ...state,
        loading: false,
        climberBannerData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case CLIMBER_CONST.CLIMBER_BANNER_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        climberBannerData: null,
      };
      break;

    case CLIMBER_CONST.CLIMBER_PARTNERS_REQUEST:
      state = {
        ...state,
        loading: true,
        climberPartnerData: null,
        error: "",
      };
      break;
    case CLIMBER_CONST.CLIMBER_PARTNERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        climberPartnerData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case CLIMBER_CONST.CLIMBER_PARTNERS_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        climberPartnerData: null,
      };
      break;

    case CLIMBER_CONST.CLIMBER_ABOUT_US_REQUEST:
      state = {
        ...state,
        loading: true,
        climberAboutUs: null,
        error: "",
      };
      break;
    case CLIMBER_CONST.CLIMBER_ABOUT_US_SUCCESS:
      state = {
        ...state,
        loading: false,
        climberAboutUs:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case CLIMBER_CONST.CLIMBER_ABOUT_US_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        climberAboutUs: null,
      };
      break;

    case CLIMBER_CONST.CLIMBER_OTHER_TOOLS_REQUEST:
      state = {
        ...state,
        loading: true,
        climberOtherTools: null,
        error: "",
      };
      break;
    case CLIMBER_CONST.CLIMBER_OTHER_TOOLS_SUCCESS:
      state = {
        ...state,
        loading: false,
        climberOtherTools:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case CLIMBER_CONST.CLIMBER_OTHER_TOOLS_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        climberOtherTools: null,
      };
      break;

    case CLIMBER_CONST.CLIMBER_FOOTER_REQUEST:
      state = {
        ...state,
        loading: true,
        climberFooterData: null,
        error: "",
      };
      break;
    case CLIMBER_CONST.CLIMBER_FOOTER_SUCCESS:
      state = {
        ...state,
        loading: false,
        climberFooterData:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case CLIMBER_CONST.CLIMBER_FOOTER_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        climberFooterData: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Climber;
