import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { GrFormClose } from "react-icons/gr";

function ClimateAdaptModal({
  toggle,
  modal,
  title,
  description,
  onHandleSubmitData,
}) {
  const { t } = useTranslation();

  const onHandleSubmit = () => {
    onHandleSubmitData();
  };
  return (
    <div>
      <Modal isOpen={modal} size="md" toggle={toggle}>
        <ModalHeader className=" delete-header mr-2">
          <p className="climber-header-text ">{title}</p>
        </ModalHeader>
        <ModalBody className="climate-modal-padding">
          <div className=" mb-2">
            <p className="mt-2 pb-1 climber-body-text">{description}</p>
          </div>
          <div className="text-center mt-4  ">
            <Button
              className="climate-adapt-open-btn ml-2"
              onClick={() => onHandleSubmit()}
            >
              {t("CLIMBER_HOME_PAGE.OPEN")}
            </Button>
          </div>
        </ModalBody>
        <GrFormClose
          size={30}
          onClick={() => toggle()}
          className="pointer closebtn-adaptmodal"
        />
      </Modal>
    </div>
  );
}

export default ClimateAdaptModal;
