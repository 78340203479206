import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import _ from "lodash";

import { GrFormClose } from "react-icons/gr";
import { ReactComponent as PlusIcon } from "../../assest/img/plus-icon.svg";
import { ReactComponent as AddMedia } from "../../assest/img/add-media.svg";
import ViewIcon from "../../assest/img/readynessView.png";
import DeleteIcon from "../../assest/img/readynessDelete.png";
import { ReactComponent as LinkIcon } from "../../assest/img/ef-link.svg";
// import SendBtn from "../../assest/img/commentSend.svg";
import {
  createEarlyFinanceReportRequest,
  deleteEarlyFinanceReferencesRequest,
  // editEarlyFinanceReportRequest,
} from "../../store/actions";
import MediaReferenceModal from "../../components/Common/MediaReferenceModal";
import { showError } from "../../components/Common/Notification";
import DeleteModal from "../../components/Common/DeleteConfirmationModal";
import CarouselModal from "../../components/Common/CarouselModal";

function MediaReferenceComponent({
  isGeneratedSelected,
  projectDetails,
  reportData,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [mediaReferneces, setMediaReferences] = useState([]);
  const [imageReferences, setImageReferences] = useState([]);
  const [urlReferences, setUrlReferences] = useState([]);
  // const [remark, setRemark] = useState("");
  const [deleteReference, setDeleteReference] = useState({});

  const handleAddMediaReference = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Setting media files data
  useEffect(() => {
    if (!_.isEmpty(reportData)) {
      setDeleteReference({});
      let media = [];
      let urls = [];
      if (
        reportData?.media_reference_file?.length ||
        reportData?.media_reference_url?.length
      ) {
        if (reportData?.media_reference_file?.length) {
          media = reportData?.media_reference_file?.map((item) => {
            return {
              type: "image",
              link: item,
            };
          });
        }
        if (reportData?.media_reference_url?.length) {
          urls = reportData?.media_reference_url?.map((item) => {
            return {
              type: "url",
              link: item,
            };
          });
        }
        const data = [...media, ...urls].filter(
          (item) => item !== null || item !== "",
        );
        setUrlReferences(urls);
        setImageReferences(media);
        setMediaReferences(data);
      } else {
        setMediaReferences([]);
      }
      // if (reportData?.media_reference_remarks?.length) {
      //   setRemark(reportData?.media_reference_remarks[0]);
      // }
    }
  }, [reportData]);

  // API CALL
  const handleSetMediaReferences = (files) => {
    if (files?.length + mediaReferneces?.length > 8) {
      showError(t("EARLY_FINANCE_REPORT.MAX_FILES_LIMIT"));
    } else {
      const payload = {
        project_id: projectDetails?.id,
        id: !_.isEmpty(reportData) ? reportData?.id : "",
      };

      const mediaFiles = [];
      const mediaUrls = [];

      files.forEach((item) => {
        if (item?.file?.type === "image/png") {
          mediaFiles.push({
            base64: item.base64.split(",")?.[1],
            file: item?.file?.name,
          });
        } else {
          mediaUrls.push(item?.url);
        }
      });
      if (mediaFiles?.length) {
        payload.media_refernce_file = [...mediaFiles];
      }

      if (mediaUrls?.length) {
        payload.media_reference_url = [...mediaUrls];
      }
      dispatch(createEarlyFinanceReportRequest(payload));
    }
  };

  // DELETING MEDIA REFERENCES API CALL
  const handleDeleteMediaReference = () => {
    const payload = { id: reportData?.id };
    if (deleteReference?.type === "image") {
      payload.media_reference_file = deleteReference?.url;
    }
    if (deleteReference?.type === "url") {
      payload.media_reference_url = deleteReference?.url;
    }
    setIsDeleteModalOpen(!isDeleteModalOpen);
    dispatch(deleteEarlyFinanceReferencesRequest(payload));
  };

  // const onHandleComments = () => {
  //   const payload = {
  //     project_id: projectDetails?.id,
  //     media_reference_remarks: [remark],
  //   };
  //   if (!_.isEmpty(reportData)) {
  //     payload.id = reportData.id;
  //     dispatch(editEarlyFinanceReportRequest(payload));
  //   } else {
  //     dispatch(createEarlyFinanceReportRequest(payload));
  //   }
  // };

  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter" && remark !== "") {
  //     onHandleComments();
  //   }
  // };

  return (
    <>
      {isCarouselOpen ? (
        <CarouselModal
          carouselData={imageReferences}
          toggle={setIsCarouselOpen}
          modal={isCarouselOpen}
        />
      ) : (
        ""
      )}
      {isModalOpen ? (
        <MediaReferenceModal
          modal={isModalOpen}
          toggle={setIsModalOpen}
          handleSetMediaReferences={handleSetMediaReferences}
        />
      ) : (
        ""
      )}
      {isDeleteModalOpen ? (
        <DeleteModal
          modal={isDeleteModalOpen}
          toggle={setIsDeleteModalOpen}
          onConfirmDelete={handleDeleteMediaReference}
          message={t("EARLY_FINANCE_REPORT.DELETE_REFERENCE")}
          title={t("EARLY_FINANCE_REPORT.DELETE_MEDIA_REFERENCE_QUESTION")}
        />
      ) : (
        ""
      )}
      <div className="intervention-div w-100">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="intervention-h2" id="media-reference">
            {t("EARLY_FINANCE_REPORT.MEDIA_REFERENCES")}
          </h2>
          {mediaReferneces?.length ? (
            <div className="actions-container d-flex align-items-center justify-content-end">
              {!isGeneratedSelected ? (
                <Button
                  className="ef-add-row-btn"
                  onClick={handleAddMediaReference}
                >
                  {t("EARLY_FINANCE_REPORT.ADD_NEW_MEDIA_REFERENCE")}
                  <PlusIcon />
                </Button>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        <Row>
          {mediaReferneces?.length ? (
            <>
              <div
                className={
                  mediaReferneces?.length
                    ? "summary-container my-1 p-2"
                    : "mt-3"
                }
              >
                {imageReferences?.length
                  ? imageReferences?.map((item) => (
                      <div
                        className="media-card-container pointer"
                        key={item?.id}
                      >
                        <div className="media-card-content">
                          <img
                            src={item?.link}
                            alt={item?.link}
                            height={200}
                            width={300}
                            className="ef-report-image-attachment"
                          />
                        </div>
                        {!isGeneratedSelected ? (
                          <div className="media-card-section-2">
                            <div className="card-showing">
                              <div>
                                <img
                                  src={ViewIcon}
                                  alt="View"
                                  onKeyDown={() => {}}
                                  width={48}
                                  height={48}
                                  onClick={() =>
                                    setIsCarouselOpen(!isCarouselOpen)
                                  }
                                />
                              </div>
                              <span
                                role="button"
                                className="mx-2"
                                onKeyUp={() => {}}
                                tabIndex="0"
                                onClick={() => {
                                  setIsDeleteModalOpen(!isDeleteModalOpen);
                                  setDeleteReference({
                                    type: "image",
                                    url: item?.link,
                                  });
                                }}
                              >
                                <img
                                  src={DeleteIcon}
                                  alt="Delete"
                                  onKeyDown={() => {}}
                                  width={48}
                                  height={48}
                                />
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ))
                  : ""}
              </div>
              <div className="w-100 my-1 p-2">
                {urlReferences?.length
                  ? urlReferences?.map((item) => (
                      <div
                        key={item?.id}
                        className="attachment-doc d-flex justify-content-between mb-1"
                      >
                        <div className="upload-section ml-2">
                          <LinkIcon />
                          <a
                            href={item?.link}
                            target="_blank"
                            rel="noreferrer"
                            className="ml-2"
                          >
                            {item?.link}
                          </a>
                        </div>
                        {!isGeneratedSelected ? (
                          <span
                            role="button"
                            className="report-delete-attachment-btn"
                            onKeyUp={() => {}}
                            tabIndex="0"
                            onClick={() => {
                              setIsDeleteModalOpen(!isDeleteModalOpen);
                              setDeleteReference({
                                type: "url",
                                url: item?.link,
                              });
                            }}
                            aria-label="reportMediaREfDelete"
                          >
                            <GrFormClose size={24} />
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    ))
                  : ""}
              </div>
            </>
          ) : (
            <Col
              xs={12}
              className="ef-media-add-container cursor-pointer w-100"
              onClick={handleAddMediaReference}
            >
              <AddMedia width={46} height={46} />
              <span className="add-media-text">
                {t("EARLY_FINANCE_REPORT.CLICK_HERE_TEXT")}
              </span>
            </Col>
          )}
        </Row>
      </div>
      {/* Commenting for now */}
      {/* <div className="w-100">
        <Col
          sm={12}
          className="mx-auto d-flex flex-column justify-conten-start w-100 mb-3"
        >
          <Label className="region-label mt-0 ml-2">
            {" "}
            {t("EARLY_FINANCE_REPORT.REMARK")}{" "}
          </Label>
          {!isGeneratedSelected ? (
            <div className="send-container">
              <Input
                classNamePrefix="react-select"
                className="ea-text-field"
                placeholder="Write your remarks here"
                onChange={(e) => setRemark(e.target.value)}
                value={remark}
                onKeyDown={handleKeyDown}
              />

              <img
                src={SendBtn}
                alt="send"
                className="send-btn"
                onClick={onHandleComments}
                onKeyDown={() => {}}
              />

              {remark?.length > 250 ? (
                <p className="error">{t("VALIDATION.COMMENT_VALIDAATION")}</p>
              ) : (
                ""
              )}
            </div>
          ) : (
            <p className="remark-padding">
              {remark?.length ? remark : t("EARLY_FINANCE_REPORT.NO_REMARK")}
            </p>
          )}
        </Col>
      </div> */}
    </>
  );
}

export default MediaReferenceComponent;
