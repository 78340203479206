import React, { useState, useEffect } from "react";
import { Alert } from "reactstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { GrFormClose } from "react-icons/gr";
import _ from "lodash";
import { renderChartTime } from "../../components/Common/Utils";
import HighChartGraph from "../../components/Common/HighCharts";
import {
  Line,
  CHART_LABEL_COLORS,
  chartColorLabel,
  HighChartConstants,
  // DrysPell,
} from "../../constants";
import { dateFormatters } from "../../helpers";
import GraphLoader from "../../components/GraphLoader";
import { handleExportToCSVOpenWeather } from "../../helpers/excelDownload";

function FloodGraph({
  indicatorsData,
  compareIndicatorData,
  setShowChart,
  showChart,
  chartDatas,
  aggregation,
  startDate,
  endDate,
  selectedLocation,
  errorData,
  setErrorData,
  secondaggregation,
  compare,
  adminRadio,
}) {
  const myDiv = document.getElementById("graph1");
  const containerWidth = myDiv?.offsetWidth;
  const containerHeight = myDiv?.offsetHeight;
  const { t } = useTranslation();
  const [highChartData, setHighChartData] = useState({
    xaxis: [],
    yaxis: [],
    serise: [],
  });
  const [chartWidth, setChartWidth] = useState(containerWidth);
  const [showGraph, setGraphShow] = useState(false);

  const nextProps = useSelector((state) => ({
    floodTimeseriesData: state.Drought.floodTimeseriesData,
    floodTimeSeriesloading: state.Drought.floodTimeSeriesloading,
    graphLoading: state.Loader.graphLoading,
    floodTimeseriesError: state.Drought.error,
  }));

  // const indicatorName =
  //   indicatorsData?.indices_slug === DrysPell
  //     ? "Daily Rainfall"
  //     : indicatorsData?.indices_name;
  // const CompareIndicatorName =
  //   compareIndicatorData?.indices_slug === DrysPell
  //     ? "Daily Rainfall"
  //     : compareIndicatorData?.indices_name;

  const options = {
    title: {
      text:
        compareIndicatorData?.indices_name && compare
          ? `${indicatorsData?.indices_name} ( ${aggregation?.label} ) - ${compareIndicatorData?.indices_name}  ( ${secondaggregation?.label} )`
          : `${indicatorsData?.indices_name}  ( ${aggregation?.label} )`,
      style: {
        fontSize: "17px",
        color: "#435A52",
      },
    },
    chart: {
      type: "line",
      height: containerHeight - 15,
      width: chartWidth,
      zoomType: "x",
      resetZoomButton: {
        position: {
          align: "left",
          verticalAlign: "top",
          x: 10,
          y: 10,
        },
      },
    },

    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          align: "left",
          x: 35,
          y: -5,
          menuItems: [
            "viewFullscreen", // View in full screen
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
            {
              text: "Export to CSV",
              onclick() {
                // eslint-disable-next-line react/no-this-in-sfc
                const seriesData = this.series.map((series) => ({
                  name: series.name,
                  data: series.data.map((point) => point.y),
                }));
                // eslint-disable-next-line react/no-this-in-sfc
                const xAxisData = this.xAxis[0].categories;
                handleExportToCSVOpenWeather(
                  seriesData,
                  xAxisData,
                  indicatorsData?.indices_name,
                );
                // Log series data and x-axis data to the console
              },
            },
          ],
        },
      },
    },
    legend: {
      layout: "horizontal",
      align: "left",
      verticalAlign: "bottom",
    },
    xAxis: [
      {
        categories: highChartData?.xaxis,
        crosshair: false,
        gridLineWidth: 2,
      },
    ],
    yAxis: highChartData?.yaxis,

    tooltip: {
      shared: true,
      useHTML: true,
      headerFormat: '<table><tr><th colspan="2">{point.x}</th></tr>',
      pointFormat:
        "<tr><td >{series.name} : </td>" +
        '<td style="text-align: right"><b>{point.y} </b></td></tr>',
      footerFormat: "</table>",
      valueDecimals: 2,
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          borderRadius: HighChartConstants?.two,

          allowOverlap: true,
        },
      },
    },

    series: highChartData?.serise,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  const onSetDataValue = (formattedDates, data) => {
    const b = [];

    data?.map((eachData, index) => b.push([formattedDates[index], eachData]));
    return b;
  };

  useEffect(() => {
    if (nextProps?.floodTimeseriesData) {
      if (
        nextProps?.floodTimeseriesData?.graph_data?.[0] &&
        compare &&
        compare
      ) {
        const chartData = nextProps?.floodTimeseriesData;
        let dates;
        const yaxisdata = [];
        const yaxisSerise = [];
        let formattedDates = [];
        if (!_.isEmpty(chartData) && chartData?.graph_data?.length) {
          dates = chartData?.graph_data[0]?.millis;
          if (chartData?.graph_data?.length === 2) {
            // eslint-disable-next-line no-unused-vars
            dates =
              chartData?.graph_data[0]?.millis.length >
              chartData?.graph_data[1]?.millis.length
                ? chartData?.graph_data[0]?.millis
                : chartData?.graph_data[1]?.millis;
          }
        }
        // eslint-disable-next-line no-unused-vars
        formattedDates = dates.map((item) => {
          const dateFormat = new Date(item);
          return renderChartTime(dateFormat);
        });

        const width =
          formattedDates.length >= HighChartConstants?.thirty
            ? formattedDates?.length * HighChartConstants?.fiftyFive
            : null;
        containerWidth > width
          ? setChartWidth(containerWidth)
          : setChartWidth(width);

        chartData?.graph_data?.map((eachData, index) => {
          const data1 = onSetDataValue(formattedDates, eachData?.data);

          const seriseData = {
            name:
              index === 0
                ? `${indicatorsData?.indices_name} (${
                    adminRadio === "admin"
                      ? eachData?.location?.district === ""
                        ? eachData?.location?.state === ""
                          ? eachData?.location?.country
                          : eachData?.location?.state
                        : eachData?.location?.district
                      : eachData?.location?.sub_basin === ""
                      ? eachData?.location?.basin === ""
                        ? eachData?.location?.country
                        : eachData?.location?.basin
                      : eachData?.location?.sub_basin
                  })`
                : `${compareIndicatorData?.indices_name} (${
                    adminRadio === "admin"
                      ? eachData?.location?.district === ""
                        ? eachData?.location?.state === ""
                          ? eachData?.location?.country
                          : eachData?.location?.state
                        : eachData?.location?.district
                      : eachData?.location?.sub_basin === ""
                      ? eachData?.location?.basin === ""
                        ? eachData?.location?.country
                        : eachData?.location?.basin
                      : eachData?.location?.sub_basin
                  })`,
            type: "line",
            data: data1,
            yAxis: index,
            color: chartColorLabel[index],
            dataLabels: {
              format: "{y:.2f}",
              backgroundColor: chartColorLabel[index],
              borderRadius: HighChartConstants?.three,
              padding: HighChartConstants?.three,
              y:
                index === 0
                  ? HighChartConstants?.minusTen
                  : HighChartConstants?.thirty,
              style: {
                color: HighChartConstants?.white,
                textOutline: "none",
              },
            },
          };
          const yaxis = {
            lineColor: chartColorLabel[index],
            lineWidth: 1,
            tickLength: 10,
            tickWidth: 1,
            tickAmount: 6,
            tickColor: chartColorLabel[index],
            labels: {
              format: "{value:.1f}",
              style: {
                size: 12,
                color: chartColorLabel[index],
              },
            },
            title: {
              text:
                index === 0
                  ? `${indicatorsData?.indices_name} - ${
                      adminRadio === "admin"
                        ? eachData?.location?.district === ""
                          ? eachData?.location?.state === ""
                            ? eachData?.location?.country
                            : eachData?.location?.state
                          : eachData?.location?.district
                        : eachData?.location?.sub_basin === ""
                        ? eachData?.location?.basin === ""
                          ? eachData?.location?.country
                          : eachData?.location?.basin
                        : eachData?.location?.sub_basin
                    }`
                  : `${compareIndicatorData?.indices_name} -${
                      adminRadio === "admin"
                        ? eachData?.location?.district === ""
                          ? eachData?.location?.state === ""
                            ? eachData?.location?.country
                            : eachData?.location?.state
                          : eachData?.location?.district
                        : eachData?.location?.sub_basin === ""
                        ? eachData?.location?.basin === ""
                          ? eachData?.location?.country
                          : eachData?.location?.basin
                        : eachData?.location?.sub_basin
                    }`,
              style: {
                color: chartColorLabel[index],
                fontWeight: "bold",
              },
            },
          };
          if (index > 0) {
            yaxis.opposite = true;
          }
          yaxis.showEmpty = false;
          yaxisdata.push(yaxis);
          yaxisSerise.push(seriseData);
          return null;
        });
        setHighChartData((prev) => {
          return {
            ...prev,
            xaxis: formattedDates,
            yaxis: yaxisdata,
            serise: yaxisSerise,
          };
        });
        setGraphShow(true);
      } else {
        const mills = nextProps?.floodTimeseriesData?.graph_data?.millis;
        const data = nextProps?.floodTimeseriesData?.graph_data?.data;

        const width =
          mills.length >= HighChartConstants?.thirty
            ? mills?.length * HighChartConstants?.fiftyFive
            : null;

        containerWidth > width
          ? setChartWidth(containerWidth)
          : setChartWidth(width);

        const yaxisdata = [];
        const yaxisSerise = [];
        let formattedDates = [];
        setGraphShow(true);
        if (!_.isEmpty(mills)) {
          formattedDates = mills?.length
            ? mills.map((item) => {
                const dateFormat = new Date(item);
                return renderChartTime(dateFormat);
              })
            : "";
          const data1 = onSetDataValue(formattedDates, data);
          const a = {
            name: `${indicatorsData?.indices_name}`,
            type: Line,
            data: data1,
            dataLabels: {
              format: "{y:.2f}",
              backgroundColor: chartColorLabel[0],
              borderRadius: HighChartConstants?.three,
              padding: HighChartConstants?.three,

              style: {
                color: HighChartConstants?.white,
                textOutline: "none",
              },
            },
          };
          yaxisSerise.push(a);
          yaxisdata.push({
            lineColor: chartColorLabel[0],
            lineWidth: 1,
            tickLength: 10,
            tickWidth: 1,
            tickColor: chartColorLabel[0],
            tickAmount: 6,
            showEmpty: false,
            labels: {
              format: "{value:.1f}",
              style: {
                size: 12,
                color: CHART_LABEL_COLORS[1],
              },
            },
            title: {
              text: `${indicatorsData?.indices_name} - ${
                selectedLocation?.state === ""
                  ? t("DASHBOARD.ALL_REGIONS")
                  : selectedLocation?.state?.label
              }- ${
                selectedLocation?.district === ""
                  ? t("DASHBOARD.ALL_DISTRICTS")
                  : selectedLocation?.district?.label
              }`,
              style: {
                size: 12,
                color: CHART_LABEL_COLORS[1],
              },
            },
          });
          setHighChartData((prev) => {
            return {
              ...prev,
              xaxis: formattedDates,
              yaxis: yaxisdata,
              serise: yaxisSerise,
            };
          });
        } else {
          setHighChartData((prev) => {
            return {
              ...prev,
              xaxis: [],
              yaxis: [],
              serise: [],
            };
          });
        }
      }
    }
  }, [nextProps.floodTimeseriesData]);

  useEffect(() => {
    setGraphShow(!nextProps.floodTimeSeriesloading);
    setErrorData({
      showError: false,
      message: "",
    });
  }, [nextProps.floodTimeSeriesloading]);

  useEffect(() => {
    setGraphShow(!nextProps.graphLoading);
  }, [nextProps.graphLoading]);

  useEffect(() => {
    if (!_.isEmpty(nextProps.floodTimeseriesError)) {
      setErrorData({
        showError: true,
        message: nextProps.floodTimeseriesError,
      });
    }
  }, [nextProps.floodTimeseriesError]);

  useEffect(() => {
    if (chartDatas) {
      let dates;
      const yaxisdata = [];
      const yaxisSerise = [];
      let formattedDates = [];
      if (!_.isEmpty(chartDatas) && chartDatas?.graph_data?.length) {
        dates = chartDatas?.graph_data[0]?.millis;
        if (chartDatas?.graph_data?.length === 2) {
          // eslint-disable-next-line no-unused-vars
          dates =
            chartDatas?.graph_data[0]?.millis.length >
            chartDatas?.graph_data[1]?.millis.length
              ? chartDatas?.graph_data[0]?.millis
              : chartDatas?.graph_data[1]?.millis;
        }
      }
      // eslint-disable-next-line no-unused-vars
      formattedDates = dates.map((item) => {
        const dateFormat = new Date(item);
        return renderChartTime(dateFormat);
      });

      const width =
        formattedDates.length >= HighChartConstants?.thirty
          ? formattedDates?.length * HighChartConstants?.fourtyFive
          : null;

      containerWidth > width
        ? setChartWidth(containerWidth)
        : setChartWidth(width);

      chartDatas?.graph_data?.map((eachData, index) => {
        const data1 = onSetDataValue(formattedDates, eachData?.data);
        const seriseData = {
          name:
            eachData.layerType === "marker"
              ? `Point (${eachData.latlng[1].toFixed(
                  2,
                )},${eachData.latlng[0].toFixed(2)})`
              : `${t("DRAW_SHAPE.PLOYGON_GRAPH")}-${eachData?.polygonCount}`,
          type: "line",
          data: data1,
          yAxis: index,
          color: chartColorLabel[index],
          dataLabels: {
            format: "{y:.2f}",
            backgroundColor: chartColorLabel[index],

            borderRadius: HighChartConstants?.three,
            padding: HighChartConstants?.three,
            y:
              index === 0
                ? HighChartConstants?.minusTen
                : HighChartConstants?.thirty,
            style: {
              color: HighChartConstants?.white,
              textOutline: "none",
            },
          },
        };
        const yaxis = {
          lineColor: chartColorLabel[index],
          lineWidth: 1,
          tickLength: 10,
          tickWidth: 1,
          tickAmount: 6,
          tickColor: chartColorLabel[index],
          labels: {
            format: "{value:.1f}",
            style: {
              color: chartColorLabel[index],
              fontWeight: "bold",
            },
          },

          title: {
            text:
              eachData.layerType === "marker"
                ? `point (${eachData.latlng[1].toFixed(
                    2,
                  )},${eachData.latlng[0].toFixed(2)})`
                : `${t("DRAW_SHAPE.PLOYGON_GRAPH")}-${eachData?.polygonCount}`,
            style: {
              color: chartColorLabel[index],
            },
          },
        };
        if (index > 0) {
          yaxis.opposite = true;
        }
        yaxis.showEmpty = false;
        yaxisdata.push(yaxis);
        yaxisSerise.push(seriseData);
        return null;
      });
      setHighChartData((prev) => {
        return {
          ...prev,
          xaxis: formattedDates,
          yaxis: yaxisdata,
          serise: yaxisSerise,
        };
      });
      setGraphShow(true);
    }
  }, [chartDatas]);

  return (
    showChart && (
      <div
        className={
          containerWidth === chartWidth
            ? "drought-footer-area chart-design chart-hidden"
            : "drought-footer-area chart-design chart-flow"
        }
        id="graph1"
      >
        {showGraph && !errorData?.showError ? (
          <>
            <HighChartGraph options={options} />
            <div>
              <span className="time-show">
                {t("LOADING.DATE")} {dateFormatters(startDate)}{" "}
                {t("LOADING.TO")} {dateFormatters(endDate)}
              </span>
              <div className="close-icon-highcharts">
                <GrFormClose
                  className="close-graph-bth"
                  onClick={() => {
                    setShowChart(false);
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <GraphLoader />
            {errorData?.showError && (
              <div className="floodgraph-error">
                <div className="graph-error-style h-100">
                  <Alert color="warning" className="alert-warning-container">
                    {errorData?.message}
                  </Alert>
                </div>
              </div>
            )}
            <div style={{ left: 30 }} className="close-icon-highcharts">
              <GrFormClose
                className="close-graph-bth"
                onClick={() => {
                  setChartWidth(null);
                  setShowChart(false);
                }}
              />
            </div>
          </>
        )}
      </div>
    )
  );
}
export default FloodGraph;
