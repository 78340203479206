import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import successCheckmark from "../../assest/img/successCheckmark.svg";
import GlobalLoader from "../../components/Common/Loader";
import { showError } from "../../components/Common/Notification";
import SuccessConfirmationModal from "../../components/Common/SucessConfirmModel";
import {
  District,
  RAPID_RESPONSE_NEWSFEED_DISASTER_TYPES,
  State,
} from "../../constants";
import { renderReactOptionsArraystate } from "../../helpers";
import { coredistrictRequest, corestateRequest } from "../../store/actions";
import {
  createDisasterAlertRequest,
  getDisasterAlertListRequest,
  getDisasterEventsDataRequest,
  getDisasterListDataRequest,
  getRapidResponseNewsfeedDataRequest,
} from "../../store/rapidResponse/actions";
import CreateDisaster from "./CreateDisasterModal";
import Events from "./Events";
import MapContainerComponent from "./MapContainer";
import ViewEvent from "./ViewEvent";

function RapidResponseNewsfeed() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const wrap = useRef();
  const [eventsData, setEventsData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedDisasterType, setSelectedDisasterType] = useState({
    label: "all",
    value: "",
  });
  const [selectedEvent, setSelectedEvent] = useState({});
  const [local, setLocal] = useState(
    JSON.parse(localStorage?.getItem("selectedCountry")),
  );
  const [position, setPosition] = useState([local?.latitude, local?.longitude]);
  const [zooms, setZooms] = useState(local?.zoom_level);
  const [disasterTypes, setDisasterTypes] = useState([]);
  const [isCreateDisasterOpen, setIsCreateDisasterOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedDisaster, setSelectedDisater] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [regionList, setRegionList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({
    label: "Select Region",
    value: "",
  });
  const [selectedDistrict, setSelectedDistrict] = useState({
    label: "Select District",
    value: "",
  });
  const [subDistrict, setSubDistrict] = useState("");
  const [city, setCity] = useState("");
  const [coordinates, setCoordinates] = useState({
    latitude: "",
    longitude: "",
  });
  const [description, setDescription] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();

  const nextProps = useSelector((state) => ({
    countryList: state.Core.coreList,
    newsfeedData: state.RapidResponse.rapidResponseNewsfeedData,
    disasterEvents: state.RapidResponse.disasterEventsData,
    isdisasterEventsLoading: state.RapidResponse.isDisasterEventsDataRequesting,
    isnewsFeedLoading: state.RapidResponse.isNewsfeedDataRequesting,
    disasterListData: state.RapidResponse.disasterListData,
    isDisasterLoading: state.RapidResponse.isDisasterEventsDataRequesting,
    isGdacsLoading: state.RapidResponse.isNewsfeedDataRequesting,
    statesList: state.Core.corestateList,
    districtList: state.Core.coredistrictList,
    createDisasterAlertSuccess: state.RapidResponse.createDisasterAlertData,
    createDisasterAlertError: state.RapidResponse.createDisasterAlertDataError,
    disasterAlertList: state.RapidResponse.disasterAlertListData,
  }));

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("selectedCountry"));
    if (!_.isEmpty(data)) {
      dispatch(getDisasterListDataRequest({})); // FOR LISTS
      dispatch(
        getDisasterAlertListRequest({
          is_backoffice: false,
          country_id: data.value,
        }),
      ); // FOR CREATED DISASTERS ALERTS
      setSelectedCountry(data);
      setLocal(data);

      dispatch(
        corestateRequest({
          data: {
            parent_id: data?.value,
            location_type: State,
          },
          isCompareLocation: false,
        }),
      );

      dispatch(
        getDisasterEventsDataRequest({
          country_name: data.label,
          offset: 0,
          limit: 10,
        }),
      );

      dispatch(
        getRapidResponseNewsfeedDataRequest({
          event_type: "",
          event_limit: 10,
          country_id: data.value,
        }),
      );
    }
    return () => {
      setEventsData([]);
    };
  }, [localStorage.getItem("selectedCountry")]);

  useEffect(() => {
    if (!_.isEmpty(nextProps.disasterListData)) {
      const list = Object.keys(nextProps.disasterListData)?.map((item) => {
        return {
          label: item?.split("_")?.join(" "),
          value: nextProps?.disasterListData[item],
        };
      });

      if (list?.length) setDisasterTypes(list);
    }
  }, [nextProps.disasterListData]);

  useEffect(() => {
    if (nextProps?.statesList?.data) {
      const regionData = renderReactOptionsArraystate(
        nextProps?.statesList?.data,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
      )?.map((eachValue) => eachValue);
      setRegionList([...regionData]);
    }
  }, [nextProps?.statesList]);

  useEffect(() => {
    if (nextProps.districtList?.data) {
      if (nextProps.districtList?.data) {
        const districtData = renderReactOptionsArraystate(
          nextProps.districtList?.data,
          "name",
          "id",
          "latitude",
          "longitude",
          "zoom_level",
        )?.map((eachValue) => eachValue);

        setDistrictList([...districtData]);
      }
    }
  }, [nextProps.districtList]);

  const isGdacEventsRun = useRef(true);
  useEffect(() => {
    if (isGdacEventsRun.current) {
      isGdacEventsRun.current = false;
      return;
    }

    if (nextProps?.newsfeedData) {
      const data = eventsData.concat(nextProps.newsfeedData);
      setEventsData(data);
    }
  }, [nextProps.newsfeedData]);

  const isDisasterEventsRun = useRef(true);
  useEffect(() => {
    if (isDisasterEventsRun.current) {
      isDisasterEventsRun.current = false;
      return;
    }

    if (nextProps?.disasterEvents) {
      const data = eventsData.concat(nextProps.disasterEvents);
      setEventsData(data);
    }
  }, [nextProps.disasterEvents]);

  const isDisasterAlertListRun = useRef(true);
  useEffect(() => {
    if (isDisasterAlertListRun.current) {
      isDisasterAlertListRun.current = false;
      return;
    }

    if (nextProps?.disasterAlertList) {
      if (nextProps?.disasterAlertList?.results?.length) {
        setPageCount(nextProps?.disasterAlertList?.count);
        const approvedData = nextProps?.disasterAlertList?.results.filter(
          (alert) => alert.status === "APPROVED",
        );
        const events = eventsData.concat(approvedData);
        setEventsData(events);
      }
    } else {
      setPageCount();
    }
  }, [nextProps.disasterAlertList]);

  const handleBackEvent = () => {
    setPageNumber(1);
    setSelectedEvent({});
  };

  const handleCancelCreate = () => {
    setPageNumber(1);
    setTitle("");
    setSubDistrict("");
    setCity("");
    setDescription("");
    setCoordinates({
      latitude: "",
      longitude: "",
    });
    setSelectedDistrict({
      label: "Select District",
      value: "",
    });
    setSelectedRegion({
      label: "Select Region",
      value: "",
    });
    setStartDate("");
    setEndDate("");
    setSelectedDisater({});
  };

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (nextProps?.createDisasterAlertSuccess) {
      handleBackEvent();
      handleCancelCreate();
      setIsSuccessModalOpen(!isSuccessModalOpen);
      setIsCreateDisasterOpen(false);
    } else if (nextProps.createDisasterAlertError) {
      handleBackEvent();
      handleCancelCreate();
      const errorMessage =
        nextProps.createDisasterAlertError?.message?.[0] ||
        nextProps.createDisasterAlertError?.errors ||
        t("LOGIN.SOMETHING_WENT_WRONG");
      showError(errorMessage);
    }
  }, [
    nextProps?.createDisasterAlertSuccess,
    nextProps.createDisasterAlertError,
  ]);

  const handleTitle = (value) => {
    setTitle(value);
  };

  const handleSelectDisasterType = (e) => {
    handleBackEvent();
    setSelectedDisasterType(e);
    setEventsData([]);

    const disasterPayload = {
      country_name: selectedCountry.label,
      offset: 0,
      limit: 10,
      disaster_type: e.value,
    };
    if (e.label === "all") {
      delete disasterPayload.disaster_type;
    }
    if (e.label !== "landslide") {
      dispatch(
        getRapidResponseNewsfeedDataRequest({
          event_type: e.value,
          event_limit: 10,
          country_id: selectedCountry.value,
        }),
      );
    }
    dispatch(getDisasterEventsDataRequest(disasterPayload));
    dispatch(
      getDisasterAlertListRequest({
        page: 1,
        is_backoffice: false,
        country_id: selectedCountry.value,
        disaster_type: e.value,
      }),
    );
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  };

  const onHandleZoom = (center, zoom) => {
    setPosition(center);
    setZooms(zoom);
  };

  const handleRenderDisasterIcon = (type) => {
    return RAPID_RESPONSE_NEWSFEED_DISASTER_TYPES.filter(
      (disaster) => disaster.label === type || disaster.value === type,
    );
  };

  const handleSelectDisaster = (disaster) => {
    setSelectedDisater(disaster);
  };

  const handleDates = (date, type) => {
    if (type === "start") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleSelectRegion = (region) => {
    setSelectedRegion(region);

    dispatch(
      coredistrictRequest({
        requestObj: {
          parent_id: region?.value,
          location_type: District,
        },
        isCompareLocation: false,
      }),
    );
  };

  const handleSelectDistrict = (district) => {
    setSelectedDistrict(district);
  };

  const handleSubDistrict = (district) => {
    setSubDistrict(district);
  };

  const handleCity = (cityName) => {
    setCity(cityName);
  };

  const handleCoordinates = (value, type) => {
    if (type === "latitude") {
      setCoordinates((prev) => {
        return {
          ...prev,
          latitude: value,
        };
      });
    } else {
      setCoordinates((prev) => {
        return {
          ...prev,
          longitude: value,
        };
      });
    }
  };

  const handleDescription = (desc) => {
    setDescription(desc);
  };

  const handleSubmit = () => {
    const payload = {
      country_id: selectedCountry.value,
      state_id: selectedRegion.value,
      district_id: selectedDistrict.value,
      disaster_type: selectedDisaster.value,
      start_date: startDate,
      description,
      name: title,
      sub_district_name: subDistrict,
      city_name: city,
    };
    if (endDate !== "") payload.end_date = endDate;
    if (coordinates.latitude) payload.latitude = coordinates.latitude;
    if (coordinates.longitude) payload.longitude = coordinates.longitude;
    if (selectedFiles.length) payload.upload_file = selectedFiles;

    dispatch(createDisasterAlertRequest(payload));
  };

  const fetchNextData = () => {
    setPageNumber(pageNumber + 1);

    dispatch(
      getDisasterAlertListRequest({
        page: pageNumber + 1,
        is_backoffice: false,
        country_id: selectedCountry.value,
        disaster_type: selectedDisaster.value,
      }),
    );
  };

  return (
    <div className="dashboard-main-page common-dashboard-ftr">
      {isSuccessModalOpen && (
        <SuccessConfirmationModal
          modal
          icon={successCheckmark}
          message={t("CROP_YIELD.SUCCESS_MESSAGE")}
          title=""
          toggle={() => {
            setIsSuccessModalOpen(false);
          }}
        />
      )}
      {isCreateDisasterOpen ? (
        <CreateDisaster
          isOpen={isCreateDisasterOpen}
          toggle={setIsCreateDisasterOpen}
          handleTitle={handleTitle}
          title={title}
          handleSelectDisaster={handleSelectDisaster}
          selectedDisaster={selectedDisaster}
          handleDates={handleDates}
          startDate={startDate}
          endDate={endDate}
          regionList={regionList}
          handleSelectRegion={handleSelectRegion}
          selectedRegion={selectedRegion}
          districtList={districtList}
          handleSelectDistrict={handleSelectDistrict}
          selectedDistrict={selectedDistrict}
          handleSubDistrict={handleSubDistrict}
          subDistrict={subDistrict}
          handleCity={handleCity}
          city={city}
          handleCoordinates={handleCoordinates}
          coordinates={coordinates}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          description={description}
          handleDescription={handleDescription}
          handleCancelCreate={handleCancelCreate}
          handleSubmit={handleSubmit}
        />
      ) : null}
      <div className="loader-div">
        {(nextProps.isdisasterEventsLoading || nextProps.isnewsFeedLoading) && (
          <GlobalLoader info="earlyFinanceReport" />
        )}
      </div>
      <div className="dash-main-row">
        <div className="dashboard-drought">
          <div className="dashboard-filter-div">
            <div
              className="rapid-response-filter p-0 px-3 d-flex justify-content-between align-items-center"
              style={{ top: "0vh !important" }}
            >
              <h3 className="dashboard-filter-h3 m-0">
                {t("RAPID_RESPONSE.DISASTER_ALERT")}
              </h3>
              <div className="d-flex align-items-center">
                <Button
                  className="rp-forecast-btn create-disaster-btn-sm w-auto mr-2"
                  type="button"
                  onClick={() => setIsCreateDisasterOpen(!isCreateDisasterOpen)}
                >
                  {t("RAPID_RESPONSE.REPORT_DISASTER_EVENT")}
                </Button>
                <Button
                  className="rp-forecast-btn green-btn-sm w-auto"
                  type="button"
                  onClick={() =>
                    navigate(`/rapid_response?slug=rapid_response`)
                  }
                >
                  {t("RAPID_RESPONSE.PREAPRE_RAPID_RESPONSE")}
                </Button>
              </div>
            </div>
            <Row className="dashboard-drought-section">
              <Col sm={12} lg={6} className="rp-newsfeed-map-drop p-0">
                <Row className="filter-control-dp p-0">
                  <Col sm={12} className="filter-control-dp-md mb-2">
                    <div className="event-filter-container">
                      {disasterTypes?.map((option) => (
                        <div
                          key={option.label}
                          onKeyUp={() => {}}
                          role="button"
                          tabIndex={0}
                          className={`event-filter-options mr-2 ${
                            selectedDisasterType.label === option.label
                              ? "event-active-filter"
                              : ""
                          }`}
                          onClick={() => handleSelectDisasterType(option)}
                        >
                          {option.label}
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col sm={12} className="filter-control-dp-md">
                    {!_.isEmpty(selectedEvent) ? (
                      <ViewEvent
                        selectedEvent={selectedEvent}
                        selectedDisasterType={selectedDisasterType}
                        handleBackEvent={handleBackEvent}
                        handleRenderDisasterIcon={handleRenderDisasterIcon}
                      />
                    ) : (
                      <Events
                        eventsData={eventsData}
                        selectedDisasterType={selectedDisasterType}
                        handleSelectEvent={handleSelectEvent}
                        handleRenderDisasterIcon={handleRenderDisasterIcon}
                        pageNumber={pageNumber}
                        pageCount={pageCount}
                        isLoading={
                          nextProps.isdisasterEventsLoading ||
                          nextProps.isnewsFeedLoading
                        }
                        fetchNextData={fetchNextData}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col sm={12} lg={6} className="h-100 p-0">
                <MapContainerComponent
                  onHandleZoom={onHandleZoom}
                  wrap={wrap}
                  position={position}
                  zooms={zooms}
                  eventsData={eventsData}
                  handleSelectEvent={handleSelectEvent}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RapidResponseNewsfeed;
