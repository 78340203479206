import PERMISSIONS_CONST from "./actionTypes";

export const getAllPermissionsRequest = (payload) => {
  return {
    type: PERMISSIONS_CONST.GET_ALL_PERMISSIONS_REQUEST,
    payload,
  };
};
export const getAllPermissionsSuccess = (user) => {
  return {
    type: PERMISSIONS_CONST.GET_ALL_PERMISSIONS_SUCCESS,
    payload: user,
  };
};
export const getAllPermissionsError = (error) => {
  return {
    type: PERMISSIONS_CONST.GET_ALL_PERMISSIONS_ERROR,
    payload: error,
  };
};

// ASSING PERMISSIONS FOR MANAGE USERS
export const assignUsersRequest = (data) => ({
  type: PERMISSIONS_CONST.ASSIGN_USER_REQUEST,
  payload: data,
});

export const assignUsersSuccess = (response) => ({
  type: PERMISSIONS_CONST.ASSIGN_USER_SUCCESS,
  payload: response,
});

export const assignUsersFailure = (error) => ({
  type: PERMISSIONS_CONST.ASSIGN_USER_ERROR,
  payload: error,
});
