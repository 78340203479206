import React from "react";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import { AiFillDelete, AiFillCheckCircle } from "react-icons/ai";
import { GoCircleSlash } from "react-icons/go";
import { HiOutlineXMark } from "react-icons/hi2";
import { useTranslation } from "react-i18next";

function AlertModal({
  modal,
  isToggle,
  onClick,
  data,
  onDeleteClick,
  onPublishClick,
}) {
  const { t } = useTranslation();

  const handleChanges = () => {
    if (onDeleteClick) {
      onClick(data?.id);
    } else if (onPublishClick) {
      onClick(data, { is_publish: !data?.is_publish });
    }
  };
  const publish = data?.is_publish ? "Unpublish" : "Publish";

  return (
    <div className="news-feed-alert-modal">
      <Row>
        <Col>
          <Modal
            className="modal-dialog modal-lg modal-dialog-centered publish-modal"
            isOpen={modal}
            toggle={isToggle}
            centered
          >
            <div className="alert-heading">
              <div className="header-text">
                &nbsp;&nbsp;
                <h6 className="heading">Warning</h6>
              </div>
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
                className="cursor-ponter"
              >
                <HiOutlineXMark size={25} onClick={isToggle} />
              </div>
            </div>
            <ModalBody className="text-center delete-modal-body">
              {onDeleteClick && <AiFillDelete size={30} />}
              {onPublishClick &&
                (data?.status ? (
                  <AiFillCheckCircle size={30} />
                ) : (
                  <GoCircleSlash size={30} />
                ))}
              <div className="mt-3 text-dark">
                <b>{t("BULLET_IN.ARE_YOU_SURE")}</b>
              </div>
              <div className="mt-3">
                {onPublishClick ? `This will ${publish} this Bulletin` : ""}
              </div>
              <div className="mt-3">
                <button
                  className="btn btn-cancel mx-2"
                  type="button"
                  onClick={isToggle}
                >
                  {t("BULLET_IN.CANCEL")}
                </button>
                <button
                  className={
                    onDeleteClick
                      ? "btn btn-news-feed-submit mx-2"
                      : "btn btn-bulletin-submit mx-2"
                  }
                  type="button"
                  onClick={handleChanges}
                >
                  {onDeleteClick
                    ? t("BULLET_IN.DELETE")
                    : onPublishClick && data?.is_publish
                    ? t("BULLET_IN.UNPUBLISH")
                    : t("BULLET_IN.PUBLISH")}
                </button>
              </div>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </div>
  );
}

export default AlertModal;
