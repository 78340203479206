import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrFormClose } from "react-icons/gr";
import { BiUser } from "react-icons/bi";
import { useDispatch } from "react-redux";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { RAPID_RESPONSE_NEWSFEED_DISASTER_TYPES } from "../../constants";
import { dateFormatters } from "../../helpers";
import { getApproveDisasterEventRequest } from "../../store/actions";
import MapComponent from "./MapComponent";

import MediaPreviewComponent from "../../components/Common/MediaPreviewComponent";

function ViewDisasterEvents({ isOpen, latLng, selectedDisasterEvent, toggle }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disasterType, setDisasterType] = useState({});
  const [selectedApprovalType, setSelectedApprovalType] = useState("");
  const [comment, setComment] = useState("");

  const closeBtn = (
    <GrFormClose
      size={24}
      onClick={() => toggle()}
      className="pointer media-modal-header-close-icon"
    />
  );

  useEffect(() => {
    // Add a CSS class to the body element when the sideEditor is open
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  useEffect(() => {
    const disasterEvent = RAPID_RESPONSE_NEWSFEED_DISASTER_TYPES.find(
      (event) => event.value === selectedDisasterEvent.type,
    );
    setDisasterType(disasterEvent);
    setSelectedApprovalType(selectedDisasterEvent?.status);
  }, [selectedDisasterEvent]);

  const handleSelectApproval = (e) => {
    const { value } = e.target;
    setSelectedApprovalType(value);
  };

  const handleSubmit = () => {
    const payload = {
      is_approved: selectedApprovalType === "APPROVED",
      disaster_event_id: selectedDisasterEvent?.id,
      reason: comment,
    };
    dispatch(getApproveDisasterEventRequest(payload));
    toggle();
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        className="disaster-events-modal"
      >
        <ModalHeader
          toggle={toggle}
          close={closeBtn}
          className="align-items-center media-modal-header-background"
        >
          Disaster event details
        </ModalHeader>
        <ModalBody className="px-0">
          <Row>
            <Col sm={7} className="event-view-details-container">
              <Row className="py-3 view-event-hr">
                <Col sm={3} className="px-0">
                  <strong>{t("RAPID_RESPONSE.TITLE")}</strong>
                </Col>
                <Col sm={9} className="px-0">
                  {selectedDisasterEvent.name}
                </Col>
              </Row>
              <Row className="py-3 view-event-hr">
                <Col sm={3} className="px-0">
                  <strong>{t("RAPID_RESPONSE.DISASTER_TYPE")}</strong>
                </Col>
                <Col sm={9} className="px-0">
                  <div className="event-info-details my-2">
                    <img
                      src={disasterType?.icon}
                      alt={disasterType?.label}
                      width={16}
                      height={16}
                      className="mr-1"
                    />
                    <span>{disasterType?.label}</span>
                  </div>
                </Col>
              </Row>
              <Row className="py-3 view-event-hr">
                <Col sm={3} className="px-0">
                  <strong>{t("RAPID_RESPONSE.DATE")}</strong>
                </Col>
                <Col sm={9} className="px-0">
                  {dateFormatters(selectedDisasterEvent.fromdate)}
                </Col>
              </Row>
              <Row className="py-3 view-event-hr">
                <Col sm={3} className="px-0">
                  <strong>{t("RAPID_RESPONSE.LOCATION")}</strong>
                </Col>
                <Col sm={9} className="px-0">
                  {`${selectedDisasterEvent.country_name}, ${selectedDisasterEvent?.state_name}, ${selectedDisasterEvent?.district_name}`}
                </Col>
              </Row>
              <Row className="py-3 view-event-hr">
                <Col sm={3} className="px-0">
                  <strong>{t("RAPID_RESPONSE.LATITUDE_LONGITUDE")}</strong>
                </Col>
                <Col sm={9} className="px-0">
                  {`${selectedDisasterEvent?.geometry?.lat}, ${selectedDisasterEvent?.geometry?.lon}`}
                </Col>
              </Row>
              <Row className="py-3 view-event-hr">
                <Col sm={3} className="px-0">
                  <strong>{t("RAPID_RESPONSE.UPLOADED_BY")}</strong>
                </Col>
                <Col sm={9} className="px-0">
                  {selectedDisasterEvent?.source}
                </Col>
              </Row>
              <Row
                className={`py-3 view-event-hr ${
                  selectedDisasterEvent?.media_urls?.length ? "" : "border-0"
                }`}
              >
                <Col sm={3} className="px-0">
                  <strong>{t("RAPID_RESPONSE.DESCRIPTION")}</strong>
                </Col>
                <Col sm={9} className="px-0">
                  {selectedDisasterEvent.description}
                </Col>
              </Row>
              {selectedDisasterEvent?.media_urls?.length ? (
                <Row className="py-3 view-event-hr border-0">
                  <Col sm={3} className="px-0">
                    <strong>{t("RAPID_RESPONSE.MEDIA")}</strong>
                  </Col>
                  <Col sm={9} className="px-0">
                    <Row>
                      <MediaPreviewComponent
                        data={selectedDisasterEvent}
                        cardSize={4}
                      />
                    </Row>
                  </Col>
                </Row>
              ) : null}
            </Col>
            <Col sm={5} className="view-event-map-container">
              <MapComponent
                position={latLng}
                zoom={10}
                selectedDisasterEvent={selectedDisasterEvent}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="disaster-view-event">
          <div className="d-flex align-items-center">
            <p className="approve-detail-h3 d-inline-block mr-3">
              {t("APPROVE_DATA.STATUS")}
            </p>{" "}
            <select
              className={`form-select form-select-sm disaster-view-select ${
                selectedApprovalType === "APPROVED"
                  ? "disaster-view-select-approved"
                  : selectedApprovalType === "REJECTED"
                  ? "disaster-view-select-rejected"
                  : ""
              }`}
              aria-label=".form-select-sm example"
              value={
                selectedApprovalType === "APPROVED"
                  ? "APPROVED"
                  : selectedApprovalType === "REJECTED"
                  ? "REJECTED"
                  : ""
              }
              style={
                selectedDisasterEvent?.status === "REJECTED"
                  ? { pointerEvents: "none" }
                  : {}
              }
              onChange={(e) => handleSelectApproval(e)}
            >
              <option value="" disabled>
                {t("CLIMBER_HOME_PAGE.SELECT")}
              </option>
              <option value="APPROVED">{t("RAPID_RESPONSE.APPROVE")}</option>
              <option value="REJECTED">{t("RAPID_RESPONSE.REJECTED")}</option>
            </select>
            <span className="disaster-event-upload-text mx-3">
              {t("RAPID_RESPONSE.UPLOADED_ON")}{" "}
              {dateFormatters(selectedDisasterEvent?.created_date)}
            </span>
          </div>
          <div className="active-pahse-btn-section justify-content-end">
            <Button
              type="button"
              className="media-cancel-btn mr-3"
              onClick={() => {
                toggle();
              }}
            >
              {t("EARLY_ACTIONS.CANCLE")}
            </Button>
            {!["REJECTED", "APPROVED"].includes(
              selectedDisasterEvent?.status,
            ) ? (
              <Button
                type="button"
                className="media-add-btn mb-1"
                disabled={selectedApprovalType === "PENDING"}
                onClick={() => handleSubmit()}
              >
                {t("BULLET_IN.SUBMIT")}
              </Button>
            ) : null}
          </div>
          {selectedApprovalType === "REJECTED" ? (
            <Col sm={12} className="my-2 py-2 view-event-top-bottom-hr">
              {selectedDisasterEvent?.rejected_reason ? (
                <div className="d-flex">
                  <BiUser />{" "}
                  <div className="ml-2">
                    <strong>
                      {selectedDisasterEvent?.rejected_user || ""}
                    </strong>
                    <span className="disaster-event-upload-text mx-3">
                      {t("RAPID_RESPONSE.UPLOADED_ON")}{" "}
                      {dateFormatters(selectedDisasterEvent?.created_date)}
                    </span>
                    <br />
                    <p>{selectedDisasterEvent?.rejected_reason}</p>
                  </div>
                </div>
              ) : (
                <div className=" d-flex align-items-center">
                  <BiUser />{" "}
                  <Input
                    type="text"
                    placeholder="Add your comments here...(Max 250 characters)"
                    className="ml-2"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    maxLength={250}
                  />
                </div>
              )}
            </Col>
          ) : (
            ""
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ViewDisasterEvents;
