const EARLY_ACTIONS_CONST = {
  EARLY_ACTION_RISK_TYPE_REQUEST: "EARLY_ACTION_RISK_TYPE_REQUEST",
  EARLY_ACTION_RISK_TYPE_SUCCESS: "EARLY_ACTION_RISK_TYPE_SUCCESS",
  EARLY_ACTION_RISK_TYPE_ERROR: "EARLY_ACTION_RISK_TYPE_ERROR",

  EARLY_ACTION_CREATE_PROJECT_REQUEST: "EARLY_ACTION_CREATE_PROJECT_REQUEST",
  EARLY_ACTION_CREATE_PROJECT_SUCCESS: "EARLY_ACTION__CREATE_PROJECT_SUCCESS",
  EARLY_ACTION_CREATE_PROJECT_ERROR: "EARLY_ACTION_CREATE_PROJECT_ERROR",

  EARLY_ACTION_PROJECT_LIST_REQUEST: "EARLY_ACTION_PROJECT_LIST_REQUEST",
  EARLY_ACTION_PROJECT_LIST_SUCCESS: "EARLY_ACTION_PROJECT_LIST_SUCCESS",
  EARLY_ACTION_PROJECT_LIST_ERROR: "EARLY_ACTION_PROJECT_LIST_ERROR",

  EARLY_ACTION_DELETE_PROJECT_REQUEST: "EARLY_ACTION_DELETE_PROJECT_REQUEST",
  EARLY_ACTION_DELETE_PROJECT_SUCCESS: "EARLY_ACTION_DELETE_PROJECT_SUCCESS",
  EARLY_ACTION_DELETE_PROJECT_ERROR: "EARLY_ACTION_DELETE_PROJECT_ERROR",

  EARLY_ACTION_EDIT_PROJECT_REQUEST: "EARLY_ACTION_EDIT_PROJECT_REQUEST",
  EARLY_ACTION_EDIT_PROJECT_SUCCESS: "EARLY_ACTION_EDIT_PROJECT_SUCCESS",
  EARLY_ACTION_EDIT_PROJECT_ERROR: "EARLY_ACTION_EDIT_PROJECT_ERROR",

  // Preparedness
  EARLY_ACTION_PREPAREDNESS_PHASE_REQUEST:
    "EARLY_ACTION_PREPAREDNESS_PHASE_REQUEST",
  EARLY_ACTION_PREPAREDNESS_PHASE_SUCCESS:
    "EARLY_ACTION_PREPAREDNESS_PHASE_SUCCESS",
  EARLY_ACTION_PREPAREDNESS_PHASE_ERROR:
    "EARLY_ACTION_PREPAREDNESS_PHASE_ERROR",

  // Preparedness phase table data
  EARLY_ACTION_PREPAREDNESS_PHASE_SUGGESTIONS_REQUEST:
    "EARLY_ACTION_PREPAREDNESS_PHASE_SUGGESTIONS_REQUEST",
  EARLY_ACTION_PREPAREDNESS_PHASE_SUGGESTIONS_SUCCESS:
    "EARLY_ACTION_PREPAREDNESS_PHASE_SUGGESTIONS_SUCCESS",
  EARLY_ACTION_PREPAREDNESS_PHASE_SUGGESTIONS_ERROR:
    "EARLY_ACTION_PREPAREDNESS_PHASE_SUGGESTIONS_ERROR",

  // project status
  EARLY_ACTION_PROJECT_STATUS_REQUEST: "EARLY_ACTION_PROJECT_STATUS_REQUEST",
  EARLY_ACTION_PROJECT_STATUS_SUCCESS: "EARLY_ACTION_PROJECT_STATUS_SUCCESS",
  EARLY_ACTION_PROJECT_STATUS_ERROR: "EARLY_ACTION_PROJECT_STATUS_ERROR",

  // CURRENCY LISTING
  CURRENCY_LISTING_REQUEST: "CURRENCY_LISTING_REQUEST",
  CURRENCY_LISTING_SUCCESS: "CURRENCY_LISTING_SUCCESS",
  CURRENCY_LISTING_ERROR: "CURRENCY_LISTING_ERROR",
};
export default EARLY_ACTIONS_CONST;
