const EARLY_WARNING_HEALTH_CONST = {
  // GET HEALTH MALRIA DATA
  GET_HEALTH_MALARIA_DATA_REQUEST: "GET_HEALTH_MALARIA_DATA_REQUEST",
  GET_HEALTH_MALARIA_DATA_SUCCESS: "GET_HEALTH_MALARIA_DATA_SUCCESS",
  GET_HEALTH_MALARIA_DATA_FAILED: "GET_HEALTH_MALARIA_DATA_FAILED",

  // TIME SERIES
  GET_HEALTH_TIMESERIES_REQUEST: "GET_HEALTH_TIMESERIES_REQUEST",
  GET_HEALTH_TIMESERIES_SUCCESS: "GET_HEALTH_TIMESERIES_SUCCESS",
  GET_HEALTH_TIMESERIES_ERROR: "GET_HEALTH_TIMESERIES_ERROR",

  GET_EW_RAW_DATA_DATES_REQUEST: "GET_EW_RAW_DATA_DATES_REQUEST",
  GET_EW_RAW_DATA_DATES_SUCCESS: "GET_EW_RAW_DATA_DATES_SUCCESS",
  GET_EW_RAW_DATA_DATES_ERROR: "GET_EW_RAW_DATA_DATES_ERROR",

  // get health predetion data
  GET_HEALTH_PREDECTION_DATA_REQUEST: "GET_HEALTH_PREDECTION_DATA_REQUEST",
  GET_HEALTH_PREDECTION_DATA_SUCCESS: "GET_HEALTH_PREDECTION_DATA_SUCCESS",
  GET_HEALTH_PREDECTION_DATA_ERROR: "GET_HEALTH_PREDECTION_DATA_ERROR",
};
export default EARLY_WARNING_HEALTH_CONST;
