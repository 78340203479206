import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  FaChevronDown,
  FaChevronUp,
  FaAngleRight,
  FaAngleLeft,
} from "react-icons/fa";
import SplitterLayout from "react-splitter-layout";
import "react-splitter-layout/lib/index.css";
import _ from "lodash";
import {
  TemperatureAnomaly,
  RainfallDeficit,
  DrysPell,
  Temporal,
  State,
  TemporalAggregation,
  Map,
  Graph,
} from "../../constants";
import {
  mapPreferenceRequest,
  getDroughtTimeSeriesRequest,
  // getDroughtTimeSeriesSuccess,
  coreRequest,
  startGraphLoader,
  stopGraphLoader,
  corestateRequest,
  coreBasinRequest,
} from "../../store/actions";
import {
  AXIOS_INSTANCE_LOADER,
  BASE_URL,
  EARLY_WARNING_API,
} from "../../store/apiUtils/config";
import Loader from "../../components/Common/Loader";
import DroughtGraph from "./DroughtHighChart";
import {
  dateFormatter,
  renderReactOptionsArraystate,
  getSelectedDate,
} from "../../helpers";
import { showError } from "../../components/Common/Notification";

// lazy loading components
const MapContainer = lazy(() => import("./MapContainer"));
const Filter = lazy(() => import("./Filter"));

function Drought() {
  const [, setOpen] = useOutletContext();
  const [openPanel, setOpenPanel] = useState(false);
  const { t } = useTranslation();
  const [layerControl, setLayerControl] = useState({
    firstLayer: {},
    secondLayer: {},
  });
  const splitterRef = useRef(null);
  const [loadingMap, setLoadingMap] = useState(false);
  const [mapControl, setMapControl] = useState({});
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [date, setDate] = useState("");
  const [seconddate, setsecondDate] = useState("");
  const [indexLayers, setIndexLayers] = useState([]);
  const [legend, setLegend] = useState([]);
  const [comparelegend, setcompareLegend] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [opaci, setOpaci] = useState();
  const [endDate, setEndDate] = useState("");
  const [secondstartDate, setsecondStartDate] = useState("");
  const [secondendDate, setsecondEndDate] = useState("");
  const [openDropdown, setOpenDropdown] = useState(false);
  const [, setGetBoundariesData] = useState([]);
  const [getGraphCall, setGraphCall] = useState(false);
  const [layerData, setLayerData] = useState("");
  const [chartData, setChartData] = useState("");
  const [indicatorList, setIndicatorList] = useState([]);
  const [, setMapPreferenceCategories] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [aggregation, setAggregation] = useState(Temporal[0]);
  const [secondaggregation, setsecondAggregation] = useState(Temporal[0]);
  const [compareGraph, setCompareGraph] = useState();
  const [compare, setCompare] = useState(false);
  const [mapes, setMapes] = useState();
  const [errorData, setErrorData] = useState({ showError: false, message: "" });
  const [selectedStartYear, setSelectedStartYear] = useState("");
  const [selectedEndYear, setSelectedEndYear] = useState("");
  const [yearsList, setYearsList] = useState([]);
  const [anomalyYearsList, setAnomalyYearsList] = useState([]);
  const [secondselectedStartYear, setsecondSelectedStartYear] = useState("");
  const [secondselectedEndYear, setsecondSelectedEndYear] = useState("");
  const [secondyearsList, setsecondYearsList] = useState([]);
  const [secondanomalyYearsList, setsecondAnomalyYearsList] = useState([]);
  const [compareCountry, setCompareCountry] = useState();
  const [specialStartdate, setStartEnddate] = useState("");
  const [selectedAnomalyYear, setSelectedAnomalyYear] = useState("");
  const [mins, setMin] = useState("");
  const [maxs, setMax] = useState("");

  const [secondIndicatorList, setSecondIndicatorList] = useState([]);
  const [firstRadio, setFirstRadio] = useState(Map);
  const [secondCollaspedMenu, setsecondCollaspedMenu] = useState("");
  const [indicatorsData, setIndicatorsData] = useState({});
  const [compareIndicatorData, setCompareIndicatorData] = useState({});
  const [aggregationData, setAggregationData] = useState();
  const [compareAggregationData, setCompareAggregationData] = useState();
  const [graphStartDate, seGraphStartDate] = useState();
  const [graphEndDate, setGraphEndDate] = useState();
  const [adminRadio, setAdminRadio] = useState("admin");

  const dispatch = useDispatch();
  const nextProps = useSelector((state) => ({
    statesList: state.Core.corestateList,
    districtsList: state.Core.coredistrictList,
    countryList: state.Core.coreList,
    basinList: state.Core.coreBasinList,
    maps: state.Drought.droughtList,
    droughtTimeseriesData: state.Drought.droughtTimeseriesData,
    indicator: state.Dashboard.indicatorList,
    secondindicator: state.Dashboard.indicatorList,
    mapPreferenceList: state.MapPreference,
    dashBoardMenuData: state.Dashboard.dashBoardMenuData,
    droughtListLoading: state.Drought,
  }));

  const [local, setLocal] = useState(
    JSON.parse(localStorage?.getItem("selectedCountry")),
  );
  const params = new URLSearchParams(window.location.search);
  const selectedModule = params.get("module");
  const module = params.get("subModule");

  const [position, setPosition] = useState([local.latitude, local.longitude]);
  const [zooms, setZooms] = useState(local.zoom_level);
  const [graphPanelHeight, setGraphPanelHeight] = useState(0);
  const updatePage = (title, title2) => {
    setPosition(title);
    setZooms(title2);
  };
  useEffect(() => {
    if (nextProps.maps) {
      setLayerData(nextProps.maps);
      setLegend([]);
      setcompareLegend([]);
    }
  }, [nextProps.maps]);

  const selectedRegions = [
    {
      label: t("DASHBOARD.ALL_REGIONS"),
      value: "",
      latitude: selectedLocation?.country?.latitude,
      longitude: selectedLocation?.country?.longitude,
      zoom_level: selectedLocation?.country?.zoom_level,
    },
  ];
  const selectedDistricts = [
    {
      label: t("DASHBOARD.ALL_DISTRICTS"),
      value: "",
      latitude: selectedLocation?.state?.latitude,
      longitude: selectedLocation?.state?.longitude,
      zoom_level: selectedLocation?.state?.zoom_level,
    },
  ];
  const selectedBasin = [
    {
      label: t("DASHBOARD.SELECT_BASIN"),
      value: "",
      latitude: selectedLocation?.country?.latitude,
      longitude: selectedLocation?.country?.longitude,
      zoom_level: selectedLocation?.country?.zoom_level,
    },
  ];
  const selectedSubBasin = [
    {
      label: t("DASHBOARD.ALL_SUB_BASIN"),
      value: "",
      latitude: selectedLocation?.basin?.latitude,
      longitude: selectedLocation?.basin?.longitude,
      zoom_level: selectedLocation?.basin?.zoom_level,
    },
  ];

  useEffect(() => {
    const CountryData = selectedLocation?.country;

    if (localStorage.getItem("i18nextLng") && CountryData) {
      setLocal(JSON.parse(localStorage.getItem("selectedCountry")));
      dispatch(
        mapPreferenceRequest({
          country_id: CountryData?.value,
          module: selectedModule,
        }),
      );

      dispatch(
        corestateRequest({
          data: {
            parent_id: selectedLocation?.country?.value,
            location_type: State,
          },
          isCompareLocation: false,
        }),
      );

      dispatch(
        corestateRequest({
          data: {
            parent_id: selectedLocation?.country?.value,
            location_type: State,
          },
          isCompareLocation: true,
        }),
      );

      window.scrollTo(0, 0);
      setLocal(CountryData);
      setPosition([CountryData?.latitude, CountryData?.longitude]);
      setZooms(CountryData?.zoom_level);

      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: CountryData,
          state: "",
          district: "",
        };
      });

      setCompareCountry(CountryData);
      dispatch(
        coreBasinRequest({
          data: {
            country_id: selectedLocation?.country?.value,
          },
          isCompareLocation: false,
        }),
      );
    }
  }, [selectedLocation?.country, localStorage.getItem("i18nextLng")]);

  // Re-setting location whenever admin/Basin selection changes
  useEffect(() => {
    const CountryData = selectedLocation?.country;
    if (CountryData) {
      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: CountryData,
          state: "",
          district: "",
          basin: "",
          subBasin: "",
        };
      });
    }
    setShowChart(false); // close chart if open
    setCompare(false);
  }, [adminRadio]);

  useEffect(() => {
    setOpen(true);
    dispatch(
      coreRequest({
        location_type: "country",
        menu_slug: module,
        sub_menu_slug: selectedModule,
      }),
    );
    window.scrollTo(0, 0);
  }, []);

  const datas = local.value;
  const isMapPreferenceList = useRef(true);
  useEffect(() => {
    if (isMapPreferenceList.current) {
      isMapPreferenceList.current = false;
      return;
    }
    if (nextProps.mapPreferenceList.mapPreferenceList) {
      const array = [];
      setGetBoundariesData([...nextProps.mapPreferenceList.mapPreferenceList]);
      nextProps.mapPreferenceList.mapPreferenceList.map((eachValue) =>
        array.push(eachValue.category),
      );
      setMapPreferenceCategories(array);
    }
  }, [nextProps.mapPreferenceList]);

  const isCountryList = useRef(true);
  useEffect(() => {
    if (isCountryList.current) {
      isCountryList.current = false;
      return;
    }
    if (nextProps?.countryList) {
      const countryarray = [];
      renderReactOptionsArraystate(
        nextProps?.countryList,
        "name",
        "id",
        "latitude",
        "longitude",
        "zoom_level",
        "code",
        false,
        null,
        null,
        null,
        null,
        null,
        null,
        "basin_feature_collection",
      )?.map((eachValue) => countryarray.push(eachValue));
      setCountryList(countryarray);
    }
  }, [nextProps?.countryList]);

  const onHandleDrawshapeGraph = (chartData1) => {
    setChartData(chartData1);
  };
  const onHandleZoom = (center, zoom) => {
    setPosition(center);
    setZooms(zoom);
  };

  // function to call the selected distric and state
  const handleSelectedLocation = (filterLocation) => {
    setSelectedLocation(filterLocation);
  };
  const handleChartDisplay = () => {
    setShowChart(!showChart);
  };
  useEffect(() => {
    if (nextProps?.indicator) {
      const parameterArr = [];
      let count = 0;

      nextProps?.indicator?.map((item) => {
        const x = _.clone(item);
        x.indices.map((i) => {
          i.checked = false;
          i.paramIndex = count;
          count += 1;
          parameterArr.push(i);
          return null;
        });
        return null;
      });

      setIndicatorList(parameterArr);
    } else {
      setIndicatorList([]);
    }
  }, [nextProps.indicator]);

  const onHandleViewGraphButton = (
    timeSeriesData,
    otherData,
    compareData,
    compareOtherData,
  ) => {
    setIndicatorsData(date);
    setAggregationData(aggregation);
    seGraphStartDate(startDate);
    setShowChart(true);
    setOpen(false);
    setCompareGraph();
    setChartData("");
    timeSeriesData.start_date = dateFormatter(startDate);
    timeSeriesData.end_date = dateFormatter(endDate);

    if (date?.indices_slug === DrysPell) {
      timeSeriesData.precipitation_threshold =
        otherData?.comparePrecipitationThreshold;
    }
    if (
      otherData?.mins !== "" &&
      date?.indices_slug !== otherData?.DrysPell &&
      date?.indices_slug !== RainfallDeficit
    ) {
      timeSeriesData.min = otherData?.mins;
    }
    if (
      otherData?.maxs !== "" &&
      date?.indices_slug !== DrysPell &&
      date?.indices_slug !== RainfallDeficit
    ) {
      timeSeriesData.max = otherData?.maxs;
    }
    if (date?.indices_slug === TemperatureAnomaly) {
      timeSeriesData.anomaly_year = `${otherData?.anomaly_year?.label}`;
      timeSeriesData.anomaly_month = otherData?.anomaly_month?.value;
      timeSeriesData.spatial_aggregation = Temporal[0]?.value;
      delete timeSeriesData.start_date;
      delete timeSeriesData.end_date;
    }
    if (
      date?.indices_slug === TemperatureAnomaly ||
      date?.indices_slug === RainfallDeficit
    ) {
      timeSeriesData.lt_start_year = `${selectedStartYear?.label}`;
      timeSeriesData.lt_end_year = `${selectedEndYear?.label}`;
    }
    if (date?.indices_slug === RainfallDeficit) {
      timeSeriesData.spatial_aggregation = TemporalAggregation[0]?.value;
    }
    if (selectedLocation?.region && selectedLocation?.region?.length) {
      const API_URL = [];
      selectedLocation?.region.map((item) => {
        const arr = [];
        arr.push(item?.latlngs);
        timeSeriesData.geometry = item.layerType === "marker" ? arr : arr[0];
        const config = {
          layerType: item.layerType,
          latlng: item.layerType === "marker" ? item.latlngs : "",
          polygonCount: item.layerType === "polygon" ? item.polygonCount : "",
        };

        API_URL.push(
          AXIOS_INSTANCE_LOADER.post(
            `${BASE_URL}/${timeSeriesData?.api_url}timeseries`,
            timeSeriesData,
            config,
          ),
        );
        dispatch(startGraphLoader());
        setErrorData({
          showError: false,
          message: "",
        });
        return null;
      });

      Promise.all(API_URL)
        .then((response) => {
          const tempData = {};
          tempData.graph_data = response.map((item) => {
            const graph_data = item?.data?.result?.graph_data;
            graph_data.layerType = item?.config?.layerType;
            graph_data.latlng = item?.config?.latlng;
            graph_data.polygonCount = item?.config?.polygonCount;
            return graph_data;
          });
          dispatch(stopGraphLoader());
          onHandleDrawshapeGraph(tempData, date);
        })
        .catch((error) => {
          dispatch(stopGraphLoader());
          showError(error?.response?.data?.message);
          setErrorData({
            showError: true,
            message: error?.response?.data?.message,
          });
        });

      return;
    }
    if (otherData?.compare) {
      setCompareIndicatorData(seconddate);
      setCompareAggregationData(secondaggregation);
      setGraphEndDate(endDate);
      compareData.start_date = dateFormatter(startDate);
      compareData.end_date = dateFormatter(endDate);
      if (seconddate?.indices_slug === DrysPell) {
        compareData.precipitation_threshold =
          compareOtherData?.secondcomparePrecipitationThreshold;
      }
      if (
        compareOtherData?.secondmins !== "" &&
        seconddate?.indices_slug !== DrysPell &&
        seconddate?.indices_slug !== RainfallDeficit
      ) {
        compareData.min = compareOtherData?.secondmins;
      }
      if (
        compareOtherData?.secondmaxs !== "" &&
        seconddate?.indices_slug !== DrysPell &&
        seconddate?.indices_slug !== RainfallDeficit
      ) {
        compareData.max = compareOtherData?.secondmaxs;
      }
      if (seconddate?.indices_slug === TemperatureAnomaly) {
        compareData.anomaly_year = `${otherData?.anomaly_year?.label}`;
        compareData.anomaly_month = otherData?.anomaly_month?.value;
        compareData.spatial_aggregation = Temporal[0]?.value;
        delete compareData.start_date;
        delete compareData.end_date;
      }
      if (seconddate?.indices_slug === RainfallDeficit) {
        compareData.spatial_aggregation = TemporalAggregation[0]?.value;
      }
      if (
        seconddate?.indices_slug === TemperatureAnomaly ||
        seconddate?.indices_slug === RainfallDeficit
      ) {
        compareData.lt_start_year = `${selectedStartYear?.label}`;
        compareData.lt_end_year = `${selectedEndYear?.label}`;
      }
      if (
        !_.isEmpty(timeSeriesData?.api_url) &&
        !_.isEmpty(compareData?.api_url)
      ) {
        const initialRequestResponse = AXIOS_INSTANCE_LOADER.post(
          `${EARLY_WARNING_API}/${timeSeriesData?.api_url}timeseries`,
          timeSeriesData,
          timeSeriesData?.api_url,
        );
        const finalRequestResponse = AXIOS_INSTANCE_LOADER.post(
          `${EARLY_WARNING_API}/${compareData?.api_url}timeseries`,
          compareData,
          compareData?.api_url,
        );
        dispatch(startGraphLoader());
        setErrorData({
          showError: false,
          message: "",
        });
        Promise.all([initialRequestResponse, finalRequestResponse])
          .then((response) => {
            const tempData = {};
            tempData.graph_data = response.map((item, index) => {
              const graph_data = item?.data?.result?.graph_data;
              graph_data.location =
                index === 0
                  ? {
                      state: timeSeriesData?.state_name,
                      district: timeSeriesData?.district_name,
                      country: timeSeriesData?.country_name,
                      basin: timeSeriesData?.basin_name,
                      sub_basin: timeSeriesData?.sub_basin_name,
                    }
                  : {
                      state: compareData?.state_name,
                      district: compareData?.district_name,
                      country: compareData?.country_name,
                      basin: compareData?.basin_name,
                      sub_basin: compareData?.sub_basin_name,
                    };
              return graph_data;
            });
            dispatch(stopGraphLoader());

            setCompareGraph(tempData);
          })
          .catch((error) => {
            dispatch(stopGraphLoader());
            showError(error?.response?.data?.message);
            setErrorData({
              showError: true,
              message: error?.response?.data?.message,
            });
          });
      }
    }
    !selectedLocation?.region?.length &&
      !otherData?.compare &&
      dispatch(
        getDroughtTimeSeriesRequest(timeSeriesData, otherData?.indicatorurl),
      );
  };

  useEffect(() => {
    if (splitterRef && splitterRef.current) {
      if (showChart) {
        splitterRef.current.setState({ secondaryPaneSize: 60 });
      } else {
        splitterRef.current.setState({ secondaryPaneSize: 0 });
      }
    }
  }, [showChart]);
  useEffect(() => {
    if (!_.isEmpty(selectedStartYear) && !_.isEmpty(selectedEndYear)) {
      if (!_.isEmpty(yearsList)) {
        const anomalyYears = yearsList.filter(
          (eachDate) =>
            eachDate.label >= selectedStartYear.label &&
            eachDate.label <= selectedEndYear.label,
        );
        setAnomalyYearsList(anomalyYears);
      }
    }
  }, [selectedStartYear, selectedEndYear]);

  useEffect(() => {
    if (
      !_.isEmpty(secondselectedStartYear) &&
      !_.isEmpty(secondselectedEndYear)
    ) {
      if (!_.isEmpty(secondyearsList)) {
        const secondanomalyYears = secondyearsList.filter(
          (secondeachDate) =>
            secondeachDate.label >= secondselectedStartYear.label &&
            secondeachDate.label <= secondselectedEndYear.label,
        );
        setsecondAnomalyYearsList(secondanomalyYears);
      }
    }
  }, [secondselectedStartYear, secondselectedEndYear]);

  const onHandleCompareCountry = (e) => {
    setCompareCountry(e);

    dispatch(
      corestateRequest({
        data: {
          parent_id: e?.value,
          location_type: State,
        },
        isCompareLocation: true,
      }),
    );
  };
  useEffect(() => {
    if (date !== "") {
      setStartEnddate(date?.indices_start_date);
      if (
        date?.indices_slug === TemperatureAnomaly ||
        date?.indices_slug === RainfallDeficit
      ) {
        if (
          new Date(date?.indices_start_date).getFullYear() !==
          new Date(date?.indices_end_date).getFullYear() - 2
        ) {
          setStartDate(
            new Date(
              getSelectedDate(
                new Date(date?.indices_end_date).getFullYear() - 2,
              )?.value,
            ),
          );
        } else {
          setStartDate(moment(date?.indices_start_date).toDate());
        }
        setEndDate(moment(date?.indices_end_date).toDate());
      } else {
        setStartDate(
          moment(date?.indices_end_date).subtract(30, "days").toDate(),
        );
        setEndDate(moment(date?.indices_end_date).toDate());
      }
    } else {
      setYearsList([]);
      setStartDate("");
      setEndDate("");
      setSelectedStartYear("");
      setSelectedAnomalyYear("");
      setSelectedEndYear("");
      setMin("");
      setMax("");
    }
  }, [date]);

  useEffect(() => {
    setSecondIndicatorList(nextProps?.secondindicator);
  }, [nextProps?.secondindicator]);

  useEffect(() => {
    const secondIndicatorListCopy = _.cloneDeep(nextProps?.secondindicator);
    if (
      (date?.indices_slug === RainfallDeficit ||
        date?.indices_slug === TemperatureAnomaly) &&
      firstRadio === Graph
    ) {
      secondIndicatorListCopy?.forEach((eachItem, index) => {
        if (eachItem?.category === date?.category_name) {
          eachItem.indices.forEach((element) => {
            if (element?.indices_slug === date?.indices_slug) {
              const a = [];
              a.push(element);
              secondIndicatorListCopy[index].indices = a;
            }
          });
        }
      });
      const finalSecondIndicators = secondIndicatorListCopy?.filter(
        (each) => each?.category === date?.category_name,
      );
      setSecondIndicatorList(finalSecondIndicators);
      setsecondDate("");
      setsecondCollaspedMenu("");
    } else if (firstRadio === Graph) {
      setsecondDate("");
      setsecondCollaspedMenu("");
      secondIndicatorListCopy?.forEach((eachItem, index) => {
        if (eachItem?.category === `${t("OTHER_TOOLS.METEOROLOGICAL")}`) {
          secondIndicatorListCopy[index].indices = eachItem?.indices?.filter(
            (element) =>
              element?.indices_slug !== TemperatureAnomaly &&
              element?.indices_slug !== RainfallDeficit,
          );
        } else {
          secondIndicatorListCopy[index].indices = eachItem?.indices;
        }
      });
      setSecondIndicatorList(secondIndicatorListCopy);
    } else {
      setSecondIndicatorList(nextProps?.secondindicator);
    }
  }, [date, firstRadio]);

  useEffect(() => {
    setsecondDate("");
    setsecondCollaspedMenu("");
  }, [firstRadio]);
  return (
    <div className="dashboard-main-page common-dashboard-ftr">
      {loadingMap && <Loader isSuspense indicator />}
      <Suspense fallback={<Loader isSuspense />}>
        <div className="dash-main-row">
          <div className="dashboard-drought">
            <div className="dashboard-filter-div">
              <div>
                <div className="dashboard-filter">
                  <h3 className="dashboard-filter-h3">
                    {t("DASHBOARD.DROUGHT")}
                  </h3>
                  <Button
                    className="green-btn-sm"
                    onClick={() => setOpenDropdown(!openDropdown)}
                    aria-controls="example-collapse-text"
                    aria-expanded={!openDropdown}
                    disabled
                  >
                    {t("HOME.EARLY_ACTION")}

                    {openDropdown ? (
                      <FaChevronUp className="up-icon" />
                    ) : (
                      <FaChevronDown className="up-icon" />
                    )}
                  </Button>
                  <Dropdown
                    isOpen={openDropdown}
                    className="dashboard-earlyaction"
                  >
                    <DropdownMenu>
                      <DropdownItem>{t("DASHBOARD.AGRICULTURE")}</DropdownItem>
                      <DropdownItem>{t("DASHBOARD.HEALTH")}</DropdownItem>
                      <DropdownItem>{t("DASHBOARD.DISASTER")}</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <Row className="dashboard-drought-section">
                  <Col
                    lg={3}
                    className={
                      openPanel
                        ? "dasboard-drought-expand-1"
                        : "dashboard-drought-col1"
                    }
                  >
                    <Button
                      className="button-expand-panel"
                      onClick={() => setOpenPanel(!openPanel)}
                      aria-controls="example-collapse-text"
                      aria-expanded={openPanel}
                    >
                      {openPanel ? (
                        <div className="panel-btn">
                          <span className="mr-2">Expand Bar</span>
                          <FaAngleRight className="right-icon" />
                        </div>
                      ) : (
                        <div className="tooltip-div">
                          <span className="mr-2 tooltip-text">Hide Bar</span>
                          <FaAngleLeft className="right-icon right-icon-md float-right" />
                        </div>
                      )}
                    </Button>
                    <div
                      className={openPanel ? "d-none" : "dashboard-drought-div"}
                    >
                      <Filter
                        statesList={nextProps.statesList}
                        districtsList={nextProps.districtsList}
                        maps={nextProps.maps}
                        countryList={countryList}
                        indicator={nextProps.indicator}
                        // secondindicator={nextProps.secondindicator}
                        secondindicator={secondIndicatorList}
                        local={local}
                        datas={datas}
                        updatePage={updatePage}
                        legend={legend}
                        comparelegend={comparelegend}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        secondstartDate={secondstartDate}
                        setsecondStartDate={setsecondStartDate}
                        secondendDate={secondendDate}
                        setsecondEndDate={setsecondEndDate}
                        setLegend={setLegend}
                        layerControl={layerControl}
                        setLayerControl={setLayerControl}
                        setcompareLegend={setcompareLegend}
                        setAggregation={setAggregation}
                        aggregation={aggregation}
                        selectedLocation={selectedLocation}
                        handleSelectedLocation={handleSelectedLocation}
                        opaci={opaci}
                        indexLayers={indexLayers}
                        setIndexLayers={setIndexLayers}
                        mapControl={mapControl}
                        setMapControl={setMapControl}
                        mapes={mapes}
                        setMapes={setMapes}
                        selectedLocationPolygon={selectedLocation}
                        dashBoardMenuData={nextProps.dashBoardMenuData}
                        date={date}
                        setDate={setDate}
                        seconddate={seconddate}
                        setsecondDate={setsecondDate}
                        mapPreferencesData={mapPreferencesData}
                        selectedRegions={selectedRegions}
                        selectedDistricts={selectedDistricts}
                        setShowChart={setShowChart}
                        onHandleViewGraphButton={onHandleViewGraphButton}
                        secondaggregation={secondaggregation}
                        setsecondAggregation={setsecondAggregation}
                        compare={compare}
                        setCompare={setCompare}
                        chartData={chartData}
                        setChartData={setChartData}
                        loading={loadingMap}
                        setLoading={setLoadingMap}
                        yearsList={yearsList}
                        setYearsList={setYearsList}
                        secondyearsList={secondyearsList}
                        setsecondYearsList={setsecondYearsList}
                        anomalyYearsList={anomalyYearsList}
                        selectedStartYear={selectedStartYear}
                        setSelectedStartYear={setSelectedStartYear}
                        selectedEndYear={selectedEndYear}
                        setSelectedEndYear={setSelectedEndYear}
                        secondselectedStartYear={secondselectedStartYear}
                        setsecondSelectedStartYear={setsecondSelectedStartYear}
                        secondselectedEndYear={secondselectedEndYear}
                        setsecondSelectedEndYear={setsecondSelectedEndYear}
                        secondanomalyYearsList={secondanomalyYearsList}
                        compareCountry={compareCountry}
                        setCompareCountry={setCompareCountry}
                        onHandleCompareCountry={onHandleCompareCountry}
                        specialStartdate={specialStartdate}
                        setStartEnddate={setStartEnddate}
                        mins={mins}
                        setMin={setMin}
                        maxs={maxs}
                        setMax={setMax}
                        selectedAnomalyYear={selectedAnomalyYear}
                        setOpen={setOpen}
                        setSelectedAnomalyYear={setSelectedAnomalyYear}
                        firstRadio={firstRadio}
                        setFirstRadio={setFirstRadio}
                        secondCollaspedMenu={secondCollaspedMenu}
                        setsecondCollaspedMenu={setsecondCollaspedMenu}
                        setIndicatorsData={setIndicatorsData}
                        selectedBasin={selectedBasin}
                        selectedSubBasin={selectedSubBasin}
                        basinsList={nextProps.basinList}
                        setAdminRadio={setAdminRadio}
                        adminRadio={adminRadio}
                      />
                    </div>
                  </Col>
                  <Col
                    lg={openPanel ? 12 : 9}
                    className={
                      openPanel ? "px-0" : "px-0 dashboard-drought-map"
                    }
                  >
                    <div
                      className={
                        showChart
                          ? "map-space-div visible-splitter"
                          : "map-space-div invisible-splitter"
                      }
                    >
                      <SplitterLayout
                        ref={splitterRef}
                        primaryIndex={0}
                        vertical
                        percentage
                        primaryMinSize={40}
                        secondaryInitialSize={0}
                        onSecondaryPaneSizeChange={(e) =>
                          setGraphPanelHeight(e)
                        }
                      >
                        <div
                          className={
                            showChart
                              ? "dashboard-drought-col-graph-view"
                              : "dashboard-drought-col2"
                          }
                        >
                          <MapContainer
                            local={local}
                            legend={legend}
                            mapes={mapes}
                            setMapes={setMapes}
                            mapControl={mapControl}
                            date={date}
                            mapPreferencesData={mapPreferencesData}
                            setMapPreferencesData={setMapPreferencesData}
                            setDate={setDate}
                            seconddate={seconddate}
                            setsecondDate={setsecondDate}
                            setMapControl={setMapControl}
                            setLegend={setLegend}
                            layerControl={layerControl}
                            setLayerControl={setLayerControl}
                            comparelegend={comparelegend}
                            setcompareLegend={setcompareLegend}
                            layerData={layerData}
                            maps={nextProps.maps}
                            position={position}
                            zooms={zooms}
                            onHandleZoom={onHandleZoom}
                            setOpaci={setOpaci}
                            indexLayers={indexLayers}
                            setIndexLayers={setIndexLayers}
                            startDate={startDate}
                            endDate={endDate}
                            secondstartDate={secondstartDate}
                            secondendDate={secondendDate}
                            boundaryData={
                              nextProps.mapPreferenceList.mapPreferenceList
                            }
                            selectedLocation={selectedLocation}
                            getGraphCall={getGraphCall}
                            handleClickGraph={setGraphCall}
                            showChart={showChart}
                            handleChartDisplay={handleChartDisplay}
                            setSelectedLocation={setSelectedLocation}
                            indicatorList={indicatorList}
                            loadingMap={loadingMap}
                            setOpen={setOpen}
                            compare={compare}
                            adminRadio={adminRadio}
                          />
                        </div>
                        <DroughtGraph
                          indicatorsData={indicatorsData}
                          compareIndicatorData={compareIndicatorData}
                          setShowChart={setShowChart}
                          showChart={showChart}
                          chartData={chartData}
                          startDate={graphStartDate}
                          endDate={graphEndDate}
                          aggregation={aggregationData}
                          compareAggregation={compareAggregationData}
                          compareGraph={compareGraph}
                          compare={compare}
                          errorData={errorData}
                          setErrorData={setErrorData}
                          setCompareGraph={setCompareGraph}
                          setOpen={setOpen}
                          adminRadio={adminRadio}
                          graphHight={graphPanelHeight}
                        />
                      </SplitterLayout>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </div>
  );
}

export default React.memo(Drought);
