import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function QuilTextEditor(props) {
  const { content, onEditorChange } = props;
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ];
  return (
    <div>
      <ReactQuill
        theme="snow"
        value={content === undefined ? "" : content}
        onChange={(Text) => onEditorChange(Text)}
        modules={{
          toolbar: toolbarOptions,
        }}
      />
    </div>
  );
}
