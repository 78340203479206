import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { BsArrowLeft, BsPlusLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import EarlyActionProcess from "../../components/Common/EarlyActionProcess";
import ICON from "../../assest/img/readiness-icon.png";

function ReadinessPhase() {
  const { t } = useTranslation();
  const history = useNavigate();
  const [locationState, setLocationState] = useState();
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("projectDetails"));
    setLocationState(data);
  }, []);

  const handleClickBack = () => {
    history("/preparedness-phase");
  };
  const handleIntervention = () => {
    history("/new-intervention");
  };
  const handleRiskForecast = () => {
    history("/risk-forecasting");
  };

  return (
    <div className="ea-project-container">
      <div className="EA-Header ea-space-between">
        <div>
          <BsArrowLeft
            className="back-icon-ea"
            onClick={handleClickBack}
            role="button"
          />
          <p className="ea-header-text">{locationState?.name} </p>
        </div>
      </div>
      <div className="readiness-header">
        <EarlyActionProcess />
      </div>
      <div className="ea-space ea-space-b">
        <div className="readiness-div">
          <div className="readinness-heading">
            <h2 className="readiness-h2">
              {" "}
              {t("EARLY_ACTIONS.PLANNED_INTERVENTION")}
            </h2>
            <Button className="readiness-btn" onClick={handleIntervention}>
              {t("EARLY_ACTIONS.ADD_NEW_INTERVENTION")}
              <BsPlusLg className="ml-2" />
            </Button>
          </div>
          <Row className="pred-row">
            <Col lg={4} className="read-col">
              <div
                className="readiness-project-card"
                onClick={handleRiskForecast}
                role="button"
                tabIndex="0"
                onKeyUp={() => {}}
              >
                <img src={ICON} alt="" />
                <p>Risk Forecasting, Preparedness and Preventive measures</p>
              </div>
            </Col>
            <Col lg={4} className="read-col">
              <div className="readiness-project-card">
                <img src={ICON} alt="" />
                <p>Risk Forecasting, Preparedness and Preventive measures</p>
              </div>
            </Col>
            <Col lg={4} className="read-col">
              <div className="readiness-project-card">
                <img src={ICON} alt="" />
                <p>Risk Forecasting, Preparedness and Preventive measures</p>
              </div>
            </Col>
          </Row>
          <Row className="pred-row">
            <Col lg={4} className="read-col">
              <div className="readiness-project-card">
                <img src={ICON} alt="" />
                <p>Risk Forecasting, Preparedness and Preventive measures</p>
              </div>
            </Col>
          </Row>
          <div className="readinness-heading mt-3">
            <h2 className="readiness-h2">
              {" "}
              {t("EARLY_ACTIONS.ENABLING_APPROACHES")}
            </h2>
            <Button className="readiness-btn">
              {t("EARLY_ACTIONS.ADD_NEW_APPROACH")}
              <BsPlusLg className="ml-2" />
            </Button>
          </div>
          <Row className="pred-row">
            <Col lg={4} className="read-col">
              <div className="readiness-project-card">
                <img src={ICON} alt="" />
                <p>Risk Forecasting, Preparedness and Preventive measures</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-3">
          <input
            type="checkbox"
            id="box"
            onClick={() => setIsTermsAccepted(!isTermsAccepted)}
          />
          <p className="ea-checkbox-text">
            {t("EARLY_ACTIONS.READINESS_CHECKBOX")}
          </p>
          <Button
            className="ea-create-btn float-right"
            onClick={() =>
              history("/active-phase", {
                state: {
                  queryData: locationState?.state?.queryData,
                },
              })
            }
            disabled={!isTermsAccepted}
          >
            {" "}
            {t("EARLY_ACTIONS.PROCEED")}
          </Button>
        </div>
      </div>
    </div>
  );
}
export default ReadinessPhase;
