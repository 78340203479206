import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import errorImage from "../../assest/img/delete-confirmation.svg";

function DeleteConfirmationModal({
  toggle,
  modal,
  title,
  message,
  onConfirmDelete,
}) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={modal} size="md">
      <div className="error-modal">
        <ModalHeader className="p-0 delete-header user-flex-end mr-2 mt-5" />
        <ModalBody className="delete-modal-padding">
          <div className="reg-success">
            <img
              src={errorImage}
              alt="not-available"
              className="reg-check-image"
            />
            <h2 className="delete-modal-header mt-1 mb-4">{title}</h2>
            <p className="delete-modal-text mb-5">{message}</p>
            <div className="w-50 d-flex align-items-center justify-content-around">
              <Button
                type="button"
                className="delete-confirmation-canlce-btn mr-4"
                onClick={() => {
                  toggle();
                }}
              >
                {t("APPROVE_DATA.CANCEL")}
              </Button>
              <Button
                type="button"
                className="delete-confirmation-ok-btn mb-1"
                onClick={() => {
                  onConfirmDelete();
                }}
              >
                {t("ODK.YES")}
              </Button>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default DeleteConfirmationModal;
