import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import FolderIcon from "../../assest/img/folder-icon.svg";

function ChooseDataModal({ modal, selectedSubModule }) {
  const { t } = useTranslation();
  const history = useNavigate();

  return (
    <Modal isOpen={modal} size="md">
      <div className="success-modal px-3">
        <ModalHeader className="p-0 delete-header user-flex-end mr-2 mt-5" />
        <ModalBody className="delete-modal-padding">
          <div className="reg-success">
            <img
              src={FolderIcon}
              alt="folder-icon"
              className="reg-check-image"
            />
            <h2 className="back-office-succes-head-text">
              {t("EARLY_ACTIONS.CHOOSE_DATA_SOURCE")}
            </h2>
            <p className="back-office-succes-desc">
              {t("EARLY_ACTIONS.CHOOSE_DATA_SOURCE_PARA")}
            </p>
            <div className="w-75 d-flex align-items-center justify-content-around">
              <Button
                type="button"
                className="default-btn mb-1"
                onClick={() => {
                  history({
                    pathname: "/preparedness-phase",
                    search: `?isDefault=true&slug=${selectedSubModule}`,
                  });
                  localStorage.setItem("isDefaultCategory", "true");
                  localStorage.setItem("subStepperActivePhase", 0); // Sub stepper active step
                }}
              >
                {t("EARLY_ACTIONS.DEFAULT_TABLE")}
              </Button>
              <Button
                type="button"
                className="ea-upload-btn mb-1"
                onClick={() => {
                  history({
                    pathname: "/preparedness-phase",
                    search: `?isDefault=false&slug=${selectedSubModule}`,
                  });
                  localStorage.setItem("isDefaultCategory", "false");
                  localStorage.setItem("subStepperActivePhase", 0); // Sub stepper active step
                }}
              >
                {t("EARLY_ACTIONS.UPLOAD_DATA")}
              </Button>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default ChooseDataModal;
