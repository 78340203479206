import React from "react";
import { Collapse } from "reactstrap";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { documentUploadListing } from "../../constants";

function DocumentNavigation({ setSelectedCategory, selectedCategory }) {
  return (
    <div className="upload-border-right">
      {documentUploadListing?.map((each) => {
        const collaspeHeading = Object.keys(each);
        const collapseDropdown = Object.values(each);

        return (
          <div>
            <div
              className="home-clr mt-3"
              role="button"
              tabIndex="0"
              onKeyUp={() => {}}
              onClick={() =>
                setSelectedCategory((prev) => {
                  return {
                    ...prev,
                    collaspe: collaspeHeading?.[0],
                    param: collapseDropdown?.[0]?.[0]?.value,
                    slug: collapseDropdown?.[0]?.[0]?.slug,
                  };
                })
              }
            >
              <span className="du-header-collaspe-heading">
                {collaspeHeading?.length ? collaspeHeading?.[0] : ""}
              </span>

              {collaspeHeading?.[0] === selectedCategory?.collaspe ? (
                <BsChevronUp className="down-icon-upload mt-0" />
              ) : (
                <BsChevronDown className="down-icon-upload mt-0" />
              )}
            </div>
            <Collapse
              isOpen={collaspeHeading?.[0] === selectedCategory?.collaspe}
            >
              {collapseDropdown?.[0]?.map((each1) => {
                return (
                  <div
                    role="button"
                    tabIndex="0"
                    onKeyUp={() => {}}
                    onClick={() =>
                      setSelectedCategory((prev) => {
                        return {
                          ...prev,

                          param: each1?.value,
                          slug: each1?.slug,
                        };
                      })
                    }
                    className={
                      selectedCategory?.param === each1?.value
                        ? "du-collasped-text-selected navagitor-text-container"
                        : "du-collasped-tex-unselected navagitor-text-container"
                    }
                  >
                    <img
                      src={each1?.icon}
                      alt={each?.value}
                      className="navigator-icons mr-1"
                    />
                    <p className="navagitor-text"> {each1?.value}</p>
                  </div>
                );
              })}
            </Collapse>
          </div>
        );
      })}
    </div>
  );
}

export default DocumentNavigation;
