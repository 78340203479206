import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import ManageUserGuideContent from "./UserGuideContent";
import ManageUserGuideFilter from "./Filter";
import {
  searchByCriteriaManageUserGuideRequest,
  manageUserGuideSubTopicsRequest,
  deleteManageUserGuideRequest,
  publishManageUserGuideRequest,
  userDetailsRequest,
} from "../../store/actions";
import DeleteModal from "../../components/Common/DeleteModal";
import PublishModal from "./PublishModal";

import { showSuccess } from "../../components/Common/Notification";

function ManageUserGuide() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [catrgory, setCategory] = useState([]);
  const [selectedCatrgory, setSelectedCategory] = useState(null);
  const [selectedSubCatrgory, setSubSelectedCategory] = useState([]);
  const [subList, setSubList] = useState();
  const [isHide, setIsHide] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isPublishModal, setIsPublishModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState();
  const [publishItem, setPublishItem] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [userGuideList, setUserGuideList] = useState([]);
  const [scrollValue, setScrollValue] = useState(0);

  const nextProps = useSelector((state) => ({
    GuideList: state?.ManageUser?.useMenuData,
    subTopicsList: state?.ManageUser?.subTopicData,
    loading: state?.ManageUser?.isLoading,
    deleteUser: state?.ManageUser?.deleteUser,
    publishUser: state?.ManageUser?.publishUser,
    userDetails: state.User?.userDetails,
  }));
  const userId = JSON.parse(Cookies.get("user"))?.user_details?.[0]?.id;

  useEffect(() => {
    dispatch(
      searchByCriteriaManageUserGuideRequest({
        page: 1,
        data: {
          is_backoffice: "True",
          is_delete: "False",
        },
      }),
    );
    dispatch(manageUserGuideSubTopicsRequest({}));
    setSelectedCategory(null);
    setSubList();
    dispatch(userDetailsRequest({ id: userId }));
  }, [localStorage.getItem("i18nextLng")]);

  const isSubTopicRef = useRef(true);
  useEffect(() => {
    if (isSubTopicRef.current) {
      isSubTopicRef.current = false;
      return;
    }
    if (nextProps.subTopicsList?.length) {
      setCategory(nextProps.subTopicsList);
    }
  }, [nextProps?.subTopicsList]);

  const isGuideListRef = useRef(true);
  useEffect(() => {
    if (isGuideListRef.current) {
      isGuideListRef.current = false;
      return;
    }
    if (nextProps.GuideList) {
      let new_list = [];

      if (nextProps?.GuideList?.results) {
        const result1 = nextProps?.GuideList?.results;
        if (userGuideList) {
          if (pageNumber > 1) {
            new_list = userGuideList.concat(result1);
          } else {
            new_list = result1;
          }
        } else {
          new_list = result1;
        }
        setUserGuideList(new_list);
        setPageCount(nextProps?.GuideList?.count);
      } else {
        setUserGuideList([]);
        setPageCount();
      }
    }
    if (scrollValue !== 0) {
      window.scrollTo({ top: scrollValue, behavior: "auto" });
    }
  }, [nextProps?.GuideList]);

  const isDeleteRef = useRef(true);
  useEffect(() => {
    if (isDeleteRef.current) {
      isDeleteRef.current = false;
      return;
    }
    if (nextProps.deleteUser) {
      setIsDeleteModal(false);
      showSuccess(nextProps.deleteUser);
      setPageNumber(1);
      setUserGuideList([]);

      dispatch(
        searchByCriteriaManageUserGuideRequest({
          page: 1,
          data: {
            is_backoffice: "True",
            is_delete: "False",
          },
        }),
      );
      dispatch(manageUserGuideSubTopicsRequest({}));
    }
  }, [nextProps?.deleteUser]);

  const isPublishUserRef = useRef(true);
  useEffect(() => {
    if (isPublishUserRef.current) {
      isPublishUserRef.current = false;
      return;
    }
    if (nextProps.publishUser) {
      setPageNumber(1);
      setScrollValue(parseInt(window.pageYOffset, 10));
      setIsPublishModal(false);
      setUserGuideList([]);
      showSuccess(nextProps.publishUser);
      dispatch(
        searchByCriteriaManageUserGuideRequest({
          page: 1,
          data: {
            is_backoffice: "True",
            is_delete: "False",
          },
        }),
      );
      dispatch(manageUserGuideSubTopicsRequest({}));
    }
  }, [nextProps?.publishUser]);

  const onDeleteUserGuide = (data) => {
    setDeleteItem(data);
    setIsDeleteModal(true);
  };

  useEffect(() => {
    if (!isDeleteModal) {
      setDeleteItem();
    }
  }, [isDeleteModal]);

  const onConfirmDelete = () => {
    setScrollValue(parseInt(window.pageYOffset, 10));
    dispatch(deleteManageUserGuideRequest({ id: deleteItem?.id }));
  };

  const onPublishUserGuide = (data) => {
    setIsPublishModal(true);
    setPublishItem(data);
  };

  const onChangeStatus = (data) => {
    const publishData = {
      status: !data.status,
      id: data.id,
    };
    dispatch(publishManageUserGuideRequest(publishData));
  };

  return (
    <>
      {isPublishModal && (
        <PublishModal
          modal={isPublishModal}
          toggle={setIsPublishModal}
          data={publishItem}
          setPublishItem={setPublishItem}
          onChangeStatus={onChangeStatus}
        />
      )}
      <Row>
        {!isHide && (
          <Col sm={2} className="filter-container p-0">
            <ManageUserGuideFilter
              catrgory={catrgory}
              selectedSubCatrgory={selectedSubCatrgory}
              setSelectedCategory={setSelectedCategory}
              selectedCatrgory={selectedCatrgory}
              setSubSelectedCategory={setSubSelectedCategory}
              setSubList={setSubList}
              subList={subList}
              setIsHide={setIsHide}
              isHide={isHide}
              setUserGuideList={setUserGuideList}
              setPageNumber={setPageNumber}
              setScrollValue={setScrollValue}
            />
          </Col>
        )}
        <Col sm={isHide ? 12 : 10} className="userguide-content p-0">
          <ManageUserGuideContent
            name={t("USER_GUIDE.MANAGE_USER")}
            userList={userGuideList}
            setIsHide={setIsHide}
            isHide={isHide}
            onDeleteUserGuide={onDeleteUserGuide}
            loading={nextProps.loading}
            setIsPublishModal={setIsPublishModal}
            onPublishUserGuide={onPublishUserGuide}
            pageCount={pageCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            selectedCatrgory={selectedCatrgory}
            subList={subList}
            permissionsList={nextProps.userDetails?.user_permissions}
          />
        </Col>
      </Row>
      {isDeleteModal && (
        <DeleteModal
          modal={isDeleteModal}
          toggle={setIsDeleteModal}
          onConfirmDelete={onConfirmDelete}
          message={t("DELETE_MODAL.MESSAGE")}
        />
      )}
    </>
  );
}

export default ManageUserGuide;
