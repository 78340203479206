// import { act } from "react-dom/test-utils";
import CORE_CONST from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};
const Core = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case CORE_CONST.CORE_REQUEST:
      state = {
        ...state,
        loading: true,
        coreList: null,
        error: "",
      };
      break;
    case CORE_CONST.CORE_SUCCESS:
      state = {
        ...state,
        loading: false,
        coreList:
          action.payload.statusCode === 200 ? action.payload.data : false,
        error: "",
      };
      break;
    case CORE_CONST.CORE_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        coreList: null,
      };
      break;

    case CORE_CONST.CORE_STATE_REQUEST:
      state = {
        ...state,
        loading: true,
        corestateList: null,
        error: "",
      };
      break;
    case CORE_CONST.CORE_STATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        corestateList:
          action.payload.statusCode === 200 ? action.payload : false,
        error: "",
        isCreateState: action?.payload?.isCreate,
      };
      break;
    case CORE_CONST.CORE_STATE_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        corestateList: null,
      };
      break;

    case CORE_CONST.CORE_DISTRICT_REQUEST:
      state = {
        ...state,
        loading: true,
        coredistrictList: null,
        error: "",
        isCompareLocation: action.payload.isCompareLocation,
        isCreateDistrict: action.payload?.isCreate,
      };
      break;
    case CORE_CONST.CORE_DISTRICT_SUCCESS:
      state = {
        ...state,
        loading: false,
        coredistrictList:
          action.payload.statusCode === 200 ? action.payload : false,
        error: "",
        isCompareLocation: action.payload.isCompareLocation,
      };
      break;
    case CORE_CONST.CORE_DISTRICT_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        coredistrictList: null,
        isCompareLocation: action.payload.isCompareLocation,
      };
      break;

    case CORE_CONST.CORE_BASIN_REQUEST:
      state = {
        ...state,
        loading: true,
        coreBasinList: null,
        error: "",
        isCompareLocation: action.payload.isCompareLocation,
      };
      break;
    case CORE_CONST.CORE_BASIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        coreBasinList:
          action.payload.statusCode === 200 ? action.payload : false,
        error: "",
        isCompareLocation: action.payload.isCompareLocation,
      };
      break;
    case CORE_CONST.CORE_BASIN_ERROR:
      state = {
        ...state,
        error: action.payload.data ? action.payload.data.errorDescription : "",
        loading: false,
        coreBasinList: null,
        isCompareLocation: action.payload.isCompareLocation,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
export default Core;
