import React from "react";
import carter from "../../assest/img/caret.png";
import { dateFormatters } from "../../helpers";

const CustomDateInput = React.forwardRef(
  ({ onClick, customPlaceholder, data, displaytext, disabled }, ref) => {
    const customDate = dateFormatters(data);
    return (
      <button
        type="button"
        className="date-filed-crop-yield date-field-spacing rp-datepicker"
        onClick={onClick}
        ref={ref}
        disabled={disabled}
      >
        <p> {!data ? customPlaceholder : ` ${displaytext} : ${customDate}`} </p>
        <img
          src={carter}
          alt="dropdownimage"
          className="drop-down-icon-image"
        />
      </button>
    );
  },
);

export default CustomDateInput;
