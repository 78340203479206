import { takeEvery, fork, all, call, put } from "redux-saga/effects";
// Login Redux States
import CLIMBER_CONST from "./actionTypes";
import {
  climberBannerSuccess,
  climberBannerError,
  climberPartnerSuccess,
  climberPartnerError,
  climberAboutUsSuccess,
  climberAboutUsError,
  climberOtherToolsSuccess,
  climberOtherToolsError,
  climberFooterError,
  climberFooterSuccess,
} from "./actions";
import checkHttpStatus from "../apiUtils";
import { CLIMBER_BANNER_API, AXIOS_INSTANCE } from "../apiUtils/config";
import baseMethod from "../../components/Common/BaseMethod";

function* climberBanner(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${CLIMBER_BANNER_API}/banners/${action.payload.system}/${action.payload.order}?country_id=${action.payload?.country_id}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };

      yield put(climberBannerSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(climberBannerError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(climberBannerError(errorData));
  }
}

function* climberPartner(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${CLIMBER_BANNER_API}/partners/${action.payload.order}${
          action.payload.country_id !== undefined
            ? `?country_id=${action.payload.country_id}`
            : ""
        }`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };

      yield put(climberPartnerSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(climberPartnerError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(climberPartnerError(errorData));
  }
}

function* climberAboutUs(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${CLIMBER_BANNER_API}/about-us/${action.payload.system}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };

      yield put(climberAboutUsSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(climberAboutUsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(climberAboutUsError(errorData));
  }
}

function* climberOtherTools() {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${CLIMBER_BANNER_API}/tools/general_tools`),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };

      yield put(climberOtherToolsSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(climberOtherToolsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(climberOtherToolsError(errorData));
  }
}

function* climberFooter(action) {
  AXIOS_INSTANCE.defaults.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng");
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${CLIMBER_BANNER_API}/footer_logos/${action.payload}`,
      ),
      "",
      "",
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.status === 200) {
      const responseData = {
        statusCode: 200,
        data: response.data.result,
      };

      yield put(climberFooterSuccess(responseData));
    } else {
      const responseData = {
        // statusCode: response.data.error.error || 400,
        data: response.data.error,
      };
      yield put(climberFooterError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(climberOtherToolsError(errorData));
  }
}

export function* watchClimberBanner() {
  yield takeEvery(CLIMBER_CONST.CLIMBER_BANNER_REQUEST, climberBanner);
}

export function* watchClimberPartner() {
  yield takeEvery(CLIMBER_CONST.CLIMBER_PARTNERS_REQUEST, climberPartner);
}

export function* watchClimberAboutUs() {
  yield takeEvery(CLIMBER_CONST.CLIMBER_ABOUT_US_REQUEST, climberAboutUs);
}

export function* watchClimberOtherTools() {
  yield takeEvery(CLIMBER_CONST.CLIMBER_OTHER_TOOLS_REQUEST, climberOtherTools);
}

export function* watchClimberFooter() {
  yield takeEvery(CLIMBER_CONST.CLIMBER_FOOTER_REQUEST, climberFooter);
}

function* ClimberSaga() {
  yield all([
    fork(watchClimberBanner),
    fork(watchClimberPartner),
    fork(watchClimberAboutUs),
    fork(watchClimberOtherTools),
    fork(watchClimberFooter),
  ]);
}

export default ClimberSaga;
