const BOUNDARY_CONST = {
  COUNTRY_BOUNDRY_REQUEST: "COUNTRY_BOUNDRY_REQUEST",
  COUNTRY_BOUNDRY_SUCCESS: "COUNTRY_BOUNDRY_SUCCESS",
  COUNTRY_BOUNDRY_ERROR: "COUNTRY_BOUNDRY_ERROR",

  // state boundry
  STATE_BOUNDRY_REQUEST: "STATE_BOUNDRY_REQUEST",
  STATE_BOUNDRY_SUCCESS: "STATE_BOUNDRY_SUCCESS",
  STATE_BOUNDRY_ERROR: "STATE_BOUNDRY_ERROR",

  // talukas  boundry
  TALUKAS_BOUNDRY_REQUEST: "TALUKAS_BOUNDRY_REQUEST",
  TALUKAS_BOUNDRY_SUCCESS: "TALUKAS_BOUNDRY_SUCCESS",
  TALUKAS_BOUNDRY_ERROR: "TALUKAS_BOUNDRY_ERROR",
};

export default BOUNDARY_CONST;
