import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, Label, Input } from "reactstrap";
import Select from "react-select";

function ClimateAdaptModal({
  toggle,
  modal,
  countryList,
  stateList,
  riskList,
  districtsList,
  setSelectedLocation,
  setCommunityName,
  setSelectedRisk,
  selectedLocation,
  onCreateProject,
  communtyName,
  selectedRisk,
  setStateListCreate,
  setDistrictsListCreate,
}) {
  const { t } = useTranslation();

  const onCountrySelection = (e) => {
    setSelectedLocation((prev) => {
      return {
        ...prev,
        country: e,
        state: "",
        district: "",
      };
    });
    if (e.value === "") {
      setDistrictsListCreate([]);
      setStateListCreate([]);
    }
  };

  const onSelectState = (e) => {
    setSelectedLocation((prev) => {
      return {
        ...prev,
        state: e,
        district: "",
      };
    });
  };

  const onSelectDistrict = (e) => {
    setSelectedLocation((prev) => {
      return {
        ...prev,

        district: e,
      };
    });
  };
  const onHandleSubmit = () => {
    onCreateProject();
  };

  return (
    <div>
      <Modal isOpen={modal} size="md" toggle={toggle}>
        <ModalBody className="climate-modal-padding">
          <p className="creat-project-modal-title mt-4">
            {" "}
            {t("EARLY_ACTIONS.CREATE_PROJECT")}{" "}
          </p>
          <div className="create-project">
            <div className="dash-drought-col-div">
              <Label className="region-label ">
                {t("EARLY_ACTIONS.COUNTRY")}
              </Label>
              <Select
                options={countryList}
                classNamePrefix="react-select"
                value={selectedLocation?.country}
                className="dash-select common-select-r"
                placeholder={t("OTHER_TOOLS.COUNTRY")}
                onChange={onCountrySelection}
              />
              <Label className="region-label mt-3">
                {t("EARLY_ACTIONS.STATE")} {t("EARLY_ACTIONS.*")}
              </Label>
              <Select
                options={stateList}
                classNamePrefix="react-select"
                value={selectedLocation?.state}
                className="dash-select common-select-r"
                onChange={onSelectState}
                placeholder={t("EARLY_ACTIONS.SELECT_STATE")}
              />
              <Label className="region-label mt-3">
                {" "}
                {t("EARLY_ACTIONS.DISTRICT")}
              </Label>
              <Select
                options={districtsList}
                classNamePrefix="react-select"
                value={selectedLocation?.district}
                onChange={onSelectDistrict}
                className="dash-select common-select-r"
                placeholder={t("EARLY_ACTIONS.SELECT_DISTRICT")}
              />

              <Label className="region-label mt-3">
                {" "}
                {t("EARLY_ACTIONS.COMMUNITY_NAME")}
              </Label>
              <Input
                classNamePrefix=""
                className="community-name"
                placeholder={t("EARLY_ACTIONS.WRITE_A_PROJECT_NAME")}
                onChange={(e) => setCommunityName(e.target.value)}
              />
              <p className="error">
                {" "}
                {communtyName.length > 31
                  ? t("VALIDATION.CREATE_PROJECT_VALIDATION")
                  : ""}{" "}
              </p>
              <Label className="region-label mt-3">
                {" "}
                {t("EARLY_ACTIONS.RISK_TYPE")}
              </Label>
              <Select
                options={riskList}
                classNamePrefix="react-select"
                className="dash-select common-select-r"
                placeholder={t("EARLY_ACTIONS.SELECT_RISK_TYPE")}
                onChange={(e) => setSelectedRisk(e)}
              />
            </div>
          </div>
          <div className=" mt-4  text-end">
            <Button className="create-project-btn ml-2" onClick={toggle}>
              {" "}
              {t("EARLY_ACTIONS.CANCLE")}
            </Button>
            <Button
              className="climate-adapt-open-btn ml-2"
              onClick={onHandleSubmit}
              disabled={
                selectedLocation?.country === "" ||
                selectedLocation?.country?.value === "" ||
                selectedLocation?.state === "" ||
                selectedLocation?.district === "" ||
                communtyName === "" ||
                communtyName.length > 31 ||
                selectedRisk?.length === 0 ||
                selectedRisk?.value === ""
              }
            >
              {t("EARLY_ACTIONS.OPEN")}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ClimateAdaptModal;
