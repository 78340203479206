import USER_CONST from "./actionTypes";

export const organizationRequest = (payload) => {
  return {
    type: USER_CONST.ORGANIZATION_REQUEST,
    payload,
  };
};
export const organizationSuccess = (user) => {
  return {
    type: USER_CONST.ORGANIZATION_SUCCESS,
    payload: user,
  };
};
export const organizationError = (error) => {
  return {
    type: USER_CONST.ORGANIZATION_ERROR,
    payload: error,
  };
};

export const registerationRequest = (payload) => {
  return {
    type: USER_CONST.REGISTERATION_REQUEST,
    payload,
  };
};
export const registerationSuccess = (user) => {
  return {
    type: USER_CONST.REGISTERATION_SUCCESS,
    payload: user,
  };
};
export const registerationError = (error) => {
  return {
    type: USER_CONST.REGISTERATION_FAILURE,
    payload: error,
  };
};

export const userVerificationRequest = (payload) => {
  return {
    type: USER_CONST.REGISTERATION_VERIFICATION_REQUEST,
    payload,
  };
};
export const userVerificationSuccess = (user) => {
  return {
    type: USER_CONST.REGISTERATION_VERIFICATION_SUCCESS,
    payload: user,
  };
};
export const userVerificationError = (error) => {
  return {
    type: USER_CONST.REGISTERATION_VERIFICATION_FAILURE,
    payload: error,
  };
};

// USER DETAILS
export const userDetailsRequest = (payload) => {
  return {
    type: USER_CONST.USER_DETAILS_REQUEST,
    payload,
  };
};
export const userDetailsSuccess = (user) => {
  return {
    type: USER_CONST.USER_DETAILS_SUCCESS,
    payload: user,
  };
};
export const userDetailsError = (error) => {
  return {
    type: USER_CONST.USER_DETAILS_FAILURE,
    payload: error,
  };
};

export const userOdkInfoUpdateRequest = (data) => ({
  type: USER_CONST.USER_ODK_INFO_UPDATE_REQUEST,
  payload: data,
});

export const userOdkInfoUpdateSuccess = (response) => ({
  type: USER_CONST.USER_ODK_INFO_UPDATE_SUCCESS,
  payload: response,
});

export const userOdkInfoUpdateFailed = (error) => ({
  type: USER_CONST.USER_ODK_INFO_UPDATE_FAILED,
  payload: error,
});

export const getLoginUserDetailsRequest = (data) => ({
  type: USER_CONST.LOGIN_USER_DETAILS_REQUEST,
  payload: data,
});

export const getLoginUserDetailsSuccess = (response) => ({
  type: USER_CONST.LOGIN_USER_DETAILS_SUCCESS,
  payload: response,
});

export const getLoginUserDetailsFailed = (error) => ({
  type: USER_CONST.LOGIN_USER_DETAILS_FAILED,
  payload: error,
});
