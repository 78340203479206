const EARLY_FINANCE_REPORT_CONST = {
  ADD_EARLY_FINANCE_REPORT_REQUEST: "ADD_EARLY_FINANCE_REPORT_REQUEST",
  ADD_EARLY_FINANCE_REPORT_SUCCESS: "ADD_EARLY_FINANCE_REPORT_SUCCESS",
  ADD_EARLY_FINANCE_REPORT_ERROR: "ADD_EARLY_FINANCE_REPORT_ERROR",

  GET_EARLY_FINANCE_REPORT_REQUEST: "GET_EARLY_FINANCE_REPORT_REQUEST",
  GET_EARLY_FINANCE_REPORT_SUCCESS: "GET_EARLY_FINANCE_REPORT_SUCCESS",
  GET_EARLY_FINANCE_REPORT_ERROR: "GET_EARLY_FINANCE_REPORT_ERROR",

  EDIT_EARLY_FINANCE_REPORT_REQUEST: "EDIT_EARLY_FINANCE_REPORT_REQUEST",
  EDIT_EARLY_FINANCE_REPORT_SUCCESS: "EDIT_EARLY_FINANCE_REPORT_SUCCESS",
  EDIT_EARLY_FINANCE_REPORT_ERROR: "EDIT_EARLY_FINANCE_REPORT_ERROR",

  DELETE_EARLY_FINANCE_REFERENCES_REQUEST:
    "DELETE_EARLY_FINANCE_REFERENCES_REQUEST",
  DELETE_EARLY_FINANCE_REFERENCES_SUCCESS:
    "DELETE_EARLY_FINANCE_REFERENCES_SUCCESS",
  DELETE_EARLY_FINANCE_REFERENCES_ERROR:
    "DELETE_EARLY_FINANCE_REFERENCES_ERROR",

  SEND_EARLY_FINANCE_REPORT_REQUEST: "SEND_EARLY_FINANCE_REPORT_REQUEST",
  SEND_EARLY_FINANCE_REPORT_SUCCESS: "SEND_EARLY_FINANCE_REPORT_SUCCESS",
  SEND_EARLY_FINANCE_REPORT_ERROR: "SEND_EARLY_FINANCE_REPORT_ERROR",
};
export default EARLY_FINANCE_REPORT_CONST;
