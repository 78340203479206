import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import _ from "lodash";
import Select from "react-select";
import { Collapse, Button, Label } from "reactstrap";
import UpIcon from "../../assest/img/up.png";
import DownIcon from "../../assest/img/down.png";
import Close from "../../assest/img/close.png";
import {
  renderAPIParameterOptions,
  FORECAST_LEAD_TIME_LIST,
} from "../../components/Common/Utils";
import { formickCheckboxField } from "../../components/Common/FormickField";

function RightSideModal({
  IRIAvailableYearList,
  IRIForecastTime,
  setIRIAvailableYearList,
  setEndIRIAvailableYearList,
  setIRIForecastTime,
  endIRIForecastTime,
  endIRIAvailableYearList,
  setIRIAvailableMonthList,
  IRIAvailableMonthList,
  setEndIRIAvailableMonthList,
  endIRIAvailableMonthList,
  forecastAvailableDates,
  formFilterData,
  handleFilterFormData,
  initialData,
  handleFilter,
  setToggleRightBar,
  toggleRightBar,
  setEndIRIForecastTime,
}) {
  const { t } = useTranslation();

  const [isDataSourceOpen, setIsDataSourceOpen] = useState(false);

  const [isParametersOpen, setIsParametersOpen] = useState(false);
  const [isDataRangeOpen, setIsDataRangeOpen] = useState(false);
  const [initialValues] = useState({
    dataSource: true,
    Precipitation: "",
  });

  const filter_req_data = {
    weather_forecast_source_slug: [],
    weather_forecast_parameter_slug: [],
    start_date: null,
    end_date: null,
    limit: 0,
    iri_start_date: "",
    iri_end_date: "",
  };

  const [invalidYear, setInvalidyear] = useState(false);
  const [invalidMonth, setInvalidMonth] = useState(false);
  const [emptyIriFields, setEmptyIriFields] = useState(false);

  const handleForecastLeadMonthFormat = (a) => {
    let b;
    FORECAST_LEAD_TIME_LIST().map((i) => {
      if (parseInt(i?.value, 10) === parseInt(a?.category, 10)) {
        b = {
          name: i?.name,
          value: i?.name,
          label: i?.name,
          id: i?.id,
          data: a?.data,
        };
      }
      return null;
    });
    return b;
  };
  const handleForecastTimeYear = (e) => {
    setIRIAvailableMonthList([]);
    IRIForecastTime.year = e;
    IRIForecastTime.month = null;
    IRIForecastTime.lead_time = null;
    setIRIForecastTime({ ...IRIForecastTime });
  };

  useEffect(() => {
    formFilterData.weather_forecast_source_slug = ["iri"];
    formFilterData.weather_forecast_parameter_slug = [
      initialData?.parameter?.slug,
    ];
  }, [initialData]);
  const handleForecastLeadTimeFormat = (a) => {
    let latest_list;
    const list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST()));
    const option_list = list?.splice(parseInt(a?.forecast_time_month, 10), 4);
    const new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST()));
    const new_arr = new_list?.splice(parseInt(0, 10), 4 - option_list.length);
    // eslint-disable-next-line prefer-const
    latest_list = option_list.concat(new_arr);
    latest_list?.map((i, index) => {
      i.name = `${i.name}( ${index + 1} Month)`;
      return null;
    });
    return latest_list[parseInt(a?.forecast_lead_time, 10) - 1];
  };

  const handleForecastTimeMonth = (e) => {
    IRIForecastTime.month = e;
    IRIForecastTime.lead_time = null;
    setIRIForecastTime({ ...IRIForecastTime });
  };

  const handleEndForecastTimeYear = (e) => {
    setEndIRIAvailableMonthList([]);

    endIRIForecastTime.year = e;
    endIRIForecastTime.month = null;

    setEndIRIForecastTime({ ...endIRIForecastTime });
  };

  const handleEndForecastTimeMonth = (e) => {
    endIRIForecastTime.month = e;

    setEndIRIForecastTime({ ...endIRIForecastTime });
  };

  useEffect(() => {
    if (
      Number(endIRIForecastTime?.year?.label) <
      Number(IRIForecastTime?.year?.label)
    ) {
      setInvalidyear(true);
    } else {
      setInvalidyear(false);
    }
  }, [
    endIRIForecastTime?.year,
    IRIForecastTime?.year,
    endIRIForecastTime?.month,
    IRIForecastTime?.month,
  ]);

  useEffect(() => {
    if (
      endIRIForecastTime?.year?.label ||
      IRIForecastTime?.year?.label ||
      endIRIForecastTime?.month?.label ||
      IRIForecastTime?.month?.label
    ) {
      if (
        endIRIForecastTime?.year?.label &&
        IRIForecastTime?.year?.label &&
        endIRIForecastTime?.month?.label &&
        IRIForecastTime?.month?.label
      ) {
        setEmptyIriFields(false);
      } else {
        setEmptyIriFields(true);
      }
    } else {
      setEmptyIriFields(false);
    }
  }, [endIRIForecastTime, IRIForecastTime]);

  useEffect(() => {
    if (
      Number(endIRIForecastTime?.year?.label) ===
      Number(IRIForecastTime?.year?.label)
    ) {
      if (
        Number(IRIForecastTime?.month?.id) >=
        Number(endIRIForecastTime?.month?.id)
      ) {
        setInvalidMonth(true);
      } else {
        setInvalidMonth(false);
      }
    } else {
      setInvalidMonth(false);
    }
  }, [
    endIRIForecastTime?.month,
    IRIForecastTime?.month,
    endIRIForecastTime?.year,
    IRIForecastTime?.year,
  ]);

  useEffect(() => {
    if (!_.isEmpty(forecastAvailableDates)) {
      const list = [];
      forecastAvailableDates?.map((i) => {
        list.push({
          id: i?.category,
          name: i?.category,
          value: i?.category,
          label: i?.category,
          data: i?.data,
        });
        return null;
      });
      setIRIAvailableYearList(list);
      setEndIRIAvailableYearList(list);
    }
  }, [forecastAvailableDates]);

  useEffect(() => {
    if (!_.isEmpty(IRIForecastTime.year)) {
      let data;
      const list = [];
      IRIAvailableYearList?.map((i) => {
        if (i?.value === IRIForecastTime?.year?.value) {
          data = i?.data;
        }
        return null;
      });
      data?.map((a) => {
        list.push(handleForecastLeadMonthFormat(a));
        return null;
      });
      setIRIAvailableMonthList(list);
    }
  }, [IRIForecastTime.year]);
  useEffect(() => {
    if (!_.isEmpty(endIRIForecastTime.year)) {
      let data;
      const list = [];
      endIRIAvailableYearList?.map((i) => {
        if (i?.value === endIRIForecastTime?.year?.value) {
          data = i?.data;
        }
        return null;
      });
      data?.map((a) => {
        list.push(handleForecastLeadMonthFormat(a));
        return null;
      });
      setEndIRIAvailableMonthList(list);
    }
  }, [endIRIForecastTime.year]);

  useEffect(() => {
    if (IRIForecastTime?.year && IRIForecastTime.month) {
      formFilterData.iri_start_date = `${IRIForecastTime?.year.label}-${IRIForecastTime?.month.id}`;
      handleFilterFormData(formFilterData);
    } else {
      formFilterData.iri_start_date = "";
      handleFilterFormData(formFilterData);
    }
  }, [IRIForecastTime]);

  useEffect(() => {
    if (endIRIForecastTime?.year && endIRIForecastTime.month) {
      formFilterData.iri_end_date = `${endIRIForecastTime?.year.label}-${endIRIForecastTime?.month.id}`;
      handleFilterFormData(formFilterData);
    } else {
      formFilterData.iri_end_date = "";
      handleFilterFormData(formFilterData);
    }
  }, [endIRIForecastTime]);

  useEffect(() => {
    if (!_.isEmpty(IRIForecastTime.month)) {
      let data;
      const list = [];
      IRIAvailableMonthList.map((i) => {
        if (i?.value === IRIForecastTime.month?.value) {
          data = i?.data;
        }
        return null;
      });
      data?.map((a) => {
        list.push(handleForecastLeadTimeFormat(a));
        return null;
      });
    }
  }, [IRIForecastTime.month]);

  const onSubmit = () => {
    handleFilter(formFilterData);
  };

  const handleClearFilters = () => {
    setIsDataRangeOpen(false);
    setIsParametersOpen(false);
    setIsDataSourceOpen(false);
    handleFilter(filter_req_data);
    setIRIForecastTime({
      year: null,
      month: null,
      lead_time: null,
    });
    setEndIRIForecastTime({
      year: null,
      month: null,
      lead_time: null,
    });
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  return (
    <div className="st-right-sidebar-wrapper">
      <div className="d-flex st-filter-header">
        <h5 className="st-txt-primary">{t("FILTER.FILTER")}</h5>
        <div
          className="st-close ml-auto cursor"
          onClick={() => {
            setToggleRightBar(!toggleRightBar);
          }}
          role="button"
          tabIndex="0"
          onKeyUp={() => {}}
        >
          <img className="st-close-img" alt="not-available" src={Close} />
        </div>
      </div>
      <div
        className="st-acc-bar"
        onClick={() => setIsDataSourceOpen(!isDataSourceOpen)}
        role="button"
        tabIndex="0"
        onKeyUp={() => {}}
      >
        <div className="d-flex">
          <h6 className="st-txt-primary">{t("FILTER.DATA_SOURCE")}</h6>
          <div className="st-close ml-auto">
            <img
              className="st-close-img"
              alt="not-available"
              src={isDataSourceOpen ? UpIcon : DownIcon}
            />
          </div>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {() => (
          <Form id="filter-form">
            <div className="st-collapse-padding">
              <Collapse isOpen={isDataSourceOpen}>
                <Field
                  name="dataSource"
                  id="dataSource"
                  component={formickCheckboxField}
                  type="checkbox"
                  onChangeField={() => {}}
                  className="data-source-checkbox"
                  lable=" IRI "
                  checked
                  disabled
                />
              </Collapse>
            </div>
            <div
              className="st-acc-bar"
              onClick={() => setIsParametersOpen(!isParametersOpen)}
              role="button"
              tabIndex="0"
              onKeyUp={() => {}}
            >
              <div className="d-flex">
                <h6 className="st-txt-primary">{t("FILTER.PARAMETERS")} </h6>
                <div className="st-close ml-auto">
                  <img
                    className="st-close-img"
                    alt="not-available"
                    src={isParametersOpen ? UpIcon : DownIcon}
                  />
                </div>
              </div>
            </div>
            <div>
              <Collapse isOpen={isParametersOpen}>
                <div className="st-collapse-padding">
                  <Field
                    name="Precipitation"
                    id="Precipitation"
                    component={formickCheckboxField}
                    type="checkbox"
                    onChangeField={() => {}}
                    className="data-source-checkbox"
                    lable="Precipitation"
                    checked
                    disabled
                  />
                </div>
              </Collapse>
            </div>
            <br />
            <div
              className="st-acc-bar"
              onClick={() => setIsDataRangeOpen(!isDataRangeOpen)}
              role="button"
              tabIndex="0"
              onKeyUp={() => {}}
            >
              <div className="d-flex">
                <h6 className="st-txt-primary">{t("FILTER.DATE_RANGE")} </h6>
                <div className="st-close ml-auto">
                  <img
                    className="st-close-img"
                    alt="not-available"
                    src={isDataRangeOpen ? UpIcon : DownIcon}
                  />
                </div>
              </div>
            </div>
            <div>
              <Collapse isOpen={isDataRangeOpen}>
                <div className="st-collapse-padding">
                  <div className="iri-position">
                    <Label className="iri-label-static">
                      {t("MANAGE_IRI.START_FORECAST_YEAR_LABLE")}
                    </Label>
                    <Select
                      classNamePrefix="react-select"
                      placeholder={t(
                        "MANAGE_IRI.START_FORECAST_YEAR_PLACEHOLDER",
                      )}
                      onChange={handleForecastTimeYear}
                      value={IRIForecastTime?.year}
                      className="data-sources-select-1 common-select-r  mt-3"
                      options={renderAPIParameterOptions(IRIAvailableYearList)}
                      id="forecast_category"
                    />
                  </div>
                  <div className="iri-position mt-4">
                    <Label className="iri-label-static">
                      {t("MANAGE_IRI.START_FORECAST_MONTH_LABLE")}
                    </Label>

                    <Select
                      classNamePrefix="react-select"
                      placeholder={t(
                        "MANAGE_IRI.START_FORECAST_MONTH_PLACEHOLDER",
                      )}
                      onChange={handleForecastTimeMonth}
                      className="data-sources-select-1 common-select-r mb-3 mt-3"
                      value={IRIForecastTime?.month}
                      options={renderAPIParameterOptions(IRIAvailableMonthList)}
                      id="forecast_category"
                    />
                  </div>

                  {/* for end date and year */}

                  <div className="iri-position mt-4">
                    <Label className="iri-label-static">
                      {t("MANAGE_IRI.End_FORECAST_YEAR")}
                    </Label>
                    <Select
                      classNamePrefix="react-select"
                      className="data-sources-select-1 common-select-r mt-3"
                      id="forecast_category"
                      onChange={handleEndForecastTimeYear}
                      options={renderAPIParameterOptions(
                        endIRIAvailableYearList,
                      )}
                      placeholder={t(
                        "MANAGE_IRI.End_FORECAST_YEAR_PLACEHOLDER",
                      )}
                      required={false}
                      type="select"
                      value={endIRIForecastTime?.year}
                    />
                    {invalidYear && (
                      <p className="pl-3 error">
                        {t("MANAGE_IRI.END_YEAR_ERROR")}
                      </p>
                    )}
                  </div>
                  <div className="iri-position mt-4">
                    <Label className="iri-label-static">
                      {t("MANAGE_IRI.END_MONTH_LABEL")}
                    </Label>

                    <Select
                      classNamePrefix="react-select"
                      placeholder={t("MANAGE_IRI.END_MONTH_PLACEHOLDER")}
                      className="data-sources-select-1 common-select-r mt-3"
                      onChange={handleEndForecastTimeMonth}
                      id="forecast_category1"
                      value={endIRIForecastTime?.month}
                      options={renderAPIParameterOptions(
                        endIRIAvailableMonthList,
                      )}
                    />
                    {invalidMonth && (
                      <p className="pl-3 error">
                        {" "}
                        {t("MANAGE_IRI.MONTH_ERROR")}
                      </p>
                    )}
                  </div>
                </div>
              </Collapse>
            </div>
            <hr />
            <div className="filter-buttons">
              <Button
                className="btn st-btn-custom-ot clear-filter-btn clear-filter-btn-box-shadow"
                onClick={() => handleClearFilters()}
              >
                {t("FILTER.CLEAR_FILTERS")}
              </Button>
              <Button
                className="btn st-btn-custom manage-static-submit-btn"
                disabled={invalidMonth || invalidYear || emptyIriFields}
              >
                {t("FILTER.SHOW_RESULT")}
              </Button>
            </div>
            <hr />
            <div style={{ height: "200px" }} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RightSideModal;
